import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetLogoutUrl } from '@tradingblock/components';
import { account } from '../../data/global/selectors/accountSelectors';
import { SessionActions } from '../../data/global/actions';
import { ExpiredTokenCode } from '@tradingblock/types';

export const LogoutLink: React.FC<React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>> = ({ children }) => {
  const accountNumber = useSelector(account.accountNumber);
  const dispatch = useDispatch();
  let timeout: NodeJS.Timeout | undefined;
  const expireToken = () => {
    dispatch(
      SessionActions.expired({
        code: ExpiredTokenCode.Logout,
        reason: 'You have successfully logged out. Now redirecting to login page',
      })
    );

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      window.location.href = GetLogoutUrl(accountNumber);
    }, 3500);
  };
  return (
    <button onClick={() => expireToken()} className="caps btn btn-warning" style={{ fontWeight: 'bold', color: '#33415E' }}>
      {children}
    </button>
  );
};
