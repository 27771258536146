import createCachedSelector from 're-reselect';
import { DataState } from '../state';
import _ from 'lodash';

export const IsWarningSymbol = createCachedSelector(
  (s: DataState, underlyingSymbol?: string) => s.symbols.warningSymbols,
  (s: DataState, underlyingSymbol?: string) => underlyingSymbol,
  (warningSymbols, symbol) => {
    if (symbol === undefined) {
      return false;
    }
    if (!warningSymbols) {
      return false;
    }
    return warningSymbols.map(s => s.toLocaleLowerCase()).find(s => s === symbol.toLocaleLowerCase()) ? true : false;
  }
)((s, underlyingSymbol) => underlyingSymbol || 'UNKNOWN');
