import { useEffect, useMemo, useRef } from 'react';
import { useStateSelector } from '../../data/global/dataSelectors';
import { useWhyDidYouUpdate, useWindowSizeContext } from '@tradingblock/components';
import { useDocumentWidth } from '../../hooks/dom/useDocumentWidth';

export const GridResizeSync = () => {
  const layouts = useStateSelector(s => s.layout.layouts);
  
  const windowWidth = useWindowSizeContext().width;
  const documentWidth = useDocumentWidth();

  const hasVerticalScrollbar = useMemo(() => windowWidth > documentWidth, [windowWidth, documentWidth]);

  useWhyDidYouUpdate('GridResizeSync', { layouts, windowWidth, documentWidth, hasVerticalScrollbar });

  const timeout = useRef<any>(null);
  useEffect(() => {
    if (layouts) {
      // hack needed to trigger grid to honor the containerPadding prop passed in (sometimes would ignore padding on right when scrollbar present)
      timeout.current = setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 50);
    }
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [layouts, hasVerticalScrollbar]);
};
