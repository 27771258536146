import createCachedSelector from 're-reselect';
import { DataState, QuoteSymbolQuote } from '../state';
import * as _ from 'lodash';
import { SymbolQuote, SymbolQuoteAndChangeData } from '@tradingblock/api';
import { createSelector } from 'reselect';
import { Fetchable, EarningsTimeframeValues, DividendTimeframeValues } from '@tradingblock/types';
import { createDeepArraySelector } from '../dataSelectors';
import { QuoteDataToPartialQuote } from './transforms';

export const getQuoteData = (state: DataState) => state.quotes.quoteMetadata.data;

const getLoadingAndLoadedSymbols = createSelector(
  getQuoteData,
  (quotes: Record<string, QuoteSymbolQuote>) => {
    return _.filter(quotes, q => q.isLoadedFromApi === true).map(q => q.Symbol);
  }
);
export const filterLoadedFromApiQuotes = createSelector(
  getLoadingAndLoadedSymbols,
  (state: DataState, symbolsToCheck: string[]) => symbolsToCheck,
  (loading: string[], symbolsToCheck: string[]) => {
    const filtered = _.filter(symbolsToCheck, s => !loading.includes(s));
    return filtered;
  }
);

export const getQuoteMetadataOrEmpty = createCachedSelector(
  getQuoteData,
  (_state: DataState, symbol: string) => symbol,
  (quotes: Record<string, SymbolQuote>, symbol: string) => {
    const { NetChange, ClosePrice, Open, High, Low, BidPrice, AskPrice, LastTradePrice } = quotes[symbol] || {
      LastTradePrice: undefined,
      AskPrice: undefined,
      NetChange: undefined,
      ClosePrice: undefined,
      Open: undefined,
      High: undefined,
      Low: undefined,
      BidPrice: undefined,
    };
    return { NetChange, ClosePrice, Open, High, Low, BidPrice, AskPrice, LastTradePrice };
  }
)((_, symbol) => symbol);
export const getQuoteDirection = createCachedSelector(
  getQuoteData,
  (_state: DataState, symbol: string) => symbol,
  (quotes: Record<string, SymbolQuoteAndChangeData>, symbol: string) => {
    const value = quotes[symbol];
    return value ? quotes[symbol].changeDirection : undefined;
  }
)((_, symbol) => symbol);

export const getQuoteMetadata = createCachedSelector(
  getQuoteData,
  (_state: DataState, symbol: string) => symbol,
  (quotes: Record<string, SymbolQuote>, symbol: string) => {
    return quotes[symbol];
  }
)((_, symbol) => symbol);
export const getQuotesBySymbols = createCachedSelector(
  (state: DataState, symbol: string[]) =>
    _.filter(state.quotes.quoteMetadata.data, (v: Fetchable<SymbolQuote>, k: string) => symbol.includes(k)),
  quotes => {
    return quotes;
  }
)((_, symbol) => symbol.sort().join(','));

export const getPartialQuoteForPositions = createCachedSelector(
  (state: DataState, symbols: string[]) =>
    _.filter(state.quotes.quoteMetadata.data, (v: Fetchable<SymbolQuote>, k: string) => symbols.includes(k)),
  QuoteDataToPartialQuote
)((_, symbols) => symbols.sort().join(','));
export const getQuotesBidAskBySymbols = createCachedSelector(
  (state: DataState, blockId: string, symbols: string[]) =>
    _.filter(state.quotes.quoteMetadata.data, (v: Fetchable<SymbolQuote>) => symbols.includes(v.Symbol)),
  quotes => {
    const quoteValues = _.map(quotes, q => ({
      Symbol: q.Symbol,
      AskPrice: q.AskPrice,
      BidPrice: q.BidPrice,
      LastTradePrice: q.LastTradePrice,
      Multiplier: q.Multiplier,
    }));
    return quoteValues;
  }
)((_, blockId, symbol) => `${blockId}`, { selectorCreator: createDeepArraySelector });

export const getQuoteMidpoint = createSelector(
  getQuoteData,
  (_state: DataState, symbol: string) => symbol,
  (quotes: Record<string, SymbolQuote>, symbol: string) => {
    const quoteVal = quotes[symbol] || undefined;
    if (quoteVal) {
      return quoteVal.BidAskMidPrice > 0 ? quoteVal.BidAskMidPrice : quoteVal.LastTradePrice;
    } else {
      return undefined;
    }
  }
);
export const getQuotesMetadata = createCachedSelector(
  getQuoteData,
  (_state: DataState, symbol: string[]) => symbol,
  (quotes: Record<string, SymbolQuote>, symbol: string[]) => {
    const quoteVals: { [key: string]: SymbolQuote } = _.reduce(
      symbol,
      (acc: {}, s) => {
        if (quotes[s] === undefined) {
          return acc;
        }
        return {
          ...acc,
          [s]: quotes[s],
        };
      },
      {}
    );
    return quoteVals;
  }
)(
  // re-reselect keySelector
  // Use "libraryId" as cacheKey
  (_, symbol) => symbol.sort().join(',')
);

const getFilteredQuotes = (state: DataState, symbol: string[] | string): Record<string, SymbolQuote> => {
  const symbols = _.flatMap([symbol]);
  return _.reduce(
    state.quotes.quoteMetadata.data,
    (acc: Record<string, SymbolQuote>, sym) => {
      if (symbols.includes(sym.Symbol)) {
        return {
          ...acc,
          [sym.Symbol]: sym,
        };
      }
      return acc;
    },
    {}
  );
};

export const getQuotesBidAsk = createCachedSelector(
  // getQuoteData,
  // (_state: DataState, symbol: string[]) => symbol,
  [getFilteredQuotes],
  (quotes: Record<string, SymbolQuote>) => {
    const quoteVals: { [key: string]: Pick<SymbolQuote, 'BidPrice' | 'AskPrice'> } = _.reduce(
      quotes,
      (acc: {}, s) => {
        if (s === undefined) {
          return acc;
        }
        const { BidPrice, AskPrice } = s;
        return {
          ...acc,
          [s.Symbol]: { BidPrice, AskPrice },
        };
      },
      {}
    );
    return quoteVals;
  }
)(
  // re-reselect keySelector
  // Use "libraryId" as cacheKey
  (_state, symbol) =>
    _.flatMap([symbol])
      .sort()
      .join(',')
);
export const getQuotesVolumes = createCachedSelector(
  // getQuoteData,
  // (_state: DataState, symbol: string[]) => symbol,
  [getFilteredQuotes],
  (quotes: Record<string, SymbolQuote>) => {
    const quoteVals: { [key: string]: Pick<SymbolQuote, 'Volume'> } = _.reduce(
      quotes,
      (acc: {}, s) => {
        if (s === undefined) {
          return acc;
        }
        const { Volume } = s;
        return {
          ...acc,
          [s.Symbol]: { Volume },
        };
      },
      {}
    );
    return quoteVals;
  }
)(
  // re-reselect keySelector
  // Use "libraryId" as cacheKey
  (_state, symbol) =>
    _.flatMap([symbol])
      .sort()
      .join(',')
);
export const getQuoteBidAsk = createCachedSelector(
  // getQuoteData,
  // (_state: DataState, symbol: string[]) => symbol,
  [(s: DataState, symbol: string) => getQuotesBidAsk(s, symbol)[symbol]],
  quote => {
    return quote;
  }
)(
  // re-reselect keySelector
  // Use "libraryId" as cacheKey
  (_, symbol) => symbol
);

export const getQuoteVolume = createCachedSelector(
  // getQuoteData,
  // (_state: DataState, symbol: string[]) => symbol,
  [(s: DataState, symbol: string) => getQuotesVolumes(s, symbol)[symbol]],
  quote => {
    return quote;
  }
)(
  // re-reselect keySelector
  // Use "libraryId" as cacheKey
  (_, symbol) => symbol
);
const quoteSelector = (state: DataState, symbol: string) =>
  _.find(state.quotes.quoteMetadata.data, (v: Fetchable<SymbolQuote>, k: string) => k === symbol);
const getId = (state: DataState, id: string) => id;
export const quoteSelectors = {
  quote: quoteSelector,
  LastOrClosePrice: createCachedSelector(quoteSelector, quote => {
    return quote ? quote.LastOrClosePrice : undefined;
  })((_, symbol) => `lastOrClosePrice-${symbol}`),
  byId: createCachedSelector(
    // inputSelectors
    state => state.quotes.quoteMetadata.data,
    getId,
    (quotes, id) => quotes[id]
  )(
    // re-reselect keySelector
    // Use "libraryId" as cacheKey
    (_, id) => id
  ),
  earnings: {
    symbolEarnings: createCachedSelector(
      (state: DataState, { symbol, period }: { symbol: string; period: EarningsTimeframeValues }) =>
        state.quotes.earnings[symbol] ? state.quotes.earnings[symbol][period] : { isFetching: true, earnings: [] },
      data => {
        if (_.isNil(data)) {
          return {
            earnings: [],
            isFetching: false,
            isLoading: false,
          };
        }
        const isFetching = data.isFetching;
        return { earnings: data.earnings, isFetching, isLoading: data.earnings.length > 0 ? false : isFetching };
      }
    )((_, { symbol, period }) => `${symbol}-${period}`),
  },
  dividends: {
    symbolDividends: createCachedSelector(
      (state: DataState, { symbol, period }: { symbol: string; period: DividendTimeframeValues }) =>
        state.quotes.dividends[symbol] ? state.quotes.dividends[symbol][period] : { isFetching: true, dividends: [] },
      dividendInfo => {
        if (_.isNil(dividendInfo)) {
          return { dividendInfo: [], isFetching: false, isLoading: false };
        }
        const isFetching = dividendInfo.isFetching;
        return {
          dividendInfo: dividendInfo.dividends,
          isFetching,
          isLoading: dividendInfo.dividends.length > 0 ? false : isFetching,
        };
      }
    )((_, { period, symbol }) => `${symbol}-${period}`),
  },
};

export const quoteExtendedSelectors = {
  quote: createCachedSelector(
    (state: DataState, symbol: string) => state.quotes.quoteExtended.data[symbol],
    extendedQuoteData => extendedQuoteData || undefined
  )((_, id) => id),
  isLoading: createCachedSelector(
    (state: DataState, symbol: string) => state.quotes.quoteMetadata.data[symbol],
    extendedQuoteData => (extendedQuoteData ? extendedQuoteData.isFetching : undefined)
  )((_, id) => id),
  address: createCachedSelector(
    (state: DataState, symbol: string) => state.quotes.quoteExtended.data[symbol],
    extendedQuoteData => (extendedQuoteData ? extendedQuoteData.address1 && extendedQuoteData.address2 : undefined)
  )((_, id) => id),
};
