import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import * as tslib_1 from "tslib";
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { useToggle } from '../../hooks/useToggle';
import { Portal } from '../../containers';
import { getClassNames } from '../../utilities';
export var Dropdown = function Dropdown(_a) {
  var buttonOptions = _a.buttonOptions,
      children = _a.children,
      content = _a.content,
      className = _a.className,
      onToggle = _a.onToggle,
      direction = _a.direction,
      dropdownContent = _a.dropdownContent,
      disabled = _a.disabled,
      rest = tslib_1.__rest(_a, ["buttonOptions", "children", "content", "className", "onToggle", "direction", "dropdownContent", "disabled"]);

  var _ref = buttonOptions || {
    toggleButtonClassName: undefined,
    title: undefined
  },
      toggleButtonClassName = _ref.toggleButtonClassName,
      title = _ref.title;

  var dropDirectionClass = useMemo(function () {
    var dir = direction || 'right';
    return "dropdown-menu-".concat(dir);
  }, [direction]);

  var _useToggle = useToggle(false),
      _useToggle2 = _slicedToArray(_useToggle, 2),
      open = _useToggle2[0],
      toggleDropdown = _useToggle2[1];

  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      parent = _useState2[0],
      setParent = _useState2[1];

  useEffect(function () {
    if (onToggle) {
      onToggle(open);
    }
  }, [open]);
  var toggleClassName = useMemo(function () {
    return toggleButtonClassName ? toggleButtonClassName : 'btn dropdown-toggle';
  }, [toggleButtonClassName]);
  var wrapperClasses = useMemo(function () {
    return getClassNames(['dropdown', className, open ? 'show' : undefined]);
  }, [className, open]);
  var menuClasses = React.useMemo(function () {
    return open ? 'show' : '';
  }, [open]);
  return React.createElement("div", Object.assign({
    className: wrapperClasses
  }, rest, {
    ref: setParent
  }), React.createElement("button", {
    className: toggleClassName,
    "data-toggle": "dropdown",
    onClick: function onClick() {
      return toggleDropdown();
    },
    title: title,
    disabled: disabled === true ? true : false,
    type: "button"
  }, content), React.createElement(Portal, {
    parent: parent,
    scrollableParent: "block-body",
    open: open,
    toggle: toggleDropdown
  }, React.createElement("div", {
    className: "dropdown-menu ".concat(dropDirectionClass, " ").concat(menuClasses),
    onClick: function onClick() {
      return toggleDropdown();
    }
  }, children, dropdownContent && dropdownContent({
    toggleDropdown: toggleDropdown
  }))));
};
export default Dropdown;
export var SplitDropdown = function SplitDropdown(_ref2) {
  var parentClass = _ref2.parentClass,
      setValue = _ref2.setValue,
      values = _ref2.values,
      buttonclassName = _ref2.buttonclassName,
      children = _ref2.children,
      className = _ref2.className,
      split = _ref2.split;

  var _useToggle3 = useToggle(false),
      _useToggle4 = _slicedToArray(_useToggle3, 2),
      open = _useToggle4[0],
      toggleDropdown = _useToggle4[1];

  var _useState3 = useState(),
      _useState4 = _slicedToArray(_useState3, 2),
      parent = _useState4[0],
      setParent = _useState4[1];

  var menuClasses = useMemo(function () {
    return open ? 'show' : '';
  }, [open]);
  var handleOnClick = useCallback(function (val) {
    toggleDropdown(false);

    if (setValue) {
      setValue(val);
    }
  }, [setValue, toggleDropdown]);
  return React.createElement("div", {
    className: "btn-group ".concat(className || ''),
    ref: setParent
  }, React.createElement("button", {
    type: "button",
    className: "btn ".concat(buttonclassName),
    onClick: function onClick() {
      return toggleDropdown();
    }
  }, children), split && React.createElement("button", {
    onClick: function onClick() {
      return toggleDropdown();
    },
    type: "button",
    className: "btn ".concat(buttonclassName, " dropdown-toggle ").concat(split === true ? 'dropdown-toggle-split' : ''),
    "data-toggle": "dropdown",
    "aria-haspopup": "true",
    "aria-expanded": open ? 'true' : 'false'
  }, React.createElement("span", {
    className: "sr-only"
  }, "Toggle Dropdown")), React.createElement(Portal, {
    parent: parent,
    scrollableParent: parentClass || 'block-body',
    open: open,
    toggle: toggleDropdown
  }, React.createElement("div", {
    className: "dropdown-menu ".concat(menuClasses)
  }, values.map(function (rv) {
    return React.createElement("button", {
      key: "dropdown-item-".concat(rv.name),
      className: "dropdown-item",
      onClick: function onClick() {
        return handleOnClick(rv);
      },
      type: "button"
    }, rv.label ? rv.label : rv.value);
  }))));
};
export var DropdownWrapper = function DropdownWrapper(_a) {
  var parentClass = _a.parentClass,
      children = _a.children,
      content = _a.content,
      className = _a.className,
      onToggle = _a.onToggle,
      direction = _a.direction,
      dropdownContent = _a.dropdownContent,
      disabled = _a.disabled,
      rest = tslib_1.__rest(_a, ["parentClass", "children", "content", "className", "onToggle", "direction", "dropdownContent", "disabled"]);

  var dropDirectionClass = useMemo(function () {
    var dir = direction || 'right';
    return "dropdown-menu-".concat(dir);
  }, [direction]);

  var _useToggle5 = useToggle(false),
      _useToggle6 = _slicedToArray(_useToggle5, 2),
      open = _useToggle6[0],
      toggleDropdown = _useToggle6[1];

  var _useState5 = useState(),
      _useState6 = _slicedToArray(_useState5, 2),
      parent = _useState6[0],
      setParent = _useState6[1];

  useEffect(function () {
    if (onToggle) {
      onToggle(open);
    }
  }, [open]);
  var wrapperClasses = useMemo(function () {
    return getClassNames(['dropdown', className, open ? 'show' : undefined]);
  }, [className, open]);
  var menuClasses = React.useMemo(function () {
    return open ? 'show' : '';
  }, [open]);
  return React.createElement("div", Object.assign({
    className: wrapperClasses
  }, rest, {
    ref: setParent
  }), React.createElement("div", {
    onClick: function onClick() {
      return toggleDropdown();
    }
  }, children), React.createElement(Portal, {
    parent: parent,
    scrollableParent: parentClass || 'block-body',
    open: open,
    toggle: toggleDropdown
  }, React.createElement("div", {
    className: "dropdown-menu ".concat(dropDirectionClass, " ").concat(menuClasses),
    onClick: function onClick() {
      return toggleDropdown();
    }
  }, content, dropdownContent && dropdownContent({
    toggleDropdown: toggleDropdown
  }))));
};