import { createSelector } from 'reselect';
import { createDeepSelector } from '../../../data/global/dataSelectors';
import { PriceChartState } from './PriceChartState';

const rootSelector = (state: PriceChartState) => state;
const symbolSel = createSelector(
  rootSelector,
  v => v.symbol
);
const quoteSel = createDeepSelector(rootSelector, v => v.quote);
const isFetchingSel = createDeepSelector(rootSelector, v => v.isFetching);
const isLoadedSel = createDeepSelector(rootSelector, v => v.isLoaded);
export const PriceChartSelectors = {
  symbol: symbolSel,
  symbolName: createDeepSelector(symbolSel, v => (v ? v.symbol : undefined)),
  quote: createDeepSelector(quoteSel, q => (q ? q : undefined)),
  isFetching: createDeepSelector(isFetchingSel, f => f),
  isLoaded: createDeepSelector(symbolSel, isLoadedSel, (s, i) => {
    return i === true && s !== undefined;
  }),
};
