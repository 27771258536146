import { debug } from 'debug';
import _ from 'lodash';

var extendLog = function extendLog(log, subnames) {
  return _.reduce(subnames, function (l, name) {
    return l.extend(name);
  }, log);
};

export var useDebug = function useDebug(subnames) {
  var log = debug("tb");

  if (subnames) {
    if (typeof subnames === 'string') {
      return log.extend(subnames);
    }

    return extendLog(log, subnames);
  }

  return log;
};
export var useLog = useDebug;
export var setLogLevel = function setLogLevel(val) {
  return debug.enable(val);
};