import React, { useMemo, useEffect, useState } from 'react';
import _ from 'lodash';
import { BlockBody } from '@tradingblock/components';
import { AssetSymbol, BlockType } from '@tradingblock/types';
import { useApi } from '../../context/Api';
import { useAuthenticationSelector } from '../../data/global/dataSelectors';
import { Block } from '../../components/blocks/Block';
import { useBlockData, useBlockActions } from '../../components/blocks/BlockState';
import { getBlockProps } from '../../utilities/block';
import { useQuoteSymbolSubscription } from '../../hooks/useQuoteData';
import { Config } from '../../config';
import { PriceChartState } from './state/PriceChartState';
import { PriceChartSelectors as selectors } from './state/PriceChartSelectors';
import { DefaultPriceChartSettings, PriceChartSettingsFields, usePriceChartSettings } from './state/PriceChartSettings';
import { usePriceChartGroupHandler } from './hooks/usePriceChartGroupHandler';
import { PriceChartHeader } from './PriceChartHeader';
import { PriceChartContent } from './PriceChartContent';

export const PriceChartBlock: React.FC<{}> = () => {
  const state = useBlockData<PriceChartState>();

  const symbol = selectors.symbol(state);
  const { setField: set } = useBlockActions<PriceChartState>();
  const { isAuthenticated } = useAuthenticationSelector();
  const [loadedSymbol, setLoadedSymbol] = useState<string>();
  const api = useApi();
  usePriceChartGroupHandler();
  const symbolTicker = useMemo(() => (symbol ? symbol.symbol : undefined), [symbol]);
  // useQuoteValue();
  useEffect(() => {
    const getQuote = async (symbolName: string) => {
      set('isFetching', true);
      const [quoteRequest] = api.quotes.get(symbolName);
      const res = await quoteRequest;
      if (res && res.payload && res.payload[0]) {
        const firstQuote = res.payload[0];
        set('quote', firstQuote);
      }
      set('isFetching', false);
    };
    if (symbolTicker && isAuthenticated && loadedSymbol !== symbolTicker) {
      setLoadedSymbol(symbolTicker);
      getQuote(symbolTicker);
    }
  }, [symbolTicker, set, isAuthenticated, api, loadedSymbol]);

  // only settings available are for dev/debug
  const settings = usePriceChartSettings();
  const priceSettings = !Config.isProduction ? { ...DefaultPriceChartSettings, ...settings } : undefined;
  useQuoteSymbolSubscription<PriceChartState>((blockState: PriceChartState) =>
    blockState.symbol ? blockState.symbol.symbol : undefined
  );

  return (
    <Block
      isRefreshable={true}
      settings={priceSettings}
      settingFields={PriceChartSettingsFields}
      {...getBlockProps(
        BlockType.PriceChart,
        <PriceChartHeader setSymbol={(sym: AssetSymbol) => set('symbol', sym, { persist: true })} />
      )}
    >
      <BlockBody>
        <PriceChartContent symbol={symbol} />
      </BlockBody>
    </Block>
  );
};
