import React from 'react';
import _ from 'lodash';
import { AlertType } from '@tradingblock/types';
import { getFormikErrors } from './Errors';
export var getAlertProps = function getAlertProps(formErrors, status) {
  if (status && status.alertType && status.message) {
    return status;
  }

  return {
    alertType: AlertType.warning,
    message: getFormikErrors(formErrors),
    messageHeader: 'Please correct the following errors'
  };
};

var isAlertEmpty = function isAlertEmpty(_ref) {
  var message = _ref.message;
  return !message || _.isEmpty(_.join(message));
};

export var Alert = function Alert(_ref2) {
  var alertType = _ref2.alertType,
      message = _ref2.message,
      messageHeader = _ref2.messageHeader;

  if (isAlertEmpty({
    message: message
  })) {
    return null;
  }

  return React.createElement("div", {
    className: "alert alert-".concat(alertType)
  }, messageHeader ? React.createElement("h4", null, React.createElement("i", {
    className: "fal fa-exclamation-triangle"
  }), " ", messageHeader) : React.createElement("i", {
    className: "fal fa-exclamation-triangle"
  }), ' ', _.map(_.isString(message) ? [message] : message, function (m, i) {
    return React.createElement("span", {
      key: "message".concat(i)
    }, m, React.createElement("br", null));
  }));
};
export var AlertSection = function AlertSection(props) {
  if (isAlertEmpty(props)) {
    return null;
  }

  return React.createElement("div", {
    className: "screen-body-section"
  }, React.createElement(Alert, Object.assign({}, props)));
};