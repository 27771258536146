import { Middleware, Dispatch } from 'redux';
import { getType, ActionType } from 'typesafe-actions';
import { DataState } from '../../state';
import { RootAction, SessionActions } from '../../actions';
import { InfoDataActions } from '../../actions/InfoActions';
import { ApiProvider } from '../../../../context/Api';
import { AccountManagementSearchRequestSelector } from '../../selectors/infoSelectors';
import dayjs from 'dayjs';
import { MarketNews } from '@tradingblock/types';
import _ from 'lodash';

const timeout = {
  set: (timer: NodeJS.Timeout) => {
    (window as any).marketNewsUpdateTimeout = timer;
  },
  clear: () => {
    const existingTimer = (window as any).marketNewsUpdateTimeout;
    if (existingTimer) {
      console.debug('clearing market news timeout');
      clearInterval(existingTimer);
    }
  },
};

const loadInformation = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof InfoDataActions.requestReleaseNotes>
) => {
  const api = ApiProvider(state, dispatch);
  if (api) {
    try {
      const response: any = await api.information.get(action.payload);
      dispatch(InfoDataActions.receiveReleaseNotes({ response: response, request: action.payload }));
    } catch (err) {
      console.error('loadInformation error', err);
      dispatch(InfoDataActions.errorReleaseNotes({ error: err }));
    }
  }
};

const createReleaseNote = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof InfoDataActions.requestCreateRelease>
) => {
  const api = ApiProvider(state, dispatch);

  const response = api.information.createReleaseNote(action.payload);

  try {
    dispatch(InfoDataActions.receiveCreateRelease({ response }));
  } catch (err) {
    dispatch(InfoDataActions.errorCreateRelease({ error: err }));
  }
};

const updateReleaseNote = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof InfoDataActions.requestUpdateRelease>
) => {
  const api = ApiProvider(state, dispatch);
  if (api) {
    try {
      const { type, summary, releaseDate, id, restrictedToUserLevel } = action.payload;
      const response = await api.information.updateReleaseNote({
        id,
        type,
        summary,
        releaseDate,
        restrictedToUserLevel,
      });
      if (response.responseCode === 0) {
        dispatch(InfoDataActions.receiveUpdateRelease({ response: response.payload }));
      }
      return response;
    } catch (err) {
      console.error('updateReleaseNote error', err);
      return err;
    }
  }
};

const loadMarketNews = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof InfoDataActions.requestMarketNews>
) => {
  const api = ApiProvider(state, dispatch);
  const { symbols, updatedSince, channels, blockId } = action.payload;
  const stateMarketNews = state.information.marketNews[blockId].data;
  const priorChannelRequest = state.information.marketNews[blockId].priorChannelRequest;
  const priorSymbolRequest = state.information.marketNews[blockId].priorSymbolRequest;
  const channelsChanged = priorChannelRequest && priorChannelRequest !== channels;
  const symbolsChanged = priorSymbolRequest && priorSymbolRequest !== symbols;

  if (api) {
    try {
      // If the user selected channels have changed, we need to make a new request
      if (channelsChanged || symbolsChanged) {
        const { payload } = await api.information.news(symbols, dayjs.unix(0).toISOString(), channels);
        payload
          ? dispatch(InfoDataActions.receiveMarketNews({ symbols, marketNews: payload, channels: channels, blockId }))
          : dispatch(InfoDataActions.receiveMarketNews({ symbols, marketNews: [], channels: channels, blockId }));
        return;
      }
      // If market news has already been loaded, update the updatedSince date with the latest updated time from the list of articles to request only new articles
      if (stateMarketNews) {
        const latestArticleUpdate = stateMarketNews.sort((a, b) => {
          return dayjs(b.updated).unix() - dayjs(a.updated).unix();
        })[0].updated;

        const unixLatestArticleUpdate = dayjs(latestArticleUpdate).unix();
        const isoStringLatestArticleUpdate = dayjs.unix(unixLatestArticleUpdate).toISOString();

        const { payload } = await api.information.news(symbols, isoStringLatestArticleUpdate, channels);
        const updatedSinceArticles = payload;

        // If no new articles are present in the response, return the existing market news
        if (!updatedSinceArticles) {
          dispatch(
            InfoDataActions.receiveMarketNews({ symbols, marketNews: stateMarketNews, channels: channels, blockId })
          );
        } else {
          /* If new articles are present in the response, merge the existing market news with the new articles
          // Seperate the articles response into duplicated updates and new articles
          // Reinsert updated articles into their original positions with new data from the response
          // If brand new articles, remove the same amount from the end of the existing market news and insert the new articles
          // Sort the articles by updated date and dispatch recieveMarketNews with the updated market news */
          let duplicatedArticles: MarketNews[] = [];
          let newArticles: MarketNews[] = [];
          updatedSinceArticles.forEach(article => {
            const isArticleDuplicated = stateMarketNews.some(a => {
              return a.id === article.id;
            });
            isArticleDuplicated ? duplicatedArticles.push(article) : newArticles.push(article);
          });
          if (_.isEmpty(newArticles) && _.isEmpty(duplicatedArticles)) {
            dispatch(
              InfoDataActions.receiveMarketNews({
                symbols,
                marketNews: stateMarketNews,
                channels: channels,
                blockId,
              })
            );
          } else {
            if (!_.isEmpty(duplicatedArticles)) {
              duplicatedArticles.forEach(article => {
                const index = stateMarketNews.findIndex(a => a.id === article.id);
                stateMarketNews[index] = article;
              });
            }
            if (!_.isEmpty(newArticles)) {
              const startIndex = stateMarketNews.length - newArticles.length;
              const deleteCount = newArticles.length;
              stateMarketNews.splice(startIndex, deleteCount);
              newArticles.forEach(article => {
                stateMarketNews.push(article);
              });
            }
            stateMarketNews.sort((a, b) => {
              return dayjs(b.updated).unix() - dayjs(a.updated).unix();
            });
            dispatch(
              InfoDataActions.receiveMarketNews({
                symbols,
                marketNews: stateMarketNews,
                channels: channels,
                blockId,
              })
            );
          }
        }
      } else {
        const { payload } = await api.information.news(symbols, updatedSince, channels);
        dispatch(
          InfoDataActions.receiveMarketNews({ symbols, marketNews: payload, channels: channels, blockId: blockId })
        );
      }
    } catch (err) {
      console.error('loadMarketNews error', err);
      dispatch(InfoDataActions.errorMarketNews({ error: err, symbols, blockId }));
    }
  }
};

const startPollingMarketNews = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof InfoDataActions.beginPollingForMarketNews>,
  previousState: DataState
) => {
  const { symbols, updatedSince, channels, timeoutMS, blockId } = action.payload;
  timeout.clear();
  console.debug(`requesting market news every ${timeoutMS} ms`);
  if (previousState.information.isPollingForMarketNews === false) {
    console.debug('making initial market news request');
    dispatch(InfoDataActions.requestMarketNews({ symbols, updatedSince, channels, blockId }));
  } else {
    timeout.clear();
  }
  timeout.set(
    setInterval(() => {
      dispatch(InfoDataActions.requestMarketNews({ symbols, updatedSince, channels, blockId }));
    }, timeoutMS)
  );
};

const stopPollingMarketNews = async (state: DataState, dispatch: Dispatch<RootAction>) => {
  timeout.clear();
  console.debug(`stopped market news polling`);
};

const loadAlertMessages = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof InfoDataActions.requestAlertMessages>
) => {
  const api = ApiProvider(state, dispatch);
  if (api) {
    try {
      const response = await api.information.getActiveAlerts(action.payload);
      dispatch(InfoDataActions.receiveAlertMessages({ response: response.payload }));
      return response;
    } catch (err) {
      console.error('requestAlertMessages error', err);
      return err;
    }
  }
};

export const InformationMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  try {
    const previousState = getState();

    // state BEFORE action is dispatched
    const result = next(action);
    // state AFTER action is dispatched
    const nextState = getState();
    switch (action.type) {
      case getType(InfoDataActions.setReleaseNotesPage):
      case getType(InfoDataActions.setReleaseNotesPageSize): {
        const prevRequestKey = AccountManagementSearchRequestSelector(previousState);
        const { request, key } = AccountManagementSearchRequestSelector(nextState);

        if (prevRequestKey.key === key) {
          break;
        }

        dispatch(InfoDataActions.requestReleaseNotes(request));
        break;
      }

      case getType(InfoDataActions.requestReleaseNotes): {
        loadInformation(nextState, dispatch, action);
        break;
      }
      case getType(InfoDataActions.beginPollingForMarketNews): {
        startPollingMarketNews(nextState, dispatch, action, previousState);
        break;
      }
      case getType(InfoDataActions.stopPollingForMarketNews): {
        stopPollingMarketNews(nextState, dispatch);
        break;
      }
      case getType(InfoDataActions.requestMarketNews): {
        loadMarketNews(nextState, dispatch, action);
        break;
      }
      case getType(SessionActions.expired): {
        dispatch(InfoDataActions.stopPollingForMarketNews());
        break;
      }
      case getType(InfoDataActions.requestCreateRelease): {
        createReleaseNote(nextState, dispatch, action);
        break;
      }
      case getType(InfoDataActions.requestAlertMessages): {
        loadAlertMessages(nextState, dispatch, action);
        break;
      }
      case getType(InfoDataActions.requestUpdateRelease): {
        updateReleaseNote(nextState, dispatch, action);
        break;
      }
    }
    return result;
  } catch (err) {
    console.error('InformationMiddleware :: Caught an exception for action ', action, err);
  }
};
