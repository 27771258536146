import React from 'react';
import { AccountManagementBaseUpdate } from '@tradingblock/types';
import { AccountUpdateItemStatus } from '../AccountManagementUpdateStatus';

export const ItemCell: React.FunctionComponent<Pick<AccountManagementBaseUpdate, 'state' | 'item' | 'updated'>> = ({
  state,
  item,
}) => {
  if (item === 'PhoneNumber') {
    return (
      <>
        {item.replace(/([A-Z])/g, ' $1').trim()}
        <br />
        <AccountUpdateItemStatus state={state} className={'txt-sm'} />
      </>
    );
  }
  if (item === 'Flexability') {
    return (
      <>
        Flexibility
        <br />
        <AccountUpdateItemStatus state={state} className={'txt-sm'} />
      </>
    );
  }
  return (
    <>
      {item.replace(/([A-Z])/g, ' $1').trim()}
      <br />
      <AccountUpdateItemStatus state={state} className={'txt-sm'} />
    </>
  );
};
