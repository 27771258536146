import {
  CashieringEntityRequest,
  CashieringEntityChangeRequest,
  ApiResponse,
  AchRelationship,
  GenericError,
  CashieringDetailRelationship,
} from '@tradingblock/types';
import { buildGenericRequestReceiveActions, actionBuilder } from '../../actionUtilities';
import { AdminIdRequest } from '.';

const ADMIN_CASHIERING_RELATIONSHIP_REQUEST = '@admin/cashiering/relationship/REQUEST';
const ADMIN_CASHIERING_RELATIONSHIP_RECEIVE = '@admin/cashiering/relationship/RECEIVE';
const ADMIN_CASHIERING_RELATIONSHIP_ERROR = '@admin/cashiering/relationship/ERROR';

const ADMIN_CASHIERING_RELATIONSHIP_DETAIL_REQUEST = '@admin/cashiering/relationship/DETAIL/REQUEST';
const ADMIN_CASHIERING_RELATIONSHIP_DETAIL_RECEIVE = '@admin/cashiering/relationship/DETAIL/RECEIVE';
const ADMIN_CASHIERING_RELATIONSHIP_DETAIL_ERROR = '@admin/cashiering/relationship/DETAIL/ERROR';

const ADMIN_CASHIERING_RELATIONSHIP_APPROVECREATE_FIRM_REQUEST =
  '@admin/cashiering/relationship/APPROVE_CREATE/REQUEST';
const ADMIN_CASHIERING_RELATIONSHIP_APPROVECREATE_FIRM_RECEIVE =
  '@admin/cashiering/relationship/APPROVE_CREATE/RECEIVE';
const ADMIN_CASHIERING_RELATIONSHIP_APPROVECREATE_FIRM_ERROR = '@admin/cashiering/relationship/APPROVE_CREATE/ERROR';

const ADMIN_CASHIERING_RELATIONSHIP_REJECT_REQUEST = '@admin/cashiering/relationship/REJECT/REQUEST';
const ADMIN_CASHIERING_RELATIONSHIP_REJECT_RECEIVE = '@admin/cashiering/relationship/REJECT/RECEIVE';
const ADMIN_CASHIERING_RELATIONSHIP_REJECT_ERROR = '@admin/cashiering/relationship/REJECT/ERROR';

const ADMIN_CASHIERING_RELATIONSHIP_CANCEL_REQUEST = '@admin/cashiering/relationship/CANCEL/REQUEST';
const ADMIN_CASHIERING_RELATIONSHIP_CANCEL_RECEIVE = '@admin/cashiering/relationship/CANCEL/RECEIVE';
const ADMIN_CASHIERING_RELATIONSHIP_CANCEL_ERROR = '@admin/cashiering/relationship/CANCEL/ERROR';

const ADMIN_CASHIERING_RELATIONSHIP_UPDATE_REQUEST = '@admin/cashiering/relationship/UPDATE/REQUEST';
const ADMIN_CASHIERING_RELATIONSHIP_UPDATE_RECEIVE = '@admin/cashiering/relationship/UPDATE/RECEIVE';
const ADMIN_CASHIERING_RELATIONSHIP_UPDATE_ERROR = '@admin/cashiering/relationship/UPDATE/ERROR';

const [
  cashieringRelationshipRequest,
  cashieringRelationshipReceive,
  cashieringRelationshipError,
] = buildGenericRequestReceiveActions({
  REQUEST: ADMIN_CASHIERING_RELATIONSHIP_REQUEST,
  RECEIVE: ADMIN_CASHIERING_RELATIONSHIP_RECEIVE,
  ERROR: ADMIN_CASHIERING_RELATIONSHIP_ERROR,
})<CashieringEntityRequest, ApiResponse<AchRelationship>, GenericError<any, AdminIdRequest>>();
const [
  cashieringRelationshipDetailRequest,
  cashieringRelationshipDetailReceive,
  cashieringRelationshipDetailError,
] = buildGenericRequestReceiveActions({
  REQUEST: ADMIN_CASHIERING_RELATIONSHIP_DETAIL_REQUEST,
  RECEIVE: ADMIN_CASHIERING_RELATIONSHIP_DETAIL_RECEIVE,
  ERROR: ADMIN_CASHIERING_RELATIONSHIP_DETAIL_ERROR,
})<CashieringEntityRequest, ApiResponse<CashieringDetailRelationship>, GenericError<any, AdminIdRequest>>();
const [
  approveCreateRelationshipRequest,
  approveCreateRelationshipReceive,
  approveCreateRelationshipError,
] = actionBuilder<CashieringEntityChangeRequest, AchRelationship>()(
  ADMIN_CASHIERING_RELATIONSHIP_APPROVECREATE_FIRM_REQUEST,
  ADMIN_CASHIERING_RELATIONSHIP_APPROVECREATE_FIRM_RECEIVE,
  ADMIN_CASHIERING_RELATIONSHIP_APPROVECREATE_FIRM_ERROR
);
const [rejectRelationshipRequest, rejectRelationshipReceive, rejectRelationshipError] = actionBuilder<
  CashieringEntityChangeRequest,
  AchRelationship
>()(
  ADMIN_CASHIERING_RELATIONSHIP_REJECT_REQUEST,
  ADMIN_CASHIERING_RELATIONSHIP_REJECT_RECEIVE,
  ADMIN_CASHIERING_RELATIONSHIP_REJECT_ERROR
);
const [cancelRelationshipRequest, cancelRelationshipReceive, cancelRelationshipError] = actionBuilder<
  CashieringEntityChangeRequest,
  AchRelationship
>()(
  ADMIN_CASHIERING_RELATIONSHIP_CANCEL_REQUEST,
  ADMIN_CASHIERING_RELATIONSHIP_CANCEL_RECEIVE,
  ADMIN_CASHIERING_RELATIONSHIP_CANCEL_ERROR
);
const [updateRelationshipRequest, updateRelationshipReceive, updateRelationshipError] = actionBuilder<
  CashieringEntityChangeRequest & { note?: string; adminNote?: string; alertRep?: boolean },
  AchRelationship
>()(
  ADMIN_CASHIERING_RELATIONSHIP_UPDATE_REQUEST,
  ADMIN_CASHIERING_RELATIONSHIP_UPDATE_RECEIVE,
  ADMIN_CASHIERING_RELATIONSHIP_UPDATE_ERROR
);

export const AdminCashieringRelationshipActions = {
  cashieringRelationshipRequest,
  cashieringRelationshipReceive,
  cashieringRelationshipError,
  cashieringRelationshipDetailRequest,
  cashieringRelationshipDetailReceive,
  cashieringRelationshipDetailError,
  approveCreateRelationshipRequest,
  approveCreateRelationshipReceive,
  approveCreateRelationshipError,
  rejectRelationshipRequest,
  rejectRelationshipReceive,
  rejectRelationshipError,
  cancelRelationshipRequest,
  cancelRelationshipReceive,
  cancelRelationshipError,
  updateRelationshipRequest,
  updateRelationshipReceive,
  updateRelationshipError,
};
