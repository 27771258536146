import { getType } from 'typesafe-actions';
import { RootAction } from '../actions';
import { AccountStatementsActions } from '../actions/AccountStatementsActions';
import { InitialState } from '../initialState';

export const accountStatements = (state = InitialState.accountStatements, action: RootAction) => {
  switch (action.type) {
    case getType(AccountStatementsActions.receiveDailyAccountStatements): {
      return {
        ...state,
        dailyStatements: {
          ...state.dailyStatements,
          data: action.payload.data,
        },
      };
    }
    case getType(AccountStatementsActions.receiveMonthlyAccountStatements): {
      return {
        ...state,
        monthlyStatements: {
          ...state.monthlyStatements,
          data: action.payload.data,
        },
      };
    }
    case getType(AccountStatementsActions.receiveTaxAccountStatements): {
      return {
        ...state,
        taxStatements: {
          ...state.taxStatements,
          data: action.payload.data,
        },
      };
    }
    default:
      return state;
  }
};
