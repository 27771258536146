import React from 'react';
import { useField } from 'formik';
import { GenericField, ChoiceSettingsField } from './GenericField';

export const GenericChoiceField: React.FunctionComponent<Omit<ChoiceSettingsField, 'type'>> = ({ values, name, label, ...props }) => {
  const fieldName = name || '';
  const [field] = useField({ ...props, name: fieldName });

  return <GenericField name={fieldName} value={field.value} settings={{ options: values, label }} usePortalForSelect={true} />;
};
