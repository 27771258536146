import _ from 'lodash';
var MoneyBlockSiteGrp = 'mb';

var isMoneyBlockUrl = function isMoneyBlockUrl(href) {
  console.log('isMoneyBlockUrl', process.env.TB_SITE_GROUP);
  return process.env.TB_SITE_GROUP === MoneyBlockSiteGrp;
};

export var isMoneyBlockAccount = function isMoneyBlockAccount(accountNumber) {
  // MoneyBlock account numbers start with "3OD"
  return _.take(accountNumber, 3).join('') === '3OD';
};

var isMoneyBlockSite = function isMoneyBlockSite(href, accountNumber) {
  return isMoneyBlockUrl(href) && !accountNumber || isMoneyBlockAccount(accountNumber);
};

export var GetSiteName = function GetSiteName(href, accountNumber) {
  if (isMoneyBlockSite(href, accountNumber)) {
    return 'MoneyBlock';
  }

  return 'TradingBlock';
};
export var GetSiteGrp = function GetSiteGrp(href, accountNumber) {
  if (isMoneyBlockSite(href, accountNumber)) {
    return MoneyBlockSiteGrp;
  }

  return '';
};
export var getSiteLogo = function getSiteLogo(href, type, accountNumber) {
  // for logo mark, site branding not needed
  if (type === 'mark') {
    return "logo-".concat(type);
  }

  var logo = "logo-".concat(GetSiteName(href, accountNumber).toLowerCase());

  if (type) {
    return "".concat(logo, "-").concat(type);
  }

  return logo;
};