import React from 'react';
import {
  CashieringRoute,
  CashieringManageAction,
  TransferBankIdentifierType,
  TransferRecipientBank,
  TransferInstructionsRequest,
  UpdateTransferInstructionsRequest,
  DeleteTransferInstructionsRequest,
  ClearerType,
} from '@tradingblock/types';
import { FormGroup, SiteName } from '@tradingblock/components';
import { AccountsListProps } from './AccountsList';
import { TradingBlockLink } from '../../../../components/basic/TradingBlockLink';
import { RecipientBankList } from './RecipientBankList';
import Config from '../../../../config';
import { useClearer } from '../../../../hooks/useClearer';
import { useStateSelector } from '../../../../data/global/dataSelectors';

interface SelectWireTransferProps extends AccountsListProps {
  setManageAction: (
    action: CashieringManageAction | undefined,
    data?: TransferRecipientBank | TransferInstructionsRequest
  ) => void;
}

export const SelectWireTransfer: React.FunctionComponent<SelectWireTransferProps> = ({
  route,
  values,
  setValues,
  setManageAction,
}) => {
  const selectable = route !== CashieringRoute.Accounts;
  const clearer = useClearer();
  const origin = useStateSelector(s => s.auth.origin);

  const onAddRecipientBank = () => {
    setManageAction(CashieringManageAction.Add, {
      identifierType: TransferBankIdentifierType.ABA,
    } as TransferRecipientBank);
  };

  const onRenameTransferInstruction = (data: UpdateTransferInstructionsRequest) => {
    setManageAction(CashieringManageAction.Rename, data);
  };

  const onDeleteTransferInstruction = (data: DeleteTransferInstructionsRequest) => {
    setManageAction(CashieringManageAction.Delete, data);
  };

  return (
    <>
      {route === CashieringRoute.Deposit && clearer === ClearerType.Apex && (
        <APEXWireDetails />
      )}

      {route === CashieringRoute.Deposit && clearer === ClearerType.RQD && (
        <RQDWireDetails />
      )}

      {route !== CashieringRoute.Deposit && (
        <>
          {selectable && (
            <p>
              <span className="mute">
                Wire transfers submitted before 1:30pm CT are processed same day.{' '}
                <TradingBlockLink to={origin === 'mb' ? 'MBFeesUrl' : 'TBFeesUrl'}>Fees apply</TradingBlockLink>.
              </span>
            </p>
          )}
          <FormGroup>
            <RecipientBankList
              field="transfer.instructionId"
              values={values}
              setValues={setValues}
              selectable={selectable}
              addBtn={{ text: 'Save new bank account for wire transfers' }}
              onAdd={onAddRecipientBank}
              onRename={selectable ? undefined : onRenameTransferInstruction}
              onDelete={selectable ? undefined : onDeleteTransferInstruction}
            />
          </FormGroup>
        </>
      )}
    </>
  );
};

export function APEXWireDetails() {
  return (
    <>
          <p>
            <span className="mute">
              Incoming wires are free and must be initiated with your financial institution. Wire transfers typically
              require 1 business day to process.
            </span>
          </p>
          <FormGroup>
            <p>
              Please instruct your bank to include the sender name on the wire. This information must be included for
              the transfer to be credited to your account. A rejected wire may incur a bank fee.
            </p>
            <p>
              <strong>Third-party Wires:</strong> We typically do not accept third-party wires. An exception MAY be made
              if there is confirmation of ownership and the relationship between the account holders. Such wire request
              will be subject to review, require verification prior to acceptance and may be returned. Third-party
              foreign wires are not accepted under any circumstances.
            </p>
            <p>
              A wire from a joint bank/brokerage account may be deposited into a account by either or both joint account
              owners. A wire from an individual bank/brokerage account may be deposited into a joint account if that
              person is one of the account owners.
            </p>
            <p>
              <strong>Please send USD incoming wires as follows:</strong>
              <br />
              BMO Harris Bank
              <br />
              111 W. Monroe St.
              <br />
              Chicago, IL 60603
              <br />
            </p>
            <p>
              ABA: 071000288 (for non-domestic USD wires, use SWIFT Code: HATRUS44)
              <br />
              Acct: {'3713286'}
              <br />
              {'FBO: Apex Clearing Corporation'}
              <br />
              FFC: Account Holder's Name, <SiteName siteGroup={Config.siteGroup} /> Account Number
              <br />
            </p>
            <p>
              IMPORTANT NOTE: Without FFC Information, your wire may be rejected. If your bank does not have a specific
              FFC instruction field, enter the Account Holder's Name and Account Number in the "Message to recipient" or
              "Message to recipient bank" field.
            </p>
            <p>
              Please include the address for {'Apex Clearing'} on
              incoming wires:
            </p>
            <p>
              {'Apex Clearing Corporation'}
              <br />
              {'350 N St Paul, Ste. 1400'}
              <br />
              {'Dallas, TX 75201'}
              <br />
            </p>
          </FormGroup>
        </>
  )
}

export function RQDWireDetails() {

  return (
    <>
    <p>
          <span className="mute">
              Incoming wires are free and must be initiated with your financial institution. Wire transfers 
              typically require 1 business day to process.
            </span>
          </p>
          <FormGroup>
            <p>
              Please instruct your bank to include the sender name on the wire. This information must be 
              included for the transfer to be credited to your account. A rejected wire may incur a bank 
              fee.
            </p>
            <p>
              <strong>Third-party Wires:</strong> We typically do not accept third-party wires. An exception MAY be 
              made if there is confirmation of ownership and the relationship between the account 
              holders. Such wire request will be subject to review, require verification prior to acceptance 
              and may be returned. Third-party foreign wires are not accepted under any circumstances.
            </p>
            <p>
              <ul>
              <li>A wire from a joint bank/brokerage account may be deposited into a account by 
              either or both joint account owners. </li>
              <li>A wire from an individual bank/brokerage account may be deposited into a joint 
              account if that person is one of the account owners.</li>
              <li>A wire from an entity bank/brokerage account may only be deposited into a like-titled
              TradingBlock entity account.</li>
              </ul>
            </p>
            <p>
              <strong>Please send USD incoming wires to the following:</strong>
              <br />
              Bank Name: BMO Harris Bank, N.A.
              <br />
              Routing #/ABA: 071000288
              <br />
              Account Number: 2316222
              <br />
              Attention: RQD Clearing LLC
              <br />
              FBO: TradingBlock Account Number (beginning with “TBLK-“), Account Name
              <br />
            </p>
            <p>
              <strong>IMPORTANT NOTE:</strong>
              <br />
              Without FBO Information, your wire may be rejected. If your bank does 
              not have a specific FBO instruction field, enter the TradingBlock Account Number (beginning 
              with “TBLK-“) and Account Name in the "Message to recipient" or "Addendum" field.
            </p>
            <p>
              <strong>RQD* Clearing address (if needed):</strong>
              <br />
              250 VESEY STREET
              <br />
              SUITE 2601
              <br />
              NEW YORK, NY 10281 USA
            </p>
            <p>
              <strong>RETIREMENT ACCOUNT</strong>
              Wire contributions for an Individual Retirement Account will be coded as a “Same Year 
              Contribution”. To change/correct the contribution code, complete and submit the <a target='_blank' href="https://legacy.tradingblock.com/Admin/Forms/RQD/Cashiering/RQD_IRA_Contribution.pdf">IRA 
              Contribution Form</a>
            </p>
            <p>
              <strong>AVAILABILITY OF FUNDS</strong>
              <br />
              You may trade most marginable securities immediately after funds are deposited into your 
              account. However, these funds cannot be withdrawn or used to purchase non-marginable 
              securities, initial public offering (IPO) stocks or options during the first six business days. 
              This holding period begins on settlement date.
            </p>
          </FormGroup>
    </>
  )
}