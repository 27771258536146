import React, { useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import { toShort } from '@tradingblock/api';
import { Expiration } from '@tradingblock/types';
import { LoadingImage } from '@tradingblock/components';
import { expirationValue, isBetweenMidnightInclusiveAndOpeningHoursExclusive } from '../../../utilities/date';
import { useOptionChainData } from '../state/OptionChainState';
import { useOptionChainActions } from '../state/useOptionChainActions';
import { expirationDisplayInfo } from '../../../utilities/data';
import {
  useStateSelector,
  optionChainSelectors,
  multipleExpirationForDateExist,
  isMarketOpenSelector,
  MarketOpenTimeSelector,
  MarketCloseTimeSelector,
} from '../../../data/global/dataSelectors';
import { useBlockId, useBlockSymbol } from '../../../components/blocks/BlockState';
import { OptionChain } from '../../../constants';

interface OptionRowProps {
  expirationTypeLabel: string;
  expirationTypeClass: string;
  expanded: boolean;
  isFetching: boolean;
  onClick: () => void;
  dateString: string;
  unitAmount: number;
  units: string;
}

const ExpirationRow: React.FC<OptionRowProps> = ({
  unitAmount,
  onClick,
  isFetching,
  expanded,
  dateString,
  units,
  expirationTypeClass,
  expirationTypeLabel,
}) => {
  const className = useMemo(() => `${expirationTypeClass} row-link`, [expirationTypeClass]);
  return (
    <tr className={className} onClick={onClick}>
      <th className="chain-head" colSpan={24}>
        {expanded && <br />}
        {expanded && (
          <span style={{ color: 'white' }} className="col-auto">
            Calls <i className="fal fa-arrow-right"></i>
          </span>
        )}
        {isFetching && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
        <i className={`fal fa-angle-${expanded ? 'down' : 'right'}`} /> {dateString} {expirationTypeLabel} (
        {unitAmount - 1} {units}) {isFetching && <LoadingImage />}
        {expanded && (
          <span style={{ color: 'white' }} className="col-auto align-self-end">
            <i className="fal fa-arrow-left"></i> Puts
          </span>
        )}
      </th>
    </tr>
  );
};

const ExpirationRowMemo = React.memo(ExpirationRow);

const RootExpirationRow: React.FC<{ expiration: Expiration }> = ({ expiration }) => {
  const blockId = useBlockId();
  const symbol = useBlockSymbol();
  const optionchain = useStateSelector(s =>
    optionChainSelectors.optionChainSymbol(s, symbol ? symbol.symbol : '', blockId)
  );
  const isFetching = _.some(optionchain, c => c.expiration.date === expiration.date && c.isFetching);
  const now = dayjs.utc();
  const marketOpen = useStateSelector(s => isMarketOpenSelector(s));
  const marketOpenTime = useStateSelector(s => MarketOpenTimeSelector(s));
  const marketCloseTime = useStateSelector(s => MarketCloseTimeSelector(s));
  const dateForDiff =
    (!marketOpen && isBetweenMidnightInclusiveAndOpeningHoursExclusive() && now.isBefore(marketOpenTime)) ||
    (marketOpen && now.isBefore(marketCloseTime))
      ? dayjs.utc().add(-1, 'day')
      : dayjs.utc();
  const exDays = dayjs(expiration.date)
    .utc()
    .diff(dayjs(dateForDiff.format('YYYY-MM-DD')), 'day');
  const expandedExpirations = useOptionChainData(s => s.expandedExpirations);
  const expandedStrs = _.map(expandedExpirations, ee => expirationValue(ee));
  const expanded = _.includes(expandedStrs, expirationValue(expiration));
  const { setExpandedExpirations } = useOptionChainActions();
  const toggleExpandedExpiration = useCallback(() => {
    if (expanded) {
      setExpandedExpirations(_.filter(expandedExpirations, d => expirationValue(d) !== expirationValue(expiration)));
    } else {
      //add to expirations with max amount expanded, expirations removed FIFO
      const updatedExpanded = _.takeRight(
        [...(expandedExpirations || []), expiration],
        OptionChain.MaxExpandedExpirations
      );
      setExpandedExpirations(updatedExpanded);
    }
  }, [expanded, setExpandedExpirations, expandedExpirations, expiration]);
  const expirationDisplay = useMemo(() => expirationDisplayInfo(expiration, symbol), [expiration, symbol]);

  return (
    <ExpirationRowMemo
      expirationTypeLabel={expirationDisplay.label}
      expirationTypeClass={expirationDisplay.class}
      expanded={expanded}
      isFetching={isFetching}
      onClick={toggleExpandedExpiration}
      dateString={toShort(expiration.date)}
      unitAmount={exDays}
      units={exDays === 2 ? 'day' : 'days'}
    />
  );
};

export const RenderExpirationRow: React.FC<{ row: any }> = ({ row }) => {
  // use just the date part for diff by day
  return <RootExpirationRow expiration={row.original.expiration} />;
};
