import createCachedSelector from 're-reselect';
import { DataState } from '../state';
import { createSelector } from 'reselect';
import { ProfitLossInfo } from '@tradingblock/types';
import _ from 'lodash';

export const PerformanceSelectors = {
  todayTradingPnl: createSelector(
    (s: DataState) => s.performance.bySymbol,
    perfState =>
      _(perfState)
        .values()
        .map(v => v.trading)
        .sum() || 0
  ),
  aggregatePositionPerformances: createCachedSelector(
    (s: DataState, positionIds: string[]) => _.filter(s.performance.positions, (v, k) => positionIds.includes(k)),
    perfs => {
      return perfs.reduce(
        (acc, s) => {
          return {
            ...acc,
            pnl: acc.pnl + s.pnl,
          };
        },
        { pnl: 0 }
      );
    }
  )((s: DataState, positionIds: string[]) => `agg-pos-perf-${positionIds.sort().join(',')}`),
  bySymbol: createCachedSelector(
    (state: DataState, symbol: string) => state.performance.bySymbol[symbol],
    (state: DataState, symbol: string) => symbol,
    (pnl, symbol) => {
      const val = pnl || { symbol };
      return val;
    }
  )((state: DataState, symbol: string) => `pnl-${symbol}`),
  totals: {
    all: createSelector(
      (state: DataState) => state.performance.totals,
      totals => {
        return totals.all;
      }
    ),
    tradedToday: createSelector(
      (state: DataState) => state.performance.totals,
      totals => totals.tradedToday
    ),
  },
};
