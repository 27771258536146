import * as tslib_1 from "tslib";
import React, { useEffect, useMemo } from 'react';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import { useExpanded, useSortBy, useTable, usePagination, useColumnOrder } from 'react-table';
import _ from 'lodash';
import { BaseTable } from './BaseTable';
import { Paging } from './Paging';
/**
 * This implements react-table with <table>, <td>, <thead>, <tbody>
 * and <td>
 *
 * The headers on this table WILL move when you scroll. Possibly
 * moving out of view.
 */

export var FreeHeaderTable = function FreeHeaderTable(_a) {
  var columns = _a.columns,
      data = _a.data,
      hasPagination = _a.hasPagination,
      initialState = _a.initialState,
      manualSortBy = _a.manualSortBy,
      manualPagination = _a.manualPagination,
      pageCount = _a.pageCount,
      onSort = _a.onSort,
      onPage = _a.onPage,
      onPageSize = _a.onPageSize,
      onColumnOrderChanged = _a.onColumnOrderChanged,
      searchTerm = _a.searchTerm,
      searchOptionsPage = _a.searchOptionsPage,
      searchOptionsSearchTerm = _a.searchOptionsSearchTerm,
      tableKey = _a.tableKey,
      expandRows = _a.expandRows,
      props = tslib_1.__rest(_a, ["columns", "data", "hasPagination", "initialState", "manualSortBy", "manualPagination", "pageCount", "onSort", "onPage", "onPageSize", "onColumnOrderChanged", "searchTerm", "searchOptionsPage", "searchOptionsSearchTerm", "tableKey", "expandRows"]);

  var plugins = hasPagination ? [useSortBy, useExpanded, usePagination] : [useSortBy, useExpanded];
  var tableInstance = useTable.apply(void 0, [{
    columns: columns,
    data: data,
    initialState: initialState,
    autoResetExpanded: false,
    autoResetSortBy: false,
    autoResetPage: false,
    manualSortBy: manualSortBy,
    manualPagination: manualPagination,
    pageCount: pageCount
  }, useColumnOrder].concat(plugins));
  var currentPage = useMemo(function () {
    var currPage = hasPagination ? tableInstance.page : tableInstance.rows;
    return currPage;
  }, [hasPagination, tableInstance.page, tableInstance.rows]);
  useUpdateEffect(function () {
    if (onSort) {
      onSort(tableInstance.state.sortBy);
    }
  }, [tableInstance.state.sortBy]);
  useEffect(function () {
    if (onColumnOrderChanged && tableInstance.state.columnOrder !== undefined && tableInstance.state.columnOrder.length > 0) {
      onColumnOrderChanged(tableInstance.state.columnOrder);
    }
  }, [tableInstance.state.columnOrder]);
  useUpdateEffect(function () {
    if (onPage) {
      onPage(_.isNaN(tableInstance.state.pageIndex) ? 0 : tableInstance.state.pageIndex);
    }
  }, [tableInstance.state.pageIndex]);
  useUpdateEffect(function () {
    if (onPageSize) {
      onPageSize(tableInstance.state.pageSize);
    }
  }, [tableInstance.state.pageSize]);
  return React.createElement(React.Fragment, null, React.createElement(BaseTable, Object.assign({}, props, {
    currentPage: currentPage,
    tableInstance: tableInstance
  })), hasPagination && React.createElement(Paging, {
    tableInstance: tableInstance,
    search: searchTerm,
    searchOptionPage: searchOptionsPage,
    tableKey: tableKey,
    searchOptionsSearchTerm: searchOptionsSearchTerm
  }));
};