import React, { useCallback, useEffect, useState } from 'react';
import { UpdateDistributionRatioPayload } from '@tradingblock/api/src/commands/subaccounts';
import { EmptyBlockContent, Modal, ModalProps, TextboxField } from '@tradingblock/components';
import { Formik, Form, Field, FormikProps } from 'formik';
import { Modal as BootstrapModal, Button, FormGroup } from 'react-bootstrap';
import { useApi } from '../../context/Api';
import { useDispatch } from 'react-redux';
import { useStateSelector } from '../../data/global/dataSelectors';
import * as Yup from 'yup';
import { AccountActions } from '../../data/global/actions/AccountActions';
import useSWRMutation from 'swr/mutation';
import { isEmpty } from 'lodash';
import { ResponseCodes } from '@tradingblock/types';
import { SubAccount } from '@tradingblock/types/src/Account';

export interface UpdateDistributionRatioModelProps {
  setShow: ModalProps['setShow'];
  show: ModalProps['show'];
  initialAccountState: {
    AccountId: number;
  };
}

export default function UpdateDistributionRatioModel({
  setShow,
  show,
  initialAccountState,
}: UpdateDistributionRatioModelProps) {
  const api = useApi();
  const [errorState, setErrorState] = useState<string>('');
  const [subAccountRows, setSubAccountRows] = useState<UpdateDistributionRatioPayload['subaccountRatioMap']>({});
  const dispatch = useDispatch();
  const subAccounts = useStateSelector(s => s.account.subaccounts);

  const updateDistributionRatioFetcher = useCallback(
    (key: any, { arg }) => {
      return api.subaccounts.updateDistributionRatio(arg);
    },
    [api]
  );

  function getUpdateDistributionRatioSchema(rows: UpdateDistributionRatioPayload['subaccountRatioMap']) {
    return Yup.object().shape({
      subaccountRatioMap: Yup.lazy(value => {
        if (!isEmpty(value)) {
          const validationObject = {
            ratio: Yup.number()
              .nullable()
              .typeError('The value must be an integer between 0-100 or empty')
              .min(0, 'Please enter a number greater than 0')
              .max(100, 'Please enter a number less than or equal to 100')
              .integer('Please enter an integer'),
          };
          const newEntries = Object.keys(value).reduce(
            (acc, val) => ({
              ...acc,
              [val]: Yup.object(validationObject),
            }),
            {}
          );
          return Yup.object().shape(newEntries);
        }
        return Yup.mixed().notRequired();
      }),
    });
  }
  const { AccountId } = initialAccountState;

  const {
    data: updateDistributionRatioResult,
    trigger: triggerUpdateDistributionRatio,
    isMutating,
    error: updateDistributionRatioRequestError,
  } = useSWRMutation('create-sub-account', updateDistributionRatioFetcher);

  const onSubmit = async (values: UpdateDistributionRatioPayload) => {
    let sum = 0;
    let ratioArr: UpdateDistributionRatioPayload = { accountId: values.accountId, subaccountRatioMap: {} };
    Object.entries(values.subaccountRatioMap).forEach(([key, value]) => {
      let ratioParsed = parseInt(value.ratio);
      if (!ratioParsed) ratioParsed = 0;
      sum += ratioParsed;
      ratioArr.subaccountRatioMap = { ...ratioArr.subaccountRatioMap, [key]: ratioParsed };
    });
    if (sum !== 100) setErrorState('Please make sure the sum of the distributions is 100');
    else {
      setErrorState('');
      let result = await triggerUpdateDistributionRatio(ratioArr);
      if (result && result.responseCode === 0) dispatch(AccountActions.requestSubAccounts({ accountId: AccountId }));
    }
  };

  useEffect(() => {
    if (updateDistributionRatioResult !== undefined) {
      if (updateDistributionRatioResult.responseCode !== 0) {
        if ((updateDistributionRatioResult.payload as unknown) as string[])
          setErrorState(((updateDistributionRatioResult.payload as unknown) as string[]).join('\n'));
        else if (ResponseCodes[updateDistributionRatioResult.responseCode])
          setErrorState(ResponseCodes[updateDistributionRatioResult.responseCode].description);
        else setErrorState('Error Code ' + updateDistributionRatioResult.responseCode);
      } else {
        setShow(false);
      }
    }
  }, [updateDistributionRatioResult, setShow, setErrorState]);

  useEffect(() => {
    if (updateDistributionRatioRequestError !== undefined) {
      setErrorState(updateDistributionRatioRequestError.message);
    }
  }, [updateDistributionRatioRequestError]);

  useEffect(() => {
    if (subAccounts && subAccounts.subaccounts) {
      let newRows: UpdateDistributionRatioPayload['subaccountRatioMap'] = {};
      let sum = 0;
      let masterId = 0;
      subAccounts.subaccounts.forEach((sa: SubAccount) => {
        sum += sa.DistributionRatio ? sa.DistributionRatio : 0;
        if (sa.IsMaster) masterId = sa.Id;
        newRows = {
          ...newRows,
          [sa.Id]: {
            nickname: sa.Nickname ? `${sa.Id} (${sa.Nickname})` : sa.Id.toString() + '()',
            ratio: sa.DistributionRatio ? sa.DistributionRatio : undefined,
          },
        };
      });
      if (sum === 0 && masterId !== 0) (newRows[masterId] as { nickname: string; ratio: number }).ratio = 100;
      setSubAccountRows({ ...subAccountRows, ...newRows });
    }
  }, [subAccounts]);

  return (
    <Modal setShow={setShow} show={show}>
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>Update Distributions for Account {AccountId}</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <div style={{ color: 'red' }}>{errorState}</div>
        <Formik
          initialValues={{
            accountId: AccountId,
            subaccountRatioMap: subAccountRows,
          }}
          as
          UpdateDistributionRatioPayload
          enableReinitialize
          validationSchema={getUpdateDistributionRatioSchema(subAccountRows)}
          onSubmit={onSubmit}
        >
          {({ values }: FormikProps<UpdateDistributionRatioPayload>) => (
            <Form translate={'yes'} id="update-distribution-ratio-form">
              {Object.keys(values.subaccountRatioMap).length > 0 ? (
                <>
                  {Object.entries(values.subaccountRatioMap).map(([saId, value]) => (
                    <>
                      <h5>Subaccount {value.nickname}</h5>
                      <FormGroup>
                        <Field
                          name={saId}
                          autoComplete="off"
                          errorImmediately={true}
                          component={TextboxField}
                          id={`subaccountRatioMap.${saId}.ratio`}
                          style={{
                            textAlign: 'right',
                            type: 'number',
                            name: saId,
                          }}
                          label="Distribution %"
                        />
                      </FormGroup>
                    </>
                  ))}
                  <div className="mute" style={{ textAlign: 'center', fontStyle: 'italic' }}>
                    Please make sure the sum of the distributions is 100
                  </div>
                </>
              ) : (
                <EmptyBlockContent>
                  No subaccounts to display. Please create a subaccount to manage distributions.
                </EmptyBlockContent>
              )}
            </Form>
          )}
        </Formik>
      </BootstrapModal.Body>
      <BootstrapModal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button variant="primary" type="submit" form={'update-distribution-ratio-form'} disabled={isMutating}>
          Save
        </Button>
      </BootstrapModal.Footer>
    </Modal>
  );
}
