import _ from 'lodash';
import {
  isCustomStrategy,
  StrikeRules,
  OptionType,
  ExpirationRules,
  OrderAction,
  Expiration,
} from '@tradingblock/types';
import { OrderSelectors, LegExpirationInfo } from './../state/OrderSelector';
import { useOrderData, useOrderStrategy } from '../state/OrderState';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { DataState } from '../../../data/global/state';
import { StrikeSelectors, ExpirationSelectors } from '../../../data/global/selectors/expirationStrikeSelector';
import { useBlockId } from '../../../components/blocks/BlockState';
import { toArray } from '../../../utilities/data';
import { useGroupState } from '../../useGroupState';

const strikesWithValidity = (strikes: number[], isValid?: (strike: number) => boolean) => {
  return _.map(strikes, (s: number) => {
    return {
      value: s,
      isValid: isValid ? isValid(s) : true,
    };
  });
};

export const useFilteredStrikes = (legId: string, expiration?: Expiration) => {
  const [symbol] = useGroupState('symbol');
  const strategy = useOrderStrategy();
  const blockId = useBlockId();
  const currentLegStrike = useOrderData(s => OrderSelectors.legStrikeById(s, blockId, legId));
  const legsWithStrike = useOrderData(s => OrderSelectors.orderedLegsWithStrike(s, blockId));
  const firstLegWithStrike = _.first(legsWithStrike);
  const firstStrike = firstLegWithStrike ? firstLegWithStrike.Strike : undefined;

  const strategyLegCount = strategy && !isCustomStrategy(strategy) ? strategy.info.LegsAmount : 0;
  const strikes = useStateSelector((state: DataState) =>
    expiration && symbol ? StrikeSelectors.strikes(state, { symbol: symbol.symbol, expiration: expiration }) || [] : []
  );
  const strikeRules = strategy && !isCustomStrategy(strategy) ? toArray(strategy.profile.Rules.Strike) : [];
  const shoudlValidate = currentLegStrike === undefined || !strikeRules.includes(StrikeRules.SameStrikes);

  if (!strategy || isCustomStrategy(strategy)) {
    return strikesWithValidity(strikes);
  } else if (strikeRules.includes(StrikeRules.Any)) {
    return strikesWithValidity(strikes);
  } else if (shoudlValidate && strategyLegCount === 2 && firstLegWithStrike && _.isNumber(firstStrike)) {
    const otherLeg = _.find(legsWithStrike, l => l.LegId !== legId);
    const otherStrike = otherLeg ? otherLeg.Strike : undefined;
    if (otherLeg === undefined || otherStrike === undefined) {
      return strikesWithValidity(strikes);
    }
    const otherLegHasLowerIndex = otherLeg.Index === 0;
    // should always allow all strikes bc we default values when one strike changes
    if (strikeRules.includes(StrikeRules.SameStrikes)) {
      return strikesWithValidity(strikes);
    } else if (strikeRules.includes(StrikeRules.DifferentStrikes)) {
      return strikesWithValidity(strikes, s => s !== otherStrike);
    } else if (strikeRules.includes(StrikeRules.CallHigherThanPut)) {
      return strikesWithValidity(strikes, s =>
        otherLeg.OptionType === OptionType.Call ? s < otherStrike : s > otherStrike
      );
    } else if (strikeRules.includes(StrikeRules.DecreasingOrder)) {
      return strikesWithValidity(strikes, s => (otherLegHasLowerIndex ? s < otherStrike : s > otherStrike));
    } else if (strikeRules.includes(StrikeRules.IncreasingOrder)) {
      return strikesWithValidity(strikes, s => (otherLegHasLowerIndex ? s > otherStrike : s < otherStrike));
    }
  }

  return strikesWithValidity(strikes);
};

export const useFilteredExpirations = (legId: string) => {
  const [symbol] = useGroupState('symbol');
  const strategy = useOrderStrategy();
  const blockId = useBlockId();
  const legsWithExpiration = useOrderData(s => OrderSelectors.orderedLegsWithExpiration(s, blockId));
  const firstLegWithExpiration = useOrderData(s => OrderSelectors.firstLegWithExpiration(s, blockId));
  const currentLegAction = useOrderData(s => OrderSelectors.legActionById(s, blockId, legId));
  const firstExp = firstLegWithExpiration ? firstLegWithExpiration.Expiration : undefined;

  const expirations = useStateSelector(
    state => (symbol ? ExpirationSelectors.expirations(state, symbol.symbol) : []) || []
  );

  const otherLegs = _.reduce(
    legsWithExpiration,
    (acc: LegExpirationInfo[], l) => {
      if (l.LegId === legId) {
        return acc;
      }
      return [...acc, l];
    },
    []
  );

  if (strategy && !isCustomStrategy(strategy) && firstLegWithExpiration && _.isDate(firstExp)) {
    const expirationRule = strategy.profile.Rules.Expiration;
    if (expirationRule === ExpirationRules.DifferentExpiries) {
      return _.filter(expirations, e => !otherLegs.map(e => e.Expiration.date.valueOf()).includes(e.date.valueOf()));
    } else if (expirationRule === ExpirationRules.BuyWithLaterSellWithSooner) {
      //const firstLegIsBuy = firstLegWithExpiration.Action === OrderAction.Buy;
      const currLegIsBuy = currentLegAction && currentLegAction === OrderAction.Buy ? true : false;
      const { sellExpirations, buyExpirations } = _.reduce(
        otherLegs,
        (acc, l) => {
          const keyVal = l.Action === OrderAction.Buy ? 'buyExpirations' : 'sellExpirations';
          return {
            ...acc,
            [keyVal]: [...acc[keyVal], l.Expiration],
          };
        },
        { sellExpirations: [] as Date[], buyExpirations: [] as Date[] }
      );
      const maxSell = _(sellExpirations)
        .map(e => e.valueOf())
        .max();
      const minBuy = _(buyExpirations)
        .map(e => e.valueOf())
        .min();
      return _(expirations)
        .filter(e => {
          if (currLegIsBuy) {
            return maxSell ? e.date.valueOf() > maxSell : true;
          } else {
            return minBuy ? e.date.valueOf() < minBuy : true;
          }
        })

        .value();
    }
  }
  return _.orderBy(expirations, v => v.date.valueOf());
};
