/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { OptionType } from '@tradingblock/components';
import { AssetSymbol, BlockType } from '@tradingblock/types';
import { useBlockMetadata, useBlockData, useBlockSymbol } from '../../components/blocks/BlockState';
import { useStateSelector, blockGroupId } from '../../data/global/dataSelectors';
import { QuoteState } from './state/QuoteState';
import { SymbolPickerHeader } from '../shared/SymbolPickerHeader';
import { useDispatcher } from '../../data/global/hooks';

interface QuoteHeaderProps {
  blockType: BlockType;
  setSymbol: (symbol: AssetSymbol) => void;
}

export const QuoteHeader: React.FunctionComponent<QuoteHeaderProps> = ({ setSymbol, blockType }) => {
  const { isLoaded } = useBlockData<QuoteState>();
  const symbol = useBlockSymbol();
  const { dispatcher } = useDispatcher();
  const setGroupSymbol = dispatcher.blockGroup.setSymbol;
  const { blockId } = useBlockMetadata();
  const groupId = useStateSelector(s => blockGroupId(s, blockId));
  const onSelect = useCallback(
    (value: OptionType) => {
      if (value && value.data) {
        setSymbol(value.data);
        if (groupId) {
          setGroupSymbol(groupId, value.data);
        }
      }
    },
    [setSymbol, groupId]
  );
  const updateSymbol = useCallback(
    (symbolName: string, symbolValue: AssetSymbol | undefined) => {
      if (groupId) {
        setGroupSymbol(groupId, symbolValue);
      }
    },
    [setGroupSymbol, groupId, ]
  );
  const onClear = useCallback(() => {
    updateSymbol('', undefined);
  }, [updateSymbol]);
  return <SymbolPickerHeader hidePrice={true} blockType={BlockType.Quote} symbol={symbol} isLoaded={isLoaded} onSelect={onSelect} disableFavorite={true} onClear={onClear}/>;
};
