import React, { FC, useMemo, useCallback } from 'react';
import _ from 'lodash';
import { BlockType, OrderAction, AssetType, OptionType } from '@tradingblock/types';
import {
  parseOccSymbol,
  toAssetSymbol,
  AssetTypeToSymbolType,
  OrderBuilderType,
  OCCSymbolToOptionData,
} from '@tradingblock/api';
import { DtoToOrderleg } from '../Order/OrderUtilities';
import {
  useOrderBlockWithOrder,
  getStrategyForLegs,
  useCloseOrderAction,
} from '../../components/blocks/hooks/useOrderBlock';
import { useBlockGroupInfo } from '../useGroupState';
import { PositionActionsProps, PositionActions } from './PositionActions';
export const PositionLegActions: FC<PositionActionsProps> = ({ tradeData, position, maxVisibleActions }) => {
  const { groupId } = useBlockGroupInfo();
  const [symbol, underlyingSymbol, assetType] = [position.Symbol, position.UnderlyingSymbol, position.AssetType];
  const assetSymbol = useMemo(
    () =>
      toAssetSymbol(position.Symbol, assetType, AssetTypeToSymbolType(assetType), {
        name: underlyingSymbol,
        underlyingSymbol,
        rootSymbol: underlyingSymbol,
      }),
    [position.Symbol, assetType, underlyingSymbol]
  );
  const baseAssetSymbol = useMemo(
    () =>
      toAssetSymbol(underlyingSymbol, assetType, AssetTypeToSymbolType(assetType), {
        name: underlyingSymbol,
        underlyingSymbol,
        rootSymbol: underlyingSymbol,
      }),
    [position.Symbol, assetType, underlyingSymbol]
  );
  const orderLegs = _.map(tradeData.legs, l => DtoToOrderleg(l, assetSymbol));
  const canRoll =
    _.keys(tradeData.legs).length === 1 && _.some(tradeData.legs, (v, k) => v.AssetType === AssetType.Option);
  const openQuantity = Math.abs(position.OpenQuantity);
  const optionData = useMemo(() => OCCSymbolToOptionData(symbol), [symbol]);
  const isShort = position.OpenQuantity < 0;
  const subaccountId = position.SubaccountId;

  const buildRollOrder = useCallback(
    (builder: OrderBuilderType) => {
      const optionType = optionData.option;
      const strike = optionData.strike;
      const expiration = optionData.expiration;
      // for custom strategy, quantity is stored in spreadRatio
      // const legs = DefaultLegs({}, assetSymbol, { assetType, optionType, orderAction: action, strike, expiration:  });
      if (optionType) {
        return builder
          .symbol(baseAssetSymbol)
          .strategy('Custom')
          .quantity(1)
          .action(OrderAction.Buy)
          .leg(l =>
            l
              .assetType(AssetType.Option)
              .action(isShort ? OrderAction.Buy : OrderAction.Sell)
              .quantity(openQuantity)
              .expiration(expiration)
              .optionType(optionType)
              .strike(strike)
          )
          .leg(l =>
            l
              .assetType(AssetType.Option)
              .action(isShort ? OrderAction.Sell : OrderAction.Buy)
              .quantity(openQuantity)
              .optionType(optionType)
          );
      }
      return builder;
    },
    [position, optionData]
  );
  const handleRollClick = useOrderBlockWithOrder(buildRollOrder, { blockType: BlockType.Order, groupId });

  const tradeOrderBuilder = useCallback(
    (builder: OrderBuilderType) => {
      const orderAction = isShort ? OrderAction.Sell : OrderAction.Buy;
      const quantity = Math.abs(openQuantity);
      const baseBuilder = builder
        .symbol(baseAssetSymbol)
        .strategy(getStrategyForLegs(orderLegs))
        .action(orderAction)
        .quantity(quantity);
      return _.reduce(
        orderLegs,
        (b, l) => {
          return b.leg(ll => {
            const symbolInfo = parseOccSymbol(l.Symbol);
            const buildRes = ll.assetType(symbolInfo.assetType).action(orderAction);
            if (symbolInfo.assetType !== AssetType.Equity) {
              return buildRes
                .optionType(symbolInfo.option || OptionType.Call)
                .expiration(symbolInfo.expiration)
                .quantity(1)
                .strike(symbolInfo.strike);
            }
            return buildRes;
          });
        },
        baseBuilder
      );
    },
    [openQuantity, orderLegs, isShort, baseAssetSymbol]
  );

  const handleTradeClick = useOrderBlockWithOrder(tradeOrderBuilder, { blockType: BlockType.Order, groupId });
  const handleCloseOrder = useCloseOrderAction(
    { Quantity: openQuantity, Legs: orderLegs },
    baseAssetSymbol,
    {
      groupId,
    },
    subaccountId
  );
  const actions = useMemo(() => {
    let res = [{ title: 'Close position', onClick: () => handleCloseOrder(), icon: '' }];
    if (canRoll) {
      res = [...res, { title: 'Roll position', onClick: () => handleRollClick(), icon: 'fa-redo' }];
    }
    res = [...res, { title: 'Trade', onClick: () => handleTradeClick(), icon: 'fa-clone' }];
    return res;
  }, [handleCloseOrder, canRoll, handleRollClick, handleTradeClick]);
  return (
    <>
      <PositionActions
        actions={actions}
        identity={`${position.Symbol}-${position.DateOpened.valueOf()}`}
        maxVisibleActions={maxVisibleActions}
      />
    </>
  );
};
