import { InitialState } from '../initialState';
import { RootAction } from '../actions';
import { getType } from 'typesafe-actions';
import { InformationState } from '../state';
import { InfoDataActions } from '../actions/InfoActions';
import { releaseNotesRequestKey, removeStyleTags } from '../utilities/infoUtilities';

export const informationReducer = (state = InitialState.information, action: RootAction): InformationState => {
  switch (action.type) {
    case getType(InfoDataActions.requestReleaseNotes): {
      // const { page, pageSize } = action.payload;
      const requestKey = releaseNotesRequestKey(action.payload);
      const existingState = state.releaseNotes[requestKey] || {};
      return {
        ...state,
        releaseNotes: {
          ...state.releaseNotes,
          [requestKey]: {
            ...existingState,
            isFetching: true,
          },
        },
      };
    }
    case getType(InfoDataActions.receiveReleaseNotes): {
      const { request, response } = action.payload;
      const queryKey = releaseNotesRequestKey(request);
      const existingState = state.releaseNotes[queryKey] || {};
      const { payload } = response || { payload: undefined };
      const data = payload && payload.data ? payload.data : [];
      const total = payload && payload.total ? payload.total : 0;
      return {
        ...state,
        releaseNotes: {
          ...state.releaseNotes,
          [queryKey]: {
            ...existingState,
            isFetching: false,
            data,
            total,
          },
        },
        releaseNoteUpdate: false,
      };
    }
    case getType(InfoDataActions.errorReleaseNotes): {
      return {
        ...state,
        releaseNotes: {},
      };
    }
    case getType(InfoDataActions.requestUpdateRelease): {
      return {
        ...state,
      };
    }
    case getType(InfoDataActions.receiveUpdateRelease): {
      return {
        ...state,
        releaseNoteUpdate: true,
      };
    }
    case getType(InfoDataActions.requestMarketNews): {
      const { blockId, symbols, channels } = action.payload;
      const existingState = state.marketNews[blockId] || {};
      return {
        ...state,
        marketNews: {
          ...state.marketNews,
          [blockId]: {
            ...existingState,
            symbols,
            channels,
            isFetching: true,
            priorChannelRequest: existingState.channels,
            priorSymbolRequest: existingState.symbols,
          },
        },
      };
    }
    case getType(InfoDataActions.receiveMarketNews): {
      const { symbols, marketNews, channels, blockId } = action.payload;

      const existingState = state.marketNews[blockId] || { data: [] };
      if (!existingState.data) {
        existingState.data = [];
      }

      // map over marketNews and run the removeStyleTags function on each item
      // this will remove any style tags from the html content and avoid any styling issues
      marketNews.map((newsItem: any) => {
        newsItem.body = removeStyleTags(newsItem.body);
      });

      return {
        ...state,
        marketNews: {
          ...state.marketNews,
          [blockId]: {
            ...existingState,
            symbols,
            channels,
            isFetching: false,
            data: marketNews ? [...marketNews] : [],
          },
        },
      };
    }
    case getType(InfoDataActions.errorMarketNews): {
      const { error, symbols, blockId } = action.payload;
      const existingState = state.marketNews[blockId] || {};

      return {
        ...state,
        marketNews: {
          ...state.marketNews,
          [blockId]: {
            ...existingState,
            isFetching: false,
          },
        },
      };
    }
    case getType(InfoDataActions.beginPollingForMarketNews): {
      return {
        ...state,
        isPollingForMarketNews: true,
      };
    }
    case getType(InfoDataActions.stopPollingForMarketNews): {
      return {
        ...state,
        isPollingForMarketNews: false,
      };
    }
    case getType(InfoDataActions.setReleaseNotesPage):
    case getType(InfoDataActions.setReleaseNotesPageSize):
      return {
        ...state,
        ui: {
          ...state.ui,
          ...action.payload,
        },
      };
    case getType(InfoDataActions.receiveCreateRelease):
      return {
        ...state,
        releaseNoteUpdate: true,
      };
    case getType(InfoDataActions.requestAlertMessages):
      return {
        ...state,
        alerts: {
          ...state.alerts,
          isFetching: true,
        },
      };
    case getType(InfoDataActions.receiveAlertMessages):
      return {
        ...state,
        alerts: {
          ...state.alerts,
          isFetching: false,
          activeAlerts: action.payload.response,
        },
      };

    default: {
      return state;
    }
  }
};
