import { getType } from 'typesafe-actions';
import _ from 'lodash';
import dayjs from 'dayjs';
import { OrderLeg, Order, OrderStatuses, AccountType, AccountOrderErrorEvent, AccountInfo } from '@tradingblock/types';
import { AccountState } from '../state';
import { InitialState } from '../initialState';
import { RootAction, Actions, OrderActions } from '../actions';
import { AccountActions } from '../actions/AccountActions';
import { SubAccount } from '@tradingblock/types/src/Account';

const getSortedOrders = (orders: Order<OrderLeg>[]) => _.orderBy(orders, o => dayjs(o.Date).unix(), ['desc']);
const getSortedErrorEvents = (orders: AccountOrderErrorEvent[]) =>
  _.orderBy(orders, o => dayjs(o.createdOn).unix(), ['desc']);

export const account = (state = InitialState.account, action: RootAction): AccountState => {
  switch (action.type) {
    case getType(Actions.receiveCurrentAccountDetails):
      return {
        ...state,
        accountDetails: action.payload,
      };
    case getType(Actions.receiveToken): {
      return {
        ...state,
        accountId:
          state.accountId !== -1
            ? state.accountId
            : action.payload.accountId
            ? action.payload.accountId
            : state.accountId,
      };
    }
    case getType(AccountActions.receiveAccountEntitlement): {
      const marketDataEntitlementTypeId = action.payload.payload.marketDataEntitlementTypeId;
      return {
        ...state,
        dataEntitled: marketDataEntitlementTypeId,
      };
    }
    case getType(Actions.requestAccounts):
      return { ...state, isFetching: true };
    case getType(Actions.receiveAccounts):
      const accounts = action.payload.payload || undefined;
      const selectedAccount = _.find(accounts, (a: AccountInfo) => a.AccountId === state.accountId);
      if (!selectedAccount) {
        return {
          ...state,
          isFetching: false,
        };
      }
      const checkIRA = () => {
        if (!accountType) {
          return false;
        }
        if (
          accountType.toString() === 'Coverdell_IRA' ||
          accountType.toString() === 'IRA_RollOver' ||
          accountType.toString() === 'Roth_IRA' ||
          accountType.toString() === 'SEP_IRA' ||
          accountType.toString() === 'Traditional_IRA'
        ) {
          return true;
        }
      };

      const checkUGMA = () => {
        if (!accountType) {
          return false;
        }
        if (accountType.toString() === 'UGMA') {
          return true;
        }
      };

      const checkPartnership = () => {
        if (!accountType) {
          return false;
        }
        if (accountType.toString() === 'Partnership') {
          return true;
        }
      };

      const accountNumber = selectedAccount.AccountNumber;
      const accountType = selectedAccount.AccountType;
      const isIRA = checkIRA() || false;
      const isUGMA = checkUGMA() || false;
      const isPartnership = checkPartnership() || false;
      const roles = selectedAccount.Roles;
      const nickname = selectedAccount.AccountTitle;
      return {
        ...state,
        isFetching: false,
        account: selectedAccount,
        accountNumber,
        isIRA,
        isUGMA,
        isPartnership,
        roles,
        nickname,
      };
    case getType(Actions.requestAccountHistory):
      return { ...state, isFetchingHistory: true };
    case getType(Actions.receiveAccountHistory): {
      const { history } = action.payload;
      return {
        ...state,
        isFetchingHistory: false,
        history: history,
      };
    }
    case getType(Actions.toggleAccountSwitcher): {
      return {
        ...state,
        showAccountSwitcher: _.isBoolean(action.payload.show) ? action.payload.show : !state.showAccountSwitcher,
      };
    }
    case getType(Actions.setSubAccount):
      return { ...state, subAccountId: action.payload.subaccountId };
    case getType(Actions.requestSubAccounts):
      return { ...state, subaccounts: { ...state.subaccounts, isFetching: true } };
    case getType(Actions.receiveSubAccounts): {
      const { payload: subaccounts } = action.payload;
      return {
        ...state,
        subaccounts: {
          isFetching: false,
          subaccounts:
            subaccounts && subaccounts.length > 0
              ? subaccounts.sort((a: SubAccount, b: SubAccount) => a.Id - b.Id)
              : undefined,
        },
      };
    }
    case getType(Actions.requestAccountPnL):
      return { ...state, isFetchingHistory: true };
    case getType(Actions.receiveAccountPnL): {
      const { pnlHistory } = action.payload;
      return {
        ...state,
        pnl: [...(state.pnl || []), { pnlHistory: pnlHistory, isLoaded: false, isFetching: false }],
      };
    }
    case getType(Actions.requestAccountOrders):
      const { blockId } = action.payload;

      if (!blockId) {
        return { ...state, isFetchingOrders: true };
      }
      return {
        ...state,
        isFetchingOrders: true,
        ordersUIState: {
          ...state.ordersUIState,
          [blockId]: {
            ...state.ordersUIState[blockId],
            isFetching: true,
          },
        },
      };

    case getType(Actions.receiveAccountOrders): {
      const { orders, date, dateFrom, dateTo, total, pageSize, pageIndex, blockId } = action.payload;
      const allOrders = _.concat(state.orders || [], orders);
      const updatedOrders = _(allOrders).reduce((acc: Order<OrderLeg>[], o: Order<OrderLeg>) => {
        const orderExists = _.find(acc, ord => ord.OrderId === o.OrderId);
        if (orderExists) {
          const ordersWithoutExisting = _.filter(acc, ord => ord.OrderId !== o.OrderId);
          const updatedOrder = {
            ...orderExists,
            ...o,
          };
          return [...ordersWithoutExisting, updatedOrder];
        }
        return [...acc, o];
      }, []);

      /** CALCULATE ORDER STATISTICS HERE */
      return !blockId
        ? {
            ...state,
            isFetchingOrders: false,
            orders: getSortedOrders(updatedOrders),
            ordersLastRequestedAt: date,
            shouldLoadMoreOrders: false,
            orderStatistics: undefined,
          }
        : {
            ...state,
            isFetchingOrders: false,
            orders: getSortedOrders(updatedOrders),
            ordersLastRequestedAt: date,
            shouldLoadMoreOrders: false,
            orderStatistics: undefined,
            ordersUIState: {
              ...state.ordersUIState,
              [blockId]: {
                ...state.ordersUIState[blockId],
                total: total || 0,
                orders: orders || [],
                pageSize: pageSize || 50,
                pageIndex: pageIndex || 0,
                isFetching: false,
              },
            },
          };
    }
    case getType(Actions.setOrdersRequestPageSize):
    case getType(Actions.setOrdersRequestPageIndex): {
      const { blockId } = action.payload;
      return {
        ...state,
        ordersUIState: {
          ...state.ordersUIState,
          [blockId]: {
            ...state.ordersUIState[blockId],
            ...action.payload,
            isFetching: true,
          },
        },
      };
    }
    case getType(Actions.setMultipleAccounts): {
      return {
        ...state,
        hasMultipleAccounts: true,
      };
    }
    case getType(Actions.setLinkedAccountLoaded): {
      return {
        ...state,
        isSelectedLinkedAccountLoaded: action.payload || false,
      };
    }
    case getType(OrderActions.receivedUpdate): {
      const { order } = action.payload;
      const { OrderPrice, OrderStatus, OrderId } = order;
      const isFill = OrderStatus === OrderStatuses.Filled || OrderStatus === OrderStatuses.PartiallyFilled;
      const orderIsKnown = _.find(state.orders, o => o.OrderId === OrderId) === undefined ? false : true;
      const shouldLoadMoreOrders = isFill || !orderIsKnown ? true : false;

      const updatedOrders = _.map(state.orders || [], o => {
        const isMatch = o.OrderId === OrderId;
        const Price = typeof OrderPrice === 'number' ? OrderPrice : o.Price;
        if (isFill && isMatch) {
          return {
            ...o,
            AverageFillPrice: OrderPrice,
            Price,
            OrderStatus,
          };
        } else if (isMatch) {
          return { ...o, Price, OrderStatus: isMatch ? OrderStatus : o.OrderStatus };
        }
        return o;
      });
      return {
        ...state,
        shouldLoadMoreOrders,
        orders: getSortedOrders(updatedOrders),
      };
    }
    case getType(OrderActions.receivePlaceOrder): {
      const { order, response } = action.payload;
      const date = new Date();
      const newOrder = {
        ...order,
        OrderId: response.payload.OrderId,
        Date: date,
        OrderStatus: OrderStatuses.Initiated,
      };
      return {
        ...state,
        orders: getSortedOrders(_.union(state.orders || [], [newOrder])),
      };
    }
    case getType(AccountActions.requestUserProfile):
    case getType(AccountActions.loadUserData): {
      return {
        ...state,
        profile: {
          ...state.profile,
          isFetching: true,
        },
      };
    }
    case getType(AccountActions.receiveUserProfile): {
      return {
        ...state,
        profile: {
          ...state.profile,
          isFetching: false,
          current: action.payload.payload,
        },
      };
    }
    case getType(OrderActions.orderErrored): {
      return {
        ...state,
        orderErrorEvents: getSortedErrorEvents(
          _.concat(state.orderErrorEvents, { createdOn: new Date(), ...action.payload })
        ),
      };
    }
    case getType(AccountActions.retrieveSetAccountData): {
      document.cookie =
        'originalAccountId' +
        `=${
          state.selectedLinkedAccount && state.selectedLinkedAccount.originalAccount.accountId
            ? state.selectedLinkedAccount.originalAccount.accountId
            : state.accountId
        }`;
      return {
        ...state,
        subAccountId: undefined,
        selectedLinkedAccount: {
          accountId: action.payload.accountId,
          originalAccount: {
            accountId:
              state.selectedLinkedAccount && state.selectedLinkedAccount.originalAccount.accountId
                ? state.selectedLinkedAccount.originalAccount.accountId
                : state.accountId,
            accountTitle:
              state.selectedLinkedAccount && state.selectedLinkedAccount.originalAccount.accountTitle
                ? state.selectedLinkedAccount.originalAccount.accountTitle
                : (state.account && state.account.AccountTitle) || '',
            accountType:
              state.selectedLinkedAccount && state.selectedLinkedAccount.originalAccount.accountType
                ? state.selectedLinkedAccount.originalAccount.accountType
                : (state.account && state.account.AccountType) || '',
            accountNumber:
              state.selectedLinkedAccount && state.selectedLinkedAccount.originalAccount.accountNumber
                ? state.selectedLinkedAccount.originalAccount.accountNumber
                : (state.account && state.accountNumber) || '',
            accountStatus: 8,
            accountName:
              state.selectedLinkedAccount && state.selectedLinkedAccount.originalAccount.accountName
                ? state.selectedLinkedAccount.originalAccount.accountName
                : (state.account &&
                    state.account &&
                    state.account.LastName +
                      (state.account.MiddleInitial || '') +
                      state.account.FirstName +
                      (state.account.Suffix || '')) ||
                  '',
            hearAboutUs:
              state.selectedLinkedAccount && state.selectedLinkedAccount.originalAccount.hearAboutUs
                ? state.selectedLinkedAccount.originalAccount.hearAboutUs
                : undefined,
            hearAboutUsId:
              state.selectedLinkedAccount && state.selectedLinkedAccount.originalAccount.hearAboutUsId
                ? state.selectedLinkedAccount.originalAccount.hearAboutUsId
                : undefined,
            officeCode:
              state.selectedLinkedAccount && state.selectedLinkedAccount.originalAccount.officeCode
                ? state.selectedLinkedAccount.originalAccount.officeCode
                : undefined,
            repCode:
              state.selectedLinkedAccount && state.selectedLinkedAccount.originalAccount.repCode
                ? state.selectedLinkedAccount.originalAccount.repCode
                : undefined,
            repId:
              state.selectedLinkedAccount && state.selectedLinkedAccount.originalAccount.repId
                ? state.selectedLinkedAccount.originalAccount.repId
                : undefined,
            email:
              state.selectedLinkedAccount && state.selectedLinkedAccount.originalAccount.email
                ? state.selectedLinkedAccount.originalAccount.email
                : undefined,
            userName:
              state.selectedLinkedAccount && state.selectedLinkedAccount.originalAccount.userName
                ? state.selectedLinkedAccount.originalAccount.userName
                : undefined,
            last4Ssn:
              state.selectedLinkedAccount && state.selectedLinkedAccount.originalAccount.last4Ssn
                ? state.selectedLinkedAccount.originalAccount.last4Ssn
                : undefined,
          },
          searchOptions: {
            page: action.payload.searchOptions.page,
            pageSize: action.payload.searchOptions.pageSize,
            sortBy: action.payload.searchOptions.sortBy,
            sortByOrder: action.payload.searchOptions.sortByOrder,
            search: action.payload.searchOptions.search,
            status: action.payload.searchOptions.status,
          },
        },
      };
    }
    // case getType(Actions.receiveAccountOrders): {
    //   const { orders, date, add } = action.payload;
    //   const allOrders = add !== true ? orders : _.concat(state.orders || [], orders);
    //   const updatedOrders = _(allOrders).reduce((acc: Order<OrderLeg>[], o: Order<OrderLeg>) => {
    //     const orderExists = _.find(acc, ord => ord.OrderId === o.OrderId);
    //     if (orderExists) {
    //       const ordersWithoutExisting = _.filter(acc, ord => ord.OrderId !== o.OrderId);
    //       return [...ordersWithoutExisting, o];
    //     }
    //     return [...acc, o];
    //   }, []);
    //   return {
    //     ...state,
    //     isFetchingOrders: false,
    //     orders: getSortedOrders(updatedOrders),
    //     ordersLastRequestedAt: date,
    //     shouldLoadMoreOrders: false,
    //   };
    // }

    default:
      return state;
  }
};
