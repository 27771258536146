import React, { useCallback } from 'react';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import {
  BooleanToggleType,
  AllBooleanToggleTypes,
  IndividualRepresentationsUpdateValueProps,
} from '@tradingblock/types';
import { FormGroup, Modal, StepperField, TextboxField } from '@tradingblock/components';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { useDispatch } from 'react-redux';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { AccountManagementDataActions } from '../../../data/global/actions/AccountManagementActions';
import { getRequiredError } from './Validation';
import { formatBoolean } from '../../../utilities/accountManagement';

export const IndividualRepresentationsUpdate: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
  accountHolderIndex: number;
}> = ({ show, toggleModal, accountHolderIndex }) => {
  const dispatch = useDispatch();

  const accountId = useStateSelector(s => s.accounts.account && s.accounts.account.AccountId);

  const initialIndividualRepresentationsValues: IndividualRepresentationsUpdateValueProps = {
    stakeholder: null,
    disclosuresStakeholderTickerSymbol: '',
    disclosuresGovOfficialImmediateFamily: '',
    irsWithholding: null,
    govOfficial: null,
    disclosuresGovOfficialPoliticalOrg: '',
  };

  const onValidate = (values: IndividualRepresentationsUpdateValueProps) => {
    const {
      stakeholder,
      disclosuresGovOfficialImmediateFamily,
      disclosuresGovOfficialPoliticalOrg,
      disclosuresStakeholderTickerSymbol,
      irsWithholding,
      govOfficial,
    } = values;

    if (stakeholder === 'yes' && !disclosuresStakeholderTickerSymbol) {
      return {
        [`disclosuresStakeholderTickerSymbol`]: getRequiredError(disclosuresStakeholderTickerSymbol),
        [`stakeholder`]: getRequiredError(stakeholder),
        [`irsWithholding`]: getRequiredError(irsWithholding),
        [`govOfficial`]: getRequiredError(govOfficial),
        [`disclosuresGovOfficialImmediateFamily`]: getRequiredError(disclosuresGovOfficialImmediateFamily),
        [`disclosuresGovOfficialPoliticalOrg`]: getRequiredError(disclosuresGovOfficialPoliticalOrg),
      };
    }

    if (govOfficial === 'yes' && !disclosuresGovOfficialImmediateFamily && !disclosuresGovOfficialPoliticalOrg) {
      return {
        [`disclosuresGovOfficialImmediateFamily`]: getRequiredError(disclosuresGovOfficialImmediateFamily),
        [`disclosuresGovOfficialPoliticalOrg`]: getRequiredError(disclosuresGovOfficialPoliticalOrg),
        [`disclosuresStakeholderTickerSymbol`]: getRequiredError(disclosuresStakeholderTickerSymbol),
        [`stakeholder`]: getRequiredError(stakeholder),
        [`irsWithholding`]: getRequiredError(irsWithholding),
        [`govOfficial`]: getRequiredError(govOfficial),
      };
    }

    if (!stakeholder || !irsWithholding || !govOfficial) {
      return {
        [`stakeholder`]: getRequiredError(stakeholder),
        [`irsWithholding`]: getRequiredError(irsWithholding),
        [`govOfficial`]: getRequiredError(govOfficial),
        [`disclosuresGovOfficialImmediateFamily`]: getRequiredError(disclosuresGovOfficialImmediateFamily),
        [`disclosuresGovOfficialPoliticalOrg`]: getRequiredError(disclosuresGovOfficialPoliticalOrg),
        [`disclosuresStakeholderTickerSymbol`]: getRequiredError(disclosuresStakeholderTickerSymbol),
      };
    }
  };

  const onSubmit = useCallback(
    (values, { resetForm }) => {
      const {
        stakeholder,
        disclosuresStakeholderTickerSymbol,
        disclosuresGovOfficialImmediateFamily,
        disclosuresGovOfficialPoliticalOrg,
        irsWithholding,
        govOfficial,
      } = values;

      let updateValues = {
        item: 'Disclosures',
        accountHolder: {
          disclosures: {
            stakeholder: formatBoolean(stakeholder),
            govOfficial: formatBoolean(govOfficial),
            irsWithholding: formatBoolean(irsWithholding),
          },
          disclosuresStakeholderTickerSymbol,
          disclosuresGovOfficialImmediateFamily,
          disclosuresGovOfficialPoliticalOrg,
        },
        holder: accountHolderIndex,
      };

      dispatch(AccountManagementDataActions.requestMakeAccountUpdate({ accountId: accountId, request: updateValues }));

      resetForm();
      toggleModal();
    },
    [toggleModal]
  );

  return (
    <Formik initialValues={initialIndividualRepresentationsValues} onSubmit={onSubmit} validate={onValidate}>
      {({ handleSubmit, values, resetForm }) => (
        <Modal setShow={() => toggleModal()} show={show}>
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Financial Information Update Request</BootstrapModal.Title>
          </BootstrapModal.Header>

          <BootstrapModal.Body>
            <form id="individualRepresentationsUpdate" onSubmit={handleSubmit}>
              <h2 className="fields-title">Individual Representations</h2>
              <p>Are you A 10% shareholder, director, and/or policymaker of a publicly-owned company?</p>
              <FormGroup>
                <Field
                  component={StepperField}
                  id="stakeholder"
                  options={AllBooleanToggleTypes}
                  defaultLabelId="boolean"
                />
                {values.stakeholder === BooleanToggleType.Yes && (
                  <Field component={TextboxField} id="disclosuresStakeholderTickerSymbol" />
                )}
              </FormGroup>
              <p>
                Are you a current or former senior public official (US or foreign), or immediate family relation to
                such?
              </p>
              <FormGroup>
                <Field
                  component={StepperField}
                  id="govOfficial"
                  options={AllBooleanToggleTypes}
                  defaultLabelId="boolean"
                />
                {values.govOfficial === BooleanToggleType.Yes && (
                  <>
                    <Field component={TextboxField} id="disclosuresGovOfficialImmediateFamily" />

                    <Field component={TextboxField} id="disclosuresGovOfficialPoliticalOrg" />
                  </>
                )}
              </FormGroup>
              <p>Are you notified by the IRS that I am subject to backup withholding?</p>
              <FormGroup>
                <Field
                  component={StepperField}
                  id="irsWithholding"
                  options={AllBooleanToggleTypes}
                  defaultLabelId="boolean"
                />
              </FormGroup>
            </form>
          </BootstrapModal.Body>
          <BootstrapModal.Footer className="modal-footer-justified">
            <Button
              variant="secondary"
              onClick={() => {
                resetForm();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={(values: any) => handleSubmit(values)}>
              Submit
            </Button>
          </BootstrapModal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
