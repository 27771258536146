import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { PageActions } from '../data/global/actions/PageActions';
import { useDispatcher } from '../data/global/hooks';
import { account } from '../data/global/selectors/accountSelectors';
import { getAdminLinks } from '../components/admin/Links';
import { isAdminUserLevel, isRepUserLevel } from '../utilities/admin';

export interface PageContext {
  access: 'public' | 'admin';
  name: string;
}

export const usePageSettings = ({ name, access }: PageContext) => {
  const { dispatch } = useDispatcher();

  const userLevel = useSelector(account.userLevel);
  const userRoles = useSelector(account.userRoles);

  const currentPage = window.location.pathname;
  const adminLinks = useMemo(() => getAdminLinks(userLevel, userRoles), [userLevel, userRoles]);

  const allowed = useMemo(() => {
    if (access === 'admin' && userLevel) {
      // allow admins/reps to access root admin page
      if (_.trim(currentPage, '/') === 'admin' && (isAdminUserLevel(userLevel) || isRepUserLevel(userLevel))) {
        return true;
      }
      // allowed if current page is an accessible admin link
      return _.some(adminLinks, l => _.startsWith(currentPage, l.url));
    }
    return true;
  }, [access, userLevel, adminLinks, currentPage]);

  useEffect(() => {
    dispatch(PageActions.set({ page: name, access }));
  }, []);

  return { page: name, access, allowed };
};
