import { useMemo } from 'react';
import { TradingViewDataFeed } from '@tradingblock/tradingview';
import { CachedApi } from '../../../data/charts/CachedApi';
import { useApi } from '../../../context/Api';

export const useTradingviewDatafeed = () => {
  const api = useApi();

  const feed = useMemo(() => new TradingViewDataFeed(new CachedApi(api)), [api]);
  return feed;
};
