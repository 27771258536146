import React from 'react';
import _ from 'lodash';
import { AlertMessageBaseItem } from '@tradingblock/types';
import { AdminAlertActions } from '../../../../../data/global/actions/admin/AdminAlertActions';
import { useDispatcher } from '../../../../../data/global/hooks';
import { useAlertsData } from '../../useAlertsData';

export const AlertsActionCell: React.FunctionComponent<Pick<AlertMessageBaseItem, 'id' | 'template' | 'active'>> = ({
  id,
  template,
  active,
}) => {
  const toggle = active ? false : true;
  const { dispatch } = useDispatcher();

  const { data, total, ...filterData } = useAlertsData();
  const { pageSize, page } = filterData;

  const handleClick = (e: any, id: number) => {
    e.preventDefault();
    let payload = {
      id,
      active: toggle,
      template,
      page,
      pageSize,
    };
    dispatch(AdminAlertActions.requestUpdateAlert(payload));
  };

  return (
    <>
      <button
        className={`btn ${active ? 'btn-outline-danger' : 'btn-outline-success'}`}
        onClick={e => handleClick(e, id)}
      >
        {active ? 'Deactivate' : 'Activate'}
      </button>
    </>
  );
};
