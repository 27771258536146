import { PutCalendar, CallCalendar, Straddle, CallButterfly, Strangle, PutButterfly, CallCondor, PutCondor, IronButterfly, IronCondor } from './strategies';
import { OrderAction } from '@tradingblock/types';
import _ from 'lodash';
import { isInfinity } from '..';

var getLoss = function getLoss(strategyName, _ref, quotes) {
  var price = _ref.price,
      Quantity = _ref.Quantity,
      Legs = _ref.Legs,
      action = _ref.action;
  var isBuy = action === OrderAction.Buy;

  var allStrikes = _(Legs).map(function (l) {
    return l.Strike;
  }).filter(function (s) {
    return s !== undefined;
  }).value();

  var Px = price;
  var Cx = 0;
  var multiplier = _.every(quotes, function (q) {
    return q.Multiplier === 1;
  }) ? 1 : 100;
  var Qty = Quantity * multiplier;
  var K1 = allStrikes[0];
  var K2 = allStrikes[1];

  switch (strategyName) {
    case 'Call':
    case 'Shares':
    case Strangle.info.Name:
    case Straddle.info.Name:
      {
        return isBuy ? Px + Cx : 'Infinity';
      }

    case 'Put':
      {
        return isBuy ? Px + Cx : Px - Qty * K1 - Cx;
      }

    case 'Covered Call':
      {
        return isBuy ? Px + Cx : -Qty * K1 + Px - Cx;
      }

    case 'Married Put':
      {
        return isBuy ? Qty * K1 - Px - Cx : 'Infinity';
      }

    case 'Call Vertical':
    case 'Put Vertical':
    case CallButterfly.info.Name:
    case PutButterfly.info.Name:
    case CallCondor.info.Name:
    case PutCondor.info.Name:
    case IronButterfly.info.Name:
    case IronCondor.info.Name:
      {
        return isBuy ? Px + Cx : -Qty * Math.abs(K1 - K2) + Px - Cx;
      }

    case PutCalendar.info.Name:
      {
        return isBuy ? -Qty * _.max([0, K2 - K1]) - Px - Cx : -Qty * _.max([0, K1 - K2]) + Px - Cx;
      }

    case CallCalendar.info.Name:
      {
        return isBuy ? -Qty * _.max([0, K1 - K2]) - Px - Cx : -Qty * _.max([0, K2 - K1]) + Px - Cx;
      }
    // case Collar.info.Name: {
    //   return isBuy ? Qty * _.min([K1, K2]) - Px - Cx : -Qty * _.max([K1, K2]) + Px - Cx;
    // }

    default:
      {
        return 0;
      }
  }
};

var absVal = function absVal(val) {
  return isInfinity(val) ? val : Math.abs(val);
};

var getProfitValue = function getProfitValue(strategyName, _ref2, quotes) {
  var price = _ref2.price,
      Quantity = _ref2.Quantity,
      Legs = _ref2.Legs,
      action = _ref2.action,
      commission = _ref2.commission;
  var isBuy = action === OrderAction.Buy;

  var allStrikes = _(Legs).map(function (l) {
    return l.Strike;
  }).filter(function (s) {
    return s !== undefined && s !== null;
  }).value();

  var Px = price;
  var multiplier = _.every(quotes, function (q) {
    return q.Multiplier === 1;
  }) ? 1 : 100;
  var Qty = Quantity * multiplier;
  var K1 = allStrikes[0];
  var K2 = allStrikes[1];

  switch (strategyName) {
    case 'Call':
    case 'Shares':
    case Strangle.info.Name:
    case Straddle.info.Name:
      {
        return isBuy ? 'Infinity' : Px;
      }

    case 'Put':
    case 'Covered Call':
      {
        return isBuy ? Qty * K1 - Px : Px;
      }

    case 'Married Put':
      {
        return isBuy ? 'Infinity' : -1 * Qty * K1 + Px;
      }

    case 'Call Vertical':
    case 'Put Vertical':
    case CallButterfly.info.Name:
    case PutButterfly.info.Name:
    case CallCondor.info.Name:
    case PutCondor.info.Name:
    case IronButterfly.info.Name:
    case IronCondor.info.Name:
      {
        return isBuy ? Qty * Math.abs(K1 - K2) - Px : Px;
      }

    case PutCalendar.info.Name:
      {
        return isBuy ? Qty * _.max([0, K1 - K2]) + Px : Qty * _.max([0, K2 - K1]) - Px;
      }

    case CallCalendar.info.Name:
      {
        return isBuy ? Qty * _.max([0, K2 - K1]) + Px : Qty * _.max([0, K1 - K2]) - Px;
      }
    // case Collar.info.Name: {
    //   return isBuy ? Qty * _.max([0, K1 - K2]) - Px - Cx : -Qty * _.min([K1, K2]) + Px - Cx;
    // }

    default:
      {
        return 0;
      }
  }
};

export var getProfitLoss = function getProfitLoss(strategyName, order, quotes) {
  var profit = getProfitValue(strategyName, order, quotes);
  var loss = getLoss(strategyName, order, quotes);
  return {
    profit: absVal(profit),
    loss: absVal(loss)
  };
};