import createCachedSelector from 're-reselect';
import { DataState } from '../state';
import _ from 'lodash';
import { accountIdValue, OrderSymbols, PositionSymbolsSelector } from '../dataSelectors';
import { OrderAction, OrderActionDirection } from '@tradingblock/types';
import { getBestPrice, FilterOrderByDate, isSymbolExpired } from '@tradingblock/api';

import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import { calculateEstCommission } from '../../../blocks/Order/components/Preview/EstCommissionPreview';

export const getAccountPnL = createCachedSelector(
  (state: DataState) => state.account.pnl,
  pnl => {
    const m = _.flatMap(pnl, pnlRange => pnlRange.pnlHistory);

    return m;
  }
)(
  // re-reselect keySelector
  accountIdValue
);

export const isFetchingAccountPnL = createCachedSelector(
  (state: DataState) => state.account.pnl,
  pnl => {
    // This sums up the truth values of each pnl.isFetching
    // If every truth value in range is false it returns false,
    // Otherwise it returns true
    return _.reduce(
      pnl || [],
      (prev, curr) => {
        return { ...curr, isFetching: prev.isFetching || curr.isFetching };
      },
      { isFetching: false }
    ).isFetching;
  }
)(
  // re-reselect keySelector
  accountIdValue
);

export const isPnLLoaded = createCachedSelector(
  (state: DataState) => state,
  (_state: DataState) => _state,
  state => {
    return state.account.pnl !== undefined;
  }
)(
  // re-reselect keySelector
  accountIdValue
);

const timeframeOrdersSelector = (state: DataState, options: { startDate: Date; endDate: Date }) => {
  const orders = state.account.orders || [];
  const subAccountId = state.account.subAccountId;
  const filtered = subAccountId ? orders.filter(o => o.SubaccountId && o.SubaccountId === subAccountId) : orders;
  return FilterOrderByDate(filtered, options.startDate, options.endDate);
};

export interface PnLBySymbol {
  symbol: string;
  lastTradePrice: number;
  bestTradePrice: number;
  previousSettle: number;
  positionOnOpen: number;
  quantityBought: number;
  quantitySold: number;
  netQuantityChange: number;
  currentPosition: number;
  avgBoughtPrice: number;
  avgSoldPrice: number;
  buyPrices: number[];
  sellPrices: number[];
  tradingPnL: number;
  positionPnL: number;
  totalPnL: number;
  estCommission?: number;
}

const orderSymbolsOrUndefined = (s: DataState) =>
  s.account.isFetchingOrders || s.account.orders === undefined
    ? []
    : s.account.orders.flatMap(o => o.Legs.flatMap(ol => ol.Symbol));

// const allOrderSymbols = (s: DataState) => (s.account.orders || []).flatMap(o => o.Legs.flatMap(ol => ol.Symbol));
// const allPositionSymbols = (s: DataState) => s.positions.positions.flatMap(p => p.Symbol);
export const pnlSelectors = {
  pnlDataLoaded: createSelector(
    (s: DataState) =>
      s.account.isFetchingOrders === false && s.positions.isFetching === false && s.positions.loaded === true,
    OrderSymbols,
    PositionSymbolsSelector,
    (state: DataState) => state.quotes.quoteMetadata.data,
    (ordersAndPositionsLoaded, ordSymbols, positionSymbols, quotes) => {
      if (ordersAndPositionsLoaded === false) {
        return { isLoaded: false, reason: 'ordersAndPositionsLoaded=false' };
      }
      const symbolsWithoutQuotes = _(ordSymbols)
        .union(positionSymbols)
        .uniq()
        .filter(s => !isSymbolExpired(s))
        .filter(s => {
          const quote = _.find(quotes, q => q.Symbol === s);
          return quote === undefined;
        })
        .value();
      const symbolsWithoutQuotesExist = symbolsWithoutQuotes.length !== 0;
      if (symbolsWithoutQuotesExist) {
        // console.debug('symbols without quotes exist', symbolsWithoutQuotes);
        return { isLoaded: false, reason: 'symbolsWithoutQuotesExist=true', symbolsWithoutQuotes };
      }
      return { isLoaded: true };
    }
  ),
  trading: createCachedSelector(
    (state: DataState, options: { startDate: Date; endDate: Date }) => timeframeOrdersSelector(state, options),
    (state: DataState) => state.quotes.quoteMetadata.data,
    (state: DataState) => state.positions.info,
    (state: DataState) => state.performance,
    (state: DataState) => state.account.accountDetails,
    (orders, quotes, positions, performance, accountDetails): PnLBySymbol[] =>
      _.flatMap(orders, o => o.Legs.map(leg => ({ ...leg, parentOrder: o }))).reduce(
        (acc: PnLBySymbol[], currLeg): PnLBySymbol[] => {
          console.log({ orders, parentOrder: currLeg.parentOrder });
          const isBuy = currLeg.Action === OrderAction.Buy;
          const isSingleLegOrder = currLeg.parentOrder.Legs.length === 1;
          // custom share orders will not have a legFillQuantity and will instead have the quantity filled on the order
          // need to handle this case
          const fillQuantity =
            currLeg.LegFillQuantity != null
              ? currLeg.LegFillQuantity
              : currLeg.parentOrder.FillQuantity != null && isSingleLegOrder
              ? currLeg.parentOrder.FillQuantity
              : 0;

          if (fillQuantity === 0) {
            return acc;
          }
          const quote = quotes[currLeg.Symbol] || undefined;
          const { AskPrice, BidPrice, LastOrClosePrice, Multiplier } = quote || {
            AskPrice: 0,
            BidPrice: 0,
            LastOrClosePrice: 0,
            Multiplier: 1,
          };
          const closePrice =
            quote && quote.ClosePrice ? quote.ClosePrice : quote && quote.LastOrClosePrice ? quote.LastOrClosePrice : 0;

          const existing = _.find(acc, v => v.symbol === currLeg.Symbol) || {
            buyPrices: [],
            sellPrices: [],
            avgBoughtPrice: 0,
            avgSoldPrice: 0,
            symbol: currLeg.Symbol,
            positionOnOpen: 0,
            quantityBought: 0,
            quantitySold: 0,
            currentPosition: 0,
            estCommission: undefined,
          };

          const quantityBought = isBuy ? existing.quantityBought + fillQuantity : existing.quantityBought;
          const quantitySold = !isBuy ? existing.quantitySold + Math.abs(fillQuantity) : existing.quantitySold;
          const netQuantityChange = quantityBought - quantitySold;
          const currentPositionValue = positions[currLeg.Symbol];
          let currentPosition = 0;
          if (currentPositionValue) {
            currentPosition = currentPositionValue.quantity;
          }

          const bestPrice = getBestPrice({
            last: LastOrClosePrice || 0,
            ask: AskPrice,
            bid: BidPrice,
            defaultValue: 'avgBidAsk',
          });
          const others = _.filter(acc, v => v.symbol !== currLeg.Symbol);
          const positionOnOpen = currentPosition - netQuantityChange;
          const buyPrices: number[] = isBuy
            ? [...existing.buyPrices, ..._.times<number>(fillQuantity, () => currLeg.AverageLegFillPrice || 0)]
            : existing.buyPrices;
          const sellPrices: number[] = !isBuy
            ? [...existing.sellPrices, ..._.times<number>(fillQuantity, () => currLeg.AverageLegFillPrice || 0)]
            : existing.sellPrices;
          const avgBoughtPrice = quantityBought > 0 ? _.sum(buyPrices) / quantityBought : 0;
          const avgSoldPrice = quantitySold > 0 ? _.sum(sellPrices) / quantitySold : 0;

          const tradingPnL =
            (quantityBought * (bestPrice - avgBoughtPrice) - quantitySold * (bestPrice - avgSoldPrice)) * Multiplier;
          const positionPnL = positionOnOpen * (bestPrice - closePrice) * Multiplier;
          const totalPnL = tradingPnL + positionPnL;

          let commission = undefined;
          if (accountDetails !== undefined) {
            commission = calculateEstCommission(
              [
                {
                  assetType: currLeg.AssetType,
                  quantity: Math.abs(netQuantityChange),
                },
              ],
              accountDetails.commissions
            );
          }

          if (existing.estCommission !== undefined) {
            if (commission === undefined) {
              console.warn(
                `Could not calculate commission for position ${currLeg.Symbol} but have previous commission`
              );
            } else {
              commission = existing.estCommission + commission;
            }
          }

          return [
            ...others,
            {
              buyPrices,
              sellPrices,
              symbol: currLeg.Symbol,
              bestTradePrice: bestPrice,
              lastTradePrice: quote.LastTradePrice,
              previousSettle: closePrice,
              positionOnOpen,
              quantityBought,
              quantitySold,
              netQuantityChange,
              currentPosition,
              avgBoughtPrice,
              avgSoldPrice,
              tradingPnL,
              positionPnL,
              totalPnL,
              estCommission: commission,
            },
          ];
        },
        []
      )
  )((state: DataState, options: { startDate: Date; endDate: Date }) => {
    const start = dayjs(options.startDate).format('l');
    const end = dayjs(options.endDate).format('l');
    return `pnl-trading-${start}-${end}`;
  }),
};

export const isSummaryDataLoaded = createSelector(
  (state: DataState) => state.orders.isFetching,
  (state: DataState) => state.positions.isFetching,
  (ordersFetching, positionsFetching) => {
    return ordersFetching || positionsFetching ? false : true;
  }
);
