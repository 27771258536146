import { useRef, useMemo, useEffect } from 'react';
import createActivityDector from '../utilities/activityDector';
export function useActivityTimeout(callback, timeoutMS) {
  var lastActiveTime = useRef();
  var activityDector = useMemo(function () {
    return createActivityDector({
      timeToIdle: timeoutMS,
      autoInit: false
    });
  }, [timeoutMS]);
  useEffect(function () {
    activityDector.on('idle', function () {
      callback();
    });
    activityDector.on('active', function () {
      if (lastActiveTime.current) {
        if (Date.now() - lastActiveTime.current >= timeoutMS) {
          callback();
          activityDector.stop();
        } else {
          lastActiveTime.current = undefined;
        }
      }
    });
    activityDector.on('inactive', function () {
      lastActiveTime.current = Date.now();
    });
    activityDector.init();
    return function () {
      activityDector.stop();
    };
  }, []);
}