import React, { useEffect } from 'react';
import _ from 'lodash';
import { useWhyDidYouUpdate } from '@tradingblock/components';
import { useOrderData, OrderState, useOrderBlockData, OrderQuote } from '../state/OrderState';
import { OrderSymbolList, OrderSymbolListValues } from '../state/OrderLegSelector';
import { useOrderActions } from '../state/useOrderActions';
import { OrderActions, Actions } from '../state/OrderActions';
import { useOrderStatus } from '../data/useOrderStatus';
import { useOrderPrice } from '../hooks/useOrderPrice';
import { useQuoteBidAskMetadata } from '../../../hooks/useFeedQuote';
import { useBlockActions, useBlockActionDispatch, useBlockId } from '../../../components/blocks/BlockState';
import { OrderType } from '@tradingblock/types';

const useQuoteSync = () => {
  const blockId = useBlockId();
  const orderSymbols = useOrderData(s => OrderSymbolListValues(s, blockId));
  const quotes: OrderQuote[] = useQuoteBidAskMetadata();
  const dispatch = useBlockActionDispatch<OrderActions>();

  useEffect(() => {
    dispatch(Actions.updateQuote(quotes));
  }, [quotes, orderSymbols]);
};

export const PriceAndQuoteSync = () => {
  const price = useOrderData(s => s.price);
  const orderType = useOrderData(s => s.orderType);
  const recalculate = useOrderData(s => s.recalculatePrice);
  const legInfo = useOrderBlockData(OrderSymbolListValues);
  useQuoteSync();
  const { setPrice } = useOrderActions();
  const { mid, includesAllSymbols } = useOrderPrice();
  const { complete } = useOrderStatus();
  const { setField } = useBlockActions<OrderState>();

  useEffect(() => {
    const shouldRecalc = price === undefined || recalculate === true;

    if (shouldRecalc && !_.isNil(mid) && complete && includesAllSymbols) {
      if (orderType === OrderType.Stop_Limit) {
        setPrice(undefined, false);
      }
    }
  }, [recalculate, mid, includesAllSymbols, price, complete, setPrice, legInfo]);
  useWhyDidYouUpdate('PriceSync', { mid, price, complete, setField });
  return <></>;
};
