import { ClearerType } from '@tradingblock/types';
import { useStateSelector } from '../data/global/dataSelectors';

// use the account number to determine if office code refers to RQD or Apex cleared account
export const useClearer = (): string => {
  const accountNumber = useStateSelector(s => s.account.accountNumber);

  if (!accountNumber) {
    return 'No account number, cannot determine Clearer';
  }

  return accountNumber.includes('TBLK') ? ClearerType.RQD : ClearerType.Apex;
};
