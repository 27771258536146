import { getType } from 'typesafe-actions';
import { InitialState } from '../initialState';
import { RootAction, Actions } from '../actions';

export const subAccountsBalances = (state = InitialState.accountData.accountsBalances, action: RootAction) => {
  switch (action.type) {
    case getType(Actions.receiveSubAccountBalances): {
      return {
        ...state,
        [`${action.payload.subAccountId}`]: action.payload.balance,
      };
    }
    default:
      return state;
  }
};
