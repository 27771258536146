import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Carousel, CarouselResponsiveOptions, useAlerts, newId } from '@tradingblock/components';
import { DashboardInfo, DashboardConfiguration } from '@tradingblock/types';
import { useDispatcher } from '../../data/global/hooks';
import { dashboardsSelectors } from '../../data/global/dataSelectors';
import { useStore } from '../../context/Storage';
import { tryCreateDashboard } from '../../data/async/dashboards';
import { DashboardPresetCarousel } from './DashboardPresetCarousel';
import { EditDashboardModal } from './drawer/EditDashboardModal';
import { DashboardItem } from './drawer/DashboardItem';
import { DashboardActions } from '../../data/global/actions';
import { TradingBlockLink } from '../basic/TradingBlockLink';
import { SiteName } from '../basic/SiteName';
import { getCreateDashboardRequest } from '../../utilities/dashboard';
import Config from '../../config';

interface DashboardDrawerProps {
  isOpen: boolean;
  toggleIsOpen: () => void;
}

const defaultDashboard: DashboardInfo = {
  dashboardId: newId(),
  title: '',
};

export const DashboardDrawer: React.FunctionComponent<DashboardDrawerProps> = ({ isOpen, toggleIsOpen }) => {
  const store = useStore();
  const { dispatch, dispatcher } = useDispatcher();
  const notifications = useAlerts();

  const userDashboards = useSelector(dashboardsSelectors.dashboards);
  const currentDashboardId = useSelector(dashboardsSelectors.dashboardId);
  const [showAddBoard, setShowAddBoard] = useState(false);

  const canDeleteUserDashboard = useMemo(() => {
    // on production, can't delete last dashboard (so there is always at least one)
    return userDashboards ? _.size(userDashboards) > 1 : false;
  }, [userDashboards]);

  // hardcoded exchange boards
  const exchangeBoards = _.map(['Research #1', 'Trade Ideas #2'], t => ({ title: t, dashboardId: '' }));

  const onAddBoard = useCallback((values: DashboardInfo) => {
    const dashboardInfo: DashboardInfo = { title: values.title, dashboardId: newId() };
    dispatch(DashboardActions.addEmptyDashboard({ dashboard: dashboardInfo, setAsActive: true }));
  }, []);

  const onUpdateBoard = useCallback(
    (board: DashboardInfo) => {
      dispatcher.dashboards.updateDashboard(board);
    },
    [dispatcher.dashboards]
  );

  const onDeleteBoard = useCallback(
    (board: DashboardInfo) => {
      dispatcher.dashboards.updateDashboard({ ...board, isDeleted: true });
    },
    [dispatcher.dashboards]
  );

  const onSelectDashboard = (board: DashboardInfo) => {
    dispatcher.dashboards.setDashboard({ dashboard: board });
    // toggleIsOpen();
  };

  const onSelectPresetDashboard = useCallback(
    (board: DashboardConfiguration) => {
      const newBoard = {
        ...board,
        dashboardId: newId(),
      };
      const createRequest = getCreateDashboardRequest(newBoard);

      tryCreateDashboard(store, createRequest).then(dashboardSaved => {
        if (!dashboardSaved) {
          console.warn('saving dashboard failed! ', newBoard);
        }
        dispatcher.dashboards.setDashboard({ dashboard: { ...newBoard } });
      });
    },
    [store, dispatcher.dashboards]
  );

  const onSelectExchangeDashboard = useCallback(async () => {
    notifications.info('Exchange boards not yet available...', { id: 'exchange-boards' });
  }, [notifications]);

  const onKeyDown = (e: KeyboardEvent) => {
    // if drawer open while pressing ESC, close drawer
    if (isOpen && e.keyCode === 27) {
      e.preventDefault();
      e.stopPropagation();
      if (showAddBoard) {
        setShowAddBoard(false);
      } else {
        toggleIsOpen();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  return (
    <div className="dashboard-drawer">
      <header className="dashboard-drawer-header">
        <div className="tip">
          Welcome to the new{' '}
          <strong>
            <SiteName />
          </strong>{' '}
          &ndash; designed to let you configure it just how you like.{' '}
          <TradingBlockLink to="VideoTourUrl">
            <strong>TAKE A VIDEO TOUR</strong>
          </TradingBlockLink>
        </div>
      </header>

      <h2 className="txt-sm caps">
        <strong>My custom boards</strong> &nbsp;&nbsp;
        <button className="btn-link caps" type="button" onClick={() => setShowAddBoard(true)}>
          <strong>
            <i className="far fa-plus"></i> Add custom board
          </strong>
        </button>
      </h2>
      <div className="boards">
        <EditDashboardModal
          dashboard={defaultDashboard}
          show={showAddBoard}
          onSave={onAddBoard}
          onClose={() => setShowAddBoard(false)}
          validateOnMount={false}
          header="Add board" />
        <Carousel
          infinite={false}
          speed={500}
          slidesToShow={5}
          slidesToScroll={1}
          initialSlide={0}
          responsive={CarouselResponsiveOptions}>
          {_.map(userDashboards, (d, i) => (
            <DashboardItem
              key={`user-board-${i}`}
              dashboard={d}
              openInNewWindow
              isSelected={d.dashboardId === currentDashboardId}
              onSelectBoard={() => onSelectDashboard(d)}
              onSaveBoard={board => onUpdateBoard({ ...d, ...board })}
              onDeleteBoard={canDeleteUserDashboard ? () => onDeleteBoard(d) : undefined} />
          ))}
        </Carousel>
      </div>

      <DashboardPresetCarousel onSelectBoard={onSelectPresetDashboard} />

      {exchangeBoards.length > 0 && (
        <>
          <h2 className="txt-sm caps">
            <strong>The Exchange</strong>
          </h2>
          <div className="boards">
            <Carousel
              infinite={false}
              speed={500}
              slidesToShow={5}
              slidesToScroll={1}
              initialSlide={0}
              responsive={CarouselResponsiveOptions}>
              {exchangeBoards.map((b, i) => (
                <DashboardItem
                  key={`exchange-board-${i}`}
                  dashboard={b}
                  onSelectBoard={() => onSelectExchangeDashboard()}
                  isLocked={true} />
              ))}
            </Carousel>
          </div>
        </>
      )}
    </div>
  );
};
