import { createStandardAction } from 'typesafe-actions';
import { AccountDetails, AccountInfo, EntitlementInfo, SubAccount, UserProfile } from '@tradingblock/types';
import { buildActions, actionBuilder } from '../actionUtilities';

const REQUEST_ACCOUNT = '@data/account/REQUEST';
const RECEIVE_ACCOUNT = '@data/account/RECEIVE';
const REQUEST_ACCOUNT_ERROR = '@data/account/ERROR';

const REQUEST_SUBACCOUNT = '@data/subaccount/REQUEST';
const RECEIVE_SUBACCOUNT = '@data/subaccount/RECEIVE';
const REQUEST_SUBACCOUNT_ERROR = '@data/subaccount/ERROR';

const REQUEST_USER_ENTITLEMENT = '@data/account/entitlement/REQUEST';
const RECEIVE_USER_ENTITLEMENT = '@data/account/entitlement/RECEIVE';
const ERROR_USER_ENTITLEMENT = '@data/account/entitlement/ERROR';

const REQUEST_USERPROFILE = '@data/user/profile/REQUEST';
const RECEIVE_USERPROFILE = '@data/user/profile/RECEIVE';
const USERPROFILE_ERROR = '@data/user/profile/ERROR';

const LOAD_CURRENTUSER_DATA = '@data/user/current/load';

const TOGGLE_ACCOUNT_SWITCHER = '@data/account/SWITCHER';
const SET_SUBACCOUNT = '@data/subaccount/SET';
const SET_ACCOUNT = '@data/account/SET';
const GET_SET_ACCOUNT_DATA = '@data/account/GET_SET_DATA';
const CLEAR_SET_ACCOUNT_SEARCH_OPTIONS = '@data/account/CLEAR_SET_ACCOUNT_SEARCH_OPTIONS';

const SET_ORIGIN = '@data/account/SET_ORIGIN';

const SET_MULTIPLE_ACCOUNTS = '@data/account/SET_MULTIPLE_ACCOUNTS';
const SET_LINKED_ACCOUNT_LOADED = '@data/account/SET_LINKED_ACCOUNT_LOADED';

const [requestAccountEntitlement, receiveAccountEntitlement, errorAccountEntitlement] = buildActions({
  REQUEST: REQUEST_USER_ENTITLEMENT,
  RECEIVE: RECEIVE_USER_ENTITLEMENT,
  ERROR: ERROR_USER_ENTITLEMENT,
})<any, EntitlementInfo>();

const [requestAccounts, receiveAccounts, requestAccountsError] = buildActions({
  REQUEST: REQUEST_ACCOUNT,
  RECEIVE: RECEIVE_ACCOUNT,
  ERROR: REQUEST_ACCOUNT_ERROR,
})<{ id?: number }, AccountInfo[]>();
const [requestSubAccounts, receiveSubAccounts, requestSubAccountsError] = buildActions({
  REQUEST: REQUEST_SUBACCOUNT,
  RECEIVE: RECEIVE_SUBACCOUNT,
  ERROR: REQUEST_SUBACCOUNT_ERROR,
})<{ accountId: number }, SubAccount[]>();
const [requestUserProfile, receiveUserProfile, errorUserProfile] = actionBuilder<{ id?: number }, UserProfile>()(
  REQUEST_USERPROFILE,
  RECEIVE_USERPROFILE,
  USERPROFILE_ERROR
);

const loadUserData = createStandardAction(LOAD_CURRENTUSER_DATA)<{ accountId: number }>();

const toggleAccountSwitcher = createStandardAction(TOGGLE_ACCOUNT_SWITCHER)<{ show?: boolean }>();
const setSubAccount = createStandardAction(SET_SUBACCOUNT)<{ subaccountId: number | undefined }>();
const setAccount = createStandardAction(SET_ACCOUNT)<{ accountId: number | undefined }>();
const retrieveSetAccountData = createStandardAction(GET_SET_ACCOUNT_DATA)<{
  accountId: number;
  searchOptions: {
    page: number;
    pageSize: number;
    sortBy: string;
    sortByOrder: 'asc' | 'desc';
    search: string;
    status: string;
  };
}>();
const clearSetAccountSearchOptions = createStandardAction(CLEAR_SET_ACCOUNT_SEARCH_OPTIONS)<{}>();
const setMultipleAccounts = createStandardAction(SET_MULTIPLE_ACCOUNTS)();
const setLinkedAccountLoaded = createStandardAction(SET_LINKED_ACCOUNT_LOADED)<boolean>();

export const AccountActions = {
  requestAccountEntitlement,
  receiveAccountEntitlement,
  errorAccountEntitlement,
  requestAccounts,
  receiveAccounts,
  requestAccountsError,
  requestSubAccounts,
  receiveSubAccounts,
  requestSubAccountsError,
  requestUserProfile,
  receiveUserProfile,
  errorUserProfile,
  loadUserData,
  toggleAccountSwitcher,
  setSubAccount,
  setAccount,
  retrieveSetAccountData,
  clearSetAccountSearchOptions,
  setMultipleAccounts,
  setLinkedAccountLoaded,
};
