import React, { useEffect, useCallback, useMemo } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Cell } from 'react-table';
import { useParentSizeContext, ColumnWithSorting, Loading, useTableColumnWidths } from '@tradingblock/components';
import { useDispatcher } from '../../../../data/global/hooks';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { AdminApplicationActions } from '../../../../data/global/actions/admin/AdminApplicationActions';
import { BlockTable } from '../../../shared/BlockTable';
import {
  ApplicationInvestigationUploadedDocument,
  ApplicationModel,
  ApplicationModelWithKey,
  ApplicationsRequest,
  ApplicationStatusApplicationDocuments,
  ApplicationStatusInvestigations,
  ApplicationStep,
  CurrentItemApplicationModel,
  RequestedInvestigationDocument,
} from '@tradingblock/types';
import { useApplicationsData } from '../useApplications';
import { isFetchingApplications } from '../../../../data/global/selectors/admin/applicationsSelector';
import { useApi } from '../../../../context/Api';
import { ApplicationsActionCell } from './table/ApplicationsActionCell';
import { AdminApplicationDetail } from '../AdminApplicationsDetail';
import { ApplicationsFilters } from './ApplicationsFilters';
import { useBlockTable } from '../../../shared/table/useBlockTableActions';

const AdminApplicationsColumns: ColumnWithSorting<ApplicationModel>[] = [
  {
    Header: 'AccountId',
    accessor: v => v.accountId,
    id: 'accountId',
    width: 0.5,
  },
  {
    Header: 'Primary Name',
    accessor: v => v.primaryAccountHolder.firstName && v.primaryAccountHolder.lastName,
    id: 'accountName',
    sortType: 'alphanumeric',
    Cell: ({ row }: Cell<any>) => {
      const { firstName, middleInitial, lastName, suffix } = row.original.primaryAccountHolder;
      if (!firstName || !lastName) {
        return <></>;
      } else {
        return <>{`${firstName} ${middleInitial ? middleInitial : ''} ${lastName} ${suffix ? suffix : ''}`}</>;
      }
    },
  },
  {
    Header: 'Primary Email',
    accessor: v => v.primaryAccountHolder.email,
    id: 'email',
  },
  {
    Header: 'Username',
    accessor: v => v.ownerUsername,
    id: 'ownerUsername',
  },
  {
    Header: 'Type',
    accessor: v => v.type,
    id: 'type',
    width: 0.5,
  },
  {
    Header: 'Created On',
    accessor: v => v.createdOn,
    id: 'createdOn',
    width: 0.5,
    Cell: ({ row }: Cell<any>) => {
      const dateVal = dayjs(row.original.createdOn);
      if (dateVal && dateVal.isValid()) {
        return (
          <>
            {dateVal.format('M/DD/YY')}
            <br />
            {dateVal.format('hh:mm A')}
          </>
        );
      }
      return <></>;
    },
  },
  {
    Header: 'Last Saved',
    accessor: v => v.lastSavedOn,
    id: 'lastSavedOn',
    width: 0.5,
    Cell: ({ row }: Cell<any>) => {
      const dateVal = dayjs(row.original.lastSavedOn);
      if (dateVal && dateVal.isValid()) {
        return (
          <>
            {dateVal.format('M/DD/YY')}
            <br />
            {dateVal.format('hh:mm A')}
          </>
        );
      }
      return <></>;
    },
  },
  {
    Header: 'Latest Step',
    accessor: v => v.latestStepSaved,
    id: 'latestStepSaved',
    className: 'text-center',
    width: 0.5,
    Cell: ({ row }: Cell<any>) => {
      const lastStep = row.original.latestStepSaved;

      const isSubmitted =
        row.original.applicationSentOn !== null &&
        row.original.applicationSentOn !== undefined &&
        row.original.accountId !== null &&
        row.original.accountId !== undefined &&
        row.original.signedNames.length > 0 &&
        lastStep === ApplicationStep.Sign;

      let style =
        lastStep === 'sign' && isSubmitted
          ? 'pos text-center'
          : lastStep !== 'secure'
          ? 'pending text-center'
          : 'neg text-center';

      if (lastStep) {
        return (
          <div className={style}>
            {isSubmitted
              ? 'Submitted'
              : lastStep[0].toUpperCase() +
                lastStep
                  .slice(1)
                  .replace(/([A-Z])/g, ' $1')
                  .trim()}
          </div>
        );
      }
      return <></>;
    },
  },
  {
    Header: 'Docs Required',
    accessor: v => v.accountId,
    id: 'docsRequired',
    className: 'text-center',
    disableSortBy: true,
    width: 0.5,
    Cell: ({ row }: Cell<any>) => {
      const accountId = row.original.accountId;
      const api = useApi();
      const [docsRequired, setDocsRequired] = React.useState<any>(false);
      const [isLoading, setIsLoading] = React.useState<boolean>(true);
      const getInvestigations = async () => {
        if (accountId && row.original.signedNames.length > 0) {
          const res = await api.application.getApplicationStatus(accountId);
          if (res.responseCode === 0) {
            const { payload } = res;
            const { investigations, applications } = payload;
            // Documents Required Logic from Application Status Page
            const participants: {
              firstName: string;
              lastName: string;
              middleInitial: string;
              suffix: string;
              accountHolderId?: number;
              investigationId?: number;
              requestedCatagories: {
                application: RequestedInvestigationDocument[];
                investigations: RequestedInvestigationDocument[];
              };
              uploadedDocuments: ApplicationInvestigationUploadedDocument[];
            }[] = [];

            const applicationRequestedDocuments: (ApplicationStatusInvestigations &
              ApplicationStatusApplicationDocuments)[] = [
              ...(investigations ? investigations : []),
              ...(applications ? applications : []),
            ];

            applicationRequestedDocuments.forEach(requestedDocumentDetailEntry => {
              // Check if we have an entry for the participant of the applications
              // We need to do this since accounts can have multiple people like
              // Joint accounts.
              let participantIndex = participants.findIndex(
                participant =>
                  requestedDocumentDetailEntry.firstName === participant.firstName &&
                  requestedDocumentDetailEntry.lastName === participant.lastName
              );
              if (participantIndex === -1) {
                // If we do not have an entry for the participant base on the first name
                // and last name. Then, we create one.
                participants.push({
                  firstName: requestedDocumentDetailEntry.firstName ? requestedDocumentDetailEntry.firstName : '',
                  lastName: requestedDocumentDetailEntry.lastName ? requestedDocumentDetailEntry.lastName : '',
                  middleInitial: requestedDocumentDetailEntry.middleInitial
                    ? requestedDocumentDetailEntry.middleInitial
                    : '',
                  suffix: requestedDocumentDetailEntry.suffix ? requestedDocumentDetailEntry.suffix : '',
                  accountHolderId: requestedDocumentDetailEntry.accountHolderId,
                  investigationId: requestedDocumentDetailEntry.investigationId,
                  requestedCatagories: {
                    application: [],
                    investigations: [],
                  },
                  uploadedDocuments: [],
                });
                // set the participantIndex to the new entry
                participantIndex = participants.length - 1;
              } else {
                if (!participants[participantIndex].accountHolderId && requestedDocumentDetailEntry.accountHolderId) {
                  participants[participantIndex].accountHolderId = requestedDocumentDetailEntry.accountHolderId;
                }
                if (participants[participantIndex].investigationId && requestedDocumentDetailEntry.investigationId) {
                  participants[participantIndex].investigationId = requestedDocumentDetailEntry.investigationId;
                }
              }

              //Union the requested document categories
              const categories = [
                ...(requestedDocumentDetailEntry.appCategories ? requestedDocumentDetailEntry.appCategories : []),
                ...(requestedDocumentDetailEntry.cipCategories ? requestedDocumentDetailEntry.cipCategories : []),
              ];
              // Uniquely read all documentNames that have been uploaded
              const uploadedDocumentsNames = [
                ...new Set(
                  requestedDocumentDetailEntry.uploadedDocuments
                    ? requestedDocumentDetailEntry.uploadedDocuments.map(document => document.documentName)
                    : []
                ),
              ];
              const uploadedDocuments = [
                ...new Set(
                  requestedDocumentDetailEntry.uploadedDocuments ? requestedDocumentDetailEntry.uploadedDocuments : []
                ),
              ];

              if (uploadedDocuments.length > 0) {
                participants[participantIndex].uploadedDocuments = uploadedDocuments;
              }

              // If current category is accepted and the
              const activeDocumentCategories = categories.filter(
                category =>
                  category.state !== 'Accepted' &&
                  category.state !== 'Pending' &&
                  !uploadedDocumentsNames
                    .map(uploadedDocumentName => category.requestedDocuments.includes(uploadedDocumentName))
                    .includes(true)
              );

              // If we have an investigationId then we need to set the investigations
              // otherwise it is an application request.
              if (requestedDocumentDetailEntry.investigationId) {
                participants[participantIndex].requestedCatagories.investigations = activeDocumentCategories;
              } else {
                participants[participantIndex].requestedCatagories.application = activeDocumentCategories;
              }
            });

            participants.some(
              participant =>
                participant.requestedCatagories.investigations.length > 0 ||
                participant.requestedCatagories.application.length > 0
            )
              ? setDocsRequired(true)
              : setDocsRequired(false);
          }
        }
      };
      useEffect(() => {
        getInvestigations().then(res => {
          setIsLoading(false);
        });
      }, []);

      if (isLoading)
        return (
          <div className="text-center">
            <Loading size="small" />
          </div>
        );

      return docsRequired ? <div className="neg text-center">Yes</div> : <div className="pos text-center">No</div>;
    },
  },
  {
    Header: 'Details',
    id: 'details',
    className: 'text-center',
    width: 0.5,
    Cell: ({ row }: Cell<any>) => {
      const application: CurrentItemApplicationModel = row.original;
      return (
        <div className="text-center">
          <ApplicationsActionCell {...application} />
        </div>
      );
    },
  },
];

export const ApplicationsTable = () => {
  const { dispatch } = useDispatcher();
  const { data, total, ...filterData } = useApplicationsData();
  const { page, pageSize, sortBy, sortByOrder } = filterData;
  const currentItemRequest = useStateSelector(s => s.private.applications.currentItem.application);
  const profile = useStateSelector(s => s.account.profile);
  const [isFetching, hasData] = useStateSelector(isFetchingApplications);
  const tableState = useBlockTable('adminApplicationsTable');

  const columnWidths = useTableColumnWidths(AdminApplicationsColumns as any, tableState.state.columnOrder);

  const visibleRepIds =
    profile && profile.current && profile.current.visibleRepIds ? profile.current.visibleRepIds : [];

  useEffect(() => {
    if (profile && profile.current && !isFetching) {
      dispatch(AdminApplicationActions.requestApplications({ ...filterData, repId: visibleRepIds }));
    }
  }, []);

  useEffect(() => {
    if (profile && profile.current && !isFetching) {
      const visibleRepIds = profile.current.visibleRepIds ? profile.current.visibleRepIds : [];
      dispatch(AdminApplicationActions.requestApplications({ ...filterData, repId: visibleRepIds }));

      const intervalId = setInterval(function() {
        dispatch(AdminApplicationActions.refreshApplications({ ...filterData, repId: visibleRepIds }));
      }, 60 * 3 * 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [profile]);

  const getCurrentSortBy = useCallback(
    (newDesc?: boolean) => {
      if (sortBy) {
        return { id: sortBy, desc: !_.isUndefined(newDesc) ? newDesc : sortByOrder === 'desc' };
      }
      return undefined;
    },
    [sortBy, sortByOrder]
  );

  const initialState = useMemo(() => {
    const currentSortBy = getCurrentSortBy();
    return {
      sortBy: currentSortBy ? [currentSortBy] : [],
      pageSize: _.isUndefined(pageSize) ? 10 : pageSize,
      pageIndex: page || 0,
      columnOrder: [],
    };
  }, [page, pageSize, getCurrentSortBy]);

  const pageCount = useMemo(() => {
    if (pageSize) {
      return Math.ceil(total / pageSize);
    }
    return 0;
  }, [pageSize, total]);

  const onSort = useCallback(
    (sort: any[]) => {
      const sortVal = _.first(sort) || getCurrentSortBy(sortByOrder === 'asc');
      if (sortVal) {
        const { id, desc } = sortVal;
        const sortData: Pick<ApplicationsRequest, 'sortBy' | 'sortByOrder'> = {
          sortBy: id,
          sortByOrder: desc === true ? 'desc' : 'asc',
        };
        dispatch(AdminApplicationActions.setAppSortBy(sortData));
      } else {
        dispatch(AdminApplicationActions.setAppSortBy({ sortBy: undefined, sortByOrder: undefined }));
      }
    },
    [getCurrentSortBy]
  );

  const onPage = useCallback((page: number) => {
    dispatch(AdminApplicationActions.setAppPage({ page }));
  }, []);

  const onPageSize = useCallback((pageSize: number) => {
    dispatch(AdminApplicationActions.setAppPageSize({ pageSize }));
  }, []);

  const getRowKey = useCallback((data: ApplicationModelWithKey) => `${data.entityKey}`, []);

  if (!_.isEmpty(currentItemRequest)) {
    return <AdminApplicationDetail />;
  }

  return (
    <>
      {isFetching && <Loading />}
      <useParentSizeContext.Provider>
        {!isFetching && (
          <>
            <ApplicationsFilters />
            {hasData && (
              <BlockTable
                tableKey="adminApplicationsTable"
                columns={AdminApplicationsColumns}
                numColumns={AdminApplicationsColumns.length}
                columnWidths={columnWidths}
                initialState={initialState}
                data={data}
                loaded={hasData}
                onSort={onSort}
                getRowKey={getRowKey}
                sortable
                hasPagination
                manualSortBy
                manualPagination
                pageCount={pageCount}
                onPage={onPage}
                onPageSize={onPageSize}
              />
            )}
          </>
        )}
        {!isFetching && !hasData && (
          <>
            <p>
              <i className="fal fa-user-alt-slash" /> No applications found
            </p>
          </>
        )}
      </useParentSizeContext.Provider>
    </>
  );
};
