import { AccountDetails, IBlockDataState, IBlockState } from '@tradingblock/types';
import { useBlockData } from '../../../components/blocks/BlockState';
import _ from 'lodash';
import { OutputSelector } from 'reselect';
import { useMemo } from 'react';
export type AccountManagementTab =
  | 'clientAcc'
  | 'clientFin'
  | 'clientDiscl'
  | 'clientPref'
  | 'clientBeneficiaries'
  | 'security';
export const AccountManagementConstants: {
  tabs: Record<AccountManagementTab, AccountManagementTab>;
} = {
  tabs: {
    clientAcc: 'clientAcc',
    clientFin: 'clientFin',
    clientDiscl: 'clientDiscl',
    clientPref: 'clientPref',
    clientBeneficiaries: 'clientBeneficiaries',
    security: 'security',
  },
};
export interface AccountManagementDataState extends IBlockDataState {
  account?: AccountDetails;
  tab?: AccountManagementTab;
}
export type AccountManagementState = IBlockState<AccountManagementDataState, {}>;
type Selector<T> = OutputSelector<AccountManagementDataState, T, (res: AccountManagementDataState) => T>;
type Transform<T> = (res: AccountManagementDataState) => T;
export function useAccountManagementData<T>(selector: Selector<T> | Transform<T>, defaultVal?: T) {
  const data = useBlockData<AccountManagementDataState>();
  const res = selector(data);
  return useMemo(() => (!_.isUndefined(defaultVal) && _.isNil(res) ? defaultVal : res), [defaultVal, res]);
}
export function isAccountManagementTab(tabVal: AccountManagementTab | string): tabVal is AccountManagementTab {
  return _.map(AccountManagementConstants.tabs, (v, k) => k).includes(tabVal);
}
