import _ from 'lodash';

import { OrderAction, ProfitLossInfoMap, SymbolProfitLossInfo, Order } from '@tradingblock/types';

import { QuotesState } from '../../state';

import { PositionDTO } from '@tradingblock/api';
import { OrderLegWithOrderData } from './types';

export const utilities = {
  getNetQuantityChange: (legs: OrderLegWithOrderData[]) =>
    _(legs)
      .map(l => (l.Action === OrderAction.Buy ? l.LegFillQuantity || 0 : -1 * (l.LegFillQuantity || 0)))
      .sum() || 0,
  toArray: (arg: ProfitLossInfoMap | SymbolProfitLossInfo[], type: string) => (_.isArray(arg) ? arg : _.map(arg, (v, k) => ({ ...v, id: k, pnlType: type }))),
  getQuoteForSymbol: (state: QuotesState) => (symbol: string) => _.find(state.quoteMetadata.data, q => q.Symbol === symbol),
  ordersToExecutedOrderLegs: (orders: Order[]) => _.flatMap(orders, o => o.Legs.map(l => ({ ...l, UnderlyingSymbol: o.UnderlyingSymbol, OrderId: o.OrderId, Date: o.Date }))).filter(ol => !_.isNil(ol.LegFillQuantity) && ol.LegFillQuantity !== 0),
  totalQuantityBySymbol: (positions: PositionDTO[], symbol: string) =>
    _(positions)
      .filter(p => p.Symbol === symbol)
      .map(p => p.OpenQuantity)
      .sum() || 0,
};
