import React, { FunctionComponent, useMemo } from 'react';
import _ from 'lodash';
import {
  OptionPairWithExpiration,
  SizeType,
  OrderAction,
  OptionType,
  AssetSymbol,
  BlockType,
} from '@tradingblock/types';
import {
  Column,
  FrozenHeaderTable,
  Number,
  DropdownWrapper,
  CellProps,
  EmptyBlockContent,
} from '@tradingblock/components';
import { OptionChainTableRow } from './components/OptionChainTableRow';
import {
  TableData,
  TableDateHeaderData,
  TableOptionPairData,
  OptionChainDataType,
  isOptionChainField,
  isTableOptionPairData,
  isOptionChainVolumeField,
} from './OptionChainTypes';
import { ExpirationOptionPair } from '../../types';
import { isTodayOrAfter, expirationValue } from '../../utilities/date';
import { RenderExpirationRow } from './components/RootExpirationRow';
import { useOptionChainData } from './state/OptionChainState';
import { useOptionChain } from './data/useOptionChain';
import { BidAskMemo } from './components/BidAsk';
import { OptionPairVolumeMemo } from './components/OptionPairVolume';
import { AddRemoveFavorite } from './AddRemoveFavorite';
import { useStateSelector } from '../../data/global/dataSelectors';
import { OptionChainGlobalSelectors } from './state/OptionChainSelector';
import { useOptionChainSettings } from './data/useOptionChainSettings';
import { ExpirationsFilter } from '../shared/ExpirationsFilter';
import { useBlockMetadata } from '../../components/blocks/BlockState';
import { blockSettings } from '../../data/global/selectors/settingSelectors';
import { generateNewBlock } from '../../utilities/block';
import { useDispatcher } from '../../data/global/hooks';

const StrikeField = 'Strike';
const dataOptionsWithoutStrikePriceCompare = {
  tdClass: (cell: any) => {
    if (cell.column.id === StrikeField) {
      return 'strike default-cursor';
    }
    const isCallOption = _.includes(cell.column.id, 'Call.');
    const showPosNeg = _.includes(cell.column.id, '.Change') || _.includes(cell.column.id, '.Delta');
    const changeClass = showPosNeg ? (cell.value > 0 ? 'pos' : cell.value < 0 ? 'neg' : '') : '';
    return `option-type-${isCallOption ? 'call' : 'put'} ${changeClass}`;
  },
};

function getCellOptionData<T extends TableOptionPairData>(original: T) {
  return { expiration: original.expiration, strike: original.Strike };
}
function getCellOccSymbol<T extends TableOptionPairData>(original: T, type: OptionType) {
  return type === OptionType.Call ? original.Call.Symbol : original.Put.Symbol;
}

const getColumnHeader = (column: string) => {
  if (column === 'ImpliedVolatility') {
    return 'IV';
  }
  if (column === 'OpenInterest') {
    return 'Open int.';
  }
  return column;
};
// columns for each option call/put in chain pair
// TODO: Restore optionColumns with Delta, ImpliedVolatility, and OpenInterest once backend calculates these values correctly
const optionColumns = [
  'Bid',
  'Ask',
  'Last',
  'Change',
  'Close',
  'Delta',
  'Theta',
  'Gamma',
  'ImpliedVolatility',
  'Volume',
];

const columns: any[] = [
  // call columns
  ..._.map(optionColumns, c => {
    return {
      Header: getColumnHeader(c),
      accessor: `Call.${c}`,
    };
  }),
  {
    Header: <div className="strike">{StrikeField}</div>,
    accessor: StrikeField,
  },
  // put columns
  ..._.map(optionColumns, c => ({
    Header: getColumnHeader(c),
    accessor: `Put.${c}`,
  })),
];
type ColumnWithWidth<D extends object = {}> = Column<D> & {
  width?: number;
};
export const OptionChainTable: FunctionComponent<{
  orderActionForBid: OrderAction;
  width: SizeType;
  symbol: AssetSymbol;
}> = ({ orderActionForBid, width, symbol }) => {
  const { blockId, groupId } = useBlockMetadata();
  const settings = useStateSelector(s => blockSettings.optionChain(s, blockId));

  const { weeklyExpirations, monthlyExpirations, quarterlyExpirations, yearlyExpirations } = settings;

  const expandedExpirations = useOptionChainData(s => s.expandedExpirations);
  const { optionchain } = useOptionChain(symbol.symbol);
  const { current } = useOptionChainSettings();
  const { dispatcher } = useDispatcher();

  const filteredOptionChain = useMemo(() => {
    // filter out expirations that are not selected based on expirationFreqCode
    const filtered = _.filter(optionchain, o => {
      const expirationFreqCode = o.expiration.ExpirationFreqCode;

      const isWeekly =
        expirationFreqCode === 'W' ||
        expirationFreqCode === 'MW' ||
        expirationFreqCode === 'WW' ||
        (expirationFreqCode && expirationFreqCode.includes('Week')) ||
        (expirationFreqCode && expirationFreqCode.includes('W'));

      const isMonthly =
        expirationFreqCode === 'M' ||
        expirationFreqCode === 'MM' ||
        expirationFreqCode === 'Monthly' ||
        expirationFreqCode === 'VX' ||
        expirationFreqCode === 'ME';
      const isQuarterly = expirationFreqCode === 'QuarterlyEOM';
      const isYearly = expirationFreqCode === 'Yearly';

      return (
        (isWeekly && weeklyExpirations) ||
        (isMonthly && monthlyExpirations) ||
        (isQuarterly && quarterlyExpirations) ||
        (isYearly && yearlyExpirations)
      );
    });
    return filtered;
  }, [optionchain, weeklyExpirations, monthlyExpirations, quarterlyExpirations, yearlyExpirations]);

  const price = useStateSelector(s =>
    OptionChainGlobalSelectors.getBidPriceOrLastPriceOrUndefined(s, { symbol: symbol ? symbol.symbol : '' })
  );

  const onFavoriteAdd = () => {
    const favoritesBlock = generateNewBlock(BlockType.Favorites);
    dispatcher.block.addOrUpdateBlock({ ...favoritesBlock, groupId }, true, { blockType: BlockType.Favorites });
  };

  const columnsDisplayed: ColumnWithWidth<any>[] = useMemo(() => {
    // hide some columns based on width...
    const hiddenColumns: string[] = [
      // hide 'greeks' columns if not large view
      ...(width !== SizeType.lg ? ['Delta', 'ImpliedVolatility', 'Theta', 'Gamma', 'Volume'] : []),
      // hide Last/Change columns if not med/large view
      ...(width !== SizeType.lg && width !== SizeType.md ? ['Last', 'Change', 'Close'] : []),
    ];

    // filter out hidden columns...
    let cols = _.filter(columns, c => !_.includes(hiddenColumns, c.accessor.substr(c.accessor.lastIndexOf('.') + 1)));
    cols = cols.filter(column => {
      const header = getColumnHeader(column.Header);
      if (column.accessor === 'Strike') {
        return column;
      }
      return current[header as keyof typeof useOptionChainSettings];
    });
    // include Cell prop to display values as order dropdowns
    return _.map(cols, c => ({
      ...c,
      width: 1,
      Cell: ({ cell: { value, row } }: CellProps<OptionChainDataType>) => {
        const fieldName = c.accessor;
        const action = fieldName.substr(fieldName.indexOf('.') + 1) as string;
        const optionType: OptionType =
          fieldName.replace('.' + action, '') === 'Call' ? OptionType.Call : OptionType.Put;
        // for change cell, prefix positive values with plus sign (negative values will be natively rendered with minus)
        const prefix = _.includes(fieldName, '.Change') && value > 0 ? '+' : '';
        const integer = _.includes(fieldName, '.Volume') || _.includes(fieldName, '.OpenInterest');
        const percent = _.includes(fieldName, '.ImpliedVolatility');
        const isStrikeField = fieldName === StrikeField;
        const greeks =
          _.includes(fieldName, '.Delta') ||
          _.includes(fieldName, '.Theta') ||
          _.includes(fieldName, '.Gamma') ||
          _.includes(fieldName, '.ImpliedVolatility');
        const cell = fieldName.includes('.Gamma') ? (
          <>{value.toFixed(3)}</>
        ) : (
          <Number
            value={value}
            prefix={prefix}
            format
            integer={integer}
            greeks={greeks}
            percent={percent}
            isStrikeField={isStrikeField}
          />
        );
        // only show dropdowns for Bid/Ask columns
        const pairData = row.original;
        const isMiddleColumn = fieldName === StrikeField;
        if (isOptionChainField(action) && symbol && isTableOptionPairData(pairData)) {
          const optionData = getCellOptionData(pairData);
          const cellSym = getCellOccSymbol(pairData, optionType);
          return (
            <BidAskMemo
              symbol={cellSym}
              optionType={optionType}
              field={action}
              orderActionForBid={orderActionForBid}
              {...optionData}
              expiration={optionData.expiration}
            />
          );
        } else if (isOptionChainVolumeField(action) && symbol && isTableOptionPairData(pairData)) {
          const optionData = getCellOptionData(pairData);
          return <OptionPairVolumeMemo optionType={optionType} {...optionData} />;
        } else if (isTableOptionPairData(pairData) && !isMiddleColumn) {
          if (symbol !== undefined) {
            const cellAssetSymbol = { ...symbol, symbol: getCellOccSymbol(pairData, optionType) };
            return (
              <DropdownWrapper
                direction="down"
                content={<AddRemoveFavorite onFavoriteAdd={onFavoriteAdd} symbol={cellAssetSymbol} />}
              >
                {cell}
              </DropdownWrapper>
            );
          }
        } else {
          return cell;
        }
        return cell;
      },
    }));
  }, [width, symbol, orderActionForBid, current, expandedExpirations && expandedExpirations[0]]);
  const pairs: OptionPairWithExpiration[] = useMemo(() => {
    if (expandedExpirations && expandedExpirations.length > 2) expandedExpirations.shift();
    const simpleExpires = _.map(expandedExpirations, ee => expirationValue(ee));
    return _(filteredOptionChain)
      .flatMap(o =>
        simpleExpires.includes(expirationValue(o.expiration))
          ? _.map(o.pairs, p => ({ ...p, expiration: o.expiration }))
          : []
      )
      .value();
  }, [filteredOptionChain, expandedExpirations]);
  const dataDisplayed = useMemo(() => {
    // build array of rows for table
    const defaultTableOptionPairData: TableData = [];

    const results = _.reduce(
      filteredOptionChain,
      (rows: TableData, d: ExpirationOptionPair) => {
        if (isTodayOrAfter(d.expiration.date)) {
          // include header row for each expiration
          // eslint-disable-next-line react/display-name
          const initialPairValue: TableDateHeaderData = {
            ...d,
            display: {
              renderRow: (row: any) => <RenderExpirationRow row={row} />,
            },
          };
          let rowValues: TableData = [initialPairValue];
          // include rows of chain pairs if expiration is expanded
          const matchingExpanded = _.find(
            expandedExpirations,
            e => expirationValue(e) === expirationValue(d.expiration)
          );
          if (matchingExpanded) {
            const matchingPairs = _.filter(
              pairs,
              p =>
                expirationValue(p.expiration) === expirationValue(d.expiration) &&
                p.symbol === matchingExpanded.rootSymbol
            );
            const expandedRows: TableOptionPairData[] = _.map(matchingPairs, x => ({
              ...x,
              expiration: d.expiration,
              display: dataOptionsWithoutStrikePriceCompare,
            }));
            rowValues = [...rowValues, ...expandedRows];

            if (price) {
              const closestStrike = _.minBy(expandedRows, r => Math.abs(r.Strike - price));
              // set the isClosestToPrice property to true for the strike closest to the price
              if (closestStrike) {
                const closestStrikeIndex = _.findIndex(expandedRows, r => r.Strike === closestStrike.Strike);
                if (closestStrikeIndex > -1) {
                  expandedRows[closestStrikeIndex].isClosestToPrice = true;
                }
              }
            }
          }
          return [...rows, ...rowValues];
        }

        return rows;
      },
      defaultTableOptionPairData
    );
    return _.orderBy(results, v => expirationValue(v.expiration));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredOptionChain, expandedExpirations, dataOptionsWithoutStrikePriceCompare, pairs]);
  const show = _.size(dataDisplayed) > 0;
  const columnWidths = useMemo(() => {
    const totalWidth = _.sumBy(columnsDisplayed, c => c.width || 1);
    return _.map(columnsDisplayed, c => {
      const proportion = (c.width || 1) / totalWidth;
      return `${proportion * 100}%`;
    });
  }, [columnsDisplayed]);

  return (
    <>
      <ExpirationsFilter />
      {show && (
        <FrozenHeaderTable
          columnWidths={columnWidths}
          RowComponent={OptionChainTableRow}
          className="chain"
          tableKey="optionsChain"
          responsiveDisabled={true}
          columns={columnsDisplayed}
          data={dataDisplayed}
        />
      )}
      {!show && (
        <EmptyBlockContent title="No Options Available">
          <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <i className="fas fa-2x fa-search-dollar" /> No options are available given the current filters and symbol.
            Try adjusting your filters to see more.
          </span>
        </EmptyBlockContent>
      )}
    </>
  );
};
