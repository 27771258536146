import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Carousel, CarouselResponsiveOptions } from '@tradingblock/components';
import { DashboardInfo, DashboardConfiguration } from '@tradingblock/types';
import { useDefaultDashboards } from './hooks/useDefaultDashboards';
import { DashboardItem } from './drawer/DashboardItem';
import { EditDashboardModal } from './drawer/EditDashboardModal';
import { dashboardsSelectors } from '../../data/global/dataSelectors';

export const DashboardPresetCarousel: React.FunctionComponent<{ onSelectBoard: (board: DashboardConfiguration) => void }> = ({ onSelectBoard }) => {
  const [defaultDashboards, isFetching] = useDefaultDashboards();
  const [selectedBoard, setSelectedBoard] = useState<DashboardConfiguration | undefined>();
  const dashboardTitles = useSelector(dashboardsSelectors.dashboardTitles);
  const onBoardSelected = useCallback(
    (board: DashboardConfiguration) => {
      if (dashboardTitles && dashboardTitles.includes(board.title.toLocaleLowerCase().trim())) {
        setSelectedBoard(board);
      } else {
        onSelectBoard(board);
      }
    },
    [dashboardTitles, onSelectBoard]
  );

  const onClose = useCallback(() => {
    setSelectedBoard(undefined);
  }, []);
  const onSave = useCallback(
    (board: DashboardInfo) => {
      const saveValue = selectedBoard ? { ...selectedBoard, title: board.title } : undefined;
      setSelectedBoard(undefined);
      if (saveValue) {
        onSelectBoard(saveValue);
      }
    },
    [onSelectBoard, selectedBoard]
  );
  return (
    <>
      <h2 className="txt-sm caps">
        <strong>Starter boards</strong>
      </h2>
      <div className="boards">
        {isFetching === true && 'Loading...'}
        {isFetching === false && (
          <>
            <Carousel infinite={false} speed={500} slidesToShow={5} slidesToScroll={1} initialSlide={0} responsive={CarouselResponsiveOptions}>
              {defaultDashboards.map(d => (
                <DashboardItem key={d.dashboardId} dashboard={d} onSelectBoard={() => onBoardSelected(d)} />
              ))}
            </Carousel>
            {selectedBoard && <EditDashboardModal header={'Add board'} dashboard={selectedBoard} show={true} onSave={onSave} onClose={onClose} />}
          </>
        )}
      </div>
    </>
  );
};
