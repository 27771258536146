import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { Legs, OrderAction } from '@tradingblock/types';
import { calculateOrderPrice, orderLegOccSymbol } from '@tradingblock/api';
import { OrderSelectors } from '../state/OrderSelector';
import { OrderState } from '../state/OrderState';
import { createDeepSelector, createDeepArraySelector } from '../../../data/selectors';
import { GCD } from '../../../utilities/data';
import { LegQuoteState, Quotebidask } from './orderTypes';

export const getOrderLegPrices = createCachedSelector(
  (state: LegQuoteState) => state.legs,
  (state: LegQuoteState) => state.quotes,
  ({ orderAction, strategy, legs }: LegQuoteState) =>
    OrderSelectors.actionForPrice({ strategy, action: orderAction, legs }),
  (state: LegQuoteState) => GCD(_.map(state.legs, l => l.SpreadRatio || 1)) || 1,
  (state: LegQuoteState) => state.strategy,
  (legs: Legs, quotes: Quotebidask, action: OrderAction, gcd: number, strategy) => {
    return calculateOrderPrice(legs, quotes, action, gcd, strategy);
  }
)((s: LegQuoteState, blockId: string) => blockId || 'UNKNOWN', { selectorCreator: createDeepSelector });
// export const orderLegPrices = getOrderLegPrices({ legs, quotes: quotes, orderType, orderAction, strategy });

export const getOrderQuotes = createCachedSelector(
  (s: OrderState, blockId: string) => _.values(s.quotes),
  quotes => quotes
)((_, blockId: string) => `orderquotes-${blockId}`);

const orderLegSymbolSelector = createDeepArraySelector(
  (s: OrderState) => s.legs,
  (s: OrderState) => s.symbol,
  (legs, symbol) => {
    if (symbol === undefined) {
      return [];
    }

    const legSymbols = legs
      ? _.reduce(
          legs,
          (acc: string[], l) => {
            const legSymbol = orderLegOccSymbol(symbol, l);
            if (legSymbol === undefined) {
              return acc;
            }
            return _.uniq([...acc, legSymbol]).sort();
          },
          []
        )
      : [];
    return legSymbols;
  }
);

export const orderBlockSymbols = createCachedSelector(
  (s: OrderState, blockId: string) => (s.symbol ? s.symbol.symbol : undefined),
  (s: OrderState) => (s.symbol ? s.symbol.underlyingSymbol : undefined),
  orderLegSymbolSelector,
  (symbol, underlyingSymbol, legSymbols) => {
    let symbolValues: string[] = [];
    if (symbol) {
      symbolValues = [...symbolValues, symbol];
    }
    if (underlyingSymbol) {
      symbolValues = [...symbolValues, underlyingSymbol];
    }
    if (legSymbols && legSymbols.length > 0) {
      symbolValues = [...symbolValues, ...legSymbols];
    }

    return _.uniq(symbolValues);
  }
)((s, blockId: string) => `${blockId}-symbols`);
