import React, { useCallback, useMemo } from 'react';
import { DropdownItemType, ToggleButton } from '@tradingblock/components';
import { PositionEffect, OrderAction } from '@tradingblock/types';
import { OrderState, useOrderData } from '../../state/OrderState';
import { LegSelector } from '../../state/OrderLegSelector';
import { useOrderActions } from '../../state/useOrderActions';
import { OrderSelectors } from '../../state/OrderSelector';
import { useBlockActionDispatchAndGenericActions, useBlockData } from '../../../../components/blocks/BlockState';
import { Actions, OrderActions } from '../../state/OrderActions';
import { getType } from 'typesafe-actions';
import { useOrderPrice } from '../../hooks/useOrderPrice';

export interface LegAction extends DropdownItemType {
  type: PositionEffect;
  orderType: OrderAction;
}
// const legActions: LegAction[] = [{ name: 'buyopen', value: 'buy', type: PositionEffect.Open, label: 'Buy to open', orderType: OrderAction.Buy }, { name: 'buyclose', value: 'buy', type: PositionEffect.Close, label: 'Buy to close', orderType: OrderAction.Buy }, { name: 'sellopen', value: 'sell', type: PositionEffect.Open, label: 'Sell to open', orderType: OrderAction.Sell }, { name: 'sellclose', value: 'sell', type: PositionEffect.Close, label: 'Sell to close', orderType: OrderAction.Sell }];

export const LegAction: React.FC<{ id: string }> = ({ id }) => {
  const isCustomStrategyOrUndefined = useOrderData(OrderSelectors.isCustomStrategyOrUndefined);
  const { setLegAction } = useOrderActions();

  const setOrderType = useCallback(
    (ot: OrderAction) => {
      if (isCustomStrategyOrUndefined) {
        setLegAction(id, ot);
      } else {
        setLegAction(id);
      }
    },
    [id, isCustomStrategyOrUndefined, setLegAction]
  );

  return <LegActionPicker id={id} setOrderType={setOrderType} />;
};

const LegActionPicker: React.FC<{ id: string; setOrderType: (act: OrderAction) => void }> = ({ id, setOrderType }) => {
  const [{}, dispatch] = useBlockActionDispatchAndGenericActions<OrderState, OrderActions>();
  const {mid} = useOrderPrice();

  const action = LegSelector.Action(useBlockData<OrderState>(), id);
  const setActionValue = useCallback(
    (isActive: boolean) => {
      //setOrderType(isActive ? OrderAction.Buy : OrderAction.Sell);
      setOrderType(action === OrderAction.Buy ? OrderAction.Sell : OrderAction.Buy);
      dispatch({ type: getType(Actions.setPrice), payload: { price: undefined, updateCreditOrDebit: true, mid: mid } })
      dispatch({ type: getType(Actions.setStopPrice), payload: { price: undefined, updateCreditOrDebit: false } })
    },
    [setOrderType, action]
  );
  const actionValue = useMemo(() => (action === OrderAction.Buy ? true : false), [action]);
  //const buttonClass = useMemo(() => (action.orderType === OrderAction.Sell ? `btn-sell` : `btn-buy`), [action.orderType]);

  return (
    <ToggleButton
      on="Buy"
      off="Sell"
      onstyle="buy"
      offstyle="sell"
      width="100%"
      disabled={false}
      onClick={setActionValue}
      active={actionValue}
    />
    // <SplitDropdown split={true} buttonclassName={`btn-tall ${buttonClass} text-left`} className={'btn-block'} value={action} setValue={setActionValue} values={legActions}>
    //   <span className="btn-title">{_.camelCase(OrderAction[action.orderType])}</span>
    //   <span className="btn-subtitle">{`To ${PositionEffect[action.type]}`}</span>
    // </SplitDropdown>
  );
};
