import { getType } from 'typesafe-actions';
import _ from 'lodash';
import {
  BlockState,
  BlockGroupState,
  BlockGroupOrderInfo,
  BlockConfigurationWithSettings,
  BlockTypeSettings,
  BlockTypeStrings,
  BlockType,
} from '@tradingblock/types';
import { SetBlockIds, newId, getBlockTypeName } from '@tradingblock/components';
import { InitialState } from '../initialState';
import { RootAction, GridActions, BlockActions, LayoutActions, BlockGroupActions } from '../actions';
import { canBlockBeAddedToGroup, isOrderBlock, isChartBlock } from '../../../utilities/block';
import { groupHasData } from '../utilities';
import {
  cleanBlock,
  getBlockAndGroupIdForUpdate,
  addBlockToBlocks,
  blockSettingsOrDefault,
} from '../utilities/blockStateUtilities';
import { SettingsActions } from '../actions/SettingsActions';
import { useBlockSettings } from '../../../blocks/useBlockSettings';
import * as Sentry from '@sentry/react';

const updateGroup = (
  groups: BlockGroupState[],
  groupId: string,
  update: (group: BlockGroupState) => BlockGroupState
) => {
  return groups.map(group => {
    if (group.groupId === groupId) {
      return update(group);
    }
    return group;
  });
};

const updateOrAddGroupWithBlockInfo = (
  groups: BlockGroupState[],
  groupId: string,
  block: BlockConfigurationWithSettings,
  preserveGroupOrderData: boolean = false
) => {
  const groupExists = _.some(groups, g => g.groupId === groupId);
  const allGroups = groupExists ? groups : [...(groups || []), { groupId }];
  if (isOrderBlock(block)) {
    const symbol = block.data.symbol;
    const partialBlockData = _.omit(block.data, ['legs', 'leg']);
    const blockOrder: BlockGroupOrderInfo = {
      ...partialBlockData,
      leg: _.first(_.values(block.data.legs)),
      strategy: block.data.strategy,
      action: block.data.action,
    };
    return updateGroup(allGroups, groupId, group => ({ ...group, order: blockOrder, symbol }));
  } else if (isChartBlock(block)) {
    const symbol = block.data.symbol;
    return updateGroup(allGroups, groupId, group => ({ ...group, symbol }));
  }
  return updateGroup(allGroups, groupId, group => ({
    ...group,
    order: preserveGroupOrderData ? group.order : undefined,
  }));
};

const cleanGroups = (blocks: BlockConfigurationWithSettings[], allGroups: BlockGroupState[]) => {
  const filteredGroups: BlockGroupState[] = _(blocks)
    .map(b => b.groupId)
    .uniq()
    .reduce((groups: BlockGroupState[], blockGroupId: string): BlockGroupState[] => {
      const matchingGroup = _.find(allGroups, g => g.groupId === blockGroupId);
      if (matchingGroup) {
        return [...groups, matchingGroup];
      } else {
        return groups;
      }
    }, []);
  return filteredGroups;
};

export const blocks = (state = InitialState.blocks, action: RootAction): BlockState => {
  switch (action.type) {
    case getType(SettingsActions.setBlockSetting): {
      const { blockId, blockSettings, blockType, globalSettings } = action.payload;
      let blocks = state.blocks;
      if (blockId && blockSettings) {
        blocks = _.map(state.blocks, b => (b.blockId === blockId ? { ...b, settings: blockSettings } : b));
      }

      let blockTypeSettings = state.blockTypeSettings;
      if (globalSettings && blockType) {
        const globalBlockSettings = { ...(state.blockTypeSettings[blockType] || {}), ...globalSettings };
        blockTypeSettings = { ...state.blockTypeSettings, [blockType]: globalBlockSettings };
      }

      return {
        ...state,
        blocks,
        blockTypeSettings,
      };
    }
    case getType(BlockGroupActions.setGroupSymbol): {
      const symbol = action.payload.symbol;
      return {
        ...state,
        groups: updateGroup(state.groups, action.payload.groupId, group => {
          const groupState: BlockGroupState = { ...group, symbol, order: group.order };
          return groupState;
        }),
      };
    }
    case getType(BlockGroupActions.SetSymbolAndReset):
      return {
        ...state,
        groups: updateGroup(state.groups, action.payload.groupId, group => ({
          ...group,
          order: { leg: action.payload.leg },
          symbol: action.payload.symbol,
        })),
      };

    case getType(BlockGroupActions.addGroupLeg):
      return {
        ...state,
        groups: updateGroup(state.groups, action.payload.groupId, group => ({
          ...group,
          order: { leg: action.payload.leg },
        })),
      };

    case getType(BlockActions.linkBlock): {
      const { blockId: targetBlockId, groupId: targetGroupId } = action.payload;
      const sourceBlockInfo = state.linkSourceBlock;
      if (!sourceBlockInfo) {
        return state;
      }
      const { blockId: sourceBlockId, groupId: sourceGroupId } = sourceBlockInfo;
      const sourceBlock = _.find(state.blocks, b => b.blockId === sourceBlockId);
      const targetBlock = _.find(state.blocks, b => b.blockId === targetBlockId);
      const sourceGroup = sourceBlock ? _.find(state.groups, g => g.groupId === sourceBlock.groupId) : undefined;
      const targetGroup = targetBlock ? _.find(state.groups, g => g.groupId === targetBlock.groupId) : undefined;
      const targetHasGroupMembers =
        targetBlock && _.some(state.blocks, b => b.blockId !== targetBlockId && b.groupId === targetBlock.groupId);
      const sourceHasGroupMembers = _.some(
        state.blocks,
        b => b.blockId !== sourceBlockId && b.groupId === sourceGroupId
      );

      // const onlySourceHasData = sourceGroup && groupHasData(sourceGroup) && (! targetGroup || !groupHasData(targetGroup));
      const onlyTargetHasData =
        targetGroup && groupHasData(targetGroup) && (!sourceGroup || !groupHasData(sourceGroup));
      // const addTargetToSource = (sourceHasGroupMembers && !targetHasGroupMembers) || onlySourceHasData;
      const addSourceToTarget = onlyTargetHasData || (!sourceHasGroupMembers && targetHasGroupMembers);

      let linkError: string | undefined = undefined;
      if (!addSourceToTarget && targetBlock && !canBlockBeAddedToGroup(targetBlock, sourceGroupId, state.blocks)) {
        linkError = `A ${getBlockTypeName(targetBlock.type)} block is already included in the current group`;
      } else if (
        addSourceToTarget &&
        sourceBlock &&
        !canBlockBeAddedToGroup(sourceBlock, targetGroupId, state.blocks)
      ) {
        linkError = `A ${getBlockTypeName(sourceBlock.type)} block is already included in the selected group`;
      }
      const updatedBlocks = _.map(state.blocks, (b: BlockConfigurationWithSettings) => {
        if (!linkError && !addSourceToTarget && b.blockId === targetBlockId) {
          return {
            ...b,
            groupId: sourceGroupId,
          };
        }
        if (!linkError && addSourceToTarget && targetBlock && b.blockId === sourceBlockId) {
          return {
            ...b,
            groupId: targetBlock.groupId,
          };
        }
        return b;
      });
      // const updatedGroupIds = _(updatedBlocks).map(b => b.groupId).value();
      // const updatedGroups = _.filter(state.groups, g => updatedGroupIds.includes(g.groupId));
      return {
        ...state,
        linkSourceBlock: undefined,
        linkTargetBlock: undefined,
        linking: false,
        linkError,
        linkErroredAt: linkError ? new Date() : undefined,
        blocks: updatedBlocks,
        groups: cleanGroups(updatedBlocks, state.groups),
      };
    }
    case getType(BlockActions.setColoredLink): {
      const {
        linkColor,
        blockData: { groupId },
      } = action.payload;

      const groupIndex = state.groups.findIndex(g => g.groupId === groupId);

      if (groupIndex !== -1) {
        const groups = [...state.groups];
        groups[groupIndex] = {
          ...groups[groupIndex],
          linkedColor: linkColor,
        };
        return {
          ...state,
          groups: [...groups],
        };
      } else {
        console.warn(`Tried to set color for a group ${groupId} that does not exist`);
      }
      return state;
    }
    case getType(BlockActions.unlinkBlock): {
      const { blockId } = action.payload;
      // assign to a new groupId to unlink
      const groupId = newId();
      const blockToUpdate = _.find(state.blocks, b => b.blockId === blockId);
      const resetHighlight =
        !blockToUpdate || _.size(state.blocks.filter(b => b.groupId === blockToUpdate.groupId)) <= 2;
      const previousGroup = blockToUpdate ? _.find(state.groups, g => g.groupId === blockToUpdate.groupId) : undefined;
      const groups = updateGroup([...state.groups, { groupId }], groupId, group => ({ ...previousGroup, ...group }));
      return {
        ...state,
        blocks: state.blocks.map((b: BlockConfigurationWithSettings) => {
          if (b.blockId === blockId) {
            return {
              ...b,
              groupId,
            };
          }
          return b;
        }),
        groups: groups,
        highlightGroupId: resetHighlight ? undefined : state.highlightGroupId,
      };
    }
    case getType(BlockActions.highlightGroup): {
      return {
        ...state,
        highlightGroupId: action.payload.groupId,
        highlightGroupAt: action.payload.highlightAt,
      };
    }
    case getType(BlockActions.addQuoteSubscription):
    case getType(BlockActions.setQuoteSubscription): {
      const isAdd = action.type === getType(BlockActions.addQuoteSubscription);
      const { blockId, symbols } = action.payload;
      return {
        ...state,
        blocks: state.blocks.map((b: BlockConfigurationWithSettings) => {
          if (b.blockId === blockId) {
            const existingValues = b.subscriptions && b.subscriptions.quotes ? b.subscriptions.quotes : [];
            const allQuoteValues = isAdd ? [...existingValues, ...symbols] : symbols;
            const quotes = _.uniq(allQuoteValues).sort();
            return {
              ...b,
              subscriptions: {
                quotes,
              },
            };
          }
          return b;
        }),
      };
    }
    case getType(GridActions.toggleLocked):
      const { locked, showTooltip } = action.payload;
      return {
        ...state,
        showTooltip: showTooltip,
        locked: locked === undefined ? !state.locked : locked,
      };
    case getType(GridActions.toggleLinking): {
      const { enabled, sourceBlock, targetBlock } = action.payload;
      return {
        ...state,
        linking: enabled === undefined ? !state.linking : enabled,
        linkSourceBlock: sourceBlock,
        linkTargetBlock: targetBlock,
      };
    }

    case getType(BlockActions.setBlocks):
      return { ...state, blocks: SetBlockIds(action.payload.blocks) };
    case getType(BlockActions.addOrUpdateBlock): {
      const { block, addedAt, preserveGroupOrderData, replaceFirstMatch } = action.payload;

      if (replaceFirstMatch) {
        const matchingBlockInfo = getBlockAndGroupIdForUpdate(state, action);
        if (matchingBlockInfo) {
          const blockid = matchingBlockInfo.blockId;
          // use new groupId if passed in payload; fallback to groupId of replaced block
          const blockToReplaceGroupId = matchingBlockInfo.groupId;

          const blocksToReplace = _.reduce(
            state.blocks,
            (acc: BlockConfigurationWithSettings[], b) => {
              const blockSettings =
                block.type === BlockType.Order
                  ? {
                      ...b.settings,
                      weeklyExpirations: true,
                      monthlyExpirations: true,
                      quarterlyExpirations: true,
                      yearlyExpirations: true,
                    }
                  : b.settings || {};
              if (matchingBlockInfo && b.blockId === blockid) {
                return [
                  ...acc,
                  {
                    ...cleanBlock(block),
                    groupId: blockToReplaceGroupId,
                    blockId: blockid,
                    settings: blockSettings,
                  },
                ];
              }
              return [...acc, cleanBlock(b)];
            },
            []
          );

          // clear out any order data in group state if not preserving
          const groupsToReplace = updateOrAddGroupWithBlockInfo(
            state.groups,
            blockToReplaceGroupId,
            block,
            preserveGroupOrderData
          );

          return {
            ...state,
            highlightBlockIds: [blockid],
            highlightTimeoutMs: 2000,
            highlightedAt: addedAt,
            blocks: blocksToReplace,
            groups: groupsToReplace,
          };
        }
      }

      const groupId = block.groupId && block.groupId.length > 0 ? block.groupId : newId();
      const blockWithSettings = {
        ...block,
        settings: blockSettingsOrDefault(state, block),
      };
      const updatedGroups = updateOrAddGroupWithBlockInfo(
        state.groups,
        groupId,
        blockWithSettings,
        preserveGroupOrderData
      );
      return {
        ...state,
        highlightBlockIds: [blockWithSettings.blockId],
        highlightTimeoutMs: 2000,
        highlightedAt: addedAt,
        blocks: state.blocks.concat(SetBlockIds([cleanBlock(blockWithSettings)], groupId)),
        groups: updatedGroups,
      };
    }
    case getType(BlockActions.addBlock): {
      const { block } = action.payload;
      const blockWithSettings = {
        ...block,
        settings: blockSettingsOrDefault(state, block),
      };
      const groupId = block.groupId || newId();
      const updatedGroups = updateOrAddGroupWithBlockInfo(state.groups, groupId, blockWithSettings);
      return {
        ...state,
        blocks: addBlockToBlocks(state, blockWithSettings, groupId),
        groups: updatedGroups,
      };
    }

    case getType(BlockActions.highlightBlocks): {
      const { blockType, highlightedAt, options } = action.payload;
      const { allMatches, timeoutMs } = options || { allMatches: undefined, timeoutMs: undefined };
      const blocksMatchingType = _.filter(state.blocks, b => b.type === blockType);
      const unlinkedBlocksMatchingType = _.filter(
        blocksMatchingType,
        b => !_.some(state.blocks, bb => b.blockId !== bb.blockId && b.groupId === bb.groupId)
      );
      const blocksToHighlight = _.isEmpty(unlinkedBlocksMatchingType) ? blocksMatchingType : unlinkedBlocksMatchingType;
      const highlightBlockIds = _.take(
        _.map(blocksToHighlight, b => b.blockId),
        !allMatches ? 1 : blocksToHighlight.length
      );
      return {
        ...state,
        highlightBlockIds,
        highlightedAt,
        highlightTimeoutMs: timeoutMs,
      };
    }
    case getType(BlockActions.clearHighlightedBlocks): {
      return {
        ...state,
        highlightBlockIds: undefined,
        highlightedAt: undefined,
        highlightTimeoutMs: undefined,
      };
    }

    case getType(BlockActions.updateBlock):
      return {
        ...state,
        blocks: state.blocks.map(b => {
          if (b.blockId === action.payload.blockId) {
            return {
              ...b,
              ...action.payload.blockData,
            };
          }
          return b;
        }),
      };
    case getType(BlockActions.removeBlock):
      const blockToRemove = _.find(state.blocks, b => b.blockId === action.payload);
      const resetHighlight =
        !blockToRemove || _.size(state.blocks.filter(b => b.groupId === blockToRemove.groupId)) <= 2;
      const blocksWithoutDeleted = state.blocks.filter(b => b.blockId !== action.payload);
      return {
        ...state,
        blocks: blocksWithoutDeleted,
        highlightGroupId: resetHighlight ? undefined : state.highlightGroupId,
        groups: cleanGroups(blocksWithoutDeleted, state.groups),
      };
    case getType(BlockActions.requestBlocks):
      return {
        ...state,
        isFetchingInitial: true,
        blocks: [],
      };
    case getType(BlockActions.noBlocks): {
      return {
        ...state,
        isFetchingInitial: false,
      };
    }
    case getType(BlockActions.receiveBlocks):
      const { payload } = action;
      const { blockTypeSettings } = payload;
      const allTypeKeys: BlockTypeStrings[] = [
        ..._.keys(blockTypeSettings),
        ..._.keys(state.blockTypeSettings),
      ] as BlockTypeStrings[];
      const updatedBlockTypeSettings = _.reduce(
        allTypeKeys,
        (acc: BlockTypeSettings, key: BlockTypeStrings): BlockTypeSettings => {
          const newVal = _.get(blockTypeSettings, key, undefined);
          const oldVal = _.get(state.blockTypeSettings, key, undefined);
          if (oldVal && newVal) {
            return {
              ...acc,
              [key]: {
                ...oldVal,
                ...newVal,
              },
            };
          } else if (oldVal) {
            return {
              ...acc,
              [key]: oldVal,
            };
          } else {
            return {
              ...acc,
              [key]: newVal,
            };
          }
        },
        {}
      );

      const blocks = action.payload ? SetBlockIds(action.payload.blocks) : state.blocks;
      if (blocks.length === 0) {
        // rg4js('send', {
        //   error: new Error('Receive empty blocks from the database'),
        //   customData: {
        //     action,
        //     state,
        //   },
        // });
        Sentry.captureException(new Error('Receive empty blocks from the database'), {
          extra: {
            action,
            state,
          },
        });
      }

      return {
        ...state,
        isFetchingInitial: false,
        blocks: blocks,
        groups: action.payload ? action.payload.groups : state.groups,
        blockTypeSettings: updatedBlockTypeSettings,
      };
    default:
      return state;
  }
};
