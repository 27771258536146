import { Dispatch } from 'redux';
import _ from 'lodash';
import dayjs from 'dayjs';
import { ActionMeta } from '@tradingblock/components';
import { SymbolQuote, PositionDTO } from '@tradingblock/api';
import {
  OrderConfirmation,
  Notification,
  Layouts,
  IFeedSpread,
  DashboardInfo,
  BlockConfiguration,
  HistoryItem,
  ResponseCode,
  BlockType,
  BlockInfo,
  Order,
  UiSettings,
  Balances,
  SizeType,
  Expiration,
  AssetSymbol,
  GroupOrderLeg,
  FeedOrderUpdate,
  TradingblockOrder,
  FeedQuote,
  ExpiredTokenCode,
  OrderResponse,
  PnLItem,
  LoginJwtValue,
  FeedEventData,
  Transfer,
  AchRelationship,
  CreateAchRelationshipRequest,
  ApproveAchRelationshipRequest,
  GenericError,
  UpdateAchRelationshipRequest,
  DeleteAchRelationshipRequest,
  AchDepositRequest,
  ApiResponse,
  AssetSymbolInfo,
  TransferRecipientBank,
  DeleteRecipientBankRequest,
  UpdateRecipientBankRequest,
  CreateRecipientBankRequest,
  AccountRequestFor,
  AchWithdrawalRequest,
  WireWithdrawalRequest,
  CheckWithdrawalRequest,
  TransferBeneficiary,
  DeleteTransferRequest,
  OptionPairWithSymbol,
  BlockSettingsSavePayload,
  SecurityChallenge,
  SecurityQuestionType,
  PlaceOrderRequest,
  NewNotificationRequest,
  SubAccount,
  AllNotificationType,
  PlaidLinkToken,
  TransferInstruction,
  DeleteTransferInstructionsRequest,
  UpdateTransferInstructionsRequest,
  CreateTransferInstructionRequest,
  GroupLinkColors,
  BalancesForWithdrawal,
} from '@tradingblock/types';
import {
  BlockActions,
  GridActions,
  Actions,
  FeedActions,
  DataActions,
  OrderActions,
  NotificationActions,
  FavoriteActions,
  DashboardActions,
  ExpirationStrikeActions,
  LayoutActions,
  OptionChainActions,
  BlockGroupActions,
  SessionActions,
} from './actions';
import { CashieringActions } from './actions/CashieringActions';
import { SettingsActions } from './actions/SettingsActions';
import { PageActions } from './actions/PageActions';
import { EnvironmentActions } from './actions/EnvironmentActions';
import { AccountActions } from './actions/AccountActions';
import { SubaccountActions } from './actions/SubAccountActions';

export const Dispatcher = (dispatch: Dispatch<any>) => ({
  global: {
    exception: (error: GenericError) => dispatch(PageActions.globalException(error)),
  },
  environment: {
    toggleIsVirtual: () => dispatch(EnvironmentActions.toggleIsVirtual()),
  },
  subAccount: {
    requestSubAccounts: (accountId: number) => dispatch(SubaccountActions.requestSubaccounts({ accountId })),
    receiveSubAccounts: (payload: ApiResponse<SubAccount[]>) =>
      dispatch(SubaccountActions.receieveSubaccounts({ response: payload })),
    updateSubAccount: (subAccount: SubAccount) => dispatch(SubaccountActions.requestUpdateSubaccount({ subAccount })),
    createSubAccount: (subAccount: Omit<SubAccount, 'Id'>) =>
      dispatch(SubaccountActions.requestCreateSubaccount({ subAccount })),
    deleteSubAccount: (accountId: number, Id: number) =>
      dispatch(SubaccountActions.requestDeleteSubaccount({ accountId, Id })),
    getPositions: (accountId: number, Id: number) => dispatch(SubaccountActions.requestGetPositions({ accountId, Id })),
    receieveErrorCreate: (error: any, data: any, message: any) =>
      dispatch(SubaccountActions.errorCreateSubaccount({ error, data, message })),
    receieveErrorUpdate: (error: any, data: any, message: any) =>
      dispatch(SubaccountActions.errorUpdateSubaccount({ error, data, message })),
    receieveErrorDelete: (error: any, data: any, message: any) =>
      dispatch(SubaccountActions.errorDeleteSubaccount({ error, data, message })),
  },
  account: {
    requestSubAccounts: (accountId: number) => dispatch(AccountActions.requestSubAccounts({ accountId })),
    receiveSubAccounts: (payload: ApiResponse<SubAccount[]>) => dispatch(AccountActions.receiveSubAccounts(payload)),
    toggleAccountSwitcher: (show?: boolean) => dispatch(AccountActions.toggleAccountSwitcher({ show })),
    setSubAccount: (subaccountId?: number) => dispatch(AccountActions.setSubAccount({ subaccountId })),
  },
  block: {
    requestBlocks: () => dispatch(BlockActions.requestBlocks()),
    addBlock: (block: BlockConfiguration, meta?: ActionMeta) =>
      dispatch(BlockActions.addBlock({ block, addedAt: dayjs().toDate() }, meta || { persist: true })),
    addOrUpdateBlock: (
      block: BlockConfiguration,
      preserveGroupOrderData?: boolean,
      replaceFirstMatch?: { blockType: BlockType; groupId?: string },
      meta?: ActionMeta
    ) =>
      dispatch(
        BlockActions.addOrUpdateBlock(
          { block, addedAt: dayjs().toDate(), preserveGroupOrderData, replaceFirstMatch },
          meta || { persist: true }
        )
      ),
    updateBlock: (blockId: string, blockData: Partial<BlockConfiguration>, meta?: ActionMeta) =>
      dispatch(BlockActions.updateBlock({ blockId, blockData }, meta)),
    removeBlock: (blockId: string, meta?: ActionMeta) => dispatch(BlockActions.removeBlock(blockId, meta)),
    highlightBlocks: (blockType: BlockType, options?: { allMatches?: boolean; timeoutMs?: number }) =>
      dispatch(BlockActions.highlightBlocks({ blockType, highlightedAt: dayjs().toDate(), options })),
    clearHighlightedBlocks: () => dispatch(BlockActions.clearHighlightedBlocks()),
    addQuoteSubscription: (blockId: string, symbols: string[]) =>
      dispatch(BlockActions.addQuoteSubscription({ blockId, symbols })),
    setQuoteSubscription: (blockId: string, symbols: string[]) =>
      dispatch(BlockActions.setQuoteSubscription({ blockId, symbols })),
    linkBlock: (blockId: string, groupId: string) => dispatch(BlockActions.linkBlock({ blockId, groupId })),
    unlinkBlock: (blockId: string) => dispatch(BlockActions.unlinkBlock({ blockId })),
    highlightGroup: (groupId: string | undefined) =>
      dispatch(BlockActions.highlightGroup({ groupId, highlightAt: dayjs().toDate() })),
    setLinkColor: (linkColor: GroupLinkColors | undefined, blockData: { groupId: string }) =>
      dispatch(BlockActions.setColoredLink({ linkColor, blockData })),
  },
  blockGroup: {
    setSymbol: (groupId: string, symbol: AssetSymbol | undefined) =>
      dispatch(BlockGroupActions.setGroupSymbol({ symbol, groupId })),
    addGroupLeg: (groupId: string, leg: GroupOrderLeg) => dispatch(BlockGroupActions.addGroupLeg({ leg, groupId })),
  },
  grid: {
    toggleLocked: (options: { locked?: boolean; showTooltip?: boolean }) => dispatch(GridActions.toggleLocked(options)),
    toggleLinking: (linking?: boolean, sourceBlock?: BlockInfo, targetBlock?: { type: BlockType }) =>
      dispatch(GridActions.toggleLinking({ enabled: linking, sourceBlock, targetBlock })),
  },
  dashboards: {
    requestDashboards: () => dispatch(DashboardActions.requestDashboards()),
    receiveDashboards: (payload: DashboardInfo[]) => dispatch(DashboardActions.receiveDashboards(payload)),
    setDashboard: (payload: { dashboard: DashboardInfo; addDashboard?: boolean }) =>
      dispatch(DashboardActions.setDashboard(payload)),

    addDashboard: (payload: { dashboard: DashboardInfo; blocks: BlockConfiguration[] }, meta?: ActionMeta) =>
      dispatch(DashboardActions.addDashboard(payload, meta || { persist: true })),
    updateDashboard: (payload: DashboardInfo, meta?: ActionMeta) =>
      dispatch(
        DashboardActions.updateDashboard(
          { dashboardId: payload.dashboardId, dashboardData: payload },
          meta || { persist: true }
        )
      ),
  },
  balances: {
    request: () => dispatch(Actions.requestAccountBalances({ throttle: true })),
    receive: (payload: { data: Balances; responseCode: ResponseCode }) =>
      dispatch(Actions.receiveAccountBalances(payload)),
    requestPendingTransfers: () => dispatch(Actions.requestPendingTransfers({ throttle: false })),
    receivePendingTransfers: (payload: { data: Transfer[]; responseCode: ResponseCode }) =>
      dispatch(Actions.receivePendingTransfers(payload)),
    requestBalancesForWithdrawal: () => dispatch(Actions.requestBalancesForWithdrawal({ throttle: false })),
    receiveBalancesForWithdrawal: (payload: { data: BalancesForWithdrawal; responseCode: ResponseCode }) =>
      dispatch(Actions.receiveBalancesForWithdrawal(payload)),
  },
  settings: {
    block: {
      setBlockSetting: (payload: BlockSettingsSavePayload) => dispatch(SettingsActions.setBlockSetting(payload)),
    },

    requestDefaultDashboards: () => dispatch(SettingsActions.defaultDashboardRequest({})),
    requestToken: () => dispatch(Actions.requestToken()),

    signout: () => dispatch(Actions.signout()),
    requestSettings: (accountId: number) => dispatch(Actions.requestSettings({ accountId })),
    receiveSettings: (accountId: number, settings: UiSettings) =>
      dispatch(Actions.receiveSettings({ accountId, settings })),
    setSettings: (accountId: number, settings: UiSettings) => dispatch(Actions.setSettings({ accountId, settings })),
    toggleSettings: (show?: boolean) => dispatch(Actions.toggleSettings({ show })),
  },
  favorites: {
    request: () => dispatch(FavoriteActions.request()),
    receive: (symbols: AssetSymbolInfo[]) => dispatch(FavoriteActions.receive({ value: symbols })),
    add: (symbol: AssetSymbolInfo) => dispatch(FavoriteActions.addFavorite(symbol)),
    remove: (symbol: string) => dispatch(FavoriteActions.remove(symbol)),
  },
  data: {
    positions: {
      request: (accountId?: number, subaccountId?: number) =>
        dispatch(DataActions.requestPositions({ accountId, subaccountId }, { debounce: 1000 })),
      receive: (positions: PositionDTO[]) => dispatch(DataActions.receivePositions(positions)),
      manualRefresh: () => dispatch(DataActions.manualRefreshOfPositions()),
    },
    favorites: {
      request: () => dispatch(FavoriteActions.request()),
      receive: (symbols: AssetSymbolInfo[]) => dispatch(FavoriteActions.receive({ value: symbols })),
      add: (symbol: AssetSymbolInfo) => dispatch(FavoriteActions.addFavorite(symbol)),
      remove: (symbol: string) => dispatch(FavoriteActions.remove(symbol)),
    },
    feed: {
      addSpread: (spread: IFeedSpread) => dispatch(FeedActions.addSpread(spread)),
      addQuote: (quote: string | string[]) => dispatch(FeedActions.addQuote(quote)),
      removeQuote: (quote: string | string[]) => dispatch(FeedActions.removeQuote(quote)),
    },
    quote: {
      request: (symbol: string | string[]) => dispatch(DataActions.requestQuote({ symbol })),
      receive: (quotes: { symbol: string; quote: SymbolQuote }[]) => dispatch(DataActions.receiveQuote(quotes)),
    },
  },

  order: {
    // created: (order: PlaceOrderRequest, response: OrderConfirmation) => dispatch(OrderActions.orderCreated({ order, response })),
    errored: (order: PlaceOrderRequest, responseOrError: OrderResponse | string) => {
      const { Response, Code } = _.isObject(responseOrError)
        ? responseOrError
        : { Response: undefined, Code: undefined };
      const error = _.isString(responseOrError) ? responseOrError : undefined;
      dispatch(
        OrderActions.orderErrored(_.isString(responseOrError) ? { order, error } : { order, Response, Code, error })
      );
    },
    request: (
      accountId: number,
      subaccountId?: number,
      dateFrom?: Date,
      dateTo?: Date,
      includeOrderEvents?: boolean,
      pageSize?: number,
      pageIndex?: number,
      blockId?: string,
      isTradesTab?: boolean,
      workingOrdersOnly?: boolean
    ) =>
      dispatch(
        Actions.requestAccountOrders(
          {
            accountId,
            subaccountId,
            dateFrom,
            dateTo,
            includeOrderEvents,
            pageSize,
            pageIndex,
            blockId,
            isTradesTab,
            workingOrdersOnly,
          },
          { debounce: 1000 }
        )
      ),
    receive: (accountId: number, orders: Order[], date: Date) =>
      dispatch(Actions.receiveAccountOrders({ accountId, orders, date })),
    requestCancel: (orderId: number) => dispatch(OrderActions.requestCancel({ orderId })),
    receiveCancel: (orderId: number, date: Date, response: OrderConfirmation, responseCode: ResponseCode) =>
      dispatch(OrderActions.receiveCancel({ orderId, date, response, responseCode })),
  },
  notifications: {
    addOrderNotification: ({
      title,
      message,
      status,
      options,
      order,
      code,
    }: NewNotificationRequest & { order: Order }) => {
      dispatch(
        NotificationActions.add({
          type: 'Order',
          order,
          status,
          title,
          message,
          code,
          time: new Date(),
          key: _.uniqueId(),
          read: false,
          hide: false,
          viewed: false,
          ...(options || {}),
        })
      );
    },
    addNew: ({ title, message, status, options, code }: NewNotificationRequest) => {
      dispatch(
        NotificationActions.add({
          status,
          title,
          message,
          code,
          time: new Date(),
          key: _.uniqueId(),
          read: false,
          hide: false,
          viewed: false,
          ...(options || {}),
        })
      );
    },
    hide: (notification: Notification) => dispatch(NotificationActions.hide(notification)),
    read: (notification: Notification | Notification[]) => dispatch(NotificationActions.read(notification)),
    view: (notification: Notification) => dispatch(NotificationActions.view(notification)),
  },
  expirations: {
    request: (symbol: string) => dispatch(ExpirationStrikeActions.requestExpirationAction({ symbol })),
    receive: (symbol: string, expirations: Expiration[]) =>
      dispatch(ExpirationStrikeActions.receiveExpirations({ symbol, expirations })),
  },
  strikes: {
    request: (symbol: string, expiration: Expiration) =>
      dispatch(ExpirationStrikeActions.requestStrikes({ symbol, expiration })),
    receive: (symbol: string, expiration: Expiration, strikes: number[]) =>
      dispatch(ExpirationStrikeActions.receiveStrikes({ symbol, expiration, strikes })),
  },
  cashiering: {
    resetForm: () => dispatch(CashieringActions.resetForm()),
    securityQuestion: {
      get: {
        request: () => dispatch(CashieringActions.requestSecurityQuestion()),
        receive: (data: SecurityQuestionType) => dispatch(CashieringActions.receiveSecurityQuestion({ data })),
        error: (error: GenericError) => dispatch(CashieringActions.securityQuestionError(error)),
      },
      verify: {
        request: (securityResponse: SecurityChallenge) =>
          dispatch(CashieringActions.requestVerifySecurityQuestion(securityResponse)),
        receive: (result: boolean, message?: string) =>
          dispatch(CashieringActions.receiveVerifySecurityQuestion({ result, message })),
        resetStatus: () => dispatch(CashieringActions.resetSecurityQuestionStatus()),
      },
    },
    balances: {
      request: (subaccountId?: number) => dispatch(CashieringActions.requestBalances({ subaccountId })),
      receive: (balances: Balances, pendingTransfers?: Transfer[]) =>
        dispatch(CashieringActions.receiveBalances({ balances, pendingTransfers })),
    },
    transfers: {
      all: {
        request: () => dispatch(CashieringActions.requestTransfers()),
        receive: (data: Transfer[]) => dispatch(CashieringActions.receiveTransfers({ data })),
      },
      achDeposit: {
        request: (request: AccountRequestFor<AchDepositRequest>) =>
          dispatch(CashieringActions.requestAchDeposit(request)),
        receive: (response: ApiResponse<Transfer>) => dispatch(CashieringActions.receiveAchDeposit(response)),
        error: (error: GenericError) => dispatch(CashieringActions.depositAchError(error)),
      },
      achWithdrawal: {
        request: (request: AccountRequestFor<AchWithdrawalRequest>) =>
          dispatch(CashieringActions.requestAchWithdrawal(request)),
        receive: (response: ApiResponse<Transfer>) => dispatch(CashieringActions.receiveAchWithdrawal(response)),
        error: (error: GenericError) => dispatch(CashieringActions.withdrawalAchError(error)),
      },
      wireWithdrawal: {
        request: (request: AccountRequestFor<WireWithdrawalRequest>) =>
          dispatch(CashieringActions.requestWireWithdrawal(request)),
        receive: (response: ApiResponse<Transfer>) => dispatch(CashieringActions.receiveWireWithdrawal(response)),
        error: (error: GenericError) => dispatch(CashieringActions.withdrawalWireError(error)),
      },
      checkWithdrawal: {
        request: (request: AccountRequestFor<CheckWithdrawalRequest>) =>
          dispatch(CashieringActions.requestCheckWithdrawal(request)),
        receive: (response: ApiResponse<Transfer>) => dispatch(CashieringActions.receiveCheckWithdrawal(response)),
        error: (error: GenericError) => dispatch(CashieringActions.withdrawalCheckError(error)),
      },
      delete: {
        request: (request: AccountRequestFor<DeleteTransferRequest>) =>
          dispatch(CashieringActions.deleteTransferRequest(request)),
        receive: (data: Pick<Transfer, 'id'>, responseCode: ResponseCode) =>
          dispatch(CashieringActions.deleteTransferReceive({ payload: data, responseCode })),
        error: (error: GenericError) => dispatch(CashieringActions.deleteTransferError(error)),
      },
    },
    achRelationships: {
      all: {
        request: (accountId: number) => dispatch(CashieringActions.allAchRelationshipRequest(accountId)),
        receive: (payload: ApiResponse<AchRelationship[]>) =>
          dispatch(CashieringActions.allAchRelationshipReceive(payload)),
        error: (error: GenericError) => dispatch(CashieringActions.allAchRelationshipError(error)),
      },
      plaidLinkToken: {
        request: (accountId: number, redirectUri: string) =>
          dispatch(CashieringActions.plaidLinkTokenRequest({ accountId, redirectUri })),
        receive: (response: ApiResponse<PlaidLinkToken>) => dispatch(CashieringActions.plaidLinkTokenReceive(response)),
        error: (error: GenericError) => dispatch(CashieringActions.plaidLinkTokenError(error)),
      },
      create: {
        request: (data: CreateAchRelationshipRequest) => dispatch(CashieringActions.createAchRelationshipRequest(data)),
        receive: (data: AchRelationship, responseCode: ResponseCode) =>
          dispatch(CashieringActions.createAchRelationshipReceive({ payload: data, responseCode })),
        error: (error: GenericError) => dispatch(CashieringActions.createAchRelationshipError(error)),
      },
      approve: {
        request: (data: AccountRequestFor<ApproveAchRelationshipRequest>) =>
          dispatch(CashieringActions.approveAchRelationshipRequest(data)),
        receive: (data: AchRelationship, responseCode: ResponseCode) =>
          dispatch(CashieringActions.approveAchRelationshipReceive({ payload: data, responseCode })),
        error: (error: GenericError) => dispatch(CashieringActions.approveAchRelationshipError(error)),
      },
      update: {
        request: (request: AccountRequestFor<UpdateAchRelationshipRequest>) =>
          dispatch(CashieringActions.updateAchRelationshipRequest(request)),
        receive: (data: AchRelationship, responseCode: ResponseCode) =>
          dispatch(CashieringActions.updateAchRelationshipReceive({ payload: data, responseCode })),
        error: (error: GenericError) => dispatch(CashieringActions.updateAchRelationshipError(error)),
      },
      delete: {
        request: (request: AccountRequestFor<DeleteAchRelationshipRequest>) =>
          dispatch(CashieringActions.deleteAchRelationshipRequest(request)),
        receive: (data: Pick<AchRelationship, 'id'>, responseCode: ResponseCode) =>
          dispatch(CashieringActions.deleteAchRelationshipReceive({ payload: data, responseCode })),
        error: (error: GenericError) => dispatch(CashieringActions.deleteAchRelationshipError(error)),
      },
    },
    recipientBank: {
      all: {
        request: (accountId: number) => dispatch(CashieringActions.allTransferBankRequest(accountId)),
        receive: (payload: ApiResponse<TransferRecipientBank[]>) =>
          dispatch(CashieringActions.allTransferBankReceive(payload)),
        error: (error: GenericError) => dispatch(CashieringActions.allTransferBankError(error)),
      },
      create: {
        request: (request: AccountRequestFor<TransferInstruction>) =>
          dispatch(CashieringActions.createTransferBankRequest(request)),
        receive: (payload: ApiResponse<TransferRecipientBank>) =>
          dispatch(CashieringActions.createTransferBankReceive(payload)),
        error: (error: GenericError) => dispatch(CashieringActions.createTransferBankError(error)),
      },
      update: {
        request: (request: AccountRequestFor<UpdateRecipientBankRequest>) =>
          dispatch(CashieringActions.updateTransferBankRequest(request)),
        receive: (payload: ApiResponse<TransferRecipientBank>) =>
          dispatch(CashieringActions.updateTransferBankReceive(payload)),
        error: (error: GenericError) => dispatch(CashieringActions.updateTransferBankError(error)),
      },
      delete: {
        request: (request: AccountRequestFor<DeleteRecipientBankRequest>) =>
          dispatch(CashieringActions.deleteTransferBankRequest(request)),
        receive: (payload: ApiResponse<TransferRecipientBank>) =>
          dispatch(CashieringActions.deleteTransferBankReceive(payload)),
        error: (error: GenericError) => dispatch(CashieringActions.deleteTransferBankError(error)),
      },
    },
    transferBeneficiary: {
      all: {
        request: (accountId: number) => dispatch(CashieringActions.allTransferBeneficiaryRequest(accountId)),
        receive: (payload: ApiResponse<TransferBeneficiary[]>) =>
          dispatch(CashieringActions.allTransferBeneficiaryReceive(payload)),
        error: (error: GenericError) => dispatch(CashieringActions.allTransferBeneficiaryError(error)),
      },
    },
    transferInstructions: {
      all: {
        request: (accountId: number) => dispatch(CashieringActions.allTransferInstructionsRequest(accountId)),
        receive: (payload: ApiResponse<TransferInstruction[]>) =>
          dispatch(CashieringActions.allTransferInstructionsReceive(payload)),
        error: (error: GenericError) => dispatch(CashieringActions.allTransferInstructionsError(error)),
      },
      create: {
        request: (request: AccountRequestFor<CreateTransferInstructionRequest>) =>
          dispatch(CashieringActions.createTransferInstructionsRequest(request)),
        receive: (payload: ApiResponse<TransferInstruction>) =>
          dispatch(CashieringActions.createTransferInstructionsReceive(payload)),
        error: (error: GenericError) => dispatch(CashieringActions.createTransferInstructionsError(error)),
      },
      update: {
        request: (request: AccountRequestFor<UpdateTransferInstructionsRequest>) =>
          dispatch(CashieringActions.updateTransferInstructionsRequest(request)),
        receive: (payload: ApiResponse<TransferInstruction>) =>
          dispatch(CashieringActions.updateTransferInstructionsReceive(payload)),
        error: (error: GenericError) => dispatch(CashieringActions.updateTransferInstructionsError(error)),
      },
      delete: {
        request: (request: AccountRequestFor<DeleteTransferInstructionsRequest>) =>
          dispatch(CashieringActions.deleteTransferInstructionsRequest(request)),
        receive: (payload: ApiResponse<TransferInstruction>) =>
          dispatch(CashieringActions.deleteTransferInstructionsReceive(payload)),
        error: (error: GenericError) => dispatch(CashieringActions.deleteTransferInstructionsError(error)),
      },
    },
  },
  layout: {
    setBreakpointAndColumns: (breakpoint: SizeType, columns: number) =>
      dispatch(LayoutActions.setBreakpointAndColumns({ breakpoint, columns })),
    setLayouts: (layouts: Layouts, meta?: ActionMeta) => dispatch(LayoutActions.setLayouts({ layouts }, meta)),
    setAllLayouts: (layouts: Layouts, meta?: ActionMeta) => dispatch(LayoutActions.setAllLayouts({ layouts }, meta)),
  },
  optionchain: {
    setStrikeCount: (blockId: string, symbol: string, strikeCount: number, expirations: Expiration[]) =>
      dispatch(OptionChainActions.setStrikeCount({ blockId, symbol, strikeCount, expirations })),
    requestExpirations: (blockId: string, symbol: string) =>
      dispatch(OptionChainActions.requestOptionExpirations({ blockId, symbol })),
    requestOptionChainError: (blockId: string, symbol: string, ex: Expiration, responseCode: number) =>
      dispatch(OptionChainActions.requestOptionChainError({ blockId, symbol, expiration: ex, responseCode })),
    receiveExpirations: (blockId: string, symbol: string, expirations: Expiration[]) =>
      dispatch(OptionChainActions.receiveOptionExpirations({ blockId, symbol, expirations })),
    requestOptionChain: (blockId: string, symbol: string, expirations: Expiration[], strikeCount?: number) =>
      dispatch(OptionChainActions.requestOptionChain({ blockId, symbol, expirations, strikeCount })),
    receiveOptionChain: (blockId: string, symbol: string, ex: Expiration, pairs: OptionPairWithSymbol[]) =>
      dispatch(OptionChainActions.receiveOptionChain({ blockId, symbol, expiration: ex, pairs })),
  },
  history: {
    request: (accountId: number) => dispatch(Actions.requestAccountHistory({ accountId })),
    receive: (accountId: number, history: HistoryItem[]) =>
      dispatch(Actions.receiveAccountHistory({ accountId, history })),
  },
  feed: {
    connected: () => dispatch(FeedActions.connected()),
    closed: (data: FeedEventData) => dispatch(FeedActions.feedClosed(data)),
    errored: (data: FeedEventData) => dispatch(FeedActions.feedErrored(data)),
    updateQuotes: (quotes: { [symbol: string]: FeedQuote }) =>
      dispatch(FeedActions.updateQuotes({ quotes }, { verbose: true })),
  },
  pnlHistory: {
    request: (accountId: number) => dispatch(Actions.requestAccountPnL({ accountId })),
    receive: (accountId: number, pnlHistory: PnLItem[]) =>
      dispatch(Actions.receiveAccountPnL({ accountId, pnlHistory })),
  },
});

export type TradingBlockDispatcher = ReturnType<typeof Dispatcher>;
