import React, { useEffect, useState, useMemo } from 'react';
import { useStateSelector } from '../../data/global/dataSelectors';
import { useDispatch } from 'react-redux';
import { AccountManagementDataActions } from '../../data/global/actions/AccountManagementActions';
import { Loading } from '@tradingblock/components';
import { AddressUpdate } from './modals//AddressUpdate';
import { EmailUpdate } from './modals/EmailUpdate';
import { PhoneUpdate } from './modals/PhoneUpdate';
import { TrustedContactUpdate } from './modals/TrustedContactUpdate';
import { DetailFields, DetailField } from '../../components/basic/DetailFields';
import { formatPhoneNumber, formatYearsEmployed } from '../../utilities/accountManagement';
import { EmployInfoUpdate } from './modals/EmployInfoUpdate';
import { RemoveTrustedContactOrTradeAuth } from './modals/RemoveTrustedContactOrTradeAuth';
import { ResetPassword } from './modals/ResetPassword';
import dayjs from 'dayjs';

export const AccountDetails: React.FunctionComponent<{}> = () => {
  const accountId = useStateSelector(s => s.account.accountId);
  const accountDetails = useStateSelector(s => s.accountManagement.accountDetails.data);
  const isLoaded = useStateSelector(s => s.accountManagement.accountDetails.isLoaded);

  // Pending Account Management Update Requests
  const pendingAccountUpdates = useStateSelector(s => s.accountManagement.pendingAccountManagementUpdates.data);
  const pendingEmailUpdate = pendingAccountUpdates && pendingAccountUpdates.filter(u => u.item === 'Email');
  const pendingMailAddressUpdate =
    pendingAccountUpdates && pendingAccountUpdates.filter(u => u.item === 'MailingAddress');
  const pendingPhoneUpdate = pendingAccountUpdates && pendingAccountUpdates.filter(u => u.item === 'PhoneNumber');

  const accountManagementDetails = useStateSelector(s => s.accountManagement.accountManagementDetails.details);
  const { accountHolders } = accountManagementDetails;

  const { trustedContact } = accountManagementDetails;
  const isFetching = useStateSelector(s => s.accountManagement.accountManagementDetails.isFetching);
  const selectedLinkedAccountId = useStateSelector(
    s => s.account.selectedLinkedAccount && s.account.selectedLinkedAccount.accountId
  );

  const dispatch = useDispatch();
  const [showAddressConfirm, setShowAddressConfirm] = useState(false);
  const [showEmailConfirm, setShowEmailConfirm] = useState(false);
  const [showPhoneConfirm, setShowPhoneConfirm] = useState(false);
  const [showTrustedContact, setShowTrustedContact] = useState(false);
  const [showEmploymentUpdate, setShowEmploymentUpdate] = useState(false);
  const [addressType, setAddressType] = useState('');
  const [accountHolderIndex, setAccountHolderIndex] = useState<number>(0);
  const [showRemoveTrustedContact, setShowRemoveTrustedContact] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState<boolean>(false);
  const updateData = useStateSelector(s => s.accountManagement.update.data);

  useEffect(() => {
    if (!isLoaded && accountId) {
      if (selectedLinkedAccountId) {
        dispatch(AccountManagementDataActions.requestAccountDetails({ accountId: selectedLinkedAccountId }));
        dispatch(AccountManagementDataActions.requestAccountManagementDetails({ accountId: selectedLinkedAccountId }));
      } else {
        dispatch(AccountManagementDataActions.requestAccountDetails({ accountId }));
        dispatch(AccountManagementDataActions.requestAccountManagementDetails({ accountId }));
      }
    }
  }, [accountId, dispatch, isLoaded, selectedLinkedAccountId, updateData]);

  const handleClick = (event: any) => {
    event.preventDefault();
    switch (event.target.id) {
      case 'email-0':
        setAccountHolderIndex(0);
        setShowEmailConfirm(true);
        break;
      case 'email-1':
        setAccountHolderIndex(1);
        setShowEmailConfirm(true);
        break;
      case 'physAddress-0':
        setAddressType('physical');
        setAccountHolderIndex(0);
        setShowAddressConfirm(true);
        break;
      case 'physAddress-1':
        setAddressType('physical');
        setAccountHolderIndex(1);
        setShowAddressConfirm(true);
        break;
      case 'mailAddress-0':
        setAddressType('mail');
        setAccountHolderIndex(0);
        setShowAddressConfirm(true);
        break;
      case 'mailAddress-1':
        setAddressType('mail');
        setAccountHolderIndex(1);
        setShowAddressConfirm(true);
        break;
      case 'trustedContact':
        setShowTrustedContact(true);
        break;
      case 'employmentInfo-0':
        setAccountHolderIndex(0);
        setShowEmploymentUpdate(true);
        break;
      case 'employmentInfo-1':
        setAccountHolderIndex(1);
        setShowEmploymentUpdate(true);
        break;
      case 'phoneUpdate-0':
        setAccountHolderIndex(0);
        setShowPhoneConfirm(true);
        break;
      case 'phoneUpdate-1':
        setAccountHolderIndex(1);
        setShowPhoneConfirm(true);
        break;
      case 'removeTrustedContact':
        setShowRemoveTrustedContact(true);
        break;
      case 'resetPassword':
        setShowResetPassword(true);
        break;
      default:
        break;
    }
  };

  const toggleAddressUpdate = () => {
    showAddressConfirm ? setShowAddressConfirm(false) : setShowAddressConfirm(true);
  };

  const toggleEmailUpdate = () => {
    showEmailConfirm ? setShowEmailConfirm(false) : setShowEmailConfirm(true);
  };

  const togglePhoneUpdate = () => {
    showPhoneConfirm ? setShowPhoneConfirm(false) : setShowPhoneConfirm(true);
  };

  const toggleTrustedContact = () => {
    showTrustedContact ? setShowTrustedContact(false) : setShowTrustedContact(true);
  };

  const toggleEmploymentUpdate = () => {
    showEmploymentUpdate ? setShowEmploymentUpdate(false) : setShowEmploymentUpdate(true);
  };

  const toggleRemoveTrustedContact = () => {
    showRemoveTrustedContact ? setShowRemoveTrustedContact(false) : setShowRemoveTrustedContact(true);
  };

  const toggleShowResetPassword = () => {
    showResetPassword ? setShowResetPassword(false) : setShowResetPassword(true);
  };

  const getAccountHolderName = (index: number) => {
    if (accountHolders[index].firstName && accountHolders[index].lastName) {
      return `${accountHolders[index].firstName} ${accountHolders[index].middleInitial || ''} ${
        accountHolders[index].lastName
      } ${accountHolders[index].suffix || ''}`;
    }
    return 'Name not found';
  };

  const getPhoneNumberByType = (index: number, type: string) => {
    const phone = accountHolders[index].phones ? accountHolders[index].phones.filter(phone => phone.type === type) : [];
    if (phone.length > 0) {
      return phone[0].phoneNumber;
    }
    return 'N/A';
  };

  const personalInfoFields = useMemo(() => {
    let fieldList: DetailField[] = [];
    if (accountDetails && accountManagementDetails && !isFetching && isLoaded) {
      accountHolders.map((accountHolder, index) => {
        fieldList = [
          ...fieldList,

          {
            Value: () => (
              <>
                <i className="fal fa-user-edit dashboard-icon" /> Personal Information -{' '}
                <span className="mute">{getAccountHolderName(index)}</span>
              </>
            ),
            className: 'fields-title',
          },

          { Value: () => null },

          {
            Value: () => (
              <>
                <span className="props-title">
                  <strong>Email Address </strong>
                  <button className="mute txt-sm" id={`email-${index}`} onClick={event => handleClick(event)}>
                    (Update)
                  </button>
                </span>
                <br />
                <span className="mute">{accountHolder.email ? accountHolder.email : 'No Email Address on Record'}</span>
                <br />
                {pendingEmailUpdate && pendingEmailUpdate.length > 0 && (
                  <span className="pending txt-sm">
                    <strong>Update Request Pending Admin Review</strong>
                    <br />
                    <strong>
                      {dayjs(pendingEmailUpdate[pendingEmailUpdate.length - 1].createDate).format(
                        'MM/DD/YYYY - hh:mm A'
                      )}
                    </strong>
                  </span>
                )}
              </>
            ),
          },

          {
            Value: () => (
              <>
                <strong>
                  <span className="prop-title">Password</span>
                </strong>
                <span className="mute">
                  <button id={'resetPassword'} onClick={event => handleClick(event)}>
                    Reset Password
                  </button>
                </span>
              </>
            ),
          },

          {
            Value: () => (
              <>
                <span className="props-title">
                  <strong>Mailing Address </strong>
                  <button className="mute txt-sm" id={`mailAddress-${index}`} onClick={event => handleClick(event)}>
                    (Update)
                  </button>
                </span>
                <br />
                <span className="mute">
                  {accountHolder.mailingAddress.address1 ? accountHolder.mailingAddress.address1 : 'N/A'}
                </span>
                <br />
                {accountHolder.mailingAddress.address2 && (
                  <>
                    <span className="mute">{accountHolder.mailingAddress.address2}</span>
                    <br />
                  </>
                )}
                <span className="mute">
                  {accountHolder.mailingAddress.city ? accountHolder.mailingAddress.city : ''},{' '}
                  {accountHolder.mailingAddress.state ? accountHolder.mailingAddress.state : ''}{' '}
                  {accountHolder.mailingAddress.postalCode ? accountHolder.mailingAddress.postalCode : ''}
                </span>
                <br />
                <span className="mute">
                  {accountHolder.mailingAddress.country ? accountHolder.mailingAddress.country : 'N/A'}{' '}
                </span>
                <br />
                {pendingMailAddressUpdate && pendingMailAddressUpdate.length > 0 && (
                  <span className="pending txt-sm">
                    <strong>Update Request Pending Admin Review</strong>
                    <br />
                    <strong>
                      {dayjs(pendingMailAddressUpdate[pendingMailAddressUpdate.length - 1].createDate).format(
                        'MM/DD/YYYY - hh:mm A'
                      )}
                    </strong>
                  </span>
                )}
              </>
            ),
          },

          {
            Value: () => (
              <>
                <span className="props-title">
                  <strong>Physical Address </strong>
                  <button className="mute txt-sm" id={`physAddress-${index}`} onClick={event => handleClick(event)}>
                    (Update)
                  </button>
                </span>
                <br />
                <span className="mute">{accountHolder.address.address1 ? accountHolder.address.address1 : 'N/A'}</span>
                <br />
                {accountHolder.address.address2 && (
                  <>
                    <span className="mute">{accountHolder.address.address2}</span>
                    <br />
                  </>
                )}

                <span className="mute">
                  {accountHolder.address.city ? accountHolder.address.city : ''},{' '}
                  {accountHolder.address.state ? accountHolder.address.state : ''}{' '}
                  {accountHolder.address.postalCode ? accountHolder.address.postalCode : 'N/A'}
                </span>
                <br />
                <span className="mute">{accountHolder.address.country ? accountHolder.address.country : 'N/A'} </span>
              </>
            ),
          },

          {
            Value: () => (
              <>
                <span className="prop-title">
                  <strong>Phone Numbers </strong>
                  <button className="mute txt-sm" id={`phoneUpdate-${index}`} onClick={event => handleClick(event)}>
                    (Update)
                  </button>
                </span>
                <span className="txt-sm">
                  <span>Home: </span>
                  <span className="mute">{formatPhoneNumber(getPhoneNumberByType(index, 'Home'))}</span>{' '}
                </span>
                <br />
                <span className="txt-sm">
                  <span>Mobile: </span>
                  <span className="mute">{formatPhoneNumber(getPhoneNumberByType(index, 'Mobile'))}</span>{' '}
                </span>
                <br />
                <span className="txt-sm">
                  <span>Work: </span>
                  <span className="mute">{formatPhoneNumber(getPhoneNumberByType(index, 'Work'))}</span>{' '}
                </span>
                <br />
                {pendingPhoneUpdate && pendingPhoneUpdate.length > 0 && (
                  <span className="pending txt-sm">
                    <strong>Update Request Pending Admin Review</strong>
                    <br />
                    <strong>
                      {pendingPhoneUpdate[pendingPhoneUpdate.length - 1] &&
                        dayjs(pendingPhoneUpdate[pendingPhoneUpdate.length - 1].createDate).format(
                          'MM/DD/YYYY - hh:mm A'
                        )}
                    </strong>
                  </span>
                )}
              </>
            ),
          },

          {
            Value: () => null,
          },
        ];
      });
    }
    return fieldList;
  }, [
    accountHolders,
    accountDetails,
    isLoaded,
    isFetching,
    pendingAccountUpdates,
    pendingPhoneUpdate,
    pendingMailAddressUpdate,
    pendingEmailUpdate,
  ]);

  const trustedContactInfoFields = useMemo(() => {
    let fieldList: DetailField[] = [];

    if (accountDetails && accountManagementDetails && !isFetching && isLoaded) {
      fieldList = [
        ...fieldList,

        {
          Value: () => (
            <>
              <i className="fal fa-address-card dashboard-icon" /> Trusted Contact{' '}
              <button className="mute txt-sm" id="trustedContact" onClick={event => handleClick(event)}>
                (Update)
              </button>
            </>
          ),
          className: 'fields-title',
        },

        { Value: () => null },

        {
          Value: () => (
            <>
              <span className="mute">No trusted contact on record.</span>
            </>
          ),
          hide: trustedContact !== null,
        },

        { Value: () => null },

        {
          Value: () => (
            <>
              <strong>
                <span className="prop-title">Name</span>
              </strong>
              <span className="mute">
                {trustedContact.firstName} {trustedContact.middleInitial || ''} {trustedContact.lastName}{' '}
                {trustedContact.suffix || ''}
              </span>
            </>
          ),
          hide: trustedContact === null,
        },

        {
          Value: () => (
            <>
              <strong>
                <span className="prop-title">Email</span>
              </strong>
              <span className="mute">{trustedContact.email}</span>
            </>
          ),
          hide: trustedContact === null,
        },

        {
          Value: () => (
            <>
              <strong>
                <span className="prop-title">Address</span>
              </strong>
              <span className="mute">{trustedContact.address && trustedContact.address.address1}</span>
              <br />
              {trustedContact.address && trustedContact.address.address2 && (
                <>
                  <span className="mute">{trustedContact.address.address2}</span>
                </>
              )}
              <span className="mute">
                {trustedContact.address && trustedContact.address.city},{' '}
                {trustedContact.address && trustedContact.address.state}{' '}
                {trustedContact.address && trustedContact.address.postalCode}
              </span>
              <br />
              <span className="mute">{trustedContact.address && trustedContact.address.country}</span>
            </>
          ),
          hide: trustedContact === null || trustedContact.address === null,
        },

        {
          Value: () => (
            <>
              <strong>
                <span className="prop-title">Phone Number</span>
              </strong>
              <span className="mute">{formatPhoneNumber(trustedContact.phones[0].phoneNumber)}</span>
            </>
          ),
          hide: trustedContact === null || trustedContact.phones === null,
        },
        {
          Value: () => (
            <>
              <button className="mute txt-sm" id="removeTrustedContact" onClick={event => handleClick(event)}>
                Remove
              </button>
            </>
          ),
          hide: trustedContact === null,
        },
      ];
    }

    return fieldList;
  }, [trustedContact, accountDetails, isFetching, isLoaded]);

  const employmentInfoFields = useMemo(() => {
    let fieldList: DetailField[] = [];
    if (accountDetails && accountManagementDetails && !isFetching && isLoaded) {
      accountHolders.map((accountHolder, index) => {
        fieldList = [
          ...fieldList,

          {
            Value: () => (
              <>
                <i className="fal fa-building dashboard-icon" /> Employment Information -{' '}
                <span className="mute">{getAccountHolderName(index)}</span>{' '}
                <button className="mute txt-sm" id={`employmentInfo-${index}`} onClick={event => handleClick(event)}>
                  (Update)
                </button>
              </>
            ),
            className: 'fields-title',
          },

          { Value: () => null },

          {
            Value: () => (
              <>
                <strong>
                  <span className="prop-title">Employment Type</span>
                </strong>
                <span className="mute">{accountHolder.employmentType}</span>
              </>
            ),
            hide: !accountHolder.employmentType,
          },

          {
            Value: () => (
              <>
                <strong>
                  <span className="prop-title">Employer Name</span>
                </strong>
                <span className="mute">{accountHolder.employer}</span>
              </>
            ),
            hide: accountHolder.employmentType !== 'Employed' || !accountHolder.employer,
          },

          {
            Value: () => (
              <>
                <strong>
                  <span className="prop-title">Job Title</span>
                </strong>
                <span className="mute">{accountHolder.jobPosition}</span>
              </>
            ),
            hide: accountHolder.employmentType !== 'Employed' || !accountHolder.jobPosition,
          },

          {
            Value: () => (
              <>
                <strong>
                  <span className="prop-title">Years Employed</span>
                </strong>
                <span className="mute">
                  {accountHolder.yearsEmployed ? formatYearsEmployed(accountHolder.yearsEmployed) : 'Not applicable'}
                </span>
              </>
            ),
            hide: accountHolder.employmentType !== 'Employed',
          },

          {
            Value: () => (
              <>
                <strong>
                  <span className="prop-title">Employment Address</span>
                </strong>
                <span className="mute">
                  {accountHolder.employerAddress && accountHolder.employerAddress.address1 && (
                    <>
                      {accountHolder.employerAddress.address1}
                      <br />
                    </>
                  )}
                  {accountHolder.employerAddress && accountHolder.employerAddress.address2 && (
                    <>
                      {accountHolder.employerAddress.address2}
                      <br />
                    </>
                  )}
                  {accountHolder.employerAddress && accountHolder.employerAddress.city}
                  {accountHolder.employerAddress && accountHolder.employerAddress.state
                    ? ', ' + accountHolder.employerAddress.state
                    : ''}{' '}
                  {accountHolder.employerAddress && accountHolder.employerAddress.postalCode}
                  <br />
                  {accountHolder.employerAddress && accountHolder.employerAddress.country}
                </span>
              </>
            ),
            hide: accountHolder.employmentType !== 'Employed' || !accountHolder.employerAddress,
          },
          {
            Value: () => null,
          },
        ];
      });
    }

    return fieldList;
  }, [accountHolders, accountDetails, isFetching, isLoaded]);

  if (!isLoaded || isFetching || !accountManagementDetails) return <Loading />;

  return (
    <>
      <div className="screen-body-section">
        <DetailFields fieldList={personalInfoFields} />
      </div>
      <div className="screen-body-section">
        <DetailFields fieldList={employmentInfoFields} />
      </div>
      <div className="screen-body-section">
        <DetailFields fieldList={trustedContactInfoFields} />
      </div>
      <AddressUpdate
        toggleModal={toggleAddressUpdate}
        show={showAddressConfirm}
        addressType={addressType}
        accountHolderIndex={accountHolderIndex}
        accountDetails={accountDetails}
      />
      <EmailUpdate toggleModal={toggleEmailUpdate} show={showEmailConfirm} accountHolderIndex={accountHolderIndex} />
      <PhoneUpdate toggleModal={togglePhoneUpdate} show={showPhoneConfirm} accountHolderIndex={accountHolderIndex} />
      <TrustedContactUpdate toggleModal={toggleTrustedContact} show={showTrustedContact} />
      <EmployInfoUpdate
        toggleModal={toggleEmploymentUpdate}
        show={showEmploymentUpdate}
        accountHolderIndex={accountHolderIndex}
      />
      <RemoveTrustedContactOrTradeAuth
        toggleModal={toggleRemoveTrustedContact}
        show={showRemoveTrustedContact}
        type="removeTrustedContact"
      />
      <ResetPassword
        toggleModal={toggleShowResetPassword}
        show={showResetPassword}
        accountHolderIndex={accountHolderIndex}
      />
    </>
  );
};
