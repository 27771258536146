import { OrderFeedActions } from '../../actions/OrderFeedActions';
import { getType } from 'typesafe-actions';
import { DataState } from '../../state';
import { RootAction, OrderUpdateEventAction, DataActions, Actions } from '../../actions';
import { accountIdSelector } from '../../dataSelectors';
import { OrderStatusCategories } from '@tradingblock/api';
import { Middleware, Dispatch } from 'redux';

export const handleOrderFeedUpdates = (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: OrderUpdateEventAction
) => {
  const accountId = accountIdSelector(state);
  dispatch(DataActions.requestPositions({ accountId, subaccountId: undefined }, { debounce: 1000 }));
  if (OrderStatusCategories.Executed.includes(action.payload.order.OrderStatus)) {
    dispatch(Actions.requestAccountBalances({ throttle: true }));
  }
  dispatch(Actions.requestAccountOrders({ accountId }, { debounce: 1000 }));
  return state;
};
export const OrderFeedMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = middleware => (
  next: Dispatch<RootAction>
) => (action: RootAction) => {
  try {
    const dispatch = middleware.dispatch;
    // state BEFORE action is dispatched
    const prevState = middleware.getState();
    const result = next(action);
    // state AFTER action is dispatched
    const nextState = middleware.getState();
    switch (action.type) {
      case getType(OrderFeedActions.receivedUpdate):
        handleOrderFeedUpdates(nextState, dispatch, action);
        break;
    }
    return result;
  } catch (err) {
    console.error('OrderFeedMiddleware :: Caught an exception for action ', action, err);
  }
};
