import {
  ApplicationModel,
  ApplicationType,
  BooleanToggleType,
  ClearerType,
  CurrentItemApplicationModel,
  EntityAccountType,
  FlexibilityType,
  RetirementAccountType,
  TradeGoalType,
} from '@tradingblock/types';
import _ from 'lodash';

export const getAllAgreementKeysForApplication = (
  application: CurrentItemApplicationModel | undefined,
  needsApiAgreement?: boolean
) => {
  const isIra = application && application.retirementAccount === BooleanToggleType.Yes;
  const isEntity = application && application.type === ApplicationType.Entity;
  const isMargin = application && application.flexibilityType === FlexibilityType.Margin;
  // only first trade goal (stocks/ETFs/mutual funds/bonds) doesn't include options
  const isOptionsInTradeGoals =
    application && application.tradeGoalType && application.tradeGoalType !== TradeGoalType.StocksBonds;

  const allAgreementKeys: string[] = [
    'customerAndPrivacyPolicy',
    'electronicConsent',
    'onlineServices',
    'exchangeDataAgreement',
    'clientRelationshipSummary',
  ];

  if (application && application.clearer === ClearerType.Apex) {
    allAgreementKeys.push('customerAndPrivacyPolicy');
  } else if (application && application.clearer === ClearerType.RQD) {
    allAgreementKeys.push('rqdCustomerAgreement');
    allAgreementKeys.push('rqdExecutionDisclosures');
    allAgreementKeys.push('rqdClearingDisclosures');
    allAgreementKeys.push('rqdCustomerInformationBrochure');
    allAgreementKeys.push('rqdPrivacyPolicy');
    allAgreementKeys.push('rqdPrivacyNotice');
    allAgreementKeys.push('rqdResponsibilitiesOfIbAndCarryingBroker');
    allAgreementKeys.push('rqdSpecialStatementForUncoveredOptionWriters');
    allAgreementKeys.push('rqdPowerOfAttorney');
    allAgreementKeys.push('rqdLimitedTradingAuthorization');
  }

  if (application && isIra) {
    if (application.clearer === ClearerType.RQD) {
      allAgreementKeys.push('rqdIRADisclosure');
    } else {
      if (application.retirementAccountType === RetirementAccountType.Roth) {
        allAgreementKeys.push('iRARothAdoption');
      } else if (application.retirementAccountType === RetirementAccountType.Simple) {
        allAgreementKeys.push('iRASimpleAdoption');
      } else if (application.retirementAccountType === RetirementAccountType.CoverDell) {
        allAgreementKeys.push('iRACoverDellAdoption');
      } else {
        // SEP agreement applies to all other IRA types
        allAgreementKeys.push('iRASepAdoption');
      }
    }
  }
  if (isOptionsInTradeGoals) {
    if (application && application.clearer === ClearerType.Apex) {
      allAgreementKeys.push('option');
    } else if (application && application.clearer === ClearerType.RQD) {
      allAgreementKeys.push('rqdOption');
    }
    allAgreementKeys.push('riskDisclosure');
  }
  if (isMargin) {
    if (application && application.clearer === ClearerType.Apex) {
      if (isIra) {
        allAgreementKeys.push('limitedMargin');
      } else {
        allAgreementKeys.push('margin');
      }
    } else if (application && application.clearer === ClearerType.RQD) {
      if (isIra) {
        allAgreementKeys.push('rqdLimitedMargin');
      } else {
        allAgreementKeys.push('rqdMargin');
      }
    }
  }
  if (isEntity) {
    if (isOptionsInTradeGoals) {
      allAgreementKeys.push('entityOption');
    }
    if (isMargin) {
      allAgreementKeys.push('entityMargin');
    }
  }
  if (
    application &&
    isEntity &&
    _.includes([EntityAccountType.LlcCCorporation, EntityAccountType.LlcSCorporation], application.entityAccountType)
  ) {
    allAgreementKeys.push('llcAccount');
  }
  if (
    application &&
    isEntity &&
    _.includes([EntityAccountType.CCorporation, EntityAccountType.SCorporation], application.entityAccountType)
  ) {
    if (isMargin) {
      allAgreementKeys.push('corporateMargin');
    } else {
      allAgreementKeys.push('corporateCash');
    }
  }
  if (application && application.type === ApplicationType.Joint && application.jointAccountType === 'Community') {
    allAgreementKeys.push('jointCommunity');
  }
  if (application && application.type === ApplicationType.Joint && application.jointAccountType === 'Survivor') {
    allAgreementKeys.push('jointSurvivor');
  }
  if (application && application.type === ApplicationType.Joint && application.jointAccountType === 'TenantsInCommon') {
    allAgreementKeys.push('jointTenantsInCommon');
  }
  if (
    application &&
    application.type === ApplicationType.Joint &&
    application.jointAccountType === 'TenantsInEntirety'
  ) {
    allAgreementKeys.push('jointTenantsInEntirety');
  }
  //TODO: implement the needsApiAgreement parameter
  if (application) {
    allAgreementKeys.push('apiAgreement');
  }

  return allAgreementKeys;
};

export const getMarketDataResponses = (marketDataKeys: string[], marketData: any) => {
  let answeredMarketDataQs: string[] = [];
  marketDataKeys.map((key: string) => {
    if (marketData[key]) {
      answeredMarketDataQs.push(key);
    }
  });

  return answeredMarketDataQs;
};
