import { AggregatedPositionDTO, parseOccSymbol, PositionDTO } from '@tradingblock/api';
import { AssetType, OptionType } from '@tradingblock/types';
import _ from 'lodash';
import { useMemo } from 'react';
import { SymbolPositions } from '../../../types';

export default function useAggregatedPositions(
  underlyingSymbol: string,
  symbolPositions: SymbolPositions,
  combineSameSymbols: boolean = true,
  groupPositionsByStrategy: boolean = true,
  subAccountId?: number
) {
  const shouldGroupByStrategy = !groupPositionsByStrategy;
  return useMemo<AggregatedPositionDTO[]>(() => {
    const positionValues = symbolPositions[underlyingSymbol] ? symbolPositions[underlyingSymbol].positions : [];
    // Filtering for selected sub-account
    const subIdsFromPositions = _.uniq(positionValues.map(p => p.SubaccountId));
    const isMaster = subAccountId && !subIdsFromPositions.includes(subAccountId);
    const isAll = subAccountId === -1;
    const filteredPositionValues = !isMaster
      ? positionValues.filter(p => p.SubaccountId === subAccountId)
      : positionValues.filter(p => p.SubaccountId === -1);
    const currentPositions =
      !subAccountId || isAll
        ? _.filter(positionValues, p => p.UnderlyingSymbol === underlyingSymbol)
        : _.filter(filteredPositionValues, p => p.UnderlyingSymbol === underlyingSymbol);
    const groupedPositions = _.groupBy(currentPositions, p => p.OrderId);

    // This reduce will convert all the PositionDTO into AggregatedPositionDTO with their correct block settings
    const agg_positions: AggregatedPositionDTO[] = currentPositions
      .reduce((alteredPositions: AggregatedPositionDTO[], position: PositionDTO) => {
        // Check if in current alteredPositions we have a symbol that matches. This is only used for combining settings logic
        const inAlteredPositions =
          alteredPositions.findIndex(
            alteredPosition =>
              alteredPosition.Symbol === position.Symbol &&
              (shouldGroupByStrategy ? groupedPositions[alteredPosition.OrderId].length === 1 : true)
          ) === -1
            ? false
            : true;
        const positionValueBasis = position.OpenQuantity * position.OptionMultiplier;

        // Logic for combining positions
        if (combineSameSymbols && inAlteredPositions) {
          // If we are grouping positions by strategy but our current position is not a single leg, we add it as is to avoid combining and breaking
          // the logic for grouping by strategy
          if (shouldGroupByStrategy && groupedPositions[position.OrderId].length > 1) {
            return [
              ...alteredPositions,
              {
                ...position,
                ValueBasis: positionValueBasis,
                PositionIds: [position.PositionId],
              },
            ];
          }
          // Get the current combinable position if it exists in the altered positions
          const currentCombinedPositionIndex = alteredPositions.findIndex(
            combinedPosition =>
              combinedPosition.Symbol === position.Symbol &&
              (shouldGroupByStrategy ? groupedPositions[combinedPosition.OrderId].length === 1 : true) //This just makes sure we do not combine on a multi leg strategy
          );
          // If we have a currentCombinedPositionIndex, we combined positions values with the existing combinable position
          if (currentCombinedPositionIndex !== -1) {
            // If the positions are held in different sub accounts, do not combine and instead reflect each position on its own row
            if (alteredPositions[currentCombinedPositionIndex].SubaccountId !== position.SubaccountId) {
              return [
                ...alteredPositions,
                { ...position, ValueBasis: positionValueBasis, PositionIds: [position.PositionId] },
              ];
            }

            //If we are on the first item, then we need to alter the OpenPrice first before computing the rest. to accurately calculate the OpenPrice Avg.
            if (alteredPositions[currentCombinedPositionIndex].PositionIds.length <= 1) {
              alteredPositions[currentCombinedPositionIndex] = {
                ...alteredPositions[currentCombinedPositionIndex],
                OpenPrice:
                  alteredPositions[currentCombinedPositionIndex].OpenPrice *
                  alteredPositions[currentCombinedPositionIndex].OpenQuantity,
              };
            }
            alteredPositions[currentCombinedPositionIndex] = {
              ...alteredPositions[currentCombinedPositionIndex],
              OpenQuantity: alteredPositions[currentCombinedPositionIndex].OpenQuantity + position.OpenQuantity,
              CostBasis: alteredPositions[currentCombinedPositionIndex].CostBasis + position.CostBasis,
              ValueBasis: alteredPositions[currentCombinedPositionIndex].ValueBasis + positionValueBasis,
              OpenPrice:
                alteredPositions[currentCombinedPositionIndex].OpenPrice + position.OpenPrice * position.OpenQuantity,
              PositionIds: [...alteredPositions[currentCombinedPositionIndex].PositionIds, position.PositionId],
            };
            return alteredPositions;
          }
        }

        //If we are not combining or we are not already in alteredPositions, convert to AggregatedPositionDTO
        return [
          ...alteredPositions,
          {
            ...position,
            ValueBasis: positionValueBasis,
            PositionIds: [position.PositionId],
          },
        ];
      }, [])
      .map(positions => {
        // If we are combining positions we need to go through all our combine positions and avg out the OpenPrice.
        if (combineSameSymbols && positions.PositionIds.length > 1) {
          return {
            ...positions,
            OpenPrice: positions.OpenPrice / positions.OpenQuantity,
          };
        }
        return positions;
      });

    return agg_positions;
  }, [symbolPositions, underlyingSymbol, combineSameSymbols, groupPositionsByStrategy, subAccountId]);
}

export function sortedCustomOptionsFunction(position_1: AggregatedPositionDTO, position_2: AggregatedPositionDTO) {
  if (position_1.AssetType === AssetType.Equity) {
    return -1;
  }
  if (position_2.AssetType === AssetType.Equity) {
    return 1;
  }

  if (position_1.AssetType === AssetType.Option && position_2.AssetType === AssetType.Option) {
    const option_1_details = parseOccSymbol(position_1.Symbol);
    const option_2_details = parseOccSymbol(position_2.Symbol);

    // if we working with options in the same expiration
    if (
      option_1_details.expiration &&
      option_2_details.expiration &&
      option_1_details.expiration.valueOf() === option_2_details.expiration.valueOf()
    ) {
      // Then we need to sort Call options descending
      if (
        option_1_details.option === OptionType.Call &&
        option_2_details.option === OptionType.Call &&
        option_1_details.strike &&
        option_2_details.strike
      ) {
        return option_1_details.strike - option_2_details.strike;
      }

      // Then we need to sort Put options ascending
      if (
        option_1_details.option === OptionType.Put &&
        option_2_details.option === OptionType.Put &&
        option_1_details.strike &&
        option_2_details.strike
      ) {
        return option_2_details.strike - option_1_details.strike;
      }

      // If we are not comparing same contracts, then Calls need to go over puts
      if (option_1_details.option === OptionType.Call && option_2_details.option === OptionType.Put) {
        return -1;
      } else {
        return 1;
      }
      // Otherwise we need to sort by exp
    } else if (option_1_details.expiration && option_2_details.expiration) {
      return option_1_details.expiration.valueOf() - option_2_details.expiration.valueOf();
    }
  }
  return -1;
}
