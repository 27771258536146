import React from 'react';
import _ from 'lodash';
import { getClassNames } from '@tradingblock/components';
import { useDirectionalValue } from '../basic/Directional';

export const Directional: React.FC<{ value?: string | number; className?: string }> = ({
  value,
  className,
  children,
}) => {
  const directionalClass = useDirectionalValue(_.isNumber(value) ? value : undefined);
  const classes = getClassNames([className, directionalClass]);
  return <div className={classes}>{children}</div>;
};
