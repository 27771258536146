import _ from 'lodash';
import { getType, isActionOf } from 'typesafe-actions';
import { OrderBlockState, OrderState } from '../OrderState';
import { OrderActions, Actions, AllOrderActions, ValidationActions } from '../OrderActions';
import { DebitOrCredit, OrderAction, isCustomStrategy } from '@tradingblock/types';
import { orderLegsToClassifiedStrategy } from '../../../../data/global/utilities/strategyClassification';
import { Strategies } from '@tradingblock/api';

// const isOrderValidSelector = createCachedSelector(
//   (s: OrderState, blockId: string) => s.validation.valid,
//   (s: OrderState, blockId: string) => {
//     const legs = s.legs;
//     if (_.isNil(legs) || _.values(legs).length === 0) {
//       return false;
//     }
//     return _.every(legs, ({ OptionType, Strike, Expiration, SpreadRatio }) => {
//       if (_.isNil(OptionType)) {
//         return true;
//       } else {
//         return _.every([Strike, Expiration, SpreadRatio], v => !_.isNil(v));
//       }
//     });
//   },
//   (valid, complete) => {
//     return { valid, complete };
//   }
// )((s: OrderState, blockId: string) => blockId);

const isOrderComplete = (state: OrderState) => {
  const legs = state.legs;
  const numLegs = _.values(legs).length;
  if (_.isNil(legs) || _.values(legs).length === 0) {
    return false;
  }
  return _.every(legs, ({ OptionType, Strike, Expiration, SpreadRatio }) => {
    if (_.isNil(OptionType)) {
      return true;
    } else {
      // only check SpreadRatio for multi-leg orders
      if (numLegs === 1) {
        return _.every([Strike, Expiration], v => !_.isNil(v));
      } else {
        return _.every([Strike, Expiration, SpreadRatio], v => !_.isNil(v));
      }
    }
  });
};

export const OrderPreviewReducer = (state: OrderBlockState, action: AllOrderActions): OrderBlockState => {
  switch (action.type) {
    case 'initializeData':
    case getType(Actions.clear):
    case getType(Actions.setSymbol):
    case getType(Actions.removeLeg):
    case getType(ValidationActions.startValidation):
    case getType(ValidationActions.finishValidation):
    case getType(Actions.setPositionInfo):
    case getType(Actions.setOptionType):
    case getType(Actions.setLegQuantity):
    case getType(Actions.setExpiration):
    case getType(Actions.setStrike):
    case getType(Actions.setOrderAction):
    case getType(Actions.setLegAction):
    case getType(Actions.addOrderLeg):
    case getType(Actions.setStrategy):
    case getType(Actions.handleOrderGroupChange):
    case getType(Actions.reset):
    case getType(Actions.SetSymbolAndReset):
    case getType(Actions.addOrderLegFromGroup): {
      const isOrderAction = isActionOf(Actions.setOrderAction, action);
      const { legs } = state.data;

      const classifyStrategy = orderLegsToClassifiedStrategy(Object.values(legs) as any);
      const isComplete = isOrderComplete(state.data);
      const isValid = state.data.validation.valid;
      const isCustom = state.data.strategy === 'Custom';
      let recalculatePrice = true;
      if (isOrderAction) {
        recalculatePrice = false;
      } else {
        recalculatePrice = true;
      }

      let debitCredit;
      let orderAction;

      debitCredit =
        state.data.debitCredit || (classifyStrategy.action === 'BUY' && DebitOrCredit.Debit) || DebitOrCredit.Credit;

      // Determine the order action based on whether the order is custom or predefined strategy
      if (!isCustom) {
        // If the order is not custom (i.e., it follows a predefined strategy),
        // set the order action based on the classified strategy's action
        // If the action is 'BUY', set orderAction to OrderAction.Buy, otherwise set it to OrderAction.Sell
        orderAction = classifyStrategy.action === 'BUY' ? OrderAction.Buy : OrderAction.Sell;
      } else {
        // If the order is custom, set the order action based on the current state data's action value
        orderAction = state.data.action;
      }

      return {
        ...state,
        data: {
          ...state.data,
          recalculatePrice,
          debitCredit: state.data.debitCredit,
          action: orderAction,
          preview: {
            // status: isComplete === false ? 'incomplete' : isValid === false ? 'invalid' : 'complete',
            enabled: isValid && isComplete ? true : false,
          },
        },
      };
    }

    default:
      return state;
  }
};
