/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useMemo, useEffect, useState, useCallback } from 'react';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { WebsocketFeedParser, isQuote, isOrderUpdate, isExpiredToken, isSettlement } from '@tradingblock/api';
import { Config } from '../../config';
import { Websockets } from '../../constants';
import { DataState } from '../global/state';
import { useDispatcher } from '../global/hooks';
import { useStateSelector } from '../global/dataSelectors';
import { SessionActions } from '../global/actions';
import { OrderFeedActions } from '../global/actions/OrderFeedActions';
import { getFeedActions, FeedActions } from './FeedState';
import { useFeedStatus } from './FeedSelectors';
import { setExtras } from '@sentry/react';

const shouldTryReconnect = (socket?: WebSocket) => {
  if (!socket || socket.readyState === WebSocket.CLOSED) {
    return true;
  }
  return false;
};

interface UseWebsocketProps {
  websocket: React.MutableRefObject<WebSocket | undefined>;
  dispatch: React.Dispatch<FeedActions>;
  onClose: () => void;
}

const canConnectToWebsocket = createSelector(
  (s: DataState) => s.auth,
  auth => (auth.isExpired !== true && auth.isAuthenticated === true ? true : false)
);

export const useWebsocket = ({ websocket, dispatch, onClose }: UseWebsocketProps) => {
  const [shouldReconnect, setShouldReconnect] = useState(false);
  const [closeReason, setCloseReason] = useState<string>();
  const retryTimeoutMs = useRef<number>(250);
  const retryTimeout = useRef<NodeJS.Timeout>();
  const dispatchers = useDispatcher();
  const dispatcher = dispatchers.dispatcher;
  const globalDispatch = dispatchers.dispatch;
  const { onQuote, onSettlement, onOrderUpdate, setStatus } = getFeedActions(dispatch);
  const feedStatus = useFeedStatus();
  const canConnect = useStateSelector(canConnectToWebsocket);

  const closeSocket = () => {
    if (websocket.current) {
      websocket.current.close();
      websocket.current = undefined;
    }
    onClose();
  };
  //TODO: Review this timeout logic, TBFE-51
  const trySetupWebsocket = async () => {
    closeSocket();
    try {
      const client = new WebSocket(Config.tradingApiWebsockets);
      client.onopen = () => {
        if (retryTimeout.current) {
          clearTimeout(retryTimeout.current);
        }
        retryTimeoutMs.current = 250;
        dispatcher.feed.connected();
        setStatus('open');
      };
      client.onclose = (ev: CloseEvent) => {
        if (ev.reason) {
          console.warn(ev.reason);
          setCloseReason(ev.reason);
        }
        setStatus('closed');
      };
      client.onmessage = (ev: MessageEvent) => {
        const val = ev.data;
        const parser = new WebsocketFeedParser(val);
        const getHours = new Date().getHours();
        const after2AmBefore8Am = getHours >= 2 && getHours <= 7;
        const floatValueCheck = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
        if (after2AmBefore8Am) {
          floatValueCheck.map((float: any) => {
            // cast websocket value to boolean & negate
            if (!(!!parser.float(float))) {
              setExtras({
                key: 'falsyWebSocketValueNumber' + float, extra: {
                  [new Date().toDateString().toString()]: parser.float(float)
                }
              })
            }
          })
        }
        const msg = parser.parse();
        if (_.isNil(msg)) {
          console.warn('unknown feed type', val);
        } else if (!msg.isValid) {
          console.warn('errored feed message', val);
        } else if (isQuote(msg)) {
          onQuote(msg, val);
        } else if (isSettlement(msg)) {
          onSettlement(msg, val);
        } else if (isOrderUpdate(msg)) {
          onOrderUpdate(msg, val);
          globalDispatch(OrderFeedActions.receivedUpdate({ order: msg }));
        } else if (isExpiredToken(msg)) {
          console.warn('expired token message', val);
          globalDispatch(SessionActions.expired({ code: msg.Code, reason: msg.Reason }));
        }
      };
      client.onerror = (ev: Event) => {
        dispatcher.feed.errored({ error: ev, reason: 'Unable to connect', message: 'Datafeed error' });
        setStatus('error');
      };
      websocket.current = client;
    } catch (createWebsocketError) {
      dispatcher.feed.errored({
        error: createWebsocketError,
        reason: 'Error creating websocket',
        message: 'Websocket error',
      });
    }
  };

  useEffect(() => {
    if (shouldReconnect) {
      if (retryTimeout.current) {
        clearTimeout(retryTimeout.current);
      }
      retryTimeout.current = setTimeout(() => {
        if (shouldTryReconnect(websocket.current)) {
          trySetupWebsocket().then(() => setShouldReconnect(false));
        }
      }, retryTimeoutMs.current);
    }
  }, [shouldReconnect]);

  useEffect(() => {
    if (feedStatus === 'closed') {
      if (canConnect) {
        const newTimeout = Math.min(Websockets.ReconnectInterval, retryTimeoutMs.current + retryTimeoutMs.current);
        const newTimeoutDisplay = _.round(newTimeout / 1000, 2);
        const closeMessage = `Socket is closed. Reconnect will be attempted in ${newTimeoutDisplay} second.`;
        dispatcher.feed.closed({
          reason: closeReason || '',
          retryingInSeconds: newTimeoutDisplay,
          message: closeMessage,
        });
        retryTimeoutMs.current = newTimeout;
        console.warn(closeMessage);
        setShouldReconnect(true);
      } else {
        const closeMessage = 'Socket is closed because user can no longer connect';
        dispatcher.feed.closed({ reason: closeReason || '', message: closeMessage });
        console.warn(closeMessage);
      }
    }
  }, [feedStatus]);

  useEffect(() => {
    if (canConnect && websocket.current === undefined) {
      console.log('initializing websocket (this should only happen once!)');
      trySetupWebsocket();
    } else if (!canConnect && websocket.current) {
      console.log('disconnecting websocket because user can no longer connect');
      closeSocket();
    } else {
      console.log('doing nothing');
    }
  }, [dispatcher, canConnect]);

  const send = useMemo(() => {
    return {
      send: (data: any) => {
        if (websocket.current && websocket.current.readyState === websocket.current.OPEN) {
          websocket.current.send(data);
        } else if (websocket.current) {
          console.warn('trying to send message to closed/closing websocket', data);
        } else if (!shouldReconnect && canConnect) {
          console.warn('websocket client not setup yet, send wont go through', data);
        }
      },
    };
  }, [websocket, shouldReconnect, canConnect]);

  return [send];
};
