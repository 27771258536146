import React, { useMemo } from 'react';
import _ from 'lodash';
import { DetailField, DetailFields } from '../../../../components/basic/DetailFields';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import {
  AccountManagementBaseUpdate,
  BeneficiaryType,
  Country,
  PhoneUpdate,
  TradeAuthorizationType,
  getCountryNameByCode,
} from '@tradingblock/types';
import {
  formatAgentName,
  formatIncome,
  formatPhoneNumber,
  formatTaxBracket,
  formatTradesPerYear,
  formatYearsEmployed,
  formatYearsTrading,
  getAgentPhoneNumbersByType,
} from '../../../../utilities/accountManagement';
import { Loading } from '@tradingblock/components';
import { maskSSN } from '../../Applications/services/formatters';
import { formatDateWithoutTime } from '../../../../utilities/date';

export const AccountManagementUpdateRequest: React.FunctionComponent<Pick<AccountManagementBaseUpdate, 'item'>> = ({
  item,
}) => {
  const details = useStateSelector(s => s.private.accountManagement.currentItem.details);
  const accountInfo = useStateSelector(s => s.private.accountManagement.currentItem.accountInfo);
  const { original, updated } = details;
  const isFetching = useStateSelector(s => s.private.accountManagement.currentItem.isFetching);
  const { accountHolders } = accountInfo;
  const { state } = details;
  const isCompleteOrApproved: boolean = state === 'Approved' || state === 'Complete' ? true : false;

  const formatBoolean = (boolean: boolean) => {
    if (boolean === true) {
      return 'Yes';
    } else {
      return 'No';
    }
  };

  const { accountHolder, investmentObjectiveType, tradeGoalType, characteristics, tradingAuthorization } = original;
  const { mailingAddress, phones, email, disclosures } = accountHolder;
  const { risk, bracket, horizon, liquidity } = characteristics;

  const origEmail = accountHolder.email;
  const isPrimary = accountHolders[0].email === origEmail;
  const isUgma = accountInfo.type === 'Ugma';
  const secondaryOrUgmaBen = isUgma ? 'UGMA Beneficiary Update' : 'Secondary Account Holder Update';
  const hasGrantedTradeAuth = updated.tradingAuthorization !== null;
  const agentName =
    updated.tradingAuthorization &&
    updated.tradingAuthorization.firstName + ' ' + updated.tradingAuthorization.lastName;

  const fields = useMemo(() => {
    let fieldList: DetailField[] = [];
    if (details && item === 'Address') {
      fieldList = [
        ...fieldList,
        {
          Value: () => (
            <span className="fields-title">{isPrimary ? 'Primary Account Holder Update' : secondaryOrUgmaBen}</span>
          ),
        },
        { Value: () => null },
        { Value: () => <>Update Value of {item}: </>, className: 'fields-title' },
        { Value: () => null },
        {
          Value: () => (
            <>
              <span className="mute">
                {updated.accountHolder.address.address1}
                <br />
                {updated.accountHolder.address.address2 ? updated.accountHolder.address.address2 : <></>}
                {updated.accountHolder.address.address2 ? <br /> : <></>}
                {updated.accountHolder.address.city}
                {updated.accountHolder.address.state ? ', ' + updated.accountHolder.address.state : ''}{' '}
                {updated.accountHolder.address.postalCode}
                <br />
                {updated.accountHolder.address.country}
              </span>
            </>
          ),
        },
      ];
    }
    if (details && item === 'MailingAddress' && !isCompleteOrApproved) {
      fieldList = [
        ...fieldList,
        {
          Value: () => (
            <span className="fields-title">
              {isPrimary ? 'Primary Account Holder Update' : 'Secondary Account Holder Update'}
            </span>
          ),
        },
        { Value: () => null },
        { Value: () => <>Original Value of {item.replace(/([A-Z])/g, ' $1').trim()}: </>, className: 'fields-title' },
        { Value: () => <>Update Value of {item.replace(/([A-Z])/g, ' $1').trim()}: </>, className: 'fields-title' },

        {
          Value: () => (
            <>
              <span className="mute">
                {mailingAddress.address1}
                <br />
                {mailingAddress.address2 ? mailingAddress.address2 : <></>}
                {mailingAddress.address2 ? <br /> : <></>}
                {mailingAddress.city}
                {mailingAddress.state ? ', ' + mailingAddress.state : ''} {mailingAddress.postalCode}
                <br />
                {mailingAddress.country}
              </span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="mute">
                {updated.accountHolder.mailingAddress.address1}
                <br />
                {updated.accountHolder.mailingAddress.address2 ? updated.accountHolder.mailingAddress.address2 : <></>}
                {updated.accountHolder.mailingAddress.address2 ? <br /> : <></>}
                {updated.accountHolder.mailingAddress.city}
                {updated.accountHolder.mailingAddress.state
                  ? ', ' + updated.accountHolder.mailingAddress.state
                  : ''}{' '}
                {updated.accountHolder.mailingAddress.postalCode}
                <br />
                {updated.accountHolder.mailingAddress.country}
              </span>
            </>
          ),
        },
      ];
    }
    if (details && item === 'MailingAddress' && isCompleteOrApproved) {
      fieldList = [
        ...fieldList,
        {
          Value: () => (
            <span className="fields-title">
              {isPrimary ? 'Primary Account Holder Update' : 'Secondary Account Holder Update'}
            </span>
          ),
        },
        { Value: () => null },

        { Value: () => <>Update Value of {item.replace(/([A-Z])/g, ' $1').trim()}: </>, className: 'fields-title' },

        {
          Value: () => null,
        },
        {
          Value: () => (
            <>
              <span className="mute">
                {updated.accountHolder.mailingAddress.address1}
                <br />
                {updated.accountHolder.mailingAddress.address2 ? updated.accountHolder.mailingAddress.address2 : <></>}
                {updated.accountHolder.mailingAddress.address2 ? <br /> : <></>}
                {updated.accountHolder.mailingAddress.city}
                {updated.accountHolder.mailingAddress.state
                  ? ', ' + updated.accountHolder.mailingAddress.state
                  : ''}{' '}
                {updated.accountHolder.mailingAddress.postalCode}
                <br />
                {updated.accountHolder.mailingAddress.country}
              </span>
            </>
          ),
        },
      ];
    }
    if (details && item === 'Email' && !isCompleteOrApproved) {
      fieldList = [
        ...fieldList,
        {
          Value: () => (
            <span className="fields-title">
              {isPrimary ? 'Primary Account Holder Update' : 'Secondary Account Holder Update'}
            </span>
          ),
        },
        { Value: () => null },
        { Value: () => <>Original Value of {item}: </>, className: 'fields-title' },
        { Value: () => <>Update Value of {item}: </>, className: 'fields-title' },
        { Value: () => <span className="mute">{email}</span> },
        { Value: () => <span className="mute">{updated.accountHolder.email}</span> },
      ];
    }
    if (details && item === 'Email' && isCompleteOrApproved) {
      fieldList = [
        ...fieldList,
        {
          Value: () => (
            <span className="fields-title">
              {isPrimary ? 'Primary Account Holder Update' : 'Secondary Account Holder Update'}
            </span>
          ),
        },
        { Value: () => null },
        // { Value: () => <>Original Value of {item}: </>, className: 'fields-title' },
        { Value: () => <>Update Value of {item}: </>, className: 'fields-title' },

        { Value: () => null },
        { Value: () => <span className="mute">{updated.accountHolder.email}</span> },
      ];
    }
    if (details && item === 'PhoneNumber' && !isCompleteOrApproved) {
      let phonesValue = phones;
      let updatedTypeValue = updated.accountHolder.phones[0].type;
      if (phones === null) {
        phonesValue = [
          {
            phoneNumber: 'N/A',
            type: updatedTypeValue,
          },
        ];
      }
      let originalPhone: PhoneUpdate[] = phonesValue.filter(phone => phone.type === updatedTypeValue);

      if (originalPhone.length === 0) {
        originalPhone = [
          {
            phoneNumber: 'N/A',
            type: updatedTypeValue,
          },
        ];
      }
      fieldList = [
        ...fieldList,
        {
          Value: () => (
            <span className="fields-title">
              {isPrimary ? 'Primary Account Holder Update' : 'Secondary Account Holder Update'}
            </span>
          ),
        },
        { Value: () => null },
        {
          Value: () => (
            <>
              Original Value of {item.replace(/([A-Z])/g, ' $1').trim()} - {updatedTypeValue}:{' '}
            </>
          ),
          className: 'fields-title',
        },
        {
          Value: () => (
            <>
              Update Value of {item.replace(/([A-Z])/g, ' $1').trim()} - {updatedTypeValue}:{' '}
            </>
          ),
          className: 'fields-title',
        },

        { Value: () => <span className="mute">{formatPhoneNumber(originalPhone[0].phoneNumber)}</span> },
        { Value: () => <span className="mute">{formatPhoneNumber(updated.accountHolder.phones[0].phoneNumber)}</span> },
      ];
    }
    if (details && item === 'PhoneNumber' && isCompleteOrApproved) {
      let type = updated.accountHolder.phones[0].type;
      let originalPhone: PhoneUpdate[] = phones.filter(phone => phone.type === type);

      if (originalPhone.length === 0) {
        originalPhone = [
          {
            phoneNumber: 'No current value',
            type: type,
          },
        ];
      }
      fieldList = [
        ...fieldList,
        {
          Value: () => (
            <span className="fields-title">
              {isPrimary ? 'Primary Account Holder Update' : 'Secondary Account Holder Update'}
            </span>
          ),
        },
        { Value: () => null },
        {
          Value: () => (
            <>
              Update Value of {item.replace(/([A-Z])/g, ' $1').trim()} - {type}:{' '}
            </>
          ),
          className: 'fields-title',
        },

        { Value: () => null },
        { Value: () => <span className="mute">{formatPhoneNumber(updated.accountHolder.phones[0].phoneNumber)}</span> },
      ];
    }
    if (details && item === 'Financial') {
      fieldList = [
        ...fieldList,
        {
          Value: () => <>Updated Financial Information</>,
          className: 'fields-title',
        },
        {
          Value: () => null,
        },
        {
          Value: () => (
            <>
              <span className="props-title">Annual Income</span>
              <br />
              <span className="mute">{formatIncome(updated.annualIncome)}</span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="props-title">Total Net Worth</span>
              <br />
              <span className="mute">{formatIncome(updated.totalNetWorth)}</span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="props-title">Liquid Net Worth</span>
              <br />
              <span className="mute">{formatIncome(updated.liquidNetWorth)}</span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="props-title">Investment Objective</span>
              <br />
              {updated.investmentObjectiveType ? (
                <span className="mute">
                  {updated.investmentObjectiveType
                    ? updated.investmentObjectiveType.replace(/([A-Z])/g, ' $1').trim()
                    : 'No Investment Objective Type Found'}
                </span>
              ) : (
                <span className="mute">
                  {investmentObjectiveType
                    ? investmentObjectiveType.replace(/([A-Z])/g, ' $1').trim()
                    : 'No Investment Objective Type Found'}
                </span>
              )}
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="props-title">Risk Tolerance &amp; Liqudity Needs</span>
              <br />
              <span className="txt-sm">
                Risk Tolerance:{' '}
                <span className="mute">{updated.characteristics.risk ? updated.characteristics.risk : risk}</span>
                <br />
                Bracket:{' '}
                <span className="mute">
                  {formatTaxBracket(updated.characteristics.bracket ? updated.characteristics.bracket : bracket)}
                </span>
                <br />
                Horizion:{' '}
                <span className="mute">
                  {updated.characteristics.horizon ? updated.characteristics.horizon : horizon}
                </span>
                <br />
                Liquidity:{' '}
                <span className="mute">
                  {updated.characteristics.liquidity
                    ? updated.characteristics.liquidity.replace(/([A-Z])/g, ' $1').trim()
                    : liquidity.replace(/([A-Z])/g, ' $1').trim()}
                </span>
              </span>
            </>
          ),
        },
      ];
    }
    if (details && item === 'TradeGoal') {
      fieldList = [
        ...fieldList,
        {
          Value: () => <>Original Trade Goal</>,
          className: 'fields-title',
        },
        {
          Value: () => <>Updated Trade Goal</>,
          className: 'fields-title',
        },

        {
          Value: () => (
            <span className="mute">
              {tradeGoalType ? tradeGoalType.replace(/([A-Z])/g, ' $1').trim() : 'Original Trade Goal Type Not Found'}
            </span>
          ),
        },
        {
          Value: () => (
            <span className="mute">
              {updated.tradeGoalType
                ? updated.tradeGoalType.replace(/([A-Z])/g, ' $1').trim()
                : 'Updated Trade Goal Type Not Found'}
            </span>
          ),
        },
      ];
    }
    if (details && item === 'Suitability' && !isCompleteOrApproved) {
      fieldList = [
        ...fieldList,
        {
          Value: () => <>Original Suitability Info.</>,
          className: 'fields-title',
        },
        {
          Value: () => <>Updated Suitability Info.</>,
          className: 'fields-title',
        },

        {
          Value: () => (
            <span className="txt-sm">
              Risk Tolerance: <span className="mute">{characteristics.risk}</span>
              <br />
              Liquidity Needs:{' '}
              <span className="mute">
                {characteristics.liquidity
                  ? characteristics.liquidity.replace(/([A-Z])/g, ' $1').trim()
                  : 'Liquidity Needs Not Found'}
              </span>
              <br />
              Investment Objective: <span className="mute">{investmentObjectiveType}</span>
            </span>
          ),
        },

        {
          Value: () => (
            <span className="txt-sm">
              Risk Tolerance:{' '}
              <span className="mute">
                {updated.characteristics.risk ? updated.characteristics.risk : characteristics.risk}
              </span>
              <br />
              Liquidity Needs:{' '}
              <span className="mute">
                {updated.characteristics.liquidity
                  ? updated.characteristics.liquidity.replace(/([A-Z])/g, ' $1').trim()
                  : characteristics.liquidity.replace(/([A-Z])/g, ' $1').trim()}
              </span>
              <br />
              Investment Objective:{' '}
              <span className="mute">
                {updated.investmentObjectiveType
                  ? updated.investmentObjectiveType.replace(/([A-Z])/g, ' $1').trim()
                  : investmentObjectiveType.replace(/([A-Z])/g, ' $1').trim()}
              </span>
            </span>
          ),
        },
      ];
    }
    if (details && item === 'Suitability' && isCompleteOrApproved) {
      fieldList = [
        ...fieldList,
        {
          Value: () => <>Updated Suitability Info.</>,
          className: 'fields-title',
        },

        { Value: () => null },

        {
          Value: () => (
            <span className="txt-sm">
              Risk Tolerance:{' '}
              <span className="mute">
                {updated.characteristics.risk ? updated.characteristics.risk : characteristics.risk}
              </span>
              <br />
              Liquidity Needs:{' '}
              <span className="mute">
                {updated.characteristics.liquidity
                  ? updated.characteristics.liquidity.replace(/([A-Z])/g, ' $1').trim()
                  : characteristics.liquidity.replace(/([A-Z])/g, ' $1').trim()}
              </span>
              <br />
              Investment Objective:{' '}
              <span className="mute">
                {updated.investmentObjectiveType
                  ? updated.investmentObjectiveType.replace(/([A-Z])/g, ' $1').trim()
                  : investmentObjectiveType.replace(/([A-Z])/g, ' $1').trim()}
              </span>
            </span>
          ),
        },
      ];
    }
    if (details && item === 'IndustryEmployed') {
      fieldList = [
        ...fieldList,
        {
          Value: () => (
            <span className="fields-title">
              {isPrimary ? 'Primary Account Holder Update' : 'Secondary Account Holder Update'}
            </span>
          ),
        },
        { Value: () => null },
        {
          Value: () => <>Original Industry Employed Information</>,
          className: 'fields-title',
        },
        {
          Value: () => <>Updated Industry Employed Information</>,
          className: 'fields-title',
        },

        {
          Value: () => (
            <>
              <span>
                Industry Employed
                <br />
                <span className="mute">{formatBoolean(disclosures.industryEmployed)}</span>
                {accountHolder.disclosuresIndustryEmployedFirmName && (
                  <>
                    <br />
                    <span>
                      Firm Name
                      <br />
                      <span className="mute">{accountHolder.disclosuresIndustryEmployedFirmName}</span>
                    </span>
                  </>
                )}
              </span>
            </>
          ),
        },

        {
          Value: () => (
            <span>
              Industry Employed:{' '}
              <span className="mute">{formatBoolean(updated.accountHolder.disclosures.industryEmployed)}</span>
              <br />
              Firm Name
              <br />
              <span className="mute">{updated.accountHolder.disclosuresIndustryEmployedFirmName}</span>
            </span>
          ),
        },
      ];
    }
    if (details && item === 'Disclosures') {
      fieldList = [
        ...fieldList,
        {
          Value: () => (
            <span className="fields-title">
              {isPrimary ? 'Primary Account Holder Update' : 'Secondary Account Holder Update'}
            </span>
          ),
        },
        { Value: () => null },
        {
          Value: () => <>Updated Disclosure Information</>,
          className: 'fields-title',
        },
        {
          Value: () => null,
        },
        {
          Value: () => (
            <span>
              Stakeholder:{' '}
              <span className="mute">
                {updated.accountHolder.disclosures.stakeholder
                  ? formatBoolean(updated.accountHolder.disclosures.stakeholder)
                  : 'Updated Disclosure Response Not Found'}
              </span>
              <br />
              Stakeholder Company Ticker:{' '}
              <span className="mute">
                {updated.accountHolder.disclosuresStakeholderTickerSymbol || 'Not applicable'}
              </span>
              <br />
              Goverment Official:{' '}
              <span className="mute">
                {updated.accountHolder.disclosures.govOfficial
                  ? formatBoolean(updated.accountHolder.disclosures.govOfficial)
                  : 'Updated Disclosure Response Not Found'}
              </span>
              <br />
              Family Member(s):{' '}
              <span className="mute">
                {updated.accountHolder.disclosuresGovOfficialImmediateFamily || 'Not applicable'}
              </span>
              <br />
              Political Organization:{' '}
              <span className="mute">
                {updated.accountHolder.disclosuresGovOfficialPoliticalOrg || 'Not applicable'}
              </span>
              <br />
              IRS Withholding:{' '}
              <span className="mute">
                {updated.accountHolder.disclosures.irsWithholding
                  ? formatBoolean(updated.accountHolder.disclosures.irsWithholding)
                  : 'Updated Disclosure Response Not Found'}
              </span>
            </span>
          ),
        },
      ];
    }
    if (details && item === 'AccountDisclosures') {
      fieldList = [
        ...fieldList,

        {
          Value: () => <>Updated Account Disclosures Information</>,
          className: 'fields-title',
        },

        {
          Value: () => null,
        },

        {
          Value: () => (
            <span>
              Foreign Bank:{' '}
              <span className="mute">
                {formatBoolean(updated.accountDisclosures.foreignBank) || 'Updated Disclosure Response Not Found'}
              </span>
              <br />
              Foreign Bank US Agent:{' '}
              <span className="mute">{updated.disclosuresForeignBankUSAgent || 'Not applicable'}</span>
              <br />
              Foreign Shell Bank:{' '}
              <span className="mute">
                {formatBoolean(updated.accountDisclosures.foreignShellBank) || 'Updated Disclosure Response Not Found'}
              </span>
              <br />
              Foreign Financial Institution:{' '}
              <span className="mute">
                {formatBoolean(updated.accountDisclosures.foreignFinancialInstitution) ||
                  'Updated Disclosure Response Not Found'}
              </span>
              <br />
              Proprietary Securities Account:{' '}
              <span className="mute">
                {formatBoolean(updated.accountDisclosures.proprietarySecuritiesAccount) ||
                  'Updated Disclosure Response Not Found'}
              </span>
            </span>
          ),
        },
      ];
    }
    if (details && item === 'TradeAuthorization') {
      fieldList = [
        ...fieldList,

        {
          Value: () => <>Updated Trade Authorization Information</>,
          className: 'fields-title',
        },
        {
          Value: () => null,
        },
        {
          Value: () => (
            <>
              <span className="prop-title">Third Party Trade Authorization Granted</span>
              <span className="mute">{hasGrantedTradeAuth ? 'Yes' : 'No'}</span>{' '}
            </>
          ),
        },

        {
          Value: () => (
            <>
              <span className="prop-title">Agent Name</span>

              <span className="mute">{formatAgentName(agentName, hasGrantedTradeAuth)}</span>
            </>
          ),
        },
      ];
    }

    if (hasGrantedTradeAuth) {
      fieldList = [
        ...fieldList,
        {
          Value: () => (
            <>
              <span className="prop-title">Trade Authorization Type</span>
              <span className="mute">{tradingAuthorization.type}</span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="prop-title">Email</span>
              <span className="mute">{tradingAuthorization.email}</span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="prop-title">Address</span>
              <span className="mute">
                {tradingAuthorization.address && tradingAuthorization.address.address1
                  ? tradingAuthorization.address.address1
                  : 'N/A'}
              </span>
              <br />
              {tradingAuthorization.address && tradingAuthorization.address.address2 && (
                <>
                  <span className="mute">{tradingAuthorization.address.address2}</span>
                  <br />
                </>
              )}
              <span className="mute">
                {tradingAuthorization.address && tradingAuthorization.address.city
                  ? tradingAuthorization.address.city
                  : ''}
                ,{' '}
                {tradingAuthorization.address && tradingAuthorization.address.state
                  ? tradingAuthorization.address.state
                  : ''}{' '}
                {tradingAuthorization.address && tradingAuthorization.address.postalCode
                  ? tradingAuthorization.address.postalCode
                  : ''}
              </span>
              <br />
              <span className="mute">
                {tradingAuthorization.address && tradingAuthorization.address.country
                  ? tradingAuthorization.address.country
                  : 'N/A'}{' '}
              </span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="prop-title">Phone Numbers</span>
              <span className="txt-sm">
                <span>Home: </span>
                <span className="mute">
                  {formatPhoneNumber(getAgentPhoneNumbersByType('Home', updated.tradingAuthorization))}
                </span>{' '}
              </span>
              <br />
              <span className="txt-sm">
                <span>Mobile: </span>
                <span className="mute">
                  {formatPhoneNumber(getAgentPhoneNumbersByType('Mobile', updated.tradingAuthorization))}
                </span>{' '}
              </span>
              <br />
              <span className="txt-sm">
                <span>Work: </span>
                <span className="mute">
                  {formatPhoneNumber(getAgentPhoneNumbersByType('Work', updated.tradingAuthorization))}
                </span>{' '}
              </span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="prop-title">Employment Type</span>
              <span className="mute">{updated.tradingAuthorization.employmentType}</span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="prop-title">Employer Name</span>
              <span className="mute">
                {updated.tradingAuthorization.employer ? updated.tradingAuthorization.employer : 'N/A'}
              </span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="prop-title">Citizenship Country</span>
              <span className="mute">{updated.tradingAuthorization.citizenshipCountry}</span>
              {updated.tradingAuthorization.citizenshipCountry !== Country.UnitedStatesOfAmerica && (
                <>
                  <br />
                  <span className="prop-title">Foreign Tax ID Country</span>
                  <span className="mute">{updated.tradingAuthorization.foreignTaxIdCountry}</span>
                  <br />
                  <span className="prop-title">Foreign Tax ID</span>
                  <span className="mute">{updated.tradingAuthorization.foreignTaxIdNumber}</span>
                </>
              )}
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="prop-title">Date of Birth</span>
              <span className="mute">
                {updated.tradingAuthorization.dateOfBirth
                  ? formatDateWithoutTime(updated.tradingAuthorization.dateOfBirth)
                  : 'Date not found'}
              </span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="prop-title">Disclosures</span>
              <span className="txt-sm">
                Is the agent employed by or a member of a registered broker-dealer, securities or futures exchange, or
                securities industry regulatory body (e.g., FINRA, NFA)?{' '}
                <span className="mute">
                  {updated.tradingAuthorization.disclosures && updated.tradingAuthorization.disclosures.industryEmployed
                    ? 'Yes'
                    : 'No'}
                </span>
              </span>

              {updated.tradingAuthorization.disclosures && updated.tradingAuthorization.disclosures.industryEmployed && (
                <>
                  <br />
                  <span className="txt-sm">
                    Firm name:{' '}
                    <span className="mute">{updated.tradingAuthorization.disclosuresIndustryEmployedFirmName}</span>
                  </span>
                </>
              )}
              <br />
              <br />
              <span className="txt-sm">
                Is the agent a 10% shareholder, director, and/or policy maker of a publicly-owned company?{' '}
                <span className="mute">
                  {updated.tradingAuthorization.disclosures && updated.tradingAuthorization.disclosures.stakeholder
                    ? 'Yes'
                    : 'No'}
                </span>
              </span>
              {updated.tradingAuthorization.disclosures && updated.tradingAuthorization.disclosures.stakeholder && (
                <>
                  <br />
                  <span className="txt-sm">
                    Ticker symbol:{' '}
                    <span className="mute">
                      {updated.tradingAuthorization.disclosures &&
                        updated.tradingAuthorization.disclosuresStakeholderTickerSymbol}
                    </span>
                  </span>
                </>
              )}
            </>
          ),
          hide: updated.tradingAuthorization.type === TradeAuthorizationType.Limited,
        },
      ];
    }

    // TODO: Implement the new tradingAuthorization fields
    // {
    //   Value: () => (
    //     <>
    //       <span className="props-title">Trade Authorization</span>
    //       <br />
    //       <span className="mute">
    //         {updated.tradeAuthorization
    //           ? formatBoolean(updated.tradeAuthorization)
    //           : 'Original Disclosure Response Not Found'}
    //       </span>
    //       <br />

    //       <br />
    //     </>
    //   ),
    // },

    // {
    //   Value: () => (
    //     <>
    //       <span className="props-title">Agent Name</span>
    //       <br />
    //       <span className="mute">{updated.tradeAuthorizationAgentName || 'Not applicable'}</span>
    //     </>
    //   ),
    // },

    if (details && item === 'Employment' && updated.accountHolder.employmentType !== 'Employed') {
      fieldList = [
        ...fieldList,
        {
          Value: () => <>Updated Employment Information</>,
          className: 'fields-title',
        },

        {
          Value: () => null,
        },

        {
          Value: () => (
            <span>
              Employment Type: <span className="mute">{updated.accountHolder.employmentType}</span>
            </span>
          ),
        },
      ];
    }
    if (details && item === 'Employment' && updated.accountHolder.employmentType === 'Employed') {
      fieldList = [
        ...fieldList,
        {
          Value: () => <>Updated Employment Information</>,
          className: 'fields-title',
        },

        {
          Value: () => null,
        },

        {
          Value: () => (
            <span>
              Employment Type
              <br />
              <span className="mute">{updated.accountHolder.employmentType}</span>
            </span>
          ),
        },
        {
          Value: () => (
            <>
              Job Position
              <br />
              <span className="mute">
                {updated.accountHolder.jobPosition ? updated.accountHolder.jobPosition : 'Not Found'}
              </span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="props-title">Years Employed</span>
              <br />
              <span className="mute">
                {updated.accountHolder.yearsEmployed
                  ? formatYearsEmployed(updated.accountHolder.yearsEmployed)
                  : 'Not Found'}
              </span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              Employer
              <br />
              <span className="mute">
                {updated.accountHolder.employer ? updated.accountHolder.employer : 'Not Found'}
              </span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              Employer Address
              <br />
              <span className="mute">
                {updated.accountHolder.employerAddress.address1
                  ? updated.accountHolder.employerAddress.address1
                  : 'Not Found'}
                <br />
                {updated.accountHolder.employerAddress.address2 ? (
                  updated.accountHolder.employerAddress.address2
                ) : (
                  <></>
                )}
                {updated.accountHolder.employerAddress.address2 ? <br /> : <></>}
                {updated.accountHolder.employerAddress.city}
                {updated.accountHolder.employerAddress.state
                  ? ', ' + updated.accountHolder.employerAddress.state
                  : ''}{' '}
                {updated.accountHolder.employerAddress.postalCode}
                <br />
                {updated.accountHolder.employerAddress.country}
              </span>
            </>
          ),
          hide: !updated.accountHolder.employerAddress,
        },
      ];
    }
    if (details && item === 'TrustedContact') {
      fieldList = [
        ...fieldList,

        {
          Value: () => <>Updated Trusted Contact Information</>,
          className: 'fields-title',
        },

        {
          Value: () => null,
        },

        {
          Value: () => (
            <>
              <span className="props-title">Name</span>
              <br />
              <span className="mute">
                {updated.trustedContact.firstName} {updated.trustedContact.lastName}
              </span>
              <br />
              <span className="props-title">Email</span>
              <br />
              <span className="mute">{updated.trustedContact.email}</span>
              <br />
              <span className="props-title">Phone Number</span>
              <br />
              <span className="mute">{formatPhoneNumber(updated.trustedContact.phones[0].phoneNumber)}</span>
            </>
          ),
        },

        {
          Value: () => (
            <>
              <span>
                <span className="props-title">Address</span>
              </span>
              <br />
              <span className="mute">
                {updated.trustedContact.address.address1 ? updated.trustedContact.address.address1 : 'Not Found'}
                <br />
                {updated.trustedContact.address.address2 ? updated.trustedContact.address.address2 : <></>}
                {updated.trustedContact.address.address2 ? <br /> : <></>}
                {updated.trustedContact.address.city}
                {updated.trustedContact.address.state ? ', ' + updated.trustedContact.address.state : ''}{' '}
                {updated.trustedContact.address.postalCode}
                <br />
                {updated.trustedContact.address.country}
              </span>
            </>
          ),
        },
      ];
    }
    if (details && item === 'Experience') {
      fieldList = [
        ...fieldList,

        {
          Value: () => <>Updated Trade Experience Information</>,
          className: 'fields-title',
        },

        {
          Value: () => null,
        },

        {
          Value: () => (
            <>
              <span className="prop-title">Bonds</span>
              <span className="txt-sm">Years Trading:</span>{' '}
              <span className="mute txt-sm">{formatYearsTrading(updated.experience.bonds.years)}</span>{' '}
              {updated.experience.bonds.tradesPerYear && (
                <>
                  <br />
                  <span className="txt-sm">Trades per Year:</span>{' '}
                  <span className="mute txt-sm">{formatTradesPerYear(updated.experience.bonds.tradesPerYear)}</span>
                </>
              )}
            </>
          ),
          hide: !updated.experience.bonds,
        },

        {
          Value: () => (
            <>
              <span className="prop-title">Options</span>
              <span className="txt-sm">Years Trading:</span>{' '}
              <span className="mute txt-sm">{formatYearsTrading(updated.experience.options.years)}</span>{' '}
              {updated.experience.options.tradesPerYear && (
                <>
                  <br />
                  <span className="txt-sm">Trades per Year:</span>{' '}
                  <span className="mute txt-sm">{formatTradesPerYear(updated.experience.options.tradesPerYear)}</span>
                </>
              )}
            </>
          ),
          hide: !updated.experience.options,
        },

        {
          Value: () => (
            <>
              <span className="prop-title">Stocks</span>
              <span className="txt-sm">Years Trading:</span>{' '}
              <span className="mute txt-sm">{formatYearsTrading(updated.experience.stocks.years)}</span>{' '}
              {updated.experience.stocks.tradesPerYear && (
                <>
                  <br />
                  <span className="txt-sm">Trades per Year:</span>{' '}
                  <span className="mute txt-sm">{formatTradesPerYear(updated.experience.stocks.tradesPerYear)}</span>
                </>
              )}
            </>
          ),
          hide: !updated.experience.stocks,
        },

        {
          Value: () => (
            <>
              <span className="prop-title">Commodities</span>
              <span className="txt-sm">Years Trading:</span>{' '}
              <span className="mute txt-sm">{formatYearsTrading(updated.experience.commodities.years)}</span>{' '}
              {updated.experience.commodities.tradesPerYear && (
                <>
                  <br />
                  <span className="txt-sm">Trades per Year:</span>{' '}
                  <span className="mute txt-sm">
                    {formatTradesPerYear(updated.experience.commodities.tradesPerYear)}
                  </span>{' '}
                </>
              )}
            </>
          ),
          hide: !updated.experience.commodities,
        },

        {
          Value: () => (
            <>
              <span className="prop-title">Margins</span>
              <span className="txt-sm">Years Trading:</span>{' '}
              <span className="mute txt-sm">{formatYearsTrading(updated.experience.margins.years)}</span>
              {updated.experience.margins.tradesPerYear && (
                <>
                  <br />
                  <span className="txt-sm">Trades per Year:</span>{' '}
                  <span className="mute txt-sm">{formatTradesPerYear(updated.experience.margins.tradesPerYear)}</span>
                </>
              )}
            </>
          ),
          hide: !updated.experience.margins,
        },
      ];
    }
    if (details && item === 'Flexability') {
      fieldList = [
        ...fieldList,

        {
          Value: () => <>Original Flexibility Type</>,
          className: 'fields-title',
        },

        {
          Value: () => <>Updated Flexibility Type</>,
          className: 'fields-title',
        },

        {
          Value: () => <span className="mute">{original.flexibilityType.replace(/([A-Z])/g, ' $1').trim()}</span>,
          hide: !original.flexibilityType,
        },

        {
          Value: () => <span className="mute">{updated.flexibilityType.replace(/([A-Z])/g, ' $1').trim()}</span>,
          hide: !updated.flexibilityType,
        },
      ];
    }
    if (details && item === 'ServiceProfile') {
      fieldList = [
        ...fieldList,

        {
          Value: () => <>Original Service Profile Values</>,
          className: 'fields-title',
        },

        {
          Value: () => <>Updated Service Profile Values</>,
          className: 'fields-title',
        },

        {
          Value: () => (
            <>
              Issuer Direct Communication
              <br />
              <span className="mute">{original.serviceProfile.issuerDirectCommunication || 'Not Found'}</span>
            </>
          ),
          hide: !original.serviceProfile,
        },

        {
          Value: () => (
            <>
              Issuer Direct Communication
              <br />
              <span className="mute">{updated.serviceProfile.issuerDirectCommunication || 'Not Found'}</span>
            </>
          ),
          hide: !updated.serviceProfile,
        },
      ];
    }
    if (details && item === 'Beneficiaries') {
      fieldList = [
        ...fieldList,

        {
          Value: () => <>Updated Beneficiaries</>,
          className: 'fields-title',
        },

        {
          Value: () => null,
        },
      ];
      if (updated.beneficiaries) {
        updated.beneficiaries.map((b, i) => {
          fieldList = [
            ...fieldList,
            {
              Value: () => (
                <>
                  {b.beneficiaryType === BeneficiaryType.Individual && (
                    <span className={`individualBeneficiary-${i}`}>
                      <span className="mute">
                        {b.firstName} {b.middleInitial || ''} {b.lastName} {b.suffix || ''}{' '}
                        <span className="txt-sm">({b.beneficiaryAccountType})</span>
                      </span>
                      <br />
                      <span>
                        Relationship: <span className="mute">{b.relationship ? b.relationship : 'Not found'}</span>
                      </span>
                      <br />
                      <span>
                        Date of Birth: <span className="mute">{b.dateOfBirth && <>{b.dateOfBirth}</>}</span>
                      </span>
                      <br />
                      <span>
                        SSN/ITIN: <span className="mute">{b.ssnSecret ? maskSSN(b.ssnSecret) : 'Not found'}</span>
                      </span>
                      <br />
                      <span>
                        Percent: <span className="mute">{b.percent ? b.percent : 'Not found'}</span>
                      </span>
                      <br />
                      <br />
                      <span>
                        Address
                        <br />
                        <span className="mute">
                          {b && b.address && b.address.address1 && (
                            <>
                              {b && b.address && b.address.address1}
                              <br />
                            </>
                          )}
                          {b && b.address && b.address.address2 && (
                            <>
                              {b.address.address2}
                              <br />
                            </>
                          )}
                          {b && b.address && b.address.city}
                          {b && b.address && b.address.state ? ', ' + b.address.state : ''}{' '}
                          {b && b.address && b.address.postalCode}
                          <br />
                          {b && b.address && b.address.country}
                        </span>
                      </span>
                    </span>
                  )}
                  {b.beneficiaryType === BeneficiaryType.Trust && (
                    <span className={`trustBeneficiary-${i}`}>
                      <span className="props-title">
                        {b.beneficiaryAccountType} - {b.beneficiaryType}
                      </span>
                      <br />
                      <br />
                      <span className="mute">
                        {b.trustName ? b.trustName : 'Not found'}
                        <br />
                        {b.einSecret ? b.einSecret : 'Not found'}
                      </span>
                      <br />
                      <span>
                        Percent: <span className="mute">{b.percent ? b.percent : 'Not found'}</span>
                      </span>
                      <br />
                      <br />
                      <span>
                        Address
                        <br />
                        <span className="mute">
                          {b && b.address && b.address.address1 && (
                            <>
                              {b && b.address && b.address.address1}
                              <br />
                            </>
                          )}
                          {b && b.address && b.address.address2 && (
                            <>
                              {b.address.address2}
                              <br />
                            </>
                          )}
                          {b && b.address && b.address.city}
                          {b && b.address && b.address.state ? ', ' + b.address.state : ''}{' '}
                          {b && b.address && b.address.postalCode}
                          <br />
                          {b && b.address && b.address.country}
                        </span>
                      </span>
                    </span>
                  )}
                </>
              ),
            },
          ];
        });
      }
    }
    if (details && item === 'PersonalInformation') {
      fieldList = [
        ...fieldList,
        {
          Value: () => <>Updated Personal Information Values</>,
          className: 'fields-title',
        },
        {
          Value: () => null,
        },
        {
          Value: () => (
            <>
              <span className="prop-title">Marital Status</span>
              <span className="mute">{updated.accountHolder.maritalStatus || 'Not Found'}</span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="prop-title">Number of Dependents</span>
              <span className="mute">{updated.accountHolder.dependents || 'Not Found'}</span>
            </>
          ),
        },
      ];
    }
    if (details && item === 'BirthCountry') {
      fieldList = [
        ...fieldList,
        {
          Value: () => <>Original Birth Country</>,
          className: 'fields-title',
        },
        {
          Value: () => <>Updated Birth Country</>,
          className: 'fields-title',
        },
        {
          Value: () => (
            <>
              <span className="mute">
                {getCountryNameByCode(original.accountHolder.birthCountry as Country) || 'Not Found'}
              </span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="mute">
                {getCountryNameByCode(updated.accountHolder.birthCountry as Country) || 'Not Found'}
              </span>
            </>
          ),
        },
      ];
    }
    if (details && item === 'CitizenshipCountry') {
      fieldList = [
        ...fieldList,
        {
          Value: () => <>Original Citizenship Country</>,
          className: 'fields-title',
        },
        {
          Value: () => <>Updated Citizenship Country</>,
          className: 'fields-title',
        },
        {
          Value: () => (
            <>
              <span className="mute">
                {getCountryNameByCode(original.accountHolder.citizenshipCountry as Country) || 'Not Found'}
              </span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="mute">
                {getCountryNameByCode(updated.accountHolder.citizenshipCountry as Country) || 'Not Found'}
              </span>
            </>
          ),
        },
      ];
    }
    return fieldList;
  }, [details, isCompleteOrApproved, updated, original, isFetching]);

  if (isFetching) return <Loading />;

  return (
    <div className="screen-body-section">
      <DetailFields fieldList={fields} />
    </div>
  );
};
