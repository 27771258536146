import _ from 'lodash';
import { OptionType, DirectionRules, OrderAction } from '@tradingblock/types';

var buildDirectionErrors = function buildDirectionErrors(strikes, sch) {
  return function (message) {
    var errs = [{
      keyword: 'action',
      message: message,
      params: {
        strikes: strikes,
        rule: DirectionRules[sch]
      }
    }]; // currentValidatorFunc.errores = currentValidatorFunc.errores ? [...currentValidatorFunc.errores, ...errs] : errs;

    return errs;
  };
};

var validateRules = function validateRules(rule, legs, actions, getErrors) {
  var shouldBeDifferent = rule === DirectionRules.DifferentDirections;

  if (shouldBeDifferent) {
    var isValid = _.uniq(actions).length === legs.length;

    if (!isValid) {
      return getErrors('Actions must be different');
    }
  } else if (rule === DirectionRules.SameDirections) {
    var _isValid = _.uniq(actions).length === 1;

    if (!_isValid) {
      return getErrors('Actions must be the same');
    }
  } else if (rule === DirectionRules.Butterfly) {
    // butterfly should have two legs with the same action and one leg with the opposite action
    var actionCounts = _.countBy(actions);

    var _isValid2 = actionCounts[OrderAction.Buy] === 2 && actionCounts[OrderAction.Sell] === 1 || actionCounts[OrderAction.Buy] === 1 && actionCounts[OrderAction.Sell] === 2; // in addition, the leg with the opposite action must be the leg with the highest spread ratio


    var legsBySpreadRatio = _.sortBy(legs, function (l) {
      return l.SpreadRatio;
    });

    var highestSpreadRatioLeg = _.last(legsBySpreadRatio);

    var otherLegs = _.filter(legsBySpreadRatio, function (l) {
      return l !== highestSpreadRatioLeg;
    }); // if the highest spread ratio leg is a buy, then the other two legs must be sells and vice versa


    var isValidLegs = highestSpreadRatioLeg.Action === OrderAction.Buy ? otherLegs[0].Action === OrderAction.Sell && otherLegs[1].Action === OrderAction.Sell : otherLegs[0].Action === OrderAction.Buy && otherLegs[1].Action === OrderAction.Buy;

    if (!_isValid2) {
      return getErrors('Butterfly must have two legs with the same action and one leg with the opposite action');
    }

    if (!isValidLegs) {
      return getErrors('The leg with the highest spread ratio must be the opposite action of the other two legs');
    }
  } else if (rule === DirectionRules.Condor) {
    // condor should have two legs with the same action and two legs with the opposite action
    var _actionCounts = _.countBy(actions);

    var _isValid3 = _actionCounts[OrderAction.Buy] === 2 && _actionCounts[OrderAction.Sell] === 2; // in addition, the middle two strikes must have the same action


    var legsByStrike = _.sortBy(legs, function (l) {
      return l.Strike;
    });

    var middleTwoStrikes = _.slice(legsByStrike, 1, 3);

    var middleTwoStrikesActions = _.map(middleTwoStrikes, function (l) {
      return l.Action;
    });

    var isValidStrikes = _.uniq(middleTwoStrikesActions).length === 1;

    if (!_isValid3) {
      return getErrors('Condor must have two legs with the same action and two legs with the opposite action');
    }

    if (!isValidStrikes) {
      return getErrors('The middle two strikes must have the same action');
    }
  } else if (rule === DirectionRules.Iron) {
    // iron butterfly/condor consists of four legs, 2 calls and 2 puts
    // the calls should have opposite actions as should the puts
    // Sell Call, Buy Call, Sell Put, Buy Put
    var callLegs = _.filter(legs, function (l) {
      return l.OptionType === OptionType.Call;
    });

    var putLegs = _.filter(legs, function (l) {
      return l.OptionType === OptionType.Put;
    });

    var callActions = _.map(callLegs, function (l) {
      return l.Action;
    });

    var putActions = _.map(putLegs, function (l) {
      return l.Action;
    });

    var _isValid4 = callActions.length === 2 && putActions.length === 2 && callActions[0] !== callActions[1] && putActions[0] !== putActions[1];

    if (!_isValid4) {
      return getErrors('Iron Butterfly must have two calls and two puts with opposite actions');
    }
  }

  return null;
};

var tryValidateRules = function tryValidateRules(rule, legs) {
  var actions = legs.map(function (l) {
    return l.Action;
  });
  var getStrikeErrors = buildDirectionErrors(actions, rule);

  try {
    return validateRules(rule, legs, actions, getStrikeErrors);
  } catch (err) {
    return getStrikeErrors("Error occurred while validating: ".concat(JSON.stringify(err)));
  }
};

export var directionValidation = function directionValidation(sch) {
  return function directionValidation(data) {
    if (_.get(directionValidation, 'errors', null) === null) {}

    var legs = data;
    var rules = _.isArray(sch) ? sch : [sch];

    var errors = _.reduce(rules, function (errors, rule) {
      var ruleErrors = tryValidateRules(rule, legs);

      if (ruleErrors === null) {
        return errors;
      }

      return _.concat(errors, ruleErrors);
    }, []);

    if (errors.length > 0) {
      directionValidation['errors'] = errors;
      return false;
    }

    return true;
  };
};