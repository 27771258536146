import React, { useMemo } from 'react';
import _ from 'lodash';
import { useStateSelector } from '../../../../../data/global/dataSelectors';
import { BooleanToggleType, DefaultApplicationModel } from '@tradingblock/types';
import { DetailField, DetailFields } from '../../../../../components/basic/DetailFields';
import {
  formatIncome,
  formatTaxBracket,
  formatTradesPerYear,
  formatYearsTrading,
} from '../../../../../utilities/accountManagement';

export const ApplicationInvestingProfile: React.FunctionComponent<{}> = () => {
  const application = useStateSelector(s => s.private.applications.currentItem.application);
  const {
    characteristics,
    annualIncome,
    liquidNetWorth,
    totalNetWorth,
    investmentObjectiveType,
    tradeGoalType,
  } = application;
  const { risk, bracket, horizon, liquidity } = characteristics || DefaultApplicationModel.characteristics;

  const { experience } = application;

  const fields = useMemo(() => {
    let fieldList: DetailField[] = [];
    if (application) {
      fieldList = [
        ...fieldList,

        {
          Value: () => (
            <>
              <div className="fields-title">Investing Profile</div>

              <div className="mb-3">
                <div>Trade Level Requested</div>
                <div className="mute">
                  {tradeGoalType ? tradeGoalType.replace(/([A-Z])/g, ' $1').trim() : 'Trade Goal Type Not Found'}
                </div>
              </div>

              <div className="mb-3">
                <div>Investment Objective</div>
                <div className="mute">
                  {investmentObjectiveType
                    ? investmentObjectiveType.replace(/([A-Z])/g, ' $1').trim()
                    : 'Investment Objective Type Not Found'}
                </div>
              </div>

              <div className="fields-title">Income and Net Worth</div>

              <div className="mb-3">
                <div>Annual Income</div>
                <div className="mute">{annualIncome ? formatIncome(annualIncome) : 'Annual Income Not Found'}</div>
              </div>

              <div className="mb-3">
                <div>Total Net Worth</div>
                <div className="mute">{totalNetWorth ? formatIncome(totalNetWorth) : 'Total Net Worth Not Found'}</div>
              </div>

              <div className="mb-3">
                <div>Liquid Net Worth</div>
                <div className="mute">
                  {liquidNetWorth ? formatIncome(liquidNetWorth) : 'Liquid Net Worth Not Found'}
                </div>
              </div>

              <div className="fields-title">Investor Characteristics</div>

              <div className="mb-3">
                <div>Risk Tolerance</div>
                <div className="mute">{risk}</div>
              </div>

              <div className="mb-3">
                <div>Tax Bracket</div>
                <div className="mute">{bracket ? formatTaxBracket(bracket) : 'Tax Bracket Not Found'}</div>
              </div>

              <div className="mb-3">
                <div>Time Horizon</div>
                <div className="mute">{horizon ? horizon : 'Time Horizon Not Found'}</div>
              </div>

              <div className="mb-3">
                <div>Liquidity Needs</div>
                <div className="mute">
                  {liquidity ? liquidity.replace(/([A-Z])/g, ' $1').trim() : 'Liquidity Needs Not Found'}
                </div>
              </div>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <div className="fields-title">Trading Experience</div>

              <div className="trading-experience-section">
                <div className="prop-title" style={{ fontWeight: 'bold' }}>
                  Bonds
                </div>
                <div className="mb-3">
                  <div>Years Trading</div>
                  <div className="mute">
                    {experience && experience.bonds.years ? formatYearsTrading(experience.bonds.years) : 'N/A'}
                  </div>
                  {experience && experience.bonds.tradesPerYear && (
                    <div>
                      <div>Trades per Year</div>
                      <div className="mute">
                        {experience && experience.bonds.tradesPerYear
                          ? formatTradesPerYear(experience.bonds.tradesPerYear)
                          : 'N/A'}
                      </div>
                    </div>
                  )}
                </div>

                <div className="prop-title" style={{ fontWeight: 'bold' }}>
                  Options
                </div>
                <div className="mb-3">
                  <div>Years Trading</div>
                  <div className="mute">
                    {experience && experience.options.years ? formatYearsTrading(experience.options.years) : 'N/A'}
                  </div>
                  {experience && experience.options.tradesPerYear && (
                    <div className="mb-3">
                      <div>Trades per Year</div>
                      <div className="mute">
                        {experience && experience.options.tradesPerYear
                          ? formatTradesPerYear(experience.options.tradesPerYear)
                          : 'N/A'}
                      </div>
                    </div>
                  )}
                </div>

                <div className="prop-title" style={{ fontWeight: 'bold' }}>
                  Stocks
                </div>
                <div className="mb-3">
                  <div>Years Trading</div>
                  <div className="mute">
                    {experience && experience.stocks.years ? formatYearsTrading(experience.stocks.years) : 'N/A'}
                  </div>
                  {experience && experience.stocks.tradesPerYear && (
                    <div>
                      <div>Trades per Year</div>
                      <div className="mute">
                        {experience && experience.stocks.tradesPerYear
                          ? formatTradesPerYear(experience.stocks.tradesPerYear)
                          : 'N/A'}
                      </div>
                    </div>
                  )}
                </div>

                <div className="prop-title" style={{ fontWeight: 'bold' }}>
                  Commodities
                </div>
                <div className="mb-3">
                  <div>Years Trading</div>
                  <div className="mute">
                    {experience && experience.commodities.years
                      ? formatYearsTrading(experience.commodities.years)
                      : 'N/A'}
                  </div>
                  {experience && experience.commodities.tradesPerYear && (
                    <div>
                      <div>Trades per Year</div>
                      <div className="mute">
                        {experience && experience.commodities.tradesPerYear
                          ? formatTradesPerYear(experience.commodities.tradesPerYear)
                          : 'N/A'}
                      </div>
                    </div>
                  )}
                </div>

                <div className="prop-title" style={{ fontWeight: 'bold' }}>
                  Margins
                </div>
                <div className="mb-3">
                  <div>Years Trading</div>
                  <div className="mute">
                    {experience && experience.margins.years ? formatYearsTrading(experience.margins.years) : 'N/A'}
                  </div>
                  {experience && experience.margins.tradesPerYear && (
                    <div>
                      <div>Trades per Year: </div>
                      <div className="mute">
                        {experience && experience.margins.tradesPerYear
                          ? formatTradesPerYear(experience.margins.tradesPerYear)
                          : 'N/A'}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          ),
        },
      ];
    }
    return fieldList;
  }, [application]);

  return (
    <div className="screen-body-section">
      <DetailFields fieldList={fields} valueAs="div" />
    </div>
  );
};
