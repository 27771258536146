/* eslint-disable react-hooks/exhaustive-deps */
import { useStateSelector } from '../../../data/global/dataSelectors';
import { blockSettings } from '../../../data/global/selectors/settingSelectors';
import { useBlockId } from '../../../components/blocks/BlockState';

export const useInfoBlockSettings = (blockId?: string) => {
  return useStateSelector(s => blockSettings.info(s, blockId));
};
export const useInfoSettings = () => {
  const blockId = useBlockId();
  return useInfoBlockSettings(blockId);
};
