import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { DropdownItemType, ToggleButton, Number } from '@tradingblock/components';
import { AssetType, OptionType, isCustomStrategy, Expiration } from '@tradingblock/types';
import { useOrderActions } from '../state/useOrderActions';
import { LegSelector } from '../state/OrderLegSelector';
import { useLegsActions } from '../state/useLegsActions';
import { useOrderLegData } from '../state/useOrderLegData';
import { OrderSelectors } from '../state/OrderSelector';
import { OrderState, useOrderData } from '../state/OrderState';
import { BlockPropTitle } from '../../../components/blocks/blockProp/BlockProp';
import { useBlockData } from '../../../components/blocks/BlockState';
import { OrderLegExpiration } from './Legs/Expiration';
import { OrderLegStrikes } from './Legs/Strikes';
import { LegAction } from './Legs/Action';
import { LegQuantity } from './Legs/Quantity';
import { LegPosition } from './Legs/Position';
import { LegPrices } from './Legs/BidAsk';
import { useGroupAssetSymbol } from '../../useGroupState';
import { OrderQuantity } from './Order/OrderQuantity';
import { HasEquityLeg } from '../OrderUtilities';
import { useOrderSettings } from '../hooks/useOrderSettings';
import { useEffect } from 'react';
import { PositionDTO } from '@tradingblock/api';

interface LegAssetType extends DropdownItemType {
  type: AssetType;
  optionType?: OptionType;
}

const assetTypes: LegAssetType[] = [
  { name: 'shares', value: 'Shares', type: AssetType.Equity },
  { optionType: OptionType.Call, name: 'call', value: 'Call', type: AssetType.Option },
  { optionType: OptionType.Put, name: 'put', value: 'Put', type: AssetType.Option },
];

export const useAssetTypes = (id: string) => {
  const { legs } = useBlockData<OrderState>();
  const legAssetType = LegSelector.AssetType(useBlockData<OrderState>(), id);
  return legAssetType !== AssetType.Equity && HasEquityLeg(legs)
    ? assetTypes.filter(at => at.type !== AssetType.Equity)
    : assetTypes;
};

// export const LegSync = (id: string) => {
//   const leg = useOrderData(state => state.legs[id]);
//   useEffect(() => {
//     console.log('leg changed', { Action: OrderAction[leg.Action], leg });
//   }, [leg]);
//   return leg;
// };

export const OrderLeg: React.FC<{
  id: string;
  price: number;
  invalid?: boolean;
  positions: PositionDTO[];
  setNoExpirations?: (val: boolean) => any;
}> = ({ id, price, invalid, positions, setNoExpirations }) => {
  const symbol = useGroupAssetSymbol();

  const isCustomStrategyOrUndefined = useOrderData(OrderSelectors.isCustomStrategyOrUndefined);
  const isSingleLeggedStrategy = useOrderData(OrderSelectors.isSingleLegStrategy);

  const { setExpiration, setStrike } = useOrderActions();

  const { strike, expiration } = useOrderLegData(id);
  const { remove } = useLegsActions();
  const legAssetType = LegSelector.AssetType(useBlockData<OrderState>(), id);
  const legOptionType = LegSelector.OptionType(useBlockData<OrderState>(), id);

  const assetType = useMemo(
    () => assetTypes.find(at => at.type === legAssetType && at.optionType === legOptionType) || assetTypes[0],
    [legAssetType, legOptionType]
  );

  const isOption = useMemo(() => symbol && assetType && assetType.type === AssetType.Option, [assetType, symbol]);
  const strategy = useOrderData((s: OrderState) => s.strategy);
  const isCustom = useMemo(() => (strategy === undefined || isCustomStrategy(strategy) ? true : false), [strategy]);
  //TODO: temporary fix for custom strategies with no legs causing white screen, will review and provide a better solution soon
  const lastLeg = useOrderData(OrderSelectors.legs).length > 1 && true;

  // useLegOptionDefaults(id, symbol, price);
  // useStrikeLoader(symbol, expiration);
  const setLegStrike = useCallback(
    (value: number) => {
      localStorage.setItem(`leg-strike-${id}`, JSON.stringify(value));
      setStrike(id, value);
    },
    [setStrike, id]
  );
  let strikeStored: any = localStorage.getItem(`leg-strike-${id}`);
  const setLegExpiration = useCallback(
    (date: Expiration) => {
      setExpiration(id, date);
    },
    [setExpiration, id]
  );

  useEffect(() => {
    if (!strike) {
      setStrike(id, JSON.parse(strikeStored));
    }
  }, []);

  const validationClass = useMemo(() => (invalid ? ' invalid' : ''), [invalid]);

  const enableLegQuantity = useMemo(() => {
    return isCustom || isSingleLeggedStrategy;
  }, [isCustom, isSingleLeggedStrategy]);

  const { modernBidAskLabelOrientation } = useOrderSettings();

  return (
    <div className={`order-leg${validationClass}`}>
      <div className="order-leg-setting order-leg-buysell">
        <LegAction id={id} />
      </div>
      {/* <div className="order-leg-setting order-leg-buysell">
        <LegSpreadRatio id={id} />  
      </div> */}
      <div className="order-leg-setting">
        {!isSingleLeggedStrategy && <LegQuantity id={id} disabled={!enableLegQuantity} />}
        {isSingleLeggedStrategy && <OrderQuantity id={id}/>}
      </div>
      <div className="order-leg-setting order-leg-position">
        <LegPosition positions={positions} legId={id} />
      </div>
      <div className="order-leg-setting order-leg-putcall">
        {isOption ? (
          <LegOptionType id={id} disabled={!isCustomStrategyOrUndefined} />
        ) : (
          <ToggleButton on="Shrs" off="" onstyle="shares" offstyle="" width="100%" disabled={true} />
        )}
      </div>

      <div className="order-leg-setting order-leg-timing">
        {isOption && symbol && (
          <OrderLegExpiration
            legId={id}
            symbol={symbol}
            expiration={expiration}
            setExpiration={setLegExpiration}
            setNoExpirations={setNoExpirations}
          />
        )}
      </div>

      <div className="order-leg-setting order-leg-qty">
        {isOption && symbol && expiration && (
          <OrderLegStrikes
            legId={id}
            symbol={symbol.symbol}
            expiration={expiration}
            strike={strike}
            setStrike={setLegStrike}
          />
        )}
      </div>

      {modernBidAskLabelOrientation && (
        <div className="order-leg-setting order-leg-askbid">
          <div className="columns columns2">
            <div className="column">
              <ul className="props props-cols props-aligned txt-sm">
                <li className="prop-inline caps txt-sm">
                  <span className="mute prop-title">
                    <BlockPropTitle short="A" full="Ask" />
                  </span>
                  <LegPrices.Ask legId={id} />
                </li>
                <li className="prop-inline caps txt-sm">
                  <span className="mute prop-title">
                    <BlockPropTitle short="B" full="Bid" />
                  </span>
                  <LegPrices.Bid legId={id} />
                </li>
                {/* <li className="prop-inline caps txt-sm">
                  <span className="mute prop-title">
                    <BlockPropTitle short="C" full="Close" />
                  </span>
                  <LegPrices.Close legId={id} />
                </li> */}
              </ul>
            </div>
            <div className="column">
              <ul className="props props-cols props-aligned txt-sm">
                <li className="prop-inline caps txt-sm">
                  <span className="mute prop-title">
                    <BlockPropTitle short="H" full="High" />
                  </span>
                  <LegPrices.High legId={id} />
                </li>
                <li className="prop-inline caps txt-sm">
                  <span className="mute prop-title">
                    <BlockPropTitle short="L" full="Low" />
                  </span>
                  <LegPrices.Low legId={id} />
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {!modernBidAskLabelOrientation && (
        <div className="order-leg-setting order-leg-askbid">
          <div className="columns columns2">
            <div className="column">
              <ul className="props props-cols props-aligned txt-sm">
                <li className="prop-inline caps txt-sm">
                  <span className="mute prop-title">
                    <BlockPropTitle short="B" full="Bid" />
                  </span>
                  <LegPrices.Bid legId={id} />
                </li>
                <li className="prop-inline caps txt-sm">
                  <span className="mute prop-title">
                    <BlockPropTitle short="L" full="Low" />
                  </span>
                  <LegPrices.Low legId={id} />
                </li>
                {/* <li className="prop-inline caps txt-sm">
                  <span className="mute prop-title">
                    <BlockPropTitle short="C" full="Close" />
                  </span>
                  <LegPrices.Close legId={id} />
                </li> */}
              </ul>
            </div>
            <div className="column">
              <ul className="props props-cols props-aligned txt-sm">
                <li className="prop-inline caps txt-sm">
                  <span className="mute prop-title">
                    <BlockPropTitle short="A" full="Ask" />
                  </span>
                  <LegPrices.Ask legId={id} />
                </li>
                <li className="prop-inline caps txt-sm">
                  <span className="mute prop-title">
                    <BlockPropTitle short="H" full="High" />
                  </span>
                  <LegPrices.High legId={id} />
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      <div className="order-leg-setting order-leg-remove">
        {isCustomStrategyOrUndefined && lastLeg && (
          <button
            className="btn btn-blend remove-btn"
            title="Remove"
            onClick={() => remove(id)}
            disabled={!isCustomStrategyOrUndefined}
            type="button"
          >
            <i className="fas fa-times-circle"></i>
          </button>
        )}
      </div>
    </div>
  );
};

const LegOptionType: React.FC<{ id: string; disabled: boolean }> = ({ id, disabled }) => {
  const { setIsDirty, setOptionType, setPrice } = useOrderActions();
  const value = LegSelector.OptionType(useBlockData<OrderState>(), id);
  const setValue = useCallback(
    (isActive: boolean) => {
      setOptionType(id, isActive ? OptionType.Call : OptionType.Put);
      setPrice(undefined, false);
      setIsDirty(true);
    },
    [id, setOptionType, setIsDirty]
  );
  const isActive = useMemo(() => value === OptionType.Call, [value]);

  return (
    <ToggleButton
      on="Call"
      off="Put"
      onstyle="call"
      offstyle="put"
      width="100%"
      disabled={disabled}
      onClick={setValue}
      active={isActive}
    />
  );
};
