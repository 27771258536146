import React, { useCallback } from 'react';
import { Dropdown, DropdownItem } from '@tradingblock/components';
import { useDispatcher } from '../../data/global/hooks';
import { useBlockMetadata } from './BlockState';
import { useStateSelector } from '../../data/global/dataSelectors';

interface BlockSettingsDropdownProps {
  toggleBlockSettings: () => void;
  hasSettings: boolean;
}

export const BlockSettingsDropdown: React.FunctionComponent<BlockSettingsDropdownProps> = ({ toggleBlockSettings, hasSettings }) => {
  const { blockId } = useBlockMetadata();
  const { dispatcher } = useDispatcher();

  const removeBlock = dispatcher.block.removeBlock;
  const isDashboardLocked = useStateSelector(s => s.ui.dashboard.isLocked);
  const openSettings = useCallback(() => {
    toggleBlockSettings();
  }, [toggleBlockSettings]);

  const unlockGrid = useCallback(() => {
    dispatcher.grid.toggleLocked({ locked: false });
  }, [dispatcher.grid]);

  return (
    <Dropdown
      buttonOptions={{
        toggleButtonClassName: 'block-action-button',
        title: 'Options',
      }}
      direction="right"
      content={<i className="fas fa-ellipsis-v" />}
    >
      {hasSettings && <DropdownItem onClick={openSettings}>Settings&hellip;</DropdownItem>}
      {!isDashboardLocked && <DropdownItem onClick={unlockGrid}>Move block&hellip;</DropdownItem>}
      {!isDashboardLocked && <DropdownItem onClick={() => removeBlock(blockId)}>Delete</DropdownItem>}
      {isDashboardLocked && <DropdownItem>Dashboard is locked &nbsp;<i style={{ color: 'var(--salmon)' }} className="fa fa-lock"></i></DropdownItem>}
    </Dropdown>
  );
};
