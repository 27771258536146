import React, { useCallback, useEffect } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useBlockActionDispatchAndGenericActions } from '../../components/blocks/BlockState';
import {
  AccountManagementConstants,
  useAccountManagementData,
  AccountManagementDataState,
  isAccountManagementTab,
} from './state/AccountManagementState';
import { AccountManagementBlockAction, AccountManagementBlockActions } from './state/AccountManagementBlockAction';
import { AccountDetails } from './AccountDetails';
import { FinancialDetails } from './FinancialDetails';
import { AccountDisclosures } from './AccountDisclosures';
import { useStateSelector } from '../../data/global/dataSelectors';
import { Beneficiaries } from './Beneficiaries';
import { useDispatch } from 'react-redux';
import { AccountManagementDataActions } from '../../data/global/actions/AccountManagementActions';
import { AccountSecurity } from './Security';
import { Preferences } from './Preferences';

const {
  clientAcc,
  clientFin,
  clientDiscl,
  clientPref,
  clientBeneficiaries,
  security,
} = AccountManagementConstants.tabs;

export const AccountManagementContent: React.FunctionComponent<{}> = ({}) => {
  const dispatch = useDispatch();
  const accountId = useStateSelector(s => s.account.accountId);
  const retirementAccount = useStateSelector(
    s => s.accountManagement.accountManagementDetails.details.retirementAccountType
  );
  const isRetirementAccount = retirementAccount && retirementAccount !== '' ? true : false;
  const tab = useAccountManagementData(s => s.tab || 'clientAcc');
  const [, blockDispatch] = useBlockActionDispatchAndGenericActions<
    AccountManagementDataState,
    AccountManagementBlockAction
  >();
  const setTab = useCallback(
    (ev: string) => {
      if (isAccountManagementTab(ev)) {
        blockDispatch(AccountManagementBlockActions.setTab(ev));
      }
    },
    [blockDispatch]
  );

  useEffect(() => {
    if (accountId) {
      dispatch(AccountManagementDataActions.requestPendingAccountManagementUpdates({ accountId }));
    }
  }, [accountId, dispatch]);

  return (
    <>
      <div className="nav-tabs-pill">
        <Tabs defaultActiveKey={tab} id="notification-tabs" onSelect={(ev: string) => setTab(ev)}>
          <Tab eventKey={clientAcc} title="Personal Info">
            {tab === clientAcc && <AccountDetails />}
          </Tab>
          <Tab eventKey={clientFin} title="Investing Profile">
            {tab === clientFin && <FinancialDetails />}
          </Tab>
          <Tab eventKey={clientDiscl} title="Account Disclosures">
            {tab === clientDiscl && <AccountDisclosures />}
          </Tab>
          <Tab eventKey={clientPref} title="Preferences">
            {tab === clientPref && <Preferences />}
          </Tab>
          <Tab eventKey={security} title="Security">
            {tab === security && <AccountSecurity />}
          </Tab>
          {isRetirementAccount && (
            <Tab eventKey={clientBeneficiaries} title="Beneficiaries">
              {tab === clientBeneficiaries && <Beneficiaries />}
            </Tab>
          )}
        </Tabs>
      </div>
    </>
  );
};
