import { getType } from 'typesafe-actions';
import { InitialState } from '../initialState';
import { RootAction } from '../actions';
import { SubaccountActions } from '../actions/SubAccountActions';

//reducer
export const subAccountManagement = (state = InitialState.subAccountManagement, action: RootAction) => {
  switch (action.type) {
    case getType(SubaccountActions.receieveSubaccounts): {
      return {
        ...state,
        'subAccounts': action.payload.response.payload,
      };
    }
    case getType(SubaccountActions.receiveDeleteSubaccount): {
      const deletedAccountSubId = action.payload.response.payload.Id;
      return {
        ...state,
        'subAccounts': state.subAccounts.toSpliced(state.subAccounts.findIndex(subacc => subacc.Id === deletedAccountSubId), 1)
      }
    }
    case getType(SubaccountActions.receiveUpdateSubaccount): {
      if (state.subAccounts && action.payload && action.payload.response && action.payload.response.payload && action.payload.response.payload.Id) {
        const updatedAccountIndex = state.subAccounts.findIndex((currentAccounts) => currentAccounts.Id === action.payload.response.payload.Id);
        if (updatedAccountIndex > -1) {
          const stateWithoutUpdatedAccount = [...state.subAccounts.toSpliced(updatedAccountIndex, 1)]
          return {
            ...state,
            'subAccounts': [state.subAccounts[updatedAccountIndex] = action.payload.response.payload, ...stateWithoutUpdatedAccount]
          }
        }
      } else return {
        ...state
      }
    }
    case getType(SubaccountActions.receiveCreateSubaccount): {
      return {
        ...state,
        'subAccounts': [action.payload.response.payload, ...state.subAccounts],
      };
    }
    case getType(SubaccountActions.receivePositions): {
      return {
        ...state,
        'subAccountPositions': [action.payload.response.payload, ...state.subAccountPositions],
      };
    }
    default:
      return state;

  }

};
