import { useEffect, useMemo, useCallback } from 'react';
import _ from 'lodash';
import { IBlockDataState } from '@tradingblock/types';
import { isString } from '@tradingblock/api';
import { useDispatcher, TradingBlockDispatcher } from '../data/global/hooks';
import { useBlockData, useBlockState, useBlockSelector } from '../components/blocks/BlockState';
import { useStateSelector } from '../data/global/dataSelectors';
import { DataState } from '../data/global/state';
import { useWhyDidYouUpdate } from '@tradingblock/components';

type StringOrQuoteRequest =
  | string
  | string[]
  | {
      fetchAndSubscribe?: string[];
      subscribe: string[];
    };

export const setBlockQuotes = (blockId: string, dispatcher: TradingBlockDispatcher) => (symbolVal?: StringOrQuoteRequest) => {
  if (!symbolVal) {
    return;
  }
  let quotesToFetch: string[] = [];
  let allQuotes: string[] = [];
  if (isString(symbolVal)) {
    quotesToFetch = [symbolVal];
    allQuotes = [symbolVal];
  } else if (_.isArray(symbolVal)) {
    quotesToFetch = symbolVal;
    allQuotes = symbolVal;
  } else {
    quotesToFetch = symbolVal.fetchAndSubscribe || [];
    allQuotes = _.union(symbolVal.subscribe, symbolVal.fetchAndSubscribe || []);
  }
  if (quotesToFetch.length > 0) {
    // dispatcher.data.quote.request(_.uniq(quotesToFetch));
  }

  dispatcher.block.setQuoteSubscription(blockId, allQuotes);
  return;
};

export const useQuoteActions = () => {
  const { dispatcher } = useDispatcher();
  const blockId = useBlockState('blockId');

  const addQuote = (symbolVal: string | string[]) => {
    const symbols = _.isArray(symbolVal) ? symbolVal : [symbolVal];
    // dispatcher.data.quote.request(symbolVal);
    dispatcher.block.addQuoteSubscription(blockId, symbols);
    return;
  };
  const setFeedQuotes = (symbolVal: string | string[]) => {
    const symbols = _.isArray(symbolVal) ? symbolVal : [symbolVal];
    dispatcher.block.setQuoteSubscription(blockId, symbols);
  };
  return {
    setQuotes: useCallback(setBlockQuotes(blockId, dispatcher), [dispatcher, blockId]),
    setFeedQuotes: useCallback(setFeedQuotes, [dispatcher]),
    addQuote: useCallback(addQuote, [dispatcher]),
  };
};

export const useQuoteFeedSubscription = () => {
  const { setQuotes } = useQuoteActions();
  const setQuoteSubscriptions = useCallback(
    (symbolVal: string[] | string | undefined) => {
      const symbols = _.isString(symbolVal) ? [symbolVal] : _.isArray(symbolVal) ? symbolVal : [];
      const stringSymbols = _.filter(symbols, _.isString);
      if (stringSymbols.length > 0) {
        setQuotes(stringSymbols);
      }
    },
    [setQuotes]
  );
  return setQuoteSubscriptions;
};

const useQuoteSubscription = (symbolVal: string[] | string | undefined) => {
  const setQuotes = useQuoteFeedSubscription();

  useEffect(() => {
    setQuotes(symbolVal);
  }, [symbolVal]);
};

export const useQuoteSymbolSubscriptionFromGlobalState = (selector: (state: DataState) => string[] | string | undefined) => {
  const symbolVal = useStateSelector(selector);
  useQuoteSubscription(symbolVal);
  return symbolVal;
};
export const useQuoteSymbolSubscription = <T extends IBlockDataState>(getSymbol: (state: T) => string[] | string | undefined) => {
  // const state = useBlockData<T>();
  const symbolVal = useBlockSelector(getSymbol);
  // const symbolVal = useMemo(() => getSymbol(state), [state, getSymbol]);
  useQuoteSubscription(symbolVal);
};
