/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useWhyDidYouUpdate } from '@tradingblock/components';
import { useOrderActions } from '../state/useOrderActions';
import { useOrderData } from '../state/OrderState';
import { OrderSymbolList, OrderPositions } from '../state/OrderLegSelector';
import { useStateSelector, positionInfos } from '../../../data/global/dataSelectors';
import { useBlockId } from '../../../components/blocks/BlockState';
import { areEqual } from '../../../utilities/order';

export const useOrderPositionSync = () => {
  const blockId = useBlockId();
  const { setPositionInfo } = useOrderActions();
  const orderSymbols = useOrderData(s => OrderSymbolList(s, blockId));
  const localPositions = useOrderData(s => OrderPositions(s, blockId));

  const positionInfo = useStateSelector(s => positionInfos(s, orderSymbols.map(os => os.symbol), blockId));

  useWhyDidYouUpdate('useOrderPositionSync', { positionInfo, localPositions, orderSymbols, blockId });
  useEffect(() => {
    if (localPositions && !areEqual(localPositions, positionInfo)) {
      setPositionInfo(positionInfo);
    }
  }, [positionInfo, setPositionInfo, orderSymbols, localPositions]);
};
