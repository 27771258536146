import { Middleware, Dispatch } from 'redux';
import { RootAction, OptionChainActions } from '../../actions';
import { getType } from 'typesafe-actions';
import _ from 'lodash';
import { OptionPair, Expiration, OptionPairWithSymbol } from '@tradingblock/types';
import { DataState } from '../../state';
import { Dispatcher } from '../../dispatcher';
import { ApiProvider } from '../../../../context/Api';
import { expirationValue } from '../../../../utilities/date';

const getExistingOptionChainData = (state: DataState, blockId: string, symbol: string) => {
  const existingBlockData = state.optionchain[blockId];

  const existingBlockSymbolData = existingBlockData ? existingBlockData[symbol] : undefined;
  return existingBlockSymbolData ? existingBlockSymbolData : {};
};
const countOfPairsForSymbolExpiration = (state: DataState, blockId: string, symbol: string, exp: Expiration) => {
  const existingOptionChainData = getExistingOptionChainData(state, blockId, symbol);
  const existingChainData = _.find(
    existingOptionChainData,
    p => expirationValue(p.expiration) === expirationValue(exp)
  );
  let chainData: OptionPair[] = existingChainData ? existingChainData.pairs : [];
  return chainData.length;
};

const loadOptionChains = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  dispatcher: ReturnType<typeof Dispatcher>,
  expirations: Expiration[],
  blockId: string,
  symbol: string,
  strikeCount: number = 0
) => {
  const existingOptionChainData = getExistingOptionChainData(state, blockId, symbol);
  const api = ApiProvider(state, dispatch);
  return await Promise.all(
    _.map(expirations || [], async exp => {
      const existingChainData = _.find(
        existingOptionChainData,
        p => expirationValue(p.expiration) === expirationValue(exp)
      );
      let chainData: OptionPairWithSymbol[] = existingChainData ? existingChainData.pairs : [];
      if (chainData.length < strikeCount || chainData.length > strikeCount || strikeCount === 0) {
        const { data, responseCode } = await api.options.optionchain(symbol, exp.date, strikeCount, true);
        chainData = data;
        if (responseCode > 0) {
          dispatcher.optionchain.requestOptionChainError(blockId, symbol, exp, responseCode);
        } else {
          dispatcher.optionchain.receiveOptionChain(blockId, symbol, exp, chainData);
        }
      } else {
        dispatcher.optionchain.receiveOptionChain(blockId, symbol, exp, chainData);
      }
    })
  );
  // console.warn(`loaded chains for %o`, sym, expirationDates, chains);
};

export const OptionChainMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  const dispatcher = Dispatcher(dispatch);
  try {
    // state BEFORE action is dispatched
    const prevState = getState();
    const result = next(action);
    // state AFTER action is dispatched
    const nextState = getState();
    try {
      switch (action.type) {
        case getType(OptionChainActions.requestOptionChain): {
          const { payload } = action;
          const { expirations, strikeCount, symbol, blockId } = payload;
          loadOptionChains(prevState, dispatch, dispatcher, expirations, blockId, symbol, strikeCount);
          break;
        }

        case getType(OptionChainActions.setStrikeCount): {
          const { payload } = action;
          const { strikeCount, expirations, blockId, symbol } = payload;
          const expirationsThatShouldReload = _.filter(
            expirations,
            exp => countOfPairsForSymbolExpiration(nextState, blockId, symbol, exp) < strikeCount
          );
          if (expirationsThatShouldReload.length > 0) {
            dispatcher.optionchain.requestOptionChain(blockId, symbol, expirationsThatShouldReload, strikeCount);
          }

          break;
        }
      }
    } catch (apiError) {
      Dispatcher(dispatch).global.exception({ error: apiError, data: action });
    }

    return result;
  } catch (err) {
    console.error('optionchainMiddleware :: Caught an exception for action ', action, err);
  }
};
