import React, { useMemo, useEffect, useCallback } from 'react';
// import useThrottle from 'react-use/lib/useThrottle';
import _ from 'lodash';
import createCachedSelector from 're-reselect';
import useMount from 'react-use/lib/useMount';
import { BlockBody, Loading, EmptyBlockContent, useWhyDidYouUpdate } from '@tradingblock/components';
import { AssetType, BlockType } from '@tradingblock/types';
import { orderLegOccSymbol } from '@tradingblock/api';
import { OrderState } from './state/OrderState';
import { useOrderState } from './state/useOrderState';
import { OrderBlockSettingsFields } from './state/OrderSettings';
import { orderBlockSymbols } from './data/orderSelectors';
import { useDispatcher } from '../../data/global/hooks';
import { useQuoteSymbolSubscription } from '../../hooks/useQuoteData';
import { useOrderBlockGroupHandler } from './hooks/useOrderBlockGroupHandler';
import { useOrderSettings } from './hooks/useOrderSettings';
import { Block } from '../../components/blocks/Block';
import { useBlockId } from '../../components/blocks/BlockState';
import { OrderHeaderMemo } from './OrderHeader';
import { OrderContent } from './OrderContent';
import { OrderFooter } from './OrderFooter';
import { getBlockProps } from '../../utilities/block';
import { useGroupState } from '../useGroupState';

const useOrderQuoteSymbolSubscription = () => {
  const blockId = useBlockId();
  useQuoteSymbolSubscription<OrderState>(s => orderBlockSymbols(s, blockId));
};

const Content: React.FC<{}> = () => {
  const [assetSymbol] = useGroupState('symbol');
  const isLoaded = useOrderState('isLoaded');
  const legs = useOrderState('legs');
  useOrderBlockGroupHandler();
  useOrderQuoteSymbolSubscription();
  useWhyDidYouUpdate('OrderContent', { isLoaded, assetSymbol });

  const hasOptionLegs = useMemo(() => {
    return _.some(legs, leg => leg.AssetType === AssetType.Option);
  }, [legs]);

  const [noExpirations, setNoExpirations] = React.useState(false);

  useEffect(() => {
    if (!hasOptionLegs) {
      setNoExpirations(false);
    }
  }, [hasOptionLegs]);

  return (
    <>
      <BlockBody>
        {assetSymbol ? (
          <>
            <OrderContent setNoExpirations={setNoExpirations} />
          </>
        ) : (
          <EmptyBlockContent>{isLoaded ? 'Enter symbol above.' : <Loading />}</EmptyBlockContent>
        )}
      </BlockBody>
      <OrderFooter symbol={assetSymbol ? assetSymbol.symbol : ''} noExpirations={noExpirations} />
    </>
  );
};

export const OrderBlock: React.FC<{}> = () => {
  const settings = useOrderSettings();
  const { dispatcher } = useDispatcher();
  const [initialLogin, setInitialLogin] = React.useState(true);

  useEffect(() => {
    if (initialLogin) {
      settings.weeklyExpirations = true;
      settings.monthlyExpirations = true;
      settings.quarterlyExpirations = true;
      settings.yearlyExpirations = true;
      setInitialLogin(false);
    }
  }, []);

  useMount(() => dispatcher.data.positions.request());
  const blockprops = useMemo(() => getBlockProps(BlockType.Order, <OrderHeaderMemo />), []);
  return (
    <Block settings={settings} settingFields={OrderBlockSettingsFields} {...blockprops}>
      <Content />
    </Block>
  );
};
