import React from 'react';
import _ from 'lodash';
import { FormGroup } from '@tradingblock/components';
import { SettingsFields, SettingsField, GenericField } from './GenericField';
import { GenericRadioButton } from './GenericRadioButton';
import { GenericChoiceField } from './GenericChoiceField';

export function renderGenericForm<T, S extends SettingsFields<T>>(fields: S | undefined, values?: T) {
  return (
    <>
      {_.map(fields, (f: SettingsField, k: string) => {
        return <FormGroup key={`field-${k}`}>{f.type === 'choice' ? <GenericChoiceField name={k} {...f} /> : f.type === 'radio' ? <GenericRadioButton name={k} {...f} /> : <GenericField name={k} value={_.get(values, k)} settings={f} />}</FormGroup>;
      })}
    </>
  );
}
export function renderGenericFormWithFilter<T, SF, S extends SettingsFields<T, SF>>(fields: S | undefined, filter: (field: any) => boolean, values?: T, mapField?: (field: SF) => SF) {
  const filtered: S = _.reduce(
    fields || {},
    (acc: S, f: SF, k) => {
      if (f && filter(f)) {
        const val = mapField ? mapField(f) : f;
        return {
          ...acc,
          [k]: val,
        };
      }
      return acc;
    },
    {} as S
  );
  return renderGenericForm(filtered, values);
}
