import React from 'react';
import Config from '../../../config';
import { useStateSelector } from '../../../data/global/dataSelectors';

const getEnvironmentName = () => {
  switch (Config.TradingEnvironment) {
    case 'dev':
      return 'development';
    case 'beta':
      return 'beta';
    default:
      return 'production';
  }
};

export const UsernameAndEnvironment = () => {
  const userName = useStateSelector(s => s.auth.userName);
  return (
    <span className="mute">
      {userName} ({getEnvironmentName()})
    </span>
  );
};
