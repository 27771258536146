import { createStandardAction, ActionType } from 'typesafe-actions';
import { AllNotesTimeframeValues } from '@tradingblock/types';
import { InfoTab } from './InfoState';

export const InfoBlockActions = {
  setTab: createStandardAction('@quote/tab/set')<InfoTab>(),
  setNoticePeriod: createStandardAction('@quote/notices/period/set')<AllNotesTimeframeValues>(),
};

export type InfoBlockAction = ActionType<typeof InfoBlockActions>;
