import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { PlaidLinkOnSuccessMetadata, usePlaidLink } from 'react-plaid-link';
import { Field } from 'formik';
import _ from 'lodash';
import { AchRelationshipType, PlaidMetadata } from '@tradingblock/types';
import { FormGroup, StepperField, TextboxField, Svg, SelectDropdownInput } from '@tradingblock/components';
import { CashieringViewProps, CashieringViewFormValues } from '../data/useCashieringView';
import { TradingBlockLink } from '../../../../components/basic/TradingBlockLink';
import { useDispatcher } from '../../../../data/global/hooks';
import { accountIdSelector, cashieringSelectors } from '../../../../data/global/dataSelectors';

interface AchRelationshipFormProps extends CashieringViewProps<CashieringViewFormValues> {}

export const AchRelationshipForm: React.FunctionComponent<AchRelationshipFormProps> = ({ values, ...props }) => {
  const accountId = useSelector(accountIdSelector);
  const plaidLinkToken = useSelector(cashieringSelectors.plaidLinkToken);

  const { dispatcher } = useDispatcher();

  useEffect(() => {
    if (values.account && values.account.approvalMethod === 'Plaid' && !plaidLinkToken) {
      // fetch Plaid link token
      dispatcher.cashiering.achRelationships.plaidLinkToken.request(accountId, `${window.location.protocol}//${window.location.hostname}${window.location.port !== "" ? `:${window.location.port}` : ''}/accounts/oauth`);
    }
  }, [values, plaidLinkToken, accountId, dispatcher]);

  return (
    <>
      {values.account && values.account.approvalMethod === 'Plaid' && plaidLinkToken && <AchRelationshipPlaidForm values={values} plaidLinkToken={plaidLinkToken} {...props} />}
      {values.account && values.account.approvalMethod === 'MicroDeposit' && <AchRelationshipMicroDepositForm />}
      {values.account && values.account.approvalMethod === 'PDF' && (
        <>
          <p className="fields-title">Downloadable application</p>
          <p>
            <TradingBlockLink className="btn btn-primary" to="AchRelationshipPdfApplicationUrl">
              Download the PDF application
            </TradingBlockLink>
          </p>
        </>
      )}
    </>
  );
};

const AchRelationshipPlaidForm: React.FunctionComponent<AchRelationshipFormProps & { plaidLinkToken: string }> = ({ plaidLinkToken, values, setValues }) => {
  const [plaidMetadata, setPlaidMetadata] = useState<PlaidLinkOnSuccessMetadata>();
  const [plaidFailed, setPlaidFailed] = useState<boolean>();

  const plaidSucceeded = useMemo(() => {
    return values.account && values.account.plaidPublicToken ? true : false;
  }, [values]);

  const plaidConfig = useMemo(() => {
    localStorage.setItem('lastPlaidLinkToken', plaidLinkToken)
    return {
      token: plaidLinkToken,
      linkCustomizationName: 'config_en',
      onExit: (error: object | null) => {
        console.warn('Exited Plaid Link... ', error);
        setPlaidFailed(true);
      },
      onSuccess: (plaidPublicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
        setPlaidMetadata(metadata);
        setValues({ ...values, account: { ...values.account, plaidPublicToken } });
      },
    };
  }, [plaidLinkToken]);

  const { open: openPlaidLink, ready: plaidReady } = usePlaidLink(plaidConfig);

  useEffect(() => {
    // open Plaid link right away once it's ready
    if (plaidReady) {
      openPlaidLink();
    }
  }, [plaidReady, openPlaidLink]);

  const onOpenPlaid = useCallback(() => {
    if (plaidReady) {
      openPlaidLink();
    }
  }, [plaidReady, openPlaidLink]);

  const bankName = useMemo(() => {
    return (plaidMetadata && plaidMetadata.institution && plaidMetadata.institution.name) || 'bank';
  }, [plaidMetadata]);

  const accounts = useMemo(() => {
    // only allow checking|savings accounts to be selected
    return _.filter(plaidMetadata && plaidMetadata.accounts, a => a.type === 'depository' && (a.subtype === 'checking' || a.subtype === 'savings'));
  }, [plaidMetadata]);

  const onChangePlaidAccountId = useCallback(
    (plaidAccountId: string) => {
      const bankAccount = _.find(accounts, a => a.id === plaidAccountId);
      setValues({ ...values, account: { ...values.account, plaidAccountId, nickName: bankAccount ? bankAccount.name : undefined } });
    },
    [accounts, values]
  );

  return (
    <>
      <p className="fields-title">Link new bank account</p>
      {!plaidSucceeded && plaidFailed && (
        <button className="btn btn-primary" type="button" onClick={onOpenPlaid}>
          Link to your bank
        </button>
      )}
      {plaidSucceeded && (
        <>
          <p className="mute">Select an account and give it a nickname to complete linking.</p>
          <FormGroup>
            <p>
              <span className="mute">Bank:</span> {bankName}
            </p>
          </FormGroup>
          <FormGroup>
            <Field
              component={SelectDropdownInput}
              id="account.plaidAccountId"
              value={values.account && values.account.plaidAccountId}
              options={_.map(accounts, a => ({
                value: a.id,
                label: (
                  <>
                    {a.name} <span className="mute">(***{a.mask})</span>
                  </>
                ),
              }))}
              label={`Select ${bankName} account`}
              onchange={(e: any, plaidAccountId: string) => onChangePlaidAccountId(plaidAccountId)}
            />
          </FormGroup>
          <FormGroup>
            <Field component={TextboxField} id="account.nickName" label="Nickname for account (e.g. Personal checking)" />
          </FormGroup>
        </>
      )}
    </>
  );
};

const AchRelationshipMicroDepositForm: React.FC<{}> = () => {
  return (
    <>
      <p className="fields-title">Link new bank account</p>
      <p className="mute">You’ll verify the account below in 1-2 days by confirming microdeposits.</p>
      <FormGroup>
        <Field component={StepperField} id="account.bankAccountType" options={_.map(['Checking', 'Savings'], (t: AchRelationshipType) => ({ value: t, label: _.startCase(t) }))} />
      </FormGroup>
      <FormGroup>
        <Field component={TextboxField} id="account.bankRoutingNumber" label="Routing Number" />
      </FormGroup>
      <FormGroup>
        <Field component={TextboxField} id="account.bankAccount" label="Account Number" />
      </FormGroup>
      <FormGroup>
        <Field component={TextboxField} id="account.bankName" label="Bank Name" />
      </FormGroup>
      <FormGroup>
        <Field component={TextboxField} id="account.bankAccountOwnerName" label="Bank Account Owner Name / Title" />
      </FormGroup>
      <FormGroup>
        <Field component={TextboxField} id="account.nickName" label="Nickname for account (e.g. Personal checking)" />
      </FormGroup>
      <FormGroup>
        <Svg path="check" />
      </FormGroup>
    </>
  );
};
