import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Modal as BootstrapModal } from 'react-bootstrap';
import { Modal, Button } from '@tradingblock/components';
import { AchRelationship, CashieringManageAction, CashieringRoute, CashieringView } from '@tradingblock/types';
import { cashieringSelectors } from '../../../../data/global/dataSelectors';
import {
  isPendingStatus,
  isNotYetCreatedOrPendingStatus,
  isNotYetCreatedStatus,
  isApprovedStatus,
  getTransferActionStyle,
  getTransferActionIconClass,
  getFormattedAchRelationship,
  isRejectedStatus,
  isRejectedOrCancelledStatus,
  isCancelledStatus,
} from '../../../../utilities/cashiering';
import { createGenericAccountList, AccountListWrapperProps } from './GenericAccountList';
import { AccountRenameForm } from './AccountRenameForm';
import { AchRelationshipForm } from './AchRelationshipForm';
import { AchRelationshipVerifyForm } from './AchRelationshipVerifyForm';
import { useCashieringDataContext } from '../data/useCashieringData';
import { Cashiering } from '../../../../constants';

interface AchRelationshipListProps extends AccountListWrapperProps<AchRelationship> {}

const BaseAchRelationshipList = createGenericAccountList<AchRelationship>();

export const AchRelationshipList: React.FunctionComponent<AchRelationshipListProps> = ({
  values,
  setValues,
  selectable,
  onAdd,
  onVerify,
  onRename,
  addBtn,
  ...props
}) => {
  const { action, formStatus, setAction, setFormStatus } = useCashieringDataContext();
  const isDisabled = useMemo(() => !_.isUndefined(action) && action !== CashieringManageAction.AddOptions, [action]);

  // determine if we are in a withdrawal/deposit view or a manage view
  const isManageView = useMemo(() => formStatus.view === CashieringView.Manage, [formStatus]);

  const achRelationships = useSelector(cashieringSelectors.achRelationships);

  const selectableAchRelationships = useMemo(
    () => _.filter(achRelationships, r => !isRejectedOrCancelledStatus(r.state)),
    [achRelationships, isManageView]
  );

  const [maxItemsWarning, setMaxItemsWarning] = useState(false);

  const onSelect = useCallback(
    (achRelationshipId: number) => {
      setValues({ transfer: { ...values.transfer, achRelationshipId } });
    },
    [values]
  );

  const onCloseClick = useCallback(() => {
    setValues({ ...values, account: undefined });
    setAction(undefined);
  }, [values]);

  const onAddClick = useCallback(() => {
    if (_.size(selectableAchRelationships) >= Cashiering.MaxAchRelationships) {
      setMaxItemsWarning(true);
    } else {
      onAdd();
    }
  }, [selectableAchRelationships, onAdd]);

  const gotoManageView = useCallback(() => {
    setFormStatus({ view: CashieringView.Manage });
  }, [setFormStatus]);

  const isEditing = useCallback(
    (acct: AchRelationship) => {
      return values.account && acct.id === values.account.id;
    },
    [values]
  );

  const getAccountName = useCallback(
    (acct: AchRelationship) => {
      const style = isEditing(acct) ? getTransferActionStyle(action) : undefined;
      return <div style={style}>{getFormattedAchRelationship(acct)}</div>;
    },
    [action]
  );

  const getAccountIcon = useCallback(
    (acct: AchRelationship) => {
      const style = isEditing(acct) ? getTransferActionStyle(action) : undefined;
      const icon = (isEditing(acct) && getTransferActionIconClass(action)) || 'fa-check';
      if (isNotYetCreatedOrPendingStatus(acct.state)) {
        return (
          <span className="pending" style={style}>
            <i className="fal fa-2x fa-hourglass-half" />
          </span>
        );
      }
      if (isRejectedStatus(acct.state)) {
        return (
          <span className="warn">
            <i className="fal fa-2x fa-times" />
          </span>
        );
      }
      return <i className={`fal fa-2x ${icon}`} style={style} />;
    },
    [action]
  );

  const getAccountStatus = useCallback(
    (acct: AchRelationship) => {
      const style = isEditing(acct) ? getTransferActionStyle(action) : undefined;
      if (isNotYetCreatedStatus(acct.state)) {
        return (
          <>
            <span className="pending" style={style}>
              Pending approval<strong>*</strong>
            </span>
          </>
        );
      }
      if (isPendingStatus(acct.state) && onVerify) {
        const verifyBtn =
          acct.approvalMethod !== 'Plaid' ? (
            <>
              <br />
              <button className="btn-link" type="button" onClick={() => onVerify(acct)} disabled={isDisabled}>
                Verify
              </button>
            </>
          ) : (
            undefined
          );
        return (
          <>
            <span className="pending" style={style}>
              Pending verification<strong>*</strong>
            </span>

            {verifyBtn}
          </>
        );
      }
      if (!selectable && onRename && isApprovedStatus(acct.state)) {
        return (
          <>
            Linked successfully
            <br />
            <button className="btn-link" type="button" onClick={() => onRename(acct)} disabled={isDisabled}>
              Rename
            </button>
          </>
        );
      }
      if (isRejectedStatus(acct.state)) {
        return (
          <>
            <span className="warn">Account rejected</span>
          </>
        );
      }
      if (isCancelledStatus(acct.state)) {
        return (
          <>
            <span className="warn">Account cancelled</span>
            {/* cancellation comment */}
            {acct.cancellation.comment && <div className="warn txt-sm">{acct.cancellation.comment}</div>}
          </>
        );
      }
      return null;
    },
    [action, selectable, isDisabled, onVerify, onRename]
  );

  const getFootnote = useCallback(() => {
    const pendingAchRelationships = _.filter(selectableAchRelationships, a => isNotYetCreatedOrPendingStatus(a.state));
    if (
      !action &&
      _.some(pendingAchRelationships) &&
      _.every(pendingAchRelationships, a => a.approvalMethod === 'Plaid')
    ) {
      return (
        <p className="mute">
          <strong>*</strong>Your account is pending approval by an administrator.
        </p>
      );
    }
    if (!action && _.some(pendingAchRelationships, a => a.approvalMethod === 'MicroDeposit')) {
      return (
        <p className="mute">
          <strong>*</strong>Look for two deposits under 0.50. You have 10 days to verify your account by entering the
          two deposit amounts from your bank activity or statement.
        </p>
      );
    }
    return null;
  }, [action, selectableAchRelationships]);

  return (
    <>
      {action !== CashieringManageAction.Add && (
        <BaseAchRelationshipList
          {...props}
          selectable={selectable}
          data={selectableAchRelationships}
          onSelect={onSelect}
          onAdd={onAddClick}
          addBtn={isDisabled ? undefined : addBtn}
          isDisabled={a => isDisabled}
          renderName={getAccountName}
          renderIcon={getAccountIcon}
          renderStatus={getAccountStatus}
          renderFootnote={getFootnote}
        />
      )}
      {action && (
        <>
          <hr />

          {!_.includes([CashieringManageAction.AddOptions, CashieringManageAction.Delete], action) && (
            <button
              className="btn btn-blend"
              type="button"
              title="Close"
              onClick={onCloseClick}
              style={{ float: 'right' }}
            >
              <i className="fas fa-2x fa-times-circle" />
            </button>
          )}

          {action === CashieringManageAction.Add && <AchRelationshipForm values={values} setValues={setValues} />}
          {action === CashieringManageAction.Rename && <AccountRenameForm mechanism="Ach" />}
          {action === CashieringManageAction.Verify && (
            <AchRelationshipVerifyForm values={values} setValues={setValues} />
          )}
          {action === CashieringManageAction.Delete && (
            <p className="fields-title">Confirm delete of linked account?</p>
          )}
        </>
      )}

      <Modal show={maxItemsWarning} setShow={setMaxItemsWarning} backdrop="static" isError={true}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Linked accounts</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          <p>
            You may have a maximum of {Cashiering.MaxAchRelationships} linked accounts.{' '}
            {formStatus.view === CashieringView.Manage ? (
              <>Please delete an existing linked account if you wish to add a new one.</>
            ) : (
              <>
                Please{' '}
                <Link to={`/${CashieringRoute.Accounts}#${CashieringView.Manage}`} onClick={gotoManageView}>
                  delete an existing linked account
                </Link>{' '}
                if you wish to add a new one.
              </>
            )}
          </p>
        </BootstrapModal.Body>
        <BootstrapModal.Footer className="modal-footer">
          <Button className="btn-primary" onClick={() => setMaxItemsWarning(false)}>
            OK
          </Button>
        </BootstrapModal.Footer>
      </Modal>
    </>
  );
};
