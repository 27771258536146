import React from 'react';
import { Tabs, Tab } from '@tradingblock/components';

interface BlockSettingsTabs {
  onSelectTab: (eventKey: 'this' | 'all' | string) => void;
  thisBlockTab: React.ReactNode;
  allBlockTab: React.ReactNode;
}

export const BlockSettingsTabs: React.FunctionComponent<BlockSettingsTabs> = ({ onSelectTab, thisBlockTab, allBlockTab }) => {
  return (
    <Tabs defaultActiveKey="this" id="uncontrolled-tab-example" onSelect={onSelectTab}>
      <Tab eventKey="this" title="For this block">
        {thisBlockTab}
      </Tab>
      <Tab eventKey="all" title="For new blocks like this">
        {allBlockTab}
      </Tab>
    </Tabs>
  );
};
