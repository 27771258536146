import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import _ from 'lodash';
import { DataState } from '../../state';
import { entitlementRequestKey } from '../../utilities/entitlementsUtilities';

export const getRequestKey = createSelector(
  (state: DataState) => state.private.entitlements.ui,
  uiState => entitlementRequestKey(uiState)
);

export const getEntitlementUpdateSearchEntities = createCachedSelector(
  (state: DataState) => state.private.entitlements.entitlementItems,
  (state: DataState) => state.private.entitlements.byQuery,
  (state: DataState) => state.private.entitlements.ui,
  getRequestKey,
  (entitlementItems, queryState, uiState, requestKey) => {
    const allValues = queryState[requestKey] ? queryState[requestKey].ids : [];

    return (
      _(allValues)
        .map(id => entitlementItems[id])
        .filter(v => v !== undefined)
        // .drop(page * pageSize)
        // .take(pageSize)
        .value()
    );
  }
)(getRequestKey);

export const getEntitlementsSearchFilterData = createCachedSelector(
  (state: DataState) => state.private.entitlements.ui,
  getRequestKey,
  (uiState, requestKey) => {
    return { ...uiState };
  }
)(getRequestKey);

export const getEntitlementsSearchTotal = createCachedSelector(
  (state: DataState) => state.private.entitlements.entitlementItems,
  (state: DataState) => state.private.entitlements.byQuery,
  (state: DataState) => state.private.entitlements.ui,
  getRequestKey,
  (entitlementItems, queryState, uiState, requestKey) => {
    const total = queryState[requestKey] ? queryState[requestKey].total : 0;
    return total;
  }
)(getRequestKey);

export const EntitlementsSearchRequestSelector = createCachedSelector(
  (state: DataState) => state.private.entitlements.ui,
  (state: DataState) => entitlementRequestKey(state.private.entitlements.ui),
  (request, key) => {
    return { request, key };
  }
)((state: DataState) => entitlementRequestKey(state.private.entitlements.ui));

export const isFetchingEntitlements = createCachedSelector(
  (state: DataState) => state.private.entitlements.byQuery,
  getRequestKey,
  (queryState, requestKey) => {
    const qState = queryState[requestKey];
    const isFetching = qState ? qState.isFetching : true;
    const hasData = qState && qState.ids.length > 0;
    return [isFetching, hasData];
  }
)(getRequestKey);

export const currentItemEntitlementsUpdates = createCachedSelector(
  (state: DataState) => state.private.entitlements.currentItem,
  getRequestKey,
  (queryState, requestKey) => {
    console.log({ queryState, requestKey });
  }
)(getRequestKey);
