import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import _ from 'lodash';
import dayjs from 'dayjs';
import {
  Legs,
  OrderLegDTOState,
  AssetType,
  OrderLegDTO,
  OrderAction,
  OrderType,
  Durations,
  isCustomStrategy,
  TradingStrategy,
  CustomTradingStrategy,
  TradingStrategyOrCustom,
  AssetSymbol,
  Order,
  OptionType,
  DebitOrCredit,
  Expiration,
  isStrategy,
} from '@tradingblock/types';
import { GetOCCSymbol, getStrategy, CallCalendar, PutCalendar, isCalendar } from '@tradingblock/api';
import { setLogLevel } from '@tradingblock/components';
import { createDeepSelector } from '../../../data/global/dataSelectors';
import { OrderState } from './OrderState';
import { OrderStateToOrder } from './OrderStateToOrder';
import { SymbolOccSelector } from './OrderLegSelector';
import { isSingleLegStrategy } from '../OrderUtilities';

const rootSel = (state: OrderState) => state;

const legsSel = (state: OrderState) => state.legs;

const sels = {
  legs: (state: OrderState) => state.legs,
  legArray: (state: OrderState) => {
    return _.map(state.legs, l => l);
  },
  orderType: (state: OrderState) => state.orderType,
  symbol: (state: OrderState) => (state.symbol ? state.symbol.symbol : undefined),
  assetSymbol: (state: OrderState) => state.symbol,
  id: (state: OrderState, id: string) => id,
  duration: (state: OrderState) => state.duration,
  price: (state: OrderState) => state.price,
  stopPrice: (state: OrderState) => state.stopPrice,
  strategy: (state: OrderState) => getStrategy(state.strategy),
  matchingStrategy: (state: OrderState) => state.matchingStrategy,
  quantity: (state: OrderState) => state.quantity,
  positions: (state: OrderState) => state.positions,
  debitCredit: (state: OrderState) => state.debitCredit,
  subaccountId: (state: OrderState) => state.subaccountId,
  orderId: (state: OrderState) => state.orderId,
  commissionOverrides: (state: OrderState) => ({
    placeAs: state.placeAs,
    executeAs: state.executeAs,
    commission: state.commission,
    commissionOverrideCode: state.commissionOverrideCode,
    bypassMargin: state.bypassMargin,
  }),
  action: (state: OrderState) => state.action,
};

const isSpreadOrder = createCachedSelector(
  (state: OrderState, blockId: string) => (state.legs ? _.keys(state.legs).length : 0),
  legAmount => legAmount > 1
)((state, blockId) => blockId);

const computed = {
  orderIsComplete: createSelector(
    sels.legArray,
    (legValues: OrderLegDTOState[]) => {
      const legsAreComplete = _.every(legValues, l => {
        if (l.AssetType === AssetType.Option) {
          return !_.isNil(l.Expiration) && !_.isNil(l.Strike);
        }
        return true;
      });

      return legsAreComplete;
    }
  ),
};

interface LegInfo {
  OptionType: OptionType;
  LegId: string;
  Index: number;
}

interface LegStrikeInfo extends LegInfo {
  Strike: number;
}

export interface LegExpirationInfo extends LegInfo {
  Expiration: Expiration;
  Action: OrderAction;
}

const orderActionSelector = createSelector(
  (state: Pick<OrderState, 'action'>) => state.action,
  action => action || OrderAction.Buy
);

export const OrderSelectors = {
  invalidLegIds: createCachedSelector((s: OrderState, blockId: string) => s.validation.legIds, val => val || [])(
    (s: OrderState, blockId: string) => blockId
  ),
  debitOrCredit: createCachedSelector(
    (s: OrderState, blockId: string) => s.debitCredit,
    (debitCredit: DebitOrCredit | undefined) => {
      return debitCredit;
    }
  )((s: OrderState, blockId: string) => blockId),
  canEditDebitOrCredit: createCachedSelector(
    (s: OrderState, blockId: string) => getStrategy(s.strategy),
    (s: OrderState) => s.validation.match,
    (s: OrderState, blockId: string) => s.legs,
    (s: OrderState, blockId: string) => isSpreadOrder(s, blockId),
    (s: OrderState, blockId: string) => s.orderType,
    (
      strat: CustomTradingStrategy | TradingStrategy | undefined,
      match,
      legs,
      isSpreadOrder: boolean,
      orderType: OrderType
    ) => {
      if (strat && isCustomStrategy(strat)) {
        const allSell = _.every(legs, l => l.Action === OrderAction.Sell);
        const allBuy = _.every(legs, l => l.Action === OrderAction.Buy);
        if (orderType === OrderType.Market) {
          return false;
        }
        if (allSell || allBuy) {
          return false;
        } else if (match && !isCalendar(match.info.Name)) {
          return false;
        }

        return true;
      } else if (strat && strat.info.BidirectionalDebitCredit && isSpreadOrder) {
        if (orderType === OrderType.Market) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    }
  )((s: OrderState, blockId: string) => blockId),
  isComplete: computed.orderIsComplete,
  orderedLegsWithExpiration: createCachedSelector(
    (state: OrderState, blockId: string) => state.legs,
    (_, blockId) => blockId,
    (legs: Legs) => {
      const legValues = _.values(legs);
      const legsWithExpiration = _.reduce(
        legValues,
        (acc: LegExpirationInfo[], l: OrderLegDTOState, ind: number): LegExpirationInfo[] => {
          const hasExpiration = l.Expiration !== undefined;
          if (!hasExpiration) {
            return acc;
          }
          const { Expiration, OptionType, Id, Action } = l;
          if (Expiration && OptionType && Id) {
            return [
              ...acc,
              {
                Expiration,
                OptionType,
                LegId: Id,
                Index: ind,
                Action: Action,
              },
            ];
          } else {
            return acc;
          }
        },
        [] as LegExpirationInfo[]
      );
      return legsWithExpiration;
    }
  )((state: OrderState, blockId: string) => blockId),
  orderedLegsWithStrike: createCachedSelector(
    (state: OrderState, blockId: string) => state.legs,
    (_, blockId) => blockId,
    (legs: Legs) => {
      const legValues = _.values(legs);
      const legsWithStrike = _.reduce(
        legValues,
        (acc: LegStrikeInfo[], l: OrderLegDTOState, ind: number): LegStrikeInfo[] => {
          const legHasStrike = l.Strike !== undefined;
          if (!legHasStrike) {
            return acc;
          }
          const { Strike, OptionType, Id } = l;
          if (Strike && OptionType && Id) {
            return [
              ...acc,
              {
                Strike,
                OptionType,
                LegId: Id,
                Index: ind,
              },
            ];
          } else {
            return acc;
          }
        },
        [] as LegStrikeInfo[]
      );
      return legsWithStrike;
    }
  )((state: OrderState, blockId: string) => blockId),
  firstLegWithStrike: createCachedSelector(
    (state: OrderState, blockId: string) => state.legs,
    (_, blockId) => blockId,
    (legs: Legs) => {
      const legWithStrike = _.find(legs, l => l.Strike !== undefined);
      const legIndex = legWithStrike ? _.findIndex(_.values(legs), l => l.Id === legWithStrike.Id) : -1;
      return legWithStrike
        ? {
            Strike: legWithStrike.Strike,
            OptionType: legWithStrike.OptionType,
            LegId: legWithStrike.Id,
            Index: legIndex,
          }
        : undefined;
    }
  )((state: OrderState, blockId: string) => blockId),
  legStrikeById: createCachedSelector(
    (state: OrderState, blockId: string) => state.legs,
    (_, blockId: string, legId: string) => blockId,
    (_, blockId: string, legId: string) => legId,
    (legs: Legs, blockId, legId) => {
      const leg = _.find(legs, l => l.Id === legId);
      return leg ? leg.Strike : undefined;
    }
  )((state: OrderState, blockId: string, legId: string) => `${blockId}-${legId}`),
  firstLegWithExpiration: createCachedSelector(
    (state: OrderState, blockId: string) => state.legs,
    (_, blockId) => blockId,
    (legs: Legs) => {
      const legWithExp = _.find(legs, l => l.Expiration !== undefined);
      return legWithExp
        ? {
            Expiration: legWithExp.Expiration,
            Action: legWithExp.Action,
            LegId: legWithExp.Id,
          }
        : undefined;
    }
  )((state: OrderState, blockId: string) => blockId),
  legExpirationById: createCachedSelector(
    (state: OrderState, blockId: string) => state.legs,
    (_, blockId: string, legId: string) => blockId,
    (_, blockId: string, legId: string) => legId,
    (legs: Legs, blockId, legId) => {
      const leg = _.find(legs, l => l.Id === legId);
      return leg ? leg.Expiration : undefined;
    }
  )((state: OrderState, blockId: string, legId: string) => `${blockId}-${legId}`),
  legActionById: createCachedSelector(
    (state: OrderState, blockId: string) => state.legs,
    (_, blockId: string, legId: string) => blockId,
    (_, blockId: string, legId: string) => legId,
    (legs: Legs, blockId, legId) => {
      const leg = _.find(legs, l => l.Id === legId);
      return leg ? leg.Action : undefined;
    }
  )((state: OrderState, blockId: string, legId: string) => `action-${blockId}-${legId}`),
  symbol: createCachedSelector(
    (state: OrderState, blockId: string) => state.symbol,
    (_, blockId) => blockId,
    (symbol?: AssetSymbol) => (symbol ? symbol.symbol : undefined)
  )((state: OrderState, blockId: string) => blockId),
  hasSharesLeg: createSelector(
    rootSel,
    (state: OrderState) => (_.find(state.legs, value => value.AssetType === AssetType.Equity) ? true : false)
  ),
  hasLegs: createCachedSelector(
    (state: OrderState, blockId: string) => state.legs,
    (_, blockId) => blockId,
    (legs: Legs) => (_.keys(legs).length > 0 ? true : false)
  )((state: OrderState, blockId: string) => blockId),
  legById: createSelector(
    (state: OrderState) => state.legs,
    (state: OrderState, id: string) => id,
    (legs, id) => legs[id]
  ),

  legs: createDeepSelector(rootSel, (state: OrderState) => _.map(state.legs, l => l)),
  legsWithSymbols: createSelector(
    (state: OrderState) => state.legs,
    sels.assetSymbol,
    (legs: Legs, symbol: AssetSymbol | undefined) =>
      _.map(legs, (leg, key: string) => {
        let symbolOcc = undefined;
        if (symbol === undefined) {
          symbolOcc = undefined;
        } else if (!leg.OptionType) {
          symbolOcc = symbol;
        } else if (leg.OptionType && leg.Expiration && leg.Strike) {
          symbolOcc = GetOCCSymbol({
            underlyingSymbol: symbol.symbol,
            symbolType: symbol.type,
            expiration: leg.Expiration,
            optionType: leg.OptionType,
            strike: leg.Strike,
          });
        } else {
          symbolOcc = undefined;
        }
        return {
          ...leg,
          occSymbol: symbolOcc,
        };
      })
  ),
  legField: createCachedSelector(
    rootSel,
    (state: OrderState, id: string) => id,
    <K extends keyof OrderLegDTO>(state: OrderState, id: string, field: K) => field,
    <K extends keyof OrderLegDTO>(state: OrderState, id: string, field: K): Partial<OrderLegDTO>[K] => {
      const leg = state.legs[id];
      return leg ? leg[field] : undefined;
    }
  )(<K extends keyof OrderLegDTO>(state: OrderState, id: string, field: K) => `${id}-${field}`),
  legIds: createSelector(
    // inputSelectors
    (state: OrderState) => state.legs,
    (legs: Legs) =>
      _(legs)
        .orderBy((v, k) => (v.AssetType === AssetType.Equity ? 0 : 1))
        .map((v, k) => v.Id)
        .filter(v => v !== undefined)
        .value()
  ),
  legStrikes: createSelector(
    // inputSelectors
    rootSel,
    (state: OrderState) => _.map(state.legs, (v, k) => ({ id: v.Id, strike: v.Strike }))
  ),
  optionLegs: createDeepSelector(
    // inputSelectors
    rootSel,
    (state: OrderState) => _.filter(state.legs, (l, k) => l.AssetType === AssetType.Option)
  ),
  order: createCachedSelector(
    sels.legArray,
    sels.duration,
    sels.orderType,
    sels.price,
    sels.stopPrice,
    sels.assetSymbol,
    sels.quantity,
    sels.strategy,
    sels.positions,
    sels.debitCredit,
    sels.subaccountId,
    sels.orderId,
    sels.commissionOverrides,
    sels.action,
    (state: OrderState, blockId: string) => blockId,
    (
      legValues: OrderLegDTOState[],
      duration: Durations,
      orderType: OrderType,
      price: number | undefined,
      stopPrice: number | undefined,
      symbol: AssetSymbol | undefined,
      quantity: number,
      strategy,
      positions,
      debitCredit,
      subaccountId,
      orderId,
      commissionOverrides,
      action,
      blockId
    ) => {
      let legs: Legs = _.reduce(
        legValues,
        (legAcc, l) => {
          return {
            ...legAcc,
            [l.Id]: {
              ...l,
            },
          };
        },
        {}
      );
      if (legValues.length > 1) {
        legs = _.reduce(
          legValues,
          (legAcc, l) => {
            const Quantity = strategy.info.Name !== 'Custom' ? (l.SpreadRatio || 1) * quantity : l.SpreadRatio || 1;
            return {
              ...legAcc,
              [l.Id]: {
                ...l,
                Quantity,
              },
            };
          },
          {}
        );
      }

      const partialState = {
        legs,
        duration,
        orderType,
        symbol,
        price,
        stopPrice,
        quantity,
        strategy: strategy.info.Name,
        positions,
        debitCredit,
        subaccountId,
        orderId,
        action,
        ...commissionOverrides,
      };

      const orderValue = OrderStateToOrder(partialState);
      return orderValue;
    }
  )((state: OrderState, blockId: string) => `${blockId}:${state.symbol ? state.symbol.symbol : ''}`),
  cachedOrder: createDeepSelector([(state: OrderState) => state, sels.assetSymbol], (state: OrderState, symbol) =>
    OrderStateToOrder({ ...state, symbol })
  ),
  quantity: createSelector(
    [rootSel],
    (state: OrderState) => (state.quantity === undefined ? 1 : state.quantity)
  ),
  action: orderActionSelector,
  actionForPrice: createSelector(
    (s: Pick<OrderState, 'action' | 'strategy' | 'legs'>) => orderActionSelector(s),
    (s: Pick<OrderState, 'action' | 'strategy' | 'legs'>) => s.legs,
    (s: Pick<OrderState, 'action' | 'strategy' | 'legs'>) => s.strategy,
    (action, legs, strategy) => {
      if (isStrategy([CallCalendar, PutCalendar], strategy)) {
        return OrderAction.Buy;
      } else if (strategy === 'Custom' && _.every(legs, l => l.Action === OrderAction.Sell)) {
        return OrderAction.Sell;
      }
      return action;
    }
  ),
  positions: createSelector(
    (state: OrderState) => state,
    (state: OrderState) => state.positions
  ),
  matchingStrategy: createSelector(
    [sels.matchingStrategy],
    strats => (strats ? _.first(strats) : undefined)
  ),
  strategy: createSelector(
    [rootSel],
    (state: OrderState) => state.strategy
  ),
  isCustomStrategyOrUndefined: createSelector(
    [sels.strategy],
    strategy => (strategy ? isCustomStrategy(strategy) : true)
  ),
  isSingleLegStrategy: createSelector(
    [rootSel],
    orderState => {
      if (
        (orderState.strategy && isSingleLegStrategy(orderState.strategy)) ||
        Object.keys(orderState.legs).length === 1
      ) {
        return true;
      }
      return false;
    }
  ),
  strategyName: createSelector(
    [rootSel],
    (state: OrderState) => (state.strategy ? state.strategy : undefined)
  ),
  strategyIsValid: createSelector(
    [computed.orderIsComplete, sels.strategy, sels.matchingStrategy],
    (
      isComplete: boolean,
      strategy: TradingStrategy | CustomTradingStrategy | undefined,
      matchingStrategies?: TradingStrategyOrCustom[]
    ) => {
      console.info('strategyIsValid', isComplete, strategy, matchingStrategies);
      if (!strategy || isCustomStrategy(strategy)) {
        return undefined;
      }
      if (isComplete && strategy) {
        const match = _.find(matchingStrategies || [], s => s.info.Name === strategy.info.Name) || undefined;
        return match ? true : false;
      } else {
        return undefined;
      }
    }
  ),
  total: createSelector(
    [rootSel, legsSel, (orderState: OrderState) => orderState.quantity],
    (state: OrderState, legs: Legs, quantity: number) => {
      const price = state.price;
      if (price === undefined) {
        return 0;
      }
      const prices = _.map(legs, val => {
        const ratio = val.SpreadRatio === undefined ? 1 : val.SpreadRatio;
        let unitPrice: number | undefined = price;
        if (val.AssetType === AssetType.Option) {
          unitPrice = val.Strike;
        }
        if (unitPrice === undefined) {
          console.warn('invalid price', val);
          return 0;
        } else {
          return ratio * (unitPrice * quantity);
        }
      });
      return _.sum(prices);
    }
  ),
  legExpirations: createSelector(
    [(state: OrderState) => state],
    (state: OrderState) => {
      const expirations = _.map(state.legs, l => l.Expiration);
      return _.reduce(
        expirations,
        (all: { date: dayjs.Dayjs; expiration: Expiration }[], e) => {
          if (e === undefined) {
            return all;
          } else {
            return [...all, { date: dayjs(e.date), expiration: e }];
          }
        },
        []
      );
    }
  ),
  // strikesAreInvalid: createCachedSelector([(state: OrderState) => state.validation.field, (state: OrderState, blockId: string) => blockId], field => {
  //   return field === 'Strike';
  // })((_, blockId) => blockId),
  fieldIsInvalid: createCachedSelector(
    [
      (state: OrderState) => state.validation.field,
      (state: OrderState, blockId: string) => blockId,
      (state: OrderState, blockId: string, fieldName: keyof OrderLegDTO | keyof Order) => fieldName,
    ],
    (field, blockId, fieldName) => {
      return field === fieldName || _.toLower(field) === _.toLower(fieldName);
    }
  )((_, blockId, fieldName) => `${blockId}-${fieldName}`),

  isStrategyInvalid: createCachedSelector(
    (state: OrderState, blockId: string) => state.validation.errors,
    (state: OrderState, blockId: string) => state.validation.valid,
    (errors, valid) => {
      const positionIsInvalid = _.some(errors, e => e.type === 'OrderFieldError' && e.field === 'Position');
      if (valid) {
        return false;
      } else if (positionIsInvalid) {
        return false;
      } else {
        return valid === false;
      }
    }
  )((state, blockId) => blockId),
  invalidLegPosition: createCachedSelector(
    (state: OrderState, legId: string) => state.validation.errors,
    (state: OrderState, legId: string) => state.validation.valid,
    (state: OrderState, legId: string) => SymbolOccSelector(state, { id: legId }),
    (state: OrderState, legId: string) => legId,
    (errors, valid, symbol) => {
      if (valid) {
        return undefined;
      }
      const res = _.find(errors, e => e.symbol === symbol && e.type === 'OrderFieldError' && e.field === 'Position');
      return res;
    }
  )((state, legId) => legId),
  validationErrorMessage: createCachedSelector(
    (state: OrderState, blockId: string) => state.validation,
    validation => {
      if (validation.valid === true) {
        return undefined;
      } else if (!_.isNil(validation.message)) {
        return validation.message;
      } else {
        const firstError = _.first(validation.errors);
        return firstError ? _.get(firstError, 'message', undefined) : undefined;
      }
    }
  )((state, blockId) => blockId),
  isSpread: isSpreadOrder,
};
