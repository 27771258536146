import React from 'react';
import { useQuoteDirectionAndValue } from '../../hooks/useFeedQuote';
import { Number } from '@tradingblock/components';
import { DirectionalValue } from '../../components/basic/Directional';

export const SymbolPrice: React.FunctionComponent<{ symbol: string }> = ({ symbol }) => {
  const { price } = useQuoteDirectionAndValue(symbol);

  return (
    <DirectionalValue value={price}>
      <strong className="txt-lg">
        <span className="currency">$</span>
        <Number value={price} format />
      </strong>
    </DirectionalValue>
  );
};
