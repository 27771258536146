import React, { useCallback } from 'react';
import { Formik, Field } from 'formik';
import { useDispatch } from 'react-redux';
import { AllReleaseNoteIssueTypes, AllUserLevelTypes, ReleaseNotesUpdateValues } from '@tradingblock/types';
import { InfoDataActions } from '../../../../data/global/actions/InfoActions';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { FormGroup, SelectDropdownField, Modal, TextareaField } from '@tradingblock/components';
import dayjs from 'dayjs';
import { getRequiredError } from '../../../AccountManagement/modals/Validation';
import { useReleaseNotesData } from '../../../Info/useInfo';

export const EditReleaseNote: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
  releaseNote: ReleaseNotesUpdateValues;
}> = ({ show, toggleModal, releaseNote }) => {
  const dispatch = useDispatch();
  const { type, jiraIssueNumber, releaseDate, restrictedToUserLevel, summary, id } = releaseNote;
  const { ...filterData } = useReleaseNotesData();
  const { pageSize, page } = filterData;

  const initialReleaseNoteValues: ReleaseNotesUpdateValues = {
    id,
    type,
    jiraIssueNumber,
    releaseDate,
    restrictedToUserLevel,
    summary,
    page,
    pageSize,
  };

  const onValidate = (values: ReleaseNotesUpdateValues) => {
    const { type, jiraIssueNumber, releaseDate, restrictedToUserLevel, summary } = values;

    if (!type || !restrictedToUserLevel || !summary || summary === '') {
      return {
        [`type`]: getRequiredError(type),
        [`jiraIssueNumber`]: getRequiredError(jiraIssueNumber),
        [`releaseDate`]: getRequiredError(releaseDate),
        [`restrictedToUserLevel`]: getRequiredError(restrictedToUserLevel),
        [`summary`]: getRequiredError(summary),
      };
    }
  };

  const now = dayjs().toISOString();

  const onSubmit = useCallback(
    (values, { resetForm }) => {
      const releaseNoteValues = {
        id: values.id,
        restrictedToUserLevel: values.restrictedToUserLevel,
        summary: values.summary,
        type: values.type,
        releaseDate: now,
      };
      dispatch(InfoDataActions.requestUpdateRelease(releaseNoteValues));

      resetForm();
      toggleModal();
    },
    [toggleModal]
  );

  return (
    <>
      <Formik initialValues={initialReleaseNoteValues} validate={onValidate} onSubmit={onSubmit}>
        {({ handleSubmit, resetForm, values }) => (
          <Modal setShow={() => toggleModal()} show={show}>
            <BootstrapModal.Header closeButton>
              <BootstrapModal.Title>Create Release Notes</BootstrapModal.Title>
            </BootstrapModal.Header>
            <BootstrapModal.Body>
              <form id="releaseNotes">
                <FormGroup>
                  <FormGroup>
                    <Field
                      component={SelectDropdownField}
                      id={'restrictedToUserLevel'}
                      options={AllUserLevelTypes}
                      label={'User Level Restriction'}
                    />
                    <span className="txt-sm">
                      Determines the users able to see the release notice. (Ex. Selecting Admin will make notice
                      viewable only to Admin level users. Default value of 'Account' is viewable to all)
                    </span>
                  </FormGroup>
                  <Field
                    component={SelectDropdownField}
                    id={'type'}
                    options={AllReleaseNoteIssueTypes}
                    label={'Release Type'}
                  />
                </FormGroup>
                <FormGroup>
                  <Field
                    component={TextareaField}
                    name={'summary'}
                    id={'summary'}
                    label="Enter release notes summary"
                  />
                </FormGroup>
              </form>
            </BootstrapModal.Body>
            <BootstrapModal.Footer className="modal-footer-justified">
              <Button
                variant="secondary"
                onClick={() => {
                  resetForm();
                  toggleModal();
                }}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={(values: any) => handleSubmit(values)}>
                Submit
              </Button>
            </BootstrapModal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};
