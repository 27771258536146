import { getType } from 'typesafe-actions';
import _ from 'lodash';
import {
  PrivateUserCashieringState,
  SuccessResponseCode,
  GenericError,
  CashieringSearchEntityWithKey,
  CashieringSearchEntitiesState,
} from '@tradingblock/types';
import { getIdNumber } from '@tradingblock/api';
import { InitialState } from '../../initialState';
import {
  cashieringRequestKey,
  cashieringRequestToQueryString,
  getEntityKey,
  cashieringEntityKey,
} from '../../utilities/cashieringUtilities';
import { AdminCashieringActions, AdminCashieringAction } from '../../actions/admin/AdminCashieringActions';
import { CashieringEventActions, CashieringEventAction } from '../../actions/CashieringEventActions';

const entityTypeForAction = (
  action: AdminCashieringAction
):
  | (keyof Pick<
      PrivateUserCashieringState,
      | 'beneficiaries'
      | 'recipientBanks'
      | 'relationships'
      | 'relationshipDetails'
      | 'transfers'
      | 'transferDetails'
      | 'wireInstructions'
      | 'wireInstructionsDetails'
    >)
  | undefined => {
  switch (action.type) {
    case getType(AdminCashieringActions.approveFirmError):
    case getType(AdminCashieringActions.approveFirmReceive):
    case getType(AdminCashieringActions.approveFirmRequest):
    case getType(AdminCashieringActions.approveRepError):
    case getType(AdminCashieringActions.approveRepReceive):
    case getType(AdminCashieringActions.approveRepRequest):
    case getType(AdminCashieringActions.cancelTransferError):
    case getType(AdminCashieringActions.cancelTransferReceive):
    case getType(AdminCashieringActions.cancelTransferRequest):
    case getType(AdminCashieringActions.rejectTransferError):
    case getType(AdminCashieringActions.rejectTransferReceive):
    case getType(AdminCashieringActions.rejectTransferRequest):
    case getType(AdminCashieringActions.updateTransferError):
    case getType(AdminCashieringActions.updateTransferReceive):
    case getType(AdminCashieringActions.updateTransferRequest):
    case getType(AdminCashieringActions.cashieringTransferError):
    case getType(AdminCashieringActions.cashieringTransferReceive):
    case getType(AdminCashieringActions.cashieringTransferRequest): {
      return 'transfers';
    }
    case getType(AdminCashieringActions.cashieringTransferDetailError):
    case getType(AdminCashieringActions.cashieringTransferDetailReceive):
    case getType(AdminCashieringActions.cashieringTransferDetailRequest): {
      return 'transferDetails';
    }
    case getType(AdminCashieringActions.approveCreateRelationshipError):
    case getType(AdminCashieringActions.approveCreateRelationshipReceive):
    case getType(AdminCashieringActions.approveCreateRelationshipRequest):
    case getType(AdminCashieringActions.cancelRelationshipError):
    case getType(AdminCashieringActions.cancelRelationshipReceive):
    case getType(AdminCashieringActions.cancelRelationshipRequest):
    case getType(AdminCashieringActions.rejectRelationshipError):
    case getType(AdminCashieringActions.rejectRelationshipReceive):
    case getType(AdminCashieringActions.rejectRelationshipRequest):
    case getType(AdminCashieringActions.updateRelationshipError):
    case getType(AdminCashieringActions.updateRelationshipReceive):
    case getType(AdminCashieringActions.updateRelationshipRequest):
    case getType(AdminCashieringActions.cashieringRelationshipError):
    case getType(AdminCashieringActions.cashieringRelationshipReceive):
    case getType(AdminCashieringActions.cashieringRelationshipRequest): {
      return 'relationships';
    }
    case getType(AdminCashieringActions.cashieringRelationshipDetailError):
    case getType(AdminCashieringActions.cashieringRelationshipDetailReceive):
    case getType(AdminCashieringActions.cashieringRelationshipDetailRequest): {
      return 'relationshipDetails';
    }
    case getType(AdminCashieringActions.cashieringWireInstructionsApproveError):
    case getType(AdminCashieringActions.cashieringWireInstructionsApproveReceive):
    case getType(AdminCashieringActions.cashieringWireInstructionsApproveRequest):
    case getType(AdminCashieringActions.cashieringWireInstructionsRejectError):
    case getType(AdminCashieringActions.cashieringWireInstructionsRejectReceive):
    case getType(AdminCashieringActions.cashieringWireInstructionsRejectRequest):
    case getType(AdminCashieringActions.cashieringWireInstructionsUpdateError):
    case getType(AdminCashieringActions.cashieringWireInstructionsUpdateReceive):
    case getType(AdminCashieringActions.cashieringWireInstructionsUpdateRequest):
    case getType(AdminCashieringActions.cashieringWireInstructionsError):
    case getType(AdminCashieringActions.cashieringWireInstructionsReceive):
    case getType(AdminCashieringActions.cashieringWireInstructionsRequest):
    case getType(AdminCashieringActions.cashieringWireInstructionsCancelError):
    case getType(AdminCashieringActions.cashieringWireInstructionsCancelReceive):
    case getType(AdminCashieringActions.cashieringWireInstructionsCancelRequest): {
      return 'wireInstructions';
    }
    case getType(AdminCashieringActions.cashieringWireInstructionsDetailsError):
    case getType(AdminCashieringActions.cashieringWireInstructionsDetailsReceive):
    case getType(AdminCashieringActions.cashieringWireInstructionsDetailsRequest): {
      return 'wireInstructionsDetails';
    }
    default: {
      return undefined;
    }
  }
};

const defaultQueryState = (queryString: string) => ({
  queryString,
  ids: [],
  isFetching: false,
  error: undefined,
});

const SearchError = (message: string, error: string): GenericError<string, string> => ({
  message,
  error,
});
type Actions = AdminCashieringAction | CashieringEventAction;
export const privateCashieringReducer = (
  state: PrivateUserCashieringState = InitialState.private.cashiering,
  action: Actions
): PrivateUserCashieringState => {
  switch (action.type) {
    case getType(AdminCashieringActions.setPage):
    case getType(AdminCashieringActions.setPageSize):
    case getType(AdminCashieringActions.setSortBy): {
      return {
        ...state,
        ui: {
          ...state.ui,
          ...action.payload,
        },
      };
    }
    case getType(AdminCashieringActions.setType):
    case getType(AdminCashieringActions.setAccountStatus):
    case getType(AdminCashieringActions.setTimeframe):
    case getType(AdminCashieringActions.setStatus):
    case getType(AdminCashieringActions.refresh):
    case getType(AdminCashieringActions.setRepCode):
    case getType(AdminCashieringActions.setSearch): {
      return {
        ...state,
        ui: {
          ...state.ui,
          ...action.payload,
          page: 0,
        },
      };
    }
    case getType(AdminCashieringActions.cashieringSearchRequest): {
      const requestkey = cashieringRequestKey(action.payload);
      const existingQueryState =
        state.byQuery[requestkey] || defaultQueryState(cashieringRequestToQueryString(action.payload));
      return {
        ...state,
        byQuery: {
          ...state.byQuery,
          [requestkey]: {
            ...existingQueryState,
            isFetching: true,
            error: undefined,
          },
        },
      };
    }
    case getType(AdminCashieringActions.cashieringSearchReceive): {
      const { request, response } = action.payload;
      const { replace } = request;
      const querykey = cashieringRequestKey(request);
      const existingQueryState = state.byQuery[querykey] || defaultQueryState(cashieringRequestToQueryString(request));
      const { payload } = response || { payload: undefined };
      const data = payload && payload.data ? payload.data : [];
      const total = payload && payload.total ? payload.total : 0;
      const incomingData = _.map(data, (re): CashieringSearchEntityWithKey => ({ ...re, entityKey: getEntityKey(re) }));
      const isErrored = response.responseCode !== SuccessResponseCode;
      const updatedEntities = _.reduce(
        incomingData,
        (acc: CashieringSearchEntitiesState, value: CashieringSearchEntityWithKey) => {
          return {
            ...acc,
            [value.entityKey]: value,
          };
        },
        state.entities
      );

      const ids = incomingData.map(v => v.entityKey);

      return {
        ...state,
        entities: updatedEntities,
        byQuery: {
          ...state.byQuery,
          [querykey]: {
            ...existingQueryState,
            isFetching: false,
            ids,
            total,
            error: isErrored
              ? SearchError('Cashiering search error', `Responsecode ${response.responseCode}`)
              : undefined,
          },
        },
      };
    }

    case getType(AdminCashieringActions.approveCreateRelationshipRequest):
    case getType(AdminCashieringActions.rejectRelationshipRequest):
    case getType(AdminCashieringActions.cancelRelationshipRequest):
    case getType(AdminCashieringActions.updateRelationshipRequest): {
      const { payload } = action;
      const id = getIdNumber(payload.id);

      return {
        ...state,
        relationships: {
          ...state.relationships,
          [id]: {
            ...(state.relationships[id] || {}),
            isSaving: true,
          },
        },
      };
    }
    case getType(AdminCashieringActions.approveCreateRelationshipReceive):
    case getType(AdminCashieringActions.rejectRelationshipReceive):
    case getType(AdminCashieringActions.cancelRelationshipReceive):
    case getType(AdminCashieringActions.updateRelationshipReceive): {
      const relationship = action.payload.payload;
      const { id, note, adminNote } = relationship;

      const existingState = state.relationships[id] || { data: {} };
      const entityKey = cashieringEntityKey('relationships', id);
      return {
        ...state,
        entities: {
          ...state.entities,
          [entityKey]: {
            ...(state.entities[entityKey] || {}),
            note,
            adminNote,
          },
        },
        relationships: {
          ...state.relationships,
          [id]: {
            ...existingState,
            isSaving: false,
            data: { ...existingState.data, ...relationship },
          },
        },
      };
    }

    case getType(AdminCashieringActions.cashieringWireInstructionsCancelRequest): {
    }

    case getType(AdminCashieringActions.approveFirmRequest):
    case getType(AdminCashieringActions.approveRepRequest):
    case getType(AdminCashieringActions.rejectTransferRequest):
    case getType(AdminCashieringActions.cancelTransferRequest):
    case getType(AdminCashieringActions.updateTransferRequest): {
      const { payload } = action;
      const id = getIdNumber(payload.id);

      return {
        ...state,
        transfers: {
          ...state.transfers,
          [id]: {
            ...(state.transfers[id] || {}),
            isSaving: true,
          },
        },
      };
    }
    case getType(AdminCashieringActions.approveFirmReceive):
    case getType(AdminCashieringActions.approveRepReceive):
    case getType(AdminCashieringActions.rejectTransferReceive):
    case getType(AdminCashieringActions.cancelTransferReceive):
    case getType(AdminCashieringActions.updateTransferReceive): {
      const { id, note, adminNote } = action.payload.payload;
      const entityState = action.payload.payload.state;
      const existingState = state.transfers[id] || { data: {} };
      const entityType = 'transfers';
      const entityKey = cashieringEntityKey(entityType, id);
      return {
        ...state,
        entities: {
          ...state.entities,
          [entityKey]: {
            ...(state.entities[entityKey] || {}),
            note,
            adminNote,
          },
        },
        transfers: {
          ...state.transfers,
          [id]: {
            ...existingState,
            isSaving: false,
            data: { ...existingState.data, note, state: entityState },
          },
        },
      };
    }
    case getType(AdminCashieringActions.cashieringTransferRequest):
    case getType(AdminCashieringActions.cashieringTransferDetailRequest):
    case getType(AdminCashieringActions.cashieringRelationshipRequest):
    case getType(AdminCashieringActions.cashieringRelationshipDetailRequest):
    case getType(AdminCashieringActions.cashieringWireInstructionsRequest): {
      const { payload } = action;
      const entityType = entityTypeForAction(action);
      const id = getIdNumber(payload.id);

      if (!entityType) {
        return state;
      }

      return {
        ...state,
        [entityType]: {
          ...state[entityType],
          [id]: {
            ...(state[entityType][id] || {}),
            isFetching: true,
            isErrored: false,
          },
        },
      };
    }
    case getType(AdminCashieringActions.cashieringWireInstructionsDetailsRequest): {
      const { payload } = action;
      const entityType = entityTypeForAction(action);
      const id = getIdNumber(payload.transferInstructionId || payload.id);

      if (!entityType) {
        return state;
      }

      return {
        ...state,
        [entityType]: {
          ...state[entityType],
          [id]: {
            ...(state[entityType][id] || {}),
            isFetching: true,
            isErrored: false,
          },
        },
      };
    }

    case getType(AdminCashieringActions.cashieringWireInstructionsDetailsReceive): {
      const { payload } = action.payload;
      const entityType = entityTypeForAction(action);
      const id = getIdNumber(payload.transferInstructionId || payload.id);

      if (!entityType) {
        return state;
      }

      return {
        ...state,
        [entityType]: {
          ...state[entityType],
          [id]: {
            ...(state[entityType][id] || {}),
            isFetching: false,
            isErrored: false,
            error: undefined,
            data: payload,
          },
        },
      };
    }

    case getType(AdminCashieringActions.cashieringTransferReceive):
    case getType(AdminCashieringActions.cashieringTransferDetailReceive):
    case getType(AdminCashieringActions.cashieringRelationshipReceive):
    case getType(AdminCashieringActions.cashieringRelationshipDetailReceive):
    case getType(AdminCashieringActions.cashieringWireInstructionsReceive):
    case getType(AdminCashieringActions.cashieringWireInstructionsDetailsReceive): {
      const { payload } = action.payload;
      const entityType = entityTypeForAction(action);
      const id = getIdNumber(payload.id);

      if (!entityType) {
        return state;
      }

      return {
        ...state,
        [entityType]: {
          ...state[entityType],
          [id]: {
            ...(state[entityType][id] || {}),
            isFetching: false,
            isErrored: false,
            error: undefined,
            data: payload,
          },
        },
      };
    }
    case getType(AdminCashieringActions.cashieringTransferError):
    case getType(AdminCashieringActions.cashieringTransferDetailError):
    case getType(AdminCashieringActions.cashieringRelationshipError):
    case getType(AdminCashieringActions.cashieringRelationshipDetailError):
    case getType(AdminCashieringActions.approveCreateRelationshipError):
    case getType(AdminCashieringActions.approveFirmError):
    case getType(AdminCashieringActions.approveRepError):
    case getType(AdminCashieringActions.rejectRelationshipError):
    case getType(AdminCashieringActions.rejectTransferError):
    case getType(AdminCashieringActions.cancelRelationshipError):
    case getType(AdminCashieringActions.cancelTransferError):
    case getType(AdminCashieringActions.updateRelationshipError):
    case getType(AdminCashieringActions.updateTransferError):
    case getType(AdminCashieringActions.cashieringSearchError):
    case getType(AdminCashieringActions.cashieringWireInstructionsError):
    case getType(AdminCashieringActions.cashieringWireInstructionsDetailsError): {
      const entityType = entityTypeForAction(action);
      const error = action.payload;
      const { data } = error;
      if (data === undefined || !entityType) {
        return state;
      }
      const id = getIdNumber(data.id);
      return {
        ...state,
        [entityType]: {
          ...state[entityType],
          [id]: {
            ...(state[entityType][id] || {}),
            isFetching: false,
            isSaving: false,
            isErrored: true,
            error,
          },
        },
      };
    }

    case getType(AdminCashieringActions.cashieringWireInstructionsUpdateRequest):
    case getType(AdminCashieringActions.cashieringWireInstructionsCancelRequest): {
      const { payload } = action;
      const entityType = entityTypeForAction(action);
      const id = getIdNumber(payload.id);

      if (!entityType) {
        return state;
      }

      return {
        ...state,
        [entityType]: {
          ...state[entityType],
          [id]: {
            ...(state[entityType][id] || {}),
            isSaving: true,
            isErrored: false,
          },
        },
      };
    }

    case getType(AdminCashieringActions.cashieringWireInstructionsUpdateReceive):
    case getType(AdminCashieringActions.cashieringWireInstructionsCancelReceive): {
      const { payload } = action;
      const entityType = entityTypeForAction(action);
      const id = getIdNumber(action.payload.payload.id);
      const { adminNote, note } = action.payload.payload;

      const entityKey = cashieringEntityKey('wireInstructions', id);

      if (!entityType) {
        return state;
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [entityKey]: {
            ...(state.entities[entityKey] || {}),
            note,
            adminNote,
          },
        },
        [entityType]: {
          ...state[entityType],
          [id]: {
            ...(state[entityType][id] || {}),
            isSaving: false,
            isErrored: false,
            error: undefined,
            data: payload.payload,
          },
        },
      };
    }

    case getType(AdminCashieringActions.cashieringWireInstructionsUpdateError):
    case getType(AdminCashieringActions.cashieringWireInstructionsCancelError): {
      const entityType = entityTypeForAction(action);
      const error = action.payload;
      const { data } = error;
      if (data === undefined || !entityType) {
        return state;
      }

      const id = getIdNumber(data.id);
      return {
        ...state,
        [entityType]: {
          ...state[entityType],
          [id]: {
            ...(state[entityType][id] || {}),
            isSaving: false,
            isErrored: true,
            error,
          },
        },
      };
    }

    default:
      return state || InitialState.private.cashiering;
  }
};
