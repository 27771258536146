/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Formik } from 'formik';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import * as Yup from 'yup';
import { FormGroup, Modal, Tooltip, } from '@tradingblock/components';
import { DefaultGridLayout, UiSettings } from '@tradingblock/types';
import { useDispatcher } from './../../data/global/hooks';
import { useStateSelector, account, dashboardsSelectors } from '../../data/global/dataSelectors';
import { useStore } from './../../context/Storage';
import { useDashboardSettings, useDashboardSettingsUpdater } from './../../hooks/useDashboardSettings';
import { GenericField } from '../form/GenericField';
import { Config } from '../../config';
import { useVirtualTrading } from '../../hooks/useVirtualTrading';
import { useSelector } from 'react-redux';
import { InitialState } from '../../data/global/initialState';

export const DashboardSettings: React.FC<{ show?: boolean; toggleModal: () => void }> = ({ show, toggleModal }) => {
  const { columns, ...initialSettings } = useDashboardSettings();
  const { updateSettings } = useDashboardSettingsUpdater();
  const { isVirtualEnv } = useVirtualTrading();
  const { dispatcher } = useDispatcher();
  const [shouldResetSettings, setShouldResetSettings] = useState(false);
  const [shouldDisableSubmit, setShouldDisableSubmit] = useState(false);
  const dashboardId = useSelector(dashboardsSelectors.dashboardId);
  const accountId = useStateSelector(account.accountId);
  const store = useStore();
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState<boolean>(false);
  const [submitBtnRef, setSubmitBtnRef] = useState<HTMLButtonElement | null>(null);

  const initialUiState = InitialState.settings.ui;
  const defaultDashboardSettings = {
    ...DefaultGridLayout,
    muteAllSounds: initialUiState.muteAllSounds,
    showBannerSymbols: initialUiState.showBannerSymbols,
    bannerSymbols: initialUiState.bannerSymbols,
    afterHoursTrading: initialUiState.afterHoursTrading,
    balancePollingThrottleMs: initialUiState.balancePollingThrottleMs,
    feedSyncIntervalMs: initialUiState.feedSyncIntervalMs,
    cashieringPollingInterval: initialUiState.cashieringPollingInterval,
    cashieringPendingReminderInterval: initialUiState.cashieringPendingReminderInterval,
    cashieringPendingReminderMaxAttempts: initialUiState.cashieringPendingReminderMaxAttempts,
    hotkeys: initialUiState.hotkeys,
  };

  useEffect(() => {
    const tryReset = async () => {
      const approvedSettingsReset = shouldResetSettings && window.confirm(
        'Are you sure you want to reset dashboard settings?\nAll settings will be return to default values.\nContinue?'
      );
      if (dashboardId && approvedSettingsReset) {
        if (accountId) {
          dispatcher.settings.setSettings(accountId, defaultDashboardSettings);
        }
      }
      if (approvedSettingsReset) toggleModal();
    };
    if (store && (shouldResetSettings)) tryReset();
    return () => {
      setShouldDisableSubmit(false);
      setShouldResetSettings(false);
    }
  }, [dashboardId, shouldResetSettings, shouldDisableSubmit, store, toggleModal]);

  const onSubmit = useCallback(
    (values: UiSettings, { setSubmitting }) => {
      const settings = {
        ...values,
        gridColumnsLarge: _.toNumber(values.gridColumnsLarge),
        gridColumnsMedium: _.toNumber(values.gridColumnsMedium),
        gridColumnsSmall: _.toNumber(values.gridColumnsSmall),
        width: _.toNumber(values.width),
        feedSyncIntervalMs: _.toNumber(values.feedSyncIntervalMs),
        cashieringPollingInterval: _.toNumber(values.cashieringPollingInterval),
        cashieringPendingReminderInterval: _.toNumber(values.cashieringPendingReminderInterval),
        cashieringPendingReminderMaxAttempts: _.toNumber(values.cashieringPendingReminderMaxAttempts),
      };
      !shouldDisableSubmit && updateSettings(settings);
      !shouldDisableSubmit && toggleModal();
    },
    [updateSettings, toggleModal]
  );
  const onCancel = useCallback(() => {
    toggleModal();
  }, [toggleModal]);
  const canViewCashieringAdmin = useStateSelector(account.hasCashieringAdminPermission);

  // Validation
  const genericNumberInputErrorMsg = 'Please enter a positive integer greater than zero.'
  const genericNumberInputValidationSchema = Yup
  .number()
  .transform((value) => isNaN(value) ? null : value)
  .nullable()
  .required(genericNumberInputErrorMsg)
  .positive(genericNumberInputErrorMsg)
  .integer(genericNumberInputErrorMsg);
  
  const feedSyncIntervalMsInputMinValue = 500;
  const feedSyncIntervalMsInputMaxValue = 1000000000;
  const feedSyncIntervalMsInputErrorMsg = `Please enter an integer between ${feedSyncIntervalMsInputMinValue} and ${feedSyncIntervalMsInputMaxValue}.`
  const feedSyncIntervalMsInputValidationSchema = Yup
    .number()
    .transform((value) => isNaN(value) ? null : value)
    .nullable()
    .required(feedSyncIntervalMsInputErrorMsg)
    .positive(feedSyncIntervalMsInputErrorMsg)
    .integer(feedSyncIntervalMsInputErrorMsg)
    .min(feedSyncIntervalMsInputMinValue, feedSyncIntervalMsInputErrorMsg)
    .max(feedSyncIntervalMsInputMaxValue, feedSyncIntervalMsInputErrorMsg);

  const gridColumnsLargeName = 'gridColumnsLarge';
  const gridColumnsMediumName = 'gridColumnsMedium';
  const gridColumnsSmallName = 'gridColumnsSmall';
  const widthName = 'width';
  const feedSyncIntervalMsName = 'feedSyncIntervalMs';
  const cashieringPollingIntervalName = 'cashieringPollingInterval';
  const cashieringPendingReminderIntervalName = 'cashieringPendingReminderInterval';
  const cashieringPendingReminderMaxAttemptsName = 'cashieringPendingReminderMaxAttempts';

  return (
    <Formik
      initialValues={initialSettings}
      enableReinitialize={true}
      validateOnChange
      validationSchema={Yup
        .object()
        .shape({
          gridColumnsSmall: genericNumberInputValidationSchema,
          gridColumnsLarge: genericNumberInputValidationSchema,
          gridColumnsMedium: genericNumberInputValidationSchema,
          width: genericNumberInputValidationSchema,
          cashieringPollingInterval: genericNumberInputValidationSchema,
          cashieringPendingReminderInterval: genericNumberInputValidationSchema,
          cashieringPendingReminderMaxAttempts: genericNumberInputValidationSchema,
          feedSyncIntervalMs: feedSyncIntervalMsInputValidationSchema,
        })
      }
      onSubmit={onSubmit}
    >
      {({ values, errors, handleSubmit }) => (
        <Modal setShow={() => toggleModal()} show={show}>
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Dashboard settings</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            <form id="settings" onSubmit={(e) => {
              e.preventDefault()
              handleSubmit()
            }}>
              <h5>Grid</h5>
              <FormGroup>
                <GenericField
                  name={gridColumnsLargeName}
                  value={values.gridColumnsLarge}
                  settings={{
                    type: 'number',
                    label: 'Grid columns for large display',
                    name: gridColumnsLargeName,
                    min: '1',
                  }}
                />
                <GenericField
                  name={gridColumnsMediumName}
                  value={values.gridColumnsMedium}
                  settings={{
                    type: 'number',
                    label: 'Grid columns for medium display',
                    name: gridColumnsMediumName,
                    min: '1',
                  }}
                />
                <GenericField
                  name={gridColumnsSmallName}
                  value={values.gridColumnsSmall}
                  settings={{
                    type: 'number',
                    label: 'Grid columns for small display',
                    name: gridColumnsSmallName,
                    min: '1',
                  }}
                />
                <GenericField
                  name={widthName}
                  value={values.width}
                  settings={{
                    type: 'number',
                    label: 'Grid width',
                    name: widthName,
                    min: '1',
                  }}
                />
              </FormGroup>
              <h5>Trading</h5>
              <FormGroup>
                {!Config.isProduction && (
                  <GenericField
                    name="afterHoursTrading"
                    value={values.afterHoursTrading}
                    settings={{ type: 'boolean', label: 'Enable trading after market close' }}
                  />
                )}
              </FormGroup>
              <h5>Data</h5>
              <FormGroup>
                <GenericField
                  name={feedSyncIntervalMsName}
                  value={values.feedSyncIntervalMs}
                  settings={{
                    type: 'number',
                    label: 'Quote update interval (milliseconds)',
                    name: feedSyncIntervalMsName,
                    min: feedSyncIntervalMsInputMinValue.toString(),
                    max: feedSyncIntervalMsInputMaxValue.toString(),
                  }}
                />
              </FormGroup>
              <FormGroup>
                {canViewCashieringAdmin && (
                  <>
                    <GenericField
                      name={cashieringPollingIntervalName}
                      value={values.cashieringPollingInterval}
                      settings={{
                        type: 'number',
                        label: 'Cashiering update poll interval (seconds)',
                        name: cashieringPollingIntervalName,
                        min: '1',
                      }}
                    />
                    <GenericField
                      name={cashieringPendingReminderIntervalName}
                      value={values.cashieringPendingReminderInterval}
                      settings={{
                        type: 'number',
                        label: 'Cashiering pending event reminder interval (seconds)',
                        name: cashieringPendingReminderIntervalName,
                        min: '1',
                      }}
                    />
                    <GenericField
                      name={cashieringPendingReminderMaxAttemptsName}
                      value={values.cashieringPendingReminderMaxAttempts}
                      settings={{
                        type: 'number',
                        label: 'Cashiering pending event reminder max attempts',
                        name: cashieringPendingReminderMaxAttemptsName,
                        min: '1',
                      }}
                    />
                  </>
                )}
              </FormGroup>
              <h5>Search Banner Symbols</h5>
              <FormGroup>
                <GenericField
                  name="pickBannerSymbols"
                  value={values.bannerSymbols}
                  settings={{ type: 'symbolPicker' }}
                />
              </FormGroup>
              <h5>Miscellaneous</h5>
              <FormGroup>
                <GenericField
                  name="hotkeys"
                  value={values.hotkeys}
                  settings={{ type: 'boolean', label: 'Enable Hotkeys' }}
                />
                <Tooltip
                  effect="solid"
                  id="hotkeysMessage"
                  content={
                    <ul>
                      <li>ALT+E - Toggle Dashboard lock</li>
                      <li>ALT+S - Toggle Dashboard settings</li>
                      <li>ALT+O - Create Order block</li>
                    </ul>
                  }
                  type="light"
                >
                  <button className="btn" type="button" style={{ paddingLeft: 0 }}>
                    (see hotkey shortcuts)
                  </button>
                </Tooltip>
                <br />
                <GenericField
                  name="muteAllSounds"
                  value={values.muteAllSounds}
                  settings={{ type: 'boolean', label: 'Mute All Sounds' }}
                />
                <br />
                <GenericField
                  name="showBannerSymbols"
                  value={values.showBannerSymbols}
                  settings={{ type: 'boolean', label: 'Enable Banner Symbols' }}
                />
                <br />
              </FormGroup>
              {/* TODO: In order to keep the buttons in the footer in order to have the placement of the buttons fixed so they do not scroll */}
              {/* in the body of the modal and since we need the Formik context the invisible button below was added which hooks into */}
              {/* the Formik context. The ref from the button is then used to pass the click handler and the disabled state to the button in the footer. */}
              {/* The ref prop is using a function instead of useRef in order to get the latest ref state (node) to set the applicable items in the */}
              {/* component state in order to get the component to rerender so that it has the latest versions of the attributes of the node. */}
              {/* We should look into refactoring how we setup the forms using Formik in order to avoid this hack. */}
              <button
                type="submit"
                style={{ display: 'none' }}
                ref={(node) => {
                  setSubmitBtnDisabled(!!(node && node.disabled));
                  setSubmitBtnRef(node)
                }}
                disabled={Object.keys(errors).length > 0}
              />
            </form>
            <span style={{ display: 'flex', justifyContent: 'left' }}>
              {!Config.isProduction && !isVirtualEnv && (
                <button className="nav-link" type="button" onClick={() => setShouldResetSettings(true)}>
                  <i className="fal fa-arrow-left" /> Reset Settings
                </button>
              )}
            </span>
          </BootstrapModal.Body>
          <BootstrapModal.Footer className="modal-footer-justified">
            <Button variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => submitBtnRef && submitBtnRef.click()}
              disabled={submitBtnDisabled}
              className="settings-submit-btn"
            >
              Save
            </Button>
          </BootstrapModal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
