import _ from 'lodash';
import {
  TradingblockOrder,
  OrderType,
  OrderClass,
  Durations,
  OrderLegDTO,
  isCustomStrategy,
  OrderStrategyType,
  TradingStrategyNameOrCustom,
  OrderStrategyTypeId,
  StrategyType,
  AssetType,
  OrderAction,
} from '@tradingblock/types';
import { OrderState } from './OrderState';
import { DtoToOrderleg, getPositionEffect, getStrategiesForSymbol } from '../OrderUtilities';
import { GCD } from '../../../utilities/data';
import { AllStrategies, parseOccSymbol, toAssetSymbol, toExpiration } from '@tradingblock/api';

export function OrderStrategyIdToOrderStrategyName(stratId: OrderStrategyTypeId): any {
  switch (stratId) {
    case OrderStrategyTypeId.Stock:
      return 'Shares';
    case OrderStrategyTypeId.Call: //Need Single Call:
      return 'Call';
    case OrderStrategyTypeId.Put: //Need Single Put:
      return 'Put';
    case OrderStrategyTypeId.CoveredCall:
      return 'Covered Call';
    case OrderStrategyTypeId.MarriedPut:
      return 'Married Put';
    case OrderStrategyTypeId.Vertical: //Need a Call Vertical:
      return 'Vertical';
    case OrderStrategyTypeId.Calendar: //Need a Call Calendar:
      return 'Calendar';
    case OrderStrategyTypeId.Calendar: // Need Put Calendar:
      return 'Calendar';
    case OrderStrategyTypeId.Vertical: //Need Put Vertical:
      return 'Vertical';
    case OrderStrategyTypeId.Straddle:
      return 'Straddle';
    case OrderStrategyTypeId.Strangle:
      return 'Strangle';
    case OrderStrategyTypeId.Butterfly: //Need Call:
      return 'Butterfly';
    case OrderStrategyTypeId.Butterfly: //Need Put
      return 'Butterfly';
    case OrderStrategyTypeId.Condor: //Need Call:
      return 'Condor';
    case OrderStrategyTypeId.Condor: //Need Put:
      return 'Condor';
    case OrderStrategyTypeId.IronButterfly:
      return 'Iron Butterfly';
    case OrderStrategyTypeId.IronConder:
      return 'Iron Condor';
    case OrderStrategyTypeId.Conversion:
      return 'Conversion';
    case OrderStrategyTypeId.Undefined:
      return 'Custom';
    case OrderStrategyTypeId.Box:
      return 'Box Spread';
  }
  return 'Custom';
}
export function OrderStrategyTypeToOrderStrategyName(strat: OrderStrategyType): TradingStrategyNameOrCustom {
  switch (strat) {
    case OrderStrategyType.Stock:
      return 'Shares';
    case OrderStrategyType.Call: //Need Single Call:
      return 'Call';
    case OrderStrategyType.Put: //Need Single Put:
      return 'Put';
    case OrderStrategyType.CoveredCall:
      return 'Covered Call';
    case OrderStrategyType.MarriedPut:
      return 'Married Put';
    case OrderStrategyType.Vertical: //Need a Call/PUT Vertical:
      return 'Vertical';
    case OrderStrategyType.CallVertical: //Need a Call Vertical:
      return 'Call Vertical';
    case OrderStrategyType.PutVertical: //Need a Call Vertical:
      return 'Put Vertical';
    case OrderStrategyType.Calendar: //Need a Call Calendar:
      return 'Calendar';
    case OrderStrategyType.Calendar: // Need Put Calendar:
      return 'Calendar';
    case OrderStrategyType.CallCalendar: //Need a Call Calendar:
      return 'Call Calendar';
    case OrderStrategyType.PutCalendar: // Need Put Calendar:
      return 'Put Calendar';
    case OrderStrategyType.Straddle:
      return 'Straddle';
    case OrderStrategyType.Strangle:
      return 'Strangle';
    case OrderStrategyType.CallButterfly:
      return 'Call Butterfly';
    case OrderStrategyType.PutButterfly:
      return 'Put Butterfly';
    case OrderStrategyType.CallCondor: //Need Call:
      return 'Call Condor';
    case OrderStrategyType.PutCondor: //Need Put:
      return 'Put Condor';
    case OrderStrategyType.IronButterfly:
      return 'Iron Butterfly';
    case OrderStrategyType.IronConder:
      return 'Iron Condor';
    case OrderStrategyType.Conversion:
      return 'Conversion';
    case OrderStrategyType.Undefined:
      return 'Custom';
    case OrderStrategyType.Box:
      return 'Box Spread';
  }
  return 'Custom';
}
export function OrderStrategyNameToOrderStrategyType(
  strat: TradingStrategyNameOrCustom | undefined
): OrderStrategyType {
  switch (strat) {
    case 'Shares':
      return OrderStrategyType.Stock;
    case 'Call':
      return OrderStrategyType.Call; //Need Single Call
    case 'Put':
      return OrderStrategyType.Put; //Need Single Put
    case 'Covered Call':
      return OrderStrategyType.CoveredCall;
    case 'Married Put':
      return OrderStrategyType.MarriedPut;
    case 'Call Vertical':
      return OrderStrategyType.CallVertical; //Need a Call Vertical
    case 'Put Vertical':
      return OrderStrategyType.PutVertical; //Need Put Vertical
    case 'Calendar':
      return OrderStrategyType.Calendar; //Need a Call Calendar
    case 'Call Calendar':
      return OrderStrategyType.CallCalendar; // Need Put Calendar
    case 'Put Calendar':
      return OrderStrategyType.PutCalendar; // Need Put Calendar
    case 'Straddle':
      return OrderStrategyType.Straddle;
    case 'Strangle':
      return OrderStrategyType.Strangle;
    case 'Butterfly':
      return OrderStrategyType.Butterfly;
    case 'Put Butterfly':
      return OrderStrategyType.PutButterfly;
    case 'Call Butterfly':
      return OrderStrategyType.CallButterfly;
    case 'Condor':
      return OrderStrategyType.Condor; //Need Call
    case 'Call Condor':
      return OrderStrategyType.CallCondor; //Need Put
    case 'Put Condor':
      return OrderStrategyType.PutCondor; //Need Call
    case 'Iron Butterfly':
      return OrderStrategyType.IronButterfly;
    case 'Iron Condor':
      return OrderStrategyType.IronConder;
    case 'Conversion':
      return OrderStrategyType.Conversion;
    case 'Custom':
      return OrderStrategyType.Undefined;
    case 'Box Spread':
      return OrderStrategyType.Box;
  }
  return OrderStrategyType.Undefined;
}

export const OrderStateToOrder = (
  {
    legs,
    orderType,
    duration,
    price,
    stopPrice,
    symbol,
    quantity,
    strategy,
    positions,
    debitCredit,
    subaccountId,
    orderId,
    placeAs,
    executeAs,
    commission,
    commissionOverrideCode,
    bypassMargin,
    action,
  }: Pick<
    OrderState,
    | 'strategy'
    | 'quantity'
    | 'legs'
    | 'orderType'
    | 'duration'
    | 'price'
    | 'stopPrice'
    | 'symbol'
    | 'positions'
    | 'debitCredit'
    | 'subaccountId'
    | 'orderId'
    | 'placeAs'
    | 'executeAs'
    | 'commission'
    | 'commissionOverrideCode'
    | 'bypassMargin'
    | 'action'
  >,
  prefixOptionLegNames: boolean = true
): TradingblockOrder => {
  const isCustom = strategy ? isCustomStrategy(strategy) : true;
  const filteredLegs = _.filter(legs, l => l.Id !== undefined);
  let orderQuantity = quantity;

  const isSpread = filteredLegs.length > 1 ? true : false;
  const orderLegs = filteredLegs.map(l => {
    const leg = l as OrderLegDTO;
    const legRes = DtoToOrderleg(leg, symbol);

    const existingPosition = positions && positions[legRes.Symbol] ? positions[legRes.Symbol] : undefined;
    const existingPositionDirection = existingPosition ? existingPosition.direction : undefined;
    return {
      ...legRes,
      PositionEffect: getPositionEffect(l.Action, existingPositionDirection),
    };
  });

  const AllOrNone = false;

  const Description = '';

  //const Quantity = isSpread ? 1 : hasLegs && orderLegs[0].SpreadRatio ? orderLegs[0].SpreadRatio : 1;
  //set default ordType to limit
  //isLimitOrder = true if ordType/orderType is limit or stop_limit or stop_market
  const ordType = orderType || OrderType.Limit;
  const isLimitOrder =
    orderType === OrderType.Limit ||
    orderType === OrderType.Stop_Limit ||
    orderType === OrderType.Stop_Market ||
    ordType === OrderType.Limit ||
    ordType === OrderType.Stop_Limit ||
    ordType === OrderType.Stop_Market;
  const isCustomStrategyOrCalendar = isCustom || strategy === 'Put Calendar' || strategy === 'Call Calendar';

  const Price =
    isLimitOrder && price !== undefined ? (!isCustomStrategyOrCalendar ? Math.abs(price) : price) : undefined;
  const Stop =
    isLimitOrder && stopPrice !== undefined
      ? !isCustomStrategyOrCalendar
        ? Math.abs(stopPrice)
        : stopPrice
      : undefined;

  const ord: TradingblockOrder = {
    kind: 'order',
    AccountId: undefined,
    SubaccountId: subaccountId,
    UnderlyingSymbol: symbol ? symbol.symbol : undefined,
    Legs: orderLegs,
    ClientRefId: undefined,
    OrderClass: isSpread ? OrderClass.Multileg : OrderClass.Single,
    StrategyType: OrderStrategyNameToOrderStrategyType(strategy),
    OrderType: ordType,
    Duration: duration || Durations.Day,
    Price: ordType !== OrderType.Stop_Market ? Price : undefined,
    Stop: ordType === OrderType.Stop_Market || ordType === OrderType.Stop_Limit ? Stop : undefined,
    Quantity: orderQuantity,
    AllOrNone,
    Description,
    PlacedAs: placeAs,
    ExecutedAs: executeAs,
    CommissionType: commission,
    CommissionScheduleId: commissionOverrideCode,
    BypassMargin: bypassMargin,
    // we will set this later
    DebitCredit: debitCredit,
    OrderId: orderId,
    Action: action,
  };
  return ord;
};

export const OrderToOrderState = (order: TradingblockOrder): OrderState => {
  const legs = order.Legs.reduce(
    (legs, l, index) => {
      const leg = (l as unknown) as OrderLegDTO;
      const assetSymbol = toAssetSymbol(leg.UnderlyingSymbol, leg.AssetType, '', {});
      const legRes = DtoToOrderleg(leg, assetSymbol);
      const symbolInfo = parseOccSymbol(l.Symbol);

      let leg_res = {
        ...legs,
        ['legs_' + (index + 1)]: {
          ...legRes,
          Symbol: leg.OccSymbol,
          OccSymbol: leg.OccSymbol,
          Strike: symbolInfo.strike,
          OptionType: symbolInfo.option,
          Action: l.Action,
          Id: 'legs_' + (index + 1),
          Expiration: symbolInfo.expiration ? toExpiration(symbolInfo.expiration, symbolInfo.symbol) : undefined,
        },
      };
      if (order.Legs.length > 1) {
        leg_res['legs_' + (index + 1)] = {
          ...leg_res['legs_' + (index + 1)],
          SpreadRatio: l.SpreadRatio,
        };
      } else {
        // remove the spread ratio if it is a single leg order
        delete leg_res['legs_' + (index + 1)].SpreadRatio;
      }
      return leg_res;
    },
    {} as any
  );
  const isSpread = Object.keys(legs).length > 1 ? true : false;

  let strategy = OrderStrategyTypeToOrderStrategyName(
    order.StrategyType ? order.StrategyType : OrderStrategyType.Undefined
  );

  // Gross code is here... We are gonna rewrite this when it gets better sync
  // TODO: Rewrite this when we have better sync with backend
  if (strategy === 'Vertical') {
    let temp_legs: any[] = Object.values(legs);
    if (temp_legs.length > 0 && temp_legs[0].OptionType === 'Call') {
      strategy = 'Call Vertical';
    } else {
      strategy = 'Put Vertical';
    }
  }

  if (strategy === 'Calendar') {
    let temp_legs: any[] = Object.values(legs);
    if (temp_legs.length > 0 && temp_legs[0].OptionType === 'Call') {
      strategy = 'Call Calendar';
    } else {
      strategy = 'Put Calendar';
    }
  }

  if (strategy === 'Butterfly') {
    let temp_legs: any[] = Object.values(legs);
    if (temp_legs.length > 0 && temp_legs[0].OptionType === 'Call') {
      strategy = 'Call Butterfly';
    } else {
      strategy = 'Put Butterfly';
    }
  }

  if (strategy === 'Condor') {
    let temp_legs: any[] = Object.values(legs);
    if (temp_legs.length > 0 && temp_legs[0].OptionType === 'Call') {
      strategy = 'Call Condor';
    } else {
      strategy = 'Put Condor';
    }
  }

  const isCustom = strategy ? isCustomStrategy(strategy) : true;
  const orderQuantity = isCustom && isSpread ? 1 : order.Quantity;
  const orderType = order.OrderType;
  const duration = order.Duration;
  const price = order.Price;
  const stopPrice = order.Stop;
  const quantity = orderQuantity;
  const positions = {};
  const debitCredit = order.DebitCredit;
  const subaccountId = order.SubaccountId;
  const orderId = order.OrderId;

  const symbol = toAssetSymbol(order.UnderlyingSymbol ? order.UnderlyingSymbol : '', AssetType.Equity, '', {});

  return {
    legs,
    orderType,
    duration,
    price,
    stopPrice,
    symbol,
    quantity,
    strategy,
    positions,
    debitCredit,
    subaccountId,
    orderId,
    placeAs: order.PlacedAs,
    executeAs: order.ExecutedAs,
    commission: order.CommissionType,
    commissionOverrideCode: order.CommissionScheduleId,
    bypassMargin: order.BypassMargin,
    preview: {
      enabled: false,
    },
    validStrategies: getStrategiesForSymbol(symbol),
    recalculatePrice: false,
    quotes: {},
    action: order.Action ? order.Action : OrderAction.Buy,
    isDirty: false,
    validation: {
      isValidating: false,
      errors: [],
    },
    tables: {},
  };
};
