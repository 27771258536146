import { BlockBody, Tab, Tabs } from '@tradingblock/components';
import { BlockType } from '@tradingblock/types';
import React, { useState } from 'react';
import { Block } from '../../components/blocks/Block';
import { getBlockProps } from '../../utilities/block';
import AccountStatementsHeader from './AccountStatementsHeader';
import AccountStatementsDailyConfirms from './tabs/AccountStatementsDailyConfirms';
import AccountStatementsMonthlyStatements from './tabs/AccountStatementsMonthlyStatements';
import AccountStatementsTaxDocuments from './tabs/AccountStatementsTaxDocuments';

export interface AccountStatementsProps {

}

export default function AccountStatements({}: AccountStatementsProps) {
    return (
    <Block
        {...getBlockProps(
          BlockType.AccountManagement,
          <AccountStatementsHeader blockType={BlockType.AccountStatements}/>
        )}
      >
        <BlockBody className={'block-body'}>
            <AccountStatementsRender/>
        </BlockBody>
      </Block>
    )
}

export interface AccountStatementsRenderProps {

}
export function AccountStatementsRender({}: AccountStatementsRenderProps) {
    const [tab, setTab] = useState<string>("daily_confirms");

    return (
        <div className="nav-tabs-pill">
        <Tabs defaultActiveKey={tab} id="notification-tabs" onSelect={(ev: string) => setTab(ev)}>
          <Tab eventKey={"daily_confirms"} title="Daily Confirms">
            {tab === "daily_confirms" && <AccountStatementsDailyConfirms />}
          </Tab>
          <Tab eventKey={"monthly_statements"} title="Monthly Statement">
            {tab === "monthly_statements" && <AccountStatementsMonthlyStatements />}
          </Tab>
          <Tab eventKey={"tax_documents"} title="Tax Documents">
            {tab === "tax_documents" && <AccountStatementsTaxDocuments />}
          </Tab>
        </Tabs>
      </div>
    );
}