import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { useOnClickOutside } from 'use-hooks';
import { useScrollablePosition } from '../hooks/useScrollablePosition';
export var Portal = function Portal(_ref) {
  var parent = _ref.parent,
      scrollableParent = _ref.scrollableParent,
      open = _ref.open,
      toggle = _ref.toggle,
      children = _ref.children;

  var onPortalClick = function onPortalClick(e) {
    if (!e.isDefaultPrevented()) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  var onPortalChildClick = function onPortalChildClick(e) {
    e.preventDefault();
  };

  if (!open) {
    return null;
  }

  var ref = useRef(null);
  useOnClickOutside(ref, function (e) {
    if (!parent.contains(e.target)) {
      toggle(false);
    }
  });
  var position = useScrollablePosition(parent, scrollableParent); // use parent position to style portal

  var style = {
    position: 'absolute',
    top: position.top + position.height,
    left: position.left,
    width: position.width
  };
  return ReactDOM.createPortal(React.createElement("div", {
    className: "portal",
    style: style,
    onClick: onPortalClick,
    ref: ref
  }, React.createElement("div", {
    onClick: onPortalChildClick
  }, children)), document.getElementById('portal-root'));
};