import { BlockColumn } from '../../components/blocks/BlockColumn';
import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  AggregatedProfitLossInfo,
  Balances,
  BalanceTotals,
  BasicSymbolProfitLossInfo,
  ProfitLossInfoMap,
} from '@tradingblock/types';
import _ from 'lodash';
import { accountBalances, useStateSelector } from '../../data/global/dataSelectors';
import { SubAccountFilter } from '../shared/SubAccountFilter';
import { useDispatch, useSelector } from 'react-redux';
import Config from '../../config';
import { account } from '../../data/global/selectors/accountSelectors';
import {
  AccountApiFactory,
  GetSymbolsFromOrders,
  PartialQuote,
  PositionDTO,
  TodaysFilledOrders,
} from '@tradingblock/api';
import { QuoteSymbolQuotes } from '../../data/global/state';
import { aggregatedSymbolPosition, QuoteDataToPartialQuote } from '../../data/global/selectors/transforms';
import { getCashAndCashAlts } from '../../data/global/utilities/balances';
import { PerformanceSelectors } from '../../data/global/selectors/performanceSelectors';
import { pnlSelectors } from '../../data/global/selectors/PnLSelectors';
import { SymbolPnLReducer } from '../../data/global/sliceReducers/performance/performanceBySymbolReducer';
import { UIActions } from '../../data/global/actions';

export const AccountBalanceView: FC<{ totals?: BalanceTotals }> = ({ totals }) => {
  // variables for building api client
  const accountId = useSelector(account.accountId);
  const apiRootUrl = Config.tradingApi;
  const token = useStateSelector(state => state.auth.apiToken) || undefined;

  // redux states
  const subId = useStateSelector(state => state.account.subAccountId);
  const quotes = useStateSelector(state => state.quotes.quoteMetadata.data);
  const positionValue = useStateSelector(s => s.performance.totals.positionValue);
  const { total } = useStateSelector(PerformanceSelectors.totals.all);
  const loaded = useStateSelector(pnlSelectors.pnlDataLoaded);
  const quoteState = useStateSelector(state => state.quotes);
  const orders = useStateSelector(state => state.account.orders);
  const hasSubAccounts = useStateSelector(
    s => s.account.subaccounts.subaccounts && s.account.subaccounts.subaccounts.length > 0
  );
  const accountValue = useStateSelector(s => s.performance.totals.accountValue);
  const subAccountFilterId = useStateSelector(s => s.ui.dashboard.subAccountFilterId);
  const positions = useStateSelector(state => state.positions.positions);
  const subAccountBalances = useStateSelector(s => s.accountData.subAccountsBalances);
  const masterSubAccount = useStateSelector(
    state => state.account.subaccounts.subaccounts && state.account.subaccounts.subaccounts.find(s => s.IsMaster)
  );
  const isFetchingBalances = useStateSelector(accountBalances.isFetching);
  const isFetchingPositions = useStateSelector(s => s.positions.isFetching);

  const dispatch = useDispatch();

  // useStates for handling sub account filter
  const [selectedSubAccountId, setSelectedSubAccountId] = useState<number | undefined>(undefined);
  const [selectedSubAccountBalances, setSelectedSubAccountBalances] = useState<Balances | undefined>(undefined);
  const [selectedSubAccountPositions, setSelectedSubAccountPositions] = useState<PositionDTO[]>([]);
  const [selectedSubAccountCashValue, setSelectedSubAccountCashValue] = useState<number | undefined>(undefined);
  const [selectedSubAccountPositionValue, setSelectedSubAccountPositionValue] = useState<number | undefined>(undefined);
  const [selectedSubAccountTotalValue, setSelectedSubAccountTotalValue] = useState<number | undefined>(undefined);
  const [positionsRequested, setPositionsRequested] = useState(false);
  const [selectedSubAccountDailyPNL, setSelectedSubAccountDailyPNL] = useState<number | undefined>(undefined);
  const [selectedSubAccountTradingPNL, setSelectedSubAccountTradingPNL] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!subAccountFilterId) {
      setSelectedSubAccountId(undefined);
      setSelectedSubAccountBalances(undefined);
      setSelectedSubAccountPositions([]);
      setSelectedSubAccountPositionValue(undefined);
      setSelectedSubAccountCashValue(undefined);
      setSelectedSubAccountTotalValue(undefined);
      setSelectedSubAccountDailyPNL(undefined);
      setSelectedSubAccountTradingPNL(undefined);
    }
  }, [subAccountFilterId]);

  const setSubAccountId = async (subAccountId?: number) => {
    if (subAccountId) {
      if (subAccountId === -1) {
        setSelectedSubAccountId(undefined);
      } else {
        setSelectedSubAccountId(subAccountId);
      }
      setSelectedSubAccountPositions([]);
      setSelectedSubAccountCashValue(undefined);
      setSelectedSubAccountPositionValue(undefined);
      setSelectedSubAccountTotalValue(undefined);
      setSelectedSubAccountDailyPNL(undefined);
      setSelectedSubAccountTradingPNL(undefined);
      setSelectedSubAccountBalances(undefined);
      setPositionsRequested(false);
    }
  };

  const accountApi = useMemo(() => {
    if (accountId && token && !selectedSubAccountId) {
      return AccountApiFactory(apiRootUrl, token, accountId);
    }
    if (accountId && token && selectedSubAccountId === -1) {
      return AccountApiFactory(apiRootUrl, token, accountId);
    }
    if (accountId && apiRootUrl && token && selectedSubAccountId) {
      return AccountApiFactory(apiRootUrl, token, accountId, selectedSubAccountId);
    }
  }, [selectedSubAccountId, accountId, apiRootUrl, token]);

  const getSubAccountPositions = async () => {
    if (accountApi && selectedSubAccountId && selectedSubAccountPositions.length === 0 && !positionsRequested) {
      const positions = await accountApi.positions();
      setSelectedSubAccountPositions(positions.payload);
      setPositionsRequested(true);
      return positions.payload;
    }
  };

  const getSubAccountBalances = async () => {
    if (accountApi && selectedSubAccountId && !selectedSubAccountBalances) {
      const balances = await accountApi.balances();
      setSelectedSubAccountBalances(balances.payload);
      return balances.payload;
    }
  };

  const getSubAccountPositionQuotes = async (quotes: QuoteSymbolQuotes, positions: PositionDTO[]) => {
    const uniquePositions = _(positions)
      .map(p => [p.Symbol, p.UnderlyingSymbol])
      .flatMap()
      .uniq()
      .value();
    const quotesData = _.filter(quotes, (v, k: string) => uniquePositions.includes(k));
    const partialQuote = QuoteDataToPartialQuote(quotesData);
    return partialQuote;
  };

  const getSubAccountPositionValue = async (positions: PositionDTO[], subQuotes: PartialQuote[]) => {
    const positionValue = _(positions)
      .map(p => aggregatedSymbolPosition([p], subQuotes, p.UnderlyingSymbol).value)
      .sum();

    return positionValue;
  };

  const getSubAccountCashValue = async (balances: Balances) => {
    const cashValue = getCashAndCashAlts(balances);
    return cashValue;
  };

  const getAggregatedTotals = async (pnlMap: ProfitLossInfoMap) =>
    _.reduce(
      pnlMap,
      (acc: AggregatedProfitLossInfo, v: BasicSymbolProfitLossInfo): AggregatedProfitLossInfo => {
        const trading = acc.trading + v.trading;
        const position = acc.position + v.position;
        const total = trading + position;
        return {
          pnls: [...acc.pnls, v],
          trading,
          position,
          total,
        };
      },
      { trading: 0, position: 0, total: 0, pnls: [] }
    );

  const getSubAccountPositionsAndQuotes = async () => {
    if (accountApi && selectedSubAccountId && subAccountFilterId) {
      const positionResponse =
        selectedSubAccountPositions && selectedSubAccountPositions.length > 0
          ? selectedSubAccountPositions
          : await getSubAccountPositions();

      const balancesResponse = selectedSubAccountBalances || (await getSubAccountBalances());

      if (positionResponse && balancesResponse) {
        const subQuotes = await getSubAccountPositionQuotes(quotes, positionResponse);

        const positionValue = await getSubAccountPositionValue(positionResponse, subQuotes);

        const cashValue = await getSubAccountCashValue(balancesResponse);

        const totalValue = positionValue + cashValue;
        setSelectedSubAccountPositionValue(positionValue);
        setSelectedSubAccountCashValue(cashValue);
        setSelectedSubAccountTotalValue(totalValue);

        const todaysOrders =
          orders &&
          TodaysFilledOrders(orders).filter(o =>
            selectedSubAccountId !== -1 ? o.SubaccountId === selectedSubAccountId : o
          );

        const bySymbol = todaysOrders && SymbolPnLReducer(quoteState, todaysOrders, positionResponse);

        const all = bySymbol && (await getAggregatedTotals(bySymbol));

        if (all) {
          const { total } = all;
          setSelectedSubAccountDailyPNL(total);
        }
        const tradedSymbolsToday: string[] = GetSymbolsFromOrders(todaysOrders ? todaysOrders : []);

        const tradedToday = await getAggregatedTotals(
          _.reduce(
            bySymbol,
            (acc, v, k) => {
              if (tradedSymbolsToday.includes(v.symbol)) {
                return {
                  ...acc,
                  [k]: v,
                };
              }
              return acc;
            },
            {}
          )
        );

        if (tradedToday) {
          setSelectedSubAccountTradingPNL(tradedToday.trading);
        }
      }
    }
  };

  // This is put into place to refresh the Balance block when there is a change to the subaccount balances/positions.
  useEffect(() => {
    // This only needs to refresh when there is a selectedSubAccountId
    if (selectedSubAccountId) {
      const tempSubId = selectedSubAccountId;
      // SelectedSubAccount is the master (Assume either master or related subaccounts has changed)
      // Always refresh
      if (masterSubAccount && masterSubAccount.Id === tempSubId && subAccountBalances) {
        setSubAccountId(-1);
        setTimeout(() => setSubAccountId(tempSubId), 500);
      } else if (
        // if there is a change between the current block values and the new subAccountBalance cash values
        // refresh
        subAccountBalances &&
        selectedSubAccountCashValue &&
        subAccountBalances[tempSubId.toString()] &&
        subAccountBalances[tempSubId.toString()].Balances.CashBalance !== selectedSubAccountCashValue
      ) {
        setSubAccountId(-1);
        setTimeout(() => setSubAccountId(tempSubId), 500);
      } else if (positions) {
        // Checking if positions have been journaled into/away from the selected subaccount
        const fetchedSelectedSubPos = positions.filter(p => p.SubaccountId && p.SubaccountId === tempSubId);
        if (fetchedSelectedSubPos.length !== selectedSubAccountPositions.length) {
          setSubAccountId(-1);
          setTimeout(() => setSubAccountId(tempSubId), 500);
        }
      }
    }
  }, [subAccountBalances, positions]);

  useEffect(() => {
    if (!hasSubAccounts && subAccountFilterId) {
      dispatch(UIActions.setSubAccountFilterId({ id: -1 }));
      setSubAccountId(-1);
    }
  }, [hasSubAccounts]);

  useEffect(() => {
    if (accountApi && selectedSubAccountId && !subId) {
      getSubAccountPositionsAndQuotes();
    }
  }, [selectedSubAccountId, accountApi, subId, orders, quoteState]);

  return (
    <>
      {!subId && hasSubAccounts && (
        <div>
          <SubAccountFilter setSubAccountId={setSubAccountId} className="sub-account-filter-position-override" />
        </div>
      )}
      <div className="block-section">
        <div className="row">
          <BlockColumn
            title="Account value"
            value={selectedSubAccountTotalValue === 0 ? 0 : selectedSubAccountTotalValue || accountValue}
            isLoading={isFetchingBalances || isFetchingPositions}
          />
          <BlockColumn
            title="Position value"
            value={selectedSubAccountPositionValue === 0 ? 0 : selectedSubAccountPositionValue || positionValue}
            isLoading={isFetchingBalances || isFetchingPositions}
          />
        </div>
      </div>
      <div className="block-section">
        <div className="row">
          <BlockColumn
            title="Daily P/L"
            value={selectedSubAccountDailyPNL === 0 ? 0 : selectedSubAccountDailyPNL || total}
            signed
            isLoading={loaded.isLoaded !== true}
          />
        </div>
      </div>
      <div className="block-section">
        <div className="row">
          <BlockColumn
            title="Cash/Cash Alts"
            value={selectedSubAccountCashValue === 0 ? 0 : selectedSubAccountCashValue || ((totals && totals.cashAndCashAlts) || 0)}
            isLoading={isFetchingBalances}
          />
          <BlockColumn
            title="Available for trading"
            value={
              selectedSubAccountBalances
                ? selectedSubAccountBalances.BuyingPower.AvailableFunds === 0
                  ? 0
                  : selectedSubAccountBalances.BuyingPower.AvailableFunds
                : ((totals && totals.availableForTrading) || 0)
            }
            signed={true}
            hideIcon={true}
            hidePrefix={true}
            isLoading={isFetchingBalances}
          />
        </div>
      </div>
    </>
  );
};
