import React, { useCallback } from 'react';
import { Formik, Field, useField } from 'formik';
import { useDispatch } from 'react-redux';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { FormGroup, SelectDropdownField, Modal, TextboxField, TextareaField } from '@tradingblock/components';
import { AlertMessage, AlertMessageBaseItem, AllBooleanToggleTypes, BooleanToggleType } from '@tradingblock/types';
import { getRequiredError } from '../../../AccountManagement/modals/Validation';
import { AdminAlertActions } from '../../../../data/global/actions/admin/AdminAlertActions';
import { useAlertsData } from '../useAlertsData';

export const EditAlertMessage: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
  alertMessage: AlertMessageBaseItem;
}> = ({ show, toggleModal, alertMessage }) => {
  const { id, template, active } = alertMessage;
  const { data, total, ...filterData } = useAlertsData();
  const { pageSize, page } = filterData;
  const dispatch = useDispatch();
  const [showToolTip, setShowToolTip] = React.useState(false);

  const handleClick = () => {
    setShowToolTip(!showToolTip);
  };

  const initialAlertMessageValues: AlertMessage = {
    active: active,
    template: template,
  };

  const onValidate = (values: AlertMessage) => {
    const { template, active } = values;
    if (!template || !active) {
      return {
        [`template`]: getRequiredError(template),
        [`active`]: getRequiredError(active),
      };
    }
  };

  const onSubmit = useCallback(
    (values, { resetForm }) => {
      const alertValues = {
        id: id,
        active: values.active === 'yes' ? true : false,
        template: values.template,
        page,
        pageSize,
      };
      dispatch(AdminAlertActions.requestUpdateAlert(alertValues));
      resetForm();
      toggleModal();
    },
    [toggleModal]
  );
  return (
    <>
      <Formik initialValues={initialAlertMessageValues} onSubmit={onSubmit} validate={onValidate}>
        {({ handleSubmit, resetForm }) => (
          <Modal setShow={() => toggleModal()} show={show}>
            <BootstrapModal.Header closeButton>
              <BootstrapModal.Title>Edit Alert Message</BootstrapModal.Title>
            </BootstrapModal.Header>
            <BootstrapModal.Body>
              <FormGroup>
                <p className="txt-sm">Please provide the active state along with your alert template.</p>
                <Field
                  component={SelectDropdownField}
                  id={'active'}
                  options={AllBooleanToggleTypes}
                  defaultLabelId="boolean"
                  label={'Broadcast alert?'}
                />
                <Field component={TextareaField} id={'template'} label={'Alert Message'} type={'text'} />
              </FormGroup>
            </BootstrapModal.Body>
            <BootstrapModal.Footer>
              <div onClick={handleClick}>
                <i className="fal fa-question-circle fa-lg" />
              </div>
              <Button
                variant="secondary"
                onClick={() => {
                  toggleModal();
                  resetForm();
                }}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={(values: any) => handleSubmit(values)}>
                Save
              </Button>
            </BootstrapModal.Footer>
            {showToolTip && (
              <div className="container">
                <div className="tooltip-content">
                  <span className="fields-title">Available Tags</span>
                  <p className="txt-sm mute">
                    You may insert the following tags to be dynamically converted to the actual values from our
                    SiteConfig table according to which website a user is visiting:
                  </p>
                  <div>
                    <ul className="txt-sm" style={{ listStyle: 'none' }}>
                      <li>[SiteLabel] - TradingBlock</li>
                      <li>[PhoneNumber] - 1-1800-494-0451</li>
                      <li>[FaxNumber] - 1-312-253-0376</li>
                      <li>[InfoEmail] - info@tradingblock.com</li>
                      <li>[Url] - tradingblock.com</li>
                      <li>[ServiceEmail] - service@tradingblock.com</li>
                      <li>[AutoTradeEmail] - autotrade@tradingblock.com</li>
                      <li>[InternationalPhone] - +1-312-253-0385</li>
                      <li>[NewAccountEmail] - new_accounts@tradingblock.com</li>
                      <li>[ApiSupportEmail] - apisupport@tradingblock.com</li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </Modal>
        )}
      </Formik>
    </>
  );
};
