import React from 'react';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { AccountUpdateItemStatus } from './AccountManagementUpdateStatus';
import { AccountManagementBaseUpdate } from '@tradingblock/types';
import { AccountUpdateEntityAuditItems } from './details/AccountUpdateEntityAuditItems';

export const AccountManagementUpdateItemStatus: React.FunctionComponent<
  Pick<AccountManagementBaseUpdate, 'state' | 'item'>
> = ({ state, item }) => {
  const details = useStateSelector(s => s.private.accountManagement.currentItem.details);
  const { date } = details.created;
  const renderDate = new Date(date);

  return (
    <div className="screen-body-section">
      <p className="fields-title">Review Status:</p>

      <p>
        <strong>
          <AccountUpdateItemStatus state={state} />
        </strong>
      </p>

      <AccountUpdateEntityAuditItems item={item} />

      <p></p>
    </div>
  );
};
