import React, { useMemo } from 'react';
import _ from 'lodash';
import { useStateSelector } from '../../../../../data/global/dataSelectors';
import { BooleanToggleType, DefaultApplicationModel, RetirementAccountType } from '@tradingblock/types';
import { DetailField, DetailFields } from '../../../../../components/basic/DetailFields';

export const ApplicationSecureAccount: React.FunctionComponent<{}> = () => {
  const application = useStateSelector(s => s.private.applications.currentItem.application);
  const {
    primaryAccountHolder,
    ownerUsername,
    retirementAccount,
    ownerCountry,
    flexibilityType,
    type,
    clearer,
    retirementAccountType,
    beneficiaryIraDecedentName,
    simpleIraPlanName,
  } = application || DefaultApplicationModel;
  const { firstName, middleInitial, lastName, suffix, email } =
    primaryAccountHolder || DefaultApplicationModel.primaryAccountHolder;

  // console.log({ retirementAccount, type, retirementAccountType });

  const formatRetirementAccount = (toggleType: BooleanToggleType | null | undefined) => {
    if (!toggleType) {
      return 'No';
    }
    if (toggleType === BooleanToggleType.Yes) {
      return 'Yes';
    } else {
      return 'No';
    }
  };

  const formatFlexibilityType = (flexibilityType: string | null | undefined) => {
    if (!flexibilityType) {
      return '';
    }
    if (flexibilityType === 'CashOnly') {
      return 'Cash Only';
    } else {
      return 'Margin';
    }
  };

  const formatClearerType = (clearer: string | null | undefined) => {
    if (!clearer) {
      return '';
    }
    if (clearer === 'RQD') {
      return 'Zero Commission - RQD';
    } else {
      return 'Full Service - APEX';
    }
  };

  const fields = useMemo(() => {
    let fieldList: DetailField[] = [];
    if (application) {
      fieldList = [
        ...fieldList,

        {
          Value: () => (
            <>
              <div>
                <div className="fields-title mb-3">Secure Account</div>
                <div className="mb-3">
                  <div>Name</div>
                  <div className="mute">
                    {firstName} {middleInitial ? middleInitial : ''} {lastName} {suffix ? suffix : ''}
                  </div>
                </div>
                <div className="mb-3">
                  <div>Email</div>
                  <div className="mute">{email}</div>
                </div>
              </div>
              <div className="mb-3">
                <div>Username</div>
                <div className="mute mb-3">{ownerUsername}</div>
              </div>
            </>
          ),
        },
        {
          Value: () => (
            <div>
              <div className="fields-title mb-3">Account Type</div>
              <div className="mb-3">
                <div>Primary Account Holder Country</div>
                <div className="mute">{ownerCountry}</div>
              </div>
              <div className="mb-3">
                <div>Account Type</div>
                <div className="mute">{type}</div>
              </div>
              <div className="mb-3">
                <div> Retirement Account?</div>
                <div className="mute">{formatRetirementAccount(retirementAccount)}</div>
                {retirementAccountType === RetirementAccountType.Beneficiary && (
                  <div className="mb-3">
                    <div>Beneficiary IRA Decedent Name</div>
                    <div className="mute">{beneficiaryIraDecedentName}</div>
                  </div>
                )}
                {retirementAccountType === RetirementAccountType.Simple && (
                  <div className="mb-3">
                    <div>Simple IRA Plan Name</div>
                    <div className="mute">{simpleIraPlanName}</div>
                  </div>
                )}
              </div>
              <div className="mb-3">
                <div>Trading Flexibility</div>
                <div className="mute">{formatFlexibilityType(flexibilityType)}</div>
              </div>
              <div className="mb-3">
                <div>Clearer Type</div>
                <div className="mute">{formatClearerType(clearer)}</div>
              </div>
            </div>
          ),
        },
      ];
    }
    return fieldList;
  }, [application]);

  return (
    <div className="screen-body-section">
      <DetailFields fieldList={fields} valueAs="div" />
    </div>
  );
};
