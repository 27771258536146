import { DataState } from '../state';

import { Actions, RootAction } from '../actions';
import { performanceReducer } from './performance/performanceReducer';
import { Reducer } from 'redux';
import { InitialState } from '../initialState';
import { AccountInfo, AccountType, ClientEntity } from '@tradingblock/types';
import { includes } from 'lodash';
import { getType } from 'typesafe-actions';

export const RootCrossSliceReducer: Reducer<DataState> = (state: DataState = InitialState, action: RootAction) => {
  switch (action.type) {
    case getType(Actions.setAccount):
      const selectedAccount = state.accounts.accounts
        ? state.accounts.accounts.find(
            (account: AccountInfo) => account.AccountId === (action as any).payload.accountId
          )
        : state.accounts.account;

      const selectedLinkedAccount = state.linkedAccounts.accounts
        ? state.linkedAccounts.accounts.find(
            (account: ClientEntity) => account.accountId === (action as any).payload.accountId
          )
        : null;

      const originalAccount =
        state.account.selectedLinkedAccount && state.account.selectedLinkedAccount.originalAccount;

      if (selectedAccount) {
        const accountNumber = selectedAccount.AccountNumber;
        const isIRA = includes(
          [
            AccountType.Coverdell_IRA,
            AccountType.IRA_RollOver,
            AccountType.Roth_IRA,
            AccountType.SEP_IRA,
            AccountType.Traditional_IRA,
          ],
          selectedAccount.AccountType
        );
        const isUGMA = includes([AccountType.UGMA], selectedAccount.AccountType);
        const roles = selectedAccount.Roles;
        const nickname = selectedAccount.AccountTitle;

        return {
          ...state,
          account: {
            ...state.account,
            accountId: selectedAccount.AccountId,
            accountNumber,
            isIRA,
            isUGMA,
            roles,
            nickname,
            account: { ...selectedAccount },
            orders: undefined,
          },
          accounts: {
            ...state.accounts,
            account: { ...selectedAccount },
          },
          performance: {
            bySymbol: {},
            positions: {},
            totals: {
              positionValue: 0,
              accountValue: 0,
              tradedToday: { trading: 0, position: 0, total: 0, pnls: [] },
              all: { trading: 0, position: 0, total: 0, pnls: [] },
            },
          },
          positions: { isFetching: false, positions: [], isDirty: false, info: {}, loaded: false },
        };
      }
      if (selectedLinkedAccount) {
        const accountNumber = selectedLinkedAccount.accountNumber;
        const isIRA = includes(
          [
            AccountType.Coverdell_IRA,
            AccountType.IRA_RollOver,
            AccountType.Roth_IRA,
            AccountType.SEP_IRA,
            AccountType.Traditional_IRA,
          ],
          selectedLinkedAccount.accountType
        );
        const isUGMA = includes([AccountType.UGMA], selectedLinkedAccount.accountType);
        const nickname = selectedLinkedAccount.accountName;

        return {
          ...state,
          account: {
            ...state.account,
            accountId: selectedLinkedAccount.accountId,
            accountNumber,
            isIRA,
            isUGMA,
            roles: [],
            nickname,
            orders: undefined,
          },
          accounts: {
            ...state.accounts,
          },
          performance: {
            bySymbol: {},
            positions: {},
            totals: {
              positionValue: 0,
              accountValue: 0,
              tradedToday: { trading: 0, position: 0, total: 0, pnls: [] },
              all: { trading: 0, position: 0, total: 0, pnls: [] },
            },
          },
          positions: { isFetching: false, positions: [], isDirty: false, info: {}, loaded: false },
        };
      }
      if (originalAccount) {
        const accountNumber = originalAccount.accountNumber;
        const isIRA = includes(
          [
            AccountType.Coverdell_IRA,
            AccountType.IRA_RollOver,
            AccountType.Roth_IRA,
            AccountType.SEP_IRA,
            AccountType.Traditional_IRA,
          ],
          originalAccount.accountType
        );
        const isUGMA = includes([AccountType.UGMA], originalAccount.accountType);
        const nickname = originalAccount.accountName;

        return {
          ...state,
          account: {
            ...state.account,
            accountId: originalAccount.accountId,
            accountNumber: originalAccount.accountNumber,
            isIRA,
            isUGMA,
            roles: [],
            nickname,
            orders: undefined,
          },
          accounts: {
            ...state.accounts,
          },
          performance: {
            bySymbol: {},
            positions: {},
            totals: {
              positionValue: 0,
              accountValue: 0,
              tradedToday: { trading: 0, position: 0, total: 0, pnls: [] },
              all: { trading: 0, position: 0, total: 0, pnls: [] },
            },
          },
          positions: { isFetching: false, positions: [], isDirty: false, info: {}, loaded: false },
        };
      }
    default:
      return {
        ...state,
        performance: performanceReducer(
          state.performance,
          action,
          state.quotes,
          state.positions,
          state.account,
          state.accountData.balances
        ),
      };
  }
};
