import { BlockAction } from './../../../components/blocks/BlockState';
import { getType, Action } from 'typesafe-actions';
import _ from 'lodash';
import {
  IBlockState,
  OrderLegDTOState,
  TradingStrategy,
  TradingblockOrder,
  CustomTradingStrategy,
  isCustomStrategy,
  OrderAction,
  PositionInfo,
  OrderFieldError,
  OrderValidationError,
  AssetType,
  OptionType,
} from '@tradingblock/types';
import {
  tryValidateOrderForStrategy,
  SchemaValidator,
  Strategies,
  StrategyValidationResult,
  OrderStrategyValidationResult,
  getStrategy,
} from '@tradingblock/api';
import { OrderState } from '../state/OrderState';
import { OrderSelectors } from '../state/OrderSelector';
import { Actions, ValidationActions } from '../state/OrderActions';
import { validateOrderQuantitiesAndPositions } from '../validation/validateOrderQuantitiesAndPositions';
import { ValidateCustomStrategy } from './validation/CustomStrategyValidation';
import { OrderValidationTimeoutMS } from '../../../constants';
import {
  ClassificationOrderLeg,
  classifyStrategy,
  orderLegsToClassifiedStrategy,
} from '../../../data/global/utilities/strategyClassification';

interface StrategyValidation extends StrategyValidationResult {
  strategy: TradingStrategy;
}

const schema = SchemaValidator();

interface OrderValidationResults {
  results: { [id: string]: StrategyValidation };
  matches: TradingStrategy[];
}

const validateOrderAsync = async (strategies: TradingStrategy[], order: TradingblockOrder) => {
  const validationResults: {
    [id: string]: StrategyValidation;
  } = await _.reduce(
    strategies,
    async (validationResults: Promise<{ [id: string]: StrategyValidation }>, strat: TradingStrategy) => {
      const res = await validationResults;
      const { buy, sell } = await tryValidateOrderForStrategy(schema, strat, order);
      return { ...res, [strat.info.Name]: { strategy: strat, buy, sell } };
    },
    Promise.resolve({})
  );
  const valRes: StrategyValidation[] = _.values(validationResults);
  const matches = _.filter(valRes, vr => vr.buy.isValid || vr.sell.isValid);
  return {
    results: validationResults,
    matches,
  };
};

export const isOrderValid = (
  complete: boolean,
  dirty: boolean,
  strategy?: TradingStrategy | CustomTradingStrategy,
  match?: TradingStrategy
) => {
  let isValid = undefined;
  if (!complete || !dirty) {
    isValid = undefined;
  } else if (complete && strategy && isCustomStrategy(strategy)) {
    isValid = true;
  } else if (strategy && match && match.info.Name === strategy.info.Name) {
    isValid = true;
  } else {
    isValid = false;
  }
  return isValid;
};

const tryValidateOrderWithAnyStrategy = async (order: TradingblockOrder, complete: boolean) => {
  const stratsToMatch = Strategies;
  const validationRes = await validateOrderAsync(stratsToMatch, order);
  const firstRes = _.first(validationRes.matches);
  return {
    valid: true,
    match: firstRes ? firstRes.strategy : undefined,
    errors: [],
  };

  // setMatchingStrategies(strategies.map(ms => ms.strategy));
};

const parseValidationErrors = (validationResults: OrderStrategyValidationResult) => {
  if (!validationResults || !validationResults.errors) {
    return [];
  }
  return _.reduce(
    validationResults.errors,
    (acc: OrderValidationError[], item) => {
      const { keyword, message, dataPath } = item;

      // const rule = _.get(params || {}, 'rule', undefined);
      // const existing = _.get(acc, keyword, []) as OrderFieldValidationError[];
      if (!message) {
        return acc;
      }
      let messageVal = message;
      if (message === 'should be equal to constant') {
        messageVal = `${dataPath} error`;
      }

      const err: OrderValidationError = { field: keyword, message: messageVal, type: 'OrderFieldError' };
      return [...acc, err];
    },
    // eslint-disable-next-line @typescript-eslint/no-object-literal-type-assertion
    [] as OrderValidationError[]
  );
};

const tryValidateOrderWithStrategy = async (
  order: TradingblockOrder,
  complete: boolean,
  strategy: TradingStrategy,
  action: OrderAction
) => {
  const { buy, sell } = await tryValidateOrderForStrategy(schema, strategy, order);
  const directionValidationResults = action === OrderAction.Buy ? buy : sell;
  return {
    valid: complete ? directionValidationResults.isValid : undefined,
    match: strategy,
    errors: parseValidationErrors(directionValidationResults.results),
    data: { order, action },
  };

  // setMatchingStrategies(strategies.map(ms => ms.strategy));
};

// Function to validate a trading strategy against an order
const validateStrategy = async (
  strategy: TradingStrategy | CustomTradingStrategy, // The trading strategy to validate
  order: TradingblockOrder, // The order to validate against
  legs: OrderLegDTOState[], // The legs of the order
  complete: boolean, // Flag indicating if the validation is complete
  action: OrderAction, // The action of the order (e.g., buy or sell)
  positions: PositionInfo, // Information about the current positions
  orderQuantity: number // Quantity of the order
) => {
  // Attempt to validate the order with the strategy
  try {
    // If there are no order legs or validation is incomplete, consider it valid and continue
    if (legs.length === 0 || complete === false) {
      return {
        valid: true,
        match: strategy as TradingStrategy,
        errors: undefined,
      };
    }

    const uniqueLegs = new Set(
      legs.map(leg => {
        // Only return the relevant properties for comparison, action is not one...
        return JSON.stringify({
          legType: leg.OptionType,
          legStrike: leg.Strike,
          legExpiration: leg.Expiration ? leg.Expiration.date : undefined,
        });
      })
    );

    if (uniqueLegs.size !== legs.length) {
      throw new Error('Legs should be unique');
    }

    // Classify the strategy and check if it matches the current order's strategy. If not, throw an error to display to the user
    const classification = orderLegsToClassifiedStrategy(legs, strategy.info.Name !== 'Custom');
    if (classification.name !== strategy.info.Name && strategy.info.Name !== 'Custom') {
      throw new Error('Strategy does not match the current order');
    }

    const detectedStrategy = Strategies.find(s => s.info.Name === classification.name);
    return {
      valid: true,
      match: detectedStrategy as TradingStrategy,
      errors: undefined,
    };

    // Handle exceptions during strategy classification
  } catch (e) {
    if (e instanceof Error) {
      return {
        valid: false,
        match: undefined,
        errors: [
          {
            field: 'strategy',
            message: e.message,
            type: 'OrderFieldError',
          },
        ],
      };
    }

    // Return a generic error if the specific error type is not caught
    return {
      valid: false,
      match: {},
      errors: [
        {
          field: 'strategy',
          message: 'Strategy does not match the current order',
          type: 'OrderFieldError',
        },
      ],
    };
  }
};

const triggerValidation = async ({ blockId, data }: IBlockState<OrderState>, dispatch: (action: any) => void) => {
  //const { blockId, data }: IBlockState<OrderState> = store.getState() as any;
  const order = OrderSelectors.order(data, blockId);
  const legs = OrderSelectors.legs(data);
  const complete = OrderSelectors.isComplete(data);
  const action = OrderSelectors.action(data);
  const positions = OrderSelectors.positions(data);
  const quantity = OrderSelectors.quantity(data);
  const strategy = data.strategy;
  const orderQuantity = strategy && isCustomStrategy(strategy) ? order.Quantity : quantity;

  validateStrategy(getStrategy(strategy), order, legs, complete, action, positions, orderQuantity).then(res => {
    dispatch(
      ValidationActions.finishValidation({
        ...res,
        valid: _.isNil(res.valid) ? true : res.valid,
        errors: res.errors || [],
      })
    );
  });
};

export const StrategyValidationMiddleware = () => {
  return function<T, A extends Action>(store: { dispatch: (action: any) => void; getState: () => T }) {
    return (next: (action: A) => any) => (action: A) => {
      let result = next(action);
      const storeVal: IBlockState<OrderState> = store.getState() as any;
      switch (action.type) {
        case getType(BlockAction.updateData):
        case getType(Actions.addOrderLeg):
        case getType(Actions.removeLeg):
        case getType(Actions.setStrike):
        case getType(Actions.setExpiration):
        case getType(Actions.setLegQuantity):
        case getType(Actions.setLegAction):
        case getType(Actions.setOrderAction):
        case getType(Actions.setStrategy):
        case getType(Actions.setQuantity):
        case getType(BlockAction.initializeData):
        case getType(Actions.addOrderLegFromGroup):
        case getType(Actions.SetSymbolAndReset):
        case getType(Actions.setPositionInfo):
        case getType(Actions.setOptionType):
        case getType(Actions.setOrder): {
          triggerValidation(storeVal, store.dispatch);
          return result;
        }
        case getType(Actions.setSymbol):
        case getType(Actions.setPrice):
        case getType(Actions.asyncValidateOrder): {
          if (storeVal.data.validation.valid !== true) {
            triggerValidation(storeVal, store.dispatch);
          }
          return result;
        }
        default: {
          return result;
        }
      }
    };
  };
};
