import React, { useMemo } from 'react';
import { getClassNames } from '../../utilities';
export var DashboardContent = function DashboardContent(_ref) {
  var className = _ref.className,
      children = _ref.children,
      editClass = _ref.editClass,
      linkingClass = _ref.linkingClass,
      locked = _ref.locked,
      linking = _ref.linking;
  var cssName = useMemo(function () {
    return getClassNames(['dashboard-content', className, !locked ? editClass : undefined, linking && locked ? linkingClass : undefined]);
  }, [className, editClass, linkingClass, locked, linking]);
  return React.createElement("div", {
    id: "dashboard-content",
    className: cssName
  }, children);
};