import { InitialState } from '../../initialState';
import { getType } from 'typesafe-actions';
import _ from 'lodash';
import {
  PrivateApplicationsState,
  ApplicationModel,
  ApplicationModelWithKey,
  ApplicationsSearchUpdatesState,
} from '@tradingblock/types';
import { AdminApplicationActions, AdminApplicationAction } from '../../actions/admin/AdminApplicationActions';
import {
  applicationRequestKey,
  applicationRequestToQueryString,
  getEntityKey,
} from '../../utilities/applicationUtilities';

const defaultQueryState = (queryString: string) => ({
  queryString,
  ids: [],
  isFetching: false,
  error: undefined,
});

type Actions = AdminApplicationAction;
export const privateApplicationsReducer = (
  state = InitialState.private.applications,
  action: Actions
): PrivateApplicationsState => {
  switch (action.type) {
    case getType(AdminApplicationActions.requestApplications): {
      const requestKey = applicationRequestKey(action.payload);
      const existingQueryState =
        state.byQuery[requestKey] || defaultQueryState(applicationRequestToQueryString(action.payload));
      return {
        ...state,
        isFetching: true,
        byQuery: {
          ...state.byQuery,
          [requestKey]: {
            ...existingQueryState,
            isFetching: true,
            error: undefined,
          },
        },
      };
    }
    case getType(AdminApplicationActions.receiveApplications): {
      const { request, response } = action.payload;
      const querykey = applicationRequestKey(request);
      const existingQueryState = state.byQuery[querykey] || defaultQueryState(applicationRequestToQueryString(request));
      const { applications, total } = response;
      const incomingData = _.map(applications, (application: ApplicationModel) => ({
        ...application,
        entityKey: getEntityKey(application),
      }));
      const formattedApps = _.reduce(
        incomingData,
        (acc: ApplicationsSearchUpdatesState, application: ApplicationModelWithKey) => {
          return {
            ...acc,
            [application.entityKey]: application,
          };
        },
        state.applications
      );

      const ids = incomingData.map(v => v.entityKey);

      return {
        ...state,
        isFetching: false,
        applications: formattedApps,
        byQuery: {
          ...state.byQuery,
          [querykey]: {
            ...existingQueryState,
            ids,
            isFetching: false,
            total,
          },
        },
      };
    }
    case getType(AdminApplicationActions.setAppPage):
    case getType(AdminApplicationActions.setAppPageSize):
    case getType(AdminApplicationActions.setAppSortBy):
    case getType(AdminApplicationActions.setAppSearchStep):
    case getType(AdminApplicationActions.setAppSearch):
    case getType(AdminApplicationActions.setAppSearchType): {
      return {
        ...state,
        ui: {
          ...state.ui,
          ...action.payload,
        },
      };
    }
    case getType(AdminApplicationActions.setAppCurrentItem): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          application: action.payload,
        },
        ui: {
          ...state.ui,
        },
      };
    }
    case getType(AdminApplicationActions.clearAppCurrentItem): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          application: {},
          investigation: {
            isFetching: false,
            response: {},
          },
        },
        ui: {
          ...state.ui,
        },
      };
    }
    case getType(AdminApplicationActions.requestInvestigations): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          investigation: {
            ...state.currentItem.investigation,
            isFetching: true,
          },
        },
      };
    }
    case getType(AdminApplicationActions.receiveInvestigations): {
      const { response } = action.payload;
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          investigation: {
            ...state.currentItem.investigation,
            isFetching: false,
            response: response,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};
