import { ApplicationStep, ApplicationType } from '@tradingblock/types';
import { BasicSelect, OptionType, useInput } from '@tradingblock/components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useApplicationsActions, useApplicationsData } from '../useApplications';
import _ from 'lodash';

export const stepOptions: OptionType<ApplicationStep | undefined>[] = [
  { value: 'All', label: 'Step: All', data: undefined },
  { value: 'Secure Account', label: 'Secure Account', data: ApplicationStep.SecureAccount },
  { value: 'Clearer Type', label: 'Clearer Type', data: ApplicationStep.ClearerType },
  { value: 'Account Type', label: 'Account Type', data: ApplicationStep.AccountType },
  { value: 'Investing Profile', label: 'Investing Profile', data: ApplicationStep.InvestingProfile },
  { value: 'Account Information', label: 'Account Information', data: ApplicationStep.AccountInformation },
  { value: 'Beneficiaries', label: 'Beneficiaries', data: ApplicationStep.Beneficiaries },
  { value: 'Agreements', label: 'Agreements', data: ApplicationStep.Agreements },
  { value: 'Sign', label: 'Sign', data: ApplicationStep.Sign },
];

export const typeOptions: OptionType<ApplicationType | undefined>[] = [
  { value: 'All', label: 'Type: All', data: undefined },
  { value: 'Individual', label: 'Individual', data: ApplicationType.Individual },
  { value: 'Joint', label: 'Joint', data: ApplicationType.Joint },
  { value: 'Entity', label: 'Entity', data: ApplicationType.Entity },
  { value: 'UGMA', label: 'UGMA', data: ApplicationType.UGMA },
];

export const ApplicationsFilters = () => {
  const { search, steps, types } = useApplicationsData();
  const { setSearch, setStep, setType } = useApplicationsActions();

  const onSearchSubmit = useCallback((search: string) => {
    setSearch(search);
  }, []);

  const [searchValue, setSearchValue, inputProps] = useInput(undefined, { onEnter: onSearchSubmit });

  const onClearSearch = useCallback(() => {
    onSearchSubmit('');
    setSearchValue('');
  }, []);

  const currentSearch = useMemo(() => {
    if (!_.isUndefined(searchValue)) {
      return searchValue;
    }
    return search || '';
  }, [search, searchValue]);

  const currentStep = useMemo(() => {
    const firstStep = _.first(steps);
    if (firstStep) {
      return firstStep;
    }

    const defaultStep = _.first(stepOptions);
    return defaultStep ? defaultStep.label : '';
  }, [steps]);

  const currentType = useMemo(() => {
    const firstType = _.first(types);
    if (firstType) {
      return firstType;
    }

    const defaultType = _.first(typeOptions);
    return defaultType ? defaultType.label : '';
  }, [types]);

  const onStepChange = useCallback((val: OptionType | OptionType[] | null | undefined, { action }) => {
    const steps = _.isNil(val) ? [] : _.isArray(val) ? val : [val];
    setStep(steps.filter(s => s.data !== undefined).map(s => s.data));
  }, []);

  const onTypeChange = useCallback((val: OptionType | OptionType[] | null | undefined, { action }) => {
    const types = _.isNil(val) ? [] : _.isArray(val) ? val : [val];
    setType(types.filter(t => t.data !== undefined).map(t => t.data));
  }, []);

  return (
    <div className="table-toolbar table-toolbar-top">
      <div className="table-toolbar-primary">
        <input
          className="input input-dark input-tall"
          type="text"
          placeholder={'Search...'}
          value={currentSearch}
          {...inputProps}
        />
        {currentSearch && (
          <i
            className="fa fa-times fa-2x"
            onClick={onClearSearch}
            style={{ color: 'var(--blue)', cursor: 'pointer', paddingLeft: '0.32em' }}
          />
        )}
      </div>
      <div className="table-toolbar-secondary">
        <BasicSelect
          onChange={onTypeChange}
          placeholder={
            currentType[0].toUpperCase() +
            currentType
              .slice(1)
              .replace(/([A-Z])/g, ' $1')
              .trim()
          }
          className="btn btn-dark btn-tall dropdown-toggle text-left"
          options={typeOptions}
          isClearable={false}
        />
        <BasicSelect
          onChange={onStepChange}
          placeholder={
            currentStep[0].toUpperCase() +
            currentStep
              .slice(1)
              .replace(/([A-Z])/g, ' $1')
              .trim()
          }
          className="btn btn-dark btn-tall dropdown-toggle text-left"
          options={stepOptions}
          isClearable={false}
        />
      </div>
    </div>
  );
};
