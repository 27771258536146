import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Cell, Column, UseSortByColumnOptions } from 'react-table';

import { AccountInfo, AccountType, Balances, ClientEntity } from '@tradingblock/types';
import { account } from '../../data/global/selectors/accountSelectors';
import AccountSelectionButton from './AccountSelectionButton';
import { formatNumber } from '@tradingblock/components';
import { AccountActions } from '../../data/global/actions/AccountActions';
import _, { delay } from 'lodash';
import { BlockTable } from '../../blocks/shared/BlockTable';
import { DataState } from '../../data/global/state';
import { LinkedAccountsActions } from '../../data/global/actions/LinkedAccountsActions';
import { useStateSelector } from '../../data/global/dataSelectors';
import { BasicSelect } from '@tradingblock/components';
import { useWhyDidYouUpdate } from 'use-hooks';
import { useFeedActions } from '../../data/feed/useFeedActions';

/**
 * The account table for multiple accounts
 * @returns {JSX.Element}
 */
export default function AccountsTable() {
  const dispatch = useDispatch();
  const accounts = useSelector(account.multipleAccounts);
  const isSelectedLinkedAccountLoaded = useSelector(account.isSelectedLinkedAccountLoaded);
  const totalAccounts = useSelector(account.totalLinkedAccounts);
  const linkedAccountBalances = useSelector(account.linkedAccountsBalances);
  const selectedAccountId = useSelector(account.accountId);
  const [persistSearchOptions, setPersistSearchOptions] = useState(true);
  const searchOptions = useStateSelector(
    state => state.account.selectedLinkedAccount && state.account.selectedLinkedAccount.searchOptions
  );
  const [search, setSearch] = useState(searchOptions ? searchOptions.search : '');
  const [page, setPage] = useState(searchOptions ? searchOptions.page : 0);
  const [pageSize, setPageSize] = useState(searchOptions ? searchOptions.pageSize : 5);
  const [sortBy, setSortBy] = useState(searchOptions ? searchOptions.sortBy : '');
  const [sortByOrder, setSortByOrder] = useState<'asc' | 'desc'>(searchOptions ? searchOptions.sortByOrder : 'asc');
  const [statusFilter, setStatusFilter] = useState(searchOptions ? searchOptions.status : '');
  const selectedLinkedAccountId = useStateSelector(
    state => state.account.selectedLinkedAccount && state.account.selectedLinkedAccount.accountId
  );
  const originalAccountId = useStateSelector(
    state => state.account.selectedLinkedAccount && state.account.selectedLinkedAccount.originalAccount.accountId
  );

  // If any of our table properties change, then we need to call the backend to update linked accounts redux state.
  useEffect(() => {
    // Add a slight delay between option updates, as this will prevent
    // rapid backend calls if the user is changing a ton of options really
    // fast. (Like really fast paging, sorting, etc.)
    let optionsUpdateTimerId = delay(() => {
      if (!persistSearchOptions) {
        dispatch(
          LinkedAccountsActions.requestLinkedAccounts({
            page: page,
            pageSize: pageSize,
            sortBy: sortBy,
            sortByOrder: sortByOrder,
            search: search,
            status: statusFilter,
          })
        );
      }
    }, 250);

    return function cleanUp() {
      // Clear delay timer if it's still running.
      clearTimeout(optionsUpdateTimerId);
    };
  }, [page, pageSize, sortBy, sortByOrder, statusFilter]);

  const onSearchValueChanged = () => {
    dispatch(
      LinkedAccountsActions.requestLinkedAccounts({
        page: page,
        pageSize: pageSize,
        sortBy: sortBy,
        sortByOrder: sortByOrder,
        search: search.trim(),
        status: statusFilter,
      })
    );
  };

  useEffect(() => {
    setPage(0);
    const timer = setTimeout(() => {
      onSearchValueChanged();
    }, 300);

    return () => clearTimeout(timer);
  }, [search]);

  useEffect(() => {
    if (selectedAccountId !== undefined) {
      dispatch(AccountActions.requestSubAccounts({ accountId: selectedAccountId }));
    }
  }, [selectedAccountId]);

  useEffect(() => {
    if (searchOptions && persistSearchOptions) {
      setPage(searchOptions.page);
      setPageSize(searchOptions.pageSize);
      setSortBy(searchOptions.sortBy);
      setSortByOrder(searchOptions.sortByOrder);
      setSearch(searchOptions.search);
      setStatusFilter(searchOptions.status);
    }
    setPersistSearchOptions(false);
  }, [searchOptions, persistSearchOptions]);

  const accountSelectedCallback = (accountId: number, resetSearch?: boolean) => {
    if (resetSearch) {
      dispatch(
        AccountActions.retrieveSetAccountData({
          accountId,
          searchOptions: {
            page: 0,
            pageSize: pageSize,
            sortBy: '',
            sortByOrder: sortByOrder,
            search: '',
            status: '',
          },
        })
      );
    } else {
      dispatch(
        AccountActions.retrieveSetAccountData({
          accountId,
          searchOptions: {
            page: page,
            pageSize: pageSize,
            sortBy: sortBy,
            sortByOrder: sortByOrder,
            search: search,
            status: statusFilter,
          },
        })
      );
    }
  };

  return selectedAccountId ? (
    <AccountsTableRender
      balances={linkedAccountBalances}
      accounts={accounts}
      selectedAccountId={selectedAccountId}
      accountSelectedCallback={accountSelectedCallback}
      pageSize={pageSize}
      setPageSize={setPageSize}
      sortBy={sortBy}
      setSortBy={setSortBy}
      page={page}
      setPage={setPage}
      setSortByOrder={setSortByOrder}
      sortByOrder={sortByOrder}
      search={search}
      setSearch={setSearch}
      setStatusFilter={setStatusFilter}
      statusFilter={statusFilter}
      total={totalAccounts}
      selectedLinkedAccountId={selectedLinkedAccountId}
      originalAccountId={originalAccountId}
      setPersistSearchOptions={setPersistSearchOptions}
      searchOptionsPage={searchOptions && searchOptions.page}
      searchOptionsSearchTerm={searchOptions && searchOptions.search}
      isSelectedLinkedAccountLoaded={isSelectedLinkedAccountLoaded}
    />
  ) : (
    <></>
  );
}

/**
 * @property {AccountInfo[]} accounts List of account infos to allow switching
 * @property {number} selectedAccountId Currently selected account
 * @property { (accountId: number) => void} accountSelectedCallback Callback for when an account is selected
 */
export interface AccountsTableRenderProps {
  accounts: DataState['linkedAccounts']['accounts'];
  balances: { [accountId: number]: Balances | undefined };
  selectedAccountId?: number;
  accountSelectedCallback: (accountId: number, resetSearch?: boolean) => void;
  search: string;
  setSearch: (search: string) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  sortBy: string;
  setSortBy: (sortBy: string) => void;
  sortByOrder: 'asc' | 'desc';
  setSortByOrder: (sortByOrder: 'asc' | 'desc') => void;
  page: number;
  setPage: (page: number) => void;
  setStatusFilter: (statusFilter: string) => void;
  statusFilter: string;
  total: number;
  selectedLinkedAccountId?: number;
  originalAccountId?: number;
  setPersistSearchOptions: (persistSearchOptions: boolean) => void;
  searchOptionsPage?: number;
  searchOptionsSearchTerm?: string;
  isSelectedLinkedAccountLoaded?: boolean;
}
/**
 * Renders the account table for the frontend
 * @param {AccountsTableRenderProps} props Props needed to render list of accounts
 * @returns {JSX.Element} renders a table containing switchable accounts
 */
export function AccountsTableRender({
  accounts,
  balances,
  selectedAccountId,
  accountSelectedCallback,
  search,
  setSearch,
  pageSize,
  setPageSize,
  sortByOrder,
  setSortByOrder,
  sortBy,
  setSortBy,
  page,
  setPage,
  total,
  statusFilter,
  setStatusFilter,
  originalAccountId,
  setPersistSearchOptions,
  searchOptionsPage,
  searchOptionsSearchTerm,
  isSelectedLinkedAccountLoaded,
}: AccountsTableRenderProps) {
  const formatAccountType = (accountType: AccountType | string | undefined) => {
    switch (accountType) {
      case AccountType.Individual: {
        return 'Individual';
      }
      case AccountType.JTWROS: {
        return 'JTWROS';
      }
      case AccountType.Traditional_IRA: {
        return 'Traditional IRA';
      }
      case AccountType.Trust: {
        return 'Trust';
      }
      case AccountType.IRA_RollOver: {
        return 'IRA Rollover';
      }
      case AccountType.Roth_IRA: {
        return 'Roth IRA';
      }
      case AccountType.SEP_IRA: {
        return 'SEP IRA';
      }
      case AccountType.JTIC: {
        return 'JTIC';
      }
      case AccountType.Corporate: {
        return 'Corporate';
      }
      case AccountType.LLC: {
        return 'LLC';
      }
      case AccountType.Partnership: {
        return 'Partnership';
      }
      case AccountType.Qualified_Plan: {
        return 'Qualified Plan';
      }
      case AccountType.Sole_Proprietorship: {
        return 'Sole Proprietorship';
      }
      case AccountType.InvestmentClub: {
        return 'Investment Club';
      }
      case AccountType.TBE: {
        return 'TBE';
      }
      case AccountType.CommunityProperty: {
        return 'Community Property';
      }
      case AccountType.Coverdell_IRA: {
        return 'Coverdell IRA';
      }
      case AccountType.UGMA: {
        return 'UGMA';
      }
      case 'Individual': {
        return 'Individual';
      }
      default: {
        return 'Unknown';
      }
    }
  };

  const statuses = [
    {
      id: 1,
      label: 'Prospect',
      data: 'Prospect',
    },
    {
      id: 2,
      label: 'Prospect Application Started',
      data: 'ProspectApplicationStarted',
    },
    {
      id: 3,
      label: 'Prospect Application Completed',
      data: 'ProspectApplicationCompleted',
    },
    {
      id: 4,
      label: 'Prospect Application Expired',
      data: 'ProspectApplicationExpired',
    },
    {
      id: 5,
      label: 'Processing',
      data: 'Processing',
    },
    {
      id: 6,
      label: 'Processing Items Missing',
      data: 'ProcessingItemsMissing',
    },
    {
      id: 7,
      label: 'Active Not Funded',
      data: 'ActiveNotFunded',
    },
    {
      id: 8,
      label: 'Active',
      data: 'Active',
    },
    {
      id: 14,
      label: 'Active Closing Trades Only',
      data: 'ActiveClosingTradesOnly',
    },
    {
      id: 9,
      label: 'Closed',
      data: 'Closed',
    },
    {
      id: 10,
      label: 'Closed Never Funded',
      data: 'ClosedNeverFunded',
    },
    {
      id: 11,
      label: 'Rejected',
      data: 'Rejected',
    },
    {
      id: 12,
      label: 'Deceased',
      data: 'Deceased',
    },
  ];

  const onSort = useCallback(
    (sort: any[]) => {
      const sortVal = _.first(sort) || 'asc';
      if (sortVal) {
        const { id, desc } = sortVal;
        setSortBy(id);
        setSortByOrder(desc ? 'desc' : 'asc');
      }
    },
    [setSortBy, sortBy]
  );

  const getCurrentSortBy = useCallback(
    (newDesc?: boolean) => {
      if (sortBy) {
        return { id: sortBy, desc: !_.isUndefined(newDesc) ? newDesc : sortByOrder === 'desc' };
      }
      return undefined;
    },
    [sortBy, sortByOrder]
  );

  const initialState = useMemo(() => {
    const currentSortBy = getCurrentSortBy();
    return {
      sortBy: currentSortBy ? [currentSortBy] : [{ id: 'Account', desc: true }],
      pageSize: _.isUndefined(pageSize) ? 25 : pageSize,
      pageIndex: page,
      columnOrder: [],
    };
  }, [pageSize, page, getCurrentSortBy]);

  const pageCount = useMemo(() => {
    if (pageSize) {
      return Math.ceil(total / pageSize);
    }
    return 0;
  }, [pageSize, total]);

  const apiToken = useStateSelector(state => state.auth.apiToken);

  const { unsubscribeOrderUpdates } = useFeedActions(apiToken || '');

  const isSelectedEqualToOriginal = selectedAccountId === originalAccountId;

  const getAccountStatus = useCallback((status: number) => {
    // format status to place a space between words
    const foundStatus = statuses.find(s => s.id === status);
    if (foundStatus)
      switch (foundStatus.data) {
        case 'Active':
          return <span style={{ color: 'var(--green)', fontWeight: 500 }}>{foundStatus.label}</span>;
        case 'Inactive':
          return <span style={{ color: 'var(--salmon)', fontWeight: 500 }}>{foundStatus.label}</span>;
        case 'Closed':
          return <span style={{ color: 'var(--salmon)', fontWeight: 500 }}>{foundStatus.label}</span>;
        case 'ActiveClosingTradesOnly':
          return <span style={{ color: 'var(--gold)', fontWeight: 500 }}>{foundStatus.label}</span>;
        default:
          return (
            <span className="warn" style={{ fontWeight: 500 }}>
              {foundStatus.label}
            </span>
          );
      }
  }, []);

  const accountsTableColumns: (Column<ClientEntity> & UseSortByColumnOptions<ClientEntity>)[] = [
    {
      Header: 'Account',
      accessor: a => a.accountNumber,
      id: 'Account',
      Cell: ({ row }: Cell<ClientEntity>) => {
        return (
          <AccountSelectionButton
            text={`#${row.original.accountNumber}`}
            selected={false}
            type="link"
            onSelectedCallback={() => {
              selectedAccountId && unsubscribeOrderUpdates(selectedAccountId);
              accountSelectedCallback(row.original.accountId);
              setPersistSearchOptions(true);
            }}
            disabled={!isSelectedLinkedAccountLoaded}
          />
        );
      },
    },
    {
      Header: 'Account Status',
      accessor: a => {
        return getAccountStatus(a.accountStatus);
      },
      id: 'accountStatus',
    },
    {
      Header: 'Account Title',
      accessor: a => a.accountTitle,
      id: 'accountTitle',
    },
    {
      Header: 'Account Type',
      accessor: a => (typeof a.accountType === 'string' ? a.accountType : formatAccountType(a.accountType)),
      id: 'accountType',
    },
    {
      Header: 'Username',
      accessor: a => a.userName,
      id: 'userName',
    },
    {
      Header: 'Email',
      accessor: a => a.email,
      id: 'email',
    },
    {
      Header: 'Available for trading',
      disableSortBy: true,
      accessor: a => {
        const balance = balances[a.accountId];
        if (balance) {
          return formatNumber(balance.BuyingPower.AvailableFunds, { currency: true }) + '*';
        }
        return <i className={'fas fa-cog fa-spin'} />;
      },
      id: 'AvailableForTrading',
    },
    {
      Header: 'Rep Code',
      disableSortBy: true,
      accessor: a => {
        return a.repCode;
      },
      id: 'RepCode',
      width: '120px',
    },
    {
      Header: 'Action',
      id: 'action',
      disableSortBy: true,
      Cell: ({ row }: Cell<ClientEntity>) => {
        return (
          <AccountSelectionButton
            text={'Select'}
            selectedText={'Selected'}
            selected={row.original.accountId === selectedAccountId}
            onSelectedCallback={() => {
              accountSelectedCallback(row.original.accountId);
              setPersistSearchOptions(true);
            }}
            disabled={!isSelectedLinkedAccountLoaded}
          />
        );
      },
      width: '100px',
    },
  ];
  const getRowKey = useCallback((data: AccountInfo) => `${data.AccountId}`, []);

  const selectedFilter = statuses.find(status => status.data === statusFilter);

  return (
    <div className="container-fluid px-0" id="accountTable">
      <div className="row">
        <div className="col-md-5" id="selectAccount">
          <p style={{ margin: 0 }}>Select account:</p>
        </div>
        <div className="col-md-7" id="accountSearch" style={{ justifyContent: 'flex-start ' }}>
          <div className="table-toolbar table-toolbar-top" style={{ margin: 0, flexDirection: 'row-reverse' }}>
            <button
              onClick={e => {
                e.preventDefault();
                if (originalAccountId) {
                  selectedAccountId && unsubscribeOrderUpdates(selectedAccountId);
                  accountSelectedCallback(originalAccountId, true);
                  setSearch('');
                  setStatusFilter('');
                }
              }}
              className="btn btn-outline-light"
              title="Return to original login account"
              disabled={!isSelectedLinkedAccountLoaded || isSelectedEqualToOriginal}
            >
              <i className="fas fa-undo" />
            </button>
            <div className="table-toolbar-primary" style={{ backgroundColor: '#001236', borderRadius: 4 }}>
              <input
                className="input input-dark input-tall ml-2"
                type="text"
                placeholder="Search..."
                value={search}
                onChange={event => {
                  setSearch(event.target.value);
                }}
                style={{ backgroundColor: '#001236' }}
              />
              <i
                className="fa fa-times fa-2x"
                onClick={() => {
                  setSearch('');
                }}
                style={{
                  color: 'var(--blue)',
                  cursor: 'pointer',
                  fontSize: '1em',
                  visibility: search.length > 0 ? 'visible' : 'hidden',
                  paddingRight: '8px',
                }}
              />
            </div>
          </div>
          <div
            className="table-toolbar-secondary"
            style={{ margin: '1px', backgroundColor: '#001236', borderRadius: '4px' }}
          >
            <div className="table-toolbar-primary">
              <BasicSelect
                onChange={(v: any) => {
                  setStatusFilter(v.data);
                }}
                placeholder={selectedFilter ? selectedFilter.label : 'Filter'}
                className="btn btn-tall dropdown-toggle text-left"
                options={statuses}
                isClearable={false}
                value={statusFilter}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <BlockTable
            tableKey="accountsTable"
            columns={accountsTableColumns}
            numColumns={9}
            columnWidths={['7%', '10%', '18%', '10%', '12%', '10%', '10%', '10%', '13%']}
            initialState={initialState}
            data={accounts ? accounts : []}
            loaded={true}
            sortable={true}
            hasPagination
            onSort={onSort}
            getRowKey={getRowKey}
            pageCount={pageCount}
            manualPagination
            onPage={setPage}
            onPageSize={setPageSize}
            searchTerm={search}
            searchOptionsPage={searchOptionsPage}
            searchOptionsSearchTerm={searchOptionsSearchTerm}
          />
        </div>
      </div>
    </div>
  );
}
