import { BalanceHistoryItem, PageResponseOfBalanceHistory } from '@tradingblock/types';
import { actionBuilder } from '../actionUtilities';
import { GetBalancesRequestParams } from '@tradingblock/api/src/commands/historyGetBalance';

// Deprecated code and actions, legacy
const REQUEST_GET_BALANCE_HISTORY = '@data/History/GetBalanceHistory/REQUEST';
const RECEIVE_GET_BALANCE_HISTORY = '@data/History/GetBalanceHistory/RECEIVE';
const ERROR_GET_BALANCE_HISTORY = '@data/History/GetBalanceHistory/ERROR';
const [requestGetBalanceHistory, receiveGetBalanceHistory, errorGetBalanceHistory] = actionBuilder<
  { accountId?: number },
  BalanceHistoryItem[]
>()(REQUEST_GET_BALANCE_HISTORY, RECEIVE_GET_BALANCE_HISTORY, ERROR_GET_BALANCE_HISTORY);

const REQUEST_HISTORY_GET_BALANCES = '@data/History/GetBalances/REQUEST';
const RECEIVE_HISTORY_GET_BALANCES = '@data/History/GetBalances/RECEIVE';
const ERROR_HISTORY_GET_BALANCES = '@data/History/GetBalances/ERROR';

const [requestHistoryGetBalances, receiveHistoryGetBalances, errorHistoryGetBalances] = actionBuilder<
  GetBalancesRequestParams,
  PageResponseOfBalanceHistory
>()(REQUEST_HISTORY_GET_BALANCES, RECEIVE_HISTORY_GET_BALANCES, ERROR_HISTORY_GET_BALANCES);
export const HistoryActions = {
  requestGetBalanceHistory,
  receiveGetBalanceHistory,
  errorGetBalanceHistory,
  requestHistoryGetBalances,
  receiveHistoryGetBalances,
  errorHistoryGetBalances,
};
