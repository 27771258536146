import { createStandardAction, ActionType } from 'typesafe-actions';
import { IBlockState, TabOptions } from '@tradingblock/types';
import { Savable } from '../../../utilities/action';

export const BLOCK_IS_LOADING = 'blockIsLoading';
export const BLOCK_INITIALIZE_DATA = 'initializeData';

export const BlockAction = {
  setSettings: createStandardAction('setSettings')<any>(),
  blockIsLoading: createStandardAction(BLOCK_IS_LOADING)<{}>(),
  setData: createStandardAction('setData')<Savable<{ field: string; value: any }>>(),
  initialize: createStandardAction('initialize')<Partial<IBlockState>>(),
  setTitle: createStandardAction('setTitle')<string>(),
  setGroup: createStandardAction('setGroup')<string>(),
  setTab: createStandardAction('setTab')<TabOptions>(),
  initializeData: createStandardAction(BLOCK_INITIALIZE_DATA)<any>(),
  updateData: createStandardAction('updateData')<Savable<{ data: any }>>(),
};
export type BlockActions = ActionType<typeof BlockAction>;
export type GenericBlockActionTypes<T extends any> = BlockActions | ActionType<T>;
