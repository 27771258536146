import _ from 'lodash';
import { UserProfileLevel, AdminLink, UserRole } from '@tradingblock/types';

export const isAdminUserLevel = (userLevel: UserProfileLevel | undefined) => {
  return userLevel === UserProfileLevel.Admin;
};

export const isRepUserLevel = (userLevel: UserProfileLevel | undefined) => {
  // these are all various levels of reps
  return _.includes([UserProfileLevel.Rep, UserProfileLevel.Branch, UserProfileLevel.Region, UserProfileLevel.Firm], userLevel);
};

export const canAccessAdminLink = (adminLink: AdminLink, userLevel: UserProfileLevel | undefined, userRoles: UserRole[] | undefined) => {
  // only admins/reps can access admin functions
  if (isAdminUserLevel(userLevel) || isRepUserLevel(userLevel)) {
    // user can access if no roles defined for the link, or user has an allowed role for the link
    return !adminLink.roles || _.some(adminLink.roles, r => _.includes(userRoles, r));
  }
  return false;
};
