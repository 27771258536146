import { QuotesState } from '../../state';
import { Order, ProfitLossInfoMap } from '@tradingblock/types';
import { PositionDTO, pnlByPrice, getBestPrice, orderLegTradingPnL, isOptionSymbol } from '@tradingblock/api';
import _ from 'lodash';
import { utilities } from './utilities';

export const SymbolPnLReducer = (
  quoteState: QuotesState,
  orders: Order[],
  allPositions: PositionDTO[]
): ProfitLossInfoMap => {
  const quo = utilities.getQuoteForSymbol(quoteState);
  const orderLegs = utilities.ordersToExecutedOrderLegs(orders);
  const symbolsWithPositions = allPositions.map(p => p.Symbol);
  const symbolsWithOrders = _.map(orderLegs, o => o.Symbol);

  const allSymbols = _(symbolsWithPositions)
    .union(symbolsWithOrders)
    .uniq()
    .value();
  const symbolPnls: ProfitLossInfoMap = _(allSymbols).reduce((acc: ProfitLossInfoMap, symbol: string) => {
    const positions = allPositions.filter(p => p.Symbol === symbol);
    const legs = _.filter(orderLegs, o => o.Symbol === symbol);
    const underlyingSymbol =
      positions.length > 0 ? positions[0].UnderlyingSymbol : legs.length > 0 ? legs[0].UnderlyingSymbol : symbol;
    const netQuantityChange = utilities.getNetQuantityChange(legs);
    const positionQuantityNow = _.sum(positions.map(p => p.OpenQuantity)) || 0;
    const positionOnOpen = positionQuantityNow - netQuantityChange;
    const quote = quo(symbol);
    const optionMultiplier = quote && quote.Multiplier ? quote.Multiplier : 1;
    const closePrice =
      quote && quote.IsSettlementSet ? quote.ClosePriceTMinus2 : quote && quote.ClosePrice ? quote.ClosePrice : quote && quote.LastOrClosePrice ? quote.LastOrClosePrice : 0;
    if (_.isNil(quote)) {
      // console.warn(symbol, 'quote not loaded. Defaulting symbol pnl');
      return acc;
    }
    if (positions.length === 0 && legs.length === 0) {
      return acc;
    }

    const quoteInfo = {
      AskPrice: quote.AskPrice,
      BidPrice: quote.BidPrice,
      LastOrClosePrice: quote.LastOrClosePrice,
      Multiplier: quote.Multiplier,
      isSettlementSet: quote.IsSettlementSet,
    };
    let positionPnl = pnlByPrice(
      {
        ...quoteInfo,
        PositionOnOpen: positionOnOpen,
        Symbol: symbol,
      },
      closePrice
    );

    const tradingPnlInfo = orderLegTradingPnL(quote, legs);
    const trading = tradingPnlInfo.pnl;

    const total = trading + positionPnl;
    if (_.isNaN(positionPnl)) {
      // console.warn(symbol, 'invalid symbol pnl', positionPnl, {
      //   legs,
      //   positions,
      //   calculated: {
      //     trading: trading,
      //     tradingFromPositions: positionTradingPnL,
      //   },
      //   quote: {
      //     AskPrice: quote.AskPrice,
      //     BidPrice: quote.BidPrice,
      //     LastOrClosePrice: quote.LastOrClosePrice,
      //   },
      //   PositionOnOpen: positionOnOpen,
      //   closePrice,
      // });
    }
    return {
      ...acc,
      [symbol]: { trading, position: positionPnl, total, symbol: symbol, underlyingSymbol },
    };
  }, {});
  // const orderLegsForSymbolsWithoutPositions = orderLegs.filter(o => !symbolsWithPositions.includes(o.Symbol));
  // const orderPnls = tradingPnlReducer(quoteState, orderLegs, positions);

  // const positionsBySymbol = _.reduce(
  //   { ...positionPnls, ...orderPnls },
  //   (acc: ProfitLossInfoMap, v: BasicSymbolProfitLossInfo): ProfitLossInfoMap => {
  //     const existing = acc[v.symbol] || { trading: 0, position: 0, total: 0, symbol: v.symbol, underlyingSymbol: v.underlyingSymbol };
  //     // const trading = existing.trading + v.trading;
  //     const trading = orderPnls[v.symbol] ? orderPnls[v.symbol].trading : 0;
  //     const position = existing.position + v.position;
  //     return {
  //       ...acc,
  //       [v.symbol]: {
  //         ...existing,
  //         trading,
  //         position,
  //         total: trading + position,
  //       },
  //     };
  //   },
  //   {}
  // );
  return symbolPnls;
};
