import React from 'react';
import { useOrderData, OrderState } from '../state/OrderState';
import { useOrderActions } from '../state/useOrderActions';
import PriceComponent from './PriceComponent';

export const StopOrderPrice: React.FC<{}> = () => {
  const { setStopPrice } = useOrderActions();
  const price = useOrderData((s: OrderState) => s.stopPrice ? s.stopPrice : undefined);
  const onChange = (value: number | null) => {
    setStopPrice(value === null ? undefined : value < 0 ? 0 : Math.abs(value));
  };
  
  return <PriceComponent
    value={price === undefined ? null : price}
    onChange={onChange}
    placeholder='Stop Price...'
  />;
};
