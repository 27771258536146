import React from 'react';
import _ from 'lodash';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { Modal } from '@tradingblock/components';
import { ToggleVirtualTradingButton } from '../dashboard/navigation/ToggleVirtualTradingButton';

export interface VirtualTradingModalProps {
  show: boolean;
  onClose: () => void;
}

export const VirtualTradingModal: React.FC<VirtualTradingModalProps> = ({ show, onClose }) => {
  if (!show) {
    return null;
  }

  return (
    <Modal setShow={() => onClose} show>
      <BootstrapModal.Header onHide={onClose} closeButton>
        <BootstrapModal.Title>Virtual trading</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <p>
          <strong className="vr">You are in virtual trading</strong>
        </p>
        <p>You are trading with paper money and your orders are not placed with a real exchange.</p>
        <p>Need to switch to live trading?</p>
      </BootstrapModal.Body>
      <BootstrapModal.Footer className="modal-footer-justified">
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <ToggleVirtualTradingButton className="btn btn-primary" onClick={() => onClose()}>
          Log in to live trading here
        </ToggleVirtualTradingButton>
      </BootstrapModal.Footer>
    </Modal>
  );
};
