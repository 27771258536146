import { Option, OptionPair, Expiration } from '@tradingblock/types';
import _ from 'lodash';
import { ExpirationOptionPair } from '../../types';

interface TableRowOptionData {
  Call: Option;
  Put: Option;
  display: {
    tdClass?: (cell: any) => string;
    renderRow?: (row: any, i: number) => any;
  };
}
export type TableOptionPairData = Omit<OptionPair, 'Call' | 'Put'> &
  TableRowOptionData & { expiration: Expiration; isClosestToPrice?: boolean };
export type TableDateHeaderData = ExpirationOptionPair &
  Pick<TableRowOptionData, 'display'> & { isClosestToPrice?: boolean };
export type TableData = (TableOptionPairData | TableDateHeaderData)[];
export interface OptionChainDataType {
  Bid: number;
  Ask: number;
}

export type OptionChainField = keyof OptionChainDataType;

export function isOptionChainField(val: string | OptionChainField): val is OptionChainField {
  const fields: OptionChainField[] = ['Bid', 'Ask'];
  return fields.map(f => f.toString()).includes(val);
}
export function isTableOptionPairData(val: any | TableOptionPairData): val is TableOptionPairData {
  return _.get(val, 'Call', undefined) !== undefined && val.Put !== undefined;
}

export function isOptionChainVolumeField(val: string | OptionChainField): val is OptionChainField {
  const fields: string[] = ['Volume'];
  return fields.map(f => f.toString()).includes(val);
}
