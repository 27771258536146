import React from 'react';
import { BlockBody } from '@tradingblock/components';
import { BlockType } from '@tradingblock/types';
import { getBlockProps } from '../../utilities/block';
import { AdminToolsContent } from './AdminToolsContent';
import { AdminToolsHeader } from './AdminToolsHeader';
import { Block } from '../../components/blocks/Block';

export const AdminToolsContentBlock: React.FunctionComponent<{}> = () => {
  return (
    <BlockBody>
      <AdminToolsContent />
    </BlockBody>
  );
};

export const AdminToolsBlock: React.FunctionComponent<{}> = () => {
  return (
    <Block {...getBlockProps(BlockType.AdminTools, <AdminToolsHeader blockType={BlockType.AdminTools} />)}>
      <AdminToolsContentBlock />
    </Block>
  );
};
