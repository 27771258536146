/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react';
import { useStateSelector, optionChainSelectors, symbolExpirationStateSelector } from './../../../data/global/dataSelectors';
import { useDispatcher } from '../../../data/global/hooks';
import { useOptionChainData } from '../state/OptionChainState';
import { useOptionChainSettings } from './useOptionChainSettings';
import { useBlockId } from '../../../components/blocks/BlockState';

export const useOptionChain = (symbol?: string) => {
  const blockId = useBlockId();
  const expirations = useStateSelector(s => symbolExpirationStateSelector(s, symbol || ''));
  const optionchain = useStateSelector(s => optionChainSelectors.optionChainSymbol(s, symbol || '', blockId));
  const { current } = useOptionChainSettings();
  const isLoadingExpirations = useMemo(() => expirations && expirations.isFetching, [expirations]);

  const expandedExpirations = useOptionChainData(s => s.expandedExpirations);

  const { dispatcher } = useDispatcher();
  useEffect(() => {
    const symbolValue = symbol ? symbol : undefined;
    if (symbolValue) {
      dispatcher.optionchain.setStrikeCount(blockId, symbolValue, current.strikeCount, expandedExpirations || []);
    }
  }, [current.strikeCount]);

  return { optionchain, isLoadingExpirations };
};
