import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useStateSelector } from '../../data/global/dataSelectors';
import { quoteExtendedSelectors, quoteSelectors } from '../../data/global/selectors/quoteSelector';
import { Loading, OptionType, BasicSelect, Number } from '@tradingblock/components';
import { EarningsTimeframeValues } from '@tradingblock/types';
import { QuoteDataActions } from '../../data/global/actions/QuoteActions';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import _, { isNil } from 'lodash';
import { QuoteBlockActions, QuoteBlockAction } from './state/QuoteBlockAction';
import { useBlockActionDispatchAndGenericActions } from '../../components/blocks/BlockState';
import { QuoteState, useQuoteData } from './state/QuoteState';

const OrderField: React.FunctionComponent<{ label: string }> = ({ label, children }) => (
  <li className="prop-inline">
    <span className="mute prop-title">{label}</span> <span className="prop-value">{children}</span>
  </li>
);

export const earningOptions: OptionType<EarningsTimeframeValues>[] = [
  { value: 'quarter', label: 'Quarterly', data: 'quarter' },
  { value: 'annual', label: 'Annual', data: 'annual' },
];

export const EarningsInfo: React.FunctionComponent<{ symbol: string }> = ({ symbol }) => {
  const dispatch = useDispatch();
  const period = useQuoteData(s => s.earningsPeriod || 'quarter');
  const [__, blockDispatch] = useBlockActionDispatchAndGenericActions<QuoteState, QuoteBlockAction>();
  const setPeriod = useCallback(
    (ev: EarningsTimeframeValues) => blockDispatch(QuoteBlockActions.setEarningsPeriod(ev)),
    [blockDispatch]
  );
  const currentTimeframeOption = useMemo(() => {
    const curr = _.find(earningOptions, o => o.data === period);
    return curr;
  }, [period]);
  const currentTimeframe = useMemo(() => (currentTimeframeOption ? currentTimeframeOption.label : 'Quarterly'), [
    currentTimeframeOption,
  ]);
  useEffect(() => {
    dispatch(QuoteDataActions.requestEarnings({ symbol: symbol, period }));
  }, [symbol, period, dispatch]);
  const onTimeframeChange = useCallback((opt: OptionType<EarningsTimeframeValues>) => {
    const periodVal = opt.data;
    setPeriod(periodVal);
    dispatch(QuoteDataActions.requestEarnings({ symbol: symbol, period: periodVal }));
  }, []);
  const { isLoading, earnings } = useStateSelector(s => quoteSelectors.earnings.symbolEarnings(s, { symbol, period }));

  return (
    <>
      <div className="block-section block-section-fixed">
        <div className="row justify-content-between">
          <div className="col-auto">
            <BasicSelect
              value={currentTimeframeOption}
              onChange={onTimeframeChange}
              placeholder={currentTimeframe}
              className="btn btn-dark btn-tall dropdown-toggle text-left"
              options={earningOptions}
              isClearable={false}
              isSearchable={false}
            />
          </div>
        </div>
      </div>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <table>
            <thead>
              <tr>
                <th>Date reported</th>
                <th>Actual EPS</th>

                {currentTimeframe === 'Quarterly' && (
                  <>
                    <th>Consensus EPS</th>
                    <th>Surprise</th>
                  </>
                )}

                <th>Period</th>
                {currentTimeframe === 'Quarterly' && (
                  <>
                    <th>Previous year</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {earnings && (
                <>
                  {earnings.map((item, index) => (
                    <tr key={`${symbol}-earnings-${index}`}>
                      <td>
                        {item.reportDates
                          ? dayjs(item.reportDates && item.reportDates[0]).format('MM/DD/YYYY')
                          : 'Invalid Date'}
                      </td>

                      <td>
                        {!(isNaN(item.actualEps) || _.isNil(item.actualEps)) && (
                          <>
                            <Number value={item.actualEps} currency />
                          </>
                        )}
                      </td>

                      {currentTimeframe === 'Quarterly' && (
                        <>
                          <td>
                            {!(isNaN(item.consensusEps) || _.isNil(item.consensusEps)) && (
                              <>
                                <Number value={item.consensusEps} currency />
                              </>
                            )}
                          </td>

                          <td>
                            {!(isNaN(item.epsSurpriseDollar) || _.isNil(item.epsSurpriseDollar)) &&
                              !(isNaN(item.epsSurprisePercent) || _.isNil(item.epsSurprisePercent)) && (
                                <>
                                  <Number value={item.epsSurpriseDollar} currency /> (
                                  <Number value={item.epsSurprisePercent} percent />)
                                </>
                              )}
                          </td>
                        </>
                      )}

                      {currentTimeframe === 'Quarterly' && (
                        <>
                          <td>{item.period}</td>
                        </>
                      )}

                      {currentTimeframe === 'Annual' && (
                        <>
                          <td>{item.period.substring(3)}</td>
                        </>
                      )}

                      {currentTimeframe === 'Quarterly' && (
                        <>
                          <td>
                            {!isNil(item.prevYearActualEps) &&
                              !isNaN(item.prevYearActualEps) &&
                              !isNil(item.prevYearActualEpsPercentChange) &&
                              !isNaN(item.prevYearActualEpsPercentChange) && (
                                <>
                                  <Number value={item.prevYearActualEps} currency /> (
                                  <Number value={item.prevYearActualEpsPercentChange} percent />)
                                </>
                              )}
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </>
      )}
    </>
  );
};
