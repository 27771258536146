/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import { FeedQuote } from '@tradingblock/types';
import { useDashboardSettings } from '../../hooks/useDashboardSettings';
import { useDispatcher } from '../global/hooks';
import { useFeedActions } from './FeedState';
import { useFeedQuoteQueue } from './FeedSelectors';

export const FeedSync: React.FC = () => {
  const { dispatcher } = useDispatcher();
  const { feedSyncIntervalMs } = useDashboardSettings();
  const quotes = useFeedQuoteQueue();
  const { dropFromQueue } = useFeedActions();

  const quotesRef = useRef<FeedQuote[]>([]);
  useEffect(() => {
    quotesRef.current = quotes;
  }, [quotes]);

  const intervalRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const q = quotesRef.current;
      if (q && q.length > 0) {
        const combinedQuotes = _.reduce(
          q,
          (acc, q) => {
            return {
              ...acc,
              [q.Symbol]: q,
            };
          },
          {}
        );
        dispatcher.feed.updateQuotes(combinedQuotes);
        dropFromQueue(q.length);
      }
    }, feedSyncIntervalMs);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [feedSyncIntervalMs]);

  return <></>;
};
