import createSagaMiddleware from 'redux-saga';
import { applyMiddleware } from 'redux';
import { VersioningMiddleware } from './versioningMiddleware';
import { GroupMiddleware } from './groupMiddleware';
import { DataMiddleware } from './dataRequestMiddleware';
import { debounceMiddleware } from './debounceMiddleware';
import { throttleMiddleware } from './throttleMiddleware';
import { notificationMiddleware } from './notificationMiddleware';
import { crashReporter } from './crashReporterMiddleware';
import { eventLogMiddleware } from './eventLogMiddleware';
import { layoutMiddleware } from './layoutMiddleware';
import { stateSyncMiddleware } from './stateSyncMiddleware';
import { HandlerMiddleware } from './handlers';

// Create the middleware instance.
const sagaMiddleware = createSagaMiddleware();
export const setupMiddleware = () => {
  const middleware = applyMiddleware(
    ...[
      sagaMiddleware,
      VersioningMiddleware,
      GroupMiddleware,
      debounceMiddleware,
      throttleMiddleware,
      DataMiddleware,
      ...HandlerMiddleware,
      stateSyncMiddleware,
      notificationMiddleware,
      crashReporter,
      eventLogMiddleware,
      layoutMiddleware,
    ]
  );
  return {
    middleware,
    sagaMiddleware,
  };
};
