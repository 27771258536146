import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Select, { Creatable } from 'react-select';
import { components } from 'react-select/lib/components';
import { AutocompleteControlComponent } from './Autocomplete';
var topStyle = {
  transform: 'translateY(calc(-100% - 2rem))'
};
var PORTAL_OFFSET = 50;
var DROPDOWN_OFFSET = 190;
export var showPortalAbove = function showPortalAbove(height, portalRef) {
  // return true if the portal dimentions are below the window dimentions (aka getting cut off at the bottom)
  return portalRef !== undefined && portalRef.bottom + PORTAL_OFFSET > height + PORTAL_OFFSET - DROPDOWN_OFFSET;
};
export var SelectMenuPortal = function SelectMenuPortal(props) {
  var element = document.getElementById('dashboard-content');

  if (element) {
    element.className += ' disableScroll';
  }

  var ref = useRef(null);
  useEffect(function () {
    return function () {
      var element = document.getElementById('dashboard-content');

      if (element) {
        element.className = element.className.slice(0, element.className.indexOf('disableScroll'));
      }
    };
  }, [ref]);
  return React.createElement(components.MenuPortal, Object.assign({}, props, {
    ref: ref
  }));
};
export var defaultStyles = {
  menuPortal: function menuPortal(provided) {
    return Object.assign({}, provided, {
      width: 'auto',
      zIndex: 9999
    });
  },
  singleValue: function singleValue(provided) {
    return Object.assign({}, provided, {
      color: 'var(--blue) !important'
    });
  }
};
export var topDropdownStyles = {
  menuPortal: function menuPortal(provided) {
    return Object.assign({}, provided, topStyle, {
      width: 'auto',
      zIndex: 9999
    });
  },
  singleValue: function singleValue(provided) {
    return Object.assign({}, provided, {
      color: 'var(--blue) !important'
    });
  }
};

var useSelectProps = function useSelectProps(props) {
  var styles = useMemo(function () {
    return defaultStyles;
  }, [props]);
  return useMemo(function () {
    return Object.assign({
      styles: styles,
      classNamePrefix: 'select',
      id: 'scrollable',
      className: "".concat(props.className || '', " basic-multi-select"),
      menuPortalTarget: document.getElementById('portal-root'),
      components: Object.assign({
        DropdownIndicator: null,
        Control: AutocompleteControlComponent,
        MenuPortal: SelectMenuPortal
      }, props.components || {})
    }, props);
  }, [props, styles]);
};

export var BasicSelect = function BasicSelect(props) {
  var selectRef = useRef(null);
  var selectProps = useSelectProps(props);

  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      menuStyles = _useState2[0],
      setMenuStyles = _useState2[1];

  return React.createElement("span", {
    ref: selectRef
  }, React.createElement(Select, Object.assign({}, Object.assign({}, selectProps, {
    styles: Object.assign({
      // TBFE-1297: Customized styles for control component from react-select package in order to limit the width of the input to make sure the text overflows correctly.
      control: function control(provided) {
        return Object.assign({}, provided, {
          'max-width': '7.5em'
        });
      }
    }, selectProps.styles, menuStyles)
  }), {
    onMenuOpen: function onMenuOpen() {
      return setMenuStyles(selectRef && selectRef.current && showPortalAbove(window.innerHeight, selectRef.current.getBoundingClientRect()) ? topDropdownStyles : defaultStyles);
    }
  })));
};
export var BasicCreatableSelect = function BasicCreatableSelect(props) {
  return React.createElement(Creatable, Object.assign({}, {
    classNamePrefix: 'select',
    menuPortalTarget: document.getElementById('portal-root'),
    styles: defaultStyles,
    className: "basic-multi-select"
  }, props));
};