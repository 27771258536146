import React from 'react';
import { useSelector } from 'react-redux';
import { accountBalances } from '../../data/global/dataSelectors';
import { AccountBalanceView } from './AccountBalanceView';

export const AccountContent: React.FunctionComponent<{}> = () => {
  const totals = useSelector(accountBalances.totals);

  return <AccountBalanceView totals={totals} />;
};
