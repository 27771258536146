import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Row, Cell } from 'react-table';
import _ from 'lodash';
import {
  PositionPerformanceInfo,
  SizeType,
  OrderAction,
  AssetType,
  BlockType,
  ProfitLossInfoMap,
  AggregatedProfitLossInfo,
  BasicSymbolProfitLossInfo,
} from '@tradingblock/types';
import {
  Number,
  CellWithExpandedToggleProps,
  SignedNumber,
  useTableColumnWidths,
  Loading,
  EmptyBlockContent,
  TradingBlockLink,
} from '@tradingblock/components';
import {
  OrderBuilderType,
  Shares,
  CustomStrategy,
  AssetTypeToSymbolType,
  toAssetSymbol,
  isOCCSymbol,
  PositionDTO,
  PartialQuote,
  TodaysFilledOrders,
  AggregatedPositionDTO,
} from '@tradingblock/api';
import { DirectionalPrice } from '../../../components/trading/DirectionalPrice';
import { useOrderBlockWithOrder } from '../../../components/blocks/hooks/useOrderBlock';
import { useChartSymbolAction, PriceChartBuilderType } from '../../../components/blocks/hooks/usePriceChartBlock';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { getAllPositionInfo, getPositionsFromSymbol } from '../../../data/global/selectors/positionSelector';
import { BlockTable } from '../../shared/BlockTable';
import { useBlockGroupInfo } from '../../useGroupState';
import { PositionActions } from '../PositionActions';
import { avgPositionStrat, PositionLeg } from '../PositionLeg';
import { PositionDropdown } from './PositionDropdown';
import { SubAccountFilter } from '../../shared/SubAccountFilter';
import { useDispatch, useSelector } from 'react-redux';
import { account } from '../../../data/global/selectors/accountSelectors';
import { Config } from '../../../config';
import { AccountApiFactory } from '@tradingblock/api';
import { SymbolPosition, SymbolPositions } from '../../../types';
import { PositionPerformanceWithPnL, QuoteSymbolQuotes } from '../../../data/global/state';
import { aggregatedSymbolPosition, QuoteDataToPartialQuote } from '../../../data/global/selectors/transforms';
import { positionToInfo } from '../../../utilities/position';
import { SymbolPnLReducer } from '../../../data/global/sliceReducers/performance/performanceBySymbolReducer';
import { sumNumbers } from '../../../utilities/data';
import { usePositionSettingsValue } from '../usePositionSettings';
import useAggregatedPositions from '../hooks/useAggregatedPositions';
import { calculatePositionCostValue } from '../util';
import { UIActions } from '../../../data/global/actions';

export const PositionTable: React.FC<{
  setTotalGain: (totalGain: number | undefined) => void;
  setDailyPnl: (dailyPnl: number | undefined) => void;
}> = ({ setDailyPnl, setTotalGain }) => {
  const { groupId } = useBlockGroupInfo();
  const data = useStateSelector(getAllPositionInfo);
  const [tableSize, setTableSize] = useState<SizeType>();
  const wideTable = useMemo(() => tableSize === SizeType.lg, [tableSize]);
  const maxVisibleActions = useMemo(() => (wideTable ? 3 : 0), [wideTable]);

  // variables for building api client
  const accountId = useSelector(account.accountId);
  const apiRootUrl = Config.tradingApi;
  const token = useStateSelector(state => state.auth.apiToken) || undefined;

  // redux states
  const quotes = useStateSelector(state => state.quotes.quoteMetadata.data);
  const performance = useStateSelector(state => state.performance.positions);
  const hasSubAccounts = useStateSelector(
    s => s.account.subaccounts.subaccounts && s.account.subaccounts.subaccounts.length > 0
  );
  const subId = useStateSelector(state => state.account.subAccountId);
  const orders = useStateSelector(state => state.account.orders);
  const quoteState = useStateSelector(state => state.quotes);
  const subAccountFilterId = useStateSelector(s => s.ui.dashboard.subAccountFilterId);

  // useStates for handling sub account filter
  const [selectedSubAccountId, setSelectedSubAccountId] = useState<number | undefined>(undefined);
  const [selectedSubAccountPositions, setSelectedSubAccountPositions] = useState<PositionDTO[]>([]);
  const [selectedSubAccountData, setSelectedSubAccountData] = useState<PositionPerformanceInfo[]>([]);
  const [positionsRequested, setPositionsRequested] = useState(false);
  const positions = useStateSelector(state => state.positions.positions);
  const subAccountBalances = useStateSelector(s => s.accountData.subAccountsBalances);
  const masterSubAccount = useStateSelector(
    state => state.account.subaccounts.subaccounts && state.account.subaccounts.subaccounts.find(s => s.IsMaster)
  );
  const dispatch = useDispatch();

  // if a sub account is selected, use the sub account positions and data
  const tableData = !selectedSubAccountId ? data : selectedSubAccountData;
  const accountApi = useMemo(() => {
    if (accountId && token && !selectedSubAccountId) {
      return AccountApiFactory(apiRootUrl, token, accountId);
    }
    if (accountId && apiRootUrl && token && selectedSubAccountId) {
      return AccountApiFactory(apiRootUrl, token, accountId, selectedSubAccountId);
    }
  }, [selectedSubAccountId]);

  const positionSettingsValueObject = usePositionSettingsValue();
  const columnSettingsChange = useMemo(
    () => positionSettingsValueObject && Object.entries(positionSettingsValueObject).flat(1),
    [positionSettingsValueObject]
  );
  const initialState = useMemo(() => {
    return {
      pageIndex: 0,
      sortBy: ['expander'],
      hiddenColumns: [
        !positionSettingsValueObject.Description && 'description',
        !positionSettingsValueObject.Last && 'last',
        !positionSettingsValueObject.Change && 'change',
        !positionSettingsValueObject.Percent && '%',
        !positionSettingsValueObject.PNL && 'Daily P/L',
        !positionSettingsValueObject.Gain && 'Gain',
        !positionSettingsValueObject.Value && 'Value',
        !positionSettingsValueObject.Cost && 'Cost',
        !positionSettingsValueObject.Options && 'options',
        !positionSettingsValueObject.Shares && 'shares',
        !positionSettingsValueObject.AvgPrice && 'avg_price',
        !positionSettingsValueObject.Close && 'Settlement',
        !positionSettingsValueObject.PrevClose && 'close',
        !positionSettingsValueObject.Ask && 'ask',
        !positionSettingsValueObject.Bid && 'bid',
      ],
    } as any;
  }, [columnSettingsChange]);

  // This is put into place to refresh the Position block when there is a change to the subaccount positions.
  useEffect(() => {
    // This only needs to refresh when there is a selectedSubAccountId
    if (selectedSubAccountId) {
      const tempSubId = selectedSubAccountId;
      if (positions) {
        // Checking if positions have been journaled into/away from the selected subaccount
        const fetchedSelectedSubPos = positions.filter(p => p.SubaccountId && p.SubaccountId === tempSubId);
        if (fetchedSelectedSubPos.length !== selectedSubAccountPositions.length) {
          setSubAccountId(-1);
          setTimeout(() => setSubAccountId(tempSubId), 500);
        }
      }
    }
  }, [subAccountBalances, positions]);

  useEffect(() => {
    if (!hasSubAccounts && subAccountFilterId) {
      dispatch(UIActions.setSubAccountFilterId({ id: -1 }));
      setSubAccountId(-1);
    }
  }, [hasSubAccounts]);

  useEffect(() => {
    if (!subAccountFilterId) {
      setSelectedSubAccountId(undefined);
      setTotalGain(undefined);
      setDailyPnl(undefined);
      setSelectedSubAccountPositions([]);
      setPositionsRequested(false);
    }
  }, [subAccountFilterId]);

  // functions to transform sub account position data into table data
  const setSubAccountId = async (subAccountId: number) => {
    if (subAccountId) {
      if (subAccountId === -1) {
        setSelectedSubAccountId(undefined);
        setTotalGain(undefined);
        setDailyPnl(undefined);
      } else {
        setSelectedSubAccountId(subAccountId);
      }
      setSelectedSubAccountPositions([]);
      setPositionsRequested(false);
    }
  };

  const setSubAccountData = async (data: PositionPerformanceInfo[]) => {
    if (data) {
      setSelectedSubAccountData(data);
    }
  };

  const getSubAccountPositions = async () => {
    if (accountApi && selectedSubAccountId && selectedSubAccountPositions.length === 0 && !positionsRequested) {
      const positions = await accountApi.positions();
      setSelectedSubAccountPositions(positions.payload);
      setPositionsRequested(true);
      return positions.payload;
    }
  };

  const getSubAccountPositionsFromSymbol = async (positions: PositionDTO[]) => {
    const subPositions = _.reduce(
      positions,
      (acc: SymbolPositions, pos: PositionDTO): SymbolPositions => {
        // To make sure that options and common stock fall under the same category, group it buy the underlying symbol,
        // otherwise, if grouped by symbol, the symbols wont match (options have an id in the symbol name while common stock don't)
        const underlyingSymbol = pos.UnderlyingSymbol;
        const DEFAULTVALUE = { shares: 0, value: 0, positions: [], symbol: underlyingSymbol, options: 0 };
        let symbolValue = acc[underlyingSymbol] || DEFAULTVALUE;
        // If the accumulated object doesn't have a symbol, assign it default values.
        // if (!acc.hasOwnProperty(sym)) Object.assign({ [sym]: [pos], symbol: sym }, acc);
        // If the AssetType is common stock
        const isEquity = pos.AssetType === AssetType.Equity;
        // Aggregate the shares and values of the stock
        return {
          ...acc,
          [underlyingSymbol]: {
            ...symbolValue,
            shares: isEquity ? symbolValue.shares + pos.OpenQuantity : symbolValue.shares,
            options: isEquity ? symbolValue.options : symbolValue.options + pos.OpenQuantity,
            // value: symbolValue.value + pos.OpenPrice,
            positions: [...symbolValue.positions, pos],
          },
        };
      },
      {}
    );
    return subPositions;
  };

  const getSubAccountPositionQuotes = async (quotes: QuoteSymbolQuotes, positions: PositionDTO[]) => {
    const uniquePositions = _(positions)
      .map(p => [p.Symbol, p.UnderlyingSymbol])
      .flatMap()
      .uniq()
      .value();
    const quotesData = _.filter(quotes, (v, k: string) => uniquePositions.includes(k));
    const partialQuote = QuoteDataToPartialQuote(quotesData);
    return partialQuote;
  };

  const getSubAccountPositionsAndQuotes = async () => {
    if (accountApi && selectedSubAccountId) {
      const positionResponse =
        selectedSubAccountPositions && selectedSubAccountPositions.length > 0
          ? selectedSubAccountPositions
          : await getSubAccountPositions();

      if (positionResponse) {
        const subPositions = await getSubAccountPositionsFromSymbol(positionResponse);
        const subQuotes = await getSubAccountPositionQuotes(quotes, positionResponse);
        const subData = await getSubAccountPositionsWithPerformance(subPositions, subQuotes);

        const todaysOrders =
          orders &&
          TodaysFilledOrders(orders).filter(o =>
            selectedSubAccountId !== -1 ? o.SubaccountId === selectedSubAccountId : o
          );

        const bySymbol = todaysOrders && SymbolPnLReducer(quoteState, todaysOrders, positionResponse);

        const all = bySymbol && (await getAggregatedTotals(bySymbol));

        if (all) {
          const { total } = all;
          setDailyPnl(total);
        }
        return subData;
      }
    }
  };

  const getAggregatedTotals = async (pnlMap: ProfitLossInfoMap) =>
    _.reduce(
      pnlMap,
      (acc: AggregatedProfitLossInfo, v: BasicSymbolProfitLossInfo): AggregatedProfitLossInfo => {
        const trading = acc.trading + v.trading;
        const position = acc.position + v.position;
        const total = trading + position;
        return {
          pnls: [...acc.pnls, v],
          trading,
          position,
          total,
        };
      },
      { trading: 0, position: 0, total: 0, pnls: [] }
    );

  const getSubAccountPositionsWithPerformance = async (subPositions: SymbolPositions, subQuotes: PartialQuote[]) => {
    const positionsWithPerformance = _.reduce(
      subPositions,
      (res, pos: SymbolPosition, key: string): SymbolPositions => {
        const posIds = _.map(pos.positions, p => p.PositionId);
        const aggregatedPnl = _.reduce(
          performance,
          (acc, pp, key) => {
            if (!posIds.includes(_.toString(key))) {
              return acc;
            }

            return {
              pnl: acc.pnl + pp.pnl,
            };
          },
          { pnl: 0 }
        );
        const curr: PositionPerformanceWithPnL = {
          ...aggregatedSymbolPosition(pos.positions, subQuotes, key),
          pnl: aggregatedPnl.pnl,
        };
        return {
          ...res,
          [key]: curr,
        };
      },
      {}
    );
    const data = _.map(positionsWithPerformance, (v, k) => {
      return positionToInfo(v);
    });
    await setSubAccountData(data);

    const totalGain =
      data &&
      sumNumbers(
        _.map(data, d => {
          if (d.symbol.assetType === AssetType.MoneyMarket) {
            return 0;
          }
          return d.totalGain;
        })
      );
    setTotalGain(totalGain);
    return data;
  };

  const [tableStateColumnOrder, setTableStateColumnOrder] = useState<string[]>([]);
  const getColumnOrder = useCallback(
    (columnOrder: string[]) => {
      setTableStateColumnOrder(columnOrder);
    },
    [setTableStateColumnOrder, positionSettingsValueObject]
  );

  const columns: any[] = useMemo(
    () => [
      {
        Header: 'Symbol',
        accessor: (a: PositionPerformanceInfo) => a.underlyingSymbol,
        id: 'expander',
        canSort: true,
        sortType: 'alphanumeric',
        Cell: ({ row }: CellWithExpandedToggleProps<PositionPerformanceInfo>) => {
          return (
            <span {...row.getToggleRowExpandedProps()}>
              <PositionDropdown expandRow={positionSettingsValueObject.expandRows}>
                {row.original.underlyingSymbol}
              </PositionDropdown>
            </span>
          );
        },
      },
      {
        Header: 'Description',
        accessor: (a: PositionPerformanceInfo) => a.description,
        id: 'description',
        width: 2,
        canSort: true,
        sortType: 'alphanumeric',
        Cell: ({ row }: Cell<PositionPerformanceInfo>) => {
          return <>{row.original.description}</>;
        },
      },
      {
        Header: 'Last',
        accessor: (a: PositionPerformanceInfo) => a.lastTradePrice,
        id: 'last',
        canSort: true,
        sortType: 'alphanumeric',
        Cell: ({ row }: Cell<PositionPerformanceInfo>) => (
          <DirectionalPrice precisionOverride={true} prefix={'$'} value={row.original.lastTradePrice} />
        ),
      },
      {
        Header: 'Change',
        accessor: (a: PositionPerformanceInfo) => a.change,
        id: 'change',
        Cell: ({ row }: Cell<PositionPerformanceInfo>) => {
          if (isNaN(row.original.change)) return <Loading size={'small'} />;
          return <SignedNumber precisionOverride={true} value={row.original.change} bold currency />;
        },
      },
      {
        Header: '(%)',
        accessor: (a: PositionPerformanceInfo) => a.changePerc,
        id: '%',
        width: 0.7,
        canSort: true,
        sortType: 'alphanumeric',
        Cell: ({ row }: Cell<PositionPerformanceInfo>) => {
          if (isNaN(row.original.changePerc)) return <Loading size={'small'} />;
          return <SignedNumber precisionOverride={true} value={row.original.changePerc} bold percent hideIcon />;
        },
      },
      {
        Header: 'Bid',
        accessor: (a: PositionPerformanceInfo) => a.bid,
        id: 'bid',
        canSort: true,
        sortType: 'alphanumeric',
        Cell: ({ row }: Cell<PositionPerformanceInfo>) => {
          if (
            row.original.symbol.assetType === AssetType.MoneyMarket ||
            row.original.symbol.assetType === AssetType.MutualFund
          ) {
            return <></>;
          }
          return <DirectionalPrice value={row.original.bid} />;
        },
      },
      {
        Header: 'Ask',
        accessor: (a: PositionPerformanceInfo) => a.ask,
        id: 'ask',
        width: 0.7,
        canSort: true,
        sortType: 'alphanumeric',
        Cell: ({ row }: Cell<PositionPerformanceInfo>) => {
          if (
            row.original.symbol.assetType === AssetType.MoneyMarket ||
            row.original.symbol.assetType === AssetType.MutualFund
          ) {
            return <></>;
          }
          return <DirectionalPrice value={row.original.ask} />;
        },
      },
      {
        Header: 'Prev. Close',
        accessor: (a: PositionPerformanceInfo) => a.close,
        id: 'close',
        canSort: true,
        sortType: 'alphanumeric',
        Cell: ({ row }: Cell<PositionPerformanceInfo>) => {
          if (row.original.IsSettlementSet) {
            return <Number precisionOverride={true} prefix={'$'} value={row.original.prevClose} currency />;
          } else return <Number precisionOverride={true} prefix={'$'} value={row.original.close} currency />;
        },
      },
      {
        Header: 'Close',
        accessor: (a: PositionPerformanceInfo) => a.IsSettlementSet,
        id: 'Settlement',
        canSort: true,
        Cell: ({ row }: Cell<PositionPerformanceInfo>) => {
          const isSettled = row.original.IsSettlementSet;
          return isSettled ? (
            <Number precisionOverride={true} value={row.original.close} currency />
          ) : (
            <i className="fal fa-clock fa-lg mute" title="Settlement price not yet available." />
          );
        },
      },
      {
        Header: 'Avg Price',
        accessor: (a: PositionPerformanceInfo) => a.bid,
        id: 'avg_price',
        canSort: true,
        sortType: 'alphanumeric',
        Cell: ({ row }: Cell<PositionPerformanceInfo>) => {
          const symbolPositions: SymbolPositions = useStateSelector(getPositionsFromSymbol);
          const positions: AggregatedPositionDTO[] = useAggregatedPositions(
            row.original.underlyingSymbol,
            symbolPositions,
            true,
            false,
            selectedSubAccountId
          );

          if (positions.length > 1) {
            return <></>;
          }

          return <Number value={avgPositionStrat(positions)} currency />;
        },
      },

      {
        Header: () => {
          return <div className="text-center">Shares</div>;
        },
        accessor: (a: PositionPerformanceInfo) => a.quantity,
        id: 'shares',
        width: 0.4,
        canSort: true,
        sortType: 'alphanumeric',
        Cell: ({ row }: Cell<PositionPerformanceInfo>) => {
          if (
            row.original.symbol.assetType === AssetType.MoneyMarket ||
            row.original.symbol.assetType === AssetType.MutualFund
          ) {
            return (
              <div className="text-center">
                {!_.isUndefined(row.original.sharesSum) && <Number value={row.original.sharesSum} />}
              </div>
            );
          }

          return (
            <div className="text-center">
              {!_.isUndefined(row.original.sharesSum) && <Number value={row.original.sharesSum} integer />}
            </div>
          );
        },
      },
      {
        Header: wideTable ? 'Options' : 'Opts',
        accessor: (a: PositionPerformanceInfo) => a.optionsCount,
        id: 'options',
        width: 0.5,
        canSort: true,
        sortType: 'alphanumeric',
        className: 'text-center',
        Cell: ({ row }: Cell<PositionPerformanceInfo>) => (
          <div className="text-center">{row.original.optionsCount > 0 && <i className="fal fa-check" />}</div>
        ),
      },
      {
        Header: 'Cost',
        accessor: (a: PositionPerformanceInfo) => a.cost,
        id: 'Cost',
        canSort: true,
        sortType: 'alphanumeric',
        Cell: ({ row }: Cell<PositionPerformanceInfo>) => {
          const positionCostValue = row.original.commission
            ? row.original.cost - row.original.commission
            : row.original.cost;

          return <Number value={row.original.cost} currency />;
        },
      },
      {
        Header: 'Value',
        accessor: (a: PositionPerformanceInfo) => a.value,
        id: 'Value',
        width: 1.1,
        canSort: true,
        sortType: 'alphanumeric',
        Cell: ({ row }: Cell<PositionPerformanceInfo>) => <Number value={row.original.value} currency />,
      },
      {
        Header: 'Gain',
        accessor: (a: PositionPerformanceInfo) => a.totalGain,
        id: 'Gain',
        width: 1.1,
        Cell: ({ row }: Cell<PositionPerformanceInfo>) => {
          if (row.original.symbol.assetType === AssetType.MoneyMarket) {
            return <SignedNumber value={0} bold currency hideIcon />;
          }
          const gain =
            row.original.commission && !row.original.IsSettlementSet
              ? row.original.totalGain + row.original.commission
              : row.original.totalGain;

          return <SignedNumber value={gain} bold currency hideIcon />;
        },
      },
      {
        Header: 'Daily P/L',
        accessor: (a: PositionPerformanceInfo) => a.pnl,
        id: 'Daily P/L',
        Cell: ({ row }: Cell<PositionPerformanceInfo>) => {
          if (row.original.symbol.assetType === AssetType.MoneyMarket) {
            return <SignedNumber value={0} bold currency hideIcon />;
          }
          const pnl = row.original.pnl;
          return _.isNaN(pnl) ? <></> : <SignedNumber value={row.original.pnl} bold currency hideIcon />;
        },
      },
      {
        Header: 'Actions',
        accessor: 'action',
        width: wideTable ? 1.2 : 0.6,
        id: 'Actions',
        Cell: ({ row }: Cell<PositionPerformanceInfo>) => {
          const { symbol, sharesSum } = row.original;
          const noShareLegs = sharesSum === undefined || sharesSum === 0;
          const rawQuantity = sharesSum || 1;
          const isShort = rawQuantity < 0;
          const orderAndLegAction = isShort || noShareLegs ? OrderAction.Buy : OrderAction.Sell;
          const assetSymbol = row.original.symbol;
          const underlyingSymbol = assetSymbol.underlyingSymbol || assetSymbol.symbol;
          const baseAssetSymbol = toAssetSymbol(
            underlyingSymbol,
            assetSymbol.assetType,
            AssetTypeToSymbolType(assetSymbol.assetType),
            { name: underlyingSymbol, underlyingSymbol, rootSymbol: underlyingSymbol }
          );
          const isOCC = isOCCSymbol(assetSymbol.symbol);
          const priceChartBuilder = useCallback((builder: PriceChartBuilderType) => {
            return builder;
          }, []);

          const handleCloseClick = useOrderBlockWithOrder(
            (builder: OrderBuilderType) => {
              let quantity = Math.abs(rawQuantity);

              if (quantity < 1) {
                quantity = Math.ceil(quantity);
              } else {
                quantity = Math.floor(quantity);
              }

              return builder
                .symbol(symbol)
                .strategy(Shares)
                .quantity(quantity)
                .action(orderAndLegAction)
                .leg(l => l.assetType(AssetType.Equity).action(orderAndLegAction));
            },
            { blockType: BlockType.Order, groupId }
          );

          const handleTradeClick = useOrderBlockWithOrder(
            (builder: OrderBuilderType) => {
              return builder
                .symbol(symbol)
                .strategy(CustomStrategy)
                .quantity(1)
                .action(OrderAction.Buy);
            },
            { blockType: BlockType.Order, groupId }
          );

          const handleChartClick = useChartSymbolAction(baseAssetSymbol, priceChartBuilder, { groupId });

          const positionActions = useMemo(() => {
            if (isOCC) {
              const actions = [{ title: 'Trade', onClick: () => handleTradeClick(), icon: 'fa-clone' }];
              return actions;
            } else {
              const actions = [
                { title: 'Close position', onClick: () => handleCloseClick(), icon: '' },
                { title: 'Trade', onClick: () => handleTradeClick(), icon: 'fa-clone' },
                { title: 'Chart', onClick: () => handleChartClick(), icon: 'fa-chart-line' },
              ];
              return actions;
            }
          }, [handleCloseClick, handleTradeClick, handleChartClick]);

          if (
            row.original.symbol.assetType === AssetType.MoneyMarket ||
            row.original.symbol.assetType === AssetType.MutualFund
          ) {
            return (
              <div>
                <TradingBlockLink to={'LegacySiteLoginUrl'}>
                  <i className="fa-2x badge-icon far fa-info-circle"></i> Legacy Support Only
                </TradingBlockLink>
              </div>
            );
          }

          return (
            <PositionActions
              actions={positionActions}
              identity={`${symbol.symbol}-primary`}
              maxVisibleActions={maxVisibleActions}
            />
          );
        },
      },
    ],
    [groupId, wideTable, maxVisibleActions, tableStateColumnOrder, positionSettingsValueObject]
  );

  const renderSubRow = useCallback(
    (row: Row<PositionPerformanceInfo>, tableOptions: { size: SizeType }) => {
      return (
        <>
          <PositionLeg
            underlyingSymbol={row.original.underlyingSymbol}
            size={tableOptions.size}
            maxVisibleActions={maxVisibleActions}
            subAccountId={selectedSubAccountId}
            columnOrder={tableStateColumnOrder}
          />
        </>
      );
    },
    [maxVisibleActions, selectedSubAccountId, tableStateColumnOrder, positionSettingsValueObject]
  );

  const getRowKey = useCallback((data: PositionPerformanceInfo) => `${data.symbol}`, []);

  useEffect(() => {
    if (accountApi && selectedSubAccountId && !subId) {
      getSubAccountPositionsAndQuotes();
    }
  }, [accountApi, selectedSubAccountId, quotes, subId]);

  const columnWidths = useTableColumnWidths(columns);
  // prettier-ignore
  return (
    <>
      {hasSubAccounts && !subId && (
        <SubAccountFilter setSubAccountId={setSubAccountId} className="sub-account-filter-position-override" />
      )}
      {tableData && tableData.length > 0 && (
        <BlockTable
          tableKey="positions"
          columns={columns}
          data={tableData}
          renderSubRow={renderSubRow}
          getRowKey={getRowKey}
          onSizeChanged={setTableSize}
          sortable
          frozenHeaders
          numColumns={_.size(columns)}
          initialState={initialState}
          columnWidths={columnWidths}
          getColumnOrder={getColumnOrder}
          expandRows={positionSettingsValueObject.expandRows}
        />
      )}
      {!tableData || tableData.length === 0 && (
        <EmptyBlockContent>
          You have no positions.
        </EmptyBlockContent>
      )}
    </>
  );
};
