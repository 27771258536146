import React from 'react';
import { BlockBody } from '@tradingblock/components';
import { BlockType, AssetSymbol, OptionChainSettings } from '@tradingblock/types';
import { Block } from '../../components/blocks/Block';
import { OptionChainHeader } from './OptionChainHeader';
import { OptionChainContent } from './OptionChainContent';
import { getBlockProps } from '../../utilities/block';
import { useOptionChainSymbols } from './data/useOptionChainSymbols';
import { useOptionChainGroupHandler } from './state/useOptionChainGroupHandler';
import { useOptionChainSettings } from './data/useOptionChainSettings';
import { SettingsFields } from '../../components/form/GenericField';

const SymbolContent: React.FunctionComponent<{ symbol: AssetSymbol }> = ({ symbol }) => {
  useOptionChainSymbols(symbol);
  return (
    <>
      {symbol !== undefined && (
        <>
          <BlockBody>
            <OptionChainContent />
          </BlockBody>
        </>
      )}
    </>
  );
};

const Content: React.FunctionComponent<{ symbol: AssetSymbol }> = ({ symbol }) => {
  return <>{symbol !== undefined && <SymbolContent symbol={symbol} />}</>;
};
const settingsFields: SettingsFields<OptionChainSettings> = {
  strikeCount: {
    type: 'choice',
    values: [{ value: 10 }, { value: 20 }, { value: 40 }, { value: 100 }, { value: 0, label: 'All' }],
  },
  bidUsesBuyOrderAction: {
    type: 'radio',
    yesOptionLabel: 'BUY',
    noOptionLabel: 'SELL',
    fieldLabel: 'Clicking BID will result in order to:',
    fieldNote: (value: boolean) => `Conversely, clicking ASK will result in order to ${value ? 'SELL' : 'BUY'}.`,
  },
  Bid: {
    type: 'boolean',
  },
  Ask: {
    type: 'boolean',
  },
  Last: {
    type: 'boolean',
  },
  Change: {
    type: 'boolean',
  },
  Close: {
    type: 'boolean',
  },
  Delta: {
    type: 'boolean',
  },
  Theta: {
    type: 'boolean',
  },
  Gamma: {
    type: 'boolean',
  },
  Volume: {
    type: 'boolean',
  },
  IV: {
    type: 'boolean',
  },
  weeklyExpirations: {
    type: 'radio',
    fieldLabel: 'Show weekly expirations:',
    yesOptionLabel: 'Yes',
    noOptionLabel: 'No',
  },
  monthlyExpirations: {
    type: 'radio',
    fieldLabel: 'Show monthly expirations:',
    yesOptionLabel: 'Yes',
    noOptionLabel: 'No',
  },
  quarterlyExpirations: {
    type: 'radio',
    fieldLabel: 'Show quarterly expirations:',
    yesOptionLabel: 'Yes',
    noOptionLabel: 'No',
  },
  yearlyExpirations: {
    type: 'radio',
    fieldLabel: 'Show yearly expirations:',
    yesOptionLabel: 'Yes',
    noOptionLabel: 'No',
  },
};
export const OptionChainBlock: React.FunctionComponent<{}> = () => {
  const [symbol] = useOptionChainGroupHandler();
  const settings = useOptionChainSettings();

  return (
    <Block
      settings={settings}
      settingFields={settingsFields}
      {...getBlockProps(BlockType.OptionChain, <OptionChainHeader />)}
    >
      <Content symbol={symbol} />
    </Block>
  );
};
