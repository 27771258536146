import React from 'react';
import { Field } from 'formik';
import { FormGroup, TextboxField } from '@tradingblock/components';
import { TransferMechanism } from '@tradingblock/types';

interface AccountRenameFormProps {
  mechanism: TransferMechanism;
}

export const AccountRenameForm: React.FunctionComponent<AccountRenameFormProps> = ({ mechanism }) => {
  return (
    <>
      <p className="fields-title">Rename {mechanism === 'Wire' ? 'saved account for wire transfers' : 'linked account'}</p>
      <FormGroup>
        <Field component={TextboxField} id="account.nickName" label={mechanism === 'Wire' ? 'Bank name' : 'Account name'} />
      </FormGroup>
    </>
  );
};
