import { Middleware, Dispatch } from 'redux';
import { getType, ActionType } from 'typesafe-actions';
import { DataState } from '../../state';
import { RootAction } from '../../actions';
import { ApiProvider } from '../../../../context/Api';
import { SubaccountActions } from '../../actions/SubAccountActions';
import { getResponseCodeDescription } from '../../../../utilities/action';

export const SubaccountManagementMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState, }) => (next: Dispatch<RootAction>) => (action: RootAction) => {
    try {
      const previousState = getState();
      const result = next(action);
      const nextState = getState();
      switch (action.type) {
        case getType(SubaccountActions.requestSubaccounts): {
          getSubaccounts(nextState, dispatch, action);
        }
          break;
        case getType(SubaccountActions.requestUpdateSubaccount): {
          updateSubaccount(nextState, dispatch, action);
        }
          break;
        case getType(SubaccountActions.requestCreateSubaccount): {
          createSubAccount(nextState, dispatch, action);
        }
          break;
        case getType(SubaccountActions.requestDeleteSubaccount): {
          deleteSubaccount(nextState, dispatch, action);
        }
          break;
        case getType(SubaccountActions.requestGetPositions): {
          getPositions(nextState, dispatch, action);
        }
          break;
      }
      return result;
    } catch (err) {
      console.error('SubaccountManagement Middleware :: Caught an exception for action ', action, err);
    }
  };

const createSubAccount = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof SubaccountActions.requestCreateSubaccount>
) => {
  const api = ApiProvider(state, dispatch);
  const { subAccount } = action.payload;
  if (api) {
    try {
      const { payload, responseCode, errors } = await api.subaccountManagement.createSubaccount(subAccount);
      dispatch(SubaccountActions.receiveCreateSubaccount({ response: { payload: payload, responseCode: responseCode, errors: errors } }));
    } catch (err) {
      console.error('createSubAccount error', err);
      dispatch(SubaccountActions.errorCreateSubaccount({ error: err }));
    }
  }
};
const getSubaccounts = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof SubaccountActions.requestSubaccounts>) => {
  const api = ApiProvider(state, dispatch);
  if (api) {
    try {
      const { accountId } = action.payload;
      const { payload, responseCode, errors } = await api.subaccountManagement.getSubaccounts(accountId);
      dispatch(SubaccountActions.receieveSubaccounts({ response: { payload: payload, responseCode: responseCode, errors: errors } }))
    } catch (err) {
      console.error('getSubAccounts error', err);
      dispatch(SubaccountActions.errorSubaccounts({ error: err }));
    }
  }
};
const updateSubaccount = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof SubaccountActions.requestUpdateSubaccount>) => {
  const api = ApiProvider(state, dispatch);
  const { subAccount } = action.payload;
  if (api) {
    try {
      const { payload, responseCode, errors } = await api.subaccountManagement.updateSubaccount(subAccount);
      dispatch(SubaccountActions.receiveUpdateSubaccount({ response: { payload: payload, responseCode: responseCode, errors: errors } }));
    } catch (err) {
      console.error('updateSubAccount error', err);
      dispatch(SubaccountActions.errorUpdateSubaccount({ error: err }));
    }
  };
}
const deleteSubaccount = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof SubaccountActions.requestDeleteSubaccount>) => {
  const api = ApiProvider(state, dispatch);
  const { Id: subAccountId, accountId } = action.payload;
  if (api) {
    try {
      const { payload, responseCode, errors } = await api.subaccountManagement.deleteSubaccount(accountId, subAccountId);
      dispatch(SubaccountActions.receiveDeleteSubaccount({ response: { payload: payload, responseCode: responseCode, errors: errors } }));
    } catch (err) {
      console.error('deleteSubAccount error', err);
      dispatch(SubaccountActions.errorDeleteSubaccount({ error: err }));
    }
  };
}
const getPositions = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof SubaccountActions.requestGetPositions>) => {
  const api = ApiProvider(state, dispatch);
  const { accountId, Id: subaccountId } = action.payload;
  if (api) {
    try {
      const { payload, responseCode, errors } = await api.subaccountManagement.getPositions(accountId, subaccountId);
      dispatch(SubaccountActions.receivePositions({ response: { payload: payload, responseCode: responseCode, errors: errors } }));
    } catch (err) {
      console.error('getPositions error', err);
      dispatch(SubaccountActions.errorReceivePositions({ error: err }));
    }
  };
}
