import React from 'react';

export const Column: React.FunctionComponent<{ title?: string; titleClass?: string }> = ({ title, titleClass, children }) => (
  <div className="col">
    <div className="prop-stacked">
      <div className={`prop-title ${titleClass}`}>{title}</div>
      {children}
    </div>
  </div>
);

export interface StackedColumnProps<As extends React.ReactType = 'p' | 'div'> {
  title?: string;
  valueAs?: As;
  className?: string;
}

export const StackedColumn: React.FunctionComponent<StackedColumnProps> = ({ title, valueAs, className, children }) => {
  const ValueElement = valueAs || 'div';
  return (
    <Column title={title}>
      <ValueElement className={className || 'prop-value'}>{children}</ValueElement>
    </Column>
  );
};
