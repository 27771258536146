import React from 'react';
import { BlockBody, EmptyBlockContent } from '@tradingblock/components';
import { BlockType } from '@tradingblock/types';
import { AccountTransferContent } from './AccountTransferContent';
import { Block } from '../../components/blocks/Block';
import { getBlockProps } from '../../utilities/block';
import { useStateSelector } from '../../data/global/dataSelectors';
import { account } from '../../data/global/selectors/accountSelectors';
import { useMemo } from 'react';
import AccountTransferNotSupportedContent from './AccountTransferNotSupportedContent';
import { useClearer } from '../../hooks/useClearer';

export const AccountTransferBlock: React.FunctionComponent<{}> = () => {
  const accountType = useStateSelector(account.type);
  const clearer = useClearer();
  const isRQD = clearer === 'RQD';
  const isIRA =
    (accountType && accountType.toString() === 'Tradtional_IRA') ||
    (accountType && accountType.toString() === 'IRA_RollOver') ||
    (accountType && accountType.toString() === 'Roth_IRA') ||
    (accountType && accountType.toString() === 'SEP_IRA') ||
    (accountType && accountType.toString() === 'Coverdell_IRA') ||
    (accountType && accountType.toString() === 'UGMA') ||
    (accountType && accountType.toString() === 'Beneficiary_Roth_IRA');

  //Loads cashiering only on supported accounts TBFE-116 should resolve this code better
  const blockContent = useMemo(() => {
    if (accountType === undefined) {
      return <></>;
    }
    switch (accountType.toString()) {
      case 'Traditional_IRA':
      case 'IRA_RollOver':
      case 'Roth_IRA':
      case 'SEP_IRA':
      case 'Coverdell_IRA':
      case 'UGMA':
      case 'Beneficiary_Roth_IRA':
        return <AccountTransferNotSupportedContent />;
      default:
        return <AccountTransferContent />;
    }
  }, [accountType, isRQD, isIRA]);
  return (
    <Block {...getBlockProps(BlockType.AccountTransfer)}>
      <BlockBody>{blockContent}</BlockBody>
    </Block>
  );
};
