import React, { useMemo } from 'react';
import _ from 'lodash';
import { DetailField, DetailFields } from '../../../../components/basic/DetailFields';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { DefaultAccountUpdateDetails, DefaultUserUpdateAccountDetails } from '@tradingblock/types';
import { Loading } from '@tradingblock/components';

export const AccountManagementUserDetails: React.FunctionComponent<{}> = () => {
  const details = useStateSelector(s => s.private.accountManagement.currentItem.details);
  const accountInfo = useStateSelector(s => s.private.accountManagement.currentItem.accountInfo);
  const { accountNumber } = details || DefaultAccountUpdateDetails;
  const isFetching = useStateSelector(s => s.private.accountManagement.currentItem.isFetching);
  const isAccountFetching = useStateSelector(s => s.private.accountManagement.currentItem.isAccountFetching);
  const isUgma = accountInfo.type === 'Ugma';
  const secondaryOrUgmaBen = isUgma ? 'UGMA Beneficiary' : 'Secondary Account Holder';

  const { accountHolders, experience } = accountInfo || DefaultUserUpdateAccountDetails;

  const formatPhoneNumber = (phoneNumberString: string) => {
    if (phoneNumberString !== null && phoneNumberString.length > 10) {
      return phoneNumberString;
    }
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    if (phoneNumberString === null) {
      return 'None';
    }
    return null;
  };

  const fields = useMemo(() => {
    let fieldList: DetailField[] = [];
    if (details && accountInfo && !isFetching && !isAccountFetching) {
      fieldList = [
        ...fieldList,

        {
          Value: () => <>TB Account &amp; Customer Information</>,
          className: 'fields-title',
        },
      ];
      accountHolders.map((accountHolder, index) => {
        const prefix = index === 0 ? 'Primary Account Holder' : secondaryOrUgmaBen;
        fieldList = [
          ...fieldList,

          { Value: () => null },
          {
            Value: () => <span className="fields-title">{prefix}</span>,
          },
          { Value: () => null },
          {
            title: 'Name',
            Value: () => (
              <span className="mute">
                {accountHolder.firstName} {accountHolder.middleInitial || ''} {accountHolder.lastName}{' '}
                {accountHolder.suffix || ''}
              </span>
            ),
          },
          { title: 'Account Number', Value: () => <span className="mute">{accountNumber}</span> },
          {
            title: 'Phone',
            Value: () => {
              if (!accountHolder.phones || accountHolder.phones.length === 0) return <span className="mute">None</span>;
              return (
                <a className="mute" href={`tel:${accountHolder.phones[0].phoneNumber}`}>
                  {accountHolder.phones && accountHolder.phones.length > 0 && accountHolder.phones[0]
                    ? formatPhoneNumber(accountHolder.phones[0].phoneNumber)
                    : 'Phone Number Not Found'}{' '}
                  - {`${accountHolder.phones[0].type}`}
                </a>
              );
            },
          },
          {
            title: 'Email Address',
            Value: () => (
              <span className="mute">
                {accountHolder.email ? <a href={`mailto:${accountHolder.email}`}>{accountHolder.email}</a> : <>none</>}
              </span>
            ),
          },
          {
            title: 'Mailing Address',
            Value: () => (
              <span className="mute">
                {accountHolder.mailingAddress.address1 && (
                  <>
                    {accountHolder.mailingAddress.address1}
                    <br />
                  </>
                )}
                {accountHolder.mailingAddress.address2 && (
                  <>
                    {accountHolder.mailingAddress.address2}
                    <br />
                  </>
                )}
                {accountHolder.mailingAddress.city}
                {accountHolder.mailingAddress.state ? ', ' + accountHolder.mailingAddress.state : ''}{' '}
                {accountHolder.mailingAddress.postalCode}
                <br />
                {accountHolder.mailingAddress.country}
              </span>
            ),
          },
        ];
      });
    }
    return fieldList;
  }, [details, accountInfo, experience, isFetching, isAccountFetching, accountHolders]);

  if (isFetching || isAccountFetching) return <Loading />;

  return (
    <div className="screen-body-section">
      <DetailFields fieldList={fields} />
    </div>
  );
};
