import React, { useState, useCallback, FC } from 'react';
export const BlockPropTitle: FC<{ short: string; full: string }> = ({ short, full }) => {
  return (
    <span className="trunc">
      <abbr className="trunc-abbr" title={full}>
        {short}
      </abbr>
      <span className="trunc-full">{full}</span>
    </span>
  );
};
