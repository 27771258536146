import React from 'react';
import useMount from 'react-use/lib/useMount';
import { BlockType, UserRole } from '@tradingblock/types';
import { useDispatcher } from '../../data/global/hooks';
import {
  useAuthenticationSelector,
  GetPositionAndOrderSymbols,
  useStateSelector,
} from '../../data/global/dataSelectors';
import { BlockBody } from '@tradingblock/components';
import { AccountContent } from './AccountContent';
import { Block } from '../../components/blocks/Block';
import { getBlockProps } from '../../utilities/block';
import { useQuoteSymbolSubscriptionFromGlobalState } from '../../hooks/useQuoteData';
import { useGlobalStateQuoteListener } from '../../components/blocks/useBlockSubscriptions';
import { account } from '../../data/global/selectors/accountSelectors';
import AccountHeader from './AccountHeader';

export const AccountBlock: React.FunctionComponent<{}> = () => {
  const { dispatcher } = useDispatcher();
  const { isAuthenticated } = useAuthenticationSelector();
  const accountRoles = useStateSelector(account.userRoles);
  const isIRA = useStateSelector(account.accountIsIRA);
  const isUGMA = useStateSelector(account.accountIsUGMA);
  const isPartnership = useStateSelector(account.accountIsPartnership);

  const refreshAccount = () => {
    if (accountRoles !== undefined && accountRoles.includes(UserRole.CashieringApproval) && !isIRA && !isUGMA && !isPartnership) {
      dispatcher.balances.requestPendingTransfers();
    }
    dispatcher.balances.request();
    dispatcher.data.positions.request();
  }
  const pendingRefresh = () => {
    refreshAccount();
  }

  useMount(() => {
    if (isAuthenticated) {
      if (accountRoles !== undefined) {
        if (accountRoles.includes(UserRole.CashieringApproval) && !isIRA && !isUGMA && !isPartnership) {
          dispatcher.balances.requestPendingTransfers();
        }
      }
      dispatcher.balances.request();
      dispatcher.data.positions.request();
    }
  })

  useQuoteSymbolSubscriptionFromGlobalState(GetPositionAndOrderSymbols);
  useGlobalStateQuoteListener(GetPositionAndOrderSymbols);
  return (
    <Block {...getBlockProps(BlockType.Account)} onRefresh={() => pendingRefresh()} header={<AccountHeader />}>
      <BlockBody>
          <AccountContent />
      </BlockBody>
    </Block>)
};