import { ActionType, createStandardAction } from 'typesafe-actions';
import {
  AccountManagementDetailRequest,
  ApiResponse,
  GenericError,
  MiscDocMetadataResponse,
  MiscDocumentFirmApproveRejectRequest,
  MiscDocumentRequest,
} from '@tradingblock/types';
import { buildGenericRequestReceiveActions, actionBuilder } from '../../actionUtilities';
import { AdminIdRequest } from '.';
import {
  AccountUpdateSearchRequest,
  AccountManagementItem,
  AccountUpdateDetails,
  AccountUpdateUserAccountDetailRequest,
  FirmApproveOrRejectUpdateRequest,
} from '@tradingblock/types';

const ADMIN_ACCOUNT_UPDATE_SEARCH_REQUEST = '@admin/accountManagement/search/data/REQUEST';
const ADMIN_ACCOUNT_UPDATE_SEARCH_RECEIVE = '@admin/accountManagement/search/data/RECEIVE';
const ADMIN_ACCOUNT_UPDATE_SEARCH_ERROR = '@admin/accountManagement/search/data/ERROR';

const ADMIN_ACCOUNT_UPDATE_SEARCH_SET_SEARCH = '@admin/accountManagement/search/ui/SET_SEARCH';
const ADMIN_ACCOUNT_UPDATE_SEARCH_SET_TIMEFRAME = '@admin/accountManagement/search/ui/SET_TIMEFRAME';
const ADMIN_ACCOUNT_UPDATE_SEARCH_SET_TYPE = '@admin/accountManagement/search/ui/SET_TYPE';
const ADMIN_ACCOUNT_UPDATE_SEARCH_SET_STATUS = '@admin/accountManagement/search/ui/SET_STATUS';
const ADMIN_ACCOUNT_UPDATE_SEARCH_SET_PAGE = '@admin/accountManagement/search/ui/SET_PAGE';
const ADMIN_ACCOUNT_UPDATE_SEARCH_SET_PAGE_SIZE = '@admin/accountManagement/search/ui/SET_PAGE_SIZE';
const ADMIN_ACCOUNT_UPDATE_SEARCH_SET_SORTBY = '@admin/accountManagement/search/ui/SET_SORTBY';
const ADMIN_ACCOUNT_UPDATE_SEARCH_SET_CURRENT_ITEM = '@admin/accountManagmenet/search/ui/SET_CURRENT_ITEM';
const ADMIN_ACCOUNT_UPDATE_SEARCH_CLEAR_CURRENT_ITEM = '@admin/accountManagement/search/ui/CLEAR_CURRENT_ITEM';

// Retrieve All User Account Updates
const REQUEST_USER_ACCOUNT_UPDATES = '@admin/REQUEST/USER_ACCOUNT_UPDATES';
const RECEIVE_USER_ACCOUNT_UPDATES = '@admin/RECEIVE/USER_ACCOUNT_UPDATES';
const ERROR_USER_ACCOUNT_UPDATES = '@admin/ERROR/USER_ACCOUNT_UPDATES';

// Retrieve Specific User Account Update Details
const REQUEST_USER_ACCOUNT_UPDATE_DETAIL = '@admin/REQUEST/USER_ACCOUNT_UPDATE_DETAIL';
const RECEIVE_USER_ACCOUNT_UPDATE_DETAIL = '@admin/RECEIVE/USER_ACCOUNT_UPDATE_DETAIL';
const ERROR_USER_ACCOUNT_UPDATE_DETAIL = '@admin/ERROR/USER_ACCOUNT_UPDATE_DETAIL';

// Retreive User Account Details
const REQUEST_USER_ACCOUNT_DETAILS = '@admin/REQUEST/USER_ACCOUNT_DETAILS';
const RECEIVE_USER_ACCOUNT_DETAILS = '@admin/RECEIVE/USER_ACCOUNT_DETAILS';
const ERROR_USER_ACCOUNT_DETAILS = '@admin/ERROR/USER_ACCOUNT_DETAILS';

// Firm Approve Client Update
const REQUEST_FIRM_APPROVE_UPDATE = '@admin/REQUEST/FIRM_APPROVE_UPDATE';
const RECEIVE_FIRM_APPROVE_UPDATE = '@admin/RECEIVE/FIRM_APPROVE_UPDATE';
const ERROR_FIRM_APPROVE_UPDATE = '@admin/ERROR/FIRM_APPROVE_UPDATE';

// Firm Reject Client Update
const REQUEST_FIRM_REJECT_UPDATE = '@admin/REQUEST/FIRM_REJECT_UPDATE';
const RECEIVE_FIRM_REJECT_UPDATE = '@admin/RECEIVE/FIRM_REJECT_UPDATE';
const ERROR_FIRM_REJECT_UPDATE = '@admin/ERROR/FIRM_REJECT_UPDATE';

// Approve without Admin
const REQUEST_UPDATE_WITHOUT_ADMIN = '@admin/REQUEST/UPDATE_WITHOUT_ADMIN';
const RECEIVE_UPDATE_WITHOUT_ADMIN = '@admin/RECEIVE/UPDATE_WITHOUT_ADMIN';
const ERROR_UPDATE_WITHOUT_ADMIN = '@admin/ERROR/UPDATE_WITHOUT_ADMIN';

// Request Miscellaneous Document Image/Metadata
const REQUEST_MISC_DOC = '@admin/REQUEST/MISC_DOC';
const RECEIVE_MISC_DOC = '@admin/RECEIVE/MISC_DOC';
const ERROR_MISC_DOC = '@admin/ERROR/MISC_DOC';

const REQUEST_MISC_DOC_METADATA = '@admin/REQUEST/MISC_DOC_METADATA';
const RECEIVE_MISC_DOC_METADATA = '@admin/RECEIVE/MISC_DOC_METADATA';
const ERROR_MISC_DOC_METADATA = '@admin/ERROR/MISC_DOC_METADATA';

// Approve/Reject Miscellaneous Documents
const REQUEST_FIRM_APPROVE_DOC = '@admin/REQUEST/FIRM_APPROVE_DOC';
const RECEIVE_FIRM_APPROVE_DOC = '@admin/RECEIVE/FIRM_APPROVE_DOC';
const ERROR_FIRM_APPROVE_DOC = '@admin/ERROR/FIRM_APPROVE_DOC';

const REQUEST_FIRM_REJECT_DOC = '@admin/REQUEST/FIRM_REJECT_DOC';
const RECEIVE_FIRM_REJECT_DOC = '@admin/RECEIVE/FIRM_REJECT_DOC';
const ERROR_FIRM_REJECT_DOC = '@admin/ERROR/FIRM_REJECT_DOC';
const ADMIN_MISC_DOC_CLEAR_UPDATE = '@admin/accountManagement/documents/CLEAR_UPDATE_DOC';

const [requestMiscDoc, receiveMiscDoc, errorMiscDoc] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_MISC_DOC,
  RECEIVE: RECEIVE_MISC_DOC,
  ERROR: ERROR_MISC_DOC,
})<MiscDocumentRequest, { response: any }, GenericError<any, any>>();

const [requestMiscDocMetadata, receiveMiscDocMetadata, errorMiscDocMetadata] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_MISC_DOC_METADATA,
  RECEIVE: RECEIVE_MISC_DOC_METADATA,
  ERROR: ERROR_MISC_DOC_METADATA,
})<MiscDocumentRequest, { response: MiscDocMetadataResponse }, GenericError<any, any>>();

const [requestFirmApproveDoc, receiveFirmApproveDoc, errorFirmApproveDoc] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_FIRM_APPROVE_DOC,
  RECEIVE: RECEIVE_FIRM_APPROVE_DOC,
  ERROR: ERROR_FIRM_APPROVE_DOC,
})<MiscDocumentFirmApproveRejectRequest, { response: any }, GenericError<any, any>>();

const [requestFirmRejectDoc, receiveFirmRejectDoc, errorFirmRejectDoc] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_FIRM_REJECT_DOC,
  RECEIVE: RECEIVE_FIRM_REJECT_DOC,
  ERROR: ERROR_FIRM_REJECT_DOC,
})<MiscDocumentFirmApproveRejectRequest, { response: any }, GenericError<any, any>>();

const [
  requestUserAccountUpdates,
  receiveUserAccountUpdates,
  errorUserAccountUpdates,
] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_USER_ACCOUNT_UPDATES,
  RECEIVE: RECEIVE_USER_ACCOUNT_UPDATES,
  ERROR: ERROR_USER_ACCOUNT_UPDATES,
})<
  AccountUpdateSearchRequest,
  { response: any; request: AccountUpdateSearchRequest },
  GenericError<any, AdminIdRequest>
>();

const [
  requestUserAccountUpdateDetails,
  receiveUserAccountUpdateDetails,
  errorUserAccountUpdateDetails,
] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_USER_ACCOUNT_UPDATE_DETAIL,
  RECEIVE: RECEIVE_USER_ACCOUNT_UPDATE_DETAIL,
  ERROR: ERROR_USER_ACCOUNT_UPDATE_DETAIL,
})<AccountManagementDetailRequest, { details: any | AccountUpdateDetails }, GenericError<any, AdminIdRequest>>();

const [
  requestUserAccountDetails,
  receiveUserAccountDetails,
  errorUserAccountDetails,
] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_USER_ACCOUNT_DETAILS,
  RECEIVE: RECEIVE_USER_ACCOUNT_DETAILS,
  ERROR: ERROR_USER_ACCOUNT_DETAILS,
})<AccountUpdateUserAccountDetailRequest, { response: any }, GenericError<any, AdminIdRequest>>();

// const [
//   accountUpdateSearchRequest,
//   accountUpdateSearchReceive,
//   accountUpdateSearchError,
// ] = buildGenericRequestReceiveActions({
//   REQUEST: ADMIN_ACCOUNT_UPDATE_SEARCH_REQUEST,
//   RECEIVE: ADMIN_ACCOUNT_UPDATE_SEARCH_RECEIVE,
//   ERROR: ADMIN_ACCOUNT_UPDATE_SEARCH_ERROR,
// })<AccountUpdateSearchRequest, { response: any; request: AccountUpdateSearchRequest }>();

const [requestFirmApproveUpdate, receiveFirmApproveUpdate, errorFirmApproveUpdate] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_FIRM_APPROVE_UPDATE,
  RECEIVE: RECEIVE_FIRM_APPROVE_UPDATE,
  ERROR: ERROR_FIRM_APPROVE_UPDATE,
})<FirmApproveOrRejectUpdateRequest, { response: any }, GenericError<any, AdminIdRequest>>();

const [requestFirmRejectUpdate, receiveFirmRejectUpdate, errorFirmRejectUpdate] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_FIRM_REJECT_UPDATE,
  RECEIVE: RECEIVE_FIRM_REJECT_UPDATE,
  ERROR: ERROR_FIRM_REJECT_UPDATE,
})<FirmApproveOrRejectUpdateRequest, { response: any }, GenericError<any, AdminIdRequest>>();

export const AdminAccountManagementSearchActions = {
  requestUserAccountUpdates,
  receiveUserAccountUpdates,
  errorUserAccountUpdates,
  requestUserAccountUpdateDetails,
  receiveUserAccountUpdateDetails,
  errorUserAccountUpdateDetails,
  requestUserAccountDetails,
  receiveUserAccountDetails,
  errorUserAccountDetails,
  requestFirmApproveUpdate,
  receiveFirmApproveUpdate,
  errorFirmApproveUpdate,
  requestFirmRejectUpdate,
  receiveFirmRejectUpdate,
  errorFirmRejectUpdate,
  requestMiscDoc,
  receiveMiscDoc,
  errorMiscDoc,
  requestMiscDocMetadata,
  receiveMiscDocMetadata,
  errorMiscDocMetadata,
  requestFirmApproveDoc,
  receiveFirmApproveDoc,
  errorFirmApproveDoc,
  requestFirmRejectDoc,
  receiveFirmRejectDoc,
  errorFirmRejectDoc,
  setSearch: createStandardAction(ADMIN_ACCOUNT_UPDATE_SEARCH_SET_SEARCH)<Pick<AccountUpdateSearchRequest, 'search'>>(),
  setTimeframe: createStandardAction(ADMIN_ACCOUNT_UPDATE_SEARCH_SET_TIMEFRAME)<
    Pick<AccountUpdateSearchRequest, 'startDate' | 'endDate'>
  >(),
  setType: createStandardAction(ADMIN_ACCOUNT_UPDATE_SEARCH_SET_TYPE)<Pick<AccountUpdateSearchRequest, 'items'>>(),
  setStatus: createStandardAction(ADMIN_ACCOUNT_UPDATE_SEARCH_SET_STATUS)<Pick<AccountUpdateSearchRequest, 'states'>>(),
  setPage: createStandardAction(ADMIN_ACCOUNT_UPDATE_SEARCH_SET_PAGE)<Pick<AccountUpdateSearchRequest, 'page'>>(),
  setPageSize: createStandardAction(ADMIN_ACCOUNT_UPDATE_SEARCH_SET_PAGE_SIZE)<
    Pick<AccountUpdateSearchRequest, 'pageSize'>
  >(),
  setSortBy: createStandardAction(ADMIN_ACCOUNT_UPDATE_SEARCH_SET_SORTBY)<
    Pick<AccountUpdateSearchRequest, 'sortBy' | 'sortByOrder'>
  >(),
  refresh: createStandardAction('@admin/accountManagement/search/ui/REFRESH')<AccountUpdateSearchRequest>(),
  setCurrentItem: createStandardAction(ADMIN_ACCOUNT_UPDATE_SEARCH_SET_CURRENT_ITEM)<AccountManagementItem>(),
  clearCurrentItem: createStandardAction(ADMIN_ACCOUNT_UPDATE_SEARCH_CLEAR_CURRENT_ITEM)<undefined>(),
  clearDocUpdate: createStandardAction(ADMIN_MISC_DOC_CLEAR_UPDATE)<undefined>(),
};

export const AdminAccountManagementActions = {
  ...AdminAccountManagementSearchActions,
};

export type AdminAccountManagementAction = ActionType<typeof AdminAccountManagementActions>;
