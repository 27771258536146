import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useRef, useEffect, useState, useCallback } from 'react';
import { getDocumentOptions } from '../utilities';
export var useCountdown = function useCountdown(date, options) {
  var _options$intervalTime = options.intervalTime,
      intervalTime = _options$intervalTime === void 0 ? 1000 : _options$intervalTime,
      _options$now = options.now,
      now = _options$now === void 0 ? function () {
    return Date.now();
  } : _options$now;

  var _getDocumentOptions = getDocumentOptions(),
      hidden = _getDocumentOptions.hidden,
      visibilityChangeEvent = _getDocumentOptions.visibilityChangeEvent; //@ts-ignore


  var _useState = useState(function () {
    return new Date(date()) - new Date(now());
  }),
      _useState2 = _slicedToArray(_useState, 2),
      timeLeft = _useState2[0],
      setTimeLeft = _useState2[1];

  var intervalRef = useRef();

  var timerStart = function timerStart(interval) {
    intervalRef.current = setInterval(function () {
      setTimeLeft(function (current) {
        if (current <= 0) {
          clearInterval(intervalRef.current);
          return 0;
        }

        return current - intervalTime;
      });
    }, interval);
  };

  var timerClear = function timerClear() {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  var reset = useCallback(function () {
    if (intervalRef.current) {
      timerClear();
    } //@ts-ignore


    setTimeLeft(function () {
      return new Date(date()) - new Date(now());
    });
    timerStart(intervalTime);
  }, [date]);

  var handleVisibilityChangeEvent = function handleVisibilityChangeEvent() {
    if (!document[hidden]) {
      reset();
    }
  };

  useEffect(function () {
    reset();
  }, [date]);
  useEffect(function () {
    timerStart(intervalTime);
    document.addEventListener(visibilityChangeEvent, handleVisibilityChangeEvent);
    return function () {
      timerClear();
      document.removeEventListener(visibilityChangeEvent, handleVisibilityChangeEvent);
    };
  }, []);
  return {
    timeLeft: timeLeft
  };
};