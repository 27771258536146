import { useContext, useMemo } from 'react';
import _ from 'lodash';
import { BlockStateContext } from './BlockState';

export function useActiveBlockTab() {
  const { tabs } = useContext(BlockStateContext);
  const res = useMemo(() => {
    if (tabs === undefined) {
        return {
          title: "Trades",
          active: true,
        }
    }
    const active = _.find(tabs || [], t => t.active === true);
    return active ? active : undefined;
  }, [tabs]);
  return res;
}
