import { Middleware, Dispatch } from 'redux';
import { getType, ActionType } from 'typesafe-actions';
import _ from 'lodash';
import {
  OrderStatuses,
  ResponseCodes,
  GenericErrorResponseCode,
  APIAgreementResponseCode,
  APIAgreementResponseUpdateCode,
} from '@tradingblock/types';
import { formatNumber, GetLogoutUrl, GetTradingBlockDomain, GetTradingBlockFullUrl } from '@tradingblock/components';
import { DataState } from '../state';
import { RootAction, OrderUpdateEventAction, Actions, OrderActions } from '../actions';
import { isMarketOpenSelector, orderSelector } from '../dataSelectors';
import { Dispatcher } from '../dispatcher';

// reference https://redux.js.org/advanced/middleware/ for best practices

export const notificationMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = middleware => (
  next: Dispatch<RootAction>
) => (action: RootAction) => {
  const dispatcher = Dispatcher(middleware.dispatch);
  try {
    const result = next(action);
    const nextState = middleware.getState();

    switch (action.type) {
      case getType(OrderActions.orderErrored):
        handleOrderError(nextState, middleware.dispatch, action);
        break;
      case getType(OrderActions.receivedUpdate):
        handleOrderUpdates(nextState, middleware.dispatch, action);
        break;
      case getType(Actions.receiveAccountBalances):
        {
          const { responseCode } = action.payload;
          if (responseCode === APIAgreementResponseCode || responseCode === APIAgreementResponseUpdateCode) {
            const tradingBlockDomain = GetTradingBlockDomain(nextState.account.accountNumber);
            const legacySiteLoginLink = `<a href="${GetTradingBlockFullUrl(
              'LegacySiteLoginUrl',
              tradingBlockDomain
            )}" target="_blank" rel="noopener noreferrer">${tradingBlockDomain}</a>`;
            const logoutLink = GetLogoutUrl(nextState.account.accountNumber);

            dispatcher.notifications.addNew({
              status: 'Error',
              title: `Signed ${
                responseCode === APIAgreementResponseUpdateCode ? 'Account' : 'API'
              } Agreement is Missing (ID: ${responseCode})`,
              message: `We need an additional agreement for your account to use the API and this platform. Please log in to the classic ${legacySiteLoginLink}, visit your Profile, and complete the API agreement checkbox. Then <a href="${logoutLink}">log in</a> again to this platform.`,
              options: {
                global: true,
                overlay: true,
              },
            });
          }
        }

        break;
    }
    return result;
  } catch (err) {
    console.error('notificationMiddleware :: Caught an exception for action ', action, err);
  }
};

const defaultOrderErrorTitle = 'Order placement error';
const handleOrderError = (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof OrderActions.orderErrored>
) => {
  const { Code, Response, error, order } = action.payload;
  const dispatcher = Dispatcher(dispatch);
  const responseDefinition = Code ? ResponseCodes[Code] : undefined;
  const isGenericError = Code === GenericErrorResponseCode;
  const responseMessage = Response ? Response.Message : undefined;

  let notificationTitle = defaultOrderErrorTitle;
  let message = error || '';
  if (responseDefinition) {
    notificationTitle = _.startCase(responseDefinition.name);
    message = _.isString(responseDefinition.description)
      ? responseDefinition.description
      : responseMessage
      ? responseDefinition.description(responseMessage)
      : '';

    if (isGenericError || Code === 98) {
      notificationTitle = defaultOrderErrorTitle;
      message = responseMessage || message;
    }
  }
  // show order errors in modal
  dispatcher.notifications.addOrderNotification({
    status: 'Error',
    message,
    title: notificationTitle,
    order,
    options: { modal: true },
    code: Code,
  });

  return state;
};

const handleOrderUpdates = (state: DataState, dispatch: Dispatch<RootAction>, action: OrderUpdateEventAction) => {
  const { OrderId, OrderStatus, FilledQuantity, OrderQuantity, OrderPrice } = action.payload.order;
  const orderPrice = OrderPrice ? Math.abs(OrderPrice) : undefined;
  const dispatcher = Dispatcher(dispatch);
  const order = orderSelector(state, OrderId);
  const statusIdMap = _.invert(OrderStatuses);

  if (order) {
    if (OrderStatus === OrderStatuses.PendingNew) {
      dispatcher.notifications.addOrderNotification({
        order,
        status: 'Success',
        message: `${order.UnderlyingSymbol} order with ${order.Legs.length} leg(s) initiated.`,
        title: 'New order initiated',
      });
    } else if (OrderStatus === OrderStatuses.PendingReplace) {
      const message = `${order.UnderlyingSymbol} order ${
        orderPrice ? `price updated to ${formatNumber(orderPrice, { currency: true })}.` : ''
      }`;
      dispatcher.notifications.addOrderNotification({ order, status: 'Success', message, title: 'Order updated' });
    } else if (OrderStatus === OrderStatuses.New || order.OrderStatus === OrderStatuses.Initiated) {
      const marketOpen = isMarketOpenSelector(state);
      const isAfterHours = !marketOpen;
      if (isAfterHours) {
        if (OrderStatus === OrderStatuses.New || OrderStatus === OrderStatuses.Initiated) {
          dispatcher.notifications.addOrderNotification({
            order,
            status: 'Success',
            message: `Warning: ${order.UnderlyingSymbol} order with ${order.Legs.length} leg(s) is queued for the next open trading session.`,
            title: 'Order created',
          });
        }
      } else {
        dispatcher.notifications.addOrderNotification({
          order,
          status: 'Success',
          message: `${order.UnderlyingSymbol} order with ${order.Legs.length} leg(s) created.`,
          title: 'Order created',
        });
      }
    } else if (OrderStatus === OrderStatuses.PartiallyFilled || OrderStatus === OrderStatuses.Filled) {
      const isPartial = order.OrderStatus === OrderStatuses.PartiallyFilled;
      const isPartiallyCancelled = order.OrderStatus === OrderStatuses.PartiallyCancelled;
      const action = isPartial ? 'partially filled' : isPartiallyCancelled ? 'partially cancelled' : 'filled';
      const message = isPartial
        ? `${order.UnderlyingSymbol} order id ${order.OrderId} filled ${FilledQuantity} of ${OrderQuantity} quantity.`
        : `${order.UnderlyingSymbol} order id ${order.OrderId} filled fully.`;
      dispatcher.notifications.addOrderNotification({ order, status: 'Success', message, title: `Order ${action}` });
    } else {
      const newStatus = OrderStatus ? statusIdMap[OrderStatus] : undefined;
      dispatcher.notifications.addOrderNotification({
        order,
        status: 'Info',
        message: `Order updated to ${newStatus}`,
        title: `Order status changed`,
      });
    }
  }

  return state;
};
