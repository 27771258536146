import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useMemo, useCallback, useState } from 'react';
import _ from 'lodash';
export function useInput(defaultValue, evHandle) {
  var _useState = useState(defaultValue),
      _useState2 = _slicedToArray(_useState, 2),
      value = _useState2[0],
      setValue = _useState2[1];

  var eventHandlers = evHandle || {};
  var onKeyDown = useCallback(function (e) {
    if (e.keyCode === 13 && evHandle && evHandle.onEnter) {
      evHandle.onEnter(value);
    }
  }, [evHandle, value]);
  var onChange = useCallback(function (e, val) {
    var targetValue = _.get(e.target, 'value', undefined);

    setValue(targetValue);

    if (eventHandlers.onChange) {
      eventHandlers.onChange(e, targetValue);
    }
  }, [setValue, eventHandlers]);
  return useMemo(function () {
    return [value, setValue, {
      onChange: onChange,
      onKeyDown: onKeyDown
    }];
  }, [value, setValue, onChange, onKeyDown]);
}