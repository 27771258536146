/* eslint-disable react/display-name */
import React, { useMemo, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Row, Cell } from 'react-table';
import {
  BoldNumber,
  CellWithExpandedToggleProps,
  Column,
  EmptyBlockContent,
  FrozenHeaderTable,
  Loading,
  useTableColumnWidths,
} from '@tradingblock/components';
import { OrderLeg, Order, AssetType, OrderStatuses, OrderActivity, SizeType } from '@tradingblock/types';
import {
  toAssetSymbol,
  AssetTypeToSymbolType,
  OrderStatusCategories,
  getLocalDateFromTimezone,
} from '@tradingblock/api';
import { BlockAction, useBlockId, useBlockMetadata } from '../../../components/blocks/BlockState';
import { dashboardIdSelector, isMarketOpenSelector, useStateSelector } from '../../../data/global/dataSelectors';
import { areOrdersLoaded, getOrdersByBlockId } from '../../../data/global/selectors/orderSelector';
import { isBetween, isToday } from '../../../utilities/date';
import {
  OrderStatusToString,
  detailFormattor,
  isCancelledOrderStatus,
  isNewOrPartialOrderStatus,
  isFilledOrderStatus,
  formatOrderLeg,
  formatOrderPrice,
} from '../util';
import { ActivityOrderStatusWrapper } from '../ActivityOrderStatusWrapper';
import { useActiveBlockTab } from '../../../components/blocks/BlockAccessors';
import { useDispatcher } from '../../../data/global/hooks';
import {
  CancelOrderButton,
  ReplaceOrderButton,
  OrderActionButton,
} from '../../../components/blocks/actions/OrderActionButtons';
import { useBlockGroupInfo } from '../../useGroupState';
import { useOrderCopyAction } from '../../../components/blocks/hooks/useOrderBlock';
import { OrderActivityStatus } from './orders/OrderActivityStatus';
import { useActivityTimeframeValues } from '../data/useActivityTimeframe';
import { PositionDropdown } from '../../Positions/components/PositionDropdown';
import ActivityOrderEventsSubRow from './ActivityOrderEventsSubRow';
import { useActivityBlockData, useActivitySettingsValue } from '../useActivitySettings';
import { Actions } from '../../../data/global/actions';
import { calculateEstCommission } from '../../Order/components/Preview/EstCommissionPreview';
import { account } from '../../../data/global/selectors/accountSelectors';
import { useSelector } from 'react-redux';
import { blockSettings } from '../../../data/global/selectors/settingSelectors';

export const ActivityOrdersTable: React.FC<{
  tabType: 'orders' | 'trades';
  onReplace: (order: Order<OrderLeg>) => void;
  onCancelOrder: (order: Order<OrderLeg>) => void;
}> = ({ tabType, onReplace, onCancelOrder }) => {
  const { groupId } = useBlockGroupInfo();
  const blockId = useBlockId();

  // Get the start and endDate
  const { startDate, endDate } = useActivityTimeframeValues();
  // get the orders
  const orderHistory = useStateSelector(getOrdersByBlockId(blockId));
  const accountNumber = useStateSelector(state => state.account.accountNumber);
  const tab = useActiveBlockTab();
  const subAccounts = useStateSelector(account.subAccounts);
  const shouldBeHighlighted = (row: Row<Order<OrderLeg>>): string => {
    return isNewOrPartialOrderStatus(row.original.OrderStatus) ? 'row-link' : '';
  };
  const { workingOrdersOnly } = useActivitySettingsValue();
  const { dispatcher, dispatch } = useDispatcher();
  const dashboardId = useStateSelector(dashboardIdSelector);
  const { type } = useBlockMetadata();
  const settings = useStateSelector(s => blockSettings.position(s));

  const resetWorkingOrders = () => {
    const settingsSaveData = {
      dashboardId: dashboardId || '',
      blockId,
      blockType: type,
      blockSettings: {
        ...settings,
        workingOrdersOnly: false,
      },
    };
    dispatch(BlockAction.setSettings(settingsSaveData.blockSettings));
    dispatcher.settings.block.setBlockSetting(settingsSaveData);
  };

  useEffect(() => {
    if (tabType) resetWorkingOrders();
    return () => {
      resetWorkingOrders();
    };
  }, [tabType]);

  const isLoaded = useStateSelector(areOrdersLoaded);
  const timeFilteredMessages = useMemo(() => {
    return _.filter(orderHistory, (n: OrderActivity) => {
      if (
        workingOrdersOnly ||
        (startDate as Date) === (endDate as Date) ||
        startDate === undefined ||
        endDate === undefined
      ) {
        return true;
      }
      return isBetween(n.Date, startDate, endDate);
    })
      .map(d => ({ ...d, display: { trClass: '' } }))
      .sort((a, b) => dayjs(b.Date).valueOf() - dayjs(a.Date).valueOf());
    // add display options to each row of data
  }, [endDate, orderHistory, startDate, workingOrdersOnly, tabType]);

  const data = useMemo(() => {
    return _.uniqBy(timeFilteredMessages, v => v.OrderId);
  }, [startDate, endDate, timeFilteredMessages, tab]);

  const commissionDetails = useStateSelector(s =>
    s.account.accountDetails ? s.account.accountDetails.commissions : undefined
  );
  const { pageSize, pageIndex, total } = useActivityBlockData();
  const isFetchingBlockOrders = useStateSelector(
    s => s.account.ordersUIState[blockId] && s.account.ordersUIState[blockId].isFetching
  );
  const hasSubAccounts = useSelector(account.hasSubAccounts);
  const initialState = useMemo(() => {
    const numPages = Math.ceil((total || 0) / pageSize);
    // check if the current pageIndex is out of bounds
    // current implementation does not track timeframe/tab for persistence
    // so we need to reset the tableState to default values in order to prevent an errored state
    const isPageIndexOutOfBounds = pageIndex > numPages - 1;
    if (isPageIndexOutOfBounds) {
      return {
        pageSize: 50,
        pageIndex: 0,
        sortBy: [],
        columnOrder: [],
      };
    }
    return {
      pageSize: _.isUndefined(pageSize) ? 50 : pageSize,
      pageIndex: pageIndex || 0,
      sortBy: [],
      columnOrder: [],
    };
  }, [pageSize, pageIndex, total]);

  const pageCount = useMemo(() => {
    if (pageSize) {
      return _.ceil((total || 0) / pageSize) === 0 ? 1 : _.ceil((total || 0) / pageSize);
    }
    return 0;
  }, [total, pageSize]);

  const onPage = useCallback((pageIndex: number) => {
    dispatch(Actions.setOrdersRequestPageIndex({ pageIndex, blockId }));
  }, []);

  const onPageSize = useCallback((pageSize: number) => {
    dispatch(Actions.setOrdersRequestPageSize({ pageSize, blockId }));
  }, []);

  const [tableStateColumnOrder, setTableStateColumnOrder] = useState<string[]>([]);

  const renderSubRow = useCallback(
    (row, tableOptions: { size: SizeType }) => {
      return <ActivityOrderEventsSubRow row={row.original} />;
    },
    [tableStateColumnOrder, isFetchingBlockOrders]
  );

  const getRowKey = useCallback((ord: Order<OrderLeg>) => {
    return _.toString(ord.OrderId || ord.ClientRefId || 'order');
  }, []);

  const getColumnOrder = useCallback(
    (columnOrder: string[]) => {
      setTableStateColumnOrder(columnOrder);
    },
    [setTableStateColumnOrder]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Symbol',
        accessor: (a: Order) => {
          return (a.UnderlyingSymbol as string).toString();
        },
        display: ({ row }: Cell<Order>) =>
          (isCancelledOrderStatus(row.original.OrderStatus) ? 'mute' : '') + ' ' + shouldBeHighlighted(row),
        canSort: true,
        sortType: 'alphanumeric',
        id: 'expander',
        width: 1.5,
        Cell: ({ row }: CellWithExpandedToggleProps<Order>) => {
          return (
            <span {...row.getToggleRowExpandedProps()}>
              <PositionDropdown>{row.original.UnderlyingSymbol}</PositionDropdown>
            </span>
          );
        },
      },
      {
        Header: 'Order ID',
        id: 'OrderID',
        canSort: false,
        accessor: (a: string) => {
          return a;
        },
        Cell: ({ row }: Cell<OrderActivity>) => <>{row.original.OrderId}</>,
      },
      {
        Header: 'Subaccount ID',
        canSort: true,
        sortType: 'alphanumeric',
        hide: !hasSubAccounts,
        accessor: (a: Order) => {
          return a.SubaccountId;
        },
        id: 'Subaccount ID',
        Cell: ({ row }: Cell<Order>) => {
          const subId = row.original.SubaccountId;
          if (subId && subAccounts) {
            const account = subAccounts.find(object => object['Id'] === subId && object['Nickname']);
            return <>{account ? account.Nickname : subId}</>;
          }
          return null;
        },
      },
      {
        Header: 'Order Details',
        width: 3,
        canSort: false,
        accessor: (a: string) => {
          return a;
        },
        display: ({ row }: Cell<Order>) =>
          (isCancelledOrderStatus(row.original.OrderStatus) ? 'mute' : '') + ' ' + shouldBeHighlighted(row),
        id: 'details',
        Cell: ({ row }: Cell<OrderActivity>): JSX.Element[] => {
          const { Legs, OrderStatus, UnderlyingSymbol, activityDate } = row.original;
          const marketOpen = useStateSelector(s => isMarketOpenSelector(s));
          const isAfterHours = !marketOpen;
          const isNewOrInitiatedOrder =
            row.original.OrderStatus === OrderStatuses.New || row.original.OrderStatus === OrderStatuses.Initiated;
          if (UnderlyingSymbol === undefined) {
            return [<>No underlying symbol</>];
          }
          return Legs.map((val, index) => {
            return (
              <React.Fragment key={val.Symbol + '-' + index}>
                <ActivityOrderStatusWrapper status={OrderStatus}>
                  {formatOrderLeg(val, detailFormattor(val, UnderlyingSymbol), row.original)}
                  <br />
                  {isAfterHours && isNewOrInitiatedOrder && (
                    <span className="txt-sm error">
                      <strong>Warning:</strong> Order is queued for the next open trading session.
                    </span>
                  )}
                </ActivityOrderStatusWrapper>
              </React.Fragment>
            );
          });
        },
      },

      {
        Header: 'Order price',
        canSort: true,
        sortType: 'alphanumeric',
        accessor: (a: string) => {
          return a;
        },
        display: ({ row }: Cell<Order>) =>
          (isCancelledOrderStatus(row.original.OrderStatus) ? 'mute' : '') + ' ' + shouldBeHighlighted(row),
        id: 'order-price',
        width: 2.0,
        Cell: ({ row }: Cell<Order>) => {
          const { OrderStatus, UnderlyingSymbol } = row.original;
          if (UnderlyingSymbol === undefined) {
            return <>No underlying symbol</>;
          }
          return (
            <ActivityOrderStatusWrapper status={OrderStatus}>
              {formatOrderPrice(row.original)}
            </ActivityOrderStatusWrapper>
          );
        },
      },
      {
        Header: 'Fill Price',
        accessor: (a: Order) => {
          return a.AverageFillPrice ? a.AverageFillPrice : 0;
        },
        display: ({ row }: Cell<Order>) =>
          (isCancelledOrderStatus(row.original.OrderStatus) ? 'mute' : '') + ' ' + shouldBeHighlighted(row),
        canSort: true,
        sortType: 'alphanumeric',
        id: 'fill-price',
        Cell: ({ row }: Cell<Order>) => {
          const { OrderStatus } = row.original;

          return (
            <ActivityOrderStatusWrapper status={row.original.OrderStatus}>
              {OrderStatus && [OrderStatuses.Filled].includes(OrderStatus) && row.original.AverageFillPrice && (
                <BoldNumber value={Math.abs(row.original.AverageFillPrice)} currency />
              )}
            </ActivityOrderStatusWrapper>
          );
        },
      },
      {
        Header: 'Est. Commission',
        canSort: false,
        id: 'estCommission',
        Cell: ({ row }: Cell<Order>) => {
          const order = row.original;
          let commission: number | undefined = undefined;

          if (commissionDetails && order) {
            let orderAssetQuantities = order.Legs.map(l => ({
              assetType: l.AssetType,
              quantity: l.LegFillQuantity ? l.LegFillQuantity : 0,
            }));
            let estCommission = calculateEstCommission(orderAssetQuantities, commissionDetails);
            commission = estCommission ? estCommission : 0;
          }
          if (commission === undefined) {
            console.warn('There was an issue calculating commission for the position in the activities block');
          }
          return <BoldNumber value={commission} currency />;
        },
      },
      {
        Header: 'Status',
        accessor: (a: Order) => OrderStatusToString(a.OrderStatus),
        display: ({ row }: Cell<Order>) =>
          (isCancelledOrderStatus(row.original.OrderStatus) ? 'mute' : '') + ' ' + shouldBeHighlighted(row),
        canSort: true,
        sortType: 'basic',
        id: 'status',
        Cell: ({ row }: Cell<OrderActivity>) => {
          return <OrderActivityStatus activity={row.original} />;
        },
      },
      {
        Header: 'Updated on',
        width: 1.5,
        accessor: (a: Order) => {
          if (a.Date === undefined) return undefined;
          return getLocalDateFromTimezone(new Date(a.Date)).format('MM/DD/YY h:mm:ss.SSS A');
        },
        display: ({ row }: Cell<Order>) =>
          (isCancelledOrderStatus(row.original.OrderStatus) ? 'mute' : '') + ' ' + shouldBeHighlighted(row),
        canSort: true,
        sortDescFirst: true,
        sortInverted: true,
        id: 'updated_on',
        Cell: ({ row }: Cell<Order>) => {
          const orderDate = row.original.Date;
          if (orderDate) {
            return (
              <ActivityOrderStatusWrapper status={row.original.OrderStatus}>
                {dayjs(orderDate).format(isToday(orderDate) ? 'h:mm:ss.SSS A' : 'MM/DD/YY h:mm:ss.SSS A')}
              </ActivityOrderStatusWrapper>
            );
          }
          return '';
        },
        sortType: 'basic',
      },
      {
        Header: 'Actions',
        width: 1.5,
        accessor: (a: Order) => a,
        id: 'actions',
        Cell: ({ row }: Cell<Order>) => {
          const { UnderlyingSymbol, OrderStatus, Legs } = row.original;
          const underSymb = UnderlyingSymbol || '';
          const assSymbol = toAssetSymbol(underSymb, AssetType.Equity, AssetTypeToSymbolType(AssetType.Equity), {
            name: underSymb,
            underlyingSymbol: underSymb,
            rootSymbol: underSymb,
          });
          const leg = _.map(Legs, l => ({ ...l }));
          const handleTradeOrder = useOrderCopyAction(
            { ...row.original, Legs: leg },
            assSymbol,
            { groupId },
            undefined,
            undefined,
            true
          );
          const isFilledOrCancelled = isCancelledOrderStatus(OrderStatus) || isFilledOrderStatus(OrderStatus);
          const isOpen = _.includes(OrderStatusCategories.Open, OrderStatus);
          let dateExtract;

          if (
            isLoaded &&
            row &&
            row.original &&
            row.original.Legs &&
            row.original.Legs.length > 0 &&
            row.original.Legs[0].Symbol
          ) {
            dateExtract = row.original.Legs[0].Symbol.split(' ')[1];
          }
          const encodingExtract = dateExtract && dateExtract.substring(0, 6);
          const encodingExpirationFormatted = encodingExtract
            ? encodingExtract.substring(2, 4) +
              '/' +
              encodingExtract.substring(4) +
              '/' +
              encodingExtract.substring(0, 2)
            : '';
          const isExpired =
            dayjs(encodingExpirationFormatted).isBefore(new Date().toDateString()) &&
            row.original.Legs[0].AssetType === 2;
          return (
            <>
              {OrderStatus && (
                <>
                  {OrderStatusCategories.Cancellable.includes(OrderStatus) && (
                    <CancelOrderButton onClick={() => onCancelOrder(row.original)} />
                  )}
                  {OrderStatusCategories.Replaceable.includes(OrderStatus) && (
                    <ReplaceOrderButton onClick={() => onReplace(row.original)} />
                  )}
                  {(tabType === 'trades' || isFilledOrCancelled || isOpen) && !isExpired && (
                    <OrderActionButton onClick={() => handleTradeOrder()} title="Duplicate order" icon="fa-clone" />
                  )}
                </>
              )}
            </>
          );
        },
      },
    ],
    [groupId, tableStateColumnOrder, hasSubAccounts]
  );

  // if the user does not have subaccounts, hide the subaccount column
  if (!hasSubAccounts && _.find(columns, { id: 'Subaccount ID' })) {
    columns.splice(2, 1);
  }

  const columnWidths = useTableColumnWidths(columns as Column<object>[]);

  return isFetchingBlockOrders ? (
    <Loading />
  ) : data.length ? (
    <FrozenHeaderTable
      tableKey="orders"
      columns={columns}
      renderSubRow={renderSubRow}
      numColumns={columns.length}
      columnWidths={columnWidths}
      //@ts-ignore
      getColumnOrder={getColumnOrder}
      data={data}
      initialState={initialState}
      loaded={isLoaded}
      sortable
      rowClass={shouldBeHighlighted}
      getRowKey={getRowKey}
      hasPagination
      manualPagination
      pageCount={pageCount}
      onPage={onPage}
      onPageSize={onPageSize}
      frozenHeaders={true}
    />
  ) : (
    <EmptyBlockContent>No data for current timeframe.</EmptyBlockContent>
  );
};
