import createCachedSelector from 're-reselect';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { GetOCCSymbol, toAssetSymbol, orderLegOccSymbol } from '@tradingblock/api';
import { AssetType, AssetSymbol, OrderLegDTOState, Legs } from '@tradingblock/types';
import { createDeepSelector } from '../../../data/global/dataSelectors';
import { createDeepArraySelector } from '../../../data/selectors';
import { OrderState } from './OrderState';

const rootSel = (state: OrderState) => state;
const orderLegSelector = (state: OrderState) => state.legs;
const symbolSelector = (state: OrderState) => (state.symbol ? state.symbol.symbol : '');
const assetSymbolSelector = (state: OrderState) => (state.symbol ? state.symbol : undefined);
const idSelector = (state: OrderState, id: string) => id;

type LegSelector<T> = (leg: Partial<OrderLegDTOState>) => T;

function legSelectorCacheFactory<T>(sel: LegSelector<T>) {
  return createSelector(
    rootSel,
    (state: OrderState, id: string) => id,
    (state: OrderState, id: string) => {
      const leg = state.legs[id];
      return sel(leg);
    }
  );
}

// function legSelFactory<T>(sel: LegSelector<T>) {
//   return createSelector(
//     rootSel,
//     (state: OrderState, id: string) => state.legs[id],
//     (state: OrderState, lState: Partial<OrderLegDTO>) => sel(lState)
//   );
// }
// const legSel = {
//   Action: legSelFactory(l => l.Action),
//   AssetType: legSelFactory(l => l.AssetType),
//   Expiration: legSelFactory(l => l.Expiration),
//   PositionEffect: legSelFactory(l => l.PositionEffect),
//   OptionType: legSelFactory(l => l.OptionType),
//   SpreadRatio: legSelFactory(l => l.SpreadRatio),
//   Strike: legSelFactory(l => l.Strike),
// };

const legPropSelector = {
  Action: legSelectorCacheFactory(l => l.Action),
  AssetType: legSelectorCacheFactory(l => l.AssetType),
  Expiration: legSelectorCacheFactory(l => l.Expiration),
  PositionEffect: legSelectorCacheFactory(l => l.PositionEffect),
  OptionType: legSelectorCacheFactory(l => l.OptionType),
  SpreadRatio: legSelectorCacheFactory(l => (l.SpreadRatio === undefined ? 1 : l.SpreadRatio)),
  Strike: legSelectorCacheFactory(l => {
    if (l.Strike === null) {
      return undefined;
    }
    return l.Strike;
  }),
  DefaultStrike: legSelectorCacheFactory(l => l.DefaultStrikeIfValid),
};

export const SymbolOccBasicSelector = createCachedSelector(
  (state: OrderState, id: string) => _.find(state.legs, l => l.Id === id),
  assetSymbolSelector,
  idSelector,
  (leg: OrderLegDTOState | undefined, symbol) => {
    if (!leg || symbol === undefined) {
      return undefined;
    } else {
      const { Expiration, OptionType, Strike } = leg;
      let symbolOcc = undefined;
      if (!OptionType || leg.AssetType === AssetType.Equity) {
        symbolOcc = symbol.symbol;
      } else if (OptionType && Expiration && Strike) {
        symbolOcc = GetOCCSymbol({
          underlyingSymbol: symbol.symbol,
          symbolType: symbol.type,
          expiration: Expiration,
          optionType: OptionType,
          strike: Strike,
        });
      } else {
        symbolOcc = undefined;
      }
      return symbolOcc;
    }
  }
)((state, id) => {
  const symbol = state.symbol ? state.symbol.symbol : undefined;
  const leg = _.find(state.legs, l => l.Id === id);
  if (!leg) {
    return `${id}`;
  } else {
    const { Expiration, Strike } = leg;
    return `${id}:${symbol}:${Expiration}:${Strike}`;
  }
});

const orderLegsWithSymbols = (legs: Legs, symbol?: AssetSymbol): { [id: string]: AssetSymbol } => {
  if (symbol === undefined) {
    return {};
  }
  const legSymbolMap = _.reduce(
    legs,
    (all: { [id: string]: AssetSymbol }, leg) => {
      const { Expiration, OptionType, Strike } = leg;

      let symbolOcc: AssetSymbol | undefined = undefined;
      if (OptionType === undefined || leg.AssetType === AssetType.Equity) {
        symbolOcc = symbol;
      } else if (OptionType && Expiration && Strike) {
        const optionSymbol = GetOCCSymbol({
          underlyingSymbol: symbol.symbol,
          symbolType: symbol.type,
          expiration: Expiration,
          optionType: OptionType,
          strike: Strike,
        });
        symbolOcc = toAssetSymbol(optionSymbol, leg.AssetType, symbol.type, symbol);
      } else {
        symbolOcc = undefined;
      }

      if (symbolOcc === undefined) {
        return all;
      }
      return {
        ...all,
        [leg.Id]: symbolOcc,
      };
    },
    {}
  );
  return legSymbolMap;
};

export const OrderPositions = createCachedSelector(
  (state: OrderState, blockId: string) => state.positions,
  positions => positions
)((__, blockId) => blockId);

export const OrderSymbolsSelector = createCachedSelector(
  (state: OrderState, blockId: string) => state.legs,
  assetSymbolSelector,
  orderLegsWithSymbols
)((s: OrderState, blockId: string) => blockId);

export const OrderSymbolList = createCachedSelector(
  (state: OrderState, blockId: string) => state.legs,
  assetSymbolSelector,

  (legs, symbol) => {
    const legSymbolMap = orderLegsWithSymbols(legs, symbol);
    return _(legSymbolMap)
      .values()
      .uniqBy(s => s.symbol)
      .sortBy(s => s.symbol)
      .value();
  }
)((__, blockId) => blockId, { selectorCreator: createDeepArraySelector });

export const OrderSymbolListValues = createCachedSelector(
  (state: OrderState, blockId: string) => state.legs,
  assetSymbolSelector,

  (legs, symbol) => {
    return _(orderLegsWithSymbols(legs, symbol))
      .values()
      .map(l => l.symbol)
      .uniq()
      .sort()
      .push(symbol ? symbol.rootSymbol : '')
      .value();
  }
)((__, blockId) => `SymbolListValues-${blockId}`);

export const SymbolOccSelector = createCachedSelector(
  (state: OrderState) => state.legs,
  assetSymbolSelector,
  (state: OrderState, props: { id: string }) => props.id,

  (legs: Legs, symbol: AssetSymbol | undefined, id: string) => {
    const leg = _.find(legs, l => l.Id === id);

    if (!leg || symbol === undefined) {
      return undefined;
    } else {
      return orderLegOccSymbol(symbol, leg);
    }
  }
)((state, props) => `${props.id}:${state.symbol ? state.symbol.symbol : undefined}`);

export const LegSelector = {
  ...legPropSelector,

  LegsWithOCC: createDeepSelector(
    (state: OrderState) => state,
    orderLegSelector,
    symbolSelector,
    (state: OrderState, legs: Legs, symbol: string) =>
      _.map(legs, (leg, key: string) => {
        const occ = SymbolOccSelector(state, { id: leg.Id || key });
        return {
          ...leg,
          occSymbol: occ,
        };
      })
  ),
  LegsByOCC: createCachedSelector(
    (state: OrderState, blockId: string) => state,
    orderLegSelector,
    (state: OrderState, legs: Legs): { [key: string]: OrderLegDTOState } =>
      _.reduce(
        legs,
        (acc: { [key: string]: OrderLegDTOState }, leg, key: string) => {
          const occ = SymbolOccSelector(state, { id: leg.Id || key });
          if (occ) {
            return {
              ...acc,
              [occ]: leg,
            };
          } else {
            return acc;
          }
        },
        {}
      )
  )((state: OrderState, blockId: string) => blockId, { selectorCreator: createDeepSelector }),
  SymbolAsOCC: SymbolOccSelector,
};
