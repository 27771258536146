import { getMinBlockSize, getDefaultBlockSize } from '@tradingblock/api';
import { newId, getBlockTypeName } from '@tradingblock/components';
import _ from 'lodash';
import {
  BlockType,
  Layout,
  TabOptions,
  BlockConfiguration,
  BlockGridLayout,
  LayoutWithType,
  SizeType,
} from '@tradingblock/types';
import { IBlockProps } from '../components/blocks/Block';
import { OrderBlockState } from '../blocks/Order/state/OrderState';
import { InfoBlockState } from '../blocks/Info/state/InfoState';

export const LinkableBlockTypes = [
  BlockType.Favorites,
  BlockType.SubAccountManagement,
  BlockType.OptionChain,
  BlockType.Order,
  BlockType.PriceChart,
  BlockType.Quote,
  BlockType.Positions,
  BlockType.Activity,
  BlockType.Info,
];

export const RefreshableBlockTypes = [
  BlockType.OptionChain,
  BlockType.PriceChart,
  BlockType.Account,
  BlockType.Positions,
  BlockType.Activity,
  BlockType.SubAccountManagement,
];

export function getBlockProps<T>(
  type: BlockType,
  header?: JSX.Element,
  tabs?: TabOptions[],
  settings?: T
): IBlockProps {
  const isLinkable = isBlockLinkable(type);
  const className = `block-${_.kebabCase(type)}`;
  const isRefreshable = isBlockRefreshable(type);
  return {
    type,
    isLinkable,
    className,
    header: header || (tabs ? undefined : getBlockTypeName(type)),
    tabs,
    settings,
    isRefreshable,
  };
}

export const isBlockRefreshable = (type: BlockType) => RefreshableBlockTypes.includes(type);

export const isBlockLinkable = (type: BlockType) => LinkableBlockTypes.includes(type);

export const canBlockBeLinked = (type: BlockType, groupBlocks: BlockConfiguration[]) => {
  if (!isBlockLinkable(type)) {
    return false;
  }

  // prevent multiple order blocks in same group
  if (type === BlockType.Order && _.some(groupBlocks, b => b.type === BlockType.Order)) {
    return false;
  }
  return true;
};

export const canBlockBeAddedToGroup = (block: BlockConfiguration, groupId: string, blocks: BlockConfiguration[]) => {
  // console.warn('canBlockBeAddedToGroup :: checking block', block.type, 'against blocks in group', groupId, ': ', _.map(blocks, b => _.values(_.pick(b, ['groupId']))[0]));

  // cannot add order block to group that already has one
  if (block.type === BlockType.Order && _.some(blocks, b => b.groupId === groupId && b.type === BlockType.Order)) {
    return false;
  }
  return true;
};

export const getLayoutWithMinSize = (
  layout: LayoutWithType,
  windowWidth: number,
  gridColumns: number,
  minBlockWidth = windowWidth / 3,
  breakpoint: SizeType
) => {
  // Calculate unit width (size of a single column)
  const unitWidth = windowWidth / gridColumns;

  // Get minimum size for the block type
  const minSize = getMinBlockSize(layout.type, gridColumns, breakpoint);
  // Determine the minimum width considering both the predefined minimum and the minimum block width
  const minW = Math.max(minSize.w, Math.ceil(minBlockWidth / unitWidth));

  // Ensure the width is not less than the minimum width
  let w = Math.max(layout.w, minW);

  // If gridColumns limit is exceeded, adjust w to gridColumns
  if (w > gridColumns) {
    w = gridColumns;
  }

  // Calculate height ensuring it's not less than the minimum height
  const minH = minSize.h;
  let h = Math.max(layout.h, minH);
  // Return the new layout object, omitting 'type' and adding minW and minH
  return {
    ...layout,
    w,
    h,
    minW,
    minH,
  };
};

export const generateNewBlock = (type: BlockType, data: any = {}): BlockConfiguration => {
  // NOTE: by NOT including layouts, closest fit layout will be generated by reducer in addBlock action
  return {
    type,
    blockId: newId(),
    groupId: newId(),
    data,
  };
};

export const generateBlockClosestFitLayout = (
  type: BlockType,
  layouts: Layout[],
  {
    breakpoint,
    columns,
    gridColumnsLarge,
    gridColumnsMedium,
    gridColumnsSmall,
  }: Pick<BlockGridLayout, 'breakpoint' | 'columns' | 'gridColumnsLarge' | 'gridColumnsMedium' | 'gridColumnsSmall'>
): LayoutWithType => {
  // determine gridColumns based on breakpoint
  // (i.e. if breakpoint is 'lg', use gridColumnsLarge, etc.)
  let gridColumns: number;
  switch (breakpoint) {
    case SizeType.lg:
      gridColumns = gridColumnsLarge;
      break;
    case SizeType.md:
      gridColumns = gridColumnsMedium;
      break;
    case SizeType.sm:
    case SizeType.xs:
    case SizeType.xxs:
      gridColumns = gridColumnsSmall;
      break;
    default:
      gridColumns = columns;
      break;
  }

  // 2D array of x,y grid positions occupied by current blocks
  const layoutsArray = generate2DArrayOfBlockLayouts(layouts, { breakpoint, columns: gridColumns });
  // default size for new block
  const size = getDefaultBlockSize(type, gridColumns, breakpoint);
  // 2D array of new block size
  const blockSizeArray = generate2DArray({ x: size.w, y: size.h });
  // default new position to next row below all blocks
  const defaultPosition = {
    x: 0,
    y: (layoutsArray.length && layoutsArray[0].length) || 0,
  };
  // for each x,y in 2D layout array, check if new block would fit if top left were placed at x,y
  //  loop over y axis first, then x (to search for best fit row by row, instead of column by column)
  let position: { x: number; y: number } | null = null;
  for (let y = 0; y < layoutsArray[0].length; y++) {
    for (let x = 0; x < layoutsArray.length; x++) {
      // only check x,y if not already taken by existing block and new block will fit horizontally
      if (!layoutsArray[x][y] && x + blockSizeArray.length <= layoutsArray.length) {
        // block fits if every x,y layout position is NOT already taken (false value in 2D array)
        if (
          _.every(blockSizeArray, (col: boolean[], bx) => _.every(col, (_val, by) => !layoutsArray[x + bx][y + by]))
        ) {
          position = { x, y };
          break;
        }
      }
    }
    if (position) {
      break;
    }
  }

  return {
    ...(position || defaultPosition),
    ...size,
    type,
  };
};

// returns 2D boolean array indicating x,y positions in block grid occupied by existing blocks
const generate2DArrayOfBlockLayouts = (
  layouts: Layout[],
  { breakpoint, columns }: Pick<BlockGridLayout, 'breakpoint' | 'columns'>
): boolean[][] => {
  // calculate max height needed for array
  const maxY = _.max(_.map(layouts, l => (l ? l.y + l.h : 0))) || 1;
  // fill array with defaults (all false values)
  const layoutsArray = generate2DArray({ x: columns, y: maxY });

  // loop through block layouts to mark every x,y point in grid that is occupied
  return _.reduce(
    layouts,
    (acc: boolean[][], layout) => {
      if (layout) {
        for (let x = layout.x; x < layout.x + layout.w; x++) {
          for (let y = layout.y; y < layout.y + layout.h; y++) {
            // mark x,y point in layout array as occupied (true)
            //  NOTE: can't use multiple indices to set (e.g. "acc[x][y] = ..."), will actually set for ALL x arrays!
            acc[x] = Object.assign([...(acc[x] !== undefined ? acc[x] : [])], { [y]: true });
          }
        }
      }
      return acc;
    },
    layoutsArray
  );
};

// returns 2D boolean array of dimensions x,y with default (false) values
const generate2DArray = ({ x, y }: { x: number; y: number }) => _.fill(new Array(x), _.fill(new Array(y), false));

export function isOrderBlock<T extends BlockConfiguration>(block: T | OrderBlockState): block is OrderBlockState {
  return block.type === BlockType.Order;
}

export function isChartBlock<T extends BlockConfiguration>(block: T | OrderBlockState): block is OrderBlockState {
  return block.type === BlockType.PriceChart;
}

export function isInfoBlock<T extends BlockConfiguration>(block: T | InfoBlockState): block is InfoBlockState {
  return block.type === BlockType.Info;
}

/**
 * Corrects an invalid layout item by adjusting the width and height to the minimum required size. and adjusting the x and y coordinates to maintain a two column layout.
 * @param {LayoutWithType} layout - The layout item to correct.
 * @param {number} columns - The number of columns in the grid.
 * @param {SizeType} breakpoint - The current screen size breakpoint.
 * @returns An object containing the corrected layout item.
 * @remarks This function is used to correct invalid layouts when the number of columns changes.
 * @example
 * Returns { x: 0, y: 0, w: 2, h: 2, minW: 1, minH: 2, type: 'Account' }
 * correctInvalidLayoutItem({ x: 0, y: 0, w: 3, h: 5, minW: 1, minH: 5, type: 'Account' }, 2, 'lg');
 */
export const correctInvalidLayoutItem = (
  layout: LayoutWithType,
  index: number,
  columns: number,
  breakpoint: SizeType
) => {
  const type = layout.type as BlockType;
  const { h, w, minH, minW } = getDefaultBlockSize(type, columns, breakpoint, true);

  // Adjust the x coordinate to maintain a two column layout
  const x = index % 2 === 0 ? 0 : Math.floor(columns / 2);

  const y = Math.floor(index / 2) * h;
  return { ...layout, x, y, w, h, minW, minH };
};

/**
 * Examines a layout item and determines if it is valid. A layout item is considered valid if it is an object, has an x and y coordinate that are both numbers, and has a width and height that are both numbers greater than 0 and an i property that is a string.
 * @param {Layout} layout - The layout item to examine.
 * @returns A boolean indicating whether the layout item is valid.
 * @example
 * Returns true
 * isLayoutItemValid({ x: 0, y: 0, w: 2, h: 2, i: '1' });
 * @example
 * Returns false
 * isLayoutItemValid({ x: 0, y: 0, w: 2, h: 2, i: 1 });
 */
export const isLayoutItemValid = (layout: Layout) => {
  return (
    _.isObject(layout) &&
    _.isNumber(layout.x) &&
    _.isNumber(layout.y) &&
    _.isNumber(layout.w) &&
    _.isNumber(layout.h) &&
    layout.w > 0 &&
    layout.h > 0 &&
    _.isString(layout.i)
  );
};
