import React, { useCallback } from 'react';
import _, { isError } from 'lodash';
import { useDispatch } from 'react-redux';
import { Formik, Field } from 'formik';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { FormGroup, TextboxField, Modal, SelectDropdownField } from '@tradingblock/components';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { AccountManagementDataActions } from '../../../data/global/actions/AccountManagementActions';
import {
  AllYearsEmployed,
  EmploymentTypes,
  AllStates,
  EmployInfoUpdateValueProps,
  getGroupedCountryOptions,
  Country,
  EmploymentType,
} from '@tradingblock/types';
import { getRequiredError, USPSErrors, uspsVerification } from './Validation';

export const EmployInfoUpdate: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
  accountHolderIndex: number;
}> = ({ show, toggleModal, accountHolderIndex }) => {
  const accountId = useStateSelector(s => s.accounts.account && s.accounts.account.AccountId);
  const dispatch = useDispatch();
  const { accountHolders } = useStateSelector(s => s.accountManagement.accountManagementDetails.details);
  const user = accountHolders[accountHolderIndex];
  const initialEmployInfoUpdateValues: EmployInfoUpdateValueProps = {
    employmentType: user ? user.employmentType : '',
    employer: user ? user.employer : '',
    jobPosition: user ? user.jobPosition : '',
    yearsEmployed: user ? user.yearsEmployed : '',
    employerAddress: {
      address1: user ? user.address.address1 : '',
      address2: user ? user.address.address2 : '',
      country: user ? user.address.country : '',
      state: user ? user.address.state : '',
      city: user ? user.address.city : '',
      postalCode: user ? user.address.postalCode : '',
    },
  };

  // TODO: Review localStorage usage
  const onValidate = (values: EmployInfoUpdateValueProps) => {
    const { employmentType, employer, jobPosition, yearsEmployed, employerAddress } = values;
    const { address1, country, state, city } = employerAddress;
    let uspsResponse;
    let isValidAddress;
    const stateOrPostalCodeRequired = employerAddress.country === Country.UnitedStatesOfAmerica;

    const uspsResponseFromStorage = localStorage.getItem(`employerAddress-${accountHolderIndex}`);

    if (!employmentType) {
      return { employmentType: getRequiredError('employmentType') };
    }

    if (employmentType && employmentType !== EmploymentType.Employed) {
      return {};
    }

    if (uspsResponseFromStorage && uspsResponseFromStorage !== 'undefined') {
      uspsResponse = JSON.parse(uspsResponseFromStorage);
      const { city, state, zip5, error } = uspsResponse;
      const formattedCityCapitalized = _.capitalize(city);
      const startCaseCity = _.startCase(formattedCityCapitalized);
      const isCityEqual = _.toLower(startCaseCity) === _.toLower(employerAddress.city);
      const isZipEqual = zip5 === employerAddress.postalCode;
      const isStateEqual = state === employerAddress.state;
      const isAddressCorrect = !error && isCityEqual && isZipEqual && isStateEqual;

      isAddressCorrect ? (isValidAddress = true) : (isValidAddress = false);
    }

    if (address1 && city && state && country === Country.UnitedStatesOfAmerica && !isValidAddress) {
      const getUSPS = async () => {
        await new Promise(resolve => setTimeout(resolve, 100));
        return await uspsVerification(employerAddress);
      };

      getUSPS().then(result => {
        localStorage.setItem(`employerAddress-${accountHolderIndex}`, JSON.stringify(result));
      });
    }

    if (uspsResponse) {
      const { city, state, zip5, error } = uspsResponse;

      if (
        error &&
        employerAddress.address1 &&
        employerAddress.city &&
        employerAddress.state &&
        employerAddress.postalCode
      ) {
        return {
          [`postalCode`]: `${error.description.trim()} Please review the entered address.`,
          [`city`]: `${error.description.trim()} Please review the entered address.`,
          [`state`]: `${error.description.trim()} Please review the entered address.`,
          [`address1`]: `${error.description.trim()} Please review the entered address.`,
        };
      }

      if (!employerAddress.address1 || !employerAddress.city) {
        localStorage.setItem(`employerAddress-${accountHolderIndex}`, 'undefined');
      }

      if (city && state && zip5) {
        const formattedCityCapitalized = _.capitalize(city);
        const startCaseCity = _.startCase(formattedCityCapitalized);
        const isCityEqual = _.toLower(startCaseCity) === _.toLower(employerAddress.city);
        const isZipEqual = zip5 === employerAddress.postalCode;
        const isStateEqual = state === employerAddress.state;

        // USPS + Required Verification Errors
        const errors: USPSErrors = {
          ['employerAddress.country']: getRequiredError(employerAddress.country),
          ['employerAddress.address1']: getRequiredError(employerAddress.address1),
          ['employerAddress.city']:
            getRequiredError(employerAddress.city) || !isCityEqual
              ? `Please verify the city. USPS Provided City: ${startCaseCity}`
              : undefined,
          ['employerAddress.state']:
            getRequiredError(stateOrPostalCodeRequired ? employerAddress.state : true) || !isStateEqual
              ? `Please verify the state. USPS Provided State: ${state}`
              : undefined,
          ['employerAddress.postalCode']:
            getRequiredError(stateOrPostalCodeRequired ? employerAddress.postalCode : true) || !isZipEqual
              ? `Please verify the zip code. USPS Provided Zip Code: ${zip5}`
              : undefined,
          [`employmentType`]: getRequiredError(employmentType),
          [`employer`]: getRequiredError(employer),
          [`jobPosition`]: getRequiredError(jobPosition),
          [`yearsEmployed`]: getRequiredError(yearsEmployed),
        };

        Object.keys(errors).forEach(key => (errors[key] === undefined ? delete errors[key] : {}));

        return errors;
      }
    }

    return {
      [`employmentType`]: getRequiredError(employmentType),
      [`employer`]: getRequiredError(employer),
      [`jobPosition`]: getRequiredError(jobPosition),
      [`yearsEmployed`]: getRequiredError(yearsEmployed),
      [`employerAddress.address1`]: getRequiredError(address1),
      [`employerAddress.city`]: getRequiredError(city),
      [`employerAddress.state`]: getRequiredError(state),
      [`employerAddress.country`]: getRequiredError(country),
      [`employerAddress.postalCode`]: getRequiredError(stateOrPostalCodeRequired ? employerAddress.postalCode : true),
    };
  };

  const onSubmit = useCallback(
    (values, { resetForm }) => {
      const { employmentType, employer, jobPosition, yearsEmployed, employerAddress } = values;
      const { address1, address2, country, state, city, postalCode } = employerAddress;
      let updateValues = {};

      switch (employmentType) {
        case 'Employed':
          updateValues = {
            item: 'Employment',
            accountHolder: {
              employmentType,
              employer,
              jobPosition,
              yearsEmployed,
              employerAddress: {
                address1,
                address2,
                country,
                state,
                city,
                postalCode,
              },
            },
            holder: accountHolderIndex,
          };
          break;
        case 'Student':
        case 'Unemployed':
        case 'Retired':
          updateValues = {
            item: 'Employment',
            accountHolder: {
              employmentType,
              employer: '',
              jobPosition: '',
              yearsEmployed: '',
              employerAddress: {
                address1: '',
                address2: '',
                country: 'USA',
                state: 'IL',
                city: '',
                postalCode: 99999,
              },
            },
            holder: accountHolderIndex,
          };
          break;
        default:
          break;
      }

      dispatch(
        AccountManagementDataActions.requestMakeAccountUpdate({
          accountId: accountId,
          request: updateValues,
        })
      );

      resetForm();
      toggleModal();

      localStorage.setItem(`employerAddress-${accountHolderIndex}`, 'undefined');
    },
    [toggleModal, accountId, accountHolderIndex, dispatch]
  );

  return (
    <Formik initialValues={initialEmployInfoUpdateValues} onSubmit={onSubmit} validate={onValidate}>
      {({ values, handleSubmit, resetForm }) => (
        <Modal setShow={() => toggleModal()} show={show}>
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Employment Information Update</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            <form id="employmentModal" onSubmit={handleSubmit} className="employmentModal">
              <FormGroup>
                <Field
                  component={SelectDropdownField}
                  isSearchable={false}
                  id="employmentType"
                  options={EmploymentTypes}
                  label="Employment Type"
                />
              </FormGroup>
              <p className="txt-sm mute">Please provide your employment status and any applicable information.</p>
              {values.employmentType === 'Employed' && (
                <>
                  <label>Employer Address</label>
                  <FormGroup>
                    <Field
                      component={SelectDropdownField}
                      name={'country'}
                      id={'employerAddress.country'}
                      label="Country"
                      options={getGroupedCountryOptions()}
                      optionsDefaultLabelId="country"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      component={TextboxField}
                      name={'address1'}
                      id={'employerAddress.address1'}
                      label="Address 1"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      component={TextboxField}
                      name={'address2'}
                      id={'employerAddress.address2'}
                      label="Address 2"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field component={TextboxField} name={'city'} id={'employerAddress.city'} label="City" />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      component={SelectDropdownField}
                      name={'state'}
                      id={'employerAddress.state'}
                      label="State"
                      options={AllStates}
                      optionsDefaultLabelId="state"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      component={TextboxField}
                      name={'postalCode'}
                      id={'employerAddress.postalCode'}
                      label="Postal Code"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field component={TextboxField} id="jobPosition" name={'jobPosition'} label="Job Title" />
                  </FormGroup>
                  <label>Employer Info</label>
                  <FormGroup>
                    <Field component={TextboxField} id="employer" name={'employer'} label="Employer Name" />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      component={SelectDropdownField}
                      isSearchable={false}
                      id="yearsEmployed"
                      options={AllYearsEmployed}
                      label="Years Employed"
                    />
                  </FormGroup>
                </>
              )}
            </form>
          </BootstrapModal.Body>
          <BootstrapModal.Footer className="modal-footer-justified">
            <Button
              variant="secondary"
              onClick={() => {
                resetForm();
                toggleModal();
                localStorage.setItem(`employerAddress-${accountHolderIndex}`, 'undefined');
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={(values: any) => handleSubmit(values)}>
              Submit
            </Button>
          </BootstrapModal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
