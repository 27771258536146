import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useBlockActionDispatchAndGenericActions } from '../../components/blocks/BlockState';
import { AdminToolsConstants, useAdminToolsData, AdminToolsDataState, isAdminToolsTab } from './state/AdminToolsState';
import { AdminToolsBlockAction, AdminToolsBlockActions } from './state/AdminToolsBlockAction';
import { AdminAccountManagementIndex } from './AccountManagement/AdminAccountManagementIndex';
import { AlertsTable } from './SystemAlerts/components/AlertsTable';
import { Loading } from '@tradingblock/components';
import { AdminEntitlementsIndex } from './Entitlements/AdminEntitlementsIndex';
import { AdminApplicationsIndex } from './Applications/AdminApplicationsIndex';
import { account, useStateSelector } from '../../data/global/dataSelectors';
import { AdminApplicationActions } from '../../data/global/actions/admin/AdminApplicationActions';
import { AdminAccountManagementSearchActions } from '../../data/global/actions/admin/AccountManagementActions';
import { AdminEntitlementsActions } from '../../data/global/actions/admin/EntitlementsActions';
import { AdminReleaseNotesIndex } from './ReleaseNotes/components/ReleaseNotesAdminIndex';

const { accountManagement, entitlements, alerts, applications, releaseNotes } = AdminToolsConstants.tabs;

export const AdminToolsContent: React.FunctionComponent<{}> = ({ }) => {
  const tab = useAdminToolsData(s => s.tab) || 'accountManagement';
  const isLoaded = useAdminToolsData(s => s.isLoaded);
  const currentApplicationItem = useStateSelector(s => s.private.applications.currentItem.application);
  const currentAccManagementItem = useStateSelector(s => s.private.accountManagement.currentItem.details);
  const currentEntitlementsItem = useStateSelector(s => s.private.entitlements.currentItem.details);
  const dispatch = useDispatch();
  const userLevel = useSelector(account.userLevel);
  const isAdmin = userLevel === 'Admin';

  const [, blockDispatch] = useBlockActionDispatchAndGenericActions<AdminToolsDataState, AdminToolsBlockAction>();
  const setTab = useCallback(
    (ev: string) => {
      if (isAdminToolsTab(ev)) {
        blockDispatch(AdminToolsBlockActions.setTab(ev));
      }
      if (tab === 'applications' && currentApplicationItem) {
        dispatch(AdminApplicationActions.clearAppCurrentItem());
      }
      if (tab === 'accountManagement' && currentAccManagementItem) {
        dispatch(AdminAccountManagementSearchActions.clearCurrentItem());
      }
      if (tab === 'entitlements' && currentEntitlementsItem) {
        dispatch(AdminEntitlementsActions.clearCurrentItem());
      }
    },
    [blockDispatch]
  );

  return (
    <>
      {(!isLoaded || !userLevel) && <Loading />}
      {isLoaded && isAdmin && (
        <div className="nav-tabs-pill">
          <Tabs defaultActiveKey={tab} id="notification-tabs" onSelect={(ev: string) => setTab(ev)}>
            <Tab eventKey={accountManagement} title="Account Management">
              {tab === accountManagement && <AdminAccountManagementIndex />}
            </Tab>
            <Tab eventKey={alerts} title="Alert Templates">
              {tab === alerts && <AlertsTable />}
            </Tab>
            <Tab eventKey={applications} title="Applications">
              {tab === applications && <AdminApplicationsIndex />}
            </Tab>
            <Tab eventKey={entitlements} title="Market Data Entitlements">
              {tab === entitlements && <AdminEntitlementsIndex />}
            </Tab>
            <Tab eventKey={releaseNotes} title="Release Notes">
              {tab === releaseNotes && <AdminReleaseNotesIndex />}
            </Tab>
          </Tabs>
        </div>
      )}
      {isLoaded && !isAdmin && (
        <div className="nav-tabs-pill">
          <Tabs defaultActiveKey={tab} id="notification-tabs" onSelect={(ev: string) => setTab(ev)}>
            <Tab eventKey={accountManagement} title="Account Management">
              {tab === accountManagement && <AdminAccountManagementIndex />}
            </Tab>
            <Tab eventKey={applications} title="Applications">
              {tab === applications && <AdminApplicationsIndex />}
            </Tab>
            <Tab eventKey={entitlements} title="Market Data Entitlements">
              {tab === entitlements && <AdminEntitlementsIndex />}
            </Tab>
          </Tabs>
        </div>
      )}
    </>
  );
};