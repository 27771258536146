import React, { useMemo } from 'react';
import { BlockProps, useWhyDidYouUpdate, getClassNames } from '@tradingblock/components';
import { useBlockMetadata } from '../BlockState';
import { useBboxDimensions } from '../../../blocks/shared/useBboxDimensions';
import { useStateSelector, blocksSelectors } from '../../../data/global/dataSelectors';
import { useBlockGroupSync } from '../useBlockGroupSync';
import { useBlockDimensions } from '../../../blocks/shared/useBlockDimensions';
import { groupLinkedColor } from '../../../data/global/selectors/groupSelectors';

type BlockContentProps = Pick<BlockProps, 'className'>;

export const BlockContentWrapper: React.FunctionComponent<BlockContentProps> = ({ className, children }) => {
  useBlockGroupSync();
  const { blockId, groupId } = useBlockMetadata();
  const [{ bboxWidth, bboxHeight, ...dimensions }, blockElement] = useBboxDimensions<HTMLDivElement>();

  const isBlockHighlighted = useStateSelector(s => blocksSelectors.isHighlighted(s)(blockId));
  const highlightedAt: Date | undefined = useStateSelector(s => blocksSelectors.highlightedAt(s));

  const isLinked = useStateSelector(s => blocksSelectors.isLinked(s)(blockId));
  const highlightGroupId = useStateSelector(s => s.blocks.highlightGroupId);
  const highlightGroupAt = useStateSelector(s => s.blocks.highlightGroupAt);

  const isGroupHighlighted = useMemo(() => groupId === highlightGroupId, [groupId, highlightGroupId]);
  const wasBlockTypeHighlighted = useMemo(() => highlightedAt && highlightGroupAt && highlightedAt > highlightGroupAt, [highlightedAt, highlightGroupAt]);

  const linkedColor = useStateSelector(s => groupLinkedColor(s, groupId || ''));

  const cssClasses = useMemo(() => {
    return getClassNames([
      'block',
      className,
      dimensions.heightClassName,
      dimensions.widthClassName,
      isLinked && isGroupHighlighted && !isBlockHighlighted ? 
        `linked-block ${
          wasBlockTypeHighlighted ? '' : 
          `animated ${linkedColor}`
        }` : 
        undefined, 
      isBlockHighlighted ? 
        'highlighted-block animated' : 
        undefined
    ]);
  }, [className, dimensions.heightClassName, dimensions.widthClassName, isLinked, isGroupHighlighted, isBlockHighlighted, wasBlockTypeHighlighted, linkedColor]);

  useWhyDidYouUpdate(`${blockId}:BlockContentWrapper`, { cssClasses, isGroupHighlighted, blockId, className, isBlockHighlighted, isLinked, highlightGroupId, highlightGroupAt, dimensions });

  return (
    <div className={cssClasses} ref={blockElement}>
      <useBlockDimensions.Provider {...dimensions}>{children}</useBlockDimensions.Provider>
    </div>
  );
};
