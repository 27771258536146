/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import './ReactGridLayout.css';
import './ReactResizable.css';
import _ from 'lodash';
import { SizeType, Layout, Layouts, BlockType } from '@tradingblock/types';
import { Grid, useWhyDidYouUpdate, useAlerts, useWindowSizeContext } from '@tradingblock/components';
import { useDispatcher } from '../../data/global/hooks';
import { useStateSelector, blockIdsAndTypesSelector, layoutSelectors } from '../../data/global/dataSelectors';
import { account } from '../../data/global/selectors/accountSelectors';
import { BlockFactory } from '../blocks/BlockFactory';
import { useStore } from '../../context/Storage';
import { useDashboardSettings } from '../../hooks/useDashboardSettings';
import { GridResizeSync } from './GridResizeSync';
import { correctInvalidLayoutItem, getLayoutWithMinSize } from '../../utilities/block';
import { useAccountSettings } from './hooks/useAccountSettings';

export const DashboardGrid: React.FC<{}> = ({ children }) => {
  const blockInfos = useStateSelector(blockIdsAndTypesSelector);
  useWhyDidYouUpdate('DashboardGrid', { blockInfos });
  useAccountSettings();

  return (
    <>
      <GridNotifications />
      <GridWrapper>
        {blockInfos.map(info => {
          const blockWrapperId = `block-${info.blockId}`;
          return (
            <div data-wrapper-id={blockWrapperId} id={blockWrapperId} key={info.blockId}>
              <BlockFactory
                blockId={info.blockId}
                groupId={info.groupId}
                type={info.blockType}
                wrapperId={blockWrapperId}
                data={info.data}
              />
            </div>
          );
        })}
      </GridWrapper>
    </>
  );
};

const GridNotifications: React.FC = () => {
  const { dispatcher } = useDispatcher();
  const { locked, linkError, linkErroredAt, showTooltip } = useStateSelector(s => s.blocks);
  const notifications = useAlerts();

  useWhyDidYouUpdate('GridNotifications', { locked, linkError });

  const lockGrid = useCallback(() => {
    dispatcher.grid.toggleLocked({ locked: true });
  }, [dispatcher.grid]);

  useEffect(() => {
    if (!locked && showTooltip !== false) {
      notifications.info(
        <div
          style={{
            backgroundColor: 'var(--blue-dark-lighter)',
            borderLeft: '1px solid var(--gray)',
            borderRight: '1px solid var(--gray)',
          }}
          className="moveBlocksContainer"
        >
          <p>Move blocks by dragging.</p>
          <button style={{ backgroundColor: 'var(--green)' }} className="btn" type="button" onClick={lockGrid}>
            Finished moving
          </button>
        </div>,
        { id: 'locked', unClosable: true }
      );
    } else {
      notifications.dismiss('locked');
    }
    if (locked && linkError && linkErroredAt) {
      notifications.error(linkError, { id: linkError, autoClose: 5000 });
    }
  }, [locked, linkError, linkErroredAt]);

  return null;
};

const GridWrapper: React.FC<{}> = ({ children }) => {
  const { dispatcher } = useDispatcher();
  const store = useStore();
  const accountLoaded = useSelector(account.accountLoaded);
  const sessionExpired = useStateSelector(state => state.auth.isExpired);
  const blocksLoading = useStateSelector(s => s.blocks.isFetchingInitial);
  const locked = useStateSelector(s => s.blocks.locked);
  const linking = useStateSelector(s => s.blocks.linking);
  const breakpoint = useStateSelector(layoutSelectors.breakpoint);
  const blockLayouts = useStateSelector(layoutSelectors.layouts);
  const { columns, width, loaded } = useDashboardSettings();
  const windowWidth = useWindowSizeContext().width;
  const isDashboardLocked = useStateSelector(s => s.ui.dashboard.isLocked);
  const blocks = useStateSelector(s => s.blocks.blocks);
  // pass layouts (with widths customized for grid settings) for each breakpoint
  const layouts = useMemo(() => {
    return _.mapValues(columns, (cols, b) =>
      _.map(blockLayouts && blockLayouts[b], l =>
        getLayoutWithMinSize(l, windowWidth, cols, windowWidth / cols, breakpoint)
      )
    );
  }, [columns, windowWidth, blockLayouts, blocks]);

  GridResizeSync();

  useWhyDidYouUpdate('GridWrapper', {
    breakpoint,
    loaded,
    layouts,
    blockLayouts,
    columns,
    windowWidth,
    store,
    dispatcher,
  });

  const onBreakpointChange = useCallback(
    (newBreakpoint: string, newCols: number) => {
      dispatcher.layout.setBreakpointAndColumns(newBreakpoint as SizeType, newCols);
    },
    [dispatcher]
  );

  const onLayoutChange = useCallback(
    (_newLayouts: Layout[], allLayouts: Layouts) => {
      // only save layout after settings have loaded, to make sure we only save for loaded grid column settings
      // map over allLayouts and add type to each layout based on blockId
      const allLayoutsWithType = _.mapValues(allLayouts, (layouts, b) =>
        _.map(layouts, l => {
          const block = blocks.find(b => b.blockId === l.i);
          if (block) {
            return { ...l, type: block.type };
          } else {
            return l;
          }
        })
      );
      dispatcher.layout.setLayouts(allLayoutsWithType);
    },
    [dispatcher, loaded, blocks]
  );

  const onKeyDown = (e: KeyboardEvent) => {
    // pressed ESC...
    if (e.keyCode === 27) {
      if (!locked) {
        // if dashboard unlocked, toggle to locked
        dispatcher.grid.toggleLocked({ locked: true });
      } else if (linking) {
        // if linking, toggle off
        dispatcher.grid.toggleLinking(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  useEffect(() => {
    if (_.isEmpty(layouts[breakpoint]) && _.isEmpty(blockLayouts) && loaded && !blocksLoading) {
      // if no layout for breakpoint, and blocks have loaded, and blockLayouts is not empty, set layout
      // map over the blocks, and create a layout for each block in an array
      let newLayouts: any = [];
      blocks.forEach((block, i) => {
        const layout = correctInvalidLayoutItem(
          {
            i: block.blockId,
            x: i % 2 === 0 ? 0 : Math.floor(columns[breakpoint] / 2),
            y: Math.floor(i / columns[breakpoint]),
            w: 1,
            h: 1,
            type: block.type,
          },
          i,
          columns[breakpoint],
          breakpoint
        );

        newLayouts.push(layout);
      });

      // set layout
      dispatcher.layout.setLayouts({ [breakpoint]: newLayouts });
    }
  }, [layouts, breakpoint, blocks, blockLayouts, loaded, blocksLoading]);
  // don't render grid when session expired or block are loading, since it will trigger a layout change/save with empty grid
  if (
    !accountLoaded ||
    sessionExpired ||
    blocksLoading ||
    !loaded ||
    _.isEmpty(layouts[breakpoint]) ||
    _.isEmpty(blockLayouts)
  ) {
    return null;
  }
  return (
    <Grid
      id="dashboardgrid"
      className="layout"
      layouts={layouts}
      cols={columns}
      width={width}
      margin={[8, 8]}
      containerPadding={[0, 0]}
      rowHeight={75}
      storageProvider={store}
      onBreakpointChange={onBreakpointChange}
      isDashboardLocked={isDashboardLocked}
      onLayoutChange={onLayoutChange}
    >
      {children}
    </Grid>
  );
};
