import {
  AssetSymbol,
  Quote,
  FeedQuote,
  AllNotesTimeframeValues,
  EarningsTimeframeValues,
  DividendTimeframeValues,
  IBlockState,
} from '@tradingblock/types';
import { SymbolQuote } from '@tradingblock/api';
import { IBlockDataState } from '@tradingblock/types';
import { OutputSelector } from 'reselect';
import { useBlockData } from '../../../components/blocks/BlockState';
import _ from 'lodash';
import { useMemo } from 'react';
export type InfoTab = 'releaseNotes' | 'newFeatures' | 'pressRelease' | 'addReleaseNotes' | 'alerts';
export const InfoBlockConstants: {
  tabs: Record<InfoTab, InfoTab>;
} = {
  tabs: {
    releaseNotes: 'releaseNotes',
    newFeatures: 'newFeatures',
    pressRelease: 'pressRelease',
    addReleaseNotes: 'addReleaseNotes',
    alerts: 'alerts',
  },
};
export interface InfoBlockDataState extends IBlockDataState {
  symbol?: AssetSymbol;
  //quoteMetaData?: SymbolQuote;
  tab?: InfoTab;
  noticesPeriod?: AllNotesTimeframeValues;
}
export type InfoBlockState = IBlockState<InfoBlockDataState, {}>;
type Selector<T> = OutputSelector<InfoBlockDataState, T, (res: InfoBlockDataState) => T>;
type Transform<T> = (res: InfoBlockDataState) => T;
export function useInfoData<T>(selector: Selector<T> | Transform<T>, defaultVal?: T) {
  const data = useBlockData<InfoBlockDataState>();
  const res = selector(data);
  return useMemo(() => (!_.isUndefined(defaultVal) && _.isNil(res) ? defaultVal : res), [defaultVal, res]);
}
export function isInfoTab(tabVal: InfoTab | string): tabVal is InfoTab {
  return _.map(InfoBlockConstants.tabs, (v, k) => k).includes(tabVal);
}
