import { Modal, ModalProps, TextboxField, CurrencyField } from '@tradingblock/components';
import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useDispatch } from 'react-redux';
import useSWRMutation from 'swr/mutation';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { Subaccount, UpdateSubaccountPayload } from '@tradingblock/api/src/commands/subaccounts';
import { useApi } from '../../context/Api';
import { ResponseCodes } from '@tradingblock/types';
import { useStateSelector } from '../../data/global/dataSelectors';
import { AccountActions } from '../../data/global/actions/AccountActions';

const updateSubAccountSchema = Yup.object<UpdateSubaccountPayload>().shape({
  accountId: Yup.number().required(),
  subaccountId: Yup.number().required(),
  nickname: Yup.string()
    .required('Please enter a nickname')
    .trim()
    .max(15, 'Please enter a max of 15 characters')
    .test(
      'empty-check',
      "Can't have an empty Nickname",
      (nickname: string) => nickname !== undefined && nickname.length > 0
    ),
  description: Yup.string(),
  balance: Yup.string().matches(
    /^\$?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/,
    'Please enter a positive currency value. Ex: $10,101.11'
  ),
});

export interface EditSubAccountModelProps {
  setShow: ModalProps['setShow'];
  show: ModalProps['show'];
  initialSubAccountState: Subaccount;
}

export function EditSubAccountModel({ setShow, show, initialSubAccountState }: EditSubAccountModelProps) {
  const api = useApi();
  const [errorState, setErrorState] = useState<string>('');
  const fundingAccountBalance = initialSubAccountState.Number ? initialSubAccountState.Number : '0';
  const subAccounts = useStateSelector(s => s.account.subaccounts);
  const editSubAccountFetcher = useCallback(
    (key: any, { arg }) => {
      return api.subaccounts.updateSubaccount(arg);
    },
    [api]
  );
  const dispatch = useDispatch();

  const {
    data: updateSubAccountResult,
    trigger: triggerUpdateSubAccount,
    isMutating,
    error: updateSubAccountRequestError,
  } = useSWRMutation('update-sub-account', editSubAccountFetcher);

  const onSubmit = async (values: UpdateSubaccountPayload) => {
    const payloadValues: UpdateSubaccountPayload = {
      ...values,
      nickname: values.nickname.trim(),
      balance: values.balance ? parseFloat(values.balance.toString().replace(/[$,]+/g, '')) : undefined,
    };
    let result = await triggerUpdateSubAccount(payloadValues);
    if (result && result.responseCode === 0)
      dispatch(AccountActions.requestSubAccounts({ accountId: initialSubAccountState.AccountId }));
  };

  useEffect(() => {
    if (updateSubAccountResult) {
      if (updateSubAccountResult.responseCode !== 0) {
        if ((updateSubAccountResult.payload as unknown) as string[])
          setErrorState(((updateSubAccountResult.payload as unknown) as string[]).join('\n'));
        else if (ResponseCodes[updateSubAccountResult.responseCode])
          setErrorState(ResponseCodes[updateSubAccountResult.responseCode].description);
        else setErrorState('Error Code ' + updateSubAccountResult.responseCode);
      } else {
        setShow(false);
      }
    }
  }, [updateSubAccountResult]);

  useEffect(() => {
    if (updateSubAccountRequestError !== undefined) {
      setErrorState(updateSubAccountRequestError.message);
    }
  }, [updateSubAccountRequestError]);

  return (
    <Modal setShow={setShow} show={show}>
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>Edit Subaccount</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <div style={{ color: 'red' }}>{errorState}</div>
        <Formik
          initialValues={
            {
              accountId: initialSubAccountState.AccountId,
              subaccountId: initialSubAccountState.Id,
              nickname: initialSubAccountState.Nickname || '',
              description: initialSubAccountState.Description,
              balance: initialSubAccountState.Balance,
            } as UpdateSubaccountPayload
          }
          validationSchema={updateSubAccountSchema}
          onSubmit={onSubmit}
        >
          {({ setFieldValue }: FormikProps<UpdateSubaccountPayload>) => (
            <Form translate={'yes'} id="edit-sub-account-form">
              <Field component={TextboxField} id="nickname" placeholder="Nickname" errorImmediately={true} />
              <Field component={TextboxField} id="description" placeholder="Description" />
              <Field
                disabled
                component={CurrencyField}
                value={parseFloat(fundingAccountBalance) + initialSubAccountState.Balance}
                placeholder="Maximum Subaccount Balance Allowed"
              />
              <Field component={CurrencyField} id="balance" placeholder="SubAccount Balance" errorImmediately={true} />
              <br></br>
              {parseFloat(fundingAccountBalance) > 0 && (
                <Button
                  variant="primary"
                  onClick={() => {
                    setFieldValue('balance', parseFloat(fundingAccountBalance) + initialSubAccountState.Balance);
                  }}
                >
                  Add funds from Master
                </Button>
              )}
            </Form>
          )}
        </Formik>
      </BootstrapModal.Body>
      <BootstrapModal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button variant="primary" type="submit" form={'edit-sub-account-form'} disabled={isMutating}>
          Save
        </Button>
      </BootstrapModal.Footer>
    </Modal>
  );
}
