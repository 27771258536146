import React from 'react';
import _ from 'lodash';
import { IndexStrategies, Strategies } from '@tradingblock/api';
import { StrategyButton } from '../../../components/blocks/StrategyButton';

interface StrategyProps {
  isIndex: boolean;
}

export const StrategyTemplateButtons: React.FC<StrategyProps> = ({ isIndex }) => {
  if (isIndex) {
    return (
      <ul className="strategy-templates">
        {_.map(IndexStrategies, s => (
          <li key={s.info.Name}>
            <StrategyButton strategy={s.info.Name} />
          </li>
        ))}
      </ul>
    );
  }

  return (
    <ul className="strategy-templates">
      {_.map(Strategies, s => (
        <li key={s.info.Name}>
          <StrategyButton strategy={s.info.Name} />
        </li>
      ))}
    </ul>
  );
};
