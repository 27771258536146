import { useRef, useEffect } from 'react';

import { useStateSelector } from '../data/global/dataSelectors';

import _ from 'lodash';

export const useLatestPendingCashieringDate = (onLatestDateChange: () => void, onlyRunOnChange: boolean = false) => {
  const prevDateValue = useRef<Date>();

  const latestCashieringUpdate = useStateSelector(s => s.cashiering.latestPendingCashieringEventDate);
  useEffect(() => {
    if (!_.isNil(latestCashieringUpdate) && prevDateValue && !_.isNil(prevDateValue.current) && prevDateValue.current.valueOf() !== latestCashieringUpdate.valueOf()) {
      prevDateValue.current = latestCashieringUpdate;
      onLatestDateChange();
    } else if (!_.isNil(latestCashieringUpdate) && _.isNil(prevDateValue.current)) {
      if (onlyRunOnChange !== true) {
        onLatestDateChange();
      }

      prevDateValue.current = latestCashieringUpdate;
    }
  }, [latestCashieringUpdate, onLatestDateChange]);
  return { latestCashieringUpdate };
};
