import { Middleware, Dispatch } from 'redux';
import { getType, ActionType } from 'typesafe-actions';
import _ from 'lodash';
import { StateSync } from '@tradingblock/types';
import { LocalStorageProvider, SyncTabStorageKey, TokenStorageKey } from '@tradingblock/storage';
import { DataState } from '../state';
import { RootAction, Actions, SessionActions } from '../actions';

const localStorageProvider = LocalStorageProvider({});

const syncSignout = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof Actions.signout>
) => {
  // const token = await localStorageProvider.get(TokenStorageKey);
  // const wrappedAction = Object.assign({ sync: StateSync.SIGNOUT, token }, action);
  // await localStorageProvider.save(SyncTabStorageKey, JSON.stringify(wrappedAction));
  // localStorage.setItem(SyncTabStorageKey, `${StateSync.SIGNOUT}-${Date.now()}`);
  localStorage.setItem(SyncTabStorageKey, JSON.stringify({ sync: StateSync.SIGNOUT, createDate: Date.now() }));
};

const syncSessionExpired = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof SessionActions.expired>
) => {
  // const token = localStorageProvider.get(TokenStorageKey);
  // const wrappedAction = Object.assign({ sync: StateSync.EXPIRATION, token }, action);
  // await localStorageProvider.save(SyncTabStorageKey, JSON.stringify(wrappedAction));
  // localStorage.setItem(SyncTabStorageKey, `${StateSync.EXPIRATION}-${Date.now()}`);
  const wrappedAction = Object.assign({ sync: StateSync.EXPIRATION, createDate: Date.now() }, action);
  localStorage.setItem(SyncTabStorageKey, JSON.stringify(wrappedAction));
};

export const stateSyncMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  try {
    // state BEFORE action is dispatched
    const prevState = getState();
    const result = next(action);
    // state AFTER action is dispatched
    const nextState = getState();
    switch (action.type) {
      case getType(Actions.signout): {
        // syncSignout(prevState, dispatch, action);
        break;
      }
      case getType(SessionActions.expired): {
        if (!action.payload.syncTab) {
          action.payload.syncTab = true;
          syncSessionExpired(prevState, dispatch, action);
        }
      }
    }
    return result;
  } catch (err) {
    console.error('stateSyncMiddleware :: Caught an exception for action ', action, err);
  }
};
