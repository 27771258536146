import React, { FC } from 'react';
import _ from 'lodash';
import { BlockBody, EmptyBlockContent, Loading } from '@tradingblock/components';
import { PositionSync } from '../shared/PositionSync';
import { useStateSelector } from '../../data/global/dataSelectors';
import { initialPositionsAreLoading } from '../../data/global/selectors/positionSelector';

import { PositionTable } from './components/PositionTable';
export const PositionBlockContent: FC<{
  setTotalGain: (totalGain: number | undefined) => void;
  setDailyPnl: (dailyPnl: number | undefined) => void;
}> = ({ setTotalGain, setDailyPnl }) => {
  const { loading, positions } = useStateSelector(initialPositionsAreLoading);
  const blockisEmptyOrLoading = positions.existing !== true;
  return (
    <BlockBody>
      <PositionSync />
      <>
        {blockisEmptyOrLoading === false ? (
          <PositionTable setTotalGain={setTotalGain} setDailyPnl={setDailyPnl} />
        ) : (
          <EmptyBlockContent>{loading ? <Loading /> : 'You have no positions.'}</EmptyBlockContent>
        )}
      </>
    </BlockBody>
  );
};
