import React from 'react';
import { AssetSymbolInfo } from '@tradingblock/types';
import { useFavorite } from '../../hooks/useFavorite';

export const FavoriteButton: React.FC<{ symbol: AssetSymbolInfo; className?: string }> = ({ symbol, className }) => {
  // const symbolIsFavorite = useStateSelector((s: DataState) => isFavorite(s, favSymbol ? favSymbol : ''));
  const [symbolIsFavorite, toggleFavorite] = useFavorite(symbol);
  // const { dispatcher } = useDispatcher();
  //   const addRemoveFavorite = useCallback(() => {
  //     console.log(favSymbol);
  //       if (favSymbol ) {
  //         if (symbolIsFavorite) {
  //           dispatcher.favorites.remove(favSymbol);
  //         } else {
  //           dispatcher.favorites.add(favSymbol);
  //         }
  //       }
  //     }, [dispatcher, symbolIsFavorite, favSymbol]);
  return (
    <button onClick={toggleFavorite} type="button" className={className}>
      <i className={`${symbolIsFavorite ? 'fas' : 'fal'} fa-star`} />
    </button>
  );
};
