import React from 'react';
import _ from 'lodash';
import { Button } from '@tradingblock/components';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { useDispatcher } from '../../../data/global/hooks';
import { AdminApplicationActions } from '../../../data/global/actions/admin/AdminApplicationActions';
import { ApplicationSecureAccount } from './components/details/SecureAccount';
import { ApplicationInvestingProfile } from './components/details/InvestingProfile';
import { ApplicationAccountInformation } from './components/details/AccountInformation';
import { ApplicationBeneficiaryInformation } from './components/details/BeneficiaryInformation';
import { ApplicationDocumentsRequested } from './components/details/DocumentsRequested';
import { ApplicationAgreements } from './components/details/Agreements';

export const AdminApplicationDetail: React.FunctionComponent<{}> = () => {
  const { dispatch } = useDispatcher();
  const application = useStateSelector(s => s.private.applications.currentItem.application);
  const handleClick = (e: any) => {
    e.preventDefault();
    dispatch(AdminApplicationActions.clearAppCurrentItem());
  };

  return (
    <>
      <div>
        <div className="screen-header screen-header-justified">
          <Button className="btn btn-blend" onClick={e => handleClick(e)}>
            <i className="fal fa-arrow-left" />
            Go back
          </Button>
        </div>
        <ApplicationSecureAccount />
        <ApplicationInvestingProfile />
        <ApplicationAccountInformation />
        {application && application.beneficiaries && <ApplicationBeneficiaryInformation />}
        <ApplicationDocumentsRequested />
        <ApplicationAgreements />
        <div className="screen-footer screen-footer-justified">
          <div className="screen-footer-actions-secondary">
            <Button className="btn btn-blend" onClick={e => handleClick(e)}>
              <i className="fal fa-arrow-left" />
              Go back
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
