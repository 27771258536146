import { getType } from 'typesafe-actions';
import _ from 'lodash';
import { EntityMap, PrivateUserAccountState, AccountInfo } from '@tradingblock/types';
import { getIdNumber } from '@tradingblock/api';
import { AdminAccountActions, AdminAccountAction } from '../../actions/admin/AdminAccountActions';
import { InitialState } from '../../initialState';

const entityTypeForAction = (
  action: AdminAccountAction
): keyof Pick<PrivateUserAccountState, 'accounts' | 'accountDetails' | 'balances' | 'balancesForWithdrawal'> => {
  switch (action.type) {
    case getType(AdminAccountActions.accountDetailsRequest):
    case getType(AdminAccountActions.accountDetailsReceive):
    case getType(AdminAccountActions.accountDetailsError): {
      return 'accountDetails';
    }
    case getType(AdminAccountActions.accountBalancesRequest):
    case getType(AdminAccountActions.accountBalancesReceive):
    case getType(AdminAccountActions.accountBalancesError): {
      return 'balances';
    }
    case getType(AdminAccountActions.accountBalancesForWithdrawalRequest):
    case getType(AdminAccountActions.accountBalancesForWithdrawalReceive):
    case getType(AdminAccountActions.accountBalancesForWithdrawalError): {
      return 'balancesForWithdrawal';
    }
    default: {
      return 'accounts';
    }
  }
};

export const privateAccountReducer = (
  state: PrivateUserAccountState = InitialState.private.account,
  action: AdminAccountAction
): PrivateUserAccountState => {
  switch (action.type) {
    case getType(AdminAccountActions.accountRequest):
    case getType(AdminAccountActions.accountDetailsRequest):
    case getType(AdminAccountActions.accountBalancesRequest):
    case getType(AdminAccountActions.accountBalancesForWithdrawalRequest): {
      const { payload } = action;
      const entityType = entityTypeForAction(action);
      const id = getIdNumber(payload.id);

      return {
        ...state,
        [entityType]: {
          ...state[entityType],
          [id]: {
            ...(state[entityType][id] || {}),
            isFetching: true,
            isErrored: false,
          },
        },
      };
    }
    case getType(AdminAccountActions.accountReceive): {
      const { payload } = action.payload;

      const updatedAccounts = _.reduce(
        payload,
        (accts, item): EntityMap<AccountInfo> => {
          return {
            ...accts,
            [item.AccountId]: {
              isFetching: false,
              data: item,
            },
          };
        },
        { ...state.accounts }
      );
      return {
        ...state,
        accounts: {
          ...state.accounts,
          ...updatedAccounts,
        },
      };
    }
    case getType(AdminAccountActions.accountDetailsReceive):
    case getType(AdminAccountActions.accountBalancesReceive):
    case getType(AdminAccountActions.accountBalancesForWithdrawalReceive): {
      const { payload } = action.payload;
      const entityType = entityTypeForAction(action);
      const id = getIdNumber(payload.id);

      return {
        ...state,
        [entityType]: {
          ...state[entityType],
          [id]: {
            ...(state[entityType][id] || {}),
            isFetching: false,
            isErrored: false,
            error: undefined,
            data: payload,
          },
        },
      };
    }

    case getType(AdminAccountActions.accountError):
    case getType(AdminAccountActions.accountDetailsError):
    case getType(AdminAccountActions.accountBalancesError):
    case getType(AdminAccountActions.accountBalancesForWithdrawalError): {
      const error = action.payload;
      const { data } = error;
      if (data === undefined) {
        return state;
      }
      const entityType = entityTypeForAction(action);
      const id = getIdNumber(data.id);
      return {
        ...state,
        [entityType]: {
          ...state[entityType],
          [id]: {
            ...(state[entityType][id] || {}),
            isFetching: false,
            isErrored: true,
            error,
          },
        },
      };
    }

    default:
      return state || InitialState.private.account;
  }
};
