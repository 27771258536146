import { useCallback, useState } from 'react';
import { AssetSymbol } from '@tradingblock/types';
import { useGroupStateHandler } from '../../useGroupState';
import { useOptionChainActions } from './useOptionChainActions';

export const useOptionChainGroupHandler = () => {
  // const { symbol } = useBlockData<OptionChainState>();
  const [currentSymbol, setCurrentSymbol] = useState();
  const { setExpandedExpirations } = useOptionChainActions();
  // const setSymbol = useCallback((sym: AssetSymbol) => set('symbol', sym, { persist: false }), [set]);
  const onSymbolChange = useCallback(
    (incomingSymbol: AssetSymbol | undefined) => {
      if (incomingSymbol && (!currentSymbol || currentSymbol.symbol !== incomingSymbol.symbol)) {
        setExpandedExpirations(null);
        setCurrentSymbol(incomingSymbol);

        //useOptionChainSymbols(incomingSymbol);
      }
    },
    [currentSymbol, setExpandedExpirations]
  );
  useGroupStateHandler('symbol', onSymbolChange, { fireOnGroupChange: true });
  return [currentSymbol];
};
