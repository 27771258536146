import qs from 'qs';
import dayjs from 'dayjs';
import _ from 'lodash';
import { alphabeticalSort } from '../../../utilities/data';
import { ReleaseNotesSearchRequest } from '@tradingblock/types';

export const releaseNotesRequestToQueryString = (request: ReleaseNotesSearchRequest) =>
  qs.stringify(request, { indices: false, sort: alphabeticalSort });

export const releaseNotesRequestKey = (request: ReleaseNotesSearchRequest) =>
  releaseNotesRequestToQueryString({ ...request });

export const removeStyleTags = (htmlContent: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');

  const styleTags = doc.querySelectorAll('style');
  styleTags.forEach(tag => tag.remove());

  return doc.body.innerHTML;
};
