import _ from 'lodash';
import { useLog } from '@tradingblock/components';
import { TradingEnvironments, TradingEnvironment } from './constants';

const isTruthyValue = (value: string | undefined) =>
  value && ['1', 'true', 'True', 'TRUE', 'yes'].includes(value) ? true : false;

function valueOrDefault<T = string>(key: string, getValue?: (val: string | undefined) => T | undefined) {
  return (defaultValue: T): T => {
    const keyVal = isTruthyValue(process.env.REACT_APP_IS_BETA) ? `REACT_APP_BETA_${key}` : `REACT_APP_${key}`;
    const strVal = process.env[keyVal];
    const envVal: T | undefined = getValue ? getValue(strVal) : (strVal as any);
    return _.isNil(envVal) ? defaultValue : envVal;
  };
}
const envConfig = {
  schemaVersion: process.env.REACT_APP_SCHEMA_VERSION as string,
  uiApi: process.env.REACT_APP_UI_API_URL as string,
  tradingApi: process.env.REACT_APP_TRADING_API_URL as string,
  tradingApiWebsockets: process.env.REACT_APP_TRADING_API_WS_URL as string,
  authRedirect: process.env.REACT_APP_AUTH_REDIRECT as string,
  loginUrl: process.env.REACT_APP_LOGIN_URL as string,
  applicationUrl: process.env.REACT_APP_APPLICATION_URL as string,
  liveTradingUrl: process.env.REACT_APP_LIVE_URL as string,
  virtualTradingUrl: process.env.REACT_APP_VIRTUAL_URL as string,
  isProduction: process.env.REACT_APP_ENVIRONMENT === 'production',
  gaUaTrackingCode: process.env.REACT_APP_GA_UA_TRACKING_CODE as string,
  ga4TrackingCode: (process.env.REACT_APP_GA_4_TRACKING_CODE as string) || '',
  ga4VTTrackingCode: (process.env.REACT_APP_VT_GA_4_TRACKING_CODE as string) || '',
  eventLogWhitelist: ['@order/created'],
  isVirtual: isTruthyValue(process.env.REACT_APP_IS_VIRTUAL as string),
  subdirectory: (process.env.REACT_APP_TB_SUBDIRECTORY as string)
    ? (process.env.REACT_APP_TB_SUBDIRECTORY as string)
    : '',
  uspsId: process.env.REACT_APP_USPS_ID as string,
  uspsSecret: process.env.REACT_APP_USPS_SECRET as string,
  uspsBaseUrl: process.env.REACT_APP_USPS_BASE_URL as string,
  siteGroup: process.env.REACT_APP_TB_SITE_GROUP as string,
};

const notOverridableConfig = {
  TradingEnvironment: valueOrDefault<TradingEnvironments>(
    'TRADING_ENVIRONMENT',
    (val: string | undefined): TradingEnvironments => {
      const tEnv = _.toLower(val || '');
      switch (tEnv) {
        case 'local':
          return TradingEnvironment.local;
        case 'dev':
        case 'development':
        case 'staging':
          return TradingEnvironment.dev;
        case 'beta':
          return TradingEnvironment.beta;
        default:
          return TradingEnvironment.production;
      }
    }
  )(TradingEnvironment.dev),
};

const combinedConfig = {
  ...envConfig,
  ...notOverridableConfig,
};
const log = useLog('config');
log('using loaded config %o', combinedConfig);
type EnvConfigType = typeof envConfig;
type NotOverridableConfig = typeof notOverridableConfig;
export const Config: EnvConfigType & NotOverridableConfig = combinedConfig;
export default Config;
