import { isBlockAction, blockReducer } from '../../../components/blocks/BlockState';
import { getType } from 'typesafe-actions';
import { AdminToolsState, AdminToolsDataState } from './AdminToolsState';
import { AdminToolsBlockActions, AdminToolsBlockAction } from './AdminToolsBlockAction';

export const AdminToolsReducer = (blockState: AdminToolsState, action: AdminToolsBlockAction): AdminToolsState => {
  const state = isBlockAction(action) ? blockReducer<AdminToolsDataState, {}>(blockState, action) : blockState;

  switch (action.type) {
    case getType(AdminToolsBlockActions.setTab): {
      const tabVal = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          tab: tabVal,
        },
      };
    }

    default:
      return state;
  }
};
