import { useCallback, useEffect } from 'react';
import createCachedSelector from 're-reselect';
import _ from 'lodash';
import { useLog } from '@tradingblock/components';
import { SaveOptions, Expiration, OptionExpirationType, AssetSymbol, ExtendedExpiration } from '@tradingblock/types';
import {
  useBlockMetadata,
  useBlockSymbol,
  useBlockActionDispatchAndGenericActions,
} from '../../../components/blocks/BlockState';
import { OptionChainState, useOptionChainData } from './OptionChainState';
import { useDispatcher } from '../../../data/global/hooks';
import { useOptionChainSettings } from '../data/useOptionChainSettings';
import { OptionChainAction, OptionChainActions } from './OptionChainAction';

const expirationsSelector = createCachedSelector(
  (state: OptionChainState, symbol: AssetSymbol | undefined, blockId: string) => state.expandedExpirations,
  (state: OptionChainState, symbol: AssetSymbol | undefined, blockId: string) => symbol,
  (expandedExpirations: ExtendedExpiration[] | null | undefined, symbol: AssetSymbol | undefined) => {
    const expValues = expandedExpirations ? expandedExpirations : [];
    return _(expValues)
      .filter(e => (symbol ? e.underlyingSymbol === symbol.underlyingSymbol : true))
      .orderBy(
        e => `${e.date.toString()}-${e.optionExpirationType ? OptionExpirationType[e.optionExpirationType] : ''}`
      )
      .value();
  }
)(
  (_, symbol: AssetSymbol | undefined, blockId: string) =>
    `${blockId}-${symbol ? symbol.underlyingSymbol : ''}-expandedExpirations`
);

export function useOptionChainActions() {
  const [{ updateData, setField }, dispatch] = useBlockActionDispatchAndGenericActions<
    OptionChainState,
    OptionChainAction
  >();
  const log = useLog('useOptionChainActions');
  const setIsDirty = useCallback(
    (dirty: boolean) => {
      updateData({ isDirty: dirty });
    },
    [updateData]
  );
  const updateField: typeof setField = useCallback(
    <K extends keyof OptionChainState>(field: K, value: OptionChainState[K], options?: SaveOptions) =>
      setField(field, value, options),
    [setField]
  );
  const symbol = useBlockSymbol();
  const setExpandedExpirations = useCallback(
    (values: Expiration[] | null) => {
      const extended: ExtendedExpiration[] = _.map(values || [], v => ({
        ...v,
        underlyingSymbol: symbol && symbol.underlyingSymbol ? symbol.underlyingSymbol : 'UNKNOWN',
      }));
      log(`setExpandedExpirations: %o`, extended);

      dispatch(OptionChainActions.setExpandedExpirations({ expirations: extended }));

      // const symbolValue = symbol ? symbol.symbol : undefined;
      // if (values !== null && symbolValue) {
      //   dispatcher.optionchain.requestOptionChain(blockId, symbolValue, values, strikeCount);
      // }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, symbol]
  );

  return { updateField, setIsDirty, setExpandedExpirations };
}

export function useOptionChainLoader() {
  const log = useLog('useOptionChainLoader');
  const { blockId } = useBlockMetadata();
  const optionChainSettings = useOptionChainSettings();
  const symbol = useBlockSymbol();
  const { dispatcher } = useDispatcher();
  const expandedExpirations = useOptionChainData(s => expirationsSelector(s, symbol, blockId));
  useEffect(() => {
    const symbolValue = symbol ? symbol.symbol : undefined;
    log(`useEffect %o`, { expandedExpirations, symbol });
    if (optionChainSettings && symbolValue !== undefined && expandedExpirations && expandedExpirations.length > 0) {
      dispatcher.optionchain.requestOptionChain(
        blockId,
        symbolValue,
        expandedExpirations,
        optionChainSettings.current.strikeCount
      );
    }
  }, [expandedExpirations, symbol, optionChainSettings.current.strikeCount]);
}
