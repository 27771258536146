import React, { useCallback } from 'react';
import { AssetType } from '@tradingblock/types';
import { Shares, getCustomStrategyLegQuantity } from '@tradingblock/api';
import { useOrderData } from '../state/OrderState';
import { useLegsActions } from '../state/useLegsActions';

export const AddOrderLegContent: React.FC<{ isSymbolIndex: boolean }> = ({ isSymbolIndex }) => {
  const { add } = useLegsActions();
  const canAddShares = useOrderData(o => o.validStrategies.some(s => s.info.Name === Shares.info.Name));

  const onAddEquityLeg = useCallback(() => {
    add(AssetType.Equity, { persist: true });
  }, [add]);
  const onAddOptionLeg = useCallback(() => {
    add(AssetType.Option, { persist: true });
  }, [add]);

  return (
    <div className="empty">
      <div className="empty-title">Build your own</div>
      <button className="btn btn-outline-light" onClick={onAddOptionLeg} type="button">
        <i className="fal fa-plus"></i> Add options leg
      </button>
      {canAddShares && !isSymbolIndex && (
        <button className="btn btn-outline-light" onClick={onAddEquityLeg} type="button">
          <i className="fal fa-plus"></i> Add shares leg
        </button>
      )}
    </div>
  );
};
