import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { getBlockProps } from '../../utilities/block';
import { BlockType } from '@tradingblock/types';
import { SubAccountManagementHeader } from './SubAccountManagementHeader';
import { BlockBody } from '@tradingblock/components';
import { Block } from '../../components/blocks/Block';
import { SubAccountManagementTable } from './SubAccountManagementTable';
import { account, useStateSelector } from '../../data/global/dataSelectors';
import { subAccountSelectors } from '../../data/global/selectors/subAccountSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { AccountActions } from '../../data/global/actions/AccountActions';
import { useDispatcher } from '../../data/global/hooks';
import { useApi } from '../../context/Api';
import useSWR from 'swr';

export interface SubAccountManagementBlockProps {}

export const SubAccountManagementBlock: FC<SubAccountManagementBlockProps> = () => {
  return (
    <Block
      {...getBlockProps(BlockType.SubAccountManagement, <SubAccountManagementHeader />)}
      header={<SubAccountManagementHeader />}
    >
      <BlockBody className="block-body subaccount-management">
        <SubAccountManagementTable />
      </BlockBody>
    </Block>
  );
};
