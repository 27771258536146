import React, { useCallback, useEffect, useRef } from 'react';
import { useStateSelector, blocksSelectors } from '../../data/global/dataSelectors';
import { DashboardContent, IDashboardContentProps } from '@tradingblock/components';
import { useDispatcher } from '../../data/global/hooks';
import { autoScrollToEnhanced } from './DashboardNavigation';

export const DashboardContentWrapper: React.FunctionComponent<Omit<IDashboardContentProps, 'locked' | 'linking'>> = ({ children, ...props }) => {
  const locked = useStateSelector(s => s.blocks.locked);
  const linking = useStateSelector(s => s.blocks.linking);

  const highlightBlockIds = useStateSelector(blocksSelectors.highlightBlockIds);
  const highlightedAt = useStateSelector(blocksSelectors.highlightedAt);
  const timeoutMs = useStateSelector(blocksSelectors.highlightTimeoutMs);

  const { dispatcher } = useDispatcher();
  const clearHighlightedBlocks = useCallback(() => dispatcher.block.clearHighlightedBlocks(), [dispatcher.block]);

  const timeoutId = useRef<number>();
  useEffect(() => {
    // if highlight set, restart timeout to clear highlight
    if (highlightBlockIds && highlightedAt) {
      const blockReference = document.querySelector(`div[id*=block-${highlightBlockIds[0]}]`);
      autoScrollToEnhanced(blockReference);
      window.clearTimeout(timeoutId.current);
      timeoutId.current = window.setTimeout(clearHighlightedBlocks, timeoutMs || 4000);
    }
    return () => {
      window.clearTimeout(timeoutId.current);
    };
  }, [timeoutId, highlightBlockIds, highlightedAt, timeoutMs, clearHighlightedBlocks]);

  return (
    <DashboardContent {...props} locked={locked} linking={linking}>
      {children}
    </DashboardContent>
  );
};
