/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { OptionType } from '@tradingblock/components';
import { AssetSymbol, BlockType } from '@tradingblock/types';
import { OrderState } from './state/OrderState';
import { useOrderActions } from './state/useOrderActions';
import { useDispatcher } from '../../data/global/hooks';
import { useStateSelector, blockGroupId } from '../../data/global/dataSelectors';
import { useBlockData, useBlockMetadata } from '../../components/blocks/BlockState';
import { SymbolPickerHeader } from '../shared/SymbolPickerHeader';
import { useGroupState } from '../useGroupState';

interface OrderHeaderProps {}

export const OrderHeader: React.FC<OrderHeaderProps> = () => {
  const [symbol] = useGroupState('symbol');
  const { isLoaded } = useBlockData<OrderState>();
  const { blockId } = useBlockMetadata();
  const groupId = useStateSelector(s => blockGroupId(s, blockId));
  const { dispatcher } = useDispatcher();
  const setGroupSymbol = dispatcher.blockGroup.setSymbol;
  const { setSymbol } = useOrderActions();

  const onSelect = useCallback(
    (value: OptionType<AssetSymbol>) => {
      const symbolValue = value ? value.data : undefined;
      const symbolName = symbolValue ? symbolValue.symbol : undefined;
      if (symbolName && symbolValue && groupId) {
        //setSym(symbolValue);
        setSymbol(symbolValue, false, { persist: true });
        setGroupSymbol(groupId, symbolValue);
      }
    },
    [groupId]
  );
  // useEffect(() => {
  //   if (groupId && sym && (!symbol || sym.symbol !== symbol.symbol)) {
  //     setSymbol(sym, { persist: true });
  //     setGroupSymbol(groupId, sym);
  //   }
  // }, [sym]);
  const onClear = useCallback(() => {
    //setSymbol(undefined);
    if (groupId !== undefined) {
      setSymbol(undefined, false, { persist: true });
      setGroupSymbol(groupId, undefined);
    } else {
      console.log('not clearing block because group is undefined');
    }
  }, [setGroupSymbol, groupId]);

  return (
    <SymbolPickerHeader
      blockType={BlockType.Order}
      symbol={symbol}
      isLoaded={isLoaded}
      onSelect={onSelect}
      focus={false}
      onClear={onClear}
    />
  );
};

export const OrderHeaderMemo = React.memo(OrderHeader);
