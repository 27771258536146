import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useSounds, audioSrc } from '../../hooks/useSounds';
import { useStateSelector, account } from '../../data/global/dataSelectors';
import { cashiering } from '../../data/global/selectors/cashieringSelectors';
import { CashieringEventActions } from '../../data/global/actions/CashieringEventActions';
import { NotificationActions } from '../../data/global/actions';
import { useDashboardSettings } from '../../hooks/useDashboardSettings';
import { useLatestPendingCashieringDate } from '../../hooks/useLatestPendingCashieringDate';

const PENDING_NOTIFICATION_KEY = 'pending-cashiering-requests';

const useReminder = (fireReminder: () => void, interval: number, max: number) => {
  const pendingExists = useStateSelector(cashiering.pendingEntitiesExist);
  const reminderInterval = useRef<NodeJS.Timeout>();
  const [{ remaining, check }, setReminderState] = useState<{ check: boolean; remaining: number }>({
    check: false,
    remaining: 0,
  });

  const start = useCallback(
    (offset: number) => {
      setReminderState(prev => ({ ...prev, remaining: max - offset }));

      console.debug(`starting cashiering reminder interval to run every ${interval} ms ${max} times`);
      if (reminderInterval.current) {
        console.debug('clearing cashiering reminder interval');
        clearInterval(reminderInterval.current);
      }
      reminderInterval.current = setInterval(() => {
        setReminderState(prev => ({ ...prev, check: true }));
      }, interval);
    },
    [max, interval]
  );

  const stop = useCallback(() => {
    if (reminderInterval.current) {
      console.debug('stopping cashiering reminders');
      clearInterval(reminderInterval.current);
    }
  }, []);

  useEffect(() => {
    const shouldSendMore = remaining > 0;

    if (check) {
      if (shouldSendMore) {
        if (pendingExists) {
          console.debug('pending cashiering entities exist', { remaining, interval });
          fireReminder();
          return setReminderState(prev => ({ ...prev, check: false, remaining: prev.remaining - 1 }));
        } else {
          console.log('no pending exist');
        }
      } else {
        console.debug(`max reminder count reached ${max}, stopping reminders`);
        stop();
      }
      console.log({ check, fireReminder, remaining, stop, max });
      setReminderState(prev => ({ ...prev, check: false }));
    }
  }, [check, pendingExists, fireReminder, remaining, stop, max]);

  return { start, stop };
};

const CashieringUpdatesPollContent: React.FC<{
  cashieringPendingReminderMaxAttempts: number;
  cashieringPollingInterval: number;
  cashieringPendingReminderInterval: number;
}> = ({ cashieringPollingInterval, cashieringPendingReminderMaxAttempts, cashieringPendingReminderInterval }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const hasCashieringAdminPermission = useSelector(account.hasCashieringAdminPermission);

  const dispatch = useDispatch();

  const playUpdateSound = useCallback(() => {
    const currAudioRef = audioRef.current;
    if (currAudioRef) {
      const play = currAudioRef.play();
      if (play !== undefined) {
        play.catch(() => {
          // Do nothing
          //TODO: Determine if we want to warn the user that the sound failed to play
        });
      }
    }
    dispatch(
      NotificationActions.add({
        status: 'Info',
        title: 'Cashiering updates',
        message: 'One or more pending cashiering requests exist',
        global: false,
        time: new Date(),
        key: PENDING_NOTIFICATION_KEY,
        read: false,
        hide: false,
        viewed: false,
      })
    );
  }, [audioRef]);

  const { start, stop } = useReminder(
    playUpdateSound,
    cashieringPendingReminderInterval,
    cashieringPendingReminderMaxAttempts
  );

  useEffect(() => {
    if (hasCashieringAdminPermission) {
      dispatch(CashieringEventActions.beginPollingForPending({ timeoutMS: cashieringPollingInterval }));

      return () => {
        dispatch(CashieringEventActions.stopPollingForPending());

        if (stop) {
          stop();
        }
      };
    }
  }, [start, stop, cashieringPollingInterval, cashieringPendingReminderMaxAttempts, hasCashieringAdminPermission]);

  const onUpdateOccurs = useCallback(() => {
    playUpdateSound();
    start(1);
    dispatch(
      NotificationActions.add({
        status: 'Info',
        title: 'Cashiering updates',
        message: 'One or more cashiering requests have been received',
        global: false,
        time: new Date(),
        key: PENDING_NOTIFICATION_KEY,
        read: false,
        hide: false,
        viewed: false,
      })
    );
  }, [start, playUpdateSound]);

  useLatestPendingCashieringDate(onUpdateOccurs);

  return (
    <div>
      <audio src={audioSrc.updates} ref={audioRef}></audio>
      {/* <CashieringPendingReminder fireReminder={playUpdateSound} /> */}
    </div>
  );
};

export const CashieringUpdatesPoll: React.FC<{}> = () => {
  const isCashieringAdmin = useStateSelector(account.hasCashieringAdminPermission);
  const {
    cashieringPollingInterval,
    cashieringPendingReminderMaxAttempts,
    cashieringPendingReminderInterval,
  } = useDashboardSettings();
  //const pollingIntervalMs = cashieringPollingInterval * 60 * 1000;
  const cashieringPendingReminderIntervalMs = cashieringPendingReminderInterval * 1000;
  const cashieringCheckInterval = cashieringPollingInterval * 1000;

  return (
    <>
      {isCashieringAdmin && (
        <>
          <CashieringUpdatesPollContent
            cashieringPendingReminderInterval={cashieringPendingReminderIntervalMs}
            cashieringPollingInterval={cashieringCheckInterval}
            cashieringPendingReminderMaxAttempts={cashieringPendingReminderMaxAttempts}
          />
        </>
      )}
    </>
  );
};
