import React from 'react';
import { FormGroup, Modal, StepperField, TextboxField } from '@tradingblock/components';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { useStateSelector } from '../../data/global/dataSelectors';

export const AlertMessage: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
}> = ({ show, toggleModal }) => {
  const activeAlerts = useStateSelector(s => s.information.alerts.activeAlerts);

  return (
    <div>
      <Modal show={show} setShow={() => toggleModal()}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>IMPORTANT ALERT</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          <ul style={{ listStyleType: 'none' }}>
            <h5 className="pending" style={{ textDecoration: 'underline' }}>
              Messages
            </h5>
            {activeAlerts &&
              activeAlerts.map(alert => (
                <li key={alert.id}>
                  <p>{alert.message}</p>
                </li>
              ))}
          </ul>
        </BootstrapModal.Body>
        <BootstrapModal.Footer>
          <Button variant="secondary" onClick={toggleModal}>
            Close
          </Button>
        </BootstrapModal.Footer>
      </Modal>
    </div>
  );
};
