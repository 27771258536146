import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import React, { useCallback } from 'react';
import { useStringOrDefault } from '../../hooks/useValueOrDefault';
import { BlockDragHandle } from '../block';

var TabWell = function TabWell(_ref) {
  var wrapperClass = _ref.wrapperClass,
      itemClass = _ref.itemClass,
      linkClass = _ref.linkClass,
      activeClass = _ref.activeClass,
      tabs = _ref.tabs,
      onTabClick = _ref.onTabClick;

  var _useStringOrDefault = useStringOrDefault(wrapperClass, itemClass, linkClass, [activeClass, 'active']),
      _useStringOrDefault2 = _slicedToArray(_useStringOrDefault, 4),
      wrapperCss = _useStringOrDefault2[0],
      itemCss = _useStringOrDefault2[1],
      linkCss = _useStringOrDefault2[2],
      activeCss = _useStringOrDefault2[3];

  var href = '';
  var onClick = useCallback(function (tab) {
    return function (event) {
      event.preventDefault();
      event.stopPropagation();
      return onTabClick(tab);
    };
  }, []);
  return React.createElement("ul", {
    className: wrapperCss,
    style: {
      marginLeft: 0
    }
  }, tabs.map(function (tab, ind) {
    return React.createElement("li", {
      className: itemCss,
      key: "tab".concat(ind)
    }, tab.active === true && React.createElement(BlockDragHandle, {
      title: tab.title,
      className: "".concat(linkCss, " ").concat(activeCss)
    }, tab.title), !tab.active && React.createElement("a", {
      className: linkCss,
      href: href,
      onClick: onClick(tab),
      title: tab.title
    }, tab.title));
  }));
};

export var BlockTabs = function BlockTabs(props) {
  return React.createElement(TabWell, Object.assign({}, props, {
    wrapperClass: "block-nav nav nav-pills",
    itemClass: "nav-item",
    linkClass: "nav-link"
  }));
};