import { Middleware } from 'redux';
import localforage from 'localforage';
import { RootAction } from '../actions';
import { DataState } from '../state';
import { Dispatch } from '../../../types';

export const crashReporter: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = store => (
  next: Dispatch<RootAction>
) => (action: RootAction) => {
  try {
    return next(action);
  } catch (err) {
    const key = `tradingblock-crash-${new Date().valueOf()}`;
    console.error(`Caught an exception!. Stored crash at ${key}`, err);
    return Promise.resolve(
      localforage.setItem(key, {
        error: err,
        action,
        state: store.getState(),
      })
    )
      .then(() => {
        throw err;
      })
      .catch(saveErr => console.error('errorhandling error!', saveErr));
  }
};
