import qs from 'qs';
import dayjs from 'dayjs';
import _ from 'lodash';
import {
  CashieringSearchRequest,
  CashieringBaseEntity,
  CashieringEntityDetailTypes,
  CashieringEntityTypes,
} from '@tradingblock/types';
import { alphabeticalSort } from '../../../utilities/data';

export const cashieringRequestToQueryString = (request: CashieringSearchRequest) =>
  qs.stringify(request, { indices: false, sort: alphabeticalSort });
export const cashieringRequestKey = (request: CashieringSearchRequest) =>
  cashieringRequestToQueryString({ ...request });

const objectTypes: {
  [key: string]: CashieringEntityDetailTypes;
} = {
  AchRelationship: 'relationships',
  Transfer: 'transfers',
  TransferRecipientBank: 'recipientBanks',
  TransferWireInstruction: 'wireInstructions',
};

export const cashieringEntityKey = (type: CashieringEntityTypes, id: number | string) => {
  return `${type}-${id}`;
};

export const getEntityKey = (re: CashieringBaseEntity) => {
  const entityId = re.transferRecipientBank
    ? re.transferRecipientBank.transferRecipientBankId
    : re.transfer
    ? re.transfer.transferId
    : re.relationship
    ? re.relationship.relationshipId
    : re.transferWireInstruction
    ? re.transferWireInstruction.transferWireInstructionId
    : re.accountId;
  return cashieringEntityKey(objectTypes[re.type], entityId);
};

export const latestDate = <T extends CashieringBaseEntity>(entities: T[]) =>
  _(entities)
    .map(e => dayjs(e.date))
    .orderBy(d => d.valueOf(), 'desc')
    .first();
