import React from 'react';
import { Provider } from 'react-redux';
import { Loading } from '@tradingblock/components';
import { DataStore } from './data/global/store';
import { createStorageListener } from './data/global/utilities/storageListener';
import { useReduxConfiguration } from './hooks/useReduxConfiguration';

export const dataStore = DataStore({ enableDevTools: true });

const AppStoreContent: React.FC = ({ children }) => {
  window.addEventListener('storage', createStorageListener(dataStore));
  return <Provider store={dataStore}>{children}</Provider>;
};

export const AppStoreProvider: React.FC = ({ children }) => {
  return <AppStoreContent>{children}</AppStoreContent>;
};
