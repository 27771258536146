import { useBaseBlockActionDispatch, BlockStateContext } from '../../../components/blocks/BlockState';
import { IBlockDataState, IBlockState } from '@tradingblock/types';
import { BlockAndTableActions, BlockTableActions } from '../../../components/blocks/state/blockTable/BlockTableActions';
import { WithTables } from '../../../components/blocks/state/blockTable/BlockTableState';
import createCachedSelector from 're-reselect';
import _ from 'lodash';
import { useCallback, useContext } from 'react';
import { DataTableState } from '@tradingblock/components';

const defaultDataTableState: DataTableState = { sortBy: [], pageSize: 50, pageIndex: 0, columnOrder: [] };
const defaultAdminAccountManagementState: DataTableState = { sortBy: [], pageSize: 10, pageIndex: 0, columnOrder: [] };
const isAdminAccountManagementTable = (key: string) => {
  const adminTableKeys = [
    'adminAccountManagmentTable',
    'adminApplicationsTable',
    'admin-entitlements-table',
    'releaseNotes',
    'adminAlertsTemplateTable',
  ];

  return adminTableKeys.includes(key);
};

const blockTableStateSelector = createCachedSelector(
  (s: IBlockState<WithTables<IBlockDataState>>, key: string) => (s.data.tables ? s.data.tables[key] : undefined),
  (s: IBlockState<WithTables<IBlockDataState>>, key: string) => key,
  (tablesState, key): DataTableState => {
    if (_.isNil(tablesState) && !isAdminAccountManagementTable(key)) {
      return defaultDataTableState;
    }
    if (_.isNil(tablesState) && isAdminAccountManagementTable(key)) {
      return defaultAdminAccountManagementState;
    }

    if (!isAdminAccountManagementTable(key)) {
      return { ...defaultDataTableState, ...tablesState };
    } else {
      return { ...defaultAdminAccountManagementState, ...tablesState };
    }
  }
)((s, key: string) => `block-table-${s.blockId}-${key}`);

export const useBlockTableActions = () => useBaseBlockActionDispatch<BlockAndTableActions>();

export const useBlockTable = (key: string) => {
  const state: IBlockState<WithTables<IBlockDataState>> = useContext(BlockStateContext);
  const initialState = blockTableStateSelector(state, key);
  const dispatch = useBaseBlockActionDispatch<BlockAndTableActions>();
  const setTable = useCallback(
    (table: Partial<DataTableState>) => {
      dispatch(BlockTableActions.setTableState({ key: key, table }, { persist: true }));
    },
    [dispatch]
  );
  return { state: initialState, setTable };
};
