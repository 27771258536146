import { useMemo } from 'react';
import { OutputSelector } from 'reselect';
import _ from 'lodash';
import { IBlockDataState, ExtendedExpiration } from '@tradingblock/types';
import { useBlockData } from '../../../components/blocks/BlockState';

export interface OptionChainState extends IBlockDataState {
  isInitializing?: boolean;
  expandedExpirations?: ExtendedExpiration[] | null;
}

type Selector<T> = OutputSelector<OptionChainState, T, (res: OptionChainState) => T>;
type Transform<T> = (res: OptionChainState) => T;

export function useOptionChainData<T>(selector: Selector<T> | Transform<T>, defaultVal?: T) {
  const data = useBlockData<OptionChainState>();
  const res = selector(data);
  return useMemo(() => (!_.isUndefined(defaultVal) && _.isNil(res) ? defaultVal : res), [defaultVal, res]);
}
