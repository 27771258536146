import { BlockType } from '@tradingblock/types';
import _ from 'lodash';

const downloadFile: Function = <T extends BlobPart>({
  data,
  fileName,
  fileType,
}: {
  data: T;
  fileName: string;
  fileType: string;
}): void => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType });
  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  const anchor = document.createElement('a');
  anchor.download = fileName;
  anchor.href = window.URL.createObjectURL(blob);
  const clickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  anchor.dispatchEvent(clickEvent);
  anchor.remove();
};

export const formatMoney: Function = (data: number): string => {
  return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(data);
};

//Main function for using CSV functionality. Requires a data array, array of headers for strings and a blocktype (for making this function generic)
export const exportToCsv: Function = (data: [] | undefined | object, blockType: BlockType | undefined, key?: string): void => {
  // Cashiering Table CSV branch
  if (key && key === 'cashieringTable' && blockType === undefined) {
    const extractHeaders: string[] = [];
    const extractRows: any[] = [];
    let maxLength = 0;
    // Find the length of the spreadsheet by checking any truthy array length and also extract headers
    Object.entries(data as any).map(([headerName, columnData]) => { 
        if (data && columnData && (columnData as []).length) { 
          extractHeaders.push(headerName);
          maxLength = Math.max(maxLength, (columnData as []).length)
    }})
    //column scope
    for (let column = 0; column < maxLength; column++) {
      //row scope
      for (let row = 0; row < extractHeaders.length; row++) {
        extractRows.push([`"${(data as [])[extractHeaders[row] as any][column]}"`].toString())
      }
    }
    const groupChunks: any = []
    const chunkSize = extractHeaders.length;
    for (let i = 0; i < extractRows.length; i += chunkSize) {
        groupChunks.push(extractRows.slice(i, i + chunkSize));
    }
    downloadFile({
        data: [(extractHeaders.map((val) => _.capitalize(val))), ...groupChunks].join('\n'),
        fileName: `Trading-Block_Cashiering_Data${new Date().toLocaleString()}.csv`,
        fileType: 'text/csv',
      });
  // Analytics Block CSV branch
  } else if (blockType === 'Analytics') {
    const AnalyticsCSVheaders: string[] = ['Date, Cash Available, Money Market Value, Securities Value, Account Value, Dividends, Cash Transfers'];
    // Convert data to a csv
    let dataArray = data
      ?  Object.entries(data).reduce((dataArray: string[], datum: any) => {
          let { recordDate, cashAvailable, moneyMarketValue, securitiesValue, accountValue, dividends, cashTransfers } = datum[1];
          dataArray.push(
            [
              new Date(recordDate).toLocaleDateString(),
              `"${formatMoney(cashAvailable)}"`,
              `"${formatMoney(moneyMarketValue)}"`,
              `"${formatMoney(securitiesValue)}"`,
              `"${formatMoney(accountValue)}"`,
              `"${formatMoney(dividends)}"`,
              `"${isNaN(formatMoney(cashTransfers)) ? 'N/A' : formatMoney(cashTransfers)}"`,
            ].join(',')
          );
        return dataArray;
      }, [])
      : [];
    downloadFile({
      data: [AnalyticsCSVheaders, ...dataArray].join('\n'),
      fileName: `Trading-Block_Balances_${new Date().toLocaleString()}.csv`,
      fileType: 'text/csv',
    });
  }
};

//Code adapted from: https://github.com/ThomasFindlay/csv-json-files-download-in-react
