import React, { useEffect, useState } from 'react';
import { Field } from 'formik';
import _ from 'lodash';
import { AddIcon, CheckboxField, FormGroup, Loading, StepperField } from '@tradingblock/components';
import { CashieringViewFormValues, CashieringViewProps } from '../data/useCashieringView';
import { MiscUploadFileField } from '../../../../components/form/UploadMiscFile';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { useApi } from '../../../../context/Api';
import { AllCashieringDocumentTypes, CashieringDocumentType, CashieringRoute } from '@tradingblock/types';
import { useClearer } from '../../../../hooks/useClearer';

interface BaseType {
  id: number;
}

interface GenericAccountListProps<T extends BaseType> {
  field: string;
  data?: T[];
  selectable?: boolean;
  renderName?: (item: T) => JSX.Element;
  renderIcon?: (item: T) => JSX.Element;
  renderStatus?: (item: T) => JSX.Element | null;
  renderFootnote?: () => JSX.Element | null;
  addBtn?: { text: string; help?: string; active?: boolean };
  isDisabled?: (item: T) => boolean;
  onAdd: () => void;
  onSelect?: (id: number) => void;
  onDelete?: (item: T) => void;
}

export interface AccountListWrapperProps<T extends BaseType>
  extends GenericAccountListProps<T>,
    CashieringViewProps<CashieringViewFormValues> {
  onVerify?: (account: T) => void;
  onRename?: (account: T) => void;
}

export const createGenericAccountList = <T extends { id: number; state?: any }>() => {
  const GenericAccountList: React.FunctionComponent<GenericAccountListProps<T>> = ({
    field,
    data,
    selectable,
    addBtn,
    renderName,
    renderIcon,
    renderStatus,
    renderFootnote,
    isDisabled,
    onSelect,
    onDelete,
    onAdd,
  }) => {
    const api = useApi();
    const isPendingStatus = (item: T) => {
      return item && item.state && item.state.includes('Pending');
    };

    const accountId = useStateSelector(s => s.account.accountId);
    const [uploadId, setUploadId] = useState<number>();
    const clearer = useClearer();

    const handleDocumentUpload = (documentId: number) => {
      setUploadId(documentId);
    };

    const [documentType, setDocumentType] = useState<{ type: CashieringDocumentType; id: number }[]>([]);
    const [intermediaryDocumentType, setIntermediaryDocumentType] = useState<
      { type: CashieringDocumentType; id: number }[]
    >([]);

    const isTransferInstructionField = field === 'transfer.instructionId';

    const [bankIdsWithUploads, setBankIdsWithUploads] = useState<number[]>([]);
    const [bankIdsWithMultipleUploads, setBankIdsWithMultipleUploads] = useState<number[]>([]);
    const [bankIdsWithIntermediary, setBankIdsWithIntermediary] = useState<number[]>([]);
    const [isFetchingDocuments, setIsFetchingDocuments] = useState<boolean>(true);

    const getDocType = (id: number, isIntermediary: boolean) => {
      if (isIntermediary) {
        const doc = intermediaryDocumentType.find(d => d.id === id);
        return doc ? doc.type : null;
      } else {
        const doc = documentType.find(d => d.id === id);
        return doc ? doc.type : null;
      }
    };

    useEffect(() => {
      const getDocumentIds = async (item: T) => {
        const { responseCode, payload } = await api.cashiering.documents.getId(accountId, item.id);
        if (responseCode === 0 && payload && payload.length > 0) {
          setBankIdsWithUploads(current => [...current, item.id]);
          if (payload.length > 1) {
            setBankIdsWithMultipleUploads(current => [...current, item.id]);
          }
        }
        const timer = setTimeout(() => {
          setIsFetchingDocuments(false);
        }, 500);

        return () => {
          clearTimeout(timer);
        };
      };

      const getIntermediaryIds = async (item: any) => {
        if (item.intermediary) {
          setBankIdsWithIntermediary(current => [...current, item.id]);
        }
      };

      data &&
        data.forEach(item => {
          if (isTransferInstructionField) {
            getDocumentIds(item);
            getIntermediaryIds(item);
          }
        });
    }, [data]);

    return (
      <>
        {data && (
          <ul className={`linked-accounts ${selectable ? 'checklist' : ''}`}>
            {_.map(data, (a, i) => (
              <li key={`data${i}`}>
                <div className="linked-accounts-icon">
                  {selectable &&
                    (isDisabled && isDisabled(a) ? (
                      <i className="far fa-times fa-2x neg" />
                    ) : (
                      <>
                        <Field
                          component={CheckboxField}
                          id={field}
                          type="radio"
                          value={a.id}
                          label={' '}
                          onChanged={onSelect ? () => onSelect(a.id) : undefined}
                          disabled={a.state && a.state !== 'Approved'}
                        />
                      </>
                    ))}

                  {!selectable && renderIcon && renderIcon(a)}
                </div>
                <div className="linked-accounts-name">
                  {selectable && renderName && (
                    <>
                      <label htmlFor={`${field}.${a.id}`} className={isDisabled && isDisabled(a) ? 'disabled' : ''}>
                        {renderName(a)}
                      </label>
                      {isTransferInstructionField && !isFetchingDocuments && (
                        <div className="pending linked-accounts-warning txt-sm" style={{ fontStyle: 'italic' }}>
                          <strong>
                            {!bankIdsWithUploads.includes(a.id) && a.state && a.state !== 'Rejected' && (
                              <p>
                                <i className="fas fa-exclamation-circle" /> REQUIRED: Please upload bank statement or
                                voided check for bank account entered. Select document type and click "Upload Document"
                                (PDF, JPG, PNG, GIF).
                              </p>
                            )}
                            {bankIdsWithIntermediary.includes(a.id) &&
                              !bankIdsWithMultipleUploads.includes(a.id) &&
                              a.state &&
                              a.state !== 'Rejected' && (
                                <span>
                                  <i className="fas fa-exclamation-circle" /> REQUIRED: Additional upload required for
                                  intermediary bank account. Please upload bank statement or voided check for bank
                                  account entered. Select document type and click "Upload Document" (PDF, JPG, PNG,
                                  GIF).
                                </span>
                              )}
                          </strong>
                        </div>
                      )}
                    </>
                  )}
                  {!selectable && renderName && (
                    <div>
                      {renderName(a)}
                      {isTransferInstructionField && !isFetchingDocuments && (
                        <div className="pending linked-accounts-warning txt-sm" style={{ fontStyle: 'italic' }}>
                          <strong>
                            {!bankIdsWithUploads.includes(a.id) && a.state && a.state !== 'Rejected' && (
                              <p>
                                <i className="fas fa-exclamation-circle" /> REQUIRED: Please upload bank statement or
                                voided check for bank account entered. Select document type and click "Upload Document"
                                (PDF, JPG, PNG, GIF).
                              </p>
                            )}
                            {bankIdsWithIntermediary.includes(a.id) &&
                              !bankIdsWithMultipleUploads.includes(a.id) &&
                              a.state &&
                              a.state !== 'Rejected' && (
                                <span>
                                  <i className="fas fa-exclamation-circle" /> REQUIRED: Additional upload required for
                                  intermediary bank account. Please upload bank statement or voided check for bank
                                  account entered. Select document type and click "Upload Document" (PDF, JPG, PNG,
                                  GIF).
                                </span>
                              )}
                          </strong>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {renderStatus && <div className="linked-accounts-status">{renderStatus(a)}</div>}
                {!isPendingStatus(a) && <div className="linked-accounts-upload"></div>}
                {isPendingStatus(a) && (
                  <>
                    {isFetchingDocuments && isTransferInstructionField && (
                      <div className="linked-accounts-upload">
                        <Loading size="small" />
                      </div>
                    )}
                    {!isTransferInstructionField && <div className="linked-accounts-upload"></div>}
                    {isTransferInstructionField && !isFetchingDocuments && (
                      <div className="linked-accounts-upload">
                        {!bankIdsWithUploads.includes(a.id) && (
                          <>
                            <FormGroup>
                              <Field
                                component={StepperField}
                                id={`docType-${a.id}`}
                                options={AllCashieringDocumentTypes}
                                defaultLabelId={'cashieringDocuments'}
                                className="txt-sm"
                                onChangedAndSetValue={true}
                                onChanged={(value: CashieringDocumentType) => {
                                  setDocumentType(current => [...current, { type: value, id: a.id }]);
                                }}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Field
                                component={MiscUploadFileField}
                                id={`uploadDocuments-${i}`}
                                accountId={accountId}
                                accountHolderIndex={i}
                                description={'Bank statement or voided check'}
                                documentType={getDocType(a.id, false)}
                                handleDocumentUpload={handleDocumentUpload}
                                isCashieringDocument={true}
                                recipientBankId={a.id}
                                disabled={documentType && documentType.filter(d => d.id === a.id).length === 0}
                                clearer={clearer}
                              />
                            </FormGroup>
                          </>
                        )}
                        {bankIdsWithIntermediary.includes(a.id) && !bankIdsWithMultipleUploads.includes(a.id) && (
                          <>
                            <FormGroup>
                              <Field
                                component={StepperField}
                                id={`docType-${a.id}-intermediary`}
                                options={AllCashieringDocumentTypes}
                                defaultLabelId={'cashieringDocuments'}
                                className="txt-sm"
                                onChangedAndSetValue={true}
                                onChanged={(value: CashieringDocumentType) => {
                                  setIntermediaryDocumentType(current => [...current, { type: value, id: a.id }]);
                                }}
                              />
                            </FormGroup>
                            <Field
                              component={MiscUploadFileField}
                              id={`uploadDocuments-${i}-intermediary`}
                              accountId={accountId}
                              accountHolderIndex={i}
                              description={'Bank statement or voided check'}
                              documentType={getDocType(a.id, true)}
                              handleDocumentUpload={handleDocumentUpload}
                              isCashieringDocument={true}
                              recipientBankId={a.id}
                              disabled={
                                intermediaryDocumentType &&
                                intermediaryDocumentType.filter(d => d.id === a.id).length === 0
                              }
                              clearer={clearer}
                            />
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}
                {!selectable && onDelete && (!isDisabled || !isDisabled(a)) && (
                  <div className="linked-accounts-remove">
                    <button className="btn btn-blend" type="button" title="Delete" onClick={() => onDelete(a)}>
                      <i className="fas fa-2x fa-times-circle" />
                    </button>
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}

        {renderFootnote && renderFootnote()}

        {addBtn && (
          <p>
            <span
              onClick={onAdd}
              onKeyDown={e => (e.keyCode === 13 || e.keyCode === 32) && onAdd && onAdd()}
              style={{ cursor: 'pointer' }}
              role="button"
              tabIndex={0}
            >
              <AddIcon size="1x" inverse active={addBtn.active} />
            </span>
            <button className="btn btn-link" type="button" onClick={onAdd} style={{ paddingLeft: '1em' }}>
              {addBtn.text}
            </button>{' '}
            {addBtn.help && <span className="mute" dangerouslySetInnerHTML={{ __html: addBtn.help }} />}
          </p>
        )}
      </>
    );
  };

  return GenericAccountList;
};
