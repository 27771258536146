import React, { useEffect, useCallback, useMemo } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Cell, Column } from 'react-table';
import { useParentSizeContext, Loading } from '@tradingblock/components';
import { ReleaseNotes, ReleaseNotesBaseEntity } from '@tradingblock/types';
import { useDispatcher } from '../../../data/global/hooks';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { InfoDataActions } from '../../../data/global/actions/InfoActions';
import { useReleaseNotesData } from '../useInfo';
import { releaseNotesRequestKey } from '../../../data/global/utilities/infoUtilities';
import { BlockTable } from '../../shared/BlockTable';

const ReleaseNotesColumns: Column<ReleaseNotesBaseEntity>[] = [
  {
    Header: 'Release Type',
    accessor: v => v.type,
    id: 'type',
    Cell: ({ row }: Cell<ReleaseNotes>) => {
      const type = row.original.type;
      return <span style={{ color: '#f9cd0b' }}>{type && type.replace(/([A-Z])/g, ' $1').trim()}</span>;
    },
  },
  {
    Header: 'Release Date',
    accessor: v => v.releaseDate,
    id: 'releaseDate',
    Cell: ({ row }: Cell<ReleaseNotes>) => {
      const dateVal = dayjs(row.original.releaseDate);
      if (dateVal && dateVal.isValid()) {
        return dateVal.format('MM/DD/YYYY');
      }
    },
  },
  {
    Header: 'Summary',
    accessor: v => v.summary,
    id: 'summary',
  },
];

export const ReleaseNotesTable: React.FunctionComponent<{}> = () => {
  const { dispatch } = useDispatcher();
  const { page, pageSize } = useReleaseNotesData();
  const requestKey = releaseNotesRequestKey({ page, pageSize });
  const { isFetching, data, total } = useStateSelector(
    s => s.information.releaseNotes[requestKey] || { isFetching: true, data: [], total: 0 }
  );
  const newReleaseNote = useStateSelector(s => s.information.releaseNoteUpdate);

  const hasData = data && data.length > 0;

  useEffect(() => {
    dispatch(InfoDataActions.requestReleaseNotes({ page, pageSize }));
  }, [newReleaseNote]);

  const initialState = useMemo(() => {
    return {
      pageSize: _.isUndefined(pageSize) ? 5 : pageSize,
      pageIndex: page || 0,
      sortBy: [],
      columnOrder: [],
    };
  }, [pageSize, page]);

  const pageCount = useMemo(() => {
    if (pageSize) {
      return _.ceil(total / pageSize);
    }
    return 0;
  }, [pageSize, total]);

  const onPage = useCallback((page: number) => {
    dispatch(InfoDataActions.setReleaseNotesPage({ page }));
  }, []);

  const onPageSize = useCallback((pageSize: number) => {
    dispatch(InfoDataActions.setReleaseNotesPageSize({ pageSize }));
  }, []);

  return (
    <>
      {isFetching && <Loading />}
      <useParentSizeContext.Provider>
        {!isFetching && hasData && (
          <>
            <BlockTable
              tableKey="releaseNotes"
              columns={ReleaseNotesColumns}
              numColumns={3}
              data={data}
              initialState={initialState}
              hasPagination
              manualPagination
              pageCount={pageCount}
              onPage={onPage}
              onPageSize={onPageSize}
            />
          </>
        )}
        {!isFetching && !hasData && (
          <>
            <h5>
              <i className="fal fa-folder-open" /> No matching items found
            </h5>
          </>
        )}
      </useParentSizeContext.Provider>
    </>
  );
};
