import { SymbolQuote } from '@tradingblock/api';
import { formatNumber, Loading } from '@tradingblock/components';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { getFavoriteSymbols, useStateSelector } from '../../data/global/dataSelectors';
import { getQuoteMetadata } from '../../data/global/selectors/quoteSelector';
import { QuoteDataActions } from '../../data/global/actions';
import { useDispatcher } from '../../data/global/hooks';
import { useDashboardSettings } from '../../hooks/useDashboardSettings';
import { useQuoteActions } from '../../hooks/useQuoteData';
import { AssetSymbol, AssetType } from '@tradingblock/types';
import { useFeedActions } from '../../data/feed/useFeedActions';
export interface DashboardSymbolWidgetProps {
  symbol?: string;
}
const centeringStyle = { paddingLeft: '1rem', alignItems: 'center' };
const widgetSymbolsStyle = { color: 'ghostwhite', fontWeight: 'bold' } as object; //very strange necessary cast

export const DashboardSymbolWidget: FC<DashboardSymbolWidgetProps> = ({ symbol }): JSX.Element => {
  const quote: SymbolQuote = useStateSelector(s => getQuoteMetadata(s, symbol ? symbol : ''));
  const changePercentStyle = { color: quote && quote.NetChange > 0.0 ? 'var(--teal)' : 'var(--salmon)' };
  const { dispatch } = useDispatcher();
  const { dispatcher } = useDispatcher();
  const { showBannerSymbols, bannerSymbols } = useDashboardSettings();
  const { addQuote } = useQuoteActions();
  const widgetContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    fontSize: `0.75rem`,
    ...centeringStyle,
  } as object;

  const apiToken = useStateSelector(state => state.auth.apiToken);
  const accountId = useStateSelector(state => state.account.accountId);
  const { subscribeQuote } = useFeedActions(apiToken || '');

  useEffect(() => {
    dispatch(QuoteDataActions.requestQuote({ symbol: bannerSymbols }));
    addQuote(symbol ? symbol : '');
    subscribeQuote(accountId, { kind: 'Quote', symbols: [bannerSymbols] });
  }, [dispatch, bannerSymbols]);

  const change = useRef(0.0);

  if (quote && quote.NetChange) {
    change.current = quote.NetChange ? quote.NetChange : 0.0;
  }

  const widgetCells = quote ? (
    <span style={widgetContainerStyle}>
      <span style={widgetSymbolsStyle}>
        {symbol}
        &nbsp;
        {formatNumber(quote.LastOrClosePrice, { currency: true }).replace('$', '')}&nbsp;
        <span style={changePercentStyle}>
          {change.current && (
            <i className={`fas fa-xs fa-triangle ${change.current < 0.0 ? 'fas fa-flip-vertical' : ''}`}></i>
          )}
          {formatNumber(change.current)}
        </span>
      </span>
    </span>
  ) : (
    <div style={{ ...centeringStyle, ...widgetContainerStyle, fontSize: 'inherit' }}>
      <Loading size="small" />
    </div>
  );
  if (!showBannerSymbols) return <></>;
  else return widgetCells;
};
