import { Middleware, Dispatch } from 'redux';
import { PayloadMetaAction, TypeConstant } from 'typesafe-actions';
import _ from 'lodash';
import { DataState } from '../state';
import { RootAction } from '../actions';

const debounced: { [actionType: string]: NodeJS.Timer } = {};

function isDebouncedAction<T extends TypeConstant, P, M extends { debounce: number }>(
  action: RootAction | PayloadMetaAction<T, P, M>
): action is PayloadMetaAction<T, P, M> {
  const debounce = _.get(action, 'meta.debounce', undefined);
  if (_.isNil(debounce)) {
    return false;
  }
  return true;
}

export const debounceMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = () => (
  next: Dispatch<RootAction>
) => (action: RootAction) => {
  if (isDebouncedAction(action)) {
    const time = action.meta && action.meta.debounce;
    if (!time) {
      return next(action);
    } else {
      const existing = debounced[action.type];
      if (existing) {
        clearTimeout(existing);
      }
      debounced[action.type] = setTimeout(() => {
        next(action);
      }, time);
    }
  } else {
    next(action);
  }
};
