import { WithTables } from './state/blockTable/BlockTableState';

import { IBlockDataState, IBlockState } from '@tradingblock/types';

import { BlockTableAction } from './state/blockTable/BlockTableActions';
import { BlockTablesReducer } from './state/blockTable/BlockTableReducer';
import _ from 'lodash';
import { fromJS } from 'immutable';
import { BlockActions, BLOCK_IS_LOADING, BLOCK_INITIALIZE_DATA, BlockAction } from './state/BlockState';
import { getType } from 'typesafe-actions';

export function blockReducer<T extends IBlockDataState = any, S = any, A extends BlockActions = BlockActions>(
  state: IBlockState<T, S>,
  action: A
): IBlockState<T, S> {
  switch (action.type) {
    case getType(BlockAction.setSettings): {
      return {
        ...state,
        settings: action.payload,
      };
    }
    case BLOCK_IS_LOADING: {
      return {
        ...state,
        data: {
          ...state.data,
          isLoaded: false,
        },
      };
    }
    case 'initialize':
      return {
        ...state,
        ...action.payload,
        data: {
          ...action.payload.data,
          isLoaded: true,
        },
      };
    case 'setTitle':
      return {
        ...state,
        title: action.payload,
      };
    case 'setGroup':
      return {
        ...state,
        groupId: action.payload,
      };
    case 'setTab':
      return {
        ...state,
        tabs: _.map(state.tabs, t => ({ ...t, active: t.title === action.payload.title })),
      };
    case 'setData':
      const isCurrentlyDirty = state && state.data && _.isBoolean(state.data.isDirty) ? state.data.isDirty : undefined;
      const isDirty = action.payload.options && action.payload.options.persist === true ? true : isCurrentlyDirty;
      const newState = fromJS(state.data)
        .set('isDirty', isDirty)
        .toJS();

      const updatedData: any = _.set(newState, action.payload.field, action.payload.value);
      return {
        ...state,
        data: { ...state.data, ...updatedData },
      };
    case BLOCK_INITIALIZE_DATA:
    case 'updateData':
      const { payload } = action;
      let dataValue = action.type === 'updateData' ? action.payload.data : payload;
      let settings: any = {};
      if (action.type === BLOCK_INITIALIZE_DATA && dataValue.settings) {
        settings = dataValue.settings;
        dataValue = _.omit(dataValue, 'settings');
      }
      if (action.type === 'updateData' && payload.options && payload.options.persist) {
        dataValue = _.set(dataValue, 'isDirty', true);
      }

      return {
        ...state,
        data: {
          ...state.data,
          ...dataValue,
          isLoaded: true,
        },
        settings: {
          ...state.settings,
          ...settings,
        },
      };

    default:
      return state;
  }
}

export function BlockReducerWithTables<T extends WithTables<IBlockDataState>, S = any>(
  baseReducer: (state: IBlockState<T, S>, action: any) => IBlockState<T, any>
) {
  return (state: IBlockState<T, S>, action: BlockTableAction) => {
    const baseState = baseReducer(state, action);
    const finalState = BlockTablesReducer(baseState, action);
    return finalState;
  };
}
