import { createStandardAction, ActionType } from 'typesafe-actions';
import { CashieringSearchResponse, ApiResponse, CashieringSearchRequest } from '@tradingblock/types';
import { buildGenericRequestReceiveActions } from '../actionUtilities';

const CASHIERING_SEARCH_PENDING_REQUEST = '@cashiering/search/pending/REQUEST';
const CASHIERING_SEARCH_PENDING_RECEIVE = '@cashiering/search/pending/RECEIVE';
const CASHIERING_SEARCH_PENDING_ERROR = '@cashiering/search/pending/ERROR';

const CASHIERING_PENDING_POLL_START = '@cashiering/pending/poll/START';
const CASHIERING_PENDING_POLL_STOP = '@cashiering/pending/poll/STOP';

const [
  cashieringPendingSearchRequest,
  cashieringPendingSearchReceive,
  cashieringPendingSearchError,
] = buildGenericRequestReceiveActions({
  REQUEST: CASHIERING_SEARCH_PENDING_REQUEST,
  RECEIVE: CASHIERING_SEARCH_PENDING_RECEIVE,
  ERROR: CASHIERING_SEARCH_PENDING_ERROR,
})<CashieringSearchRequest, { response: ApiResponse<CashieringSearchResponse>; request: CashieringSearchRequest }>();

export const CashieringEventActions = {
  cashieringPendingSearchRequest,
  cashieringPendingSearchReceive,
  cashieringPendingSearchError,
  beginPollingForPending: createStandardAction(CASHIERING_PENDING_POLL_START)<{ timeoutMS: number }>(),
  stopPollingForPending: createStandardAction(CASHIERING_PENDING_POLL_STOP)(),
};

export type CashieringEventAction = ActionType<typeof CashieringEventActions>;
