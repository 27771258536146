import React, { useRef } from 'react';
import useClickAway from 'react-use/lib/useClickAway';

export const NotificationWrapper: React.FunctionComponent<{ onClickAway: () => void }> = ({ children, onClickAway }) => {
  const popoverRef = useRef(null);
  useClickAway(popoverRef, onClickAway);
  return (
    <div className="popover-body" ref={popoverRef}>
      {children}
    </div>
  );
};
