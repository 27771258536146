import dayjs from 'dayjs';
import _ from 'lodash';
import { DataFeedDataProvider } from '@tradingblock/tradingview';
import { ApiFactory, GetBarsOptions, Bar, ITradingBlockApi } from '@tradingblock/api';
import { stringOrQuery } from '@tradingblock/types';
import { MarketCloseTime } from '../../utilities/date';

export type ApiType = ReturnType<typeof ApiFactory>;

interface PeriodBar {
  from: Date;
  to: Date;
  bars: Bar[];
}
interface PeriodBars {
  [period: string]: PeriodBar;
}
interface CachedBars {
  [symbol: string]: PeriodBars;
}
const getError = (reason: string) => ({ reason });

const getAllBarsForPeriod = async (
  api: ITradingBlockApi,
  symbol: string,
  options: Omit<GetBarsOptions<string | Date>, 'symbol'>
) => {
  const { starttime, endtime, period, bars } = options;
  const start = dayjs(starttime || new Date());
  const end = endtime ? dayjs(endtime) : undefined;

  const tryGetBars = (from: dayjs.Dayjs, to: dayjs.Dayjs) => {
    return api.getBars({
      symbol,
      period,
      bars,
      starttime: from.toDate(),
      endtime: to.toDate(),
    });
  };
  const { payload } = await tryGetBars(start, end || dayjs(new Date()));
  let barResult: Bar[] = payload ? [...payload] : [];
  // let minDateValue = _(barResult)
  //   .map(b => dayjs(b.D).valueOf())
  //   .min();
  // let requestCount = 1;
  // while (minDateValue !== undefined && minDateValue > start.valueOf() && requestCount < 5) {
  //   requestCount++;
  //   const currEnd = dayjs(minDateValue);
  //   const moreBarResults = await tryGetBars(start.add(-1, 'day'), currEnd);
  //   barResult.concat(moreBarResults.Payload);
  //   minDateValue = _(barResult)
  //     .map(b => dayjs(b.D).valueOf())
  //     .min();
  // }
  return barResult;
};

export class CachedApi implements DataFeedDataProvider {
  private api: ITradingBlockApi;
  private cache: CachedBars;
  public constructor(tbapi: ITradingBlockApi) {
    this.api = tbapi;
    if (!tbapi) {
      throw getError('API cannot be undefined');
    }
    this.cache = {};
  }
  public marketClose = MarketCloseTime().toDate();
  public getSymbols = (query: stringOrQuery, limit: number = 10) => this.api.getSymbols(query, limit);
  public getBars = async (options: GetBarsOptions<string | Date>) => {
    const { symbol, starttime, endtime, period } = options;
    const symbolVal = _.isString(symbol) ? symbol : _.first(symbol);
    if (symbolVal === undefined) {
      throw getError('symbol must be set');
    } else if (typeof symbol !== 'string') {
      console.warn('symbol isnt a string', symbol);
    }
    const cacheKey = `${symbolVal}-${period}`;
    const symbolData = this.cache[cacheKey];

    const start = dayjs(starttime || new Date());
    if (starttime === undefined) {
      throw getError('starttime must be set');
    }
    const end = dayjs(endtime || new Date());
    const symbolPeriodData = symbolData ? _.get(symbolData, period, undefined) : undefined;
    if (symbolPeriodData && dayjs(symbolPeriodData.from).valueOf() <= start.valueOf()) {
      const cachedResults =
        starttime === undefined
          ? symbolPeriodData.bars
          : _.filter(symbolPeriodData.bars, b => dayjs(b.D) >= start && dayjs(b.D) <= end);
      if (cachedResults !== undefined && cachedResults.length !== 0) {
        console.info(
          `cached data for ${symbol} - ${period} exists, but refetching anyway. CachedData: `,
          cachedResults
        );
        //return cachedResults;
      }
    }
    const bars = await getAllBarsForPeriod(this.api, symbolVal, options).then(barResults => {
      return _.map(barResults, b => {
        return {
          ...b,
          //open
          O: _.round(b.O, 2),
          //high
          H: _.round(b.H, 2),
          //low
          L: _.round(b.L, 2),
          //close
          C: _.round(b.C, 2),
          //volume
          V: _.round(b.V, 2),
        };
      });
    });
    this.cache = {
      ...this.cache,
      [cacheKey]: {
        ...symbolData,
        [period]: {
          from: start.toDate(),
          to: end.toDate(),
          bars,
        },
      },
    };
    return bars;
  };
  public getQuotes = async (symbols: string[]) => {
    const { payload } = await this.api.quotes.getChartingFeed(symbols);
    return payload;
  };
}
