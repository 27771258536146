import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import _ from 'lodash';
import { DataState } from '../../state';
import { getIdNumber } from '@tradingblock/api';
import { accountManagementRequestKey } from '../../utilities/accountManagementUtilities';

export const getRequestKey = createSelector(
  (state: DataState) => state.private.accountManagement.ui,
  uiState => accountManagementRequestKey(uiState)
);

// export const getUpdateDetailKey = (type: any)
export const getAccountUpdateSearchEntities = createCachedSelector(
  (state: DataState) => state.private.accountManagement.updates,
  (state: DataState) => state.private.accountManagement.byQuery,
  (state: DataState) => state.private.accountManagement.ui,
  getRequestKey,
  (updates, queryState, uiState, requestKey) => {
    const allValues = queryState[requestKey] ? queryState[requestKey].ids : [];

    return (
      _(allValues)
        .map(id => updates[id])
        .filter(v => v !== undefined)
        // .drop(page * pageSize)
        // .take(pageSize)
        .value()
    );
  }
)(getRequestKey);

export const getAccountManagementSearchFilterdata = createCachedSelector(
  (state: DataState) => state.private.accountManagement.ui,
  getRequestKey,
  (uiState, requestKey) => {
    return { ...uiState };
  }
)(getRequestKey);

export const getAccountManagementSearchTotal = createCachedSelector(
  (state: DataState) => state.private.accountManagement.updates,
  (state: DataState) => state.private.accountManagement.byQuery,
  (state: DataState) => state.private.accountManagement.ui,
  getRequestKey,
  (updates, queryState, uiState, requestKey) => {
    const total = queryState[requestKey] ? queryState[requestKey].total : 0;
    return total;
  }
)(getRequestKey);

export const AccountManagementSearchRequestSelector = createCachedSelector(
  (d: DataState) => d.private.accountManagement.ui,
  (d: DataState) => accountManagementRequestKey(d.private.accountManagement.ui),
  (request, key) => {
    return { request, key };
  }
)((d: DataState) => accountManagementRequestKey(d.private.accountManagement.ui));

export const isFetchingAccountManagementSearchUpdates = createCachedSelector(
  (state: DataState) => state.private.accountManagement.byQuery,
  getRequestKey,
  (queryState, requestKey) => {
    const qState = queryState[requestKey];
    const isFetching = qState ? qState.isFetching : true;
    const hasData = qState && !isFetching && qState.ids.length > 0;
    return [isFetching, hasData];
  }
)(getRequestKey);

export const currentItemAccountManagementSearchUpdates = createCachedSelector(
  (state: DataState) => state.private.accountManagement.currentItem,
  getRequestKey,
  (queryState, requestKey) => {
    console.log({ queryState, requestKey });
  }
)(getRequestKey);
