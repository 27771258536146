import { Balances, Transfer, BalanceTotals } from '@tradingblock/types';
import { getChangePercent } from '../../../utilities/data';

export const getBalanceTotals = (balances: Balances, pendingTransfers?: Transfer[]): BalanceTotals => {
  const { Balances, Securities, BuyingPower, MarginRequirements } = balances;

  // total balance value (used in sums below but not returned)
  const balanceValue = getBalanceValue(Balances);

  const positionValue = getPositionValue(Securities);

  const accountValue = balanceValue + positionValue;

  const todaysChange = getTodaysChangeValue(Balances, Securities);

  // api doesn't return YTD change yet...
  const ytdChange = 0;

  const todaysChangePercent = getChangePercent(accountValue, todaysChange);
  const ytdChangePercent = getChangePercent(accountValue, ytdChange);

  const cashAndCashAlts = getCashAndCashAlts(balances);

  const pendingTransactions = getPendingTransactions(BuyingPower);

  const availableForTrading = getAvailableForTrading(BuyingPower);

  const availableForWithdrawal = getAvailableForWithdrawal(balances);

  const nonmarginableFunds = getNonmarginableFunds(Balances, MarginRequirements);

  const cashBalance = Balances.CashBalance;

  const marginBalance = Balances.MarginBalance;

  // unclearedDeposits/unsettledCash values not being used, might not be calculated correctly...

  // const unclearedDeposits = getUnclearedDeposits(Balances);

  // const unsettledCash = getUnsettledCash(BuyingPower, unclearedDeposits);

  return {
    accountValue,
    positionValue,
    cashAndCashAlts,
    todaysChange,
    ytdChange,
    todaysChangePercent,
    ytdChangePercent,
    pendingTransactions,
    availableForTrading,
    availableForWithdrawal,
    nonmarginableFunds,
    cashBalance,
    marginBalance,
    // unclearedDeposits,
    // unsettledCash,
  };
};

const getBalanceValue = (balances: Balances['Balances']): number => {
  if (balances) {
    return balances.MarginBalance + balances.ShortBalance + balances.CashBalance;
  }
  return 0;
};

const getPositionValue = (securities: Balances['Securities']): number => {
  if (securities) {
    return (
      securities.LongStockValue +
      securities.ShortStockValue +
      securities.LongOptionValue +
      securities.ShortOptionValue +
      securities.MutualFundsValue +
      securities.BondsAndCDValue
    );
  }
  return 0;
};

const getTodaysChangeValue = (balances: Balances['Balances'], securities: Balances['Securities']): number => {
  let value = 0;
  if (balances) {
    value += balances.CashBalanceTodaysChange + balances.MarginBalanceTodaysChange + balances.ShortBalanceTodaysChange;
  }
  if (securities) {
    value +=
      securities.LongStockValueTodaysChange +
      securities.ShortStockValueTodaysChange +
      securities.LongOptionValueTodaysChange +
      securities.ShortOptionValueTodaysChange +
      securities.MutualFundsValueTodaysChange +
      securities.BondsAndCDValueTodaysChange +
      securities.MoneyMarketFundValueTodaysChange;
  }
  return value;
};

export const getCashAndCashAlts = (balances: Balances): number => {
  const balVal = getBalanceValue(balances.Balances);
  if (balances.Securities) {
    return balVal + balances.Securities.MoneyMarketFundValue;
  }
  return balVal;
};

const getPendingTransactions = (buyingPower: Balances['BuyingPower']): number => {
  if (buyingPower) {
    return buyingPower.PendingTransactions;
  }
  return 0;
};

const getAvailableForTrading = (buyingPower: Balances['BuyingPower']): number => {
  if (buyingPower) {
    return buyingPower.AvailableFunds;
  }
  return 0;
};

const getAvailableForWithdrawal = (balances: Balances): number => {
  if (balances.Balances) {
    return balances.Balances.WithdrawalAmount;
  }
  return 0;
};

const getNonmarginableFunds = (
  balances: Balances['Balances'],
  marginRequirements: Balances['MarginRequirements']
): number => {
  if (balances && marginRequirements) {
    return balances.MarginBalance * (1 - marginRequirements.MarginableEquityPercent);
  }
  return 0;
};

const getUnclearedDeposits = (balances: Balances['Balances']): number => {
  if (balances) {
    return balances.WithdrawalAmount;
  }
  return 0;
};

const getUnsettledCash = (buyingPower: Balances['BuyingPower'], unclearedDeposits: number): number => {
  if (buyingPower) {
    return buyingPower.AvailableFunds - unclearedDeposits;
  }
  return 0;
};
