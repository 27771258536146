import { Middleware, Dispatch } from 'redux';
import { PayloadMetaAction, TypeConstant } from 'typesafe-actions';
import _ from 'lodash';
import { encode } from '@tradingblock/api';
import { DataState } from './../state';
import { uiApiToken } from '../dataSelectors';
import { RootAction } from '../actions';
import { StorageProviderFactory } from '../../../context/Storage';
import Config from '../../../config';

function isMetaAction<T extends TypeConstant, P, M extends { verbose?: boolean }>(
  action: RootAction | PayloadMetaAction<T, P, M>
): action is PayloadMetaAction<T, P, M> {
  const meta = _.get(action, 'meta', undefined);
  if (_.isNil(meta)) {
    return false;
  }
  return true;
}

function isWhitelisted(action: RootAction) {
  if (Config.eventLogWhitelist === undefined || Config.eventLogWhitelist.length === 0) {
    return false;
  }
  return _.some(Config.eventLogWhitelist, rg => {
    return action.type ? action.type.match(new RegExp(rg)) !== null : false;
  });
}

const tryLog = async (token: string, action: any) => {
  try {
    const provider = StorageProviderFactory(Config.uiApi, token);
    await provider.log({ value: encode(action) });
  } catch (err) {
    console.warn('EventLog errored');
  }
};

export const eventLogMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = middleware => (
  next: Dispatch<RootAction>
) => async (action: RootAction) => {
  const isVerbose = isMetaAction(action) && action.meta.verbose;
  const valid = isWhitelisted(action);
  if (isVerbose === false && valid === true) {
    const state = middleware.getState();
    const token = uiApiToken(state);
    if (token) {
      setTimeout(() => tryLog(token, action), 1000);
    }
  }

  next(action);
};
