import React, { useMemo } from 'react';
import { AssetSymbol } from '@tradingblock/types';
import { Loading, Number } from '@tradingblock/components';
import { useStateSelector } from '../../data/global/dataSelectors';
import { quoteSelectors, quoteExtendedSelectors } from '../../data/global/selectors/quoteSelector';

export const CompanyInfo: React.FunctionComponent<{ symbol: string }> = ({ symbol }) => {
  const extendedQuote = useStateSelector(s => quoteExtendedSelectors.quote(s, symbol));
  const isLoading = useStateSelector(s => quoteExtendedSelectors.isLoading(s, symbol));

  return (
    <>
      {isLoading && extendedQuote === undefined && <Loading />}
      {extendedQuote && (
        <>
          <p>
            <strong>{extendedQuote.companyName}</strong>
          </p>

          <div className="columns columns2">
            <div className="column">
              <ul className="props props-aligned-icons">
                <li className="prop-inline">
                  <span className="mute prop-title txt-sm">
                    <i className="fal fa-map-marked-alt" title="Postal address" />
                  </span>
                  <span className="prop-value">
                    {extendedQuote.address1 && extendedQuote.city && extendedQuote.state && (
                      <>
                        {extendedQuote.address1}
                        <br />
                        {extendedQuote.address2 ? extendedQuote.address2 && <br /> : null}
                        {extendedQuote.city}, {extendedQuote.state} {extendedQuote.zip}
                        <br />
                        {extendedQuote.country}
                      </>
                    )}
                  </span>
                </li>
                <li className="prop-inline">
                  <span className="mute prop-title txt-sm">
                    <i className="fal fa-phone" title="Phone" />
                  </span>
                  <span className="prop-value">
                    <a href="tel:{extendedQuote.phone}">{extendedQuote.phone}</a>
                  </span>
                </li>
                <li className="prop-inline">
                  <span className="mute prop-title txt-sm">
                    <i className="fal fa-globe" title="Website" />
                  </span>
                  <span className="prop-value">
                    <a href={extendedQuote.website} target="blank" title={extendedQuote.companyName}>
                      {extendedQuote.website}
                    </a>
                  </span>
                </li>
              </ul>
            </div>
            <div className="column">
              <ul className="props props-aligned">
                <li className="prop-inline">
                  <span className="mute prop-title txt-sm">Sector</span>
                  <span className="prop-value">{extendedQuote.sector}</span>
                </li>
                <li className="prop-inline">
                  <span className="mute prop-title txt-sm">Industry</span>
                  <span className="prop-value">{extendedQuote.industry}</span>
                </li>
                <li className="prop-inline">
                  <span className="mute prop-title txt-sm">CEO</span>
                  <span className="prop-value">{extendedQuote.ceo}</span>
                </li>
                <li className="prop-inline">
                  <span className="mute prop-title txt-sm">Employees</span>
                  {extendedQuote.employees && (
                    <span className="prop-value">
                      <Number value={extendedQuote.employees} format integer />
                    </span>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </>
      )}
    </>
  );
};
