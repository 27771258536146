import React, { useCallback, useMemo } from 'react';
import { Cell, Row } from 'react-table';
import _ from 'lodash';
import dayjs from 'dayjs';
import { OrderMessage } from '@tradingblock/types';
import { isString } from '@tradingblock/api';
import { isMarketOpenSelector, useStateSelector } from '../../../data/global/dataSelectors';
import { isBetween, isToday } from '../../../utilities/date';
import { getOrderMessages } from '../../../data/global/selectors/orderSelector';
import { useActivityTimeframeValues } from '../data/useActivityTimeframe';
import { useActivityBlockData } from '../useActivitySettings';
import { useDispatcher } from '../../../data/global/hooks';
import { Actions } from '../../../data/global/actions';
import { useBlockId } from '../../../components/blocks/BlockState';
import { BlockTable } from '../../shared/BlockTable';
import { EmptyBlockContent } from '@tradingblock/components';

type NotificationViewDTO = Notification & {
  display: {
    trClass: string;
  };
};
export const ActivityMessagesTable = () => {
  const { dispatch } = useDispatcher();
  const blockId = useBlockId();
  // Get the start and endDate
  const { startDate, endDate } = useActivityTimeframeValues();
  const accountNumber = useStateSelector(s => s.account.accountNumber);
  const { pageSize, pageIndex, total } = useActivityBlockData();
  const isFetchingBlockOrders = useStateSelector(
    s => s.account.ordersUIState[blockId] && s.account.ordersUIState[blockId].isFetching
  );

  const initialState = useMemo(() => {
    if (pageSize * pageIndex > total) {
      dispatch(Actions.setOrdersRequestPageIndex({ pageIndex: 0, blockId }));
      return {
        pageSize: pageSize,
        pageIndex: 0,
        sortBy: [],
        columnOrder: [],
      };
    } else
      return {
        pageSize: _.isUndefined(pageSize) ? 50 : pageSize,
        pageIndex: pageIndex || 0,
        sortBy: [],
        columnOrder: [],
      };
  }, [pageSize, pageIndex, total]);

  const pageCount = useMemo(() => {
    if (pageSize) {
      return _.ceil((total || 0) / pageSize) === 0 ? 1 : _.ceil((total || 0) / pageSize);
    }
    return 0;
  }, [total, pageSize]);

  const onPage = useCallback((pageIndex: number) => {
    dispatch(Actions.setOrdersRequestPageIndex({ pageIndex, blockId }));
  }, []);

  const onPageSize = useCallback((pageSize: number) => {
    dispatch(Actions.setOrdersRequestPageSize({ pageSize, blockId }));
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Message',
        canSort: false,
        accessor: (a: OrderMessage) => a.description,
        id: 'message',
        Cell: ({ row }: Cell<OrderMessage>) => {
          const { message } = row.original;
          const marketOpen = useStateSelector(s => isMarketOpenSelector(s));
          const isAfterHours = !marketOpen;
          const isNewOrInitiatedOrder =
            row.original.status === 'Order New' || row.original.status === 'Order Initiated';
          const messages = isString(message) ? [message] : message;
          return (
            <>
              {row.original.status}{' '}
              {messages.map((m, i) => (
                <React.Fragment key={`${row.id}.${i}`}>
                  <br />
                  {m}
                  {isAfterHours && isNewOrInitiatedOrder && (
                    <>
                      <br />
                      <span className="txt-sm error">Warning: Order is queued for the next open trading session.</span>
                    </>
                  )}
                </React.Fragment>
              ))}
            </>
          );
        },
      },
      {
        Header: 'Account',
        accessor: () => accountNumber,
        id: 'accountNumber',
        canSort: false,
      },
      {
        Header: 'Order ID',
        accessor: (a: OrderMessage) => a.id,
        canSort: true,
        sortType: 'alphanumeric',
        id: 'orderId',
        Cell: ({ row }: Cell<OrderMessage>) => {
          return row.original.id === -1 ? '' : row.original.id;
        },
      },
      {
        Header: 'Received on',
        accessor: (a: OrderMessage) => dayjs(a.received).format('MM/DD/YY h:mm:ss.SSS A'),
        id: 'received_on',
        canSort: true,
        sortType: 'basic',
        Cell: ({ row }: Cell<OrderMessage>) => {
          const recievedOn = dayjs(row.original.received).format(
            isToday(row.original.received) ? 'h:mm:ss.SSS A' : 'MM/DD/YY h:mm:ss.SSS A'
          );
          return recievedOn;
        },
      },
    ],
    []
  );
  const messages = useStateSelector(getOrderMessages(blockId));
  const timeFilteredMessages = useMemo(() => {
    return _.filter(messages, (n: OrderMessage) => {
      if ((startDate as Date) === (endDate as Date) || startDate === undefined || endDate === undefined) {
        return true;
      }
      return isBetween(n.received, startDate, endDate);
    })
      .map(d => ({ ...d, display: { trClass: '' } }))
      .sort((a, b) => dayjs(b.received).valueOf() - dayjs(a.received).valueOf());
  }, [endDate, messages, startDate]);
  const loaded = useMemo(() => timeFilteredMessages !== undefined, [timeFilteredMessages]);
  const renderSubRow = React.useCallback(
    (row: Row<NotificationViewDTO>) => (
      <tr className="row-expanded row-sub">
        <td>{row}</td>
      </tr>
    ),
    []
  );

  return (
    <>
      {!isFetchingBlockOrders &&
        (timeFilteredMessages.length ? (
          <BlockTable
            tableKey="orders"
            columns={columns}
            numColumns={columns.length}
            data={timeFilteredMessages}
            initialState={initialState}
            renderSubRow={renderSubRow}
            loaded={loaded}
            sortable
            hasPagination
            manualPagination
            pageCount={pageCount}
            onPage={onPage}
            onPageSize={onPageSize}
            frozenHeaders={true}
          />
        ) : (
          <EmptyBlockContent>No messages for current timeframe.</EmptyBlockContent>
        ))}
    </>
  );
};
