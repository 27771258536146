import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Loading, formatNumber, onDateSort, Modal, EmptyBlockContent, ContactPhone } from '@tradingblock/components';
import { CashieringRoute, CashieringView, Transfer } from '@tradingblock/types';
import { getTransferStatusText, getTransferMethod } from '../../utilities/cashiering';
import { BlockTable } from '../shared/BlockTable';
import { cashieringSelectors, accountIdSelector, useStateSelector, account } from '../../data/global/dataSelectors';
import { useDispatcher } from '../../data/global/hooks';
import { CashieringEntityStatus } from '../../components/cashiering/CashieringEntityStatus';

export const AccountTransferContent: React.FunctionComponent<{}> = () => {
  const { dispatcher } = useDispatcher();

  const accountId = useSelector(accountIdSelector);
  const userName = useStateSelector(s => s.auth.userName);
  // even though option to add a cashiering block should be hidden, also hide action btns as additional precaution
  const accountCanAccessCashiering = useSelector(account.accountCanAccessCashiering);
  const transfers = useSelector(cashieringSelectors.transfers);
  const newTransferId = useSelector(cashieringSelectors.newTransferId);
  const isLoadingTransfers = useStateSelector(s => cashieringSelectors.dataIsLoading(s, 'transfers'));
  const achRelationships = useSelector(cashieringSelectors.achRelationships);
  const isLoadingAchRelationships = useStateSelector(s => cashieringSelectors.dataIsLoading(s, 'achRelationships'));
  const transferInstructions = useSelector(cashieringSelectors.transferInstructions);
  const isLoadingTransferInstructions = useStateSelector(s =>
    cashieringSelectors.dataIsLoading(s, 'transferInstructions')
  );
  const originalAccountId = useStateSelector(
    s => s.account && s.account.selectedLinkedAccount && s.account.selectedLinkedAccount.originalAccount.accountId
  );
  const accountStatus = useStateSelector(s => s.account.accountDetails && s.account.accountDetails.accountStatus)
  const targetedAccountStatuses = accountStatus === 'Rejected' || accountStatus === 'Closed' || accountStatus === 'Closed Never Funded';
  const rejectMsg = "Please note that the account is not eligible to make cashiering requests at the moment. Please contact the support team for more information."
  // track if the accountId changes (e.g. when switching accounts)
  const [currentAccountId, setCurrentAccountId] = useState<number>();
  const [fetchData, setFetchData] = useState(false);
  useEffect(() => {
    if (accountId !== currentAccountId) {
      setCurrentAccountId(accountId);
      setFetchData(true);
    }
  }, [accountId, currentAccountId, dispatcher.cashiering]);

  const [transferIdToDelete, setTransferIdToDelete] = useState<number>();

  useEffect(() => {
    if (originalAccountId && originalAccountId !== accountId && fetchData) {
      if (!isLoadingTransfers) {
        dispatcher.cashiering.transfers.all.request();
      }
      if (!isLoadingAchRelationships) {
        dispatcher.cashiering.achRelationships.all.request(accountId);
      }
      if (!isLoadingTransferInstructions) {
        dispatcher.cashiering.transferInstructions.all.request(accountId);
      }
      setFetchData(false);
    }
  }, [
    accountId,
    originalAccountId,
    isLoadingTransfers,
    isLoadingAchRelationships,
    isLoadingTransferInstructions,
    dispatcher.cashiering,
    fetchData,
  ]);

  useEffect(() => {
    // fetch transfers shown in block content (refetch until new transfer is returned in list)
    if (
      accountId &&
      !isLoadingTransfers &&
      ((!newTransferId && !transfers) || (newTransferId && !_.some(transfers, t => t.id === newTransferId)))
    ) {
      dispatcher.cashiering.transfers.all.request();
    }
  }, [accountId, transfers, newTransferId, isLoadingTransfers, dispatcher.cashiering]);

  useEffect(() => {
    // need related ach relationships for display
    if (accountId && !isLoadingAchRelationships && !achRelationships) {
      dispatcher.cashiering.achRelationships.all.request(accountId);
    }
  }, [accountId, achRelationships, isLoadingAchRelationships, dispatcher.cashiering]);

  useEffect(() => {
    // need related transfer instructions for display
    if (accountId && !isLoadingTransferInstructions && !transferInstructions) {
      dispatcher.cashiering.transferInstructions.all.request(accountId);
    }
  }, [accountId, transferInstructions, isLoadingTransferInstructions, dispatcher.cashiering]);

  const onDelete = useCallback(() => {
    if (transferIdToDelete) {
      const comment = `Cancelled by Client (${userName})`;
      dispatcher.cashiering.transfers.delete.request({ accountId, transferId: transferIdToDelete, comment });
      setTransferIdToDelete(undefined);
    }
  }, [accountId, userName, transferIdToDelete, dispatcher.cashiering]);

  const columns = useMemo(
    () => [
      {
        Header: 'Transaction',
        id: 'transaction',
        Cell: ({ row }: Cell<Transfer>) => (
          <>
            {row.original.disbursementType &&
            (row.original.disbursementType === 'FullBalance' ||
              row.original.disbursementType === 'FullBalanceAndCloseAccount')
              ? 'FULL BALANCE'
              : formatNumber(
                  row.original.amount
                    ? row.original.amount
                    : row.original.requestedAmount
                    ? row.original.requestedAmount
                    : 0,
                  { currency: true }
                )}{' '}
            <span className="caps">{row.original.direction === 'Outgoing' ? 'Withdrawal' : 'Deposit'}</span>
            <br />
            <CashieringEntityStatus status={row.original.state}>
              {getTransferStatusText(row.original)}
            </CashieringEntityStatus>
          </>
        ),
      },
      {
        Header: 'Notes',
        id: 'info',
        Cell: ({ row }: Cell<Transfer>) => <span className="mute">{row.original.note}</span>,
      },
      {
        Header: 'Method',
        id: 'method',
        Cell: ({ row }: Cell<Transfer>) => getTransferMethod(row.original, { achRelationships }),
      },
      {
        Header: 'Transaction Date',
        id: 'createDate',
        canSort: true,
        sortType: onDateSort,
        sortDescFirst: true,
        sortInverted: true,
        Cell: ({ row }: Cell<Transfer>) => dayjs(row.original.createDate).format('MM/DD/YY h:mm A'),
      },
      {
        Header: ' ',
        id: 'actions',
        Cell: ({ row }: Cell<Transfer>) => {
          if (row.original.state === 'PendingRepCreateApproval') {
            return (
              <button
                className="btn btn-blend"
                type="button"
                title="Cancel"
                onClick={() => setTransferIdToDelete(row.original.id)}
              >
                <i className="fas fa-2x fa-times-circle" />
              </button>
            );
          }
          return '';
        },
      },
    ],
    [achRelationships]
  );

  const data = useMemo(() => {
    return _.map(_.orderBy(transfers, t => t.createDate, 'desc'), t => ({
      ...t,
      display: t.state === 'Canceled' ? { trClass: 'mute' } : undefined,
    }));
  }, [transfers]);

  return (
    <>
      {accountCanAccessCashiering && !targetedAccountStatuses && (
        <p>
          <Link className="btn btn-outline-light" to={`/${CashieringRoute.Withdraw}#${CashieringView.Authorize}`}>
            <i className="fas fa-inbox-out" /> Withdraw funds
          </Link>{' '}
          <Link className="btn btn-outline-light" to={`/${CashieringRoute.Deposit}#${CashieringView.Authorize}`}>
            <i className="fas fa-inbox-in" /> Fund account
          </Link>{' '}
          <Link className="btn btn-outline-light" to={`/${CashieringRoute.Accounts}#${CashieringView.Authorize}`}>
            <i className="fas fa-university" /> Manage accounts
          </Link>{' '}
        </p>
      )}
      { transfers && transfers.length !== 0 && targetedAccountStatuses && (
        <p className='mute'>
          {rejectMsg} <ContactPhone />
        </p>
      )}
      {(!transfers || isLoadingTransfers) && <Loading size="small" />}
      {transfers && transfers.length !== 0 ? 
        <BlockTable
          tableKey="transfers"
          sortable
          columnWidths={['22%', '33%', '20%', '20%', '%5']}
          frozenHeaders
          numColumns={_.size(columns)}
          columns={columns}
          data={data}
        />
       :
       targetedAccountStatuses ? 
          <EmptyBlockContent >No transfers for current timeframe.<br/>{rejectMsg} <ContactPhone /></EmptyBlockContent> 
          : 
          <EmptyBlockContent >No transfers for current timeframe.</EmptyBlockContent>
        
    }

      {transferIdToDelete && (
        <Modal show={true} setShow={() => true} backdrop="static">
          <BootstrapModal.Header>
            <BootstrapModal.Title>Confirm</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            <p>Are you sure you want to cancel this transfer?</p>
          </BootstrapModal.Body>
          <BootstrapModal.Footer className="modal-footer-justified">
            <Button variant="secondary" onClick={() => setTransferIdToDelete(undefined)}>
              Go back
            </Button>
            <Button variant="danger" onClick={onDelete}>
              Cancel transfer
            </Button>
          </BootstrapModal.Footer>
        </Modal>
      )}
    </>
  );
};


