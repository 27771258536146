import { ActionType, getType } from 'typesafe-actions';
import { Middleware, Dispatch } from 'redux';
import _ from 'lodash';
import { DataState } from '../../../state';
import { RootAction } from '../../../actions';
import { AdminClientAction, AdminClientActions } from '../../../actions/admin/AdminClientActions';
import { clientList } from '../../../selectors/admin/clientListSelectors';
import { Dispatcher } from '../../../dispatcher';
import { ApiProvider } from '../../../../../context/Api';

const clientSearch = (state: DataState, dispatch: Dispatch<RootAction>) => {
  const api = ApiProvider(state, dispatch);
  return {
    request: (action: ActionType<typeof AdminClientActions.clientRequest>) => {
      return api.accounts
        .search(action.payload)
        .then(resp => {
          dispatch(AdminClientActions.clientReceive({ response: resp, request: action.payload }));
        })
        .catch(err => {
          dispatch(AdminClientActions.clientError(err));
        });
    },
  };
};

export const AdminClientMiddleware: Middleware<Dispatch<AdminClientAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<AdminClientAction>) => (action: AdminClientAction) => {
  try {
    // state BEFORE action is dispatched
    const prevState = getState();
    const result = next(action);
    const nextState = getState();

    const search = clientSearch(prevState, dispatch);
    try {
      switch (action.type) {
        case getType(AdminClientActions.setPage):
        case getType(AdminClientActions.setPageSize):
        case getType(AdminClientActions.setSortBy):
        case getType(AdminClientActions.setSearch): {
          const prevRequestKey = clientList.requestKey(prevState);
          const { request, key } = clientList.requestKey(nextState);
          if (prevRequestKey.key === key) {
            //request hasn't changed
            break;
          }
          dispatch(AdminClientActions.clientRequest(request));
          break;
        }
        case getType(AdminClientActions.clientRequest): {
          search.request(action);
          break;
        }
      }
    } catch (apiError) {
      Dispatcher(dispatch).global.exception({ error: apiError, data: action });
    }

    return result;
  } catch (err) {
    console.error('AdminClientMiddleware :: Caught an exception for action ', action, err);
  }
};
