import { ActionType, createStandardAction } from 'typesafe-actions';
import {
  CashieringEntityRequest,
  CashieringSearchRequest,
  ApiResponse,
  CashieringSearchResponse,
  GenericError,
  TransferBeneficiary,
  TransferRecipientBank,
  ClientEntity,
  CashieringEntityChangeRequest,
  TransferIntermediary,
  TransferInstruction,
} from '@tradingblock/types';
import { actionBuilder, buildGenericRequestReceiveActions } from '../../actionUtilities';
import { AdminCashieringTransferActions } from './AdminCashieringTransferActions';
import { AdminCashieringRelationshipActions } from './AdminCashieringRelationshipActions';
import { AdminIdRequest } from '.';

const ADMIN_CASHIERING_SEARCH_REQUEST = '@admin/cashiering/search/data/REQUEST';
const ADMIN_CASHIERING_SEARCH_RECEIVE = '@admin/cashiering/search/data/RECEIVE';
const ADMIN_CASHIERING_SEARCH_ERROR = '@admin/cashiering/search/data/ERROR';

const ADMIN_CASHIERING_SEARCH_SET_SEARCH = '@admin/cashiering/search/ui/SET_SEARCH';
const ADMIN_CASHIERING_SET_REP_CODE = '@admin/cashiering/repCode/ui/SET_REP_CODE';
const ADMIN_CASHIERING_SEARCH_SET_TIMEFRAME = '@admin/cashiering/search/ui/SET_TIMEFRAME';
const ADMIN_CASHIERING_SEARCH_SET_TYPE = '@admin/cashiering/search/ui/SET_TYPE';
const ADMIN_CASHIERING_SEARCH_SET_STATUS = '@admin/cashiering/search/ui/SET_STATUS';
const ADMIN_CASHIERING_SET_ACCOUNT_STATUS = '@admin/cashiering/accountStatus/ui/SET_ACCOUNT_STATUS';
const ADMIN_CASHIERING_SEARCH_SET_PAGE = '@admin/cashiering/search/ui/SET_PAGE';
const ADMIN_CASHIERING_SEARCH_SET_PAGE_SIZE = '@admin/cashiering/search/ui/SET_PAGE_SIZE';
const ADMIN_CASHIERING_SEARCH_SET_SORTBY = '@admin/cashiering/search/ui/SET_SORTBY';

const ADMIN_CASHIERING_WIREINSTRUCTIONS_REQUEST = '@admin/cashiering/wireInstructions/REQUEST';
const ADMIN_CASHIERING_WIREINSTRUCTIONS_RECEIVE = '@admin/cashiering/wireInstructions/RECEIVE';
const ADMIN_CASHIERING_WIREINSTRUCTIONS_ERROR = '@admin/cashiering/wireInstructions/ERROR';

const ADMIN_CASHIERING_WIREINSTRUCTIONSDETAILS_REQUEST = '@admin/cashiering/wireInstructionsDetails/REQUEST';
const ADMIN_CASHIERING_WIREINSTRUCTIONSDETAILS_RECEIVE = '@admin/cashiering/wireInstructionsDetails/RECEIVE';
const ADMIN_CASHIERING_WIREINSTRUCTIONSDETAILS_ERROR = '@admin/cashiering/wireInstructionsDetails/ERROR';

const ADMIN_CASHIERING_WIREINSTRUCTIONS_UPDATE_REQUEST = '@admin/cashiering/wireInstructionsUpdate/REQUEST';
const ADMIN_CASHIERING_WIREINSTRUCTIONS_UPDATE_RECEIVE = '@admin/cashiering/wireInstructionsUpdate/RECEIVE';
const ADMIN_CASHIERING_WIREINSTRUCTIONS_UPDATE_ERROR = '@admin/cashiering/wireInstructionsUpdate/ERROR';

const ADMIN_CASHIERING_APPROVE_WIREINSTRUCTIONS_REQUEST = '@admin/cashiering/approveWireInstructions/REQUEST';
const ADMIN_CASHIERING_APPROVE_WIREINSTRUCTIONS_RECEIVE = '@admin/cashiering/approveWireInstructions/RECEIVE';
const ADMIN_CASHIERING_APPROVE_WIREINSTRUCTIONS_ERROR = '@admin/cashiering/approveWireInstructions/ERROR';

const ADMIN_CASHIERING_REJECT_WIREINSTRUCTIONS_REQUEST = '@admin/cashiering/rejectWireInstructions/REQUEST';
const ADMIN_CASHIERING_REJECT_WIREINSTRUCTIONS_RECEIVE = '@admin/cashiering/rejectWireInstructions/RECEIVE';
const ADMIN_CASHIERING_REJECT_WIREINSTRUCTIONS_ERROR = '@admin/cashiering/rejectWireInstructions/ERROR';

const ADMIN_CASHIERING_CANCEL_WIREINSTRUCTIONS_REQUEST = '@admin/cashiering/cancelWireInstructions/REQUEST';
const ADMIN_CASHIERING_CANCEL_WIREINSTRUCTIONS_RECEIVE = '@admin/cashiering/cancelWireInstructions/RECEIVE';
const ADMIN_CASHIERING_CANCEL_WIREINSTRUCTIONS_ERROR = '@admin/cashiering/cancelWireInstructions/ERROR';

const [cashieringSearchRequest, cashieringSearchReceive, cashieringSearchError] = buildGenericRequestReceiveActions({
  REQUEST: ADMIN_CASHIERING_SEARCH_REQUEST,
  RECEIVE: ADMIN_CASHIERING_SEARCH_RECEIVE,
  ERROR: ADMIN_CASHIERING_SEARCH_ERROR,
})<CashieringSearchRequest, { response: ApiResponse<CashieringSearchResponse>; request: CashieringSearchRequest }>();

export const AdminCashieringSearchActions = {
  cashieringSearchRequest,
  cashieringSearchReceive,
  cashieringSearchError,
  setSearch: createStandardAction(ADMIN_CASHIERING_SEARCH_SET_SEARCH)<Pick<CashieringSearchRequest, 'search'>>(),
  setRepCode: createStandardAction(ADMIN_CASHIERING_SET_REP_CODE)<Pick<CashieringSearchRequest, 'repCode'>>(),
  setTimeframe: createStandardAction(ADMIN_CASHIERING_SEARCH_SET_TIMEFRAME)<
    Pick<CashieringSearchRequest, 'startDate' | 'endDate'>
  >(),
  setType: createStandardAction(ADMIN_CASHIERING_SEARCH_SET_TYPE)<Pick<CashieringSearchRequest, 'types'>>(),
  setStatus: createStandardAction(ADMIN_CASHIERING_SEARCH_SET_STATUS)<Pick<CashieringSearchRequest, 'status'>>(),
  setAccountStatus: createStandardAction(ADMIN_CASHIERING_SET_ACCOUNT_STATUS)<
    Pick<CashieringSearchRequest, 'accountStatus'>
  >(),
  setPage: createStandardAction(ADMIN_CASHIERING_SEARCH_SET_PAGE)<Pick<CashieringSearchRequest, 'page'>>(),
  setPageSize: createStandardAction(ADMIN_CASHIERING_SEARCH_SET_PAGE_SIZE)<Pick<CashieringSearchRequest, 'pageSize'>>(),
  setSortBy: createStandardAction(ADMIN_CASHIERING_SEARCH_SET_SORTBY)<
    Pick<CashieringSearchRequest, 'sortBy' | 'sortByOrder'>
  >(),
  refresh: createStandardAction('@admin/cashiering/search/ui/REFRESH')<CashieringSearchRequest>(),
};

const [
  cashieringWireInstructionsRequest,
  cashieringWireInstructionsReceive,
  cashieringWireInstructionsError,
] = buildGenericRequestReceiveActions({
  REQUEST: ADMIN_CASHIERING_WIREINSTRUCTIONS_REQUEST,
  RECEIVE: ADMIN_CASHIERING_WIREINSTRUCTIONS_RECEIVE,
  ERROR: ADMIN_CASHIERING_WIREINSTRUCTIONS_ERROR,
})<CashieringEntityRequest, ApiResponse<TransferInstruction>, GenericError<any, AdminIdRequest>>();

const [
  cashieringWireInstructionsUpdateRequest,
  cashieringWireInstructionsUpdateReceive,
  cashieringWireInstructionsUpdateError,
] = buildGenericRequestReceiveActions({
  REQUEST: ADMIN_CASHIERING_WIREINSTRUCTIONS_UPDATE_REQUEST,
  RECEIVE: ADMIN_CASHIERING_WIREINSTRUCTIONS_UPDATE_RECEIVE,
  ERROR: ADMIN_CASHIERING_WIREINSTRUCTIONS_UPDATE_ERROR,
})<
  CashieringEntityRequest & { nickName?: string; note?: string; adminNote?: string; alertRep?: boolean },
  ApiResponse<TransferInstruction>,
  GenericError<any, AdminIdRequest>
>();

const [
  cashieringWireInstructionsDetailsRequest,
  cashieringWireInstructionsDetailsReceive,
  cashieringWireInstructionsDetailsError,
] = buildGenericRequestReceiveActions({
  REQUEST: ADMIN_CASHIERING_WIREINSTRUCTIONSDETAILS_REQUEST,
  RECEIVE: ADMIN_CASHIERING_WIREINSTRUCTIONSDETAILS_RECEIVE,
  ERROR: ADMIN_CASHIERING_WIREINSTRUCTIONSDETAILS_ERROR,
})<CashieringEntityRequest, ApiResponse<TransferInstruction>, GenericError<any, AdminIdRequest>>();

const [
  cashieringWireInstructionsApproveRequest,
  cashieringWireInstructionsApproveReceive,
  cashieringWireInstructionsApproveError,
] = buildGenericRequestReceiveActions({
  REQUEST: ADMIN_CASHIERING_APPROVE_WIREINSTRUCTIONS_REQUEST,
  RECEIVE: ADMIN_CASHIERING_APPROVE_WIREINSTRUCTIONS_RECEIVE,
  ERROR: ADMIN_CASHIERING_APPROVE_WIREINSTRUCTIONS_ERROR,
})<
  CashieringEntityChangeRequest,
  { instructionId: number; data: ApiResponse<TransferInstruction> },
  GenericError<any, AdminIdRequest>
>();

const [
  cashieringWireInstructionsRejectRequest,
  cashieringWireInstructionsRejectReceive,
  cashieringWireInstructionsRejectError,
] = buildGenericRequestReceiveActions({
  REQUEST: ADMIN_CASHIERING_REJECT_WIREINSTRUCTIONS_REQUEST,
  RECEIVE: ADMIN_CASHIERING_REJECT_WIREINSTRUCTIONS_RECEIVE,
  ERROR: ADMIN_CASHIERING_REJECT_WIREINSTRUCTIONS_ERROR,
})<
  CashieringEntityChangeRequest,
  { instructionId: number; data: ApiResponse<TransferInstruction> },
  GenericError<any, AdminIdRequest>
>();

const [
  cashieringWireInstructionsCancelRequest,
  cashieringWireInstructionsCancelReceive,
  cashieringWireInstructionsCancelError,
] = buildGenericRequestReceiveActions({
  REQUEST: ADMIN_CASHIERING_CANCEL_WIREINSTRUCTIONS_REQUEST,
  RECEIVE: ADMIN_CASHIERING_CANCEL_WIREINSTRUCTIONS_RECEIVE,
  ERROR: ADMIN_CASHIERING_CANCEL_WIREINSTRUCTIONS_ERROR,
})<CashieringEntityChangeRequest & { note?: string; adminNote?: string }, ApiResponse<TransferInstruction>>();

const AdminCashieringEntityActions = {
  cashieringWireInstructionsRequest,
  cashieringWireInstructionsReceive,
  cashieringWireInstructionsError,
  cashieringWireInstructionsUpdateRequest,
  cashieringWireInstructionsUpdateReceive,
  cashieringWireInstructionsUpdateError,
  cashieringWireInstructionsDetailsRequest,
  cashieringWireInstructionsDetailsReceive,
  cashieringWireInstructionsDetailsError,
  cashieringWireInstructionsApproveRequest,
  cashieringWireInstructionsApproveReceive,
  cashieringWireInstructionsApproveError,
  cashieringWireInstructionsRejectRequest,
  cashieringWireInstructionsRejectReceive,
  cashieringWireInstructionsRejectError,
  cashieringWireInstructionsCancelRequest,
  cashieringWireInstructionsCancelReceive,
  cashieringWireInstructionsCancelError,
};

export const AdminCashieringActions = {
  ...AdminCashieringTransferActions,
  ...AdminCashieringRelationshipActions,
  ...AdminCashieringEntityActions,
  ...AdminCashieringSearchActions,
};

export type AdminCashieringAction = ActionType<typeof AdminCashieringActions>;
