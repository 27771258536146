import { EmptyBlockContent, FrozenHeaderTable, onDateSort } from '@tradingblock/components';
import { BalanceHistory, BalanceHistoryItem } from '@tradingblock/types';
import React, { useMemo } from 'react';
import { Cell } from 'react-table';
import { Timeframe } from '../../components/basic/Timeframe';
import { useBlockId } from '../../components/blocks/BlockState';
import { useStateSelector } from '../../data/global/dataSelectors';
import { formatMoney } from './AnalyticsHelper';

interface AnalyticsCashFlowProps {
  filteredBalances: BalanceHistory[];
  dateRange: {
    start: Date;
    end: Date;
  };
  handleTimeFrame: (start: Date | undefined, end: Date | undefined) => void;
  pageSize?: number;
  pageIndex?: number;
  total?: number;
  handlePageSizeChange: (pageSize: number) => void;
}
type cashFlowTableObjectData = Pick<BalanceHistoryItem, 'TransferAmount' | 'RecordDate' | 'TransferDirection'>;

//a tab within performance/analytics block
export const AnalyticsCashFlow: React.FC<AnalyticsCashFlowProps> = ({
  filteredBalances,
  dateRange,
  handleTimeFrame,
  pageSize,
  pageIndex,
  total,
  handlePageSizeChange,
}: AnalyticsCashFlowProps): JSX.Element => {
  const [page, setPage] = React.useState(0);

  const initialState = useMemo(() => {
    return {
      pageSize: pageSize === undefined ? 50 : pageSize,
      pageIndex: page || 0,
      sortBy: [],
      columnOrder: [],
    };
  }, [pageSize, pageIndex]);
  const pageCount = useMemo(() => {
    if (pageSize) {
      return Math.ceil((total || 0) / pageSize) === 0 ? 1 : Math.ceil((total || 0) / pageSize);
    }
    return 0;
  }, [total, pageSize]);

  let cashFlows: any = [];

  filteredBalances.filter(balanceHistoryItem => {
    if (balanceHistoryItem.cashieringTransfers !== null && balanceHistoryItem.cashieringTransfers !== undefined) {
      balanceHistoryItem.cashieringTransfers.forEach(cashieringTransfer => {
        cashFlows.push({
          recordDate: balanceHistoryItem.recordDate,
          ...cashieringTransfer,
        });
      });
      return balanceHistoryItem.cashieringTransfers;
    }
    if (balanceHistoryItem.dividends !== null && balanceHistoryItem.dividends !== undefined) {
      balanceHistoryItem.dividends.forEach(dividend => {
        cashFlows.push({
          recordDate: balanceHistoryItem.recordDate,
          ...dividend,
        });
      });
      return balanceHistoryItem.dividends;
    }
  });

  // sort cashflows so that the most recent date is at the top
  cashFlows.sort((a: any, b: any) => {
    return new Date(b.recordDate).getTime() - new Date(a.recordDate).getTime();
  });

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        canSort: true,
        sortType: onDateSort,
        accessor: (accessData: any) => {
          return new Date(accessData.recordDate).toLocaleDateString();
        },
        id: 'recordDate',
      },
      {
        Header: 'Amount',
        accessor: (accessData: any) => {
          return formatMoney(accessData.amount);
        },
        id: 'amount',
        Cell: ({ value }: Cell) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: 'Type',
        accessor: (accessData: any) => {
          if (!accessData.type) {
            return accessData.direction;
          } else {
            return accessData.type;
          }
        },
        id: 'type',
        Cell: ({ value }: Cell) => {
          return (
            <div
              style={{
                color:
                  value === 'Incoming' ||
                  value === 'Deposit' ||
                  value === 'Dividend' ||
                  value === 'CapitalGainsDistribution' ||
                  value === 'Interest'
                    ? 'rgb(2,255,246)'
                    : 'rgb(237,103,103',
              }}
            >
              {/* need to add space between capitalized words in value */}
              {value && value.replace(/([A-Z])/g, ' $1').trim()}
            </div>
          );
        },
      },
      {
        Header: 'Description',
        accessor: (accessData: any) => {
          if (accessData.direction) {
            return {
              description: 'Cash Transfer',
              identifier: '',
              identifierType: '',
            };
          } else {
            return {
              description: accessData.description,
              identifier: accessData.identifier,
              identifierType: accessData.identifierType,
            };
          }
        },
        id: 'description',
        Cell: ({ value }: Cell) => {
          return (
            <div>
              {value.description && `${value.description} `}
              {value.identifier && `(${value.identifier}) `}
              {value.identifierType === 'Cusip' ? `(${value.identifierType})` : ''}
            </div>
          );
        },
      },
    ],
    [filteredBalances, dateRange]
  );

  return (
    <>
      {cashFlows.length > 0 && (
        <>
          <FrozenHeaderTable
            tableKey="cashFlow"
            sortable={true}
            columnWidths={['20%', '20%', '20%', '40%']}
            columns={columns}
            hasPagination={true}
            pageCount={pageCount}
            initialState={initialState}
            data={cashFlows}
            frozenHeaders={true}
            onPage={() => setPage(page + 1)}
            onPageSize={handlePageSizeChange}
          />
        </>
      )}
      {cashFlows.length === 0 && (
        <EmptyBlockContent title="No Cash Flows on Record">
          <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <i className="fas fa-2x fa-search-dollar" /> No cash flows on record for the selected time period.
          </span>
        </EmptyBlockContent>
      )}
    </>
  );
};

export default AnalyticsCashFlow;
