import { useCallback } from 'react';
import { OrderLegDTO, AssetType, SaveOptions } from '@tradingblock/types';
import { getCustomStrategyLegQuantity } from '@tradingblock/api';
import { useOrderSettings } from '../hooks/useOrderSettings';
import {
  useBlockActions,
  useBlockData,
  useBlockActionDispatchAndGenericActions,
} from '../../../components/blocks/BlockState';
import { DefaultLegs } from '../OrderUtilities';
import { OrderState } from './OrderState';
import { Actions, OrderActions } from './OrderActions';
import { useGroupAssetSymbol } from '../../useGroupState';
import { useOrderActions } from './useOrderActions';

export const useLegsUpdateAction = () => {
  const { setSubstate } = useBlockActions<OrderState>();
  const update = useCallback(
    (orderLeg: OrderLegDTO, options?: SaveOptions | undefined) => {
      setSubstate('legs', orderLeg.Id, orderLeg, options);
    },
    [setSubstate]
  );
  return { update };
};

export const useLegsActions = () => {
  const symbol = useGroupAssetSymbol();
  const settings = useOrderSettings();
  const { legs, quantity } = useBlockData<OrderState>();
  const [{ updateData }, dispatch] = useBlockActionDispatchAndGenericActions<OrderState, OrderActions>();
  const { setDebitCredit, setQuantity } = useOrderActions();

  // const updatePartial = useCallback(
  //   (legsToUpdate: PartialOrderLegDTO[], options?: SaveOptions | undefined) => {
  //     const updated = _.reduce(
  //       legs,
  //       (updated, l, k) => {
  //         const updatedLeg = _.find(legsToUpdate, incLeg => incLeg.Id === l.Id) || {};
  //         return {
  //           ...updated,
  //           [k]: {
  //             ...l,
  //             ...updatedLeg,
  //           },
  //         };
  //       },
  //       {} as Legs
  //     );
  //     setField('legs', updated, options);
  //   },
  //   [setField, legs, symbol]
  // );
  const add = useCallback(
    (assetType: AssetType, options?: SaveOptions) => {
      const initialValues = {
        assetType,
        spreadRatio: getCustomStrategyLegQuantity(assetType, settings),
      };

      const updatedLegs = {
        ...legs,
        ...DefaultLegs(legs, symbol, initialValues),
      };

      // Define the quantity settings based on assetType
      const settingsQuantity =
        assetType === AssetType.Option
          ? settings.defaultQuantityForOptionsAndStrategies
          : settings.defaultQuantityForShares;

      const originalLegKeys = Object.keys(legs);
      const updatedLegKeys = Object.keys(updatedLegs);

      if (updatedLegKeys.length > 1) {
        updatedLegKeys.forEach(legId => {
          const leg = updatedLegs[legId];
          const isOriginalLeg = originalLegKeys.includes(legId);

          // Adjust SpreadRatio based on the number of original legs
          if (originalLegKeys.length === 1) {
            leg.SpreadRatio = isOriginalLeg ? quantity : settingsQuantity;
          } else {
            leg.SpreadRatio = leg.SpreadRatio || 1; // Default to 1 if not set
          }
        });
      }

      // Update data with new quantities and recalculated prices
      updateData(
        {
          quantity: originalLegKeys.length === 0 ? settingsQuantity : 1,
          legs: updatedLegs,
          recalculatePrice: true,
        },
        options
      );
    },
    [updateData, legs, symbol, settings]
  );

  const remove = useCallback(
    (id: string) => {
      const legKeys = Object.keys(legs);

      // Check if the number of legs is exactly two before removal
      if (legKeys.length === 2) {
        // Find the remaining leg that is not being removed
        const remainingLegKey = legKeys.find(key => key !== id);

        if (remainingLegKey) {
          // Ensure the key is not undefined
          const remainingLeg = legs[remainingLegKey];

          if (remainingLeg) {
            // Update the quantity to the spread ratio of the remaining leg or default to 1
            const newQuantity = remainingLeg.SpreadRatio || 1;
            setQuantity(newQuantity, { persist: true });

            // Update remaining leg's SpreadRatio to 1 and adjust Quantity
            updateData(
              {
                legs: {
                  ...legs,
                  [remainingLegKey]: {
                    ...remainingLeg,
                    SpreadRatio: 1,
                    Quantity: newQuantity * quantity,
                  },
                },
                recalculatePrice: true,
              },
              { persist: true }
            );
          }
        }
      }

      // Dispatch the action to remove the leg
      dispatch(Actions.removeLeg({ id }, { persist: true }));

      // Recalculate debit or credit after modification
      setDebitCredit();
    },
    [updateData, legs, dispatch, setDebitCredit, setQuantity, quantity]
  );

  return {
    add,
    remove,
  };
};
