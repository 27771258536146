import React from 'react';
import _ from 'lodash';
import { CashieringView, Transfer, AchRelationship } from '@tradingblock/types';
import { formatNumber } from '@tradingblock/components';
import { getFormattedAchRelationship, isNotYetCreatedOrPendingStatus } from '../../utilities/cashiering';
import { CashieringEntityOwnerNames } from './CashieringEntityOwnerNames';

interface AccountDepositDisplayProps {
  nickname: string;
  availableForWithdrawal: number;
  positions?: number;
  transfer: Partial<Transfer> | undefined;
  achRelationship: AchRelationship | undefined;
  adminReview?: boolean;
  isPendingFullWithdrawal?: boolean;
  gotoNextView?: (view: CashieringView) => void;
}

export const AccountDepositDisplay: React.FC<AccountDepositDisplayProps> = ({
  nickname,
  availableForWithdrawal,
  positions,
  transfer,
  achRelationship,
  adminReview,
  gotoNextView,
  children,
}) => {
  return (
    <>
      <div className="screen-body-section">
        <p className="fields-title">How much to deposit?</p>
        <p>
          <span className="mute">Depositing to:</span> {nickname}. <span className="mute">Current balance:</span>{' '}
          {formatNumber(availableForWithdrawal, { currency: true })}.{' '}
          {!_.isUndefined(positions) && positions !== 0 && (
            <>
              <span className="mute">Positions:</span> {formatNumber(positions, { currency: true })}.
            </>
          )}
        </p>

        <p>
          <span className="mute">Amount:</span>
          <br />
          {transfer && transfer.amount && formatNumber(transfer.amount, { currency: true })}
        </p>

        {gotoNextView && (
          <p>
            <button type="button" className="btn-link" onClick={() => gotoNextView(CashieringView.Type)}>
              <i className="fal fa-pencil" /> Change
            </button>
          </p>
        )}
      </div>

      <div className="screen-body-section">
        <p className="fields-title">How {adminReview ? '' : 'would you like'} to deposit the funds?</p>
        {transfer && transfer.mechanism === 'Ach' && (
          <>
            <>
              <span>Linked bank account:</span>
              <br />
              {achRelationship && getFormattedAchRelationship(achRelationship)}
            </>
            {adminReview && transfer.id && <CashieringEntityOwnerNames id={transfer.id} type="transfers" />}
            {adminReview && achRelationship && isNotYetCreatedOrPendingStatus(achRelationship.state) && (
              <p className="warn">This relationship is pending.</p>
            )}
          </>
        )}

        {gotoNextView && (
          <p>
            <button type="button" className="btn-link" onClick={() => gotoNextView(CashieringView.Type)}>
              <i className="fal fa-pencil" /> Change
            </button>
          </p>
        )}
      </div>

      {children}
    </>
  );
};
