import * as tslib_1 from "tslib";
import React, { useCallback, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { useExpanded, useSortBy, useTable, useColumnOrder, usePagination } from 'react-table';
import { Loading, calculateColumnWidth, getTableClass } from '..';
import { BaseTableBody, BaseTableHead } from './BaseTable';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import { Paging } from './Paging';
export var positionBlockHeadersMapObject = {
  Cost: 'Cost',
  Value: 'Value',
  Gain: 'Gain',
  'Daily P/L': 'Daily P/L',
  Actions: 'Actions',
  Description: 'description',
  Close: 'close',
  Last: 'last',
  Change: 'change',
  '(%)': '%',
  'Avg Price': 'avg_price',
  Bid: 'bid',
  Ask: 'ask',
  Shares: 'shares',
  Expiration: 'expander',
  Options: 'options',
  Settlement: 'Settlement'
};
/**
 * This implements react-table that has a sticky header
 * when you scroll
 */

export var FrozenHeaderTable = function FrozenHeaderTable(_a) {
  var columns = _a.columns,
      data = _a.data,
      loaded = _a.loaded,
      initialState = _a.initialState,
      className = _a.className,
      responsiveDisabled = _a.responsiveDisabled,
      sortable = _a.sortable,
      numColumns = _a.numColumns,
      columnWidths = _a.columnWidths,
      onSort = _a.onSort,
      onColumnOrderChanged = _a.onColumnOrderChanged,
      hasPagination = _a.hasPagination,
      manualPagination = _a.manualPagination,
      manualSortBy = _a.manualSortBy,
      pageCount = _a.pageCount,
      onPage = _a.onPage,
      onPageSize = _a.onPageSize,
      searchTerm = _a.searchTerm,
      searchOptionsPage = _a.searchOptionsPage,
      searchOptionsSearchTerm = _a.searchOptionsSearchTerm,
      tableKey = _a.tableKey,
      expandRows = _a.expandRows,
      isDraggable = _a.isDraggable,
      firstRowExpanded = _a.firstRowExpanded,
      bodyProps = tslib_1.__rest(_a, ["columns", "data", "loaded", "initialState", "className", "responsiveDisabled", "sortable", "numColumns", "columnWidths", "onSort", "onColumnOrderChanged", "hasPagination", "manualPagination", "manualSortBy", "pageCount", "onPage", "onPageSize", "searchTerm", "searchOptionsPage", "searchOptionsSearchTerm", "tableKey", "expandRows", "isDraggable", "firstRowExpanded"]);

  var plugins = hasPagination ? [useSortBy, useExpanded, usePagination] : [useSortBy, useExpanded];
  var tableInstance = useTable.apply(void 0, [{
    columns: columns,
    data: data,
    initialState: initialState,
    autoResetExpanded: false,
    autoResetSortBy: false,
    autoResetPage: false,
    manualSortBy: manualSortBy,
    manualPagination: manualPagination,
    pageCount: pageCount
  }, useColumnOrder].concat(plugins));
  var getTableProps = tableInstance.getTableProps,
      getTableBodyProps = tableInstance.getTableBodyProps,
      headerGroups = tableInstance.headerGroups,
      rows = tableInstance.rows,
      setColumnOrder = tableInstance.setColumnOrder; // We need to update the column order manually because react-table rerender memos the columnOrder, and on lock and unlock causes a rerender of the base table but
  // the column order is not updated. So we manually update it here on column order reference.

  useEffect(function () {
    if (initialState !== undefined && initialState.columnOrder !== undefined) {
      setColumnOrder(initialState.columnOrder || []);
    }
  }, [initialState ? initialState.columnOrder : false]); // We need to update the page index manually for the favorites table due to how the favorites table is implemented
  // Due to the data retrieval returning all the data, we need to manually chunk the data into pages and then manually
  // update the page index/pageSize on initial load

  useEffect(function () {
    if (initialState !== undefined && initialState.pageIndex !== undefined && tableInstance.state.pageIndex !== initialState.pageIndex && (tableKey === 'favorites' || tableKey === 'balanceHistory')) {
      tableInstance.state.pageIndex = initialState.pageIndex;
    }
  }, [initialState ? initialState.pageIndex : false]);
  useEffect(function () {
    if (initialState !== undefined && initialState.pageSize !== undefined && tableInstance.state.pageSize !== initialState.pageSize && tableKey === 'favorites') {
      tableInstance.state.pageSize = initialState.pageSize;
    }
  }, [initialState ? initialState.pageSize : false]);
  useEffect(function () {
    if (firstRowExpanded) tableInstance.toggleRowExpanded(['0'], true);
  }, []);
  useEffect(function () {
    // if pageIndex is not set, or is NaN, for a favorites table set it to 0
    if (_.isNaN(tableInstance.state.pageIndex && tableKey === 'favorites')) {
      tableInstance.state.pageIndex = 0;
    }
  }, [tableInstance.state.pageIndex, tableKey]);
  useEffect(function () {
    if (initialState !== undefined && initialState.sortBy !== undefined && tableInstance.state.sortBy !== initialState.sortBy && tableKey === 'favorites') {
      tableInstance.state.sortBy = initialState.sortBy;
    }
  }, [initialState ? initialState.sortBy : false]);
  var tableProps = getTableProps();
  var tableClassName = getTableClass(className, tableProps.className, responsiveDisabled);

  var resyncPositionHeaders = function resyncPositionHeaders(table_) {
    if (table_ && table_.state && table_.state.columnOrder.find(function (val) {
      return val === 'Expiration';
    })) {
      var positionBlockHeaders = table_.state.columnOrder.map(function (header) {
        return positionBlockHeadersMapObject[header];
      });
      table_.setColumnOrder(positionBlockHeaders);
    }
  };

  useUpdateEffect(function () {
    if (onSort) {
      onSort(tableInstance.state.sortBy);
    }
  }, [tableInstance.state.sortBy]);
  useUpdateEffect(function () {
    if (onColumnOrderChanged) {
      onColumnOrderChanged(tableInstance.state.columnOrder);
    }
  }, [tableInstance.state.columnOrder]);
  var getCustomHeaderStyles = useCallback(function (column, index) {
    return {
      width: !_.isEmpty(columnWidths) ? columnWidths[index] : calculateColumnWidth(numColumns)
    };
  }, [columnWidths, numColumns]);
  var currentPage = useMemo(function () {
    var currPage = hasPagination ? tableInstance.page : tableInstance.rows;
    return currPage;
  }, [hasPagination, tableInstance.page, tableInstance.rows]);
  useUpdateEffect(function () {
    if (onSort) {
      onSort(tableInstance.state.sortBy);
    }
  }, [tableInstance.state.sortBy]);
  useUpdateEffect(function () {
    if (onPage) {
      onPage(_.isNaN(tableInstance.state.pageIndex) ? 0 : tableInstance.state.pageIndex);
    }
  }, [tableInstance.state.pageIndex]);
  useUpdateEffect(function () {
    if (onPageSize) {
      onPageSize(tableInstance.state.pageSize);
    }
  }, [tableInstance.state.pageSize]);
  useUpdateEffect(function () {
    if (expandRows) {
      // Line below for forcing table instance to use header ids for positions table.
      resyncPositionHeaders(tableInstance);
      tableInstance.toggleAllRowsExpanded(true);
    } else {
      // Line below for forcing table instance to use header ids for positions table.
      resyncPositionHeaders(tableInstance);
      tableInstance.toggleAllRowsExpanded(false);
    }
  }, [expandRows, tableInstance.toggleAllRowsExpanded, tableInstance.state.columnOrder]);
  return React.createElement(React.Fragment, null, loaded === false && React.createElement(Loading, {
    size: "medium"
  }), loaded !== false && React.createElement("div", {
    className: "table-fixed ".concat(tableClassName)
  }, React.createElement("table", Object.assign({}, getTableProps(), {
    className: "table-fixed-header"
  }), React.createElement(BaseTableHead, {
    headersDraggable: isDraggable,
    headerGroups: headerGroups,
    sortable: sortable,
    getCustomHeaderStyles: getCustomHeaderStyles,
    setColumnOrder: tableInstance.setColumnOrder,
    tableInstance: tableInstance,
    tableKey: tableKey
  }), React.createElement(BaseTableBody, Object.assign({
    className: "table-fixed-body",
    tableInstance: tableInstance,
    currentPage: currentPage,
    responsiveDisabled: responsiveDisabled,
    numColumns: numColumns,
    columnWidths: columnWidths,
    expandRows: expandRows
  }, bodyProps))), hasPagination && React.createElement(Paging, {
    tableInstance: tableInstance,
    search: searchTerm,
    searchOptionPage: searchOptionsPage,
    tableKey: tableKey,
    searchOptionsSearchTerm: searchOptionsSearchTerm
  })));
};