import React from 'react';
import { BlockTabWell } from '../../components/blocks/BlockTabWell';

interface AnalyticsHeaderProps {
    handleDownloadCSV: () => void
}

export const AnalyticsHeader: React.FC<AnalyticsHeaderProps> = ({ handleDownloadCSV }: AnalyticsHeaderProps): JSX.Element =>
(
    <div className='handleDownloadCSVButton'>
        <BlockTabWell />
        <button onClick={handleDownloadCSV}><i title="Download as .CSV" className='fas fa-download'></i></button>
    </div>
)
