import React, { useMemo } from 'react';
import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { useBidAsk } from '../useBidAsk';
import { DropdownItemType, ToggleButton, Number, CSS, Loading } from '@tradingblock/components';
import { OrderState } from '../../state/OrderState';
import { SymbolOccBasicSelector } from '../../state/OrderLegSelector';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { quoteSelectors } from '../../../../data/global/selectors/quoteSelector';
import { BidAskPrice } from '../../../../components/symbol/BidAsk';
import { Directional } from '../../../../components/symbol/Price';
import { useBlockData } from '../../../../components/blocks/BlockState';

const getQuoteHigh = createCachedSelector(quoteSelectors.byId, quote => (quote ? quote.High : undefined))(
  (_, id) => `${id}-high`
);
const getQuoteLow = createCachedSelector(quoteSelectors.byId, quote => (quote ? quote.Low : undefined))(
  (_, id) => `${id}-low`
);
const getQuoteClose = createCachedSelector(quoteSelectors.byId, quote => (quote ? quote.ClosePrice : undefined))(
  (_, id) => `${id}-close`
);

const LegPrice: React.FC<{ value?: number }> = ({ value }) => {
  return (
    <>
      {!_.isNil(value) && (
        <div className={CSS.props.value}>
          <Number value={value} currency />
        </div>
      )}
    </>
  );
};

const DirectionalLegPrice: React.FC<{ value?: number }> = ({ value }) => {
  return (
    <>
      {!_.isNil(value) && (
        <Directional value={value} className={CSS.props.value}>
          <Number value={value} currency />
        </Directional>
      )}
    </>
  );
};

const LegBidQuote: React.FC<{ legId: string }> = ({ legId }) => {
  const { bid } = useBidAsk(legId);
  return <DirectionalLegPrice value={bid} />;
};

const LegAskQuote: React.FC<{ legId: string }> = ({ legId }) => {
  const { ask } = useBidAsk(legId);
  return <DirectionalLegPrice value={ask} />;
};

const LegQuoteHigh: React.FC<{ legId: string }> = ({ legId }) => {
  const blockState = useBlockData<OrderState>();
  const occSymbol = SymbolOccBasicSelector(blockState, legId);
  const High = useStateSelector(s => getQuoteHigh(s, occSymbol || ''));
  return <LegPrice value={High} />;
};

const LegQuoteLow: React.FC<{ legId: string }> = ({ legId }) => {
  const blockState = useBlockData<OrderState>();
  const occSymbol = SymbolOccBasicSelector(blockState, legId);
  const Low = useStateSelector(s => getQuoteLow(s, occSymbol || ''));
  return <LegPrice value={Low} />;
};

// const LegQuoteClose: React.FC<{ legId: string }> = ({ legId }) => {
//   const blockState = useBlockData<OrderState>();
//   const occSymbol = SymbolOccBasicSelector(blockState, legId);
//   const Close = useStateSelector(s => getQuoteClose(s, occSymbol || ''));
//   if (!Close && blockState.price) {
//     return <Loading size={'small'} />;
//   } else {
//     return <LegPrice value={Close} />;
//   }
// };

export const LegPrices = {
  Bid: LegBidQuote,
  Ask: LegAskQuote,
  High: LegQuoteHigh,
  Low: LegQuoteLow,
  // Close: LegQuoteClose,
};
