import { Store } from 'redux';
import { getType } from 'typesafe-actions';
import _ from 'lodash';
import { StateSync } from '@tradingblock/types';
import { LocalStorageProvider, SyncTabStorageKey } from '@tradingblock/storage';
import { getDocumentOptions } from '@tradingblock/components';
import { DataState } from '../state';
import { uiApiToken } from '../dataSelectors';
import { RootAction, Actions } from '../actions';

const localStorageProvider = LocalStorageProvider({});

/**
 * The listener parses back the object stored in the storage and by injecting the actual store
 * We can use a closure and use store.dispatch every time the event is triggered.
 * The middleware needs to be included to the Redux flow and the listener needs to be subscribed.
 */
export const createStorageListener = (store: Store<DataState, RootAction>) => {
  return () => {
    const syncTab = localStorage.getItem(SyncTabStorageKey);
    if (syncTab) {
      const action = JSON.parse(syncTab);
      if (action.createDate && action.sync) {
        // storage event's callback within 1000ms
        if (Date.now() - action.createDate < 1000) {
          switch (action.sync) {
            case StateSync.SIGNOUT: {
              store.dispatch(Actions.signout());
              break;
            }
            case StateSync.EXPIRATION: {
              if (action.payload.syncTab) {
                delete action.sync;
                delete action.createDate;
                store.dispatch(action);
              }
              break;
            }
            default:
              break;
          }
        }
      }
    }
  };
};
