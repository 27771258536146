import React, { useCallback } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Formik, Field } from 'formik';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { FormGroup, TextboxField, Modal, SelectDropdownField } from '@tradingblock/components';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { AccountManagementDataActions } from '../../../data/global/actions/AccountManagementActions';
import { AddressUpdateValueProps, AllStates, Country, getGroupedCountryOptions } from '@tradingblock/types';
import { getInvalidError, getRequiredError, RE_PO_BOX, USPSErrors, uspsVerification } from './Validation';

export const AddressUpdate: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
  addressType: string;
  accountHolderIndex: number;
  accountDetails: any;
}> = ({ show, toggleModal, addressType, accountHolderIndex, accountDetails }) => {
  const accountId = useStateSelector(s => s.accounts.account && s.accounts.account.AccountId);
  const dispatch = useDispatch();
  const { physicalAddress: { address1, address2, city, country, postalCode, state } } = accountDetails;
  const { accountHolders } = useStateSelector(s => s.accountManagement.accountManagementDetails.details);
  const user = accountHolders[accountHolderIndex];
  const initialAddressUpdateValues: AddressUpdateValueProps = {
    address1: user.address.address1 ? user.address.address1 : '',
    address2: user.address.address2 ? user.address.address2 : '',
    city: user.address.city ? user.address.city : '',
    state: user.address.state ? user.address.state : '',
    postalCode: user.address.postalCode ? user.address.postalCode : '',
    country: user.address.country ? user.address.country : '',
    addressType: '', //not used
  };

  let header = '';

  switch (addressType) {
    case 'physical':
      header = 'Physical Address';
      break;
    case 'mail':
      header = 'Mailing Address';
      break;
    default:
      break;
  }

  // TODO: Review localStorage usage
  const onValidate = (values: AddressUpdateValueProps) => {
    const { address1, city, state, country, addressType } = values;
    let uspsResponse;
    let isValidAddress;
    const headerValue = header === 'Physical Address' ? 'physical' : 'mail';
    const isMailing = headerValue === 'mail';
    const stateOrPostalCodeRequired = country === Country.UnitedStatesOfAmerica;

    const uspsResponseFromStorage = localStorage.getItem(`addressVerification-${headerValue}-${accountHolderIndex}`);

    if (uspsResponseFromStorage && uspsResponseFromStorage !== 'undefined') {
      uspsResponse = JSON.parse(uspsResponseFromStorage);
      const { city, state, zip5, error } = uspsResponse;
      const formattedCityCapitalized = _.capitalize(city);
      const startCaseCity = _.startCase(formattedCityCapitalized);
      const isCityEqual = _.toLower(startCaseCity) === _.toLower(values.city);
      const isZipEqual = zip5 === values.postalCode;
      const isStateEqual = state === values.state;
      const isAddressCorrect = !error && isCityEqual && isZipEqual && isStateEqual;

      isAddressCorrect ? (isValidAddress = true) : (isValidAddress = false);
    }

    if (address1 && city && state && country === Country.UnitedStatesOfAmerica && !isValidAddress) {
      const getUSPS = async () => {
        await new Promise(resolve => setTimeout(resolve, 100));
        return await uspsVerification(values);
      };

      getUSPS().then(result => {
        localStorage.setItem(`addressVerification-${headerValue}-${accountHolderIndex}`, JSON.stringify(result));
      });
    }

    if (uspsResponse) {
      const { city, state, zip5, error } = uspsResponse;

      if (error && values.address1 && values.city && values.state && values.postalCode) {
        return {
          [`postalCode`]: `${error.description.trim()} Please review the entered address.`,
          [`city`]: `${error.description.trim()} Please review the entered address.`,
          [`state`]: `${error.description.trim()} Please review the entered address.`,
          [`address1`]: `${error.description.trim()} Please review the entered address.`,
        };
      }

      if (!values.address1 || !values.city) {
        localStorage.setItem(`addressVerification-${headerValue}-${accountHolderIndex}`, 'undefined');
      }

      if (city && state && zip5) {
        const formattedCityCapitalized = _.capitalize(city);
        const startCaseCity = _.startCase(formattedCityCapitalized);
        const isCityEqual = _.toLower(startCaseCity) === _.toLower(values.city);
        const isZipEqual = zip5 === values.postalCode;
        const isStateEqual = state === values.state;

        // USPS + Required Errors

        const errors: USPSErrors = {
          ['country']: getRequiredError(values.country),
          ['address1']:
            getRequiredError(values.address1) && !isMailing
              ? getInvalidError(!RE_PO_BOX.test(values.address1))
              : undefined,
          ['address2']: values.address2 && !isMailing ? getInvalidError(!RE_PO_BOX.test(values.address2)) : undefined,
          ['postalCode']:
            getRequiredError(stateOrPostalCodeRequired ? values.postalCode : true) || !isZipEqual
              ? `Please verify the zip code. USPS Provided Zip Code: ${zip5}`
              : undefined,
          ['city']:
            getRequiredError(values.city) || !isCityEqual
              ? `Please verify the city. USPS Provided City: ${startCaseCity}`
              : undefined,
          ['state']:
            getRequiredError(stateOrPostalCodeRequired ? values.state : true) || !isStateEqual
              ? `Please verify the state. USPS Provided State: ${state}`
              : undefined,
        };

        Object.keys(errors).forEach(key => (errors[key] === undefined ? delete errors[key] : {}));

        return errors;
      }
    }
    // Required Errors
    if (!isMailing) {
      return {
        [`country`]: getRequiredError(values.country),
        [`address1`]: getRequiredError(values.address1) || getInvalidError(!RE_PO_BOX.test(values.address1)),
        [`address2`]: values.address2 && getInvalidError(!RE_PO_BOX.test(values.address2)),
        [`postalCode`]: getRequiredError(stateOrPostalCodeRequired ? values.postalCode : true),
        [`city`]: getRequiredError(values.city),
        [`state`]: getRequiredError(stateOrPostalCodeRequired ? values.state : true),
      };
    } else if (isMailing) {
      return {
        [`country`]: getRequiredError(values.country),
        [`address1`]: getRequiredError(values.address1),
        [`postalCode`]: getRequiredError(stateOrPostalCodeRequired ? values.postalCode : true),
        [`city`]: getRequiredError(values.city),
        [`state`]: getRequiredError(stateOrPostalCodeRequired ? values.state : true),
      };
    }
  };

  const onSubmit = useCallback(
    (values, { resetForm }) => {
      const { address1, address2, city, country, postalCode, state } = values;
      let updateValues = {};

      if (header === 'Physical Address') {
        updateValues = {
          item: 'Address',
          accountHolder: {
            address: {
              address1: address1,
              address2: address2,
              city: city,
              country: country,
              postalCode: postalCode,
              state: state,
            },
          },
          holder: accountHolderIndex,
        };
      }
      if (header === 'Mailing Address') {
        updateValues = {
          item: 'MailingAddress',
          accountHolder: {
            mailingAddress: {
              address1: address1,
              address2: address2,
              city: city,
              country: country,
              postalCode: postalCode,
              state: state,
            },
          },
          holder: accountHolderIndex,
        };
      }

      dispatch(
        AccountManagementDataActions.requestMakeAccountUpdate({
          accountId: accountId,
          request: updateValues,
        })
      );
      resetForm();
      toggleModal();
      const headerValue = header === 'Physical Address' ? 'physical' : 'mail';
      localStorage.setItem(`addressVerification-${headerValue}-${accountHolderIndex}`, 'undefined');
    },
    [toggleModal, address1, address2, city, country, postalCode, state, dispatch]
  );

  return (
    <Formik
      initialValues={initialAddressUpdateValues}
      onSubmit={onSubmit}
      validate={onValidate}
      validateOnChange={true}
    >
      {({ handleSubmit, resetForm, values, errors }) => (
        <Modal setShow={() => toggleModal()} show={show}>
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Address Update</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            <h6>{header}</h6>
            <form id="addressUpdate" onSubmit={handleSubmit}>
              <FormGroup>
                <Field
                  component={SelectDropdownField}
                  name={'country'}
                  id={'country'}
                  label="Country"
                  options={getGroupedCountryOptions()}
                  optionsDefaultLabelId="country"
                />
              </FormGroup>
              <FormGroup>
                <Field component={TextboxField} name={'address1'} id={'address1'} label="Address 1" />
              </FormGroup>
              <FormGroup>
                <Field component={TextboxField} name={'address2'} id={'address2'} label="Address 2" />
              </FormGroup>
              <FormGroup>
                <Field component={TextboxField} name={'city'} id={'city'} label="City" />
              </FormGroup>
              <FormGroup>
                {values.country === Country.UnitedStatesOfAmerica && (
                  <Field
                    component={SelectDropdownField}
                    name={'state'}
                    id={'state'}
                    label="State"
                    options={AllStates}
                    optionsDefaultLabelId="state"
                  />
                )}
                {values.country !== Country.UnitedStatesOfAmerica && values.country !== '' && (
                  <Field component={TextboxField} name={'state'} id={'state'} label="Province" />
                )}
              </FormGroup>
              <FormGroup>
                <Field component={TextboxField} name={'postalCode'} id={'postalCode'} label="Postal Code" />
              </FormGroup>
            </form>
            {addressType === 'mail' && (
              <p className="txt-sm mute">
                All updates are pending admin review. You will be notified when your submission has been processed.
              </p>
            )}
          </BootstrapModal.Body>
          <BootstrapModal.Footer className="modal-footer-justified">
            <Button
              variant="secondary"
              onClick={() => {
                resetForm();
                toggleModal();
                const headerValue = header === 'Physical Address' ? 'physical' : 'mail';
                localStorage.setItem(`addressVerification-${headerValue}-${accountHolderIndex}`, 'undefined');
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={(values: any) => handleSubmit(values)}>
              Submit
            </Button>
          </BootstrapModal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
