/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useRef, useEffect, useLayoutEffect, useCallback } from 'react';
import _ from 'lodash';
import { AssetSymbol } from '@tradingblock/types';
import { TradingViewChartOptions, createTradingViewChart } from '@tradingblock/tradingview';
import { IChartingLibraryWidget, TimeFrameItem } from '@tradingblock/tradingview/dist/charting_library';
import { useBlockActions, useBlockData } from '../../components/blocks/BlockState';
import { useLastTradePricesOrUndefined } from '../../hooks/useFeedQuote';
import { Colors } from '../../constants';
import { PriceChartState } from './state/PriceChartState';
import { PriceChartSettingsKey, usePriceChartSettings } from './state/PriceChartSettings';
import { useTradingviewDatafeed } from './hooks/useTradingviewDatafeed';
import { getDrawingOverrides } from './options/drawingOverrides';
import { getOverrides } from './options/overrides';
import { timeZone } from '@tradingblock/api';
import { EntityInfo, ResolutionString } from '@tradingblock/tradingview/src/charting_library';
import { useDispatch } from 'react-redux';
import { QuoteDataActions } from '../../data/global/actions';
import { useQuoteActions } from '../../hooks/useQuoteData';
import { isMarketOpenSelector, useStateSelector } from '../../data/global/dataSelectors';
import { Loading } from '@tradingblock/components';

interface PriceChartGraphProps {
  symbol: AssetSymbol;
  containerId: string;
}

const DefaultInterval = '5' as ResolutionString;

// chartType enum
export enum ChartType {
  Bar = 0,
  Candle = 1,
  Line = 2,
  Area = 3,
  Renko = 4,
  Kagi = 5,
  PnF = 6,
  LineBreak = 7,
  HeikinAshi = 8,
  HollowCandle = 9,
  Baseline = 10,
  HiLo = 12,
  Column = 13,
}

// function to determine if chart type is heikin ashi
const isHeikinAshi = (chartType: ChartType) => {
  return chartType === ChartType.HeikinAshi;
};

export const PriceChartGraph: React.FC<PriceChartGraphProps> = props => {
  const { isLoaded, savedData, interval, showLeftToolbar, chartType, symbol } = useBlockData<PriceChartState>();
  const { setField: set } = useBlockActions<PriceChartState>();

  const saveChartData = useCallback((key: PriceChartSettingsKey, data: any) => {
    set(key, data, { persist: true });
  }, []);
  const blockSymbol = symbol && symbol.rootSymbol;

  // don't render chart until block data has loaded
  if (!savedData && !isLoaded) {
    console.error('not rendering chart because data doesnt exist OR isLoaded is false', { isLoaded, savedData });
    return null;
  }

  return (
    <PriceChartGraphComponent
      {...props}
      savedData={savedData}
      interval={interval}
      showLeftToolbar={showLeftToolbar}
      saveData={saveChartData}
      chartType={chartType}
      blockSymbol={blockSymbol}
    />
  );
};

interface PriceChartGraphComponentProps
  extends PriceChartGraphProps,
    Pick<PriceChartState, 'savedData' | 'interval' | 'showLeftToolbar' | 'chartType'> {
  saveData: (key: PriceChartSettingsKey, data: any) => void;
  blockSymbol?: string;
}

const PriceChartGraphComponent: React.FC<PriceChartGraphComponentProps> = props => {
  const { containerId, savedData, interval, showLeftToolbar, chartType, saveData, blockSymbol } = props;
  const { symbol, provider } = props.symbol;

  const chartRef = useRef<IChartingLibraryWidget>();
  const datafeed = useTradingviewDatafeed();
  const { debug } = usePriceChartSettings();
  const symbols = _.uniq(datafeed.symbols);
  const dispatch = useDispatch();
  const { addQuote } = useQuoteActions();
  const [chartTypeValue, setChartTypeValue] = React.useState<ChartType>(chartType || ChartType.Candle);
  const [chartInterval, setChartInterval] = React.useState<string>(interval || DefaultInterval);
  const state = useStateSelector(state => state);
  const [studies, setStudies] = React.useState<EntityInfo[]>([]);

  useEffect(() => {
    if (symbols.length > 0) {
      dispatch(QuoteDataActions.requestQuote({ symbol: symbols }));
      addQuote(symbols);
    }
  }, [datafeed, symbols.length, dispatch]);

  const visibilityChartSync = function() {
    if (document.visibilityState === 'visible') {
      if (chartRef && chartRef.current) {
        if (datafeed && chartRef && chartRef.current !== undefined) {
          datafeed.resetCache();
          if (!chartRef || chartRef.current === undefined) {
            return;
          } else if (chartRef && chartRef.current !== undefined && chartRef.current.activeChart() !== undefined) {
            chartRef.current.activeChart().resetData();
          }
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', visibilityChartSync);
    return () => {
      document.removeEventListener('visibilitychange', visibilityChartSync);
    };
  }, [chartRef, datafeed]);

  const timeFrames: TimeFrameItem[] = [
    { text: '3m', resolution: '60' as ResolutionString, description: '3 Months' },
    { text: '1m', resolution: '30' as ResolutionString, description: '1 Month' },
    { text: '5d', resolution: '5' as ResolutionString, description: '5 Days' },
    { text: '1d', resolution: '1' as ResolutionString, description: '1 Day' },
  ];

  const graphSettings = useMemo<TradingViewChartOptions>(() => {
    return {
      locale: 'en',
      libraryPath: '/charting_library/',
      // chartsStorageUrl: 'https://saveload.tradingview.com',
      // chartsStorageApiVersion: '1.1',
      clientId: 'tradingview.com',
      userId: 'public_user_id',
      disabled_features: [
        'use_localstorage_for_settings',
        'adaptive_logo',
        'header_screenshot',
        'header_symbol_search',
        'widget_logo',
        'go_to_date',
        'popup_hints',
      ],
      create_volume_indicator_by_default: false,
      enabled_features: ['move_logo_to_main_pane', 'hide_left_toolbar_by_default'],
      fullscreen: false,
      studiesOverrides: {},
      datafeed,
      debug: false,
      autosize: true,
      symbol: `${provider}:${symbol}`,
      savedData,
      // autoSaveDelay: 1000,
      interval: (interval as ResolutionString) || (DefaultInterval as ResolutionString),
      timezone: timeZone,
      theme: 'Dark',
      style: '1',
      custom_css_url: `${process.env.PUBLIC_URL}/styles/tradingview.css`,
      enable_publishing: false,
      allow_symbol_change: false,
      hide_top_toolbar: true,
      save_image: false,
      containerId,
      container: 'tv_chart_container',
      overrides: {
        ...getOverrides({ primaryColor: Colors.primary }),
        ...getDrawingOverrides(),
      },
      time_frames: timeFrames,
    };
    // note: chartData and symbol have been left out of deps purposefully, to avoid regenerating chart
  }, [datafeed, debug, containerId]);

  // useLayoutEffect(() => {
  //   if (chartRef.current) {
  //     chartRef.current.changeTheme(theme);
  //   }
  // }, [theme]);

  useLayoutEffect(() => {
    if (chartRef && chartRef.current) {
      chartRef.current.onChartReady(() => {
        if (chartRef && chartRef.current !== undefined) {
          chartRef.current.hideAllDrawingTools().setValue(!showLeftToolbar);
        }
      });
    }
  }, [showLeftToolbar]);

  // sync chart type on change
  useEffect(() => {
    if (chartType && chartType !== chartTypeValue && chartRef && chartRef.current !== undefined) {
      chartRef.current.onChartReady(() => {
        visibilityChartSync();
        setChartTypeValue(chartType);
      });
    }
  }, [chartType, chartTypeValue, chartRef.current]);

  const [disableChart, setDisableChart] = React.useState<boolean>(false);

  // sync chart interval on change
  useEffect(() => {
    const chart = document.querySelectorAll('.block-price-chart');
    if (interval && chartRef && chartRef.current !== undefined && chart.length > 0) {
      chartRef.current.onChartReady(() => {
        visibilityChartSync();
        setChartInterval(interval);
        setDisableChart(true);
        // disable clicking on chart element for 3 seconds
        chart.forEach((element: any) => {
          element.classList.add('chart-disabled');
          const timer = setTimeout(() => {
            setDisableChart(false);
            element.classList.remove('chart-disabled');
          }, 3000);

          return () => clearTimeout(timer);
        });
      });
    }
  }, [interval, chartInterval, chartRef.current, chartTypeValue, chartType, chartRef]);

  useEffect(() => {
    if (chartRef && chartRef.current !== undefined) {
      chartRef.current.onChartReady(() => {
        if (chartRef && chartRef.current !== undefined && symbol && provider !== undefined) {
          chartRef.current.setSymbol(`${provider}:${symbol}`, (interval as any) || DefaultInterval, () => {});
        }
        if (chartRef && chartRef.current !== undefined && _.includes(symbol, '$')) {
          chartRef.current.setSymbol(`${provider}:${symbol}`, (interval as any) || DefaultInterval, () => {});
        }
      });
    }
  }, [symbol, provider, interval]);

  useEffect(() => {
    if (chartRef && chartRef.current === undefined) {
      console.warn('PriceChartGraph :: creating', symbol, 'chart with data ', graphSettings.savedData);
      chartRef.current = createTradingViewChart(graphSettings, saveData);
    }

    return () => {
      if (chartRef && chartRef.current !== undefined) {
        chartRef.current.remove();
        chartRef.current = undefined;
      }
    };
  }, [graphSettings, saveData, chartRef]);

  useEffect(() => {
    // if charting symbol and block symbol are different, then we need to update the chart to utilize the block symbol
    if (chartRef && chartRef.current !== undefined && blockSymbol !== undefined && blockSymbol !== symbol) {
      console.warn('PriceChartGraph :: Block/Chart Symbol Desync - updating chart symbol to ', blockSymbol);
      chartRef.current.onChartReady(() => {
        if (chartRef && chartRef.current !== undefined && blockSymbol !== undefined) {
          chartRef.current.setSymbol(`${provider}:${blockSymbol}`, (interval as any) || DefaultInterval, () => {});
        }
      });
    }
  }, [blockSymbol, interval, provider, symbol]);

  // Fetch last trade prices of all symbols involved in the chart and update the datafeed
  // If the chart is a heikin ashi chart, TimeReceived will not include seconds only hour and minutes
  const lastPrices = useLastTradePricesOrUndefined(symbols, isHeikinAshi(chartType || 1), interval || DefaultInterval);

  const intervalCheck = interval === ('1D' as ResolutionString);
  const marketOpen = useStateSelector(s => isMarketOpenSelector(s));
  // if day interval return true
  // otherwise afterhours the current day's bar will not build
  // TODO: need to determine if a bar has been built for after hours 1D interval, if so return false otherwise we will return all quotes received after hours
  const marketIsOpen = intervalCheck ? intervalCheck : marketOpen;

  useEffect(() => {
    if (lastPrices && marketIsOpen) {
      datafeed.onQuoteChange(lastPrices, interval, isHeikinAshi(chartType || 1));
    }
  }, [lastPrices, datafeed, interval, marketIsOpen]);

  // Need to save the chart data whenever a study is created or removed from the chart
  // previously, these updates were delayed by up to 5 seconds, which was causing some confusion as to why removed studies were still showing up
  // or why newly created studies were not showing up after a refresh or page navigation
  useEffect(() => {
    // We're checking if `chartRef` is defined and if it points to a current chart.
    if (chartRef && chartRef.current !== undefined) {
      // If `chartRef.current` is defined, we then wait for the chart to be ready.
      chartRef.current.onChartReady(() => {
        // Once the chart is ready, we check again if `chartRef.current` is still defined.
        // This check is necessary because the reference may have changed while we were waiting for the chart to be ready.
        if (chartRef && chartRef.current !== undefined && chartRef.current.activeChart() !== undefined) {
          // If `chartRef.current` is still defined, we retrieve all studies from the active chart.
          const currentStudies = chartRef.current.activeChart().getAllStudies();
          // We then store these studies in the state.
          setStudies(currentStudies);
          // We compare the new list of studies with the old list.
          // If they are not equal, it means the studies have changed.
          if (!_.isEqual(currentStudies, studies)) {
            // If the studies have changed, we update the state with the new studies,
            // and then we save the chart's current state.
            setStudies(currentStudies);
            chartRef.current.save((savedData: any) => {
              // The `save` method's callback receives the chart's saved state,
              // which we then store in our mongo database.
              saveData('savedData', savedData);
            });
          }
        }
      });
    }
  }, [chartRef.current, lastPrices]); // The effect depends on `chartRef.current` and `lastPrices`.

  return (
    <>
      <div className="tradingview-widget-container">
        <div id={props.containerId} className="tradingviewChart">
          {/* <TradingViewChart chart={chartRef} {...graphSettings} /> */}
          <div id="tv_chart_container" />
        </div>
      </div>
      <div>{disableChart && <Loading />}</div>
    </>
  );
};
