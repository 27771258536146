import { Dispatch, Middleware } from 'redux';
import { getType } from 'typesafe-actions';
import { Actions, RootAction } from '../../actions';
import { LinkedAccountsActions } from '../../actions/LinkedAccountsActions';
import { DataState } from '../../state';
import { ApiProvider } from '../../../../context/Api';
import { ClientEntity, SearchAccountsRequest } from '@tradingblock/types';

function fetchLinkedAccounts(state: DataState, dispatch: Dispatch<RootAction>, args: SearchAccountsRequest = {}) {
  const api = ApiProvider(state, dispatch);

  if (api) {
    api.accounts.search(args).then(response => {
      if (response.payload !== undefined && response.payload.data !== null && response.payload.data.length > 1) {
        dispatch(
          Actions.requestLinkedAccountBalances({
            accountIds: response.payload.data.map((account: ClientEntity) => account.accountId),
          })
        );
      }
      return dispatch(LinkedAccountsActions.receiveLinkedAccounts(response));
    });
  }
}

export const LinkedAccountMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  try {
    const prevState = getState();
    const result = next(action);

    switch (action.type) {
      case getType(LinkedAccountsActions.requestLinkedAccounts):
        fetchLinkedAccounts(prevState, dispatch, action.payload);
        break;
    }

    return result;
  } catch (err) {
    console.error('linkedAccountsMiddleware :: Caught an exception for action ', action, err);
  }
};
