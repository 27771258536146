import { InitialState } from '../../initialState';
import { getType } from 'typesafe-actions';
import _ from 'lodash';
import {
  PrivateAccountManagementState,
  AccountManagementSearchEntityWithKey,
  SuccessResponseCode,
  AccountManagementSearchUpdatesState,
  DefaultAccountUpdateDetails,
  DefaultUserUpdateAccountDetails,
} from '@tradingblock/types';
import {
  AdminAccountManagementAction,
  AdminAccountManagementActions,
} from '../../actions/admin/AccountManagementActions';
import {
  accountManagementRequestKey,
  accountManagementRequestToQueryString,
  getEntityKey,
} from '../../utilities/accountManagementUtilities';

const defaultQueryState = (queryString: string) => ({
  queryString,
  ids: [],
  isFetching: false,
  error: undefined,
});

type Actions = AdminAccountManagementAction;
export const privateAccountManagementReducer = (
  state = InitialState.private.accountManagement,
  action: Actions
): PrivateAccountManagementState => {
  switch (action.type) {
    case getType(AdminAccountManagementActions.requestUserAccountUpdates): {
      const requestKey = accountManagementRequestKey(action.payload);
      const existingQueryState =
        state.byQuery[requestKey] || defaultQueryState(accountManagementRequestToQueryString(action.payload));
      return {
        ...state,
        byQuery: {
          ...state.byQuery,
          [requestKey]: {
            ...existingQueryState,
            isFetching: true,
            error: undefined,
          },
        },
      };
    }
    case getType(AdminAccountManagementActions.receiveUserAccountUpdates): {
      const { request, response } = action.payload;
      const { replace } = request;
      const querykey = accountManagementRequestKey(request);
      const existingQueryState =
        state.byQuery[querykey] || defaultQueryState(accountManagementRequestToQueryString(request));
      const { payload } = response || { payload: undefined };
      const data = payload && payload.data ? payload.data : [];
      const total = payload && payload.total ? payload.total : 0;
      const incomingData = _.map(
        data,
        (re): AccountManagementSearchEntityWithKey => ({ ...re, entityKey: getEntityKey(re) })
      );

      const isErrored = response.responseCode !== SuccessResponseCode;
      const updatedUpdates = _.reduce(
        incomingData,
        (acc: AccountManagementSearchUpdatesState, value: AccountManagementSearchEntityWithKey) => {
          return {
            ...acc,
            [value.entityKey]: value,
          };
        },
        state.updates
      );

      const ids = incomingData.map(v => v.entityKey);

      return {
        ...state,
        updates: updatedUpdates,
        byQuery: {
          ...state.byQuery,
          [querykey]: {
            ...existingQueryState,
            isFetching: false,
            ids,
            total,
          },
        },
      };
    }
    case getType(AdminAccountManagementActions.errorUserAccountUpdates): {
      return {
        ...state,
        updates: {},
        isFetching: false,
        error: action.payload,
        ui: {},
      };
    }
    case getType(AdminAccountManagementActions.setPage):
    case getType(AdminAccountManagementActions.setPageSize):
    case getType(AdminAccountManagementActions.setType):
    case getType(AdminAccountManagementActions.setSortBy):
    case getType(AdminAccountManagementActions.setTimeframe):
    case getType(AdminAccountManagementActions.setStatus):
    case getType(AdminAccountManagementActions.refresh):
    case getType(AdminAccountManagementActions.setSearch): {
      return {
        ...state,
        ui: {
          ...state.ui,
          ...action.payload,
        },
      };
    }
    case getType(AdminAccountManagementActions.setCurrentItem): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          request: action.payload,
          isFetching: false,
        },
        ui: {
          ...state.ui,
        },
      };
    }
    case getType(AdminAccountManagementActions.clearCurrentItem): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          request: {},
          isFetching: false,
          details: DefaultAccountUpdateDetails,
        },
        ui: {
          ...state.ui,
        },
      };
    }
    case getType(AdminAccountManagementActions.requestUserAccountUpdateDetails): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          isFetching: true,
        },
      };
    }
    case getType(AdminAccountManagementActions.receiveUserAccountUpdateDetails): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          details: action.payload.details,
          isFetching: false,
        },
      };
    }
    case getType(AdminAccountManagementActions.errorUserAccountUpdateDetails): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          details: DefaultAccountUpdateDetails,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }
    case getType(AdminAccountManagementActions.requestUserAccountDetails): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          isAccountFetching: true,
        },
      };
    }
    case getType(AdminAccountManagementActions.receiveUserAccountDetails): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          isAccountFetching: false,
          accountInfo: action.payload.response,
        },
      };
    }
    case getType(AdminAccountManagementActions.errorUserAccountDetails): {
      return {
        ...state,
        error: action.payload.error,
        currentItem: {
          ...state.currentItem,
          isAccountFetching: false,
          accountInfo: DefaultUserUpdateAccountDetails,
        },
      };
    }
    case getType(AdminAccountManagementActions.requestFirmApproveUpdate): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          isUpdated: false,
        },
      };
    }
    case getType(AdminAccountManagementActions.receiveFirmApproveUpdate): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          isUpdated: true,
        },
      };
    }
    case getType(AdminAccountManagementActions.errorFirmApproveUpdate): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          isUpdated: false,
          error: action.payload,
        },
      };
    }
    case getType(AdminAccountManagementActions.requestFirmRejectUpdate): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          isUpdated: false,
        },
      };
    }
    case getType(AdminAccountManagementActions.receiveFirmRejectUpdate): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          isUpdated: true,
        },
      };
    }
    case getType(AdminAccountManagementActions.errorFirmRejectUpdate): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          isUpdated: false,
          error: action.payload,
        },
      };
    }
    case getType(AdminAccountManagementActions.requestFirmApproveDoc): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          documentUpdate: false,
        },
      };
    }
    case getType(AdminAccountManagementActions.receiveFirmApproveDoc): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          documentUpdate: true,
        },
      };
    }
    case getType(AdminAccountManagementActions.errorFirmApproveDoc): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          error: action.payload,
          documentUpdate: false,
        },
      };
    }
    case getType(AdminAccountManagementActions.requestFirmRejectDoc): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          documentUpdate: false,
        },
      };
    }
    case getType(AdminAccountManagementActions.receiveFirmRejectDoc): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          documentUpdate: true,
        },
      };
    }
    case getType(AdminAccountManagementActions.errorFirmRejectDoc): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          error: action.payload,
          documentUpdate: false,
        },
      };
    }
    case getType(AdminAccountManagementActions.clearDocUpdate): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          documentUpdate: false,
        },
      };
    }
    default: {
      return state;
    }
  }
};
