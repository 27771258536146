import { useState, useCallback, useMemo, useEffect } from 'react';
import _ from 'lodash';
import constate from 'constate';
import useAudio from 'react-use/lib/useAudio';
import {
  TradingblockOrder,
  DebitOrCredit,
  Order,
  OrderLeg,
  OrderClass,
  OrderType,
  Durations,
  UserLevelTypes,
  UserProfileLevel,
  OrderStrategyType,
  AssetType,
} from '@tradingblock/types';
import { orderToReplaceOrder } from '@tradingblock/api';
import { useOrderActions } from '../../state/useOrderActions';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { IsWarningSymbol } from '../../../../data/global/selectors/symbolSelector';
import { useDispatcher } from '../../../../data/global/hooks';
import { OrderActions } from '../../../../data/global/actions';
import { useOrderSettings } from '../../hooks/useOrderSettings';
import { useSounds } from '../../../../hooks/useSounds';
import { useOrderTotal } from '../../hooks/useOrderTotal';
import { PreviewResult } from './PreviewPopup';
import { legToDto } from '../../OrderUtilities';
import { useDashboardSettings } from '../../../../hooks/useDashboardSettings';
import { OrderState, useOrderBlockData } from '../../state/OrderState';
import { OrderSelectors } from '../../state/OrderSelector';
import { set } from 'numeral';
import { useBlockActions, useBlockId } from '../../../../components/blocks/BlockState';

export type PreviewActions = 'execute' | 'replace' | 'error';

export const useOrderPreview = () => {
  const [isPreview, setIsPreview] = useState(false);

  const { successControls, errorControls, successAudio, errorAudio } = useSounds();
  const { disableSounds } = useOrderSettings();
  const { orderPlaced } = useOrderActions();
  const { muteAllSounds } = useDashboardSettings();
  const togglePreview = useCallback(() => setIsPreview(!isPreview), [isPreview]);

  const previewClosed = useCallback(
    (result: PreviewResult) => {
      setIsPreview(false);
      if (disableSounds !== true && result.action === 'order-placed') {
        !muteAllSounds &&
          (() => {
            const play = successControls.play();
            if (play !== undefined) {
              play.catch(() => {
                // Do nothing
                //TODO: Determine if we want to warn the user that the sound failed to play
              });
            }
          })();
        orderPlaced();
      } else if (disableSounds !== true && result.action === 'error') {
        !muteAllSounds &&
          (() => {
            const play = errorControls.play();
            if (play !== undefined) {
              play.catch(() => {
                // Do nothing
                //TODO: Determine if we want to warn the user that the sound failed to play
              });
            }
          })();
      } else {
        //do nothing if popup is just closed
      }
    },
    [setIsPreview, successControls, errorControls, disableSounds]
  );

  return useMemo(() => ({ isPreview, togglePreview, previewClosed, audio: { successAudio, errorAudio } }), [
    isPreview,
    togglePreview,
    previewClosed,
    successAudio,
    errorAudio,
  ]);
};

export const getOrderPreviewLegs = (order: TradingblockOrder | Order<OrderLeg>) => {
  const hasMultipleLegs = order.Legs && order.Legs.length > 1;

  return _(order.Legs)
    .filter(l => {
      if (_.isNil(l.Symbol)) {
        return false;
      }
      if (hasMultipleLegs) {
        return l.Symbol && l.SpreadRatio ? true : false;
      } else if (l.Symbol) {
        return true;
      }
      return false;
    })
    .map(legToDto)
    .value();
};

const adjustOrderQuantitiesAndRatios = (order: Order<OrderLeg>) => {
  if (!order) {
    return [0, []];
  }
  // function to find greatest common divisor
  const gcd = (a: number, b: number): number => {
    return b === 0 ? a : gcd(b, a % b);
  };

  // function to find highest common factor
  const findHCF = (arr: number[]): number => {
    return arr.reduce((a, b) => gcd(a, b));
  };

  const hcf = findHCF(order.Legs.map(l => l.SpreadRatio || 1));

  const adjustedOverallQuantity = order.Quantity * hcf;

  const adjustedRatios = order.Legs.map(l => {
    const ratio = l.SpreadRatio || 1;
    const adjustedRatio = ratio / hcf;
    return {
      ...l,
      SpreadRatio: adjustedRatio,
      Quantity: adjustedOverallQuantity * adjustedRatio,
    };
  });

  return [adjustedOverallQuantity, adjustedRatios];
};

function finalizeOrder<T extends Order>(
  order: T,
  accountId?: number,
  debitOrCredit?: DebitOrCredit,
  subaccountId?: number
): T {
  // determine the strategy of the order
  const isCustom = order.StrategyType === 'Undefined';
  // determine if all legs are option legs
  const allOptionLegs = order.Legs.every(l => l.AssetType === AssetType.Option);
  if (isCustom && allOptionLegs && order && order.Legs && order.Legs.length > 1) {
    const [adjustedOverallQuantity, adjustedRatios] = adjustOrderQuantitiesAndRatios(order);

    return {
      ...order,
      AccountId: accountId,
      DebitCredit: debitOrCredit,
      SubaccountId: subaccountId,
      Quantity: adjustedOverallQuantity,
      Legs: adjustedRatios,
    };
  }

  return {
    ...order,
    AccountId: accountId,
    DebitCredit: debitOrCredit,
    SubaccountId: subaccountId,
  };
}

export const useOrderPreviewOrder = (action: PreviewActions) => {
  const order = useOrderBlockData(OrderSelectors.order);
  const [initialPrice, setInitialPrice] = useState<number>();
  const [replacePrice, setReplacePrice] = useState<number>();
  const [initialStopPrice, setInitialStopPrice] = useState<number>();
  const [replaceStopPrice, setReplaceStopPrice] = useState<number>();
  const [orderId, setOrderId] = useState<number>();
  const { setStopPrice, setPrice, setDebitCredit, setSubaccountId, setOrder: setOrderCallback } = useOrderActions();
  const accountId = useStateSelector(state => state.account.accountId);
  const profile = useStateSelector(state => state.account.profile);
  const userLevel = profile && profile.current && profile.current.level;
  const isAboveAccountLevel = userLevel && userLevel !== UserProfileLevel.Account;
  const subAccounts = useStateSelector(state => state.account.subaccounts.subaccounts);

  useEffect(() => {
    const orderPrice = order ? order.Price : undefined;
    const currId = order ? order.OrderId : undefined;
    if (order && (initialPrice === undefined || orderId !== currId) && orderPrice) {
      setInitialPrice(orderPrice);
      setOrderId(currId);
      if (order && order.OrderType === OrderType.Stop_Limit && initialStopPrice === undefined && order.Stop) {
        setInitialStopPrice(order.Stop);
      }
    }
  }, [order, initialPrice, orderId, initialStopPrice]);

  useEffect(() => {
    const subaccountId = order && order.SubaccountId;
    const isSubaccountDeleted = subaccountId && subAccounts && !subAccounts.find(sa => sa.Id === subaccountId);
    const hasNoSubAccounts = !subAccounts && subaccountId;

    // If the user has deleted the subaccount associated with the order,
    // or if there are no subaccounts but the order has a subaccountId, we need to remove the subaccount designation from the order object.
    if (subaccountId && (isSubaccountDeleted || hasNoSubAccounts)) {
      setSubaccountId(undefined);
    }
  }, [order, subAccounts]);

  // const orderlegs = useOrderData(orderLegsSelector);
  const legs = useMemo(() => {
    if (order) {
      return getOrderPreviewLegs(order);
    }
    return [];
  }, [order]);

  const totalResults = useOrderTotal();
  const isWarningSymbol = useStateSelector(s => IsWarningSymbol(s, order ? order.UnderlyingSymbol : ''));

  const isTradingblockOrder = useMemo(() => {
    if (order) {
      return (order as TradingblockOrder).kind === 'order';
    }
    return false;
  }, [order]);

  const finalOrder = useMemo(() => {
    if (_.isNil(order)) {
      return undefined;
    }
    const debitOrCredit = order.DebitCredit || totalResults.debitCredit;
    // if the order strategy type contains vertical, calendar, butterfly, or condor we need to set the strategy type accordingly
    // currently the backend does not differentiate between call and put verticals/calendars/butterflies/condors so we need to do it here
    return finalizeOrder(
      {
        ...order,
        BypassWarnings: isWarningSymbol,
        StrategyType:
          order.StrategyType && _.includes(order.StrategyType, 'Vertical')
            ? OrderStrategyType.Vertical
            : order.StrategyType && _.includes(order.StrategyType, 'Calendar')
            ? OrderStrategyType.Calendar
            : order.StrategyType && order.StrategyType === OrderStrategyType.Call
            ? OrderStrategyType.Single
            : order.StrategyType && order.StrategyType === OrderStrategyType.Put
            ? OrderStrategyType.Single
            : order.StrategyType && _.includes(order.StrategyType, 'Butterfly')
            ? OrderStrategyType.Butterfly
            : order.StrategyType && _.includes(order.StrategyType, 'Condor')
            ? OrderStrategyType.Condor
            : order.StrategyType,
      },
      order.AccountId || accountId,
      debitOrCredit,
      order.SubaccountId
    );
  }, [order]);

  const changeSubaccountId = useCallback(
    (id?: number) => {
      if (order === undefined) {
        return;
      }
      setSubaccountId(id);
    },
    [setSubaccountId, order]
  );

  const changeLimitPrice = useCallback(
    (price: number | null, isNegativePrice?: boolean) => {
      if (order === undefined) {
        return;
      }
      if (price !== null) {
        setPrice(price, false, isNegativePrice);
      }
    },
    [order]
  );

  const changeStopPrice = useCallback(
    (price: number | null) => {
      if (order === undefined) {
        return;
      }
      if (price !== null) {
        setStopPrice(price);
      }
    },
    [order]
  );

  const changeDebitCredit = useCallback(
    (val: DebitOrCredit | undefined) => {
      if (order === undefined) {
        return;
      }
      setDebitCredit(val);
    },
    [order]
  );

  const changeReplacePrice = useCallback(
    (price: number | null) => {
      if (order === undefined) {
        return;
      }
      if (price !== null) {
        setReplacePrice(price);
      }
    },
    [order]
  );

  const changeReplaceStopPrice = useCallback(
    (price: number | null) => {
      if (order === undefined) {
        return;
      }
      if (price !== null) {
        setReplaceStopPrice(price);
      }
    },
    [order]
  );

  const { dispatch } = useDispatcher();
  const onSave = useCallback(() => {
    if (finalOrder) {
      // If the user is an admin, representative, or advisor we can bypass warnings by default
      if (isAboveAccountLevel) {
        finalOrder.BypassWarnings = true;
      }
      // need to remove SpreadRatio from order leg of single legged orders prior to sending to the backend
      if (finalOrder.Legs && finalOrder.Legs.length === 1) {
        finalOrder.Legs[0].SpreadRatio = undefined;
      }
      if (action === 'execute' || action === undefined) {
        dispatch(OrderActions.requestPlaceOrder(finalOrder));
      } else if (action === 'replace') {
        dispatch(OrderActions.requestChange(orderToReplaceOrder(finalOrder)));
      }
      if (finalOrder.Price) {
        setInitialPrice(finalOrder.Price);
      }
    }
  }, [order, action, isWarningSymbol, totalResults, accountId, dispatch]);

  const setOrder = useCallback(
    (order: Order<OrderLeg>) => {
      setOrderCallback(order);
    },
    [order]
  );

  return {
    order,
    legs,
    action,
    initialPrice,
    replacePrice,
    initialStopPrice,
    replaceStopPrice,
    isWarningSymbol,
    isTradingblockOrder,
    setOrder,
    changeSubaccountId,
    changeDebitCredit,
    changeLimitPrice,
    changeStopPrice,
    changeReplacePrice,
    changeReplaceStopPrice,
    onSave,
  };
};
