import { Middleware, Dispatch } from 'redux';
import { getType } from 'typesafe-actions';
import _ from 'lodash';
import { DataState } from '../state';
import { RootAction, Actions, NotificationActions } from '../actions';

// reference https://redux.js.org/advanced/middleware/ for best practices
export const versioningMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  // const log = useDebug('dataRequestMiddleware');
  try {
    // state BEFORE action is dispatched
    const result = next(action);
    // state AFTER action is dispatched
    switch (action.type) {
      case getType(Actions.clientVersionMismatch): {
        console.debug('Client version changed. Reloading page', action.payload);
        //window.location.reload();
        dispatch(
          NotificationActions.add({
            status: 'Info',
            title: 'New version detected!',
            message: 'Please reload your browser',
            global: true,
            time: new Date(),
            key: _.uniqueId(),
            read: false,
            hide: false,
            viewed: false,
          })
        );
      }
    }
    return result;
  } catch (err) {
    console.error('versioningMiddleware :: Caught an exception for action ', action, err);
  }
};

export const VersioningMiddleware = versioningMiddleware;
