import { SettingsFields } from '../../components/form/GenericField';
import { ActivitySettings, ActivityTimeframes } from '@tradingblock/types';
import { useBlockId } from '../../components/blocks/BlockState';
import { useStateSelector } from '../../data/global/dataSelectors';
import { blockSettings } from '../../data/global/selectors/settingSelectors';
import _ from 'lodash';

export const ActivitySettingsFields: SettingsFields<ActivitySettings> = {
  timeframe: {
    type: 'choice',
    values: _.map(
      [
        ActivityTimeframes.Today,
        ActivityTimeframes.Week,
        ActivityTimeframes.Month,
        ActivityTimeframes.Year,
        ActivityTimeframes['Three Years'],
      ],
      tf => {
        return {
          value: tf,
          label: ActivityTimeframes[tf],
        };
      }
    ),
  },
};

export const useActivitySettings = () => {
  // const settings = useBlockSettings<OptionChainSettings>();
  //return settingsWithDefaults;
  const blockId = useBlockId();
  const current = useStateSelector(s => blockSettings.activity(s, blockId));
  return {
    current: current,
    definitions: ActivitySettingsFields,
  };
};

export const useActivitySettingsValue = (): ActivitySettings => {
  // const settings = useBlockSettings<OptionChainSettings>();
  //return settingsWithDefaults;
  const { current } = useActivitySettings();
  return current || { timeframe: undefined, workingOrdersOnly: false };
};

export const useActivityBlockData = () => {
  const blockId = useBlockId();
  const blockState = useStateSelector(s => s.account.ordersUIState[blockId]);
  if (!blockState) {
    return { pageSize: 50, pageIndex: 0, total: 0 };
  }
  return {
    pageSize: blockState.pageSize || 50,
    pageIndex: blockState.pageIndex || 0,
    total: blockState.total,
  };
};
