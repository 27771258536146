import React, { useEffect } from 'react';
import { BlockBody } from '@tradingblock/components';
import { TabOptions, BlockType } from '@tradingblock/types';
import { ActivityContent } from '././ActivityContent';
import { PositionSync } from '../shared/PositionSync';
import { Block } from '../../components/blocks/Block';
import { useQuoteSymbolSubscriptionFromGlobalState, useQuoteFeedSubscription } from '../../hooks/useQuoteData';
import { getBlockProps } from '../../utilities/block';
import { useGlobalStateQuoteListener } from '../../components/blocks/useBlockSubscriptions';
import { BlockTabWell } from '../../components/blocks/BlockTabWell';
import { getPositionAndOrderSymbols } from '../../data/global/selectors/orderSelector';
import { useActivitySettings } from './useActivitySettings';

const ActivityTabs: TabOptions[] = [
  { title: 'Trades', active: true },
  { title: 'Orders' },
  { title: 'Messages' },
  { title: 'Summary' },
];

export const ActivityBlock: React.FunctionComponent<{}> = () => {
  useQuoteSymbolSubscriptionFromGlobalState(getPositionAndOrderSymbols);
  useGlobalStateQuoteListener(getPositionAndOrderSymbols);
  const { current, definitions } = useActivitySettings();
  return (
    <Block
      {...getBlockProps(BlockType.Activity, <BlockTabWell />, ActivityTabs)}
      settings={current}
      settingFields={definitions}
    >
      <BlockBody className={'block-body'}>
        <PositionSync />
        <ActivityContent />
      </BlockBody>
    </Block>
  );
};
