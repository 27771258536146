import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import React from 'react';
import { usePageNav } from '../../context/page/PageContext';
import { LoadingImage } from '..';
export var PageNav = function PageNav(_ref) {
  var children = _ref.children;

  var _usePageNav = usePageNav(),
      _usePageNav2 = _slicedToArray(_usePageNav, 1),
      pageNav = _usePageNav2[0];

  return React.createElement("div", {
    className: "dashboard-nav ".concat(pageNav && pageNav.collapsed ? 'collapsed' : '')
  }, children);
};

var PageNavPanel = function PageNavPanel(_ref2) {
  var className = _ref2.className,
      children = _ref2.children;
  return React.createElement("div", {
    className: "dashboard-nav-panel ".concat(className)
  }, children);
};

export var PageNavPrimary = function PageNavPrimary(_ref3) {
  var children = _ref3.children;
  return React.createElement(PageNavPanel, {
    className: "dashboard-nav-primary"
  }, children);
};
export var PageNavTertiary = function PageNavTertiary(_ref4) {
  var children = _ref4.children;
  return React.createElement(PageNavPanel, {
    className: "dashboard-nav-tertiary"
  }, children);
};
export var DashboardToggle = function DashboardToggle() {
  var _usePageNav3 = usePageNav(),
      _usePageNav4 = _slicedToArray(_usePageNav3, 2),
      pageNav = _usePageNav4[0],
      toggle = _usePageNav4[1].toggle;

  var title = pageNav.collapsed ? 'Show' : 'Hide';
  return React.createElement("div", {
    className: "dashboard-nav-toggle",
    title: title,
    onClick: function onClick() {
      return toggle();
    }
  }, title);
};
export var PageNavToggleButton = function PageNavToggleButton(_ref5) {
  var title = _ref5.title,
      onClick = _ref5.onClick,
      toggleId = _ref5.toggleId;
  return React.createElement("button", {
    className: "dashboard-nav-account-menu dashboard-nav-account-item",
    id: toggleId,
    onClick: onClick,
    type: "button"
  }, React.createElement("span", {
    className: "caps"
  }, React.createElement("span", {
    className: "hide-mobile"
  }, title), !title && React.createElement(LoadingImage, null)), React.createElement("span", {
    className: "dashboard-nav-account-trigger"
  }, React.createElement("i", {
    className: "far fa-lg fa-ellipsis-v"
  })));
};