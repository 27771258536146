import { Middleware, Dispatch } from 'redux';
import { getType, ActionType } from 'typesafe-actions';
import _ from 'lodash';
import { DefaultGridLayout, Layouts, BlockConfiguration, LayoutWithType, BlockLayouts } from '@tradingblock/types';
import { DataState } from '../state';
import { RootAction, BlockActions } from '../actions';
import { Dispatcher } from '../dispatcher';
import { generateBlockClosestFitLayout } from '../../../utilities/block';

// reference https://redux.js.org/advanced/middleware/ for best practices

type blockActions = ActionType<typeof BlockActions.addBlock> | ActionType<typeof BlockActions.addOrUpdateBlock>;
const handleAddBlock = (
  state: DataState,
  prevState: DataState,
  dispatch: Dispatch<RootAction>,
  action: blockActions
) => {
  const dispatcher = Dispatcher(dispatch);
  const block = { ...action.payload.block };
  const { blocks } = state.blocks;
  const { blocks: prevBlocks } = prevState.blocks;
  const layout = { ...DefaultGridLayout, ...state.layout };
  const { breakpoint, layouts } = { ...DefaultGridLayout, ...layout };
  const currLayouts = layouts ? layouts[breakpoint] : [];
  let blockLayouts: BlockLayouts = {
    ...block.layouts,
  };
  // Determine if replaceFirstMatch should be used based on the action type
  const replaceFirstMatch =
    action.type === getType(BlockActions.addOrUpdateBlock) ? action.payload.replaceFirstMatch : null;

  // Check if the block layouts or specific breakpoint layout is missing
  if (!block.layouts || !block.layouts[breakpoint]) {
    // Generate a new layout that closely fits the block type
    const newLayout = generateBlockClosestFitLayout(block.type, currLayouts, layout);
    let firstMatchLayout = null;

    if (replaceFirstMatch) {
      // Extract block type from replaceFirstMatch payload
      const { blockType } = replaceFirstMatch;
      // Find the first block that matches the specified block type
      const firstMatch = _.find(blocks, b => b.type === blockType);

      if (firstMatch) {
        // Find the layout corresponding to the first match's block ID
        firstMatchLayout = _.find(currLayouts, l => l.i === firstMatch.blockId);
      }
    }

    // If no firstMatchLayout is found, update the blockLayouts with the new layout
    if (!firstMatchLayout) {
      blockLayouts = {
        ...blockLayouts,
        [breakpoint]: {
          ...newLayout,
          i: block.blockId, // Set the block ID in the layout
          type: block.type, // Set the block type in the layout
        },
      };
    }
    // No action is needed if firstMatchLayout is defined, as the layout is already set
  }

  const nextLayouts = updateLayouts(layouts, blocks, blockLayouts);

  dispatcher.layout.setAllLayouts(nextLayouts, { persist: true });

  return state;
};

const handleRemoveBlock = (
  state: DataState,
  prevState: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof BlockActions.removeBlock>
) => {
  const dispatcher = Dispatcher(dispatch);
  const { blocks } = state.blocks;
  const { layouts } = { ...DefaultGridLayout, ...state.layout };

  const nextLayouts = _.reduce(
    _.isEmpty(layouts) ? { lg: [], md: [], sm: [], xs: [], xxs: [] } : layouts,
    (acc: Layouts, breakpointLayouts, key) => {
      return {
        ...acc,
        [key]: _.filter(breakpointLayouts, bl => _.some(blocks, b => bl.i === b.blockId)),
      };
    },
    {}
  );

  dispatcher.layout.setAllLayouts(nextLayouts, { persist: true });

  return state;
};

const updateLayouts = (layouts: Layouts | undefined, blocks: BlockConfiguration[], blockLayouts?: BlockLayouts) => {
  const updatedLayouts = _.reduce(
    _.isEmpty(layouts) ? { lg: [], md: [], sm: [], xs: [], xxs: [] } : layouts,
    (acc: Layouts, breakpointLayouts, key) => {
      // fallback to lg layout
      const blockLayout = blockLayouts && (blockLayouts[key] || blockLayouts.lg);
      return {
        ...acc,
        [key]: getUpdatedLayouts('ADD', breakpointLayouts, blockLayout),
      };
    },
    {}
  );

  return updatedLayouts;
};

const getUpdatedLayouts = (mode: 'ADD' | 'REM', layouts: LayoutWithType[], blockLayout: LayoutWithType | undefined) => {
  const newLayouts =
    mode === 'ADD' && blockLayout && blockLayout.i && !_.some(layouts, l => l.i === blockLayout.i) ? [blockLayout] : [];
  return _.reduce(
    [...layouts, ...newLayouts],
    (acc: LayoutWithType[], layout) => {
      if (blockLayout && blockLayout.i && blockLayout.i === layout.i) {
        return [...acc, blockLayout];
      }
      return [...acc, layout];
    },
    []
  );
};

export const layoutMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = middleware => (
  next: Dispatch<RootAction>
) => (action: RootAction) => {
  try {
    const prevState = middleware.getState();
    const result = next(action);
    const nextState = middleware.getState();
    switch (action.type) {
      case getType(BlockActions.addOrUpdateBlock):
      case getType(BlockActions.addBlock):
        handleAddBlock(nextState, prevState, middleware.dispatch, action);
        break;
      case getType(BlockActions.removeBlock):
        handleRemoveBlock(nextState, prevState, middleware.dispatch, action);
        break;
    }
    return result;
  } catch (err) {
    console.error('layoutMiddleware :: Caught an exception for action ', action, err);
  }
};
