import React from 'react';
import { TableProps, useParentSizeContext } from '@tradingblock/components';
import { BlockTableContent } from './table/BlockTableContent';
import { BlockTableProps } from './table/BlockTableTypes';

export const BlockTable: React.FunctionComponent<BlockTableProps> = props => {
  return (
    <useParentSizeContext.Provider>
      <BlockTableContent {...props} />
    </useParentSizeContext.Provider>
  );
};
