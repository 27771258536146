import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { SiteName as BaseSiteName } from '@tradingblock/components';
import { account } from '../../data/global/selectors/accountSelectors';
import { origin } from '../../data/global/selectors/originSelector';

export const SiteName: React.FC = () => {
  const accountNumber = useSelector(account.accountNumber);
  const originValue = useSelector(origin.origin);

  return <BaseSiteName accountNumber={accountNumber} origin={originValue} />;
};
