import React from 'react';
import { ToastContainer, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
export var AlertsContainer = function AlertsContainer() {
  return React.createElement(ToastContainer, {
    autoClose: 6000,
    closeOnClick: false,
    // position="top-center"
    transition: Flip
  });
};