import { SettingsFields } from '../../components/form/GenericField';

import { PositionSettings } from '@tradingblock/types';
import { useBlockId } from '../../components/blocks/BlockState';
import { useStateSelector } from '../../data/global/dataSelectors';
import { blockSettings } from '../../data/global/selectors/settingSelectors';

export const PositionSettingsFields: SettingsFields<PositionSettings> = {
  combineSameSymbols: {
    type: 'radio',
    yesOptionLabel: 'Show positions on the same row',
    noOptionLabel: 'Show positions on multiple rows, one for each order',
    fieldLabel: 'For same instrument positions:',
  },
  groupPositionsByStrategy: {
    type: 'radio',
    yesOptionLabel: 'Show positions not grouped by strategy',
    noOptionLabel: 'Show positions grouped by strategy',
    fieldLabel: 'Group positions by strategy:',
  },
  expandRows: {
    type: 'radio',
    yesOptionLabel: 'Show positions expanded',
    noOptionLabel: 'Show positions collapsed',
    fieldLabel: 'Expand positions:',
  },
  Symbol: {
    type: 'boolean',
    disabled: true
  },
  Description: { type: 'boolean' },
  Last: { type: 'boolean' },
  Change: { type: 'boolean' },
  Percent: { type: 'boolean' },
  Bid: { type: 'boolean' },
  Ask: { type: 'boolean' },
  Close: { type: 'boolean' },
  PrevClose: { type: 'boolean' },
  AvgPrice: { type: 'boolean' },
  Shares: { type: 'boolean' },
  Options: { type: 'boolean' },
  Cost: { type: 'boolean' },
  Value: { type: 'boolean' },
  Gain: { type: 'boolean' },
  PNL: { type: 'boolean' },
  Actions: { type: 'boolean', disabled: true },

};

export const DefaultPositionsBlockSettings: PositionSettings = {
  Symbol: true,
  Last: true,
  Description: true,
  Change: true,
  Bid: true,
  Ask: true,
  Cost: true,
  AvgPrice: true,
  Close: true,
  PNL: true,
  Value: true,
  Gain: true,
  Options: true,
  Shares: true,
  Actions: true,
  Percent: true,
  PrevClose: true,
  combineSameSymbols: false,
  groupPositionsByStrategy: false,
  expandRows: false
};

export const usePositionSettings = () => {
  const blockId = useBlockId();
  const current = useStateSelector(s => blockSettings.position(s, blockId));
  return {
    current: current || {},
    definitions: PositionSettingsFields,
  };
};

export const usePositionSettingsValue = () => {
  const { current } = usePositionSettings();
  return current;
};
