import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { toShort } from '@tradingblock/api';
import {
  DefaultAccountDetails,
  AccountType,
  CashieringDetailRelationship,
  AchRelationship,
  BankRiskScoreValues,
} from '@tradingblock/types';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { accountSelectors, accountDetailsSelectors } from '../../../../data/global/selectors/admin/accountSelectors';
import { ValueArray } from '../../../../components/basic/ValueArray';
import { DetailField, DetailFields } from '../../../../components/basic/DetailFields';
import { formatPhoneNumber } from '../../../../utilities/accountManagement';
import { Number } from '@tradingblock/components';

interface CustomerAccountDetailsProps {
  accountId: number;
  relationship?: AchRelationship;
  relationshipDetails?: CashieringDetailRelationship;
}

export const CustomerAccountDetails: React.FunctionComponent<CustomerAccountDetailsProps> = ({
  accountId,
  relationship,
  relationshipDetails,
}) => {
  const account = useStateSelector(s => accountSelectors.data(s, accountId));
  const accountDetails = useStateSelector(s => accountDetailsSelectors.data(s, accountId));

  const {
    accountNames,
    mailingAddress,
    physicalAddress,
    email,
    homePhone,
    mobilePhone,
    workPhone,
    lastChanged,
    repFirstName,
    repLastName,
    repPhoneNumber,
    accountStatus,
  } = accountDetails || DefaultAccountDetails;

  const getAccountStatus = useCallback((status: string) => {
    // format status to place a space between words
    const formattedStatus = status.replace(/([A-Z])/g, ' $1').trim();
    switch (status) {
      case 'Active':
        return (
          <span className="pos" style={{ fontWeight: 500 }}>
            {formattedStatus}
          </span>
        );
      case 'Inactive':
        return (
          <span className="neg" style={{ fontWeight: 500 }}>
            {formattedStatus}
          </span>
        );
      case 'Closed':
        return (
          <span className="neg" style={{ fontWeight: 500 }}>
            {formattedStatus}
          </span>
        );
      default:
        return (
          <span
            style={{
              display: 'block',
              borderBottom: '1px solid rgba(255, 255, 255, 0.15)',
              marginBottom: '1rem',
              marginTop: '1rem',
            }}
          ></span>
        );
    }
  }, []);

  const getBankRiskScore = useCallback((score?: BankRiskScoreValues) => {
    const formattedRiskScore = score && score.replace(/([A-Z])/g, ' $1').trim();

    switch (score) {
      case BankRiskScoreValues.LowRisk:
        return (
          <>
            <span>Bank Risk Score:</span>

            <p className="low-risk-score" style={{ fontWeight: 'bold' }}>
              {formattedRiskScore} <i className="fal fa-laugh-beam" />
            </p>
          </>
        );
      case BankRiskScoreValues.MediumRisk:
        return (
          <>
            <span>Bank Risk Score:</span>

            <p className="medium-risk-score" style={{ fontWeight: 'bold' }}>
              {formattedRiskScore} <i className="fal fa-meh" />
            </p>
          </>
        );
      case BankRiskScoreValues.HighRisk:
        return (
          <>
            <span>Bank Risk Score:</span>

            <p className="high-risk-score" style={{ fontWeight: 'bold' }}>
              {formattedRiskScore} <i className="fal fa-frown" />
            </p>
          </>
        );
      default:
        return (
          <span
            style={{
              display: 'block',
              borderBottom: '1px solid rgba(255, 255, 255, 0.15)',
              marginBottom: '1rem',
              marginTop: '1rem',
            }}
          ></span>
        );
    }
  }, []);

  const formatLastUpdated = useCallback((lastUpdated: Date | null | undefined, label: string) => {
    return (
      <span className={lastUpdated && dayjs(lastUpdated).isAfter(dayjs().subtract(2, 'week')) ? 'neg' : 'mute'}>
        {label}: {lastUpdated ? toShort(lastUpdated) : _.isUndefined(lastUpdated) ? 'Unknown' : 'Never'}
      </span>
    );
  }, []);
  const fields = useMemo(() => {
    let fieldList: DetailField[] = [];
    if (account) {
      fieldList = [
        ...fieldList,
        { Value: () => <>TB Account &amp; Customer Information</>, className: 'fields-title' },
        { Value: () => <>Bank Account Information</>, hide: !relationship, className: 'fields-title' },

        {
          title: 'Account title',
          Value: () => <div className="mute mb-3">{account.AccountTitle}</div>,
        },
        {
          title: 'Bank name',
          Value: () => <div className="mute mb-3">{relationship && relationship.bankName}</div>,
          hide: !relationship,
        },

        { title: 'Account number', Value: () => <div className="mute mb-3">{account.AccountNumber}</div> },
        {
          title: 'Bank account number',
          Value: () => (
            <div className="mb-3">
              <div className="mute mb-3">{relationship && relationship.bankAccount}</div>
              {relationshipDetails && relationshipDetails.bankAccountBalance && (
                <>
                  <div className="mt-3">Balance</div>

                  <div className="mute">
                    <Number value={relationshipDetails.bankAccountBalance} currency />
                  </div>
                </>
              )}
            </div>
          ),
          hide: !relationship,
        },
        {
          title: 'Account status',
          Value: () => getAccountStatus(accountStatus || 'Unknown'),
        },
        {
          title: 'Recent activity',
          Value: () => (
            <div className="mb-3">
              <div>{relationship && formatLastUpdated(relationship.lastModifiedDate, 'Bank info last modified')}</div>
              {relationship && relationship.approvalMethod === 'Plaid' && relationshipDetails && (
                <div>
                  {relationshipDetails && (
                    <div>
                      <div className="mute">
                        Last transaction:{' '}
                        {relationshipDetails.bankAccountLastTransactionDate
                          ? dayjs(relationshipDetails.bankAccountLastTransactionDate).format('MMM D, YYYY')
                          : 'No transactions in the last 30 days.'}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ),
          hide: !relationship,
        },
        {
          title: 'Rep name',
          Value: () => (
            <div className="mute mb-3">
              {repFirstName} {repLastName || 'None'}
            </div>
          ),
        },
        {
          title: 'Approval method',
          Value: () => <div className="mute mb-3">{relationship && relationship.approvalMethod}</div>,
          hide: !relationship,
        },
        { title: 'Rep code', Value: () => <div className="mute mb-3">{account.RepCode || 'None'}</div> },
        {
          title: 'Status Approved',
          Value: () => {
            if (relationship && !relationship.approval) {
              return <div className="mute mb-3">N/A</div>;
            } else if (relationship && relationship.approval) {
              return <div className="mute mb-3">{dayjs(relationship.approval.approvalTime).format('MMM D, YYYY')}</div>;
            }
          },
          hide: !relationship,
        },

        {
          title: 'Rep Phone Number',
          Value: () => <div className="mute mb-3">{formatPhoneNumber(repPhoneNumber)}</div>,
        },
        {
          Value: () => getBankRiskScore(relationshipDetails && relationshipDetails.bankRiskScore),
          hide: !relationshipDetails,
        },

        {
          title: 'Account type',
          Value: () => <div className="mute mb-3">{account.AccountType && AccountType[account.AccountType]}</div>,
        },
        {
          title: 'Bank account nickname',
          Value: () => <div className="mute mb-3">{relationship && relationship.nickName}</div>,
          hide: !relationship,
        },

        {
          title: 'Account owner name(s)',
          Value: () => {
            let hasAllOwners;
            const isMatch = accountNames.some(name => {
              const upperName = name.toUpperCase();
              const upperSecret =
                relationshipDetails && relationshipDetails.bankAccountOwnerNameSecret
                  ? relationshipDetails.bankAccountOwnerNameSecret.toUpperCase()
                  : '';
              const nameWords = upperName.split(' ').filter(word => word.length > 1);
              const secretWords = upperSecret.split(' ').filter(word => word.length > 1);
              hasAllOwners = nameWords.every(word => secretWords.includes(word));
              return hasAllOwners;
            });
            const status = isMatch ? 'pos' : !relationship ? 'mute' : 'neg';
            return (
              <>
                <div className={`${status} mb-3`}>{_.join(accountNames, ' / ')}</div>
                {!hasAllOwners && relationshipDetails && (
                  <>
                    <div className="txt-sm warn mt-3">*Review Owners/Signatories*</div>
                  </>
                )}
              </>
            );
          },
        },
        {
          title: 'Bank account signatories',
          Value: () => {
            // check if the bankAccountOwnerNameSecret matches the accountNames irregardless of order or case sensitivity
            // should also return as positive if the bankAccountOwnerNameSecret is a substring of any of the accountNames
            // so for example "Kevin Smith" would return positive for "Kevin Smith" and "Kevin Smith Jr" or "Kevin A Smith"
            const isMatch = accountNames.some(name => {
              const upperName = name.toUpperCase();
              const upperSecret =
                relationshipDetails && relationshipDetails.bankAccountOwnerNameSecret
                  ? relationshipDetails.bankAccountOwnerNameSecret.toUpperCase()
                  : '';
              const nameWords = upperName.split(' ').filter(word => word.length > 1);
              const secretWords = upperSecret.split(' ').filter(word => word.length > 1);
              const hasAllOwners = nameWords.every(word => secretWords.includes(word));
              return hasAllOwners;
            });

            const status = isMatch ? 'pos' : 'neg';
            return (
              <>
                <div className={`${status} mb-3`}>
                  {/* format comes in as all uppercase, need to format to only capitalize first letter of words */}
                  {relationshipDetails && _.startCase(_.toLower(relationshipDetails.bankAccountOwnerNameSecret))}
                </div>
              </>
            );
          },
          hide: !relationship,
        },

        {
          title: 'Physical address',
          Value: () => (
            <div className="mute mb-3">
              {physicalAddress ? (
                <div>
                  {physicalAddress.address1 && <div>{physicalAddress.address1}</div>}
                  {physicalAddress.address2 && <div>{physicalAddress.address2}</div>}
                  <div>
                    {physicalAddress.city}
                    {physicalAddress.state ? ', ' + physicalAddress.state : ''} {physicalAddress.postalCode}
                  </div>
                  <div> {physicalAddress.country}</div>
                </div>
              ) : (
                'none'
              )}
            </div>
          ),
        },
        {
          title: 'Primary address',
          Value: () => (
            <div className="mute mb-3">
              {relationshipDetails && relationshipDetails.reviewAddresses && (
                <div>
                  {relationshipDetails.reviewAddresses[0].street && (
                    <div>{relationshipDetails.reviewAddresses[0].street}</div>
                  )}
                  <div>
                    {relationshipDetails.reviewAddresses[0].city}
                    {relationshipDetails.reviewAddresses[0].stateOrRegion
                      ? ', ' + relationshipDetails.reviewAddresses[0].stateOrRegion
                      : ''}{' '}
                    {relationshipDetails.reviewAddresses[0].postalCode}
                    <div>{relationshipDetails.reviewAddresses[0].country}</div>
                  </div>
                </div>
              )}
            </div>
          ),
          hide: !relationship,
        },
        {
          title: 'Mailing address',
          Value: () => (
            <div className="mute mb-3">
              {mailingAddress ? (
                <div>
                  {mailingAddress.address1 && <div>{mailingAddress.address1}</div>}
                  {mailingAddress.address2 && <div>{mailingAddress.address2}</div>}
                  <div>
                    {mailingAddress.city}
                    {mailingAddress.state ? ', ' + mailingAddress.state : ''} {mailingAddress.postalCode}
                  </div>
                  <div>{mailingAddress.country}</div>
                </div>
              ) : (
                'none'
              )}
            </div>
          ),
        },
        {
          title: 'Secondary address',
          Value: () => (
            <div className="mute mb-3">
              {relationshipDetails && relationshipDetails.reviewAddresses && relationshipDetails.reviewAddresses[1] && (
                <div>
                  {relationshipDetails.reviewAddresses[1].street && (
                    <div>{relationshipDetails.reviewAddresses[1].street}</div>
                  )}
                  <div>
                    {relationshipDetails.reviewAddresses[1].city}
                    {relationshipDetails.reviewAddresses[1].stateOrRegion
                      ? ', ' + relationshipDetails.reviewAddresses[1].stateOrRegion
                      : ''}{' '}
                    {relationshipDetails.reviewAddresses[1].postalCode}
                  </div>
                  <div>{relationshipDetails.reviewAddresses[1].country}</div>
                </div>
              )}
            </div>
          ),
          hide: !relationship,
        },

        {
          title: 'Email address',
          Value: () => <div className="mute mb-3">{email ? <a href={`mailto:${email}`}>{email}</a> : <>none</>}</div>,
        },
        {
          title: 'Relationship Email address',
          Value: () => (
            <div className="mute mb-3">
              {relationshipDetails && (
                <ValueArray
                  values={relationshipDetails.reviewEmails}
                  renderer={email => <a href={`mailto:${email}`}>{email}</a>}
                  delimiter=" / "
                />
              )}
            </div>
          ),
          hide: !relationship,
        },

        {
          title: 'Phone',
          Value: () => (
            <div className="mute mb-3">
              {homePhone && (
                <div className="mb-3">
                  <a href={`tel:${homePhone}`}>{homePhone}</a> (home)
                </div>
              )}
              {workPhone && (
                <div className="mb-3">
                  <a href={`tel:${workPhone}`}>{workPhone}</a> (work)
                </div>
              )}
              {mobilePhone && (
                <div className="mb-3">
                  <a href={`tel:${mobilePhone}`}>{mobilePhone}</a> (mobile)
                </div>
              )}
            </div>
          ),
        },
        {
          title: 'Relationship Phone',
          Value: () => (
            <div className="mute mb-3">
              {relationshipDetails && (
                <ValueArray
                  values={relationshipDetails.reviewPhoneNumbers}
                  renderer={phone => <a href={`tel:${phone}`}>{phone}</a>}
                  delimiter=" / "
                />
              )}
            </div>
          ),
          hide: !relationship,
        },
      ];
    }
    return fieldList;
  }, [
    account,
    accountNames,
    mailingAddress,
    physicalAddress,
    email,
    homePhone,
    mobilePhone,
    workPhone,
    relationship,
    relationshipDetails,
  ]);

  if (!account && !accountDetails) {
    return null;
  }

  return (
    <div className="screen-body-section">
      <DetailFields fieldList={fields} valueAs="div" />

      {lastChanged && (
        <div>
          <div className="mb-3">Last updated contacts</div>
          <div className="mute">
            <div className="mb-3">{formatLastUpdated(lastChanged.email, 'Email address')}</div>
            <div className="mb-3">{formatLastUpdated(lastChanged.phone, 'Phone number')}</div>
            <div>{formatLastUpdated(lastChanged.address, 'Mailing address(es)')}</div>
          </div>
        </div>
      )}
    </div>
  );
};
