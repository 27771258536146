/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Cell } from 'react-table';
import { ActivitySummaryProps, SummaryBuilder } from '../SummaryBuilder';
import { useStateSelector } from '../../../data/global/dataSelectors';
import {
  getAccountPnL,
  PnLBySymbol,
  pnlSelectors,
  isSummaryDataLoaded,
} from '../../../data/global/selectors/PnLSelectors';

import { Number, Column, SignedNumber } from '@tradingblock/components';
import { BlockTable } from '../../shared/BlockTable';

import { DirectionalPrice } from '../../../components/trading/DirectionalPrice';
import _ from 'lodash';

import { PrettySymbol } from '../../../components/blocks/content/PrettySymbol';
import { PerformanceSelectors } from '../../../data/global/selectors/performanceSelectors';

const BeforeAndAfterTrade: React.FunctionComponent<{ quantity: number; price: number; direction: string }> = ({
  quantity,
  price,
  direction,
}) => {
  return (
    <div>
      <span className={direction}>{quantity}</span>@<Number value={price} />
    </div>
  );
};
const columns: any[] = [
  {
    Header: 'Symbol',
    canSort: true,
    accessor: (a: PnLBySymbol) => {
      return a.symbol;
    },
    id: 'symbol',
    Cell: ({ row }: Cell<PnLBySymbol>) => <PrettySymbol symbol={row.original.symbol} condensced={true} />,
  },

  {
    Header: 'Last Price',
    accessor: (a: PnLBySymbol) => a.lastTradePrice,
    id: 'lastprice',
    canSort: true,
    sortType: 'alphanumeric',
    Cell: ({ row }: Cell<PnLBySymbol>) => <DirectionalPrice value={row.original.lastTradePrice} />,
  },

  {
    Header: 'Previous Settle',
    canSort: true,
    accessor: (a: PnLBySymbol) => {
      return a.previousSettle;
    },
    id: 'prevsettle',
    Cell: ({ row }: Cell<PnLBySymbol>) => {
      return <Number value={row.original.previousSettle} currency />;
    },
  },
  {
    Header: 'Position on Open',
    canSort: true,
    accessor: (a: PnLBySymbol) => {
      return a.positionOnOpen;
    },
    id: 'posOpen',
    Cell: ({ row }: Cell<PnLBySymbol>) => {
      return row.original.positionOnOpen;
    },
  },
  {
    Header: 'Qty Bought',
    canSort: true,
    accessor: (a: PnLBySymbol) => {
      return a.quantityBought;
    },
    id: 'qtyBought',
  },
  {
    Header: 'Qty Sold',
    canSort: true,
    accessor: (a: PnLBySymbol) => {
      return a.quantitySold;
    },
    id: 'qtySold',
  },
  {
    Header: 'Net Qty Change',
    canSort: true,
    accessor: (a: PnLBySymbol) => {
      return a.netQuantityChange;
    },
    id: 'qtyChange',
  },
  {
    Header: 'Current position',
    canSort: true,
    accessor: (a: PnLBySymbol) => {
      return a.currentPosition;
    },
    id: 'currentPosition',
  },
  {
    Header: 'Avg Price Bought',
    canSort: true,
    accessor: (a: PnLBySymbol) => {
      return _.round(a.avgBoughtPrice, 2);
    },
    id: 'avgBoughtPrice',
    Cell: ({ row }: Cell<PnLBySymbol>) => (
      <>{row.original.avgBoughtPrice !== 0 && <Number value={row.original.avgBoughtPrice} currency />}</>
    ),
  },
  {
    Header: 'Avg Price Sold',
    canSort: true,
    accessor: (a: PnLBySymbol) => {
      return _.round(a.avgSoldPrice, 2);
    },
    id: 'avgSoldPrice',
    Cell: ({ row }: Cell<PnLBySymbol>) => (
      <>{row.original.avgSoldPrice !== 0 && <Number value={row.original.avgSoldPrice} currency />}</>
    ),
  },
  {
    Header: 'Trading P/L',
    canSort: true,
    accessor: (a: PnLBySymbol) => {
      return a.tradingPnL;
    },
    id: 'tradingpnl',
    Cell: ({ row }: Cell<PnLBySymbol>) => {
      const { trading, position, total } = useStateSelector(s => PerformanceSelectors.bySymbol(s, row.original.symbol));
      return (
        <>
          <SignedNumber value={trading} currency hideIcon />
          {/* <SignedNumber value={row.original.tradingPnL} currency hideIcon /> */}
        </>
      );
    },
  },
  {
    Header: 'Position P/L',
    canSort: true,
    accessor: (a: PnLBySymbol) => a.positionPnL,
    id: 'positionpnl',
    Cell: ({ row }: Cell<PnLBySymbol>) => {
      const { position } = useStateSelector(s => PerformanceSelectors.bySymbol(s, row.original.symbol));
      return (
        <>
          <SignedNumber value={position} currency hideIcon />
          {/* <SignedNumber value={row.original.positionPnL} currency hideIcon /> */}
        </>
      );
    },
  },
  {
    Header: 'Daily P/L',
    canSort: true,
    accessor: (a: PnLBySymbol) => a.totalPnL,
    id: 'totalpnl',
    Cell: ({ row }: Cell<PnLBySymbol>) => {
      const { total } = useStateSelector(s => PerformanceSelectors.bySymbol(s, row.original.symbol));
      return (
        <>
          <SignedNumber value={total} currency hideIcon />
          {/* <SignedNumber value={row.original.totalPnL} currency hideIcon /> */}
        </>
      );
    },
  },
  {
    Header: 'Est. Commission',
    canSort: true,
    accessor: (a: PnLBySymbol) => a.totalPnL,
    id: 'estCommission',
    Cell: ({ row }: Cell<PnLBySymbol>) => {
      return (
        <>
          <Number value={row.original.estCommission} currency />
        </>
      );
    },
  },
];

export const ActivitySummaryTable = () => {
  // const data = usePnL({ startDate, endDate });
  const data = useStateSelector(s =>
    pnlSelectors.trading(s, {
      startDate: dayjs(new Date())
        .hour(0)
        .toDate(),
      endDate: dayjs(new Date()).toDate(),
    })
  );
  const isLoaded = useStateSelector(isSummaryDataLoaded);
  return (
    <BlockTable
      tableKey="summary"
      loaded={isLoaded}
      sortable
      columns={columns}
      data={data}
      rowClass="row-link"
      frozenHeaders={true}
    />
  );
};
