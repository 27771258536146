import React, { useCallback } from 'react';
import { useDispatcher } from '../../data/global/hooks';
import { Hotkey, HotkeyProps } from '@tradingblock/components';

export const GridHotkeys: React.FunctionComponent<Pick<HotkeyProps, 'modifier'>> = ({ modifier }) => {
  const { dispatcher } = useDispatcher();
  const toggleLocked = useCallback(() => dispatcher.grid.toggleLocked({ showTooltip: false }), [dispatcher]);
  return (
    <>
      <Hotkey keys="e" modifier={modifier} callback={toggleLocked} />
    </>
  );
};
