import React, { useMemo } from 'react';
import _ from 'lodash';
import { BlockProps } from '@tradingblock/components';
import { useStateSelector } from '../../data/global/dataSelectors';
import { useBlockMetadata } from './BlockState';

type BlockLinkingMaskProps = Pick<BlockProps, 'type' | 'isLinkable'> & {
  isLinked: boolean;
  onClick: () => void;
  onCancelClick: () => void;
};

export const BlockLinkingMask: React.FunctionComponent<BlockLinkingMaskProps> = ({ type, isLinkable, isLinked, onClick, onCancelClick }) => {
  const { blockId, groupId } = useBlockMetadata();
  const sourceBlock = useStateSelector(s => s.blocks.linkSourceBlock);
  const targetBlock = useStateSelector(s => s.blocks.linkTargetBlock);
  const linkingCurrentGroup = sourceBlock && groupId === sourceBlock.groupId;
  const isLinkableToGroup = isLinkable && !linkingCurrentGroup && (!targetBlock || type === targetBlock.type);
  const isCancellable = linkingCurrentGroup && sourceBlock && sourceBlock.blockId === blockId;
  const linkStateIndex = useMemo(() => {
    if (isLinkableToGroup) {
      return 0;
    }
    if (isCancellable) {
      return 1;
    }
    return 2;
  }, [isLinkableToGroup, isCancellable]);

  return (
    <div className={`block-mask block-linkable ${_.get(['', '', 'block-unlinkable'], linkStateIndex)}${isLinked && linkingCurrentGroup ? ' current-group' : ''}`} onClick={_.get([onClick, onCancelClick, undefined], linkStateIndex)} title={_.get(['Link to this block', 'Cancel linking', 'Not available for linking'], linkStateIndex)}>
      <i className={`fas ${_.get(['fa-link', 'fa-undo', 'fa-unlink'], linkStateIndex)}`} />
    </div>
  );
};
