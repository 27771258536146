import { createStandardAction } from 'typesafe-actions';
import {
  AccountRequestFor,
  RecipientBankRequest,
  TransferRecipientBank,
  GenericError,
  AchDepositRequest,
  Balances,
  Transfer,
  DeleteAchRelationshipRequest,
  AchRelationship,
  UpdateAchRelationshipRequest,
  ApproveAchRelationshipRequest,
  CreateAchRelationshipRequest,
  UpdateRecipientBankRequest,
  DeleteRecipientBankRequest,
  CreateRecipientBankRequest,
  AchWithdrawalRequest,
  WireWithdrawalRequest,
  CheckWithdrawalRequest,
  TransferBeneficiary,
  TransferBeneficiaryRequest,
  CreateTransferBeneficiaryRequest,
  UpdateTransferBeneficiaryRequest,
  DeleteTransferBeneficiaryRequest,
  DeleteTransferRequest,
  SecurityChallenge,
  SecurityQuestionType,
  PlaidLinkToken,
  TransferInstruction,
  UpdateTransferInstructionsRequest,
  DeleteTransferInstructionsRequest,
  CreateTransferInstructionRequest,
} from '@tradingblock/types';
import { buildActions } from '../actionUtilities';

const RESET_FORM = '@cashiering/RESET_FORM';

const REQUEST_TRANSFERS = '@cashiering/REQUEST_TRANSFERS';
const RECEIVE_TRANSFERS = '@cashiering/RECEIVE_TRANSFERS';

const SECURITY_QUESTION_REQUEST = '@cashiering/securityQuestion/get/REQUEST';
const SECURITY_QUESTION_RECEIVE = '@cashiering/securityQuestion/get/RECEIVE';
const SECURITY_QUESTION_ERROR = '@cashiering/securityQuestion/get/ERROR';
const VERIFY_SECURITY_QUESTION_REQUEST = '@cashiering/securityQuestion/verify/REQUEST';
const VERIFY_SECURITY_QUESTION_RECEIVE = '@cashiering/securityQuestion/verify/RECEIVE';
const RESET_SECURITY_QUESTION_STATUS = '@cashiering/securityQuestion/verify/RESET';

const CASHIERING_BALANCES_REQUEST = '@cashiering/REQUEST_BALANCES';
const CASHIERING_BALANCES_RECEIVE = '@cashiering/RECEIVE_BALANCES';

const CASHIERING_TRANSFERS_ACH_DEPOSIT_REQUEST = '@cashiering/transfers/achDeposit/REQUEST';
const CASHIERING_TRANSFERS_ACH_DEPOSIT_RECEIVE = '@cashiering/transfers/achDeposit/RECEIVE';
const CASHIERING_TRANSFERS_ACH_DEPOSIT_ERROR = '@cashiering/transfers/achDeposit/ERROR';
const CASHIERING_TRANSFERS_ACH_WITHDRAWAL_REQUEST = '@cashiering/transfers/achWithdrawal/REQUEST';
const CASHIERING_TRANSFERS_ACH_WITHDRAWAL_RECEIVE = '@cashiering/transfers/achWithdrawal/RECEIVE';
const CASHIERING_TRANSFERS_ACH_WITHDRAWAL_ERROR = '@cashiering/transfers/achWithdrawal/ERROR';
const CASHIERING_TRANSFERS_WIRE_WITHDRAWAL_REQUEST = '@cashiering/transfers/wireWithdrawal/REQUEST';
const CASHIERING_TRANSFERS_WIRE_WITHDRAWAL_RECEIVE = '@cashiering/transfers/wireWithdrawal/RECEIVE';
const CASHIERING_TRANSFERS_WIRE_WITHDRAWAL_ERROR = '@cashiering/transfers/wireWithdrawal/ERROR';
const CASHIERING_TRANSFERS_CHECK_WITHDRAWAL_REQUEST = '@cashiering/transfers/checkWithdrawal/REQUEST';
const CASHIERING_TRANSFERS_CHECK_WITHDRAWAL_RECEIVE = '@cashiering/transfers/checkWithdrawal/RECEIVE';
const CASHIERING_TRANSFERS_CHECK_WITHDRAWAL_ERROR = '@cashiering/transfers/checkWithdrawal/ERROR';
const CASHIERING_TRANSFERS_DELETE_REQUEST = '@cashiering/transfers/delete/REQUEST';
const CASHIERING_TRANSFERS_DELETE_RECEIVE = '@cashiering/transfers/delete/RECEIVE';
const CASHIERING_TRANSFERS_DELETE_ERROR = '@cashiering/transfers/delete/ERROR';

const CASHIERING_ACH_ALL_REQUEST = '@cashiering/ach/all/REQUEST';
const CASHIERING_ACH_ALL_RECEIVE = '@cashiering/ach/all/RECEIVE';
const CASHIERING_ACH_ALL_ERROR = '@cashiering/ach/all/ERROR';
const CASHIERING_PLAID_LINK_TOKEN_REQUEST = '@cashiering/plaidLinkToken/REQUEST';
const CASHIERING_PLAID_LINK_TOKEN_RECEIVE = '@cashiering/plaidLinkToken/RECEIVE';
const CASHIERING_PLAID_LINK_TOKEN_ERROR = '@cashiering/plaidLinkToken/ERROR';
const CASHIERING_ACH_CREATE_REQUEST = '@cashiering/ach/create/REQUEST';
const CASHIERING_ACH_CREATE_RECEIVE = '@cashiering/ach/create/RECEIVE';
const CASHIERING_ACH_CREATE_ERROR = '@cashiering/ach/create/ERROR';
const CASHIERING_ACH_APPROVE_REQUEST = '@cashiering/ach/approve/REQUEST';
const CASHIERING_ACH_APPROVE_RECEIVE = '@cashiering/ach/approve/RECEIVE';
const CASHIERING_ACH_APPROVE_ERROR = '@cashiering/ach/approve/ERROR';
const CASHIERING_ACH_UPDATE_REQUEST = '@cashiering/ach/update/REQUEST';
const CASHIERING_ACH_UPDATE_RECEIVE = '@cashiering/ach/update/RECEIVE';
const CASHIERING_ACH_UPDATE_ERROR = '@cashiering/ach/update/ERROR';
const CASHIERING_ACH_DELETE_REQUEST = '@cashiering/ach/delete/REQUEST';
const CASHIERING_ACH_DELETE_RECEIVE = '@cashiering/ach/delete/RECEIVE';
const CASHIERING_ACH_DELETE_ERROR = '@cashiering/ach/delete/ERROR';

const CASHIERING_TRANSFERRECIPIENTBANK_GET_REQUEST = '@cashiering/transferrecipientbank/get/REQUEST';
const CASHIERING_TRANSFERRECIPIENTBANK_GET_RECEIVE = '@cashiering/transferrecipientbank/get/RECEIVE';
const CASHIERING_TRANSFERRECIPIENTBANK_GET_ERROR = '@cashiering/transferrecipientbank/get/ERROR';
const CASHIERING_TRANSFERRECIPIENTBANK_ALL_REQUEST = '@cashiering/transferrecipientbank/all/REQUEST';
const CASHIERING_TRANSFERRECIPIENTBANK_ALL_RECEIVE = '@cashiering/transferrecipientbank/all/RECEIVE';
const CASHIERING_TRANSFERRECIPIENTBANK_ALL_ERROR = '@cashiering/transferrecipientbank/all/ERROR';
const CASHIERING_TRANSFERRECIPIENTBANK_CREATE_REQUEST = '@cashiering/transferrecipientbank/create/REQUEST';
const CASHIERING_TRANSFERRECIPIENTBANK_CREATE_RECEIVE = '@cashiering/transferrecipientbank/create/RECEIVE';
const CASHIERING_TRANSFERRECIPIENTBANK_CREATE_ERROR = '@cashiering/transferrecipientbank/create/ERROR';
const CASHIERING_TRANSFERRECIPIENTBANK_UPDATE_REQUEST = '@cashiering/transferrecipientbank/update/REQUEST';
const CASHIERING_TRANSFERRECIPIENTBANK_UPDATE_RECEIVE = '@cashiering/transferrecipientbank/update/RECEIVE';
const CASHIERING_TRANSFERRECIPIENTBANK_UPDATE_ERROR = '@cashiering/transferrecipientbank/update/ERROR';
const CASHIERING_TRANSFERRECIPIENTBANK_DELETE_REQUEST = '@cashiering/transferrecipientbank/delete/REQUEST';
const CASHIERING_TRANSFERRECIPIENTBANK_DELETE_RECEIVE = '@cashiering/transferrecipientbank/delete/RECEIVE';
const CASHIERING_TRANSFERRECIPIENTBANK_DELETE_ERROR = '@cashiering/transferrecipientbank/delete/ERROR';

const CASHIERING_TRANSFERBENEFICIARY_GET_REQUEST = '@cashiering/transferbeneficiary/get/REQUEST';
const CASHIERING_TRANSFERBENEFICIARY_GET_RECEIVE = '@cashiering/transferbeneficiary/get/RECEIVE';
const CASHIERING_TRANSFERBENEFICIARY_GET_ERROR = '@cashiering/transferbeneficiary/get/ERROR';
const CASHIERING_TRANSFERBENEFICIARY_ALL_REQUEST = '@cashiering/transferbeneficiary/all/REQUEST';
const CASHIERING_TRANSFERBENEFICIARY_ALL_RECEIVE = '@cashiering/transferbeneficiary/all/RECEIVE';
const CASHIERING_TRANSFERBENEFICIARY_ALL_ERROR = '@cashiering/transferbeneficiary/all/ERROR';
const CASHIERING_TRANSFERBENEFICIARY_CREATE_REQUEST = '@cashiering/transferbeneficiary/create/REQUEST';
const CASHIERING_TRANSFERBENEFICIARY_CREATE_RECEIVE = '@cashiering/transferbeneficiary/create/RECEIVE';
const CASHIERING_TRANSFERBENEFICIARY_CREATE_ERROR = '@cashiering/transferbeneficiary/create/ERROR';
const CASHIERING_TRANSFERBENEFICIARY_UPDATE_REQUEST = '@cashiering/transferbeneficiary/update/REQUEST';
const CASHIERING_TRANSFERBENEFICIARY_UPDATE_RECEIVE = '@cashiering/transferbeneficiary/update/RECEIVE';
const CASHIERING_TRANSFERBENEFICIARY_UPDATE_ERROR = '@cashiering/transferbeneficiary/update/ERROR';
const CASHIERING_TRANSFERBENEFICIARY_DELETE_REQUEST = '@cashiering/transferbeneficiary/delete/REQUEST';
const CASHIERING_TRANSFERBENEFICIARY_DELETE_RECEIVE = '@cashiering/transferbeneficiary/delete/RECEIVE';
const CASHIERING_TRANSFERBENEFICIARY_DELETE_ERROR = '@cashiering/transferbeneficiary/delete/ERROR';

const CASHIERING_TRANSFERINSTRUCTIONS_GET_REQUEST = '@cashiering/transferinstructions/get/REQUEST';
const CASHIERING_TRANSFERINSTRUCTIONS_GET_RECEIVE = '@cashiering/transferinstructions/get/RECEIVE';
const CASHIERING_TRANSFERINSTRUCTIONS_GET_ERROR = '@cashiering/transferinstructions/get/ERROR';
const CASHIERING_TRANSFERINSTRUCTIONS_ALL_REQUEST = '@cashiering/transferinstructions/all/REQUEST';
const CASHIERING_TRANSFERINSTRUCTIONS_ALL_RECEIVE = '@cashiering/transferinstructions/all/RECEIVE';
const CASHIERING_TRANSFERINSTRUCTIONS_ALL_ERROR = '@cashiering/transferinstructions/all/ERROR';
const CASHIERING_TRANSFERINSTRUCTIONS_CREATE_REQUEST = '@cashiering/transferinstructions/create/REQUEST';
const CASHIERING_TRANSFERINSTRUCTIONS_CREATE_RECEIVE = '@cashiering/transferinstructions/create/RECEIVE';
const CASHIERING_TRANSFERINSTRUCTIONS_CREATE_ERROR = '@cashiering/transferinstructions/create/ERROR';
const CASHIERING_TRANSFERINSTRUCTIONS_UPDATE_REQUEST = '@cashiering/transferinstructions/update/REQUEST';
const CASHIERING_TRANSFERINSTRUCTIONS_UPDATE_RECEIVE = '@cashiering/transferinstructions/update/RECEIVE';
const CASHIERING_TRANSFERINSTRUCTIONS_UPDATE_ERROR = '@cashiering/transferinstructions/update/ERROR';
const CASHIERING_TRANSFERINSTRUCTIONS_DELETE_REQUEST = '@cashiering/transferinstructions/delete/REQUEST';
const CASHIERING_TRANSFERINSTRUCTIONS_DELETE_RECEIVE = '@cashiering/transferinstructions/delete/RECEIVE';
const CASHIERING_TRANSFERINSTRUCTIONS_DELETE_ERROR = '@cashiering/transferinstructions/delete/ERROR';

const [requestAchDeposit, receiveAchDeposit, depositAchError] = buildActions({
  REQUEST: CASHIERING_TRANSFERS_ACH_DEPOSIT_REQUEST,
  RECEIVE: CASHIERING_TRANSFERS_ACH_DEPOSIT_RECEIVE,
  ERROR: CASHIERING_TRANSFERS_ACH_DEPOSIT_ERROR,
})<AccountRequestFor<AchDepositRequest>, Transfer>();
const [requestAchWithdrawal, receiveAchWithdrawal, withdrawalAchError] = buildActions({
  REQUEST: CASHIERING_TRANSFERS_ACH_WITHDRAWAL_REQUEST,
  RECEIVE: CASHIERING_TRANSFERS_ACH_WITHDRAWAL_RECEIVE,
  ERROR: CASHIERING_TRANSFERS_ACH_WITHDRAWAL_ERROR,
})<AccountRequestFor<AchWithdrawalRequest>, Transfer>();
const [requestWireWithdrawal, receiveWireWithdrawal, withdrawalWireError] = buildActions({
  REQUEST: CASHIERING_TRANSFERS_WIRE_WITHDRAWAL_REQUEST,
  RECEIVE: CASHIERING_TRANSFERS_WIRE_WITHDRAWAL_RECEIVE,
  ERROR: CASHIERING_TRANSFERS_WIRE_WITHDRAWAL_ERROR,
})<AccountRequestFor<WireWithdrawalRequest>, Transfer>();
const [requestCheckWithdrawal, receiveCheckWithdrawal, withdrawalCheckError] = buildActions({
  REQUEST: CASHIERING_TRANSFERS_CHECK_WITHDRAWAL_REQUEST,
  RECEIVE: CASHIERING_TRANSFERS_CHECK_WITHDRAWAL_RECEIVE,
  ERROR: CASHIERING_TRANSFERS_CHECK_WITHDRAWAL_ERROR,
})<AccountRequestFor<CheckWithdrawalRequest>, Transfer>();
const [deleteTransferRequest, deleteTransferReceive, deleteTransferError] = buildActions({
  REQUEST: CASHIERING_TRANSFERS_DELETE_REQUEST,
  RECEIVE: CASHIERING_TRANSFERS_DELETE_RECEIVE,
  ERROR: CASHIERING_TRANSFERS_DELETE_ERROR,
})<AccountRequestFor<DeleteTransferRequest>, Pick<Transfer, 'id'>>();

const TransferActions = {
  requestAchDeposit,
  receiveAchDeposit,
  depositAchError,
  requestAchWithdrawal,
  receiveAchWithdrawal,
  withdrawalAchError,
  requestWireWithdrawal,
  receiveWireWithdrawal,
  withdrawalWireError,
  requestCheckWithdrawal,
  receiveCheckWithdrawal,
  withdrawalCheckError,
  deleteTransferRequest,
  deleteTransferReceive,
  deleteTransferError,
};

const [allAchRelationshipRequest, allAchRelationshipReceive, allAchRelationshipError] = buildActions({
  REQUEST: CASHIERING_ACH_ALL_REQUEST,
  RECEIVE: CASHIERING_ACH_ALL_RECEIVE,
  ERROR: CASHIERING_ACH_ALL_ERROR,
})<number, AchRelationship[]>();
const [plaidLinkTokenRequest, plaidLinkTokenReceive, plaidLinkTokenError] = buildActions({
  REQUEST: CASHIERING_PLAID_LINK_TOKEN_REQUEST,
  RECEIVE: CASHIERING_PLAID_LINK_TOKEN_RECEIVE,
  ERROR: CASHIERING_PLAID_LINK_TOKEN_ERROR,
})<{ accountId: number; redirectUri: string }, PlaidLinkToken>();
const [createAchRelationshipRequest, createAchRelationshipReceive, createAchRelationshipError] = buildActions({
  REQUEST: CASHIERING_ACH_CREATE_REQUEST,
  RECEIVE: CASHIERING_ACH_CREATE_RECEIVE,
  ERROR: CASHIERING_ACH_CREATE_ERROR,
})<CreateAchRelationshipRequest, AchRelationship>();
const [approveAchRelationshipRequest, approveAchRelationshipReceive, approveAchRelationshipError] = buildActions({
  REQUEST: CASHIERING_ACH_APPROVE_REQUEST,
  RECEIVE: CASHIERING_ACH_APPROVE_RECEIVE,
  ERROR: CASHIERING_ACH_APPROVE_ERROR,
})<AccountRequestFor<ApproveAchRelationshipRequest>, AchRelationship>();
const [updateAchRelationshipRequest, updateAchRelationshipReceive, updateAchRelationshipError] = buildActions({
  REQUEST: CASHIERING_ACH_UPDATE_REQUEST,
  RECEIVE: CASHIERING_ACH_UPDATE_RECEIVE,
  ERROR: CASHIERING_ACH_UPDATE_ERROR,
})<AccountRequestFor<UpdateAchRelationshipRequest>, AchRelationship>();
const [deleteAchRelationshipRequest, deleteAchRelationshipReceive, deleteAchRelationshipError] = buildActions({
  REQUEST: CASHIERING_ACH_DELETE_REQUEST,
  RECEIVE: CASHIERING_ACH_DELETE_RECEIVE,
  ERROR: CASHIERING_ACH_DELETE_ERROR,
})<AccountRequestFor<DeleteAchRelationshipRequest>, Pick<AchRelationship, 'id'>>();

const AchRelationshipActions = {
  allAchRelationshipRequest,
  allAchRelationshipReceive,
  allAchRelationshipError,
  plaidLinkTokenRequest,
  plaidLinkTokenReceive,
  plaidLinkTokenError,
  createAchRelationshipRequest,
  createAchRelationshipReceive,
  createAchRelationshipError,
  approveAchRelationshipRequest,
  approveAchRelationshipReceive,
  approveAchRelationshipError,
  updateAchRelationshipRequest,
  updateAchRelationshipReceive,
  updateAchRelationshipError,
  deleteAchRelationshipRequest,
  deleteAchRelationshipReceive,
  deleteAchRelationshipError,
};

const [getTransferBankRequest, getTransferBankReceive, getTransferBankError] = buildActions({
  REQUEST: CASHIERING_TRANSFERRECIPIENTBANK_GET_REQUEST,
  RECEIVE: CASHIERING_TRANSFERRECIPIENTBANK_GET_RECEIVE,
  ERROR: CASHIERING_TRANSFERRECIPIENTBANK_GET_ERROR,
})<RecipientBankRequest, TransferRecipientBank>();
const [allTransferBankRequest, allTransferBankReceive, allTransferBankError] = buildActions({
  REQUEST: CASHIERING_TRANSFERRECIPIENTBANK_ALL_REQUEST,
  RECEIVE: CASHIERING_TRANSFERRECIPIENTBANK_ALL_RECEIVE,
  ERROR: CASHIERING_TRANSFERRECIPIENTBANK_ALL_ERROR,
})<number, TransferRecipientBank[]>();
const [createTransferBankRequest, createTransferBankReceive, createTransferBankError] = buildActions({
  REQUEST: CASHIERING_TRANSFERRECIPIENTBANK_CREATE_REQUEST,
  RECEIVE: CASHIERING_TRANSFERRECIPIENTBANK_CREATE_RECEIVE,
  ERROR: CASHIERING_TRANSFERRECIPIENTBANK_CREATE_ERROR,
})<AccountRequestFor<any>, TransferRecipientBank>();
const [updateTransferBankRequest, updateTransferBankReceive, updateTransferBankError] = buildActions({
  REQUEST: CASHIERING_TRANSFERRECIPIENTBANK_UPDATE_REQUEST,
  RECEIVE: CASHIERING_TRANSFERRECIPIENTBANK_UPDATE_RECEIVE,
  ERROR: CASHIERING_TRANSFERRECIPIENTBANK_UPDATE_ERROR,
})<AccountRequestFor<UpdateRecipientBankRequest>, Pick<TransferRecipientBank, 'id'>>();
const [deleteTransferBankRequest, deleteTransferBankReceive, deleteTransferBankError] = buildActions({
  REQUEST: CASHIERING_TRANSFERRECIPIENTBANK_DELETE_REQUEST,
  RECEIVE: CASHIERING_TRANSFERRECIPIENTBANK_DELETE_RECEIVE,
  ERROR: CASHIERING_TRANSFERRECIPIENTBANK_DELETE_ERROR,
})<AccountRequestFor<DeleteRecipientBankRequest>, Pick<TransferRecipientBank, 'id'>>();

const TransferRecipientBankActions = {
  getTransferBankRequest,
  getTransferBankReceive,
  getTransferBankError,
  allTransferBankRequest,
  allTransferBankReceive,
  allTransferBankError,
  createTransferBankRequest,
  createTransferBankReceive,
  createTransferBankError,
  updateTransferBankRequest,
  updateTransferBankReceive,
  updateTransferBankError,
  deleteTransferBankRequest,
  deleteTransferBankReceive,
  deleteTransferBankError,
};

const [getTransferBeneficiaryRequest, getTransferBeneficiaryReceive, getTransferBeneficiaryError] = buildActions({
  REQUEST: CASHIERING_TRANSFERBENEFICIARY_GET_REQUEST,
  RECEIVE: CASHIERING_TRANSFERBENEFICIARY_GET_RECEIVE,
  ERROR: CASHIERING_TRANSFERBENEFICIARY_GET_ERROR,
})<TransferBeneficiaryRequest, TransferBeneficiary>();
const [allTransferBeneficiaryRequest, allTransferBeneficiaryReceive, allTransferBeneficiaryError] = buildActions({
  REQUEST: CASHIERING_TRANSFERBENEFICIARY_ALL_REQUEST,
  RECEIVE: CASHIERING_TRANSFERBENEFICIARY_ALL_RECEIVE,
  ERROR: CASHIERING_TRANSFERBENEFICIARY_ALL_ERROR,
})<number, TransferBeneficiary[]>();
const [
  createTransferBeneficiaryRequest,
  createTransferBeneficiaryReceive,
  createTransferBeneficiaryError,
] = buildActions({
  REQUEST: CASHIERING_TRANSFERBENEFICIARY_CREATE_REQUEST,
  RECEIVE: CASHIERING_TRANSFERBENEFICIARY_CREATE_RECEIVE,
  ERROR: CASHIERING_TRANSFERBENEFICIARY_CREATE_ERROR,
})<AccountRequestFor<CreateTransferBeneficiaryRequest>, TransferBeneficiary>();
const [
  updateTransferBeneficiaryRequest,
  updateTransferBeneficiaryReceive,
  updateTransferBeneficiaryError,
] = buildActions({
  REQUEST: CASHIERING_TRANSFERBENEFICIARY_UPDATE_REQUEST,
  RECEIVE: CASHIERING_TRANSFERBENEFICIARY_UPDATE_RECEIVE,
  ERROR: CASHIERING_TRANSFERBENEFICIARY_UPDATE_ERROR,
})<AccountRequestFor<UpdateTransferBeneficiaryRequest>, Pick<TransferBeneficiary, 'id'>>();
const [
  deleteTransferBeneficiaryRequest,
  deleteTransferBeneficiaryReceive,
  deleteTransferBeneficiaryError,
] = buildActions({
  REQUEST: CASHIERING_TRANSFERBENEFICIARY_DELETE_REQUEST,
  RECEIVE: CASHIERING_TRANSFERBENEFICIARY_DELETE_RECEIVE,
  ERROR: CASHIERING_TRANSFERBENEFICIARY_DELETE_ERROR,
})<AccountRequestFor<DeleteTransferBeneficiaryRequest>, Pick<TransferBeneficiary, 'id'>>();

const TransferBeneficiaryActions = {
  getTransferBeneficiaryRequest,
  getTransferBeneficiaryReceive,
  getTransferBeneficiaryError,
  allTransferBeneficiaryRequest,
  allTransferBeneficiaryReceive,
  allTransferBeneficiaryError,
  createTransferBeneficiaryRequest,
  createTransferBeneficiaryReceive,
  createTransferBeneficiaryError,
  updateTransferBeneficiaryRequest,
  updateTransferBeneficiaryReceive,
  updateTransferBeneficiaryError,
  deleteTransferBeneficiaryRequest,
  deleteTransferBeneficiaryReceive,
  deleteTransferBeneficiaryError,
};

const [getTransferInstructionsRequest, getTransferInstructionsReceive, getTransferInstructionsError] = buildActions({
  REQUEST: CASHIERING_TRANSFERINSTRUCTIONS_GET_REQUEST,
  RECEIVE: CASHIERING_TRANSFERINSTRUCTIONS_GET_RECEIVE,
  ERROR: CASHIERING_TRANSFERINSTRUCTIONS_GET_ERROR,
})<TransferInstruction, TransferInstruction>();

const [allTransferInstructionsRequest, allTransferInstructionsReceive, allTransferInstructionsError] = buildActions({
  REQUEST: CASHIERING_TRANSFERINSTRUCTIONS_ALL_REQUEST,
  RECEIVE: CASHIERING_TRANSFERINSTRUCTIONS_ALL_RECEIVE,
  ERROR: CASHIERING_TRANSFERINSTRUCTIONS_ALL_ERROR,
})<number, TransferInstruction[]>();

const [
  createTransferInstructionsRequest,
  createTransferInstructionsReceive,
  createTransferInstructionsError,
] = buildActions({
  REQUEST: CASHIERING_TRANSFERINSTRUCTIONS_CREATE_REQUEST,
  RECEIVE: CASHIERING_TRANSFERINSTRUCTIONS_CREATE_RECEIVE,
  ERROR: CASHIERING_TRANSFERINSTRUCTIONS_CREATE_ERROR,
})<AccountRequestFor<any>, TransferInstruction>();

const [
  updateTransferInstructionsRequest,
  updateTransferInstructionsReceive,
  updateTransferInstructionsError,
] = buildActions({
  REQUEST: CASHIERING_TRANSFERINSTRUCTIONS_UPDATE_REQUEST,
  RECEIVE: CASHIERING_TRANSFERINSTRUCTIONS_UPDATE_RECEIVE,
  ERROR: CASHIERING_TRANSFERINSTRUCTIONS_UPDATE_ERROR,
})<AccountRequestFor<UpdateTransferInstructionsRequest>, Pick<TransferInstruction, 'id'>>();

const [
  deleteTransferInstructionsRequest,
  deleteTransferInstructionsReceive,
  deleteTransferInstructionsError,
] = buildActions({
  REQUEST: CASHIERING_TRANSFERINSTRUCTIONS_DELETE_REQUEST,
  RECEIVE: CASHIERING_TRANSFERINSTRUCTIONS_DELETE_RECEIVE,
  ERROR: CASHIERING_TRANSFERINSTRUCTIONS_DELETE_ERROR,
})<AccountRequestFor<DeleteTransferInstructionsRequest>, Pick<TransferInstruction, 'id'>>();

const TransferInstructionsActions = {
  getTransferInstructionsRequest,
  getTransferInstructionsReceive,
  getTransferInstructionsError,
  allTransferInstructionsRequest,
  allTransferInstructionsReceive,
  allTransferInstructionsError,
  createTransferInstructionsRequest,
  createTransferInstructionsReceive,
  createTransferInstructionsError,
  updateTransferInstructionsRequest,
  updateTransferInstructionsReceive,
  updateTransferInstructionsError,
  deleteTransferInstructionsRequest,
  deleteTransferInstructionsReceive,
  deleteTransferInstructionsError,
};

export const CashieringActions = {
  ...TransferActions,
  ...AchRelationshipActions,
  ...TransferRecipientBankActions,
  ...TransferInstructionsActions,
  ...TransferBeneficiaryActions,
  requestTransfers: createStandardAction(REQUEST_TRANSFERS)(),
  receiveTransfers: createStandardAction(RECEIVE_TRANSFERS)<{ data: Transfer[] }>(),
  requestBalances: createStandardAction(CASHIERING_BALANCES_REQUEST)<{ subaccountId?: number }>(),
  receiveBalances: createStandardAction(CASHIERING_BALANCES_RECEIVE)<{
    balances: Balances;
    pendingTransfers?: Transfer[];
  }>(),
  requestSecurityQuestion: createStandardAction(SECURITY_QUESTION_REQUEST)(),
  receiveSecurityQuestion: createStandardAction(SECURITY_QUESTION_RECEIVE)<{ data: SecurityQuestionType }>(),
  securityQuestionError: createStandardAction(SECURITY_QUESTION_ERROR)<GenericError>(),
  requestVerifySecurityQuestion: createStandardAction(VERIFY_SECURITY_QUESTION_REQUEST)<SecurityChallenge>(),
  receiveVerifySecurityQuestion: createStandardAction(VERIFY_SECURITY_QUESTION_RECEIVE)<{
    result: boolean;
    message?: string;
  }>(),
  resetSecurityQuestionStatus: createStandardAction(RESET_SECURITY_QUESTION_STATUS)(),
  resetForm: createStandardAction(RESET_FORM)(),
};
