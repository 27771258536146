import React, { useCallback, useState } from 'react';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import {
  AccountManagementDocuments,
  AllBooleanToggleTypes,
  AllTradeGoalTypes,
  BooleanToggleType,
  ClearerType,
  Country,
  TradeGoalType,
  TradeGoalUpdateValueProps,
} from '@tradingblock/types';
import { FormGroup, CheckListField, Modal, StepperField, ExternalLink, Text } from '@tradingblock/components';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { useDispatch } from 'react-redux';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { RiskLevelIcon } from './components/RiskLevelIcon';
import { getRiskLevelForTradeGoalType } from '../../../utilities/accountManagement';
import { AccountManagementDataActions } from '../../../data/global/actions/AccountManagementActions';
import { getInvalidError, getRequiredError, getUploadError } from './Validation';
import { MiscUploadFileField } from '../../../components/form/UploadMiscFile';
import { useClearer } from '../../../hooks/useClearer';

/**
 * Modal for updating a user's tradeGoal (trade level)
 *
 * @param {boolean} show Boolean value used for showing/hiding modal
 * @param {void} toggleModal Function to toggle show from true or false
 * @returns {JSX.Element}
 */

export const formatTradeGoalType = (tradeGoalType: string) => {
  switch (tradeGoalType) {
    case 'StocksBonds':
      return 'Stocks & Bonds';
    case 'AllAbovePlusCallsPuts':
      return 'Stocks & Bonds + Calls & Puts';
    case 'AllAbovePlusOptionSpreads':
      return 'Stocks, Bonds, Calls, Puts + Option Spreads';
    case 'AllAbovePlusPutWriting':
      return 'Stocks, Bonds, Calls, Puts, Option Spreads + Put Writing';
    case 'AllAbovePlusAllOptionStrategies':
      return 'Stocks, Bonds, Calls, Puts, Option Spreads, Put Writing + All Option Strategies';
    default:
      return tradeGoalType.replace(/([A-Z])/g, ' $1').trim();
  }
};

export const TradeGoalUpdate: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
}> = ({ show, toggleModal }) => {
  // used hooks
  const dispatch = useDispatch();

  // state selectors
  const accountId = useStateSelector(s => s.accounts.account && s.accounts.account.AccountId);
  const accountManagementDetails = useStateSelector(s => s.accountManagement.accountManagementDetails.details);
  const { accountHolders } = accountManagementDetails;

  // Used States
  const [clickedAgreements, setClickedAgreements] = useState<string[]>([]);
  const [uploadIds, setUploadId] = useState<{ documentId: number; accountHolderIndex: number }[]>([]);
  const documentIds: {
    documentId: number;
    accountHolderIndex: number;
    documentType: AccountManagementDocuments;
  }[] = [];
  const [numberOfUploads, setNumberOfUploads] = useState<number>(0);

  // Retrieve documentId, accountHolderIndex, documentType of uploaded files, push into documentIds array for submission & increment numberOfUploads
  const handleDocumentUpload = (
    documentId: number,
    accountHolderIndex: number,
    documentType: AccountManagementDocuments
  ) => {
    if (documentId) {
      setUploadId([...uploadIds, { accountHolderIndex, documentId }]);
      documentIds.push({ documentId, accountHolderIndex, documentType });
      setNumberOfUploads(numberOfUploads => numberOfUploads + 1);
    }
    return;
  };

  // Log clicked agreements for disable/enable of accept/reject buttons
  const onClickedAgreementLink = useCallback(
    (agreement: string) => {
      setClickedAgreements([...clickedAgreements, agreement]);
    },
    [clickedAgreements]
  );

  // Return undefined, disabling button, for agreements that have not been clicked
  const getAgreementDisabledOptions = useCallback(
    (agreement: string) => {
      // if haven't clicked agreement, disable toggle buttons
      if (!_.includes(clickedAgreements, agreement)) {
        return AllBooleanToggleTypes;
      }
      return undefined;
    },
    [clickedAgreements]
  );

  // Clearer
  const clearer = useClearer();

  // Link to Option Agreements
  // TODO: Ensure RQD Link is accurate once it is available
  const link = 'https://legacy.tradingblock.com/Docs/Agreements//Option_Agreement_v5.5_20180821.pdf';
  const rqdLink = 'https://legacy.tradingblock.com/Docs/Agreements//RQD_Options_Agreement_v30_220421.pdf';

  const formatBooleanToggleType = (toggleType: string) => {
    if (toggleType === 'yes') {
      return true;
    } else {
      return false;
    }
  };

  // Check if either account holder has foreign citizenship
  const checkForForeign = () => {
    const holderCitizenship = accountHolders.filter(
      holder => holder.citizenshipCountry !== Country.UnitedStatesOfAmerica
    );
    if (holderCitizenship.length > 0) {
      return true;
    }
    return false;
  };
  const isForeign = checkForForeign();

  /**
   *
   * @property {TradeGoalType | undefined} tradeGoalType User response to their desired trade level for account update
   * @property {BooleanToggleType | null} option If applicable, user acceptance or rejection of Option Agreement
   */

  const initialTradeGoalFlexUpdate: TradeGoalUpdateValueProps = {
    tradeGoalType: undefined,
    agreements: {
      option: null,
    },
  };

  // Form validation prior to submission, ensures all disclosures are answered and necessary docs are gathered
  const onValidate = (values: TradeGoalUpdateValueProps) => {
    const { tradeGoalType, agreements } = values;
    const { option } = agreements;

    if (!tradeGoalType) {
      return {
        [`tradeGoalType`]: getRequiredError(tradeGoalType),
      };
    }
    if (tradeGoalType !== TradeGoalType.StocksBonds && !option) {
      return {
        [`agreements.option`]: getRequiredError(option),
      };
    }

    if (tradeGoalType !== TradeGoalType.StocksBonds && option && option !== BooleanToggleType.Yes) {
      return {
        [`agreements.option`]: getInvalidError(false),
      };
    }

    if (isForeign && accountManagementDetails.type !== 'Joint' && numberOfUploads < 2) {
      return {
        [`uploadDocuments`]: getUploadError(false),
      };
    }

    if (isForeign && accountManagementDetails.type === 'Joint' && numberOfUploads < 4) {
      return {
        [`uploadedDocuments`]: getUploadError(false),
      };
    }
  };

  // On form submission set updateValues and dispatch update request
  const onSubmit = useCallback(
    (values, { resetForm }) => {
      const { tradeGoalType } = values;
      let updateValues;
      updateValues = {
        item: 'TradeGoal',
        tradeGoalType,
        agreements: {
          option: formatBooleanToggleType(values.agreements.option),
        },
      };

      if (isForeign) {
        updateValues = {
          item: 'TradeGoal',
          tradeGoalType,
          agreements: {
            option: formatBooleanToggleType(values.agreements.option),
          },
          uploadedDocuments: documentIds.map(doc => {
            return {
              accountHolder: doc.accountHolderIndex,
              documentId: doc.documentId,
              documentName: doc.documentType,
            };
          }),
        };
      }

      if (tradeGoalType === accountManagementDetails.tradeGoalType) {
        resetForm();
        toggleModal();
        return;
      }

      dispatch(AccountManagementDataActions.requestMakeAccountUpdate({ accountId: accountId, request: updateValues }));
      resetForm();
      toggleModal();
    },
    [toggleModal]
  );

  return (
    <Formik initialValues={initialTradeGoalFlexUpdate} onSubmit={onSubmit} validate={onValidate}>
      {({ handleSubmit, values, errors, submitCount, resetForm }) => (
        <Modal setShow={() => toggleModal()} show={show}>
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Financial Information Update Request</BootstrapModal.Title>
          </BootstrapModal.Header>

          <BootstrapModal.Body>
            <form id="tradeGoalFlexUpdate" onSubmit={handleSubmit} className="tradeGoalUpdate">
              <h6>Trade Goal Update</h6>
              <FormGroup>
                <Field
                  component={CheckListField}
                  id="tradeGoalType"
                  options={_.map(AllTradeGoalTypes, t => ({
                    value: t,
                    icon: <RiskLevelIcon level={getRiskLevelForTradeGoalType(t)} />,
                  }))}
                  type="radio"
                  className="checklist-descriptions"
                  renderOption={(opt: { value: TradeGoalType }) => (
                    <>
                      <span>{opt.value ? formatTradeGoalType(opt.value) : 'Not Found'}</span>
                    </>
                  )}
                />
                <p className="txt-sm mute">
                  While every account is approved to trade stocks, ETFs, mutual funds, and fixed income, we are required
                  to review your investment objectives, experience, income, and net worth if you wish to trade options.
                </p>
                <p className="txt-sm mute">
                  Trade level type updates are pending Admin review and acceptance of the options agreement.
                </p>
              </FormGroup>
              {values.tradeGoalType && values.tradeGoalType !== TradeGoalType.StocksBonds && (
                <>
                  <h4>
                    <ExternalLink
                      className="mr-3"
                      href={clearer === ClearerType.Apex ? link : rqdLink}
                      onClick={() => onClickedAgreementLink('option')}
                    >
                      <i className="far fa-download" /> Option Agreement
                    </ExternalLink>{' '}
                  </h4>
                  <FormGroup>
                    <Field
                      component={StepperField}
                      id={`agreements.option`}
                      options={AllBooleanToggleTypes}
                      disabledOptions={getAgreementDisabledOptions('option')}
                      defaultLabelId="agreements"
                    />
                  </FormGroup>
                </>
              )}
              {values.tradeGoalType && isForeign && (
                <>
                  <p className="txt-sm mute">
                    Foreign account trade goal updates require signature and government ID images to be uploaded for
                    admin review. Please attach documents accordingly below prior to submission.
                  </p>
                  {errors.uploadedDocuments && !!submitCount && (
                    <>
                      <span className="error">
                        <Text id={'uploadedDocuments'} textKey={`govAndSig`} type={'error'} />
                      </span>
                      <br />
                      <br />
                    </>
                  )}
                  {accountHolders.map((holder, i) => (
                    <div key={i}>
                      <h6>
                        {holder.firstName} {holder.middleInitial || ''} {holder.lastName} {holder.suffix || ''}
                      </h6>
                      <FormGroup>
                        <label className="txt-sm mute">Signature Image</label>
                        <Field
                          component={MiscUploadFileField}
                          id={`uploadDocuments-${i}`}
                          accountId={accountId}
                          accountHolderIndex={i}
                          description={'SignatureImage'}
                          documentType={AccountManagementDocuments.SignatureImage}
                          handleDocumentUpload={handleDocumentUpload}
                          clearer={clearer}
                        />
                        <label className="txt-sm mute">Government ID</label>
                        <Field
                          component={MiscUploadFileField}
                          id={`uploadDocuments-${i}`}
                          accountId={accountId}
                          accountHolderIndex={i}
                          description={'GovernmentId'}
                          documentType={AccountManagementDocuments.GovernmentId}
                          handleDocumentUpload={handleDocumentUpload}
                          clearer={clearer}
                        />
                      </FormGroup>
                    </div>
                  ))}
                  <br />
                </>
              )}
            </form>
          </BootstrapModal.Body>
          <BootstrapModal.Footer className="modal-footer-justified">
            <Button
              variant="secondary"
              onClick={() => {
                resetForm();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={(values: any) => handleSubmit(values)}>
              Submit
            </Button>
          </BootstrapModal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
