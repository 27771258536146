import { AggregatedPositionDTO, calculateOrderPrice, parseOccSymbol, Strategies } from '@tradingblock/api';
import {
  AssetType,
  ExpirationType,
  Legs,
  OptionType,
  OrderAction,
  OrderActionDirection,
  OrderType,
  PositionEffect,
  TradingStrategyNameOrCustom,
} from '@tradingblock/types';
import _ from 'lodash';
import { QuoteSymbolQuote } from '../../../data/global/state';
import { useQuoteMetadata } from '../../../hooks/useFeedQuote';
import { GCD } from '../../../utilities/data';
import { changeCalculation } from '../../../utilities/position';
import { StrategyName, validatePositionLegs } from '../PositionLeg';

const RatioForPrice = {
  [AssetType.Equity.toFixed()]: 0.01,
  [AssetType.Option.toFixed()]: 1,
};
const getLegMarketValueTotalForPrice = (leg: AggregatedPositionDTO, price: number) => {
  const at = leg.AssetType;
  const priceRatio = RatioForPrice[at.toFixed()];
  const quantity = leg.OptionMultiplier === 100 ? 1 : 100 || 1;
  return priceRatio * price * quantity;
};
const getAggregatedQuotes = (legs: { ask: number; bid: number; last: number; close: number; prevClose: number }[]) => {
  return _.reduce(
    legs,
    (acc, l) => {
      return {
        ...acc,
        bid: acc.bid + l.bid,
        ask: acc.ask + l.ask,
        last: acc.last + l.last,
        close: acc.close + l.close,
        prevClose: acc.prevClose + l.prevClose,
      };
    },
    { bid: 0, ask: 0, last: 0, close: 0, prevClose: 0 }
  );
};
const minOrZero = (vals: number[]) => _.min(vals) || 0;
const maxOrZero = (vals: number[]) => _.max(vals) || 0;
const getPositionLeg = (positions: AggregatedPositionDTO[], quotes: QuoteSymbolQuote[]) => {
  return positions.reduce(
    (acc, position) => {
      const legQuote = _.find(quotes, q => q.Symbol === position.Symbol);
      if (legQuote && position && position.Symbol) {
        // const isBuy = leg.Action === OrderAction.Buy;
        // const marketBidPrice = isBuy ? legQuote.BidPrice : legQuote.AskPrice;
        // const marketAskPrice = isBuy ? legQuote.AskPrice : legQuote.BidPrice;
        const legbid = getLegMarketValueTotalForPrice(position, legQuote.BidPrice);
        const legask = getLegMarketValueTotalForPrice(position, legQuote.AskPrice);
        const legLast = getLegMarketValueTotalForPrice(position, legQuote.LastTradePrice);
        const legClose = getLegMarketValueTotalForPrice(position, legQuote.ClosePrice);
        const prevClose = getLegMarketValueTotalForPrice(position, legQuote.ClosePriceTMinus2);
        return [
          ...acc,
          {
            position,
            bid: legbid,
            ask: legask,
            last: legLast,
            close: legClose,
            prevClose: prevClose,
            action: position.OpenQuantity < 0 ? OrderAction.Buy : OrderAction.Sell,
          },
        ];
      } else {
        return acc;
      }
    },
    [] as any
  );
};

function determineLongOrShort(positions: AggregatedPositionDTO[]) {
  if (positions.length === 1) {
    return positions[0].OpenQuantity > 0 ? OrderAction.Buy : OrderAction.Sell;
  }

  const stratName = StrategyName(positions);
  if (stratName === 'Custom') {
    return OrderAction.Buy;
  }
  const strategy = Strategies.find(s => s.info.Name === stratName);

  if (strategy === undefined || positions.length !== strategy.profile.Legs.length) {
    return OrderAction.Buy;
  }

  //Sort order, Equities, CALLS, PUTS on strategy legs and position legs
  let sortedStrategyLegs = strategy.profile.Legs.sort((a, b) => {
    let aWeight =
      a.AssetType === AssetType.Equity
        ? 0
        : a.OptionType === OptionType.Call && a.Direction === OrderActionDirection.Long
        ? 1
        : a.OptionType === OptionType.Call && a.Direction === OrderActionDirection.Short
        ? 2
        : a.OptionType === OptionType.Put && a.Direction === OrderActionDirection.Long
        ? 3
        : a.OptionType === OptionType.Put && a.Direction === OrderActionDirection.Short
        ? 4
        : 5;
    let bWeight =
      b.AssetType === AssetType.Equity
        ? 0
        : b.OptionType === OptionType.Call && a.Direction === OrderActionDirection.Long
        ? 1
        : b.OptionType === OptionType.Call && a.Direction === OrderActionDirection.Short
        ? 2
        : b.OptionType === OptionType.Put && a.Direction === OrderActionDirection.Long
        ? 3
        : b.OptionType === OptionType.Put && a.Direction === OrderActionDirection.Short
        ? 4
        : 5;
    return aWeight - bWeight;
  });
  const sortedPositionsLegs = positions.sort((a, b) => {
    let aWeight =
      a.AssetType === AssetType.Equity
        ? 0
        : a.Description.includes('CALL') && a.OpenQuantity > 0
        ? 1
        : a.Description.includes('CALL') && a.OpenQuantity < 0
        ? 2
        : a.Description.includes('PUT') && a.OpenQuantity > 0
        ? 3
        : a.Description.includes('PUT') && a.OpenQuantity < 0
        ? 4
        : 5;
    let bWeight =
      b.AssetType === AssetType.Equity
        ? 0
        : b.Description.includes('CALL') && a.OpenQuantity > 0
        ? 1
        : b.Description.includes('CALL') && a.OpenQuantity < 0
        ? 2
        : b.Description.includes('PUT') && a.OpenQuantity > 0
        ? 3
        : b.Description.includes('PUT') && a.OpenQuantity < 0
        ? 4
        : 5;
    return aWeight - bWeight;
  });

  //Lowest common denominator of position quantities
  // const lcd = 1;

  const longStrategyValid = sortedStrategyLegs.every((strategyLeg, legIndex) => {
    //If any of our legs are not within the spread radio, then the user broke away from the strategy
    return validatePositionLegs(strategyLeg, sortedPositionsLegs[legIndex]);
  });

  //By default if not bidirectional strategy is not selected, the short side is true;
  let shortStrategyValid = true;
  if (strategy.profile.Bidirectional) {
    //Invert Strategy Legs to see if we are short for a bidirectional type of strategy
    sortedStrategyLegs = sortedStrategyLegs.map(leg => ({
      ...leg,
      Direction: leg.Direction === OrderActionDirection.Long ? OrderActionDirection.Short : OrderActionDirection.Long,
    }));
    shortStrategyValid = sortedStrategyLegs.every((strategyLeg, legIndex) => {
      //If any of our legs are not within the spread radio, then the user broke away from the strategy
      return validatePositionLegs(strategyLeg, sortedPositionsLegs[legIndex]);
    });
    if (shortStrategyValid) {
      return OrderAction.Sell;
    }
  }
  if (longStrategyValid) {
    return OrderAction.Buy;
  }
  return OrderAction.Sell;
}

const useOrderPriceRawValues = (positions: AggregatedPositionDTO[], quotes: QuoteSymbolQuote[]) => {
  const onlyShares = positions.filter(position => position.AssetType === AssetType.Option).length <= 0;
  const commission = positions.reduce((acc, p) => (acc += p.Commission), 0);
  if (onlyShares && positions.length > 0 && quotes.length > 0) {
    // Since we are only working with shares, then we do not need to aggregate the positions quote, and just use the first one.
    const quote = quotes[0];
    const { change, changePercent } = changeCalculation(
      quote.LastTradePrice,
      quote.ClosePrice,
      quote.ClosePriceTMinus2,
      quote.BidPrice,
      quote.AskPrice,
      quote.Symbol,
      quote.IsSettlementSet
    );

    return {
      ask: quote.AskPrice,
      bid: quote.BidPrice,
      last: quote.LastTradePrice,
      close: quote.ClosePrice,
      change: quote.NetChange,
      changePercent: quote.ChangePercentage,
      calculatedChange: change,
      calculatedChangePercent: changePercent * 100,
      isSettlementSet: quote.IsSettlementSet,
      prevClose: quote.ClosePriceTMinus2,
      commission: commission,
    };
  }

  const quote = quotes[0];

  const maxRatio = _.max(_.map(positions, l => RatioForPrice[l.AssetType.toFixed()])) || 1;
  const legPrices = getPositionLeg(positions, quotes);
  const shortLegs = _.filter(legPrices, l => l.action === OrderAction.Sell);
  const longLegs = _.filter(legPrices, l => l.action === OrderAction.Buy);
  const shorts = getAggregatedQuotes(shortLegs);
  const longs = getAggregatedQuotes(longLegs);
  const longOrShort = determineLongOrShort(positions);

  const gcd = GCD(_.map(positions, l => l.OptionMultiplier || 1)) || 1;

  const bidValue = longOrShort === OrderAction.Sell ? longs.bid - shorts.ask : shorts.bid - longs.ask;
  const askValue = longOrShort === OrderAction.Sell ? longs.ask - shorts.bid : shorts.ask - longs.bid;
  const lastValue =
    (longOrShort === OrderAction.Sell ? (longs.last - shorts.last) * -1 : shorts.last - longs.last) / maxRatio;
  const closeValue =
    (longOrShort === OrderAction.Sell ? (longs.close - shorts.close) * -1 : shorts.close - longs.close) / maxRatio;
  const prevClose =
    (longOrShort === OrderAction.Sell
      ? (longs.prevClose - shorts.prevClose) * -1
      : shorts.prevClose - longs.prevClose) / maxRatio;
  const bid = minOrZero([bidValue, askValue]) / gcd / maxRatio;
  const ask = maxOrZero([bidValue, askValue]) / gcd / maxRatio;
  const { change, changePercent } = changeCalculation(
    lastValue,
    closeValue,
    prevClose,
    bid,
    ask,
    positions[0].Symbol,
    quote.IsSettlementSet
  );

  return {
    ask: _.round(ask, 2),
    bid: _.round(bid, 2),
    last: lastValue,
    close: closeValue,
    change: quote.NetChange,
    changePercent: quote.ChangePercentage,
    calculatedChange: change,
    calculatedChangePercent: changePercent * 100,
    isSettlementSet: quote.IsSettlementSet,
    prevClose: quote.ClosePriceTMinus2,
    commission: commission,
  };
};

export const usePositionInfo = (positions: AggregatedPositionDTO[]) => {
  const quotes = useQuoteMetadata(positions.map(p => p.Symbol));
  const sharesCount = positions.reduce((acc, p) => (acc += p.AssetType === AssetType.Equity ? p.OpenQuantity : 0), 0);
  const usesOptions = positions.some(p => p.AssetType === AssetType.Option);

  return {
    sharesCount,
    usesOptions,
    ...useOrderPriceRawValues(positions, quotes),
  };
};
