import { useMemo } from 'react';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { useDispatcher } from '../../../data/global/hooks';
import { AdminEntitlementsSearchActions } from '../../../data/global/actions/admin/EntitlementsActions';
import {
  getEntitlementUpdateSearchEntities,
  getEntitlementsSearchFilterData,
  getEntitlementsSearchTotal,
} from '../../../data/global/selectors/admin/entitlementsSelector';

export const useEntitlementsData = () => {
  const data = useStateSelector(getEntitlementUpdateSearchEntities);
  const total = useStateSelector(getEntitlementsSearchTotal);

  const { ...filterData } = useStateSelector(getEntitlementsSearchFilterData);

  return {
    data,
    total,
    ...filterData,
  };
};
