import dayjs, { Dayjs } from 'dayjs';
import parseMs from 'parse-ms';
import { DateValueModel, Expiration, OptionExpirationType } from '@tradingblock/types';
import _ from 'lodash';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

type Holiday = {
  name: string;
  date: string;
  earlyClose: boolean;
  message: string;
};

const HOLIDAYS_DATA: Holiday[] = [
  {
    name: "New Year's Day",
    date: '2024-01-01',
    earlyClose: false,
    message: 'Market Closed Due to',
  },
  {
    name: 'Martin Luther King, Jr. Day',
    date: '2024-01-15',
    earlyClose: false,
    message: 'Market Closed Due to',
  },
  {
    name: "Washington's Birthday",
    date: '2024-02-19',
    earlyClose: false,
    message: 'Market Closed Due to',
  },
  {
    name: 'Good Friday',
    date: '2024-03-29',
    earlyClose: false,
    message: 'Market Closed Due to',
  },
  {
    name: 'Memorial Day',
    date: '2024-05-27',
    earlyClose: false,
    message: 'Market Closed Due to',
  },
  {
    name: 'Juneteenth National Independence Day',
    date: '2024-06-19',
    earlyClose: false,
    message: 'Market Closed Due to',
  },
  {
    name: 'Independence Eve',
    date: '2024-07-03',
    earlyClose: true,
    message: 'Early Market Close Due to',
  },
  {
    name: 'Independence Day',
    date: '2024-07-04',
    earlyClose: false,
    message: 'Market Closed Due to',
  },
  {
    name: 'Thanksgiving Day',
    date: '2024-11-28',
    earlyClose: false,
    message: 'Market Closed Due to',
  },
  {
    name: 'After Thanksgiving',
    date: '2024-11-29',
    earlyClose: true,
    message: 'Early Market Close Due to',
  },
  {
    name: 'Christmas Eve',
    date: '2024-12-24',
    earlyClose: true,
    message: 'Early Market Close Due to',
  },
  {
    name: 'Christmas Day',
    date: '2024-12-25',
    earlyClose: false,
    message: 'Market Closed Due to',
  },
];

export const isSameDate = (date1: Date | dayjs.Dayjs | undefined, date2: Date | dayjs.Dayjs | undefined) => {
  if (date1 && date2) {
    return dayjs(date1).isSame(date2, 'date');
  }
  return false;
};

export function isToday(date: Date | undefined): boolean {
  return date ? isSameDate(date, new Date()) : false;
}

export function returnMarketHoliday(date?: Date): Holiday | undefined {
  let now;
  if (date) now = dayjs.utc(date);
  else now = dayjs.utc().toDate();
  //Check to see if today is a holiday, if so, return Holiday object. If not, return undefined
  return HOLIDAYS_DATA.find(h => dayjs(h.date).isSame(now, 'day'));
}

export const MarketOpenTime = () => {
  const isDST = dayjs.tz(undefined, 'America/New_York').utcOffset() === -240 ? true : false;
  let hour = isDST ? 13 : 14;

  return dayjs
    .utc()
    .hour(hour)
    .minute(30)
    .second(0)
    .millisecond(0);
};

export const MarketCloseTime = () => {
  const isDST = dayjs.tz(undefined, 'America/New_York').utcOffset() === -240 ? true : false;
  const holiday = returnMarketHoliday();
  let hour = isDST ? 20 : 21;

  // if today is a holiday with early close, set hours to 1 pm EST (check for DST)
  // See isMarketOpen to see when the market is closed fully on the holiday.
  if (holiday && holiday.earlyClose) hour = isDST ? 17 : 18;

  return dayjs
    .utc()
    .set('hour', hour)
    .set('minute', 0)
    .set('second', 0);
};

export const IndexMarketCloseTime = () => {
  const isDST = dayjs.tz(undefined, 'America/New_York').utcOffset() === -240 ? true : false;
  const holiday = returnMarketHoliday();
  let hour = isDST ? 20 : 21;

  // if today is a holiday with early close, set hours to 1 pm EST (check for DST)
  // See isMarketOpen to see when the market is closed fully on the holiday.
  if (holiday && holiday.earlyClose) hour = isDST ? 17 : 18;

  return dayjs
    .utc()
    .set('hour', hour)
    .set('minute', 15)
    .set('second', 0);
};

export const isMarketOpen = () => {
  const now = dayjs.utc().toDate();
  const holiday = returnMarketHoliday();
  const marketOpen = MarketOpenTime().toDate();
  const marketClose = MarketCloseTime().toDate();

  // Check to see if today is a holiday.  If so, return false if market is fully closed.
  // See TimeTillClose for when the market is partially open
  if (holiday && !holiday.earlyClose) return false;

  return isBetween(now, marketOpen, marketClose);
};
export const isIndexMarketOpen = () => {
  const now = dayjs.utc().toDate();
  const holiday = returnMarketHoliday();
  const marketOpen = MarketOpenTime().toDate();
  const indexMarketClose = IndexMarketCloseTime().toDate();

  // Check to see if today is a holiday.  If so, return false if market is fully closed.
  // See IndexTimeTillClose for when the market is partially open
  if (holiday && !holiday.earlyClose) return false;

  return isBetween(now, marketOpen, indexMarketClose);
};

export const isBetweenMidnightInclusiveAndOpeningHoursExclusive = (): boolean => {
  if (!isMarketOpen()) {
    let now = dayjs.utc();
    let todayUtcMidnight = dayjs.utc().startOf('day');
    let marketOpenTime = MarketOpenTime();
    return (now.isAfter(todayUtcMidnight) || now.isSame(todayUtcMidnight)) && now.isBefore(marketOpenTime);
  }
  return false;
};

// Calculate the next Market Open Time
export const NextMarketOpenTime = () => {
  // If the time now is prior to open time (market is closed and the time is before opening market time), then don't add a day. Otherwise set nextOpen to the next day
  // This check is needed when the time now changes to the next UTC day:
  // i.e. - Time now UTC is Monday post-market close, this function returns Tuesday's Open hours
  //      - Time now UTC is Tuesday pre-market open, this function returns Tuesday's Open Hours
  let nextOpen = dayjs(MarketOpenTime()).add(isBetweenMidnightInclusiveAndOpeningHoursExclusive() ? 0 : 1, 'day');
  let holiday = returnMarketHoliday(nextOpen.toDate());
  // If the nextOpen is the weekend/(closed holiday), then keep adding a day until reaching an open business day
  while (isWeekend(nextOpen) || (holiday && !holiday.earlyClose)) {
    nextOpen = nextOpen.add(1, 'day');
    holiday = returnMarketHoliday(nextOpen.toDate());
  }
  return nextOpen;
};

export const isTodayOrAfter = (date: Date | dayjs.Dayjs) =>
  // assuming that if today, should be before market closes
  (isSameDate(date, dayjs()) && dayjs().isBefore(MarketCloseTime())) || dayjs().isBefore(date);

export function isExpiration<T>(value: Expiration | T): value is Expiration {
  return _.keys(value).includes('date');
}
export const expirationToDayjs = (date: Date | Expiration) => {
  if (isExpiration(date)) {
    return dayjs(date.date);
  }
  return dayjs(date);
};
export const expirationUnixTimestamp = (date: Date | Expiration | undefined) =>
  date === undefined ? -1 : expirationToDayjs(date).valueOf();
export const getSimpleDateString = (date: Date | Expiration) => {
  return expirationToDayjs(date).format('YYMMDD:HHmmss');
};
export const expirationValue = (date: Expiration) => {
  return `${getSimpleDateString(date)} ${OptionExpirationType[date.optionExpirationType]}`;
};
export const isSameExpiration = (date1: Expiration, date2: Expiration) =>
  expirationValue(date1) === expirationValue(date2);

export const DisplayTimeTilClose = (parsed: parseMs.Parsed) => {
  return `${parsed.hours} hour${parsed.hours === 1 ? '' : 's'}, ${parsed.minutes} minute${
    parsed.minutes === 1 ? '' : 's'
  }`;
};

/**
 * Checks to see whether or not the given date is between two dates
 *
 * @param date the Date to be checked
 * @param startDate start of date range
 * @param endDate end of date range
 */
export const isBetween = (date: Date | undefined, startDate: Date, endDate: Date) => {
  if (date) {
    const d = dayjs(date);
    return d.isAfter(startDate) && d.isBefore(endDate);
  }
  return false;
};

// is startingDate in year of 1970
export const isStartingDateIn1970 = (startingDate: Dayjs) => {
  return startingDate.year() === 1970;
};

export function daterangeToTimeframe(
  startingDate: dayjs.Dayjs | null | undefined,
  endingDate: dayjs.Dayjs | null | undefined
): string {
  if (!startingDate || !endingDate || isStartingDateIn1970(startingDate)) {
    return 'All';
  }
  const today = dayjs();
  if (isSameDate(startingDate, today) && isSameDate(endingDate, today)) {
    return 'Today';
  }
  if (isSameDate(startingDate, today.subtract(1, 'week')) && isSameDate(endingDate, today)) {
    return 'Week';
  }
  if (isSameDate(startingDate, today.subtract(1, 'month')) && isSameDate(endingDate, today)) {
    return 'Month';
  }
  if (isSameDate(startingDate, today.subtract(1, 'year')) && isSameDate(endingDate, today)) {
    return 'Year';
  }
  const format = 'M/D/YY';
  return `${startingDate.format(format)} - ${endingDate.format(format)}`;
}

export const formatDate = (date: dayjs.Dayjs | string) => {
  return (_.isString(date) ? dayjs(date) : date).format('MMM D, YYYY h:mm A');
};

export const formatDateWithoutTime = (date: dayjs.Dayjs | string) => {
  return (_.isString(date) ? dayjs(date) : date).format('MMM D, YYYY');
};

export const cleanDatePart = (value: number | undefined) => {
  return value && `${value < 10 ? '0' : ''}${value}`;
};

export const cleanDateModel = (value: DateValueModel | null | undefined) => {
  if (value) {
    return `${cleanDatePart(value.year)}-${cleanDatePart(value.month)}-${cleanDatePart(value.day)}`;
  }
  return null;
};

// Function to check if a date is a weekend
const isWeekend = (date: dayjs.Dayjs): boolean => {
  const day = date.day(); // Get the day of the week (0: Sunday, 1: Monday, ..., 6: Saturday)
  return day === 0 || day === 6; // Return true if it's Sunday or Saturday
};
