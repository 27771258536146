import { Dispatch, Middleware } from 'redux';
import { getType } from 'typesafe-actions';
import _ from 'lodash';
import { getIdNumber } from '@tradingblock/api';
import { DataState } from '../../../state';
import { RootAction } from '../../../actions';
import { AdminAccountAction, AdminAccountActions } from '../../../actions/admin/AdminAccountActions';
import { Dispatcher } from '../../../dispatcher';
import { ApiProvider } from '../../../../../context/Api';

const accountEntities = (state: DataState, dispatch: Dispatch<RootAction>) => {
  const api = ApiProvider(state, dispatch);

  return {
    accounts: {
      get: async (accountId: number | string) => {
        const acctId = getIdNumber(accountId);
        const getAccount = api.accounts
          .get(accountId)
          .then(resp => dispatch(AdminAccountActions.accountReceive(resp)))
          .catch(err => dispatch(AdminAccountActions.accountError(err)));

        const getAccountDetails = api.accounts
          .details(accountId)
          .then(resp =>
            dispatch(AdminAccountActions.accountDetailsReceive({ ...resp, payload: { ...resp.payload, id: acctId } }))
          )
          .catch(err => dispatch(AdminAccountActions.accountDetailsError(err)));

        await Promise.all([getAccount, getAccountDetails]);
      },
      balances: async (accountId: number | string) => {
        const acctId = getIdNumber(accountId);
        await api.balances
          .get(acctId)
          .then(resp =>
            dispatch(AdminAccountActions.accountBalancesReceive({ ...resp, payload: { ...resp.payload, id: acctId } }))
          )
          .catch(err => dispatch(AdminAccountActions.accountBalancesError(err)));
      },
      balancesForWithdrawal: async (accountId: number | string) => {
        const acctId = getIdNumber(accountId);
        await api.balances
          .getForWithdrawals(acctId)
          .then(resp => {
            dispatch(
              AdminAccountActions.accountBalancesForWithdrawalReceive({
                ...resp,
                payload: { ...resp.payload, id: acctId },
              })
            );
          })
          .catch(err => dispatch(AdminAccountActions.accountBalancesForWithdrawalError(err)));
      },
    },
  };
};

export const AdminAccountMiddleware: Middleware<Dispatch<AdminAccountAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<AdminAccountAction>) => (action: AdminAccountAction) => {
  try {
    // state BEFORE action is dispatched
    const prevState = getState();
    const result = next(action);
    const nextState = getState();

    const entityHandler = accountEntities(prevState, dispatch);
    try {
      switch (action.type) {
        case getType(AdminAccountActions.accountRequest): {
          entityHandler.accounts.get(action.payload.id);
          break;
        }
        case getType(AdminAccountActions.accountBalancesRequest): {
          entityHandler.accounts.balances(action.payload.id);
          break;
        }
        case getType(AdminAccountActions.accountBalancesForWithdrawalRequest): {
          entityHandler.accounts.balancesForWithdrawal(action.payload.id);
          break;
        }
      }
    } catch (apiError) {
      Dispatcher(dispatch).global.exception({ error: apiError, data: action });
    }

    return result;
  } catch (err) {
    console.error('adminAccountHandler :: Caught an exception for action ', action, err);
  }
};
