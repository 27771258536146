import React, { useEffect, useMemo } from 'react';
import round from 'lodash/round';
import { AssetType, DebitOrCredit, OrderAction, OrderType } from '@tradingblock/types';
import { useOrderData, OrderState } from '../state/OrderState';
import { useOrderState } from '../state/useOrderState';
import { useOrderActions } from '../state/useOrderActions';
import { useOrderStatus } from '../data/useOrderStatus';
import { useOrderPrice } from '../hooks/useOrderPrice';
import { MarkerLabel } from '../OrderContent';
import Slider from 'rc-slider';
import { useBlockActionDispatchAndGenericActions } from '../../../components/blocks/BlockState';
import { OrderActions } from '../state/OrderActions';
import _ from 'lodash';
import { orderLegsToClassifiedStrategy } from '../../../data/global/utilities/strategyClassification';
import { OrderSelectors } from '../state/OrderSelector';
import { determineIsBuy } from '../OrderUtilities';

export const PriceSlider: React.FC = () => {
  const { bid, mid, ask } = useOrderPrice();
  const { setPrice, setStopPrice, setAction } = useOrderActions();
  const orderType = useOrderState('orderType');
  const orderAction = useOrderState('action');
  const isCustomOrder = useOrderData((s: OrderState) => s.strategy === 'Custom');
  const legs = useOrderData(OrderSelectors.legs);
  const isValid = useOrderData(s => s.validation && s.validation.valid);
  const classifiedStrategy = orderLegsToClassifiedStrategy(legs);
  const isStrategyMatch = classifiedStrategy.name !== 'Custom';
  const debitCredit = useOrderData(s => s.debitCredit);
  const price = useOrderData((s: OrderState) => s.price, undefined);

  const isCustomWithoutClassifiedStrategy = isCustomOrder && !isStrategyMatch;
  const isClassifiedAsCalendar = _.includes(classifiedStrategy.name, 'Calendar');

  const isBuy = determineIsBuy(
    isCustomOrder,
    orderAction,
    isClassifiedAsCalendar,
    isStrategyMatch,
    classifiedStrategy,
    isCustomWithoutClassifiedStrategy,
    debitCredit,
    legs,
    price
  );

  const { complete } = useOrderStatus();
  const isMarketOrder = orderType === OrderType.Market;
  const num_legs = legs ? Object.values(legs).length : 1;

  const formatCurrency = (num: number) => {
    let decimalPlaces = 2; // default value

    // split the number into its integer and decimal parts
    const parts = num.toString().split('.');

    // check if the number has a decimal part and if it has more than 2 decimal places
    if (parts.length > 1 && parts[1].length > 2) {
      decimalPlaces = 3;
    }

    return '$' + num.toFixed(decimalPlaces).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  };

  const setPriceFromSlider = (value: number | number[]) => {
    if (typeof value === 'number' && complete !== false && orderType !== OrderType.Market) {
      // Account for reversed slider state
      const adjustedValue = isBuy ? value : 100 - value;

      let bottom = bid > 0 ? bid - bid / 25 : bid + bid / 25;
      let top = ask > 0 ? ask + ask / 25 : ask - ask / 25;
      let updatedPrice = parseFloat(((adjustedValue / 100) * (top - bottom) + bottom).toFixed(2));

      if (adjustedValue === 25) {
        updatedPrice = bid;
      } else if (adjustedValue === 50) {
        updatedPrice = mid;
      } else if (adjustedValue === 75) {
        updatedPrice = ask;
      }

      let priceParts = updatedPrice.toString().split('.');

      if (priceParts.length > 1 && priceParts[1].length > 2) {
        updatedPrice = parseFloat(updatedPrice.toFixed(2));
      }
      if (orderType === OrderType.Stop_Market) {
        setPrice(updatedPrice, true, updatedPrice < 0);
        setStopPrice(updatedPrice);
      } else {
        setPrice(updatedPrice, true, updatedPrice < 0);
      }
    }
  };

  const priceSliderValue = useMemo(() => {
    if (price === undefined || isMarketOrder) {
      return 0;
    }

    if (price === bid) {
      return isBuy ? 25 : 75;
    } else if (price === mid) {
      return 50;
    } else if (price === ask) {
      return isBuy ? 75 : 25;
    }

    const midDiff = round((ask - bid) / 2, 2);
    const percentage = (ask + midDiff - (bid - midDiff)) / 100;
    const sizeDiff = (price - (bid - midDiff)) / percentage;
    let res = sizeDiff;
    if (price > midDiff + ask) {
      res = 100;
    } else if (price < bid - midDiff) {
      res = 0;
    } else {
      res = sizeDiff;
    }

    return isBuy ? Math.abs(res) : 100 - Math.abs(res);
  }, [bid, mid, ask, price, isBuy]);

  const [{}, dispatch] = useBlockActionDispatchAndGenericActions<OrderState, OrderActions>();

  return (
    <Slider
      step={1}
      className={isBuy ? '' : 'slider-is-reversed'}
      min={0}
      max={100}
      value={priceSliderValue}
      onChange={setPriceFromSlider}
      disabled={isMarketOrder || !isValid || !complete}
      reverse={!isBuy}
      marks={
        isBuy
          ? {
              [25]: {
                label: (
                  <MarkerLabel
                    styles={{
                      top: num_legs > 1 ? '-3.2rem' : '-2.2rem',
                    }}
                    title={(num_legs > 1 ? 'Impl. ' : '') + 'Bid'}
                    value={
                      complete === false ? '-' : bid.toLocaleString(undefined, { currency: 'USD', style: 'currency' })
                    }
                  />
                ),
                style: {},
              },
              [50]: {
                label: (
                  <MarkerLabel
                    styles={{
                      top: num_legs > 1 ? '-3.2rem' : '-2.2rem',
                    }}
                    title={(num_legs > 1 ? 'Impl. ' : '') + 'Mid'}
                    value={
                      complete === false ? '-' : mid.toLocaleString(undefined, { currency: 'USD', style: 'currency' })
                    }
                  />
                ),
                style: {},
              },
              [75]: {
                label: (
                  <MarkerLabel
                    styles={{
                      top: num_legs > 1 ? '-3.2rem' : '-2.2rem',
                    }}
                    title={(num_legs > 1 ? 'Impl. ' : '') + 'Ask'}
                    value={
                      complete === false ? '-' : ask.toLocaleString(undefined, { currency: 'USD', style: 'currency' })
                    }
                  />
                ),
                style: {},
              },
            }
          : {
              [25]: {
                label: (
                  <MarkerLabel
                    styles={{
                      top: num_legs > 1 ? '-3.2rem' : '-2.2rem',
                    }}
                    title={(num_legs > 1 ? 'Impl. ' : '') + 'Ask'}
                    value={
                      complete === false ? '-' : ask.toLocaleString(undefined, { currency: 'USD', style: 'currency' })
                    }
                    isReversed={true}
                  />
                ),
                style: {},
              },
              [50]: {
                label: (
                  <MarkerLabel
                    styles={{
                      top: num_legs > 1 ? '-3.2rem' : '-2.2rem',
                    }}
                    title={(num_legs > 1 ? 'Impl. ' : '') + 'Mid'}
                    value={
                      complete === false ? '-' : mid.toLocaleString(undefined, { currency: 'USD', style: 'currency' })
                    }
                    isReversed={true}
                  />
                ),
                style: {},
              },
              [75]: {
                label: (
                  <MarkerLabel
                    styles={{
                      top: num_legs > 1 ? '-3.2rem' : '-2.2rem',
                    }}
                    title={(num_legs > 1 ? 'Impl. ' : '') + 'Bid'}
                    value={
                      complete === false ? '-' : bid.toLocaleString(undefined, { currency: 'USD', style: 'currency' })
                    }
                    isReversed={true}
                  />
                ),
                style: {},
              },
            }
      }
    />
  );
};
