import { useBlockId } from '../../../components/blocks/BlockState';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { blockSettings } from '../../../data/global/selectors/settingSelectors';
import { DefaultOptionChainBlockSettings } from '../state/OptionChainSettings';

export const useOptionChainSettings = () => {
  const blockId = useBlockId();
  //@ts-ignore
  const current = useStateSelector(s => blockSettings.optionChain(s, blockId));
  return {
    current: current || {},
    definitions: DefaultOptionChainBlockSettings,
  };
};
