import { ActionType, createStandardAction } from 'typesafe-actions';
import {
  GenericError,
  AlertMessage,
  AlertMessageUpdateRequest,
  AlertTemplatesRequest,
  AlertToggleRequest,
} from '@tradingblock/types';
import { buildGenericRequestReceiveActions } from '../../actionUtilities';

const REQUEST_CREATE_ALERT = '@admin/data/REQUEST/CREATE_ALERT';
const RECEIVE_CREATE_ALERT = '@admin/data/RECEIVE/CREATE_ALERT';
const ERROR_CREATE_ALERT = '@admin/data/ERROR/CREATE_ALERT';

const REQUEST_UPDATE_ALERT = '@admin/data/REQUEST/UPDATE_ALERT';
const RECEIVE_UPDATE_ALERT = '@admin/data/RECEIVE/UPDATE_ALERT';
const ERROR_UPDATE_ALERT = '@admin/data/ERROR/UPDATE_ALERT';

const REQUEST_ALERT_TEMPLATES = '@admin/data/REQUEST/ALERT_TEMPLATES';
const RECEIVE_ALERT_TEMPLATES = '@admin/data/RECEIVE/ALERT_TEMPLATES';
const ERROR_ALERT_TEMPLATES = '@admin/data/ERROR/ALERT_TEMPLATES';

const ALERTS_SET_PAGE = '@admin/data/ui/SET_PAGE';
const ALERTS_SET_PAGE_SIZE = '@admin/data/ui/SET_PAGE_SIZE';

const ADMIN_REQUEST_TOGGLE_ALERT = '@admin/data/REQUEST/TOGGLE_ALERT';
const ADMIN_RECEIVE_TOGGLE_ALERT = '@admin/data/RECEIVE/TOGGLE_ALERT';
const ADMIN_ERROR_TOGGLE_ALERT = '@admin/data/ERROR/TOGGLE_ALERT';

const [requestUpdateAlert, receiveUpdateAlert, errorUpdateAlert] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_UPDATE_ALERT,
  RECEIVE: RECEIVE_UPDATE_ALERT,
  ERROR: ERROR_UPDATE_ALERT,
})<AlertToggleRequest, { response: any }, GenericError>();

const [requestAlertTemplates, receiveAlertTemplates, errorAlertTemplates] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_ALERT_TEMPLATES,
  RECEIVE: RECEIVE_ALERT_TEMPLATES,
  ERROR: ERROR_ALERT_TEMPLATES,
})<AlertTemplatesRequest, { request: AlertTemplatesRequest; response: any }, GenericError>();

const [requestCreateAlert, receiveCreateAlert, errorCreateAlert] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_CREATE_ALERT,
  RECEIVE: RECEIVE_CREATE_ALERT,
  ERROR: ERROR_CREATE_ALERT,
})<AlertMessage, { response: any }, GenericError>();

export const AdminAlertSearchActions = {
  requestAlertTemplates,
  receiveAlertTemplates,
  errorAlertTemplates,
  requestCreateAlert,
  receiveCreateAlert,
  errorCreateAlert,
  requestUpdateAlert,
  receiveUpdateAlert,
  errorUpdateAlert,
  setAlertsPage: createStandardAction(ALERTS_SET_PAGE)<Pick<AlertTemplatesRequest, 'page'>>(),
  setAlertsPageSize: createStandardAction(ALERTS_SET_PAGE_SIZE)<Pick<AlertTemplatesRequest, 'pageSize'>>(),
};

export const AdminAlertActions = {
  ...AdminAlertSearchActions,
};

export type AdminAlertAction = ActionType<typeof AdminAlertActions>;
