import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SubAccount } from '@tradingblock/types';
import { useDispatcher } from '../../data/global/hooks';
import { AccountActions } from '../../data/global/actions/AccountActions';
import { Actions, DataActions } from '../../data/global/actions';
import { account } from '../../data/global/selectors/accountSelectors';
import { formatAccountNumber, isSelectedSubAccount } from '../../utilities/account';

export const SelectSubAccountButton: React.FC<{ subAccount: SubAccount; type: 'link' | 'btn'; disabled?: boolean }> = ({
  subAccount,
  type,
  disabled,
}) => {
  const { dispatch } = useDispatcher();

  const accountId = useSelector(account.accountId);
  const accountNumber = useSelector(account.accountNumber);
  const selectedSubAccountId = useSelector(account.subAccountId);

  const selected = useMemo(() => isSelectedSubAccount(selectedSubAccountId, subAccount), [selectedSubAccountId]);

  const onSelectSubAccount = useCallback(() => {
    const subaccountId = subAccount.Id === -1 ? undefined : subAccount.Id;
    dispatch(AccountActions.setSubAccount({ subaccountId }));
    dispatch(AccountActions.toggleAccountSwitcher({ show: false }));
    dispatch(Actions.requestAccountBalances({ throttle: false }));
    dispatch(DataActions.requestPositions({ accountId, subaccountId, more: true }, { debounce: 1000 }));

    if (accountId) {
      dispatch(Actions.requestAccountOrders({ accountId, subaccountId }, { debounce: 2000 }));
    }
  }, [selectedSubAccountId, dispatch, accountId, subAccount.Id]);

  if (type === 'link') {
    return (
      <button type="button" className="btn-link" onClick={onSelectSubAccount} disabled={disabled}>
        {formatAccountNumber(accountNumber, subAccount.AcctSuffix)}
      </button>
    );
  }

  if (selected) {
    return (
      <strong className="selected caps">
        &nbsp;&nbsp;&nbsp;
        <i className="fa fa-check" /> Selected
      </strong>
    );
  }

  return (
    <button type="button" className="btn btn-outline-light" onClick={onSelectSubAccount} disabled={disabled}>
      Select
    </button>
  );
};
