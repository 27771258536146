export var getDocumentOptions = function getDocumentOptions() {
  var hidden, visibilityChangeEvent;

  if (typeof document.hidden !== 'undefined') {
    hidden = 'hidden';
    visibilityChangeEvent = 'visibilitychange';
  } else {
    var prefixes = ['webkit', 'moz', 'ms'];

    for (var i = 0; i < prefixes.length; i++) {
      var prefix = prefixes[i];

      if (typeof document["".concat(prefix, "Hidden")] !== 'undefined') {
        hidden = "".concat(prefix, "Hidden");
        visibilityChangeEvent = "".concat(prefix, "visibilitychange");
        break;
      }
    }
  }

  return {
    hidden: hidden,
    visibilityChangeEvent: visibilityChangeEvent
  };
};