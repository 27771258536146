import React, { useCallback } from 'react';
import { OptionType } from '@tradingblock/components';
import { AssetSymbol, BlockType } from '@tradingblock/types';
import { useOptionChainActions } from './state/useOptionChainActions';
import { useBlockData, useBlockMetadata, useBlockSymbol } from '../../components/blocks/BlockState';
import { OptionChainState } from './state/OptionChainState';
import { useStateSelector, blockGroupId } from '../../data/global/dataSelectors';
import { SymbolPickerHeader } from '../shared/SymbolPickerHeader';
import { useDispatcher } from '../../data/global/hooks';

export const OptionChainHeader: React.FunctionComponent<{}> = () => {
  const { blockId } = useBlockMetadata();
  const { isLoaded } = useBlockData<OptionChainState>();
  const symbol = useBlockSymbol();
  const { setExpandedExpirations } = useOptionChainActions();
  const { dispatcher } = useDispatcher();
  const setGroupSymbol = dispatcher.blockGroup.setSymbol;
  const groupId = useStateSelector(s => blockGroupId(s, blockId));
  const updateSymbol = useCallback(
    (symbolName: string, symbolValue: AssetSymbol | undefined) => {
      setExpandedExpirations(null);
      if (groupId) {
        setGroupSymbol(groupId, symbolValue);
      }
    },
    [setGroupSymbol, groupId, setExpandedExpirations]
  );
  const onSelect = useCallback(
    (value: OptionType<AssetSymbol>) => {
      const symbolValue = value ? value.data : undefined;
      const symbolName = symbolValue && symbolValue.symbol;
      if (symbolName && symbolValue) {
        updateSymbol(symbolName, symbolValue);
      }
    },
    [updateSymbol]
  );
  const onClear = useCallback(() => {
    updateSymbol('', undefined);
  }, [updateSymbol]);
  return (
    <SymbolPickerHeader
      blockType={BlockType.OptionChain}
      symbol={symbol}
      isLoaded={isLoaded}
      onSelect={onSelect}
      onClear={onClear}
    />
  );
};
