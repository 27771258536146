import createCachedSelector from 're-reselect';
import _ from 'lodash';
import { AccountInfo, Balances, AccountDetails, AccountEntityTypes, BalancesForWithdrawal } from '@tradingblock/types';
import { getIdNumber } from '@tradingblock/api';
import { DataState } from '../../state';

interface PrivateAccountRequest {
  id: number | string;
  type: AccountEntityTypes;
}

const baseEntitySelector = (state: DataState, { id, type }: PrivateAccountRequest) => {
  const idNum = getIdNumber(id);
  return state.private.account[type][idNum] || undefined;
};

const accountById = (state: DataState, id: number | string) => baseEntitySelector(state, { id, type: 'accounts' });
const balanceById = (state: DataState, id: number | string) => baseEntitySelector(state, { id, type: 'balances' });
const accountDetailsById = (state: DataState, id: number | string) =>
  baseEntitySelector(state, { id, type: 'accountDetails' });
const balancesForWithdrawalById = (state: DataState, id: number | string) =>
  baseEntitySelector(state, { id, type: 'balancesForWithdrawal' });

export const accountSelectors = {
  isFetching: createCachedSelector(accountById, item => (item ? item.isFetching : false))(
    (_, id) => `account-fetching-${id}`
  ),
  data: createCachedSelector(accountById, item => (item ? (item.data as AccountInfo) : undefined))(
    (_, id) => `account-${id}`
  ),
};

export const balanceSelectors = {
  isFetching: createCachedSelector(balanceById, item => (item ? item.isFetching : false))(
    (_, id) => `balances-fetching-${id}`
  ),
  data: createCachedSelector(balanceById, item => (item ? (item.data as Balances) : undefined))(
    (_, id) => `balances-${id}`
  ),
  balancesForWithdrawal: createCachedSelector(balancesForWithdrawalById, item =>
    item ? (item.data as BalancesForWithdrawal) : undefined
  )((_, id) => `balancesForWithdrawal-${id}`),
};

export const accountDetailsSelectors = {
  isFetching: createCachedSelector(accountDetailsById, item => (item ? item.isFetching : false))(
    (_, id) => `details-fetching-${id}`
  ),
  data: createCachedSelector(accountDetailsById, item => (item ? (item.data as AccountDetails) : undefined))(
    (_, id) => `details-${id}`
  ),
};
