import React, { useMemo } from 'react';
import _ from 'lodash';
import { useStateSelector } from '../../../../../data/global/dataSelectors';
import { DetailField, DetailFields } from '../../../../../components/basic/DetailFields';
import { getAllAgreementKeysForApplication, getMarketDataResponses } from '../../services/getAllAgreementKeys';
import { formatAgreementKey, formatMarketDataKey } from '../../services/formatters';
import { useApi } from '../../../../../context/Api';

export const ApplicationAgreements: React.FunctionComponent<{}> = () => {
  const application = useStateSelector(s => s.private.applications.currentItem.application);
  const { agreements, marketData, marketDataType } = application;
  const agreementKeys = getAllAgreementKeysForApplication(application);
  const marketDataKeys = _.keys(marketData);
  const answeredMarketDataKeys = getMarketDataResponses(marketDataKeys, marketData);

  const getAgreementResponse = (agreementKey: string, agreements: any) => {
    const agreementResponse = agreements[agreementKey];
    return agreementResponse;
  };

  const getMarketDataResponse = (marketDataKey: string, marketData: any) => {
    const marketDataResponse = marketData[marketDataKey];
    return marketDataResponse;
  };

  const fields = useMemo(() => {
    let fieldList: DetailField[] = [];
    if (application && agreements) {
      fieldList = [
        ...fieldList,

        {
          Value: () => <div className="fields-title">Agreements</div>,
        },
        {
          Value: () => null,
        },
      ];
      agreementKeys.map((agreement, index) => {
        const response = getAgreementResponse(agreement, agreements);
        fieldList = [
          ...fieldList,
          {
            Value: () => (
              <div className="mb-3">
                <div className="props-title">
                  {formatAgreementKey(agreement)}{' '}
                  <div className="mute">{response ? _.capitalize(response) : 'Response Unavailable'}</div>
                </div>
              </div>
            ),
          },
        ];
      });
    }

    return fieldList;
  }, [application, agreements]);

  const marketDataFields = useMemo(() => {
    let fieldList: DetailField[] = [];
    if (application && marketData) {
      fieldList = [
        ...fieldList,

        {
          Value: () => (
            <>
              <div className="fields-title mt-3">Market Data Questionnaire</div>
            </>
          ),
        },
        {
          Value: () => null,
        },
        {
          Value: () => (
            <div className="props-title mb-3">
              Market Data Entitlement <div className="mute">{marketDataType ? marketDataType : 'Not Available'}</div>
            </div>
          ),
        },
        {
          Value: () => null,
        },
      ];
      answeredMarketDataKeys.map((marketDataKey, index) => {
        const response = getMarketDataResponse(marketDataKey, marketData);
        fieldList = [
          ...fieldList,
          {
            Value: () => (
              <div className="mb-3">
                <div className="props-title">
                  {formatMarketDataKey(marketDataKey)}{' '}
                  <div className="mute">{response ? _.capitalize(response) : 'Not accepted'}</div>
                </div>
              </div>
            ),
          },
        ];
      });
    }
    return fieldList;
  }, [application, marketData]);

  return (
    <div className="screen-body-section">
      <DetailFields fieldList={fields} valueAs="div" />
      <DetailFields fieldList={marketDataFields} valueAs="div" />
    </div>
  );
};
