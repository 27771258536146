import React, { useMemo } from 'react';
import _ from 'lodash';
import {
  OrderAction,
  StrategyType,
  OrderActionDirection,
  isCustomStrategy,
  TradingStrategy,
  CustomTradingStrategy,
  OrderLegDTO,
  AssetType,
  OrderClass,
} from '@tradingblock/types';
import { useOrderData, OrderStrength } from '../state/OrderState';
import { OrderSelectors } from '../state/OrderSelector';
import { DtoToOrderleg, isCalendarStrategy } from '../OrderUtilities';
import { useBlockGroupInfo } from '../../useGroupState';
import { useOrderAssetSymbolOrUndefined } from '../state/useOrderState';
import { useOrderCopyAction } from '../../../components/blocks/hooks/useOrderBlock';
import { AssetTypeToSymbolType, toAssetSymbol } from '@tradingblock/api';
import { useOrderSettings } from '../hooks/useOrderSettings';
import { Svg } from '@tradingblock/components';

interface StrengthIcon {
  icon: string;
  css: string;
}
interface StrengthDef {
  icons: StrengthIcon | StrengthIcon[];
  text: string;
  css?: 'pos' | 'neg';
}
type StrengthMap = { [key in OrderStrength]: StrengthDef };
type SentimentMap<T> = {
  [key in StrategyType]: T;
};

const SentimentIcons: SentimentMap<StrengthMap> = {
  [StrategyType.Bull]: {
    [OrderStrength.Low]: { icons: { icon: `far fa-triangle`, css: 'pos' }, text: 'Somewhat Bullish', css: 'pos' },
    [OrderStrength.Medium]: {
      icons: { icon: `fa fa-triangle`, css: 'pos' },
      text: 'Bullish',
      css: 'pos',
    },
    [OrderStrength.High]: {
      icons: [{ icon: `far fa-triangle`, css: 'pos' }, { icon: `fa fa-triangle`, css: 'pos' }],
      text: 'Very Bullish',
      css: 'pos',
    },
    [OrderStrength.VeryHigh]: {
      icons: [{ icon: `fa fa-triangle`, css: 'pos' }, { icon: `fa fa-triangle`, css: 'pos' }],
      text: 'Most Bullish',
      css: 'pos',
    },
  },
  [StrategyType.Bear]: {
    [OrderStrength.Low]: {
      icons: { icon: `far fa-triangle fa-rotate-180`, css: 'neg' },
      text: 'Somewhat Bearish',
      css: 'neg',
    },
    [OrderStrength.Medium]: {
      icons: { icon: `fa fa-triangle fa-rotate-180`, css: 'neg' },
      text: 'Bearish',
      css: 'neg',
    },
    [OrderStrength.High]: {
      icons: [
        { icon: `far fa-triangle fa-rotate-180`, css: 'neg' },
        { icon: `fa fa-triangle fa-rotate-180`, css: 'neg' },
      ],
      text: 'Very Bearish',
      css: 'neg',
    },
    [OrderStrength.VeryHigh]: {
      icons: [
        { icon: `fa fa-triangle fa-rotate-180`, css: 'neg' },
        { icon: `fa fa-triangle fa-rotate-180`, css: 'neg' },
      ],
      text: 'Most Bearish',
      css: 'neg',
    },
  },
  [StrategyType.Volatility]: {
    [OrderStrength.Low]: {
      icons: [{ icon: `far fa-triangle`, css: 'pos' }, { icon: `far fa-triangle fa-rotate-180`, css: 'neg' }],
      text: 'Low Volatility',
    },
    [OrderStrength.Medium]: {
      icons: [{ icon: `far fa-triangle`, css: 'pos' }, { icon: `far fa-triangle fa-rotate-180`, css: 'neg' }],
      text: 'Med Volatility',
    },
    [OrderStrength.High]: {
      icons: [{ icon: `fa fa-triangle`, css: 'pos' }, { icon: `fa fa-triangle fa-rotate-180`, css: 'neg' }],
      text: 'High Volatility',
    },
    [OrderStrength.VeryHigh]: {
      icons: [{ icon: `fa fa-triangle`, css: 'pos' }, { icon: `fa fa-triangle fa-rotate-180`, css: 'neg' }],
      text: 'High Volatility',
    },
  },
};

export const useStrategySentiment = (strategy: TradingStrategy | CustomTradingStrategy) => {
  const action = useOrderData(OrderSelectors.action);
  const orderSentiment = useMemo(() => {
    if (!strategy || !action) {
      return undefined;
    }
    if (isCustomStrategy(strategy) || isCalendarStrategy(strategy)) {
      return undefined;
    }
    const dir = action === OrderAction.Buy ? OrderActionDirection.Long : OrderActionDirection.Short;
    const directionInfo = strategy.profile[dir];
    const stratType = directionInfo ? directionInfo.StrategyType : undefined;
    const strengthVal = directionInfo ? directionInfo.Strength : undefined;
    if (!stratType || strengthVal === undefined) {
      return undefined;
    }
    let strength = OrderStrength.Low;
    if (strengthVal <= 3) {
      strength = OrderStrength.Low;
    } else if (strengthVal <= 6) {
      strength = OrderStrength.Medium;
    } else if (strengthVal <= 8) {
      strength = OrderStrength.High;
    } else {
      strength = OrderStrength.VeryHigh;
    }
    let overallSentimentClass = stratType === StrategyType.Bull ? 'pos' : stratType === StrategyType.Bear ? 'neg' : '';
    return {
      strength,
      type: stratType,
      overallSentimentClass,
    };
  }, [action, strategy]);

  const sentimentDef = useMemo(
    () => (orderSentiment ? SentimentIcons[orderSentiment.type][orderSentiment.strength] : undefined),
    [orderSentiment]
  );

  const sentimentClass = useMemo(() => (sentimentDef && sentimentDef.css ? sentimentDef.css : ''), [sentimentDef]);
  const sentimentText = useMemo(() => (sentimentDef && sentimentDef.text ? sentimentDef.text : ''), [sentimentDef]);

  const icons = useMemo(() => {
    return sentimentDef ? (_.isArray(sentimentDef.icons) ? sentimentDef.icons : [sentimentDef.icons]) : [];
  }, [sentimentDef]);

  return {
    sentiment: orderSentiment,
    icons,
    sentimentClass,
    sentimentText,
  };
};

export const OrderSentiment: React.FC<{
  strategy: any;
  legs: OrderLegDTO[];
}> = ({ strategy, legs }) => {
  const { icons, sentimentText, sentimentClass } = useStrategySentiment(strategy as TradingStrategy);
  const { groupId } = useBlockGroupInfo();

  const assSymbol = toAssetSymbol(
    legs.length > 0 ? legs[0].UnderlyingSymbol : '',
    AssetType.Equity,
    AssetTypeToSymbolType(AssetType.Equity),
    {
      name: legs.length > 0 ? legs[0].UnderlyingSymbol : undefined,
      underlyingSymbol: legs.length > 0 ? legs[0].UnderlyingSymbol : undefined,
      rootSymbol: legs.length > 0 ? legs[0].UnderlyingSymbol : undefined,
    }
  );
  const action = useOrderData(OrderSelectors.action);
  const invertSentiment = action === 1 ? { transform: 'scaleY(1)' } : { transform: 'scaleY(-1)' };
  const assetSymbol = useOrderAssetSymbolOrUndefined();
  const assetSymbolInfo = _.omit(assetSymbol, ['name', 'provider']);
  const orderData = useOrderData(s => s);
  const isCalendar = isCalendarStrategy(strategy as TradingStrategy);
  const isCoveredCallOrMarriedPut = strategy.info.Name === 'Covered Call' || strategy.info.Name === 'Married Put';
  const isButterflyCallOrPut = strategy.info.Name === 'Call Butterfly' || strategy.info.Name === 'Put Butterfly';
  const isSingleLeggedStrategy = useOrderData(OrderSelectors.isSingleLegStrategy);

  const createOrder = () => {
    let quantity = Math.max(...[orderData.quantity, ...legs.map(l => (l.SpreadRatio ? l.SpreadRatio : 0))]);

    if (isCoveredCallOrMarriedPut) {
      const shareLeg = legs.find(l => l.AssetType === AssetType.Equity);
      const shareLegRatio = shareLeg ? shareLeg.SpreadRatio : 100;
      quantity = shareLegRatio ? shareLegRatio / 100 : 1;
    }

    return {
      Legs: legs.map(leg => ({
        ...DtoToOrderleg(leg, assetSymbolInfo),
        SpreadRatio: isSingleLeggedStrategy
          ? undefined
          : isCoveredCallOrMarriedPut
          ? leg.AssetType === AssetType.Option
            ? orderData.quantity
            : 100 * orderData.quantity
          : isButterflyCallOrPut
          ? leg.SpreadRatio && leg.SpreadRatio * orderData.quantity
          : orderData.quantity,
      })),
      OrderClass: legs.length > 1 ? OrderClass.Multileg : OrderClass.Single,
      Duration: orderData.duration,
      Quantity: quantity,
      OrderType: orderData.orderType,
      AllOrNone: false,
      Description: '',
    };
  };

  const copy = useOrderCopyAction(createOrder(), assetSymbol || assSymbol, { groupId }, undefined, true);
  const copyOrder = () => copy();
  const { verboseSentiment } = useOrderSettings();
  const optionLegsHaveExpirationAndStrike = useMemo(() => {
    return (
      legs.filter(leg => leg.AssetType === AssetType.Option).length ===
      legs.filter(leg => leg.AssetType === AssetType.Option && leg.Strike && leg.Expiration).length
    );
  }, [legs, invertSentiment]);

  return (
    <>
      <button
        className="tb-icon-multiline unmatchStrategyButton"
        onClick={() => {
          window.onscroll = () => {
            window.scroll(0, 0);
          };
          copyOrder();
        }}
        disabled={!optionLegsHaveExpirationAndStrike}
        title={
          optionLegsHaveExpirationAndStrike
            ? 'Change to a custom strategy'
            : 'Please set expiration and strike for all options prior to changing to a custom strategy'
        }
        // style to denote when the button is available/disabled
        style={{
          opacity: optionLegsHaveExpirationAndStrike ? 1 : 0.5,
          cursor: optionLegsHaveExpirationAndStrike ? 'pointer' : 'not-allowed',
        }}
      >
        {verboseSentiment && (
          <div className="tb-icon-multiline-icon">
            <span className="tb-icon-column">
              {icons.map((ic, ind) => (
                <span key={ind.toString()} className={ic.css}>
                  <i className={`${ic.icon} fa-xs`}></i>
                </span>
              ))}
              {isCalendar && !verboseSentiment && (
                <span>
                  <i className="fa fa-analytics fa-xs" />
                </span>
              )}
            </span>
          </div>
        )}
        <div className="tb-icon-multiline-label">
          <div className="prop-stacked caps txt-sm">
            {verboseSentiment && (
              <>
                <div className="prop-title mute">Sentiment</div>
                <div className={`prop-value ${sentimentClass}`}>{sentimentText}</div>
              </>
            )}
            {isCalendar && verboseSentiment && <div className="prop-value">VOL PLAY</div>}
          </div>
        </div>
        <span>
          {strategy && (
            <>
              <span
                style={!strategy.info.Name.includes('Calendar') ? invertSentiment : {}}
                className="strategy-templates-icon"
              >
                {(strategy.info.IconKind as string) === 'svg' ? (
                  <span className="strategy-icon">
                    <Svg path={`strategy-${strategy.info.SvgPath as string}`} />
                  </span>
                ) : (
                  <i className={strategy.info.Icon as string} />
                )}
              </span>
              <span className="strategy-templates-title-sentiment">{''}</span>
            </>
          )}
        </span>
      </button>
    </>
  );
};
