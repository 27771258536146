import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { OrderType } from '@tradingblock/types';
import { BasicSelect, DropdownItemType, OptionType, SplitDropdownProps } from '@tradingblock/components';
import { useOrderData, OrderState } from '../state/OrderState';
import { useBlockActions } from '../../../components/blocks/BlockState';
import { useOrderActions } from '../state/useOrderActions';

const orderTypePlaceholderDisplay = {
  [OrderType.Market]: 'MKT',
  [OrderType.Limit]: 'LMT',
  [OrderType.Stop_Market]: 'STP MKT',
  [OrderType.Stop_Limit]: 'STP LMT',
  [OrderType.Market_On_Close]: 'MKT ON CLS',
};

const orderTypePickerOptions = [
  {
    label: 'Market',
    value: OrderType.Market,
    data: OrderType.Market,
  },
  {
    label: 'Limit',
    value: OrderType.Limit,
    data: OrderType.Limit,
  },
  {
    label: 'Stop Market',
    value: OrderType.Stop_Market,
    data: OrderType.Stop_Market,
  },
  {
    label: 'Stop Limit',
    value: OrderType.Stop_Limit,
    data: OrderType.Stop_Limit,
  },
  {
    label: 'Market on Close',
    value: OrderType.Market_On_Close,
    data: OrderType.Market_On_Close,
  },
];

export const OrderTypePicker: React.FC<Pick<SplitDropdownProps<DropdownItemType>, 'className'>> = ({ className }) => {
  const [allowedOrderTypePickerOptions, setAllowedOrderTypePickerOptions] = useState(orderTypePickerOptions);
  const orderType = useOrderData((s: OrderState) => s.orderType, OrderType.Limit);
  const legs = useOrderData((s: OrderState) => s.legs);
  const { setField: set } = useBlockActions<OrderState>();
  const { setStopPrice, setPrice } = useOrderActions();

  const setValue = useCallback(
    ({ data }: OptionType<OrderType>) => {
      set('orderType', data, { persist: true });
      if (data === OrderType.Stop_Limit || data === OrderType.Stop_Market) {
        setPrice(undefined, false);
        setStopPrice(undefined);
      }
    },
    [set, setPrice]
  );

  useEffect(() => {
    // If we change our strategy from a single leg one, remove unsupported order types
    if (Object.values(legs).length > 1) {
      if (
        orderType === OrderType.Stop_Limit ||
        orderType === OrderType.Stop_Market ||
        orderType === OrderType.Market_On_Close
      ) {
        set('orderType', OrderType.Limit, { persist: true });
        setStopPrice(undefined);
      }
      setAllowedOrderTypePickerOptions(
        orderTypePickerOptions.filter(
          ({ data: option }) =>
            option !== OrderType.Stop_Limit && option !== OrderType.Stop_Market && option !== OrderType.Market_On_Close
        )
      );
    } else {
      setAllowedOrderTypePickerOptions(orderTypePickerOptions);
    }
  }, [legs]);

  return (
    <BasicSelect
      className={`btn btn-dark btn-tall dropdown-toggle pr-0 ${className}`}
      value={orderType}
      placeholder={orderTypePlaceholderDisplay[orderType]}
      options={allowedOrderTypePickerOptions}
      onChange={setValue}
      isSearchable={false}
    />
  );
};
