import React, { useEffect } from 'react';
import _ from 'lodash';
import { Button, Loading } from '@tradingblock/components';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { useDispatcher } from '../../../data/global/hooks';
import { AdminEntitlementsActions } from '../../../data/global/actions/admin/EntitlementsActions';
import { AdminEntitlementsFooter } from './components/EntitlementsFooter';
import { EntitlementRequestDetails } from './components/EntitlementRequestDetails';
import { EntitlementRequestAccountDetails } from './components/EntitlementRequestAccountDetails';
import { EntitlementRequestFinancialDetails } from './components/EntitlementRequestFinancialDetails';

export const AdminEntitlementsDetail: React.FunctionComponent<{}> = () => {
  const { dispatch } = useDispatcher();
  const currentItemRequest = useStateSelector(s => s.private.entitlements.currentItem.request);
  const isAccountFetching = useStateSelector(s => s.private.entitlements.currentItem.isAccountFetching);
  const isCurrentItemFetching = useStateSelector(s => s.private.entitlements.currentItem.isFetching);
  const isFetching = useStateSelector(s => s.private.entitlements.isFetching);
  useEffect(() => {
    if (currentItemRequest) {
      const { UserId } = currentItemRequest;
      dispatch(AdminEntitlementsActions.requestEntitlementAccountDetails({ accountId: UserId }));
    }
  }, [currentItemRequest]);

  return (
    <>
      {(isAccountFetching || isFetching || isCurrentItemFetching || !currentItemRequest) && <Loading />}
      {!isFetching && !isAccountFetching && !isCurrentItemFetching && currentItemRequest && (
        <>
          <EntitlementRequestDetails />
          <EntitlementRequestAccountDetails />
          <EntitlementRequestFinancialDetails />
          <AdminEntitlementsFooter />
        </>
      )}
    </>
  );
};
