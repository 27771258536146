import React from 'react';
import _ from 'lodash';
import { OrderAction } from '@tradingblock/types';
import { PreviewActions, useOrderPreviewOrder } from './useOrderPreview';
import { DebitCreditToggleButton } from '../DebitCreditToggle';

export const PreviewDebitOrCredit: React.FC<{ action: PreviewActions }> = ({ action }) => {
  const { order, changeDebitCredit } = useOrderPreviewOrder(action);
  const debitOrCredit = order && order.DebitCredit ? order.DebitCredit : undefined;
  const enabled =
    order &&
    _.find(order.Legs, l => l.Action === OrderAction.Buy) &&
    _.find(order.Legs, l => l.Action === OrderAction.Sell)
      ? true
      : false;

  return (
    <>
      {order && (
        <DebitCreditToggleButton
          width={'6em'}
          baseClass={'btn-lighten'}
          value={debitOrCredit}
          setDebitCredit={changeDebitCredit}
          enabled={enabled}
        />
      )}
    </>
  );
};
