import { useStateSelector } from '../../../data/global/dataSelectors';
import { getAlertsSearchFilterData, getAlertsSearchTotal } from '../../../data/global/selectors/admin/alertsSelector';

export const useAlertsData = () => {
  const total = useStateSelector(getAlertsSearchTotal);
  const data = useStateSelector(s => s.private.alerts.alerts);

  const { ...filterData } = useStateSelector(getAlertsSearchFilterData);

  return {
    total,
    data,
    ...filterData,
  };
};
