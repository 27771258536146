/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useWhyDidYouUpdate } from '@tradingblock/components';
import { useOrderActions } from '../state/useOrderActions';
import { OrderActions } from '../state/OrderActions';
import { useBlockGroupField, useBlockGroupInfo } from '../../useGroupState';
import { GroupManager } from '../../../context/GroupManager';
import { useBlockActionDispatch } from '../../../components/blocks/BlockState';
import { GenericBlockActionTypes } from '../../../components/blocks/state/BlockState';

export const useOrderBlockGroupHandler = () => {
  const { blockId, groupId } = useBlockGroupInfo();
  const { setSymbol, handleOrderGroupChange } = useOrderActions();
  const dispatch = useBlockActionDispatch<GenericBlockActionTypes<OrderActions>>();
  const symbolValue = useBlockGroupField('symbol');

  useEffect(() => {
    if (groupId) {
      GroupManager.attach({ blockId, groupId, dispatch }, true);
      setSymbol(symbolValue, true, { persist: false });
    }

    return () => {
      GroupManager.detach(blockId);
    };
  }, [groupId]);
  // const onOrderChange = useCallback(
  //   (order: BlockGroupOrderInfo | undefined, groupId?: string) => {
  //     // try to add new order leg with custom strategy
  //     const symb = symbolValue;
  //     if (symb === undefined) {
  //       console.warn('ignoreing order group change because symbol is undefined');
  //       return;
  //     }
  //     if (order && orderGroupId === groupId) {
  //       //const symb = order.symbol || symbolValue;
  //       handleOrderGroupChange(symb, { ...order, strategy: order.strategy || { info: { Name: 'Custom' } } });
  //     }
  //     setOrderGroupId(groupId);
  //   },
  //   [symbolValue]
  // );
  // const onGroupIdChange = useCallback(
  //   (val?: string, groupId?: string) => {
  //     if (orderGroupId !== groupId) {
  //       GroupManager.detach(blockId);
  //     }

  //     GroupManager.attach({ blockId, groupId: val || groupId || orderGroupId, dispatch }, true);
  //     setOrderGroupId(groupId);
  //     console.log('setting symbol to', symbolValue);
  //     setSymbol(symbolValue, false);
  //   },
  //   [symbolValue]
  // );
  useWhyDidYouUpdate('useOrderBlockGroupHandler', { setSymbol, handleOrderGroupChange, symbolValue });
  //useGroupStateHandler('symbol', onSymbolChange, { fireOnGroupChange: true, fireOnUndefined: true });
  // useGroupStateHandler('order', onOrderChange, { fireOnGroupChange: false });
  //useGroupStateHandler('groupId', onGroupIdChange, { fireOnGroupChange: false });
};
