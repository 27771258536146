import React from 'react';
import _ from 'lodash';
import { GetOCCSymbol } from '@tradingblock/api';
import { Number } from '@tradingblock/components';
import { OptionType, AssetSymbol, Expiration } from '@tradingblock/types';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { getQuoteVolume } from '../../../data/global/selectors/quoteSelector';
import { useBlockSymbol } from '../../../components/blocks/BlockState';

const SymbolOptionPairVolume: React.FC<{ assetSymbol: AssetSymbol; optionType: OptionType; expiration: Expiration; strike: number }> = ({ optionType, expiration, strike, assetSymbol }) => {
  const { symbol, type } = assetSymbol;
  const occSymbol = GetOCCSymbol({ underlyingSymbol: symbol, symbolType: type, expiration: expiration, optionType, strike });
  const quoteVolume = useStateSelector(s => {
    const vol = getQuoteVolume(s, occSymbol);
    if (_.isNil(vol)) {
      return 0;
    }
    return vol.Volume;
  });

  return <>{symbol && <Number format value={quoteVolume} integer={true} />}</>;
};

const OptionPairVolume: React.FC<{ optionType: OptionType; expiration: Expiration; strike: number }> = props => {
  // const symbol = useOptionChainData(s => s.symbol);
  const symbol = useBlockSymbol();
  return <>{symbol && <SymbolOptionPairVolume {...props} assetSymbol={symbol} />}</>;
};

export const OptionPairVolumeMemo = React.memo(OptionPairVolume);
