import React, { useCallback } from 'react';
import { CashieringRoute, CashieringManageAction, CashieringView } from '@tradingblock/types';
import {
  CashieringViewFormValues,
  CashieringViewProps,
  ViewOnValidateType,
  ViewOnSubmitType,
} from './data/useCashieringView';
import { validateTransferAmount, validateTransferMechanism } from './data/cashieringValidation';
import { useDepositAmountView } from './DepositAmountView';
import { useCashieringAccountsList } from './components/AccountsList';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { account } from '../../../data/global/selectors/accountSelectors';

const DepositTypeView: React.FC<CashieringViewProps<CashieringViewFormValues>> = ({ children, ...props }) => {
  const [DepositAmountView] = useDepositAmountView();
  const [TransferAccountsList] = useCashieringAccountsList();

  return (
    <>
      <DepositAmountView {...props} />

      <div className="screen-body-section">
        <p className="fields-title">How would you like to deposit the funds?</p>
        <TransferAccountsList {...props} route={CashieringRoute.Deposit} />
      </div>

      {children}
    </>
  );
};

export const useDepositTypeView = (
  action: CashieringManageAction | undefined
): [typeof DepositTypeView, ViewOnSubmitType | undefined, ViewOnValidateType] => {
  const [, onSubmitActions, onValidateActions] = useCashieringAccountsList();

  const hasSubAccounts = useStateSelector(account.hasSubAccounts);

  const onValidate = useCallback(
    (values: CashieringViewFormValues) => {
      if (action) {
        return onValidateActions(values);
      }

      return {
        ...validateTransferAmount(values, CashieringRoute.Deposit, hasSubAccounts),
        ...validateTransferMechanism(values, CashieringRoute.Deposit),
      };
    },
    [hasSubAccounts, onValidateActions]
  );

  const onSubmit = useCallback(
    (values: CashieringViewFormValues, view: CashieringView | undefined) => {
      if (action) {
        onSubmitActions(values, view);
      }
    },
    [onSubmitActions]
  );

  return [DepositTypeView, action ? onSubmit : undefined, onValidate];
};
