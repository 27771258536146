import React, { useMemo } from 'react';
import { useOrderSymbolOrUndefined } from '../state/useOrderState';
import { useOrderData } from '../state/OrderState';
import { OrderSelectors } from '../state/OrderSelector';
import { useOrderActions } from '../state/useOrderActions';

export const ClearOrder = () => {
  const symbol = useOrderSymbolOrUndefined();
  const { clearOrder } = useOrderActions();
  const isCustomStrategyOrUndefined = useOrderData(OrderSelectors.isCustomStrategyOrUndefined);
  const showClearAll = useMemo(() => isCustomStrategyOrUndefined && symbol !== undefined, [
    isCustomStrategyOrUndefined,
    symbol,
  ]);

  return (
    <div className="order-leg-clear">
      {showClearAll && (
        <button className="btn btn-blend" style={{
          border: 'solid 1px',
          padding: '2px 8px'
        }} onClick={clearOrder} type="button">
          <i className="fal fa-times"></i> Clear all
        </button>
      )}
    </div>
  );
};
