import { InitialState } from '../../initialState';
import { RootAction, DataActions, OrderActions, FeedActions, Actions } from '../../actions';
import { getType } from 'typesafe-actions';
import {
  PositionsState,
  QuotesState,
  AccountState,
  PositionPerformanceState,
  BalanceState,
  PerformanceState,
} from '../../state';
import _ from 'lodash';
import {
  pnlByPrice,
  PositionDTO,
  TodaysFilledOrders,
  TodayDate,
  GetSymbolsFromOrders,
  isBefore,
  getBestPrice,
  isToday,
} from '@tradingblock/api';
import {
  ProfitLossInfoMap,
  SymbolProfitLossInfo,
  AggregatedProfitLossInfo,
  BasicSymbolProfitLossInfo,
} from '@tradingblock/types';
import { utilities } from './utilities';
import { SymbolPnLReducer } from './performanceBySymbolReducer';
import { aggregatedSymbolPosition, QuoteDataToPartialQuote } from '../../selectors/transforms';
import { getCashAndCashAlts } from '../../utilities/balances';

const positionPnLReducer = (quoteState: QuotesState, positions: PositionDTO[]): PositionPerformanceState => {
  const quo = utilities.getQuoteForSymbol(quoteState);

  return _.reduce(
    positions,
    (acc: PositionPerformanceState, p: PositionDTO) => {
      const PositionOnOpen = p.OpenQuantity;
      const currkey = p.PositionId;
      const sym = p.Symbol;
      const quote = quo(sym);
      const closePrice =
        quote && quote.IsSettlementSet ? quote.ClosePriceTMinus2 : quote && quote.ClosePrice ? quote.ClosePrice : quote && quote.LastOrClosePrice ? quote.LastOrClosePrice : 0;
      const existing = _.isNil(acc[currkey]) ? { position: 0, total: 0, trading: 0 } : acc[currkey];
      if (_.isNil(quote)) {
        return acc;
      }
      const commission = isBefore(p.DateOpened, TodayDate()) === false ? p.Commission : 0;
      const quoteInfo = {
        AskPrice: quote.AskPrice,
        BidPrice: quote.BidPrice,
        LastOrClosePrice: quote.LastOrClosePrice,
        Multiplier: quote.Multiplier,
      };
      const wasToday = isToday(p.DateOpened);
      const priceBaseline = wasToday ? p.OpenPrice : closePrice;
      const positionQuoteInfo = {
        ...p,
        ...quoteInfo,
        PositionOnOpen,
        isSettlementSet: quote.IsSettlementSet,
      };
      let currPnL = pnlByPrice(positionQuoteInfo, priceBaseline, commission);

      if (_.isNaN(currPnL)) {
        // console.warn(sym, 'invalid position pnl', currPnL, existing, {
        //   ...p,
        //   AskPrice: quote.AskPrice,
        //   BidPrice: quote.BidPrice,
        //   LastOrClosePrice: quote.LastOrClosePrice,
        //   PositionOnOpen,
        //   closePrice,
        // });
      }
      return {
        ...acc,
        [currkey]: { pnl: currPnL, symbol: sym, underlyingSymbol: p.UnderlyingSymbol, date: p.DateOpened },
      };
    },
    {}
  );
};

const getAggregatedTotals = (pnlMap: ProfitLossInfoMap) =>
  _.reduce(
    pnlMap,
    (acc: AggregatedProfitLossInfo, v: BasicSymbolProfitLossInfo): AggregatedProfitLossInfo => {
      const trading = acc.trading + v.trading;
      const position = acc.position + v.position;
      const total = trading + position;
      return {
        pnls: [...acc.pnls, v],
        trading,
        position,
        total,
      };
    },
    { trading: 0, position: 0, total: 0, pnls: [] }
  );

export const performanceReducer = (
  state = InitialState.performance,
  action: RootAction,
  quoteState: QuotesState,
  positionState: PositionsState,
  accountState: AccountState,
  balances: BalanceState
): PerformanceState => {
  const reducers = {
    position: positionPnLReducer,
    // order: orderPnLReducer(),
  };

  switch (action.type) {
    case getType(OrderActions.receivePlaceOrder):
    case getType(DataActions.receivePositions):
    case getType(OrderActions.receivedUpdate):
    case getType(FeedActions.updateQuotes):
    case getType(Actions.receiveAccountBalances):
    case getType(Actions.receiveAccountOrders):
    case getType(DataActions.receiveQuote): {
      const allPositions = positionState.positions;
      const todaysOrders = TodaysFilledOrders(accountState.orders || []);
      // const orders = reducers.order(quoteState, todaysOrders, allPositions);
      const tradedSymbolsToday: string[] = GetSymbolsFromOrders(todaysOrders);
      const bySymbol = SymbolPnLReducer(quoteState, todaysOrders, allPositions);
      const tradedToday = getAggregatedTotals(
        _.reduce(
          bySymbol,
          (acc, v, k) => {
            if (tradedSymbolsToday.includes(v.symbol)) {
              return {
                ...acc,
                [k]: v,
              };
            }
            return acc;
          },
          {}
        )
      );
      const all = getAggregatedTotals(bySymbol);
      const partialQuotes = QuoteDataToPartialQuote(_.map(quoteState.quoteMetadata.data, v => v));
      const positionValue = _(allPositions)
        .map(p => aggregatedSymbolPosition([p], partialQuotes, p.UnderlyingSymbol).value)
        .sum();
      const cashValue = balances.balances ? getCashAndCashAlts(balances.balances) : 0;
      return {
        ...state,
        positions: reducers.position(quoteState, positionState.positions),
        // orders,
        bySymbol,
        totals: {
          positionValue,
          accountValue: positionValue + cashValue,
          tradedToday,
          all,
        },
      };
    }
    default:
      return state;
  }
};
