import _ from 'lodash';
import { BlockGroupState, GroupLinkColors } from '@tradingblock/types';
import { SymbolQuote } from '@tradingblock/api';

export const groupHasData = (group: BlockGroupState) => !_.isNil(group.symbol) || !_.isNil(group.order);

export const getLastOrClosePrice = (
  feedQuote: Pick<SymbolQuote, 'ClosePrice' | 'LastTradePrice' | 'IsSettlementSet'>
) => {
  const { ClosePrice, LastTradePrice, IsSettlementSet } = feedQuote;
  if (IsSettlementSet) {
    return ClosePrice;
  } else {
    return LastTradePrice > 0 ? LastTradePrice : ClosePrice;
  }
};

export const LINK_COLORS_ARRAY: GroupLinkColors[] = ['GREEN', 'RED', 'WHITE', 'TEAL', 'YELLOW', 'ORANGE'];
export const getLinkColorMapping = (linkColor: GroupLinkColors | undefined): GroupLinkColors | string => {
  switch (linkColor) {
    case 'GREEN': {
      return 'rgb(114,255,131)';
    }
    case 'RED': {
      return '#ed6767';
    }
    case 'WHITE': {
      return 'ghostwhite';
    }
    case 'TEAL': {
      return '#02fff6';
    }
    case 'YELLOW': {
      return 'rgb(255,255,120)';
    }
    case 'ORANGE': {
      return '#FFAF19';
    }
    default: {
      // console.warn(`Could not map link color. Got ${linkColor}. Returning var(--teal) as default.`);
      return 'var(--teal)';
    }
  }
};
