/* eslint-disable react/display-name */
import React, { useMemo, useCallback, useState, useEffect, useRef } from 'react';
import { Cell } from 'react-table';
import _ from 'lodash';
import { AssetSymbol, OrderAction, AssetType } from '@tradingblock/types';
import { toAssetSymbol, AssetTypeToSymbolType, OrderBuilderType, isOCCSymbol, isIndexSymbol } from '@tradingblock/api';
import { EmptyBlockContent, Loading, Number, SignedNumber } from '@tradingblock/components';
import {
  useStateSelector,
  getFavorites,
  getFavoriteSymbols,
  dashboardIdSelector,
} from '../../data/global/dataSelectors';
import { getQuotesBySymbols } from '../../data/global/selectors/quoteSelector';
import { SymbolName } from '../../components/basic/SymbolName';
import { useDispatcher } from '../../data/global/hooks';

import { DirectionalPrice } from '../../components/trading/DirectionalPrice';
import { useTradeSymbolAction } from '../../components/blocks/hooks/useOrderBlock';
import { useChartSymbolAction, PriceChartBuilderType } from '../../components/blocks/hooks/usePriceChartBlock';
import { TradeOrderButton, ChartButton } from '../../components/blocks/actions/OrderActionButtons';
import { useBlockGroupInfo } from '../useGroupState';
import { changeCalculation } from '../../utilities/position';
import { useFavoriteSettings } from './useFavoriteSettings';
import { BlockTable } from '../shared/BlockTable';
import { BlockAction, useBlockMetadata } from '../../components/blocks/BlockState';
import { useBlockTable } from '../shared/table/useBlockTableActions';

interface FavoriteRowProps {
  AssetSymbol: AssetSymbol;
  Symbol: string;
  LastTradePrice: number;
  NetChange: number;
  ChangePercentage: number;
  BidPrice: number;
  AskPrice: number;
  Open: number;
  High: number;
  Low: number;
  ClosePrice: number;
  ClosePriceTMinus2: number;
  IsSettlementSet: boolean;
  CalculatedNetChange: number;
  CalculatedChangePercent: number;
}

const DefaultQuoteData = {
  Symbol: '',
  LastTradePrice: 0,
  NetChange: 0,
  ChangePercentage: 0,
  BidPrice: 0,
  AskPrice: 0,
  Open: 0,
  High: 0,
  Low: 0,
  ClosePrice: 0,
  ClosePriceTMinus2: 0,
  IsSettlementSet: false,
  CalculatedNetChange: 0,
  CalculatedChangePercent: 0,
};

export const FavoritesContent: React.FunctionComponent<{}> = () => {
  const { state } = useBlockTable('favorites');
  const [sortBy, setSortBy] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | undefined>(undefined);
  const { groupId } = useBlockGroupInfo();
  const { favorites, symbols } = useStateSelector(s => ({
    favorites: getFavorites(s),
    symbols: getFavoriteSymbols(s),
  }));
  const [pageIndex, setPageIndex] = useState(state.pageIndex);
  const [pageSize, setPageSize] = useState(state.pageSize);
  const total = _.size(favorites);

  const { dispatcher } = useDispatcher();
  const remove = useCallback(
    (symbol: string) => {
      dispatcher.favorites.remove(symbol);
    },
    [dispatcher.favorites]
  );

  const quoteMetaData = useStateSelector(s => getQuotesBySymbols(s, symbols));

  const data = useMemo(() => {
    return _.filter(
      _.map(favorites, f => {
        const quoteData = _.find(quoteMetaData, q => q.Symbol === f.symbol) || DefaultQuoteData;
        const {
          Symbol,
          LastTradePrice,
          NetChange,
          ChangePercentage,
          ClosePrice,
          Open,
          High,
          Low,
          BidPrice,
          AskPrice,
          IsSettlementSet,
          ClosePriceTMinus2,
        } = quoteData;

        const { change, changePercent } = changeCalculation(
          LastTradePrice,
          ClosePrice,
          ClosePriceTMinus2,
          BidPrice,
          AskPrice,
          Symbol,
          IsSettlementSet
        );
        return {
          Symbol,
          AssetSymbol: f,
          LastTradePrice,
          NetChange,
          ChangePercentage,
          BidPrice,
          AskPrice,
          Open,
          High,
          Low,
          ClosePrice,
          ClosePriceTMinus2,
          IsSettlementSet,
          CalculatedNetChange: change,
          CalculatedChangePercent: changePercent * 100,
        };
      }),
      d => !!d.Symbol
    );
  }, [quoteMetaData]);

  const getRowKey = useCallback((data: FavoriteRowProps) => `${data.Symbol}`, []);

  const getCurrentSortBy = useCallback(
    (newDesc?: boolean) => {
      if (sortBy) {
        return { id: sortBy, desc: !_.isUndefined(newDesc) ? newDesc : sortDirection === 'desc' };
      }
      return undefined;
    },
    [sortBy, sortDirection]
  );

  const [tableColumnOrder, setTableColumnOrder] = useState<string[]>([]);

  const onSort = useCallback(
    (sort: any[]) => {
      const sortVal = _.first(sort) || getCurrentSortBy(sortDirection === 'asc');
      if (sortVal) {
        const { id, desc, asc } = sortVal;
        setSortBy(id);
        setSortDirection(desc ? 'desc' : asc ? 'asc' : undefined);
      }
    },
    [tableColumnOrder]
  );

  const getColumnOrder = (columns: string[]) => {
    return setTableColumnOrder(columns);
  };

  const { blockId } = useBlockMetadata();
  const dashboardId = useStateSelector(dashboardIdSelector);
  const { type } = useBlockMetadata();
  const { dispatch } = useDispatcher();
  const blockType = type;
  const blocksLoading = useStateSelector(s => s.blocks.isFetching);

  useEffect(() => {
    const settingsSaveData = {
      dashboardId: dashboardId || '',
      blockId,
      blockType,
      blockSettings: {
        ...current,
      },
    };
    dispatch(BlockAction.setSettings(settingsSaveData.blockSettings));
    dispatcher.settings.block.setBlockSetting(settingsSaveData);
    return () => {
      dispatch(BlockAction.setSettings(settingsSaveData.blockSettings));
      dispatcher.settings.block.setBlockSetting(settingsSaveData);
    };
  }, [tableColumnOrder, sortBy]);

  const pageCount = useMemo(() => {
    if (pageSize) {
      return _.ceil((total || 0) / pageSize) === 0 ? 1 : _.ceil((total || 0) / pageSize);
    }
    return 0;
  }, [total, pageSize]);

  const onPage = useCallback((pageIndex: number) => {
    setPageIndex(pageIndex);
  }, []);

  const onPageSize = useCallback((pageSize: number) => {
    setPageSize(pageSize);
  }, []);

  const columns: any[] = useMemo(
    () => [
      {
        Header: 'Symbol',
        accessor: 'Symbol',
        canSort: true,
        Cell: ({ row }: Cell<FavoriteRowProps>) => (
          <SymbolName
            symbol={row.original.Symbol}
            underlyingSymbol={row.original.AssetSymbol.underlyingSymbol || row.original.Symbol}
          />
        ),
      },
      {
        Header: 'Last',
        accessor: 'LastTradePrice',
        canSort: true,
        sortType: 'alphanumeric',
        Cell: ({ row }: Cell<FavoriteRowProps>) => <DirectionalPrice value={row.original.LastTradePrice} />,
      },
      {
        Header: 'Change',
        accessor: 'NetChange',
        canSort: true,
        sortType: 'alphanumeric',
        Cell: ({ row }: Cell<FavoriteRowProps>) => {
          const val = useRef(0.0);
          if (row.original.NetChange) val.current = row.original.NetChange;
          return <SignedNumber value={val.current} bold currency roundDecimal={3} />;
        },
      },
      {
        Header: '(%)',
        accessor: (a: FavoriteRowProps) => a.ChangePercentage,
        canSort: true,
        sortType: 'alphanumeric',
        Cell: ({ row }: Cell<FavoriteRowProps>) => {
          const val = useRef(0.0);
          if (row.original.ChangePercentage) val.current = row.original.ChangePercentage;
          return <SignedNumber value={val.current} bold percent hideIcon />;
        },
      },
      {
        Header: 'Bid',
        accessor: 'BidPrice',
        canSort: true,
        sortType: 'alphanumeric',
        Cell: ({ row }: Cell<FavoriteRowProps>) => {
          return isIndexSymbol(row.original.Symbol) ? '' : <DirectionalPrice value={row.original.BidPrice} />;
        },
      },
      {
        Header: 'Ask',
        accessor: 'AskPrice',
        canSort: true,
        sortType: 'alphanumeric',
        Cell: ({ row }: Cell<FavoriteRowProps>) => {
          return isIndexSymbol(row.original.Symbol) ? '' : <DirectionalPrice value={row.original.AskPrice} />;
        },
      },
      {
        Header: 'Open',
        accessor: 'Open',
        canSort: true,
        sortType: 'alphanumeric',
        Cell: ({ row }: Cell<FavoriteRowProps>) => {
          const isOption = row.original.AssetSymbol.symbol.split(' ').length > 1;
          const isIndex = row.original.AssetSymbol.assetType === AssetType.Index;
          if (isOption || isIndex) return <></>;
          return <Number value={row.original.Open} currency />;
        },
      },
      {
        Header: 'Prev. Close',
        accessor: 'ClosePrice',
        canSort: true,
        sortType: 'alphanumeric',
        Cell: ({ row }: Cell<FavoriteRowProps>) => {
          return (
            <Number
              value={row.original.IsSettlementSet ? row.original.ClosePriceTMinus2 : row.original.ClosePrice}
              currency
            />
          );
        },
      },
      {
        Header: 'Close',
        canSort: true,
        sortType: 'alphanumeric',
        Cell: ({ row }: Cell<FavoriteRowProps>) => {
          if (row.original.AssetSymbol.assetType === AssetType.Index) {
            return <></>;
          }
          if (row.original.IsSettlementSet) {
            return <Number value={row.original.ClosePrice} currency />;
          } else {
            return <i className="fal fa-clock fa-lg mute" title="Settlement price not yet available." />;
          }
        },
      },
      {
        Header: 'High',
        accessor: 'High',
        canSort: true,
        sortType: 'alphanumeric',
        Cell: ({ row }: Cell<FavoriteRowProps>) => <Number value={row.original.High} currency />,
      },
      {
        Header: 'Low',
        accessor: 'Low',
        canSort: true,
        sortType: 'alphanumeric',
        Cell: ({ row }: Cell<FavoriteRowProps>) => <Number value={row.original.Low} currency />,
      },
      {
        Header: 'Trade',
        id: 'trade',
        Cell: ({ row }: Cell<FavoriteRowProps>) => {
          const assetSymbol = row.original.AssetSymbol;
          const underlyingSymbol = assetSymbol.underlyingSymbol || assetSymbol.symbol;
          const baseAssetSymbol = toAssetSymbol(
            underlyingSymbol,
            assetSymbol.assetType,
            AssetTypeToSymbolType(assetSymbol.assetType),
            { name: assetSymbol.name, underlyingSymbol, rootSymbol: underlyingSymbol }
          );
          const orderBuilder = useCallback((builder: OrderBuilderType) => {
            return builder.action(OrderAction.Buy);
          }, []);
          const handleTradeClick = useTradeSymbolAction(baseAssetSymbol, orderBuilder, { groupId });
          return <TradeOrderButton onClick={() => handleTradeClick()} />;
        },
      },
      {
        Header: 'Chart',
        id: 'chart',
        Cell: ({ row }: Cell<FavoriteRowProps>) => {
          const assetSymbol = row.original.AssetSymbol;
          const underlyingSymbol = assetSymbol.underlyingSymbol || assetSymbol.symbol;
          const baseAssetSymbol = toAssetSymbol(
            underlyingSymbol,
            assetSymbol.assetType,
            AssetTypeToSymbolType(assetSymbol.assetType),
            { name: assetSymbol.name, underlyingSymbol, rootSymbol: underlyingSymbol }
          );
          const isOCC = isOCCSymbol(assetSymbol.symbol);
          const priceChartBuilder = useCallback((builder: PriceChartBuilderType) => {
            return builder;
          }, []);
          const handleChart = useChartSymbolAction(baseAssetSymbol, priceChartBuilder, { groupId });
          const handleClick = () => {
            handleChart();
            groupId && dispatcher.blockGroup.setSymbol(groupId, assetSymbol);
          };
          //Don't show for option/symbol rows
          if (isOCC) {
            return null;
          }
          return <ChartButton onClick={handleClick} />;
        },
      },
      {
        Header: 'Remove',
        id: 'favorites-last-col',
        className: 'col-delete',
        Cell: ({ row }: Cell<FavoriteRowProps>) => (
          <button
            className="btn btn-blend remove-btn"
            title="Remove"
            onClick={() => {
              remove(row.original.Symbol);
            }}
            type="button"
          >
            <i className="fas fa-times-circle" />
          </button>
        ),
      },
    ],
    [remove, groupId, dispatcher.favorites.request, tableColumnOrder]
  );

  const { filterHiddenColumns, current } = useFavoriteSettings();
  const filterColumns = useCallback(() => filterHiddenColumns(columns, current), [current, columns, tableColumnOrder]);

  const initialState = useMemo(() => {
    const currentSortBy = getCurrentSortBy();
    return {
      pageSize: _.isUndefined(pageSize) ? 50 : pageSize,
      pageIndex: pageIndex || 0,
      sortBy: currentSortBy ? [currentSortBy] : [],
      columnOrder: [],
    };
  }, [pageSize, pageIndex, total]);

  // we need to chunk the data based on the current page size and page index
  const chunkedData = useMemo(() => {
    if (pageSize === 0) {
      return data;
    }
    const start = pageIndex * pageSize;
    const end = start + pageSize;
    return data.slice(start, end);
  }, [data, pageIndex, pageSize]);

  return (
    <>
      {data && data.length > 0 && !blocksLoading && (
        <BlockTable
          tableKey="favorites"
          columns={filterColumns()}
          data={chunkedData}
          getRowKey={getRowKey}
          sortable
          frozenHeaders
          hasPagination
          manualPagination
          onSort={onSort}
          pageCount={pageCount}
          onPage={onPage}
          initialState={initialState}
          onPageSize={onPageSize}
          numColumns={_.size(columns)}
          getColumnOrder={getColumnOrder}
          loaded={true}
        />
      )}
      {!data || (data.length === 0 && <EmptyBlockContent>You have no favorites.</EmptyBlockContent>)}
      {blocksLoading && <Loading />}
    </>
  );
};
