import React, { useCallback, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Field } from 'formik';
import {
  CashieringManageAction,
  CashieringView,
  CashieringRoute,
  TransferRecipientBank,
  TransferMechanism,
  AchRelationship,
  ApproveAchRelationshipRequest,
  CreateAchRelationshipRequest,
  ClearerType,
  TransferInstruction,
  CreateTransferInstructionRequest,
  TransferBankIdentifierType,
  TransferInstructionsRequest,
} from '@tradingblock/types';
import { FormGroup, StepperField } from '@tradingblock/components';
import { cashieringSelectors, accountIdSelector, useStateSelector } from '../../../../data/global/dataSelectors';
import { useDispatcher } from '../../../../data/global/hooks';
import {
  CashieringViewProps,
  CashieringViewFormValues,
  ViewOnSubmitType,
  ViewOnValidateType,
} from '../data/useCashieringView';
import { getCurrentTransferView } from '../../../../utilities/cashiering';
import {
  validateTransferAchRelationship,
  validateTransferRecipientBank,
  validateAmount,
  validateTransferAchRelationshipOptions,
} from '../data/cashieringValidation';
import { useCashieringDataContext } from '../data/useCashieringData';
import { SelectCheckTransfer } from './SelectCheckTransfer';
import { SelectAchRelationshipTransfer } from './SelectAchRelationshipTransfer';
import { SelectWireTransfer } from './SelectWireTransfer';
import { useClearer } from '../../../../hooks/useClearer';
import { useCurrentDayACHTransfersLimit } from '../../../../hooks/useCurrentDayACHTransfers';

export interface AccountsListProps extends CashieringViewProps<CashieringViewFormValues> {
  route: CashieringRoute;
}

const AccountsList: React.FunctionComponent<AccountsListProps> = props => {
  const { route, values, setValues } = props;
  const { action, setAction } = useCashieringDataContext();

  const { dispatcher } = useDispatcher();
  const view = getCurrentTransferView();

  const accountId = useSelector(accountIdSelector);
  const isSavingAchRelationships = useStateSelector(s => cashieringSelectors.dataIsSaving(s, 'achRelationships'));
  const isSaving = useMemo(() => isSavingAchRelationships, [isSavingAchRelationships]);
  const isLoadingTransfers = useStateSelector(s => cashieringSelectors.dataIsLoading(s, 'transfers'));
  const clearer = useClearer();

  const selectable = route !== CashieringRoute.Accounts;
  const { transfer } = values;
  const { mechanism } = transfer || { mechanism: undefined };

  // Initially request all transfers to calculate current day ACH transfer amounts. if user was to refresh the page these values would be lost from the dashboard
  useEffect(() => {
    if (accountId && !isLoadingTransfers) {
      dispatcher.cashiering.transfers.all.request();
    }
  }, []);

  const currentDayACHTransfers = useCurrentDayACHTransfersLimit();

  // convert transfer.amount to a string, remove preceding $ and then convert back to a number
  // TODO: review why this transfer.amount is coming back as a string and not a number with no errors
  const amount = useMemo(() => {
    if (transfer && transfer.amount) {
      return Number(transfer.amount.toString().replace('$', ''));
    }
    return 0;
  }, [transfer, values]);

  // check if currentDayACHTransfers plus amount is greater than 50,000 set equal to a boolean value
  const isACHTransferLimitExceeded = useMemo(() => {
    return currentDayACHTransfers + amount > 50000;
  }, [currentDayACHTransfers, amount]);

  useEffect(() => {
    if (isACHTransferLimitExceeded) {
      setValues({
        ...values,
        transfer: {
          ...values.transfer,
          mechanism: 'Wire',
        },
      });
    }
  }, [isACHTransferLimitExceeded]);

  useEffect(() => {
    if (!action && (view === CashieringView.Manage || view === CashieringView.Type) && accountId && !isSaving) {
      // refetch data
      if (mechanism === 'Ach') {
        dispatcher.cashiering.achRelationships.all.request(accountId);
      } else if (mechanism === 'Wire') {
        // dispatcher.cashiering.recipientBank.all.request(accountId);
        // also fetch beneficiaries in background, needed for wires
        // dispatcher.cashiering.transferBeneficiary.all.request(accountId);
        dispatcher.cashiering.transferInstructions.all.request(accountId);
      }
    }
    if (!action && values.account) {
      setValues({ ...values, account: undefined });
    }
  }, [view, accountId, isSaving, mechanism, action, setValues, dispatcher.cashiering]);

  const setManageAction = (
    action: CashieringManageAction | undefined,
    data?: TransferRecipientBank | AchRelationship | TransferInstructionsRequest
  ) => {
    setAction(action);
    if (data) {
      setValues({ ...values, account: data });
    }
  };

  const onChangeTransferMechanism = (mechanism: TransferMechanism) => {
    setManageAction(undefined);
    setValues({ ...values, transfer: { ...values.transfer, mechanism } });
  };

  const transferMechanismOptions = useMemo(() => {
    const options = [
      { value: 'Ach', label: 'Linked bank account', disabled: isACHTransferLimitExceeded },
      { value: 'Wire', label: 'Wire transfer' },
      { value: 'Check', label: 'Paper check', disabled: !selectable || clearer === ClearerType.RQD },
    ];

    return options;
  }, [selectable, clearer, isACHTransferLimitExceeded]);

  // text will differ slightly based on clearer
  const exceedsDailyLimitText = {
    body: {
      [ClearerType.RQD]: [
        'Daily ACH withdrawals are limited to $50,000. If the requested withdrawal amount exceeds the daily limit, you may elect to receive funds via wire transfer. Fees apply.',
      ],
      [ClearerType.Apex]: [
        'Daily ACH withdrawals are limited to $50,000. If the requested withdrawal amount exceeds the daily limit, you may elect to receive funds via wire transfer or paper check. Fees apply.',
      ],
    },
    fees:
      'To avoid fees, you may also click on the “Part of Cash Balance” tab above and request a smaller withdrawal amount today and submit additional requests on subsequent days until the desired amount has been withdrawn.',
  };

  return (
    <>
      <FormGroup>
        <Field
          component={StepperField}
          id="transfer.mechanism"
          options={transferMechanismOptions}
          onChanged={onChangeTransferMechanism}
        />
      </FormGroup>
      {isACHTransferLimitExceeded && (
        <div className="container">
          <div
            className="row mb-3"
            style={{
              alignItems: 'center',
            }}
          >
            <div className="col-1">
              <i className="fal fa-exclamation-triangle fa-2x warn" />
            </div>
            <div className="col-11 txt-sm">
              <span
                className="warn"
                style={{
                  fontStyle: 'italic',
                }}
              >
                {exceedsDailyLimitText.body[clearer as ClearerType].map((text, i) => (
                  <React.Fragment key={i}>
                    {text}
                    <br />
                    <br />
                  </React.Fragment>
                ))}
                {exceedsDailyLimitText.fees}
              </span>
            </div>
          </div>
        </div>
      )}

      {values.transfer && values.transfer.mechanism === 'Ach' && (
        <SelectAchRelationshipTransfer {...props} setManageAction={setManageAction} />
      )}

      {values.transfer && values.transfer.mechanism === 'Wire' && (
        <SelectWireTransfer {...props} setManageAction={setManageAction} />
      )}

      {values.transfer && values.transfer.mechanism === 'Check' && <SelectCheckTransfer {...props} />}
    </>
  );
};

export const useCashieringAccountsList = (): [typeof AccountsList, ViewOnSubmitType, ViewOnValidateType] => {
  const { dispatcher } = useDispatcher();
  const accountId = useSelector(accountIdSelector);
  const userName = useStateSelector(s => s.auth.userName);

  const { action, setAction } = useCashieringDataContext();

  const onValidate = useCallback(
    (values: CashieringViewFormValues) => {
      const { account, transfer } = values;
      if (action === CashieringManageAction.AddOptions) {
        if (transfer && transfer.mechanism === 'Ach') {
          return validateTransferAchRelationshipOptions(account, transfer);
        }
      } else if (action === CashieringManageAction.Add) {
        if (transfer && transfer.mechanism === 'Ach') {
          return validateTransferAchRelationship(account);
        } else if (transfer && transfer.mechanism === 'Wire') {
          return validateTransferRecipientBank(account);
        }
      } else if (action === CashieringManageAction.Rename) {
        if (!account || !account.nickName) {
          return {
            'account.nickName': `Please enter the ${
              transfer && transfer.mechanism === 'Wire' ? 'bank name' : 'account name'
            }`,
          };
        }
      } else if (action === CashieringManageAction.Verify) {
        const amount1Errors = validateAmount(
          account && account.amount1,
          {
            required: 'Please enter deposit 1',
            invalid: 'Please enter a valid amount for deposit 1',
            max: 'Deposit 1 must be less than 0.50',
          },
          'account.amount1',
          0.5
        );
        const amount2Errors = validateAmount(
          account && account.amount2,
          {
            required: 'Please enter deposit 2',
            invalid: 'Please enter a valid amount for deposit 2',
            max: 'Deposit 2 must be less than 0.50',
          },
          'account.amount2',
          0.5
        );
        return { ...amount1Errors, ...amount2Errors };
      }
      return {};
    },
    [action]
  );

  const onSubmit = useCallback(
    (values: CashieringViewFormValues, view: CashieringView | undefined) => {
      const { account, transfer } = values;

      if (transfer && transfer.mechanism === 'Ach') {
        if (action === CashieringManageAction.Add) {
          if (account && account.approvalMethod === 'Plaid') {
            // adding ach relationship via Plaid
            const request = _.pick(account, [
              'plaidPublicToken',
              'plaidAccountId',
              'nickName',
              'approvalMethod',
            ]) as CreateAchRelationshipRequest;
            dispatcher.cashiering.achRelationships.create.request(request);
          } else if (account && account.approvalMethod === 'MicroDeposit') {
            // adding ach relationship via microdeposit
            const data = _.pick(account, [
              'bankAccountType',
              'bankRoutingNumber',
              'bankName',
              'nickName',
              'approvalMethod',
            ]) as AchRelationship;
            const secretData = _.pick(account, ['bankAccount', 'bankAccountOwnerName']) as AchRelationship;
            const request = {
              ...data,
              bankAccountSecret: secretData.bankAccount,
              bankAccountOwnerNameSecret: secretData.bankAccountOwnerName,
            };
            dispatcher.cashiering.achRelationships.create.request(request);
          }
        } else if (action === CashieringManageAction.Verify) {
          // this action is deprecated, now that Plaid is being used
          const data = _.pick(account, ['amount1', 'amount2', 'approvalMethod']) as ApproveAchRelationshipRequest;
          const request = {
            ...data,
            amount1: _.toNumber(data.amount1),
            amount2: _.toNumber(data.amount2),
            achRelationshipId: _.toNumber(account && account.id),
          };
          dispatcher.cashiering.achRelationships.approve.request({ accountId, ...request });
        } else if (action === CashieringManageAction.Rename) {
          dispatcher.cashiering.achRelationships.update.request({
            achRelationshipId: (account && account.id) || 0,
            nickName: (account && account.nickName) || 'NICKNAME',
            accountId,
          });
        } else if (action === CashieringManageAction.Delete) {
          const comment = `Cancelled by Client (${userName})`;
          dispatcher.cashiering.achRelationships.delete.request({
            achRelationshipId: (account && account.id) || 0,
            accountId,
            comment,
          });
        } else if (action === CashieringManageAction.AddOptions) {
          // send to add after picking option
          setAction(CashieringManageAction.Add);
        } else {
          return true;
        }
      } else if (transfer && transfer.mechanism === 'Wire') {
        if (action === CashieringManageAction.Add) {
          const data = _.pick(account, [
            'identifierType',
            'identifier',
            'accountNumber',
            'city',
            'country',
            'additionalInfo',
            'name',
            'nameOnAccount',
            'nickName',
            'postalCode',
            'stateProvince',
            'intermediary',
          ]) as any;
          let request: CreateTransferInstructionRequest = {
            accountSecret: data.accountNumber,
            nameSecret: data.name,
            nickName: data.nickName,
            recipientBank: {
              identifierType: data.identifierType,
              identifier: data.identifier,
            },
          };
          if (data.identifierType === TransferBankIdentifierType.BIC) {
            request.recipientBank = {
              ...request.recipientBank,
              name: data.name,
              city: data.city,
              country: data.country,
            };
          }
          if (data.intermediary) {
            request.intermediary = {
              accountSecret: data.intermediary && data.intermediary.accountSecret,
              name: data.intermediary && data.intermediary.name,
              streetAddress1: data.intermediary && data.intermediary.streetAddress1,
              streetAddress2: data.intermediary && data.intermediary.streetAddress2,
              city: data.intermediary && data.intermediary.city,
              country: data.intermediary && data.intermediary.country,
              postalCode: data.intermediary && data.intermediary.postalCode,
            };
          }
          dispatcher.cashiering.transferInstructions.create.request({ ...request, accountId });
          // dispatcher.cashiering.recipientBank.create.request({ ...data, accountId });
        } else if (action === CashieringManageAction.Rename) {
          dispatcher.cashiering.transferInstructions.update.request({
            id: (account && account.id) || 0,
            nickName: (account && account.nickName) || 'NICKNAME',
            accountId,
          });
        } else if (action === CashieringManageAction.Delete) {
          dispatcher.cashiering.transferInstructions.delete.request({
            id: (account && account.id) || 0,
            accountId,
          });
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    [accountId, action, dispatcher.cashiering]
  );

  return [AccountsList, onSubmit, onValidate];
};
