import React, { useCallback, useState, useMemo, useContext } from 'react';
import { Formik, useFormikContext } from 'formik';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import _ from 'lodash';
import * as Yup from 'yup';
import { Modal, getBlockTypeName } from '@tradingblock/components';
import { BlockType, BlockSettingsObject } from '@tradingblock/types';
import { useBlockMetadata, useBlockStateSelector, BlockActionContext } from '../BlockState';
import { renderGenericFormWithFilter } from '../../form/renderGenericForm';
import { SettingsFields, BlockSettingsField, SettingsField } from '../../form/GenericField';
import { BlockSettingsTabs } from './BlockSettingsTabs';
import { BlockSettingsModalContentProps, GenericSettingsObject, BlockSettingsProps } from './BlockSettingsTypes';
import { objectDiff } from '../../../utilities/data';
import { useDispatcher } from '../../../data/global/hooks';
import { useStateSelector, dashboardIdSelector } from '../../../data/global/dataSelectors';
import { BlockSettingSelector } from '../../../data/global/selectors/settingSelectors';
import { BlockAction } from '../state/BlockState';
import { DefaultFavoriteBlockSettings } from '../../../blocks/Favorites/useFavoriteSettings';
import { DefaultOptionChainBlockSettings } from '../../../blocks/OptionChain/state/OptionChainSettings';
import { DefaultPositionsBlockSettings } from '../../../blocks/Positions/usePositionSettings';
import { DefaultOrderBlockSettings } from '../../../blocks/Order/state/OrderSettings';

const BlockSettingsModalViewContent: React.FunctionComponent<BlockSettingsModalContentProps> = ({
  show,
  toggleVisibility,
  settingFields,
  handleSubmit,
}) => {
  const { type } = useBlockMetadata();
  const onCancel = useCallback(() => {
    toggleVisibility();
  }, [toggleVisibility]);

  const [tab, setTab] = useState<'this' | 'all' | string>('this');

  const { values, errors } = useFormikContext<GenericSettingsObject>();

  switch (type) {
    case 'Favorites':
      {
        if (
          Object.keys(values.block).length < Object.keys(values.global).length ||
          Object.keys(values.block).length === 0
        ) {
          // Overrwrite
          values.block = {
            ...DefaultFavoriteBlockSettings,
          };
        }
      }
      break;
    case 'OptionChain':
      {
        if (
          Object.keys(values.block).length < Object.keys(values.global).length ||
          Object.keys(values.block).length === 0
        ) {
          // Overrwrite
          values.block = {
            ...DefaultOptionChainBlockSettings,
          };
        }
      }
      break;
    case 'Positions':
      {
        if (
          Object.keys(values.block).length < Object.keys(values.global).length ||
          Object.keys(values.block).length === 0
        ) {
          // Overrwrite
          values.block = {
            ...DefaultPositionsBlockSettings,
          };
        }
      }
      break;
    case 'Order':
      {
        if (
          Object.keys(values.block).length < Object.keys(values.global).length ||
          Object.keys(values.block).length === 0
        ) {
          console.log({ values: values.global });
          // Overrwrite
          values.block = {
            ...DefaultOrderBlockSettings,
          };
        }
      }
      break;
  }

  const onSelectTab = useCallback(
    (value: 'this' | 'all' | string) => {
      setTab(value);
    },
    [tab, values]
  );
  const mapFields = useCallback((f: any) => _.omit(f, 'global'), []);
  return (
    <Modal setShow={toggleVisibility} show={show}>
      <BootstrapModal.Header onHide={onCancel} closeButton>
        <BootstrapModal.Title>{`${type && getBlockTypeName(type)} block settings`}</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <form id="blocksettings" onSubmit={handleSubmit} className={`${type}-block-settings`.toLowerCase()}>
          <BlockSettingsTabs
            onSelectTab={onSelectTab}
            thisBlockTab={renderGenericFormWithFilter(settingFields, f => f.global === false, values, mapFields)}
            allBlockTab={renderGenericFormWithFilter(settingFields, f => f.global, values, mapFields)}
          />
          <div className="block-settings-form-buttons d-flex justify-content-space-between align-items-center">
            <Button variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" disabled={!!(errors && (errors.block || errors.global))} className="settings-submit-btn">
              Save
            </Button>
          </div>
          {
            errors && (errors.block || errors.global) &&
            <span className="error d-flex align-items-center justify-content-center block-settings-global-error-msg">
              Please resolve issues on both tabs before saving.
            </span>
          }
        </form>
      </BootstrapModal.Body>
    </Modal>
  );
};

interface BlockSettingsData<SF = SettingsFields, S = GenericSettingsObject<SF>> {
  global: S;
  block: S;
}
interface BlockSettingsSaveData {
  dashboardId: string;
  blockId: string | undefined;
  blockType: BlockType;
  blockSettings: BlockSettingsObject<SettingsFields<any, SettingsField>> | undefined;
  globalSettings: BlockSettingsObject<SettingsFields<any, SettingsField>> | undefined;
}

const BlockSettingsModalView: React.FunctionComponent<
  BlockSettingsProps & {
    onSave: (val: BlockSettingsSaveData) => void;
    type: BlockType;
    blockId: string;
    dashboardId: string;
  }
> = ({ blockId, type, dashboardId, onSave, ...props }) => {
  const { toggleVisibility, show, settingFields } = props;

  const { globalSettings, blockSettings } = useStateSelector(s => BlockSettingSelector(s, blockId));

  const initialValues = useMemo(() => ({ global: globalSettings || {}, block: blockSettings || {} }), [
    globalSettings,
    blockSettings,
  ]);

  const onSubmit = useCallback(
    (values: BlockSettingsData) => {
      const blockSettingsChanged = objectDiff(initialValues.block, values.block);
      const globalChanged = objectDiff(initialValues.global, values.global);
      toggleVisibility();
      if (globalChanged || blockSettingsChanged) {
        const payload = {
          dashboardId,
          blockId: blockSettingsChanged ? blockId : undefined,
          blockType: type,
          blockSettings: blockSettingsChanged ? values.block : undefined,
          globalSettings: globalChanged ? values.global : undefined,
        };
        onSave(payload);
      }
    },
    [toggleVisibility, initialValues]
  );
  const localAndGlobalSettingFields: BlockSettingsField = useMemo(
    () =>
      _.reduce(
        settingFields || {},
        (acc: BlockSettingsField, value, key): BlockSettingsField => {
          const globalField = `global.${key}`;
          const blockField = `block.${key}`;
          return {
            ...acc,
            [blockField]: { ...value, name: blockField, label: _.startCase(key), global: false },
            [globalField]: { ...value, name: globalField, label: _.startCase(key), global: true },
          };
        },
        {}
      ),
    [initialValues.global, initialValues.block]
  );

  // Order block settings form validation
  const orderBlockSettingsQuantityErrorMessage = 'Please enter a positive integer greater than zero.';
  const orderBlockSettingsQuantityFieldValidationSchema = Yup
        .number()
        .transform((value) => isNaN(value) ? null : value)
        .nullable()
        .required(orderBlockSettingsQuantityErrorMessage)
        .positive(orderBlockSettingsQuantityErrorMessage)
        .integer(orderBlockSettingsQuantityErrorMessage);
  const orderBlockSettingsValidationSchema = Yup.object().shape({
    block: Yup.object().shape({
      defaultQuantityForOptionsAndStrategies: orderBlockSettingsQuantityFieldValidationSchema,
      defaultQuantityForShares: orderBlockSettingsQuantityFieldValidationSchema,
    }),
    global: Yup.object().shape({
      defaultQuantityForOptionsAndStrategies: orderBlockSettingsQuantityFieldValidationSchema,
      defaultQuantityForShares: orderBlockSettingsQuantityFieldValidationSchema,
    }),
  });

  return (
    <>
      {show && (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={type === BlockType.Order ? orderBlockSettingsValidationSchema : null}
          validateOnChange
          onSubmit={onSubmit}
        >
          {formikProps => (
            <BlockSettingsModalViewContent
              {...formikProps}
              {...props}
              settingFields={localAndGlobalSettingFields}
            />
          )}
        </Formik>
      )}
    </>
  );
};

export const BlockSettingsModal: React.FunctionComponent<BlockSettingsProps> = props => {
  const { type, blockId } = useBlockStateSelector(s => ({ type: s.type, blockId: s.blockId }));
  const dashboardId = useStateSelector(dashboardIdSelector);
  const { dispatcher } = useDispatcher();
  const dispatch = useContext(BlockActionContext);
  const onSave = useCallback((payload: BlockSettingsSaveData) => {
    dispatch(BlockAction.setSettings(payload.blockSettings));
    dispatcher.settings.block.setBlockSetting(payload);
  }, []);
  return (
    <>
      {dashboardId && (
        <BlockSettingsModalView {...props} onSave={onSave} type={type} blockId={blockId} dashboardId={dashboardId} />
      )}
    </>
  );
};

export * from './BlockSettingsTypes';
