import { RootAction, Actions } from '../actions';
import { InitialState } from '../initialState';
import { getType } from 'typesafe-actions';
import Config from '../../../config';
import _ from 'lodash';
import { SettingsActions } from '../actions/SettingsActions';

export const settingsReducer = (state = InitialState.settings, action: RootAction) => {
  switch (action.type) {
    case getType(SettingsActions.defaultDashboardRequest): {
      return {
        ...state,
        defaultDashboards: {
          ...state.defaultDashboards,
          isFetching: true,
        },
      };
    }
    case getType(SettingsActions.defaultDashboardReceive): {
      const dashboards = action.payload && action.payload.value ? action.payload.value : [];
      return {
        ...state,
        defaultDashboards: {
          ...state.defaultDashboards,
          isFetching: false,
          dashboards,
        },
      };
    }

    case getType(Actions.requestSettings):
      return { ...state, ui: { ...InitialState.settings.ui, isFetching: true } };
    case getType(Actions.receiveSettings):
    case getType(Actions.setSettings): {
      const isFetching = getType(Actions.receiveSettings) === action.type ? false : state.ui.isFetching;
      const { payload } = action;
      const afterHourTrading = Config.isProduction ? false : payload.settings.afterHoursTrading || state.ui.afterHoursTrading;
      const uiSettings = { ...state.ui, isFetching, ...payload.settings, afterHourTrading };

      return { ...state, ui: { ...uiSettings } };
    }
    case getType(Actions.toggleSettings):
      return {
        ...state,
        showSettings: !_.isUndefined(action.payload.show) ? action.payload.show : !state.showSettings,
      };

    default:
      return state;
  }
};
