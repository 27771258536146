import { useMemo, useEffect, useState } from 'react';
import _ from 'lodash';
import { useDebug } from '@tradingblock/components';
import { useStateSelector, loadedoptionChains } from '../../../data/global/dataSelectors';
import { ExpirationOptionPair } from '../../../types';
import { AssetSymbol } from '@tradingblock/types';
import { useOptionChainData } from '../state/OptionChainState';
import { useQuoteActions } from '../../../hooks/useQuoteData';
import { areArraysEqual } from '../../../utilities/data';
import { useBlockGlobalData, useBlockId } from '../../../components/blocks/BlockState';
import { useDispatcher } from '../../../data/global/hooks';
import { expirationValue } from '../../../utilities/date';

export const useOptionChainSymbols = (assetSymbol: AssetSymbol) => {
  const { dispatcher } = useDispatcher();
  const { symbol } = assetSymbol;
  const log = useDebug('useOptionChainSymbols');
  const blockId = useBlockId();
  const [subscribedSymbols, setSubscribedSymbols] = useState<string[]>([]);
  const { setQuotes, addQuote } = useQuoteActions();
  const { quotes } = useBlockGlobalData();
  const expandedExpirations = useOptionChainData(s => s.expandedExpirations);

  const optionchain = useStateSelector(s => loadedoptionChains(s, symbol || '', blockId));
  const filteredChainData = _.filter(optionchain, (d: ExpirationOptionPair) => _.includes(_.map(expandedExpirations, expirationValue), expirationValue(d.expiration)));
  const symbolsForData = useMemo(() => {
    if (!symbol) {
      return [];
    }
    return _.reduce(
      filteredChainData,
      (symbols: string[], expiration) => {
        const expPairs = _.reduce(
          expiration.pairs,
          (pairSymbols: string[], pair) => {
            return [...pairSymbols, pair.Call.Symbol, pair.Put.Symbol];
          },
          []
        );
        return _.union(symbols, expPairs);
      },
      []
    );
  }, [filteredChainData, symbol]);
  useEffect(() => {
    if (symbol) {
      addQuote(symbol);
    }
  }, [symbol, addQuote]);
  useEffect(() => {
    if (!areArraysEqual(subscribedSymbols, symbolsForData)) {
      log('block symbols %o', { fetchAndSubscribe: [symbol], subscribe: symbolsForData });
      setSubscribedSymbols(symbolsForData);
      setQuotes({ subscribe: [...symbolsForData, symbol] });
    } else if (!quotes.includes(symbol)) {
      setQuotes(symbol);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbolsForData, symbol]);

  useEffect(() => {
    dispatcher.expirations.request(symbol);
  }, [symbol]);

  return symbolsForData;
};
