import { GenericError, SubAccount } from '@tradingblock/types';
import { buildGenericRequestReceiveActions } from '../actionUtilities';
import { ApiResponse } from '@tradingblock/api';

//Subaccounts 
const REQUEST_SUBACCOUNTS = '@data/REQUEST/SUBACCOUNTS';
const RECEIVE_SUBACCOUNTS = '@data/RECEIVE/SUBACCOUNTS';
const ERROR_SUBACCOUNTS = '@data/ERROR/SUBACCOUNTS';

const REQUEST_DELETE_SUBACCOUNT = '@data/REQUEST/DELETE_SUBACCOUNT';
const RECEIVE_DELETE_SUBACCOUNT = '@data/RECEIVE/DELETE_SUBACCOUNT';
const ERROR_DELETE_SUBACCOUNT = '@data/ERROR/DELETE_SUBACCOUNT';

const REQUEST_UPDATE_SUBACCOUNT = '@data/REQUEST/UPDATE_SUBACCOUNT';
const RECEIVE_UPDATE_SUBACCOUNT = '@data/RECEIVE/UPDATE_SUBACCOUNT';
const ERROR_UPDATE_SUBACCOUNT = '@data/ERROR/UPDATE_SUBACCOUNT';

const REQUEST_CREATE_SUBACCOUNT = '@data/REQUEST/CREATE_SUBACCOUNT';
const RECEIVE_CREATE_SUBACCOUNT = '@data/RECEIVE/CREATE_SUBACCOUNT';
const ERROR_CREATE_SUBACCOUNT = '@data/ERROR/CREATE_SUBACCOUNT';

const REQUEST_GET_POSITIONS = '@data/REQUEST/GET_POSITIONS';
const RECEIVE_GET_POSITIONS = '@data/RECEIVE/GET_POSITIONS';
const ERROR_GET_POSITIONS = '@data/ERROR/GET_POSITIONS';

const [requestSubaccounts, receieveSubaccounts, errorSubaccounts] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_SUBACCOUNTS,
  RECEIVE: RECEIVE_SUBACCOUNTS,
  ERROR: ERROR_SUBACCOUNTS,
})<{ accountId: number }, { response: ApiResponse<SubAccount[]> }, GenericError<any, any>>();

const [requestDeleteSubaccount, receiveDeleteSubaccount, errorDeleteSubaccount] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_DELETE_SUBACCOUNT,
  RECEIVE: RECEIVE_DELETE_SUBACCOUNT,
  ERROR: ERROR_DELETE_SUBACCOUNT,
})<{ accountId: number, Id: number }, { response: ApiResponse<SubAccount> }, GenericError<any, any>>();

const [requestUpdateSubaccount, receiveUpdateSubaccount, errorUpdateSubaccount] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_UPDATE_SUBACCOUNT,
  RECEIVE: RECEIVE_UPDATE_SUBACCOUNT,
  ERROR: ERROR_UPDATE_SUBACCOUNT,
})<{ subAccount: any }, { response: ApiResponse<any> }, GenericError<any, any>>();

const [requestGetPositions, receivePositions, errorReceivePositions] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_GET_POSITIONS,
  RECEIVE: RECEIVE_GET_POSITIONS,
  ERROR: ERROR_GET_POSITIONS,
})<{ accountId: number, Id: number }, { response: ApiResponse<any> }, GenericError<any, any>>();

const [requestCreateSubaccount, receiveCreateSubaccount, errorCreateSubaccount] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_CREATE_SUBACCOUNT,
  RECEIVE: RECEIVE_CREATE_SUBACCOUNT,
  ERROR: ERROR_CREATE_SUBACCOUNT,
})<{ subAccount: Omit<SubAccount, 'Id'> }, { response: ApiResponse<SubAccount> }, GenericError<any, any>>();

export const SubaccountActions = {
  requestSubaccounts,
  receieveSubaccounts,
  errorSubaccounts,
  requestDeleteSubaccount,
  receiveDeleteSubaccount,
  errorDeleteSubaccount,
  requestUpdateSubaccount,
  receiveUpdateSubaccount,
  errorUpdateSubaccount,
  requestCreateSubaccount,
  receiveCreateSubaccount,
  errorCreateSubaccount,
  requestGetPositions,
  receivePositions,
  errorReceivePositions
};
