/* eslint-disable react/display-name */
import React, { useCallback, useState } from 'react';
import { ActivityOrdersTable } from './components/ActivityOrdersTable';
import { ActivityOrderTimeframe } from './components/ActivityOrderTimeframe';
import { useOrderPreview, useOrderPreviewOrder } from '../Order/components/Preview/useOrderPreview';
import { OrderPreviewPopup } from '../Order/components/Preview/PreviewPopup';
import { Order, OrderLeg } from '@tradingblock/types';
import { useDashboardSettings } from '../../hooks/useDashboardSettings';

const ActivityOrders: React.FunctionComponent<{
  tabType: 'orders' | 'trades';
}> = ({ tabType }) => {
  const [isCancelOrder, setIsCancelOrder] = useState(false);
  const [orderValue, setOrderValue] = useState<Order<OrderLeg>>();
  const { muteAllSounds } = useDashboardSettings();
  const {
    isPreview,
    togglePreview,
    previewClosed,
    audio: { successAudio, errorAudio },
  } = useOrderPreview();
  const { setOrder, action } = useOrderPreviewOrder('replace');
  const onReplaceOrder = useCallback(
    (order: Order<OrderLeg>) => {
      setOrderValue(order);
      setOrder(order);
      setIsCancelOrder(false);
      togglePreview();
    },
    [setOrder, togglePreview]
  );
  const onCancelOrder = useCallback(
    (order: Order<OrderLeg>) => {
      setOrder(order);
      setIsCancelOrder(true);
      togglePreview();
    },
    [setOrder, togglePreview]
  );

  return (
    <>
      {!muteAllSounds && successAudio}
      {!muteAllSounds && errorAudio}
      {isPreview && (
        <OrderPreviewPopup
          show={isPreview}
          isCancelOrder={isCancelOrder}
          previewClosed={previewClosed}
          action={action}
          orderValue={orderValue}
        />
      )}
      <ActivityOrderTimeframe tabType={tabType} />
      <ActivityOrdersTable
        tabType={tabType}
        onCancelOrder={onCancelOrder}
        onReplace={onReplaceOrder}
      />
    </>
  );
};

export const ActivityOrderContent: React.FunctionComponent<{
  tabType: 'orders' | 'trades';
}> = props => {
  return <ActivityOrders {...props} />;
};
