import React, { useEffect } from 'react';
import _ from 'lodash';
import { Button, Loading } from '@tradingblock/components';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { useDispatcher } from '../../../../data/global/hooks';
import { AdminEntitlementsActions } from '../../../../data/global/actions/admin/EntitlementsActions';
import { DefaultEntitlementsItem, UpdateUserMarketDataEntitlementRequest } from '@tradingblock/types';
import { formatMarketDataEntitlementType } from '../../../../utilities/entitlements';

export const AdminEntitlementsFooter: React.FunctionComponent<{}> = () => {
  const { dispatch } = useDispatcher();
  const ApprovingUserName = useStateSelector(s => s.private.entitlements.currentItem.details.ApprovingUserName);
  const isPending = !ApprovingUserName ? true : false;
  const details = useStateSelector(s => s.private.entitlements.currentItem.details) || DefaultEntitlementsItem;
  const { Id } = details;
  const userLevel = useStateSelector(s => s.account.profile.current && s.account.profile.current.level);
  const isAdmin = userLevel === 'Admin';

  const handleClick = (e: any) => {
    e.preventDefault();
    dispatch(AdminEntitlementsActions.clearCurrentItem());
  };

  const handleApproval = (e: any) => {
    e.preventDefault();

    if (Id) {
      const updateRequest: UpdateUserMarketDataEntitlementRequest = {
        pendingId: Id,
      };
      dispatch(AdminEntitlementsActions.requestApproveUserMarketDataEntitlement(updateRequest));

      dispatch(AdminEntitlementsActions.clearCurrentItem());
    }
  };

  const handleReject = (e: any) => {
    e.preventDefault();
    if (Id) {
      const updateRequest: UpdateUserMarketDataEntitlementRequest = {
        pendingId: Id,
      };
      dispatch(AdminEntitlementsActions.requestRejectUserMarketDataEntitlement(updateRequest));
      dispatch(AdminEntitlementsActions.clearCurrentItem());
    }
    // if (UserId && UserId !== undefined) {
    //   dispatch(AdminEntitlementsActions.requestExpireUserMarketDataEntitlement({ UserId }));
    //   dispatch(AdminEntitlementsActions.clearCurrentItem());
    // }
  };

  return (
    <>
      <div className="screen-footer screen-footer-justified">
        <div className="screen-footer-actions-secondary d-flex align-items-center justify-content-center mr-5">
          <Button className="btn btn-blend" onClick={e => handleClick(e)}>
            <i className="fal fa-arrow-left" />
            Go back
          </Button>
        </div>
        <div className="screen-footer-actions-primary">
          {isPending && isAdmin && (
            <>
              <div>
                If approving the request for Professional real-time quotes, make sure we have each applicable Professional Subscriber Agreement on file.
              </div>
              <div className="d-flex justify-content-flex-end align-items-center mt-2">
                <Button className="btn-reject" onClick={e => handleReject(e)}>
                  Reject
                </Button>

                <Button className="btn-primary" onClick={e => handleApproval(e)}>
                  Approve
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
