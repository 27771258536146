import React, { useEffect, useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Cell } from 'react-table';
import { useParentSizeContext, ColumnWithSorting, Loading } from '@tradingblock/components';
import { useDispatcher } from '../../../../data/global/hooks';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { AdminEntitlementsActions } from '../../../../data/global/actions/admin/EntitlementsActions';
import { EntitlementsSearchEntityWithKey } from '@tradingblock/types';
import { useEntitlementsData } from '../useEntitlements';
import { isFetchingEntitlements } from '../../../../data/global/selectors/admin/entitlementsSelector';
import { EntitlementsActionCell } from './table/EntitlementsActionCell';
import { EntitlementsItemCell } from './table/EntitlementsItemCell';
import { AdminEntitlementsDetail } from '../AdminEntitlementsDetail';
import { BlockTable } from '../../../shared/BlockTable';

const AdminEntitlementsColumns: ColumnWithSorting<EntitlementsSearchEntityWithKey>[] = [
  {
    Header: 'Account',
    accessor: v => v.AccountNumber,
    id: 'AccountNumber',
  },
  {
    Header: 'Account ID',
    accessor: v => v.UserId,
    id: 'UserId',
  },
  {
    Header: 'Name',
    accessor: v => v.FirstName && v.LastName,
    id: 'AccountName',
    Cell: ({ row }: Cell<any>) => {
      const { FirstName, MiddleInitial, Suffix, LastName } = row.original;
      if (!FirstName || !LastName) {
        return <></>;
      } else {
        return <>{`${FirstName} ${MiddleInitial || ''} ${LastName} ${Suffix || ''}`}</>;
      }
    },
  },
  {
    Header: 'Request',
    id: 'item',
    Cell: ({ row }: Cell<any>) => {
      const {
        ApprovingUserName,
        RequestedMarketDataEntitlementType,
        ApprovedUserMarketDataEntitlementType,
      } = row.original;
      return (
        <>
          <EntitlementsItemCell
            {...{ ApprovingUserName, RequestedMarketDataEntitlementType, ApprovedUserMarketDataEntitlementType }}
          />
        </>
      );
    },
  },
  {
    Header: 'Date',
    accessor: v => v.RequestDateTime,
    sortDescFirst: true,
    id: 'Date',
    Cell: ({ row }: Cell<any>) => {
      const dateVal = dayjs(row.original.RequestDateTime);
      if (dateVal && dateVal.isValid()) {
        return (
          <>
            {dateVal.format('MM/DD/YYYY')}
            <br />
            {dateVal.format('h:mm A')}
          </>
        );
      }
      return <></>;
    },
  },
  {
    Header: 'Action',
    id: 'action',
    className: 'text-center',
    Cell: ({ row }: Cell<any>) => {
      return (
        <div className="text-center">
          <EntitlementsActionCell {...row.original} />
        </div>
      );
    },
  },
];

export const EntitlementsTable: React.FC<{}> = () => {
  const { dispatch } = useDispatcher();
  const { data, total, ...filterData } = useEntitlementsData();
  const { page, pageSize } = filterData;

  const currentItemDetails = useStateSelector(s => s.private.entitlements.currentItem.details);
  const isUpdated = useStateSelector(s => s.private.entitlements.currentItem.isUpdated);
  const [isFetching, hasData] = useStateSelector(isFetchingEntitlements);
  const [requestSent, setRequestSent] = useState<boolean>(false);

  useEffect(() => {
    dispatch(AdminEntitlementsActions.requestEntitlementUpdates(filterData));
    setRequestSent(true);
  }, [isUpdated]);

  useEffect(() => {
    if (!hasData && !requestSent) {
      dispatch(AdminEntitlementsActions.requestEntitlementUpdates(filterData));
      setRequestSent(true);
    }

    const intervalId = setInterval(function() {
      dispatch(AdminEntitlementsActions.refreshEntitlements({ ...filterData }));
    }, 60 * 3 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [page, pageSize, isUpdated, dispatch, filterData, hasData]);

  const initialState = useMemo(() => {
    return {
      sortBy: [],
      pageSize: _.isUndefined(pageSize) ? 10 : pageSize,
      pageIndex: page || 0,
      columnOrder: [],
    };
  }, [pageSize, page]);

  const pageCount = useMemo(() => {
    if (pageSize) {
      return Math.ceil(total / pageSize);
    }
    return 0;
  }, [pageSize, total]);

  const onPage = useCallback(
    (page: number) => {
      setRequestSent(false);
      dispatch(AdminEntitlementsActions.setEntitlementsPage({ page }));
    },
    [dispatch]
  );

  const onPageSize = useCallback(
    (pageSize: number) => {
      setRequestSent(false);
      dispatch(AdminEntitlementsActions.setEntitlementsPageSize({ pageSize }));
    },
    [dispatch]
  );

  const getRowKey = useCallback((data: EntitlementsSearchEntityWithKey) => `${data.entityKey}`, []);

  if (!_.isEmpty(currentItemDetails)) {
    return <AdminEntitlementsDetail />;
  }

  return (
    <>
      {isFetching && <Loading />}
      <useParentSizeContext.Provider>
        {!isFetching && hasData && (
          <BlockTable
            tableKey="admin-entitlements-table"
            columns={AdminEntitlementsColumns}
            numColumns={6}
            initialState={initialState}
            data={data}
            loaded={true}
            getRowKey={getRowKey}
            hasPagination
            manualPagination
            pageCount={pageCount}
            onPage={onPage}
            onPageSize={onPageSize}
          />
        )}
      </useParentSizeContext.Provider>
    </>
  );
};
