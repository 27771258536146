import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import _ from 'lodash';
import {
  Transfer,
  AchRelationship,
  TransferRecipientBank,
  TransferBeneficiary,
  CashieringEntityTypes,
  CashieringEntityDetailTypes,
  CashieringDetailTransfer,
  CashieringDetailRelationship,
  CashieringEntityDetailDataTypes,
  TransferInstruction,
} from '@tradingblock/types';
import { getIdNumber } from '@tradingblock/api';
import { cashieringRequestKey, cashieringEntityKey } from '../../utilities/cashieringUtilities';
import { DataState } from '../../state';

export const getRequestKey = createSelector(
  (state: DataState) => state.private.cashiering.ui,
  uiState => cashieringRequestKey(uiState)
);

export const getEntityDetailKey = (type: CashieringEntityDetailTypes): CashieringEntityDetailDataTypes => {
  return type === 'relationships'
    ? 'relationships'
    : type === 'recipientBanks'
    ? 'recipientBanks'
    : type === 'wireInstructions'
    ? 'wireInstructions'
    : 'transfers';
};

export const getCashieringSearchEntities = createCachedSelector(
  (state: DataState) => state.private.cashiering.entities,
  (state: DataState) => state.private.cashiering.byQuery,
  (state: DataState) => state.private.cashiering.ui,
  getRequestKey,
  (entities, queryState, uiState, requestKey) => {
    const allValues = queryState[requestKey] ? queryState[requestKey].ids : [];
    // const { page, pageSize } = { ...{ page: 0, pageSize: 25 }, ...uiState };
    return (
      _(allValues)
        .map(id => entities[id])
        .filter(v => v !== undefined)
        // .drop(page * pageSize)
        // .take(pageSize)
        .value()
    );
  }
)(getRequestKey);

export const getCashieringSearchTotal = createCachedSelector(
  (state: DataState) => state.private.cashiering.entities,
  (state: DataState) => state.private.cashiering.byQuery,
  (state: DataState) => state.private.cashiering.ui,
  getRequestKey,
  (entities, queryState, uiState, requestKey) => {
    const total = queryState[requestKey] ? queryState[requestKey].total : 0;
    return total;
  }
)(getRequestKey);

export const getCashieringSearchTimeframe = createCachedSelector(
  (state: DataState) => state.private.cashiering.ui,
  getRequestKey,
  (uiState, requestKey) => {
    return { startDate: uiState.startDate, endDate: uiState.endDate };
  }
)(getRequestKey);
export const getCashieringSearchFilterData = createCachedSelector(
  (state: DataState) => state.private.cashiering.ui,
  getRequestKey,
  (uiState, requestKey) => {
    return { ...uiState };
  }
)(getRequestKey);

export const isFetchingCashieringSearchEntities = createCachedSelector(
  (state: DataState) => state.private.cashiering.byQuery,
  getRequestKey,
  (queryState, requestKey) => {
    const qState = queryState[requestKey];
    const isFetching = qState ? qState.isFetching : true;
    const hasData = qState && qState.ids.length > 0;
    return [isFetching, hasData];
  }
)(getRequestKey);

interface PrivateCashieringRequest<T = CashieringEntityTypes> {
  id: number | string;
  type: T;
}

interface PrivateCashieringEntityRequest extends PrivateCashieringRequest<CashieringEntityDetailTypes> {}

const searchEntitySelector = (state: DataState, { id, type }: PrivateCashieringEntityRequest) => {
  const idNum = getIdNumber(id);
  const key = cashieringEntityKey(type, idNum);
  return state.private.cashiering.entities[key] || undefined;
};

const searchEntityDataSelector = (state: DataState, { id, type }: PrivateCashieringEntityRequest) => {
  const idNum = getIdNumber(id);
  const key = cashieringEntityKey(type, idNum);
  const entity = state.private.cashiering.entities[key];
  if (type === 'relationships') {
    return entity && entity.relationship;
  }
  if (type === 'transfers') {
    return entity && entity.transfer;
  }
  return undefined;
};

const baseEntitySelector = (state: DataState, { id, type }: PrivateCashieringRequest) => {
  const idNum = getIdNumber(id);
  return state.private.cashiering[type][idNum] || undefined;
};

const baseEntityAccountIdSelector = (state: DataState, { id, type }: PrivateCashieringRequest) => {
  const idNum = getIdNumber(id);
  const defaultVal = -1;
  if (type === 'relationships' || type === 'transfers' || type === 'recipientBanks' || type === 'beneficiaries') {
    const typeData = state.private.cashiering[type][idNum];
    return typeData && typeData.data ? typeData.data.accountId : defaultVal;
  }
  return defaultVal;
};

const transferById = (state: DataState, id: number | string) => baseEntitySelector(state, { id, type: 'transfers' });
const transferDetailsById = (state: DataState, id: number | string) =>
  baseEntitySelector(state, { id, type: 'transferDetails' });
const relationshipById = (state: DataState, id: number | string) =>
  baseEntitySelector(state, { id, type: 'relationships' });
const relationshipDetailsById = (state: DataState, id: number | string) =>
  baseEntitySelector(state, { id, type: 'relationshipDetails' });
const recipientBankById = (state: DataState, id: number | string) =>
  baseEntitySelector(state, { id, type: 'recipientBanks' });
const beneficiaryById = (state: DataState, id: number | string) =>
  baseEntitySelector(state, { id, type: 'beneficiaries' });
const wireInstructionsById = (state: DataState, id: number | string) =>
  baseEntitySelector(state, { id, type: 'wireInstructions' });
const wireInstructionsDetailsById = (state: DataState, id: number | string) =>
  baseEntitySelector(state, { id, type: 'wireInstructionsDetails' });

const transferData = createCachedSelector(transferById, item => (item ? (item.data as Transfer) : undefined))(
  (_, id) => `transfer-${id}`
);
const relationshipData = createCachedSelector(relationshipById, item =>
  item ? (item.data as AchRelationship) : undefined
)((_, id) => `relationship-${id}`);

const recipientBankData = createCachedSelector(recipientBankById, item =>
  item ? (item.data as TransferRecipientBank) : undefined
)((_, id) => `recipientBank-${id}`);

const wireInstructionsData = createCachedSelector(wireInstructionsById, item =>
  item ? (item.data as TransferInstruction) : undefined
)((_, id) => `wireInstructions-${id}`);

export const transferSelectors = {
  isFetching: createCachedSelector(transferById, item => (item ? item.isFetching : false))(
    (_, id) => `transfer-fetching-${id}`
  ),
  data: transferData,
  details: createCachedSelector(transferDetailsById, item =>
    item ? (item.data as CashieringDetailTransfer) : undefined
  )((_, id) => `transferDetails-${id}`),
  status: createCachedSelector(transferData, item => (item ? item.state : undefined))(
    (_, id) => `transfer-state-${id}`
  ),
};

export const relationshipSelectors = {
  isFetching: createCachedSelector(relationshipById, item => (item ? item.isFetching : false))(
    (_, id) => `relationship-fetching-${id}`
  ),
  data: relationshipData,
  details: createCachedSelector(relationshipDetailsById, item =>
    item ? (item.data as CashieringDetailRelationship) : undefined
  )((_, id) => `relationshipDetails-${id}`),
  status: createCachedSelector(relationshipData, item => (item ? item.state : undefined))(
    (_, id) => `relationship-state-${id}`
  ),
};

export const recipientBankSelectors = {
  isFetching: createCachedSelector(recipientBankById, item => (item ? item.isFetching : false))(
    (_, id) => `recipientBank-fetching-${id}`
  ),
  data: createCachedSelector(recipientBankById, item => (item ? (item.data as TransferRecipientBank) : undefined))(
    (_, id) => `recipientBank-${id}`
  ),
  note: createCachedSelector(
    (state: DataState, req: PrivateCashieringEntityRequest) => searchEntitySelector(state, req),
    (state: DataState, req: PrivateCashieringEntityRequest) => {
      const { id, type } = req;
      const detailType = getEntityDetailKey(type);
      const idNum = getIdNumber(id);
      const entity = state.private.cashiering[detailType][idNum];
      return entity && entity.data ? entity.data.note : undefined;
    },
    (searchEntity, entityNote) => {
      return searchEntity && searchEntity.note ? searchEntity.note : entityNote ? entityNote : undefined;
    }
  )((_: DataState, { type, id }: PrivateCashieringEntityRequest) => `entity-note-${type}-id-${id}`),
  adminNote: createCachedSelector(
    (state: DataState, req: PrivateCashieringEntityRequest) => searchEntitySelector(state, req),
    (state: DataState, req: PrivateCashieringEntityRequest) => {
      const { id, type } = req;
      const detailType = getEntityDetailKey(type);
      const idNum = getIdNumber(id);
      const entity = state.private.cashiering[detailType][idNum];
      return entity && entity.data ? entity.data.adminNote : undefined;
    },
    (searchEntity, entityAdminNote) => {
      return searchEntity && searchEntity.adminNote
        ? searchEntity.adminNote
        : entityAdminNote
        ? entityAdminNote
        : undefined;
    }
  )((_: DataState, { type, id }: PrivateCashieringEntityRequest) => `entity-adminNote-${type}-id-${id}`),
};

export const wireInstructionSelectors = {
  isFetching: createCachedSelector(wireInstructionsById, item => (item ? item.isFetching : false))(
    (_, id) => `wireInstructions-fetching-${id}`
  ),
  data: createCachedSelector(wireInstructionsById, item => (item ? (item.data as TransferInstruction) : undefined))(
    (_, id) => `wireInstructions-${id}`
  ),
  details: createCachedSelector(wireInstructionsDetailsById, item =>
    item ? (item.data as CashieringDetailTransfer) : undefined
  )((_, id) => `wireInstructionDetails-${id}`),
  note: createCachedSelector(
    (state: DataState, req: PrivateCashieringEntityRequest) => searchEntitySelector(state, req),
    (state: DataState, req: PrivateCashieringEntityRequest) => {
      const { id, type } = req;
      const detailType = getEntityDetailKey(type);
      const idNum = getIdNumber(id);
      const entity = state.private.cashiering[detailType][idNum];
      return entity && entity.data ? entity.data.note : undefined;
    },
    (searchEntity, entityNote) => {
      return searchEntity && searchEntity.note ? searchEntity.note : entityNote ? entityNote : undefined;
    }
  )((_: DataState, { type, id }: PrivateCashieringEntityRequest) => `entity-note-${type}-id-${id}`),
  adminNote: createCachedSelector(
    (state: DataState, req: PrivateCashieringEntityRequest) => searchEntitySelector(state, req),
    (state: DataState, req: PrivateCashieringEntityRequest) => {
      const { id, type } = req;
      const detailType = getEntityDetailKey(type);
      const idNum = getIdNumber(id);
      const entity = state.private.cashiering[detailType][idNum];
      return entity && entity.data ? entity.data.adminNote : undefined;
    },
    (searchEntity, entityAdminNote) => {
      return searchEntity && searchEntity.adminNote
        ? searchEntity.adminNote
        : entityAdminNote
        ? entityAdminNote
        : undefined;
    }
  )((_: DataState, { type, id }: PrivateCashieringEntityRequest) => `entity-adminNote-${type}-id-${id}`),
};

export const beneficiarySelectors = {
  isFetching: createCachedSelector(beneficiaryById, item => (item ? item.isFetching : false))(
    (_, id) => `beneficiary-fetching-${id}`
  ),
  data: createCachedSelector(beneficiaryById, item => (item ? (item.data as TransferBeneficiary) : undefined))(
    (_, id) => `beneficiary-${id}`
  ),
  all: createCachedSelector(beneficiaryById, item =>
    item ? ((item.data as unknown) as TransferBeneficiary[]) : undefined
  )((_, id) => `beneficiary-${id}`),
};

export const entitySelector = {
  searchEntityData: searchEntityDataSelector,
  detailEntityData: baseEntitySelector,
  info: createCachedSelector(baseEntitySelector, item => {
    return {
      isFetching: item ? item.isFetching : false,
      isErrored: item && item.isErrored ? true : false,
      isSaving: item ? _.get(item, 'isSaving', false) : false,
      error: item && item.isErrored ? item.error : undefined,
    };
  })((_, { type, id }) => `entity-${type}-${id}`),
  accountId: createCachedSelector([baseEntityAccountIdSelector], id => id)(
    (_, { type, id }) => `entity-${type}-id-${id}`
  ),
  status: createCachedSelector(
    [
      (s: DataState, req: { id: number; type: CashieringEntityTypes }) => transferData(s, req.id),
      (s: DataState, req: { id: number; type: CashieringEntityTypes }) => relationshipData(s, req.id),
      (s: DataState, req: { id: number; type: CashieringEntityTypes }) => recipientBankData(s, req.id),
      (s: DataState, req: { id: number; type: CashieringEntityTypes }) => wireInstructionsData(s, req.id),
      (s: DataState, req: { id: number; type: CashieringEntityTypes }) => req.type,
    ],
    (transfer, relationship, recipientBank, wireInstructions, type) =>
      type === 'relationships' && relationship
        ? relationship.state
        : type === 'transfers' && transfer
        ? transfer.state
        : type === 'recipientBanks' && recipientBank
        ? recipientBank.state
        : type === 'wireInstructions' && wireInstructions
        ? wireInstructions.state
        : undefined
  )((_, { type, id }) => `entity-state-${type}-id-${id}`),
  entityNote: createCachedSelector(
    (state: DataState, req: PrivateCashieringEntityRequest) => searchEntitySelector(state, req),
    (state: DataState, req: PrivateCashieringEntityRequest) => {
      const { id, type } = req;
      const detailType = getEntityDetailKey(type);
      const idNum = getIdNumber(id);
      const entity = state.private.cashiering[detailType][idNum];
      return entity && entity.data ? entity.data.note : undefined;
    },
    (searchEntity, entityNote) => {
      return searchEntity && searchEntity.note ? searchEntity.note : entityNote ? entityNote : undefined;
    }
  )((_: DataState, { type, id }: PrivateCashieringEntityRequest) => `entity-note-${type}-id-${id}`),
  entityAdminNote: createCachedSelector(
    (state: DataState, req: PrivateCashieringEntityRequest) => searchEntitySelector(state, req),
    (state: DataState, req: PrivateCashieringEntityRequest) => {
      const { id, type } = req;
      const detailType = getEntityDetailKey(type);
      const idNum = getIdNumber(id);
      const entity = state.private.cashiering[detailType][idNum];
      return entity && entity.data ? entity.data.adminNote : undefined;
    },
    (searchEntity, entityAdminNote) => {
      return searchEntity && searchEntity.adminNote
        ? searchEntity.adminNote
        : entityAdminNote
        ? entityAdminNote
        : undefined;
    }
  )((_: DataState, { type, id }: PrivateCashieringEntityRequest) => `entity-adminNote-${type}-id-${id}`),
};

export const CashieringSearchRequestSelector = createCachedSelector(
  (d: DataState) => d.private.cashiering.ui,
  (d: DataState) => cashieringRequestKey(d.private.cashiering.ui),
  (request, key) => {
    return { request, key };
  }
)((d: DataState) => cashieringRequestKey(d.private.cashiering.ui));
