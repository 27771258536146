import { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { useStateSelector, blocksSelectors } from './../../data/global/dataSelectors';
import { IBlockDataState } from '@tradingblock/types';
import { useDispatcher } from '../../data/global/hooks';
import { useBlockState, useBlockSelector } from './BlockState';
import { DataState } from '../../data/global/state';
import { areArraysEqual } from '../../utilities/data';

export function useBlockSubscriptions() {
  const { dispatcher } = useDispatcher();

  const blockId = useBlockState('blockId');

  const setQuotes = useCallback(
    (symbols: string | string[]) => {
      const symbolValues = _.isArray(symbols) ? symbols : [symbols];
      dispatcher.block.setQuoteSubscription(blockId, symbolValues);
    },
    [blockId, dispatcher]
  );
  return {
    setQuotes,
  };
}

export function useQuoteListener(symbols: string | string[] | undefined) {
  const blockId = useBlockState('blockId');
  const { setQuotes } = useBlockSubscriptions();
  const blockSymbols = useStateSelector(s => blocksSelectors.getBlockQuoteSubscriptions(s, blockId));
  useEffect(() => {
    const symbolVals = symbols ? (_.isArray(symbols) ? symbols : [symbols]) : undefined;
    if (symbolVals && !areArraysEqual(symbolVals, blockSymbols)) {
      setQuotes(symbolVals);
    }
  }, [symbols, blockSymbols, setQuotes]);
  return { local: symbols, global: blockSymbols };
}

export function useBlockQuoteListener<T extends IBlockDataState>(selector: (state: T) => string | string[] | undefined) {
  const symbols = useBlockSelector(selector);
  return useQuoteListener(symbols);
}

export function useGlobalStateQuoteListener(selector: (state: DataState) => string | string[] | undefined) {
  const symbols = useStateSelector(selector);
  return useQuoteListener(symbols);
}
