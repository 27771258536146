import React, { useCallback, } from 'react';
import { BlockType } from '@tradingblock/types';
import { BlockBody, EmptyBlockContent, Loading } from '@tradingblock/components';
import { FavoritesContent } from './FavoritesContent';
import { Block } from '../../components/blocks/Block';
import { useQuoteSymbolSubscriptionFromGlobalState } from '../../hooks/useQuoteData';
import { getFavoriteSymbols, useStateSelector } from '../../data/global/dataSelectors';
import { getBlockProps } from '../../utilities/block';
import { FavoritesHeader } from './FavoritesHeader';
import { useBlockStateSelector } from '../../components/blocks/BlockState';
import { DataState } from '../../data/global/state';
import { initialPositionsAreLoading } from '../../data/global/selectors/positionSelector';
import { useFavoriteSettings } from './useFavoriteSettings';

export const FavoritesBlock: React.FunctionComponent<{}> = () => {
  useQuoteSymbolSubscriptionFromGlobalState(getFavoriteSymbols);
  const isLoaded = useStateSelector(state => state.blocks.isFetchingInitial === false);
  const loaded = useBlockStateSelector(s => s.data.isLoaded === true);
  const dataAndBlockLoaded = isLoaded === true && loaded === true;

  const isFetchingSelector = useCallback((state: DataState) => {
    const { loading } = initialPositionsAreLoading(state);
    return loading;
  }, []);

  const { current, definitions } = useFavoriteSettings();

  return (
    <Block
      {...getBlockProps(BlockType.Favorites, undefined)}
      header={<FavoritesHeader />}
      isFetchingSelector={isFetchingSelector}
      settings={current}
      isRefreshable={true}
      settingFields={definitions}
    >
      <BlockBody>
        {dataAndBlockLoaded && <FavoritesContent />}
        {!dataAndBlockLoaded && <EmptyBlockContent>{<Loading />}</EmptyBlockContent>}
      </BlockBody>
    </Block>
  );
};
