import { useMemo } from 'react';
import { ApplicationStep, ApplicationType } from '@tradingblock/types';
import { AdminPendingApplicationActions } from '../../../data/global/actions/admin/AdminApplicationActions';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { useDispatcher } from '../../../data/global/hooks';
import {
  getApplicationSearchEntities,
  getApplicationSearchFilterData,
  getApplicationSearchTotal,
} from '../../../data/global/selectors/admin/applicationsSelector';

export const useApplicationsData = () => {
  const data = useStateSelector(getApplicationSearchEntities);
  const total = useStateSelector(getApplicationSearchTotal);

  const { ...filterData } = useStateSelector(getApplicationSearchFilterData);

  return {
    data,
    total,
    ...filterData,
  };
};

export const useApplicationsActions = () => {
  const { dispatch } = useDispatcher();
  return useMemo(
    () => ({
      setSearch: (search: string) => dispatch(AdminPendingApplicationActions.setAppSearch({ search })),
      setStep: (steps: ApplicationStep[]) => dispatch(AdminPendingApplicationActions.setAppSearchStep({ steps })),
      setType: (types: ApplicationType[]) => dispatch(AdminPendingApplicationActions.setAppSearchType({ types })),
    }),
    [dispatch]
  );
};
