import { useEffect, useState, useCallback } from 'react';
import { LocalStorageProvider } from '@tradingblock/storage';
import { LocalStorageKey } from '../constants';
import { Config } from '../config';

const localStorageProvider = LocalStorageProvider({});

export const useReduxConfiguration = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [enableRedux, setEnableRedux] = useState<boolean>(Config.isProduction ? false : true);
  useEffect(() => {
    localStorageProvider.get<boolean>(LocalStorageKey.enableRedux).then(res => {
      if (res !== undefined) {
        setEnableRedux(res);
      }
      setLoaded(true);
    });
  }, []);
  const set = useCallback((val: boolean) => {
    localStorageProvider.save(LocalStorageKey.enableRedux, val).then(() => {
      setEnableRedux(val);
    });
  }, []);
  return {
    enableRedux,
    loaded,
    set,
  };
};
