import React, { useCallback, useMemo } from 'react';
import { BlockTabs } from '@tradingblock/components';
import { TabOptions } from '@tradingblock/types';
import { useBlockMetadata, useBlockActions } from './BlockState';

export const BlockTabWell: React.FunctionComponent = () => {
  const { title, tabs } = useBlockMetadata();
  const { setTab } = useBlockActions();

  const hasTabs = useMemo(() => tabs && tabs.length > 0, [tabs]);
  const onTabClick = useCallback((tab: TabOptions) => setTab(tab), [setTab]);
  return (
    <>
      {!hasTabs && title}
      {hasTabs && tabs && <BlockTabs tabs={tabs} onTabClick={onTabClick} activeClass={'active'} />}
    </>
  );
};
