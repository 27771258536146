import React, { useCallback } from 'react';
import { Field, FieldProps, FormikErrors, useFormikContext } from 'formik';
import _ from 'lodash';
import {
  TextboxInput,
  SelectDropdownInput,
  CheckboxInput,
  BaseInputProps,
  InputOption,
} from '@tradingblock/components';
import { DashboardSymbolPicker } from '../dashboard/DashboardSymbolPicker';
import { OrderDefaults, UiSettings } from '@tradingblock/types';

export interface BasicSettingsField extends Partial<BaseInputProps> {
  type?: 'boolean' | 'toggle' | 'string' | 'number' | 'symbolPicker';
  toggleOpposite?: boolean;
  options?: string[] | InputOption[];
}
export type SettingsField = BasicSettingsField | RadioSettingsField | ChoiceSettingsField;
export type BlockSettingsField = SettingsFields<any, SettingsField & { global: boolean }>;
export interface ChoiceSettingsField<T = string | number | any> extends Partial<BaseInputProps> {
  type: 'choice';
  values: { value: T; label?: string }[];
}
type stringFunc = (value: boolean) => string;
export interface RadioSettingsField extends Partial<BaseInputProps> {
  type: 'radio';
  fieldLabel: string;
  yesOptionLabel: string;
  noOptionLabel: string;
  fieldNote?: string | stringFunc;
}
export type SettingsFields<T = any, F = SettingsField> = {
  [K in keyof T]?: F;
};

interface GenericFieldProps {
  name: string;
  settings: SettingsField;
  value: any;
  parentFieldName?: string;
  usePortalForSelect?: boolean;
}

export const GenericField: React.FC<GenericFieldProps> = ({ name, value, settings, usePortalForSelect }) => {
  const { setFieldValue, errors } = useFormikContext();
  // As we add more validation on forms using this component we will need to update the type below.
  const formikErrors = errors as FormikErrors<{ block: OrderDefaults, global: OrderDefaults, } & UiSettings>;
  const { type, ...fieldprops } = settings;
  const toggleOpposite = _.get(settings, 'toggleOpposite', false);

  const options: string[] | InputOption[] | undefined = _.get(settings, 'options', undefined);
  const onChange = useCallback((e: React.ChangeEvent<HTMLElement> | undefined, value?: any) => {
    const nextValue = toggleOpposite ? !value : value;
    setFieldValue(name, nextValue);
  }, []);

  let label: any;

  if (
    (settings.defaultLabelId && settings.name === 'block.etfCoverage') ||
    settings.name === 'global.etfCoverage' ||
    settings.name === 'block.ipos' ||
    settings.name === 'global.ipos' ||
    settings.name === 'block.corporateActionsDividends' ||
    settings.name === 'global.corporateActionsDividends'
  ) {
    label = settings.defaultLabelId;
  } else {
    label = settings.label;
  }

  const blockDefaultQuantityForOptionsAndStrategiesId = 'block.defaultQuantityForOptionsAndStrategies';
  const blockDefaultQuantityForSharesId = 'block.defaultQuantityForShares';
  const globalDefaultQuantityForOptionsAndStrategiesId = 'global.defaultQuantityForOptionsAndStrategies';
  const globalDefaultQuantityForSharesId = 'global.defaultQuantityForShares';
  const cashieringPollingIntervalId =  'cashieringPollingInterval';
  const cashieringPendingReminderIntervalId = 'cashieringPendingReminderInterval';
  const cashieringPendingReminderMaxAttemptsId = 'cashieringPendingReminderMaxAttempts';
  const feedSyncIntervalMsId = 'feedSyncIntervalMs';
  const gridColumnsLargeId = 'gridColumnsLarge';
  const gridColumnsMediumId = 'gridColumnsMedium';
  const gridColumnsSmallId = 'gridColumnsSmall';
  const widthId = 'width';
  
  const getFieldIdWithError = (): string => {
    if (
      settings.name === blockDefaultQuantityForOptionsAndStrategiesId &&
      formikErrors &&
      formikErrors.block &&
      formikErrors.block.defaultQuantityForOptionsAndStrategies
    ) {
        return blockDefaultQuantityForOptionsAndStrategiesId;
      }

    if (
      settings.name === blockDefaultQuantityForSharesId &&
      formikErrors &&
      formikErrors.block &&
      formikErrors.block.defaultQuantityForShares
    ) {
        return blockDefaultQuantityForSharesId;
      }

    if (
      settings.name === globalDefaultQuantityForOptionsAndStrategiesId &&
      formikErrors &&
      formikErrors.global &&
      formikErrors.global.defaultQuantityForOptionsAndStrategies
    ) {
        return globalDefaultQuantityForOptionsAndStrategiesId;
      }

    if (
      settings.name === globalDefaultQuantityForSharesId &&
      formikErrors &&
      formikErrors.global &&
      formikErrors.global.defaultQuantityForShares
    ) {
        return globalDefaultQuantityForSharesId;
      }

    if (
      settings.name === cashieringPollingIntervalId &&
      formikErrors &&
      formikErrors.cashieringPollingInterval
    ) {
      return cashieringPollingIntervalId;
    }

    if (
      settings.name === cashieringPendingReminderIntervalId &&
      formikErrors &&
      formikErrors.cashieringPendingReminderInterval
    ) {
      return cashieringPendingReminderIntervalId;
    }

    if (
      settings.name === cashieringPendingReminderMaxAttemptsId &&
      formikErrors &&
      formikErrors.cashieringPendingReminderMaxAttempts
    ) {
      return cashieringPendingReminderMaxAttemptsId;
    }

    if (
      settings.name === feedSyncIntervalMsId &&
      formikErrors &&
      formikErrors.feedSyncIntervalMs
    ) {
      return feedSyncIntervalMsId;
    }

    if (
      settings.name === gridColumnsLargeId &&
      formikErrors &&
      formikErrors.gridColumnsLarge
    ) {
      return gridColumnsLargeId;
    }

    if (
      settings.name === gridColumnsMediumId &&
      formikErrors &&
      formikErrors.gridColumnsMedium
    ) {
      return gridColumnsMediumId;
    }

    if (
      settings.name === gridColumnsSmallId &&
      formikErrors &&
      formikErrors.gridColumnsSmall
    ) {
      return gridColumnsSmallId;
    }

    if (
      settings.name === widthId &&
      formikErrors &&
      formikErrors.width
    ) {
      return widthId;
    }

    return ''
  }

  const fieldIdWithError = getFieldIdWithError();

  return (
    <Field name={name}>
      {({ form }: FieldProps) => {
        let props = {
          ...fieldprops,
          label: label || _.startCase(name),
          onchange: onChange,
        };

        return (
          <>
            {options && (
              <SelectDropdownInput
                options={_.every(options, o => _.isObject(o)) ? options : _.map(options, o => ({ value: o }))}
                menuPortalTargetId={usePortalForSelect ? 'portal-root' : undefined}
                value={value}
                {...props}
              />
            )}
            {settings.type === 'boolean' && <CheckboxInput type="checkbox" checked={!!value} {...props} />}
            {settings.type === 'toggle' && <CheckboxInput checked={!!value} {...props} />}
            {settings.type === 'string' && !settings.options && <TextboxInput value={value} {...props} />}
            {settings.type === 'number' && !settings.options && (
              <>
                <TextboxInput value={value} {...props} type={settings.type} error={fieldIdWithError} />
                {
                  settings.name === blockDefaultQuantityForOptionsAndStrategiesId &&
                  formikErrors &&
                  formikErrors.block &&
                  formikErrors.block.defaultQuantityForOptionsAndStrategies &&
                  <span className="error">
                    {formikErrors.block.defaultQuantityForOptionsAndStrategies}
                  </span>
                }
                {
                  settings.name === globalDefaultQuantityForOptionsAndStrategiesId &&
                  formikErrors &&
                  formikErrors.global &&
                  formikErrors.global.defaultQuantityForOptionsAndStrategies &&
                  <span className="error">
                    {formikErrors.global.defaultQuantityForOptionsAndStrategies}
                  </span>
                }
                {
                  settings.name === blockDefaultQuantityForSharesId &&
                  formikErrors &&
                  formikErrors.block &&
                  formikErrors.block.defaultQuantityForShares &&
                  <span className="error">
                    {formikErrors.block.defaultQuantityForShares}
                  </span>
                }
                {
                  settings.name === globalDefaultQuantityForSharesId &&
                  formikErrors &&
                  formikErrors.global &&
                  formikErrors.global.defaultQuantityForShares &&
                  <span className="error">
                    {formikErrors.global.defaultQuantityForShares}
                  </span>
                }
                {
                  settings.name === cashieringPollingIntervalId &&
                  formikErrors &&
                  formikErrors.cashieringPollingInterval &&
                  <div className="error mb-1rem">
                    {formikErrors.cashieringPollingInterval}
                  </div>
                }
                {
                  settings.name === cashieringPendingReminderIntervalId &&
                  formikErrors &&
                  formikErrors.cashieringPendingReminderInterval &&
                  <div className="error mb-1rem">
                    {formikErrors.cashieringPendingReminderInterval}
                  </div>
                }
                {
                  settings.name === cashieringPendingReminderMaxAttemptsId &&
                  formikErrors &&
                  formikErrors.cashieringPendingReminderMaxAttempts &&
                  <div className="error mb-1rem">
                    {formikErrors.cashieringPendingReminderMaxAttempts}
                  </div>
                }
                {
                  settings.name === feedSyncIntervalMsId &&
                  formikErrors &&
                  formikErrors.feedSyncIntervalMs &&
                  <span className="error">
                    {formikErrors.feedSyncIntervalMs}
                  </span>
                }
                {
                  settings.name === gridColumnsLargeId &&
                  formikErrors &&
                  formikErrors.gridColumnsLarge &&
                  <div className="error mb-1rem">
                    {formikErrors.gridColumnsLarge}
                  </div>
                }
                {
                  settings.name === gridColumnsMediumId &&
                  formikErrors &&
                  formikErrors.gridColumnsMedium &&
                  <div className="error mb-1rem">
                    {formikErrors.gridColumnsMedium}
                  </div>
                }
                {
                  settings.name === gridColumnsSmallId &&
                  formikErrors &&
                  formikErrors.gridColumnsSmall &&
                  <div className="error mb-1rem">
                    {formikErrors.gridColumnsSmall}
                  </div>
                }
                {
                  settings.name === widthId &&
                  formikErrors &&
                  formikErrors.width &&
                  <div className="error mb-1rem">
                    {formikErrors.width}
                  </div>
                }
              </>
            )}
            {settings.type === 'symbolPicker' && <DashboardSymbolPicker {...props} />}
          </>
        );
      }}
    </Field>
  );
};
