import React from 'react';
import { PreviewActions, useOrderPreviewOrder } from './useOrderPreview';
import PriceComponent from '../PriceComponent';

export const PreviewStopPrice: React.FC<{ action: PreviewActions }> = ({ action }) => {
  const { order, changeStopPrice, changeReplaceStopPrice, replaceStopPrice } = useOrderPreviewOrder(action);

  const handleChangeStopPrice = (value: number | null) => {
    if (action === 'replace') {
      changeReplaceStopPrice(value || 0);
      changeStopPrice(value || 0);
    } else {
      changeStopPrice(value || 0);
    }
  };

  const stopValue = action === 'replace' ? replaceStopPrice || order.Stop : order.Stop || 0;

  return (
    <>
      {order && (
        <PriceComponent
          onChange={handleChangeStopPrice}
          defaultValue={stopValue ? Math.abs(stopValue) : 0}
          value={stopValue ? Math.abs(stopValue) : 0}
        />
      )}
    </>
  );
};
