import React from 'react';
import { useStateSelector, accountIdSelector } from '../../data/global/dataSelectors';
import { useAccountApi } from '../../context/Api';
import { useDispatcher } from '../../data/global/hooks';
import { ActivitySummaryTable } from './components/ActivitySummaryTable';
import useMount from 'react-use/lib/useMount';
import _ from 'lodash';
import { ActivitySummaryTotals } from './ActivitySummaryTotals';
import { PerformanceSelectors } from '../../data/global/selectors/performanceSelectors';
import { SignedNumber } from '@tradingblock/components';

const ActivitySummaryView: React.FunctionComponent<{}> = () => {
  const { total } = useStateSelector(PerformanceSelectors.totals.tradedToday);
  return (
    <>
      {/* <TimeframeContainer /> */}
      <div style={{ paddingBottom: '10px' }} className="block-section-fixed">
        <div className="row justify-content-between">
          <div className="col-auto">
            Symbols traded <strong>today</strong>: Daily P/L
            <SignedNumber value={total} format currency hideIcon />
          </div>
          <div className="col-auto align-self-end">
            <ActivitySummaryTotals />
          </div>
        </div>
      </div>

      <ActivitySummaryTable />
    </>
  );
};

export const ActivitySummary: React.FunctionComponent<{}> = () => {
  useMount(() => {
    //loadPnL(api, dispatcher)(currId as number, { accountId: currId as number });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });
  return <ActivitySummaryView />;
};
