import React, { useMemo } from 'react';
import { DetailField, DetailFields } from '../../../../components/basic/DetailFields';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { DefaultAccountUpdateDetails, DefaultUserUpdateAccountDetails } from '@tradingblock/types';
import { Loading } from '@tradingblock/components';
import {
  formatTaxBracket,
  formatIncome,
  formatYearsTrading,
  formatTradesPerYear,
} from '../../../../utilities/accountManagement';

export const EntitlementRequestFinancialDetails = () => {
  const details = useStateSelector(s => s.private.entitlements.currentItem.details);
  const accountInfo = useStateSelector(s => s.private.entitlements.currentItem.accountInfo);
  const isFetching = useStateSelector(s => s.private.entitlements.isFetching);
  const isAccountFetching = useStateSelector(s => s.private.entitlements.currentItem.isAccountFetching);

  const { characteristics, annualIncome, liquidNetWorth, totalNetWorth, investmentObjectiveType, experience } =
    accountInfo || DefaultUserUpdateAccountDetails;

  const { risk, bracket, horizon, liquidity } = characteristics;

  const { bonds, options, stocks, commodities, margins } = experience;

  const financialFields = useMemo(() => {
    let fieldList: DetailField[] = [];
    if (details && accountInfo && !isFetching && !isAccountFetching) {
      fieldList = [
        ...fieldList,

        {
          Value: () => <> Financial Information &amp; Trade Experience</>,
          className: 'fields-title',
        },

        {
          Value: () => null,
        },

        {
          Value: () => (
            <>
              <span className="prop-title">Annual Income</span>
              <span className="mute">{annualIncome ? formatIncome(annualIncome) : 'Annual Income Not Found'}</span>
              <br />
              <br />
              <span className="prop-title">Total Net Worth</span>
              <span className="mute">{totalNetWorth ? formatIncome(totalNetWorth) : 'Total Net Worth Not Found'}</span>
              <br />
              <br />
              <span className="prop-title">Liquid Net Worth</span>
              <span className="mute">
                {liquidNetWorth ? formatIncome(liquidNetWorth) : 'Liquid Net Worth Not Found'}
              </span>
              <br />
              <br />
              <span className="prop-title">Investment Objective</span>
              <span className="mute">
                {investmentObjectiveType
                  ? investmentObjectiveType.replace(/([A-Z])/g, ' $1').trim()
                  : 'Investment Objective Type Not Found'}
              </span>
              <br />
              <br />
              <span className="prop-title">Risk Tolerance</span>
              <span className="mute">{risk}</span>
              <br />
              <br />
              <span className="prop-title">Bracket</span>
              <span className="mute">{bracket ? formatTaxBracket(bracket) : 'Tax Bracket Not Found'}</span>
              <br />
              <br />
              <span className="prop-title">Horizion</span>
              <span className="mute">{horizon}</span>
              <br />
              <br />
              <span className="prop-title">Liquidity</span>
              <span className="mute">
                {liquidity ? liquidity.replace(/([A-Z])/g, ' $1').trim() : 'Liquidity Needs Not Found'}
              </span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span>
                <span className="prop-title">Bonds</span>
                <span className="txt-sm">Years Trading:</span>{' '}
                <span className="mute txt-sm">{bonds.years ? formatYearsTrading(bonds.years) : 'N/A'}</span>{' '}
                {bonds.tradesPerYear && (
                  <>
                    <br />
                    <span className="txt-sm">Trades per Year:</span>{' '}
                    <span className="mute txt-sm">
                      {bonds.tradesPerYear ? formatTradesPerYear(bonds.tradesPerYear) : 'N/A'}
                    </span>
                  </>
                )}
                <br />
                <br />
                <span className="prop-title">Options</span>
                <span className="txt-sm">Years Trading:</span>{' '}
                <span className="mute txt-sm">{options.years ? formatYearsTrading(options.years) : 'N/A'}</span>{' '}
                {options.tradesPerYear && (
                  <>
                    <br />
                    <span className="txt-sm">Trades per Year:</span>{' '}
                    <span className="mute txt-sm">
                      {options.tradesPerYear ? formatTradesPerYear(options.tradesPerYear) : 'N/A'}
                    </span>
                  </>
                )}
                <br />
                <br />
                <span className="prop-title">Stocks</span>
                <span className="txt-sm">Years Trading:</span>{' '}
                <span className="mute txt-sm">{stocks.years ? formatYearsTrading(stocks.years) : 'N/A'}</span>{' '}
                {stocks.tradesPerYear && (
                  <>
                    <br />
                    <span className="txt-sm">Trades per Year:</span>{' '}
                    <span className="mute txt-sm">
                      {stocks.tradesPerYear ? formatTradesPerYear(stocks.tradesPerYear) : 'N/A'}
                    </span>
                  </>
                )}
                <br />
                <br />
                <span className="prop-title">Commodities</span>
                <span className="txt-sm">Years Trading:</span>{' '}
                <span className="mute txt-sm">{commodities.years ? formatYearsTrading(commodities.years) : 'N/A'}</span>{' '}
                {commodities.tradesPerYear && (
                  <>
                    <br />
                    <span className="txt-sm">Trades per Year:</span>{' '}
                    <span className="mute txt-sm">
                      {commodities.tradesPerYear ? formatTradesPerYear(commodities.tradesPerYear) : 'N/A'}
                    </span>{' '}
                  </>
                )}
                <br />
                <br />
                <span className="prop-title">Margins</span>
                <span className="txt-sm">Years Trading:</span>{' '}
                <span className="mute txt-sm">{margins.years ? formatYearsTrading(margins.years) : 'N/A'}</span>
                {margins.tradesPerYear && (
                  <>
                    <br />
                    <span className="txt-sm">Trades per Year:</span>{' '}
                    <span className="mute txt-sm">
                      {margins.tradesPerYear ? formatTradesPerYear(margins.tradesPerYear) : 'N/A'}
                    </span>
                  </>
                )}
              </span>
            </>
          ),
        },
      ];
    }

    return fieldList;
  }, [characteristics, experience, accountInfo, isFetching, isAccountFetching]);

  if (isFetching || isAccountFetching) return <Loading />;

  return (
    <div className="screen-body-section">
      <DetailFields fieldList={financialFields} />
    </div>
  );
};
