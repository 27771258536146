import React from 'react';
import _ from 'lodash';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { relationshipSelectors } from '../../../../data/global/selectors/admin/cashieringSelectors';
import { CustomerAccountDetails } from './CustomerAccountDetails';

export const CashieringRelationshipDetails: React.FunctionComponent<{
  id: number;
  accountId: number;
  isFetching: boolean;
}> = ({ id, accountId, isFetching }) => {
  const relationship = useStateSelector(s => relationshipSelectors.data(s, id));
  const relationshipDetails = useStateSelector(s => relationshipSelectors.details(s, id));
  const isFetchingDetails = useStateSelector(
    s => s.private.cashiering.relationshipDetails[id] && s.private.cashiering.relationshipDetails[id].isFetching
  );

  return (
    <>
      <div className="screen-body-section">
        <p className="fields-title">ACH Relationship</p>
      </div>

      {(isFetching || isFetchingDetails) && <div className="screen-body-section">Loading...</div>}

      {!isFetching && !isFetchingDetails && (
        <CustomerAccountDetails
          accountId={accountId}
          relationship={relationship}
          relationshipDetails={relationshipDetails}
        />
      )}
    </>
  );
};
