import React, { useMemo } from 'react';
import { Button, Dropdown, DropdownItem, Tooltip } from '@tradingblock/components';
import _ from 'lodash';

interface TradeButtonProps {
  linkableBlockExists?: boolean;
  className?: string;
  handleTradeClick: (addBlockAsNew?: boolean | undefined) => void;
  title?: string;
  description?: string;
}

export const TradeButton: React.FunctionComponent<TradeButtonProps> = ({ linkableBlockExists, className, handleTradeClick, title, description }) => {
  const buttonTitle = useMemo(() => title || 'Trade', [title]);

  // if linkable block exists, show 2 trade options in dropdown

  if (linkableBlockExists) {
    return (
      <Dropdown
        buttonOptions={{
          toggleButtonClassName: `btn btn-outline-light ${className}`,
          title: buttonTitle,
        }}
        direction="left"
        content={buttonTitle}
      >
        <DropdownItem onClick={() => handleTradeClick()}>Trade in existing block</DropdownItem>
        <DropdownItem onClick={() => handleTradeClick(true)}>Trade in new block</DropdownItem>
      </Dropdown>
    );
  }

  return (
    <Tooltip delayShow={500} id={_.uniqueId('tradebutton')} content={description || buttonTitle}>
      <Button className={className} onClick={() => handleTradeClick()}>
        {buttonTitle}
      </Button>
    </Tooltip>
  );
};
