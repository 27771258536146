import { InitialState } from '../../initialState';
import { getType } from 'typesafe-actions';
import { AdminAlertAction, AdminAlertActions } from '../../actions/admin/AdminAlertActions';
import { PrivateAlertsState } from '@tradingblock/types';
import { alertRequestKey, alertRequestToQueryString } from '../../utilities/alertUtilities';

const defaultQueryState = (queryString: string) => ({
  queryString,
  isFetching: false,
  error: undefined,
});

type Actions = AdminAlertAction;
export const privateAlertsReducer = (state = InitialState.private.alerts, action: Actions): PrivateAlertsState => {
  switch (action.type) {
    case getType(AdminAlertActions.requestAlertTemplates): {
      const requestKey = alertRequestKey(action.payload);
      const existingQueryState =
        state.byQuery[requestKey] || defaultQueryState(alertRequestToQueryString(action.payload));
      return {
        ...state,
        byQuery: {
          ...state.byQuery,
          [requestKey]: {
            ...existingQueryState,
            isFetching: true,
            error: undefined,
          },
        },
      };
    }
    case getType(AdminAlertActions.receiveAlertTemplates): {
      const { request, response } = action.payload;
      const { payload } = response;

      const querykey = alertRequestKey(request);
      const existingQueryState =
        state.byQuery[querykey] || defaultQueryState(alertRequestToQueryString(response.request));

      const data = response && payload ? payload.data : [];
      const total = response && payload ? payload.total : 0;

      const ids = data && data.map((i: any) => i.id);

      return {
        ...state,
        alerts: data,
        byQuery: {
          ...state.byQuery,
          [querykey]: {
            ...existingQueryState,
            isFetching: false,
            error: undefined,
            total,
            ids,
          },
        },
      };
    }

    case getType(AdminAlertActions.errorAlertTemplates): {
      return {
        ...state,
        alerts: [],
        isFetching: false,
        error: action.payload,
        ui: {},
      };
    }

    case getType(AdminAlertActions.setAlertsPage):
    case getType(AdminAlertActions.setAlertsPageSize): {
      return {
        ...state,
        ui: {
          ...state.ui,
          ...action.payload,
        },
      };
    }

    default: {
      return state;
    }
  }
};
