import React, { useMemo } from 'react';
import _ from 'lodash';
import { useStateSelector } from '../../../../../data/global/dataSelectors';
import { DetailField, DetailFields } from '../../../../../components/basic/DetailFields';
import { formatBirthDate, maskSSN } from '../../services/formatters';

export const ApplicationBeneficiaryInformation: React.FunctionComponent<{}> = () => {
  const application = useStateSelector(s => s.private.applications.currentItem.application);
  const { beneficiaries } = application;

  const fields = useMemo(() => {
    let fieldList: DetailField[] = [];
    if (application) {
      fieldList = [
        ...fieldList,

        {
          Value: () => (
            <>
              <span className="fields-title">Beneficiary Information</span>
            </>
          ),
        },
        {
          Value: () => null,
        },
      ];
      if (beneficiaries) {
        beneficiaries.map((beneficiary, index) => {
          fieldList = [
            ...fieldList,
            {
              Value: () => {
                if (beneficiary.beneficiaryType === 'Individual') {
                  return (
                    <>
                      <div className="props-title">
                        {beneficiary.beneficiaryAccountType} - {beneficiary.beneficiaryType}
                      </div>
                      <div style={{ fontWeight: 'bold', margin: '1rem 0' }}>
                        {beneficiary.firstName && beneficiary.lastName && beneficiary.relationship
                          ? `${beneficiary.firstName} ${beneficiary.lastName} - ${beneficiary.relationship}`
                          : 'Not found'}
                        <div>{beneficiary.ssn || 'Not found'}</div>
                      </div>
                      <div className="mb-3">
                        <div>Percent</div>
                        <div className="mute">{beneficiary.percent || 'Not found'}</div>
                      </div>
                      <div className="address-section" style={{ marginTop: '1rem' }}>
                        Address
                        <div className="mute">
                          {beneficiary && beneficiary.address && beneficiary.address.address1 && (
                            <div>{beneficiary.address.address1}</div>
                          )}
                          {beneficiary && beneficiary.address && beneficiary.address.address2 && (
                            <div>{beneficiary.address.address2}</div>
                          )}
                          <div>
                            {beneficiary && beneficiary.address && beneficiary.address.city}
                            {beneficiary.address.state ? `, ${beneficiary.address.state}` : ''}{' '}
                            {beneficiary.address.postalCode}
                          </div>
                          <div>{beneficiary && beneficiary.address && beneficiary.address.country}</div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="props-title" style={{ marginTop: '1rem' }}>
                          Date of Birth
                        </div>
                        <div className="mute">{formatBirthDate(beneficiary.dateOfBirth)}</div>
                      </div>
                      <div className="props-title">Phone</div>
                      <div className="mute">
                        {beneficiary.phones[0].type && beneficiary.phones[0].phoneNumber
                          ? `${beneficiary.phones[0].type} - ${beneficiary.phones[0].phoneNumber}`
                          : 'Not found'}
                      </div>
                    </>
                  );
                }
                if (beneficiary.beneficiaryType === 'Trust') {
                  return (
                    <>
                      <span className="props-title">
                        {beneficiary.beneficiaryAccountType} - {beneficiary.beneficiaryType}
                      </span>
                      <br />
                      <br />
                      <span style={{ fontWeight: 'bold' }}>
                        {beneficiary.trustName ? beneficiary.trustName : 'Not found'}
                        <br />
                        {beneficiary.ein ? beneficiary.ein : 'Not found'}
                      </span>
                      <br />
                      <span>
                        Percent: <span className="mute">{beneficiary.percent ? beneficiary.percent : 'Not found'}</span>
                      </span>
                      <br />
                      <br />
                      <span>
                        Address
                        <br />
                        <span className="mute">
                          {beneficiary && beneficiary.address && beneficiary.address.address1 && (
                            <>
                              {beneficiary && beneficiary.address && beneficiary.address.address1}
                              <br />
                            </>
                          )}
                          {beneficiary && beneficiary.address && beneficiary.address.address2 && (
                            <>
                              {beneficiary.address.address2}
                              <br />
                            </>
                          )}
                          {beneficiary && beneficiary.address && beneficiary.address.city}
                          {beneficiary && beneficiary.address && beneficiary.address.state
                            ? ', ' + beneficiary.address.state
                            : ''}{' '}
                          {beneficiary && beneficiary.address && beneficiary.address.postalCode}
                          <br />
                          {beneficiary && beneficiary.address && beneficiary.address.country}
                        </span>
                      </span>
                    </>
                  );
                }
              },
            },
          ];
        });
      }
    }
    return fieldList;
  }, [application]);

  return (
    <>
      <div className="screen-body-section">
        {beneficiaries && beneficiaries.length > 0 && <DetailFields fieldList={fields} valueAs='div' />}
        {(!beneficiaries || beneficiaries.length === 0) && <p className="fields-title">No Beneficiaries On Record</p>}
      </div>
    </>
  );
};
