import { Dispatch, Middleware } from 'redux';
import { getType, ActionType } from 'typesafe-actions';
import { RootAction } from '../../actions';
import { AccountStatementsActions } from '../../actions/AccountStatementsActions';
import { DataState } from '../../state';
import { ApiProvider } from '../../../../context/Api';

const loadAccountDailyStatements = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AccountStatementsActions.requestDailyAccountStatements>
) => {
  const api = ApiProvider(state, dispatch);
  let { accountId, requestedStartDate, requestedEndDate } = action.payload;

  if (api) {
    try {
      const { payload } = await api.downloads.getDailyConfirms(accountId, requestedStartDate, requestedEndDate);
      dispatch(AccountStatementsActions.receiveDailyAccountStatements({ data: payload }));
    } catch (err) {
      console.error('loadAccountDetails error', err);
      //   dispatch(AccountManagementDataActions.errorAccountDetails({ error: err, accountId: accountId }));
    }
  }
};

const loadMonthlyAccountStatements = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AccountStatementsActions.requestMonthlyAccountStatements>
) => {
  const api = ApiProvider(state, dispatch);
  let { accountId, requestedStartDate, requestedEndDate } = action.payload;

  if (api) {
    try {
      const { payload } = await api.downloads.getMonthlyStatements(accountId, requestedStartDate, requestedEndDate);
      dispatch(AccountStatementsActions.receiveMonthlyAccountStatements({ data: payload }));
    } catch (err) {
      console.error('loadAccountDetails error', err);
      //   dispatch(AccountManagementDataActions.errorAccountDetails({ error: err, accountId: accountId }));
    }
  }
};

const loadTaxAccountStatements = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AccountStatementsActions.requestTaxAccountStatements>
) => {
  const api = ApiProvider(state, dispatch);
  let { accountId, taxDocument, taxYear } = action.payload;
  if (api) {
    try {
      const { payload } = await api.downloads.getTaxDocuments(
        accountId,
        taxDocument,
        taxYear
      );
      dispatch(AccountStatementsActions.receiveTaxAccountStatements({ data: payload }));
    } catch (err) {
      console.error('loadAccountDetails error', err);
      //   dispatch(AccountManagementDataActions.errorAccountDetails({ error: err, accountId: accountId }));
    }
  }
};

export const AccountStatements: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  const previousState = getState();
  const result = next(action);
  const nextState = getState();

  switch (action.type) {
    case getType(AccountStatementsActions.requestDailyAccountStatements): {
      loadAccountDailyStatements(nextState, dispatch, action);
      break;
    }
    case getType(AccountStatementsActions.requestMonthlyAccountStatements): {
      loadMonthlyAccountStatements(nextState, dispatch, action);
      break;
    }
    case getType(AccountStatementsActions.requestTaxAccountStatements): {
      loadTaxAccountStatements(nextState, dispatch, action);
      break;
    }
  }

  return result;
};
