import React from 'react';
import _ from 'lodash';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { StatusClassMap } from './AccountManagementUpdateStatus';

export const AccountUpdateDetails: React.FunctionComponent<{}> = () => {
  const updateDetails = useStateSelector(s => s.private.accountManagement.currentItem.details);
  let item = updateDetails.item;
  if (item === 'Flexability') {
    item = 'Flexibility';
  }
  if (updateDetails.item === 'PhoneNumber' && updateDetails.updated && updateDetails.updated.accountHolder.phones) {
    item = `${updateDetails.item.replace(/([A-Z])/g, ' $1').trim()} - ${
      updateDetails.updated.accountHolder.phones[0].type
    }`;
  }

  return (
    <>
      <div className="screen-body-section">
        <p className="fields-title">Account Update Request Type</p>
        <p className="mute">{item.replace(/([A-Z])/g, ' $1').trim()}</p>
      </div>
    </>
  );
};
