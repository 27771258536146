import _ from 'lodash';
import { createSelector } from 'reselect';
import { OrderBlockSettings } from '@tradingblock/types';
import { AllStrategies } from '@tradingblock/api';
import { SettingsFields } from '../../../components/form/GenericField';
import { DataState } from '../../../data/global/state';

export const DefaultOrderBlockSettings: OrderBlockSettings = {
  defaultStrategy: 'Shares',
  defaultQuantityForOptionsAndStrategies: 1,
  defaultQuantityForShares: 1,
  disableSounds: false,
  verboseSentiment: true,
  modernBidAskLabelOrientation: false,
  weeklyExpirations: true,
  monthlyExpirations: true,
  quarterlyExpirations: true,
  yearlyExpirations: true,
};

export const OrderBlockSettingsFields: SettingsFields = {
  defaultStrategy: {
    type: 'choice',
    label: 'Default strategy',
    values: _.map(AllStrategies, s => {
      return {
        value: s.info.Name,
        label: s.info.Name,
      };
    }),
  },
  defaultQuantityForOptionsAndStrategies: {
    type: 'number',
    label: 'Default quantity for options and strategies',
    min: '1',
  },
  defaultQuantityForShares: {
    type: 'number',
    label: 'Default quantity for shares',
    min: '1',
  },
  disableSounds: {
    type: 'boolean',
  },
  verboseSentiment: {
    type: 'boolean',
  },
  modernBidAskLabelOrientation: {
    type: 'radio',
    noOptionLabel: 'Classic',
    yesOptionLabel: 'Modern',
    fieldLabel: 'Bid Ask High Low style:',
  },
  weeklyExpirations: {
    type: 'radio',
    fieldLabel: 'For option legs, show weekly expirations:',
    yesOptionLabel: 'Yes',
    noOptionLabel: 'No',
  },
  monthlyExpirations: {
    type: 'radio',
    fieldLabel: 'For option legs, show monthly expirations:',
    yesOptionLabel: 'Yes',
    noOptionLabel: 'No',
  },
  quarterlyExpirations: {
    type: 'radio',
    fieldLabel: 'For option legs, show quarterly expirations:',
    yesOptionLabel: 'Yes',
    noOptionLabel: 'No',
  },
  yearlyExpirations: {
    type: 'radio',
    fieldLabel: 'For option legs, show yearly expirations:',
    yesOptionLabel: 'Yes',
    noOptionLabel: 'No',
  },
};

export const defaultOrderBlockSettingsSelector = createSelector(
  (s: DataState) => s.blocks.blockTypeSettings.Order || {},
  defaultOrderBlockState => {
    return {
      ...DefaultOrderBlockSettings,
      ...defaultOrderBlockState,
    };
  }
);
