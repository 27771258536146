import { DataState } from './state';
import { DefaultGridLayout } from '@tradingblock/types';
import { DefaultOrderBlockSettings } from '../../blocks/Order/state/OrderSettings';
import Config from '../../config';
import { Session } from '../../constants';
import { DefaultAccountUpdateDetails, DefaultUserUpdateAccountDetails } from '@tradingblock/types';
import { DefaultOptionChainBlockSettings } from '../../blocks/OptionChain/state/OptionChainSettings';
import { DefaultInfoBlockSettings } from '../../blocks/Info/state/InfoSettings';
import { DefaultPositionsBlockSettings } from '../../blocks/Positions/usePositionSettings';
import { DefaultFavoriteBlockSettings } from '../../blocks/Favorites/useFavoriteSettings';

export const InitialState: DataState = {
  auth: {
    isAuthenticated: false,
    isExpired: false,
    origin: '',
  },
  account: {
    dataEntitled: undefined,
    nickname: '',
    isIRA: false,
    isUGMA: false,
    isPartnership: false,
    accountId: -1,
    accountNumber: '',
    isFetching: false,
    account: undefined,
    subaccounts: { isFetching: false },
    history: [],
    pnl: [],
    profile: {
      isFetching: false,
    },
    isFetchingHistory: false,
    isFetchingOrders: false,
    shouldLoadMoreOrders: false,
    orders: undefined,
    orderErrorEvents: [],
    ordersUIState: {},
    showAccountSwitcher: false,
    hasMultipleAccounts: false,
    isSelectedLinkedAccountLoaded: true,
    accountDetails: undefined,
  },
  subAccountManagement: {
    subAccounts: [],
  },
  history: {
    balanceHistory: [],
  },
  accountData: {
    balances: { isFetching: false },
    accountsBalances: {},
    subAccountsBalances: {},
  },
  accounts: { isFetching: false },
  linkedAccounts: {
    accounts: [],
    page: 0,
    pageSize: 0,
    total: 0,
    isFetching: false,
  },
  accountManagement: {
    accountDetails: {
      isLoaded: false,
      data: {
        email: '',
        homePhone: '',
        mobilePhone: '',
        workPhone: '',
        mailingAddress: {
          address1: '',
          address2: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        physicalAddress: {
          address1: '',
          address2: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
      },
    },
    update: {
      isLoading: false,
      isLoaded: false,
      data: undefined,
    },
    accountManagementDetails: {
      details: DefaultUserUpdateAccountDetails,
      isFetching: false,
      error: null,
    },
    pendingAccountManagementUpdates: {
      isFetching: false,
      data: null,
      error: null,
    },
    isPollingForPending: false,
    security: {
      isFetching: false,
      devices: [],
      error: null,
    },
    authHistory: {
      data: [],
      isFetching: false,
      error: null,
    },
  },
  environment: { isVirtual: Config.isVirtual },
  errors: {},
  favorites: {
    isFetching: false,
    symbols: [],
  },
  feedProvider: {},
  //info: {},
  layout: {},
  orders: {
    isFetching: false,
  },
  settings: {
    authenticationTimeout: Session.inactivityTimeout,
    ui: {
      ...DefaultGridLayout,
      muteAllSounds: false,
      showBannerSymbols: true,
      isFetching: false,
      bannerSymbols: [],
      afterHoursTrading: false,
      balancePollingThrottleMs: 1000 * 5,
      feedSyncIntervalMs: 500,
      cashieringPollingInterval: 120,
      cashieringPendingReminderInterval: 15 * 60,
      cashieringPendingReminderMaxAttempts: 16,
      hotkeys: true,
    },
    defaultDashboards: {
      isFetching: false,
    },
  },
  positions: { isFetching: false, positions: [], isDirty: false, info: {}, loaded: false },
  blocks: {
    isFetching: false,
    blocks: [],
    locked: true,
    linking: false,
    isFetchingInitial: false,
    groups: [],
    blockTypeSettings: {
      Order: DefaultOrderBlockSettings,
      OptionChain: DefaultOptionChainBlockSettings,
      Info: DefaultInfoBlockSettings,
      Positions: DefaultPositionsBlockSettings,
      Favorites: DefaultFavoriteBlockSettings,
    },
  },
  dashboards: { isFetching: false },
  quotes: {
    quoteMetadata: {
      isFetching: false,
      data: {},
      extended: {},
    },
    quoteExtended: {
      isFetching: false,
      data: {},
    },
    dividends: {},
    earnings: {},
  },
  notifications: [],
  optionchain: {
    bySymbol: {},
  },
  expirations: {},
  strikes: {},
  cashiering: {
    forms: {
      account: { isFetching: false, isSaving: false },
      transfer: { isFetching: false, isSaving: false },
    },
    securityQuestion: { isFetching: false, isSaving: false },
    balances: {},
    transfers: { isFetching: false, isSaving: false },
    achRelationships: { isFetching: false, isSaving: false },
    transferInstructions: { isFetching: false, isSaving: false },
    entities: {},
    isFetchingBalances: false,
    isFetchingEntities: false,
    isPollingForPending: false,
  },
  private: {
    account: {
      accounts: {},
      balances: {},
      accountDetails: {},
      balancesForWithdrawal: {},
    },
    accountManagement: {
      isFetching: false,
      updates: {},
      error: null,
      byQuery: {},
      ui: {
        page: 0,
        pageSize: 10,
        sortBy: 'Date',
        sortByOrder: 'desc',
      },
      currentItem: {
        request: {},
        details: DefaultAccountUpdateDetails,
        isFetching: false,
        isAccountFetching: false,
        error: null,
        accountInfo: DefaultUserUpdateAccountDetails,
        isUpdated: false,
        documentUpdate: false,
      },
    },
    alerts: {
      isFetching: false,
      ui: {
        page: 0,
        pageSize: 10,
      },
      alerts: [],
      error: undefined,
      byQuery: {},
    },
    applications: {
      isFetching: false,
      applications: {},
      ui: {
        page: 0,
        pageSize: 10,
        sortBy: 'createdOnValue',
        sortByOrder: 'desc',
      },
      currentItem: {
        application: {},
        investigation: {
          isFetching: false,
          response: {},
        },
      },
      error: undefined,
      byQuery: {},
    },
    cashiering: {
      relationships: {},
      relationshipDetails: {},
      transfers: {},
      transferDetails: {},
      beneficiaries: {},
      recipientBanks: {},
      wireInstructions: {},
      wireInstructionsDetails: {},
      entities: {},
      entityUpdatesExist: false,
      byQuery: {},
      ui: {
        pageSize: 50,
        page: 0,
        sortBy: 'Date',
        sortByOrder: 'desc',
      },
    },
    clients: {
      byQuery: {},
      clients: {},
      ui: {
        pageSize: 25,
        page: 0,
        sortBy: 'AccountName',
        sortByOrder: 'asc',
      },
    },
    entitlements: {
      entitlementItems: {},
      isFetching: false,
      error: null,
      byQuery: {},
      ui: {
        pageSize: 10,
        page: 0,
      },
      currentItem: {
        request: {
          UserId: 0,
          AccountNumber: '',
        },
        isFetching: false,
        isAccountFetching: false,
        error: null,
        details: {},
        accountInfo: DefaultUserUpdateAccountDetails,
        isUpdated: false,
      },
    },
  },
  symbols: {
    isFetching: false,
    warningSymbols: [],
  },
  ui: {
    dashboard: {
      drawerIsOpen: false,
      isLocked: false,
      subAccountFilterId: undefined,
      entitlements: {
        showPendingEntitlementModal: null,
      },
    },
    marketSchedule: {
      market: undefined,
      indexMarket: undefined,
    },
  },
  performance: {
    bySymbol: {},
    positions: {},
    totals: {
      positionValue: 0,
      accountValue: 0,
      tradedToday: { trading: 0, position: 0, total: 0, pnls: [] },
      all: { trading: 0, position: 0, total: 0, pnls: [] },
    },
  },
  information: {
    alerts: {
      isFetching: false,
      activeAlerts: [],
    },
    releaseNotes: {},
    ui: {
      page: 0,
      pageSize: 5,
    },
    releaseNoteUpdate: false,
    marketNews: {},
    isPollingForMarketNews: false,
  },
  accountStatements: {
    dailyStatements: {
      dataStartDate: new Date(),
      dataEndDate: new Date(),
      data: [],
    },
    monthlyStatements: {
      dataStartDate: new Date(),
      dataEndDate: new Date(),
      data: [],
    },
    taxStatements: {
      dataStartDate: new Date(),
      dataEndDate: new Date(),
      data: [],
    },
  },
};
