import React, { FC, useCallback } from 'react';
import { OrderActivity } from '@tradingblock/types';
import { OrderStatusToString } from '../util';
import { getOrders, getOpenOrdersCount, getOrdersByBlockId } from '../../../data/global/selectors/orderSelector';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { isBetweenInclusive } from '@tradingblock/api';
import { useActivityTimeframeValues } from '../data/useActivityTimeframe';
import { useBlockId } from '../../../components/blocks/BlockState';

export const ActivityOrderStats: FC<{}> = () => {
  const blockId = useBlockId();
  const accountHistory = useStateSelector(getOrdersByBlockId(blockId));
  const { startDate, endDate } = useActivityTimeframeValues();

  const countFilteredOrders = useCallback(
    (orders: OrderActivity[]) => (status: string) => {
      return orders.filter((order: OrderActivity) => {
        if (OrderStatusToString(order.OrderStatus) === status) {
          if ((startDate as Date) === (endDate as Date) || startDate === undefined || endDate === undefined) {
            return true;
          }
          const ordDate = order.Date;
          return ordDate ? isBetweenInclusive(ordDate, startDate, endDate) : false;
        }
        return false;
      }).length;
    },
    [startDate, endDate]
  );

  const getStatusCount = countFilteredOrders(accountHistory);
  const filledCount = getStatusCount('Filled');
  const cancelledCount = getStatusCount('Cancelled');
  const openCount = useStateSelector(s => getOpenOrdersCount(s));
  return (
    <div className="col-auto align-self-center">
      <ul className="inline-list">
        <li>Open: {openCount === 0 ? 0 : openCount}</li>
        <li>
          <span className="pos">Filled:</span> {filledCount === 0 ? 0 : filledCount}
        </li>
        <li>
          <span className="mute">Cancelled:</span> {cancelledCount === 0 ? 0 : cancelledCount}
        </li>
      </ul>
    </div>
  );
};
