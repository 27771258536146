import React from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import {
  Transfer,
  TransferMechanism,
  TransferFee,
  TransferDeliveryMethod,
  WireAccountType,
  WireType,
  AchRelationshipState,
  CashieringView,
  CashieringRoute,
  CashieringManageAction,
  TransferRecipientBank,
  TransferBankIdentifierType,
  AchRelationship,
  ApprovalMethod,
  TransferInstruction,
  TransferRecipientBankState,
} from '@tradingblock/types';
import { LoadingImage, Text } from '@tradingblock/components';
import { maskAccountNumber } from './data';
import { CashieringViewFormValues } from '../pages/views/cashiering/data/useCashieringView';
import { TradingBlockLink } from '../components/basic/TradingBlockLink';

export const getCurrentTransferView = () => {
  if (window.location.pathname == '/accounts/oauth') {
    return CashieringView.OAuthPlaidRedirect;
  }

  const view = window.location.hash.replace(/^#/, '');
  if (_.includes(_.values(CashieringView), view)) {
    return view as CashieringView;
  }
  return undefined;
};

export const getNextTransferView = (view: CashieringView | undefined, route: CashieringRoute | undefined) => {
  switch (view) {
    case CashieringView.Authorize:
      return route === CashieringRoute.Accounts ? CashieringView.Manage : CashieringView.Amount;
    case CashieringView.Amount:
      return CashieringView.Type;
    case CashieringView.Type:
      return CashieringView.Review;
    case CashieringView.OAuthPlaidRedirect:
      return CashieringView.Manage;
    default:
      return undefined;
  }
};

interface PrimaryButtonOptions {
  view: CashieringView | undefined;
  route: CashieringRoute | undefined;
  action?: CashieringManageAction;
  values?: CashieringViewFormValues;
}

export const getViewPrimaryButton = (
  options: PrimaryButtonOptions,
  isDisabled: boolean,
  isSaving: boolean,
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
) => {
  const { action } = options;
  const contents = getViewPrimaryButtonContents(options);
  if (contents) {
    return (
      <button
        className="btn btn-primary"
        style={action === CashieringManageAction.Delete ? getTransferActionStyle(action, 'backgroundColor') : undefined}
        type="button"
        disabled={isDisabled || isSaving}
        onClick={onClick}
      >
        {contents} {isSaving && <LoadingImage />}
      </button>
    );
  }
  return null;
};

const getViewPrimaryButtonContents = (options: PrimaryButtonOptions) => {
  const { view, route, action, values } = options;
  const mechanism = values && values.transfer && values.transfer.mechanism;
  const objectType = mechanism === 'Ach' ? 'account' : 'bank';
  const defaultButton = (
    <>
      Continue <i className="fal fa-arrow-right" />
    </>
  );
  if (action === CashieringManageAction.AddOptions) {
    return defaultButton;
  }
  if (action === CashieringManageAction.Add) {
    const approvalMethod = values && values.account && values.account.approvalMethod;
    const plaidPublicToken = values && values.account && values.account.plaidPublicToken;
    if (approvalMethod === 'PDF') {
      return null;
    }
    if (approvalMethod === 'Plaid' && !plaidPublicToken) {
      return null;
    }
    return <>{`${mechanism === 'Ach' ? 'Link' : 'Save'} ${objectType}`}</>;
  }
  if (action === CashieringManageAction.Rename) {
    return <>{`Save ${objectType}`}</>;
  }
  if (action === CashieringManageAction.Delete) {
    return <>{`Delete ${objectType}`}</>;
  }
  if (action === CashieringManageAction.Verify) {
    return <>Verify account</>;
  }
  if (action === CashieringManageAction.Close) {
    return <>Close</>;
  }

  switch (view) {
    case CashieringView.Authorize:
      if (values && values.authorizeFailed) {
        return null;
      }
      return defaultButton;
    case CashieringView.Manage:
      return <>Close</>;
    case CashieringView.Review:
      return <>Submit {route === CashieringRoute.Withdraw ? 'withdrawal' : 'deposit'}</>;
    default:
      return defaultButton;
  }
};

export const showPrimaryButton = (options: PrimaryButtonOptions) => {
  const { view, route, action, values } = options;
  const mechanism = values && values.transfer && values.transfer.mechanism;

  if (
    !action &&
    view === CashieringView.Type &&
    route === CashieringRoute.Deposit &&
    (mechanism === 'Wire' || mechanism === 'Check')
  ) {
    // At this time, you can only deposit funds via a linked bank account
    return false;
  }

  const primaryButton = getViewPrimaryButtonContents(options);
  // show primary button only if some contents present
  return !_.isNull(primaryButton);
};

export const getTransferRouteTitle = (route: CashieringRoute | undefined) => {
  switch (route) {
    case CashieringRoute.Withdraw:
      return (
        <>
          <i className="fas fa-inbox-out" /> Withdraw funds
        </>
      );
    case CashieringRoute.Deposit:
      return (
        <>
          <i className="fas fa-inbox-in" /> Fund account
        </>
      );
    case CashieringRoute.Accounts:
      return (
        <>
          <i className="fas fa-university" /> Manage accounts
        </>
      );
    default:
      return null;
  }
};

export const getTransferViewSubtitle = (view: CashieringView | undefined) => {
  switch (view) {
    case CashieringView.Authorize:
      return 'Verify identity';
    case CashieringView.Review:
      return 'Review';
    default:
      return '';
  }
};

export const getTransferActionStyle = (
  action: CashieringManageAction | undefined,
  key: 'color' | 'backgroundColor' = 'color'
) => {
  if (action === CashieringManageAction.Rename) {
    return { [key]: 'var(--green-lighten)' };
  }
  if (action === CashieringManageAction.Delete) {
    return { [key]: 'var(--red)' };
  }
  return undefined;
};

export const getTransferActionIconClass = (action: CashieringManageAction | undefined) => {
  if (action === CashieringManageAction.Rename) {
    return 'fa-pencil';
  }
  if (action === CashieringManageAction.Delete) {
    return 'fa-trash';
  }
  return undefined;
};

export const getTransferTypeLabel = (transferMechanism: TransferMechanism | undefined) => {
  switch (transferMechanism) {
    case 'Ach':
      return 'Linked account';
    case 'Check':
      return 'Paper Check';
    case 'Wire':
      return 'Wire';
  }
};

export const getTransferFeeText = (transferFee: TransferFee | undefined) => {
  if (transferFee && transferFee.customerDebitAmount) {
    return `$${transferFee.customerDebitAmount} Customer Debit`;
  }
  if (transferFee && transferFee.firmCreditAmount) {
    return `$${transferFee.firmCreditAmount} Firm Credit`;
  }
  if (transferFee && transferFee.correspondentNetAmount) {
    return `$${transferFee.correspondentNetAmount} Correspondent Net`;
  }
  return '';
};

const getTransferStatusName = (transfer: Transfer | undefined) => {
  if (transfer && transfer.state === 'Complete') {
    return 'Completed';
  }
  if (transfer && transfer.state === 'Canceled') {
    return 'Cancelled';
  }
  if (transfer && !_.startsWith(transfer.state, 'Pending')) {
    return _.startCase(transfer.state);
  }
  // default to pending status
  return 'Pending';
};

export const getTransferBeneficiaryNickName = (recipientBank: TransferRecipientBank | undefined) => {
  // beneficiaries are tied to recipient banks in the background by nickname
  if (recipientBank) {
    return `TransferBeneficiary-for-RecipientBank.${recipientBank.id}`;
  }
  return undefined;
};

export const getTransferInstructionNickName = (transferInstruction: TransferInstruction | undefined) => {
  // instructions are tied to recipient banks in the background by nickname
  if (transferInstruction) {
    return `TransferInstruction-for-RecipientBank.${transferInstruction.id}`;
  }
  return undefined;
};

export const getApprovalMethod = (approvalMethod: ApprovalMethod | number | undefined): ApprovalMethod | 'Legacy' => {
  // handle raw enum number values
  switch (approvalMethod) {
    case 1:
      return 'MicroDeposit';
    case 2:
      return 'Plaid';
    default:
      return _.isString(approvalMethod) ? approvalMethod : 'Legacy';
  }
};

export const getApprovalMethodLabel = (approvalMethod: ApprovalMethod | undefined) => {
  switch (approvalMethod) {
    case 'Plaid':
      return (
        <>
          <strong>Use your bank login</strong> (recommended)
        </>
      );
    case 'MicroDeposit':
      return <strong>Verify through small deposits</strong>;
    case 'PDF':
      return <strong>Print and send in a paper application</strong>;
  }
};

export const getApprovalMethodDescription = (approvalMethod: ApprovalMethod | undefined, route: CashieringRoute) => {
  switch (approvalMethod) {
    case 'Plaid':
      return 'Works for most US banks - usually takes a few minutes (bank account and routing numbers may be requested)';
    case 'MicroDeposit':
      return 'Works for any bank in the US - usually takes 1-2 business days';
    case 'PDF':
      return (
        <>
          If both of the above fail, download the{' '}
          <TradingBlockLink to="AchRelationshipPdfApplicationUrl">
            <strong>PDF application</strong>
          </TradingBlockLink>
          {route != CashieringRoute.Accounts && (
            <> (or select one of the tabs above to transfer funds via wire or check)</>
          )}
        </>
      );
  }
};

export const isNotYetCreatedStatus = (status: AchRelationshipState) => status === 'PendingCreateApproval';
export const isPendingStatus = (status: AchRelationshipState) =>
  status === 'Pending' || status === 'PendingFirmApproval';
export const isApprovedStatus = (status: AchRelationshipState) => status === 'Approved';
export const isRejectedStatus = (status: AchRelationshipState | TransferRecipientBankState) =>
  status === 'CreateRejected' || status === 'Rejected';
export const isCancelledStatus = (status: AchRelationshipState) => status === 'Cancelled';
export const isNotYetCreatedOrPendingStatus = (status: AchRelationshipState | TransferRecipientBankState) =>
  isNotYetCreatedStatus(status) || isPendingStatus(status);
export const isRejectedOrCancelledStatus = (status: AchRelationshipState | TransferRecipientBankState) =>
  isRejectedStatus(status) || isCancelledStatus(status);
export const isRejectedCancelledOrPendingStatus = (status: AchRelationshipState | TransferRecipientBankState) => {
  return (
    isRejectedStatus(status) || isCancelledStatus(status) || isPendingStatus(status) || isNotYetCreatedStatus(status)
  );
};

export const getTransferStatusText = (transfer: Transfer | undefined) => {
  let label = getTransferStatusName(transfer);
  if (label === 'Pending') {
    label +=
      transfer && transfer.estimatedFundsAvailableDate
        ? ': Expected ' + dayjs(transfer.estimatedFundsAvailableDate).format('MMM D')
        : '';
  }
  if (label === 'Cancelled') {
    label = `*${label}*`;
  }
  return label;
};

export const getTransferWireType = (type: WireType | undefined) => {
  if (type === WireType.International) {
    return WireType.International;
  }
  return WireType.Domestic;
};

export const getTransferWireTypeFromRecipientBank = (recipientBank: TransferRecipientBank | undefined) => {
  if (recipientBank && recipientBank.identifierType === TransferBankIdentifierType.BIC) {
    return WireType.International;
  }
  return WireType.Domestic;
};

export const getTransferBankIdentifierType = (data: Partial<TransferRecipientBank>) => {
  if (data.identifierType === TransferBankIdentifierType.BIC) {
    return TransferBankIdentifierType.BIC;
  }
  return TransferBankIdentifierType.ABA;
};

export const getTransferMethod = (
  data: Transfer,
  relatedData: { achRelationships?: AchRelationship[]; recipientBanks?: TransferRecipientBank[] }
) => {
  const label = getTransferTypeLabel(data.mechanism);
  switch (data.mechanism) {
    case 'Ach':
      const achRelationshipId = _.get(data, 'achRelationship'); // should really be named 'achRelationshipId' coming from TB api...
      const achRelationship =
        relatedData.achRelationships && _.find(relatedData.achRelationships, r => r.id === achRelationshipId);
      return (
        <>
          {label}
          <br />
          {achRelationship && achRelationship.bankAccount}
        </>
      );
    case 'Check':
      return (
        <>
          {label}
          <br />
          {_.join(_.filter(data.memos, m => !!m), ', ')}
        </>
      );
    case 'Wire':
      const type =
        relatedData.recipientBanks &&
        getTransferWireTypeFromRecipientBank(_.find(relatedData.recipientBanks, b => b.id === data.recipientBankId));
      return (
        <>
          {label}
          <br />
          {type && _.startCase(getTransferWireType(type))}
        </>
      );
  }
};

export const getDeliveryMethodLabel = (deliveryMethod: TransferDeliveryMethod | undefined) => {
  switch (deliveryMethod) {
    case 'Overnight':
      return 'Overnight Express Mail';
    case 'Standard':
      return 'US Regular Mail';
  }
};

export const getWireAccountTypeLabel = (accountType: WireAccountType | undefined) => {
  switch (accountType) {
    case WireAccountType.SelfOwned:
      return 'Myself';
    case WireAccountType.ThirdParty:
      return 'Third party';
  }
};

export const getFormattedAchRelationship = (acct: Partial<AchRelationship>) => {
  return (
    <div className="mute">
      Nickname: {acct.nickName}
      <br />
      {acct.bankName ? (
        <>
          Bank account: {acct.bankName} ({acct.bankAccount})
        </>
      ) : (
        acct.bankAccount
      )}
    </div>
  );
};

export const getFormattedRecipientBank = (acct: Partial<TransferRecipientBank>) => {
  return (
    <div className="mute mb-1">
      Nickname: {acct.nickName}
      <br />
      ABA/Routing: {acct.identifier}
      {acct.name && (
        <>
          <br />
          Bank: {acct.name}
        </>
      )}
      {acct.city && (
        <>
          <br />
          {acct.city}
          {acct.city && acct.stateProvince ? ', ' : ''}
          {acct.stateProvince} {acct.postalCode}
          <br />
          <Text id={`defaults.country.${acct.country}`} type="field" />
        </>
      )}
      {acct.intermediaryId && (
        <>
          <br />
          via Intermediary
        </>
      )}
    </div>
  );
};

export const getFormattedTransferInstruction = (acct: Partial<TransferInstruction>) => {
  return (
    <div className="mute mb-1">
      Nickname: {acct.nickName}
      <br />
      ABA/Routing: {acct.recipientBank && acct.recipientBank.identifier}
      {acct.name && (
        <>
          <br />
          Bank: {acct.name}
        </>
      )}
      {acct.city && (
        <>
          <br />
          {acct.city}
          {acct.city && acct.stateProvince ? ', ' : ''}
          {acct.stateProvince} {acct.postalCode}
          <br />
          <Text id={`defaults.country.${acct.country}`} type="field" />
        </>
      )}
      {acct.intermediary && (
        <>
          <br />
          via Intermediary
        </>
      )}
    </div>
  );
};

export const isDataEmpty = (data: object, omit: string[] = []): boolean => {
  const values: any[] = _.values(_.omit(data, omit));
  return _.every(values, v => (_.isObject(v) ? isDataEmpty(v) : _.isEmpty(v)));
};
