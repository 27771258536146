import React, { useCallback } from 'react';
import { useDispatcher } from '../../data/global/hooks';
import { Hotkey, HotkeyProps } from '@tradingblock/components';
import { BlockType } from '@tradingblock/types';
import { generateNewBlock } from '../../utilities/block';

export const BlockHotkeys: React.FunctionComponent<Pick<HotkeyProps, 'modifier'>> = ({ modifier }) => {
  const { dispatcher } = useDispatcher();
  const createOrderBlock = useCallback(() => dispatcher.block.addBlock(generateNewBlock(BlockType.Order, { autoFocus: true })), [dispatcher]);
  return (
    <>
      <Hotkey keys="o" modifier={modifier} callback={createOrderBlock} />
    </>
  );
};
