import { isBlockAction, blockReducer, BlockAction } from '../../../components/blocks/BlockState';

import { OptionChainSettings, IBlockState } from '@tradingblock/types';
import { getType } from 'typesafe-actions';
import { QuoteState, QuoteBlockConstants, isQuoteTab } from './QuoteState';
import { QuoteBlockAction, QuoteBlockActions } from './QuoteBlockAction';

export type QuoteBlockState = IBlockState<QuoteState, {}>;
export const QuoteBlockReducer = (blockState: QuoteBlockState, action: QuoteBlockAction): QuoteBlockState => {
  const state = isBlockAction(action) ? blockReducer<QuoteState, {}>(blockState, action) : blockState;

  switch (action.type) {
    case getType(QuoteBlockActions.setTab): {
      const tabVal = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          tab: isQuoteTab(tabVal) ? tabVal : QuoteBlockConstants.tabs.summary,
        },
      };
    }
    case getType(QuoteBlockActions.setEarningsPeriod): {
      return {
        ...state,
        data: {
          ...state.data,
          earningsPeriod: action.payload,
        },
      };
    }
    case getType(QuoteBlockActions.setDividendsPeriod): {
      return {
        ...state,
        data: {
          ...state.data,
          dividendsPeriod: action.payload,
        },
      };
    }

    default:
      return state;
  }
};
