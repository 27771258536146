import { Middleware, Dispatch } from 'redux';
import { getType } from 'typesafe-actions';
import { StorageFavoritesPath, AssetSymbolInfo } from '@tradingblock/types';
import { RootAction, FavoriteActions } from '../../actions';
import { DataState } from '../../state';
import { getFavorites } from '../../dataSelectors';
import { UiApiClient } from '../../../../context/Storage';
import _ from 'lodash';
import dayjs from 'dayjs';

export const loadFavorites = async (state: DataState, dispatch: Dispatch<RootAction>) => {
  const provider = UiApiClient(state, dispatch);
  if (provider) {
    const res = await provider.get<{ data: AssetSymbolInfo[] }>(StorageFavoritesPath);
    const favoriteValue = res && res.data ? res.data : [];

    // If favoriteValue is not empty, filter out expiring contracts, save to db and dispatch receive action
    if (!_.isEmpty(favoriteValue)) {
      const filteredFavoriteValue = favoriteValue.filter(symbol => {
        if (symbol.symbol.includes(' ')) {
          const [_, contractInfo] = symbol.symbol.split(' ');
          const [year, month, day] = [contractInfo.substr(0, 2), contractInfo.substr(2, 2), contractInfo.substr(4, 2)];
          const expiry = new Date(`${month}/${day}/${year}`);
          if (dayjs().isBefore(dayjs(expiry).add(1, 'day'))) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      });
      await provider.save(StorageFavoritesPath, filteredFavoriteValue);
      dispatch(FavoriteActions.receive({ value: filteredFavoriteValue }));
    } else {
      dispatch(FavoriteActions.receive({ value: favoriteValue }));
    }
  }
};

export const handlePersistFavorites = async (state: DataState, dispatch: Dispatch<RootAction>) => {
  const provider = UiApiClient(state, dispatch);
  // getFavorites will remove any expired options contracts from favorites list before persisting to db
  const favorites = getFavorites(state);
  if (provider) {
    await provider.save(StorageFavoritesPath, favorites);
  }
};

export const FavoriteMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  try {
    // state BEFORE action is dispatched
    const result = next(action);
    // state AFTER action is dispatched
    const nextState = getState();
    switch (action.type) {
      case getType(FavoriteActions.remove):
      case getType(FavoriteActions.addFavorite): {
        handlePersistFavorites(nextState, dispatch);
        break;
      }
      case getType(FavoriteActions.request): {
        loadFavorites(nextState, dispatch);
        break;
      }
    }
    return result;
  } catch (err) {
    console.error('DashboardMiddleware :: Caught an exception for action ', action, err);
  }
};
