import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import React, { useState, useCallback } from 'react';
import { useDebounce } from 'use-hooks';
export var BlockDragHandle = function BlockDragHandle(_ref) {
  var title = _ref.title,
      className = _ref.className,
      children = _ref.children;

  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      showDrag = _useState2[0],
      setShowDrag = _useState2[1];

  var enableHover = useCallback(function () {
    if (!showDrag) {
      setShowDrag(true);
    }
  }, [showDrag, setShowDrag]);
  var disableHover = useCallback(function () {
    if (showDrag) {
      setShowDrag(undefined);
    }
  }, [showDrag, setShowDrag]); // use debounce for drag class, for slight delay on hover

  var dragClassName = useDebounce(showDrag ? 'block-drag-handle' : '', 350); // useWhyDidYouUpdate('BlockDragHandle', {dragClassName, showDrag, title, className, setShowDrag });

  return React.createElement("div", {
    className: "".concat(className || '', "\n      ").concat(showDrag ? dragClassName : ''),
    onMouseOver: enableHover,
    onMouseOut: disableHover,
    title: title,
    style: {
      userSelect: 'none'
    }
  }, children);
};