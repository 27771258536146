import React, { useMemo } from 'react';
import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { getProfitLoss, isInfinity } from '@tradingblock/api';
import { OrderAction, isCustomStrategy, NumberOrInfinity, Legs } from '@tradingblock/types';
import { InfinityIcon, Number } from '@tradingblock/components';
import { useOrderData, OrderState, useOrderStrategy } from '../state/OrderState';
import { useOrderStatus } from '../data/useOrderStatus';
import { useOrderTotal } from '../hooks/useOrderTotal';
import { useBlockId } from '../../../components/blocks/BlockState';

const legAndQuantitySelector = createCachedSelector(
  (s: OrderState, { blockId, price }: { blockId: string; price: number }) => s.legs,
  (s: OrderState) => s.quantity,
  (s: OrderState) => s.action,
  (s: OrderState, { blockId, price }: { blockId: string; price: number }) => price,
  (legs: Legs, quantity: number, action: OrderAction, price: number) => {
    return {
      Legs: _.values(legs),
      Quantity: quantity,
      action,
      price,
      commission: 0,
    };
  }
)((s: OrderState, { blockId, price }: { blockId: string; price: number }) => `legQuantity${blockId}`);

const useOrderDataForProfitLoss = () => {
  const { total } = useOrderTotal();

  const blockId = useBlockId();
  const orderPartial = useOrderData(s => legAndQuantitySelector(s, { blockId, price: total || 0 }));

  return orderPartial;
};

const useProfitLoss = () => {
  const orderData = useOrderDataForProfitLoss();
  const strategy = useOrderStrategy();
  const quotes = useOrderData(s => s.quotes);
  const profitloss = useMemo(
    () => (strategy && !isCustomStrategy(strategy) ? getProfitLoss(strategy.info.Name, orderData, quotes) : undefined),
    [strategy, orderData]
  );
  return profitloss;
};

const PriceOrInfinity: React.FC<{ value: NumberOrInfinity }> = ({ value }) => {
  return isInfinity(value) ? <InfinityIcon /> : <Number value={value} currency />;
};

export const ProfitLoss: React.FC<{}> = () => {
  const profitLossValues = useProfitLoss();
  const { complete } = useOrderStatus();

  return (
    <p>
      {profitLossValues && complete && (
        <>
          <span className={'pos'}>
            Max profit: <PriceOrInfinity value={profitLossValues.profit} />
          </span>{' '}
          <span className={'neg'}>
            Max loss: <PriceOrInfinity value={profitLossValues.loss} />
          </span>
        </>
      )}
    </p>
  );
};
