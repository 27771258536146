import _toConsumableArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import * as tslib_1 from "tslib";
import dayjs from 'dayjs';
import { getBestPrice } from '../orders/orderPrice';
import { OrderAction } from '@tradingblock/types';
import _ from 'lodash';
export var pnlByPrice = function pnlByPrice(_ref, priceBaseline, commission) {
  var Symbol = _ref.Symbol,
      AskPrice = _ref.AskPrice,
      BidPrice = _ref.BidPrice,
      LastOrClosePrice = _ref.LastOrClosePrice,
      PositionOnOpen = _ref.PositionOnOpen,
      Multiplier = _ref.Multiplier,
      isSettlementSet = _ref.isSettlementSet;
  var bestPrice = getBestPrice({
    last: LastOrClosePrice || 0,
    ask: AskPrice,
    bid: BidPrice,
    defaultValue: 'avgBidAsk',
    isSettlementSet: isSettlementSet
  }); // const commissionValue = commission === undefined ? 0 : commission;

  var commissionValue = 0;
  var initialValue = priceBaseline * PositionOnOpen + commissionValue;
  var currentValue = bestPrice * PositionOnOpen;
  var pnl = (currentValue - initialValue) * Multiplier;

  if (_.includes(Symbol, 'HUT')) {
    console.log("".concat(Symbol), {
      bestPrice: bestPrice,
      commissionValue: commissionValue,
      initialValue: initialValue,
      currentValue: currentValue,
      pnl: pnl,
      priceBaseline: priceBaseline,
      LastOrClosePrice: LastOrClosePrice,
      AskPrice: AskPrice,
      BidPrice: BidPrice,
      PositionOnOpen: PositionOnOpen,
      Multiplier: Multiplier,
      isSettlementSet: isSettlementSet
    });
  } // const pnl = currentValue - initialValue;


  return pnl;
};
export var orderLegNetQuantityChange = function orderLegNetQuantityChange(_ref2) {
  var Action = _ref2.Action,
      LegFillQuantity = _ref2.LegFillQuantity;
  var isBuy = Action === OrderAction.Buy;
  var fillQuantity = _.isNumber(LegFillQuantity) ? LegFillQuantity : 0;
  var quantityBought = isBuy ? fillQuantity : 0;
  var quantitySold = !isBuy ? Math.abs(fillQuantity) : 0;
  return quantityBought - quantitySold;
};

var getAvgPrice = function getAvgPrice(legs) {
  var totalQuantity = _(legs).map(function (l) {
    return l.LegFillQuantity || 0;
  }).sum();

  var avgPrice = _.reduce(legs, function (acc, l) {
    var avgPrice = l.AverageLegFillPrice || 0;
    var quantity = l.LegFillQuantity || 0;
    return acc + avgPrice * (quantity / totalQuantity);
  }, 0);

  var priceInfo = {
    total: totalQuantity,
    avgPrice: avgPrice
  };
  return priceInfo;
};

export var orderLegTradingPnL = function orderLegTradingPnL(_a, legs) {
  var roundPrecision = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 4;

  var AskPrice = _a.AskPrice,
      BidPrice = _a.BidPrice,
      LastOrClosePrice = _a.LastOrClosePrice,
      ClosePrice = _a.ClosePrice,
      Multiplier = _a.Multiplier,
      IsSettlementSet = _a.IsSettlementSet,
      rest = tslib_1.__rest(_a, ["AskPrice", "BidPrice", "LastOrClosePrice", "ClosePrice", "Multiplier", "IsSettlementSet"]);

  var legGroups = _.reduce(legs, function (acc, leg) {
    var Action = leg.Action;
    var isBuy = Action === OrderAction.Buy;
    return {
      buys: isBuy ? [].concat(_toConsumableArray(acc.buys), [leg]) : acc.buys,
      sells: isBuy ? acc.sells : [].concat(_toConsumableArray(acc.sells), [leg])
    };
  }, {
    buys: [],
    sells: []
  });

  var sells = getAvgPrice(legGroups.sells);
  var buys = getAvgPrice(legGroups.buys); // const buyPrices: number[] = isBuy ? _.times<number>(fillQuantity, () => AverageLegFillPrice || 0) : [];
  // const avgBoughtPrice = quantityBought > 0 ? _.sum(buyPrices) / quantityBought : 0;
  // const sellPrices: number[] = !isBuy ? _.times<number>(fillQuantity, () => AverageLegFillPrice || 0) : [];
  // const avgSoldPrice = quantitySold > 0 ? _.sum(sellPrices) / quantitySold : 0;

  var lastPrice = getBestPrice({
    last: LastOrClosePrice || 0,
    ask: AskPrice,
    bid: BidPrice,
    defaultValue: 'avgBidAsk',
    isSettlementSet: IsSettlementSet
  });
  var tradingPnLValue = (buys.total * (lastPrice - buys.avgPrice) - sells.total * (lastPrice - sells.avgPrice)) * Multiplier;
  return {
    pnl: _.round(tradingPnLValue, roundPrecision),
    sells: sells,
    buys: buys
  };
};
export var PositionPnLInfo = function PositionPnLInfo(position) {
  var roundPrecision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 4;
  var DateOpened = position.DateOpened,
      OpenPrice = position.OpenPrice,
      ClosePrice = position.ClosePrice;
  var dailyPLPriceBaseline = dayjs(DateOpened).isSame(new Date(), 'date') ? OpenPrice : ClosePrice || 0;
  var isToday = dayjs(DateOpened).isSame(new Date(), 'date');

  var pnl = _.round(pnlByPrice(position, dailyPLPriceBaseline), roundPrecision);

  return {
    trading: isToday ? pnl : 0,
    position: isToday ? 0 : pnl,
    total: pnl
  };
}; // export const ExistingPositionsPnL = () => {
//     const positionPnL = positionDailyPnL();
// }
// export const PositionPnL = () => {
//     const positionsTradedToday = (quantityBought * (lastPrice - avgBoughtPrice) - quantitySold * (lastPrice - avgSoldPrice)) * (isOption ? 100 : 1);
//         const positionsExistingBeforeToday = positionOnOpen * (lastPrice - closePrice) * (isOption ? 100 : 1);
//         const combined = tradingPnL + positionPnL;
//         return {
//             positionsTradedToday,
//             positionsExistingBeforeToday,
//             combined,
//         }
// }