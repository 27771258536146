import { Middleware, Dispatch } from 'redux';
import { getType, ActionType } from 'typesafe-actions';
import dayjs from 'dayjs';
import _ from 'lodash';
import { TransferPendingStates, UserRole } from '@tradingblock/types';
import { DataState } from '../../state';
import { RootAction, NotificationActions, SessionActions } from '../../actions';
import { CashieringEventActions } from '../../actions/CashieringEventActions';
import { ApiProvider } from '../../../../context/Api';

const timeout = {
  set: (timer: NodeJS.Timeout) => {
    (window as any).cashieringUpdateTimeout = timer;
  },
  clear: () => {
    const existingTimer = (window as any).cashieringUpdateTimeout;
    if (existingTimer) {
      console.debug('clearing cashiering update timeout');
      clearInterval(existingTimer);
    }
  },
};

const search = (state: DataState, dispatch: Dispatch<RootAction>) => {
  const api = ApiProvider(state, dispatch);

  return {
    request: async (action: ActionType<typeof CashieringEventActions.cashieringPendingSearchRequest>) => {
      if (
        state.account.profile.current &&
        state.account.profile.current.roles &&
        state.account.profile.current.roles.includes(UserRole.CashieringApproval)
      ) {
        try {
          const searchResults = await api.cashiering.search(action.payload);
          const latestDate = state.cashiering.latestPendingCashieringEventDate;
          const latest = latestDate ? dayjs(latestDate) : undefined;
          if (latest) {
            const eventsLaterThanLatest = _.filter(searchResults.payload.data, d => {
              const ed = dayjs(d.date);
              return ed.isAfter(latest);
            });
            if (eventsLaterThanLatest.length > 0) {
              console.debug(`found ${eventsLaterThanLatest.length} cashiering updates!`);
            }
          }

          dispatch(
            CashieringEventActions.cashieringPendingSearchReceive({ response: searchResults, request: action.payload })
          );
        } catch (err) {
          dispatch(CashieringEventActions.cashieringPendingSearchError(err));
        }
      }
    },
  };
};

const startPolling = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof CashieringEventActions.beginPollingForPending>,
  previousState: DataState
) => {
  const { timeoutMS } = action.payload;
  timeout.clear();
  console.debug(`requesting cashiering updates every ${timeoutMS} ms`);
  if (previousState.cashiering.isPollingForPending === false) {
    console.debug('making initial cashiering update request');
    dispatch(
      CashieringEventActions.cashieringPendingSearchRequest({
        status: TransferPendingStates,
        sortByOrder: 'desc',
        sortBy: 'Date',
        pageSize: 25,
        page: 0,
      })
    );
  }
  timeout.set(
    setInterval(() => {
      console.debug('requesting cashiering entity updates');
      dispatch(
        CashieringEventActions.cashieringPendingSearchRequest({
          status: TransferPendingStates,
          sortByOrder: 'desc',
          sortBy: 'Date',
          pageSize: 25,
          page: 0,
        })
      );
    }, timeoutMS)
  );
};

const stopPolling = async (state: DataState, dispatch: Dispatch<RootAction>) => {
  timeout.clear();
  console.debug(`stopped cashiering update polling`);
};

export const CashieringEventMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  try {
    const previousState = getState();

    const result = next(action);
    // state AFTER action is dispatched
    const nextState = getState();
    const searchHandler = search(nextState, dispatch);
    switch (action.type) {
      case getType(CashieringEventActions.beginPollingForPending): {
        startPolling(nextState, dispatch, action, previousState);
        break;
      }
      case getType(CashieringEventActions.stopPollingForPending): {
        stopPolling(nextState, dispatch);
        break;
      }
      case getType(CashieringEventActions.cashieringPendingSearchRequest): {
        searchHandler.request(action);
        break;
      }
      case getType(SessionActions.expired): {
        dispatch(CashieringEventActions.stopPollingForPending());
        break;
      }
    }

    return result;
  } catch (err) {
    console.error('CashieringEventMiddleware :: Caught an exception for action ', action, err);
  }
};
