import React, { useCallback } from 'react';
import { BlockType } from '@tradingblock/types';
import { BlockDragHandle, ContactPhone, getBlockTypeName } from '@tradingblock/components';
import { useStateSelector } from '../../data/global/dataSelectors';

interface HeaderProps {
  blockType: BlockType;
}

export const AccountManagementHeader: React.FunctionComponent<HeaderProps> = ({ blockType }) => {
  const accountTitle = useStateSelector(s => s.account.account && s.account.account.AccountTitle);
  const accountNumber = useStateSelector(s => s.account.accountNumber);
  const accountStatus = useStateSelector(s => s.account.accountDetails && s.account.accountDetails.accountStatus);
  const accountManagementDetails = useStateSelector(s => s.accountManagement.accountManagementDetails.details);
  const accountFlexibilityType =
    accountManagementDetails && accountManagementDetails.flexibilityType
      ? accountManagementDetails.flexibilityType[0]
      : '';
  // account status can be multi-worded without spaces, so we need to add them
  const getAccountStatus = useCallback((status: string) => {
    // format status to place a space between words
    const formattedStatus = status.replace(/([A-Z])/g, ' $1').trim();
    switch (status) {
      case 'Active':
        return <span style={{ color: 'var(--green)', fontWeight: 500 }}>{formattedStatus}</span>;
      case 'Inactive':
        return <span style={{ color: 'var(--salmon)', fontWeight: 500 }}>{formattedStatus}</span>;
      case 'Closed':
        return <span style={{ color: 'var(--salmon)', fontWeight: 500 }}>{formattedStatus}</span>;
      case 'ActiveClosingTradesOnly':
        return <span style={{ color: 'var(--gold)', fontWeight: 500 }}>{formattedStatus}</span>;
      case 'ProcessingItemsMissing':
        return (
          <>
            <span className="warn" style={{ fontWeight: 500 }}>
              {formattedStatus}
            </span>
            <br />
            <span className="txt-sm mute">
              Please contact us at <ContactPhone accountNumber={accountNumber} /> if you have any questions or concerns.
            </span>
          </>
        );
      default:
        return (
          <span className="warn" style={{ fontWeight: 500 }}>
            {formattedStatus}
          </span>
        );
    }
  }, []);

  return (
    <>
      <div className="block-nav nav">
        <BlockDragHandle className="nav-item">{getBlockTypeName(blockType)} </BlockDragHandle>
      </div>
      <span className="txt-sm pt-2">
        <span className="mute">
          {accountTitle} - #{accountNumber} - {accountFlexibilityType && `${accountFlexibilityType} - `}
        </span>{' '}
        {getAccountStatus(accountStatus || '')}
      </span>
    </>
  );
};
