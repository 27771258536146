import React, { useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps, Redirect, useHistory } from 'react-router-dom';
import { Formik, FormikErrors } from 'formik';
import _ from 'lodash';
import { CashieringView, CashieringRoute, WireType, CashieringManageAction } from '@tradingblock/types';
import { AlertSection, getAlertProps, Loading } from '@tradingblock/components';
import {
  getCurrentTransferView,
  getTransferViewSubtitle,
  getTransferRouteTitle,
  getViewPrimaryButton,
  getNextTransferView,
  showPrimaryButton,
} from '../../utilities/cashiering';
import { CashieringViewFormValues, useCashieringView } from './cashiering/data/useCashieringView';
import { useCashieringDataContext, useCashieringData } from './cashiering/data/useCashieringData';
import { ScreenWrapper } from './ScreenWrapper';
import { TradingBlockLink } from '../../components/basic/TradingBlockLink';
import { useStateSelector } from '../../data/global/dataSelectors';
import { account } from '../../data/global/selectors/accountSelectors';
import { useDispatch } from 'react-redux';
import { DataActions } from '../../data/global/actions';

interface CashieringScreenRouteProps {
  route: CashieringRoute;
}

export const BaseCashieringView: React.FC<RouteComponentProps<CashieringScreenRouteProps>> = ({ match }) => {
  const route = match.path === '/accounts/oauth' ? CashieringRoute.Accounts : match.params.route;
  return (
    <useCashieringDataContext.Provider>
      <BaseCashieringViewContent route={route} />
    </useCashieringDataContext.Provider>
  );
};

const BaseCashieringViewContent: React.FC<CashieringScreenRouteProps> = ({ route }) => {
  const view = getCurrentTransferView();

  const subAccountId = useStateSelector(account.subAccountId);
  const subAccounts = useStateSelector(account.subAccounts);
  const positionsLoaded = useStateSelector(s => s.positions.loaded);
  const accountId = useStateSelector(s => s.account.accountId);
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    action,
    setAction,
    formStatus,
    setFormStatus,
    closeScreen,
    setCloseScreen,
    gotoNextView,
  } = useCashieringDataContext();

  const { isSaving, isFetching } = useCashieringData(route, view);

  const [TransferViewComponent, onViewSubmit, onViewValidate] = useCashieringView(route, view, action);

  useEffect(() => {
    if (!positionsLoaded) {
      dispatch(DataActions.requestPositions({ accountId, more: true }, { debounce: 1000 }));
    }
  }, [positionsLoaded, accountId, dispatch]);

  const isPrimaryButtonDisabled = useCallback(
    (errors: FormikErrors<CashieringViewFormValues>) => {
      // disable primary continue button on authorize view if field errors present (i.e. empty field)
      return view === CashieringView.Authorize && action !== CashieringManageAction.Close && !_.isEmpty(errors);
    },
    [view, action]
  );
  const routeTitle = useMemo(() => getTransferRouteTitle(route), [route]);
  const viewSubtitle = useMemo(() => getTransferViewSubtitle(view), [view]);
  const showCancel = useMemo(() => view === CashieringView.Review || action === CashieringManageAction.Delete, [
    view,
    action,
  ]);
  const showConfirmClose = useMemo(() => _.isUndefined(closeScreen), [closeScreen]);

  const onClose = useCallback(() => {
    const confirmationNeeded = !_.includes(
      [CashieringView.Authorize, CashieringView.Amount, CashieringView.Manage],
      view
    );
    // if we need to ask for confirmation of closing screen, set closeScreen = undefined
    if (confirmationNeeded || (action && action !== CashieringManageAction.Close)) {
      setCloseScreen(undefined);
    } else {
      setCloseScreen(true);
    }
  }, [view, action]);

  const onCancelClick = useCallback(() => {
    // cancel current action, otherwise, treat like close click
    if (action) {
      setAction(undefined);
    } else {
      onClose();
    }
  }, [action]);

  const onValidate = (values: CashieringViewFormValues) => {
    if (onViewValidate) {
      return onViewValidate(values);
    }
    return {};
  };

  const onSubmit = useCallback(
    (values: CashieringViewFormValues) => {
      if (onViewSubmit) {
        const success = onViewSubmit(values, view);
        if (success) {
          setCloseScreen(true);
        }
      } else {
        const nextView = getNextTransferView(view, route);
        if (nextView) {
          gotoNextView(nextView, history);
        }
      }
    },
    [route, view, onViewSubmit, history]
  );

  const onHandleSubmit = (handler: () => void, e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }
    // set current view and submitted flag, clearing any previous status messages
    setFormStatus({ view, submitted: true });
    // fire formik handler
    handler();
  };

  const onHandleSubmitClick = (handler: () => void, e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.blur();
    onHandleSubmit(handler);
  };

  if (closeScreen) {
    return <Redirect to="/" />;
  }

  // send to default view if not set or trying to skip ahead without saving
  if (route && (!view || (view !== CashieringView.Authorize && !formStatus.view))) {
    window.location.hash = CashieringView.Authorize;
  }

  return (
    <ScreenWrapper
      showConfirmClose={showConfirmClose}
      screenType="transfer"
      onClose={() => setCloseScreen(true)}
      onCancel={() => setCloseScreen(false)}
    >
      <div className="screen-header">
        <div className="screen-title">
          <span className="txt-lg">
            {routeTitle}
            {viewSubtitle ? ` - ${viewSubtitle}` : ''}
          </span>
        </div>
        <div className="screen-help">
          <div className="screen-help-long">
            Need help? <TradingBlockLink to="CustomerServiceUrl">Contact us</TradingBlockLink>
          </div>
          <div className="screen-help-short">
            <TradingBlockLink to="CustomerServiceUrl">
              <i className="fal fa-comment-lines" title="Need help? Contact us." />
            </TradingBlockLink>
          </div>
        </div>
        <div className="screen-actions">
          <button className="close" type="button" onClick={onClose}>
            Cancel <i className="fal fa-times" />
          </button>
        </div>
      </div>

      <div className="screen-body">
        <Formik
          initialValues={{
            transfer: {
              type: WireType.Domestic,
              disbursementType: 'PartialBalance',
              mechanism: 'Ach',
              subAccountId,
              subAccountsValues: {},
            },
          }}
          enableReinitialize={false}
          validate={onValidate}
          // validateOnBlur={true}
          validateOnChange={true}
          onSubmit={onSubmit}
        >
          {({ values, setValues, handleSubmit, touched, errors }) => (
            <form id="transfer" onSubmit={e => onHandleSubmit(handleSubmit, e)}>
              <TransferViewComponent values={values} setValues={setValues}>
                {!isSaving && (formStatus.submitted || formStatus.alertType) && (
                  <AlertSection {...getAlertProps(errors, formStatus)} />
                )}
              </TransferViewComponent>
              {showPrimaryButton({ view, route, values, action }) && (
                <div className={`screen-footer ${isFetching || showCancel ? 'screen-footer-justified' : ''}`}>
                  {isFetching && <Loading size="small" />}
                  {!isFetching && showCancel && (
                    <button className="btn btn-blend" disabled={isSaving} onClick={onCancelClick} type="button">
                      <i className="fal fa-arrow-left" />{' '}
                      {action === CashieringManageAction.Delete
                        ? 'Do not delete'
                        : `Cancel ${route === CashieringRoute.Withdraw ? 'withdrawal' : route}`}
                    </button>
                  )}
                  {getViewPrimaryButton({ view, route, action, values }, isPrimaryButtonDisabled(errors), isSaving, e =>
                    onHandleSubmitClick(handleSubmit, e)
                  )}
                </div>
              )}
            </form>
          )}
        </Formik>
      </div>
    </ScreenWrapper>
  );
};
