/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useEffect, useState } from 'react';
import { useBlockState } from '../components/blocks/BlockState';
import { BlockGroupState } from '@tradingblock/types';
import { useStateSelector, blockGroupId, blockGroup } from '../data/global/dataSelectors';
import _ from 'lodash';

// function useBlockGroups() {
//   const { state, dispatch } = BlockGroupUseContext();
//   return { state, dispatch };
// }

export const useBlockGroupInfo = () => {
  const blockId = useBlockState('blockId');
  const groupId = useStateSelector(s => blockGroupId(s, blockId));
  return { blockId, groupId };
};

export function useBlockGroup() {
  const blockId = useBlockState('blockId');
  const group = useStateSelector(s => blockGroup(s, blockId));

  return useMemo(() => group, [group]);
}
export function useBlockGroupField<K extends keyof BlockGroupState>(field: K) {
  const state = useBlockGroup();
  return useMemo(() => (state ? state[field] : undefined), [state, field]);
}

export function useGroupState<K extends keyof BlockGroupState>(field: K) {
  const state = useBlockGroup();
  const value = useMemo(() => (state ? state[field] : undefined), [field, state]);
  return [value, state !== undefined] as [BlockGroupState[K] | undefined, boolean];
}
export const useGroupAssetSymbol = () => {
  const [symbol] = useGroupState('symbol');
  return symbol;
};
// export function useGroupSymbol() {
//   const [symbol] = useGroupState('symbol');
//   return symbol;
// }
// type stringOrUndefined = string | undefined;
// export function useGroupSymbolValue(defaultValue?: string) {
//   const symbol = useGroupSymbol();
//   return symbol ? symbol.symbol : defaultValue;
// }
export function useGroupStateHandler<K extends keyof BlockGroupState, H extends (v: BlockGroupState[K] | undefined, groupId?: string) => void>(field: K, handler: H, options: { fireOnGroupChange: boolean; fireOnUndefined?: boolean }) {
  const handleIfGroupChange = options.fireOnGroupChange;
  const fireOnUndefined = options.fireOnUndefined === true;
  // const [value, hasValue] = useGroupState(field);
  // const { state } = useBlockGroup();
  const fieldValue = useBlockGroupField(field);
  const [currentFieldValue, setCurrentFieldValue] = useState<BlockGroupState[K] | undefined>();
  const { groupId } = useBlockGroupInfo();
  //const fieldValue = useMemo(() => (state ? state[field] : undefined), [field, state]);
  useEffect(() => {
    if (fieldValue && !_.isEqual(currentFieldValue, fieldValue)) {
      handler(fieldValue, groupId);
      setCurrentFieldValue(fieldValue);
    } else if (fireOnUndefined && !_.isEqual(currentFieldValue, fieldValue)) {
      handler(fieldValue, groupId);
      setCurrentFieldValue(fieldValue);
    }
  }, [fieldValue, handler]);
  useEffect(() => {
    if (groupId && handleIfGroupChange) {
      handler(fieldValue, groupId);
    }
  }, [groupId]);
}
