import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
var channelToSubscription = new Map();
export var subscribeOnStream = function subscribeOnStream(symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback, lastDailyBar) {
  var handler = {
    id: subscribeUID,
    callback: onRealtimeCallback
  };
  var channelString = "0~".concat(symbolInfo.exchange, "~").concat(symbolInfo.name, "~").concat(symbolInfo.name, "~").concat(resolution);
  var subscriptionItem = channelToSubscription.get(channelString);

  if (subscriptionItem) {
    subscriptionItem.handlers.push(handler);
    return;
  }

  subscriptionItem = {
    subscribeUID: subscribeUID,
    resolution: resolution,
    lastDailyBar: lastDailyBar,
    handlers: [handler]
  };
  channelToSubscription.set(channelString, subscriptionItem);
};
export var handleUpdates = function handleUpdates(symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback, quote, isHeikinAshi) {
  var LastTradePrice = quote.LastTradePrice,
      ReceivedTime = quote.ReceivedTime;
  var channelString = "0~".concat(symbolInfo.exchange, "~").concat(symbolInfo.name, "~").concat(symbolInfo.name, "~").concat(resolution);
  var subscriptionItem = channelToSubscription.get(channelString);

  if (subscriptionItem === undefined) {
    return;
  }

  var barTime = dayjs(ReceivedTime, 'MM/DD/YYYY HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss') + 'Z';
  var date = new Date(barTime);
  var dateValue = dayjs(date);
  var latestExistingBarTime = dayjs(subscriptionItem.lastDailyBar.time);
  var time = latestExistingBarTime.unix() > dateValue.unix() ? latestExistingBarTime : dateValue;
  var lastDailyBar = subscriptionItem.lastDailyBar;
  var isNextBar = dateValue.unix() >= latestExistingBarTime.unix();
  var bar; // heikin ashi calculations

  var haOpen = (lastDailyBar.open + lastDailyBar.close) / 2;
  var haClose = (quote.LastTradePrice + quote.LastTradePrice + quote.LastTradePrice + quote.LastTradePrice) / 4;
  var haHigh = Math.max(quote.LastTradePrice, lastDailyBar.open, lastDailyBar.close);
  var haLow = Math.min(quote.LastTradePrice, lastDailyBar.open, lastDailyBar.close);

  if (isNextBar) {
    bar = {
      time: time.valueOf(),
      open: resolution === '1D' ? lastDailyBar.open : isHeikinAshi ? haOpen : LastTradePrice,
      high: resolution === '1D' ? lastDailyBar.high : isHeikinAshi ? haHigh : LastTradePrice,
      low: resolution === '1D' ? lastDailyBar.low : isHeikinAshi ? haLow : LastTradePrice,
      close: isHeikinAshi ? haClose : LastTradePrice,
      volume: resolution === '1D' ? lastDailyBar.volume : isHeikinAshi ? quote.LastTradeVolume + lastDailyBar.volume : quote.LastTradeVolume
    };
  } else {
    bar = Object.assign({}, lastDailyBar, {
      high: Math.max(lastDailyBar.high, LastTradePrice),
      low: Math.min(lastDailyBar.low, LastTradePrice),
      close: LastTradePrice,
      time: time.valueOf(),
      volume: lastDailyBar.volume + quote.LastTradeVolume
    });
  }

  subscriptionItem.lastDailyBar = bar;
  subscriptionItem.handlers.forEach(function (handler) {
    handler.callback(bar);
  });
};
export var unsubscribeFromStream = function unsubscribeFromStream(subscribeUID) {
  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = channelToSubscription.keys()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var channelString = _step.value;
      var subscriptionItem = channelToSubscription.get(channelString);
      var handlerIndex = subscriptionItem.handlers.findIndex(function (handler) {
        return handler.id === subscribeUID;
      });

      if (handlerIndex !== -1) {
        subscriptionItem.handlers.splice(handlerIndex, 1);

        if (subscriptionItem.handlers.length === 0) {
          channelToSubscription.delete(channelString);
          break;
        }
      }
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator.return != null) {
        _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }
};