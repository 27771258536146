import { Dispatch, Middleware } from 'redux';
import { getType } from 'typesafe-actions';
import _ from 'lodash';
import {
  AccountInfo,
  APINotAuthorizedResponseCode,
  ExpiredTokenCode,
  ResponseCode,
  UserProfileLevel,
} from '@tradingblock/types';
import { DataState } from '../../state';
import { Actions, DataActions, RootAction, SessionActions, UIActions } from '../../actions';
import { AccountActions } from '../../actions/AccountActions';
import { apiTokenSelector } from '../../dataSelectors';
import { buildApiClient, ApiProvider } from '../../../../context/Api';
import Config from '../../../../config';
import { AccountApiFactory } from '@tradingblock/api';
import { AccountManagementDataActions } from '../../actions/AccountManagementActions';

const tryLoadProfile = async (state: DataState, dispatch: Dispatch<RootAction>) => {
  const api = ApiProvider(state, dispatch);

  if (api) {
    await api.user
      .profile()
      .then(res => {
        dispatch(AccountActions.receiveUserProfile(res));
      })
      .catch(err => dispatch(AccountActions.errorUserProfile(err)));
  } else {
    console.warn('not loading profile because either accountId or Token are undefined');
  }
};

const tryLoadAccount = async (state: DataState, dispatch: Dispatch<RootAction>, id?: number) => {
  // const api = ApiProvider(state);
  const api = buildApiClient(apiTokenSelector(state));
  const selectedLinkedAccountId = state.account.selectedLinkedAccount && state.account.selectedLinkedAccount.accountId;

  if (api) {
    //TODO: in the future admin users should have an account attach to them also we should not need
    // to request the profile to know if the user is an admin or not...
    const defaultAccounts = id
      ? await api.accounts.get(selectedLinkedAccountId ? selectedLinkedAccountId : id)
      : { payload: [], responseCode: 0 };
    const profile = await api.user.profile();
    let responseData = { payload: [], responseCode: 0 } as { payload: AccountInfo[]; responseCode: ResponseCode };
    if (profile.payload !== undefined && profile.payload.level !== UserProfileLevel.Admin) {
      const request = api.accounts.get(
        selectedLinkedAccountId ? selectedLinkedAccountId : profile.payload.defaultAccountId
      );
      responseData = await request;
    }
    if (
      (responseData && responseData.responseCode === APINotAuthorizedResponseCode) ||
      defaultAccounts.responseCode === APINotAuthorizedResponseCode
    ) {
      dispatch(SessionActions.expired({ code: ExpiredTokenCode.ApiTokenExpired }));
    } else {
      //If we could not find a default account in the
      if (responseData.payload.find((account: any) => id && account.AccountId === id) === undefined) {
        responseData.payload = [...defaultAccounts.payload, ...responseData.payload];
      }
      // also fetch account details and roles
      //TODO: Need to better type this any, but we do not have network payloads documented/typed
      const details = await api.accounts.details(
        selectedLinkedAccountId ? selectedLinkedAccountId : id ? id : responseData.payload[0].AccountId
      );
      responseData = {
        ...responseData,
        payload: _.reduce(
          responseData.payload,
          (acc: AccountInfo[], account) => {
            acc.push({
              ...account,
              Roles: details.payload.accountNumber === account.AccountNumber ? details.payload.roles : [],
              AccountType:
                details.payload.accountNumber === account.AccountNumber
                  ? details.payload.accountType
                  : account.AccountType,
            });
            return acc;
          },
          []
        ),
      };
      dispatch(AccountActions.receiveAccounts(responseData));
      dispatch(Actions.receiveCurrentAccountDetails(details.payload));
    }
  } else {
    console.warn('not loading account because either accountId or Token are undefined');
  }
};

const tryLoadSubAccounts = async (state: DataState, dispatch: Dispatch<RootAction>, accountId: number) => {
  const api = ApiProvider(state, dispatch);
  const selectedLinkedAccountId = state.account.selectedLinkedAccount && state.account.selectedLinkedAccount.accountId;
  if (api) {
    await api.accounts
      .subaccounts(selectedLinkedAccountId ? selectedLinkedAccountId : accountId)
      .then(res => {
        dispatch(AccountActions.receiveSubAccounts(res));
      })
      .catch(err => dispatch(AccountActions.requestSubAccountsError(err)));
  } else {
    console.warn('not loading subaccounts because either accountId or Token are undefined');
  }
};

const tryLoadAccountEntitlements = async (state: DataState, dispatch: Dispatch<RootAction>, accountId: number) => {
  const api = ApiProvider(state, dispatch);

  if (api) {
    await api.accounts
      .entitlements()
      .then(res => {
        dispatch(AccountActions.receiveAccountEntitlement(res));
      })
      .catch(err => dispatch(AccountActions.errorAccountEntitlement(err)));
  } else {
    console.warn('not loading entitlements because either accountId or Token are undefined');
  }
};

const setAccount = async (state: DataState, dispatch: Dispatch<RootAction>, accountId: number) => {
  const apiRootUrl = Config.tradingApi;
  const token = state.auth.apiToken;
  const accountApi = token && accountId && AccountApiFactory(apiRootUrl, token, accountId);

  const selectedAccount =
    (state.linkedAccounts.accounts && state.linkedAccounts.accounts.find(account => account.accountId === accountId)) ||
    (state.account.selectedLinkedAccount && state.account.selectedLinkedAccount.originalAccount.accountId);

  if (accountApi && selectedAccount) {
    dispatch(AccountActions.setLinkedAccountLoaded(false));
    dispatch(UIActions.setSubAccountFilterId({ id: undefined }));
    _.delay(() => {
      dispatch(AccountActions.setAccount({ accountId }));
    }, 250);

    _.delay(() => {
      dispatch(AccountActions.requestAccounts({ id: accountId }));
    }, 500);

    _.delay(() => {
      dispatch(AccountActions.requestSubAccounts({ accountId }));
    }, 750);

    _.delay(() => {
      dispatch(DataActions.requestPositions({ accountId, more: true }, { debounce: 1000 }));
    }, 1000);

    _.delay(() => {
      dispatch(Actions.requestAccountBalances({ throttle: true }));
    }, 1250);

    //DashboardNavigation already making this call
    // _.delay(() => {
    //   dispatch(Actions.requestAccountOrders({ accountId }, { debounce: 1000 }));
    // }, 1500);

    _.delay(() => {
      dispatch(AccountManagementDataActions.requestAccountDetails({ accountId }));
    }, 1750);

    _.delay(() => {
      dispatch(AccountManagementDataActions.requestAccountManagementDetails({ accountId }));
    }, 2000);

    _.delay(() => {
      dispatch(AccountManagementDataActions.requestPendingAccountManagementUpdates({ accountId }));
    }, 2250);

    _.delay(() => {
      dispatch(AccountActions.setLinkedAccountLoaded(true));
    }, 4000);
  }
};

export const AccountMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  try {
    // state BEFORE action is dispatched
    const prevState = getState();
    const result = next(action);
    // state AFTER action is dispatched
    // const nextState = getState();

    switch (action.type) {
      case getType(AccountActions.requestAccountEntitlement):
        tryLoadAccountEntitlements(prevState, dispatch, action.payload);
        break;
      case getType(AccountActions.loadUserData): {
        Promise.all([
          tryLoadAccount(prevState, dispatch, action.payload.accountId),
          tryLoadSubAccounts(prevState, dispatch, action.payload.accountId),
          tryLoadProfile(prevState, dispatch),
        ]);
        break;
      }
      case getType(AccountActions.requestAccounts): {
        tryLoadAccount(prevState, dispatch, action.payload.id);
        break;
      }
      case getType(AccountActions.requestSubAccounts): {
        tryLoadSubAccounts(prevState, dispatch, action.payload.accountId);
        break;
      }
      case getType(AccountActions.requestUserProfile): {
        tryLoadProfile(prevState, dispatch);
        break;
      }
      case getType(AccountActions.retrieveSetAccountData): {
        setAccount(prevState, dispatch, action.payload.accountId);
        break;
      }
    }

    return result;
  } catch (err) {
    console.error('accountMiddleware :: Caught an exception for action ', action, err);
  }
};
