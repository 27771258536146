import React from 'react';
import { useSelector } from 'react-redux';
import { TradingBlockLink as BaseTradingBlockLink, TradingBlockLinkProps } from '@tradingblock/components';
import { account } from '../../data/global/selectors/accountSelectors';
import { origin } from '../../data/global/selectors/originSelector';

export const TradingBlockLink: React.FC<Pick<TradingBlockLinkProps, 'to' | 'className'>> = ({ children, ...props }) => {
  const accountNumber = useSelector(account.accountNumber);
  const originVal = useSelector(origin.origin);
  return (
    <BaseTradingBlockLink accountNumber={accountNumber} origin={originVal} {...props}>
      {children}
    </BaseTradingBlockLink>
  );
};
