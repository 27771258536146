import React, { useEffect, useCallback, useMemo } from 'react';
import _ from 'lodash';

import { Table, useParentSizeContext, DataTableProps, ColumnWithSorting, Loading } from '@tradingblock/components';
import { SizeType, CashieringBaseEntity, CashieringSearchRequest } from '@tradingblock/types';
import { useBboxDimensions } from '../../../../blocks/shared/useBboxDimensions';
import { useDispatcher } from '../../../../data/global/hooks';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { isFetchingCashieringSearchEntities } from '../../../../data/global/selectors/admin/cashieringSelectors';
import { useCashieringData } from '../useCashiering';
import {
  AdminCashieringActions,
  AdminCashieringSearchActions,
} from '../../../../data/global/actions/admin/AdminCashieringActions';

const CashieringTableContent: React.FunctionComponent<DataTableProps<CashieringBaseEntity>> = props => {
  const b = useParentSizeContext();
  const [, ref] = useBboxDimensions<HTMLDivElement>(250);

  return (
    <div ref={ref} className="table-fixed-wrapper">
      <Table {...props} size={SizeType.lg} windowSize={b} />
    </div>
  );
};

export const CashieringTable: React.FunctionComponent<{
  handleSetSelectedRow: (index: number | undefined) => void;
  selectedRowIndex?: number;
  CashieringAdminReviewColumns: ColumnWithSorting<CashieringBaseEntity>[];
}> = ({ handleSetSelectedRow, selectedRowIndex, CashieringAdminReviewColumns }) => {
  const { dispatch } = useDispatcher();
  const { data, total, timeframe, ...filterData } = useCashieringData();
  const { sortBy, sortByOrder, pageSize, page } = filterData;
  const [isFetching, hasData] = useStateSelector(isFetchingCashieringSearchEntities);
  const cashieringSearch = sessionStorage.getItem('cashieringSearch');

  useEffect(() => {
    if (hasData && !isFetching) {
      if (!cashieringSearch) return;
      const cashieringSearchData = JSON.parse(cashieringSearch);
      const { index } = cashieringSearchData;
      handleSetSelectedRow(index);
      // scroll into view if the row is not visible
      setTimeout(() => {
        const row = document.querySelector('.selected-row');
        if (row) {
          row.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 750);
      // clear the session storage
      sessionStorage.removeItem('cashieringSearch');
    }
  }, [hasData, isFetching, cashieringSearch]);

  const rowClassName = useCallback(
    (row: any) => {
      if (row.index === selectedRowIndex) {
        return 'selected-row';
      }
      return '';
    },
    [selectedRowIndex]
  );

  useEffect(() => {
    dispatch(AdminCashieringActions.cashieringSearchRequest(filterData));
  }, [dispatch]);

  const getCurrentSortBy = useCallback(
    (newDesc?: boolean) => {
      if (sortBy) {
        return { id: sortBy, desc: !_.isUndefined(newDesc) ? newDesc : sortByOrder === 'desc' };
      }
      return undefined;
    },
    [sortBy, sortByOrder]
  );

  const initialState = useMemo(() => {
    const currentSortBy = getCurrentSortBy();
    return {
      sortBy: currentSortBy ? [currentSortBy] : [],
      pageSize: _.isUndefined(pageSize) ? 5 : pageSize,
      pageIndex: page || 0,
      columnOrder: [],
    };
  }, [pageSize, page, getCurrentSortBy]);

  const pageCount = useMemo(() => {
    if (pageSize) {
      return _.ceil(total / pageSize);
    }
    return 0;
  }, [pageSize, total]);

  const onSort = useCallback(
    (sort: any[]) => {
      const sortVal = _.first(sort) || getCurrentSortBy(sortByOrder === 'asc');
      if (sortVal) {
        const { id, desc } = sortVal;
        const sortData: Pick<CashieringSearchRequest, 'sortBy' | 'sortByOrder'> = {
          sortBy: id,
          sortByOrder: desc === true ? 'desc' : 'asc',
        };
        dispatch(AdminCashieringSearchActions.setSortBy(sortData));
      } else {
        dispatch(AdminCashieringSearchActions.setSortBy({ sortBy: undefined, sortByOrder: undefined }));
      }
      handleSetSelectedRow(undefined);
    },
    [getCurrentSortBy]
  );

  const onPage = useCallback((page: number) => {
    dispatch(AdminCashieringSearchActions.setPage({ page }));
    handleSetSelectedRow(undefined);
  }, []);

  const onPageSize = useCallback((pageSize: number) => {
    dispatch(AdminCashieringSearchActions.setPageSize({ pageSize }));
    handleSetSelectedRow(undefined);
  }, []);

  //This is used to simplify data, since our columns only support specific lengths
  // We only will allow the transfer text to be 24 chars.
  const simplifiedData = useMemo(
    () =>
      data.map(cashieringItem => {
        if (cashieringItem.transfer && cashieringItem.transfer.text && cashieringItem.transfer.text.length >= 24) {
          cashieringItem.transfer.text = cashieringItem.transfer.text.slice(0, 21) + '...';
        }
        // attach the handleSetSelectedRow function to each row
        cashieringItem.handleSetSelectedRow = handleSetSelectedRow;
        return cashieringItem;
      }),
    [data]
  );

  return (
    <>
      {isFetching && <Loading />}
      <useParentSizeContext.Provider>
        {!isFetching && (
          <>
            {hasData && (
              <CashieringTableContent
                columns={CashieringAdminReviewColumns}
                data={simplifiedData}
                initialState={initialState}
                sortable
                hasPagination
                manualSortBy
                manualPagination
                onSort={onSort}
                onPage={onPage}
                onPageSize={onPageSize}
                pageCount={pageCount}
                columnWidths={['10%', '10%', '5%', '30%', '10%', '10%', '15%', '1%', '7%', '1%', '1%']}
                rowClass={(row: any) => rowClassName(row)}
                tableKey="cashieringReviewTable"
              />
            )}
            {!hasData && <p style={{ padding: '5px' }}>No matching items found</p>}
          </>
        )}
      </useParentSizeContext.Provider>
    </>
  );
};
