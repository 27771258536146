import React, { useEffect } from 'react';
import { useStateSelector } from '../../data/global/dataSelectors';
import { useDispatcher } from '../../data/global/hooks';
import { useWhyDidYouUpdate } from 'use-hooks';

export const PositionSync: React.FunctionComponent<{}> = () => {
  const shouldReload = useStateSelector(s => s.positions.isDirty === true && s.positions.isFetching === false);
  const { dispatcher } = useDispatcher();
  useWhyDidYouUpdate('PositionSync', { shouldReload });
  useEffect(() => {
    dispatcher.data.positions.request();
  }, [dispatcher.data.positions]);
  return <></>;
};
