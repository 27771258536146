export const Colors = {
  primary: '#031237',
};

export const Websockets = {
  ReconnectInterval: 10000,
};

export const OptionChain = {
  MaxExpandedExpirations: 4,
};

export const Cashiering = {
  MaxAchRelationships: 3,
};

export const Notifications = {
  DataFeedKey: 'DataFeedGlobal',
};

export const OrderValidationTimeoutMS = 500;

export const RequestDebounceMS = {
  getQuote: 250,
};
export const RequestThrottle = {
  getStrike: { maxRequests: 4, requestWindowMs: 1000, duplicateRequestIntervalMs: 10000 },
};
export type TradingEnvironments = 'dev' | 'beta' | 'production' | 'local';
export const TradingEnvironment: Record<TradingEnvironments, TradingEnvironments> = {
  dev: 'dev',
  beta: 'beta',
  production: 'production',
  local: 'local',
};

export const LocalStorageKey = {
  enableRedux: 'enableRedux',
};

export const Session = {
  inactivityTimeout: 1000 * 60 * 60 * 2,
};
