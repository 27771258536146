import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import _ from 'lodash';
import { BasicCheckboxInput, getClassNames, Modal } from '@tradingblock/components';
import { OrderNotification } from '@tradingblock/types';
import {
  useStateSelector,
  getGlobalErrorOrWarning,
  getGlobalMessage,
  getModalError,
} from '../../data/global/dataSelectors';
import { useDispatcher } from '../../data/global/hooks';
import { PreviewContent } from '../../blocks/Order/components/Preview/PreviewContent';
import { getOrderPreviewLegs } from '../../blocks/Order/components/Preview/useOrderPreview';
import { Config } from '../../config';
import { TradingEnvironment } from '../../constants';
import { IsVirtualEnvironment } from '../../data/global/selectors/environmentSelector';
import { useDispatch } from 'react-redux';
import { OrderActions } from '../../data/global/actions';

const envClass = () => {
  const env = Config.TradingEnvironment;
  switch (env) {
    case TradingEnvironment.dev:
      return 'env-dev';
    case TradingEnvironment.beta:
      return 'env-beta';
    default:
      return 'env-prod';
  }
};

const Dashboard: React.FC<{ className: string }> = ({ className, children }) => {
  const globalErrorOrWarningNotifications = useStateSelector(getGlobalErrorOrWarning);
  const globalMessage = useStateSelector(getGlobalMessage);
  const isExpired = useStateSelector(s => s.auth.isExpired);

  const dashboardErrorClass = useMemo(() => {
    if (globalErrorOrWarningNotifications && !isExpired) {
      return `has-alert ${globalErrorOrWarningNotifications.overlay ? ' mask-on' : ''}`;
    } else if (globalMessage) {
      return `has-alert ${globalMessage.overlay ? ' mask-on' : ''}`;
    } else {
      return '';
    }
  }, [globalErrorOrWarningNotifications, globalMessage]);
  const isVirtualEnv = useStateSelector(IsVirtualEnvironment);
  const dashboardClasses = useMemo(() => {
    return getClassNames([
      'dashboard',
      isVirtualEnv ? 'night' : 'day',
      className,
      dashboardErrorClass,
      envClass(),
      isVirtualEnv ? 'env-virtual' : '',
    ]);
  }, [className, dashboardErrorClass, isVirtualEnv]);
  return <div className={dashboardClasses}>{children}</div>;
};

export const DashboardWrapper: React.FC<{ className: string }> = ({ className, children }) => {
  const { dispatcher } = useDispatcher();

  const isExpired = useStateSelector(s => s.auth.isExpired);
  const [showErrorInfo, setShowErrorInfo] = useState(false);
  const globalErrorOrWarningNotifications = useStateSelector(getGlobalErrorOrWarning);
  const globalMessage = useStateSelector(getGlobalMessage);
  const [bypassWarnings, setBypassWarnings] = useState(false);
  const dispatch = useDispatch();

  const modalError = useStateSelector(getModalError);
  const failedOrder = useMemo(() => {
    const notificationError = modalError as OrderNotification | undefined;
    if (notificationError && notificationError.type === 'Order') {
      return notificationError.order;
    }
    return undefined;
  }, [modalError]);

  const onCloseModal = useCallback(() => {
    if (modalError) {
      dispatcher.notifications.view(modalError);
      if (modalError.code === 307) {
        setBypassWarnings(false);
      }
    }
  }, [modalError, dispatcher]);

  const alertClass = useMemo(() => {
    if (!_.isNil(globalErrorOrWarningNotifications) && globalErrorOrWarningNotifications) {
      if (globalErrorOrWarningNotifications.status === 'Error') {
        return `dashboard-alert error`;
      } else if (globalErrorOrWarningNotifications.status === 'Warn') {
        return `dashboard-alert warn`;
      } else {
        return `dashboard-alert`;
      }
    } else if (!_.isNil(globalMessage)) {
      return `dashboard-alert info`;
    } else {
      return '';
    }
    return '';
  }, [globalErrorOrWarningNotifications, globalMessage]);

  const handleCheck = useCallback(
    (event, value) => {
      setBypassWarnings(value);
    },
    [bypassWarnings]
  );

  const handleSendOrder = useCallback(() => {
    if (failedOrder) {
      const finalOrder = {
        ...failedOrder,
        BypassWarnings: bypassWarnings,
      };
      try {
        dispatch(OrderActions.requestPlaceOrder(finalOrder));
        setTimeout(() => {
          onCloseModal();
        }, 1000);
      } catch (placeOrderError) {
        dispatcher.order.errored(failedOrder, placeOrderError as any);
        console.error('error placing order', placeOrderError);
      }
    }

    onCloseModal();
  }, [failedOrder, bypassWarnings]);

  // don't display global errors if on logout page
  if (window.location.pathname === '/logout') {
    return null;
  }
  return (
    <Dashboard className={className}>
      {globalErrorOrWarningNotifications && !isExpired && (
        <div className={alertClass}>
          {globalErrorOrWarningNotifications.title}
          {globalErrorOrWarningNotifications.message && (
            <button
              className="btn btn-link"
              type="button"
              style={{ paddingTop: '4px' }}
              onClick={() => setShowErrorInfo(true)}
            >
              more info
            </button>
          )}
          {globalErrorOrWarningNotifications.message && (
            <Modal setShow={setShowErrorInfo} show={showErrorInfo}>
              <BootstrapModal.Header closeButton>
                <BootstrapModal.Title>{globalErrorOrWarningNotifications.title}</BootstrapModal.Title>
              </BootstrapModal.Header>
              <BootstrapModal.Body>
                <div dangerouslySetInnerHTML={{ __html: globalErrorOrWarningNotifications.message }} />
              </BootstrapModal.Body>
            </Modal>
          )}
        </div>
      )}
      {globalMessage && (
        <div className={alertClass}>
          {globalMessage.title || ''} {globalMessage.message}
          {globalMessage.message && (
            <a
              className="btn btn-link"
              type="button"
              style={{ paddingTop: '4px' }}
              onClick={() => window.location.reload()}
            >
              Reload
            </a>
          )}
        </div>
      )}

      {modalError && failedOrder && (
        <Modal isError={true} show={true} setShow={onCloseModal}>
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>{modalError.title}</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            <p>The order below was NOT placed due to the following issue:</p>
            <p dangerouslySetInnerHTML={{ __html: modalError.message || '' }}></p>
            <PreviewContent
              isCancelOrder={false}
              order={failedOrder}
              legs={getOrderPreviewLegs(failedOrder)}
              action="error"
            />
            {modalError.code === 307 && (
              <BasicCheckboxInput checked={bypassWarnings} onchange={handleCheck} className="pt-2">
                Bypass Warnings
              </BasicCheckboxInput>
            )}
          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            {!bypassWarnings && (
              <Button variant="primary" onClick={onCloseModal}>
                Close
              </Button>
            )}
            {bypassWarnings && (
              <>
                <Button variant="primary" onClick={handleSendOrder}>
                  Place Order
                </Button>
                <Button variant="secondary" onClick={onCloseModal}>
                  Close
                </Button>
              </>
            )}
          </BootstrapModal.Footer>
        </Modal>
      )}

      {children}
    </Dashboard>
  );
};
