import React, { useState } from 'react';
import { AddReleaseNotes } from './AddReleaseNotes';
import { ReleaseNotesAdminTable } from './ReleaseNotesAdminTable';

export const AdminReleaseNotesIndex = () => {
  const [showAddReleaseNotes, setShowAddReleaseNotes] = useState(false);

  const toggleAddReleaseNotes = () => {
    showAddReleaseNotes ? setShowAddReleaseNotes(false) : setShowAddReleaseNotes(true);
  };

  const handleClick = (event: any) => {
    event.preventDefault();
    switch (event.target.id) {
      case 'addReleaseNotes':
        setShowAddReleaseNotes(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div id="releaseNotesTable">
        <ReleaseNotesAdminTable />
        <br />
        <button id="addReleaseNotes" className="btn btn-primary" onClick={event => handleClick(event)}>
          Add Release Note
        </button>
      </div>
      <AddReleaseNotes toggleModal={toggleAddReleaseNotes} show={showAddReleaseNotes} />
    </>
  );
};
