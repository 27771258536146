import { OutputSelector, createSelector } from 'reselect';
import _ from 'lodash';
import { HistoryItem, IBlockDataState, Order } from '@tradingblock/types';
import { useBlockData } from '../../../components/blocks/BlockState';
import { WithTables } from '../../../components/blocks/state/blockTable/BlockTableState';

export interface ActivityState extends WithTables<IBlockDataState> {
  startDate?: Date;
  endDate?: Date;
  history?: HistoryItem[];
  order?: Order[];
}

export const InitialState: ActivityState = {
  startDate: undefined,
  endDate: undefined,
  isDirty: false,
  tables: {},
};
type Selector<T> = OutputSelector<ActivityState, T, (res: ActivityState) => T>;
type Transform<T> = (res: ActivityState) => T;
export function useActivityData<T>(selector: Selector<T> | Transform<T>, defaultVal?: T) {
  const data = useBlockData<ActivityState>();
  const res = selector(data);
  return defaultVal && _.isNil(res) ? defaultVal : res;
}
export function useActivityDataSelector<T>(selector: (res: ActivityState) => T, defaultVal?: T) {
  const data = useBlockData<ActivityState>();
  const select = createSelector(
    (s: ActivityState) => s,
    selector
  );
  const res = select(data);
  return defaultVal && _.isNil(res) ? defaultVal : res;
}
