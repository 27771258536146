import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useState, useCallback, useRef } from 'react';
import { useOnClickOutside } from 'use-hooks';
export function useToggle(initialState) {
  var _useState = useState(initialState),
      _useState2 = _slicedToArray(_useState, 2),
      open = _useState2[0],
      setOpen = _useState2[1];

  var toggle = useCallback(function (value) {
    setOpen(value === undefined ? !open : value);
  }, [open]);
  return [open, toggle];
}
export function useToggleAndClickOutside(initialState) {
  var ref = useRef(null);

  var _useToggle = useToggle(initialState),
      _useToggle2 = _slicedToArray(_useToggle, 2),
      open = _useToggle2[0],
      toggle = _useToggle2[1];

  useOnClickOutside(ref, function () {
    return toggle(initialState);
  });
  return [open, toggle, ref];
}