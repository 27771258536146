import * as tslib_1 from "tslib";
import React, { useMemo } from 'react';
import { useCountdown } from '../../hooks';
import parseMs from 'parse-ms';
export var useParsedCountdown = function useParsedCountdown(until) {
  var intervalTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2000;

  var _useCountdown = useCountdown(function () {
    return until;
  }, {
    intervalTime: intervalTime
  }),
      timeLeft = _useCountdown.timeLeft;

  var parsed = useMemo(function () {
    return parseMs(timeLeft);
  }, [timeLeft]);
  return {
    timeLeft: timeLeft,
    parsed: parsed
  };
};
export var CountdownTimer = function CountdownTimer(_a) {
  var until = _a.until,
      children = _a.children,
      title = _a.title,
      format = _a.format,
      before = _a.before,
      after = _a.after,
      content = _a.content,
      className = _a.className,
      intervalTime = _a.intervalTime,
      props = tslib_1.__rest(_a, ["until", "children", "title", "format", "before", "after", "content", "className", "intervalTime"]); // const timeLeft = useCountdown(() => until, { intervalTime: intervalTime || 2000 });
  // const parsed = useMemo(() => parseMs(timeLeft), [timeLeft]);


  var _useParsedCountdown = useParsedCountdown(until, intervalTime || 2000),
      timeLeft = _useParsedCountdown.timeLeft,
      parsed = _useParsedCountdown.parsed;

  var titleVal = useMemo(function () {
    return title && title(parsed);
  }, [parsed, title]);
  return React.createElement("div", {
    className: className,
    title: titleVal
  }, children, format && format(parsed), before && timeLeft > 0 && React.createElement(React.Fragment, null, before(parsed)), after && timeLeft <= 0 && React.createElement(React.Fragment, null, after()), content && React.createElement(React.Fragment, null, content(timeLeft > 0, parsed)));
};