import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useCashieringActions } from '../useCashiering';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { accountDetailsSelectors } from '../../../../data/global/selectors/admin/accountSelectors';
import { DefaultAccountDetails } from '@tradingblock/types';
import { useDispatch } from 'react-redux';
import { AccountActions } from '../../../../data/global/actions/AccountActions';
import { LinkedAccountsActions } from '../../../../data/global/actions/LinkedAccountsActions';

export const FilterForAccount: React.FunctionComponent<{ accountId: number }> = ({ accountId }) => {
  const { search: onSearch, setPage, setType, setStatus } = useCashieringActions();
  const accountDetails = useStateSelector(s => accountDetailsSelectors.data(s, accountId));
  const { accountNumber, accountNames } = accountDetails || DefaultAccountDetails;
  const handleSetFilter = useCallback(
    (accountNumberValue: string) => {
      setPage(0);
      setType([]);
      setStatus([]);
      onSearch(accountNumberValue.trim());
      sessionStorage.removeItem('cashieringSearch');
    },
    [accountNumber]
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const accountSelectedCallback = () => {
    dispatch(
      AccountActions.retrieveSetAccountData({
        accountId,
        searchOptions: {
          page: 0,
          pageSize: 5,
          sortBy: '',
          sortByOrder: 'desc',
          search: accountNumber || accountNames[0],
          status: '',
        },
      })
    );
    dispatch(AccountActions.requestSubAccounts({ accountId: accountId }));
    history.push('/');
  };

  const switchToAccount = () => {
    dispatch(
      LinkedAccountsActions.requestLinkedAccounts({
        page: 0,
        pageSize: 5,
        sortBy: '',
        sortByOrder: 'desc',
        search: accountNumber || accountNames[0],
        status: '',
      })
    );
    setTimeout(() => accountSelectedCallback(), 1000);
  };

  return (
    <div className="screen-body-section">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Link to="/admin/cashiering" onClick={() => handleSetFilter(accountNumber || accountNames[0])}>
          View all entries for: {accountNumber || accountNames[0]} <i className="fal fa-filter" />
        </Link>

        <div onClick={switchToAccount} className="btn-link">
          Switch to Account >>
        </div>
      </div>
    </div>
  );
};
