import { useMemo } from 'react';
import createCachedSelector from 're-reselect';
import _ from 'lodash';
import { SymbolQuote, SymbolQuoteAndChangeData, isString } from '@tradingblock/api';
import { useWhyDidYouUpdate } from '@tradingblock/components';
import { useStateSelector } from '../data/global/dataSelectors';
import {
  getQuoteMetadata,
  getQuotesBySymbols,
  getQuotesBidAskBySymbols,
  getQuoteData,
  quoteSelectors,
} from '../data/global/selectors/quoteSelector';
import { DataState } from '../data/global/state';
import { useBlockId } from '../components/blocks/BlockState';
import { OrderSymbolListValues } from '../blocks/Order/state/OrderLegSelector';
import { useOrderData } from '../blocks/Order/state/OrderState';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const getQuotes = (state: DataState) => state.quotes.quoteMetadata.data;
const getIdOrUndefined = (state: DataState, id?: string) => id;

const getQuoteBidAsk = createCachedSelector(
  // inputSelectors
  quoteSelectors.byId,
  quote => (quote ? { BidPrice: quote.BidPrice, AskPrice: quote.AskPrice } : undefined)
)(
  // re-reselect keySelector
  // Use "libraryId" as cacheKey
  (_, id) => id
);

const tryGetLastPrice = createCachedSelector(
  // inputSelectors
  getQuotes,
  getIdOrUndefined,
  (quotes, id) =>
    isString(id) && quotes[id]
      ? {
          LastTradePrice: quotes[id].LastTradePrice,
          ReceivedTime: quotes[id].ReceivedTime,
          LastTradeVolume: quotes[id].LastTradeVolume,
          Volume: quotes[id].Volume,
          symbol: quotes[id].Symbol,
        }
      : undefined
)(
  // re-reselect keySelector
  // Use "libraryId" as cacheKey
  (_, id) => (id === undefined ? 'UNDEFINED' : id)
);

// formatReceivedTime: if isHeikinAshi is true, then format the time to the nearest interval of resolution
// 1 minute resolution example:
// 09:00:00 - 09:00:59 -> 09:00:00
// 09:01:00 - 09:01:59 -> 09:01:00
// 5 minute resolution example:
// 09:00:00 - 09:04:59 -> 09:00:00
// 09:05:00 - 09:09:59 -> 09:05:00
// 15 minute resolution example:
// 09:00:00 - 09:14:59 -> 09:00:00
// 09:15:00 - 09:29:59 -> 09:15:00
// 30 minute resolution example:
// 09:00:00 - 09:29:59 -> 09:00:00
// 09:30:00 - 09:59:59 -> 09:30:00
// hour resolution example:
// 09:00:00 - 09:59:59 -> 09:00:00

const formatReceivedTime = (time: string, resolution: string, isHeikinAshi: boolean) => {
  if (isHeikinAshi) {
    const timeMoment = dayjs(time, 'MM/DD/YYYY HH:mm:ss');
    if (resolution === '1D') {
      return timeMoment.format('MM/DD/YYYY HH:mm:ss') + 'Z';
    }
    const minute = timeMoment.minute();
    const minuteMod = minute % Number(resolution);
    return timeMoment.subtract(minuteMod, 'minute').format('MM/DD/YYYY HH:mm') + ':00Z';
  }
  return dayjs(time, 'MM/DD/YYYY HH:mm:ss').format('MM/DD/YYYY HH:mm:ss') + 'Z';
};

const tryGetLastPrices = createCachedSelector(
  // inputSelectors
  getQuotes,
  (state: DataState, ids: string[]) => ids,
  (state: DataState, ids: string[], isHeikinAshi: boolean) => isHeikinAshi,
  (state: DataState, ids: string[], isHeikinAshi: boolean, resolution: string) => resolution,
  (quotes, ids, isHeikinAshi, resolution) => {
    const result: any = {};
    // format LastTradePrice to 01/19/2023 14:32:21

    ids.forEach(id => {
      if (isString(id) && quotes[id]) {
        const time = formatReceivedTime(quotes[id].ReceivedTime, resolution, isHeikinAshi);

        // TODO: Address how to accumulate volume for Heikin Ashi intervals & phantom bar time value when switching from 1 minute to another resolution
        result[id] = {
          LastTradePrice: quotes[id].LastTradePrice,
          ReceivedTime: time,
          LastTradeVolume: quotes[id].LastTradeVolume,
          Volume: quotes[id].Volume,
          Symbol: quotes[id].Symbol,
        };
      } else {
        result[id] = undefined;
      }
    });
    return result;
  }
)(
  // re-reselect keySelector
  // Use "libraryId" as cacheKey
  (_, ids) => ids.join(',')
);

export const useQuoteTime = (symbol: string) => {
  return useStateSelector(s => getQuoteMetadata(s, symbol).ReceivedTime);
};
export const getQuoteDirectionAndValue = createCachedSelector(
  getQuoteData,
  (_state: DataState, symbol: string) => symbol,
  (quotes: Record<string, SymbolQuoteAndChangeData>, symbol: string) => {
    const value = quotes[symbol];
    return value
      ? { direction: value.changeDirection, price: value.LastOrClosePrice }
      : { direction: undefined, price: undefined };
  }
)((_, symbol) => symbol);
export const useQuoteDirectionAndValue = (symbol: string) => {
  return useStateSelector(s => getQuoteDirectionAndValue(s, symbol));
};
export const useQuote = (symbol: string): SymbolQuote => {
  const quote: SymbolQuote = useStateSelector(s => getQuoteMetadata(s, symbol));
  return quote;
};
export const useQuoteBidAsk = (symbol: string) => {
  const quote = useStateSelector(s => getQuoteBidAsk(s, symbol));
  return quote;
};
export const useFeedQuoteWithMetadata = (symbol: string): SymbolQuote => {
  const quoteMetaData: SymbolQuote = useStateSelector(s => getQuoteMetadata(s, symbol));
  return quoteMetaData;
};

// TODO: Address this for Company Info - New Quote Block
/*
export const useQuoteExtendedCompany = (symbol: string, defaultQuote?: Partial<SymbolQuoteExtended>): SymbolQuoteExtended => {
  const quoteExtendedCompany: SymbolQuoteExtended = useStateSelector(s => getQuoteMetadata(s, symbol));
  return quoteExtendedCompany;
};
*/

export const useLastTradePrice = (symbol: string): number | undefined => {
  const quoteMetaData: SymbolQuote = useStateSelector(s => getQuoteMetadata(s, symbol));
  const lastPrice = useMemo(() => {
    return _.isNumber(quoteMetaData.LastTradePrice) ? quoteMetaData.LastTradePrice : undefined;
  }, [quoteMetaData]);
  return lastPrice;
};

export const useQuoteMetadata = (symbolValues: string[]) => {
  const quoteMetaData = useStateSelector(s => getQuotesBySymbols(s, symbolValues));
  useWhyDidYouUpdate('useQuoteMetadata', { symbolValues, quoteMetaData });
  return quoteMetaData;
};

export const useQuoteBidAskMetadata = () => {
  const blockId = useBlockId();
  const symbols = useOrderData(s => OrderSymbolListValues(s, blockId));
  const quoteMetaData = useStateSelector(s => getQuotesBidAskBySymbols(s, blockId, symbols));
  useWhyDidYouUpdate('useQuoteBidAskMetadata', { symbols, quoteMetaData, blockId });
  return quoteMetaData;
};

export const useLastTradepriceOrUndefined = (symbol?: string) => useStateSelector(s => tryGetLastPrice(s, symbol));

export const useLastTradePricesOrUndefined = (symbols: string[], isHeikinAshi?: boolean, interval?: string) =>
  useStateSelector(s => tryGetLastPrices(s, symbols, isHeikinAshi, interval));

export const getFeedQuoteBid = createCachedSelector(getQuotes, getIdOrUndefined, (quotes, id) =>
  id && quotes[id] ? quotes[id].BidPrice : undefined
)((_, id) => id || 'BID');
export const getFeedQuoteAsk = createCachedSelector(getQuotes, getIdOrUndefined, (quotes, id) =>
  id && quotes[id] ? quotes[id].AskPrice : undefined
)((_, id) => id || 'ASK');

export const useQuoteBid = (id?: string) => useStateSelector(s => getFeedQuoteBid(s, id));
