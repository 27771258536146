import React from 'react';
import { isToday } from '@tradingblock/api';
import { BoldNumber, ResponsiveTd } from '@tradingblock/components';
import { Order, OrderStatuses } from '@tradingblock/types';
import dayjs from 'dayjs';
import _ from 'lodash';
import useDeepCompareEffect from 'react-use/lib/useDeepCompareEffect';
import useSWR from 'swr';
import { useApi } from '../../../context/Api';
import { accountIdSelector, subAccountIdSelector, useStateSelector } from '../../../data/global/dataSelectors';
import { getSpecificOrderMessages } from '../../../data/global/selectors/orderSelector';
import { OrderStatusToString } from '../util';
import { useSelector } from 'react-redux';
import { account } from '../../../data/global/selectors/accountSelectors';

export interface ActivityOrderEventsSubRowProps {
  row: Order;
}

export default function ActivityOrderEventsSubRow({ row }: ActivityOrderEventsSubRowProps) {
  const api = useApi();
  const accountId = useStateSelector(accountIdSelector);
  const subaccountId = useStateSelector(subAccountIdSelector);
  const messages = useStateSelector(getSpecificOrderMessages(row.OrderId));
  const hasSubAccounts = useSelector(account.hasSubAccounts);

  const { data, error, mutate } = useSWR(
    {
      accountId: accountId,
      subaccountId: subaccountId,
      orderId: row.OrderId,
      includeOrderEvents: true,
    },
    api.order(accountId, subaccountId).get
  );

  useDeepCompareEffect(() => {
    //TODO: Remove this deep compare effect, since we really should not have to use it.
    mutate();
  }, [messages]);

  // Loading data
  if (!error && !data) {
    return (
      <tr>
        <td>Loading...</td>
      </tr>
    );
  }

  if (error) {
    return (
      <tr>
        <td>Error...</td>
      </tr>
    );
  }

  const sortedOrderEvents =
    data && data.payload && data.payload.items[0] && data.payload.items[0].OrderEvents
      ? _.sortBy(data.payload.items[0].OrderEvents, 'TimeStamp').reverse()
      : [];

  if (sortedOrderEvents.length === 0) {
    return (
      <tr>
        <td>No events found.</td>
      </tr>
    );
  }

  return (
    <>
      {sortedOrderEvents.map((orderEvent, i) => {
        return (
          <tr
            style={{
              backgroundColor: 'rgb(12, 29, 63)',
            }}
            key={i}
          >
            <ResponsiveTd header="Symbol">
              {orderEvent.Description === 'Stock' || orderEvent.Description === 'Multileg'
                ? `${orderEvent.Symbol} ${orderEvent.Description}`
                : orderEvent.Description
                ? orderEvent.Description
                : orderEvent.Symbol}
            </ResponsiveTd>
            <ResponsiveTd header="OrderID">{row.OrderId}</ResponsiveTd>
            {hasSubAccounts && <ResponsiveTd header="Subaccount ID"></ResponsiveTd>}
            <ResponsiveTd header="OrderStatus">
              {OrderStatusToString((orderEvent.OrderStatus as unknown) as number)}
            </ResponsiveTd>
            <ResponsiveTd header="Limit">
              <BoldNumber value={orderEvent.Limit} currency />
            </ResponsiveTd>
            <ResponsiveTd header="FillPrice">
              {orderEvent.OrderStatus === OrderStatuses.Filled && <BoldNumber value={orderEvent.FillPrice} currency />}
            </ResponsiveTd>
            <ResponsiveTd header="Est. Commission"></ResponsiveTd>
            <ResponsiveTd header="FillQuantity">
              {(orderEvent.OrderStatus === OrderStatuses.PartiallyFilled ||
                orderEvent.OrderStatus === OrderStatuses.Filled) && (
                <>
                  <div className={`${orderEvent.OrderStatus === OrderStatuses.PartiallyFilled ? 'pending' : 'pos'}`}>
                    {orderEvent.OrderStatus === OrderStatuses.PartiallyFilled ? 'Partial Fill' : 'Filled'}
                  </div>
                  <span>{orderEvent.FillQuantity}</span>
                </>
              )}
            </ResponsiveTd>
            <ResponsiveTd header="TimeStamp">
              {dayjs(orderEvent.TimeStamp).format(
                isToday(orderEvent.TimeStamp) ? 'h:mm:ss.SSS A' : 'MM/DD/YY h:mm:ss.SSS A'
              )}
            </ResponsiveTd>
            <ResponsiveTd header="Action"></ResponsiveTd>
          </tr>
        );
      })}
    </>
  );
}
