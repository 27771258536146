import { BasicSelect } from '@tradingblock/components';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Cell } from 'react-table';
import { throttle } from 'lodash';
import YearPicker from '../../../components/basic/YearPicker';
import { useApi } from '../../../context/Api';
import { AccountStatementsActions } from '../../../data/global/actions/AccountStatementsActions';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { accountStatementsSelectors } from '../../../data/global/selectors/accountStatementsSelectors';
import { BlockTable } from '../../shared/BlockTable';
import { SessionActions } from '../../../data/global/actions';
import { APINotAuthorizedResponseCode, ClearerType, GenericErrorResponseCode } from '@tradingblock/types';
import { useClearer } from '../../../hooks/useClearer';
import { DownloadComponent } from '../components/DownloadComponent';

export interface AccountStatementsDailyConfirmsProps {}

export default function AccountStatementsTaxDocuments() {
  const dispatch = useDispatch();
  const api = useApi();
  const accountId = useStateSelector(s => s.account.accountId);
  const selectedAccount = useStateSelector(s => s.account.selectedLinkedAccount);
  const clearer = useClearer();
  const isRQD = clearer === ClearerType.RQD;

  const [timeframe, setTimeframe] = useState<{
    startDate: Date | undefined;
    endDate: Date | undefined;
  }>({
    // Set timeframe to undefined initially and allow useEffect to set the timeframe based on the account clearer type
    startDate: undefined,
    endDate: undefined,
  });
  const [taxDocument, setTaxDocument] = useState<
    'All' | '1099' | '1099R' | '5498' | '5498ESA' | '1042S' | '2439' | '408'
  >('All');
  const taxDocuments = useStateSelector(state => accountStatementsSelectors.taxDocumentStatements(state, timeframe));
  const formattedTaxDocuments = useMemo(() => {
    if (taxDocuments) {
      return taxDocuments
        .sort((a, b) => (new Date(a.date) < new Date(b.date) ? 1 : -1))
        .map(document => ({
          received_on: new Date(document.date),
          message: `${document.form} for ${dayjs(document.date).format('MM/DD/YY')}`,
          documentType: document.form,
        }));
    } else {
      return [];
    }
  }, [taxDocument, taxDocuments]);

  useEffect(() => {
    /* 
      RQD accounts must query the current year for prior year tax documents (ex. 2022 tax documents must be queried in 2023)
      Apex accounts can query the year that the tax documents are for (ex. 2022 tax documents can be queried in 2022) 
    */
    isRQD
      ? setTimeframe({
          startDate: new Date(new Date().getFullYear(), 0, 1),
          endDate: new Date(new Date().getFullYear(), 11, 31),
        })
      : setTimeframe({
          startDate: new Date(new Date().getFullYear() - 1, 0, 1),
          endDate: new Date(new Date().getFullYear() - 1, 11, 31),
        });
  }, [isRQD, setTimeframe]);

  const requestTaxAccountStatementsThrottleCallback = useCallback(
    throttle((timeframe, taxDocument, accountId) => {
      if (timeframe.startDate !== undefined && timeframe.endDate !== undefined) {
        dispatch(
          AccountStatementsActions.requestTaxAccountStatements({
            accountId: selectedAccount ? selectedAccount.accountId : accountId,
            taxDocument: taxDocument,
            taxYear: dayjs(timeframe.startDate).get('year'),
          })
        );
      }
    }, 1000),

    [dispatch, selectedAccount]
  );

  useEffect(() => {
    requestTaxAccountStatementsThrottleCallback(timeframe, taxDocument, accountId);
  }, [timeframe, taxDocument, accountId]);

  const columns = useMemo(
    () => [
      {
        Header: 'Statement',
        canSort: false,
        accessor: (a: any) => a.description,
        id: 'message',
        Cell: ({ row }: Cell<any>) => row.original.message,
      },
      {
        Header: 'View',
        accessor: (a: any) => dayjs(a.received).format('MM/DD/YY h:mm A'),
        id: 'received_on',
        canSort: true,
        sortType: 'basic',
        Cell: ({
          row,
        }: Cell<{
          received_on: Date;
          message: string;
          documentType: string;
        }>) => (
          <i
            style={{
              cursor: 'pointer',
            }}
            className="fal fa-lg fa-search dashboard-icon"
            onClick={() => {
              const [_, formType] = row.original.documentType.split('Form');
              api.downloads
                .getTaxDocument(accountId, formType, row.original.received_on)
                .then((result: any) => {
                  let file = new Blob([result.data], { type: 'application/pdf' });
                  let fileURL = URL.createObjectURL(file);
                  window.open(fileURL);
                })
                .catch(err => {
                  dispatch(
                    SessionActions.error({
                      code: GenericErrorResponseCode,
                      url: err.config.url,
                      message: 'Error downloading tax document. Please try again later.',
                      reason: 'Download Failed',
                    })
                  );
                });
            }}
          />
        ),
      },
      {
        Header: 'Download',
        accessor: (a: any) => dayjs(a.received).format('MM/DD/YY h:mm A'),
        id: 'download',
        canSort: true,
        sortType: 'basic',
        Cell: ({
          row,
        }: Cell<{
          received_on: Date;
          message: string;
          documentType: string;
        }>) => (
          <DownloadComponent
            row={row}
            accountId={accountId}
            api={api}
            dispatch={dispatch}
            SessionActions={SessionActions}
            GenericErrorResponseCode={GenericErrorResponseCode}
            type="taxDoc"
          />
        ),
      },
    ],
    [accountId, taxDocument]
  );

  return (
    <>
      <div className="block-section block-section-fixed">
        <div className="row justify-content-between">
          <div className="col-auto">
            <YearPicker
              value={(timeframe.startDate && timeframe.startDate.getFullYear()) || new Date().getFullYear() - 1}
              isRQDTaxStatementView={isRQD}
              onChange={year => {
                setTimeframe({
                  startDate: new Date(`${year}-01-01T00:00:00`),
                  endDate: new Date(`${year}-12-31T00:00:00`),
                });
              }}
            />
          </div>
          <div className="col-auto">
            <span>Tax Document Selector: </span>
            <BasicSelect
              className="btn btn-dark btn-tall dropdown-toggle"
              options={[
                {
                  value: 'All',
                  label: 'All',
                },
                {
                  value: '1099',
                  label: '1099',
                },
                {
                  value: '1099R',
                  label: '1099R',
                },
                {
                  value: '5498',
                  label: '5498',
                },
                {
                  value: '5498ESA',
                  label: '5498ESA',
                },
                {
                  value: '1042S',
                  label: '1042S',
                },
                {
                  value: '2439',
                  label: '2439',
                },
                {
                  value: '408',
                  label: '408',
                },
              ]}
              onChange={(selectedTaxDocument: {
                value: 'All' | '1099' | '1099R' | '5498' | '5498ESA' | '1042S' | '2439' | '408';
                label: 'All' | '1099' | '1099R' | '5498' | '5498ESA' | '1042S' | '2439' | '408';
              }) => {
                setTaxDocument(selectedTaxDocument.value);
              }}
              placeholder={taxDocument}
              value={taxDocument}
            />
          </div>
        </div>
      </div>
      <BlockTable
        tableKey="orders"
        columnWidths={['80%', '10%', '10%']}
        columns={columns}
        data={formattedTaxDocuments}
        sortable
        frozenHeaders
      />
    </>
  );
}
