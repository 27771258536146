import React, { useCallback, useState } from 'react';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import {
  BooleanToggleType,
  AllBooleanToggleTypes,
  TradeAuthUpdateValueProps,
  AllTradeAuthorizationTypes,
  TradeAuthorizationType,
  getGroupedCountryOptions,
  Country,
  AllStates,
  AllPhoneTypes,
  EmploymentTypes,
  EmploymentType,
  ClearerType,
} from '@tradingblock/types';
import {
  FormGroup,
  Modal,
  SelectDropdownField,
  StepperField,
  TextboxField,
  Text,
  ExternalLink,
} from '@tradingblock/components';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { useDispatch } from 'react-redux';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { AccountManagementDataActions } from '../../../data/global/actions/AccountManagementActions';
import { getInvalidError, getMismatchError, getRequiredError, USPSErrors, uspsVerification } from './Validation';
import { formatBoolean } from '../../../utilities/accountManagement';
import { DateField } from '../../../components/form/DateField';
import { useClearer } from '../../../hooks/useClearer';
import { cleanDateModel } from '../../../utilities/date';

export const TradeAuthUpdate: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
}> = ({ show, toggleModal }) => {
  const dispatch = useDispatch();

  const accountId = useStateSelector(s => s.accounts.account && s.accounts.account.AccountId);
  const [showToolTip, setShowToolTip] = React.useState(false);

  const handleToolTipClick = (e: any) => {
    e.preventDefault();
    setShowToolTip(!showToolTip);
  };

  // Trade Authorization Links
  const clearer = useClearer();
  const origin = useStateSelector(s => s.auth.origin);
  const siteGroup = origin === 'tb' ? 'tradingblock' : 'moneyblock';
  const apexTradeAuthLinks = {
    limited: `https://www.${siteGroup}.com/docs/Agreements/Apex_Limited_Trading_Authorization_221206.pdf`,
    full: `https://www.${siteGroup}.com/docs/Agreements/Apex_Full_Trading_Authorization_221206.pdf`,
  };
  // TODO: Confirm these links are accurate
  const rqdTradeAuthLinks = {
    limited: `https://ny4staging.tradingblock.com/RestHub/Documents/Agreement/rqdLimitedTradingAuthorization`,
    full: `https://ny4staging.tradingblock.com/RestHub/Documents/Agreement/rqdPowerOfAttorney`,
  };
  const links = clearer === ClearerType.Apex ? apexTradeAuthLinks : rqdTradeAuthLinks;

  const getLinkDescription = (tradeAuthorizationType: TradeAuthorizationType) => {
    if (tradeAuthorizationType === TradeAuthorizationType.Limited) {
      return clearer === ClearerType.Apex ? 'Limited Trading Authorization' : 'RQD Limited Trading Authorization';
    }
    if (tradeAuthorizationType === TradeAuthorizationType.Full) {
      return clearer === ClearerType.Apex
        ? 'Full Trading Authorization'
        : 'RQD Fee Payment and Full Trading Authorization';
    }
  };

  const [clickedAgreements, setClickedAgreements] = useState<string[]>([]);

  // Log clicked agreements for disable/enable of accept/reject buttons
  const onClickedAgreementLink = useCallback(
    (agreement: string) => {
      setClickedAgreements([...clickedAgreements, agreement]);
    },
    [clickedAgreements]
  );

  const getAgreementDisabledOptions = useCallback(
    (agreement: string) => {
      // if haven't clicked agreement, disable toggle buttons
      if (!_.includes(clickedAgreements, agreement)) {
        return AllBooleanToggleTypes;
      }
      return undefined;
    },
    [clickedAgreements]
  );

  //TODO: Update this to use the new data structure once Toby has completed account management updates for trade auth
  const intitialTradeAuthUpdateValues: TradeAuthUpdateValueProps = {
    tradeAuthorization: undefined,
    address: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
    },
    citizenshipCountry: '',
    dateOfBirth: '',
    disclosures: {
      industryEmployed: undefined,
      stakeholder: undefined,
    },
    disclosuresIndustryEmployedFirmName: undefined,
    disclosuresStakeholderTickerSymbol: undefined,
    email: '',
    employer: '',
    employmentType: '',
    firstName: '',
    middleInitial: '',
    foreignTaxIdCountry: '',
    foreignTaxIdNumber: '',
    jobPosition: '',
    lastName: '',
    suffix: '',
    phones: [
      {
        type: '',
        phoneNumber: '',
      },
    ],
    ssnSecret: '',
    type: '',
    signedName: '',
    tradeAuthAgreement: undefined,
  };

  const onSubmit = useCallback(
    (values, { resetForm }) => {
      const agreementsObject = () => {
        if (values.type === TradeAuthorizationType.Limited) {
          return clearer === ClearerType.Apex
            ? {
                limitedTradingAuthorization: formatBoolean(values.tradeAuthAgreement),
              }
            : {
                rqdLimitedTradingAuthorization: formatBoolean(values.tradeAuthAgreement),
              };
        }
        if (values.type === TradeAuthorizationType.Full) {
          return {
            fullTradingAuthorization: formatBoolean(values.tradeAuthAgreement),
          };
        }
      };
      let updateValues = {
        item: 'TradeAuthorization',
        agreements: agreementsObject(),
        tradingAuthorization: {
          address: {
            address1: values.address.address1,
            address2: values.address.address2 || undefined,
            city: values.address.city,
            state: values.address.state,
            country: values.address.country,
            postalCode: values.address.postalCode,
          },
          citizenshipCountry: values.citizenshipCountry,
          dateOfBirth: cleanDateModel(values.dateOfBirth),
          disclosures: {
            industryEmployed: formatBoolean(values.disclosures.industryEmployed) || undefined,
            stakeholder: formatBoolean(values.disclosures.stakeholder) || undefined,
          },
          disclosuresIndustryEmployedFirmName: values.disclosuresIndustryEmployedFirmName || undefined,
          disclosuresStakeholderTickerSymbol: values.disclosuresStakeholderTickerSymbol || undefined,
          email: values.email,
          employer: values.employer || undefined,
          employmentType: values.employmentType,
          firstName: values.firstName,
          foreignTaxIdCountry: values.foreignTaxIdCountry || undefined,
          foreignTaxIdNumber: values.foreignTaxIdNumber || undefined,
          jobPosition: values.jobPosition,
          lastName: values.lastName,
          phones: [
            {
              type: values.phones[0].type,
              phoneNumber: values.phones[0].phoneNumber,
            },
          ],
          ssnSecret: values.ssnSecret,
          type: values.type,
        },
      };

      dispatch(AccountManagementDataActions.requestMakeAccountUpdate({ accountId, request: updateValues }));

      resetForm();
      toggleModal();
    },
    [toggleModal]
  );

  const onValidate = (values: TradeAuthUpdateValueProps) => {
    // signed name match
    const { signedName, firstName, lastName } = values;
    const nameMatch = `${firstName} ${lastName}` === signedName;

    // TODO: Move USPS Validation to a separate file
    // USPS Address Verification
    // Checks if US address is valid and if not, returns the suggested address
    const { tradeAuthorization, type, address } = values;
    const { address1, city, state, country, postalCode } = address;
    let uspsResponse;
    let isValidAddress;
    const headerValue = 'tradeAuth';
    const stateOrPostalCodeRequired = country === Country.UnitedStatesOfAmerica;

    // Check to see if response is already in local storage
    const uspsResponseFromStorage = localStorage.getItem(`addressVerification-${headerValue}`);

    // If response is in local storage, parse it and check if values.address is valid
    if (uspsResponseFromStorage && uspsResponseFromStorage !== 'undefined') {
      uspsResponse = JSON.parse(uspsResponseFromStorage);
      const { city, state, zip5, error } = uspsResponse;
      const formattedCityCapitalized = _.capitalize(city);
      const startCaseCity = _.startCase(formattedCityCapitalized);

      // Compare values.address to response from USPS
      const isCityEqual = _.toLower(startCaseCity) === _.toLower(values.address.city);
      const isZipEqual = zip5 === values.address.postalCode;
      const isStateEqual = state === values.address.state;
      const isAddressCorrect = !error && isCityEqual && isZipEqual && isStateEqual;

      // If values.address is valid, set isValidAddress to true
      isAddressCorrect ? (isValidAddress = true) : (isValidAddress = false);
    }

    // Once most address information is entered, if values.address is not valid, call USPS verification on changes to values.address and set response to local storage
    if (address1 && city && state && country === Country.UnitedStatesOfAmerica && !isValidAddress) {
      const getUSPS = async () => {
        await new Promise(resolve => setTimeout(resolve, 100));
        return await uspsVerification(values.address);
      };

      getUSPS().then(result => {
        localStorage.setItem(`addressVerification-${headerValue}`, JSON.stringify(result));
      });
    }

    if (uspsResponse) {
      const { city, state, zip5, error } = uspsResponse;

      // If USPS returns an error, return error message
      if (
        error &&
        values.address.address1 &&
        values.address.city &&
        values.address.state &&
        values.address.postalCode
      ) {
        return {
          [`address.postalCode`]: `${error.description.trim()} Please review the entered address.`,
          [`address.city`]: `${error.description.trim()} Please review the entered address.`,
          [`address.state`]: `${error.description.trim()} Please review the entered address.`,
          [`address.address1`]: `${error.description.trim()} Please review the entered address.`,
        };
      }

      // If the user changes address1 or the city, remove the response from local storage in order to call USPS again
      if (!values.address.address1 || !values.address.city) {
        localStorage.setItem(`addressVerification-${headerValue}`, 'undefined');
      }

      // Compare the city, state, and zip5 from the response to the values.address and provide necessary error messages if they do not match along with standard validation
      if (city && state && zip5) {
        const formattedCityCapitalized = _.capitalize(city);
        const startCaseCity = _.startCase(formattedCityCapitalized);
        const isCityEqual = _.toLower(startCaseCity) === _.toLower(values.address.city);
        const isZipEqual = zip5 === values.address.postalCode;
        const isStateEqual = state === values.address.state;

        const errors: USPSErrors = {
          [`address.country`]: getRequiredError(values.address.country),
          [`address.address1`]: getRequiredError(values.address.address1),
          ['address.postalCode']:
            getRequiredError(stateOrPostalCodeRequired ? values.address.postalCode : true) || !isZipEqual
              ? `Please verify the zip code. USPS Provided Zip Code: ${zip5}`
              : undefined,
          ['address.city']:
            getRequiredError(values.address.city) || !isCityEqual
              ? `Please verify the city. USPS Provided City: ${startCaseCity}`
              : undefined,
          ['address.state']:
            getRequiredError(stateOrPostalCodeRequired ? values.address.state : true) || !isStateEqual
              ? `Please verify the state. USPS Provided State: ${state}`
              : undefined,
          [`type`]: getRequiredError(type),
          [`citizenshipCountry`]: getRequiredError(values.citizenshipCountry),
          [`dateOfBirth`]: getRequiredError(values.dateOfBirth),
          [`email`]: getRequiredError(values.email),
          [`employer`]: getRequiredError(values.employmentType === EmploymentType.Employed ? values.employer : true),
          [`employmentType`]: getRequiredError(values.employmentType),
          [`firstName`]: getRequiredError(values.firstName),
          [`jobPosition`]: getRequiredError(
            values.employmentType === EmploymentType.Employed ? values.jobPosition : true
          ),
          [`lastName`]: getRequiredError(values.lastName),
          [`phones[0].type`]: getRequiredError(values.phones[0].type),
          [`phones[0].phoneNumber`]: getRequiredError(values.phones[0].phoneNumber),
          [`ssnSecret`]: getRequiredError(values.ssnSecret),
          [`signedName`]: getRequiredError(values.signedName) || getMismatchError(nameMatch),
          [`foreignTaxIdCountry`]: getRequiredError(
            values.citizenshipCountry !== Country.UnitedStatesOfAmerica ? values.foreignTaxIdCountry : true
          ),
          [`foreignTaxIdNumber`]: getRequiredError(
            values.citizenshipCountry !== Country.UnitedStatesOfAmerica ? values.foreignTaxIdNumber : true
          ),
          [`disclosures.industryEmployed`]: getRequiredError(
            values.type === TradeAuthorizationType.Full ? values.disclosures.industryEmployed : true
          ),
          [`disclosures.stakeholder`]: getRequiredError(
            values.type === TradeAuthorizationType.Full ? values.disclosures.stakeholder : true
          ),
          [`disclosuresIndustryEmployedFirmName`]: getRequiredError(
            values.type === TradeAuthorizationType.Full && values.disclosures.industryEmployed === 'yes'
              ? values.disclosuresIndustryEmployedFirmName
              : true
          ),
          [`disclosuresStakeholderTickerSymbol`]: getRequiredError(
            values.type === TradeAuthorizationType.Full && values.disclosures.stakeholder === 'yes'
              ? values.disclosuresStakeholderTickerSymbol
              : true
          ),
          [`tradeAuthAgreement`]:
            getRequiredError(values.tradeAuthAgreement) || values.tradeAuthAgreement === BooleanToggleType.No
              ? getInvalidError(false)
              : undefined,
        };

        Object.keys(errors).forEach(key => (errors[key] === undefined ? delete errors[key] : {}));

        return errors;
      }
    }

    if (!tradeAuthorization) {
      return {
        [`tradeAuthorization`]: getRequiredError(tradeAuthorization),
      };
    }

    if (tradeAuthorization === 'yes') {
      const errors = {
        [`type`]: getRequiredError(type),
        [`address.address1`]: getRequiredError(values.address.address1),
        [`address.city`]: getRequiredError(values.address.city),
        [`address.state`]: getRequiredError(values.address.state),
        [`address.country`]: getRequiredError(values.address.country),
        [`address.postalCode`]: getRequiredError(values.address.postalCode),
        [`citizenshipCountry`]: getRequiredError(values.citizenshipCountry),
        [`dateOfBirth`]: getRequiredError(values.dateOfBirth),
        [`email`]: getRequiredError(values.email),
        [`employer`]: getRequiredError(values.employmentType === EmploymentType.Employed ? values.employer : true),
        [`employmentType`]: getRequiredError(values.employmentType),
        [`firstName`]: getRequiredError(values.firstName),
        [`jobPosition`]: getRequiredError(
          values.employmentType === EmploymentType.Employed ? values.jobPosition : true
        ),
        // middle initial is not required but should not be more than 1 character
        [`middleInitial`]:
          values.middleInitial && values.middleInitial.length > 1 ? 'Middle initial must be 1 character or less' : null,
        [`lastName`]: getRequiredError(values.lastName),
        // if suffix is present, it should be only characters with a possible "." or "-"
        [`suffix`]: values.suffix && !/^[a-zA-Z .-]*$/.test(values.suffix) ? 'Suffix must be only letters' : null,
        [`phones[0].type`]: getRequiredError(values.phones[0].type),
        [`phones[0].phoneNumber`]: getRequiredError(values.phones[0].phoneNumber),
        [`ssnSecret`]: getRequiredError(values.ssnSecret),
        [`signedName`]: getRequiredError(values.signedName) || getMismatchError(nameMatch),
        [`foreignTaxIdCountry`]: getRequiredError(
          values.citizenshipCountry !== Country.UnitedStatesOfAmerica ? values.foreignTaxIdCountry : true
        ),
        [`foreignTaxIdNumber`]: getRequiredError(
          values.citizenshipCountry !== Country.UnitedStatesOfAmerica ? values.foreignTaxIdNumber : true
        ),
        [`disclosures.industryEmployed`]: getRequiredError(
          values.type === TradeAuthorizationType.Full ? values.disclosures.industryEmployed : true
        ),
        [`disclosures.stakeholder`]: getRequiredError(
          values.type === TradeAuthorizationType.Full ? values.disclosures.stakeholder : true
        ),
        [`disclosuresIndustryEmployedFirmName`]: getRequiredError(
          values.type === TradeAuthorizationType.Full && values.disclosures.industryEmployed === 'yes'
            ? values.disclosuresIndustryEmployedFirmName
            : true
        ),
        [`disclosuresStakeholderTickerSymbol`]: getRequiredError(
          values.type === TradeAuthorizationType.Full && values.disclosures.stakeholder === 'yes'
            ? values.disclosuresStakeholderTickerSymbol
            : true
        ),
        [`tradeAuthAgreement`]:
          getRequiredError(values.tradeAuthAgreement) ||
          (values.tradeAuthAgreement !== BooleanToggleType.Yes && getInvalidError(false)),
      };

      const cleanedErrors = _.omitBy(errors, _.isNil);

      return _.omitBy(errors, _.isNil);
    }
  };

  return (
    <Formik initialValues={intitialTradeAuthUpdateValues} onSubmit={onSubmit} validate={onValidate}>
      {({ handleSubmit, values, errors, resetForm }) => (
        <Modal setShow={() => toggleModal()} show={show}>
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Trade Authorization Agent Update</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            <h2 className="fields-title">Trade authorization or agent</h2>
            <p>Do you want to grant trading authorization to another party?</p>
            <form id="tradeAuthUpdate" onSubmit={handleSubmit} className="tradeAuthUpdate">
              <FormGroup>
                <Field
                  component={StepperField}
                  id="tradeAuthorization"
                  options={AllBooleanToggleTypes}
                  defaultLabelId="boolean"
                />
              </FormGroup>
              {values.tradeAuthorization === BooleanToggleType.Yes && (
                <>
                  <p>
                    Do you wish to grant your agent limited or full trading authorization?{' '}
                    <button onClick={e => handleToolTipClick(e)}>
                      <i className="fal fa-question-circle dashboard-icon"></i>
                    </button>
                  </p>
                  {showToolTip && (
                    <p className="txt-sm mute">
                      Note: Limited trading authorization grants your authorized agent the ability to trade in this
                      account. Full trading authorization grants the authorized agent the ability to trade and move
                      funds in this account.
                    </p>
                  )}
                  <FormGroup>
                    <Field
                      component={StepperField}
                      id="type"
                      options={AllTradeAuthorizationTypes}
                      defaultLabelId="authorizationType"
                    />
                  </FormGroup>
                  {!_.isEmpty(values.type) && (
                    <>
                      <h2 className="fields-title">Authorized Agent Personal Information</h2>
                      <FormGroup>
                        <Field component={TextboxField} name="firstName" id="firstName" label="First name" />
                      </FormGroup>
                      <FormGroup>
                        <Field
                          component={TextboxField}
                          name="middleInitial"
                          id="middleInitial"
                          label="Middle initial"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Field component={TextboxField} name="lastName" id="lastName" label="Last name" />
                      </FormGroup>
                      <FormGroup>
                        <Field component={TextboxField} name="suffix" id="suffix" label="Suffix" />
                      </FormGroup>
                      <FormGroup>
                        <Field component={TextboxField} name="email" id="email" label="Email" />
                      </FormGroup>
                      <FormGroup>
                        <Field component={DateField} id={`dateOfBirth`} defaultLabelId="dateOfBirth" />
                      </FormGroup>
                      <FormGroup>
                        <Field
                          component={SelectDropdownField}
                          name={'country'}
                          id={'address.country'}
                          label="Country"
                          options={getGroupedCountryOptions()}
                          optionsDefaultLabelId="country"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Field component={TextboxField} name={'address1'} id={'address.address1'} label="Address 1" />
                      </FormGroup>
                      <FormGroup>
                        <Field component={TextboxField} name={'address2'} id={'address.address2'} label="Address 2" />
                      </FormGroup>
                      <FormGroup>
                        <Field component={TextboxField} name={'city'} id={'address.city'} label="City" />
                      </FormGroup>
                      <FormGroup>
                        {values.address.country === Country.UnitedStatesOfAmerica && (
                          <Field
                            component={SelectDropdownField}
                            name={'state'}
                            id={'address.state'}
                            label="State"
                            options={AllStates}
                            optionsDefaultLabelId="state"
                          />
                        )}
                        {values.address.country !== Country.UnitedStatesOfAmerica && values.address.country !== '' && (
                          <Field component={TextboxField} name={'state'} id={'address.state'} label="Province" />
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Field
                          component={TextboxField}
                          name={'postalCode'}
                          id={'address.postalCode'}
                          label="Postal Code"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Field
                          component={SelectDropdownField}
                          options={AllPhoneTypes}
                          name={'phoneType'}
                          id={'phones[0].type'}
                          optionsDefaultLabelId="phoneType"
                          label="Phone Type"
                        />
                        <Field
                          component={TextboxField}
                          name={'phone'}
                          id={'phones[0].phoneNumber'}
                          label="Phone Number"
                          type="tel"
                          inputMode="tel"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Field
                          component={SelectDropdownField}
                          name={'citizenshipCountry'}
                          id={'citizenshipCountry'}
                          label="Citizenship Country"
                          options={getGroupedCountryOptions()}
                          optionsDefaultLabelId="country"
                        />
                      </FormGroup>
                      {values.citizenshipCountry === Country.UnitedStatesOfAmerica && (
                        <FormGroup>
                          <Field
                            component={TextboxField}
                            id={`ssnSecret`}
                            label={<Text id="defaults.ssn" type="field" data={{ itin: <Text id="itin.label" /> }} />}
                            type="password"
                            allowUnmask={true}
                          />
                        </FormGroup>
                      )}
                      {values.citizenshipCountry !== Country.UnitedStatesOfAmerica && (
                        <>
                          <FormGroup>
                            <Field
                              component={TextboxField}
                              id={`foreignTaxIdNumber`}
                              defaultLabelId="taxIdentificationNumber"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Field
                              component={SelectDropdownField}
                              id={`foreignTaxIdCountry`}
                              options={getGroupedCountryOptions()}
                              defaultLabelId="treatyCountry"
                              optionsDefaultLabelId="country"
                            />
                          </FormGroup>
                        </>
                      )}

                      <h2 className="fields-title">Authorized Agent Employment Information</h2>
                      <FormGroup>
                        <Field
                          component={StepperField}
                          id={`employmentType`}
                          options={EmploymentTypes}
                          defaultLabelId="employmentType"
                        />
                      </FormGroup>
                      {values.employmentType === EmploymentType.Employed && (
                        <>
                          <FormGroup>
                            <Field component={TextboxField} id={`employer`} defaultLabelId="employer" />
                          </FormGroup>
                          <FormGroup>
                            <Field component={TextboxField} id={`jobPosition`} defaultLabelId="jobPosition" />
                          </FormGroup>
                        </>
                      )}

                      {values.type === TradeAuthorizationType.Full && (
                        <>
                          <h2 className="fields-title">Authorized Agent Disclosures</h2>
                          <p>
                            Is the agent employed by or a member of a registered broker-dealer, securities or futures
                            exchange, or securities industry regulatory body (e.g., FINRA, NFA)?
                          </p>
                          <FormGroup>
                            <Field
                              component={StepperField}
                              id="disclosures.industryEmployed"
                              options={AllBooleanToggleTypes}
                              defaultLabelId="boolean"
                            />
                          </FormGroup>
                          {values.disclosures && values.disclosures.industryEmployed === BooleanToggleType.Yes && (
                            <FormGroup>
                              <Field
                                component={TextboxField}
                                id="disclosuresIndustryEmployedFirmName"
                                defaultLabelId="disclosuresIndustryEmployedFirmName"
                              />
                            </FormGroup>
                          )}
                          <p>
                            Is the agent a 10% shareholder, director, and/or policy maker of a publicly-owned company?
                          </p>
                          <FormGroup>
                            <Field
                              component={StepperField}
                              id="disclosures.stakeholder"
                              options={AllBooleanToggleTypes}
                              defaultLabelId="boolean"
                            />
                          </FormGroup>
                          {values.disclosures && values.disclosures.stakeholder === BooleanToggleType.Yes && (
                            <FormGroup>
                              <Field
                                component={TextboxField}
                                id="disclosuresStakeholderTickerSymbol"
                                defaultLabelId="disclosuresStakeholderTickerSymbol"
                              />
                            </FormGroup>
                          )}
                        </>
                      )}
                      <h2 className="fields-title">Authorized Party Signature</h2>
                      <FormGroup>
                        <Field component={TextboxField} id="signedName" defaultLabelId="tradeAuthSignedName" />
                      </FormGroup>
                      <h4>
                        <ExternalLink
                          className="mr-3"
                          href={values.type === TradeAuthorizationType.Full ? links.full : links.limited}
                          onClick={() => onClickedAgreementLink('tradeAuthorization')}
                        >
                          <i className="far fa-download" /> {getLinkDescription(values.type as TradeAuthorizationType)}
                        </ExternalLink>{' '}
                      </h4>
                      <FormGroup>
                        <Field
                          component={StepperField}
                          id={`tradeAuthAgreement`}
                          options={AllBooleanToggleTypes}
                          disabledOptions={getAgreementDisabledOptions('tradeAuthorization')}
                          defaultLabelId="agreements"
                        />
                      </FormGroup>
                    </>
                  )}
                </>
              )}
            </form>
          </BootstrapModal.Body>
          <BootstrapModal.Footer className="modal-footer-justified">
            <Button
              variant="secondary"
              onClick={() => {
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={(values: any) => handleSubmit(values)}>
              Submit
            </Button>
          </BootstrapModal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
