import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Loading, TradingBlockLink } from '@tradingblock/components';
import { ScreenContentWrapper } from './views/ScreenWrapper';
import { useStateSelector } from '../data/global/dataSelectors';
import { AccountManagementDataActions } from '../data/global/actions/AccountManagementActions';
import { AcceptModal } from './modals/AcceptModal';
import { Redirect } from 'react-router';
import { Logo } from '../components/basic/Logo';

export const UpdateConfirmationPage: React.FunctionComponent = props => {
  let urlString = window.location.href;
  let url = new URL(urlString);
  const confirm = url.searchParams.get('confirm');
  let confirmString: string;
  let encodeConfirm: any;
  const updateDetails = useStateSelector(s => s.accountManagement.update.data);
  const isLoaded = useStateSelector(s => s.accountManagement.update.isLoaded);

  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const toggleAcceptModal = () => {
    showAcceptModal ? setShowAcceptModal(false) : setShowAcceptModal(true);
  };

  if (confirm) {
    confirmString = confirm.replace(' ', '+');

    encodeConfirm = encodeURIComponent(confirmString);
  }
  const dispatch = useDispatch();

  const onSubmit = (event: any, confirm: any) => {
    event.preventDefault();
    setShowAcceptModal(true);
    dispatch(AccountManagementDataActions.requestConfirmUpdate({ confirm: encodeConfirm }));
  };

  useEffect(() => {
    if (confirm) {
      dispatch(AccountManagementDataActions.requestAccountUpdate({ confirm: encodeConfirm }));
    }
  }, [confirm]);
  if (!isLoaded || !updateDetails) {
    return (
      <ScreenContentWrapper>
        <div className="screen-header">
          <div className="logo" id="dashboard-menu-toggle">
            <TradingBlockLink to="HomeUrl">
              <Logo />
            </TradingBlockLink>
          </div>
          <div className="screen-help">
            <div className="screen-help-long">
              Need help? <TradingBlockLink to="CustomerServiceUrl">Contact us</TradingBlockLink>
            </div>
            <div className="screen-help-short">
              <TradingBlockLink to="CustomerServiceUrl">
                <i className="fal fa-comment-lines" title="Need help? Contact us." />
              </TradingBlockLink>
            </div>
          </div>
        </div>
        <div className="screen-body">
          <div className="screen-body-section">
            <Loading />
          </div>
        </div>
      </ScreenContentWrapper>
    );
  }

  if (redirect) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <ScreenContentWrapper>
        <div className="screen-header">
          <div className="logo" id="dashboard-menu-toggle">
            <TradingBlockLink to="HomeUrl">
              <Logo />
            </TradingBlockLink>
          </div>
          <div className="screen-help">
            <div className="screen-help-long">
              Need help? <TradingBlockLink to="CustomerServiceUrl">Contact us</TradingBlockLink>
            </div>
            <div className="screen-help-short">
              <TradingBlockLink to="CustomerServiceUrl">
                <i className="fal fa-comment-lines" title="Need help? Contact us." />
              </TradingBlockLink>
            </div>
          </div>
        </div>
        <div className="screen-body">
          <div className="screen-body-section">
            <h3>Account Update Confirmation</h3>
            <p>Hello, {updateDetails.accountName}.</p>
            <p>Please accept or reject your recent {updateDetails.item.toLowerCase()} update request.</p>
            <strong>If you did not make this request, please contact us using the information below.</strong>
            <br />
            <br />
            <p>
              Accounts Department
              <br />
              800-494-0451
              <br />
              312-253-0385
              <br />
              accounts@tradingblock.com
            </p>
          </div>
          <div className="screen-footer screen-footer-justified">
            <div className="screen-footer-actions-secondary">
              <button className="btn btn-secondary" type="button" onClick={() => setRedirect(true)}>
                <i className="fas fa-arrow-left" /> Reject
              </button>
            </div>
            <div className="screen-footer-actions-primary">
              <button className="btn btn-primary" type="button" onClick={event => onSubmit(event, encodeConfirm)}>
                Accept <i className="fas fa-arrow-right" />
              </button>
            </div>
          </div>
        </div>
      </ScreenContentWrapper>
      <AcceptModal show={showAcceptModal} toggleModal={toggleAcceptModal} />
    </>
  );
};
