import _ from 'lodash';
import dayjs from 'dayjs';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { DataState } from '../state';
import { Order, OrderLeg, OrderMessage, OrderActivity, OrderNotification } from '@tradingblock/types';
import { accountIdValue, PositionSymbolsSelector } from '../dataSelectors';
import { OrderStatusToString, orderToMessage } from '../utilities/activity';

import { isOrderNotification } from '../utilities/notification';
import {
  toRawOccSymbol,
  OrderStatusCategories,
  orderutility,
  OrderLegWithMetadata,
  isSymbolExpired,
} from '@tradingblock/api';

export const getOpenOrdersCount = createSelector(
  (state: DataState) => state.account.orders || [],
  orders => {
    return _.filter(orders, ({ OrderStatus }) =>
      OrderStatus ? OrderStatusCategories.Open.includes(OrderStatus) : false
    ).length;
  }
);

export const getOrders = createSelector(
  (state: DataState) => state.account.orders || [],
  (state: DataState) => state.account.orderErrorEvents || [],
  (state: DataState) => state.account.accountId,
  (state: DataState) => state.account.subAccountId,
  (orders, orderErrors, accountId, subAccountId): OrderActivity[] => {
    const orderAct: OrderActivity[] = _.map(orders, o => ({
      ...o,
      orderApiStatus: 'sent',
      activityDate: dayjs(o.Date).toDate(),
    }));
    const errorAct: OrderActivity[] = _.map(orderErrors, o => {
      const dateValue = o.order.Date || o.createdOn;
      return {
        ...o.order,
        activityDate: dayjs(dateValue).toDate(),
        AccountId: accountId,
        orderApiStatus: 'error',
        data: o,
        Date: dateValue,
      };
    });
    const activities: OrderActivity[] = _.orderBy(
      [...orderAct, ...errorAct],
      o => (o.activityDate ? o.activityDate.valueOf() : 0),
      'desc'
    );

    let filtered_messages = activities;
    if (accountId !== undefined) {
      filtered_messages = filtered_messages.filter(o => o.AccountId && o.AccountId === accountId);
    }
    if (subAccountId !== undefined) {
      filtered_messages = filtered_messages.filter(o => o.SubaccountId && o.SubaccountId === subAccountId);
    }

    return filtered_messages;
  }
);

const filterOrderMessages = (
  orders: Order<OrderLeg>[],
  notifications: OrderNotification[],
  accountId: number,
  subAccountId: number | undefined
): OrderMessage[] => {
  // console.log('Filtering orders on account Id: ' + accountId);
  if (!orders || !notifications) {
    return [];
  }
  const messages: OrderMessage[] = _(
    orders.map(o => {
      return {
        id: o.OrderId || -1,
        status: `Order ${OrderStatusToString(o.OrderStatus)}`,
        message: orderToMessage(o),
        description: o.Description || '',
        received: dayjs(o.Date).toDate(),
        order: o,
      };
    })
  )
    .union(
      notifications.map(n => {
        const o = n.order;
        return {
          id: o.OrderId || -1,
          status: n.title,
          message: n.message ? [n.message] : orderToMessage(o),
          description: o.Description || '',
          received: dayjs(o.Date || n.time).toDate(),
          order: o,
        };
      })
    )
    // .uniqBy(v => v.status.toLocaleLowerCase() + v.message.toLocaleString())
    .orderBy(v => (v.received ? v.received.valueOf() : -1), ['desc'])
    .value();

  let filtered_messages = messages;
  if (accountId !== undefined) {
    filtered_messages = filtered_messages.filter(o => o.order.AccountId && o.order.AccountId === accountId);
  }
  if (subAccountId !== undefined) {
    filtered_messages = filtered_messages.filter(o => o.order.SubaccountId && o.order.SubaccountId === subAccountId);
  }

  return filtered_messages;
};

export const getOrderMessages = (blockId: string) =>
  createSelector(
    (state: DataState) => (state.account.ordersUIState[blockId] && state.account.ordersUIState[blockId].orders) || [],
    (state: DataState) => state.notifications.filter(isOrderNotification),
    (state: DataState) => state.account.accountId,
    (state: DataState) => state.account.subAccountId,
    filterOrderMessages
  );
export const getOrdersByBlockId = (blockId: string) =>
  createSelector(
    (state: DataState) => state.account.ordersUIState[blockId],
    (state: DataState) => state.account.orderErrorEvents || [],
    (state: DataState) => state.account.accountId,
    (state: DataState) => state.account.subAccountId,
    (orderState, orderErrors, accountId, subAccountId): OrderActivity[] => {
      if (!orderState || !orderState.orders) {
        return [];
      }
      const orderAct: OrderActivity[] = _.map(orderState.orders, o => ({
        ...o,
        orderApiStatus: 'sent',
        activityDate: dayjs(o.Date).toDate(),
      }));
      const errorAct: OrderActivity[] = _.map(orderErrors, o => {
        const dateValue = o.order.Date || o.createdOn;
        return {
          ...o.order,
          activityDate: dayjs(dateValue).toDate(),
          orderApiStatus: 'error',
          data: o,
          Date: dateValue,
        };
      });
      const activities: OrderActivity[] = _.orderBy(
        [...orderAct, ...errorAct],
        o => (o.activityDate ? o.activityDate.valueOf() : 0),
        'desc'
      );

      let filtered_messages = activities;
      if (accountId !== undefined) {
        filtered_messages = filtered_messages.filter(o => o.AccountId && o.AccountId === accountId);
      }
      if (subAccountId !== undefined) {
        filtered_messages = filtered_messages.filter(o => o.SubaccountId && o.SubaccountId === subAccountId);
      }

      return filtered_messages;
    }
  );

export const getSpecificOrderMessages = (orderId?: number) =>
  createSelector(
    [
      state => state,
      (state: DataState) => state.account.orders || [],
      (state: DataState) => state.notifications.filter(isOrderNotification),
      (state: DataState) => state.account.accountId,
      (state: DataState) => state.account.subAccountId,
    ],
    (state, orders: Order<OrderLeg>[], notifications, accountId, subAccountId): OrderMessage[] => {
      const filterOrders = filterOrderMessages(orders, notifications, accountId, subAccountId);
      return filterOrders.filter(order => order.id === orderId);
    }
  );

export interface ActivityOrderProps {
  // Just the ticker. I.e. "TWLO"
  Symbol: string;

  // Ex: "Buy to Open 100 SHRS @ MKT DAY"
  OrderDetails: string;

  Price: number; // ex $1,380.00

  // Either "Open", "filled \n # filled / # total"
  // or "Partial \n # filled / # total"
  Status: string;

  // If not today the format is: 08/02/19 2:40 PM
  // If placed today the format is: 2:40 PM
  PlacedOn: Date;
}

/**
 * This builds out an object that will be easily digestable
 * by the ActivityOrders Page.
 *
 */
export const getActivityOrders = createSelector(
  (state: DataState) => state.account.orders || [],
  (orders: Order<OrderLeg>[]) => {
    return _.map(orders, order => {
      // const marketOrLimit = OrderType[order.OrderType];
      // const legs = order.Legs;

      return {
        Symbol: order.UnderlyingSymbol,
        OrderDetails: order.Description,
        Price: order.AverageFillPrice || order.Price,
      };
    });
  }
);
export const isFetchingAccountOrders = createCachedSelector(
  (state: DataState) => state,
  (state: DataState) => {
    return state.account.isFetchingOrders;
  }
)(
  // re-reselect keySelector
  // Use "libraryId" as cacheKey
  accountIdValue
);
export const areOrdersLoaded = createCachedSelector(
  (state: DataState) => (state.account.orders && state.account.orders.length > 0 ? true : false),
  (state: DataState) => state.account.isFetchingOrders,
  (state: DataState) => state.blocks.isFetchingInitial,
  (ordersExist, accountIsFetching, blocksFetchingInitial) => {
    if (ordersExist) {
      return true;
    }
    const res = accountIsFetching === false && blocksFetchingInitial === false;
    return res;
  }
)(
  // re-reselect keySelector
  // Use "libraryId" as cacheKey
  accountIdValue
);
export const shouldLoadMoreOrders = createCachedSelector(
  (state: DataState) => state,
  (state: DataState) => {
    return state.account.orders && state.account.shouldLoadMoreOrders ? true : false;
  }
)(
  // re-reselect keySelector
  // Use "libraryId" as cacheKey
  accountIdValue
);

export const isOrderBeingPlacedOrChanged = false;

export const getActivityOrderSymbolsWithoutExpired = createSelector(
  (state: DataState) => orderutility.toOrderLegsWithMetaData(state.account.orders || []),
  (orderLegs: OrderLegWithMetadata[]): string[] => {
    const orderSymbols = _(orderLegs).reduce((acc: string[], l): string[] => {
      const symb = l.OccSymbol ? toRawOccSymbol(l.OccSymbol) : l.Symbol || l.UnderlyingSymbol;

      if (_.isNil(symb)) {
        return acc;
      }

      //not expired
      if (isSymbolExpired(symb)) {
        return acc;
      }
      return [...acc, symb];
    }, []);

    return _.uniq(orderSymbols);
  }
);

export const getPositionAndOrderSymbols = createCachedSelector(
  (s: DataState) => PositionSymbolsSelector(s),
  getActivityOrderSymbolsWithoutExpired,
  (positionSymbols, orderSymbols) => {
    return _(positionSymbols || [])
      .union(orderSymbols || [])
      .uniq()
      .orderBy()
      .value();
  }
)((s: DataState) => `positionorderselector`);
