import React, { useMemo, useCallback, useEffect } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Expiration, AssetSymbol } from '@tradingblock/types';
import { AsyncAutocomplete, OptionType, ReactSelectComponents } from '@tradingblock/components';
import { OrderState, useOrderData } from '../../state/OrderState';
import { OrderSelectors } from '../../state/OrderSelector';
import { useFilteredExpirations } from '../../hooks/useStrikesFilteredForStrategy';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { ExpirationSelectors } from '../../../../data/global/selectors/expirationStrikeSelector';
import { LoadingDropdownButton } from '../../../../components/basic/Loading';
import { useBlockActionDispatchAndGenericActions, useBlockId } from '../../../../components/blocks/BlockState';
import { expirationDisplayInfo } from '../../../../utilities/data';
import { expirationValue } from '../../../../utilities/date';
import { blockSettings } from '../../../../data/global/selectors/settingSelectors';
import { getType } from 'typesafe-actions';
import { OrderActions, Actions } from '../../state/OrderActions';
import { useOrderPrice } from '../../hooks/useOrderPrice';

type ExpirationOption = OptionType<Expiration & { class: string }>;

const ExpirationToOption = (ex: Expiration, symbol: AssetSymbol) => {
  const d = dayjs(ex.date);
  const expDisplayInfo = expirationDisplayInfo(ex, symbol);
  const stringValue = `${d.format('MM.D.YY')} ${expDisplayInfo.label}`;
  const sym: ExpirationOption = {
    label: stringValue,
    value: stringValue,
    data: { ...ex, class: expDisplayInfo.class },
    searchValue: `${d.format('MM.D.YY')} ${d.format('YYYY MMMM dddd')} ${expDisplayInfo.label}`,
  };
  return sym;
};

export interface OrderLegExpirationProps {
  legId: string;
  symbol: AssetSymbol;
  expiration?: Expiration;
  setExpiration: (val: Expiration) => void;
  setNoExpirations?: (val: boolean) => any;
}

type OptionComponentType = { data: ExpirationOption } & any;
export const OptionComponent = ({ data, ...rest }: OptionComponentType) => {
  const className = `${_.get(rest, 'className', '')} ${data.data.class}`;
  return <ReactSelectComponents.Option {...rest} data={data} className={className} />;
};

export const OrderLegExpiration: React.FC<OrderLegExpirationProps> = ({
  legId,
  symbol,
  expiration,
  setExpiration,
  setNoExpirations,
}) => {
  // const expirations = useStateSelector(state => ExpirationSelectors.expirations(state, symbol));
  const blockId = useBlockId();
  const { mid } = useOrderPrice();
  const expirations = useFilteredExpirations(legId);
  const isFetching = useStateSelector(state => ExpirationSelectors.isFetching(state, symbol.symbol));
  const expirationsAreInvalid = useOrderData(s => OrderSelectors.fieldIsInvalid(s, blockId, 'Expiration'));
  //@ts-ignore @param blockId
  const settings = useStateSelector(s => blockSettings.order(s, blockId));
  const { weeklyExpirations, monthlyExpirations, quarterlyExpirations, yearlyExpirations } = settings;
  const expirationOptionTypes = useMemo(() => {
    const expirationOptTypes = (expirations || []).map(e => ExpirationToOption(e, symbol));
    return expirationOptTypes;
  }, [expirations, symbol]);

  const filteredExpirationOptionTypes = useMemo(() => {
    const filtered = expirationOptionTypes.filter(eo => {
      const expirationFreqCode = eo.data.ExpirationFreqCode;

      const isWeekly =
        expirationFreqCode === 'W' ||
        expirationFreqCode === 'MW' ||
        expirationFreqCode === 'WW' ||
        (expirationFreqCode && expirationFreqCode.includes('Week')) ||
        (expirationFreqCode && expirationFreqCode.includes('W'));

      const isMonthly =
        expirationFreqCode === 'M' ||
        expirationFreqCode === 'MM' ||
        expirationFreqCode === 'Monthly' ||
        expirationFreqCode === 'VX' ||
        expirationFreqCode === 'ME';
      const isQuarterly = expirationFreqCode === 'QuarterlyEOM';
      const isYearly = expirationFreqCode === 'Yearly';

      return (
        (isWeekly && weeklyExpirations) ||
        (isMonthly && monthlyExpirations) ||
        (isQuarterly && quarterlyExpirations) ||
        (isYearly && yearlyExpirations)
      );
    });
    return filtered;
  }, [expirationOptionTypes]);

  const currValue = useMemo(() => {
    const curr = expiration
      ? filteredExpirationOptionTypes.find(eo => {
          return expiration && expiration.date && expirationValue(eo.data) === expirationValue(expiration);
        })
      : undefined;
    return curr;
  }, [filteredExpirationOptionTypes, expiration]);

  const [{}, dispatch] = useBlockActionDispatchAndGenericActions<OrderState, OrderActions>();

  const onSelectExpiration = useCallback(
    (val: OptionType<Expiration>) => {
      const match = filteredExpirationOptionTypes.find(eo => expirationValue(eo.data) === expirationValue(val.data));
      if (val && match) {
        dispatch({
          type: getType(Actions.setPrice),
          payload: { price: undefined, updateCreditOrDebit: true, mid: mid },
        });
        dispatch({ type: getType(Actions.setStopPrice), payload: { price: undefined, updateCreditOrDebit: false } });
        setExpiration(val.data);
      } else {
        console.warn('couldnt find matching expiration', val);
      }
    },
    [setExpiration, filteredExpirationOptionTypes]
  );

  const searchExpirations = useCallback(
    async (_: string) => {
      return Promise.resolve(filteredExpirationOptionTypes);
    },
    [filteredExpirationOptionTypes]
  );

  // if filteredExpirationOptionTypes is empty, then we need to set the expiration to undefined
  useEffect(() => {
    if (setNoExpirations) {
      setNoExpirations(filteredExpirationOptionTypes.length === 0);
    }
  }, [filteredExpirationOptionTypes, setNoExpirations]);

  return (
    <>
      {isFetching !== false && <LoadingDropdownButton />}
      {isFetching === false && (
        <div className={`btn btn-dark btn-tall btn-block dropdown-select ${expirationsAreInvalid ? 'invalid' : ''}`}>
          <AsyncAutocomplete
            OptionComponent={OptionComponent}
            name={`expiration-${legId}`}
            isSearchable={false}
            dropdownIcon={filteredExpirationOptionTypes.length === 0 ? '' : 'fa-chevron-down'}
            placeholder={
              filteredExpirationOptionTypes.length === 0 ? 'No options available for this symbol.' : 'Expiration...'
            }
            value={currValue}
            isClearable={false}
            initialValues={filteredExpirationOptionTypes}
            minimumLength={0}
            search={searchExpirations}
            onSelect={onSelectExpiration}
          />
        </div>
      )}
    </>
  );
};
