import { getType } from 'typesafe-actions';
import { combineReducers, Reducer } from 'redux';
import dayjs from 'dayjs';
import _ from 'lodash';
import { DashboardState, ExpiredTokenCode, Notification, AssetSymbolInfo, AccountInfo } from '@tradingblock/types';
import { GetTradingBlockSiteName } from '@tradingblock/components';
import { toShort } from '@tradingblock/api';
import {
  Actions,
  RootAction,
  NotificationActions,
  FavoriteActions,
  DashboardActions,
  PersistActions,
  FeedActions,
  SessionActions,
  OptionChainActions,
} from './actions';
import { FeedProviderState } from '../../types';
import { Notifications } from '../../constants';
import { InitialState } from './initialState';
import { DataState } from './state';
import { expirations, strikes } from './reducers/expirationStrike';
import { optionchain } from './reducers/optionchainReducer';
import { account } from './reducers/account';
import { blocks } from './reducers/block';
import { layout } from './reducers/layout';
import { uiReducer } from './reducers/uiReducer';
import { cashiering } from './reducers/cashieringReducer';
import { settingsReducer } from './reducers/settingsReducer';
import { quoteReducer } from './reducers/quoteReducer';
import { privateReducer } from './reducers/admin';
import { symbolReducer } from './reducers/symbolReducer';
import { positionsReducer } from './reducers/positionsReducer';
import { environmentReducer } from './reducers/environmentReducer';
import { informationReducer } from './reducers/informationReducer';
import { RootCrossSliceReducer } from './sliceReducers';
import { AccountDataReducer } from './accountDataReducer';
import { accountManagementReducer } from './reducers/accountManagement';
import { linkedAccounts } from './reducers/linkedAccounts';
import { accountStatements } from './reducers/accountStatements';
import { history } from './reducers/history';
import * as Sentry from '@sentry/react';
import { subAccountManagement } from './reducers/subAccountManagement';

const initialState = InitialState;

const notifications = (state = initialState.notifications, action: RootAction) => {
  switch (action.type) {
    case getType(OptionChainActions.requestOptionChainError): {
      const { symbol, expiration } = action.payload;
      const dateStr = toShort(expiration.date);
      const errKey = `optionchainerror-${symbol}-${dateStr}`;

      const feedNotification: Notification = {
        title: `Error occurred trying to request ${symbol} ${dateStr} options`,
        global: false,
        read: false,
        hide: false,
        viewed: false,
        key: errKey,
        status: 'Error',
        time: new Date(),
      };
      return _(state)
        .filter(n => n.key !== errKey)
        .union([feedNotification])
        .value();
    }
    case getType(FeedActions.feedClosed): {
      const existingNotificationsExceptFeed = _.filter(state, n => n.key !== Notifications.DataFeedKey);
      const { retryingInSeconds } = action.payload;
      const isReconnecting = retryingInSeconds && retryingInSeconds > 0 ? true : false;
      if (isReconnecting) {
        const siteName = GetTradingBlockSiteName();
        const feedNotification: Notification = {
          title: `${siteName} has temporarily lost connection. Trying to reconnect in ${retryingInSeconds} seconds...`,
          global: true,
          read: false,
          hide: false,
          viewed: false,
          key: Notifications.DataFeedKey,
          status: 'Warn',
          time: new Date(),
        };
        return [...existingNotificationsExceptFeed, feedNotification];
      }
      return [...existingNotificationsExceptFeed];
    }
    case getType(FeedActions.connected): {
      return _.filter(state, n => n.key !== Notifications.DataFeedKey);
    }
    case getType(NotificationActions.add): {
      const existingNotification = state.find(n => n.key === action.payload.key);
      if (existingNotification) {
        return state.map(n => {
          if (n.key === action.payload.key) {
            return { ...n, ...action.payload };
          }
          return n;
        });
      }
      return [...state, action.payload];
    }
    case getType(NotificationActions.hide):
      return _.map(state, n => {
        if (n.key === action.payload.key) {
          return {
            ...n,
            hide: true,
          };
        }
        return n;
      });
    case getType(NotificationActions.read):
      const notifications = _.isArray(action.payload) ? action.payload : [action.payload];
      const notificationKeys = _.map(notifications, n => n.key);
      return _.map(state, n => {
        if (notificationKeys.includes(n.key)) {
          return {
            ...n,
            read: true,
          };
        }
        return n;
      });
    case getType(NotificationActions.view):
      return _.map(state, n => {
        if (n.key === action.payload.key) {
          return {
            ...n,
            viewed: true,
          };
        }
        return n;
      });
    default:
      return state;
  }
};

const auth = (state = initialState.auth, action: RootAction) => {
  switch (action.type) {
    case getType(Actions.setClientVersion): {
      return {
        ...state,
        version: action.payload.version,
      };
    }
    case getType(Actions.clientVersionMismatch): {
      return {
        ...state,
        version: action.payload.current,
        previousVersion: action.payload.previous,
      };
    }
    case getType(SessionActions.expired):
      const { code } = action.payload;
      let reason = action.payload.reason || '';
      if (code === ExpiredTokenCode.Inactivity) {
        reason = 'due to inactivity';
      } else if (code === ExpiredTokenCode.Expired) {
        reason = 'due to expired access token';
      } else if (code === ExpiredTokenCode.ApiTokenExpired) {
        reason = 'due to expired API token';
      } else if (code === ExpiredTokenCode.DuplicateLogin) {
        reason = 'due to duplicate logins';
      }
      return {
        ...state,
        isExpired: true,
        expirationReason: reason,
      };
    // case getType(SessionActions.unauthorized):
    //   const { payload } = action;
    //   return {
    //     ...state,
    //     unauthorized: {
    //       showModal: true,
    //       url: payload.url,
    //     },
    //   };
    // case getType(SessionActions.resetUnauthorized):
    //   return {
    //     ...state,
    //     unauthorized: {
    //       showModal: false,
    //       url: undefined,
    //     },
    //   };
    case getType(Actions.requestToken):
      return { ...state, isExpired: false, expirationReason: undefined };
    case getType(Actions.receiveToken):
      // rg4js('setUser', {
      //   identifier: `${action.payload.username}`,
      //   isAnonymous: false,
      // });
      Sentry.setContext('user', {
        username: `${action.payload.username}`,
      });
      return {
        ...state,
        isAuthenticated: action.payload !== null,
        isExpired: false,
        expirationReason: undefined,
        uiApiToken: action.payload ? action.payload.token : undefined,
        uiApiTokenExpiresAt: action.payload.exp,
        apiToken: action.payload.apiToken,
        userName: action.payload.username,
      };
    case getType(Actions.signout):
      return {
        ...state,
        isAuthenticated: false,
        isExpired: true,
        expirationReason: undefined,
        uiApiToken: undefined,
        ui: initialState.settings.ui,
        userName: undefined,
      };
    case getType(Actions.setOrigin):
      return {
        ...state,
        origin: action.payload.origin,
      };
    default:
      return state;
  }
};

const accounts = (state = initialState.accounts, action: RootAction) => {
  switch (action.type) {
    case getType(Actions.requestAccounts):
      return { ...state, isFetching: true };
    case getType(Actions.receiveAccounts):
      const accounts = action.payload.payload || undefined;
      // Needed to add in the check now to see our account is selected
      const selectedAccount = _.find(
        accounts,
        (a: AccountInfo) => a.AccountId === (state.account && state.account.AccountId)
      );
      return { ...state, isFetching: false, accounts, account: selectedAccount ? selectedAccount : accounts[0] };
    case getType(Actions.receiveAccountPnL):
      return { ...state, isLoaded: true };
    default:
      return state;
  }
};

const dashboards = (state = initialState.dashboards, action: RootAction): DashboardState => {
  switch (action.type) {
    case getType(DashboardActions.requestDashboards):
      return {
        ...state,
        isFetching: true,
      };
    case getType(DashboardActions.receiveDashboards):
      return {
        ...state,
        isFetching: false,
        dashboards: action.payload ? action.payload : state.dashboards,
      };
    case getType(PersistActions.dashboardsFinished):
      return {
        ...state,
        dashboards: _.map(state.dashboards, d => ({ ...d, DataLastFetchedOn: new Date() })),
      };
    case getType(DashboardActions.setDashboard):
      const { dashboard } = action.payload;
      const existingDashboards = state.dashboards || [];
      const filteredExistingDashboards = _(existingDashboards)
        .filter(d => d.dashboardId !== dashboard.dashboardId)
        .filter(d => {
          // if (replaceTemplate && !_.isNil(dashTemplateId)) {
          //   return d.dashboardTemplateId !== dashTemplateId;
          // }
          return true;
        })
        .value();

      const dashboards = [dashboard, ...filteredExistingDashboards];

      return {
        ...state,
        dashboard,
        dashboards,
      };
    case getType(DashboardActions.addDashboard):
      return {
        ...state,
        dashboard: action.payload.dashboard,
        dashboards: _.concat(state.dashboards || [], [action.payload.dashboard]),
      };
    case getType(DashboardActions.updateDashboard):
      return {
        ...state,
        dashboard:
          state.dashboard && state.dashboard.dashboardId === action.payload.dashboardId
            ? { ...state.dashboard, ...action.payload.dashboardData }
            : state.dashboard,
        dashboards: _.map(state.dashboards || [], d =>
          d.dashboardId === action.payload.dashboardId ? { ...d, ...action.payload.dashboardData } : d
        ),
      };
    default:
      return state;
  }
};

const favorites = (state = initialState.favorites, action: RootAction) => {
  const uniqueSymbols = (vals: AssetSymbolInfo[] | any) => {
    return _.isArray(vals) ? _.uniqBy(vals, s => `${s.symbol || s.rootSymbol}`) : [];
  };
  switch (action.type) {
    case getType(FavoriteActions.addFavorite):
      return {
        ...state,
        symbols: uniqueSymbols(_.union(state.symbols, [action.payload])),
      };
    case getType(FavoriteActions.remove):
      return {
        ...state,
        symbols: _.filter(state.symbols, f => f.symbol !== action.payload),
      };
    case getType(FavoriteActions.request):
      return { ...state, isFetching: true };
    case getType(FavoriteActions.receive):
      return { ...state, isFetching: false, symbols: uniqueSymbols(action.payload.value) };
    default:
      return state;
  }
};

const feedProvider = (state = initialState.feedProvider, action: RootAction): FeedProviderState => {
  switch (action.type) {
    case getType(FeedActions.connected): {
      return {
        ...state,
        status: 'open',
        reason: undefined,
        message: undefined,
        isReconnecting: undefined,
      };
    }
    case getType(FeedActions.feedErrored):
    case getType(FeedActions.feedClosed): {
      const isError = action.type === getType(FeedActions.feedErrored);
      const { retryingInSeconds, message, reason, error } = action.payload;
      const status = isError ? 'error' : 'closed';
      return {
        ...state,
        status: status,
        isReconnecting: retryingInSeconds && retryingInSeconds > 0 ? true : false,
        message,
        reason,
        error: error || state.error,
      };
    }
    default:
      return state;
  }
};
const orders = (state = initialState.orders, action: RootAction) => {
  switch (action.type) {
    case getType(FeedActions.feedErrored):
    case getType(FeedActions.feedClosed): {
      return {
        ...state,
        stale: true,
      };
    }
    case getType(Actions.requestAccountOrders): {
      return {
        ...state,
        isFetching: true,
      };
    }
    case getType(Actions.receiveAccountOrders): {
      return {
        ...state,
        stale: false,
        isFetching: false,
      };
    }
    default:
      return state;
  }
};

const errors = (state = initialState.errors, action: RootAction) => {
  switch (action.type) {
    case getType(SessionActions.error):
      const { payload } = action;
      return {
        ...state,
        showModal: true,
        message: payload.message,
        url: payload.url,
        reason: payload.message,
      };
    case getType(SessionActions.resetError):
      return {
        ...state,
        showModal: false,
        url: undefined,
        reason: undefined,
        message: undefined,
      };
    default:
      return state;
  }
};

const DataReducer = combineReducers<DataState>({
  account,
  history,
  accountData: AccountDataReducer,
  accounts,
  linkedAccounts,
  accountManagement: accountManagementReducer,
  auth,
  settings: settingsReducer,
  blocks,
  dashboards,
  errors,
  quotes: quoteReducer,
  positions: positionsReducer,
  notifications,
  favorites,
  expirations,
  strikes,
  layout,
  optionchain,
  environment: environmentReducer,
  feedProvider,
  orders,
  cashiering,
  ui: uiReducer,
  private: privateReducer,
  symbols: symbolReducer,
  performance: (state = InitialState.performance) => state,
  information: informationReducer,
  accountStatements,
  subAccountManagement
});

export const RootReducer: Reducer<DataState> = (state: DataState = InitialState, action: RootAction) => {
  const intermediateState = DataReducer(state, action);
  const finalState = RootCrossSliceReducer(intermediateState, action);
  return finalState;
};
