import { TransferStatesNotAffectingAchLimit } from '@tradingblock/types';
import { useStateSelector } from '../data/global/dataSelectors';

// retrieve transfers data from the cashiering store and filter for the current day's transfers where state does not equal any TransferStatesNotAffectingAchLimit, utilizing a method of 'ACH', then sum the total amount of those transfers and return if the amount is greater than 50,000

export const useCurrentDayACHTransfersLimit = (): number => {
  const transfers = useStateSelector(s => s.cashiering.transfers && s.cashiering.transfers.data);
  const currentDay = {
    day: new Date().getDate(),
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  };

  if (!transfers) {
    return 0;
  }

  const filteredTransfers = transfers.filter(t => {
    const transferDate = new Date(t.createDate);
    const transferDay = {
      day: transferDate.getDate(),
      month: transferDate.getMonth(),
      year: transferDate.getFullYear(),
    };
    return (
      currentDay.day === transferDay.day &&
      currentDay.month === transferDay.month &&
      currentDay.year === transferDay.year &&
      t.mechanism === 'Ach' &&
      !TransferStatesNotAffectingAchLimit.includes(t.state as any)
    );
  });

  const totalAmount = filteredTransfers.reduce((acc, curr) => acc + (curr.amount || 0), 0);

  return totalAmount;
};
