import { useCallback, useMemo } from 'react';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { DataState } from './../data/global/state';
import { DashboardSettingsPath, DefaultGridLayout, objects, UiSettings } from '@tradingblock/types';
import { useStateSelector } from '../data/global/dataSelectors';
import { account } from '../data/global/selectors/accountSelectors';
import { useStore } from '../context/Storage';
import { useDispatcher } from '../data/global/hooks';

export const DEFAULT_WIDTH = 1200;
export const DEFAULT_SMALL_COL = 2;
export const DEFAULT_MEDIUM_COL = 4;
export const DEFAULT_LARGE_COL = 6;

export const dashboardSettingsSelector = createSelector(
  (state: DataState) => state.layout,
  (state: DataState) => state.settings.ui,
  (layout, ui) => {
    const {
      hotkeys,
      muteAllSounds,
      afterHoursTrading,
      feedSyncIntervalMs,
      showBannerSymbols,
      bannerSymbols,
      balancePollingThrottleMs,
      cashieringPollingInterval,
      cashieringPendingReminderInterval,
      cashieringPendingReminderMaxAttempts,
    } = ui;
    const { gridColumnsLarge, gridColumnsSmall, gridColumnsMedium, width } = { ...DefaultGridLayout, ...layout };
    const columns = { 
      lg: gridColumnsLarge, 
      md: gridColumnsMedium, 
      sm: gridColumnsSmall, xs: 1, xxs: 1 };

    for (let [key,val] of Object.entries(columns)) {
      if (!val) {
        if (key === 'lg') {
           columns.lg = DEFAULT_LARGE_COL 
        } else if (key === 'md') {
           columns.md = DEFAULT_MEDIUM_COL
        } else if (key === 'sm') {
           columns.sm = DEFAULT_SMALL_COL
        } 
      }
    }



    return {
      loaded: _.isNumber(layout.width),
      hotkeys,
      afterHoursTrading,
      gridColumnsLarge,
      gridColumnsSmall,
      gridColumnsMedium,
      showBannerSymbols,
      bannerSymbols,
      width : width < 1 ? DEFAULT_WIDTH : width,
      columns,
      muteAllSounds,
      cashieringPollingInterval,
      cashieringPendingReminderInterval,
      cashieringPendingReminderMaxAttempts,
      feedSyncIntervalMs,
      balancePollingThrottleMs,
    };
  }
);

export const useDashboardSettings = () => {
  const settings = useStateSelector(dashboardSettingsSelector);
  return useMemo(() => settings, [settings]);
};

export const useDashboardSettingsUpdater = () => {
  const accountId = useStateSelector(account.accountId);
  const settings = useDashboardSettings();

  const storage = useStore();
  const { dispatcher } = useDispatcher();

  const updateSettings = useCallback(
    (values: Partial<UiSettings>) => {
      const newSettings = { ...settings, ...values };
      storage.save(DashboardSettingsPath, newSettings);
      if (accountId) {
        dispatcher.settings.setSettings(accountId, newSettings);
      }
    },
    [settings, storage, accountId]
  );
  return useMemo(() => ({ updateSettings }), [updateSettings]);
};
