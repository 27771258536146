import React, { useCallback } from 'react';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import {
  AllExperienceYearTypes,
  AllTradeExperienceYearTypes,
  AllTradeExperienceTypes,
  TradeExperienceUpdateValueProps,
  ExperienceYearType,
} from '@tradingblock/types';
import { FormGroup, SelectDropdownField, Modal } from '@tradingblock/components';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { AccountManagementDataActions } from '../../../data/global/actions/AccountManagementActions';
import { getRequiredError } from './Validation';

export const TradeExperienceUpdate: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
  instrumentType: string;
}> = ({ show, toggleModal }) => {
  const dispatch = useDispatch();

  const accountId = useStateSelector(s => s.accounts.account && s.accounts.account.AccountId);

  const onSubmit = useCallback(
    (values, { resetForm }) => {
      const { years, tradesPerYear, instrumentType } = values;

      let updateValues = {
        item: 'Experience',
        experience: {
          [`${instrumentType}`]: {
            years,
            tradesPerYear: years === ExperienceYearType.Zero ? null : tradesPerYear,
          },
        },
      };

      dispatch(AccountManagementDataActions.requestMakeAccountUpdate({ accountId: accountId, request: updateValues }));
      resetForm();
      toggleModal();
    },
    [toggleModal]
  );

  const onValidate = (values: TradeExperienceUpdateValueProps) => {
    const { instrumentType, years, tradesPerYear } = values;

    if (!instrumentType) {
      return {
        [`instrumentType`]: getRequiredError(instrumentType),
      };
    }

    if (instrumentType && !years) {
      return {
        [`years`]: getRequiredError(years),
        [`tradesPerYear`]: getRequiredError(tradesPerYear),
      };
    }
    if (instrumentType && years && years === ExperienceYearType.Zero && !tradesPerYear) {
      return; // no error
    }

    if (instrumentType && years && !tradesPerYear) {
      return {
        [`tradesPerYear`]: getRequiredError(tradesPerYear),
      };
    }

    return {};
  };

  const initialTradeExperienceUpdateValues: TradeExperienceUpdateValueProps = {
    instrumentType: undefined,
    years: undefined,
    tradesPerYear: undefined,
  };

  return (
    <Formik initialValues={initialTradeExperienceUpdateValues} onSubmit={onSubmit} validate={onValidate}>
      {({ values, handleSubmit, resetForm }) => (
        <Modal setShow={() => toggleModal()} show={show}>
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Trade Experience Update Request</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            <h2 className="fields-title">Trade Experience Update</h2>
            <form id="experienceUpdate" onSubmit={handleSubmit}>
              <FormGroup className="experienceModal">
                <Field
                  component={SelectDropdownField}
                  id={`instrumentType`}
                  options={AllTradeExperienceTypes}
                  label={'Instrument Type'}
                />

                <>
                  <Field
                    component={SelectDropdownField}
                    id={`years`}
                    options={AllExperienceYearTypes}
                    label={'Years'}
                  />
                  {values.years !== ExperienceYearType.Zero && (
                    <Field
                      component={SelectDropdownField}
                      id={'tradesPerYear'}
                      options={AllTradeExperienceYearTypes}
                      label={'Trades Per Year'}
                    />
                  )}
                  <p className="txt-sm mute">
                    Your prior experience helps determine which products you can trade and the option strategies (if
                    desired) you can use.
                  </p>
                </>
              </FormGroup>
            </form>
          </BootstrapModal.Body>
          <BootstrapModal.Footer className="modal-footer-justified">
            <Button
              variant="secondary"
              onClick={() => {
                resetForm();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={(values: any) => handleSubmit(values)}>
              Submit
            </Button>
          </BootstrapModal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
