import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import _ from 'lodash';
import { DataState } from '../../state';
import { applicationRequestKey } from '../../utilities/applicationUtilities';

export const getRequestKey = createSelector(
  (state: DataState) => state.private.applications.ui,
  (state: DataState) => state.account.profile.current && state.account.profile.current.visibleRepIds,
  (uiState, visibleRepIds) => {
    return applicationRequestKey({ ...uiState, repId: visibleRepIds ? visibleRepIds : [] });
  }
);

export const getApplicationSearchEntities = createCachedSelector(
  (state: DataState) => state.private.applications.applications,
  (state: DataState) => state.private.applications.byQuery,
  (state: DataState) => state.private.applications.ui,
  getRequestKey,
  (applications, queryState, uiState, requestKey) => {
    const allValues = queryState[requestKey] ? queryState[requestKey].ids : [];

    return _(allValues)
      .map(id => applications[id])
      .filter(v => v !== undefined)
      .value();
  }
)(getRequestKey);

export const getApplicationSearchFilterData = createCachedSelector(
  (state: DataState) => state.private.applications.ui,
  getRequestKey,
  (uiState, requestKey) => {
    return { ...uiState };
  }
)(getRequestKey);

export const getApplicationSearchTotal = createCachedSelector(
  (state: DataState) => state.private.applications.applications,
  (state: DataState) => state.private.applications.byQuery,
  (state: DataState) => state.private.applications.ui,
  getRequestKey,
  (applications, queryState, uiState, requestKey) => {
    const total = queryState[requestKey] ? queryState[requestKey].total : 0;
    return total;
  }
)(getRequestKey);

export const isFetchingApplications = createCachedSelector(
  (state: DataState) => state.private.applications.byQuery,
  getRequestKey,
  (queryState, requestKey) => {
    const qState = queryState[requestKey];
    const isFetching = qState && qState.isFetching;
    const hasData = qState && qState.ids.length > 0;
    return [isFetching, hasData];
  }
)(getRequestKey);

export const ApplicationsSearchRequestSelector = createCachedSelector(
  (state: DataState) => state.private.applications.ui,
  (state: DataState) => applicationRequestKey(state.private.applications.ui),
  (request, key) => {
    return { request, key };
  }
)((state: DataState) => applicationRequestKey(state.private.applications.ui));
