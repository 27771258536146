import { createSelector } from 'reselect';
import { DataState } from '../state';

export const accountStatementsSelectors = {
  dailyStatements: createSelector(
    [
      (state: DataState) => state.accountStatements.dailyStatements,
      (dailyStatements: any, timeframe: any) => timeframe,
    ],
    (dailyStatements, timeframe) => dailyStatements.data
  ),
  monthlyStatements: createSelector(
    [
      (state: DataState) => state.accountStatements.monthlyStatements,
      (monthlyStatements: any, timeframe: any) => timeframe,
    ],
    (monthlyStatements, timeframe) => monthlyStatements.data
  ),
  taxDocumentStatements: createSelector(
    [(state: DataState) => state.accountStatements.taxStatements, (taxStatements: any, timeframe: any) => timeframe],
    (taxStatements, timeframe) => taxStatements.data
  ),
};
