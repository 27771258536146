/* eslint-disable @typescript-eslint/camelcase */
import React, { useCallback, useMemo } from 'react';
import { css } from 'react-with-styles';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import dayjs from 'dayjs';
import { isSameDate } from '../../utilities/date';
import { useActivityTimeframes } from '../../blocks/Activity/data/useActivityTimeframe';

interface TimeframePresetsProps {
  startDate: Date | undefined;
  endDate: Date | undefined;
  handleDatesChange: (values: { startDate?: dayjs.Dayjs; endDate?: dayjs.Dayjs }) => void;
}

export const TimeframePresets: React.FC<TimeframePresetsProps> = ({ startDate, endDate, handleDatesChange }) => {
  const startDateValue = useMemo(() => startDate && dayjs(startDate), [startDate]);
  const endDateValue = useMemo(() => endDate && dayjs(endDate), [endDate]);

  const { presets } = useActivityTimeframes();

  // This shouldn't go to prod. Need to work with Nick to figure out
  // How we should best style this
  //      -- Paul
  const styles = {
    PresetDateRangePicker_panel: {
      display: 'flex',
      justifyContent: 'center',
      padding: '3px',
      flexWrap: 'wrap',
    },
    PresetDateRangePicker_button: {
      position: 'relative',
      height: '100%',
      textAlign: 'center',
      background: 'none',
      border: `2px solid rgb(0,166,153)`,
      color: 'rgb(0,166,153)',
      padding: '4px 12px',
      marginTop: 1,
      marginRight: 2,
      font: 'inherit',
      fontWeight: 700,
      lineHeight: 'normal',
      overflow: 'visible',
      boxSizing: 'border-box',
      cursor: 'pointer',

      ':active': {
        outline: 0,
      },
    },
    PresetDateRangePicker_button__selected: {
      color: 'white',
      background: 'rgb(0,166,153)',
    },
  };

  const handleOnClick = useCallback(
    ({ startDate, endDate }: { startDate: dayjs.Dayjs | undefined; endDate: dayjs.Dayjs | undefined }) => {
      handleDatesChange({ startDate, endDate });
    },
    [handleDatesChange]
  );

  return (
    <div {...css(styles.PresetDateRangePicker_panel)}>
      {presets.map(({ text, start, end }) => {
        const isDefaultSelected = !start && !startDateValue && !end && !endDateValue;
        const sameEndDateOrUndefined =
          isSameDate(end, endDateValue) || (end === undefined && endDateValue === undefined);
        const isSelected = isDefaultSelected || (isSameDate(start, startDateValue) && sameEndDateOrUndefined);
        return (
          <button
            key={text}
            {...css(styles.PresetDateRangePicker_button, isSelected && styles.PresetDateRangePicker_button__selected)}
            type="button"
            onClick={() => handleOnClick({ startDate: start, endDate: end })}
          >
            {text}
          </button>
        );
      })}
    </div>
  );
};
