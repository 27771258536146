import { createStandardAction, ActionType } from 'typesafe-actions';
import { BlockActions } from '../../../components/blocks/state/BlockState';
import { createPersistableAction } from '../../../data/global/actionUtilities';
import { PriceChartStateFromGroup } from './PriceChartState';

const ChartActionTypes = {
  clear: 'clear',
  initialize: 'initialize',
};

export const ChartInitializerActions = {
  reset: createStandardAction(ChartActionTypes.initialize)<PriceChartStateFromGroup>(),
};

export type PriceChartPersistActions = ActionType<typeof ChartInitializerActions>;
export type PriceChartActions = PriceChartPersistActions | BlockActions;
