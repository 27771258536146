import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Modal as BootstrapModal } from 'react-bootstrap';
import { Modal, Button, LoadingImage } from '@tradingblock/components';
import { UserProfileLevel, AchRelationshipState, TransferState, SuccessResponseCode } from '@tradingblock/types';
import { useStateSelector } from '../../../../../data/global/dataSelectors';
import { account } from '../../../../../data/global/selectors/accountSelectors';
import {
  entitySelector,
  transferSelectors,
  relationshipSelectors,
  recipientBankSelectors,
  wireInstructionSelectors,
} from '../../../../../data/global/selectors/admin/cashieringSelectors';
import { isNotYetCreatedOrPendingStatus } from '../../../../../utilities/cashiering';
import { useApi } from '../../../../../context/Api';

interface ReviewFormProps {
  accountId: number;
  entityId: number;
  entityType: 'relationships' | 'transfers' | 'recipientBanks' | 'wireInstructions';
  onApprove: () => void;
  onReject: () => void;
  onCancel: () => void;
}

type ReviewState = TransferState | AchRelationshipState;

const RepStates: ReviewState[] = ['PendingRepCreateApproval'];

const PendingApprovalStates: ReviewState[] = [
  'PendingRepCreateApproval',
  'PendingFirmCreateApproval',
  'PendingCreateApproval',
  'PendingFirmApproval',
];

const CancelledStates: ReviewState[] = ['Canceled', 'Cancelled'];

const TransferNotCancelableStates: ReviewState[] = [
  'SentToBank',
  'FundsPosted',
  'Complete',
  'Returned',
  'Rejected',
  ...CancelledStates,
];

export const CashieringReviewFormActions: React.FunctionComponent<ReviewFormProps> = ({
  entityId,
  entityType,
  onApprove,
  onReject,
  onCancel,
}) => {
  const api = useApi();
  const [areDocumentsUploaded, setAreDocumentsUploaded] = useState(false);
  const { isFetching, isSaving } = useStateSelector(s => entitySelector.info(s, { id: entityId, type: entityType }));
  const entityStatus = useStateSelector(s => entitySelector.status(s, { id: entityId, type: entityType }));

  const transfer = useStateSelector(s => transferSelectors.data(s, entityType === 'transfers' ? entityId : -1));
  const achRelationship = useStateSelector(s =>
    relationshipSelectors.data(s, (transfer && transfer.achRelationshipId) || 0)
  );
  const recipientBank = useStateSelector(s => recipientBankSelectors.data(s, entityId || 0));
  const wireInstruction = useStateSelector(s => wireInstructionSelectors.data(s, entityId || 0));

  useEffect(() => {
    if (entityType === 'wireInstructions' && wireInstruction) {
      api.cashiering.documents
        .getId(wireInstruction.accountId, entityId)
        .then(res => {
          if (res.responseCode === SuccessResponseCode) {
            if (!res.payload) {
              return;
            }
            setAreDocumentsUploaded(res.payload.length > 0);
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [entityType, entityId, wireInstruction]);

  const userLevel = useSelector(account.userLevel);

  const isApprovalState = useMemo(() => entityStatus && PendingApprovalStates.includes(entityStatus), [entityStatus]);
  const isCancelAllowed = useMemo(() => {
    if (entityType === 'transfers') {
      return !_.includes(TransferNotCancelableStates, entityStatus);
    }
    return !_.includes(CancelledStates, entityStatus);
  }, [entityType, entityStatus]);

  const hasPendingChildren = useMemo(() => {
    if (entityType === 'transfers' && transfer && achRelationship) {
      return isNotYetCreatedOrPendingStatus(achRelationship.state);
    }
    if (entityType === 'recipientBanks' && recipientBank && !areDocumentsUploaded) {
      return true;
    }
    if (entityType === 'wireInstructions' && wireInstruction && !areDocumentsUploaded) {
      return true;
    }

    return false;
  }, [entityType, transfer, achRelationship, recipientBank, areDocumentsUploaded]);

  const isWireInstructionsWithNoDocuments = useMemo(() => {
    return entityType === 'wireInstructions' && wireInstruction && !areDocumentsUploaded;
  }, [entityType, wireInstruction, areDocumentsUploaded]);

  const permissions = useMemo(() => {
    if (userLevel === UserProfileLevel.Admin || userLevel === UserProfileLevel.Firm) {
      return {
        approve: true,
        reject: true,
        save: true,
      };
    } else if (
      _.includes([UserProfileLevel.Rep, UserProfileLevel.Branch, UserProfileLevel.Region], userLevel) &&
      entityStatus &&
      RepStates.includes(entityStatus)
    ) {
      return {
        approve: true,
        reject: true,
        save: true,
      };
    }
    return {
      approve: false,
      reject: false,
      save: false,
    };
  }, [entityStatus, userLevel]);

  // capitalize first letter of the entity type, remove any trailing 's', and add a space between capitalized words
  const entityTypeLabel = useMemo(() => {
    return _.startCase(_.trimEnd(entityType, 's'));
  }, [entityType]);

  const [confirmCancel, setConfirmCancel] = useState(false);

  if (isFetching) {
    return null;
  }

  return (
    <div className="screen-footer-actions-primary">
      {isSaving && !confirmCancel && <LoadingImage />}
      {isApprovalState && permissions.reject && (
        <Button className="btn-reject" onClick={onReject} disabled={isSaving}>
          Reject
        </Button>
      )}
      {hasPendingChildren && (
        <p
          className="warn"
          style={{
            display: 'block',
            marginTop: 'var(--gap)',
            marginBottom: '0',
          }}
        >
          This {entityTypeLabel.toLocaleLowerCase()} has dependent records that are pending.
        </p>
      )}
      {isApprovalState && permissions.approve && !hasPendingChildren && (
        <Button className="btn-primary" onClick={onApprove} disabled={isSaving || isWireInstructionsWithNoDocuments}>
          Approve
        </Button>
      )}
      {!isApprovalState && isCancelAllowed && permissions.save && !hasPendingChildren && (
        <Button className="btn-reject" onClick={() => setConfirmCancel(true)} disabled={isSaving}>
          Cancel
        </Button>
      )}
      {confirmCancel && (
        <Modal show={true} setShow={() => true} backdrop="static">
          <BootstrapModal.Header>
            <BootstrapModal.Title>Confirm</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            <p>Are you sure you want to cancel this {entityTypeLabel}?</p>
          </BootstrapModal.Body>
          <BootstrapModal.Footer className="modal-footer-justified">
            {!isSaving && (
              <Button className="btn-secondary" onClick={() => setConfirmCancel(false)} disabled={isSaving}>
                Go back
              </Button>
            )}
            {isSaving && <LoadingImage />}
            <Button
              className="btn-reject"
              onClick={() => {
                onCancel();
                setConfirmCancel(false);
              }}
              disabled={isSaving}
            >
              Cancel {entityTypeLabel}
            </Button>
          </BootstrapModal.Footer>
        </Modal>
      )}
    </div>
  );
};
