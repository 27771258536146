import React, { useEffect } from 'react';
import _ from 'lodash';
import { OrderAction } from '@tradingblock/types';
import { LoadingImage } from '@tradingblock/components';
import { useOptionChainData } from './state/OptionChainState';
import { useOptionChain } from './data/useOptionChain';
import { useStateSelector, symbolExpirationSelector } from '../../data/global/dataSelectors';
import { isTodayOrAfter } from '../../utilities/date';
import { useOptionChainActions, useOptionChainLoader } from './state/useOptionChainActions';
import { useBlockSymbol } from '../../components/blocks/BlockState';
import { useOptionChainSettings } from './data/useOptionChainSettings';
import { useBlockDimensions } from '../shared/useBlockDimensions';
import { OptionChainTable } from './OptionChainTable';

export const OptionChainContent: React.FunctionComponent<{ expandFirstExpiration?: boolean }> = ({
  expandFirstExpiration,
}) => {
  useOptionChainLoader();
  const symbol = useBlockSymbol();
  const expirations = useStateSelector(s => symbolExpirationSelector(s, symbol ? symbol.symbol : ''));
  const { isLoadingExpirations } = useOptionChain(symbol ? symbol.symbol : undefined);
  const { current } = useOptionChainSettings();
  const { width } = useBlockDimensions();
  const orderActionForBid = current.bidUsesBuyOrderAction ? OrderAction.Buy : OrderAction.Sell;
  const expandedExpirations = useOptionChainData(s => s.expandedExpirations);
  const { setExpandedExpirations } = useOptionChainActions();

  useEffect(() => {
    // expanded the first option chain when data has loaded first time (expirations state undefined or all in past)
    if (
      _.isNil(expandedExpirations) ||
      (!_.isEmpty(expandedExpirations) && !_.some(expandedExpirations, ex => isTodayOrAfter(ex.date)))
    ) {
      const expirationDates = expirations;
      const firstExpiration = _.first(_.filter(expirationDates, ex => isTodayOrAfter(ex)));
      if (firstExpiration && expandFirstExpiration) {
        //setExpandedExpirations([firstExpiration]);
        setExpandedExpirations([firstExpiration]);
      } else if ((expirationDates && _.isEmpty(expirationDates)) || !expandFirstExpiration) {
        setExpandedExpirations([]);
      } else {
      }
    } else {
    }
  }, [expirations, expandFirstExpiration, expandedExpirations, setExpandedExpirations]);

  return (
    <div>
      {isLoadingExpirations && <LoadingImage />}
      {_.size(expirations) === 0 && !isLoadingExpirations && !_.isUndefined(expirations) && (
        <p>No expirations available{symbol ? ` for ${symbol.symbol}` : ''}</p>
      )}
      {symbol && <OptionChainTable width={width} orderActionForBid={orderActionForBid} symbol={symbol} />}
    </div>
  );
};
