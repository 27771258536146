import React from 'react';
import _ from 'lodash';
import { SubAccount } from '@tradingblock/types';

export const formatAccountNumber = (
  accountNumber: string | undefined,
  subAccountSuffix?: string,
  flexibilityType?: string
) => {
  const trimmedAccountNumber = _.takeRight(accountNumber, 4).join('');
  if (trimmedAccountNumber) {
    let formattedAccountNumber = `#${trimmedAccountNumber}`;
    if (flexibilityType) {
      formattedAccountNumber += `-${flexibilityType[0]}`;
    }
    if (subAccountSuffix) {
      return (
        <>
          {formattedAccountNumber}&ndash;{subAccountSuffix}
        </>
      );
    }
    return formattedAccountNumber;
  }
  return '';
};

export const isSelectedSubAccount = (subAccountId: number | undefined, subAccount: SubAccount) => {
  // use SubAccount.Id == -1 as equivalent to "all combined" / no subAccount selected
  if (!subAccountId && subAccount.Id === -1 && subAccount.Nickname === 'ALL') {
    return true;
  }
  return subAccount.Id === subAccountId;
};
