import React, { useCallback } from 'react';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import {
  AllLiquidityNeedTypes,
  AllInvestmentObjectiveTypes,
  InvestmentObjectiveType,
  RiskToleranceType,
  AllRiskToleranceTypes,
  SuitabilityUpdateValueProps,
} from '@tradingblock/types';
import { FormGroup, CheckListField, SelectDropdownField, Modal } from '@tradingblock/components';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { useDispatch } from 'react-redux';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { RiskLevelIcon } from './components/RiskLevelIcon';
import {
  getRiskLevelForInvestmentObjectiveType,
  getDescriptionForInvestmentObjective,
} from '../../../utilities/accountManagement';
import { AccountManagementDataActions } from '../../../data/global/actions/AccountManagementActions';
import { getRequiredError } from './Validation';

export const SuitabilityUpdate: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
}> = ({ show, toggleModal }) => {
  const dispatch = useDispatch();

  const accountId = useStateSelector(s => s.accounts.account && s.accounts.account.AccountId);
  const accountManagementDetails = useStateSelector(s => s.accountManagement.accountManagementDetails.details);
  const { investmentObjectiveType, characteristics: { liquidity, risk } } = accountManagementDetails
  const initialSuitabilityUpdateValues: SuitabilityUpdateValueProps = {
    investmentObjectiveType: investmentObjectiveType ? investmentObjectiveType : '',
    characteristics: {
      liquidity: liquidity ? liquidity : '',
      risk: risk ? risk : '',
    },
  };

  const onValidate = (values: SuitabilityUpdateValueProps) => {
    const { investmentObjectiveType, characteristics } = values;
    const { liquidity, risk } = characteristics;

    if (!investmentObjectiveType || !risk || !liquidity) {
      return {
        [`investmentObjectiveType`]: getRequiredError(investmentObjectiveType),
        [`characteristics.risk`]: getRequiredError(risk),
        [`characteristics.liquidity`]: getRequiredError(liquidity),
      };
    }
  };

  const onSubmit = useCallback(
    (values, { resetForm }) => {
      const { investmentObjectiveType, characteristics } = values;
      const { risk, liquidity } = characteristics;
      let updateValues = {
        item: 'Suitability',
        characteristics: {
          risk,
          liquidity,
        },
        investmentObjectiveType,
      };

      if (
        investmentObjectiveType === accountManagementDetails.investmentObjectiveType &&
        risk === accountManagementDetails.characteristics.risk &&
        liquidity === accountManagementDetails.characteristics.liquidity
      ) {
        resetForm();
        toggleModal();
        return;
      }

      dispatch(AccountManagementDataActions.requestMakeAccountUpdate({ accountId: accountId, request: updateValues }));

      resetForm();
      toggleModal();
    },
    [toggleModal]
  );

  return (
    <Formik initialValues={initialSuitabilityUpdateValues} onSubmit={onSubmit} validate={onValidate}>
      {({ handleSubmit, values, resetForm }) => (
        <Modal setShow={() => toggleModal()} show={show}>
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Financial Information Update Request</BootstrapModal.Title>
          </BootstrapModal.Header>

          <BootstrapModal.Body>
            <form id="suitabilityUpdate" onSubmit={handleSubmit} className="suitabilityUpdate">
              <FormGroup>
                <h6>Investment Objective</h6>
                <Field
                  component={CheckListField}
                  id="investmentObjectiveType"
                  options={_.map(AllInvestmentObjectiveTypes, t => ({
                    value: t,
                    icon: <RiskLevelIcon level={getRiskLevelForInvestmentObjectiveType(t)} />,
                  }))}
                  type="radio"
                  className="checklist-descriptions"
                  renderOption={(opt: { value: InvestmentObjectiveType }) => (
                    <>
                      <span>{opt.value ? opt.value.replace(/([A-Z])/g, ' $1').trim() : 'Not Found'}</span>
                      <br />
                      <span className="txt-sm mute">{getDescriptionForInvestmentObjective(opt.value)}</span>
                    </>
                  )}
                />
                <br />
                <h6>Suitability Information</h6>
                {values.investmentObjectiveType !== 'Speculation' && (
                  <Field component={SelectDropdownField} id="characteristics.risk" options={AllRiskToleranceTypes} />
                )}
                {values.investmentObjectiveType === 'Speculation' && (
                  <Field component={SelectDropdownField} id="characteristics.risk" options={[RiskToleranceType.High]} />
                )}

                <Field component={SelectDropdownField} id="characteristics.liquidity" options={AllLiquidityNeedTypes} />
                <p className="txt-sm mute">Used to determine trading products you can use.</p>
                <p className="txt-sm mute">
                  Investment objective, risk tolerance &amp; liquidity needs updates are pending Admin review.
                </p>
              </FormGroup>
            </form>
          </BootstrapModal.Body>
          <BootstrapModal.Footer className="modal-footer-justified">
            <Button
              variant="secondary"
              onClick={() => {
                resetForm();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={(values: any) => handleSubmit(values)}>
              Submit
            </Button>
          </BootstrapModal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
