import { Middleware, Dispatch } from 'redux';
import { getType } from 'typesafe-actions';
import { RootAction, UIActions } from '../../actions';
import { DataState } from '../../state';
import { ApiProvider } from '../../../../context/Api';
import { MarketSchedule } from '@tradingblock/api/src/commands/marketSchedule';
import * as dateUtil from '../../../../utilities/date';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { MarketStateTypes, Dayjs } from '@tradingblock/types';

dayjs.extend(utc);
dayjs.extend(timezone);

const parseISO8601 = (date: string) => {
  const dateTimeArr = date.split(' ');
  //Date
  const dateArr = dateTimeArr[0].split('/');
  //Time
  const timeArr = dateTimeArr[1].split(':');
  return dayjs
    .utc()
    .month(parseInt(dateArr[0]) - 1)
    .date(parseInt(dateArr[1]))
    .year(parseInt(dateArr[2]))
    .hour(parseInt(timeArr[0]))
    .minute(parseInt(timeArr[1]))
    .second(0)
    .millisecond(0) as Dayjs;
};

const requestMarketSchedule = async (state: DataState, dispatch: Dispatch<RootAction>) => {
  const api = ApiProvider(state, dispatch);
  if (api) {
    try {
      const { payload, responseCode } = await api.getMarketSchedule();
      if (responseCode > 0 || payload === undefined) {
        dispatch(UIActions.defaultMarketSchedule());
      } else {
        let newPayload: MarketSchedule = {
          market: {
            state: payload.market.state,
          },
          indexMarket: {
            state: payload.indexMarket.state,
          },
        };

        if (payload.market.state === 'Open') {
          newPayload.market.nextClose = parseISO8601(payload.market.nextClose as string);
        } else newPayload.market.nextOpen = parseISO8601(payload.market.nextOpen as string);

        if (payload.indexMarket.state === 'Open')
          newPayload.indexMarket.nextClose = parseISO8601(payload.indexMarket.nextClose as string);
        else newPayload.indexMarket.nextOpen = parseISO8601(payload.indexMarket.nextOpen as string);

        dispatch(UIActions.setMarketSchedule(newPayload));
      }
    } catch (err) {
      dispatch(UIActions.defaultMarketSchedule());
      console.error('requestMarketSchedule error', err);
      return err;
    }
  }
};

const defaultMarketSchedule = async (dispatch: Dispatch<RootAction>) => {
  const marketOpen = dateUtil.isMarketOpen();
  const indexMarketOpen = dateUtil.isIndexMarketOpen();
  const defaultPayload: MarketSchedule = {
    market: {
      state: marketOpen ? ('Open' as MarketStateTypes) : ('Close' as MarketStateTypes),
    },
    indexMarket: {
      state: indexMarketOpen ? ('Open' as MarketStateTypes) : ('Close' as MarketStateTypes),
    },
  };
  if (marketOpen) defaultPayload.market.nextClose = dateUtil.MarketCloseTime() as Dayjs;
  else defaultPayload.market.nextOpen = dateUtil.NextMarketOpenTime() as Dayjs;

  if (indexMarketOpen) defaultPayload.indexMarket.nextClose = dateUtil.IndexMarketCloseTime() as Dayjs;
  else defaultPayload.indexMarket.nextOpen = dateUtil.NextMarketOpenTime() as Dayjs;

  dispatch(UIActions.setMarketSchedule(defaultPayload));
};

export const MarketScheduleMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  try {
    const result = next(action);
    // state AFTER action is dispatched
    const nextState = getState();
    switch (action.type) {
      case getType(UIActions.requestMarketSchedule): {
        requestMarketSchedule(nextState, dispatch);
        break;
      }
      case getType(UIActions.defaultMarketSchedule): {
        defaultMarketSchedule(dispatch);
        break;
      }
    }

    return result;
  } catch (err) {
    console.error('MarketScheduleMiddleware :: Caught an exception for action ', action, err);
  }
};
