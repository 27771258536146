import React, { useCallback } from 'react';
import { SiteName, Svg, TradingBlockLink } from '@tradingblock/components';
import { getSiteLogo, GetUrlParams } from '@tradingblock/api';
import { ScreenContentWrapper } from './views/ScreenWrapper';
import { LoginRedirectUrl } from '../utilities/url';
import { useStateSelector } from '../data/global/dataSelectors';
import { Config } from '../config';

export const NoAccountPage: React.FunctionComponent = () => {
  const token = useStateSelector(s => s.auth.uiApiToken);
  const originLS = localStorage.getItem('origin');
  let parseOriginLS = '';
  if (typeof originLS === 'string') {
    parseOriginLS = JSON.parse(originLS);
  }

  const params = GetUrlParams(window.location.href);
  const grp = process.env.REACT_APP_TB_SITE_GROUP || '';

  const onSignUp = useCallback(
    () =>
      (window.location.href = token
        ? `${Config.applicationUrl}/auth#id_token=${token}&next=/pending&grp=${grp}`
        : `${Config.applicationUrl}?grp=${grp}`),
    []
  );
  const onSignOut = useCallback(
    () => (window.location.href = `${Config.loginUrl}?redirect=${LoginRedirectUrl}&grp=${grp}`),
    []
  );
  return (
    <>
      <ScreenContentWrapper>
        <div className="screen-header">
          <div className="logo">
            <TradingBlockLink to="HomeUrl">
              <Svg path={getSiteLogo(window.location.href)} />
            </TradingBlockLink>
          </div>
          <div className="screen-help">
            <div className="screen-help-long">
              Need help? <TradingBlockLink to="CustomerServiceUrl">Contact us</TradingBlockLink>
            </div>
            <div className="screen-help-short">
              <TradingBlockLink to="CustomerServiceUrl">
                <i className="fal fa-comment-lines" title="Need help? Contact us." />
              </TradingBlockLink>
            </div>
          </div>
        </div>
        <div className="screen-body">
          <div className="screen-body-section">
            <h3>No account found</h3>
            <p>
              You need an account before you can access the{' '}
              <SiteName siteGroup={Config.siteGroup} origin={parseOriginLS} /> Dashboard.
            </p>
          </div>
          <div className="screen-footer screen-footer-justified">
            <div className="screen-footer-actions-secondary">
              <button className="btn btn-secondary" type="button" onClick={onSignOut}>
                <i className="fas fa-arrow-left" /> Log out
              </button>
            </div>
            <div className="screen-footer-actions-primary">
              <button className="btn btn-primary" type="button" onClick={onSignUp}>
                Sign up or continue
                <i className="fas fa-arrow-right" />
              </button>
            </div>
          </div>
          <div className="incomplete-application-warning">
            <span className="warn">
              <strong>Incomplete Application?</strong> Click "Continue" to pick up where you left off.
            </span>
          </div>
        </div>
      </ScreenContentWrapper>
    </>
  );
};
