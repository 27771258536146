import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CashieringRoute, CashieringView, CashieringManageAction } from '@tradingblock/types';
import {
  CashieringViewFormValues,
  CashieringViewProps,
  ViewOnValidateType,
  ViewOnSubmitType,
} from './data/useCashieringView';
import { useWithdrawalAmountView } from './WithdrawalAmountView';
import { validatePositions, validateTransferAmount, validateTransferMechanism } from './data/cashieringValidation';
import { useCashieringAccountsList } from './components/AccountsList';
import { cashieringSelectors, useStateSelector } from '../../../data/global/dataSelectors';
import { account } from '../../../data/global/selectors/accountSelectors';
import { Actions } from '../../../data/global/actions';

const WithdrawalTypeView: React.FC<CashieringViewProps<CashieringViewFormValues>> = ({ children, ...props }) => {
  const [WithdrawalAmountView] = useWithdrawalAmountView();
  const [TransferAccountsList] = useCashieringAccountsList();

  return (
    <>
      <WithdrawalAmountView {...props} />

      <div className="screen-body-section">
        <p className="fields-title">How would you like to receive the funds?</p>
        <TransferAccountsList {...props} route={CashieringRoute.Withdraw} />
      </div>

      {children}
    </>
  );
};

export const useWithdrawalTypeView = (
  action: CashieringManageAction | undefined
): [typeof WithdrawalTypeView, ViewOnSubmitType | undefined, ViewOnValidateType] => {
  const [, onSubmitActions, onValidateActions] = useCashieringAccountsList();

  const hasSubAccounts = useStateSelector(account.hasSubAccounts);
  const totals = useSelector(cashieringSelectors.balanceTotals);

  const subAccounts = useStateSelector(account.subAccounts);
  const accountId = useStateSelector(account.accountId);
  const hasPositions = useStateSelector(s => s.positions.positions.length > 0);
  const positions = useStateSelector(s => s.positions.positions);
  const subAccountBalances = useStateSelector(account.subAccountsBalances);
  const dispatch = useDispatch();
  const balancesForWithdrawal = useStateSelector(s => s.accountData.balances.balancesForWithdrawal);
  const availableForWithdrawal = balancesForWithdrawal ? balancesForWithdrawal.availableForWithdrawal : 0;

  useEffect(() => {
    if (subAccounts && subAccounts.length > 0 && accountId) {
      dispatch(Actions.requestSubAccountBalances({ accountId, subAccounts: subAccounts }));
    }
  }, [subAccounts, dispatch, accountId]);

  const onValidate = useCallback(
    (values: CashieringViewFormValues) => {
      if (action) {
        return onValidateActions(values);
      }
      return {
        ...validateTransferAmount(
          values,
          CashieringRoute.Withdraw,
          hasSubAccounts,
          subAccountBalances,
          availableForWithdrawal
        ),
        ...validatePositions(values, hasPositions, positions),
        ...validateTransferMechanism(values, CashieringRoute.Withdraw),
      };
    },
    [onValidateActions, hasSubAccounts, totals, subAccountBalances]
  );

  const onSubmit = useCallback(
    (values: CashieringViewFormValues, view: CashieringView | undefined) => {
      if (action) {
        onSubmitActions(values, view);
      }
    },
    [onSubmitActions]
  );

  return [WithdrawalTypeView, action ? onSubmit : undefined, onValidate];
};
