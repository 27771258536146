import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import { OptionExpirationType, AssetType } from '@tradingblock/types';
import { OCCSymbolToOptionData } from '@tradingblock/api';
import { ParseOCCStrike, ParseOCCOption } from '../../../blocks/Positions/util';

export const PrettySymbolCondenscedView: React.FC<{ symbol: string; expirationToString: string }> = ({
  symbol,
  expirationToString,
}) => {
  const info = OCCSymbolToOptionData(symbol);
  return (
    <>
      <strong>{info.symbol}</strong>
      <br />
      {info.assetType === AssetType.Option && (
        <>
          {expirationToString} {ParseOCCStrike(symbol)} <span className="caps">{ParseOCCOption(symbol)}</span>
        </>
      )}
    </>
  );
};

export const PrettySymbolDefaultView: React.FC<{ symbol: string; expirationToString: string }> = ({
  symbol,
  expirationToString,
}) => {
  return (
    <>
      {expirationToString}
      <br />
      {ParseOCCStrike(symbol)} <span className="caps">{ParseOCCOption(symbol)}</span>
    </>
  );
};

export const usePositionSymbolName = (
  symbolValue: string,
  expirationType?: OptionExpirationType,
  dateOpened?: Date
) => {
  return useMemo(() => {
    const expirationTime = expirationType && expirationType === OptionExpirationType.AM ? 'AM' : undefined;
    const { expiration, symbol } = OCCSymbolToOptionData(symbolValue);
    const expirationDateValue = dayjs(expiration || dateOpened).format('MMM DD YYYY');
    return _([expirationDateValue, expirationTime])
      .filter(v => v !== undefined)
      .join(' ');
  }, [dateOpened, expirationType, symbolValue]);
};

export const PrettySymbol: React.FC<{
  condensced?: boolean;
  symbol: string;
  expirationType?: OptionExpirationType;
  dateOpened?: Date;
}> = ({ condensced, expirationType, dateOpened, ...props }) => {
  const symbolValue = props.symbol;
  const expirationToString = usePositionSymbolName(symbolValue, expirationType, dateOpened);
  return (
    <>
      {condensced && <PrettySymbolCondenscedView symbol={symbolValue} expirationToString={expirationToString} />}
      {!condensced && <PrettySymbolDefaultView symbol={symbolValue} expirationToString={expirationToString} />}
    </>
  );
};
