import { AssetType, PositionPerformance, Position, SymbolTypes, Fetchable } from '@tradingblock/types';
import {
  SymbolQuoteAndChangeData,
  SymbolQuote,
  PartialQuote,
  toAssetSymbol,
  getBestPrice,
  AggregatedPositionDTO,
  AssetTypeToSymbolType,
} from '@tradingblock/api';

import _ from 'lodash';
import { sumNumbers } from '../../../utilities/data';
import { changeCalculation } from '../../../utilities/position';

export const QuoteDataToPartialQuote = (
  quoteData: Fetchable<
    SymbolQuoteAndChangeData & {
      isLoaded: boolean;
    }
  >[]
) => {
  return _.map(
    quoteData,
    ({
      LastTradePrice,
      IsSettlementSet,
      ClosePrice,
      ClosePriceTMinus2,
      BestPriceCalculated,
      ChangePercentage,
      LastOrClosePrice,
      NetChange,
      Open,
      Symbol,
      UnderlyingSymbol,
      AskPrice,
      BidPrice,
      Multiplier,
      Description,
    }: SymbolQuote): PartialQuote => ({
      IsSettlementSet,
      ClosePrice,
      ClosePriceTMinus2,
      BestPriceCalculated,
      LastTradePrice,
      ChangePercentage,
      LastOrClosePrice,
      NetChange,
      Open,
      Symbol,
      UnderlyingSymbol,
      AskPrice,
      BidPrice,
      Multiplier,
      Description,
    })
  );
};

export const positionToPerformance = (
  position: Pick<
    AggregatedPositionDTO,
    | 'OpenPrice'
    | 'DateOpened'
    | 'OpenQuantity'
    | 'ValueBasis'
    | 'CostBasis'
    | 'Symbol'
    | 'UnderlyingSymbol'
    | 'AssetType'
    | 'OptionMultiplier'
    | 'Description'
    | 'Commission'
    | 'DailyPnL'
    | 'OverallPnl'
  >,
  feedQuote: PartialQuote
): PositionPerformance => {
  /* 
    TODO: Review bug causing white screens for account switcher. Default values of 0 are being used for now but this is a workaround.
    Errors being seen through recorder scripts show that values AskPrice, BidPrice, etc. not being set are leading to white screens.
  */

  if (!feedQuote) {
    return {
      ask: 0,
      bid: 0,
      change: 0,
      changePercent: 0,
      cost: position.CostBasis * position.OptionMultiplier,
      lastTradePrice: 0,
      bestTradePrice: 0,
      value: position.ValueBasis,
      quantity: position.OpenQuantity,
      symbol: toAssetSymbol(position.Symbol, position.AssetType, AssetTypeToSymbolType(position.AssetType), {
        rootSymbol: position.Symbol,
        underlyingSymbol: position.UnderlyingSymbol,
      }),
      underlyingSymbol: position.UnderlyingSymbol,
      gain: 0,
      close: 0,
      description: position.Description,
      prevClose: 0,
      IsSettlementSet: false,
      ClosePriceTMinus2: 0,
      dailyPnl: position.DailyPnL,
      overallPnl: position.OverallPnl,
    };
  }

  const {
    AskPrice,
    BidPrice,
    LastOrClosePrice,
    LastTradePrice,
    ClosePrice,
    IsSettlementSet,
    ClosePriceTMinus2,
    NetChange,
    ChangePercentage,
  } = feedQuote;

  let close_or_settled = IsSettlementSet ? ClosePriceTMinus2 : ClosePrice;

  const bestTradePrice = getBestPrice({
    last: LastOrClosePrice || 0,
    ask: AskPrice || 0,
    bid: BidPrice || 0,
    defaultValue: 'avgBidAsk',
    isSettlementSet: IsSettlementSet,
  });

  const currentValue = bestTradePrice * position.ValueBasis;

  const { change, changePercent } = changeCalculation(
    LastTradePrice,
    ClosePrice,
    ClosePriceTMinus2,
    BidPrice,
    AskPrice,
    position.Symbol,
    IsSettlementSet
  ) || {
    change: undefined,
    changePercent: undefined,
  };

  const positionPerformance: PositionPerformance = {
    ask: AskPrice,
    bid: BidPrice,
    change: NetChange,
    changePercent: ChangePercentage,
    calculatedChange: change,
    calculatedChangePercent: changePercent * 100,
    cost: position.CostBasis,
    lastTradePrice: position.AssetType === AssetType.MoneyMarket ? 1 : LastTradePrice, //TODO: Need to pull this from quote, but right now the feed system is not supporting moneymarkets
    bestTradePrice: position.AssetType === AssetType.MoneyMarket ? 1 : bestTradePrice,
    value: currentValue,
    quantity: position.OpenQuantity,
    symbol: toAssetSymbol(position.Symbol, position.AssetType, AssetTypeToSymbolType(position.AssetType), {
      rootSymbol: position.Symbol,
      underlyingSymbol: position.UnderlyingSymbol,
    }),
    underlyingSymbol: position.UnderlyingSymbol,
    gain: currentValue - position.CostBasis,
    close: position.AssetType === AssetType.MoneyMarket ? 1 : ClosePrice,
    description: position.Description,
    prevClose: IsSettlementSet ? ClosePriceTMinus2 : ClosePrice,
    IsSettlementSet: IsSettlementSet,
    ClosePriceTMinus2: ClosePriceTMinus2,
    dailyPnl: position.DailyPnL,
    overallPnl: position.OverallPnl,
  };
  return positionPerformance;
};

export const aggregatedSymbolPosition = (
  positions: Position[],
  quotes: PartialQuote[],
  underlyingSymbol: string
): PositionPerformance => {
  const positionInfo = _.reduce<Position, PositionPerformance[]>(
    positions,
    (res: PositionPerformance[], pos: Position) => {
      // Replace the fund symbol with the underlying symbol by removing ";"
      const quote = _.find(quotes, q => q.Symbol === pos.Symbol.replace(';', ''));
      if (!quote) {
        return res;
      }
      const aggregated = {
        ...pos,
        ValueBasis: pos.OpenQuantity * pos.OptionMultiplier,
      };
      return [...res, positionToPerformance(aggregated, quote)];
    },
    []
  );
  const aggregatedSymbolQuote = _.find(quotes, q => q.Symbol === underlyingSymbol);
  const sharesPositions = _.filter(
    positions,
    p =>
      p.AssetType === AssetType.Equity || p.AssetType === AssetType.MoneyMarket || p.AssetType === AssetType.MutualFund
  );

  const aggregatedDailyPnL = sumNumbers(positionInfo.map(pi => pi.dailyPnl));
  const aggregatedOverallPnL = sumNumbers(positionInfo.map(pi => pi.overallPnl));

  return {
    symbol: toAssetSymbol(
      underlyingSymbol,
      sharesPositions.length > 0 ? sharesPositions[0].AssetType : positions[0].AssetType,
      SymbolTypes.Equity,
      {}
    ),
    underlyingSymbol: underlyingSymbol,
    cost: sumNumbers(positionInfo.map(pi => pi.cost)),
    value: sumNumbers(positionInfo.map(pi => pi.value)),
    quantity: sumNumbers(positionInfo.map(pi => pi.quantity)),
    options: _.filter(positions, p => p.AssetType === AssetType.Option).length,
    shares: sharesPositions.length,
    sharesSum: _.isEmpty(sharesPositions) ? undefined : sumNumbers(_.map(sharesPositions, p => p.OpenQuantity)),

    gain: sumNumbers(_.map(positionInfo, pi => pi.gain)),
    ask: aggregatedSymbolQuote ? aggregatedSymbolQuote.AskPrice : 0,
    bid: aggregatedSymbolQuote ? aggregatedSymbolQuote.BidPrice : 0,
    lastTradePrice: aggregatedSymbolQuote ? aggregatedSymbolQuote.LastTradePrice : 0,
    bestTradePrice: aggregatedSymbolQuote ? aggregatedSymbolQuote.LastOrClosePrice : 0,
    change: aggregatedSymbolQuote ? aggregatedSymbolQuote.NetChange : 0,
    changePercent: aggregatedSymbolQuote ? aggregatedSymbolQuote.ChangePercentage : 0,
    close: aggregatedSymbolQuote ? aggregatedSymbolQuote.ClosePrice : 0,
    prevClose: aggregatedSymbolQuote ? aggregatedSymbolQuote.ClosePriceTMinus2 : 0,
    IsSettlementSet: aggregatedSymbolQuote ? aggregatedSymbolQuote.IsSettlementSet : false,
    description: aggregatedSymbolQuote ? aggregatedSymbolQuote.Description : '',
    ClosePriceTMinus2: aggregatedSymbolQuote ? aggregatedSymbolQuote.ClosePriceTMinus2 : 0,
    dailyPnl: aggregatedDailyPnL ? aggregatedDailyPnL : 0,
    overallPnl: aggregatedOverallPnL ? aggregatedOverallPnL : 0,
  };
};
