import React, { useState, useEffect, useCallback, useRef } from 'react';
import _ from 'lodash';
import { TextboxInput, Button, FormGroup } from '@tradingblock/components';
import { useStateSelector } from '../../../../../data/global/dataSelectors';
import { useDispatcher } from '../../../../../data/global/hooks';
import { AdminAccountManagementActions } from '../../../../../data/global/actions/admin/AccountManagementActions';
import { account } from '../../../../../data/global/selectors/accountSelectors';
import { useSelector } from 'react-redux';

export interface AccountUpdateEntityNoteProps {
  onEditToggle?: (edit: boolean) => void;
  onSave?: () => void;
}

export const AccountUpdateEntityNotes: React.FunctionComponent<AccountUpdateEntityNoteProps> = ({
  onEditToggle,
  onSave,
}) => {
  const details = useStateSelector(s => s.private.accountManagement.currentItem.details);

  const { id, accountId } = details;
  const { dispatch } = useDispatcher();
  const [toggleNote, setToggleNote] = useState(false);
  const [noteValue, setNoteValue] = useState('');

  // Limit approval/rejection/note edit to admin
  const userLevel = useSelector(account.userLevel);
  const isAdmin = userLevel === 'Admin';

  const isPending = _.toLower(details.state).includes('review');

  const toggleNoteField = (event: any) => {
    event.preventDefault();
    toggleNote ? setToggleNote(false) : setToggleNote(true);
  };

  const onChange = useCallback((e: any, val: string) => {
    const newVal = val || e.target.value;
    setNoteValue(newVal);
  }, []);

  useEffect(() => {
    if (details.note) {
      setNoteValue(details.note);
    }
  }, [details.note]);

  const handleClick = (e: any) => {
    e.preventDefault();
    dispatch(AdminAccountManagementActions.clearCurrentItem());
  };

  const updateRequest = {
    accountId: accountId,
    updateId: id,
    request: {
      note: noteValue,
    },
  };

  const handleReject = (e: any) => {
    e.preventDefault(e);
    dispatch(AdminAccountManagementActions.requestFirmRejectUpdate(updateRequest));
    dispatch(AdminAccountManagementActions.clearCurrentItem());
  };

  const handleApproval = (e: any) => {
    e.preventDefault(e);
    dispatch(AdminAccountManagementActions.requestFirmApproveUpdate(updateRequest));
    dispatch(AdminAccountManagementActions.clearCurrentItem());
  };

  return (
    <>
      <div className="screen-body-section">
        {<p className="fields-title">Admin Note</p>}
        <p>{details.note}</p>
        {isAdmin && (
          <>
            <p>
              <button onClick={e => toggleNoteField(e)}>
                Edit <i className="fas fa-angle-down" />
              </button>
            </p>
            {toggleNote && (
              <FormGroup>
                <TextboxInput value={noteValue} onchange={onChange} label="Admin note" />
              </FormGroup>
            )}
          </>
        )}
      </div>
      <br />
      <div className="screen-footer screen-footer-justified">
        <div className="screen-footer-actions-secondary">
          <Button className="btn btn-blend" onClick={e => handleClick(e)}>
            <i className="fal fa-arrow-left" />
            Go back
          </Button>
        </div>
        {isAdmin && (
          <div className="screen-footer-actions-primary">
            {isPending && (
              <Button className="btn-reject" onClick={e => handleReject(e)}>
                Reject
              </Button>
            )}
            {isPending && (
              <Button className="btn-primary" onClick={e => handleApproval(e)}>
                Approve
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  );
};
