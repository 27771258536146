/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { StorageGridPath, StorageBlocksPath, allTranslations } from '@tradingblock/types';
import { CountdownTimer, ExternalLink, Svg, ToggleButton } from '@tradingblock/components';
import { useStore } from '../../context/Storage';
import { useDispatcher } from '../../data/global/hooks';
import {
  useStateSelector,
  dashboardsSelectors,
  useShowSettings,
  useShowAccountSwitcher,
} from '../../data/global/dataSelectors';
import { account } from '../../data/global/selectors/accountSelectors';
import { DashboardSettings } from './DashboardSettings';
import { Config } from '../../config';
import { DebugSettings } from '../DebugSettings';
import { getAdminLinks } from '../admin/Links';
import { TradingBlockLink } from '../basic/TradingBlockLink';
import { UsernameAndEnvironment } from './menu/UserNameAndEnvironment';
import { SiteName } from '../basic/SiteName';
import { LogoutLink } from '../basic/LogoutLink';
import { useVirtualTrading } from '../../hooks/useVirtualTrading';
import { ToggleVirtualTradingButton } from './navigation/ToggleVirtualTradingButton';
import { LinkedAccountsActions } from '../../data/global/actions/LinkedAccountsActions';
import { IsVirtualEnvironment } from '../../data/global/selectors/environmentSelector';

interface DashboardMenuProps {
  toggleMenu: (open?: boolean) => void;
}

const MenuHeader: React.FC<{}> = ({ children, ...rest }) => (
  <h3 className="dashboard-menu-heading" style={{ fontSize: '0.71rem' }} {...rest}>
    {children}
  </h3>
);

export const DashboardMenu: React.FC<DashboardMenuProps> = ({ toggleMenu }) => {
  const { dispatcher } = useDispatcher();

  const uiApiTokenExpiresAt = useStateSelector(s => s.auth.uiApiTokenExpiresAt);
  const untilTime = useMemo(() => uiApiTokenExpiresAt && new Date((uiApiTokenExpiresAt - 120) * 1000), [
    uiApiTokenExpiresAt,
  ]);

  const { isVirtualEnv } = useVirtualTrading();

  const userLevel = useSelector(account.userLevel);
  const userRoles = useSelector(account.userRoles);
  const firstAndLastName = useSelector(account.firstAndLastName);
  const accountNickname = useSelector(account.accountNickname);
  const origin = useStateSelector(s => s.auth.origin);
  const hasMultipleAccounts = useSelector(account.hasMultipleAccounts);
  const siteGroup = origin === 'tb' ? 'tradingblock' : 'moneyblock';
  const accountDetails = useStateSelector(s => s.account.accountDetails);
  const accountStatus = accountDetails && accountDetails.accountStatus ? accountDetails.accountStatus : '';
  const adminLinks = useMemo(() => getAdminLinks(userLevel, userRoles), [userLevel, userRoles]);

  const showSettings = useShowSettings();
  const toggleSettings = useCallback(() => dispatcher.settings.toggleSettings(), [dispatcher.settings]);

  const showAccountSwitcher = useShowAccountSwitcher();
  const toggleAccountSwitcher = useCallback(() => dispatcher.account.toggleAccountSwitcher(), [dispatcher.account]);

  const getAccountStatus = useCallback((status: string) => {
    // format status to place a space between words
    const formattedStatus = status.replace(/([A-Z])/g, ' $1').trim();
    switch (status) {
      case 'Active':
        return <span style={{ color: 'var(--green)', fontWeight: 500 }}>{formattedStatus}</span>;
      case 'Inactive':
        return <span style={{ color: 'var(--salmon)', fontWeight: 500 }}>{formattedStatus}</span>;
      case 'Closed':
        return <span style={{ color: 'var(--salmon)', fontWeight: 500 }}>{formattedStatus}</span>;
      case 'ActiveClosingTradesOnly':
        return <span style={{ color: 'var(--gold)', fontWeight: 500 }}>{formattedStatus}</span>;
      default:
        return (
          <span className="warn" style={{ fontWeight: 500 }}>
            {formattedStatus}
          </span>
        );
    }
  }, []);

  useEffect(() => {
    if (!showAccountSwitcher) {
      toggleMenu(false);
    }
  }, [showAccountSwitcher]);

  return (
    <>
      <DebugSettings />
      {showSettings && <DashboardSettings show={showSettings} toggleModal={toggleSettings} />}

      <div className="dashboard-menu-signout">
        <div className="columns columns2">
          <div className="column">
            <UsernameAndEnvironment />
          </div>
          <div className="column">
            <LogoutLink className="caps">Log out</LogoutLink>
          </div>
        </div>
      </div>

      <div className="account-summary">
        <hr className="dashboard-menu-hr" />
        <div className="d-flex justify-content-between">
          <div>Account</div>
          {hasMultipleAccounts && (
            <div className="account-summary-switcher">
              <button type="button" className="btn btn-outline-light" onClick={toggleAccountSwitcher}>
                Select
              </button>
            </div>
          )}
        </div>
        <div className="account-summary-name">{firstAndLastName}</div>
        <div className="account-summary-nickname">
          {hasMultipleAccounts ? (
            <button type="button" className="btn-link" onClick={toggleAccountSwitcher}>
              {accountNickname} - {getAccountStatus(accountStatus)}
            </button>
          ) : (
            <span>
              {accountNickname} - {getAccountStatus(accountStatus)}
            </span>
          )}
        </div>
      </div>

      <hr className="dashboard-menu-hr" />
      <div className="dashboard-menu-nav">
        <ul className="nav">
          <li>
            <button className="nav-link" type="button" onClick={toggleSettings}>
              <span className="tb-icon tb-icon-sm">
                <Svg path="icon-settings" />
              </span>{' '}
              Dashboard Settings
            </button>
          </li>
        </ul>
      </div>
      <hr className="dashboard-menu-hr" />

      <div className="account-summary-env">
        {isVirtualEnv ? (
          <div className="account-summary-env-virtual">
            <p className="txt-sm">
              <span className="vr caps">
                <i className="fal fa-vr-cardboard"></i> You are in virtual trading
              </span>
            </p>
            <ToggleVirtualTradingButton onClick={() => toggleMenu()}>
              <strong>
                Log in to Live Trading Account <i className="fal fa-arrow-right txt-sm" />
              </strong>
            </ToggleVirtualTradingButton>
          </div>
        ) : (
          <div className="account-summary-env-live">
            <p className="mute">Want to test new ideas with paper money?</p>
            <ToggleVirtualTradingButton onClick={() => toggleMenu()}>
              <strong>
                Log in to Virtual Trading <i className="fal fa-arrow-right txt-sm" />
              </strong>
            </ToggleVirtualTradingButton>
          </div>
        )}
      </div>

      <hr className="dashboard-menu-hr" />

      <div>Training &amp; Education</div>
      <ul className="dashboard-menu-list">
        <li>
          <TradingBlockLink to="VideoTourUrl">
            <i className="fal fa-play" /> Take a Dashboard Video Tour
          </TradingBlockLink>
        </li>
        {siteGroup === 'tradingblock' && (
          <li>
            <ExternalLink href={`https://tradingblock.com/trading-education`}>
              <i className="fal fa-user-graduate" /> Trading Education Videos
            </ExternalLink>
          </li>
        )}
      </ul>

      <hr className="dashboard-menu-hr" />
      <div>Client Tools</div>
      <ul className="dashboard-menu-list">
        <li>
          <TradingBlockLink to="LegacySiteLoginUrl">
            <i className="fal fa-history" /> Account History
          </TradingBlockLink>
        </li>
        <li>
          <ExternalLink href={'https://tradingblock.com/pricing-policies'}>
            <i className="fal fa-file-alt" /> Risks &amp; Disclosures
          </ExternalLink>
        </li>
      </ul>
      {!_.isEmpty(adminLinks) && (
        <>
          <hr className="dashboard-menu-hr" />
          <div>Admin Tools</div>
          <ul className="dashboard-menu-list">
            {adminLinks.map((al, key) => {
              if (al.text === 'Forms') {
                return (
                  <div key={key}>
                    <li>
                      <a href={origin === 'tb' ? al.url : al.secondaryUrl} target={'_blank'}>
                        <i className={al.icon} /> {al.text}
                      </a>
                    </li>
                  </div>
                );
              } else {
                return (
                  <div key={al.text}>
                    <li>
                      <Link to={al.url}>
                        <i className={al.icon} /> {al.text}
                      </Link>
                    </li>
                    <li>
                      <a
                        href={
                          origin === 'tb'
                            ? 'https://tradingblock.com/admin-video-guides'
                            : 'https://moneyblock.com/admin-video-guides'
                        }
                        target={'_blank'}
                      >
                        <i className="fa fa-play" /> Admin Video Guides
                      </a>
                    </li>
                  </div>
                );
              }
            })}
          </ul>
        </>
      )}
      <hr className="dashboard-menu-hr" />
      <ul className="dashboard-menu-list">
        <li>
          <TradingBlockLink to="CustomerServiceUrl">
            <i className="fal fa-comment-lines" /> Give us feedback
          </TradingBlockLink>
        </li>
        <li>
          <TradingBlockLink to="CustomerServiceUrl">
            <i className="fal fa-comments" /> Contact us
          </TradingBlockLink>
        </li>
        {/*TODO: Determine with Tom if this link will be coming back at any point */}
        {/* <li>
          <ExternalLink href={`https://${siteGroup}.webflow.io/`}>
            <i className="fal fa-info-circle" /> About us
          </ExternalLink>
        </li> */}
      </ul>

      {!Config.isProduction && untilTime && (
        <div className="dashboard-menu-countdown">
          <p className="mute txt-sm">UI token will be refreshed in</p>
          <CountdownTimer
            format={parsed => {
              const hours = _.max([parsed.hours, 0]) || 0;
              const minutes = _.max([parsed.minutes, 0]) || 0;
              const seconds = _.max([parsed.seconds, 0]) || 0;
              const formatted =
                hours > 0
                  ? `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds} HRS`
                  : minutes <= 0
                  ? `00:00:${seconds < 10 ? '0' : ''}${seconds} HRS`
                  : `00:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds} HRS`;
              return ` ${formatted}`;
            }}
            until={untilTime}
            intervalTime={1000}
            className={'mute txt-sm'}
          >
            <span className="tb-icon tb-icon-sm">
              <Svg path="icon-clock" />
            </span>
          </CountdownTimer>
        </div>
      )}

      {Config.schemaVersion && (
        <h3
          className="dashboard-menu-heading"
          style={{ bottom: '5px', fontSize: '0.71rem', position: 'fixed', right: '25px' }}
        >
          Version {Config.schemaVersion}
        </h3>
      )}
    </>
  );
};
