import _ from 'lodash';
import {
  AccountData,
  Balances,
  CashieringRoute,
  Position,
  TransferBankIdentifierType,
  TransferData,
} from '@tradingblock/types';
import { formatNumber, parseNumber } from '@tradingblock/components';
import { CashieringViewFormValues } from './useCashieringView';
import { getTransferBankIdentifierType } from '../../../../utilities/cashiering';

interface ValidationErrors {
  required: string;
  invalid?: string;
  max?: string;
}

export const validateAmount = (
  amount: string | number | undefined,
  errors: ValidationErrors,
  field: string,
  max?: number,
  availableForWithdrawal?: number
) => {
  if (!amount) {
    return { [field]: errors.required };
  }

  const amountNumber = parseNumber(`${amount}`);
  if (_.isNaN(amountNumber) || amountNumber <= 0) {
    return { [field]: errors.invalid || 'Invalid amount' };
  }
  if (max && amountNumber > max) {
    return {
      [field]:
        errors.max ||
        `We were not able to process your request. Maximum daily limit is $${formatNumber(max, {
          integer: true,
          format: true,
        })}.`,
    };
  }

  if (
    availableForWithdrawal !== null &&
    availableForWithdrawal !== undefined &&
    amountNumber >= availableForWithdrawal
  ) {
    return {
      [field]: `Amount cannot be greater than your total balance (${formatNumber(availableForWithdrawal, {
        currency: true,
      })}).`,
    };
  }

  return {};
};

export const validateTransferAmount = (
  values: CashieringViewFormValues,
  route: CashieringRoute,
  hasSubAccounts: boolean,
  subAccountBalances?: { [subAccountId: string]: Balances },
  availableForWithdrawal?: number
) => {
  if (!values.transfer || values.transfer.disbursementType !== 'FullBalance') {
    let amountErrors = validateAmount(
      values.transfer && values.transfer.amount,
      {
        required: `Please enter the amount to ${route}.`,
        invalid: `Please enter a valid amount to ${route}.`,
      },
      'transfer.amount',
      undefined,
      availableForWithdrawal
    );

    if (
      hasSubAccounts &&
      subAccountBalances !== undefined &&
      values.transfer !== undefined &&
      values.transfer.subAccountsValues !== undefined
    ) {
      amountErrors = {
        ...amountErrors,
        ...Object.keys(values.transfer.subAccountsValues).reduce(
          (errors, subAccountId) => {
            let error = {};
            if (
              values.transfer !== undefined &&
              values.transfer.subAccountsValues !== undefined &&
              values.transfer.subAccountsValues[subAccountId] !== undefined &&
              subAccountBalances[subAccountId] !== undefined
            ) {
              if (
                (values.transfer.subAccountsValues[subAccountId] as number) >
                subAccountBalances[subAccountId].Balances.WithdrawalAmount
              ) {
                error = {
                  [`transfer.subAccountsValues.${subAccountId}`]: `You don't have sufficient funds in this sub-account to withdraw ${formatNumber(
                    values.transfer.subAccountsValues[subAccountId],
                    {
                      currency: true,
                    }
                  )}. Please adjust amount.`,
                };
              }
            }
            return {
              ...errors,
              ...error,
            };
          },
          {} as { [field: string]: string }
        ),
      };
    }
    if (!_.isEmpty(amountErrors)) {
      return amountErrors;
    }

    const { transfer } = values;
    if (hasSubAccounts && (!transfer || !transfer.subAccountId)) {
      return {
        'transfer.subAccountId': 'Please select a sub-account.',
      };
    }

    // if (
    //   transfer &&
    //   transfer.amount &&
    //   availableForWithdrawal !== null &&
    //   availableForWithdrawal !== undefined &&
    //   transfer.amount >= availableForWithdrawal
    // ) {
    //   return {
    //     'transfer.amount': `Amount cannot be greater than your total balance (${formatNumber(availableForWithdrawal, {
    //       currency: true,
    //     })}).`,
    //   };
    // }

    // need to figure out current account info, to know if IRA fields are required
    // if (!values.distributionReason) {
    //   return { distributionReason: 'Please select the distribution reason for this IRA account' };
    // }
    // if (!values.federalTax) {
    //   return { federalTax: 'Please enter the federal tax for this IRA account' };
    // }
    // if (!values.stateTax) {
    //   return { stateTax: 'Please enter the state tax for this IRA account' };
    // }
  }
  return {};
};

export const validatePositions = (values: CashieringViewFormValues, hasPositions: boolean, positions: Position[]) => {
  const { transfer } = values;
  const positionSymbols = positions.map(p => p.Symbol);
  const uniquePositionSymbols = _.uniq(positionSymbols).join(', ');

  if (hasPositions && transfer && transfer.disbursementType === 'FullBalance' && transfer.closeAccount) {
    return {
      'transfer.closeAccount': `Unable to close account with open positions. Please review your positions and try again: ${uniquePositionSymbols}.`,
    };
  }
};

export const validateTransferMechanism = (values: CashieringViewFormValues, route: CashieringRoute) => {
  if (
    !values.transfer ||
    !values.transfer.mechanism ||
    (values.transfer.mechanism === 'Ach' && !values.transfer.achRelationshipId) ||
    (values.transfer.mechanism === 'Wire' && !values.transfer.instructionId)
  ) {
    return {
      [`transfer.${
        values.transfer && values.transfer.mechanism === 'Wire' ? 'instructionId' : 'achRelationshipId'
      }`]: `Please select how you would like to ${
        route === CashieringRoute.Withdraw ? 'receive' : 'deposit'
      } the funds.`,
    };
  }
  console.log({ values });
  if (values.transfer.mechanism === 'Check') {
    if (!values.transfer.nameOnAccount) {
      return { 'transfer.nameOnAccount': 'Please enter the name as it appears on bank account.' };
    }
    if (!values.transfer.deliveryMethod) {
      return { 'transfer.deliveryMethod': 'Please select the check delivery method.' };
    }
  }

  // if (values.check) {
  //   if (values.check.thirdParty) {
  //     const thirdPartyAddress = values.check.thirdPartyAddress || {};
  //     if (!thirdPartyAddress.inCareOf) {
  //       return { 'check.thirdPartyAddress.inCareOf': 'Please enter the "in care of" name' };
  //     }
  //     if (!thirdPartyAddress.address1) {
  //       return { 'check.thirdPartyAddress.address1': 'Please enter the mailing street name' };
  //     }
  //     if (!thirdPartyAddress.city) {
  //       return { 'check.thirdPartyAddress.city': 'Please enter the mailing city' };
  //     }
  //     if (!thirdPartyAddress.state) {
  //       return { 'check.thirdPartyAddress.state': 'Please enter the mailing state or province' };
  //     }
  //     if (!thirdPartyAddress.postalCode) {
  //       return { 'check.thirdPartyAddress.postalCode': 'Please enter the mailing postal code' };
  //     }
  //     if (!thirdPartyAddress.country) {
  //       return { 'check.thirdPartyAddress.country': 'Please enter the mailing country' };
  //     }
  //   }
  // }

  return {};
};

export const validateTransferAchRelationship = (account: AccountData | undefined) => {
  if (account && account.approvalMethod === 'Plaid') {
    if (!account || !account.plaidAccountId) {
      return { 'account.plaidAccountId': 'Please select the bank account.' };
    }
    if (!account.nickName) {
      return { 'account.nickName': 'Please enter the account nickname.' };
    }
  } else {
    if (!account || !account.bankAccountType) {
      return { 'account.bankAccountType': 'Please select the type of bank account.' };
    }
    if (!account.bankRoutingNumber) {
      return { 'account.bankRoutingNumber': 'Please enter the bank routing account.' };
    }
    if (!account.bankName) {
      return { 'account.bankName': 'Please enter the bank name.' };
    }
    if (!account.bankAccount) {
      return { 'account.bankAccount': 'Please enter the bank account number.' };
    }
    if (!account.bankAccountOwnerName) {
      return { 'account.bankAccountOwnerName': 'Please enter the account owner name.' };
    }
    if (!account.nickName) {
      return { 'account.nickName': 'Please enter the account nickname.' };
    }
  }

  return {};
};

export const validateTransferAchRelationshipOptions = (
  account: AccountData | undefined,
  transfer: TransferData | undefined
) => {
  if (!account || !account.approvalMethod) {
    return { 'account.approvalMethod': 'Please select an approval method.' };
  }

  return {};
};

export const validateTransferRecipientBank = (account: AccountData | undefined) => {
  const bankIdentifierType = account && getTransferBankIdentifierType(account);
  if (!account || !account.name) {
    return { 'account.name': 'Please enter the bank name.' };
  }
  if (!account.identifier) {
    return {
      'account.identifier': `Please enter the bank ${
        bankIdentifierType === TransferBankIdentifierType.BIC ? 'BIC/SWIFT Code' : 'ABA/Routing Number'
      }.`,
    };
  } else {
    if (bankIdentifierType === TransferBankIdentifierType.ABA && !validateABARouting(account.identifier)) {
      return { 'account.identifier': 'Please enter a valid bank ABA/Routing Number.' };
    }
    if (bankIdentifierType === TransferBankIdentifierType.BIC && !validateBICSWIFT(account.identifier)) {
      return { 'account.identifier': 'Please enter a valid bank BIC/SWIFT Code.' };
    }
  }
  if (!account.accountNumber) {
    return { 'account.accountNumber': 'Please enter the bank account.' };
  } else if (!validateAccountNumber(account.accountNumber)) {
    return { 'account.accountNumber': 'Please enter a valid bank account number.' };
  }

  if (!account.nameOnAccount) {
    return { 'transfer.nameOnAccount': 'Please enter the name as it appears on bank account.' };
  }
  if (!account.nickName) {
    return { 'account.nickName': 'Please enter the account nickname.' };
  }

  if (bankIdentifierType === TransferBankIdentifierType.BIC) {
    if (!account.city) {
      return { 'account.city': 'Please enter the bank city.' };
    }
    if (!account.stateProvince) {
      return { 'account.stateProvince': 'Please enter the bank state or province.' };
    }
    if (!account.postalCode) {
      return { 'account.postalCode': 'Please enter the bank postal code.' };
    }
    if (!account.country) {
      return { 'account.country': 'Please enter the bank country.' };
    }
  }

  // need to validate intermediary fields...

  return {};
};

const validateABARouting = (code: string) => {
  if (code.length === 9) {
    // Run through each digit and calculate the total.
    let n = 0;
    for (let i = 0; i < code.length; i += 3) {
      n += parseInt(code.charAt(i), 10) * 3 + parseInt(code.charAt(i + 1), 10) * 7 + parseInt(code.charAt(i + 2), 10);
    }
    // If the resulting sum is an even multiple of ten (but not zero), the aba routing number is good.
    if (n !== 0 && n % 10 === 0) {
      return true;
    }
  }
  return false;
};

const validateBICSWIFT = (code: string) => {
  /*
  Example: AAAABBCC123
  AAAA -- Bank code [A-Z]
  BB -- Country code [A-Z]
  CC -- Location code [0-9A-Z]
  123 -- Branch code [0-9A-Z]
  */
  return /^([A-Z]{4})([A-Z]{2})([0-9A-Z]{2})([0-9A-Z]{3})$/.test(code);
};

const validateAccountNumber = (accountNumber: any) => {
  return /^[0-9]*$/.test(accountNumber);
};
