import React, { useCallback, useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import {
  ContactEmail,
  ContactPhone,
  ExternalLink,
  FormGroup,
  Loading,
  Modal,
  TextboxField,
} from '@tradingblock/components';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { Config } from '../../../config';
import { IsVirtualEnvironment } from '../../../data/global/selectors/environmentSelector';
import { origin } from '../../../data/global/selectors/originSelector';

export const ResetPassword: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
  accountHolderIndex: number;
}> = ({ show, toggleModal, accountHolderIndex }) => {
  const isVirtual = useStateSelector(IsVirtualEnvironment);
  const originVal = useStateSelector(origin.origin);
  let group = originVal === 'mb' ? 'mb' : 'tb';
  const apiRootUrl = Config.tradingApi || undefined;
  const siteParam = originVal === 'mb' ? `?grp=${group}` : '';

  const [authError, setAuthError] = useState<string>();
  const [sentEmail, setSentEmail] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  interface FormValues {
    UserName: string;
    Email: string;
  }

  const accountManagementDetails = useStateSelector(s => s.accountManagement.accountDetails.data);
  const initialResetPasswordProps = {
    UserName: '',
    Email: accountManagementDetails.email ? accountManagementDetails.email : '',
  };

  // handle validate
  const onValidate = (values: FormValues) => {
    if (!values.UserName || !values.Email) {
      return { [`UserName`]: 'Username is required.', [`Email`]: 'Email is required.' };
    }
    if (values.Email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.Email)) {
      return { [`Email`]: 'Invalid email address.' };
    }
    return {};
  };


  const onSubmit = useCallback(async (values: FormValues, { resetForm }: any) => {
    setIsFetching(true);
    await fetch(`${apiRootUrl}/User/PasswordChangeInitiate?siteid=${group}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        UserName: values.UserName,
        Email: values.Email,
      }),
    }).then(async response => {
      if (response.status === 200) {
        setIsFetching(false);
        const content = await response.json();
        if (content.ResponseCode === 0) {
          setAuthError('');
          setIsFetching(false);
          setSentEmail(true);
        } else {
          setIsFetching(false);
          setSentEmail(true);
        }
        resetForm();
      }
    });
  }, []);

  // if sentEmail is true, set timer for 5 seconds to close the modal (if it is still open) and then reset sentEmail to false
  // this will allow users to reset their password again if they need to
  useEffect(() => {
    if (sentEmail) {
      const timer = setTimeout(() => {
        setSentEmail(false);
        if (show) {
          toggleModal();
        }
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [sentEmail]);

  return (
    <>
      <Modal setShow={() => toggleModal()} show={show}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Reset Password</BootstrapModal.Title>
        </BootstrapModal.Header>
        {isFetching && <Loading />}
        {!sentEmail && !isFetching && (
          <Formik initialValues={initialResetPasswordProps} onSubmit={onSubmit} validate={onValidate}>
            {({ errors, touched, submitCount, handleSubmit, resetForm, values }) => (
              <BootstrapModal.Body>
                {isVirtual && (
                  <>
                    <p>
                      Welcome to Virtual Trading. If you do not have a new Virtual account,{' '}
                      <ExternalLink href={Config.applicationUrl + siteParam}>
                        create a virtual trading account
                      </ExternalLink>
                    </p>
                    <p>NOTE: Your live account login is not compatible with Virtual Trading.</p>
                  </>
                )}
                <p className="txt-sm">
                  Please provide your username and email address to begin the password reset process.
                </p>
                <form>
                  <FormGroup>
                    <Field component={TextboxField} id="UserName" placeholder="Username" autoFocus={false} />
                  </FormGroup>
                  <FormGroup>
                    <Field component={TextboxField} id="Email" placeholder="Email" autoFocus={false} />
                  </FormGroup>
                </form>
                <BootstrapModal.Footer className="modal-footer-justified">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      resetForm();
                      toggleModal();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" type="button" onClick={(values: any) => handleSubmit(values)}>
                    Submit
                  </Button>
                </BootstrapModal.Footer>
              </BootstrapModal.Body>
            )}
          </Formik>
        )}
        {sentEmail && (
          <>
            <BootstrapModal.Body>
              <h1 className="fields-title">Password Reset Request Sent</h1>
              <p>
                If the username and email match, you should have received a reset email with instructions on how to
                reset your password. Please contact us if you have any further questions or concerns.
              </p>
              <p>
                <ContactEmail />
                <br />
                <ContactPhone siteGroup={Config.siteGroup} />
              </p>
            </BootstrapModal.Body>
            <BootstrapModal.Footer>
              <Button variant="primary" type="button" onClick={() => toggleModal()}>
                Close
              </Button>
            </BootstrapModal.Footer>
          </>
        )}
        {authError && (
          <BootstrapModal.Body>
            <p
              className="error"
              style={{ marginBottom: 0, marginTop: '1rem' }}
              dangerouslySetInnerHTML={{
                __html: authError,
              }}
            ></p>
          </BootstrapModal.Body>
        )}
      </Modal>
    </>
  );
};
