import { isBlockAction, blockReducer } from '../../../components/blocks/BlockState';
import { getType } from 'typesafe-actions';
import { AccountManagementState, AccountManagementDataState } from './AccountManagementState';
import { AccountManagementBlockActions, AccountManagementBlockAction } from './AccountManagementBlockAction';

export const AccountManagementReducer = (
  blockState: AccountManagementState,
  action: AccountManagementBlockAction
): AccountManagementState => {
  const state = isBlockAction(action) ? blockReducer<AccountManagementDataState, {}>(blockState, action) : blockState;

  switch (action.type) {
    case getType(AccountManagementBlockActions.setTab): {
      const tabVal = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          tab: tabVal,
        },
      };
    }

    default:
      return state;
  }
};
