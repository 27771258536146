import { AssetSymbol, Quote, FeedQuote, EarningsTimeframeValues, DividendTimeframeValues } from '@tradingblock/types';
import { SymbolQuote } from '@tradingblock/api';
import { IBlockDataState } from '@tradingblock/types';
import { OutputSelector } from 'reselect';
import { useBlockData } from '../../../components/blocks/BlockState';
import _ from 'lodash';
import { useMemo } from 'react';

export type QuoteTab = 'summary' | 'profile' | 'earnings' | 'dividends';
export const QuoteBlockConstants: {
  tabs: Record<QuoteTab, QuoteTab>;
} = {
  tabs: {
    summary: 'summary',
    profile: 'profile',
    earnings: 'earnings',
    dividends: 'dividends',
  },
};

export function isQuoteTab(tabVal: QuoteTab | string): tabVal is QuoteTab {
  return _.map(QuoteBlockConstants.tabs, (v, k) => k).includes(tabVal);
}

export interface QuoteState extends IBlockDataState {
  symbol?: AssetSymbol;
  quoteMetaData?: SymbolQuote;
  tab?: QuoteTab;
  earningsPeriod?: EarningsTimeframeValues;
  dividendsPeriod?: DividendTimeframeValues;
}

type Selector<T> = OutputSelector<QuoteState, T, (res: QuoteState) => T>;
type Transform<T> = (res: QuoteState) => T;
export function useQuoteData<T>(selector: Selector<T> | Transform<T>, defaultVal?: T) {
  const data = useBlockData<QuoteState>();
  const res = selector(data);
  return useMemo(() => (!_.isUndefined(defaultVal) && _.isNil(res) ? defaultVal : res), [defaultVal, res]);
}
