import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import {
  SizeType,
  OrderAction,
  AssetType,
  OrderStrategyTypeId,
  OptionType,
  OrderActionDirection,
  TradingStrategyLeg,
  DirectionRules,
} from '@tradingblock/types';
import {
  ResponsiveTd,
  getTableTdClass,
  Number,
  SignedNumber,
  Loading,
  TradingBlockLink,
} from '@tradingblock/components';
import {
  OCCSymbolToOptionData,
  AssetTypeToSymbolType,
  toExpiration,
  toAssetSymbol,
  deriveOptionExpirationType,
  isOCCSymbol,
  AggregatedPositionDTO,
  parseOccSymbol,
  Strategies,
} from '@tradingblock/api';
import { SymbolPositions } from '../../types';
import { useStateSelector } from '../../data/global/dataSelectors';
import { getPositionsFromSymbol } from '../../data/global/selectors/positionSelector';
import { useFeedQuoteWithMetadata, useQuoteMetadata } from '../../hooks/useFeedQuote';
import { FavoriteButton } from '../../components/blocks/FavoriteButton';
import { DefaultLegs } from '../Order/OrderUtilities';
import { DirectionalPrice } from '../../components/trading/DirectionalPrice';
import { PrettySymbol } from '../../components/blocks/content/PrettySymbol';
import { PositionLegActions } from './PositionLegActions';
import { usePositionSettingsValue } from './usePositionSettings';
import { PositionLegDailyPL } from './components/PositionLegDailyPL';
import {
  aggregatedSymbolPosition,
  positionToPerformance,
  QuoteDataToPartialQuote,
} from '../../data/global/selectors/transforms';
import dayjs from 'dayjs';
import { usePositionInfo } from './hooks/usePositionInfo';
import { GroupedPositionLegActions } from './GroupedPositionLegActions';
import { sortColumnsByOrder } from './util';
import useAggregatedPositions, { sortedCustomOptionsFunction } from './hooks/useAggregatedPositions';

export function avgPositionStrat(positions: AggregatedPositionDTO[]) {
  if (positions === undefined || positions.length <= 0) {
    return 0;
  }
  let openCost = positions
    .map(position => position.OpenPrice * position.OpenQuantity * position.OptionMultiplier)
    .reduce((a, v) => a + v);

  let combined_by_net_quantities_positions: AggregatedPositionDTO[] = [];
  for (let position of positions) {
    let position_index = combined_by_net_quantities_positions.findIndex(
      combined_by_net_quantities_position => combined_by_net_quantities_position.Symbol === position.Symbol
    );
    if (position_index === -1) {
      combined_by_net_quantities_positions.push({ ...position, OpenQuantity: Math.abs(position.OpenQuantity) });
    } else {
      combined_by_net_quantities_positions[position_index].OpenQuantity += Math.abs(position.OpenQuantity);
    }
  }

  const option_positions = combined_by_net_quantities_positions.filter(
    position => position.AssetType === AssetType.Option
  );
  let lowest_net_quantity_options = option_positions.reduce(
    (current_net, position) => {
      if (position.OpenQuantity * position.OptionMultiplier > current_net) {
        return Math.abs(current_net);
      }
      return position.OpenQuantity * position.OptionMultiplier;
    },
    option_positions.length > 0 ? option_positions[0].OpenQuantity * option_positions[0].OptionMultiplier : 0
  );

  const stock_positions = combined_by_net_quantities_positions.filter(
    position => position.AssetType !== AssetType.Option
  );
  let lowest_net_quantity_stock = stock_positions.reduce(
    (current_net, position) => {
      if (position.OpenQuantity * position.OptionMultiplier > current_net) {
        return Math.abs(current_net);
      }
      return position.OpenQuantity * position.OptionMultiplier;
    },
    stock_positions.length > 0 ? stock_positions[0].OpenQuantity * stock_positions[0].OptionMultiplier : 0
  );

  return lowest_net_quantity_options !== 0
    ? Math.abs(openCost / Math.abs(lowest_net_quantity_options))
    : lowest_net_quantity_stock !== 0
    ? Math.abs(openCost / Math.abs(lowest_net_quantity_stock))
    : 0;
}

/**
 * TODO: This file needs to be refactored a bit when the backend can better support strategies classification
 */
export const PositionLeg: React.FunctionComponent<{
  underlyingSymbol: string;
  size: SizeType;
  maxVisibleActions: number;
  subAccountId?: number;
  columnOrder?: string[];
}> = ({ underlyingSymbol, size, maxVisibleActions, subAccountId, columnOrder }) => {
  const { combineSameSymbols, groupPositionsByStrategy, expandRows } = usePositionSettingsValue();
  const settings = {
    combineSameSymbols: combineSameSymbols || false,
    groupPositionsByStrategy: groupPositionsByStrategy || false,
    expandRows: expandRows || false,
    columnOrder: [],
  };
  const shouldGroupByStrategy = !settings.groupPositionsByStrategy; //Need to invert this due to how default works.
  const symbolPositions: SymbolPositions = useStateSelector(getPositionsFromSymbol);

  const positions: AggregatedPositionDTO[] = useAggregatedPositions(
    underlyingSymbol,
    symbolPositions,
    settings.combineSameSymbols,
    settings.groupPositionsByStrategy,
    subAccountId
  );

  const groupedPositions = _.groupBy(positions, p => p.OrderId);
  const customPositions = _.groupBy(groupedPositions, gp => gp.length === 1);
  if (customPositions['true'] !== undefined && customPositions['true'][0] !== undefined) {
    customPositions['true'][0][0].StrategyTypeId = OrderStrategyTypeId.Undefined;
  }

  let sortedPositions = useMemo(() => {
    return positions.sort(sortedCustomOptionsFunction);
  }, [positions]);

  const sortedCustomOptions: AggregatedPositionDTO[] = useMemo(() => {
    if (customPositions['true'] !== undefined) {
      const agg_positions: AggregatedPositionDTO[] = customPositions['true']
        .flat()
        .filter(pos => pos.AssetType !== AssetType.Equity);
      return agg_positions.sort(sortedCustomOptionsFunction);
    }
    return [];
  }, [customPositions['true']]);

  if (shouldGroupByStrategy) {
    return (
      <>
        {customPositions['true'] !== undefined &&
          customPositions['true'].flat().filter(pos => pos.AssetType === AssetType.Equity).length > 0 && (
            <GroupedStrategyPosition
              groupedPosition={customPositions['true']
                .flat()
                .filter(pos => pos.AssetType === AssetType.Equity)
                .map(pos => ({ ...pos, StrategyTypeId: OrderStrategyTypeId.Stock }))}
              size={size}
              maxVisibleActions={maxVisibleActions}
              columnOrder={columnOrder}
              key={'shares positions'}
              expandRows={expandRows}
              subAccountId={subAccountId}
            />
          )}
        {customPositions['false'] !== undefined &&
          customPositions['false'].flat().length > 0 &&
          Object.values(customPositions['false']).map((gPos, i) => (
            <GroupedStrategyPosition
              groupedPosition={gPos}
              size={size}
              maxVisibleActions={maxVisibleActions}
              columnOrder={columnOrder}
              key={'gPos' + i}
              expandRows={expandRows}
              subAccountId={subAccountId}
            />
          ))}
        {sortedCustomOptions !== undefined &&
          sortedCustomOptions.length > 0 &&
          sortedCustomOptions.map((position, i) => (
            <PositionLegRow
              key={`${position.Symbol}-leg-${i}`}
              position={position}
              size={size}
              maxVisibleActions={maxVisibleActions}
              columnOrder={columnOrder}
              subAccountId={subAccountId}
            />
          ))}
      </>
    );
  }

  return (
    <>
      {sortedPositions.map((p, i) => {
        return (
          <PositionLegRow
            key={`${p.Symbol}-leg-${i}`}
            position={p}
            size={size}
            maxVisibleActions={maxVisibleActions}
            columnOrder={columnOrder}
          />
        );
      })}
    </>
  );
};

function StrategySymbology(groupedPosition: AggregatedPositionDTO[]) {
  const { StrategyTypeId } = groupedPosition[0];
  let strategy = 'Custom';
  switch (StrategyTypeId) {
    case OrderStrategyTypeId.Stock:
      strategy = 'Shares';
      return (
        <div>
          <div>{strategy}</div>
        </div>
      );
    case OrderStrategyTypeId.Single:
      strategy = `${groupedPosition.map(p => p.Description.includes('PUT')).includes(true) ? 'Put' : 'Call'}`;
      break;
    case OrderStrategyTypeId.CoveredCall:
      strategy = 'Covered Call';
      break;
    case OrderStrategyTypeId.MarriedCall:
      strategy = 'Covered Call';
      break;
    case OrderStrategyTypeId.MarriedPut:
      strategy = 'Married Put';
      break;
    case OrderStrategyTypeId.CoveredPut:
      strategy = 'Married Put';
      break;
    case OrderStrategyTypeId.Vertical:
      strategy = `${groupedPosition.map(p => p.Description.includes('PUT')).includes(true) ? 'Put' : 'Call'} Vertical`;
      break;
    case OrderStrategyTypeId.Calendar: //Need a Call Calendar:
      strategy = `${groupedPosition.map(p => p.Description.includes('PUT')).includes(true) ? 'Put' : 'Call'} Calendar`;
      break;
    case OrderStrategyTypeId.Straddle:
      strategy = 'Straddle';
      break;
    case OrderStrategyTypeId.Strangle:
      strategy = 'Strangle';
      break;
    case OrderStrategyTypeId.Butterfly: //Need Call:
      strategy = `${groupedPosition.map(p => p.Description.includes('PUT')).includes(true) ? 'Put' : 'Call'} Butterfly`;
      break;
    case OrderStrategyTypeId.Condor: //Need Call:
      strategy = `${groupedPosition.map(p => p.Description.includes('PUT')).includes(true) ? 'Put' : 'Call'} Condor`;
      break;
    case OrderStrategyTypeId.IronButterfly:
      strategy = 'Iron Butterfly';
      break;
    case OrderStrategyTypeId.IronConder:
      strategy = 'Iron Condor';
      break;
    case OrderStrategyTypeId.Conversion:
      strategy = 'Conversion';
      break;
    case OrderStrategyTypeId.Undefined:
      strategy = 'Reversal';
      break;
    case OrderStrategyTypeId.Box:
      strategy = 'Box Spread';
      break;
  }

  return (
    <div>
      <sub>
        {`${_.uniq(
          groupedPosition.map(p =>
            dayjs(parseOccSymbol(p.Symbol).expiration ? parseOccSymbol(p.Symbol).expiration : p.DateOpened).format(
              'MMM DD'
            )
          )
        ).join(', ')}`}
      </sub>
      <div>{strategy}</div>
      <sup>
        {`${groupedPosition
          .map(p => (parseOccSymbol(p.Symbol).strike ? parseOccSymbol(p.Symbol).strike : `${p.OpenQuantity} Shares`))
          .join(', ')}`}
      </sup>
    </div>
  );
}

export function StrategyName(groupedPosition: AggregatedPositionDTO[]) {
  const { Symbol, StrategyTypeId } = groupedPosition[0];
  let strategy = 'Custom';
  switch (StrategyTypeId) {
    case OrderStrategyTypeId.Stock:
      strategy = 'Shares';
      break;
    case OrderStrategyTypeId.Single:
      strategy = `${groupedPosition.map(p => p.Description.includes('PUT')).includes(true) ? 'Put' : 'Call'}`;
      break;
    case OrderStrategyTypeId.CoveredCall:
      strategy = 'Covered Call';
      break;
    case OrderStrategyTypeId.MarriedCall:
      strategy = 'Covered Call';
      break;
    case OrderStrategyTypeId.MarriedPut:
      strategy = 'Married Put';
      break;
    case OrderStrategyTypeId.CoveredPut:
      strategy = 'Married Put';
      break;
    case OrderStrategyTypeId.Vertical:
      strategy = `${groupedPosition.map(p => p.Description.includes('PUT')).includes(true) ? 'Put' : 'Call'} Vertical`;
      break;
    case OrderStrategyTypeId.Calendar: //Need a Call Calendar:
      strategy = `${groupedPosition.map(p => p.Description.includes('PUT')).includes(true) ? 'Put' : 'Call'} Calendar`;
      break;
    case OrderStrategyTypeId.Straddle:
      strategy = 'Straddle';
      break;
    case OrderStrategyTypeId.Strangle:
      strategy = 'Strangle';
      break;
    case OrderStrategyTypeId.Butterfly: //Need Call:
      strategy = `${groupedPosition.map(p => p.Description.includes('PUT')).includes(true) ? 'Put' : 'Call'} Butterfly`;
      break;
    case OrderStrategyTypeId.Condor: //Need Call:
      strategy = `${groupedPosition.map(p => p.Description.includes('PUT')).includes(true) ? 'Put' : 'Call'} Condor`;
      break;
    case OrderStrategyTypeId.IronButterfly:
      strategy = 'Iron Butterfly';
      break;
    case OrderStrategyTypeId.IronConder:
      strategy = 'Iron Condor';
      break;
    case OrderStrategyTypeId.Conversion:
      strategy = 'Conversion';
      break;
    case OrderStrategyTypeId.Undefined:
      strategy = 'Reversal';
      break;
    case OrderStrategyTypeId.Box:
      strategy = 'Box Spread';
      break;
  }
  return strategy;
}

const isValidPositionStrategy = (positions: AggregatedPositionDTO[]) => {
  const stratName = StrategyName(positions);
  if (stratName === 'Shares') {
    return true;
  }
  if (stratName === 'Custom') {
    return false;
  }
  let strategy = Strategies.find(s => s.info.Name === stratName);
  if (strategy === undefined || positions.length !== strategy.profile.Legs.length) {
    return false;
  }

  // Handle different directions for calendars
  if (strategy.profile.Rules.Direction === DirectionRules.DifferentDirections) {
    strategy.profile.Legs[0].Direction = OrderActionDirection.Long;
    strategy.profile.Legs[1].Direction = OrderActionDirection.Short;
  }

  //Sort order, Equities, CALLS, Short CALLS, PUTS, Short PUTS, on strategy legs and position legs
  let sortedStrategyLegs = strategy.profile.Legs.sort((a, b) => {
    let aWeight =
      a.AssetType === AssetType.Equity
        ? 0
        : a.OptionType === OptionType.Call && a.Direction === OrderActionDirection.Long
        ? 1
        : a.OptionType === OptionType.Call && a.Direction === OrderActionDirection.Short
        ? 2
        : a.OptionType === OptionType.Put && a.Direction === OrderActionDirection.Long
        ? 3
        : a.OptionType === OptionType.Put && a.Direction === OrderActionDirection.Short
        ? 4
        : 5;
    let bWeight =
      b.AssetType === AssetType.Equity
        ? 0
        : b.OptionType === OptionType.Call && a.Direction === OrderActionDirection.Long
        ? 1
        : b.OptionType === OptionType.Call && a.Direction === OrderActionDirection.Short
        ? 2
        : b.OptionType === OptionType.Put && a.Direction === OrderActionDirection.Long
        ? 3
        : b.OptionType === OptionType.Put && a.Direction === OrderActionDirection.Short
        ? 4
        : 5;
    return aWeight - bWeight;
  });
  const sortedPositionsLegs = positions.sort((a, b) => {
    let aWeight =
      a.AssetType === AssetType.Equity
        ? 0
        : a.Description.includes('CALL') && a.OpenQuantity > 0
        ? 1
        : a.Description.includes('CALL') && a.OpenQuantity < 0
        ? 2
        : a.Description.includes('PUT') && a.OpenQuantity > 0
        ? 3
        : a.Description.includes('PUT') && a.OpenQuantity < 0
        ? 4
        : 5;
    let bWeight =
      b.AssetType === AssetType.Equity
        ? 0
        : b.Description.includes('CALL') && a.OpenQuantity > 0
        ? 1
        : b.Description.includes('CALL') && a.OpenQuantity < 0
        ? 2
        : b.Description.includes('PUT') && a.OpenQuantity > 0
        ? 3
        : b.Description.includes('PUT') && a.OpenQuantity < 0
        ? 4
        : 5;
    return aWeight - bWeight;
  });

  //Lowest common denominator of position quantities
  const spreadRatioQuantities = sortedStrategyLegs.map((strategyLeg, legIndex) =>
    Math.floor(sortedPositionsLegs[legIndex].OpenQuantity / (strategyLeg.SpreadRatio ? strategyLeg.SpreadRatio : 1))
  );
  //Lowest common denominator of position quantities
  let lowest_common_denominator = Math.abs(Math.min(...spreadRatioQuantities));
  //Highest denominator
  let highest_denominator = Math.abs(Math.max(...spreadRatioQuantities));

  const longStrategyValid = sortedStrategyLegs.every((strategyLeg, legIndex) => {
    //If any of our legs are not within the spread radio, then the user broke away from the strategy
    return validatePositionLegs(strategyLeg, sortedPositionsLegs[legIndex]);
  });

  //By default if not bidirectional strategy is not selected, the short side is true;
  let shortStrategyValid = true;
  if (strategy.profile.Bidirectional) {
    //Invert Strategy Legs to see if we are short for a bidirectional type of strategy
    sortedStrategyLegs = sortedStrategyLegs.map(leg => ({
      ...leg,
      Direction: leg.Direction === OrderActionDirection.Long ? OrderActionDirection.Short : OrderActionDirection.Long,
    }));
    shortStrategyValid = sortedStrategyLegs.every((strategyLeg, legIndex) => {
      //If any of our legs are not within the spread radio, then the user broke away from the strategy
      return validatePositionLegs(strategyLeg, sortedPositionsLegs[legIndex]);
    });
  }

  return (longStrategyValid || shortStrategyValid) && lowest_common_denominator === highest_denominator;
};

export function countValidStrategyPositions(positions: AggregatedPositionDTO[]) {
  const stratName = StrategyName(positions);
  if (stratName === 'Shares') {
    return 0;
  }
  if (stratName === 'Custom') {
    return 0;
  }
  let strategy = Strategies.find(s => s.info.Name === stratName);
  if (strategy === undefined || positions.length !== strategy.profile.Legs.length) {
    return 0;
  }

  // Handle different directions for calendars
  if (strategy.profile.Rules.Direction === DirectionRules.DifferentDirections) {
    strategy.profile.Legs[0].Direction = OrderActionDirection.Long;
    strategy.profile.Legs[1].Direction = OrderActionDirection.Short;
  }

  //Sort order, Equities, CALLS, Short CALLS, PUTS, Short PUTS, on strategy legs and position legs
  let sortedStrategyLegs = strategy.profile.Legs.sort((a, b) => {
    let aWeight =
      a.AssetType === AssetType.Equity
        ? 0
        : a.OptionType === OptionType.Call && a.Direction === OrderActionDirection.Long
        ? 1
        : a.OptionType === OptionType.Call && a.Direction === OrderActionDirection.Short
        ? 2
        : a.OptionType === OptionType.Put && a.Direction === OrderActionDirection.Long
        ? 3
        : a.OptionType === OptionType.Put && a.Direction === OrderActionDirection.Short
        ? 4
        : 5;
    let bWeight =
      b.AssetType === AssetType.Equity
        ? 0
        : b.OptionType === OptionType.Call && a.Direction === OrderActionDirection.Long
        ? 1
        : b.OptionType === OptionType.Call && a.Direction === OrderActionDirection.Short
        ? 2
        : b.OptionType === OptionType.Put && a.Direction === OrderActionDirection.Long
        ? 3
        : b.OptionType === OptionType.Put && a.Direction === OrderActionDirection.Short
        ? 4
        : 5;
    return aWeight - bWeight;
  });
  const sortedPositionsLegs = positions.sort((a, b) => {
    let aWeight =
      a.AssetType === AssetType.Equity
        ? 0
        : a.Description.includes('CALL') && a.OpenQuantity > 0
        ? 1
        : a.Description.includes('CALL') && a.OpenQuantity < 0
        ? 2
        : a.Description.includes('PUT') && a.OpenQuantity > 0
        ? 3
        : a.Description.includes('PUT') && a.OpenQuantity < 0
        ? 4
        : 5;
    let bWeight =
      b.AssetType === AssetType.Equity
        ? 0
        : b.Description.includes('CALL') && a.OpenQuantity > 0
        ? 1
        : b.Description.includes('CALL') && a.OpenQuantity < 0
        ? 2
        : b.Description.includes('PUT') && a.OpenQuantity > 0
        ? 3
        : b.Description.includes('PUT') && a.OpenQuantity < 0
        ? 4
        : 5;
    return aWeight - bWeight;
  });

  //Lowest common denominator of position quantities
  const spreadRatioQuantities = sortedStrategyLegs.map((strategyLeg, legIndex) =>
    Math.floor(sortedPositionsLegs[legIndex].OpenQuantity / (strategyLeg.SpreadRatio ? strategyLeg.SpreadRatio : 1))
  );
  //Lowest common denominator of position quantities
  let lowest_common_denominator = Math.abs(Math.min(...spreadRatioQuantities));

  return lowest_common_denominator;
}

export function validatePositionLegs(strategyLeg: TradingStrategyLeg, legPosition: AggregatedPositionDTO) {
  if (strategyLeg.SpreadRatio && legPosition.OpenQuantity % strategyLeg.SpreadRatio !== 0) {
    return false;
  }
  //Equities are valid legs, no need to validate them.
  if (strategyLeg.AssetType === AssetType.Equity && legPosition.AssetType === AssetType.Equity) {
    return true;
  }

  const positionOptionType: OptionType = legPosition.Description.includes('CALL') ? OptionType.Call : OptionType.Put;
  const positionDirection: OrderActionDirection =
    legPosition.OpenQuantity > 0 ? OrderActionDirection.Long : OrderActionDirection.Short;
  return (
    strategyLeg.AssetType === legPosition.AssetType &&
    strategyLeg.OptionType === positionOptionType &&
    strategyLeg.Direction === positionDirection
  );
}

const GroupedStrategyPosition = ({
  groupedPosition,
  size,
  maxVisibleActions,
  columnOrder,
  expandRows,
  subAccountId,
}: {
  groupedPosition: AggregatedPositionDTO[];
  size: SizeType;
  maxVisibleActions: number;
  columnOrder?: string[];
  expandRows?: boolean;
  subAccountId?: number;
}) => {
  const [expandStratPosition, setExpandStratPosition] = useState<boolean>(false);
  const feedQuotes = useQuoteMetadata(groupedPosition.map(p => p.Symbol));
  const performance = useMemo(
    () =>
      aggregatedSymbolPosition(
        groupedPosition,
        QuoteDataToPartialQuote(feedQuotes as any),
        groupedPosition[0].UnderlyingSymbol
      ),
    [groupedPosition, feedQuotes]
  );
  const posInfo = usePositionInfo(groupedPosition);
  const {
    Change,
    Description,
    Last,
    PrevClose,
    Ask,
    Close,
    Bid,
    Gain,
    Options,
    Cost,
    Percent,
    Value,
    Shares,
    PNL,
    AvgPrice,
  } = usePositionSettingsValue();
  if (groupedPosition.length === 1) {
    if (!groupedPosition[0].Description) {
      groupedPosition[0].Description = 'Stock';
      groupedPosition[0].StrategyTypeId = OrderStrategyTypeId.Stock;
    }
    if (groupedPosition[0].Description.includes('CALL')) {
      groupedPosition[0].StrategyTypeId = OrderStrategyTypeId.Single;
    } else if (groupedPosition[0].Description.includes('PUT')) {
      groupedPosition[0].StrategyTypeId = OrderStrategyTypeId.Single;
    } else {
      groupedPosition[0].StrategyTypeId = OrderStrategyTypeId.Stock;
    }
  }

  const isValidStrategy = useMemo(() => {
    return isValidPositionStrategy(groupedPosition);
  }, [groupedPosition]);
  const strategyCount = useMemo(() => {
    return countValidStrategyPositions(groupedPosition);
  }, [groupedPosition]);
  useEffect(() => {
    if (expandRows) {
      setExpandStratPosition(true);
    } else {
      setExpandStratPosition(false);
    }
  }, [expandRows]);

  const subRowColumns = useMemo(() => {
    const columns = [
      <ResponsiveTd
        header="Expiration"
        id="expander"
        className="pivoted"
        onClick={() => {
          setExpandStratPosition(!expandStratPosition);
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <i
            style={{
              marginRight: 8,
              fontSize: 16,
            }}
            className={`far fa-angle-${expandStratPosition ? 'down' : 'right'}`}
          ></i>
          {isValidStrategy ? (
            StrategySymbology(groupedPosition)
          ) : (
            <div>
              <sub>
                {`${_.uniq(
                  groupedPosition.map(p =>
                    dayjs(
                      parseOccSymbol(p.Symbol).expiration ? parseOccSymbol(p.Symbol).expiration : p.DateOpened
                    ).format('MMM DD')
                  )
                ).join(', ')}`}
              </sub>
              <div>{'Custom'}</div>
              <sup>
                {`${groupedPosition
                  .map(p =>
                    parseOccSymbol(p.Symbol).strike ? parseOccSymbol(p.Symbol).strike : `${p.OpenQuantity} Shares`
                  )
                  .join(', ')}`}
              </sup>
            </div>
          )}
        </div>
      </ResponsiveTd>,
      <ResponsiveTd hidden={!Description} header="Description" id="description"></ResponsiveTd>,
      <ResponsiveTd hidden={!Last} header="Last" id="last">
        {isNaN(posInfo.last) ? (
          <Loading size={'small'} />
        ) : (
          <DirectionalPrice precisionOverride={true} prefix={'$'} value={posInfo.last} />
        )}
      </ResponsiveTd>,
      <ResponsiveTd hidden={!Change} header="Change" id="change" style={{ minWidth: '100%' }}>
        <SignedNumber precisionOverride={true} value={posInfo.change ? posInfo.change : 0} bold currency hideIcon />
      </ResponsiveTd>,
      <ResponsiveTd hidden={!Percent} header="(%)" id="%">
        <SignedNumber
          precisionOverride={true}
          value={posInfo.changePercent ? posInfo.changePercent : 0}
          bold
          percent
          hideIcon
        />
      </ResponsiveTd>,
      <ResponsiveTd hidden={!Bid} header="Bid" id="bid">
        <DirectionalPrice value={posInfo.bid} />
      </ResponsiveTd>,
      <ResponsiveTd hidden={!Ask} header="Ask" id="ask">
        <DirectionalPrice value={posInfo.ask} />
      </ResponsiveTd>,
      <ResponsiveTd hidden={!PrevClose} header="Close" id="close">
        {isNaN(posInfo.close) ? (
          <Loading size={'small'} />
        ) : posInfo.sharesCount > 0 && posInfo.usesOptions === false && posInfo.isSettlementSet ? (
          <Number precisionOverride={true} prefix={'$'} value={posInfo.prevClose} currency />
        ) : (
          <Number precisionOverride={true} prefix={'$'} value={posInfo.close} currency />
        )}
      </ResponsiveTd>,
      <ResponsiveTd hidden={!Close} header="Settlement" id="Settlement">
        {posInfo.isSettlementSet === true ? (
          <Number precisionOverride={true} prefix={'$'} value={posInfo.close} currency />
        ) : posInfo.isSettlementSet === false ? (
          <i className="fal fa-clock fa-lg mute" title="Settlement price not yet available." />
        ) : (
          <></>
        )}
      </ResponsiveTd>,
      <ResponsiveTd hidden={!AvgPrice} header="Avg Price" id="avg_price">
        <Number value={avgPositionStrat(groupedPosition)} currency />
      </ResponsiveTd>,
      <ResponsiveTd hidden={!Shares} header="Shares" id="shares" className={`text-center`}>
        {posInfo.sharesCount && <Number value={posInfo.sharesCount} integer />}
      </ResponsiveTd>,
      <ResponsiveTd hidden={!Options} header="Options" id="options" className={`text-center`}>
        {posInfo.usesOptions ? (
          isValidStrategy ? (
            <Number value={strategyCount} integer />
          ) : (
            <i className="fal fa-check" />
          )
        ) : (
          <></>
        )}
      </ResponsiveTd>,
      <ResponsiveTd hidden={!Cost} header="Cost" id="Cost">
        <Number value={performance.cost - posInfo.commission} currency />
      </ResponsiveTd>,
      <ResponsiveTd hidden={!Value} header="Value" id="Value">
        <Number value={performance.value} currency />
      </ResponsiveTd>,
      <ResponsiveTd hidden={!Gain} header="Gain" id="Gain">
        <SignedNumber value={performance.gain + posInfo.commission} bold currency hideIcon />
      </ResponsiveTd>,
      <ResponsiveTd hidden={!PNL} header="Daily P/L" id="Daily P/L">
        <PositionLegDailyPL positionIds={groupedPosition.map(p => p.PositionIds).flat()} />
      </ResponsiveTd>,
      <ResponsiveTd header="Actions" id="Actions">
        <GroupedPositionLegActions
          key={`${groupedPosition.map(p => p.PositionIds).join('-')}`}
          position={groupedPosition}
        />
      </ResponsiveTd>,
    ];

    sortColumnsByOrder(columns, columnOrder ? columnOrder : []);
    return columns;
  }, [groupedPosition, posInfo, performance, expandStratPosition, columnOrder]);

  return (
    <>
      <tr
        className="row-expanded row-sub"
        style={{
          backgroundColor: '#0c1d3f',
          cursor: 'pointer',
        }}
      >
        {subRowColumns.map((col, i) => (
          <React.Fragment key={i}>{col}</React.Fragment>
        ))}
      </tr>
      {expandStratPosition &&
        groupedPosition.map((p: any, i: any) => (
          <PositionLegRow
            indentMargin={32}
            key={`${p.Symbol}-leg-${i}`}
            position={p}
            size={size}
            maxVisibleActions={maxVisibleActions}
            columnOrder={columnOrder}
          />
        ))}
    </>
  );
};

const PositionLegRow: React.FunctionComponent<{
  position: AggregatedPositionDTO;
  size: SizeType;
  maxVisibleActions: number;
  color?: string;
  indentMargin?: number;
  columnOrder?: string[];
  subAccountId?: number;
}> = ({ position, size, maxVisibleActions, color, indentMargin, columnOrder, subAccountId }) => {
  const [symbol, underlyingSymbol, assetType] = [position.Symbol, position.UnderlyingSymbol, position.AssetType];
  const feedQuote = useFeedQuoteWithMetadata(symbol.replace(';', ''));
  const {
    Change,
    Description,
    Last,
    Ask,
    PrevClose,
    Close,
    Bid,
    Gain,
    Options,
    Cost,
    Percent,
    Value,
    Shares,
    PNL,
    AvgPrice,
  } = usePositionSettingsValue();
  const assetSymbol = useMemo(
    () =>
      toAssetSymbol(position.Symbol, assetType, AssetTypeToSymbolType(assetType), {
        name: underlyingSymbol,
        underlyingSymbol,
        rootSymbol: underlyingSymbol,
      }),
    [position.Symbol, assetType, underlyingSymbol]
  );
  const performance = useMemo(() => positionToPerformance(position, feedQuote), [position, feedQuote]);
  const optionData = useMemo(() => OCCSymbolToOptionData(symbol), [symbol]);

  const tradeData = useMemo(() => {
    const optionType = optionData.option;
    const strike = optionData.strike;
    const expiration = optionData.expiration;
    // for custom strategy, quantity is stored in spreadRatio
    const openQuantity = Math.abs(position.OpenQuantity);
    const assetType = optionType ? AssetType.Option : AssetType.Equity;
    const action = position.OpenQuantity > 0 ? OrderAction.Sell : OrderAction.Buy;
    const optionExpirationType = deriveOptionExpirationType(assetSymbol.symbol, assetSymbol.underlyingSymbol || '');
    const legs = DefaultLegs({}, assetSymbol, {
      assetType,
      optionType,
      orderAction: action,
      strike,
      expiration: expiration ? toExpiration(expiration, assetSymbol.symbol, optionExpirationType) : undefined,
    });
    return { legs, action, quantity: openQuantity };
  }, [assetSymbol, position, optionData]);

  const tdClass = getTableTdClass(size);
  const { combineSameSymbols } = usePositionSettingsValue();

  const isShare = useMemo(() => _.isNil(position.meta.OptionExpirationType), [position]);
  const combinedShares = combineSameSymbols && isShare;

  const subRowColumns = useMemo(() => {
    const columns = [
      <ResponsiveTd
        header="Expiration"
        id="expander"
        className={tdClass}
        style={indentMargin !== undefined ? { paddingLeft: indentMargin } : {}}
      >
        {combinedShares && <>{position.Symbol}</>}
        {!combinedShares && (
          <PrettySymbol
            symbol={position.Symbol}
            expirationType={position.meta.OptionExpirationType}
            dateOpened={position.DateOpened}
          />
        )}
        {isOCCSymbol(symbol) && <FavoriteButton symbol={assetSymbol} />}
      </ResponsiveTd>,
      <ResponsiveTd hidden={!Description} header="Description" id="description" className={tdClass}></ResponsiveTd>,
      <ResponsiveTd hidden={!Last} header="Last" id="last" className={tdClass}>
        <DirectionalPrice precisionOverride={true} prefix={'$'} value={performance.lastTradePrice} />
      </ResponsiveTd>,
      <ResponsiveTd hidden={!Change} header="Change" id="change" className={tdClass}>
        <SignedNumber
          precisionOverride={true}
          value={performance.change ? performance.change : 0}
          bold
          currency
          hideIcon
        />
      </ResponsiveTd>,
      <ResponsiveTd hidden={!Percent} header="(%)" id="%" className={tdClass}>
        <SignedNumber
          precisionOverride={true}
          value={performance.changePercent ? performance.changePercent : 0}
          bold
          percent
          hideIcon
        />
      </ResponsiveTd>,
      <ResponsiveTd hidden={!Bid} header="Bid" id="bid" className={tdClass}>
        {position.AssetType !== AssetType.MoneyMarket && position.AssetType !== AssetType.MutualFund && (
          <DirectionalPrice value={performance.bid} />
        )}
      </ResponsiveTd>,
      <ResponsiveTd hidden={!Ask} header="Ask" id="ask" className={tdClass}>
        {position.AssetType !== AssetType.MoneyMarket && position.AssetType !== AssetType.MutualFund && (
          <DirectionalPrice value={performance.ask} />
        )}
      </ResponsiveTd>,
      <ResponsiveTd hidden={!PrevClose} header="Close" id="close" className={tdClass}>
        {performance.IsSettlementSet === true ? (
          <Number precisionOverride={true} prefix={'$'} value={performance.prevClose} currency />
        ) : (
          <Number precisionOverride={true} prefix={'$'} value={performance.close} currency />
        )}
      </ResponsiveTd>,
      <ResponsiveTd hidden={!Close} header="Settlement" id="Settlement">
        {performance.IsSettlementSet === true && (
          <Number precisionOverride={true} prefix={'$'} value={performance.close} currency />
        )}
        {performance.IsSettlementSet === false && (
          <i className="fal fa-clock fa-lg mute" title="Settlement price not yet available." />
        )}
      </ResponsiveTd>,
      <ResponsiveTd hidden={!AvgPrice} header="Avg Price" id="avg_price" className={tdClass}>
        <Number value={position.OpenPrice} currency />
      </ResponsiveTd>,
      <ResponsiveTd hidden={!Shares} header="Shares" id="shares" className={`${tdClass} text-center`}>
        {!optionData.option && (
          <Number
            value={performance.quantity}
            integer={position.AssetType !== AssetType.MoneyMarket && position.AssetType !== AssetType.MutualFund}
          />
        )}
      </ResponsiveTd>,
      <ResponsiveTd hidden={!Options} header="Options" id="options" className={`${tdClass} text-center`}>
        {optionData.option && <Number value={performance.quantity} integer />}
      </ResponsiveTd>,
      <ResponsiveTd hidden={!Cost} header="Cost" id="Cost" className={tdClass}>
        <Number value={performance.cost - position.Commission} currency />
      </ResponsiveTd>,
      <ResponsiveTd hidden={!Value} header="Value" id="Value" className={tdClass}>
        <Number value={performance.value} currency />
      </ResponsiveTd>,
      <ResponsiveTd hidden={!Gain} header="Gain" id="Gain" className={tdClass}>
        {/* TBFE:1515: Switched to FE calculation of gain value and removed the addition of the commission. This should be reverted once we switch back to the BE value for position cost value. */}
        {position.AssetType !== AssetType.MoneyMarket && position.AssetType !== AssetType.MutualFund ? (
          <SignedNumber value={performance.gain + position.Commission} bold currency hideIcon />
        ) : (
          <SignedNumber value={0} bold currency hideIcon />
        )}
      </ResponsiveTd>,
      <ResponsiveTd hidden={!PNL} header="Daily P/L" id="Daily P/L" className={tdClass}>
        <PositionLegDailyPL positionIds={position.PositionIds} />
      </ResponsiveTd>,
      <ResponsiveTd header="Actions" id="Actions" className={tdClass}>
        {position.AssetType === AssetType.MoneyMarket || position.AssetType === AssetType.MutualFund ? (
          <div>
            <TradingBlockLink to={'LegacySiteLoginUrl'}>
              <i className="fa-2x badge-icon far fa-info-circle"></i> Legacy Support Only
            </TradingBlockLink>
          </div>
        ) : (
          <PositionLegActions
            key={`${position.PositionIds.join('-')}`}
            tradeData={tradeData}
            position={position}
            maxVisibleActions={maxVisibleActions}
          />
        )}
      </ResponsiveTd>,
    ];
    sortColumnsByOrder(columns, columnOrder ? columnOrder : []);
    return columns;
  }, [
    position,
    tradeData,
    optionData,
    combinedShares,
    size,
    indentMargin,
    performance,
    maxVisibleActions,
    columnOrder,
    combineSameSymbols,
  ]);
  return (
    <tr
      className="row-expanded row-sub"
      style={
        color !== undefined
          ? {
              backgroundColor: color,
            }
          : {}
      }
    >
      {subRowColumns.map((column, i) => (
        <React.Fragment key={i}>{column}</React.Fragment>
      ))}
    </tr>
  );
};
