import { Middleware, Dispatch } from 'redux';
import { getType } from 'typesafe-actions';
import _ from 'lodash';
import { DataState } from '../../state';
import { RootAction, OptionChainActions, ExpirationStrikeActions } from '../../actions';
import { Dispatcher } from '../../dispatcher';
import { ApiProvider } from '../../../../context/Api';

const tryLoadExpirations = async (
  prevState: DataState,
  dispatch: Dispatch<RootAction>,
  symbol: string,
  blockId?: string
) => {
  const dispatcher = Dispatcher(dispatch);
  const api = ApiProvider(prevState, dispatch);
  const { expirations } = prevState;
  const symbolExpirations = expirations[symbol] || undefined;
  const isLoading = symbolExpirations && symbolExpirations.isFetching === true;
  const isLoaded =
    symbolExpirations !== undefined &&
    symbolExpirations.expirations !== undefined &&
    symbolExpirations.expirations.length > 0;
  if (!isLoading && !isLoaded) {
    api.options.expirations(symbol).then(resp => {
      if (resp.responseCode !== 0) {
        console.warn('response code wasnt 0', resp);
      } else {
        if (blockId) {
          dispatcher.optionchain.receiveExpirations(blockId, symbol, resp.payload);
        }

        dispatcher.expirations.receive(symbol, resp.payload);
      }
    });
  } else if (blockId && isLoaded) {
    dispatcher.optionchain.receiveExpirations(blockId, symbol, symbolExpirations.expirations || []);
  }
};

export const OptionsMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  try {
    // state BEFORE action is dispatched
    const prevState = getState();
    const result = next(action);
    // state AFTER action is dispatched
    //const nextState = middleware.getState();
    try {
      switch (action.type) {
        case getType(ExpirationStrikeActions.requestExpirationAction):
        case getType(OptionChainActions.requestOptionExpirations): {
          tryLoadExpirations(prevState, dispatch, action.payload.symbol, _.get(action.payload, 'blockId', undefined));
          break;
        }
      }
    } catch (apiError) {
      Dispatcher(dispatch).global.exception({ error: apiError, data: action });
    }

    return result;
  } catch (err) {
    console.error('optionsMiddleware :: Caught an exception for action ', action, err);
  }
};
