import { EmptyBlockContent } from '@tradingblock/components';
import React from 'react';
import { useClearer } from '../../hooks/useClearer';
import { ClearerType } from '@tradingblock/types';

// export interface AccountTransferNotSupportedContentProps {

// }

export default function AccountTransferNotSupportedContent() {
  const clearer = useClearer();
  const isRQD = clearer === ClearerType.RQD;
  
  return (
    <EmptyBlockContent>
      <p>
        Coming soon! Online contributions and distributions (one-time and recurring) for IRAs. In the meantime, please
        download, complete, and submit the appropriate form (PDF) via fax to{' '}
        <a href="tel:+13125880226">1-312-588-0226</a>. If you have any questions, please call{' '}
        <a href="tel:+18004940451">1-800-494-0451</a> or email us at{' '}
        <a href="mailto:service@tradingblock.com">service@tradingblock.com</a>.
      </p>
      {isRQD ? (<AccountTransferNotSupportedRQDContent />) : (<AccountTransferNotSupportedAPEXContent />)}
    </EmptyBlockContent>
  );
}

export function AccountTransferNotSupportedRQDContent() {
  return (
    <p>
      <a target="_blank" href="https://legacy.tradingblock.com/Admin/Forms/RQD/Cashiering/RQD_ACAT.pdf" title="Account Transfer (ACAT) Form">Account Transfer (ACAT) Form</a>
      <br />
      <a target="_blank" href="https://legacy.tradingblock.com/Admin/Forms/RQD/Cashiering/RQD_IRA_Contribution.pdf" title="IRA Contribution Form">IRA Contribution Form</a>
      <br />
      <a target="_blank" href="https://legacy.tradingblock.com/Admin/Forms/RQD/Cashiering/RQD_IRA_Distribution.pdf" title="IRA Distribution Form">IRA Distribution Form</a>
      <br />
      <a target="_blank" href="https://legacy.tradingblock.com/Admin/Forms/RQD/Cashiering/RQD_Journal_Request.pdf" title="Journal Request Form">Journal Request Form</a>
      <br />
      <a target="_blank" href="https://legacy.tradingblock.com/Admin/Forms/RQD/Cashiering/RQD_Wire_Transfer.pdf" title="Wire Transfer Form">Wire Transfer Form</a>
      <br />
      <a target="_blank" href="https://legacy.tradingblock.com/Admin/Forms/RQD/Cashiering/RQD_Incoming_Wire_Instructions.pdf" title="Incoming Wire Instructions">Incoming Wire Instructions</a>
    </p>
  );
}

export function AccountTransferNotSupportedAPEXContent() {
  return (
    <p>
        <a
          target="_blank"
          href="https://legacy.tradingblock.com/Admin/Forms/Apex/Cashiering/IRA_Contribution_Form.pdf"
          title="IRA Contribution Form (all but Simple IRAs)"
        >
          IRA Contribution Form (all but Simple IRAs)
        </a>
        <br />
        <a
          target="_blank"
          href="https://legacy.tradingblock.com/Admin/Forms/Apex/Cashiering/SIMPLE_IRA_Deposit_Slip.pdf"
          title="Simple IRA Contribution Form"
        >
          Simple IRA Contribution Form
        </a>
        <br />
        <a
          target="_blank"
          href="https://legacy.tradingblock.com/Docs/Forms/Apex/Cashiering/IRA_Distribution_Form.pdf"
          title="IRA Distribution Form"
        >
          IRA Distribution Form
        </a>
        <br />
        <a
          target="_blank"
          href="https://legacy.tradingblock.com/Admin/Forms/Apex/Cashiering/IRA_Required_Minimum_Distribution.pdf"
          title="IRA Required Minimum Distribution (RMD) Form"
        >
          IRA Required Minimum Distribution (RMD) Form
        </a>
        <br />
        <a
          target="_blank"
          href="https://legacy.tradingblock.com/Admin/Forms/Apex/Cashiering/Roth_Distribution_Form.pdf"
          title="Roth IRA Distribution Form"
        >
          Roth IRA Distribution Form
        </a>
      </p>
  )
}