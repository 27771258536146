import { ActionType, createStandardAction } from 'typesafe-actions';
import { buildGenericRequestReceiveActions } from '../../actionUtilities';
import { GenericError, ApplicationsRequest, CurrentItemApplicationModel } from '@tradingblock/types';
import { AdminIdRequest } from '.';

const ADMIN_REQUEST_PENDING_APPLICATIONS = '@admin/applications/data/REQUEST';
const ADMIN_RECEIVE_PENDING_APPLICATIONS = '@admin/applications/data/RECEIVE';
const ADMIN_ERROR_PENDING_APPLICATIONS = '@admin/applications/data/ERROR';

const ADMIN_APPLICATIONS_SET_SEARCH = '@admin/applications/search/SET_SEARCH';
const ADMIN_APPLICATIONS_SET_SEARCH_STEP = '@admin/applications/search/SET_SEARCH_STEP';
const ADMIN_APPLICATIONS_SET_SORTBY = '@admin/applications/search/SET_SORTBY';
const ADMIN_APPLICATIONS_SET_PAGE = '@admin/applications/search/SET_PAGE';
const ADMIN_APPLICATIONS_SET_PAGE_SIZE = '@admin/applications/search/SET_PAGE_SIZE';
const ADMIN_APPLICATIONS_SET_CURRENT_ITEM = '@admin/applications/search/SET_CURRENT_ITEM';
const ADMIN_APPLICATIONS_SET_CLEAR_CURRENT_ITEM = '@admin/applications/search/CLEAR_CURRENT_ITEM';

const ADMIN_APPLICATIONS_REQUEST_INVESTIGATIONS = '@admin/applications/investigations/REQUEST';
const ADMIN_APPLICATIONS_RECEIVE_INVESTIGATIONS = '@admin/applications/investigations/RECEIVE';
const ADMIN_APPLICATIONS_ERROR_INVESTIGATIONS = '@admin/applications/investigations/ERROR';
// TODO: Update response types
const [requestApplications, receiveApplications, errorApplications] = buildGenericRequestReceiveActions({
  REQUEST: ADMIN_REQUEST_PENDING_APPLICATIONS,
  RECEIVE: ADMIN_RECEIVE_PENDING_APPLICATIONS,
  ERROR: ADMIN_ERROR_PENDING_APPLICATIONS,
})<ApplicationsRequest, { request: ApplicationsRequest; response: any }, GenericError<any, AdminIdRequest>>();

const [requestInvestigations, receiveInvestigations, errorInvestigations] = buildGenericRequestReceiveActions({
  REQUEST: ADMIN_APPLICATIONS_REQUEST_INVESTIGATIONS,
  RECEIVE: ADMIN_APPLICATIONS_RECEIVE_INVESTIGATIONS,
  ERROR: ADMIN_APPLICATIONS_ERROR_INVESTIGATIONS,
})<{ accountId: number }, { response: any }, GenericError<any, AdminIdRequest>>();

export const AdminPendingApplicationActions = {
  requestApplications,
  receiveApplications,
  errorApplications,
  requestInvestigations,
  receiveInvestigations,
  errorInvestigations,
  setAppSearch: createStandardAction(ADMIN_APPLICATIONS_SET_SEARCH)<Pick<ApplicationsRequest, 'search'>>(),
  setAppPage: createStandardAction(ADMIN_APPLICATIONS_SET_PAGE)<Pick<ApplicationsRequest, 'page'>>(),
  setAppPageSize: createStandardAction(ADMIN_APPLICATIONS_SET_PAGE_SIZE)<Pick<ApplicationsRequest, 'pageSize'>>(),
  setAppSortBy: createStandardAction(ADMIN_APPLICATIONS_SET_SORTBY)<
    Pick<ApplicationsRequest, 'sortBy' | 'sortByOrder'>
  >(),
  setAppSearchStep: createStandardAction(ADMIN_APPLICATIONS_SET_SEARCH_STEP)<Pick<ApplicationsRequest, 'steps'>>(),
  setAppCurrentItem: createStandardAction(ADMIN_APPLICATIONS_SET_CURRENT_ITEM)<CurrentItemApplicationModel>(),
  clearAppCurrentItem: createStandardAction(ADMIN_APPLICATIONS_SET_CLEAR_CURRENT_ITEM)<undefined>(),
  refreshApplications: createStandardAction('@admin/applications/search/ui/REFRESH')<ApplicationsRequest>(),
  setAppSearchType: createStandardAction('@admin/applications/search/ui/SET_TYPE')<
    Pick<ApplicationsRequest, 'types'>
  >(),
};

export const AdminApplicationActions = {
  ...AdminPendingApplicationActions,
};

export type AdminApplicationAction = ActionType<typeof AdminApplicationActions>;
