import React from 'react';
import { ApplicationsTable } from './components/ApplicationsTable';

export const AdminApplicationsIndex = () => {
  return (
    <div>
      <ApplicationsTable />
    </div>
  );
};
