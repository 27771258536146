import React from 'react';
import _ from 'lodash';

export interface ValueArrayProps {
  values?: (string | undefined)[];
  renderer: (value: string) => JSX.Element;
  delimiter: string | JSX.Element;
}

export const ValueArray: React.FunctionComponent<ValueArrayProps> = ({ values, renderer, delimiter }) => {
  const cleanValues = _.filter(values, v => !!v);
  return (
    <>
      {_.map(cleanValues, (v, i) => (
        <React.Fragment key={`value${i}`}>
          {renderer(v || '')}
          {i < cleanValues.length - 1 ? delimiter : ''}
        </React.Fragment>
      ))}
    </>
  );
};
