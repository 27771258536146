import { useMemo } from 'react';
import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { useWhyDidYouUpdate } from '@tradingblock/components';
import { useOrderData, useOrderDataSelector, OrderQuote, useOrderBlockData, OrderState } from '../state/OrderState';
import { LegSelector, OrderSymbolListValues } from '../state/OrderLegSelector';
import { OrderPriceData } from '../data/orderTypes';
import { getOrderLegPrices, getOrderQuotes } from '../data/orderSelectors';
import { useQuoteBidAskMetadata } from '../../../hooks/useFeedQuote';
import { areArraysEqual } from '../../../utilities/data';
import { useBlockId } from '../../../components/blocks/BlockState';
import { Strategies, getKnownStrategy } from '@tradingblock/api';
import { account } from '../../../data/global/selectors/accountSelectors';
import { useSelector } from 'react-redux';
import { AssetType, OptionType, OrderAction, OrderType } from '@tradingblock/types';
import { ClassificationOrderLeg, classifyStrategy } from '../../../data/global/utilities/strategyClassification';

const orderDataSelector = createCachedSelector(
  (s: OrderState, blockId: string) => s.strategy,
  (s: OrderState) => s.orderType,
  (s: OrderState) => s.action,
  (strategy, orderType, orderAction) => ({ strategy, orderType, orderAction })
)((s: OrderState, blockId: string) => `orderDateSelector-${blockId}`);

const useOrderPriceRawValues = (quotes: OrderQuote[], isBuy?: boolean): OrderPriceData => {
  const blockId = useBlockId();
  const { price } = useOrderDataSelector(s => ({
    price: s.price,
  }));
  const isLimitOrder = useOrderDataSelector(s => s.orderType === OrderType.Limit);
  const isIRA = useSelector(account.accountIsIRA);
  const legs = useOrderData(s => LegSelector.LegsByOCC(s, blockId));
  const { strategy, orderType, orderAction } = useOrderData(s => orderDataSelector(s, blockId));
  const match = useOrderData(s => s.validation && s.validation.match);

  // const classifyOrderLegs: ClassificationOrderLeg[] = Object.values(legs).reduce((acc, leg) => {
  //   if (leg.AssetType === AssetType.Equity) {
  //     acc.push({
  //       type: "Shares",
  //       action: leg.Action === OrderAction.Buy ? "BUY" : "SELL",
  //       strike: 0,
  //       expiration: new Date(),
  //       spreadRatio: leg.SpreadRatio ? leg.SpreadRatio : 0,
  //     });
  //     return acc;
  //   }
  //   if (leg.Strike === undefined || leg.Expiration === undefined || leg.SpreadRatio === undefined) {
  //     return acc;
  //   }

  //   acc.push({
  //     type: leg.OptionType === OptionType.Call ? "CALL" : "PUT",
  //     action: leg.Action === OrderAction.Buy ? "BUY" : "SELL",
  //     strike: leg.Strike,
  //     expiration: typeof leg.Expiration.date === "string" ? new Date(leg.Expiration.date) : leg.Expiration.date,
  //     spreadRatio: leg.SpreadRatio ? leg.SpreadRatio : 0,
  //   });
  //   return acc;
  // }, [] as ClassificationOrderLeg[]);

  // const classifiedStrategy = classifyStrategy(classifyOrderLegs.length === Object.values(legs).length ? classifyOrderLegs : []);
  // const match = Strategies.find(s => s.info.Name === classifiedStrategy.name);

  // if the strategy is custom but match is a call or put calendar, then we need to assign order action based on the action of the latest expiring option leg
  const isCustomStrategy = strategy === 'Custom';
  const isCallVerticalMatch = match && match.info.Name === 'Call Vertical';
  const isPutVerticalMatch = match && match.info.Name === 'Put Vertical';
  const isCoveredCallMatch = match && match.info.Name === 'Covered Call';
  const isCallButterflyMatch = match && match.info.Name === 'Call Butterfly';
  const isPutButterflyMatch = match && match.info.Name === 'Put Butterfly';
  const isIronButterflyMatch = match && match.info.Name === 'Iron Butterfly';
  const isIronCondorMatch = match && match.info.Name === 'Iron Condor';
  const isCallCondorMatch = match && match.info.Name === 'Call Condor';
  const isPutCondorMatch = match && match.info.Name === 'Put Condor';

  let orderActionValue = orderAction;

  if (isCustomStrategy && isCallVerticalMatch) {
    // for a call vertical spread the order is considered a buy if you are buying the lower strike and selling the higher strike

    // find the leg with the lower strike
    const lowerStrikeLeg = _.minBy(Object.values(legs), l => l.Strike);
    if (lowerStrikeLeg) {
      if (lowerStrikeLeg.Action === OrderAction.Buy) {
        orderActionValue = OrderAction.Buy;
      } else {
        orderActionValue = OrderAction.Sell;
      }
    }
  }

  if (isCustomStrategy && isPutVerticalMatch) {
    // for a put vertical spread the order is considered a buy if you are selling the lower strike and buying the higher strike

    // find the leg with the lower strike
    const lowerStrikeLeg = _.minBy(Object.values(legs), l => l.Strike);
    if (lowerStrikeLeg) {
      if (lowerStrikeLeg.Action === OrderAction.Buy) {
        orderActionValue = OrderAction.Sell;
      } else {
        orderActionValue = OrderAction.Buy;
      }
    }
  }

  if (isCustomStrategy && isCoveredCallMatch) {
    // covered call is considered a buy (bullish) if you are buying the stock and selling the call
    // covered call is considered a sell (bearish) if you are selling the stock and buying the call
    const shareLeg = Object.values(legs).find(l => l.AssetType === AssetType.Equity);
    if (shareLeg) {
      if (shareLeg.Action === OrderAction.Buy) {
        orderActionValue = OrderAction.Buy;
      } else {
        orderActionValue = OrderAction.Sell;
      }
    }
  }

  if (isCustomStrategy && isCallButterflyMatch) {
    // for a call butterfly the order is considered a buy if you are buying the lower strike and higher strike and selling the middle strike

    // find the leg with the lower strike
    const lowerStrikeLeg = _.minBy(Object.values(legs), l => l.Strike);
    if (lowerStrikeLeg) {
      if (lowerStrikeLeg.Action === OrderAction.Buy) {
        orderActionValue = OrderAction.Buy;
      } else {
        orderActionValue = OrderAction.Sell;
      }
    }
  }

  if (isCustomStrategy && isPutButterflyMatch) {
    // for a put butterfly the order is considered a buy if you are selling the lower strike and higher strike and buying the middle strike

    // find the leg with the lower strike
    const lowerStrikeLeg = _.minBy(Object.values(legs), l => l.Strike);
    if (lowerStrikeLeg) {
      if (lowerStrikeLeg.Action === OrderAction.Buy) {
        orderActionValue = OrderAction.Buy;
      } else {
        orderActionValue = OrderAction.Sell;
      }
    }
  }

  if (isCustomStrategy && isIronButterflyMatch) {
    // for an iron butterfly the order is considered a buy if you are buying the lower strike and higher strike and selling the middle strike

    // find the leg with the lower strike
    const lowerStrikeLeg = _.minBy(Object.values(legs), l => l.Strike);
    if (lowerStrikeLeg) {
      if (lowerStrikeLeg.Action === OrderAction.Buy) {
        orderActionValue = OrderAction.Sell;
      } else {
        orderActionValue = OrderAction.Buy;
      }
    }
  }

  if (isCustomStrategy && isIronCondorMatch) {
    // for an iron condor the order is considered a buy if you are selling the lower strike and higher strike and buying the middle strike
    // find the leg with the lower strike
    const lowerStrikeLeg = _.minBy(Object.values(legs), l => l.Strike);
    if (lowerStrikeLeg) {
      if (lowerStrikeLeg.Action === OrderAction.Buy) {
        orderActionValue = OrderAction.Sell;
      } else {
        orderActionValue = OrderAction.Buy;
      }
    }
  }

  if (isCustomStrategy && isCallCondorMatch) {
    // for a call condor the order is considered a buy if you are buying the lower strike and higher strike and selling the middle strike
    // find the leg with the lower strike
    const lowerStrikeLeg = _.minBy(Object.values(legs), l => l.Strike);
    if (lowerStrikeLeg) {
      if (lowerStrikeLeg.Action === OrderAction.Buy) {
        orderActionValue = OrderAction.Buy;
      } else {
        orderActionValue = OrderAction.Sell;
      }
    }
  }

  if (isCustomStrategy && isPutCondorMatch) {
    // for a put condor the order is considered a buy if you are selling the lower strike and higher strike and buying the middle strike
    // find the leg with the lower strike
    const lowerStrikeLeg = _.minBy(Object.values(legs), l => l.Strike);
    if (lowerStrikeLeg) {
      if (lowerStrikeLeg.Action === OrderAction.Buy) {
        orderActionValue = OrderAction.Buy;
      } else {
        orderActionValue = OrderAction.Sell;
      }
    }
  }

  const orderLegPrices = getOrderLegPrices({
    legs,
    quotes: quotes,
    orderType,
    orderAction: orderActionValue,
    strategy,
  });
  let marginCost = 0;

  if (strategy !== undefined && strategy !== 'Custom' && getKnownStrategy(strategy)) {
    const tradingStrategy = getKnownStrategy(strategy);
    if (tradingStrategy && tradingStrategy.profile.MarginRequirements) {
      //TODO: When upgrading typescript then can simplify type check into if statement
      const marginCostFormula = tradingStrategy.profile.MarginRequirements[orderAction];

      if (marginCostFormula && Object.values(legs).length > 0 && quotes.length > 0) {
        //TODO: Need to think if 0 is really correct here for price if pice is not set...
        marginCost = marginCostFormula(legs, quotes, isLimitOrder, price ? price : 0, isIRA);
      }
    }
  }

  if (strategy !== undefined && strategy === 'Custom' && match && match.profile && match.profile.MarginRequirements) {
    const actionValue = isBuy ? OrderAction.Buy : OrderAction.Sell;
    const tradingStrategy = getKnownStrategy(match.info.Name);
    if (tradingStrategy && tradingStrategy.profile.MarginRequirements) {
      const marginCostFormula = tradingStrategy.profile.MarginRequirements[actionValue];

      if (marginCostFormula && Object.values(legs).length > 0 && quotes.length > 0) {
        marginCost = marginCostFormula(legs, quotes, isLimitOrder, price ? price : 0, isIRA);
      }
    }
  }

  if (orderLegPrices === undefined) {
    return {
      bid: 0,
      ask: 0,
      mid: 0,
      totalMultiple: 0,
      gcd: 0,
      marginCost: 0,
    };
  }
  const { bid, ask, mid, ...marketPrices } = orderLegPrices;
  return {
    ...marketPrices,
    mid,
    ask,
    bid,
    marginCost,
  };
};

export const useOrderPrice = (isBuy?: boolean): OrderPriceData & { includesAllSymbols: boolean } => {
  const blockId = useBlockId();
  const orderSymbols = useOrderBlockData(OrderSymbolListValues);
  const quotes = useOrderData((s: OrderState) => getOrderQuotes(s, blockId));
  const uniqueQuoteSymbols = _.uniq(quotes.map(q => q.Symbol));
  const includesAllSymbols = areArraysEqual(uniqueQuoteSymbols, orderSymbols);
  const { bid, ask, mid, totalMultiple, gcd, marginCost } = useOrderPriceRawValues(quotes, isBuy);
  return { bid, ask, mid, totalMultiple, includesAllSymbols, gcd, marginCost };
};

export const useOrderPriceValue = (field: keyof ReturnType<typeof useOrderPriceRawValues>) => {
  const quotes = useQuoteBidAskMetadata();
  useWhyDidYouUpdate('useOrderPriceValue', { field, quotes });
  const value = useOrderPriceRawValues(quotes)[field];
  return useMemo(() => value, [value]);
};
// export const useSymbolBidAsk = (occSymbol: string) => {
//   const quote = useStateSelector(s => getQuoteMetadata(s, occSymbol || ''));
//   const symbolQuote = useMemo(() => (quote ? { bid: quote.BidPrice, ask: quote.AskPrice } : { bid: undefined, ask: undefined }), [quote]);
//   return symbolQuote;
// };
