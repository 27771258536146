import React, { useState, useCallback, useRef } from 'react';
import { Popover, Overlay } from 'react-bootstrap';
import _ from 'lodash';
import dayjs from 'dayjs';
// import useClickAway from 'react-use/lib/useClickAway';
import { Notification } from '@tradingblock/types';
import { useDispatcher } from '../../data/global/hooks';
import { NotificationWrapper } from './NotificationWrapper';

interface DashboardNotificationProps {
  title: string;
  notificationClass: string;
  notifications: Notification[];
  disabled?: boolean;
  onClick?: () => void;
}

export const DashboardNotification: React.FunctionComponent<DashboardNotificationProps> = ({ children, title, notificationClass, notifications, disabled, onClick }) => {
  const { dispatcher } = useDispatcher();
  const [, setClickedTarget] = useState<any>();
  const [show, setShow] = useState(false);
  const handleClickAway = useCallback(() => {
    setShow(false);
    setClickedTarget(undefined);
  }, []);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setShow(!show);
      dispatcher.notifications.read(notifications);
      setClickedTarget(event.target);
      if (onClick) {
        onClick();
      }
    },
    [dispatcher.notifications, notifications, onClick, show]
  );
  // const handleClick = useCallback(
  //   ({ target }) => {
  //     setState({ show: !show, clickedTarget: target });

  //     _.map(notifications, n => {
  //       dispatcher.notifications.read(n);
  //     });

  //     if (onClick) {
  //       onClick();
  //     }
  //   },
  //   [show, dispatcher, notifications, onClick]
  // );

  const filterReadNotifications = notifications.filter(n => n.read === false);
  const filterHiddenNotifications = notifications.filter(n => n.hide === false).length !== 0;
  const sortedNotifications = _.chain(notifications)
    .filter(n => n.hide === false)
    .sortBy(n => n.time)
    .reverse()
    .value();
  const containerRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLButtonElement>(null);
  return (
    <div ref={containerRef}>
      <button className="dashboard-nav-tool dashboard-nav-tool-btn" disabled={disabled} onClick={handleClick} type="button" ref={targetRef}>
        <span className="tb-icon tb-icon-2x tb-icon-stack tb-icon-clipboard tb-icon-counter" title={title}>
          {children}
          <span className={`tb-icon-counter-value ${notificationClass}`} hidden={filterReadNotifications.length === 0}>
            {filterReadNotifications.length}
          </span>
        </span>
      </button>
      <div>
        {targetRef.current && containerRef.current && (
          <Overlay container={containerRef.current} placement="bottom" target={targetRef.current} show={show && targetRef.current && filterHiddenNotifications}>
            <Popover id="trade-order-popover" className="notifications">
              <NotificationWrapper onClickAway={handleClickAway}>
                {sortedNotifications.map(n => {
                  return <TradeOrderPopover key={n.key} notification={n} />;
                })}
              </NotificationWrapper>
            </Popover>
          </Overlay>
        )}
      </div>
    </div>
  );
};

const TradeOrderPopover: React.FunctionComponent<{ notification: Notification }> = ({ notification }) => {
  const { dispatcher } = useDispatcher();
  const hideNotification = useCallback(() => {
    dispatcher.notifications.read(notification);
    dispatcher.notifications.hide(notification);
  }, [dispatcher, notification]);

  return (
    <div className="notify-item notify-item-clickable" hidden={notification.hide}>
      <div className="notify-primary">
        <p>{notification.status}</p>
        <p className="mute">{dayjs(notification.time).format(`h:mm A M/D/YYYY`)}</p>
      </div>
      <div className="notify-secondary">
        <p>
          <strong>{notification.title}</strong> {notification.message}
        </p>
      </div>
      <div className="notify-action">
        <button className="btn-link" type="button" title="Remove" onClick={hideNotification}>
          <i className="fas fa-2x fa-times-circle" />
        </button>
      </div>
    </div>
  );
};
