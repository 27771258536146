import {
  OrderStatusToString,
  isFilledOrderStatus,
  isPartialOrderStatus,
  getFilledQuantity,
  ActivityStatusMessage,
  isPartiallyCancelledOrderStatus,
} from '../../util';
import React, { FC } from 'react';
import { OrderActivity, OrderLeg, OrderStatus } from '@tradingblock/types';
import { ActivityOrderStatusWrapper } from '../../ActivityOrderStatusWrapper';
import { isErrorActivity } from '../../../../utilities/activity';
import _ from 'lodash';
import { orderstate } from '../../../Order/state/transforms/orderStateTransforms';

const getLegFilledTotal = (l: OrderLeg, orderQuantity: number, orderStatus: number | undefined) => {
  const filledStatus = isFilledOrderStatus(orderStatus);
  const { SpreadRatio } = l;
  if (filledStatus) {
    return orderQuantity * (SpreadRatio || 1);
  }
  return l.LegFillQuantity || 0;
};

const OrderLegsStatus: FC<{ legs: OrderLeg[]; orderQuantity: number; orderStatus: number | undefined }> = ({
  legs,
  orderQuantity,
  orderStatus,
}) => {
  const maxInd = _.size(legs) - 1;
  return (
    <>
      {_.map(legs, (l, ind) => {
        const filledQuantity = getLegFilledTotal(l, orderQuantity, orderStatus);
        const totalQuantity = (l.SpreadRatio || 1) * orderQuantity;
        return (
          <div key={ind}>
            {`${filledQuantity} / ${totalQuantity}`}
            {maxInd > ind && <br />}
          </div>
        );
      })}
    </>
  );
};

export const OrderActivityStatus: FC<{ activity: OrderActivity }> = ({ activity }) => {
  const { OrderStatus, Legs, Quantity } = activity;
  const status = OrderStatusToString(OrderStatus);
  const filledStatus = isFilledOrderStatus(OrderStatus);
  const partialStatus = isPartialOrderStatus(OrderStatus);
  const partiallyCancelledStatus = isPartiallyCancelledOrderStatus(OrderStatus);
  let statusClass = undefined;
  const statusMore = ActivityStatusMessage(activity);
  let statusValue = status;
  if (filledStatus) {
    statusClass = 'pos';
  } else if (partialStatus || partiallyCancelledStatus) {
    statusClass = 'pending';
  } else if (isErrorActivity(activity)) {
    statusValue = 'Error';
    statusClass = 'neg';
  }

  const showLegStatus = isErrorActivity(activity) === true ? false : true;
  const showLegMore = showLegStatus || statusMore;
  return (
    <ActivityOrderStatusWrapper status={OrderStatus}>
      <span className={statusClass}>{statusValue}</span>
      {showLegMore && (
        <>
          <br />
          {(filledStatus || partialStatus) && (
            <OrderLegsStatus orderStatus={OrderStatus} legs={Legs} orderQuantity={Quantity} />
          )}
          {!filledStatus && !partialStatus && statusMore && <>{statusMore}</>}
        </>
      )}

      {/* {statusMore && <>{statusMore}</>} */}
    </ActivityOrderStatusWrapper>
  );
};
