import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
export function gridDataActions(dispatch) {
  return {
    setLayout: function setLayout(layouts) {
      return dispatch({
        type: 'setLayout',
        payload: layouts
      });
    },
    addBlock: function addBlock(block) {
      return dispatch({
        type: 'addBlock',
        payload: block
      });
    },
    updateBlock: function updateBlock(blockId, blockData, meta) {
      return dispatch({
        type: 'updateBlock',
        payload: {
          id: blockId,
          block: blockData
        },
        meta: meta
      });
    },
    setBlocks: function setBlocks(blocks, meta) {
      return dispatch({
        type: 'setBlocks',
        payload: blocks,
        meta: meta
      });
    },
    setLocked: function setLocked(locked) {
      return dispatch({
        type: 'setLocked',
        payload: locked
      });
    },
    removeBlock: function removeBlock(key) {
      console.log('removeBlock', key);
      dispatch({
        type: 'removeBlock',
        payload: {
          key: key
        }
      });
    }
  };
}
export var newId = function newId() {
  return uuidv4();
};
export var SetBlockIds = function SetBlockIds(blocks, groupId) {
  var configs = _.isArray(blocks) ? blocks : [blocks];
  return configs.map(function (b) {
    return Object.assign({}, b, {
      blockId: b.blockId || newId(),
      groupId: groupId ? groupId : b.groupId || newId()
    });
  });
}; //export const DashboardContext = createContext<IDashboardContext>([initialState, () => {}]);