import React, { useMemo } from 'react';
import { useCallback } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { components } from 'react-select/lib/components';

function isOptionType(opts) {
  return opts && !_.isNil(_.get(opts, 'data')) && !_.isNil(_.get(opts, 'value'));
}

export var BasicAutocomplete = function BasicAutocomplete(_ref) {
  var dropdownIcon = _ref.dropdownIcon,
      placeholder = _ref.placeholder,
      onSelect = _ref.onSelect,
      initial = _ref.initial,
      minimumLength = _ref.minimumLength,
      isClearable = _ref.isClearable,
      options = _ref.options;
  var optionValues = useMemo(function () {
    return _.map(options, function (opts) {
      return isOptionType(opts) ? opts : {
        label: opts.toString(),
        value: opts.toString(),
        data: opts
      };
    });
  }, [options]);
  var getOptionValue = useCallback(function (option) {
    var optionVal = _.join([_.get(option, 'label'), _.get(option, 'searchValue'), _.get(option, 'value')], ' ');

    return optionVal;
  }, []);
  return React.createElement(Select, {
    placeholder: placeholder,
    defaultValue: initial,
    name: "symbol",
    isClearable: _.isBoolean(isClearable) ? isClearable : true,
    isSearchable: true,
    options: optionValues,
    className: "basic-multi-select",
    getOptionValue: getOptionValue,
    classNamePrefix: "select",
    styles: {
      menuPortal: function menuPortal(provided) {
        return Object.assign({}, provided, {
          width: 'auto'
        });
      }
    },
    menuPortalTarget: document.getElementById('portal-root'),
    components: {
      DropdownIndicator: SelectDropdownIndicator(dropdownIcon || 'fa-arrow-right'),
      Control: AutocompleteControlComponent
    }
  });
};
export var AutocompleteControlComponent = function AutocompleteControlComponent(props) {
  return React.createElement(components.Control, Object.assign({}, props));
};
export var SelectDropdownIndicator = function SelectDropdownIndicator(icon) {
  return function (props) {
    return React.createElement(components.DropdownIndicator, Object.assign({}, props), React.createElement("i", {
      className: "badge-icon fal ".concat(icon)
    }));
  };
};