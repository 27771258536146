/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState, useRef } from 'react';
import _ from 'lodash';
import { useStateSelector, blocksSelectors } from '../global/dataSelectors';
import { useLog } from '@tradingblock/components';
import { useDispatcher } from '../global/hooks';
import { RequestDebounceMS } from '../../constants';
import { QuoteDataActions } from '../global/actions/QuoteActions';

const log = useLog('QuoteRequestHandler');
export const QuoteRequestInitializer: React.FunctionComponent<{
  apiToken: string;
  accountId: number;
  authToken: string;
}> = ({ apiToken, accountId }) => {
  const { dispatch } = useDispatcher();

  const quotes = useStateSelector(blocksSelectors.allBlockQuotes);

  useEffect(() => {
    if (quotes && quotes.length > 0) {
      dispatch(QuoteDataActions.requestQuote({ symbol: [...new Set(quotes)] }));
    }
  }, [quotes, accountId, dispatch]);

  return <></>;
};
