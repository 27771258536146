import { useMemo, useState, useEffect } from 'react';
import constate from 'constate';
import { CashieringView, CashieringRoute, AlertType, CashieringManageAction } from '@tradingblock/types';
import { useAlerts } from '@tradingblock/components';
import { getNextTransferView } from '../../../../utilities/cashiering';
import { useStateSelector, cashieringSelectors } from '../../../../data/global/dataSelectors';
import { useHistory } from 'react-router-dom';

export const useCashieringDataContext = constate(() => {
  const [action, setAction] = useState<CashieringManageAction>();
  const [formStatus, setFormStatus] = useState<{
    view?: CashieringView;
    submitted?: boolean;
    alertType?: AlertType;
    message?: string;
  }>({});
  // used as tri-state:
  //  - false: screen is open (default)
  //  - true: screen should be closed
  //  - undefined: show modal to confirm/cancel closing screen
  const [closeScreen, setCloseScreen] = useState<boolean | undefined>(false);

  const setActionHandler = (action: CashieringManageAction | undefined) => {
    setFormStatus({ ...formStatus, submitted: false, alertType: undefined, message: undefined });
    setAction(action);
  };

  const gotoNextView = (nextView: CashieringView | undefined, history?: any) => {
    if (nextView) {
      if (window.location.pathname === '/accounts/oauth') {
        history.push('/accounts#manage');
      }
      window.location.hash = nextView;
      setFormStatus({ view: nextView });
    } else {
      setCloseScreen(true);
    }
  };

  return {
    action,
    setAction: setActionHandler,
    formStatus,
    setFormStatus,
    closeScreen,
    setCloseScreen,
    gotoNextView,
  };
});

export const useCashieringData = (route: CashieringRoute, view: CashieringView | undefined) => {
  const history = useHistory();
  const { action, setAction, setFormStatus, gotoNextView } = useCashieringDataContext();

  const notifications = useAlerts();

  const isSavingAccountForm = useStateSelector(s => cashieringSelectors.forms.dataIsSaving(s, 'account'));
  const isSavingTransferForm = useStateSelector(s => cashieringSelectors.forms.dataIsSaving(s, 'transfer'));
  const isSavingAchRelationship = useStateSelector(s => cashieringSelectors.dataIsSaving(s, 'achRelationships'));
  const isSavingWireInstructions = useStateSelector(s => cashieringSelectors.dataIsSaving(s, 'transferInstructions'));

  const isFetchingAchRelationships = useStateSelector(s => cashieringSelectors.dataIsFetching(s, 'achRelationships'));
  const isFetchingWireInstructions = useStateSelector(s =>
    cashieringSelectors.dataIsFetching(s, 'transferInstructions')
  );

  const statusAccountForm = useStateSelector(s => cashieringSelectors.forms.dataStatus(s, 'account'));
  const statusTransferForm = useStateSelector(s => cashieringSelectors.forms.dataStatus(s, 'transfer'));
  const statusAchRelationships = useStateSelector(s => cashieringSelectors.dataStatus(s, 'achRelationships'));
  const statusWireInstructions = useStateSelector(s => cashieringSelectors.dataStatus(s, 'transferInstructions'));

  const messageAccountForm = useStateSelector(s => cashieringSelectors.forms.dataMessage(s, 'account'));
  const messageTransferForm = useStateSelector(s => cashieringSelectors.forms.dataMessage(s, 'transfer'));
  const messageAchRelationships = useStateSelector(s => cashieringSelectors.dataMessage(s, 'achRelationships'));
  const messageWireInstructions = useStateSelector(s => cashieringSelectors.dataMessage(s, 'transferInstructions'));

  const isSaving = useMemo(
    () => isSavingTransferForm || isSavingAccountForm || isSavingAchRelationship || isSavingWireInstructions,
    [isSavingTransferForm, isSavingAccountForm, isSavingAchRelationship, isSavingWireInstructions]
  );
  const isFetching = useMemo(() => isFetchingAchRelationships || isFetchingWireInstructions, [
    isFetchingAchRelationships,
    isFetchingWireInstructions,
  ]);

  const status = useMemo(
    () => statusTransferForm || statusAccountForm || statusAchRelationships || statusWireInstructions,
    [statusTransferForm, statusAccountForm, statusAchRelationships, statusWireInstructions]
  );
  const message = useMemo(
    () => messageTransferForm || messageAccountForm || messageAchRelationships || messageWireInstructions,
    [messageTransferForm, messageAccountForm, messageAchRelationships, messageWireInstructions]
  );

  const [savingInProgress, setSavingInProgress] = useState(false);

  useEffect(() => {
    if (isSaving) {
      setSavingInProgress(true);
    }
  }, [isSaving]);

  useEffect(() => {
    // handle save results
    if (savingInProgress && !isFetching && !isSaving) {
      if (savingInProgress) {
        setSavingInProgress(false);
      }
      if (status && message) {
        // show success messages globally instead of inline
        if (status === AlertType.success) {
          notifications.success(message, { autoClose: 3500 });
        } else {
          setFormStatus({ view, alertType: status, message });
        }
      }
      // clear current action if it succeeded
      if (action && status === AlertType.success) {
        setAction(undefined);
      } else if (!status || status === AlertType.success) {
        const nextView = getNextTransferView(view, route);
        gotoNextView(nextView, history);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, route, savingInProgress, isFetching, isSaving, status, message, action, setAction, setFormStatus, history]);

  return {
    isFetching,
    isSaving,
  };
};
