import { createStandardAction } from 'typesafe-actions';
import { SymbolQuote, SymbolQuoteExtended, SymbolDividendData, ApiResponse, SymbolEarningData } from '@tradingblock/api';
import { DividendTimeframeValues, EarningsTimeframeValues } from '@tradingblock/types';
import { actionBuilder, buildGenericRequestReceiveActions } from '../actionUtilities';

export const REQUEST_QUOTE = '@data/REQUEST_QUOTE';
export const FETCHING_QUOTE = '@data/FETCHING_QUOTE';
export const RECEIVE_QUOTE = '@data/RECEIVE_QUOTE';
export const REQUEST_QUOTE_EXTENDED = '@data/REQUEST_QUOTE_EXTENDED';
export const RECEIVE_QUOTE_EXTENDED = '@data/RECEIVE_QUOTE_EXTENDED';
export const ERROR_QUOTE_EXTENDED = '@data/ERROR_QUOTE_EXTENDED';
export const REQUEST_QUOTE_DIVIDENDS = '@data/REQUEST/QUOTE_DIVIDENDS';
export const RECEIVE_QUOTE_DIVIDENDS = '@data/RECEIVE/QUOTE_DIVIDENDS';
export const ERROR_QUOTE_DIVIDENDS = '@data/ERROR/QUOTE_DIVIDENDS';

export const REQUEST_QUOTE_EARNINGS = '@data/REQUEST/QUOTE_EARNINGS';
export const RECEIVE_QUOTE_EARNINGS = '@data/RECEIVE/QUOTE_EARNINGS';
export const ERROR_QUOTE_EARNINGS = '@data/ERROR/QUOTE_EARNINGS';

const [requestDividends, receiveDividends, errorDividends] = buildGenericRequestReceiveActions({ REQUEST: REQUEST_QUOTE_DIVIDENDS, RECEIVE: RECEIVE_QUOTE_DIVIDENDS, ERROR: ERROR_QUOTE_DIVIDENDS })<{ symbol: string; range: DividendTimeframeValues }, { symbol: string; range: DividendTimeframeValues; dividends: SymbolDividendData[] }, { symbol: string; range: DividendTimeframeValues; error?: string }>();
const [requestEarnings, receiveEarnings, errorEarnings] = buildGenericRequestReceiveActions({ REQUEST: REQUEST_QUOTE_EARNINGS, RECEIVE: RECEIVE_QUOTE_EARNINGS, ERROR: ERROR_QUOTE_EARNINGS })<{ symbol: string; period: EarningsTimeframeValues }, { symbol: string; period: EarningsTimeframeValues; earnings: SymbolEarningData[] }, { symbol: string; error?: string; period: EarningsTimeframeValues }>();

export const QuoteDataActions = {
  fetchingQuotes: createStandardAction(FETCHING_QUOTE)<{ symbol: string | string[] }>(),
  requestQuote: createStandardAction(REQUEST_QUOTE)<{ symbol: string | string[] }>(),
  receiveQuote: createStandardAction(RECEIVE_QUOTE)<{ symbol: string; quote: SymbolQuote }[]>(),

  requestQuoteExtended: createStandardAction(REQUEST_QUOTE_EXTENDED)<{ symbol: string }>(),
  receiveQuoteExtended: createStandardAction(RECEIVE_QUOTE_EXTENDED)<{ symbol: string; quote: SymbolQuoteExtended }>(),
  errorQuoteExtended: createStandardAction(ERROR_QUOTE_EXTENDED)<{ symbol: string; }>(),
  requestDividends,
  receiveDividends,
  errorDividends,
  requestEarnings,
  receiveEarnings,
  errorEarnings,
};
