import { Middleware, Dispatch } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import _ from 'lodash';
import { DataState } from '../../../state';
import { UiApiClient } from '../../../../../context/Storage';
import { RootAction } from '../../../actions';
import { AdminApplicationActions } from '../../../actions/admin/AdminApplicationActions';
import { ApplicationsSearchRequestSelector } from '../../../selectors/admin/applicationsSelector';
import { ApplicationsRequest } from '@tradingblock/types';
import { ApiProvider } from '../../../../../context/Api';

const buildApplicationRequestURL = (request: ApplicationsRequest) => {
  const baseURL = 'admin/v1/db/applications';
  const { page, pageSize, sortBy, sortByOrder, repId, search, steps, types } = request;
  let params;
  if (repId) {
    params = {
      repId,
      page,
      pageSize,
      sortBy,
      sortByOrder,
      search,
      steps,
      types,
    };
  } else {
    params = {
      page,
      pageSize,
      sortBy,
      sortByOrder,
      search,
      steps,
      types,
    };
  }

  if (!search) {
    delete params.search;
  }

  if (!steps) {
    delete params.steps;
  }

  if (!types) {
    delete params.types;
  }

  return `${baseURL}?${_.map(params, (value, key) => `${key}=${value}`).join('&')}`;
};
export const getPendingApplications = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AdminApplicationActions.requestApplications>
) => {
  const provider = UiApiClient(state, dispatch);
  if (provider) {
    try {
      const requestURL = buildApplicationRequestURL(action.payload);
      const res = await provider.get<any>(requestURL);
      dispatch(AdminApplicationActions.receiveApplications({ request: action.payload, response: res }));
    } catch (err) {
      console.error('getPendingApplications Error', err);
      dispatch(AdminApplicationActions.errorApplications({ error: err }));
    }
  }
};

export const getApplicationInvestigations = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AdminApplicationActions.requestInvestigations>
) => {
  const api = ApiProvider(state, dispatch);
  if (api) {
    try {
      const response = await api.application.getApplicationStatus(action.payload.accountId);
      dispatch(AdminApplicationActions.receiveInvestigations({ response }));
    } catch (err) {
      console.error('getApplicationInvestigations Error', err);
      dispatch(AdminApplicationActions.errorInvestigations({ error: err }));
    }
  }
};

export const AdminApplicationsMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  try {
    const previousState = getState();
    const result = next(action);
    const nextState = getState();
    switch (action.type) {
      case getType(AdminApplicationActions.requestApplications): {
        getPendingApplications(nextState, dispatch, action);
        break;
      }
      case getType(AdminApplicationActions.requestInvestigations): {
        getApplicationInvestigations(nextState, dispatch, action);
        break;
      }
      case getType(AdminApplicationActions.setAppSortBy):
      case getType(AdminApplicationActions.setAppPage):
      case getType(AdminApplicationActions.refreshApplications):
      case getType(AdminApplicationActions.setAppPageSize):
      case getType(AdminApplicationActions.setAppSearch):
      case getType(AdminApplicationActions.setAppSearchStep):
      case getType(AdminApplicationActions.setAppSearchType): {
        const prevRequestKey = ApplicationsSearchRequestSelector(previousState);
        const { request, key } = ApplicationsSearchRequestSelector(nextState);
        if (prevRequestKey.key === key && getType(AdminApplicationActions.refreshApplications) !== action.type) {
          break;
        }
        dispatch(AdminApplicationActions.requestApplications(request));
        break;
      }
    }
    return result;
  } catch (err) {
    console.error('AdminApplicationsMiddleware error', err);
  }
};
