import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Loading } from '@tradingblock/components';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { useDispatcher } from '../../../data/global/hooks';
import { AdminAccountManagementActions } from '../../../data/global/actions/admin/AccountManagementActions';
import { AccountManagementTable } from './components/AccountManagementTable';
import { AccountUpdateDetails } from './components/AccountUpdateDetails';
import { AccountManagementUserDetails } from './components/AccountManagementUserDetails';
import { AccountManagementUpdateItemStatus } from './components/AccountManagementUpdateItemStatus';
import { AccountManagementUpdateRequest } from './components/AccountManagementUpdateRequest';
import { AccountUpdateEntityNotes } from './components/details/AccountUpdateEntityNotes';
import { AccountManagementFinancialDetails } from './components/AccountManagementFinancialDetails';
import { AccountManagementDocReview } from './components/AccountManagementDocReview';

export const AdminAccountManagementDetail: React.FunctionComponent<{}> = () => {
  const { dispatch } = useDispatcher();
  const currentItemRequest = useStateSelector(s => s.private.accountManagement.currentItem.request);
  let state = useStateSelector(s => s.private.accountManagement.currentItem.details.state);
  const isFetching = useStateSelector(s => s.private.accountManagement.currentItem.isFetching);
  const isAccountFetching = useStateSelector(s => s.private.accountManagement.currentItem.isAccountFetching);
  const updated = useStateSelector(s => s.private.accountManagement.currentItem.details.updated);
  let item = useStateSelector(s => s.private.accountManagement.currentItem.details.item);

  useEffect(() => {
    if (currentItemRequest) {
      const { accountId, id } = currentItemRequest;
      const request = {
        accountId,
        id,
      };
      dispatch(AdminAccountManagementActions.requestUserAccountUpdateDetails(request));
      dispatch(AdminAccountManagementActions.requestUserAccountDetails({ accountId }));
    }
  }, [currentItemRequest]);

  return (
    <>
      {isFetching && isAccountFetching && <Loading />}
      {!isFetching && !isAccountFetching && (
        <>
          <AccountManagementUserDetails />
          <AccountManagementFinancialDetails />
          <AccountUpdateDetails />
          <AccountManagementUpdateRequest item={item} />
          {updated && updated.uploadedDocuments && updated.uploadedDocuments.length > 0 && (
            <>
              <AccountManagementDocReview />
            </>
          )}
          <AccountManagementUpdateItemStatus state={state} item={item} />
          <AccountUpdateEntityNotes />
        </>
      )}
    </>
  );
};
