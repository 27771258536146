import { useContext, useCallback } from 'react';
import _ from 'lodash';
import { DataFeedSubscription } from '@tradingblock/types';
import { useLog } from '@tradingblock/components';
import { FeedSendContext } from './FeedState';
import { getQuoteSubscription, getOrderUpdateSubscription } from './DataFeed';

const getSymbols = (sub: DataFeedSubscription | DataFeedSubscription[]) => {
  const subscription = _.isArray(sub) ? sub : [sub];
  return _.flatMap(subscription, s => (s.kind === 'Quote' ? s.symbols : []));
};

export function useFeedActions(apiToken: string) {
  const log = useLog('DataFeed');
  const { send } = useContext(FeedSendContext);
  const trySend = useCallback(
    (cmd: string | undefined) => {
      try {
        if (cmd) {
          send(cmd);
          return true;
        }
      } catch (ex) {
        console.warn('useFeedActions :: Caught error trying to send feed cmd "', cmd, '": ', ex);
        // throw ex;
      }
      return false;
    },
    [send]
  );
  const subscribeQuote = useCallback(
    (accountId: string | number, sub: DataFeedSubscription | DataFeedSubscription[]) => {
      const subscription = _.isArray(sub) ? sub : [sub];
      const cmd = getQuoteSubscription(apiToken, accountId, subscription);
      log.extend('subscribe')(`%o`, { symbols: getSymbols(sub), message: cmd });
      return trySend(cmd);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trySend, apiToken]
  );
  const unsubscribeQuote = useCallback(
    (accountId: string | number, sub: DataFeedSubscription | DataFeedSubscription[]) => {
      const cmd = getQuoteSubscription(apiToken, accountId, _.isArray(sub) ? sub : [sub], true);
      log.extend('unsubscribe')(`%o`, { symbols: getSymbols(sub), message: cmd });
      return trySend(cmd);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trySend, apiToken]
  );
  const subscribeOrderUpdates = useCallback(
    (accountId: string | number) => {
      const cmd = getOrderUpdateSubscription(apiToken, accountId);
      return trySend(cmd);
    },
    [trySend, apiToken]
  );
  const unsubscribeOrderUpdates = useCallback(
    (accountId: string | number) => {
      const cmd = getOrderUpdateSubscription(apiToken, accountId, true);
      return trySend(cmd);
    },
    [trySend, apiToken]
  );
  return { subscribeQuote, unsubscribeQuote, subscribeOrderUpdates, unsubscribeOrderUpdates };
}
