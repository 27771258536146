import { Middleware, Dispatch } from 'redux';
import { getType, ActionType } from 'typesafe-actions';
import { DataState } from '../../../state';
import { RootAction } from '../../../actions';
import { ApiProvider } from '../../../../../context/Api';
import { AdminAccountManagementActions } from '../../../actions/admin/AccountManagementActions';
import { AccountManagementSearchRequestSelector } from '../../../selectors/admin/accountManagementSelector';

const getUserUpdates = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AdminAccountManagementActions.requestUserAccountUpdates>
) => {
  const api = ApiProvider(state, dispatch);

  if (api) {
    try {
      const response = await api.accounts.getUserUpdates(action.payload);
      dispatch(
        AdminAccountManagementActions.receiveUserAccountUpdates({ response: response, request: action.payload })
      );
    } catch (err) {
      dispatch(AdminAccountManagementActions.errorUserAccountUpdates({ error: err }));
      console.error('getUserAccountUpdates Error', err);
    }
  }
};

const getUpdateDetails = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AdminAccountManagementActions.requestUserAccountUpdateDetails>
) => {
  const api = ApiProvider(state, dispatch);

  if (api) {
    try {
      const response = await api.accounts.getUserUpdateDetails(action.payload);
      dispatch(AdminAccountManagementActions.receiveUserAccountUpdateDetails({ details: response.payload }));
    } catch (err) {
      dispatch(AdminAccountManagementActions.errorUserAccountUpdateDetails({ error: err }));
      console.error('getUserUpdateDetails Error', err);
    }
  }
};

const getAccountManagementDetails = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AdminAccountManagementActions.requestUserAccountDetails>
) => {
  const api = ApiProvider(state, dispatch);

  if (api) {
    try {
      const response = await api.accounts.getUserAccountDetails(action.payload);
      dispatch(AdminAccountManagementActions.receiveUserAccountDetails({ response: response.payload }));
    } catch (err) {
      dispatch(AdminAccountManagementActions.errorUserAccountDetails({ error: err }));
      console.error('getAccountManagementDetails Error', err);
    }
  }
};

const firmApproveUpdate = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AdminAccountManagementActions.requestFirmApproveUpdate>
) => {
  const api = ApiProvider(state, dispatch);

  if (api) {
    try {
      const response = await api.accounts.firmApproveUpdate(action.payload);
      dispatch(AdminAccountManagementActions.receiveFirmApproveUpdate({ response: response.payload }));
      return response;
    } catch (err) {
      console.error('firmApproveUpdate Error', err);
      dispatch(AdminAccountManagementActions.errorFirmApproveUpdate({ error: err }));
    }
  }
};

const firmRejectUpdate = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AdminAccountManagementActions.requestFirmRejectUpdate>
) => {
  const api = ApiProvider(state, dispatch);

  if (api) {
    try {
      const response = await api.accounts.firmRejectUpdate(action.payload);
      dispatch(AdminAccountManagementActions.receiveFirmRejectUpdate({ response: response.payload }));
      return response;
    } catch (err) {
      console.error('firmRejectUpdate Error', err);
      dispatch(AdminAccountManagementActions.errorFirmRejectUpdate({ error: err }));
    }
  }
};

const getMiscDocument = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AdminAccountManagementActions.requestMiscDoc>
) => {
  const api = ApiProvider(state, dispatch);

  if (api) {
    try {
      const { accountId, documentId } = action.payload;
      const response = await api.accountManagement.getMiscellaneousDocument(accountId, documentId);
      return response;
    } catch (err) {
      dispatch(AdminAccountManagementActions.errorMiscDoc({ error: err }));
    }
  }
};

const firmApproveDocument = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AdminAccountManagementActions.requestFirmApproveDoc>
) => {
  const api = ApiProvider(state, dispatch);

  if (api) {
    try {
      const { accountId, documentId, note, updateId } = action.payload;
      const response = await api.accountManagement.firmApproveDocument(accountId, documentId, note);
      dispatch(AdminAccountManagementActions.receiveFirmApproveDoc({ response }));
      if (response.responseCode === 0) {
        dispatch(AdminAccountManagementActions.requestUserAccountUpdateDetails({ accountId, id: updateId }));
      }
      return response;
    } catch (err) {
      dispatch(AdminAccountManagementActions.errorFirmApproveDoc({ error: err }));
    }
  }
};

const firmRejectDocument = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AdminAccountManagementActions.requestFirmRejectDoc>
) => {
  const api = ApiProvider(state, dispatch);

  if (api) {
    try {
      const { accountId, documentId, note, updateId } = action.payload;
      const response = await api.accountManagement.firmRejectDocument(accountId, documentId, note);
      dispatch(AdminAccountManagementActions.receiveFirmRejectDoc({ response }));
      if (response.responseCode === 0) {
        dispatch(AdminAccountManagementActions.requestUserAccountUpdateDetails({ accountId, id: updateId }));
      }
      return response;
    } catch (err) {
      dispatch(AdminAccountManagementActions.errorFirmRejectDoc({ error: err }));
    }
  }
};

const getMiscDocumentMetadata = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AdminAccountManagementActions.requestMiscDocMetadata>
) => {
  const api = ApiProvider(state, dispatch);

  if (api) {
    try {
      const { accountId, documentId } = action.payload;
      const response = await api.accountManagement.getMiscellaneousDocumentMetadata(accountId, documentId);
      return response;
    } catch (err) {
      dispatch(AdminAccountManagementActions.errorMiscDocMetadata({ error: err }));
    }
  }
};

export const AdminAccountManagementMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  try {
    const previousState = getState();
    const result = next(action);
    const nextState = getState();
    switch (action.type) {
      case getType(AdminAccountManagementActions.setPage):
      case getType(AdminAccountManagementActions.setPageSize):
      case getType(AdminAccountManagementActions.setType):
      case getType(AdminAccountManagementActions.setSortBy):
      case getType(AdminAccountManagementActions.setTimeframe):
      case getType(AdminAccountManagementActions.setStatus):
      case getType(AdminAccountManagementActions.refresh):
      case getType(AdminAccountManagementActions.setSearch): {
        const prevRequestKey = AccountManagementSearchRequestSelector(previousState);
        const { request, key } = AccountManagementSearchRequestSelector(nextState);
        if (prevRequestKey.key === key && getType(AdminAccountManagementActions.refresh) !== action.type) {
          break;
        }
        dispatch(AdminAccountManagementActions.requestUserAccountUpdates(request));
        break;
      }

      case getType(AdminAccountManagementActions.requestUserAccountUpdates): {
        getUserUpdates(nextState, dispatch, action);
        break;
      }
      case getType(AdminAccountManagementActions.requestUserAccountUpdateDetails): {
        getUpdateDetails(nextState, dispatch, action);
        break;
      }
      case getType(AdminAccountManagementActions.requestUserAccountDetails): {
        getAccountManagementDetails(nextState, dispatch, action);
        break;
      }
      case getType(AdminAccountManagementActions.requestFirmApproveUpdate): {
        firmApproveUpdate(nextState, dispatch, action);
        break;
      }
      case getType(AdminAccountManagementActions.requestFirmRejectUpdate): {
        firmRejectUpdate(nextState, dispatch, action);
        break;
      }
      case getType(AdminAccountManagementActions.requestMiscDoc): {
        getMiscDocument(nextState, dispatch, action);
        break;
      }
      case getType(AdminAccountManagementActions.requestMiscDocMetadata): {
        getMiscDocumentMetadata(nextState, dispatch, action);
        break;
      }
      case getType(AdminAccountManagementActions.requestFirmApproveDoc): {
        firmApproveDocument(nextState, dispatch, action);
        break;
      }
      case getType(AdminAccountManagementActions.requestFirmRejectDoc): {
        firmRejectDocument(nextState, dispatch, action);
        break;
      }
    }
    return result;
  } catch (err) {
    console.error('AdminAccountManagementMiddleware :: Caught an exception for action ', action, err);
  }
};
