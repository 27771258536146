/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useRef, useCallback } from 'react';
import {
  FeedReducer,
  FeedActionContext,
  FeedStateContext,
  FeedInitialState,
  FeedSendContext,
  FeedActions,
} from './FeedState';
import { FeedSync } from './FeedSync';
import { useWebsocket } from './WebsocketHandler';
import { useSubscribedSymbolsContext } from './context/DataFeedSymbolsContext';

const FeedSocketWrapper: React.FC<{ dispatch: React.Dispatch<FeedActions> }> = ({ dispatch, children }) => {
  const socketRef = useRef<WebSocket>();
  const [, setSubscribedQuotes] = useSubscribedSymbolsContext();
  const onClose = useCallback(() => {
    setSubscribedQuotes([]);
  }, [setSubscribedQuotes]);
  const [send] = useWebsocket({ websocket: socketRef, dispatch, onClose });
  return <FeedSendContext.Provider value={send}>{children}</FeedSendContext.Provider>;
};

export const Feed: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(FeedReducer, FeedInitialState);
  return (
    <FeedActionContext.Provider value={dispatch}>
      <useSubscribedSymbolsContext.Provider>
        <FeedStateContext.Provider value={state}>
          <FeedSocketWrapper dispatch={dispatch}>
            <>
              <FeedSync />
              {children}
            </>
          </FeedSocketWrapper>
        </FeedStateContext.Provider>
      </useSubscribedSymbolsContext.Provider>
    </FeedActionContext.Provider>
  );
};
