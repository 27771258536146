import React, { useMemo } from 'react';
import { CashieringAuditItem, CashieringEntityDetailTypes } from '@tradingblock/types';
import { formatDate } from '../../../../../utilities/date';
import { useStateSelector } from '../../../../../data/global/dataSelectors';
import {
  relationshipSelectors,
  transferSelectors,
  wireInstructionSelectors,
} from '../../../../../data/global/selectors/admin/cashieringSelectors';

export const CashieringEntityAuditItems: React.FC<{ id: number; type: CashieringEntityDetailTypes }> = ({
  id,
  type,
}) => {
  const transferDetails = useStateSelector(s => transferSelectors.details(s, id));
  const relationshipDetails = useStateSelector(s => relationshipSelectors.details(s, id));
  const transferInstructionDetails = useStateSelector(s => wireInstructionSelectors.details(s, id));

  const actions = useMemo(() => {
    if (type === 'relationships') {
      return relationshipDetails;
    }
    if (type === 'transfers') {
      return transferDetails;
    }
    if (type === 'wireInstructions') {
      if (!transferInstructionDetails) {
        return undefined;
      }
      return {
        ...transferInstructionDetails,
        created: {
          userId: transferInstructionDetails.accountId,
          date: formatDate(transferInstructionDetails.date),
          name: transferInstructionDetails.accountName,
        },
      };
    }
    return undefined;
  }, [type, transferDetails, relationshipDetails]);

  if (!actions) {
    return null;
  }
  return (
    <>
      <CashieringEntityAuditItem statusAction={actions.created} label="Created" />
      <CashieringEntityAuditItem statusAction={actions.lastModified} label="Last modified" />
      {type === 'transfers' && <CashieringEntityAuditItem statusAction={actions.repReviewed} label="Rep reviewed" />}
      <CashieringEntityAuditItem statusAction={actions.firmReviewed} label="Firm reviewed" />
      {type === 'transfers' && <CashieringEntityAuditItem statusAction={actions.rejected} label="Rejected" />}
      {type === 'transfers' && <CashieringEntityAuditItem statusAction={actions.returned} label="Returned" />}
      <CashieringEntityAuditItem statusAction={actions.deleted} label="Deleted" />
    </>
  );
};

const CashieringEntityAuditItem: React.FC<{ statusAction: CashieringAuditItem | undefined; label: string }> = ({
  statusAction,
  label,
}) => {
  if (!statusAction) {
    return null;
  }
  return (
    <p>
      {label}:
      <br />
      <span className="mute">
        {formatDate(statusAction.date)}
        {statusAction.name ? ` by ${statusAction.name}` : ''}
        {statusAction.reason && (
          <>
            <br />
            Reason: {statusAction.reason}
          </>
        )}
      </span>
    </p>
  );
};
