import React, { useMemo, FC, useContext, useEffect } from 'react';
import _ from 'lodash';
import { BasicCheckboxInput, BlockDragHandle, getBlockTypeName, Loading } from '@tradingblock/components';
import { AssetType, BlockType } from '@tradingblock/types';
import { dashboardIdSelector, useStateSelector } from '../../data/global/dataSelectors';
import { getAllPositionInfo } from '../../data/global/selectors/positionSelector';
import { sumNumbers } from '../../utilities/data';
import { PerformanceSelectors } from '../../data/global/selectors/performanceSelectors';
import { pnlSelectors } from '../../data/global/selectors/PnLSelectors';
import { DirectionalCurrency } from '../shared/DirectionalCurrency';
import { useBlockLog } from '../../components/blocks/hooks/useBlockLog';
import { BlockAction, BlockActionContext, useBlockMetadata } from '../../components/blocks/BlockState';
import { useDispatcher } from '../../data/global/hooks';
import { blockSettings } from '../../data/global/selectors/settingSelectors';

const NavPrice: FC<{ value: number | null | undefined; title: string; isLoaded: boolean }> = ({
  title,
  isLoaded,
  value,
}) => {
  const isValid = !_.isNil(value) && !_.isNaN(value) ? true : false;
  const log = useBlockLog('DailyPL');
  log('%o', { value });
  return (
    <>
      <div className="nav-item">
        <div className="mute txt-sm">{title}</div>
      </div>
      <div className="nav-item nav-price">
        {isValid && isLoaded && _.isNumber(value) ? (
          <DirectionalCurrency value={value} options={{ disableFlash: true }} />
        ) : (
          <span className="mute">
            <Loading size="small" />
          </span>
        )}
      </div>
    </>
  );
};

export const PositionHeader: FC<{
  totalGain?: number;
  dailyPnl?: number;
  expandRows?: boolean;
}> = ({ totalGain, dailyPnl, expandRows }) => {
  const { total } = useStateSelector(PerformanceSelectors.totals.all);
  const symbolAggregateList = useStateSelector(getAllPositionInfo);
  const totalGainValue = useMemo(
    () =>
      symbolAggregateList &&
      sumNumbers(
        _.map(symbolAggregateList, p => {
          if (p.symbol.assetType === AssetType.MoneyMarket) {
            return 0;
          }
          if (p.IsSettlementSet === true) {
            return p.overallPnl;
          }
          return p.commission ? p.totalGain + p.commission : p.totalGain;
        })
      ),
    [symbolAggregateList]
  );
  const subAccountFilterId = useStateSelector(s => s.ui.dashboard.subAccountFilterId);
  const selectedLinkedAccountId = useStateSelector(
    s => s.account.selectedLinkedAccount && s.account.selectedLinkedAccount.accountId
  );

  const loaded = useStateSelector(pnlSelectors.pnlDataLoaded);
  const isLoaded = loaded.isLoaded;
  const dispatch = useContext(BlockActionContext);
  const { dispatcher } = useDispatcher();

  const dashboardId = useStateSelector(dashboardIdSelector);
  const { blockId, type } = useBlockMetadata();
  const blockType = type;
  const settings = useStateSelector(s => blockSettings.position(s, blockId));

  const handleExpandRowsClick = () => {
    const settingsSaveData = {
      dashboardId: dashboardId || '',
      blockId,
      blockType,
      blockSettings: {
        ...settings,
        expandRows: !expandRows,
      },
    };
    dispatch(BlockAction.setSettings(settingsSaveData.blockSettings));
    dispatcher.settings.block.setBlockSetting(settingsSaveData);
  };

  // Reset expand
  useEffect(() => {
    const settingsSaveData = {
      dashboardId: dashboardId || '',
      blockId,
      blockType,
      blockSettings: {
        ...settings,
        expandRows: false,
      },
    };
    dispatch(BlockAction.setSettings(settingsSaveData.blockSettings));
    dispatcher.settings.block.setBlockSetting(settingsSaveData);
  }, [selectedLinkedAccountId, subAccountFilterId]);

  return (
    <div className="block-nav nav">
      <BlockDragHandle className="nav-item">{getBlockTypeName(BlockType.Positions)}</BlockDragHandle>
      <NavPrice
        value={dailyPnl === 0 ? 0 : dailyPnl && subAccountFilterId ? dailyPnl : total}
        isLoaded={isLoaded}
        title={'Daily P/L'}
      />
      <NavPrice
        value={totalGain === 0 ? 0 : totalGain && subAccountFilterId ? totalGain : totalGainValue}
        isLoaded={isLoaded}
        title={'Total gain'}
      />
      <BasicCheckboxInput
        className="nav-item txt-sm expand-rows-nav-item"
        checked={expandRows || false}
        onchange={e => {
          handleExpandRowsClick();
        }}
        type="checkbox"
      >
        Expand Positions
      </BasicCheckboxInput>
    </div>
  );
};
