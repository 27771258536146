import { ModalProps, ResponsiveTd, formatNumber } from '@tradingblock/components';
import React, { FC, useCallback, useMemo, useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { SubAccount } from '../../../../types/src/Account';
import { EditSubAccountModel } from './EditSubAccountModel';
import { DeleteSubAccountModel } from './DeleteSubAccountModel';
import JournalAccountModel from './JournalAccountModel';
import { useStateSelector } from '../../data/global/dataSelectors';
import { getCashAndCashAlts } from '../../data/global/utilities/balances';

export const SubAccountManagementSubRow: FC<any> = ({}) => {
  const accountId = useStateSelector(s => s.account.accountId);
  const [masterSubAccountBalance, setMasterSubAccountBalance] = useState<number>(0);
  const [distributionSum, setDistributionSum] = useState<number>();

  const [editModalData, setEditModalData] = useState<SubAccount & Pick<ModalProps, 'show'> & { Balance: number }>({
    show: false,
    IsMaster: false,
    AccountId: 0,
    Id: 0,
    Nickname: '',
    Description: '',
    Balance: 0,
    DistributionRatio: 0,
  });
  const [deleteModalData, setDeleteModalData] = useState<SubAccount & Pick<ModalProps, 'show'> & { Balance: number }>({
    show: false,
    IsMaster: false,
    AccountId: 0,
    Id: 0,
    Nickname: '',
    Description: '',
    Balance: 0,
    DistributionRatio: 0,
  });
  const [journalModalData, setJournalModalData] = useState<SubAccount & Pick<ModalProps, 'show'> & { Balance: number }>(
    {
      show: false,
      IsMaster: false,
      AccountId: 0,
      Id: 0,
      Nickname: '',
      Description: '',
      Balance: 0,
      DistributionRatio: 0,
    }
  );
  const setShowEditAccountModalCallback = useCallback(
    (show: boolean) => {
      setEditModalData({ ...editModalData, show });
    },
    [setEditModalData, editModalData]
  );
  const setShowDeleteAccountModalCallback = useCallback(
    (show: boolean) => {
      setDeleteModalData({ ...deleteModalData, show });
    },
    [setDeleteModalData, deleteModalData]
  );
  const setShowJournalAccountModalCallback = useCallback(
    (show: boolean) => {
      setJournalModalData({ ...journalModalData, show });
    },
    [setJournalModalData, journalModalData]
  );

  const subAccounts = useStateSelector(s => s.account.subaccounts);

  const subAccountBalances = useStateSelector(s => s.accountData.subAccountsBalances);

  useEffect(() => {
    if (subAccounts && subAccounts.subaccounts) {
      setDistributionSum(
        subAccounts.subaccounts.reduce((acc, sa) => acc + (sa.DistributionRatio ? sa.DistributionRatio : 0), 0)
      );
    }
  }, [subAccounts]);

  useEffect(() => {
    if (subAccountBalances && subAccounts && subAccounts.subaccounts) {
      let masterSubAccount = subAccounts.subaccounts.find(sa => sa.IsMaster);
      if (masterSubAccount) {
        const masterSubBalance = subAccountBalances[masterSubAccount.Id.toString()];
        if (masterSubBalance) setMasterSubAccountBalance(parseFloat(getCashAndCashAlts(masterSubBalance).toFixed(2)));
      }
    }
  }, [subAccountBalances]);

  const columns = useMemo(
    () => [
      <ResponsiveTd header="ID"></ResponsiveTd>,
      <ResponsiveTd header="Sub Id"></ResponsiveTd>,
      <ResponsiveTd header="Nickname"></ResponsiveTd>,
      <ResponsiveTd header="Distribution %"></ResponsiveTd>,
      <ResponsiveTd header="Description"></ResponsiveTd>,
      <ResponsiveTd header="Cash/Cash Alts Balance"></ResponsiveTd>,
      <ResponsiveTd header="Actions"></ResponsiveTd>,
    ],
    [subAccounts, subAccountBalances]
  );
  return (
    <>
      {subAccounts && subAccounts.subaccounts && subAccounts.subaccounts.length === 0 && (
        <tr>
          <td colSpan={columns.length}>No subaccounts found.</td>
        </tr>
      )}
      {subAccounts && subAccounts.subaccounts && subAccounts.subaccounts.length !== 0 && (
        <table key={`${accountId}-subaccounts`}>
          <thead
            className="row-expanded row-sub"
            style={{
              backgroundColor: '#0c1d3f',
              cursor: 'pointer',
            }}
          >
            <tr>
              {columns.map((column, mainIndex) => {
                return (
                  <th key={`${accountId}-header-${mainIndex}`} style={subRowHeaderStyle}>
                    {column.props.header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {subAccounts &&
              subAccounts.subaccounts &&
              subAccounts.subaccounts.map((val, subIndex) => {
                let balance = '0';
                let balanceNumber = 0;
                if (subAccountBalances) {
                  let obj = subAccountBalances[val.Id.toString()];
                  if (obj) {
                    balanceNumber = getCashAndCashAlts(obj);
                    balanceNumber = parseFloat(balanceNumber.toFixed(2));
                    balance = formatNumber(balanceNumber, { currency: true });
                  }
                }
                return (
                  <tr key={subIndex} style={subRowDataStyle}>
                    <td>{val && val.AccountId}</td>
                    <td>{val && val.Id}</td>
                    <td>{val && val.Nickname}</td>
                    <td>{val && (val.IsMaster && distributionSum === 0 ? '100' : val.DistributionRatio)}</td>
                    <td style={{ margin: '10px', padding: '20px', wordBreak: 'normal' }}>
                      {val && val.Description ? val.Description : 'No description.'}
                    </td>
                    <td>{balance}</td>
                    <td
                      style={{
                        ...subRowActionButtonStyle,
                        borderTop: 1,
                        borderTopColor: 'rgba(255, 255, 255, 0.2)',
                        borderStyle: 'solid',
                      }}
                    >
                      <Button
                        type="button"
                        style={val.IsMaster ? defaultButtonStyle : marginButtonStyle}
                        onClick={() => {
                          setJournalModalData({ ...val, Balance: 0, show: true });
                        }}
                      >
                        JOURNAL POSITIONS
                      </Button>
                      {!val.IsMaster && (
                        <Button
                          type="button"
                          style={marginButtonStyle}
                          onClick={() => {
                            setEditModalData({
                              ...val,
                              Balance: balanceNumber,
                              Number: masterSubAccountBalance.toString(),
                              show: true,
                            });
                          }}
                        >
                          EDIT
                        </Button>
                      )}
                      {!val.IsMaster && (
                        <Button
                          type="button"
                          style={deleteButtonStyle}
                          onClick={() => {
                            setDeleteModalData({ ...val, Balance: balanceNumber, Number: balance, show: true });
                          }}
                        >
                          DELETE
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
          {editModalData.show && (
            <EditSubAccountModel
              initialSubAccountState={editModalData}
              show={editModalData.show}
              setShow={setShowEditAccountModalCallback}
            />
          )}
          {journalModalData.show && (
            <JournalAccountModel
              initialSubAccountState={journalModalData}
              show={journalModalData.show}
              setShow={setShowJournalAccountModalCallback}
            />
          )}
          {deleteModalData.show && (
            <DeleteSubAccountModel
              initialSubAccountState={deleteModalData}
              show={deleteModalData.show}
              setShow={setShowDeleteAccountModalCallback}
            />
          )}
        </table>
      )}
    </>
  );
};

const subRowDataStyle = {
  backgroundColor: 'var(--blue-dark-lighter)',
} as object;

const subRowActionButtonStyle = {
  display: 'flex',
  flexFlow: 'row',
  paddingBottom: '10px',
  paddingTop: '30px',
  borderBottom: '0px',
} as object;

const subRowHeaderStyle = {
  borderTop: '2px solid rgba(255, 255, 255, 0.2)',
  borderBottom: '2px solid rgba(255, 255, 255, 0.2)',
  fontWeight: 'bold',
} as object;

const defaultButtonStyle = {
  borderRadius: '5px',
} as object;

const deleteButtonStyle = {
  borderRadius: '5px',
  background: 'var(--salmon)',
} as object;

const marginButtonStyle = {
  borderRadius: '5px',
  marginRight: 4,
} as object;
