import React, { useCallback } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Formik, Field } from 'formik';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { FormGroup, TextboxField, Modal, SelectDropdownField, StepperField } from '@tradingblock/components';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { AccountManagementDataActions } from '../../../data/global/actions/AccountManagementActions';
import { getRequiredError } from './Validation';
import { ServiceProfileValueProps, AllIssuerDirectCommunicationTypes } from '@tradingblock/types';

export const ServiceProfileUpdate: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
}> = ({ show, toggleModal }) => {
  const accountId = useStateSelector(s => s.accounts.account && s.accounts.account.AccountId);
  const dispatch = useDispatch();

  const initailServiceProfileValues: ServiceProfileValueProps = {
    issuerDirectCommunication: undefined,
  };

  const onValidate = (values: ServiceProfileValueProps) => {
    const { issuerDirectCommunication } = values;

    if (!issuerDirectCommunication) {
      return {
        [`issuerDirectCommunication`]: getRequiredError(issuerDirectCommunication),
      };
    }
  };

  const onSubmit = useCallback(
    (values, { resetForm }) => {
      const { issuerDirectCommunication } = values;

      const updateValues = {
        item: 'ServiceProfile',
        serviceProfile: {
          issuerDirectCommunication: issuerDirectCommunication,
        },
      };

      dispatch(
        AccountManagementDataActions.requestMakeAccountUpdate({
          accountId,
          request: updateValues,
        })
      );
      //TODO: Rework Redux logic surrounding how to display user updates that do not require admin review immdediately
      resetForm();
      toggleModal();
    },
    [toggleModal]
  );

  return (
    <Formik
      initialValues={initailServiceProfileValues}
      onSubmit={onSubmit}
      validateOnChange={true}
      validate={onValidate}
    >
      {({ handleSubmit, resetForm, values }) => (
        <Modal setShow={() => toggleModal()} show={show}>
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Service Profile Update</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            <form id="addressUpdate" onSubmit={handleSubmit}>
              <h2 className="fields-title">Issuer Direct Communication</h2>
              <p>
                Unless the applicant objects, under Rule 14b-1(c) of the Securities Exchange Act we are required to
                disclose the issuer of a given security in addition to the name, address and position of customers who
                are the beneficial owners of that issuer's securities. Please indicate below if you do or do not want to
                have your ownership disclosed.
              </p>
              <FormGroup>
                <Field
                  component={StepperField}
                  id="issuerDirectCommunication"
                  options={AllIssuerDirectCommunicationTypes}
                  defaultLabelId="issuerDirectCommunication"
                />
              </FormGroup>
            </form>
          </BootstrapModal.Body>
          <BootstrapModal.Footer className="modal-footer-justified">
            <Button
              variant="secondary"
              onClick={() => {
                resetForm();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={(values: any) => handleSubmit(values)}>
              Submit
            </Button>
          </BootstrapModal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
