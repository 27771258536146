import React from 'react';

interface YearPickerProps {
  value: number;
  onChange: (year: number) => void;
  isRQDTaxStatementView?: boolean;
}

/* 
  isRQDTaxStatementView is a boolean that is passed in from the AccountStatementsTaxDocuments.tsx file
  and is used to determine whether or not to subtract 1 from the year value
  this is due to differences in how RQD/Apex handle querying for tax documents 
*/

export default function YearPicker({ value, onChange, isRQDTaxStatementView }: YearPickerProps) {
  return (
    <div
      className="row m-2"
      style={{
        color: 'var(--blue)',
      }}
    >
      <button
        style={{
          cursor: 'pointer',
        }}
        onClick={() => {
          onChange(value - 1);
        }}
      >
        <i className="fal fa-lg fa-angle-left"></i>
      </button>
      <div className="mx-2">{isRQDTaxStatementView ? value - 1 : value}</div>
      <button
        style={{
          cursor: 'pointer',
        }}
        className="rounded-end"
        onClick={() => {
          onChange(value + 1);
        }}
      >
        <i className="fal fa-lg fa-angle-right"></i>
      </button>
    </div>
  );
}
