import React from 'react';
import _ from 'lodash';
import { Field } from 'formik';
import { CashieringRoute, TransferDeliveryMethod } from '@tradingblock/types';
import { FormGroup, CheckboxInput, SelectDropdownInput, TextboxField, SiteName } from '@tradingblock/components';
import { getDeliveryMethodLabel } from '../../../../utilities/cashiering';
import { TradingBlockLink } from '../../../../components/basic/TradingBlockLink';
import { AccountsListProps } from './AccountsList';
import Config from '../../../../config';
import { useStateSelector } from '../../../../data/global/dataSelectors';

export const SelectCheckTransfer: React.FunctionComponent<AccountsListProps> = ({ route, values, setValues }) => {
  const origin = useStateSelector(s => s.auth.origin);
  return (
    <>
      {route === CashieringRoute.Deposit && (
        <>
          <p>
            <span className="mute">
              Paper check deposits received before 1:00pm CT are processed same day. Please refer to the check funding
              instructions below to avoid having your check returned to you as unacceptable.
            </span>
          </p>
          <FormGroup>
            <p>
              <strong>Make check(s) payable to:</strong>
              <br />
              Apex Clearing FBO (last name, first name, full <SiteName siteGroup={Config.siteGroup} /> account number)
              <br />
            </p>
            <p>
              <strong>Mail checks to:</strong>
              <br />
              <SiteName siteGroup={Config.siteGroup} />
              <br />
              Attn. Cashiering
              <br />
              311 S Wacker Dr, Suite 1775
              <br />
              Chicago, IL 60606
              <br />
            </p>
            <p>
              <strong>Acceptable Check Deposits</strong>
            </p>
            <ul>
              <li>Checks must be payable in U.S. dollars and through a U.S. bank.</li>
              <li>
                Personal checks must be drawn from a bank account in an account owner's name and must include "Jr" or
                "Sr" if applicable.
              </li>
              <li>
                Checks from joint checking accounts may be deposited into either checking account owner's{' '}
                <SiteName siteGroup={Config.siteGroup} /> account.
              </li>
              <li>
                Checks from an individual checking account may be deposited into a{' '}
                <SiteName siteGroup={Config.siteGroup} /> joint account if that person is one of the account owners.
              </li>
              <li>
                Investment Club checks should be drawn from a checking account in the name of the Investment Club. If a
                member of the Investment Club remits a personal check, the check must be payable to: "Apex Clearing /
                FBO the Investment Club name." ("FBO" stands for "For the Benefit Of")
              </li>
            </ul>
            <p>
              <strong>Unacceptable Check Deposits</strong>
            </p>
            <ul>
              <li>Cashier's check</li>
              <li>Money Orders</li>
              <li>Foreign instruments</li>
              <li>Thrift withdrawal orders</li>
              <li>Domestic drafts</li>
              <li>Checks that have been double-endorsed (checks with more than one signature on the back)</li>
              <li>
                Third-party checks not properly made out and endorsed per the rules stated in the "Acceptable Deposits"
                section above
              </li>
              <li>Checks from minors</li>
              <li>Check dated over six months old</li>
              <li>Travelers checks</li>
              <li>Credit card checks</li>
              <li>Starter Checks</li>
            </ul>
          </FormGroup>
        </>
      )}

      {route === CashieringRoute.Withdraw && (
        <>
          <FormGroup>
            <p>
              <span className="mute">
                Paper check withdraws submitted before 9:00am CT are processed same day. Allow 1-10 business days for
                delivery (based on delivery method selected).{' '}
                <TradingBlockLink to={origin === 'mb' ? 'MBFeesUrl' : 'TBFeesUrl'}>Fees apply</TradingBlockLink>.
              </span>
            </p>

            <CheckboxInput label={' Deliver to account name and mailing address'} checked={true} />

            <p className="mute">
              Checks are payable and delivered to the name and mailing address associated with this account. If you
              require alternative arrangements, please contact{' '}
              <TradingBlockLink to="CustomerServiceUrl">Customer Service</TradingBlockLink>.
            </p>
          </FormGroup>

          <FormGroup title="Bank account">
            <Field component={TextboxField} id="transfer.nameOnAccount" label="Name as it appears on bank account" />
          </FormGroup>

          <FormGroup title="Delivery method">
            <Field
              component={SelectDropdownInput}
              name="transfer.deliveryMethod"
              options={_.map(['Overnight', 'Standard'], (m: TransferDeliveryMethod) => ({
                value: m,
                label: getDeliveryMethodLabel(m),
              }))}
              label="Check delivery method"
              value={values.transfer && values.transfer.deliveryMethod}
              onchange={(val: string, deliveryMethod: TransferDeliveryMethod) =>
                setValues({
                  ...values,
                  transfer: {
                    ...values.transfer,
                    deliveryMethod,
                  },
                })
              }
            />
            {values.transfer && values.transfer.deliveryMethod === 'Overnight' && (
              <p className="mute">
                Overnight express mail will be delivered to the physical street address on file for your account (not a
                P.O. Box).{' '}
                <TradingBlockLink to={origin === 'mb' ? 'MBFeesUrl' : 'TBFeesUrl'}>
                  Delivery fees may apply
                </TradingBlockLink>
                . Requests made after 3 PM Central (US) will be processed the next business day.
              </p>
            )}
          </FormGroup>

          <FormGroup title="Memo to print on the check (optional)">
            <Field component={TextboxField} id="transfer.memos[0]" label="Memo line 1 (optional)" />
            <Field component={TextboxField} id="transfer.memos[1]" label="Memo line 2 (optional)" />
            <Field component={TextboxField} id="transfer.memos[2]" label="Memo line 3 (optional)" />
          </FormGroup>
        </>
      )}
    </>
  );
};
