/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { Popover, Overlay, OverlayTrigger } from 'react-bootstrap';
import ReactDates, { DayPickerRangeController } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import dayjs from 'dayjs';
import moment from 'moment';
import { Button } from '@tradingblock/components';
import { TimeframeWrapper } from './TimeframeWrapper';
import { TimeframePresets } from './TimeframePresets';
import { daterangeToTimeframe } from '../../utilities/date';

type FocusedInputType = 'startDate' | 'endDate' | null;

interface TimeframeProps {
  initialStartDate?: Date;
  initialEndDate?: Date;
  btnClassName?: string;
  placeholderClassName?: string;
  caret?: JSX.Element;
  onSave: (start: Date | undefined, end: Date | undefined) => void;
  buttonId?: string;
}

export const Timeframe: React.FunctionComponent<TimeframeProps> = ({ initialStartDate, initialEndDate, btnClassName, placeholderClassName, caret, onSave, buttonId = '' }) => {
  const [focusedInput, setFocusedInput] = useState('startDate' as ReactDates.FocusedInputShape);

  const startDate = useMemo(() => (initialStartDate ? moment(initialStartDate) : null), [initialStartDate]);
  const endDate = useMemo(() => (initialEndDate ? moment(initialEndDate) : null), [initialEndDate]);

  const handleDatesChange = useCallback(({ startDate, endDate }: { startDate?: dayjs.Dayjs; endDate?: dayjs.Dayjs }) => {
    onSave(startDate ? startDate.toDate() : startDate, endDate ? endDate.toDate() : endDate);
  },
    [onSave]
  );

  const [presetDismiss, setPresetDismiss] = useState(false);
  useEffect(() => {
    return () => setPresetDismiss(false)
  }, [presetDismiss])

  const onPresetChange = useCallback(
    (preset: { startDate?: dayjs.Dayjs | undefined; endDate?: dayjs.Dayjs | undefined }) => {
      handleDatesChange(preset);
      setPresetDismiss(true);
    },
    [handleDatesChange, presetDismiss]
  );

  const onDatesChange = useCallback(
    (args: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => {
      const start = args.startDate;
      const end = args.endDate;
      const startDate = start ? dayjs(start.toDate()) : undefined;
      const endDate = end ? dayjs(end.toDate()) : undefined;
      handleDatesChange({ startDate, endDate });
    },
    [handleDatesChange]
  );

  const onFocusChange = useCallback((focusedInput: FocusedInputType) => {
    setFocusedInput(!focusedInput ? 'startDate' : focusedInput);
  }, []);

  const calendarInfo = useCallback(() => {
    return <TimeframePresets startDate={initialStartDate} endDate={initialEndDate} handleDatesChange={onPresetChange} />;
  }, [initialStartDate, initialEndDate, handleDatesChange]);

  return presetDismiss ? (
    <TimeframeButton
      startingDate={initialStartDate}
      endingDate={initialEndDate}
      btnClassName={btnClassName}
      placeholderClassName={placeholderClassName}
      caret={caret}
      onClick={() => setPresetDismiss(false)}
      id={buttonId}
    />) : (
    <OverlayTrigger
      placement='right'
      trigger={"click"}
      rootClose={true}
      popperConfig={{
        modifiers: {
          preventOverflow: {
            enabled: true,
            boundariesElement: 'window'
          },
        }
      }}
      overlay={
        <Popover style={{
          minWidth: 320, // These min values need to be set for the popper, as DayPicker is dynamically created and not static. So popper does not know the width and height to break onto a new line.
          minHeight: 340
        }} id={'date-popover'}>
          <DayPickerRangeController
            startDate={startDate}
            endDate={endDate}
            onDatesChange={onDatesChange}
            focusedInput={focusedInput}
            onFocusChange={onFocusChange}
            enableOutsideDays={false}
            numberOfMonths={1}
            renderCalendarInfo={calendarInfo}
            hideKeyboardShortcutsPanel
          />
        </Popover>
      }
    >
      <TimeframeButton
        startingDate={initialStartDate}
        endingDate={initialEndDate}
        btnClassName={btnClassName}
        placeholderClassName={placeholderClassName}
        caret={caret}
        onClick={null}
        id={buttonId}
      />
    </OverlayTrigger>
  );
};

interface TimeframeButtonProps extends Pick<TimeframeProps, 'btnClassName' | 'placeholderClassName' | 'caret'> {
  onClick: any;
  startingDate?: Date;
  endingDate?: Date;
  id?: string;
}

const TimeframeButton: React.FunctionComponent<TimeframeButtonProps> = ({ onClick, startingDate, endingDate, btnClassName, placeholderClassName, caret, id = '' }) => {
  const timeframe = useMemo(() => daterangeToTimeframe(startingDate && dayjs(startingDate), endingDate && dayjs(endingDate)), [startingDate, endingDate]);
  return (
    <Button onClick={onClick} className={btnClassName || 'btn-outline-light'} id={id}>
      <span className={placeholderClassName}>
        {timeframe} {caret}
      </span>
    </Button>
  );
};
