import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import _ from 'lodash';
import { DataState } from '../../state';
import { alertRequestKey } from '../../utilities/alertUtilities';

export const getRequestKey = createSelector(
  (state: DataState) => state.private.alerts.ui,
  uiState => alertRequestKey(uiState)
);

export const getAlertsSearchFilterData = createCachedSelector(
  (state: DataState) => state.private.alerts.ui,
  getRequestKey,
  (uiState, requestKey) => {
    return { ...uiState };
  }
)(getRequestKey);

export const getAlertsSearchTotal = createCachedSelector(
  (state: DataState) => state.private.alerts.alerts,
  (state: DataState) => state.private.alerts.byQuery,
  (state: DataState) => state.private.alerts.ui,
  getRequestKey,
  (alerts, queryState, uiState, requestKey) => {
    const total = queryState[requestKey] ? queryState[requestKey].total : 0;
    return total;
  }
)(getRequestKey);

export const AlertSearchRequestSelector = createCachedSelector(
  (state: DataState) => state.private.alerts.ui,
  (state: DataState) => alertRequestKey(state.private.alerts.ui),
  (request, key) => {
    return { request, key };
  }
)((state: DataState) => alertRequestKey(state.private.alerts.ui));

export const isFetchingAlerts = createCachedSelector(
  (state: DataState) => state.private.alerts.byQuery,
  getRequestKey,
  (queryState, requestKey) => {
    const qState = queryState[requestKey];
    const isFetching = qState ? qState.isFetching : false;
    const hasData = qState && qState.ids && qState.ids.length > 0;
    return [isFetching, hasData];
  }
)(getRequestKey);
