import {
  CheckListInput,
  GetLogoutUrl,
  GetTradingBlockDomain,
  GetTradingBlockFullUrl,
  Loading,
} from '@tradingblock/components';
import { DeliveryType, DuplicateStatements } from '@tradingblock/types';
import React, { useMemo, useState } from 'react';
import { useStateSelector } from '../../data/global/dataSelectors';
import { formatBooleanForDisplay, formatMarketDataEntitlement } from '../../utilities/accountManagement';
import { CommunicationPreferencesUpdate } from './modals/CommunicationPreferencesUpdate';
import { MarketDataProfileReview } from './modals/MarketDataProfileReview';

export const Preferences: React.FunctionComponent<{}> = ({}) => {
  const accountNumber = useStateSelector(s => s.account.accountNumber);
  const isLoaded = useStateSelector(s => s.accountManagement.accountDetails.isLoaded);
  const accountManagementDetails = useStateSelector(s => s.accountManagement.accountManagementDetails.details);
  const isFetching = useStateSelector(s => s.accountManagement.accountManagementDetails.isFetching);

  // Destructuring account management details & communication preferences
  const { communicationPreferences, agreements } = accountManagementDetails;
  const { apiAgreement } = agreements;
  const {
    deliveryType,
    duplicateStatements,
    duplicateStatementsAddress,
    duplicateStatementsEmail,
    preferredElectronicFormat,
    subscribeToPromotionalEmails,
  } = communicationPreferences;

  // Formatting data for display
  const receivePaperStatements = deliveryType === DeliveryType.Paper ? 'Yes' : 'No';
  const receiveDuplicateStatements = duplicateStatements !== DuplicateStatements.None ? 'Yes' : 'No';

  // TODO: Implement market data entitlement updates for account management once the API is ready
  // const getMarketDataEntitlement = () => {
  //   if (
  //     profileData &&
  //     profileData.current &&
  //     profileData.current.entitlement &&
  //     profileData.current.entitlement.marketDataEntitlementTypeId
  //   ) {
  //     return formatMarketDataEntitlement(profileData.current.entitlement.marketDataEntitlementTypeId);
  //   }
  //   return '';
  // };

  // const marketDataEntitlement = useMemo(() => getMarketDataEntitlement(), [profileData]);

  // State for toggling modals
  const [showMarketDataProfileReview, setShowMarketDataProfileReview] = useState(false);
  const [showCommunicationPreferencesUpdate, setShowCommunicationPreferencesUpdate] = useState(false);

  // Toggles for modals
  const toggleMarketDataProfileReview = () => {
    setShowMarketDataProfileReview(!showMarketDataProfileReview);
  };
  const toggleCommunicationPreferencesUpdate = () => {
    setShowCommunicationPreferencesUpdate(!showCommunicationPreferencesUpdate);
  };

  // Legacy domain for accessing the API agreement
  const tradingBlockDomain = GetTradingBlockDomain(accountNumber);
  const legacyLink = (
    <a
      href={GetTradingBlockFullUrl('LegacySiteLoginUrl', tradingBlockDomain)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {tradingBlockDomain}
    </a>
  );

  // Formatting data for display
  const formatPreferredElectronicFormat = (format: string) => {
    if (format === 'PlainText') {
      return 'Plain Text';
    }
    if (format === 'Html') {
      return 'HTML';
    }
    return 'None';
  };

  // Formatting data for display
  const formatApiAgreementResponse = (response: boolean) => {
    if (response) {
      return 'Accepted';
    }
    return 'Declined';
  };

  // If not loaded or is fetching, show loading component
  if (!isLoaded || isFetching) return <Loading />;

  return (
    <>
      <div className="screen-body-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <p className="fields-title">
                <i className="fal fa-paper-plane dashboard-icon" /> Communication Preferences
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              Would you like to receive paper confirmations and statements?
              <p className="mute">{receivePaperStatements}</p>
            </div>
            <div className="col">
              Would you like to receive duplicate confirmations and statements?
              <p className="mute">{receiveDuplicateStatements}</p>
              {receiveDuplicateStatements === 'Yes' && (
                <>
                  Duplicate Statements Type
                  <p className="mute">
                    {duplicateStatements === DuplicateStatements.PostalMail ? 'Postal Mail' : duplicateStatements}
                  </p>
                  {duplicateStatements === DuplicateStatements.PostalMail && duplicateStatementsAddress && (
                    <>
                      Duplicate Statements Address
                      <p className="mute mb-0">{duplicateStatementsAddress.address1}</p>
                      {duplicateStatementsAddress.address2 && (
                        <p className="mute">{duplicateStatementsAddress.address2}</p>
                      )}
                      <p className="mute">
                        {duplicateStatementsAddress.city}, {duplicateStatementsAddress.state}{' '}
                        {duplicateStatementsAddress.postalCode}
                      </p>
                    </>
                  )}
                  {duplicateStatements === DuplicateStatements.Email && duplicateStatementsEmail && (
                    <>
                      Duplicate Statements Email
                      <p className="mute">{duplicateStatementsEmail}</p>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              Preferred electronic communications format?
              <p className="mute">{formatPreferredElectronicFormat(preferredElectronicFormat)}</p>
            </div>
            <div className="col">
              Subscribed to promotional emails?
              <p className="mute">{formatBooleanForDisplay(subscribeToPromotionalEmails)}</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <button
                className="btn btn-primary"
                style={{ float: 'right' }}
                onClick={() => toggleCommunicationPreferencesUpdate()}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="screen-body-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <p className="fields-title">
                <i className="fal fa-handshake dashboard-icon" /> Trading Preferences
              </p>
            </div>
          </div>
          {/* TODO: Once backend supports API agreement through RestHub include ability to accept or remove the api agreement here on the dashboard */}
          <div className="row">
            {/* TODO: Implement market data entitlement updates for account management once the API is ready */}
            {/* <div className="col-6">
              <i className="fal fa-analytics fa-lg" style={{ color: 'teal' }} /> Quote data entitlement:{' '}
              <button onClick={() => toggleMarketDataProfileReview()}>{marketDataEntitlement}</button>
            </div> */}
            <div className="col-6">
              API Agreement: <button>{formatApiAgreementResponse(apiAgreement || false)}</button>
              {!apiAgreement && (
                <p className="txt-sm warn mt-3">
                  We need an additional agreement for your account to use the API and this platform. Please log in to
                  the classic {legacyLink}, visit your Profile, and complete the API agreement checkbox.
                </p>
              )}
            </div>
          </div>
          <div className="row"></div>
        </div>
      </div>
      <MarketDataProfileReview show={showMarketDataProfileReview} toggleModal={toggleMarketDataProfileReview} />
      <CommunicationPreferencesUpdate
        show={showCommunicationPreferencesUpdate}
        toggleModal={toggleCommunicationPreferencesUpdate}
      />
    </>
  );
};
