import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { account } from '../../data/global/selectors/accountSelectors';
import { getAdminLinks } from '../../components/admin/Links';

export const AdminRoot = () => {
  const userLevel = useSelector(account.userLevel);
  const userRoles = useSelector(account.userRoles);

  const adminLinks = useMemo(() => getAdminLinks(userLevel, userRoles), [userLevel, userRoles]);

  return (
    <>
      <div className="screen-body-section">
        <h1>Admin</h1>
        <nav className="nav flex-column">
          {adminLinks.map(al => (
            <Link to={al.url} key={al.text}>
              <i className={al.icon} /> {al.text}
            </Link>
          ))}
        </nav>
      </div>
    </>
  );
};
