import { useContext } from 'react';
import { createSelector } from 'reselect';
import { FeedStateContext, TradingblockWebsocketState } from './FeedState';

const getFeedStatus = createSelector(
  // inputSelectors
  (state: TradingblockWebsocketState) => state.status,
  status => status
);
export const useFeedStatus = () => {
  const state = useContext(FeedStateContext);
  return getFeedStatus(state);
};

const getFeedQuoteQueue = createSelector(
  // inputSelectors
  (state: TradingblockWebsocketState) => state.quoteQueue,
  quotes => quotes
);
export const useFeedQuoteQueue = () => {
  const state = useContext(FeedStateContext);
  return getFeedQuoteQueue(state);
};
