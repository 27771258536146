import React, { useState } from 'react';
import _ from 'lodash';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { Modal } from '@tradingblock/components';
import { useStateSelector } from '../../data/global/dataSelectors';
import { Redirect } from 'react-router';

export const AcceptModal: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
}> = ({ show, toggleModal }) => {
  const updateDetails = useStateSelector(s => s.accountManagement.update.data);

  const [redirect, setRedirect] = useState(false);

  if (redirect) {
    return <Redirect to="/" />;
  }

  return (
    <Modal setShow={() => toggleModal()} show={show} backdrop="static">
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>Email Update Confirmation</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <span>
          {updateDetails.accountName}, thank you for confirming your recent email update. The update is now pending
          admin review. You will be informed once your request has been processed.
        </span>
      </BootstrapModal.Body>
      <BootstrapModal.Footer className="modal-footer-justified">
        <Button variant="secondary" onClick={() => setRedirect(true)}>
          Exit
        </Button>
      </BootstrapModal.Footer>
    </Modal>
  );
};
