/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import _ from 'lodash';
import { IFeedSpread, DataFeedSubscription } from '@tradingblock/types';
import { useLog, useWhyDidYouUpdate } from '@tradingblock/components';
import { useStateSelector, blocksSelectors } from '../global/dataSelectors';
import { useFeedActions } from './useFeedActions';
import { useSubscribedSymbolsContext } from './context/DataFeedSymbolsContext';

export const getFeedSubscription = ({ quotes, spread }: { quotes?: string | string[]; spread?: IFeedSpread }) => {
  let feedValues: DataFeedSubscription[] = [];
  if (quotes) {
    const quoteValues = _.isArray(quotes) ? quotes : [quotes];
    feedValues = [...feedValues, { kind: 'Quote', symbols: quoteValues }];
  }
  if (spread) {
    feedValues = [...feedValues, { kind: 'Spread', spreadDefinition: [spread] }];
  }
  return feedValues;
};

const log = useLog('DataFeedInitializer');
export const DataFeedInitializer: React.FC<{ apiToken: string; accountId: number; authToken: string }> = ({
  apiToken,
  accountId,
}) => {
  // const [subscribedQuotes, setSubscribedQuotes] = useState<string[]>([]);
  const [subscribedQuotes, setSubscribedQuotes] = useSubscribedSymbolsContext();

  const { subscribeQuote, unsubscribeQuote, subscribeOrderUpdates, unsubscribeOrderUpdates } = useFeedActions(apiToken);
  const quotes = useStateSelector(blocksSelectors.allBlockQuotes);
  const feedProviderStatus = useStateSelector(s => s.feedProvider.status);
  const selectedAccountId = useStateSelector(
    s => s.account.selectedLinkedAccount && s.account.selectedLinkedAccount.accountId
  );

  useWhyDidYouUpdate('DataFeedInitializer', { log, subscribedQuotes, apiToken, accountId, quotes, feedProviderStatus });

  const quotesToAdd = useMemo(() => {
    log('quotes %o', { subscribedQuotes, incoming: quotes });
    return _.uniq(_.difference(quotes || [], subscribedQuotes || []) || []);
  }, [subscribedQuotes, quotes, log]);

  const quotesToRemove = useMemo(() => {
    return _.difference(subscribedQuotes || [], quotes || []) || [];
  }, [subscribedQuotes, quotes]);

  useEffect(() => {
    if (feedProviderStatus && feedProviderStatus === 'open') {
      subscribeOrderUpdates(accountId);
      if (subscribedQuotes && subscribedQuotes.length > 0) {
        subscribeQuote(accountId, getFeedSubscription({ quotes: subscribedQuotes || [] }));
      }
    }
  }, [feedProviderStatus]);

  useEffect(() => {
    if (feedProviderStatus && feedProviderStatus === 'open') {
      if (selectedAccountId) {
        console.log('selected linked account', selectedAccountId);
        subscribeOrderUpdates(selectedAccountId);
      }
    }
  }, [selectedAccountId]);

  useEffect(() => {
    const shouldAdd = quotesToAdd && quotesToAdd.length > 0;
    const shouldRemove = quotesToRemove && quotesToRemove.length > 0;
    let updatedSubscribedQuotes = subscribedQuotes;
    if (shouldAdd) {
      //const subscribeResult = true;
      //***UNCOMMENT THIS ONCE QUOTE SUBSCRIBE/UNSUBSCRIBE IS DEPLOYED****
      const subscribeResult = subscribeQuote(accountId, getFeedSubscription({ quotes: quotesToAdd || [] }));

      if (subscribeResult) {
        updatedSubscribedQuotes = _.uniq([...updatedSubscribedQuotes, ...quotesToAdd]);
        log('subscribed to quotes %o', quotesToAdd);
      } else {
        log('failed to subscribe to quotes %o', quotesToAdd);
      }
    }
    if (shouldRemove) {
      //const removeResult = true;
      const removeResult = unsubscribeQuote(accountId, getFeedSubscription({ quotes: quotesToRemove || [] }));
      if (removeResult) {
        updatedSubscribedQuotes = _.filter(updatedSubscribedQuotes, q => {
          return !quotesToRemove.includes(q);
        });

        log('unsubscribed from quotes %o', quotesToRemove);
      } else {
        log('failed to unsubscribe from quotes %o', quotesToRemove);
      }
    }
    if (shouldRemove || shouldAdd) {
      //***REMOVE THIS ONCE QUOTE SUBSCRIBE/UNSUBSCRIBE IS DEPLOYED****
      //const result = subscribe(accountId, getFeedSubscription({ quotes: updatedSubscribedQuotes || [] }));
      //log('subscribed to quotes %o', result);

      setSubscribedQuotes(updatedSubscribedQuotes);
    }
  }, [quotesToAdd, subscribedQuotes, subscribeQuote, accountId, quotesToRemove, log, unsubscribeQuote]);

  return <></>;
};
