import React, { useMemo, useRef, useEffect } from 'react';
import { Dispatch } from 'redux';
import constate from 'constate';
import _ from 'lodash';
import { ApiFactory, ITradingBlockApi, TradingBlockAccountApi, AccountApiFactory } from '@tradingblock/api';
import { APINotAuthorizedResponseCode, ExpiredTokenCode } from '@tradingblock/types';
import { LoadingImage } from '@tradingblock/components';
import { Config } from '../config';
import {
  useApiToken,
  useStateSelector,
  apiTokenSelector,
  accountIdSelector,
  subAccountIdSelector,
} from '../data/global/dataSelectors';
import { DataState } from '../data/global/state';
import { RootAction, SessionActions } from '../data/global/actions';

export type ApiType = ReturnType<typeof ApiFactory>;

export const buildApiClient = (token?: string, url?: string) => ApiFactory(url || Config.tradingApi, token || '');
export const ApiProvider = (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  errAction?: (error: any) => RootAction
) => {
  const token = apiTokenSelector(state);
  const apiUrl = Config.tradingApi;
  return ApiFactory(
    apiUrl,
    token || '',
    (resp: any) => {
      const responseCode = _.get(resp.data, 'ResponseCode', undefined);
      const configUrl = resp.config.url;
      if (responseCode && responseCode === APINotAuthorizedResponseCode) {
        if (resp.data.error) {
          try {
            let error = JSON.parse(resp.data.error);
            if (error.name === 'TokenExpiredError') {
              dispatch(SessionActions.expired({ code: ExpiredTokenCode.ApiTokenExpired }));
              return; //Do not dispatch other errors
            }
          } catch (e) {
            if (e instanceof Error && e.name === 'SyntaxError') {
              dispatch(
                SessionActions.error({
                  code: APINotAuthorizedResponseCode,
                  url: resp.config.url,
                  message: 'Request denied. You are not authorized to perform this action.',
                  reason: 'Unauthorized Request',
                })
              );
            }
          }
        }
        dispatch(
          SessionActions.error({
            code: APINotAuthorizedResponseCode,
            url: configUrl,
            message: 'Request denied. You are not authorized to perform this action.',
            reason: 'Unauthorized Request',
          })
        );
      }
      return resp;
    },
    (error: any) => {
      if (errAction) {
        console.log('API PROVIDER ERROR?', error);
        dispatch(errAction({ error }));
        return error;
      }
    }
  );
};
// export const ApiProvider = buildApiClient(apiTokenSelector(state));
export type ApiClientType = ReturnType<typeof ApiProvider>;

export const buildApiFromState = (state: DataState) => {
  const token = apiTokenSelector(state);
  if (token) {
    return buildApiClient(token);
  } else {
    return undefined;
  }
};

export const buildAccountApi = (token: string, accountId: number, subaccountId?: number) =>
  AccountApiFactory(Config.tradingApi, token, accountId, subaccountId);
export const accountApiFromState = (state: DataState) => {
  const token = apiTokenSelector(state);
  const accountId = accountIdSelector(state);
  const subaccountId = subAccountIdSelector(state);
  const selectedLinkedAccountId = state.account.selectedLinkedAccount && state.account.selectedLinkedAccount.accountId;
  if (token && accountId) {
    return buildAccountApi(token, selectedLinkedAccountId ? selectedLinkedAccountId : accountId, subaccountId);
  } else {
    return undefined;
  }
};

export const useApi = (): ITradingBlockApi => {
  const token = useApiToken();
  //useEffect(() => {
  //   const authenticateApi = async () => {
  //     await actions.authenticate();
  //   };
  //   if (!isAuthenticated) {
  //     authenticateApi();
  //   }
  // }, [actions, isAuthenticated]);
  const api = useMemo(() => buildApiClient(token), [token]);
  return api;
};

function useAccountApiContext({
  accountId,
  subaccountId,
  token,
}: {
  accountId: number;
  subaccountId?: number;
  token: string;
}): TradingBlockAccountApi {
  const apiRef = useRef<TradingBlockAccountApi>(buildAccountApi(token, accountId, subaccountId));

  return apiRef.current;
}
export const useAccountApi = constate(useAccountApiContext);
export const UseAccountApiProvider: React.FC<{}> = ({ children }) => {
  const token = useApiToken();
  const accountId = useStateSelector(accountIdSelector);
  const subaccountId = useStateSelector(subAccountIdSelector);
  useEffect(() => {
    const setup = async () => {};
    setup();
  }, []);
  return (
    <>
      {accountId && token ? (
        <useAccountApi.Provider accountId={accountId} subaccountId={subaccountId} token={token}>
          {children}
        </useAccountApi.Provider>
      ) : (
        <LoadingImage />
      )}
    </>
  );
};
