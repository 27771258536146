import { widget } from '@tradingblock/tradingview/dist/charting_library';
export var createTradingViewChart = function createTradingViewChart(props, onAutoSave) {
  var containerId = props.containerId,
      container = props.container,
      datafeed = props.datafeed,
      symbol = props.symbol,
      interval = props.interval,
      savedData = props.savedData,
      autoSaveDelay = props.autoSaveDelay,
      fullscreen = props.fullscreen,
      autosize = props.autosize,
      libraryPath = props.libraryPath,
      chartsStorageUrl = props.chartsStorageUrl,
      chartsStorageApiVersion = props.chartsStorageApiVersion,
      clientId = props.clientId,
      userId = props.userId,
      studiesOverrides = props.studiesOverrides;
  var widgetOptions = Object.assign({}, props, {
    symbol: symbol,
    // BEWARE: no trailing slash is expected in feed URL
    datafeed: datafeed,
    interval: interval,
    saved_data: savedData,
    auto_save_delay: autoSaveDelay,
    fullscreen: fullscreen,
    autosize: autosize,
    container: containerId,
    library_path: libraryPath,
    // locale: getLanguageFromURL() || 'en',
    locale: 'en',
    charts_storage_url: chartsStorageUrl,
    charts_storage_api_version: chartsStorageApiVersion,
    client_id: clientId,
    user_id: userId,
    studies_overrides: studiesOverrides
  });
  var tvWidget = new widget(widgetOptions);
  tvWidget.onChartReady(function () {
    tvWidget.subscribe('onAutoSaveNeeded', function () {
      tvWidget.save(function (data) {
        return onAutoSave('savedData', data);
      });
    });
    var activeChart = tvWidget.activeChart();

    if (activeChart) {
      activeChart.onIntervalChanged().subscribe(null, function (interval) {
        onAutoSave('interval', interval);
      });
      activeChart.onChartTypeChanged().subscribe(null, function (chartType) {
        onAutoSave('chartType', chartType);
      });
    } // _.each(tvWidget.activeChart().getPanes(), pane => {
    //   pane.getMainSourcePriceScale().setMode(PriceScaleMode.IndexedTo100);
    // });


    tvWidget.subscribe('toggle_sidebar', function (hidden) {
      onAutoSave('showLeftToolbar', !hidden);
    }); // onAutoSave chartType when chart is ready

    if (activeChart) {
      onAutoSave('chartType', activeChart.chartType());
      onAutoSave('interval', activeChart.resolution());
    }
  });
  return tvWidget;
};