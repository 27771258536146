/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo } from 'react';
import { useAlerts } from '@tradingblock/components';

import { OrderState, useOrderData } from './state/OrderState';
import { OrderSelectors } from './state/OrderSelector';
import { useOrderSymbolData } from './state/useOrderSymbols';
import { useOrderAssetSymbolOrUndefined, useOrderSymbolOrUndefined } from './state/useOrderState';
import { useExpirationLoader } from './data/useExpirationStrikeData';
import { useOrderPositionSync } from './hooks/useOrderPositionSync';
import { useBlockActions, useBlockMetadata } from '../../components/blocks/BlockState';
import { OrderStrategyPicker } from './components/OrderStrategyPicker';
import { AddOrderLeg } from './components/AddOrderLeg';
import { AddOrderLegContent } from './components/AddOrderLegContent';
import { ClearOrder } from './components/ClearOrder';
import { PriceAndQuoteSync } from './components/PriceAndQuoteSync';
import { OrderContentSettings } from './components/content/OrderContentSettings';
import { StrategyTemplateButtons } from './components/StrategyTemplateButtons';
import { OrderLegs } from './OrderLegs';
import _ from 'lodash';
import { AccountApiFactory, PositionDTO, isIndexSymbol } from '@tradingblock/api';
import { useSelector } from 'react-redux';
import { account, useStateSelector } from '../../data/global/dataSelectors';
import Config from '../../config';
import { BlockDataContext } from '../../components/blocks/BlockState';
import { useOrderActions } from './state/useOrderActions';
import { OrderType } from '@tradingblock/types';

export const OrderContent: React.FC<{ setNoExpirations?: (val: boolean) => any }> = ({ setNoExpirations }) => {
  //const data = useMemo(() => favorites || [], [favorites]);
  //const symbolQuote = useStateSelector(s => (symbol && symbol.symbol ? s.quotes[symbol.symbol] : undefined));
  const { blockId, data } = useBlockMetadata();
  const symbol = useOrderSymbolOrUndefined();
  const assetSymbol = useOrderAssetSymbolOrUndefined();
  const assetSymbolInfo = _.omit(assetSymbol, ['name', 'provider']);
  const isSymbolIndex = symbol ? isIndexSymbol(symbol) : false;
  const context = useContext(BlockDataContext);
  // const assetSymbol = useOrderState('symbol');
  const orderType = useOrderData(s => s.orderType);
  const legError = useOrderData(s => s.legError);
  const hidePrice = useMemo(() => orderType === OrderType.Market, [orderType]);
  const hasLegs = useOrderData(s => OrderSelectors.hasLegs(s, blockId));
  const isCustomStrategyOrUndefined = useOrderData(OrderSelectors.isCustomStrategyOrUndefined);
  useExpirationLoader(symbol || '');
  useOrderSymbolData();
  useOrderPositionSync();
  const notifications = useAlerts();
  const [positions, setPositions] = React.useState<PositionDTO[]>([]);
  const emptyCustomOrder = !hasLegs && symbol ? true : false;
  const isIndex = _.toLower(symbol).charAt(0) === '$' ? true : false;
  // variables for building api client
  const accountId = useSelector(account.accountId);
  const apiRootUrl = Config.tradingApi;
  const token = useStateSelector(state => state.auth.apiToken) || undefined;

  const accountApi = useMemo(() => {
    if (accountId && token) {
      return AccountApiFactory(apiRootUrl, token, accountId);
    }
    return undefined;
  }, [accountId, apiRootUrl, token]);

  useEffect(() => {
    // display any leg errors
    if (legError) {
      notifications.error(legError, { id: 'order-leg-error', autoClose: 7000 });
    }
  }, [legError]);

  useEffect(() => {
    // TODO: Really need to have positions in global redux state. Only issue is that sub accounts
    // will reload/rewrite positions in redux. But can't just filter on the redux state with subaccount id,
    // since PnL is tightly coupled with positions values.
    // this will need to be covered and refactored later...
    if (accountApi) {
      accountApi.positions().then(payload => {
        setPositions(payload.payload);
      });
    }
  }, []);

  // In order to alleviate the issue of a linked order block quotes/legs getting out of sync with symbol picker
  // we will force a symbol change on initial load if the context symbol is different than the header/group symbol
  // this will ensure that the block is in sync with the symbol picker.
  // TODO: Review how block data and settings are currently being stored/persisted. (Context vs. Mongo vs. Redux)
  const { changeSymbol } = useOrderActions();
  const { setField } = useBlockActions<OrderState>();
  useEffect(() => {
    if (symbol && data && data.symbol && symbol !== data.symbol.underlyingSymbol) {
      assetSymbol && changeSymbol(assetSymbolInfo, assetSymbol, { persist: true });
    }
  }, [symbol, data.symbol]);

  useEffect(() => {
    setField('orderType', OrderType.Limit, { persist: true });
  }, [symbol]);

  return (
    <>
      {symbol && (
        <div className="order-leg-set">
          <OrderStrategyPicker isSymbolIndex={isSymbolIndex} />
          {hasLegs && symbol && <OrderLegs positions={positions} symbol={symbol} setNoExpirations={setNoExpirations} />}
          {emptyCustomOrder && (
            <>
              <AddOrderLegContent isSymbolIndex={isSymbolIndex} />
              <p>Or use a strategy template</p>
              <StrategyTemplateButtons isIndex={isIndex} />
            </>
          )}
        </div>
      )}

      {symbol && (
        <>
          <PriceAndQuoteSync />
          {hasLegs && (
            <div className="order-leg-add">
              <div className="order-leg-plus">
                {isCustomStrategyOrUndefined && symbol && <AddOrderLeg isSymbolIndex={isSymbolIndex} maxLegs={4} />}
              </div>

              <ClearOrder />
            </div>
          )}
          {hasLegs && <OrderContentSettings />}
        </>
      )}
    </>
  );
};

export const MarkerLabel: React.FC<{
  title: string;
  value: string;
  styles?: React.CSSProperties;
  isReversed?: boolean;
}> = ({ title, value, styles, isReversed }) => {
  const style = isReversed
    ? {
        ...styles,
        transform: 'translateX(50%)',
        right: '50%',
        maxWidth: '75%',
      }
    : {
        ...styles,
        transform: 'translateX(-50%)',
        left: '50%',
        maxWidth: '75%',
      };

  return (
    <span className="slider-marker">
      <span style={style} className="slider-marker-top mute">
        {title}
      </span>
      <span className="slider-marker-bottom">{value}</span>
    </span>
  );
};
