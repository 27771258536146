import { createStandardAction } from 'typesafe-actions';
import {
  MarketNews,
  ReleaseNotesSearchRequest,
  ReleaseNotesSearchResponse,
  GenericError,
  ReleaseNotesUpdateValues,
  AlertMessageRequest,
  ReleaseNotesCreateValues,
} from '@tradingblock/types';
import { buildGenericRequestReceiveActions } from '../actionUtilities';
import { ApiResponse } from '@tradingblock/api';

const REQUEST_RELEASE_NOTES = '@data/REQUEST/RELEASE_NOTES';
const RECEIVE_RELEASE_NOTES = '@data/RECEIVE/RELEASE_NOTES';
const ERROR_RELEASE_NOTES = '@data/ERROR/RELEASE_NOTES';

const RELEASE_NOTES_SET_PAGE = '@data/RELEASE_NOTES/search/ui/SET_PAGE';
const RELEASE_NOTES_SET_PAGE_SIZE = '@data/RELEASE_NOTES/search/ui/SET_PAGE_SIZE';

const REQUEST_CREATE_RELEASE = '@data/REQUEST/CREATE_RELEASE';
const RECEIVE_CREATE_RELEASE = '@data/RECEIVE/CREATE_RELEASE';
const ERROR_CREATE_RELEASE = '@data/ERROR/CREATE_RELEASE';

const REQUEST_UPDATE_RELEASE = '@data/REQUEST/UPDATE_RELEASE';
const RECEIVE_UPDATE_RELEASE = '@data/RECEIVE/UPDATE_RELEASE';
const ERROR_UPDATE_RELEASE = '@data/ERROR/UPDATE_RELEASE';

const REQUEST_MARKET_NEWS = '@data/REQUEST/MARKET_NEWS';
const RECEIVE_MARKET_NEWS = '@data/RECEIVE/MARKET_NEWS';
const ERROR_MARKET_NEWS = '@data/ERROR/MARKET_NEWS';

const MARKET_NEWS_POLL_START = '@data/MARKET_NEWS/poll/START';
const MARKET_NEWS_POLL_STOP = '@data/MARKET_NEWS/poll/STOP';

const REQUEST_ALERT_MESSAGES = '@data/REQUEST/ALERT_MESSAGES';
const RECEIVE_ALERT_MESSAGES = '@data/RECEIVE/ALERT_MESSAGES';
const ERROR_ALERT_MESSAGES = '@data/ERROR/ALERT_MESSAGES';

const [requestAlertMessages, receiveAlertMessages, errorAlertMessages] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_ALERT_MESSAGES,
  RECEIVE: RECEIVE_ALERT_MESSAGES,
  ERROR: ERROR_ALERT_MESSAGES,
})<AlertMessageRequest, { response: any }, GenericError<any, any>>();

const [requestReleaseNotes, receiveReleaseNotes, errorReleaseNotes] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_RELEASE_NOTES,
  RECEIVE: RECEIVE_RELEASE_NOTES,
  ERROR: ERROR_RELEASE_NOTES,
})<
  ReleaseNotesSearchRequest,
  { response: ApiResponse<ReleaseNotesSearchResponse>; request: ReleaseNotesSearchRequest },
  GenericError<any, any>
>();

const [requestMarketNews, receiveMarketNews, errorMarketNews] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_MARKET_NEWS,
  RECEIVE: RECEIVE_MARKET_NEWS,
  ERROR: ERROR_MARKET_NEWS,
})<
  { symbols: any; updatedSince: any; channels: any; blockId: any },
  { symbols: any; marketNews: MarketNews[]; channels: any; blockId: any },
  { error: any; symbols: any; blockId: any }
>();

const [requestCreateRelease, receiveCreateRelease, errorCreateRelease] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_CREATE_RELEASE,
  RECEIVE: RECEIVE_CREATE_RELEASE,
  ERROR: ERROR_CREATE_RELEASE,
})<ReleaseNotesCreateValues, { response: any }, { error: any }>();

const [requestUpdateRelease, receiveUpdateRelease, errorUpdateRelease] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_UPDATE_RELEASE,
  RECEIVE: RECEIVE_UPDATE_RELEASE,
  ERROR: ERROR_UPDATE_RELEASE,
})<ReleaseNotesUpdateValues, { response: any }, { error: any }>();

export const InfoDataActions = {
  requestReleaseNotes,
  receiveReleaseNotes,
  errorReleaseNotes,
  setReleaseNotesPage: createStandardAction(RELEASE_NOTES_SET_PAGE)<Pick<ReleaseNotesSearchRequest, 'page'>>(),
  setReleaseNotesPageSize: createStandardAction(RELEASE_NOTES_SET_PAGE_SIZE)<
    Pick<ReleaseNotesSearchRequest, 'pageSize'>
  >(),
  requestCreateRelease,
  receiveCreateRelease,
  errorCreateRelease,
  requestMarketNews,
  receiveMarketNews,
  errorMarketNews,
  beginPollingForMarketNews: createStandardAction(MARKET_NEWS_POLL_START)<{
    symbols: any;
    updatedSince: any;
    channels: any;
    timeoutMS: number;
    blockId: any;
  }>(),
  stopPollingForMarketNews: createStandardAction(MARKET_NEWS_POLL_STOP)(),
  requestAlertMessages,
  receiveAlertMessages,
  errorAlertMessages,
  requestUpdateRelease,
  receiveUpdateRelease,
  errorUpdateRelease,
};
