import React, { Dispatch, FC, SetStateAction, useCallback, useEffect } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import _ from 'lodash';
import { CountdownTimer, Svg, ParsedMs } from '@tradingblock/components';
import {
  MarketCloseTimeSelector,
  MarketOpenTimeSelector,
  isMarketOpenSelector,
  useStateSelector,
} from '../../../data/global/dataSelectors';
import { DisplayTimeTilClose } from '../../../utilities/date';
import { useDispatch } from 'react-redux';
import { AccountActions } from '../../../data/global/actions/AccountActions';
import { MarketDataEntitlementTypeId } from '@tradingblock/types';
import { InfoDataActions } from '../../../data/global/actions/InfoActions';

dayjs.extend(relativeTime);

export const DashboardMarketClosedCountdown: FC<{}> = ({}) => {
  const dispatch = useDispatch();
  const latestReceivedDate = useStateSelector(s => s.quotes.quoteMetadata.latestReceivedTime);
  const entitlement = useStateSelector(s => s.account.dataEntitled);
  const isExpired = useStateSelector(s => s.auth.isExpired);

  const origin = useStateSelector(s => s.auth.origin);
  const siteConfigId = origin === 'mb' ? 4 : 2;
  const marketOpen = useStateSelector(s => isMarketOpenSelector(s));
  const marketOpenTime = useStateSelector(s => MarketOpenTimeSelector(s));
  const marketCloseTime = useStateSelector(s => MarketCloseTimeSelector(s));

  const countdownTitle = useCallback(
    (parsed: ParsedMs) => {
      const marketClosed =
        parsed.hours <= 0 && parsed.minutes <= 0
          ? 'NYSE Market closed'
          : `NYSE Market closes in ${DisplayTimeTilClose(parsed)}`;

      const marketOpens = `NYSE Market opens in ${DisplayTimeTilClose(parsed)}`;
      let quoteDataAsOf = 'Data refresh time not available';
      if (!_.isNil(latestReceivedDate)) {
        const now = dayjs().valueOf();
        if (now <= latestReceivedDate) {
          quoteDataAsOf = 'Data as of a few seconds ago';
        } else {
          quoteDataAsOf = `Data as of ${dayjs(latestReceivedDate).fromNow()}`;
        }
      }
      if (marketOpen) {
        return `${marketClosed}. ${quoteDataAsOf}.`;
      } else {
        return `${marketOpens}. ${quoteDataAsOf}.`;
      }
    },
    [latestReceivedDate, marketOpen]
  );

  useEffect(() => {
    dispatch(AccountActions.requestAccountEntitlement({}));
    dispatch(InfoDataActions.requestAlertMessages({ siteConfigId }));
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined = undefined;
    if (!isExpired) {
      intervalId = setInterval(function() {
        dispatch(InfoDataActions.requestAlertMessages({ siteConfigId }));
      }, 60 * 1000 * 5);
    }

    return () => {
      if (intervalId !== undefined) {
        clearInterval(intervalId);
      }
    };
  }, [isExpired, marketOpen]);

  return (
    <>
      {marketOpen && (
        <CountdownTimer
          title={countdownTitle}
          format={parsed =>
            parsed.hours > 0
              ? `${parsed.hours}:${parsed.minutes < 10 ? '0' : ''}${parsed.minutes} HRS`
              : parsed.minutes <= 0
              ? 'CLOSED'
              : `${parsed.minutes} MINS`
          }
          until={dayjs(marketCloseTime)
            .add(1, 'minutes')
            .toDate()}
          className={'dashboard-nav-tool dashboard-nav-time hide-mobile'}
        >
          {entitlement === MarketDataEntitlementTypeId.DelayedQuotesOnlyUser && (
            <span
              style={{
                marginRight: 8,
              }}
            >
              20 MIN DELAYED DATA: QUOTE, BID, ASK, OR LAST PRICE
            </span>
          )}
          <span className="tb-icon">
            <Svg path="icon-clock" />
          </span>
        </CountdownTimer>
      )}
      {!marketOpen && (
        <CountdownTimer
          title={countdownTitle}
          format={parsed => 'Closed'}
          until={dayjs(marketOpenTime)
            .add(1, 'minutes')
            .toDate()}
          className={'dashboard-nav-tool dashboard-nav-time hide-mobile'}
        >
          <span className="tb-icon">
            <Svg path="icon-clock" />
          </span>
        </CountdownTimer>
      )}
    </>
  );
};
