import { Middleware, Dispatch } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import _ from 'lodash';
import {
  PERSIST_BLOCKS_DEBOUNCE_MS,
  ActionMetaOrUndefined,
  DashboardInfo,
  DeleteDashboardPath,
  StorageDashboardPath,
  StorageDashboardsPath,
  BlockConfiguration,
  BlockGroupState,
  Layouts,
  StorageBlocksPath,
} from '@tradingblock/types';
import { DataState } from '../../state';
import { dashboardIdSelector, blocksSelectors, layoutSelectors } from '../../dataSelectors';
import {
  DashboardActions,
  RootAction,
  BlockActions,
  LayoutActions,
  BlockGroupActions,
  SharedBlockGroupActions,
} from '../../actions';
import { UiApiClient } from '../../../../context/Storage';
import * as Sentry from '@sentry/react';

export const handleDashboardDeletion = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  payload: {
    dashboardId: string;
    dashboardData: Partial<DashboardInfo>;
  }
) => {
  const provider = UiApiClient(state, dispatch);
  const { dashboardId } = payload;
  if (provider) {
    await provider.delete(DeleteDashboardPath(dashboardId));
  }
};

export const handleCreateDashboard = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof DashboardActions.addEmptyDashboard>
) => {
  const provider = UiApiClient(state, dispatch);
  const { dashboard, setAsActive } = action.payload;
  if (provider) {
    await provider.save(StorageDashboardPath, dashboard);
    if (setAsActive) {
      dispatch(DashboardActions.setDashboard({ dashboard }));
    }
  }
};

export const tryLoadDashboards = async (state: DataState, dispatch: Dispatch<RootAction>) => {
  const provider = UiApiClient(state, dispatch);
  if (provider) {
    const values = await provider.get<DashboardInfo[]>(StorageDashboardsPath);
    dispatch(DashboardActions.receiveDashboards(values || []));
  }
};

interface BlockData {
  blocks?: BlockConfiguration[];
  groups: BlockGroupState[];
  layouts?: Layouts;
}

const persistBlocks = async (state: DataState, dispatch: Dispatch<RootAction>, action: RootAction) => {
  // yield delay(500);
  // const toke = yield select(uiApiToken);
  // const dashboardId = yield select(dashboardIdSelector);
  // const blocks = yield select(blocksSelectors.blocks);
  // const groups = yield select(blocksSelectors.blockGroups);
  // const layouts = yield select(layoutSelectors.layouts);
  // const store = getHttpStore(Config.uiApi, toke);
  // yield call(trySaveBlocks, store, dashboardId, { blocks, groups, layouts });
  const dashboardId = dashboardIdSelector(state);
  const blocks = blocksSelectors.blocks(state);
  const groups = blocksSelectors.blockGroups(state);
  const layouts = layoutSelectors.layouts(state);
  const provider = UiApiClient(state, dispatch);
  if (provider) {
    try {
      if (dashboardId === undefined) {
        console.warn('trying to save blocks with an undefined dashboardId');
        return;
      }
      const blockData: BlockData = {
        groups,
      };

      if (action.type === getType(LayoutActions.setLayouts) && layouts !== undefined) {
        blockData.layouts = layouts;
      }

      if (action.type !== getType(LayoutActions.setLayouts)) {
        blockData.blocks = _.map(blocks, b => ({ ...b, subscriptions: undefined }));
      }
      if (blockData.blocks !== undefined && blockData.blocks.length === 0) {
        // rg4js('send', {
        //   error: new Error('trying to save blocks with an empty blocks array?'),
        //   customData: {
        //     action,
        //     state,
        //   },
        // });
        Sentry.captureException(new Error('trying to save blocks with an empty blocks array?'), {
          extra: {
            action,
            state,
          },
        });
      }
      const res = await provider
        .save<BlockData>(StorageBlocksPath(dashboardId), blockData)
        .then(res => (_.isArray(res) ? res : []));
    } catch (ex) {
      console.error('persistBlocks', ex);
      return Promise.resolve([]);
    }
  }
};

const tryPersistBlocks = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  actionMeta: ActionMetaOrUndefined,
  action: RootAction
) => {
  if (actionMeta && actionMeta.persist === false) {
    return;
  }
  persistBlocks(state, dispatch, action);
  return;
};

export const DashboardMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  try {
    // state BEFORE action is dispatched
    const result = next(action);
    // state AFTER action is dispatched
    const nextState = getState();
    switch (action.type) {
      case getType(BlockActions.addBlock):
      case getType(BlockActions.addOrUpdateBlock):
      case getType(BlockActions.removeBlock):
      case getType(BlockActions.updateBlock):
      case getType(BlockActions.linkBlock):
      case getType(BlockActions.unlinkBlock):
      case getType(LayoutActions.setLayouts):
      case getType(LayoutActions.setAllLayouts):
      case getType(BlockGroupActions.setGroupSymbol):
      case getType(SharedBlockGroupActions.SetSymbolAndReset): {
        tryPersistBlocks(nextState, dispatch, _.get(action, 'meta', undefined), action);
        break;
      }
      case getType(DashboardActions.updateDashboard): {
        if (action.payload.dashboardData.isDeleted) {
          handleDashboardDeletion(nextState, dispatch, action.payload);
        }
        break;
      }
      case getType(DashboardActions.addEmptyDashboard): {
        handleCreateDashboard(nextState, dispatch, action);
        break;
      }
      case getType(DashboardActions.requestDashboards): {
        tryLoadDashboards(nextState, dispatch);
        break;
      }
    }
    return result;
  } catch (err) {
    console.error('DashboardMiddleware :: Caught an exception for action ', action, err);
  }
};
