import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useStateSelector } from '../../data/global/dataSelectors';
import { useDispatch } from 'react-redux';
import { AccountManagementDataActions } from '../../data/global/actions/AccountManagementActions';
import {
  AddIcon,
  FormGroup,
  StepperField,
  LocalizeButton as Button,
  TextboxField,
  AddressField,
  Text,
  Loading,
  SelectDropdownField,
} from '@tradingblock/components';
import { FieldArray, Formik, Field } from 'formik';
import {
  AllAccountManagementBeneficiaryAccountTypes,
  AllAccountManagementBeneficiaryTypes,
  AllStates,
  BeneficiaryType,
  DateValueModel,
  getGroupedCountryOptions,
  BeneficiaryAccountType,
  AccountManagementBeneficiaryUpdateModel,
  Country,
  ErrorType,
  AddressModel,
  BeneficiariesRelationships,
} from '@tradingblock/types';
import _ from 'lodash';
import { DateField } from '../../components/form/DateField';
import { RemoveAllBeneficiaries } from './modals/RemoveAllBeneficiaries';
import { maskSSN } from '../admin/Applications/services/formatters';
import { DetailFields, DetailField } from '../../components/basic/DetailFields';
import {
  getInvalidError,
  getRequiredError,
  getAmountError,
  getContingentError,
  uspsVerification,
} from './modals/Validation';
import { cleanDateModel } from '../../utilities/date';

export const Beneficiaries: React.FunctionComponent<{}> = () => {
  const accountId = useStateSelector(s => s.account.accountId);
  const beneficiaries = useStateSelector(s => s.accountManagement.accountManagementDetails.details.beneficiaries);
  const beneArr = beneficiaries || [];
  const dispatch = useDispatch();
  const isUpdateLoading = useStateSelector(s => s.accountManagement.update.isLoading);
  const updateData = useStateSelector(s => s.accountManagement.update.data);
  const selectedLinkedAccountId = useStateSelector(
    s => s.account.selectedLinkedAccount && s.account.selectedLinkedAccount.accountId
  );
  const isLoaded = useStateSelector(s => s.accountManagement.accountDetails.isLoaded);

  const [addOrEditBeneficiary, setAddOrEditBeneficiary] = useState(false);
  const [showRemoveAll, setShowRemoveAll] = useState(false);
  const toggleShowRemoveAll = () => {
    showRemoveAll ? setShowRemoveAll(false) : setShowRemoveAll(true);
  };

  useEffect(() => {
    if (!isLoaded && accountId) {
      if (selectedLinkedAccountId) {
        dispatch(AccountManagementDataActions.requestAccountDetails({ accountId: selectedLinkedAccountId }));
        dispatch(AccountManagementDataActions.requestAccountManagementDetails({ accountId: selectedLinkedAccountId }));
      } else {
        dispatch(AccountManagementDataActions.requestAccountDetails({ accountId }));
        dispatch(AccountManagementDataActions.requestAccountManagementDetails({ accountId }));
      }
    }
  }, [accountId, dispatch, updateData, isLoaded, selectedLinkedAccountId]);

  const initialBeneficiariesValues: AccountManagementBeneficiaryUpdateModel = {
    beneficiaries: beneArr,
    max: 10,
  };

  const onSubmit = useCallback(
    (values, { resetForm }) => {
      const cleanDateBeneficiaries = values.beneficiaries.map((b: any) => {
        if (b.dateOfBirth && b.beneficiaryType === BeneficiaryType.Individual) {
          b.dateOfBirth = cleanDateModel(b.dateOfBirth);
        }
        if (b.dateOfBirth && b.beneficiaryType === BeneficiaryType.Trust) {
          b.dateOfBirth = null;
        }
        return b;
      });

      let updateValues = {
        item: 'Beneficiaries',
        beneficiaries: cleanDateBeneficiaries,
      };

      dispatch(AccountManagementDataActions.requestMakeAccountUpdate({ accountId, request: updateValues }));

      if (!isUpdateLoading) {
        resetForm();
        setAddOrEditBeneficiary(false);
      }
    },
    [accountId, dispatch]
  );

  // TODO: Review localStorage usage
  const onValidate = (values: AccountManagementBeneficiaryUpdateModel) => {
    let primaryBeneficiaries = values.beneficiaries
      ? values.beneficiaries.filter(b => b.beneficiaryAccountType === BeneficiaryAccountType.Primary)
      : [];
    let contingentBeneficiaries = values.beneficiaries
      ? values.beneficiaries.filter(b => b.beneficiaryAccountType === BeneficiaryAccountType.Contingent)
      : [];

    let totalPrimaryPercent = _.sumBy(primaryBeneficiaries, b => _.toNumber(b.percent));
    let totalContingentPercent = _.sumBy(contingentBeneficiaries, b => _.toNumber(b.percent));
    return values.beneficiaries.reduce(
      (acc, b, i) => {
        const { address } = b;
        let uspsResponse;
        let isValidAddress;

        const uspsResponseFromStorage = localStorage.getItem(`beneficiaryAddress-${i}`);

        if (uspsResponseFromStorage && uspsResponseFromStorage !== 'undefined') {
          uspsResponse = JSON.parse(uspsResponseFromStorage);
          const { city, state, zip5, error } = uspsResponse;

          const formattedCityCapitalized = _.capitalize(city);
          const startCaseCity = _.startCase(formattedCityCapitalized);
          const isCityEqual = _.toLower(startCaseCity) === _.toLower(b.address.city);
          const isZipEqual = zip5 === b.address.postalCode;
          const isStateEqual = state === b.address.state;
          const isAddressCorrect = !error && isCityEqual && isZipEqual && isStateEqual;

          isAddressCorrect ? (isValidAddress = true) : (isValidAddress = false);
        }

        if (
          b.address.address1 &&
          b.address.city &&
          b.address.state &&
          b.address.country === Country.UnitedStatesOfAmerica &&
          !isValidAddress
        ) {
          const getUSPS = async () => {
            await new Promise(resolve => setTimeout(resolve, 100));
            return await uspsVerification(address as AddressModel);
          };

          getUSPS().then(result => {
            localStorage.setItem(`beneficiaryAddress-${i}`, JSON.stringify(result));
          });
        }

        if (uspsResponse) {
          const { city, state, zip5, error } = uspsResponse;

          if (error && address.address1 && address.city && address.state && address.postalCode) {
            acc[
              `beneficiaries[${i}].address.postalCode`
            ] = `${error.description.trim()} Please review the entered address.`;
            acc[`beneficiaries[${i}].address.city`] = `${error.description.trim()} Please review the entered address.`;
            acc[`beneficiaries[${i}].address.state`] = `${error.description.trim()} Please review the entered address.`;
            acc[
              `beneficiaries[${i}].address.address1`
            ] = `${error.description.trim()} Please review the entered address.`;
          }

          if (!address.address1 || !address.city) {
            localStorage.setItem(`beneficiaryAddress-${i}`, 'undefined');
          }

          if (city && state && zip5) {
            const formattedCityCapitalized = _.capitalize(city);
            const startCaseCity = _.startCase(formattedCityCapitalized);
            const isCityEqual = _.toLower(startCaseCity) === _.toLower(address.city);
            const isZipEqual = zip5 === address.postalCode;
            const isStateEqual = state === address.state;

            !isCityEqual
              ? (acc[`beneficiaries[${i}].address.city`] = `Please verify city. USPS Provided City: ${startCaseCity}`)
              : (acc[`beneficiaries[${i}].address.city`] = undefined);

            !isZipEqual
              ? (acc[`beneficiaries[${i}].address.postalCode`] = `Please verify zip code. USPS Provided Zip: ${zip5}`)
              : (acc[`beneficiaries[${i}].address.postalCode`] = undefined);

            !isStateEqual
              ? (acc[`beneficiaries[${i}].address.state`] = `Please verify state. USPS Provided State: ${state}`)
              : (acc[`beneficiaries[${i}].address.state`] = undefined);
          }
        }

        if (!b.beneficiaryAccountType || !b.beneficiaryType) {
          acc[`beneficiaries[${i}].beneficiaryAccountType`] = getRequiredError(b.beneficiaryAccountType);
          acc[`beneficiaries[${i}].beneficiaryType`] = getRequiredError(b.beneficiaryType);
        }
        if (b.percent) {
          if (b.beneficiaryAccountType === BeneficiaryAccountType.Primary && totalPrimaryPercent !== 100) {
            acc[`beneficiaries[${i}].percent`] = getAmountError(false);
          } else if (b.beneficiaryAccountType === BeneficiaryAccountType.Contingent && totalContingentPercent !== 100) {
            acc[`beneficiaries[${i}].percent`] = getAmountError(false);
          } else if (totalPrimaryPercent !== 100 && totalContingentPercent > 0) {
            acc[`beneficiaries[${i}].percent`] = getContingentError(false);
          }
        }

        if (b.beneficiaryType === BeneficiaryType.Individual) {
          if (!b.firstName) {
            acc[`beneficiaries[${i}].firstName`] = getRequiredError(b.firstName);
          }
          if (b.middleInitial && b.middleInitial.length > 1) {
            acc[`beneficiaries[${i}].middleInitial`] = getInvalidError(b.middleInitial);
          }
          if (!b.lastName) {
            acc[`beneficiaries[${i}].lastName`] = getRequiredError(b.lastName);
          }
          // if suffix is present, it should be only characters with a possible "." or "-"
          if (b.suffix && !/^[a-zA-Z.-]+$/.test(b.suffix)) {
            acc[`beneficiaries[${i}].suffix`] = getInvalidError(b.suffix);
          }
          if (!b.ssnSecret) {
            acc[`beneficiaries[${i}].ssnSecret`] = getRequiredError(b.ssnSecret);
          }
          if (!b.relationship) {
            acc[`beneficiaries[${i}].relationship`] = getRequiredError(b.relationship);
          }
          if (!b.dateOfBirth.day) {
            acc[`beneficiaries[${i}].dateOfBirth`] = getRequiredError(b.dateOfBirth.day);
          }
          if (!b.dateOfBirth.month) {
            acc[`beneficiaries[${i}].dateOfBirth`] = getRequiredError(b.dateOfBirth.month);
          }
          if (!b.dateOfBirth.year) {
            acc[`beneficiaries[${i}].dateOfBirth`] = getRequiredError(b.dateOfBirth.year);
          }
        }

        if (b.beneficiaryType === BeneficiaryType.Trust) {
          if (!b.trustName) {
            acc[`beneficiaries[${i}].trustName`] = getRequiredError(b.trustName);
          }
          if (!b.einSecret) {
            acc[`beneficiaries[${i}].einSecret`] = getRequiredError(b.einSecret);
          }
        }

        if (!b.address.address1) {
          acc[`beneficiaries[${i}].address.address1`] = getRequiredError(b.address.address1);
        }
        if (!b.address.city) {
          acc[`beneficiaries[${i}].address.city`] = getRequiredError(b.address.city);
        }
        if (!b.address.country) {
          acc[`beneficiaries[${i}].address.country`] = getRequiredError(b.address.country);
        }
        if (b.address.country === Country.UnitedStatesOfAmerica && !b.address.state) {
          acc[`beneficiaries[${i}].address.state`] = getRequiredError(b.address.state);
        }
        if (b.address.country === Country.UnitedStatesOfAmerica && !b.address.postalCode) {
          acc[`beneficiaries[${i}].address.postalCode`] = getRequiredError(b.address.postalCode);
        }
        if (b.address.country !== Country.UnitedStatesOfAmerica && !b.address.state) {
          acc[`beneficiaries[${i}].address.state`] = getRequiredError(b.address.state);
        }
        if (!b.percent) {
          acc[`beneficiaries[${i}].percent`] = getRequiredError(b.percent);
        }
        if (b.percent && !_.isInteger(b.percent)) {
          acc[`beneficiaries[${i}].percent`] = getInvalidError(b.percent);
        }

        Object.keys(acc).forEach(key => (acc[key] === undefined ? delete acc[key] : {}));

        return acc;
      },
      {} as Record<string, ErrorType | string | undefined>
    );
  };

  const beneficiaryFields = useMemo(() => {
    let fieldList: DetailField[] = [];
    if (beneficiaries) {
      beneficiaries.map((b, i) => {
        fieldList = [
          ...fieldList,
          {
            Value: () => (
              <>
                {b.beneficiaryType === BeneficiaryType.Individual && (
                  <span className={`individualBeneficiary-${i}`}>
                    <span className="mute">
                      {b.firstName} {b.lastName} <span className="txt-sm">({b.beneficiaryAccountType})</span>
                    </span>
                    <br />
                    <span>
                      Relationship: <span className="mute">{b.relationship ? b.relationship : 'Not found'}</span>
                    </span>
                    <br />
                    <span>
                      Date of Birth:{' '}
                      <span className="mute">
                        {b.dateOfBirth && (
                          <>
                            {b.dateOfBirth.month}/{b.dateOfBirth.day}/{b.dateOfBirth.year}
                          </>
                        )}
                      </span>
                    </span>
                    <br />
                    <span>
                      SSN/ITIN: <span className="mute">{b.ssnSecret ? maskSSN(b.ssnSecret) : 'Not found'}</span>
                    </span>
                    <br />
                    <span>
                      Percent: <span className="mute">{b.percent ? b.percent : 'Not found'}</span>
                    </span>
                    <br />
                    <br />
                    <span>
                      Address
                      <br />
                      <span className="mute">
                        {b && b.address && b.address.address1 && (
                          <>
                            {b && b.address && b.address.address1}
                            <br />
                          </>
                        )}
                        {b && b.address && b.address.address2 && (
                          <>
                            {b.address.address2}
                            <br />
                          </>
                        )}
                        {b && b.address && b.address.city}
                        {b && b.address && b.address.state ? ', ' + b.address.state : ''}{' '}
                        {b && b.address && b.address.postalCode}
                        <br />
                        {b && b.address && b.address.country}
                      </span>
                    </span>
                  </span>
                )}
                {b.beneficiaryType === BeneficiaryType.Trust && (
                  <span className={`trustBeneficiary-${i}`}>
                    <span className="props-title">
                      {b.beneficiaryAccountType} - {b.beneficiaryType}
                    </span>
                    <br />
                    <br />
                    <span className="mute">
                      {b.trustName ? b.trustName : 'Not found'}
                      <br />
                      {b.einSecret ? b.einSecret : 'Not found'}
                    </span>
                    <br />
                    <span>
                      Percent: <span className="mute">{b.percent ? b.percent : 'Not found'}</span>
                    </span>
                    <br />
                    <br />
                    <span>
                      Address
                      <br />
                      <span className="mute">
                        {b && b.address && b.address.address1 && (
                          <>
                            {b && b.address && b.address.address1}
                            <br />
                          </>
                        )}
                        {b && b.address && b.address.address2 && (
                          <>
                            {b.address.address2}
                            <br />
                          </>
                        )}
                        {b && b.address && b.address.city}
                        {b && b.address && b.address.state ? ', ' + b.address.state : ''}{' '}
                        {b && b.address && b.address.postalCode}
                        <br />
                        {b && b.address && b.address.country}
                      </span>
                    </span>
                  </span>
                )}
              </>
            ),
          },
        ];
      });
    }
    return fieldList;
  }, [beneficiaries]);

  return (
    <>
      {isUpdateLoading && (
        <div className="screen-body-section">
          <div className="beneficiaries-list">
            <h2 className="fields-title">Beneficiaries</h2>
          </div>
          <Loading />
        </div>
      )}
      {!isUpdateLoading && (
        <div className="screen-body-section">
          {beneficiaries && beneficiaries.length > 0 && !addOrEditBeneficiary && (
            <div className="beneficiaries-list">
              <h2 className="fields-title">
                <i className="fal fa-users dashboard-icon" /> Beneficiaries
              </h2>
              <DetailFields fieldList={beneficiaryFields} />

              <div className="block-footer-actions">
                <button
                  className="btn btn-danger mr-2"
                  onClick={e => {
                    e.preventDefault();
                    setShowRemoveAll(true);
                  }}
                >
                  Remove All Beneficiaries
                </button>
                <button
                  className="btn btn-primary block-action-button"
                  onClick={e => {
                    e.preventDefault();
                    setAddOrEditBeneficiary(true);
                  }}
                >
                  Edit/Add Beneficiaries
                </button>
              </div>
            </div>
          )}
          {(beneficiaries.length === 0 || addOrEditBeneficiary) && (
            <>
              {beneficiaries.length > 0 && (
                <p>
                  <button
                    className="btn btn-blend"
                    onClick={e => {
                      e.preventDefault();
                      setAddOrEditBeneficiary(false);
                    }}
                  >
                    <i className="fal fa-arrow-left" />
                    Go back
                  </button>
                </p>
              )}
              <p>
                <strong>Changes must be saved in order to take effect.</strong>
              </p>

              <Formik initialValues={initialBeneficiariesValues} onSubmit={onSubmit} validate={onValidate}>
                {({ values, setValues, errors, handleSubmit }) => {
                  const total = _.size(values.beneficiaries);
                  const totalPercentageError = errors.beneficiaries;
                  const max = values.max;

                  return (
                    <div className="container">
                      <FieldArray
                        name="beneficiaries"
                        render={arrayHelpers => (
                          <>
                            {values.beneficiaries.map((beneficiary, index) => (
                              <div
                                key={`beneficiary${index}`}
                                className="form-group-removable from-group-removable-titled"
                              >
                                <div className="row">
                                  <div className="col">
                                    <h2 className="fields-title">Retirement Account Beneficiary #{index + 1}</h2>
                                  </div>
                                  <div className="col-auto">
                                    <div className="remove">
                                      <Button
                                        type="remove"
                                        onClick={() => {
                                          if (values.beneficiaries.length === 1 && beneficiaries.length === 0) {
                                            setAddOrEditBeneficiary(false);
                                          }
                                          arrayHelpers.remove(index);
                                        }}
                                        tabIndex={-1}
                                      >
                                        <i className="fal fa-lg fa-times-circle" />
                                      </Button>
                                    </div>
                                  </div>
                                </div>

                                <FormGroup>
                                  <Field
                                    component={StepperField}
                                    id={`beneficiaries[${index}].beneficiaryAccountType`}
                                    options={AllAccountManagementBeneficiaryAccountTypes}
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Field
                                    component={StepperField}
                                    id={`beneficiaries[${index}].beneficiaryType`}
                                    options={AllAccountManagementBeneficiaryTypes}
                                    disabledOptions={[BeneficiaryType.Trust]}
                                    onChanged={(beneficiaryType: BeneficiaryType) =>
                                      setValues({
                                        ...values,
                                        beneficiaries: _.map(values.beneficiaries, (b, j) => {
                                          if (index === j) {
                                            const isIndividual = beneficiaryType === BeneficiaryType.Individual;
                                            return {
                                              ...b,
                                              beneficiaryType,
                                              relationship: isIndividual ? b.relationship : undefined,
                                              firstName: isIndividual ? b.firstName : '',
                                              lastName: isIndividual ? b.lastName : '',
                                              dateOfBirth: isIndividual ? b.dateOfBirth : {},
                                              ssnSecret: isIndividual ? b.ssnSecret : '',
                                              trustName: isIndividual ? undefined : b.trustName,
                                              einSecret: isIndividual ? undefined : b.einSecret,
                                            };
                                          }
                                          return b;
                                        }),
                                      })
                                    }
                                  />
                                </FormGroup>
                                {beneficiary.beneficiaryType === BeneficiaryType.Individual && (
                                  <>
                                    <FormGroup>
                                      <Field
                                        component={SelectDropdownField}
                                        id={`beneficiaries[${index}].relationship`}
                                        isSearchable={false}
                                        options={BeneficiariesRelationships}
                                        defaultLabelId="relationship"
                                        optionsDefaultLabelId="beneficiaryRelationship"
                                      />
                                    </FormGroup>
                                    <FormGroup>
                                      <Field
                                        component={TextboxField}
                                        id={`beneficiaries[${index}].firstName`}
                                        defaultLabelId="firstName"
                                      />
                                    </FormGroup>
                                    <FormGroup>
                                      <Field
                                        component={TextboxField}
                                        id={`beneficiaries[${index}].middleInitial`}
                                        defaultLabelId="middleInitial"
                                      />
                                    </FormGroup>
                                    <FormGroup>
                                      <Field
                                        component={TextboxField}
                                        id={`beneficiaries[${index}].lastName`}
                                        defaultLabelId="lastName"
                                      />
                                    </FormGroup>
                                    <FormGroup>
                                      <Field
                                        component={TextboxField}
                                        id={`beneficiaries[${index}].suffix`}
                                        defaultLabelId="suffix"
                                      />
                                    </FormGroup>
                                    <FormGroup>
                                      <Field
                                        component={DateField}
                                        id={`beneficiaries[${index}].dateOfBirth`}
                                        defaultLabelId="dateOfBirth"
                                      />
                                    </FormGroup>
                                    <FormGroup>
                                      <Field
                                        component={TextboxField}
                                        id={`beneficiaries[${index}].ssnSecret`}
                                        label={'SSN/ITIN'}
                                        type="password"
                                        allowUnmask={true}
                                      />
                                    </FormGroup>
                                  </>
                                )}
                                {beneficiary.beneficiaryType === BeneficiaryType.Trust && (
                                  <>
                                    <FormGroup>
                                      <Field component={TextboxField} id={`beneficiaries[${index}].trustName`} />
                                    </FormGroup>
                                    <FormGroup>
                                      <Field
                                        component={TextboxField}
                                        id={`beneficiaries[${index}].einSecret`}
                                        type="password"
                                        allowUnmask={true}
                                      />
                                    </FormGroup>
                                  </>
                                )}
                                <Field
                                  component={AddressField}
                                  id={`beneficiaries[${index}].address`}
                                  labelPrefix={'Beneficiary'}
                                  countryProps={{
                                    options: getGroupedCountryOptions(),
                                    defaultLabelId: 'address.country',
                                    optionsDefaultLabelId: 'country',
                                    labelPrefix: 'Beneficiary',
                                  }}
                                  stateProps={{
                                    options: AllStates,
                                    defaultLabelId: 'address.state',
                                    optionsDefaultLabelId: 'state',
                                    labelPrefix: 'Beneficiary',
                                  }}
                                />
                                <FormGroup>
                                  <Field
                                    component={TextboxField}
                                    id={`beneficiaries[${index}].percent`}
                                    type="number"
                                    min="1"
                                    max="100"
                                    step="1"
                                  />
                                </FormGroup>
                                {totalPercentageError && (
                                  <p className="error">
                                    <strong>{totalPercentageError}</strong>{' '}
                                    <Text id={'beneficiaries.percent'} textKey={undefined} type={'error'} />
                                  </p>
                                )}
                              </div>
                            ))}
                            {max && total < max && (
                              <p>
                                <button
                                  onClick={event => {
                                    event.currentTarget.blur();
                                    event.preventDefault();
                                    arrayHelpers.push({
                                      address: {
                                        address1: '',
                                        address2: '',
                                        city: '',
                                        postalCode: null,
                                        country: '',
                                        state: null,
                                      },
                                      beneficiaryAccountType: '',
                                      beneficiaryType: '',
                                      dateOfBirth: {
                                        day: undefined,
                                        month: undefined,
                                        year: undefined,
                                      },
                                      einSecret: '',
                                      firstName: '',
                                      middleInitial: '',
                                      lastName: '',
                                      suffix: '',
                                      percent: null,
                                      relationship: '',
                                      ssnSecret: '',
                                      trustName: '',
                                    });
                                    setAddOrEditBeneficiary(true);
                                  }}
                                  type="button"
                                >
                                  <AddIcon inverse={true} />
                                  <strong>Add Beneficiary</strong>
                                </button>{' '}
                                <Text
                                  id={`available-counts.${total > 0 ? 'partial' : 'all'}`}
                                  data={{ max, left: max - total }}
                                />
                              </p>
                            )}
                            {addOrEditBeneficiary && (
                              <div className="block-footer-actions">
                                {beneficiaries.length > 0 && (
                                  <>
                                    <button
                                      className="btn btn-blend mr-2"
                                      onClick={e => {
                                        e.preventDefault();
                                        setAddOrEditBeneficiary(false);
                                      }}
                                    >
                                      <i className="fal fa-arrow-left" />
                                      Go back
                                    </button>
                                  </>
                                )}

                                <button
                                  className="btn btn-primary block-action-button"
                                  type="submit"
                                  onClick={(values: any) => handleSubmit(values)}
                                >
                                  Save
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      />
                    </div>
                  );
                }}
              </Formik>
            </>
          )}

          <RemoveAllBeneficiaries toggleModal={toggleShowRemoveAll} show={showRemoveAll} />
        </div>
      )}
    </>
  );
};
