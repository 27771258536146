import React, { useMemo, useCallback } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
export var chordFactory = function chordFactory(modifier) {
  return function (key) {
    return "".concat(modifier || 'ctrl+shift', "+").concat(key);
  };
};
export var Hotkey = function Hotkey(_ref) {
  var keys = _ref.keys,
      modifier = _ref.modifier,
      callback = _ref.callback;
  var chord = useMemo(function () {
    return chordFactory(modifier)(keys);
  }, [modifier, keys]);
  var hotkeyCallback = useCallback(function (event) {
    event.preventDefault();
    callback(event);
  }, [callback]);
  useHotkeys(chord, hotkeyCallback, [chord, hotkeyCallback]);
  return React.createElement(React.Fragment, null);
};