/* eslint-disable react/display-name */
import React from 'react';
import { TimeframeContainer } from './components/TimeframeContainer';
import { ActivityMessagesTable } from './components/ActivityMessagesTable';

export const ActivityMessages: React.FunctionComponent<{}> = () => {
  return (
    <>
      <TimeframeContainer type='activityBlockMessage' />
      <ActivityMessagesTable />
    </>
  );
};
