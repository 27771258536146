import React, { useEffect, useState, useCallback } from 'react';
import { AssetSymbol } from '@tradingblock/types';
import { SymbolQuote } from '@tradingblock/api';
import { SymbolInfo } from './SymbolInfo';
import { SymbolOrders } from './SymbolOrders';
import { CompanyInfo } from './Company';
import { SummaryInfo } from './Summary';
import { DividendsInfo } from './Dividends';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useDispatch } from 'react-redux';
import { QuoteDataActions } from '../../data/global/actions/QuoteActions';
import { EarningsInfo } from './Earnings';
import { QuoteBlockConstants, useQuoteData, QuoteState } from './state/QuoteState';
import { useBlockActionDispatchAndGenericActions } from '../../components/blocks/BlockState';
import { QuoteBlockAction, QuoteBlockActions } from './state/QuoteBlockAction';

const { summary, profile, earnings, dividends } = QuoteBlockConstants.tabs;

export const QuoteContent: React.FunctionComponent<{ symbol: AssetSymbol }> = ({ symbol }) => {
  const dispatch = useDispatch();
  const tab = useQuoteData(s => s.tab || 'summary');
  useEffect(() => {
    if (symbol) {
      dispatch(QuoteDataActions.requestQuoteExtended({ symbol: symbol.symbol }));
    }
  }, [symbol]);
  const [__, blockDispatch] = useBlockActionDispatchAndGenericActions<QuoteState, QuoteBlockAction>();
  const setTab = useCallback((ev: string) => blockDispatch(QuoteBlockActions.setTab(ev)), [blockDispatch]);
  return (
    <>
      <div className="block-quote-header">
        <div className="block-quote-header-primary">{symbol && <SymbolInfo symbol={symbol} />}</div>
        <div className="block-quote-header-secondary">{symbol && <SymbolOrders symbol={symbol} />}</div>
      </div>

      {/* Tabbed Content */}
      <div className="nav-tabs-pill">
        <Tabs defaultActiveKey={tab} id="uncontrolled-tab-example" onSelect={(ev: string) => setTab(ev)}>
          <Tab eventKey={summary} title="Summary">
            {symbol && tab === summary && <SummaryInfo symbol={symbol.symbol} />}
          </Tab>
          <Tab eventKey={profile} title="Profile">
            {symbol && tab === profile && <CompanyInfo symbol={symbol.symbol} />}
          </Tab>
          <Tab eventKey={earnings} title="Earnings">
            {symbol && tab === earnings && <EarningsInfo symbol={symbol.symbol} />}
          </Tab>
          <Tab eventKey={dividends} title="Dividends">
            {symbol && tab === dividends && <DividendsInfo symbol={symbol.symbol} />}
          </Tab>
        </Tabs>
      </div>
    </>
  );
};
