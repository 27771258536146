import React, { useEffect } from 'react';
import { OrderType } from '@tradingblock/types';
import { useOrderData } from '../../state/OrderState';
import { OrderTypePicker } from '../OrderTypePicker';
import { OrderPrice } from '../OrderPrice';
import { DebitCreditToggle } from '../DebitCreditToggle';
import { PriceSlider } from '../PriceSlider';
import { DurationPicker } from '../DurationPicker';
import { StopOrderPrice } from '../StopOrderPrice';

export const OrderContentSettings: React.FC<{}> = () => {
  const orderType = useOrderData(s => s.orderType);

  return (
    <div>
      <div className="order-settings mb-1">
        <div></div><div></div>
        <div>
          {orderType === OrderType.Stop_Limit && <StopOrderPrice />}
        </div>
      </div>
      <div className="order-settings">
        <OrderTypePicker
          className={`order-settings-feature order-settings-feature-type`}
        />
        <div className="order-settings-feature">
          <DurationPicker />
        </div>
        <div className="order-settings-feature order-settings-feature-qty">
          {orderType === OrderType.Stop_Market && <StopOrderPrice />}
          {!(orderType === OrderType.Stop_Market || orderType === OrderType.Stop_Limit || orderType === OrderType.Market || orderType === OrderType.Market_On_Close) && <OrderPrice />}
          {orderType === OrderType.Stop_Limit && <OrderPrice />}
        </div>
        <div className="order-settings-feature order-settings-feature-debit-credit">
          <DebitCreditToggle />
        </div>
        <div className="order-settings-feature order-settings-feature-minask">
          <div className="slider">
            <PriceSlider />
          </div>
        </div>
      </div>
    </div>
  );
};
