import { ActionType } from 'typesafe-actions';
import _ from 'lodash';
import { SetBlockIds } from '@tradingblock/components';
import { BlockState, BlockType, BlockConfigurationWithSettings, DashboardGridState } from '@tradingblock/types';
import { BlockActions } from '../actions';
import { DataState } from '../state';

type UpdateBlockType = ActionType<typeof BlockActions.addOrUpdateBlock>;

export const cleanBlock = (block: BlockConfigurationWithSettings) => {
  // layout data is stored separately in state
  return _(block)
    .omit('layouts')
    .value();
};
export const blockSettingsOrDefault = (state: DashboardGridState, block: BlockConfigurationWithSettings) =>
  block.settings
    ? block.settings
    : block.type && state.blockTypeSettings[block.type]
    ? state.blockTypeSettings[block.type]
    : undefined;

export const addBlockToBlocks = (state: DashboardGridState, block: BlockConfigurationWithSettings, groupId: string) => {
  const blockValue = {
    ...block,
    settings: blockSettingsOrDefault(state, block),
  };
  return state.blocks.concat(SetBlockIds([cleanBlock(blockValue)], groupId));
};

export const getBlockAndGroupIdForUpdate = (state: BlockState, action: UpdateBlockType) => {
  const { block, replaceFirstMatch } = action.payload;
  if (replaceFirstMatch) {
    // first search for existing block of same type in group (if groupId passed)
    let blockToReplace = replaceFirstMatch.groupId
      ? _.find(state.blocks, b => b.type === replaceFirstMatch.blockType && b.groupId === replaceFirstMatch.groupId)
      : undefined;
    // fallback to matching block type only (un-linked blocks)
    if (!blockToReplace) {
      blockToReplace = _.find(
        state.blocks,
        b => b.type === replaceFirstMatch.blockType && _.some(state.blocks, bb => b.blockId !== bb.blockId)
      );
    }
    if (blockToReplace) {
      const blockid = blockToReplace.blockId;
      // use new groupId if passed in payload; fallback to groupId of replaced block
      const blockToReplaceGroupId = blockToReplace.groupId || replaceFirstMatch.groupId || block.groupId;

      return {
        groupId: blockToReplaceGroupId,
        blockId: blockid,
      };
    }
  }
  return undefined;
};

export const getNewOrderBlock = (oldState: DataState, newState: DataState) => {
  const oldBlockIds = oldState.blocks.blocks.map(b => b.blockId);
  return _.find(newState.blocks.blocks, b => !oldBlockIds.includes(b.blockId) && b.type === BlockType.Order);
};

export const getNewPriceChartBlock = (oldState: DataState, newState: DataState) => {
  const oldBlockIds = oldState.blocks.blocks.map(b => b.blockId);
  return _.find(newState.blocks.blocks, b => !oldBlockIds.includes(b.blockId) && b.type === BlockType.PriceChart);
};
