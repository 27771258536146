import React from 'react';

const CircularProgressBar = ({ progress }: { progress: number }) => {
  const radius = 100;
  const strokeWidth = 20;
  const indicatorRadius = 5;
  const normalizedRadius = radius - strokeWidth / 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  // Calculate the position of the indicator
  const angle = (2 * Math.PI * progress) / 100;
  const indicatorX = radius + normalizedRadius * Math.cos(angle);
  const indicatorY = radius + normalizedRadius * Math.sin(angle);

  return (
    <svg height={radius * 2} width={radius * 2}>
      <defs>
        <linearGradient id="progressGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: '#001236', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: '#02fff6', stopOpacity: 1 }} />
        </linearGradient>
        <filter id="glow">
          <feGaussianBlur in="SourceGraphic" stdDeviation="3" result="blur" />
          <feMerge>
            <feMergeNode in="blur" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <circle
        stroke="#001236"
        strokeWidth={strokeWidth}
        cx={radius}
        cy={radius}
        r={normalizedRadius} // Adjust radius as needed
        fill="#33415e"
      />
      <circle
        stroke="url(#progressGradient)"
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset, transition: 'stroke-dashoffset .5s ease-out' }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
        filter="url(#glow)"
        strokeLinecap="round"
      />
      <circle
        cx={indicatorX}
        cy={indicatorY}
        r={indicatorRadius}
        fill="#02fff6" // Indicator color
      />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dy=".3em"
        fontSize="30px"
        fontFamily="Arial"
        fill="#02fff6"
        fontWeight={700}
      >
        {`${progress}%`}
      </text>
    </svg>
  );
};

export default CircularProgressBar;
