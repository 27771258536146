import React, { useMemo, useEffect } from 'react';
import { AccountWithdrawalDisplay } from '../../../../components/cashiering/AccountWithdrawalDisplay';
import { AccountDepositDisplay } from '../../../../components/cashiering/AccountDepositDisplay';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import {
  transferSelectors,
  relationshipSelectors,
  wireInstructionSelectors,
} from '../../../../data/global/selectors/admin/cashieringSelectors';
import { accountSelectors, balanceSelectors } from '../../../../data/global/selectors/admin/accountSelectors';
import { AdminCashieringActions } from '../../../../data/global/actions/admin/AdminCashieringActions';
import { useDispatcher } from '../../../../data/global/hooks';
import { getBalanceTotals } from '../../../../data/global/utilities/balances';
import { CustomerAccountDetails } from './CustomerAccountDetails';

const useCashieringTransferData = (
  accountId: number,
  achRelationshipId: number | undefined,
  instructionId: number | undefined
) => {
  const { dispatch } = useDispatcher();

  // need to fetch account balances for every transfer...

  useEffect(() => {
    if (achRelationshipId) {
      dispatch(AdminCashieringActions.cashieringRelationshipRequest({ accountId, id: achRelationshipId }));
    }
  }, [accountId, achRelationshipId, dispatch]);

  useEffect(() => {
    if (instructionId) {
      dispatch(AdminCashieringActions.cashieringWireInstructionsRequest({ accountId, id: instructionId }));
    }
  }, [accountId, instructionId, dispatch]);

  const balances = useStateSelector(s => balanceSelectors.data(s, accountId));
  const balancesForWithdrawal = useStateSelector(s => balanceSelectors.balancesForWithdrawal(s, accountId));
  const achRelationship = useStateSelector(s => relationshipSelectors.data(s, achRelationshipId || 0));
  const wireInstructions = useStateSelector(s => wireInstructionSelectors.data(s, instructionId || 0));

  const { adminAvailableForWithdrawal, adminPendingFullBalanceWithdrawalsExist } = balancesForWithdrawal || {};

  return {
    balanceTotals: balances ? getBalanceTotals(balances) : undefined,
    balancesForWithdrawal: {
      adminAvailableForWithdrawal,
      adminPendingFullBalanceWithdrawalsExist,
    },
    achRelationship,
    wireInstructions,
  };
};

export const CashieringTransferDetails: React.FunctionComponent<{
  id: number;
  accountId: number;
  isFetching: boolean;
}> = ({ id, accountId, isFetching }) => {
  const transfer = useStateSelector(s => transferSelectors.data(s, id));
  const transferDetails = useStateSelector(s => transferSelectors.details(s, id));
  const account = useStateSelector(s => accountSelectors.data(s, accountId));
  const isFetchingDetails = useStateSelector(
    s => s.private.cashiering.transferDetails[id] && s.private.cashiering.transferDetails[id].isFetching
  );

  const { balanceTotals, achRelationship, wireInstructions, balancesForWithdrawal } = useCashieringTransferData(
    accountId,
    transfer && transfer.achRelationshipId,
    transfer && transfer.instructionId
  );
  const { cashBalance, marginBalance } = useMemo(() => {
    return {
      cashBalance: balanceTotals ? balanceTotals.cashBalance : 0,
      marginBalance: balanceTotals ? balanceTotals.marginBalance : 0,
    };
  }, [balanceTotals]);

  const { adminAvailableForWithdrawal, adminPendingFullBalanceWithdrawalsExist } = balancesForWithdrawal || {};

  const detailsProps = useMemo(() => {
    return {
      nickname: account ? account.AccountTitle : '',
      availableForWithdrawal: balancesForWithdrawal && adminAvailableForWithdrawal ? adminAvailableForWithdrawal : 0,
      isPendingFullWithdrawal: balancesForWithdrawal && adminPendingFullBalanceWithdrawalsExist,
      availableForTrading: balanceTotals ? balanceTotals.availableForTrading : 0,
      positions: balanceTotals && balanceTotals.positionValue,
      transfer,
      transferDetails,
      achRelationship,
      wireInstructions,
      adminReview: true,
      cashBalance,
      marginBalance,
    };
  }, [account, balanceTotals, transfer, achRelationship, transferDetails]);

  return (
    <>
      {(isFetching || isFetchingDetails) && <div className="screen-body-section">Loading...</div>}
      {!isFetching && !isFetchingDetails && (
        <>
          {transfer && transfer.direction === 'Outgoing' && (
            <AccountWithdrawalDisplay {...detailsProps} transferInstructions={wireInstructions} />
          )}

          {transfer && transfer.direction === 'Incoming' && <AccountDepositDisplay {...detailsProps} />}

          <CustomerAccountDetails accountId={accountId} />
        </>
      )}
    </>
  );
};
