import { InitialState } from '../initialState';
import { RootAction } from '../actions';
import { getType } from 'typesafe-actions';
import { AccountManagementState } from '../state';
import { AccountManagementDataActions } from '../actions/AccountManagementActions';
import dayjs from 'dayjs';
import { AccountManagementBeneficiaryResponse } from '@tradingblock/types';

export const accountManagementReducer = (
  state = InitialState.accountManagement,
  action: RootAction
): AccountManagementState => {
  switch (action.type) {
    case getType(AccountManagementDataActions.requestAccountDetails): {
      const { accountId } = action.payload;
      const existingState = state || {};
      return {
        ...state,
      };
    }
    case getType(AccountManagementDataActions.receiveAccountDetails): {
      const { accountDetails } = action.payload;
      const existingState = state || {};
      return {
        ...state,
        accountDetails: {
          data: accountDetails,
          isLoaded: true,
        },
      };
    }
    case getType(AccountManagementDataActions.errorAccountDetails): {
      const { error } = action.payload;
      const existingState = state || {};
      return {
        ...state,
        accountDetails: {
          data: error,
          isLoaded: false,
        },
      };
    }
    case getType(AccountManagementDataActions.requestAccountUpdate): {
      return {
        ...state,
      };
    }
    case getType(AccountManagementDataActions.receiveAccountUpdate): {
      const { update } = action.payload;
      return {
        ...state,
        update: {
          isLoading: false,
          isLoaded: true,
          data: update,
        },
      };
    }
    case getType(AccountManagementDataActions.errorAccountUpdate): {
      const { error } = action.payload;
      return {
        ...state,
        update: {
          isLoading: false,
          isLoaded: false,
          data: error,
        },
      };
    }
    case getType(AccountManagementDataActions.requestAccountManagementDetails): {
      return {
        ...state,

        accountManagementDetails: {
          ...state.accountManagementDetails,
          isFetching: true,
        },
      };
    }
    case getType(AccountManagementDataActions.receiveAccountManagementDetails): {
      return {
        ...state,

        accountManagementDetails: {
          ...state.accountManagementDetails,
          isFetching: false,
          details: {
            ...action.payload.response,
            beneficiaries: action.payload.response.beneficiaries
              ? action.payload.response.beneficiaries.map((beneficiary: AccountManagementBeneficiaryResponse) => {
                  let dateModel = {
                    day: dayjs(beneficiary.dateOfBirth).date(),
                    month: dayjs(beneficiary.dateOfBirth).month() + 1,
                    year: dayjs(beneficiary.dateOfBirth).year(),
                  };
                  return {
                    ...beneficiary,
                    dateOfBirth: dateModel,
                  };
                })
              : [],
          },
        },
      };
    }
    case getType(AccountManagementDataActions.errorAccountManagementDetails): {
      return {
        ...state,

        accountManagementDetails: {
          ...state.accountManagementDetails,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }
    case getType(AccountManagementDataActions.requestMakeAccountUpdate): {
      return {
        ...state,
        update: {
          isLoading: true,
          isLoaded: false,
          data: {},
        },
      };
    }
    case getType(AccountManagementDataActions.receiveMakeAccountUpdate): {
      return {
        ...state,
        update: {
          isLoading: false,
          isLoaded: true,
          data: action.payload.response,
        },
      };
    }
    case getType(AccountManagementDataActions.errorMakeAccountUpdate): {
      return {
        ...state,
        update: {
          isLoading: false,
          isLoaded: false,
          data: action.payload,
        },
      };
    }
    case getType(AccountManagementDataActions.requestPendingAccountManagementUpdates): {
      return {
        ...state,
        pendingAccountManagementUpdates: {
          ...state.pendingAccountManagementUpdates,
          isFetching: true,
          data: null,
        },
      };
    }
    case getType(AccountManagementDataActions.receivePendingAccountManagementUpdates): {
      return {
        ...state,
        pendingAccountManagementUpdates: {
          ...state.pendingAccountManagementUpdates,
          isFetching: false,
          data: action.payload.pendingItems,
        },
      };
    }
    case getType(AccountManagementDataActions.errorPendingAccountManagementUpdates): {
      return {
        ...state,
        pendingAccountManagementUpdates: {
          ...state.pendingAccountManagementUpdates,
          isFetching: false,
          error: action.payload.error,
          data: null,
        },
      };
    }
    case getType(AccountManagementDataActions.requestNoPassDevices): {
      return {
        ...state,
        security: {
          ...state.security,
          isFetching: true,
        },
      };
    }
    case getType(AccountManagementDataActions.receiveNoPassDevices): {
      return {
        ...state,
        security: {
          ...state.security,
          isFetching: false,
          devices: action.payload.devices || [],
        },
      };
    }
    case getType(AccountManagementDataActions.errorNoPassDevices): {
      return {
        ...state,
        security: {
          ...state.security,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }
    case getType(AccountManagementDataActions.requestDeleteNoPassDevice): {
      return {
        ...state,
        security: {
          ...state.security,
          isFetching: true,
        },
      };
    }
    case getType(AccountManagementDataActions.receiveDeleteNoPassDevice): {
      return {
        ...state,
        security: {
          ...state.security,
          isFetching: false,
          devices: action.payload.devices || [],
        },
      };
    }
    case getType(AccountManagementDataActions.errorDeleteNoPassDevice): {
      return {
        ...state,
        security: {
          ...state.security,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }
    case getType(AccountManagementDataActions.requestAuthHistory): {
      return {
        ...state,
        authHistory: {
          ...state.authHistory,
          isFetching: true,
        },
      };
    }
    case getType(AccountManagementDataActions.receiveAuthHistory): {
      return {
        ...state,
        authHistory: {
          ...state.authHistory,
          isFetching: false,
          data: action.payload.response,
        },
      };
    }
    case getType(AccountManagementDataActions.errorAuthHistory): {
      return {
        ...state,
        authHistory: {
          ...state.authHistory,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }
    default: {
      return state;
    }
  }
};
