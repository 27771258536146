import React, { useMemo, useCallback } from 'react';
import { createSelector } from 'reselect';
import { BlockBody } from '@tradingblock/components';
import { BlockType } from '@tradingblock/types';
import { Block } from '../../components/blocks/Block';
import { getBlockProps } from '../../utilities/block';
import { InfoBlockSettingsFields } from './state/InfoSettings';
import { InfoHeader } from './InfoHeader';
import { InfoContent } from './InfoContent';
import { useInfoSettings } from './hooks/useInfoSettings';
import { useBlockActions, useBlockData } from '../../components/blocks/BlockState';
import { InfoBlockDataState } from './state/InfoState';
import { AssetSymbol } from '@tradingblock/types';
import { useGroupStateHandler } from '../useGroupState';

const rootSelector = (state: InfoBlockDataState) => state;
const symbolSel = createSelector(
  rootSelector,
  v => v.symbol
);
const selectors = {
  symbol: symbolSel,
};

const useInfoBlockGroupHandler = () => {
  const state = useBlockData<InfoBlockDataState>();
  const quoteSymbol = selectors.symbol(state);
  const { setField: set } = useBlockActions<InfoBlockDataState>();

  const setSymbol = useCallback((sym: AssetSymbol) => set('symbol', sym, { persist: false }), [set]);
  const onSymbolChange = useCallback(
    (symbol: AssetSymbol | undefined) => {
      if (symbol) {
        setSymbol(symbol);
      }
    },
    [quoteSymbol, setSymbol]
  );
  useGroupStateHandler('symbol', onSymbolChange, { fireOnGroupChange: true });
};

export const InfoContentBlock: React.FunctionComponent<{}> = ({ }) => {
  return (
    <BlockBody>
      <InfoContent />
    </BlockBody>
  );
};

export const InfoBlock: React.FunctionComponent<{}> = () => {
  const state = useBlockData<InfoBlockDataState>();
  const { setField: set } = useBlockActions<InfoBlockDataState>();
  const settings = useInfoSettings();

  const setSymb = useCallback(
    (sym: AssetSymbol) => {
      set('symbol', sym, { persist: false });
    },
    [set]
  );

  useInfoBlockGroupHandler();

  const blockprops = useMemo(
    () => getBlockProps(BlockType.Info, <InfoHeader blockType={BlockType.Info} setSymbol={setSymb} />),
    []
  );

  return (
    <Block settings={settings} settingFields={InfoBlockSettingsFields} {...blockprops}>
      <InfoContentBlock />
    </Block>
  );
};
