import { SearchAccountsRequest, SearchAccountsResponse } from '@tradingblock/types';
import { buildActions } from '../actionUtilities';

const REQUEST_LINKED_ACCOUNTS = '@linkedAccounts/REQUEST_LINKED_ACCOUNTS';
const RECEIVE_LINKED_ACCOUNTS = '@linkedAccounts/RECEIVE_LINKED_ACCOUNTS';
const RECEIVE_LINKED_ACCOUNTS_FAILED = '@linkedAccounts/RECEIVE_LINKED_ACCOUNTS_FAILED';

const [requestLinkedAccounts, receiveLinkedAccounts, errorLinkedAccounts] = buildActions({
  REQUEST: REQUEST_LINKED_ACCOUNTS,
  RECEIVE: RECEIVE_LINKED_ACCOUNTS,
  ERROR: RECEIVE_LINKED_ACCOUNTS_FAILED,
})<SearchAccountsRequest, SearchAccountsResponse>();

export const LinkedAccountsActions = {
  requestLinkedAccounts,
  receiveLinkedAccounts,
  errorLinkedAccounts,
};
