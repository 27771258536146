/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { OptionType } from '@tradingblock/components';
import { AssetSymbol, BlockType } from '@tradingblock/types';
import { useBlockMetadata, useBlockData } from '../../components/blocks/BlockState';
import { useStateSelector, blockGroupId } from '../../data/global/dataSelectors';
import { InfoBlockDataState } from './state/InfoState';
import { SymbolPickerHeader } from '../shared/SymbolPickerHeader';
import { useDispatcher } from '../../data/global/hooks';
import { QuoteDataActions } from '../../data/global/actions/QuoteActions';

interface HeaderProps {
  blockType: BlockType;
  setSymbol: (symbol: AssetSymbol) => void;
}

export const InfoHeader: React.FunctionComponent<HeaderProps> = ({ setSymbol, blockType }) => {
  let { symbol } = useBlockData<InfoBlockDataState>();
  const { dispatcher } = useDispatcher();
  const dispatch = useDispatch();
  const { blockId } = useBlockMetadata();
  const setGroupSymbol = dispatcher.blockGroup.setSymbol;
  const groupId = useStateSelector(s => blockGroupId(s, blockId));

  const onSelect = useCallback(
    (value: OptionType) => {
      const symbolName = value ? value.data : undefined;
      if (symbolName) {
        setSymbol(symbolName);
        if (groupId) {
          setGroupSymbol(groupId, symbolName);
        }
      }
      if (!symbolName) {
        setSymbol(null!);
        if (groupId) {
          setGroupSymbol(groupId, null!);
        }
      }
    },
    [setSymbol, groupId]
  );

  const onClear = useCallback(() => {
    if (groupId !== undefined) {
      setSymbol(undefined!);
      setGroupSymbol(groupId, undefined);
    }
  }, [setGroupSymbol, groupId]);

  return (
    <SymbolPickerHeader
      hidePrice={true}
      blockType={blockType}
      symbol={symbol || undefined}
      isLoaded={true}
      onSelect={onSelect}
      placeholder="Search..."
      clearOptionsOnSelect={true}
      onClear={onClear}
      hideTime={true}
      hidePercent={true}
    />
  );
};
