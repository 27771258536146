import { GlobalUIState } from '../state';
import { InitialState } from '../initialState';
import { RootAction, UIActions, DashboardActions } from '../actions';

import { getType } from 'typesafe-actions';

export const uiReducer = (state = InitialState.ui, action: RootAction): GlobalUIState => {
  switch (action.type) {
    case getType(DashboardActions.setDashboard): {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          drawerIsOpen: false,
        },
      };
    }
    case getType(UIActions.setDashboardDrawerOpen): {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          drawerIsOpen: action.payload.open,
        },
      };
    }
    case getType(UIActions.toggleLockDashboard): {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          isLocked: state.dashboard.isLocked ? false : true,
        },
      };
    }
    case getType(UIActions.setSubAccountFilterId): {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          subAccountFilterId: action.payload.id,
        },
      };
    }
    case getType(UIActions.setMarketSchedule): {
      return {
        ...state,
        marketSchedule: {
          market: action.payload.market,
          indexMarket: action.payload.indexMarket,
        },
      };
    }
    case getType(UIActions.setShowPendingEntitlementModal): {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          entitlements: {
            ...state.dashboard.entitlements,
            showPendingEntitlementModal: action.payload.showPendingEntitlementModal,
          },
        },
      };
    }
    default:
      return state;
  }
};
