/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { OptionType } from '@tradingblock/components';
import { AssetSymbol, BlockType } from '@tradingblock/types';
import { useStateSelector, blockGroupId } from '../../data/global/dataSelectors';
import { useDispatcher } from '../../data/global/hooks';
import { useBlockMetadata, useBlockData, useBlockSymbol } from '../../components/blocks/BlockState';
import { SymbolPickerHeader } from '../shared/SymbolPickerHeader';
import { PriceChartState } from './state/PriceChartState';
import { useOptionChainActions } from '../OptionChain/state/useOptionChainActions';
import { ChartType } from './PriceChartGraph';

interface PriceChartHeaderProps {
  setSymbol: (symbol: AssetSymbol) => void;
}

export const PriceChartHeader: React.FC<PriceChartHeaderProps> = ({ setSymbol }) => {
  const { isLoaded } = useBlockData<PriceChartState>();
  const symbol = useBlockSymbol();
  const { blockId } = useBlockMetadata();
  const groupId = useStateSelector(s => blockGroupId(s, blockId));
  const { dispatcher } = useDispatcher();
  const setGroupSymbol = dispatcher.blockGroup.setSymbol;
  const updateSymbol = useCallback(
    (symbolName: string, symbolValue: AssetSymbol | undefined) => {
      if (groupId) {
        setGroupSymbol(groupId, symbolValue);
      }
    },
    [setGroupSymbol, groupId]
  );
  const onSelect = useCallback(
    (value: any) => {
      const symbolValue = value ? value.data : undefined;
      const symbolName = symbolValue && symbolValue.symbol;
      if (symbolName && symbolValue) {
        updateSymbol(symbolName, symbolValue);
      }
    },
    [updateSymbol]
  );
  const onClear = useCallback(() => {
    updateSymbol('', undefined);
  }, [updateSymbol]);
  return (
    <SymbolPickerHeader blockType={BlockType.PriceChart} symbol={symbol} isLoaded={isLoaded} onSelect={onSelect} onClear={onClear}/>
  );
};
