import { getType } from 'typesafe-actions';
import { SizeType } from '@tradingblock/types';
import { Actions, RootAction, LayoutActions, BlockActions } from '../actions';

import { InitialState } from '../initialState';

export const layout = (state = InitialState.layout, action: RootAction) => {
  switch (action.type) {
    case getType(Actions.receiveSettings):
    case getType(Actions.setSettings):
      const { settings } = action.payload;
      const { width, gridColumnsSmall, gridColumnsMedium, gridColumnsLarge } = settings;
      return {
        ...state,
        width,
        gridColumnsSmall,
        gridColumnsMedium,
        gridColumnsLarge,
      };

    case getType(BlockActions.receiveBlocks):
      return {
        ...state,
        breakpoint: state.breakpoint || SizeType.lg, // default to large layout
        layouts: action.payload.layouts,
      };

    case getType(LayoutActions.setBreakpointAndColumns):
      return {
        ...state,
        breakpoint: action.payload.breakpoint,
        columns: action.payload.columns,
      };
    case getType(LayoutActions.setLayouts):
      const { layouts: newLayouts } = action.payload;
      // only update affected layout if already have layouts in state
      const layouts =
        state.breakpoint && state.layouts
          ? {
              ...state.layouts,
              [state.breakpoint]: newLayouts[state.breakpoint],
            }
          : newLayouts;
      return {
        ...state,
        layouts,
      };
    case getType(LayoutActions.setAllLayouts):
      // set all layouts directly
      return {
        ...state,
        layouts: action.payload.layouts,
      };

    default:
      return state;
  }
};
