import React, { useCallback } from 'react';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import {
  BooleanToggleType,
  AllBooleanToggleTypes,
  CommunicationPreferencesUpdateRequest,
  AllElectronicFormats,
  AllDuplicateStatementOptions,
  DuplicateStatements,
  getGroupedCountryOptions,
  AllStates,
  Country,
  CommunicationPreferencesUpdateValues,
  DeliveryType,
} from '@tradingblock/types';
import { FormGroup, Modal, SelectDropdownField, StepperField, TextboxField } from '@tradingblock/components';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { useDispatch } from 'react-redux';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { getRequiredError, USPSErrors, uspsVerification } from './Validation';
import { AccountManagementDataActions } from '../../../data/global/actions/AccountManagementActions';
import { formatBoolean } from '../../../utilities/accountManagement';

export const CommunicationPreferencesUpdate: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
}> = ({ show, toggleModal }) => {
  const dispatch = useDispatch();

  const accountId = useStateSelector(s => s.accounts.account && s.accounts.account.AccountId);

  const initialCommunicationPreferencesUpdateValues: CommunicationPreferencesUpdateRequest = {
    deliveryType: undefined,
    requestDuplicateStatements: undefined,
    duplicateStatements: undefined,
    duplicateStatementsAddress: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
    duplicateStatementsEmail: undefined,
    preferredElectronicFormat: undefined,
    subscribeToPromotionalEmails: undefined,
  };

  const onValidate = (values: CommunicationPreferencesUpdateRequest) => {
    const { duplicateStatementsAddress } = values;

    const { address1, city, state, country } = duplicateStatementsAddress || {
      address1: '',
      city: '',
      state: '',
      country: '',
    };

    let uspsResponse;
    let isValidAddress;
    const stateOrPostalCodeRequired = country === Country.UnitedStatesOfAmerica;
    const uspsResponseFromStorage = localStorage.getItem(`addressVerification-duplicateStatementsAddress`);

    if (uspsResponseFromStorage && uspsResponseFromStorage !== 'undefined') {
      uspsResponse = JSON.parse(uspsResponseFromStorage);
      const { city, state, zip5, error } = uspsResponse;
      const formattedCityCapitalized = _.capitalize(city);
      const startCaseCity = _.startCase(formattedCityCapitalized);
      const isCityEqual =
        values.duplicateStatementsAddress &&
        _.toLower(startCaseCity) === _.toLower(values.duplicateStatementsAddress.city);
      const isZipEqual = values.duplicateStatementsAddress && zip5 === values.duplicateStatementsAddress.postalCode;
      const isStateEqual = values.duplicateStatementsAddress && state === values.duplicateStatementsAddress.state;
      const isAddressCorrect = !error && isCityEqual && isZipEqual && isStateEqual;

      isAddressCorrect ? (isValidAddress = true) : (isValidAddress = false);
    }

    if (address1 && city && state && country === Country.UnitedStatesOfAmerica && !isValidAddress) {
      const getUSPS = async () => {
        await new Promise(resolve => setTimeout(resolve, 100));
        if (!values.duplicateStatementsAddress) {
          return;
        }
        return await uspsVerification(values.duplicateStatementsAddress);
      };

      getUSPS().then(result => {
        localStorage.setItem(`addressVerification-duplicateStatementsAddress`, JSON.stringify(result));
      });
    }

    if (uspsResponse) {
      const { city, state, zip5, error } = uspsResponse;

      if (
        error &&
        values.duplicateStatementsAddress &&
        values.duplicateStatementsAddress.address1 &&
        values.duplicateStatementsAddress.city &&
        values.duplicateStatementsAddress.state &&
        values.duplicateStatementsAddress.postalCode
      ) {
        return {
          [`duplicateStatementsAddress.postalCode`]: `${error.description.trim()} Please review the entered address.`,
          [`duplicateStatementsAddress.city`]: `${error.description.trim()} Please review the entered address.`,
          [`duplicateStatementsAddress.state`]: `${error.description.trim()} Please review the entered address.`,
          [`duplicateStatementsAddress.address1`]: `${error.description.trim()} Please review the entered address.`,
        };
      }

      if (
        (values.duplicateStatementsAddress && !values.duplicateStatementsAddress.address1) ||
        (values.duplicateStatementsAddress && !values.duplicateStatementsAddress.city)
      ) {
        localStorage.setItem(`addressVerification-duplicateStatementsAddress`, 'undefined');
      }

      if (city && state && zip5) {
        const formattedCityCapitalized = _.capitalize(city);
        const startCaseCity = _.startCase(formattedCityCapitalized);
        const isCityEqual =
          values.duplicateStatementsAddress &&
          _.toLower(startCaseCity) === _.toLower(values.duplicateStatementsAddress.city);
        const isZipEqual = values.duplicateStatementsAddress && zip5 === values.duplicateStatementsAddress.postalCode;
        const isStateEqual = values.duplicateStatementsAddress && state === values.duplicateStatementsAddress.state;

        // USPS + Required Errors

        const errors: USPSErrors = {
          ['duplicateStatementsAddress.country']: getRequiredError(
            values.duplicateStatementsAddress && values.duplicateStatementsAddress.country
          ),
          ['duplicateStatementsAddress.address1']: getRequiredError(
            values.duplicateStatementsAddress && values.duplicateStatementsAddress.address1
          ),
          ['duplicateStatementsAddress.postalCode']:
            getRequiredError(
              stateOrPostalCodeRequired
                ? values.duplicateStatementsAddress && values.duplicateStatementsAddress.postalCode
                : true
            ) || !isZipEqual
              ? `Please verify the zip code. USPS Provided Zip Code: ${zip5}`
              : undefined,
          ['duplicateStatementsAddress.city']:
            getRequiredError(values.duplicateStatementsAddress && values.duplicateStatementsAddress.city) ||
            !isCityEqual
              ? `Please verify the city. USPS Provided City: ${startCaseCity}`
              : undefined,
          ['duplicateStatementsAddress.state']:
            getRequiredError(
              stateOrPostalCodeRequired
                ? values.duplicateStatementsAddress && values.duplicateStatementsAddress.state
                : true
            ) || !isStateEqual
              ? `Please verify the state. USPS Provided State: ${state}`
              : undefined,
          [`deliveryType`]: getRequiredError(values.deliveryType),
          [`duplicateStatements`]: getRequiredError(values.duplicateStatements),
          [`preferredElectronicFormat`]: getRequiredError(values.preferredElectronicFormat),
          [`subscribeToPromotionalEmails`]: getRequiredError(values.subscribeToPromotionalEmails),
          [`requestDuplicateStatements`]: getRequiredError(values.requestDuplicateStatements),
        };

        Object.keys(errors).forEach(key => (errors[key] === undefined ? delete errors[key] : {}));

        return errors;
      }
    }
    const errors = {
      [`deliveryType`]: getRequiredError(values.deliveryType),
      [`duplicateStatements`]:
        values.requestDuplicateStatements === BooleanToggleType.Yes
          ? getRequiredError(values.duplicateStatements)
          : undefined,
      [`preferredElectronicFormat`]: getRequiredError(values.preferredElectronicFormat),
      [`subscribeToPromotionalEmails`]: getRequiredError(values.subscribeToPromotionalEmails),
      [`requestDuplicateStatements`]: getRequiredError(values.requestDuplicateStatements),
      [`duplicateStatementsAddress.country`]: getRequiredError(
        values.duplicateStatements === DuplicateStatements.PostalMail
          ? values.duplicateStatementsAddress && values.duplicateStatementsAddress.country
          : true
      ),
      [`duplicateStatementsAddress.address1`]: getRequiredError(
        values.duplicateStatements === DuplicateStatements.PostalMail
          ? values.duplicateStatementsAddress && values.duplicateStatementsAddress.address1
          : true
      ),
      [`duplicateStatementsAddress.postalCode`]: getRequiredError(
        values.duplicateStatements === DuplicateStatements.PostalMail
          ? values.duplicateStatementsAddress && values.duplicateStatementsAddress.postalCode
          : true
      ),
      [`duplicateStatementsAddress.city`]: getRequiredError(
        values.duplicateStatements === DuplicateStatements.PostalMail
          ? values.duplicateStatementsAddress && values.duplicateStatementsAddress.city
          : true
      ),
      [`duplicateStatementsAddress.state`]: getRequiredError(
        values.duplicateStatements === DuplicateStatements.PostalMail
          ? values.duplicateStatementsAddress && values.duplicateStatementsAddress.state
          : true
      ),
      [`duplicateStatementsEmail`]: getRequiredError(
        values.duplicateStatements === DuplicateStatements.Email ? values.duplicateStatementsEmail : true
      ),
    };
    const cleanedErrors = _.omitBy(errors, _.isNil);
    return cleanedErrors;
  };

  const onSubmit = useCallback(
    (values, { resetForm }) => {
      // construct updateValues for submission, if duplicateStatementsAddress is not provided, then remove it from the updateValues same for duplicateStatementsEmail
      // set duplicateStatements to None if requestDuplicateStatements is No
      const updateValues: CommunicationPreferencesUpdateValues = {
        item: 'CommunicationPreferences',
        communicationPreferences: {
          deliveryType: values.deliveryType === BooleanToggleType.Yes ? DeliveryType.Paper : DeliveryType.Electronic,
          duplicateStatements: values.duplicateStatements,
          duplicateStatementsAddress: values.duplicateStatementsAddress,
          duplicateStatementsEmail: values.duplicateStatementsEmail,
          preferredElectronicFormat: values.preferredElectronicFormat,
          subscribeToPromotionalEmails: formatBoolean(values.subscribeToPromotionalEmails),
        },
      };

      // remove duplicateStatementsAddress and duplicateStatementsEmail if they are not provided
      // remove duplicateStatementsAddress if duplicateStatements is not PostalMail
      if (!values.duplicateStatementsAddress) {
        delete updateValues.communicationPreferences.duplicateStatementsAddress;
      }
      if (!values.duplicateStatementsEmail) {
        delete updateValues.communicationPreferences.duplicateStatementsEmail;
      }
      if (values.duplicateStatements !== DuplicateStatements.PostalMail) {
        delete updateValues.communicationPreferences.duplicateStatementsAddress;
      }
      if (values.requestDuplicateStatements === BooleanToggleType.No) {
        updateValues.communicationPreferences.duplicateStatements = DuplicateStatements.None;
      }

      dispatch(
        AccountManagementDataActions.requestMakeAccountUpdate({
          accountId,
          request: updateValues,
        })
      );
      resetForm();
      toggleModal();

      localStorage.setItem(`addressVerification-duplicateStatementsAddress`, 'undefined');
    },
    [toggleModal]
  );

  return (
    <Formik
      initialValues={initialCommunicationPreferencesUpdateValues}
      onSubmit={onSubmit}
      validate={onValidate}
      validateOnChange={true}
    >
      {({ handleSubmit, values, errors, resetForm }) => (
        <Modal setShow={() => toggleModal()} show={show}>
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Communication Preferences Update</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            <form onSubmit={handleSubmit}>
              <p>Would you like to receive paper confirmations and statements?</p>
              <FormGroup>
                <Field
                  id="deliveryType"
                  component={StepperField}
                  options={AllBooleanToggleTypes}
                  defaultLabelId="boolean"
                />
              </FormGroup>
              <p>Preferred electronic communications format?</p>
              <FormGroup>
                <Field
                  id="preferredElectronicFormat"
                  component={StepperField}
                  options={AllElectronicFormats}
                  defaultLabelId="electronicFormat"
                />
              </FormGroup>
              <p>Would you like to receive duplicate statements?</p>
              <FormGroup>
                <Field
                  id="requestDuplicateStatements"
                  component={StepperField}
                  options={AllBooleanToggleTypes}
                  defaultLabelId="booleanCommsPref"
                />
              </FormGroup>
              {values.requestDuplicateStatements === BooleanToggleType.Yes && (
                <>
                  <p>How would you like to receive duplicate statements?</p>
                  <FormGroup>
                    <Field
                      id="duplicateStatements"
                      component={StepperField}
                      options={AllDuplicateStatementOptions}
                      defaultLabelId="duplicateStatements"
                    />
                  </FormGroup>
                  {values.duplicateStatements === DuplicateStatements.Email && (
                    <FormGroup>
                      <Field id="duplicateStatementsEmail" component={TextboxField} label="Email" />
                    </FormGroup>
                  )}
                  {values.duplicateStatements === DuplicateStatements.PostalMail && (
                    <>
                      <p>Address for Duplicate Statements</p>
                      <FormGroup>
                        <Field
                          component={SelectDropdownField}
                          name={'duplicateStatementsAddress.country'}
                          id={'duplicateStatementsAddress.country'}
                          label="Country"
                          options={getGroupedCountryOptions()}
                          optionsDefaultLabelId="country"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Field
                          id="duplicateStatementsAddress.address1"
                          name="duplicateStatementsAddress.address1"
                          component={TextboxField}
                          label="Address 1"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Field
                          id="duplicateStatementsAddress.address2"
                          name="duplicateStatementsAddress.address2"
                          component={TextboxField}
                          label="Address 2"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Field
                          id="duplicateStatementsAddress.city"
                          name="duplicateStatementsAddress.city"
                          component={TextboxField}
                          label="City"
                        />
                      </FormGroup>
                      <FormGroup>
                        {values.duplicateStatementsAddress &&
                          values.duplicateStatementsAddress.country === Country.UnitedStatesOfAmerica && (
                            <Field
                              component={SelectDropdownField}
                              name={'duplicateStatementsAddress.state'}
                              id={'duplicateStatementsAddress.state'}
                              label="State"
                              options={AllStates}
                              optionsDefaultLabelId="state"
                            />
                          )}
                        {values.duplicateStatementsAddress &&
                          values.duplicateStatementsAddress.country !== Country.UnitedStatesOfAmerica &&
                          values.duplicateStatementsAddress.country !== '' && (
                            <Field component={TextboxField} name={'state'} id={'state'} label="Province" />
                          )}
                      </FormGroup>
                      <FormGroup>
                        <Field
                          id="duplicateStatementsAddress.postalCode"
                          name="duplicateStatementsAddress.postalCode"
                          component={TextboxField}
                          label="Postal Code"
                        />
                      </FormGroup>
                    </>
                  )}
                </>
              )}
              <p>Would you like to receive promotional emails?</p>
              <FormGroup>
                <Field
                  id="subscribeToPromotionalEmails"
                  component={StepperField}
                  options={AllBooleanToggleTypes}
                  defaultLabelId="boolean"
                />
              </FormGroup>
            </form>
          </BootstrapModal.Body>
          <BootstrapModal.Footer className="modal-footer-justified">
            <Button
              variant="secondary"
              onClick={() => {
                resetForm();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={(values: any) => handleSubmit(values)}>
              Submit
            </Button>
          </BootstrapModal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
