import React, { useMemo } from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import { GetLogoutUrl, Modal } from '@tradingblock/components';
import { useStateSelector } from '../data/global/dataSelectors';
import { useSelector } from 'react-redux';
import { account } from '../data/global/selectors/accountSelectors';
import _ from 'lodash';

export const SessionExpiredModal: React.FunctionComponent = () => {
  const show = useStateSelector(s => s.auth.isExpired === true);
  const reason = useStateSelector(s => s.auth.expirationReason || undefined);
  const reasonText = useMemo(() => `Your session has expired. ${reason ? ' ' + reason : ''}. `, [reason]);
  const logoutText = 'You have successfully logged out. Now redirecting to login page. ';
  const accountNumber = useSelector(account.accountNumber);
  const reasonIsLogout = _.includes(reason, 'logged out');

  return (
    <Modal show={show} setShow={() => true} backdrop="static">
      <BootstrapModal.Header>
        <BootstrapModal.Title>{reasonIsLogout ? 'Session ended' : 'Session expired'}</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <p>
          {reasonIsLogout ? logoutText : reasonText}
          <a href={GetLogoutUrl(accountNumber)}>Click here to log in again</a>.
        </p>
      </BootstrapModal.Body>
    </Modal>
  );
};
