import React, { useCallback } from 'react';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import {
  AllTaxbracketTypes,
  AllTimeHorizonTypes,
  AllMonetaryRangeTypes,
  AllIncomeRangeTypes,
  FinancialUpdateValueProps,
} from '@tradingblock/types';
import { FormGroup, SelectDropdownField, Modal } from '@tradingblock/components';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { AccountManagementDataActions } from '../../../data/global/actions/AccountManagementActions';
import { getRequiredError } from './Validation';

export const FinancialUpdate: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
  assetType: string;
}> = ({ show, toggleModal }) => {
  const dispatch = useDispatch();
  const accountId = useStateSelector(s => s.accounts.account && s.accounts.account.AccountId);
  const { annualIncome, totalNetWorth, liquidNetWorth, characteristics: { bracket, horizon } } = useStateSelector(s => s.accountManagement.accountManagementDetails.details);
  const initialFinancialUpdateValues: FinancialUpdateValueProps = {
    annualIncome: annualIncome ? annualIncome : '',
    totalNetWorth: totalNetWorth ? totalNetWorth : '',
    liquidNetWorth: liquidNetWorth ? liquidNetWorth : '',
    characteristics: {
      bracket: bracket ? bracket : '',
      horizon: horizon ? horizon : '',
    },
  };

  const onValidate = (values: FinancialUpdateValueProps) => {
    const { annualIncome, totalNetWorth, liquidNetWorth, characteristics } = values;
    const { bracket, horizon } = characteristics;

    if (!annualIncome || !totalNetWorth || !liquidNetWorth || !bracket || !horizon) {
      return {
        [`annualIncome`]: getRequiredError(annualIncome),
        [`totalNetWorth`]: getRequiredError(totalNetWorth),
        [`liquidNetWorth`]: getRequiredError(liquidNetWorth),
        [`characteristics.bracket`]: getRequiredError(bracket),
        [`characteristics.horizon`]: getRequiredError(horizon),
      };
    }
  };

  const onSubmit = useCallback(
    (values, { resetForm }) => {
      const { annualIncome, totalNetWorth, liquidNetWorth, characteristics } = values;
      const { bracket, horizon } = characteristics;
      let updateValues = {};
      updateValues = {
        item: 'Financial',
        annualIncome: annualIncome,
        totalNetWorth: totalNetWorth,
        liquidNetWorth: liquidNetWorth,
        characteristics: {
          bracket: bracket,
          horizon: horizon,
        },
      };
      dispatch(AccountManagementDataActions.requestMakeAccountUpdate({ accountId: accountId, request: updateValues }));
      toggleModal();
      resetForm();
    },
    [toggleModal, annualIncome, annualIncome, totalNetWorth, liquidNetWorth, bracket, horizon, dispatch]
  );

  return (
    <Formik initialValues={initialFinancialUpdateValues} onSubmit={onSubmit} validate={onValidate}>
      {({ handleSubmit, resetForm }) => (
        <Modal setShow={() => toggleModal()} show={show}>
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Financial Information Update Request</BootstrapModal.Title>
          </BootstrapModal.Header>

          <BootstrapModal.Body>
            <form id="financialUpdate" onSubmit={handleSubmit} className="financialUpdate">
              <FormGroup>
                <Field
                  component={SelectDropdownField}
                  id="annualIncome"
                  options={AllIncomeRangeTypes}
                  optionsDefaultLabelId="incomeRangeType"
                />
              </FormGroup>
              <p className="txt-sm mute">
                Total gross annual income that earn or receive, or reasonable expect to earn or receive.
              </p>

              <FormGroup>
                <Field
                  component={SelectDropdownField}
                  id="totalNetWorth"
                  options={AllMonetaryRangeTypes}
                  optionsDefaultLabelId="monetaryRangeType"
                  label=""
                />
              </FormGroup>
              <p className="txt-sm mute">
                Total value of all assets you own less all liabilities, excluding primary residence.
              </p>

              <FormGroup>
                <Field
                  component={SelectDropdownField}
                  id="liquidNetWorth"
                  options={AllMonetaryRangeTypes}
                  optionsDefaultLabelId="monetaryRangeType"
                />
              </FormGroup>
              <p className="txt-sm mute">
                Total value of all cash and cash equivalent assets you own less all liabilities.
              </p>

              <FormGroup>
                <Field component={SelectDropdownField} id="characteristics.bracket" options={AllTaxbracketTypes} />
              </FormGroup>
              <p className="txt-sm mute">Used to determine trading products you can use.</p>

              <FormGroup>
                <Field component={SelectDropdownField} id="characteristics.horizon" options={AllTimeHorizonTypes} />
              </FormGroup>
              <p className="txt-sm mute">Used to determine trading products you can use.</p>
            </form>
          </BootstrapModal.Body>
          <BootstrapModal.Footer className="modal-footer-justified">
            <Button
              variant="secondary"
              onClick={() => {
                resetForm();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={(values: any) => handleSubmit(values)}>
              Submit
            </Button>
          </BootstrapModal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
