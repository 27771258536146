import * as tslib_1 from "tslib";
import React, { useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
export var Tooltip = function Tooltip(_a) {
  var id = _a.id,
      content = _a.content,
      children = _a.children,
      rest = tslib_1.__rest(_a, ["id", "content", "children"]);

  var tooltipId = useMemo(function () {
    return id;
  }, [id]);
  return React.createElement(React.Fragment, null, React.createElement("span", {
    "data-tip": true,
    "data-for": tooltipId
  }, children), React.createElement(ReactTooltip, Object.assign({}, rest, {
    id: tooltipId
  }), content));
};