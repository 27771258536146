import { createStandardAction } from 'typesafe-actions';

import {
  OrderConfirmation,
  ResponseCode,
  OrderErrorActionPayload,
  PlaceOrderRequest,
  ApiResponse,
  OrderReplaceRequest,
} from '@tradingblock/types';
import { OrderFeedActions } from './OrderFeedActions';
import { genericActionBuilder } from '../actionUtilities';

const PLACE_ORDER_REQUEST = '@order/PlaceOrder/REQUEST';
const PLACE_ORDER_RECEIVE = '@order/PlaceOrder/RECEIVE';
const PLACE_ORDER_ERROR = '@order/PlaceOrder/ERROR';

const [requestPlaceOrder, receivePlaceOrder, errorPlaceOrder] = genericActionBuilder<
  PlaceOrderRequest,
  { order: PlaceOrderRequest; response: ApiResponse<OrderConfirmation> }
>()(PLACE_ORDER_REQUEST, PLACE_ORDER_RECEIVE, PLACE_ORDER_ERROR);

const CHANGE_ORDER_REQUEST = '@order/ChangeOrder/REQUEST';
const CHANGE_ORDER_RECEIVE = '@order/ChangeOrder/RECEIVE';
const CHANGE_ORDER_ERROR = '@order/ChangeOrder/ERROR';
const [requestChange, receiveChange, errorChange] = genericActionBuilder<
  OrderReplaceRequest,
  { order: OrderReplaceRequest; response: ApiResponse<OrderConfirmation> }
>()(CHANGE_ORDER_REQUEST, CHANGE_ORDER_RECEIVE, CHANGE_ORDER_ERROR);

export const OrderActions = {
  ...OrderFeedActions,
  requestChange,
  receiveChange,
  errorChange,
  requestPlaceOrder,
  receivePlaceOrder,
  errorPlaceOrder,
  orderErrored: createStandardAction(`@order/errored`)<OrderErrorActionPayload>(),

  requestCancel: createStandardAction(`@order/request_cancel`)<{ orderId: number }>(),
  receiveCancel: createStandardAction(`@order/receive_cancel`)<{
    orderId: number;
    date: Date;
    response: OrderConfirmation;
    responseCode: ResponseCode;
  }>(),
};
