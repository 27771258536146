import { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { getType } from 'typesafe-actions';
import {
  AssetSymbol,
  SaveOptions,
  Legs,
  OrderLegDTO,
  TradingStrategyOrCustom,
  OrderAction,
  TradingStrategy,
  OptionType,
  PositionInfo,
  DebitOrCredit,
  BlockGroupOrderInfo,
  AssetSymbolInfo,
  Expiration,
  Order,
  OrderLeg,
} from '@tradingblock/types';
import { useDispatcher } from '../../../data/global/hooks';
import { useOrderBlockSettings } from '../hooks/useOrderSettings';
import { useBlockState, useBlockActionDispatchAndGenericActions } from '../../../components/blocks/BlockState';
import { DefaultLegs, useOrderDebug } from '../OrderUtilities';
import { OrderState, useOrderData } from './OrderState';
import { OrderActions, Actions } from './OrderActions';
import { useOrderSymbol } from './useOrderState';
import { useGroupAssetSymbol } from '../../useGroupState';
import { useOrderPrice } from '../hooks/useOrderPrice';

export function useOrderActions() {
  const log = useOrderDebug('useOrderSymbolData');
  // const assetSymbol = useOrderData(s => s.symbol);
  const assetSymbol = useGroupAssetSymbol();
  const symbol = useOrderSymbol();
  const { dispatcher } = useDispatcher();
  const blockId = useBlockState('blockId');
  const settings = useOrderBlockSettings(blockId);
  const legs = useOrderData((s: OrderState) => s.legs || {});

  const { mid } = useOrderPrice();

  // const action = useOrderData((s: OrderState) => s.action);
  const [{ updateData, setField }, dispatch] = useBlockActionDispatchAndGenericActions<OrderState, OrderActions>();
  const clearOrder = useCallback(() => {
    return dispatch(Actions.clear({ options: { persist: true } }, { persist: true }));
  }, [dispatch]);
  const setStrike = useCallback(
    (legId: string, strike: number) => {
      return dispatch(Actions.setStrike({ legId, strike, options: { persist: true } }, { persist: true }));
    },
    [dispatch]
  );
  const setSymbol = useCallback(
    (symbol?: AssetSymbol, defaultToEmptyCustomIfSymbolChanges?: boolean, options?: SaveOptions) => {
      return dispatch(
        Actions.setSymbol(
          { symbol, options: { defaultToEmptyCustomIfSymbolChanges, settings } },
          options || { persist: true }
        )
      );
    },
    [dispatch, settings]
  );
  // const addOrderLeg = useCallback(
  //   (leg: GroupOrderLeg, strategy: TradingStrategyOrCustom) => {
  //     const strat: TradingStrategyNameOrCustom = strategy.info.Name;
  //     return dispatch(Actions.addOrderLeg({ leg, strategy: strat }, { persist: true }));
  //   },
  //   [dispatch]
  // );

  const handleOrderGroupChange = useCallback(
    (symbol: AssetSymbol, orderInfo: BlockGroupOrderInfo) => {
      return dispatch(Actions.handleOrderGroupChange({ ...orderInfo, symbol, options: { persist: true }, settings }));
    },
    [dispatch, settings]
  );
  const setLegAction = useCallback(
    (legId: string, action?: OrderAction, options?: SaveOptions) => {
      return dispatch(Actions.setLegAction({ legId, action, options }, { persist: true }));
    },
    [dispatch]
  );
  const setExpiration = useCallback(
    (legId: string, expiration?: Expiration) => {
      return dispatch(Actions.setExpiration({ legId, expiration }, { persist: true }));
    },
    [dispatch]
  );
  const setOptionType = useCallback(
    (legId: string, optionType: OptionType) => {
      return dispatch(Actions.setOptionType({ legId, optionType }, { persist: true }));
    },
    [dispatch]
  );
  const setLegQuantity = useCallback(
    (legId: string, quantity: number) => {
      return dispatch(Actions.setLegQuantity({ legId, quantity }, { persist: true }));
    },
    [dispatch]
  );
  const setIsDirty = useCallback(
    (dirty: boolean) => {
      updateData({ isDirty: dirty });
    },
    [updateData]
  );
  const setOrderAction = useCallback(
    (action: OrderAction) => {
      return dispatch(Actions.setOrderAction({ action }, { persist: true }));
    },
    [dispatch]
  );
  const updateStateData = useMemo(
    () => (value: Partial<OrderState>, options?: SaveOptions | undefined) => {
      log('updateState %o', value);
      return updateData({ ...value }, options);
    },
    [updateData, log]
  );

  const setStrategy = useCallback(
    (
      strat: TradingStrategyOrCustom,
      options?: SaveOptions & { action?: OrderAction; reverseLegs?: boolean; isDirty?: boolean }
    ) => {
      const { action, reverseLegs, isDirty } = options || {
        action: undefined,
        reverseLegs: undefined,
        isDirty: undefined,
      };
      // allow missing symbol for 'Custom' strategy
      if (assetSymbol || strat.info.Name === 'Custom') {
        dispatch(
          Actions.setStrategy(
            { action, reverseLegs, isDirty, strategy: strat, symbol: assetSymbol, settings },
            { persist: true }
          )
        );
      } else {
        console.error('asset symbol is empty!');
      }
      dispatch({ type: getType(Actions.setPrice), payload: { price: undefined, updateCreditOrDebit: true, mid: mid } });
      dispatch({ type: getType(Actions.setStopPrice), payload: { price: undefined, updateCreditOrDebit: false } });
    },
    [assetSymbol, dispatch, settings] // , updateStateData, symbol, legs
  );
  const setMatchingStrategies = useCallback(
    (strat: TradingStrategy[], options?: SaveOptions) => {
      if (symbol) {
        updateStateData({ matchingStrategy: strat }, options);
      }
    },
    [updateStateData, symbol]
  );
  const setAction = useCallback(
    (actionVal: OrderAction, options?: SaveOptions) => {
      const updatedLegs: Legs = _.reduce(
        legs,
        (acc: Legs, val: OrderLegDTO, key: string): Legs => ({
          ...acc,
          [val.Id || key]: { ...val, Action: val.Action === OrderAction.Buy ? OrderAction.Sell : OrderAction.Buy },
        }),
        {}
      );
      updateStateData({ action: actionVal, legs: updatedLegs }, options);
    },
    [updateStateData, legs]
  );
  const startValidation = useCallback(
    (strategy?: TradingStrategy) => {
      dispatch({ type: 'startValidation', payload: { strategy } });
    },
    [dispatch]
  );
  const setPositionInfo = useCallback((info: PositionInfo) => dispatch(Actions.setPositionInfo({ info })), [dispatch]);

  const changeSymbol = useCallback(
    (symbol: AssetSymbolInfo, symbolValue: AssetSymbol, options?: SaveOptions) => {
      const baseLegs = _.keys(legs).length > 0 ? legs : DefaultLegs({}, symbol);

      dispatcher.block.setQuoteSubscription(blockId, [symbolValue.symbol]);

      const updatedData = {
        price: undefined,
        symbol: symbolValue,
        legs: _.reduce(
          baseLegs,
          (acc: Legs, leg: OrderLegDTO, id: string) => {
            return {
              ...acc,
              [id]: {
                ...leg,
                SymbolName: symbolValue.symbol,
                Symbol: symbolValue.symbol,
                UnderlyingSymbol: symbolValue.symbol,
              },
            };
          },
          {}
        )
      };
      updateStateData(updatedData, options);
    },
    [blockId, legs, updateStateData, dispatcher]
  );
  const updateField: typeof setField = useCallback(
    <K extends keyof OrderState>(field: K, value: OrderState[K], options?: SaveOptions) =>
      setField(field, value, options),
    [setField]
  );
  const setQuantity = useCallback(
    (quantity: number, options?: SaveOptions) => {
      return dispatch(Actions.setQuantity({ quantity }, { persist: true }));
    },
    [dispatch]
  );
  const orderPlaced = useCallback(() => dispatch({ type: getType(Actions.orderPlaced), payload: {} }), [dispatch]);
  const setPrice = useCallback(
    (price: number | undefined, updateCreditOrDebit: boolean, negativePrice?: boolean) =>
      dispatch({ type: getType(Actions.setPrice), payload: { price, updateCreditOrDebit, negativePrice } }),
    [dispatch]
  );
  const setStopPrice = useCallback(
    (price?: number) => dispatch({ type: getType(Actions.setStopPrice), payload: { price } }),
    [dispatch]
  );
  const setDebitCredit = useCallback(
    (debitCredit?: DebitOrCredit) => dispatch(Actions.setDebitCredit({ debitCredit }, { persist: true })),
    [dispatch]
  );

  const removeQuote = useCallback((key: string) => dispatch({ type: getType(Actions.removeQuote), payload: key }), [
    dispatch,
  ]);

  const setSubaccountId = useCallback((subAccountId) => {
    dispatch(Actions.setSubaccountId({ subaccountId: subAccountId }));
  }, [dispatch])

  const setOrder = useCallback((order: Order<OrderLeg>) => {
    dispatch(Actions.setOrder({ order }));
  }, [dispatch]);

  return {
    handleOrderGroupChange,
    removeQuote,
    setDebitCredit,
    setSubaccountId,
    setPrice,
    setStopPrice,
    orderPlaced,
    setQuantity,
    setPositionInfo,
    setOrderAction,
    clearOrder,
    startValidation,
    setSymbol,
    setOptionType,
    setLegQuantity,
    setExpiration,
    setStrike,
    setStrategy,
    updateField,
    setAction,
    changeSymbol,
    setIsDirty,
    setMatchingStrategies,
    setLegAction,
    setOrder,
  };
}
