import { createStandardAction, ActionType } from 'typesafe-actions';
import { EarningsTimeframeValues, DividendTimeframeValues } from '@tradingblock/types';
import { QuoteTab } from './QuoteState';

export const QuoteBlockActions = {
  setTab: createStandardAction('@quote/tab/set')<QuoteTab | string>(),
  setEarningsPeriod: createStandardAction('@quote/earnings/period/set')<EarningsTimeframeValues>(),
  setDividendsPeriod: createStandardAction('@quote/dividends/period/set')<DividendTimeframeValues>(),
};

export type QuoteBlockAction = ActionType<typeof QuoteBlockActions>;
