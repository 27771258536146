import React from 'react';
import { BlockBody } from '@tradingblock/components';
import { BlockType } from '@tradingblock/types';
import { Block } from '../../components/blocks/Block';
import { getBlockProps } from '../../utilities/block';
import { AccountManagementContent } from './AccountManagementContent';
import { AccountManagementHeader } from './AccountManagmentHeader';

export const AccountManagementContentBlock: React.FunctionComponent<{}> = () => {
  return (
    <BlockBody>
      <AccountManagementContent />
    </BlockBody>
  );
};

export const AccountManagementBlock: React.FunctionComponent<{}> = () => {
  return (
    <Block
      {...getBlockProps(
        BlockType.AccountManagement,
        <AccountManagementHeader blockType={BlockType.AccountManagement} />
      )}
    >
      <AccountManagementContentBlock />
    </Block>
  );
};
