import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatcher } from './dispatcher';

export type TradingBlockDispatcher = ReturnType<typeof Dispatcher>;
export const useDispatcher = () => {
  const dispatch = useDispatch();
  return { dispatcher: useMemo(() => Dispatcher(dispatch), [dispatch]), dispatch };
};

export const useDispatcherMemo = () => {
  const dispatch = useDispatch();
  return useMemo(() => Dispatcher(dispatch), []);
};
