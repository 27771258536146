import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useLocation from 'react-use/lib/useLocation';
import _ from 'lodash';
import { GetTradingBlockSiteName, setPageTitle } from '@tradingblock/components';
import { account, useStateSelector } from '../data/global/dataSelectors';
import { useVirtualTrading } from './useVirtualTrading';

export const usePageTitle = () => {
  const accountNumber = useSelector(account.accountNumber);
  const { href } = useLocation();
  const { isVirtualEnv } = useVirtualTrading();
  const origin = useStateSelector(state => state.auth.origin);

  useEffect(() => {
    if (accountNumber) {
      const suffix = _.includes(href, '/admin') ? '' : ` ${isVirtualEnv ? 'Virtual ' : ''}Dashboard`;
      setPageTitle(`${GetTradingBlockSiteName(accountNumber, href, origin)}${suffix}`);
    }
  }, [accountNumber, href, isVirtualEnv, origin]);
};
