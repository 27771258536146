import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { CashieringBaseEntity, CashieringSearchEntityType } from '@tradingblock/types';

export const CashieringActionCell: React.FunctionComponent<
  Pick<
    CashieringBaseEntity,
    'transfer' | 'status' | 'relationship' | 'type' | 'accountId' | 'transferRecipientBank' | 'transferWireInstruction'
  >
> = ({ relationship, status, transfer, type, accountId, transferRecipientBank, transferWireInstruction }) => {
  let url: string | undefined = undefined;
  if (type === CashieringSearchEntityType.Transfer && transfer) {
    url = `/admin/cashiering/${type}/${accountId}/${transfer.transferId}`;
  } else if (type === CashieringSearchEntityType.AchRelationship && relationship) {
    url = `/admin/cashiering/${type}/${accountId}/${relationship.relationshipId}`;
  } else if (type === CashieringSearchEntityType.TransferRecipientBank && transferRecipientBank) {
    url = `/admin/cashiering/${type}/${accountId}/${transferRecipientBank.transferRecipientBankId}`;
  } else if (type === CashieringSearchEntityType.TransferWireInstruction && transferWireInstruction) {
    url = `/admin/cashiering/${type}/${accountId}/${transferWireInstruction.transferWireInstructionId}`;
  }

  const isPending = _.toLower(status).includes('pending');
  return (
    <>
      {url && (
        <Link className={`btn ${isPending ? `btn-outline-light` : ''}`} to={url}>
          {isPending ? 'Review' : 'View'}
        </Link>
      )}
    </>
  );
};
