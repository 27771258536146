import React from 'react';
import { formatNumber, NumberValue } from "@tradingblock/components";
import { AccountDetails, AssetType, Order } from "@tradingblock/types";
import { useMemo } from "react";
import useSWR from "swr";
import { useApi } from "../../../../context/Api";
import { accountIdSelector, useStateSelector } from "../../../../data/global/dataSelectors";

export const DefaultCommissionDetails: AccountDetails['commissions'] = {
  commissionCodeId: 0,
  stocks: {
    minCharge: 5,
    ticketCharge: 0,
    percentOfTrade: 0,
    perShare: 0.001
  },
  options: {
    minCharge: 0,
    ticketCharge: 5,
    percentOfTrade: 0,
    perShare: 0.5
  },
  mutualFunds: {
    minCharge: 0,
    ticketCharge: 30,
    percentOfTrade: 0,
    perShare: 0
  }
}

export interface OrderAssetQuantity {
  assetType: AssetType,
  quantity: number
}

export function calculateEstCommission(orderAssetQuantities: OrderAssetQuantity[], commissionDetails?: AccountDetails['commissions']): number | undefined {
  if (commissionDetails === undefined || commissionDetails === null) {
    return undefined;
  }
  let commission = 0;
  let isStockTicket = false;
  let isOptionTicket = false;
  let isMutualFundTicket = false;
  const stockCommissionDetails = commissionDetails.stocks;
  const optionsCommissionDetails = commissionDetails.options;
  const mutualFundsCommissionDetails = commissionDetails.mutualFunds;

  let sharesCommission = 0;
  let optionsCommission = 0;
  let mutualFundsCommission = 0;

  for (let { assetType, quantity } of orderAssetQuantities) {
    if (assetType === AssetType.Equity && quantity && stockCommissionDetails) {
      isStockTicket = true;
      if (stockCommissionDetails.perShare !== undefined) {
        sharesCommission += quantity * stockCommissionDetails.perShare
      }
    }

    // Commission logic for Options
    if (assetType === AssetType.Option && quantity && optionsCommissionDetails && optionsCommissionDetails.perShare !== undefined) {
      isOptionTicket = true;
      optionsCommission += quantity * optionsCommissionDetails.perShare;
    }

    if (assetType === AssetType.MutualFund && quantity && mutualFundsCommissionDetails && mutualFundsCommissionDetails.perShare !== undefined) {
      isMutualFundTicket = true;
      mutualFundsCommission += quantity * mutualFundsCommissionDetails.perShare;
    }
  }

  let sharesMinCost = 0;
  if (stockCommissionDetails && stockCommissionDetails.minCharge !== undefined) {
    if (stockCommissionDetails.ticketCharge !== undefined && stockCommissionDetails.ticketCharge > stockCommissionDetails.minCharge) {
      sharesMinCost = stockCommissionDetails.ticketCharge;
    } else {
      sharesMinCost = stockCommissionDetails.minCharge;
    }
    if (isStockTicket && sharesCommission < sharesMinCost) {
      commission += sharesMinCost;
    } else {
      commission += sharesCommission;
    }
  }

  let optionsMinCost = 0;
  if (optionsCommissionDetails && optionsCommissionDetails.minCharge !== undefined) {
    if (optionsCommissionDetails.ticketCharge !== undefined && optionsCommissionDetails.ticketCharge > optionsCommissionDetails.minCharge) {
      optionsMinCost = optionsCommissionDetails.ticketCharge;
    } else {
      optionsMinCost = optionsCommissionDetails.minCharge;
    }
    if (isOptionTicket && optionsCommission < optionsMinCost) {
      commission += optionsMinCost;
    } else {
      commission += optionsCommission;
    }
  }

  let mutualFundsMinCost = 0;
  if (mutualFundsCommissionDetails && mutualFundsCommissionDetails.minCharge !== undefined) {
    if (mutualFundsCommissionDetails.ticketCharge !== undefined && mutualFundsCommissionDetails.ticketCharge > mutualFundsCommissionDetails.minCharge) {
      mutualFundsMinCost = mutualFundsCommissionDetails.ticketCharge;
    } else {
      mutualFundsMinCost = mutualFundsCommissionDetails.minCharge;
    }
    if (isMutualFundTicket && mutualFundsCommission < mutualFundsMinCost) {
      commission += mutualFundsMinCost;
    } else {
      commission += mutualFundsCommission;
    }
  }

  return commission;
}

export interface EstCommissionPreviewProps {
  order?: Order
}

export default function EstCommissionPreview({ order }: EstCommissionPreviewProps) {
  const api = useApi();
  const accountId = useStateSelector(accountIdSelector);
  const selectedAccount = useStateSelector(s => s.account.selectedLinkedAccount);
  // const subaccountId = useStateSelector(subAccountIdSelector);

  const { data, error } = useSWR(
    selectedAccount ? selectedAccount.accountId : accountId as any,
    api.accounts.details
  );

  //TODO: Need to see if we charge the exchange fee as the commission fee on here or not.

  const commissionValue = useMemo(() => {
    let commission: number = 0;

    if (data && order) {
      let orderAssetQuantities = order.Legs.map(l => ({ assetType: l.AssetType, quantity: l.Quantity ? l.Quantity : 0 }));
      let estCommission = undefined;
      if (data.payload.commissions === null || data.payload.commissions === undefined) {
        console.error('Commissions are not defined for the account');
        estCommission = calculateEstCommission(orderAssetQuantities, DefaultCommissionDetails);
      } else {
        estCommission = calculateEstCommission(orderAssetQuantities, data.payload.commissions);
      }
      commission = estCommission ? estCommission : 0;
    }
    return formatNumber(commission, { currency: true });
  }, [order, data]);

  if (order === undefined) {
    //TODO: Add in analytics logs for erros
    console.warn('Order is undefined for the commission preview');
    return (
      <div>Order not passed</div>
    );
  }

  return (
    <EstCommissionPreviewRender loading={data !== undefined} error={error} commissionValue={commissionValue} />
  )
}

export interface EstCommissionPreviewRenderProps {
  commissionValue?: string,
  loading: boolean,
  error: boolean
}

export function EstCommissionPreviewRender({ commissionValue }: EstCommissionPreviewRenderProps) {
  return (
    <div>
      Commission: {commissionValue}
    </div>
  );
}