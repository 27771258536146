import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import _ from 'lodash';
import { expirationValue } from '../../../utilities/date';
import { StrikeByExpirationSymbolMap, ExpirationStrike } from '../../../types';
import { DataState } from '../state';
import { Expiration } from '@tradingblock/types';
export const getExpirations = createCachedSelector(
  (state: DataState) => state.expirations,
  (_state: DataState, symbol: string) => symbol,
  (state, symbol) => {
    const symbolExp = state[symbol] || {
      isFetching: false,
      expirations: undefined,
    };
    return symbolExp;
  }
)((_, symbol) => symbol);
export const ExpirationSelectors = {
  expirations: createCachedSelector(
    (state: DataState) => state.expirations,
    (_state: DataState, symbol: string) => symbol,
    (state, symbol) => {
      const symbolExp = state[symbol] || {
        isFetching: false,
        expirations: undefined,
      };
      return symbolExp.expirations ? symbolExp.expirations : undefined;
    }
  )((_, symbol) => symbol),
  isFetching: createCachedSelector(
    (state: DataState) => state.expirations,
    (_state: DataState, symbol: string) => symbol,
    (state, symbol) => {
      const symbolExp = state[symbol] || {
        isFetching: false,
        expirations: undefined,
      };
      return symbolExp.isFetching;
    }
  )((_, symbol) => symbol),
};
const getStrikeState = (
  state: {
    [symbol: string]: StrikeByExpirationSymbolMap;
  },
  symbol: string,
  expiration: Expiration
) => {
  const expKey = expirationValue(expiration);
  const symbolState = state[symbol] || {};
  return symbolState[expKey] || { isFetching: false, strikes: undefined, symbol, expiration };
};

export const StrikeSelectors = {
  expirationsBySymbol: createSelector(
    (state: DataState) => state,
    (_state: DataState, symbol: string) => symbol,
    (state: DataState, symbol: string) => {
      const symbolStrikes: ExpirationStrike[] =
        state.strikes && state.strikes[symbol] ? _.values(state.strikes[symbol]) : [];
      return _.map(symbolStrikes, ss => ss.expiration);
    }
  ),
  strikesBySymbol: createCachedSelector(
    (state: DataState) => state.strikes,
    (_state: DataState, symbol: string) => symbol,
    (state: { [symbol: string]: StrikeByExpirationSymbolMap }, symbol: string): ExpirationStrike[] => {
      const symbolStrikes: ExpirationStrike[] = state && state[symbol] ? _.values(state[symbol]) : [];
      return symbolStrikes;
    }
  )((_, symbol) => symbol),
  strikes: createCachedSelector(
    (state: DataState) => state.strikes,
    (_state: DataState, symbolExp: { symbol: string; expiration: Expiration }) => symbolExp,
    (state, symbolExp) => {
      const { expiration, symbol } = symbolExp;
      return getStrikeState(state, symbol, expiration).strikes;
    }
  )((_, { symbol, expiration }) => `${symbol}-${expirationValue(expiration)}`),
  isFetching: createCachedSelector(
    (state: DataState) => state.strikes,
    (_state: DataState, symbolExp: { symbol: string; expiration: Expiration }) => symbolExp,
    (state, symbolExp) => {
      const { expiration, symbol } = symbolExp;
      return getStrikeState(state, symbol, expiration).isFetching;
    }
  )((_, { symbol, expiration }) => `${symbol}-${expirationValue(expiration)}`),
  anyFetching: createCachedSelector(
    (state: DataState) => state.strikes,
    (state: DataState, symbol: string) => symbol,
    (
      strState: {
        [symbol: string]: StrikeByExpirationSymbolMap;
      },
      symbol: string
    ) => {
      const strikeValues = strState[symbol] || {};
      return _.some(strikeValues, (v, k) => v.isFetching === true);
    }
  )((_, symbol) => symbol),
};
