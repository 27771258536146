import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Field } from 'formik';
import { CashieringView, AlertType, CashieringManageAction, SecurityQuestionType } from '@tradingblock/types';
import { Text, FormGroup, TextboxField, Loading } from '@tradingblock/components';
import {
  CashieringViewFormValues,
  CashieringViewProps,
  ViewOnSubmitType,
  ViewOnValidateType,
} from './data/useCashieringView';
import { useDispatcher } from '../../../data/global/hooks';
import { useStateSelector, cashieringSelectors } from '../../../data/global/dataSelectors';
import { useCashieringDataContext } from './data/useCashieringData';
import { TradingBlockLink } from '../../../components/basic/TradingBlockLink';
import { useSelector } from 'react-redux';

export const AuthorizeView: React.FC<CashieringViewProps<CashieringViewFormValues>> = ({
  values,
  setValues,
  children,
}) => {
  const { dispatcher } = useDispatcher();

  const { setFormStatus } = useCashieringDataContext();

  const securityQuestion = useStateSelector(cashieringSelectors.securityQuestion);
  const securityQuestionIsLoading = useStateSelector(s => cashieringSelectors.dataIsFetching(s, 'securityQuestion'));
  const securityQuestionMessage = useStateSelector(s => cashieringSelectors.dataMessage(s, 'securityQuestion'));
  const securityQuestionStatus = useStateSelector(s => cashieringSelectors.dataStatus(s, 'securityQuestion'));
  const securityQuestionIsVerifying = useStateSelector(s => cashieringSelectors.forms.dataIsSaving(s, 'transfer'));
  const totals = useSelector(cashieringSelectors.balanceTotals);
  const balancesForWithdrawal = useStateSelector(s => s.accountData.balances.balancesForWithdrawal);

  // if no totals are available, we need to attempt to fetch them
  useEffect(() => {
    if (!totals) {
      dispatcher.balances.request();
    }
  }, [totals]);

  useEffect(() => {
    if (!balancesForWithdrawal) {
      dispatcher.balances.requestBalancesForWithdrawal();
    }
  }, []);

  const previousVerificationFailed = useMemo(() => securityQuestionStatus === AlertType.warning, [
    securityQuestionStatus,
  ]);
  const allVerificationsFailed = useMemo(() => securityQuestionStatus === AlertType.danger, [securityQuestionStatus]);

  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    dispatcher.cashiering.resetForm();
    dispatcher.cashiering.securityQuestion.get.request();
  }, []);

  useEffect(() => {
    if (securityQuestion) {
      setValues({ ...values, transfer: { ...values.transfer, securityResponses: {} } });
    }
  }, [securityQuestion]);

  useEffect(() => {
    if (previousVerificationFailed || allVerificationsFailed) {
      if (!values.authorizeFailed) {
        setValues({ ...values, authorizeFailed: true });
      }
    } else if (values.authorizeFailed) {
      setValues({ ...values, authorizeFailed: false });
    }
  }, [previousVerificationFailed, allVerificationsFailed, values]);

  useEffect(() => {
    if (securityQuestionIsLoading || securityQuestionIsVerifying) {
      if (!isFetching) {
        setIsFetching(true);
      }
    } else if (isFetching) {
      if (previousVerificationFailed) {
        window.setTimeout(() => setIsFetching(false), 1000);
      } else {
        setIsFetching(false);
      }
    }
  }, [
    securityQuestionIsLoading,
    securityQuestionIsVerifying,
    previousVerificationFailed,
    allVerificationsFailed,
    isFetching,
  ]);

  const showNextSecurityQuestion = useCallback(() => {
    // reset security question verification status to show next question
    dispatcher.cashiering.securityQuestion.verify.resetStatus();
    // reset form status to hide errors
    setFormStatus({ view: CashieringView.Authorize });
  }, [values]);

  return (
    <>
      {securityQuestion && !values.authorizeFailed && (
        <div className="screen-body-section">
          <p>Let's confirm it's you. Do you remember this answer?</p>
          <FormGroup>
            <p>
              <Text id={`securityChallenges.securityQuestionType.${securityQuestion}`} type="field" />
            </p>
            <Field
              component={TextboxField}
              id={`transfer.securityResponses.${securityQuestion}`}
              label="Your answer"
              autoFocus={true}
              autoComplete="off"
            />
          </FormGroup>
        </div>
      )}

      {children}

      {values.authorizeFailed && (
        <div className="screen-body-section">
          {!allVerificationsFailed && isFetching && <Loading size="small" />}
          {!isFetching && previousVerificationFailed && (
            <p>
              <button className="btn btn-primary" type="button" onClick={showNextSecurityQuestion}>
                Try a different question
              </button>
            </p>
          )}
          {allVerificationsFailed && securityQuestionMessage && (
            <p>
              <span style={{ color: 'var(--salmon)' }}>{securityQuestionMessage}</span>{' '}
              <TradingBlockLink to="CustomerServiceUrl">Please contact Customer Service</TradingBlockLink>.
            </p>
          )}
        </div>
      )}

      {!allVerificationsFailed && !securityQuestion && isFetching && (
        <div className="screen-body-section">
          <Loading />
        </div>
      )}
    </>
  );
};

export const useAuthorizeView = (): [typeof AuthorizeView, ViewOnSubmitType, ViewOnValidateType] => {
  const { dispatcher } = useDispatcher();

  const { action, setAction } = useCashieringDataContext();

  const securityQuestion = useStateSelector(cashieringSelectors.securityQuestion);
  const securityQuestionStatus = useStateSelector(s => cashieringSelectors.dataStatus(s, 'securityQuestion'));

  useEffect(() => {
    if (securityQuestionStatus === AlertType.danger) {
      // set action to close after question verification failed
      setAction(CashieringManageAction.Close);
    }
  }, [securityQuestionStatus]);

  const onValidate = useCallback(
    (values: CashieringViewFormValues) => {
      const { transfer } = values;
      const { securityResponses } = transfer || { securityResponses: {} };
      // only validate if action not set to Close
      if (action !== CashieringManageAction.Close) {
        if (!securityResponses || (securityQuestion && !securityResponses[securityQuestion])) {
          return { [`transfer.securityResponses.${securityQuestion}`]: 'Please answer the security question.' };
        }
      }
      return {};
    },
    [securityQuestion, action]
  );

  const onSubmit = useCallback(
    (values: CashieringViewFormValues, view: CashieringView | undefined) => {
      // short circuit if action is set to Close
      if (action === CashieringManageAction.Close) {
        return true;
      }
      const { transfer } = values;
      const { securityResponses } = transfer || { securityResponses: {} };
      const securityResponse = _.first(
        _.map(securityResponses, (answer, securityQuestionType) => ({
          securityQuestionType: securityQuestionType as SecurityQuestionType,
          answer,
        }))
      );
      if (!securityResponses || _.isEmpty(securityResponses) || !securityResponse) {
        const BadFieldsError = {
          reason: 'No security challenge available.',
          values,
        };
        throw BadFieldsError;
      } else {
        dispatcher.cashiering.securityQuestion.verify.request(securityResponse);
      }
    },
    [dispatcher.cashiering, action]
  );

  return [AuthorizeView, onSubmit, onValidate];
};
