import { Middleware, Dispatch } from 'redux';
import { getType, ActionType } from 'typesafe-actions';
import dayjs from 'dayjs';
import _ from 'lodash';
import { ApiResponse, Expiration } from '@tradingblock/types';
import { DataState } from '../../state';
import { RootAction, ExpirationStrikeActions } from '../../actions';
import { ApiProvider } from '../../../../context/Api';
import { RequestThrottle } from '../../../../constants';
import { expirationValue } from '../../../../utilities/date';

const strikeThrottle: {
  timeout?: NodeJS.Timeout;
  lastRan: dayjs.Dayjs[];
  queue: { symbol: string; expiration: Expiration }[];
} = { timeout: undefined, queue: [], lastRan: [] };

export const requestStrike = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  symbol: string,
  expiration: Expiration
) => {
  const api = ApiProvider(state, dispatch);
  const strikeResponse = await api.options.strikes(symbol, expiration.date, expiration.optionExpirationType);
  const strikeResponsePayloadToFixed = strikeResponse.payload.map(num => parseFloat(num.toFixed(2)));
  dispatch(ExpirationStrikeActions.receiveStrikes({ symbol, expiration, strikes: strikeResponsePayloadToFixed }));
};

const getRequestsWithinWindow = (lastRequests: dayjs.Dayjs[], requestWindowMs: number) =>
  _.filter(lastRequests, lr => dayjs().diff(lr, 'ms') < requestWindowMs);

const getStrikeLastLoadedFromNowMs = (state: DataState, symbol: string, expiration: Expiration) => {
  const symState = state.strikes[symbol];
  if (_.isNil(symState)) {
    return undefined;
  }
  const expState = symState[expirationValue(expiration)];
  if (_.isNil(expState)) {
    return undefined;
  }
  const lastLoaded = expState.strikesLastLoaded;
  if (_.isNil(lastLoaded)) {
    return undefined;
  }

  return dayjs(new Date()).diff(lastLoaded, 'millisecond');
};

export const getStrikes = async (
  getState: () => DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof ExpirationStrikeActions.requestStrikes>
) => {
  const { symbol, expiration } = action.payload;
  // const res = await provider.get<{ data: AssetSymbolInfo[] }>(StorageFavoritesPath);
  // const favoriteValue = res && res.data ? res.data : [];
  // dispatch(FavoriteActions.receive({ value: favoriteValue }));
  const { queue, lastRan, timeout } = strikeThrottle;
  const { maxRequests, requestWindowMs, duplicateRequestIntervalMs } = RequestThrottle.getStrike;
  const currentState = getState();
  try {
    const lastLoadedMs = getStrikeLastLoadedFromNowMs(currentState, symbol, expiration);
    if (lastLoadedMs && lastLoadedMs <= duplicateRequestIntervalMs) {
      console.debug('throttling strike requests for ', action.payload);
      return true;
    }
    strikeThrottle.queue = [...queue, { symbol, expiration }];
    const lastRanWithinWindow = getRequestsWithinWindow(lastRan, requestWindowMs);
    if (lastRanWithinWindow.length >= maxRequests) {
      strikeThrottle.timeout = setTimeout(() => getStrikes(getState, dispatch, action), requestWindowMs);
    } else {
      const curr = _.first(strikeThrottle.queue);
      if (curr) {
        strikeThrottle.lastRan = _.concat(lastRanWithinWindow, dayjs());
        strikeThrottle.queue = _.filter(
          queue,
          v => v.expiration.date !== curr.expiration.date || v.symbol !== curr.symbol
        );
        //await requestStrike(currentState, dispatch, curr.symbol, curr.expiration);
        dispatch(ExpirationStrikeActions.fetchStrikes({ symbol, expiration }));
      }
    }
  } catch (err) {
    console.error('getQuote error', err);
  }
};

export const StrikeMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  try {
    // state BEFORE action is dispatched
    const result = next(action);
    // state AFTER action is dispatched
    const nextState = getState();
    switch (action.type) {
      case getType(ExpirationStrikeActions.requestStrikes): {
        getStrikes(getState, dispatch, action);
        break;
      }

      case getType(ExpirationStrikeActions.fetchStrikes):
        requestStrike(getState(), dispatch, action.payload.symbol, action.payload.expiration);
        break;
    }

    return result;
  } catch (err) {
    console.error('QuotesMiddleware :: Caught an exception for action ', action, err);
  }
};
