import React, { useMemo } from 'react';
import { Order, DebitOrCredit } from '@tradingblock/types';
import { BoldNumber } from '@tradingblock/components';
import { useOrderTotal } from '../../hooks/useOrderTotal';

export const PreviewOrderTotal: React.FC<{ order?: Order }> = ({ order }) => {
  const totalResults = useOrderTotal();
  const orderTotal = useMemo(() => totalResults && totalResults.total, [totalResults]);

  return (
    <>
      {order && orderTotal && (
        <div className={order.DebitCredit === DebitOrCredit.Debit ? 'neg' : 'pos'}>
          <BoldNumber value={orderTotal} currency /> {order.DebitCredit === DebitOrCredit.Debit ? 'Debit' : 'Credit'}
        </div>
      )}
    </>
  );
};
