import { Middleware, Dispatch } from 'redux';
import { getType, ActionType } from 'typesafe-actions';
import { DataState } from '../../../state';
import { RootAction } from '../../../actions';
import { ApiProvider } from '../../../../../context/Api';
import { AdminEntitlementsActions } from '../../../actions/admin/EntitlementsActions';
import { EntitlementsSearchRequestSelector } from '../../../selectors/admin/entitlementsSelector';

const getEntitlementRequests = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AdminEntitlementsActions.requestEntitlementUpdates>
) => {
  const api = ApiProvider(state, dispatch);

  if (api) {
    try {
      const response = await api.entitlements.getPendingUserMarketDataEntitlements(action.payload);
      dispatch(
        AdminEntitlementsActions.receiveEntitlementUpdates({ request: action.payload, response: response.payload })
      );
    } catch (err) {
      dispatch(AdminEntitlementsActions.errorEntitlementUpdates({ error: err }));
      console.error('getUserAccountDetails Error', err);
    }
  }
};

const getAccountDetails = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AdminEntitlementsActions.requestEntitlementAccountDetails>
) => {
  const api = ApiProvider(state, dispatch);

  if (api) {
    try {
      const response = await api.entitlements.getAccountDetails(action.payload);
      dispatch(AdminEntitlementsActions.receiveEntitlementAccountDetails({ response }));
      return response;
    } catch (err) {
      dispatch(AdminEntitlementsActions.errorEntitlementAccountDetails({ error: err }));
      console.error('getAccountDetails Error', err);
    }
  }
};

const approveUserMarketDataEntitlement = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AdminEntitlementsActions.requestApproveUserMarketDataEntitlement>
) => {
  const api = ApiProvider(state, dispatch);

  if (api) {
    try {
      const response = await api.entitlements.approvePendingUserMarketDataEntitlement(action.payload);
      dispatch(AdminEntitlementsActions.receiveApproveUserMarketDataEntitlement({ response }));
      return response;
    } catch (err) {
      dispatch(AdminEntitlementsActions.errorApproveUserMarketDataEntitlement({ error: err }));
      console.error('updateUserMarketDataEntitlement Error', err);
    }
  }
};

const rejectUserMarketDataEntitlement = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AdminEntitlementsActions.requestRejectUserMarketDataEntitlement>
) => {
  const api = ApiProvider(state, dispatch);

  if (api) {
    try {
      const response = await api.entitlements.rejectPendingUserMarketDataEntitlement(action.payload);
      dispatch(AdminEntitlementsActions.receiveRejectUserMarketDataEntitlement({ response }));
      return response;
    } catch (err) {
      dispatch(AdminEntitlementsActions.errorRejectUserMarketDataEntitlement({ error: err }));
      console.error('expireUserMarketDataEntitlement Error', err);
    }
  }
};

export const AdminEntitlementsMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  try {
    const previousState = getState();
    const result = next(action);
    const nextState = getState();
    switch (action.type) {
      case getType(AdminEntitlementsActions.setEntitlementsPage):
      case getType(AdminEntitlementsActions.refreshEntitlements):
      case getType(AdminEntitlementsActions.setEntitlementsPageSize): {
        const prevRequestKey = EntitlementsSearchRequestSelector(previousState);
        const { request, key } = EntitlementsSearchRequestSelector(nextState);
        if (prevRequestKey.key !== key && getType(AdminEntitlementsActions.refreshEntitlements) !== action.type) {
          break;
        }
        dispatch(AdminEntitlementsActions.requestEntitlementUpdates(request));
        break;
      }
      case getType(AdminEntitlementsActions.requestEntitlementUpdates): {
        getEntitlementRequests(nextState, dispatch, action);
        break;
      }
      case getType(AdminEntitlementsActions.requestEntitlementAccountDetails): {
        getAccountDetails(nextState, dispatch, action);
        break;
      }
      case getType(AdminEntitlementsActions.requestApproveUserMarketDataEntitlement): {
        approveUserMarketDataEntitlement(nextState, dispatch, action);
        break;
      }
      case getType(AdminEntitlementsActions.requestRejectUserMarketDataEntitlement): {
        rejectUserMarketDataEntitlement(nextState, dispatch, action);
        break;
      }
    }
    return result;
  } catch (err) {
    console.error('AdminEntitlementsMiddleware Error', err);
  }
};
