import React, { useEffect, useRef } from 'react';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { useApi } from '../../../../context/Api';
import { UpdateState } from '@tradingblock/types';
import { Button } from 'react-bootstrap';
import { useDispatcher } from '../../../../data/global/hooks';
import { AdminAccountManagementActions } from '../../../../data/global/actions/admin/AccountManagementActions';

export const AccountManagementDocReview: React.FunctionComponent<{}> = () => {
  const details = useStateSelector(s => s.private.accountManagement.currentItem.details);
  const accountHolders = useStateSelector(s => s.private.accountManagement.currentItem.accountInfo.accountHolders);
  const isFetching = useStateSelector(s => s.private.accountManagement.currentItem.isFetching);
  const isAccountFetching = useStateSelector(s => s.private.accountManagement.currentItem.isAccountFetching);
  const documentUpdate = useStateSelector(s => s.private.accountManagement.currentItem.documentUpdate);

  const accountId = details.accountId;
  const { updated } = details;
  const imageDocumentsRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<null | HTMLDivElement>(null);
  const api = useApi();
  const { dispatch } = useDispatcher();

  const StatusClassMap: Partial<{ [key in UpdateState]: string }> = {
    Approved: 'pos',
    Complete: 'pos',
    Rejected: 'neg',
    Cancelled: 'mute',
    Canceled: 'mute',
    PendingConfirm: 'pending',
    PendingFirmReview: 'pending',
    PendingReview: 'pending',
    Submitted: 'pos',
  };

  const handleApproval = (
    e: React.ChangeEvent<HTMLInputElement>,
    documentId: number,
    note: string,
    updateId: number
  ) => {
    e.preventDefault();
    dispatch(AdminAccountManagementActions.requestFirmApproveDoc({ accountId, documentId, note, updateId }));
  };

  const handleRejection = (
    e: React.ChangeEvent<HTMLInputElement>,
    documentId: number,
    note: string,
    updateId: number
  ) => {
    e.preventDefault();
    dispatch(AdminAccountManagementActions.requestFirmRejectDoc({ accountId, documentId, note, updateId }));
  };

  useEffect(() => {
    if (imageDocumentsRef.current && !isFetching && !isAccountFetching && accountId !== 0) {
      if (updated.uploadedDocuments.length > 0) {
        imageDocumentsRef.current.innerHTML = '';
        for (let document of updated.uploadedDocuments) {
          const index = document.accountHolder;

          const header = `${accountHolders[index].firstName} ${
            accountHolders[index].lastName
          } - ${document.documentName.replace(/([A-Z])/g, ' $1').trim()}`;

          const statusClass = document.state ? StatusClassMap[document.state] || '' : '';
          const state = document.state ? `${document.state.replace(/([A-Z])/g, ' $1').trim()}` : '';

          api.accountManagement
            .getMiscellaneousDocument(accountId, document.documentId)
            .then(res => {
              if (imageDocumentsRef.current) {
                imageDocumentsRef.current.innerHTML += `<span>${header}</span>`;
                imageDocumentsRef.current.innerHTML += `<br />`;
                imageDocumentsRef.current.innerHTML += `<strong><span class=${statusClass}>${state}</span></strong>`;
                imageDocumentsRef.current.innerHTML += `<br />`;
                imageDocumentsRef.current.innerHTML += `<img src="${res}" />`;
                imageDocumentsRef.current.innerHTML += `<br />`;
                imageDocumentsRef.current.innerHTML += `<br />`;
              }
            })
            .then(res => {
              if (documentUpdate && scrollRef.current) {
                scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
              }
            })
            .then(res => {
              if (documentUpdate) {
                dispatch(AdminAccountManagementActions.clearDocUpdate());
              }
            });
        }
      }
    }
  }, [imageDocumentsRef, updated, accountId, accountHolders, isFetching, isAccountFetching, documentUpdate]);

  return (
    <div className="screen-body-section">
      <p className="fields-title">Uploaded Documents</p>
      <div ref={imageDocumentsRef}>
        <i className="fas fa-spinner fa-spin" />
      </div>
      <p className="fields-title">Document Approval/Rejection</p>
      <div>
        {updated.uploadedDocuments.map((doc, i) => (
          <div key={doc.documentId} id={`doc-${i}`} className="doc-approval">
            <div>
              <div>
                {accountHolders[doc.accountHolder].firstName} {accountHolders[doc.accountHolder].middleInitial || ''}{' '}
                {accountHolders[doc.accountHolder].lastName} {accountHolders[doc.accountHolder].suffix || ''}
              </div>
              <div>{doc.documentName.replace(/([A-Z])/g, ' $1').trim()}</div>
              <div className={`${doc.state && StatusClassMap[doc.state]}`}>
                <strong>{doc.state && doc.state.replace(/([A-Z])/g, ' $1').trim()}</strong>
              </div>
            </div>
            {doc.state !== 'Rejected' && doc.state !== 'Submitted' && (
              <div className="ml-5">
                <Button
                  className="btn-reject"
                  type="button"
                  onClick={(e: any) => handleRejection(e, doc.documentId, '', details.id)}
                >
                  Reject
                </Button>

                <Button
                  className="btn-primary"
                  type="button"
                  onClick={(e: any) => handleApproval(e, doc.documentId, '', details.id)}
                >
                  Approve
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>
      <div ref={scrollRef} />
    </div>
  );
};
