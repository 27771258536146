import { Middleware, Dispatch } from 'redux';
import { getType, ActionType } from 'typesafe-actions';
import { setTimeout } from 'timers';
import _ from 'lodash';
import { ApiResponse } from '@tradingblock/types';
import { SymbolQuote, SymbolQuoteExtended } from '@tradingblock/api';
import { DataState } from '../../state';
import { RootAction, DataActions } from '../../actions';
import { QuoteDataActions } from '../../actions/QuoteActions';
import { ApiProvider } from '../../../../context/Api';

const monitor: { symbols: string[]; id: string; timeout: NodeJS.Timeout | undefined } = {
  id: '',
  timeout: undefined,
  symbols: [],
};

const debounceTime = 1000;

export const debounceLoadQuotes = (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof QuoteDataActions.requestQuote>
) => {
  const { symbol } = action.payload;
  const timeoutId = _.uniqueId('quote-request');
  monitor.id = timeoutId;
  monitor.timeout = setTimeout(() => {
    const symbolArr = _.isString(symbol) ? [symbol] : symbol;
    const allSymbols = _.uniq([...monitor.symbols, ...symbolArr]);
    if (timeoutId !== monitor.id) {
      monitor.symbols = allSymbols;
      console.debug('debouncing load quote request', allSymbols);
      return;
    }
    monitor.symbols = [];

    dispatch(DataActions.fetchingQuotes({ symbol: allSymbols }));
  }, debounceTime);
};

export const loadQuotes = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof QuoteDataActions.fetchingQuotes>
) => {
  const api = ApiProvider(state, dispatch);
  const { symbol } = action.payload;

  if (api) {
    try {
      // When doing quote requests, check current loaded quotes and only request for the ones that are not loaded from the API
      const currentSymbols = Object.keys(state.quotes.quoteMetadata.data).filter(symbol => {
        if (
          state.quotes.quoteMetadata.data[symbol].isLoadedFromApi !== undefined &&
          state.quotes.quoteMetadata.data[symbol].isLoadedFromApi === true
        ) {
          return true;
        }
        return false;
      });
      const symbolsToLoad = _.isArray(symbol) ? symbol : [symbol];
      const diffSymbols = _.difference(symbolsToLoad, currentSymbols);
      const chunks = _.chunk(diffSymbols, 100);
      const quotes: SymbolQuote[] = [];
      for (let chunk of chunks) {
        const [apiRequest, _] = api.quotes.get(chunk);
        const response = await apiRequest;
        quotes.push(...response.payload);
        await new Promise(r => setTimeout(r, 250)); //Throttle a quarter of a second
      }
      // const response = await apiRequest;
      // const quotes = response.payload || [];
      const quotesWithSymbols = quotes.map(v => ({
        quote: v,
        symbol: diffSymbols.find(sa => sa.toLocaleLowerCase() === v.Symbol.toLocaleLowerCase()) || '',
      }));
      dispatch(DataActions.receiveQuote(quotesWithSymbols));
    } catch (err) {
      console.error('getQuote error', err);
    }
  }
};

export const loadQuotesExtended = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof QuoteDataActions.requestQuoteExtended>
) => {
  const api = ApiProvider(state, dispatch);
  const { symbol } = action.payload;
  if (api) {
    try {
      dispatch(DataActions.fetchingQuotes({ symbol }));
      const response: ApiResponse<SymbolQuoteExtended> = await api.quotes.extended(symbol);
      const quoteResponse = response.payload;
      const quoteWithSymbol = { symbol: symbol, quote: quoteResponse };
      dispatch(DataActions.receiveQuoteExtended(quoteWithSymbol));
    } catch (err) {
      dispatch(DataActions.errorQuoteExtended({ symbol }));
      console.error('getQuoteExtended error', err);
    }
  }
};

const loadQuoteDividends = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof QuoteDataActions.requestDividends>
) => {
  const api = ApiProvider(state, dispatch);
  const { symbol, range } = action.payload;
  if (api) {
    try {
      const { payload } = await api.quotes.dividends(range || 'next', symbol);
      dispatch(DataActions.receiveDividends({ symbol, range, dividends: payload }));
    } catch (err) {
      console.error('loadQuoteDividends error', err);
      dispatch(DataActions.errorDividends({ error: err, symbol, range }));
    }
  }
};

const loadQuoteEarnings = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof QuoteDataActions.requestEarnings>
) => {
  const api = ApiProvider(state, dispatch);
  const { symbol, period } = action.payload;
  if (api) {
    try {
      const { payload } = await api.quotes.earnings(symbol, period || 'quarter');
      dispatch(DataActions.receiveEarnings({ symbol, period, earnings: payload }));
    } catch (err) {
      console.error('loadQuoteEarnings error', err);
      dispatch(DataActions.errorEarnings({ error: err, symbol, period }));
    }
  }
};

export const QuotesMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  try {
    // state BEFORE action is dispatched
    const result = next(action);
    // state AFTER action is dispatched
    const nextState = getState();
    switch (action.type) {
      case getType(QuoteDataActions.requestQuote):
        debounceLoadQuotes(nextState, dispatch, action);
        break;
      case getType(QuoteDataActions.fetchingQuotes):
        loadQuotes(nextState, dispatch, action);
        break;

      case getType(QuoteDataActions.requestQuoteExtended):
        loadQuotesExtended(nextState, dispatch, action);
        break;
      case getType(QuoteDataActions.requestDividends):
        loadQuoteDividends(nextState, dispatch, action);
        break;
      case getType(QuoteDataActions.requestEarnings):
        loadQuoteEarnings(nextState, dispatch, action);
        break;
    }

    return result;
  } catch (err) {
    console.error('QuotesMiddleware :: Caught an exception for action ', action, err);
  }
};
