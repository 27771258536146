import { OrderActions } from '../blocks/Order/state/OrderActions';
import { PriceChartActions } from '../blocks/PriceChart/state/PriceChartActions';
import _ from 'lodash';

interface GroupObserver {
  blockId: string;
  groupId: string;
  dispatch: React.Dispatch<OrderActions | PriceChartActions>;
}
type GroupAction = OrderActions | PriceChartActions;
class GroupActionManager {
  private observers: GroupObserver[] = [];
  private lastAction: { [groupId: string]: GroupAction } = {};
  public attach(observer: GroupObserver, runLastAction?: boolean) {
    this.observers = _.uniqBy([...this.observers, observer], o => o.blockId);
    const last = this.lastAction[observer.groupId];
    if (runLastAction && last) {
      observer.dispatch(last);
    }
  }
  public detach(blockId: string) {
    this.observers = _.filter(this.observers, o => o.blockId !== blockId);
  }
  public dispatch(groupId: string, action: GroupAction) {
    const obs = this.observers.filter(o => o.groupId === groupId);
    this.lastAction[groupId] = action;
    if (obs && obs.length > 0) {
      obs.forEach(o => {
        o.dispatch(action);
      });
    } else {
      console.log('no blocks found for action', action, 'in group', groupId);
    }
  }
}

export const GroupManager = new GroupActionManager();
