import { PositionPerformanceInfo } from '@tradingblock/types';
import { PositionPerformanceWithPnL } from '../data/global/state';
import { isIndexSymbol } from '@tradingblock/api';

export function changeCalculation(
  last: number,
  close: number,
  prevClose: number,
  bid?: number,
  ask?: number,
  symbol?: string,
  isSettlementSet?: boolean
) {
  let change = last - close;
  if (!isIndexSymbol(symbol ? symbol : '') && bid !== undefined && ask !== undefined && bid > 0 && ask > 0) {
    if (last < bid) {
      change = bid - close;
    } else if (last > ask) {
      change = ask - close;
    }
  }
  if (isSettlementSet) {
    change = close - prevClose;
    return {
      change: change,
      changePercent: change / prevClose,
    };
  }

  return {
    change: change,
    changePercent: change / close,
  };
}

export const positionToInfo = (p: PositionPerformanceWithPnL): PositionPerformanceInfo => {
  //Formula only MktBid and MktAsk > 0:
  //Last < MktBid ? MktChange = MktBid : Last > MktAsk ? MktChange = MktAsk : MktChange = Last - Close (server closed data).
  const { change, changePercent } = changeCalculation(
    p.lastTradePrice,
    p.close,
    p.prevClose,
    p.bid,
    p.ask,
    p.underlyingSymbol,
    p.IsSettlementSet
  );

  const changeValue = p.IsSettlementSet ? p.dailyPnl : p.change || change;
  const gainValue = p.IsSettlementSet ? p.overallPnl : p.gain;

  return {
    pnl: p.pnl,
    ask: p.ask,
    bid: p.bid,
    lastTradePrice: p.lastTradePrice,
    bestTradePrice: p.bestTradePrice,
    cost: p.cost - (p.commission || 0),
    change: p.change || changeValue,
    changePerc: p.changePercent || changePercent * 100,
    calculatedChange: change,
    calculatedChangePercent: changePercent * 100,
    quantity: p.shares || 0,
    optionsCount: p.options || 0,
    sharesSum: p.sharesSum,
    dayGain: p.pnl,
    totalGain: gainValue,
    value: p.value,
    symbol: p.symbol,
    underlyingSymbol: p.underlyingSymbol,
    close: p.close,
    description: p.description,
    IsSettlementSet: p.IsSettlementSet,
    prevClose: p.prevClose,
    commission: p.commission,
    dailyPnl: p.dailyPnl,
    overallPnl: p.overallPnl,
  };
};
