import React, { useCallback, FC, useEffect, Dispatch, SetStateAction } from 'react';
import _ from 'lodash';
import { BlockType } from '@tradingblock/types';
import { CountdownTimer, Svg, GetTradingBlockSiteName } from '@tradingblock/components';
import { useDispatcher } from '../../data/global/hooks';
import {
  useStateSelector,
  blocksSelectors,
  MarketCloseTimeSelector,
  isMarketOpenSelector,
  MarketOpenTimeSelector,
} from '../../data/global/dataSelectors';
import dayjs from 'dayjs';

const TradingStatusIconButton: React.FC<{ cssClasses?: string; title?: string; icon: string }> = ({
  title,
  cssClasses,
  icon,
}) => {
  return (
    <button className="dashboard-nav-tool dashboard-nav-tool-btn" type="button" onClick={() => {}}>
      <span className={`tb-icon tb-icon-2x tb-icon-stack tb-icon-clipboard ${cssClasses || ''}`} title={title}>
        <span className="tb-icon tb-icon-stack-2x">
          <Svg path="icon-clipboard" />
        </span>
        <span className="tb-icon tb-icon-stack-1x">
          <Svg path={`icon-${icon}`} />
        </span>
      </span>
    </button>
  );
};

export const TradingStatusIcon: FC<{ onAddBlock: (type: BlockType) => void }> = ({ onAddBlock }) => {
  const marketOpen = useStateSelector(s => isMarketOpenSelector(s));
  const blocks = useStateSelector(s => blocksSelectors.blocks(s));
  const feedInfo = useStateSelector(s => {
    const { status, message, isReconnecting } = s.feedProvider;
    const isClosed = status === 'closed';
    const isConnected = status === 'open';
    const isError = status === 'error' ? true : isClosed && isReconnecting === false ? true : false;

    return { status, message, isConnected, isError, isWarning: isClosed, isPending: status === undefined };
  });
  const { dispatcher } = useDispatcher();
  const onAddOrHighlightBlockType = useCallback(
    (blockType: BlockType) => {
      if (_.some(blocks, b => b.type === blockType)) {
        // highlight all blocks of same type
        dispatcher.block.highlightBlocks(blockType);
      } else {
        // add new block
        onAddBlock(blockType);
      }
    },
    [blocks, dispatcher.block, onAddBlock]
  );

  const cssClasses = feedInfo.isError ? 'neg' : feedInfo.isWarning ? 'warn' : feedInfo.isPending ? 'pending' : '';
  const title = feedInfo.message
    ? feedInfo.message
    : feedInfo.isPending
    ? 'Connecting...'
    : `Cannot connect to ${GetTradingBlockSiteName()} API`;
  return (
    <>
      {(feedInfo.isError || feedInfo.isWarning || feedInfo.isPending) && (
        <TradingStatusIconButton icon="lightning" cssClasses={cssClasses} title={title} />
      )}
      {feedInfo.isConnected && (
        <>
          <button
            className="dashboard-nav-tool dashboard-nav-tool-btn"
            type="button"
            onClick={() => onAddOrHighlightBlockType(BlockType.Order)}
          >
            <span
              className="tb-icon tb-icon-2x tb-icon-stack tb-icon-clipboard"
              title={marketOpen ? 'Trading open' : 'Trading closed'}
            >
              <span className="tb-icon tb-icon-stack-2x">
                <Svg path="icon-clipboard" />
              </span>
              <span className="tb-icon tb-icon-stack-1x">
                <Svg path={marketOpen ? 'icon-trading' : 'icon-night'} />
              </span>
            </span>
          </button>
        </>
      )}
    </>
  );
};
