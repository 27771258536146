import React, { FC, useContext } from 'react';
import { TimeframeContainer } from './TimeframeContainer';
import { ActivityOrderStats } from './ActivityOrderStats';
import { BasicCheckboxInput } from '@tradingblock/components';
import { BlockAction, BlockActionContext, useBlockMetadata } from '../../../components/blocks/BlockState';
import { dashboardIdSelector, useStateSelector } from '../../../data/global/dataSelectors';
import { blockSettings } from '../../../data/global/selectors/settingSelectors';
import { useDispatcher } from '../../../data/global/hooks';
import { useActivitySettingsValue } from '../useActivitySettings';

export const ActivityOrderTimeframe: FC<{ tabType: string }> = ({ tabType }) => {

  const dispatch = useContext(BlockActionContext);
  const { dispatcher } = useDispatcher();
  const dashboardId = useStateSelector(dashboardIdSelector);
  const { blockId, type } = useBlockMetadata();
  const settings = useStateSelector(s => blockSettings.position(s));
  const { workingOrdersOnly } = useActivitySettingsValue();

  const handleChecked = () => {
    const settingsSaveData = {
      dashboardId: dashboardId || '',
      blockId,
      blockType: type,
      blockSettings: {
        ...settings,
        workingOrdersOnly: !workingOrdersOnly
      },
    };
    dispatch(BlockAction.setSettings(settingsSaveData.blockSettings));
    dispatcher.settings.block.setBlockSetting(settingsSaveData);
  };
  return (
    <TimeframeContainer type='activityBlockTimeFrame'>
      {tabType === 'orders' && <BasicCheckboxInput className='col-auto' checked={workingOrdersOnly} onchange={() => handleChecked()}>
        Working Orders Only
      </BasicCheckboxInput>}
      <div style={{ marginLeft: 'auto' }}>
        <ActivityOrderStats />
      </div>
    </TimeframeContainer >
  );
};
