import useAudio from 'react-use/lib/useAudio';

export const audioSrc = {
  success: require('../assets/sounds/ok.mp3'),
  error: require('../assets/sounds/error.mp3'),
  updates: require('../assets/sounds/updates.mp3'),
};

export const useSounds = () => {
  const [successAudio, , successControls] = useAudio({
    src: audioSrc.success,
  });
  const [errorAudio, , errorControls] = useAudio({
    src: audioSrc.error,
  });
  return {
    successAudio,
    successControls,
    errorAudio,
    errorControls,
  };
};
