import { useEffect, useRef } from 'react';
import { useLog } from './useDebug'; // function objectDiff(object, base) {
//   function changes(object, base) {
//     const accumulator = {};
//     Object.keys(base).forEach(key => {
//       if (object[key] === undefined) {
//         accumulator[`-${key}`] = base[key];
//       }
//     });
//     return _.transform(
//       object,
//       (accumulator, value, key) => {
//         if (base[key] === undefined) {
//           accumulator[`+${key}`] = value;
//         } else if (!_.isEqual(value, base[key])) {
//           accumulator[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
//         }
//       },
//       accumulator
//     );
//   }
//   if (_.isNil(object)) {
//     return base;
//   }
//   if (_.isNil(base)) {
//     return object;
//   }
//   return changes(object, base);
// }
// Hook

export function useWhyDidYouUpdate(name, props) {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  var previousProps = useRef(null);
  var log = useLog("useWhyDidYouUpdate:".concat(name));
  useEffect(function () {
    if (previousProps.current) {
      // Get all keys from previous and current props
      var allKeys = Object.keys(Object.assign({}, previousProps.current, props)); // Use this object to keep track of changed props

      var changesObj = {}; // Iterate through keys

      allKeys.forEach(function (key) {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          var from = previousProps.current[key];
          var to = props[key];
          changesObj[key] = {
            from: from,
            to: to
          };
        }
      }); // If changesObj not empty then output to console

      if (Object.keys(changesObj).length) {
        log('%o', changesObj);
      }
    } // Finally update previousProps with current props for next hook call


    previousProps.current = props;
  });
}