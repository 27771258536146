import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { formatNumber } from '@tradingblock/components';
import { BlockTable } from '../shared/BlockTable';
import { Cell } from 'react-table';
import { Button } from 'react-bootstrap';
import { SubAccountManagementSubRow } from './SubAccountManagementSubRow';
import { Balances, ClientEntity } from '@tradingblock/types';
import _ from 'lodash';
import CreateSubAccountModel, { CreateSubAccountModelProps } from './CreateSubAccountModel';
import UpdateDistributionsSubAccountModel, { UpdateDistributionRatioModelProps } from './UpdateDistributionRatioModel';
import { accountBalances, useStateSelector } from '../../data/global/dataSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { getCashAndCashAlts } from '../../data/global/utilities/balances';
import { account } from '../../data/global/selectors/accountSelectors';
import { Actions } from '../../data/global/actions';
import { AccountActions } from '../../data/global/actions/AccountActions';
import { useApi } from '../../context/Api';
import useSWR from 'swr';

export const SubAccountManagementTable: FC<{}> = () => {
  const accountNumber = useStateSelector(s => s.account.accountNumber);
  const accountNickname = useStateSelector(s => s.account.nickname);
  const accountDetails = useStateSelector(s => s.account.accountDetails);
  const accountName = accountDetails && accountDetails.accountNames.length > 0 ? accountDetails.accountNames[0] : '';
  const accountType = accountDetails && accountDetails.accountType ? accountDetails.accountType : '';
  const accountTitle = accountName + (accountName && accountType && ' ') + accountType;
  const accountId = useStateSelector(s => s.account.accountId);
  const subAccounts = useStateSelector(s => s.account.subaccounts);
  const subAccountBalances = useStateSelector(s => s.accountData.subAccountsBalances);
  const hasSubAccounts = useSelector(account.hasSubAccounts);
  const accountStatus = accountDetails && accountDetails.accountStatus ? accountDetails.accountStatus : '';
  const accountManagementDetails = useStateSelector(s => s.accountManagement.accountManagementDetails.details);
  const totals = useSelector(accountBalances.totals);
  const accountFlexibilityType =
    accountManagementDetails && accountManagementDetails.flexibilityType
      ? accountManagementDetails.flexibilityType[0]
      : '';
  const [masterSubaccountBalance, setMasterSubaccountBalance] = useState<number>(0);
  const dispatch = useDispatch();
  const api = useApi();
  const [accntBalances, setAccountBalances] = useState<Balances>();

  const { data: accountBalance } = useSWR([accountId], api.balances.get);

  useEffect(() => {
    setAccountBalances(accountBalance && accountBalance.payload ? accountBalance.payload : undefined);
  }, [accountBalance]);

  const [balance, setBalance] = useState<number>();

  const getAccountStatus = useCallback((status: string) => {
    // format status to place a space between words
    const formattedStatus = status.replace(/([A-Z])/g, ' $1').trim();
    switch (status) {
      case 'Active':
        return <span style={{ color: 'var(--green)', fontWeight: 500 }}>{formattedStatus}</span>;
      case 'Inactive':
        return <span style={{ color: 'var(--salmon)', fontWeight: 500 }}>{formattedStatus}</span>;
      case 'Closed':
        return <span style={{ color: 'var(--salmon)', fontWeight: 500 }}>{formattedStatus}</span>;
      case 'ActiveClosingTradesOnly':
        return <span style={{ color: 'var(--gold)', fontWeight: 500 }}>{formattedStatus}</span>;
      default:
        return (
          <span className="warn" style={{ fontWeight: 500 }}>
            {formattedStatus}
          </span>
        );
    }
  }, []);

  useEffect(() => {
    dispatch(AccountActions.requestSubAccounts({ accountId: accountId }));

    dispatch(
      Actions.requestSubAccountBalances({
        accountId: accountId,
        subAccounts: subAccounts && subAccounts.subaccounts ? subAccounts.subaccounts : [],
        update: true,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      Actions.requestSubAccountBalances({
        accountId: accountId,
        subAccounts: subAccounts && subAccounts.subaccounts ? subAccounts.subaccounts : [],
        update: true,
      })
    );
  }, [subAccounts, totals]);

  useEffect(() => {
    let masterSubAccount =
      subAccounts && subAccounts.subaccounts ? subAccounts.subaccounts.find(sa => sa.IsMaster) : undefined;
    if (masterSubAccount && subAccountBalances && subAccountBalances[masterSubAccount.Id.toString()]) {
      setMasterSubaccountBalance(
        parseFloat(getCashAndCashAlts(subAccountBalances[masterSubAccount.Id.toString()]).toFixed(2))
      );
    }
  }, [subAccountBalances]);

  useEffect(() => {
    setBalance(accntBalances ? parseFloat(getCashAndCashAlts(accntBalances).toFixed(2)) : 0);
  }, [accntBalances]);

  const [createSubAccountModel, setCreateSubAccountModel] = useState<Omit<CreateSubAccountModelProps, 'setShow'>>({
    show: false,
    initialAccountState: {
      accountId: 0,
      balance: 0,
    },
  });
  const setShowCreateAccountModalCallback = useCallback(
    (show: boolean) => {
      setCreateSubAccountModel({ ...createSubAccountModel, show });
    },
    [setCreateSubAccountModel, createSubAccountModel]
  );
  const [updateDistributionRatioModel, setUpdateDistributionRatioModel] = useState<
    Omit<UpdateDistributionRatioModelProps, 'setShow'>
  >({
    show: false,
    initialAccountState: {
      AccountId: 0,
    },
  });
  const setShowUpdateDistributionRatioModalCallback = useCallback(
    (show: boolean) => {
      setUpdateDistributionRatioModel({ ...updateDistributionRatioModel, show });
    },
    [setUpdateDistributionRatioModel, updateDistributionRatioModel]
  );

  const columns = useMemo(
    () => [
      {
        Header: () => <div style={{ fontWeight: 'bolder' }}>Account Name</div>,
        id: 'Account Name',
        canSort: true,
        sortType: 'alphanumeric',
        Cell: ({ row }: Cell<ClientEntity>) => {
          return (
            <div style={{ fontWeight: 'bolder' }}>{row.original.accountName ? row.original.accountName : ''} </div>
          );
        },
      },
      {
        Header: () => <div style={{ fontWeight: 'bolder' }}>Account Number</div>,
        id: 'Account Number',
        canSort: true,
        sortType: 'alphanumeric',
        Cell: ({ row }: Cell<ClientEntity>) => {
          return (
            <div style={{ fontWeight: 'bolder' }}>
              {row.original.accountNumber} - {accountFlexibilityType && `${accountFlexibilityType} - `}
              {getAccountStatus(accountStatus)}
            </div>
          );
        },
      },
      {
        Header: () => <div style={{ fontWeight: 'bolder' }}>Cash/Cash Alts Balance</div>,
        id: 'Cash/Cash Alts Balances',
        canSort: true,
        sortType: 'alphanumeric',
        style: { textAlign: 'center' },
        Cell: ({ row }: Cell<ClientEntity & { balance: string }>) => {
          return <div style={{ fontWeight: 'bolder' }}>{formatNumber(row.original.balance, { currency: true })}</div>;
        },
      },
      {
        Header: () => <div style={{ textAlign: 'center', fontWeight: 'bolder' }}>Actions</div>,
        id: 'Actions',
        Cell: ({ row }: Cell<ClientEntity & { balance: number }>) => {
          return (
            <Fragment>
              <span style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  style={hasSubAccounts ? { ...mainRowActionButtonStyle, marginRight: 4 } : mainRowActionButtonStyle}
                  onClick={() => {
                    const fundingBalance = hasSubAccounts ? masterSubaccountBalance : row.original.balance;
                    setCreateSubAccountModel({
                      show: true,
                      initialAccountState: {
                        accountId: row.original.accountId,
                        balance: fundingBalance,
                      },
                    });
                  }}
                >
                  Create SubAccount
                </Button>
                {hasSubAccounts && (
                  <Button
                    style={mainRowActionButtonStyle}
                    onClick={() => {
                      setUpdateDistributionRatioModel({
                        show: true,
                        initialAccountState: {
                          AccountId: row.original.accountId,
                        },
                      });
                    }}
                  >
                    Update Distributions
                  </Button>
                )}
              </span>
            </Fragment>
          );
        },
      },
    ],
    [hasSubAccounts, masterSubaccountBalance, subAccountBalances, balance, accountStatus, accountFlexibilityType]
  );

  const renderSubRow = useCallback((row: any, index) => {
    return (
      <tr>
        <td colSpan={7}>
          <SubAccountManagementSubRow>{row}</SubAccountManagementSubRow>
        </td>
      </tr>
    );
  }, []);

  return (
    <div>
      <BlockTable
        tableKey="subAccountManagement"
        frozenHeaders={true}
        columns={columns}
        renderSubRow={renderSubRow}
        firstRowExpanded={true}
        data={[
          {
            accountId: accountId,
            accountNumber: accountNumber,
            accountName: accountName ? accountTitle : accountNickname,
            balance: balance,
          },
        ]}
        columnWidths={['15%', '15%', '5%', '10%', '10%']}
      />
      {createSubAccountModel.show && (
        <CreateSubAccountModel
          show={createSubAccountModel.show}
          initialAccountState={createSubAccountModel.initialAccountState}
          setShow={setShowCreateAccountModalCallback}
        />
      )}
      {updateDistributionRatioModel.show && (
        <UpdateDistributionsSubAccountModel
          show={updateDistributionRatioModel.show}
          initialAccountState={updateDistributionRatioModel.initialAccountState}
          setShow={setShowUpdateDistributionRatioModalCallback}
        />
      )}
    </div>
  );
};

const mainRowActionButtonStyle = {
  marginTop: '5px',
  marginBottom: '5px',
  fontSize: '10px',
  borderRadius: '5px',
  backgroundColor: 'var(--green)',
};
