import React, { useContext, useMemo } from 'react';
import _ from 'lodash';
import { FeedQuote, IFeedSpread, ConnectionStatusType, FeedOrderUpdate, FeedSettlement } from '@tradingblock/types';
import { FeedProviderStatus } from '../../types';
import dayjs from 'dayjs';
import { SymbolQuote } from '@tradingblock/api/src/types';

export interface TradingblockWebsocketState {
  status?: FeedProviderStatus;
  quotes: { [key: string]: FeedQuote };
  quoteQueue: FeedQuote[];
  orders: {
    [orderId: string]: { status: FeedOrderUpdate; history: FeedOrderUpdate[] };
  };
  lastMessage: string;
}

/// Redux state object that we use to create data for FeedDataState
// List<Spread> req = new List<Spread>();

//             Spread spread = new Spread();
//             spread.Type = SpreadType.Vertical;
//             spread.Legs = new Leg[2];

//             Leg leg = new Leg();
//             leg.Symbol = ".IWM 190215C134000";
//             leg.AssetType = enumAssetType.Option;

//             spread.Legs[0] = leg;

//             leg = new Leg();
//             leg.Symbol = ".IWM 190215C139000";
//             leg.AssetType = enumAssetType.Option;

//             spread.Legs[1] = leg;

//             req.Add(spread);

export interface FeedState {
  quotes: string[];
  spreads: IFeedSpread[];
  connectionStatus?: ConnectionStatusType;
}

export type FeedActions =
  | {
    type: 'setStatus';
    payload: FeedProviderStatus;
  }
  | {
    type: 'setQuote';
    payload: { quote: FeedQuote; raw: string };
  }
  | {
    type: 'dropFromQueue';
    payload: { count: number };
  }
  | {
    type: 'receivedOrderUpdate';
    payload: { order: FeedOrderUpdate; raw: string };
  }
  | {
    type: 'setSettlement';
    payload: { settlement: FeedSettlement; raw: string };
  };

export const FeedReducer = (state: TradingblockWebsocketState, action: FeedActions): TradingblockWebsocketState => {
  switch (action.type) {
    case 'setStatus':
      return {
        ...state,
        status: action.payload,
      };
    case 'dropFromQueue': {
      return {
        ...state,
        quoteQueue: _.drop(state.quoteQueue, action.payload.count),
      };
    }
    case 'setQuote': {
      const { quote, raw } = action.payload;
      const existingQuote = state.quotes[quote.Symbol] || {};
      const combinedQuoteValue = _.mergeWith({}, existingQuote, quote, (a, b) =>
        _.isNil(b) || _.isNaN(b) ? a : undefined
      );
      return {
        ...state,
        quotes: {
          ...state.quotes,
          [quote.Symbol]: combinedQuoteValue,
        },
        quoteQueue: [...state.quoteQueue, combinedQuoteValue],
        lastMessage: raw,
      };
    }
    case 'setSettlement': {
      const { settlement, raw } = action.payload;
      const existingQuote = state.quotes[settlement.Symbol] || {};
      const combinedQuoteValue = _.mergeWith({}, existingQuote, settlement, (a, b) =>
        _.isNil(b) || _.isNaN(b) ? a : undefined
      );
      // Set the quotes Last Trade Price to be from the settlement close price sent over
      combinedQuoteValue.LastTradePrice = settlement.ClosePrice;
      return {
        ...state,
        quotes: {
          ...state.quotes,
          [settlement.Symbol]: combinedQuoteValue,
        },
        quoteQueue: [...state.quoteQueue, combinedQuoteValue],
        lastMessage: raw,
      };
    }
    case 'receivedOrderUpdate': {
      const { order, raw } = action.payload;
      const { OrderId } = order;
      const existing = state.orders[OrderId] || { status: order, history: [] };
      return {
        ...state,
        orders: {
          ...state.orders,
          [OrderId]: {
            ...existing,
            status: order,
            history: [...existing.history, order],
          },
        },
        lastMessage: raw,
      };
    }

    default:
      return state;
  }
};

export const FeedInitialState: TradingblockWebsocketState = {
  quoteQueue: [],
  quotes: {},
  orders: {},
  lastMessage: '',
};

export const FeedActionContext = React.createContext<React.Dispatch<FeedActions>>(() => { });
export const FeedStateContext = React.createContext<TradingblockWebsocketState>(FeedInitialState);
export const FeedSendContext = React.createContext<{
  send(data: string | ArrayBufferLike | Blob | ArrayBufferView): void;
}>({ send: () => { } });

export const getFeedActions = (dispatch: React.Dispatch<FeedActions>) => {
  const setStatus = (status: FeedProviderStatus) => dispatch({ type: 'setStatus', payload: status });
  return {
    setStatus,
    onQuote: (quote: FeedQuote, raw: string) => dispatch({ type: 'setQuote', payload: { quote, raw } }),
    onOrderUpdate: (order: FeedOrderUpdate, raw: string) => dispatch({ type: 'receivedOrderUpdate', payload: { order, raw } }),
    onSettlement: (settlement: FeedSettlement, raw: string) => dispatch({ type: 'setSettlement', payload: { settlement, raw } }),
    dropFromQueue: (count: number) => dispatch({ type: 'dropFromQueue', payload: { count } }),
  };
};

export const useFeedActions = () => {
  const dispatch = useContext(FeedActionContext);
  return useMemo(() => getFeedActions(dispatch), [dispatch]);
};
