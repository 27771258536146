import React, { useCallback, useMemo, useEffect, useState, SetStateAction, Dispatch, FC, useRef } from 'react';
import _ from 'lodash';
import { Modal, Button } from 'react-bootstrap';

import { useInput, BasicSelect, OptionType, ColumnWithSorting, Tooltip, SelectDropdownInput } from '@tradingblock/components';
import { CashieringSearchEntityType, TransferStates, TransferState, CashieringBaseEntity, AccountStatus, AccountStatuses } from '@tradingblock/types';
import { useCashieringActions, useCashieringData } from '../useCashiering';
import { Timeframe } from '../../../../components/basic/Timeframe';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { isFetchingCashieringSearchEntities } from '../../../../data/global/selectors/admin/cashieringSelectors';
import { useDispatcher } from '../../../../data/global/hooks';
import dayjs from 'dayjs';
import { exportToCsv, formatMoney } from '../../../../blocks/Analytics/AnalyticsHelper';
import { AdminCashieringActions, AdminCashieringSearchActions } from '../../../../data/global/actions/admin/AdminCashieringActions';

const csvMainActionButtonContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '15px',
  paddingBottom: '15px',
  border: 'white',
  borderColor: 'var(--blue)',
  borderStyle: 'solid',
  borderWidth: '1px',
  marginTop: '20px',
  marginBottom: '20px',
  paddingLeft: '10px',
  paddingRight: '10px',
  borderRadius: '10px',
  width: '100%',
} as object

const csvMainActionButtonStyle = {
  display: 'flex',
  fontWeight: 'bold',
  alignItems: 'center',
  marginBottom: '5px',
} as object;

const centerStyle = {
  borderRadius: '0px',
  display: 'flex',
  paddingBottom: '1rem',
  flexDirection: 'row',
  justifyContent: 'center',
} as object;

const downloadCSVButtonStyle = {
  width: '25%',
  margin: '0 auto',
  borderRadius: '10px',
} as object;

const checkboxListStyle = {
  padding: '0.3rem',
} as object

const checkboxStyle = {
  transform: 'scale(1.5, 1.5)',
  marginLeft: 'revert',
  marginRight: '0.8rem',
} as object;

const DISPLAY_NAMES = {
  accountnumber: "Account Number",
  accountname: "Account Name",
  repcode: "Rep code",
  accountstatus: "Account status",
  transaction: "Transaction",
  method: "Method",
  banksignatory: "Bank Signatory",
  transferamount: "Transfer Amount",
  transferdirection: "Transfer Direction",
  date: "Date",
  status: "Status"
}

interface CashieringDownloadCSVModalProps {
  show: boolean,
  setShow: Dispatch<SetStateAction<boolean>>,
  columns: JSX.Element[],
  total: number,
  downloadAction: any,
  setIncludeList: Dispatch<SetStateAction<number[]>>,
  includeList: number[],
}

const CashieringDownloadCSVModal: FC<CashieringDownloadCSVModalProps> = ({
  show,
  setShow,
  columns,
  downloadAction,
  setIncludeList,
  includeList,
}) => {

  const toggleShow = useCallback((shouldShow?: boolean) => {
    const showVal = _.isNil(shouldShow) ? !show : shouldShow;
    setShow(showVal);
  }, [show]);

  const handleHide = useCallback(() => {
    toggleShow(false);
    setIncludeList(includeList.map((_val: number) => _val = 0));
  }, []);

  const handleSelectAll = () => setIncludeList(includeList.map((val: number) => val = 1));

  // clean up for modal
  useEffect(() => {
    return () => {
      setShow(false)
      setIncludeList(includeList.map((_val: number) => _val = 0));
    }
  }, [])

  return (
    <>
      <Modal onHide={handleHide} show={show}>
        <Modal.Header closeButton>
          <Modal.Title>CSV download</Modal.Title>
        </Modal.Header>
        <br />
        <>{columns}</>
        <button className='btn btn-dark' style={centerStyle} onClick={handleSelectAll}>
          <span style={downloadCSVButtonStyle}>
            <br />
            <i className="fal fa-check-double fa-x" />&nbsp; Select All
          </span></button>
        <button className='btn btn-dark' style={centerStyle} disabled={!includeList.find((val => val !== 0))} onClick={downloadAction}>
          <span style={downloadCSVButtonStyle}>
            <br />
            <i className="fal fa-file-excel fa-2x" />
            &nbsp; Download
          </span>
        </button>
        <Modal.Footer>
          <Button variant="primary" onClick={handleHide}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

// prettier-ignore
export const typeOptions: OptionType<CashieringSearchEntityType | undefined>[] = [
  { value: 'All', label: 'All', data: undefined },
  { value: 'ACH Relationship', label: 'ACH Relationship', data: CashieringSearchEntityType.AchRelationship },
  { value: 'Transfer', label: 'Transfer', data: CashieringSearchEntityType.Transfer, },
  { value: 'Transfer Instructions', label: 'Transfer Instructions', data: CashieringSearchEntityType.TransferWireInstruction },
];

// add a space between capitalized words
export const statusOptions: OptionType<TransferState | undefined>[] = _(TransferStates)
  .map((s: TransferState): OptionType<TransferState | undefined> => ({ value: s, label: s, data: s }))
  .union([{ value: 'All', label: 'All', data: undefined }])
  .value()
  .map(o => ({ ...o, label: o.label.replace(/([a-z])([A-Z])/g, '$1 $2') }));

// Place 'All' option at the top of the list
const allOption = statusOptions.find(o => o.value === 'All');
if (allOption) {
  statusOptions.splice(statusOptions.indexOf(allOption), 1);
  statusOptions.unshift(allOption);
}

const accountStatusOptions = AccountStatuses
  .map((accountStatus: AccountStatus): OptionType<AccountStatus> => (
    {
      value: accountStatus,
      label: accountStatus.replace(/([a-z])([A-Z])/g, '$1 $2'),
      data: accountStatus
    }
  ));

type CashieringFiltersProps = {
  handleSetSelectedRow: (index: number | undefined) => void;
  CashieringAdminReviewColumns: ColumnWithSorting<CashieringBaseEntity>[];
};

export const CashieringFilters: React.FC<CashieringFiltersProps> = ({ handleSetSelectedRow, CashieringAdminReviewColumns }) => {
  const { search, repCode, timeframe, types, status, data, total, accountStatus } = useCashieringData();
  const { search: onSearch, repCode: onRepCodeSearch, setTimeframe, setType, setStatus, setAccountStatus } = useCashieringActions();
  const [isFetching, hasData] = useStateSelector(isFetchingCashieringSearchEntities);

  const [repCodeSearchSubmitted, setRepCodeSearchSubmitted] = useState<boolean>(false);

  const onRepCodeSearchSubmit = useCallback(
    (repCode: string) => {
      if (repCode.trim().length === 0) {
        onRepCodeSearch(undefined);
        setRepCodeSearchSubmitted(false);
      } else {
        onRepCodeSearch(repCode.trim());
        setRepCodeSearchSubmitted(true);
      }
    },
    [repCode]
  );

  const [repCodeValue, setRepCodeValue, repCodeSearchInputProps] = useInput(undefined, { onEnter: onRepCodeSearchSubmit });

  const onClearRepCodeSearch = useCallback(() => {
    onRepCodeSearchSubmit('');
    setRepCodeValue('');
    sessionStorage.removeItem('cashieringSearch');
    handleSetSelectedRow(undefined);
  }, []);

    useEffect(() => {
    // if repCode is present but there is no repCodeValue update the repCodeValue
    if (repCode && _.isUndefined(repCodeValue)) {
      setSearchValue(repCode);
    }
    // if repCode is present and repCodeValue is an empty string update the repCodeValue
    if (repCode && repCodeValue === '') {
      setSearchValue(repCode);
    }
  }, [repCode]);

  useEffect(() => {
    // if there is a repCode value but the icon for clearing is missing... (using the clear button's inverted logic)
    if (repCodeValue && (!repCodeSearchSubmitted && !currentRepCode)) {
      // force a repCode
      setRepCodeValue(searchValue.trim());
    }
  }, [repCodeSearchSubmitted]);

  const currentRepCode = useMemo(() => {
    if (!_.isUndefined(repCodeValue)) {
      return repCodeValue;
    }
    return repCode || '';
  }, [repCode, repCodeValue]);

  const [searchSubmitted, setSearchSubmitted] = useState(false);

  const onSearchSubmit = useCallback(
    (search: string) => {
      if (search.trim().length === 0) {
        onSearch(undefined);
        setSearchSubmitted(false);
      } else {
        onSearch(search.trim());
        setSearchSubmitted(true);
      }
    },
    [search]
  );

  const [searchValue, setSearchValue, inputProps] = useInput(undefined, { onEnter: onSearchSubmit });

  const onClearSearch = useCallback(() => {
    onSearchSubmit('');
    setSearchValue('');
    sessionStorage.removeItem('cashieringSearch');
    handleSetSelectedRow(undefined);
  }, []);

  useEffect(() => {
    // if there is a search value but the icon for clearing is missing... (using the clear button's inverted logic)
    if (searchValue && (!searchSubmitted && !currentSearch)) {
      // force a search
      setSearchValue(searchValue.trim());
    }
  }, [searchSubmitted]);

  useEffect(() => {
    // if search is present but there is no searchValue update the searchValue
    if (search && _.isUndefined(searchValue)) {
      setSearchValue(search);
    }
    // if search is present and searchValue is an empty string update the searchValue
    if (search && searchValue === '') {
      setSearchValue(search);
    }
  }, [search]);

  const currentSearch = useMemo(() => {
    if (!_.isUndefined(searchValue)) {
      return searchValue;
    }
    return search || '';
  }, [search, searchValue]);

  const currentType = useMemo(() => {
    const firstType = _.first(types);
    if (firstType) {
      return firstType;
    }
    const defaultType = _.first(typeOptions);
    return defaultType ? defaultType.label : '';
  }, [
    types,
    typeOptions,
    currentSearch,
    searchSubmitted,
    isFetchingCashieringSearchEntities,
    searchValue,
    search,
    searchSubmitted,
  ]);

  const currentStatus = useMemo(() => {
    const firstStatus = _.first(status);
    if (firstStatus) {
      return firstStatus;
    }
    const defaultStatus = _.first(statusOptions);
    return defaultStatus ? defaultStatus.label : '';
  }, [
    status,
    statusOptions,
    currentSearch,
    searchSubmitted,
    isFetchingCashieringSearchEntities,
    searchValue,
    search,
    searchSubmitted,
  ]);

  const onTimeframeChange = useCallback((startDate: Date | undefined, endDate: Date | undefined) => {
    if (_.isNil(startDate)) {
      setTimeframe(undefined, undefined);
    } else if (_.isNil(endDate)) {
      setTimeframe(startDate, undefined);
    } else {
      setTimeframe(startDate, endDate);
    }
  }, []);
  const onTypeChange = useCallback((val: OptionType | OptionType[] | null | undefined) => {
    const types = _.isNil(val) ? [] : _.isArray(val) ? val : [val];
    setType(!types || (types && types[0].data === undefined) ? undefined : [types && types[0].data]);
    sessionStorage.removeItem('cashieringSearch');
    handleSetSelectedRow(undefined);
  }, []);
  const onStatusChange = useCallback((val: OptionType | OptionType[] | null | undefined) => {
    const values = _.isNil(val) ? [] : _.isArray(val) ? val : [val];
    setStatus(!values || (values && values[0].data === undefined) ? undefined : [values && values[0].data]);
    sessionStorage.removeItem('cashieringSearch');
    handleSetSelectedRow(undefined);
  }, []);

  const [showSearchDate, setShowSearchDate] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [searchByDateRange, setSearchByDateRange] = useState<boolean>(false);

  const dateRef = useRef([startDate, endDate])

  const { dispatch } = useDispatcher();

  //Search total 
  useEffect(() => {
    if (startDate && endDate && searchByDateRange) {
      dispatch(AdminCashieringActions.setTimeframe({ startDate: dateRef.current[0], endDate: dateRef.current[1] }));

      setSearchByDateRange(false);
    }
  }, [endDate, startDate, timeframe, dateRef.current, searchByDateRange])

  const handleDateInput = (event: any) => {
    if (event.target.name === 'start') {
      dateRef.current[0] = event.target.value;
      setStartDate(dateRef.current[0]);
    }
    else if (event.target.name === 'end') {
      dateRef.current[1] = event.target.value;
      setEndDate(event.target.value);
    }
  }

  const handleReset = (event: any) => {
    if (event.target.name === 'reset') {
      dateRef.current = [undefined, undefined];
      dispatch(AdminCashieringActions.setTimeframe({ startDate: dayjs(new Date().toDateString()).subtract(1, 'week') as any, endDate: new Date() }))
      dispatch(AdminCashieringSearchActions.setPageSize({ pageSize: 50 }));
    }
  }

  const resetDateButtonStyle = {
    textDecoration: 'underline',
    marginBottom: '20px',
    opacity: (!startDate || !endDate) ? 0.3 : 1,
    cursor: (!startDate || !endDate) ? 'not-allowed' : 'inherit'
  } as object

  // Export CSV
  const downloadCSV: () => void = () => {
    // Map bool array to the cashiering column order
    const selectionList = includeList.map((num, index) => num && CashieringAdminReviewColumns[index].id === CashieringAdminReviewColumns[index].id ? CashieringAdminReviewColumns[index].id : '');
    const csvData: ({ [DISPLAY_NAMES: string]: (string | Date)[] }) = {
      //to add a column for csv, add here each prop in csvData is a [header name -> column] combination
      //because the data is serialized and chunked later by prop length
      //sequence is the only thing that we will need to calculate
      [DISPLAY_NAMES.accountnumber]: [],
      [DISPLAY_NAMES.accountname]: [],
      [DISPLAY_NAMES.repcode]: [],
      [DISPLAY_NAMES.transaction]: [],
      [DISPLAY_NAMES.status]: [],
      [DISPLAY_NAMES.method]: [],
      [DISPLAY_NAMES.date]: [],
      [DISPLAY_NAMES.accountstatus]: [],
      [DISPLAY_NAMES.banksignatory]: [],
      [DISPLAY_NAMES.transferamount]: [],
      [DISPLAY_NAMES.transferdirection]: []
    };

    // Build CSV data
    data.forEach((row) => {
      Object.entries(row).map(([key, value]) => {
        const lowerKey = key.toLowerCase();
        if (lowerKey === 'accountnumber' && selectionList.find(val => val && val.toLowerCase() === 'accountnumber')) csvData[DISPLAY_NAMES.accountnumber].push(value);
        if (lowerKey === 'accountname' && selectionList.find(val => val && val.toLowerCase() === 'accountname')) csvData[DISPLAY_NAMES.accountname].push(value);
        if (lowerKey === 'repcode' && selectionList.find(val => val && val.toLowerCase() === 'repcode')) csvData[DISPLAY_NAMES.repcode].push(row.repCode.toString());
        if (lowerKey === 'accountstatus' && selectionList.find((val) => val && val.toLowerCase() === 'accountstatus')) csvData[DISPLAY_NAMES.accountstatus].push(value);
        if (lowerKey === 'date' && selectionList.find(val => val && val.toLowerCase() === 'date')) csvData[DISPLAY_NAMES.date].push(new Date(value).toLocaleDateString());
        if (lowerKey === 'type' && selectionList.find(val => val && val.toLowerCase() === 'transaction')) {
          csvData[DISPLAY_NAMES.transaction].push(row.type);
          csvData[DISPLAY_NAMES.status].push(row.status);
        }
        if (selectionList.find(val => val && val.toLowerCase() === 'method')) {
          if (lowerKey === 'transfer' && row.transfer) {
            csvData[DISPLAY_NAMES.method].push("Method: " + row.transfer.mechanism + " " + row.transfer.text);
            csvData[DISPLAY_NAMES.transferamount].push(formatMoney(row.transfer.amount));
            csvData[DISPLAY_NAMES.transferdirection].push(row.transfer.direction === 'Outgoing' ? 'WITHDRAWAL' : 'DEPOSIT');
            if (row.type === 'AchRelationship') csvData[DISPLAY_NAMES.banksignatory].push(row.transfer.bankAccountOwnerNameSecret);
            else csvData[DISPLAY_NAMES.banksignatory].push('undefined');
          } else if (lowerKey === 'relationship' && row.relationship) {
            csvData[DISPLAY_NAMES.method].push("Method: " + row.relationship.approvalMethod + " " + row.relationship.text);
            csvData[DISPLAY_NAMES.transferamount].push('undefined');
            csvData[DISPLAY_NAMES.transferdirection].push('undefined');
            if (row.type === 'AchRelationship') csvData[DISPLAY_NAMES.banksignatory].push(row.relationship.bankAccountOwnerNameSecret);
            else csvData[DISPLAY_NAMES.banksignatory].push('undefined');
          } else if (lowerKey === 'transferwireinstruction' && row.transferWireInstruction) {
            csvData[DISPLAY_NAMES.method].push("Method: " + row.transferWireInstruction.text);
            csvData[DISPLAY_NAMES.transferamount].push('undefined');
            csvData[DISPLAY_NAMES.transferdirection].push('undefined');
            csvData[DISPLAY_NAMES.banksignatory].push('undefined');
          }
        }
        else return
      });
    });
    exportToCsv(csvData, undefined, 'cashieringTable');
  };

  // Record all column names 
  const columnNames = CashieringAdminReviewColumns.map((col: ColumnWithSorting<CashieringBaseEntity>) => col.id as string).map((value) => {
    if (value === 'AccountName') return DISPLAY_NAMES.accountname;
    else if (value === 'AccountNumber') return DISPLAY_NAMES.accountnumber
    else if (value === 'RepCode') return DISPLAY_NAMES.repcode
    else if (value === 'accountStatus') return DISPLAY_NAMES.accountstatus
    else return value
  })
  // Filter column choices prior to this for consistent index references.
  const excludedColumnsById = ['note', 'action', 'filter'];
  // Calculate inclusions with checkboxes -> EX:[0, 1] -> exclude table header 1 include table header 2
  const [includeList, setIncludeList] = useState<number[]>(Array(CashieringAdminReviewColumns.length).fill(0));
  const [show, setShow] = useState<boolean>(false);
  const updateIncludeList = (index: number) => setIncludeList((includeList as any).toSpliced(index, 1, (includeList[index] === 0 ? 1 : 0)))
  // Return inputs that will in-place manipulate the final included header list as an encoded boolean array
  const recordedModalColumns = useMemo(() => {
    return columnNames.map((col: string, index: number) =>
      !excludedColumnsById.find((val) => val === col) ?
        <><div style={checkboxListStyle} key={index}>
          <input checked={!!includeList[index]} onChange={() => updateIncludeList(index)} style={checkboxStyle} type='checkbox' />{_.capitalize(col)}</div><hr /></> : <></>)
  }, [includeList])

  useMemo(() => {
    setIncludeList(includeList.map(_val => (_val = 0)));
  }, [isFetching]);

 const onAccountStatusChange = useCallback((val: AccountStatus[]) => {
    setAccountStatus(!val || (val && val.length === 0) ? undefined : val);
    sessionStorage.removeItem('cashieringSearch');
    handleSetSelectedRow(undefined);
  }, []);

  return (
    <div className="cashiering-filters d-flex flex-direction-col align-items-flex-start">
      <div className="mb-20px d-flex flex-direction-col w-100">
        <div className="d-flex w-100 align-items-center mb-20px cashiering-filters-tooltip-wrapper">
          <Tooltip
            content="You can search for account number, account title, account name, or notes"
            id="search-tooltip"
            effect="solid"
            place="top"
          >
            <input
              className="input input-dark input-tall w-100"
              type="text"
              placeholder={'Search...'}
              value={currentSearch}
              {...inputProps}
            />
          </Tooltip>
          {currentSearch && (
            <i
              className="fa fa-times fa-2x"
              onClick={onClearSearch}
              style={{ color: 'var(--blue)', cursor: 'pointer', paddingLeft: '0.32em' }}
            />
          )}
        </div>
        <div className="d-flex w-100 align-items-center">
          <input
            className="input input-dark input-tall w-100"
            type="text"
            placeholder={'Search by rep code...'}
            value={currentRepCode}
            {...repCodeSearchInputProps}
          />
          {currentRepCode && (
            <i
              className="fa fa-times fa-2x"
              onClick={onClearRepCodeSearch}
              style={{ color: 'var(--blue)', cursor: 'pointer', paddingLeft: '0.32em' }}
              />
            )}
        </div>
      </div>
      <div className="d-flex flex-direction-col align-items-flex-start w-100">
        <div className="mb-20px w-100">
          <label htmlFor="cashiering-filters-timeframe">Date Range</label>
          <Timeframe
            initialStartDate={timeframe.startDate}
            initialEndDate={timeframe.endDate}
            btnClassName="btn-dark btn-tall dropdown-toggle text-left position-relative w-100"
            placeholderClassName="select__placeholder"
            caret={<>&nbsp;&nbsp;</>}
            onSave={onTimeframeChange}
            buttonId="cashiering-filters-timeframe"
          />
        </div>

        <div className="d-flex mb-3">
          <input checked={!!showSearchDate} onChange={() => setShowSearchDate(!showSearchDate)} style={{ ...checkboxStyle }} type='checkbox' />
          <strong>Search by date</strong>
        </div>
        {showSearchDate &&
          (<span>
            <span>
              <input
                style={{
                  color: 'ghostwhite',
                  backgroundColor: 'var(--blue-dark-lighter)',
                  marginBottom: '10px',
                  marginTop: '10px'
                }}
                className="input input-tall"
                value={dateRef.current[0] ? dateRef.current[0] as Date : '' as any}
                onChange={(e) => handleDateInput(e)}
                id='csv_StartDate'
                name='start'
                type='date'
                required
              />
              &nbsp; Start Date
            </span>
            <span>
              <br />
              <input
                style={{
                  color: 'ghostwhite',
                  backgroundColor: 'var(--blue-dark-lighter)'
                }}
                className="input input-tall"
                value={dateRef.current[1] ? dateRef.current[1] as Date : '' as any}
                onChange={(e) => handleDateInput(e)}
                id='csv_EndDate'
                name='end'
                type='date'
                required
              />
              &nbsp; End Date
            </span>
            &nbsp;
            <br />
            <Button onClick={() => setSearchByDateRange(true)} style={{ width: 'max-content', marginTop: '10px' }} color='var(--green)' variant='primary'>Search</Button>
            <hr />
          </span>
        )}
        <button disabled={!startDate || !endDate} style={resetDateButtonStyle} onClick={(e) => handleReset(e)} id='reset' name='reset'>Reset date search</button>

        <div className="w-100 mb-20px cashiering-filters-select-dropdown-input">
          <SelectDropdownInput
            isMulti
            options={accountStatusOptions}
            placeholder="Account Status"
            name="account-status-select-input"
            value={accountStatus || []}
            onchange={(_e, value) => onAccountStatusChange(value)}
          />
        </div>

        <div className="mb-20px w-100 cashiering-filters-filter-dropdown">
          <label htmlFor="transaction-type-select-input">Transaction Type</label>
          <BasicSelect
            onChange={onTypeChange}
            placeholder={currentType}
            className="btn btn-dark btn-tall dropdown-toggle text-left w-100 p-0"
            options={typeOptions}
            isClearable={false}
            value={currentType}
            inputId="transaction-type-select-input"
          />
        </div>

        <div className="cashiering-filters-filter-dropdown w-100 mb-20px">
          <label htmlFor="transaction-status-select-input">Transaction Status</label>
          <BasicSelect
            onChange={onStatusChange}
            placeholder={currentStatus}
            className="btn btn-dark btn-tall dropdown-toggle text-left w-100 p-0"
            options={statusOptions}
            isClearable={false}
            value={currentStatus}
            inputId="transaction-status-select-input"
          />
        </div>



        {hasData && (
          <button onClick={() => setShow(true)} style={csvMainActionButtonContainerStyle}>
            <span style={csvMainActionButtonStyle}>
              CSV Download &nbsp;
              <i className="fal fa-file-excel fa-2x" />
            </span>
            <span>{data.length + ' rows selected.'}</span>
          </button>
        )}
        {show && hasData && (
          <CashieringDownloadCSVModal
            downloadAction={downloadCSV}
            columns={recordedModalColumns}
            show={show}
            total={total}
            setShow={setShow}
            setIncludeList={setIncludeList}
            includeList={includeList}
          />
        )}
      </div>
    </div>
  );
};
