import { Action, PayloadMetaAction } from 'typesafe-actions';
import _ from 'lodash';
import {
  IStorageProvider,
  HttpProviderConfig,
  PayloadAction,
  StorageBlockPath,
  IBlockState,
  IBlockDataState,
} from '@tradingblock/types';
import { useLocalStorage, TokenFromStorage } from '@tradingblock/storage';
import { useDebug } from '@tradingblock/components';
import { isString } from '@tradingblock/api';
import { useStore, StorageProviderFactory } from '../../../context/Storage';
import { Config } from '../../../config';
import { useAuthenticationToken } from '../../../data/global/dataSelectors';

// function isPersistAction<P, T extends PayloadAction<P>>(action: Action | T | PersistAction<P>): action is PersistAction<P> {
//   const payload = _.get(action, 'payload', undefined);
//   if (!payload) {
//     return false;
//   }
//   const options = _.get(payload, 'options', undefined);
//   const persist = _.get<boolean | undefined>(options, 'persist', undefined);
//   return options && _.isBoolean(persist) ? persist : false;
// }

function isMetaAction<T extends string, P = any, M = any>(
  action: PayloadAction<T> | Action | PayloadMetaAction<T, P, M>
): action is PayloadMetaAction<T, P, M> {
  const isStrType = isString(_.get(action, 'type', undefined));
  const hasPayloadAndMeta =
    _.isNil(_.get(action, 'payload', undefined)) === false && _.isNil(_.get(action, 'meta', undefined)) === false;
  // this function always returns true without checking variables above!
  return true;
}

function shouldPersistAction<T extends string, P = any>(action: PayloadAction<T> | Action<string>) {
  const payload = _.get(action, 'payload', undefined);
  if (_.isNil(payload)) {
    return false;
  }
  const options = _.get(payload, 'options', undefined);
  const persist = _.get<boolean | undefined>(options, 'persist', undefined);
  const isPersistActionAndShouldPersist = options && _.isBoolean(persist) ? persist : false;
  if (isPersistActionAndShouldPersist) {
    return true;
  }
  // should we even be using isMetaAction since it always returns true?
  if (isMetaAction<T, P>(action)) {
    return _.get<boolean | undefined>(action.meta, 'persist', false) === true;
  }

  return false;
}
export type PrePersistFunc<S extends IBlockDataState> = (state: IBlockState<S>) => IBlockState<S>;

let lastSavedState: { [blockId: string]: any } = {};
// let lastSavedSettings: { [blockId: string]: any } = {};

const debouncedPersist = _.debounce(
  (storeApi: IStorageProvider, url: string, data: any) => (url ? storeApi.save(url, data) : undefined),
  1000
);
// const debouncedPersistSettings = _.debounce((storeApi: IStorageProvider, url: string, data: any) => (url ? storeApi.save(url, data) : undefined), 1000);
// const debouncedPersistGlobalSettings = _.debounce((storeApi: IStorageProvider, url: string, data: any) => (url ? storeApi.update(url, data) : undefined), 1000);

export function PersistMiddleware<S extends IBlockDataState>(blockId: string, prePersist?: PrePersistFunc<S>) {
  const log = useDebug('BlockPersist');
  const storage = useLocalStorage();
  return function<T, A extends Action>(store: { getState: () => T }) {
    return (next: (action: A) => any) => async (action: A) => {
      //const prevState: IBlockState<S> = store.getState() as any;
      //log(`received persist ${blockId} %o`, action);
      let result = next(action);
      const nextState: IBlockState<S> = store.getState() as any;
      const { data, settings, type } = prePersist ? prePersist(nextState) : nextState;
      // if (action.type === getType(BlockAction.replaceSettings) && !_.isEqual(lastSavedSettings[nextState.blockId], settings)) {
      //   debouncedPersistSettings(storeApi, StorageBlockSettingsPath(blockId), settings);
      //   lastSavedSettings[nextState.blockId] = settings;
      //   return result;
      // } else if (action.type === getType(BlockAction.replaceGlobalSettings) && !_.isEqual(lastSavedSettings[type], settings)) {
      //   debouncedPersistGlobalSettings(storeApi, StorageSettingsBlockTypeFieldPath(type), settings);
      //   lastSavedSettings[nextState.blockId] = settings;
      //   return result;
      // }

      //if (isPersistAction(action)) {
      const actionType = action.type;
      const shouldPersist = shouldPersistAction(action);
      const stateToSave = { ...data, settings, blockType: type };
      const statesAreEqual = _.isEqual(lastSavedState[nextState.blockId], stateToSave);
      if (shouldPersist && !statesAreEqual) {
        // only persist data after block loaded
        if (data.isLoaded !== false) {
          log(`${actionType} persisting block ${blockId} %o %o`, action, stateToSave);

          const authToken = await TokenFromStorage(storage);
          if (authToken) {
            const storage: IStorageProvider<HttpProviderConfig> = StorageProviderFactory(Config.uiApi, authToken);
            debouncedPersist(storage, StorageBlockPath(blockId), stateToSave);
          }

          lastSavedState[nextState.blockId] = stateToSave;
        }
        return result;
      }
      return result;
    };
  };
}
