import React, { useMemo } from 'react';
import { CashieringEntityStatus } from '../../../../components/cashiering/CashieringEntityStatus';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { wireInstructionSelectors } from '../../../../data/global/selectors/admin/cashieringSelectors';
import { CashieringEntityAuditItems } from './details/CashieringEntityAuditItems';

export const CashieringTransferInstructionStatus: React.FunctionComponent<{ id: number; accountId: number }> = ({
  id,
}) => {
  const wireInstruction = useStateSelector(s => wireInstructionSelectors.data(s, id));

  const wireInstructionState = useMemo(() => {
    return {
      state: wireInstruction && wireInstruction.state,
    };
  }, [wireInstruction]);

  if (!wireInstruction) {
    return null;
  }

  return (
    <div className="screen-body-section">
      <p className="fields-title">Review Status</p>

      <p>
        {wireInstruction.state && (
          <strong>
            <CashieringEntityStatus status={wireInstruction.state} />
          </strong>
        )}
      </p>

      <CashieringEntityAuditItems id={id} type="wireInstructions" />
    </div>
  );
};
