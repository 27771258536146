import React, { useCallback } from 'react';
import { Dropdown, DropdownItem, IDropdownActions, AddIcon } from '@tradingblock/components';
import { AssetType } from '@tradingblock/types';
import { Shares } from '@tradingblock/api';
import { useOrderData } from '../state/OrderState';
import { OrderSelectors } from '../state/OrderSelector';
import { useLegsActions } from '../state/useLegsActions';

export const AddOrderLeg: React.FC<{ maxLegs: number; isSymbolIndex: boolean }> = ({ maxLegs, isSymbolIndex }) => {
  const { add } = useLegsActions();
  const isCustomStrategyOrUndefined = useOrderData(OrderSelectors.isCustomStrategyOrUndefined);
  const canAddShares = useOrderData(o => o.validStrategies.some(s => s.info.Name === Shares.info.Name));
  const legIds = useOrderData(OrderSelectors.legIds);
  const maxReached = legIds.length >= maxLegs;
  const hasSharesLeg = useOrderData(OrderSelectors.hasSharesLeg);
  const onAdd = useCallback(
    (type: AssetType = AssetType.Option) => {
      if (maxReached) {
        console.error('maximum number of legs already reached');
      }
      add(type, { persist: true });
    },
    [add, maxReached]
  );
  const button = <></>;

  return (
    <>
      {!hasSharesLeg && !isSymbolIndex && (
        <Dropdown
          buttonOptions={{
            title: maxReached ? 'You can only add 4 legs' : 'Add to order',
            toggleButtonClassName: `btn btn-blend ${maxReached ? 'disabled' : ''}`,
          }}
          direction="down"
          disabled={maxReached || !isCustomStrategyOrUndefined}
          content={<AddIcon size="2x" inverse />}
          dropdownContent={(actions: IDropdownActions) => {
            const onItemClick = (at: AssetType) => () => {
              onAdd(at);
              actions.toggleDropdown();
            };
            return (
              <>
                {canAddShares && <DropdownItem onClick={onItemClick(AssetType.Equity)}>Add shares leg</DropdownItem>}
                <DropdownItem onClick={onItemClick(AssetType.Option)}>Add option leg</DropdownItem>
              </>
            );
          }}
        />
      )}

      {(hasSharesLeg || isSymbolIndex) && (
        <button
          className="btn btn-blend"
          title={maxReached ? 'You can only add 4 legs' : 'Add option leg'}
          onClick={() => onAdd(AssetType.Option)}
          disabled={maxReached || !isCustomStrategyOrUndefined}
          type="button"
        >
          <AddIcon size="2x" inverse />
        </button>
      )}
    </>
  );
};
