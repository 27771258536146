import { OptionChainSettings } from '@tradingblock/types';

export const DefaultOptionChainBlockSettings: OptionChainSettings = {
  bidUsesBuyOrderAction: true,
  strikeCount: 10,
  Bid: true,
  Ask: true,
  Last: true,
  Change: true,
  Close: true,
  Delta: true,
  Theta: true,
  Gamma: true,
  Volume: true,
  IV: true,
  weeklyExpirations: true,
  monthlyExpirations: true,
  quarterlyExpirations: true,
  yearlyExpirations: true,
};
