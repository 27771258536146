import React from 'react';
import { TransferState, AchRelationshipState } from '@tradingblock/types';
import _ from 'lodash';

type EntityState = TransferState | AchRelationshipState;
export const StatusClassMap: Partial<{ [key in EntityState]: string }> = {
  Rejected: 'neg',
  CreateRejected: 'neg',
  Canceled: 'mute',
  Approved: 'pos',
  PendingRepCreateApproval: 'pending',
  PendingCreateApproval: 'pending',
  PendingFirmCreateApproval: 'pending',
  PendingFirmApproval: 'pending',
  Pending: 'pending',
  SentToBank: 'mute',
  Complete: 'pos',
  PendingPrinting: 'pending',
  Void: 'mute',
  Returned: 'mute',
  StopPayment: 'mute',
  Cancelled: 'neg',
};

export const CashieringEntityStatus: React.FC<{ status?: EntityState; className?: string; showWarning?: boolean }> = ({
  status,
  className,
  children,
  showWarning,
}) => {
  const statusClass = status ? StatusClassMap[status] || '' : '';
  return (
    <>
      <span className={`${statusClass} ${className}`}>{children || _.startCase(status)}</span>
      {statusClass === 'pending' && showWarning && (
        <>
          <br />

          <span className="txt-sm warn" style={{ fontStyle: 'italic' }}>
            Legacy CTS relationship with same bank information found for this account.
          </span>
        </>
      )}
    </>
  );
};
