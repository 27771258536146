import React from 'react';
import { useStateSelector } from '../../data/global/dataSelectors';
import { wireInstructionSelectors } from '../../data/global/selectors/admin/cashieringSelectors';

export const CashieringEntityDetails: React.FC<{
  id: number;
}> = ({ id }) => {
  const transferInstruction = useStateSelector(s => wireInstructionSelectors.data(s, id || 0));

  if (!transferInstruction) {
    return null;
  }

  return (
    <p>
      Bank account number
      <br />
      <span className="mute">{transferInstruction.account}</span>
      {transferInstruction.intermediary && (
        <>
          <br />
          <br />
          Intermediary account number
          <br />
          <span className="mute">{transferInstruction.intermediary.account}</span>
          <br />
          <br />
          Intermediary information
          <br />
          <span className="mute">
            {transferInstruction.intermediary.name}
            <br />
            {transferInstruction.intermediary.streetAddress1 && (
              <>
                {transferInstruction.intermediary.streetAddress1}
                <br />
              </>
            )}
            {transferInstruction.intermediary.streetAddress2 && (
              <>
                {transferInstruction.intermediary.streetAddress2}
                <br />
              </>
            )}
            {transferInstruction.intermediary.city}
            {transferInstruction.intermediary.stateProvince
              ? ', ' + transferInstruction.intermediary.stateProvince
              : ''}{' '}
            {transferInstruction.intermediary.postalCode}
            <br />
            {transferInstruction.intermediary.country}
          </span>
        </>
      )}
    </p>
  );
};
