import { createSelector } from 'reselect';
import { DataState } from '../state';
import _ from 'lodash';
import { CashieringPendingStates } from '@tradingblock/types';

export const cashiering = {
  latestCashieringEvents: createSelector(
    (s: DataState) => s.cashiering.entities,
    entities => _.orderBy(entities, e => e.date)
  ),
  pendingEntitiesExist: createSelector(
    (s: DataState) => s.cashiering.entities,
    entities => (_.find(entities, e => CashieringPendingStates.includes(e.status)) === undefined ? false : true)
  ),
};
