import React from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Modal } from '@tradingblock/components';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { AccountManagementDataActions } from '../../../data/global/actions/AccountManagementActions';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';

export const RemoveTrustedContactOrTradeAuth: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
  type?: 'removeTrustedContact' | 'removeTradeAuthorization';
}> = ({ show, toggleModal, type }) => {
  const accountId = useStateSelector(s => s.accounts.account && s.accounts.account.AccountId);
  const dispatch = useDispatch();

  const onClick = () => {
    const updateValues = {
      item: type === 'removeTrustedContact' ? 'RemoveTrustedContact' : 'RemoveTradeAuthorization',
    };

    dispatch(AccountManagementDataActions.requestMakeAccountUpdate({ accountId, request: updateValues }));
    toggleModal();
  };

  const modalTitle = type === 'removeTrustedContact' ? 'Remove Trusted Contact?' : 'Remove Trade Authorization?';
  const modalBody =
    type === 'removeTrustedContact'
      ? 'Are you sure you want to remove this trusted contact?'
      : 'Are you sure you want to remove this trade authorization?';

  return (
    <Modal setShow={() => toggleModal()} show={show}>
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>{modalTitle}</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <p>{modalBody}</p>
      </BootstrapModal.Body>
      <BootstrapModal.Footer>
        <Button variant="secondary" onClick={() => toggleModal()}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onClick}>
          Remove
        </Button>
      </BootstrapModal.Footer>
    </Modal>
  );
};
