export var AssetSymbolUndefinedException = {
  error: "AssetSymbol was undefined",
  message: "AssetSymbol must have a value"
};
export var AccountIdException = {
  error: "Account is undefined",
  message: "Account is undefined"
};
export var ExpirationUndefinedException = {
  error: "Expiration was undefined",
  message: "Expiration must have a value"
};
export var toException = function toException(error, data) {
  return Object.assign({}, error, {
    data: data
  });
};