import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { NumberInput } from '@tradingblock/components';
import { useOrderData, OrderState } from '../../state/OrderState';
import { useOrderActions } from '../../state/useOrderActions';
import { useOrderSettings } from '../../hooks/useOrderSettings';
import { OrderSelectors } from '../../state/OrderSelector';

const OrderQuantitySelector = createCachedSelector(
  (s: OrderState, defaultOrderQuantity: any) => s.quantity,
  (s: OrderState, defaultOrderQuantity: any) => defaultOrderQuantity,
  (quantity: number, defaultQuantity: any) => {
    if (_.isNumber(quantity)) {
      return quantity;
    }
    if (_.isNumber(defaultQuantity)) {
      return defaultQuantity;
    }
    return 1;
  }
)(() => `OrderQuantity`);

export const useOrderQuantity = () => {
  const { setQuantity } = useOrderActions();
  const { defaultQuantityForOptionsAndStrategies } = useOrderSettings();
  const quantity = useOrderData((s: OrderState) => OrderQuantitySelector(s, defaultQuantityForOptionsAndStrategies));
  const setOrderQuantity = useCallback(
    (value: number) => {
      let val = value;
      if (val < 1) {
        val = 1;
      }
      setQuantity(val);
    },
    [setQuantity]
  );

  return { quantity, setOrderQuantity };
};

export const OrderQuantity: React.FC<{ id?: string }> = ({ id }) => {
  const { quantity, setOrderQuantity } = useOrderQuantity();
  const isSingleLeggedStrategy = useOrderData(OrderSelectors.isSingleLegStrategy);
  const { setLegQuantity } = useOrderActions();

  const onChange = (value: number) => {
    setOrderQuantity(value);
    if (isSingleLeggedStrategy && id) {
      setLegQuantity(id, value);
    }
  };

  return (
    <div className={`spinner text-left btn-block`}>
      <NumberInput min={1} value={quantity} precision={0} step={1} onChange={onChange} />
    </div>
  );
};
