import _ from 'lodash';
import { Expiration, ExpirationType, OptionExpirationType, AssetSymbol } from '@tradingblock/types';
import { isIndexSymbol } from '@tradingblock/api';

export function areArraysEqual<T = any>(a: T[], b: T[]) {
  if (_.isArray(a) && _.isArray(b)) {
    const equalRes = _.union(_.differenceWith(a, b, _.isEqual), _.differenceWith(b, a, _.isEqual));
    return _.isEmpty(equalRes);
  }
  return _.isEqual(a, b);
}

export function objectDiff<T>(obj1: T, obj2: T) {
  var diffKeys = Object.keys(obj1).filter(k => _.get(obj1, k, undefined) !== _.get(obj2, k, undefined));
  return { initial: _.pick(obj1, diffKeys), after: _.pick(obj2, diffKeys) };
}

export function isArrayEqual<T>(a: T, b: T, index: number) {
  if (_.isArray(a) && _.isArray(b)) {
    return areArraysEqual(a, b);
  }
  return _.isEqual(a, b);
}

export function toArray<T>(value: T) {
  const res: T[] = _.isNil(value) ? [] : _.isArray(value) ? value : [value];
  return res;
}

export function sumNumbers(values: number[]) {
  return _.sum(_.map(values, v => (_.isNaN(v) ? 0 : v)));
}

const getGCD = (a: number, b: number): number => {
  if (!b) {
    return a;
  }

  return getGCD(b, a % b);
};

// greatest common denominator
export function GCD(input: number[]) {
  if (toString.call(input) !== '[object Array]') return null;
  var len, a, b;
  len = input.length;
  if (!len) {
    return null;
  }
  a = input[0];
  for (var i = 1; i < len; i++) {
    b = input[i];
    a = getGCD(a, b);
  }
  return a;
}

export const getChangePercent = (total: number, change: number) => {
  // avoid div by zero
  if (total !== 0) {
    return (change / total) * 100;
  }
  return 0;
};

export const expirationDisplayInfo = (expiration: Expiration, assetSymbol?: AssetSymbol) => {
  const isPMExpiration = expiration.optionExpirationType === OptionExpirationType.PM;
  const symbolIsIndex = assetSymbol ? isIndexSymbol(assetSymbol.underlyingSymbol || '') : false;
  const isWeekly =
    expiration.ExpirationFreqCode === 'W' ||
    expiration.ExpirationFreqCode === 'MW' ||
    expiration.ExpirationFreqCode === 'WW' ||
    (expiration.ExpirationFreqCode && expiration.ExpirationFreqCode.includes('Week')) ||
    (expiration.ExpirationFreqCode && expiration.ExpirationFreqCode.includes('W'));
  const isMonthly =
    expiration.ExpirationFreqCode === 'M' ||
    expiration.ExpirationFreqCode === 'MM' ||
    expiration.ExpirationFreqCode === 'Monthly' ||
    expiration.ExpirationFreqCode === 'VX' ||
    expiration.ExpirationFreqCode === 'ME';
  const isQuarterly = expiration.ExpirationFreqCode === 'QuarterlyEOM';
  const amPmExpirationLabel = (() => {
    if (symbolIsIndex) {
      if (expiration.optionExpirationType === OptionExpirationType.AM) {
        return 'AM';
      }

      if (expiration.optionExpirationType === OptionExpirationType.PM) {
        return 'PM';
      }
    }

    return '';
  })();
  const className = isWeekly || isQuarterly || (symbolIsIndex && isMonthly && isPMExpiration) ? 'weekly' : 'monthly';

  if (assetSymbol === undefined) {
    return { class: 'monthly', label: '' };
  }

  if (isQuarterly) {
    return { class: className, label: `${amPmExpirationLabel} ${ExpirationType.Quarterly}` };
  }

  if (isWeekly || (symbolIsIndex && isMonthly && isPMExpiration)) {
    return { class: className, label: `${amPmExpirationLabel} ${ExpirationType.Weekly}` };
  }

  return { class: className, label: `${amPmExpirationLabel}` };
};

// export const expirationTypeDisplay = (expirationType: ExpirationType, optionExpirationType: OptionExpirationType) => {
//   if (expirationType === ExpirationType.Weekly || optionExpirationType === OptionExpirationType.PM) {
//     return 'W';
//   }
//   return '';
// };

export const maskAccountNumber = (accountNumber: string | number | undefined) => {
  if (accountNumber) {
    const numberString = accountNumber.toString();
    const unmaskedChars = 4;
    const mask = numberString.length > unmaskedChars ? '**' : '';
    return mask + _.join(_.takeRight(numberString, unmaskedChars), '');
  }
  return '';
};

export const alphabeticalSort = (a: string, b: string) => a.localeCompare(b);
