import _ from 'lodash';
import { SaveOptions, ResponseCode, ResponseCodes } from '@tradingblock/types';

export type Savable<T> = T & { options?: SaveOptions };

export const getResponseCodeDescription = (responseCode: ResponseCode, defaultDescription?: string) => {
  const responseCodeObject = ResponseCodes[responseCode];
  if (responseCodeObject) {
    if (_.isFunction(responseCodeObject.description)) {
      return responseCodeObject.description(defaultDescription || responseCodeObject.name);
    }
    return responseCodeObject.description;
  }
  return defaultDescription;
};
