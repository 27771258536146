import { useCallback } from 'react';
import * as Fuse from 'fuse.js';
import { useApi } from '../context/Api';
import { AssetSymbol } from '@tradingblock/types';
import { OptionType } from '@tradingblock/components';
import { toAssetSymbolBySymbolType } from '@tradingblock/api';

export const SymbolToOption = (s: AssetSymbol, symbolOnly = false) => {
  const sym: OptionType<AssetSymbol> = {
    label: symbolOnly ? s.symbol : `${s.name} (${s.symbol})`,
    value: s.symbol,
    data: s,
    searchValue: `${s.symbol} ${s.name} ${s.provider}`,
  };
  return sym;
};

const sortSymbols = (symbols: AssetSymbol[], value: string) =>
  new Fuse.default(symbols, {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: [{ name: 'symbol', weight: 0.6 }, { name: 'name', weight: 0.4 }],
  }).search(value);

export const useSymbolSearch = () => {
  const api = useApi();
  const search = useCallback(async (value: string, limit?: number) => {
    const symbolRes = await api.getSymbols(value, limit ? limit : 100);
    const symbols = symbolRes.map(s => {
      const data: AssetSymbol = toAssetSymbolBySymbolType(s.Symbol, s.Type, {
        name: s.Description,
        provider: s.PrimaryExchange,
      });

      return data;
    });
    const sortedSymbols = sortSymbols(symbols, value);
    return sortedSymbols.map(s => SymbolToOption(s));
  }, []);

  return search;
};
