import React, { useMemo } from 'react';
import { RouteComponentProps, Redirect } from 'react-router';
import { Switch, Route, Link } from 'react-router-dom';
import _ from 'lodash';
import { useWindowSizeContext } from '@tradingblock/components';
import { useStateSelector } from '../../data/global/dataSelectors';
import { UseAccountApiProvider } from '../../context/Api';
import { SessionWrapper } from '../wrappers/SessionWrapper';
import { AdminRoot } from './AdminRoot';
import { AdminCashieringIndex } from './cashiering/AdminCashieringIndex';
import { AdminCashieringDetail } from './cashiering/AdminCashieringDetail';
import { usePageSettings } from '../../framework/WrappedRoute';
import { ScreenContentWrapper } from '../views/ScreenWrapper';
import { useAccountSettings } from '../../components/dashboard/hooks/useAccountSettings';

const AdminPageBase: React.FunctionComponent<{}> = ({ children }) => {
  const pathname = window.location.pathname;
  const sections = useMemo(() => {
    return _.filter(_.split(pathname, '/'), s => !!s && !/^\d+$/.test(s));
  }, [pathname]);
  useAccountSettings();
  return (
    <ScreenContentWrapper>
      <div className="screen-header">
        <div className="screen-title">
          <span className="fa-stack">
            <i className="fas fa-circle fa-stack-2x" />
            <i className="fal fa-user-cog fa-stack-1x fa-inverse" />
          </span>{' '}
          <span className="txt-lg">
            {sections.map((s, i) => (
              <React.Fragment key={`section${i}`}>
                {i === sections.length - 1 ? (
                  _.startCase(s)
                ) : (
                  <Link to={`/${_.join(_.take(sections, i + 1), '/')}`}>{_.startCase(s)}</Link>
                )}
                {i < sections.length - 1 && <> &raquo; </>}
              </React.Fragment>
            ))}
          </span>
        </div>
        <div className="screen-actions">
          <Link className="mute" to="/">
            Back to dashboards &raquo;
          </Link>
        </div>
      </div>
      <div
        className={`screen-body ${pathname === '/admin/cashiering' ? 'admin-cashiering-screen-body-width-override' : ''}`}
      >
        {children}
      </div>
    </ScreenContentWrapper>
  );
};

export const AdminRouter: React.FunctionComponent<RouteComponentProps<{ path: string }>> = () => {
  const { allowed } = usePageSettings({ name: 'Administrator home', access: 'admin' });
  const token = useStateSelector(s => s.auth.uiApiToken);

  if (!token) {
    return <span>Loading account info...</span>;
  }
  if (!allowed) {
    return <Redirect to="/" />;
  }

  return (
    <useWindowSizeContext.Provider>
      <SessionWrapper>
        <UseAccountApiProvider>
          <AdminPageBase>
            <Switch>
              <Route path="/admin/cashiering/:entityType/:accountId/:id" component={AdminCashieringDetail} />
              <Route path="/admin/cashiering" component={AdminCashieringIndex} />

              <Route path="/admin" component={AdminRoot} />
            </Switch>
          </AdminPageBase>
        </UseAccountApiProvider>
      </SessionWrapper>
    </useWindowSizeContext.Provider>
  );
};
