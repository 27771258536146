import React from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Modal } from '@tradingblock/components';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { AccountManagementDataActions } from '../../../data/global/actions/AccountManagementActions';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';

export const RemoveAllBeneficiaries: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
}> = ({ show, toggleModal }) => {
  const accountId = useStateSelector(s => s.accounts.account && s.accounts.account.AccountId);
  const dispatch = useDispatch();

  const onClick = () => {
    const updateValues = {
      item: 'RemoveBeneficiaries',
    };

    dispatch(AccountManagementDataActions.requestMakeAccountUpdate({ accountId, request: updateValues }));
    toggleModal();
  };

  return (
    <Modal setShow={() => toggleModal()} show={show}>
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>Remove All Beneficiaries?</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <p>
          <i className="far fa-question-circle" />{' '}
          <strong>Are you sure you want to remove all current beneficiaries?</strong>
        </p>
      </BootstrapModal.Body>
      <BootstrapModal.Footer>
        <Button variant="secondary" onClick={() => toggleModal()}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onClick}>
          Remove
        </Button>
      </BootstrapModal.Footer>
    </Modal>
  );
};
