import React from 'react';

export const LoadingDropdownButton: React.FunctionComponent<{ message?: string }> = ({ message }) => {
  return (
    <>
      <button className="btn-dark btn-tall dropdown-toggle text-left btn-block" disabled type="button">
        <span className="spinner-border spinner-border-sm"></span>
        {message || 'Loading..'}
      </button>
    </>
  );
};
