import React, { FC, useMemo, useCallback } from 'react';
import _ from 'lodash';
import {
  BlockType,
  OrderAction,
  AssetType,
  OptionType,
  DirectionRules,
  OrderActionDirection,
  OrderLegDTO,
  OrderStrategyTypeId,
} from '@tradingblock/types';
import {
  parseOccSymbol,
  toAssetSymbol,
  AssetTypeToSymbolType,
  OrderBuilderType,
  OCCSymbolToOptionData,
  AggregatedPositionDTO,
  deriveOptionExpirationType,
  toExpiration,
  Strategies,
  CustomStrategy,
} from '@tradingblock/api';
import { DefaultLegs, DtoToOrderleg } from '../Order/OrderUtilities';
import {
  useOrderBlockWithOrder,
  getStrategyForLegs,
  useCloseOrderAction,
} from '../../components/blocks/hooks/useOrderBlock';
import { useBlockGroupInfo } from '../useGroupState';
import { PositionActionsProps, PositionActions } from './PositionActions';
import { StrategyName, validatePositionLegs } from './PositionLeg';

export interface GroupedPositionLegActionsProps {
  position: AggregatedPositionDTO[];
}

export function GroupedPositionLegActions({ position }: GroupedPositionLegActionsProps) {
  const { UnderlyingSymbol, AssetType: assetType, Symbol } = position[0];
  const { groupId } = useBlockGroupInfo();
  const baseAssetSymbol = useMemo(
    () =>
      toAssetSymbol(UnderlyingSymbol, assetType, AssetTypeToSymbolType(assetType), {
        name: UnderlyingSymbol,
        underlyingSymbol: UnderlyingSymbol,
        rootSymbol: UnderlyingSymbol,
      }),
    [Symbol, AssetType, UnderlyingSymbol]
  );

  const stratName = StrategyName(position);
  let strategy = Strategies.find(s => s.info.Name === stratName);

  // Handle different directions for calendars
  if (strategy && strategy.profile.Rules.Direction === DirectionRules.DifferentDirections) {
    strategy.profile.Legs[0].Direction = OrderActionDirection.Long;
    strategy.profile.Legs[1].Direction = OrderActionDirection.Short;
  }

  //Sort order, Equities, CALLS, Short CALLS, PUTS, Short PUTS, on strategy legs and position legs
  let sortedStrategyLegs =
    strategy &&
    strategy.profile.Legs.sort((a, b) => {
      let aWeight =
        a.AssetType === AssetType.Equity
          ? 0
          : a.OptionType === OptionType.Call && a.Direction === OrderActionDirection.Long
          ? 1
          : a.OptionType === OptionType.Call && a.Direction === OrderActionDirection.Short
          ? 2
          : a.OptionType === OptionType.Put && a.Direction === OrderActionDirection.Long
          ? 3
          : a.OptionType === OptionType.Put && a.Direction === OrderActionDirection.Short
          ? 4
          : 5;
      let bWeight =
        b.AssetType === AssetType.Equity
          ? 0
          : b.OptionType === OptionType.Call && a.Direction === OrderActionDirection.Long
          ? 1
          : b.OptionType === OptionType.Call && a.Direction === OrderActionDirection.Short
          ? 2
          : b.OptionType === OptionType.Put && a.Direction === OrderActionDirection.Long
          ? 3
          : b.OptionType === OptionType.Put && a.Direction === OrderActionDirection.Short
          ? 4
          : 5;
      return aWeight - bWeight;
    });
  const sortedPositionsLegs = position.sort((a, b) => {
    let aWeight =
      a.AssetType === AssetType.Equity
        ? 0
        : a.Description.includes('CALL') && a.OpenQuantity > 0
        ? 1
        : a.Description.includes('CALL') && a.OpenQuantity < 0
        ? 2
        : a.Description.includes('PUT') && a.OpenQuantity > 0
        ? 3
        : a.Description.includes('PUT') && a.OpenQuantity < 0
        ? 4
        : 5;
    let bWeight =
      b.AssetType === AssetType.Equity
        ? 0
        : b.Description.includes('CALL') && a.OpenQuantity > 0
        ? 1
        : b.Description.includes('CALL') && a.OpenQuantity < 0
        ? 2
        : b.Description.includes('PUT') && a.OpenQuantity > 0
        ? 3
        : b.Description.includes('PUT') && a.OpenQuantity < 0
        ? 4
        : 5;
    return aWeight - bWeight;
  });
  const spreadRatioQuantities = sortedStrategyLegs
    ? sortedStrategyLegs.map((strategyLeg, legIndex) =>
        Math.floor(
          (sortedPositionsLegs[legIndex] && sortedPositionsLegs[legIndex].OpenQuantity) ||
            1 / (strategyLeg.SpreadRatio ? strategyLeg.SpreadRatio : 1)
        )
      )
    : [];
  //Lowest common denominator of position quantities
  let lowest_common_denominator = Math.min(...spreadRatioQuantities);
  //Highest denominator
  let highest_denominator = Math.max(...spreadRatioQuantities);

  let shortStrategyValid = false;
  if (strategy !== undefined && strategy.profile.Bidirectional) {
    //Invert Strategy Legs to see if we are short for a bidirectional type of strategy
    sortedStrategyLegs = sortedStrategyLegs
      ? sortedStrategyLegs.map(leg => ({
          ...leg,
          Direction:
            leg.Direction === OrderActionDirection.Long ? OrderActionDirection.Short : OrderActionDirection.Long,
        }))
      : [];
    shortStrategyValid = sortedStrategyLegs.every((strategyLeg, legIndex) => {
      //If any of our legs are not within the spread radio, then the user broke away from the strategy
      return validatePositionLegs(strategyLeg, sortedPositionsLegs[legIndex]);
    });
  }

  const pureOrderLegs = useMemo(() => {
    const processedLegs = position.reduce(
      (acc, pos) => {
        const optionData = OCCSymbolToOptionData(pos.Symbol);
        const optionType = optionData.option;
        const strike = optionData.strike;
        const expiration = optionData.expiration;
        // for custom strategy, quantity is stored in spreadRatio
        const openQuantity = Math.abs(pos.OpenQuantity);
        const assetType = optionType ? AssetType.Option : AssetType.Equity;
        const action = pos.OpenQuantity > 0 ? OrderAction.Sell : OrderAction.Buy;
        const optionExpirationType = deriveOptionExpirationType(
          baseAssetSymbol.symbol,
          baseAssetSymbol.underlyingSymbol || ''
        );
        const legs = DefaultLegs({}, baseAssetSymbol, {
          assetType,
          optionType,
          orderAction: action,
          strike,
          expiration: expiration ? toExpiration(expiration, baseAssetSymbol.symbol, optionExpirationType) : undefined,
        });

        return {
          ...acc,
          ...legs,
        };
      },
      {} as { [x: string]: OrderLegDTO }
    );
    return processedLegs;
  }, [position]);

  const handleCloseOrder = useOrderBlockWithOrder(
    (orderBuilder: OrderBuilderType) => {
      orderBuilder.symbol(baseAssetSymbol);
      orderBuilder.strategy(strategy !== undefined ? strategy : CustomStrategy);

      if (strategy !== undefined) {
        if (stratName !== 'Shares') {
          // We set these for share specific strats
          if (stratName === 'Call Calendar' || stratName === 'Put Calendar') {
            orderBuilder.action(shortStrategyValid ? OrderAction.Sell : OrderAction.Buy);
          } else {
            orderBuilder.action(shortStrategyValid ? OrderAction.Buy : OrderAction.Sell);
          }
          orderBuilder.quantity(Math.abs(lowest_common_denominator));
        }
      }
      if (stratName === 'Shares') {
        const shares_quantity = position.reduce(
          (shares_quantity, share_leg) => shares_quantity + share_leg.OpenQuantity,
          0
        );
        const sharesQuantityAbsoluteValue = Math.abs(shares_quantity);
        orderBuilder.quantity(sharesQuantityAbsoluteValue < 1 ? Math.ceil(sharesQuantityAbsoluteValue) : Math.floor(sharesQuantityAbsoluteValue));
        orderBuilder.action(
          shares_quantity > 0 ? OrderAction.Sell : OrderAction.Buy ? OrderAction.Buy : OrderAction.Sell
        );
        orderBuilder.leg(legBuilder => {
          const symbolInfo = parseOccSymbol(position[0].Symbol);
          const buildRes = legBuilder
            .assetType(symbolInfo.assetType)
            .action(shares_quantity > 0 ? OrderAction.Sell : OrderAction.Buy);
          return buildRes;
        });
      } else {
        Object.values(pureOrderLegs).forEach((leg, index) => {
          orderBuilder.leg(legBuilder => {
            const symbolInfo = parseOccSymbol(position[index].Symbol);
            const quantity =
              strategy !== undefined
                ? strategy.profile.Legs[index] && strategy.profile.Legs[index].SpreadRatio !== undefined
                  ? strategy.profile.Legs[index] && strategy.profile.Legs[index].SpreadRatio
                  : 1
                : position[index].OpenQuantity;

            const buildRes = legBuilder
              .assetType(symbolInfo.assetType)
              .action(leg.Action)
              .quantity(quantity ? Math.abs(quantity) : 1);
            if (symbolInfo.assetType === AssetType.Equity) {
              return buildRes;
            }
            return buildRes
              .optionType(symbolInfo.option || OptionType.Call)
              .expiration(symbolInfo.expiration)
              .strike(symbolInfo.strike);
          });
        });
      }

      return orderBuilder;
    },
    {
      blockType: BlockType.Order,
      groupId,
    }
  );

  const handleTradeOrder = useOrderBlockWithOrder(
    (orderBuilder: OrderBuilderType) => {
      orderBuilder.symbol(baseAssetSymbol);
      orderBuilder.strategy(strategy !== undefined ? strategy : CustomStrategy);
      if (strategy !== undefined) {
        orderBuilder.action(OrderAction.Buy);
        orderBuilder.quantity(1);
      }
      // If the strategy is shares, we need to only add one leg no matter how many pureOrderLegs there are
      if (stratName === 'Shares') {
        orderBuilder.leg(legBuilder => {
          const symbolInfo = parseOccSymbol(position[0].Symbol);
          const buildRes = legBuilder
            .assetType(symbolInfo.assetType)
            .action(OrderAction.Buy)
            .quantity(Math.abs(position[0].OpenQuantity));
          return buildRes;
        });
        return orderBuilder;
      }

      Object.values(pureOrderLegs).forEach((leg, index) => {
        orderBuilder.leg(legBuilder => {
          const symbolInfo = parseOccSymbol(position[index].Symbol);
          const quantity =
            strategy !== undefined
              ? strategy.profile.Legs[index] && strategy.profile.Legs[index].SpreadRatio !== undefined
                ? strategy.profile.Legs[index] && strategy.profile.Legs[index].SpreadRatio
                : 1
              : position[index].OpenQuantity;
          const buildRes = legBuilder
            .assetType(symbolInfo.assetType)
            .action(leg.Action === OrderAction.Buy ? OrderAction.Sell : OrderAction.Buy)
            .quantity(Math.abs(quantity ? Math.abs(quantity) : 1));
          if (symbolInfo.assetType === AssetType.Equity) {
            return buildRes;
          }
          return buildRes
            .optionType(symbolInfo.option || OptionType.Call)
            .expiration(symbolInfo.expiration)
            .strike(symbolInfo.strike);
        });
      });
      return orderBuilder;
    },
    {
      blockType: BlockType.Order,
      groupId,
    }
  );

  return (
    <>
      <PositionActions
        actions={[
          { title: 'Close position', onClick: () => handleCloseOrder(), icon: '' },
          { title: 'Trade', onClick: () => handleTradeOrder(), icon: 'fa-clone' },
        ]}
        identity={position.map(p => p.Symbol + '-' + p.OpenQuantity).join('.')}
        maxVisibleActions={3}
      />
    </>
  );
}
