import { ActionType, createStandardAction } from 'typesafe-actions';
import {
  ApiResponse,
  AccountInfo,
  GenericError,
  Balances,
  AccountDetails,
  SearchAccountsRequest,
  BalancesForWithdrawal,
} from '@tradingblock/types';
import { buildGenericRequestReceiveActions, actionBuilder } from '../../actionUtilities';
import { AdminIdRequest } from '.';

const ADMIN_ACCOUNT_REQUEST = '@admin/accounts/get/REQUEST';
const ADMIN_ACCOUNT_RECEIVE = '@admin/accounts/get/RECEIVE';
const ADMIN_ACCOUNT_ERROR = '@admin/accounts/get/ERROR';

const ADMIN_DETAILS_REQUEST = '@admin/accounts/details/REQUEST';
const ADMIN_DETAILS_RECEIVE = '@admin/accounts/details/RECEIVE';
const ADMIN_DETAILS_ERROR = '@admin/accounts/details/ERROR';

const ADMIN_BALANCES_REQUEST = '@admin/accounts/balances/REQUEST';
const ADMIN_BALANCES_RECEIVE = '@admin/accounts/balances/RECEIVE';
const ADMIN_BALANCES_ERROR = '@admin/accounts/balances/ERROR';

const ADMIN_BALANCES_FOR_WITHDRAWAL_REQUEST = '@admin/accounts/balances/withdrawal/REQUEST';
const ADMIN_BALANCES_FOR_WITHDRAWAL_RECEIVE = '@admin/accounts/balances/withdrawal/RECEIVE';
const ADMIN_BALANCES_FOR_WITHDRAWAL_ERROR = '@admin/accounts/balances/withdrawal/ERROR';

const ADMIN_ACCOUNT_SEARCH_SET_SEARCH = '@admin/accounts/search/ui/SET_SEARCH';

const [accountRequest, accountReceive, accountError] = buildGenericRequestReceiveActions({
  REQUEST: ADMIN_ACCOUNT_REQUEST,
  RECEIVE: ADMIN_ACCOUNT_RECEIVE,
  ERROR: ADMIN_ACCOUNT_ERROR,
})<AdminIdRequest, ApiResponse<AccountInfo[]>, GenericError<any, AdminIdRequest>>();
const [accountDetailsRequest, accountDetailsReceive, accountDetailsError] = actionBuilder<
  { id: number },
  { id: number } & AccountDetails
>()(ADMIN_DETAILS_REQUEST, ADMIN_DETAILS_RECEIVE, ADMIN_DETAILS_ERROR);
const [accountBalancesRequest, accountBalancesReceive, accountBalancesError] = actionBuilder<
  { id: number },
  { id: number } & Balances
>()(ADMIN_BALANCES_REQUEST, ADMIN_BALANCES_RECEIVE, ADMIN_BALANCES_ERROR);
const [
  accountBalancesForWithdrawalRequest,
  accountBalancesForWithdrawalReceive,
  accountBalancesForWithdrawalError,
] = actionBuilder<{ id: number }, { id: number } & BalancesForWithdrawal>()(
  ADMIN_BALANCES_FOR_WITHDRAWAL_REQUEST,
  ADMIN_BALANCES_FOR_WITHDRAWAL_RECEIVE,
  ADMIN_BALANCES_FOR_WITHDRAWAL_ERROR
);

export const AdminAccountActions = {
  accountRequest,
  accountReceive,
  accountError,
  accountDetailsRequest,
  accountDetailsReceive,
  accountDetailsError,
  accountBalancesRequest,
  accountBalancesReceive,
  accountBalancesError,
  accountBalancesForWithdrawalRequest,
  accountBalancesForWithdrawalReceive,
  accountBalancesForWithdrawalError,
  setSearch: createStandardAction(ADMIN_ACCOUNT_SEARCH_SET_SEARCH)<Pick<SearchAccountsRequest, 'search'>>(),
};

export type AdminAccountAction = ActionType<typeof AdminAccountActions>;
