import { SymbolState } from '../state';
import { InitialState } from '../initialState';
import { RootAction, LifecycleActions, SymbolActions } from '../actions';
import { getType } from 'typesafe-actions';

export const symbolReducer = (state = InitialState.symbols, action: RootAction): SymbolState => {
  switch (action.type) {
    case getType(LifecycleActions.loadInitialData):
      return {
        ...state,
        isFetching: true,
      };
    case getType(SymbolActions.receiveWarning): {
      return {
        ...state,
        isFetching: false,
        warningSymbols: action.payload || [],
      };
    }

    default:
      return state;
  }
};
