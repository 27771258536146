import React, { useMemo } from 'react';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { relationshipSelectors } from '../../../../data/global/selectors/admin/cashieringSelectors';
import { CashieringEntityStatus } from '../../../../components/cashiering/CashieringEntityStatus';
import { CashieringEntityAuditItems } from './details/CashieringEntityAuditItems';

export const CashieringRelationshipStatus: React.FunctionComponent<{ id: number; accountId: number }> = ({ id }) => {
  const relationship = useStateSelector(s => relationshipSelectors.data(s, id));
  const relationshipDetails = useStateSelector(s => relationshipSelectors.details(s, id));

  const relationshipState = useMemo(() => {
    return {
      state: relationship && relationship.state,
      cancellationReason:
        ((relationship && relationship.state === 'Cancelled') || (relationship && relationship.state === 'Rejected')) &&
        relationship.cancellation.reason,
      cancellationComment:
        ((relationship && relationship.state === 'Cancelled') || (relationship && relationship.state === 'Rejected')) &&
        relationship.cancellation.comment,
    };
  }, [relationship]);

  const pendingMicroDeposit = useMemo(
    () => relationship && relationship.approvalMethod === 'MicroDeposit' && relationshipState.state === 'Pending',
    [relationship, relationshipState]
  );

  const reviewLegacyRelationships = useMemo(
    () =>
      relationshipDetails &&
      relationshipDetails.reviewLegacyRelationships &&
      relationshipDetails.reviewLegacyRelationships.length > 0,
    [relationshipDetails]
  );

  if (!relationship) {
    return null;
  }

  return (
    <div className="screen-body-section">
      <p className="fields-title">Review status</p>
      <p>
        {relationshipState.state && (
          <strong>
            <CashieringEntityStatus status={relationshipState.state} showWarning={reviewLegacyRelationships}>
              {pendingMicroDeposit ? 'Pending MicroDeposit entry by client' : undefined}
            </CashieringEntityStatus>
          </strong>
        )}
        <br />

        {(relationshipState.cancellationReason || relationshipState.cancellationComment) && (
          <span className="neg">
            {relationshipState.cancellationReason && (
              <>
                Cancellation reason: {relationshipState.cancellationReason}
                <br />
              </>
            )}
            {relationshipState.cancellationComment && (
              <>
                Cancellation comment: {relationshipState.cancellationComment}
                <br />
              </>
            )}
          </span>
        )}
      </p>

      <CashieringEntityAuditItems id={id} type="relationships" />
    </div>
  );
};
