import { AccountDetails, IBlockDataState, IBlockState } from '@tradingblock/types';
import { useBlockData } from '../../../components/blocks/BlockState';
import _ from 'lodash';
import { OutputSelector } from 'reselect';
import { useMemo } from 'react';

export type AdminToolsTab = 'accountManagement' | 'entitlements' | 'alerts' | 'applications' | 'releaseNotes';
export const AdminToolsConstants: {
  tabs: Record<AdminToolsTab, AdminToolsTab>;
} = {
  tabs: {
    accountManagement: 'accountManagement',
    entitlements: 'entitlements',
    alerts: 'alerts',
    applications: 'applications',
    releaseNotes: 'releaseNotes',
  },
};

export interface AdminToolsDataState extends IBlockDataState {
  account?: AccountDetails;
  tab?: AdminToolsTab;
}

export type AdminToolsState = IBlockState<AdminToolsDataState, {}>;

type Selector<T> = OutputSelector<AdminToolsDataState, T, (res: AdminToolsDataState) => T>;
type Transform<T> = (res: AdminToolsDataState) => T;

export function useAdminToolsData<T>(selector: Selector<T> | Transform<T>, defaultVal?: T) {
  const data = useBlockData<AdminToolsDataState>();
  const res = selector(data);
  return useMemo(() => (!_.isUndefined(defaultVal) && _.isNil(res) ? defaultVal : res), [defaultVal, res]);
}

export function isAdminToolsTab(tabVal: AdminToolsTab | string): tabVal is AdminToolsTab {
  return _.map(AdminToolsConstants.tabs, (v, k) => k).includes(tabVal);
}
