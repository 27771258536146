import React, { useCallback, useState } from 'react';
import useMount from 'react-use/lib/useMount';
import _ from 'lodash';
import { BlockType } from '@tradingblock/types';
import { useDispatcher } from '../../data/global/hooks';
import { Block } from '../../components/blocks/Block';
import { GetPositionAndOrderSymbols } from '../../data/global/dataSelectors';
import { useQuoteSymbolSubscriptionFromGlobalState } from '../../hooks/useQuoteData';
import { getBlockProps } from '../../utilities/block';
import { useGlobalStateQuoteListener } from '../../components/blocks/useBlockSubscriptions';
import { DataState } from '../../data/global/state';
import { initialPositionsAreLoading } from '../../data/global/selectors/positionSelector';

import { usePositionSettings } from './usePositionSettings';
import { PositionHeader } from './PositionHeader';
import { PositionBlockContent } from './PositionBlockContent';

export const PositionBlock: React.FunctionComponent<{}> = () => {
  const { dispatcher } = useDispatcher();
  useMount(() => dispatcher.data.positions.request());
  useQuoteSymbolSubscriptionFromGlobalState(GetPositionAndOrderSymbols);
  useGlobalStateQuoteListener(GetPositionAndOrderSymbols);
  const isFetchingSelector = useCallback((state: DataState) => {
    const { loading } = initialPositionsAreLoading(state);
    return loading;
  }, []);
  const { current, definitions } = usePositionSettings();
  const { expandRows } = current;

  // Selected SubAccount States
  const [totalGain, setTotalGain] = useState<number | undefined>(undefined);
  const [dailyPnl, setDailyPnl] = useState<number | undefined>(undefined);

  const setSelectedSubAccountTotalGain = async (totalGain: number | undefined) => {
    setTotalGain(totalGain);
  };

  const setSelectedSubAccountDailyPnl = async (dailyPnl: number | undefined) => {
    setDailyPnl(dailyPnl);
  };

  return (
    <Block
      {...getBlockProps(BlockType.Positions, undefined)}
      header={<PositionHeader totalGain={totalGain} dailyPnl={dailyPnl} expandRows={expandRows} />}
      isFetchingSelector={isFetchingSelector}
      settings={current}
      settingFields={definitions}
      onRefresh={() => {
        dispatcher.data.positions.manualRefresh();
      }}
    >
      <PositionBlockContent setTotalGain={setSelectedSubAccountTotalGain} setDailyPnl={setSelectedSubAccountDailyPnl} />
    </Block>
  );
};
