import { RootAction, DataActions, FeedActions } from '../actions';
import dayjs from 'dayjs';
import { FeedQuote, FeedSettlement, Loadable } from '@tradingblock/types';

import { QuoteSymbolQuotes, QuotesState } from '../state';
import { InitialState } from '../initialState';
import { getType } from 'typesafe-actions';

import { getLastOrClosePrice } from '../utilities';

import {
  SymbolQuoteAndChangeData,
  SymbolChangeDirection,
  ToUtcMidnight,
  getBestPrice,
  getLocalDateFromTimezone,
} from '@tradingblock/api';
import _ from 'lodash';
import { changeCalculation } from '../../../utilities/position';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const numOrZero = <T>(...values: (T | undefined)[]) => {
  const firstNonNil = _.find(values, v => !_.isNil(v) && !_.isNaN(v));
  return _.isNil(firstNonNil) ? 0 : firstNonNil;
};

const SETTLEMENT_RESET_TIME = dayjs()
  .startOf('day')
  .add(1, 'day')
  .add(2, 'hours')
  .add(5, 'minutes')
  .valueOf();

export const quoteReducer = (state = InitialState.quotes, action: RootAction): QuotesState => {
  switch (action.type) {
    case getType(DataActions.requestQuoteExtended): {
      const { symbol } = action.payload;
      return {
        ...state,
        quoteExtended: {
          ...state.quoteExtended,
          isFetching: true,
        },
      };
    }
    case getType(DataActions.receiveQuoteExtended): {
      const { symbol, quote } = action.payload;
      return {
        ...state,
        quoteExtended: {
          ...state.quoteExtended,
          isFetching: false,
          data: {
            ...state.quoteExtended.data,
            [symbol]: quote,
          },
        },
      };
    }
    case getType(DataActions.errorQuoteExtended): {
      const { symbol } = action.payload;
      return {
        ...state,
        quoteExtended: {
          ...state.quoteExtended,
          isFetching: false,
        },
      };
    }
    case getType(DataActions.requestEarnings): {
      const { symbol, period } = action.payload;
      const periodVal = period && period === 'annual' ? 'annual' : 'quarter';
      const existingSymbolVal = state.earnings[symbol] || {};
      const existing = existingSymbolVal[periodVal] || { earnings: [], isFetching: false };
      return {
        ...state,
        earnings: {
          ...state.earnings,
          [symbol]: {
            ...existingSymbolVal,
            [periodVal]: {
              ...existing,
              isFetching: true,
            },
          },
        },
      };
    }
    case getType(DataActions.receiveEarnings): {
      const { symbol, earnings, period } = action.payload;
      const periodVal = period && period === 'annual' ? 'annual' : 'quarter';
      const existingSymbolVal = state.earnings[symbol] || {};
      const existing = existingSymbolVal[periodVal] || { earnings: [], isFetching: false };
      return {
        ...state,
        earnings: {
          ...state.earnings,
          [symbol]: {
            ...existingSymbolVal,
            [periodVal]: {
              ...existing,
              isFetching: false,
              earnings: earnings || [],
            },
          },
        },
      };
    }
    case getType(DataActions.errorEarnings): {
      const { symbol, period } = action.payload;
      const periodVal = period && period === 'annual' ? 'annual' : 'quarter';
      const existingSymbolVal = state.earnings[symbol] || {};
      const existing = existingSymbolVal[periodVal] || { earnings: [], isFetching: false };
      return {
        ...state,
        earnings: {
          ...state.earnings,
          [symbol]: {
            ...existingSymbolVal,
            [periodVal]: {
              ...existing,
              isFetching: false,
            },
          },
        },
      };
    }
    case getType(DataActions.requestDividends): {
      const { symbol, range } = action.payload;
      const existing = state.dividends[symbol] || {
        next: { isFetching: false, dividends: [] },
        ['1y']: { isFetching: false, dividends: [] },
        ['2y']: { isFetching: false, dividends: [] },
        ['5y']: { isFetching: false, dividends: [] },
      };
      const existingRangeState = existing[range] || { dividends: [], isFetching: false };
      return {
        ...state,
        dividends: {
          ...state.dividends,
          [symbol]: {
            ...existing,
            [range]: {
              ...existingRangeState,
              isFetching: true,
            },
          },
        },
      };
    }
    case getType(DataActions.receiveDividends): {
      const { symbol, dividends, range } = action.payload;
      const existing = state.dividends[symbol] || {
        next: { isFetching: false, dividends: [] },
        ['1y']: { isFetching: false, dividends: [] },
        ['2y']: { isFetching: false, dividends: [] },
        ['5y']: { isFetching: false, dividends: [] },
      };
      const existingRangeState = existing[range] || { dividends: [], isFetching: false };
      return {
        ...state,
        dividends: {
          ...state.dividends,
          [symbol]: {
            ...existing,
            [range]: {
              ...existingRangeState,
              isFetching: false,
              dividends: dividends || [],
            },
          },
        },
      };
    }
    case getType(DataActions.errorDividends): {
      const { symbol, range } = action.payload;
      const existing = state.dividends[symbol] || {
        next: { isFetching: false, dividends: [] },
        ['1y']: { isFetching: false, dividends: [] },
        ['2y']: { isFetching: false, dividends: [] },
        ['5y']: { isFetching: false, dividends: [] },
      };
      const existingRangeState = existing[range] || { dividends: [], isFetching: false };
      return {
        ...state,
        dividends: {
          ...state.dividends,
          [symbol]: {
            ...existing,
            [range]: {
              ...existingRangeState,
              isFetching: false,
            },
          },
        },
      };
    }
    case getType(DataActions.fetchingQuotes): {
      const updatedQuoteData = _([action.payload.symbol])
        .flatMap()
        .reduce((acc, sym) => {
          const existingSym = acc[sym] || {};
          return {
            ...acc,
            [sym]: {
              ...existingSym,
              Symbol: sym,
              isFetching: true,
            },
          };
        }, state.quoteMetadata.data);

      return {
        ...state,
        quoteMetadata: {
          ...state.quoteMetadata,
          data: { ...updatedQuoteData },

          isFetching: true,
        },
      };
    }

    case getType(DataActions.receiveQuote):
      const symbolDataAsObject = _.reduce(
        action.payload,
        (acc, v) => {
          const previous = acc[v.symbol];
          const existing = previous || v.quote;
          if (v.quote.IsSettlementSet && dayjs().isBefore(SETTLEMENT_RESET_TIME, 'hour')) {
            v.quote.LastTradePrice = v.quote.ClosePrice;
            existing.LastTradePrice = v.quote.ClosePrice;
          }

          const previousMeta = previous && previous.meta ? previous.meta : {};
          const AskPrice = numOrZero(v.quote.AskPrice, existing.AskPrice);
          const BidPrice = numOrZero(v.quote.BidPrice, existing.BidPrice);
          const LastOrClosePrice = getLastOrClosePrice(v.quote);

          const BestPriceCalculated = getBestPrice({
            bid: BidPrice,
            ask: AskPrice,
            last: LastOrClosePrice,
            defaultValue: 'avgBidAsk',
            isSettlementSet: v.quote.IsSettlementSet,
          });
          // const ReceivedTime = v.quote.QuoteTime;
          // format received time to match the format of the other time stamps, YYYY-MM-DD HH:mm:ssZ, then remove UTC offset and replace with Z
          const ReceivedTime = dayjs(v.quote.QuoteTime)
            .format('MM/DD/YYYY HH:mm:ssZ')
            .replace(/[-+]\d\d:\d\d$/, 'Z');

          const previousClosePrice = previous ? previous.ClosePrice : 0;
          const prevLastUpdate =
            previous && !_.isNil(previousMeta.closePriceLastUpdatedUnix)
              ? previousMeta.closePriceLastUpdatedUnix
              : undefined;
          const previousOutOfDate =
            prevLastUpdate && ToUtcMidnight(prevLastUpdate).unix() < ToUtcMidnight(new Date()).unix();
          const updateClosePrice =
            previousOutOfDate ||
            _.isNil(previous) ||
            previousClosePrice === 0 ||
            _.isNil(prevLastUpdate) ||
            v.quote.ClosePrice !== 0;
          const closePrice = v.quote.ClosePrice;
          const closePriceLastUpdate = updateClosePrice ? ToUtcMidnight(new Date()).unix() : prevLastUpdate;
          const { change, changePercent } = changeCalculation(
            v.quote.LastTradePrice,
            v.quote.ClosePrice,
            v.quote.ClosePriceTMinus2,
            v.quote.BidPrice,
            v.quote.AskPrice,
            v.quote.Symbol,
            v.quote.IsSettlementSet
          );

          return {
            ...acc,
            [v.symbol]: {
              ...existing,
              ...v.quote,
              AskPrice,
              BidPrice,
              BestPriceCalculated,
              LastOrClosePrice,
              LastTradePrice: v.quote.LastTradePrice,
              NetChange: v.quote.NetChange === 0 ? 0 : v.quote.NetChange,
              CalculatedNetChange: change,
              CalculatedChangePercentage: changePercent * 100,
              isFetching: false,
              isLoaded: true,
              isLoadedFromApi: true,
              ReceivedTime,
              ClosePrice: closePrice,
              meta: {
                closePriceLastUpdatedUnix: closePriceLastUpdate,
              },
            },
          };
        },
        state.quoteMetadata.data
      );
      return {
        ...state,
        quoteMetadata: {
          ...state.quoteMetadata,
          isFetching: false,
          data: {
            ...symbolDataAsObject,
          },
        },
      };
    case getType(FeedActions.updateQuotes): {
      const { quotes } = action.payload;
      const symbolDataAsObject = _.reduce(
        quotes,
        (acc: QuoteSymbolQuotes, v, k) => {
          let {
            AskPrice,
            BidPrice,
            QuoteTime,
            LastTradePrice,
            NetChange,
            High,
            Low,
            Volume,
            ChangePercentage,
            ...rest
          }: FeedQuote = v;
          const existingValue: Loadable<SymbolQuoteAndChangeData> | undefined = state.quoteMetadata.data[k];
          let existing = existingValue || { Symbol: rest.Symbol };

          if ((v as any).IsSettlementSet !== undefined && (v as any).IsSettlementSet === true) {
            let settlement: FeedSettlement = v as any;
            existing.IsSettlementSet = settlement.IsSettlementSet;
            existing.ClosePrice = settlement.ClosePrice;
            existing.ClosePriceTMinus2 = settlement.ClosePriceTMinus2;
            existing.CloseDate = settlement.CloseDate;
          }

          const ask = numOrZero(v.AskPrice, existing.AskPrice);
          const bid = numOrZero(v.BidPrice, existing.BidPrice);
          const BidSize = numOrZero(v.BidSize, existing.BidSize);
          const AskSize = numOrZero(v.AskSize, existing.AskSize);
          const LastOrClosePrice = getLastOrClosePrice({
            LastTradePrice,
            ClosePrice: existing.ClosePrice,
            IsSettlementSet: existing.IsSettlementSet,
          });
          const BestPriceCalculated = getBestPrice({ bid, ask, last: LastOrClosePrice, defaultValue: 'avgBidAsk' });
          const ReceivedTime = QuoteTime;
          let changeDirection: SymbolChangeDirection = undefined;
          if (existingValue && existingValue.LastOrClosePrice > LastOrClosePrice) {
            changeDirection = 'down';
          } else if (existingValue && existingValue.LastOrClosePrice < LastOrClosePrice) {
            changeDirection = 'up';
          }
          // Volume amount is the difference between the current volume and the previous volume and cannot be negative
          // if value is negative, set it to 0
          const LastTradeVolume = Volume - existing.Volume < 0 ? 0 : Volume - existing.Volume;
          return {
            ...acc,
            [v.Symbol]: {
              ...existing,
              LastTradePrice,
              NetChange,
              High,
              Low,
              Volume,
              ChangePercentage,
              AskPrice: ask,
              BidPrice: bid,
              isFetching: false,
              isLoaded: true,
              BestPriceCalculated,
              LastOrClosePrice,
              ReceivedTime,
              changeDirection,
              BidSize,
              AskSize,
              LastTradeVolume, //Uses last data's volume and current volume to determine tha volume difference between quotes
            },
          };
        },
        state.quoteMetadata.data
      );
      const latestReceivedTime = _(symbolDataAsObject)
        .map((value, key) => {
          return getLocalDateFromTimezone(new Date(value.ReceivedTime)).valueOf();
        })
        .max();
      return {
        ...state,
        quoteMetadata: {
          ...state.quoteMetadata,
          isFetching: false,
          data: {
            ...symbolDataAsObject,
          },
          latestReceivedTime,
        },
      };
    }

    default:
      return state;
  }
};
