import { isBlockAction, blockReducer, BlockAction } from '../../../components/blocks/BlockState';
import { OptionChainState } from './OptionChainState';
import { OptionChainAction, OptionChainActions } from './OptionChainAction';
import { OptionChainSettings, IBlockState } from '@tradingblock/types';
import { getType } from 'typesafe-actions';

export type OptionChainBlockState = IBlockState<OptionChainState, OptionChainSettings>;
export const OptionChainBlockReducer = (blockState: OptionChainBlockState, action: OptionChainAction): OptionChainBlockState => {
  const state = isBlockAction(action) ? blockReducer<OptionChainState, OptionChainSettings>(blockState, action) : blockState;

  switch (action.type) {
    case getType(BlockAction.initializeData): {
      return {
        ...state,
        data: {
          ...state.data,
          expandedExpirations: [],
        },
      };
    }
    case getType(OptionChainActions.setExpandedExpirations): {
      const { expirations } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          expandedExpirations: expirations,
        },
      };
    }
    case getType(OptionChainActions.resetExpirations): {
      return {
        ...state,
        data: {
          ...state.data,
          expandedExpirations: [],
        },
      };
    }

    default:
      return state;
  }
};
