import * as tslib_1 from "tslib";
import React from 'react';
import _ from 'lodash';
export function DropdownItem(props) {
  var title = props.title,
      children = props.children,
      className = props.className,
      href = props.href,
      rest = tslib_1.__rest(props, ["title", "children", "className", "href"]);

  var hrefVal = href || '#';
  var content = _.isString(title) ? title : children;
  return React.createElement("a", Object.assign({}, rest, {
    className: "dropdown-item ".concat(className ? className : '', " "),
    href: hrefVal
  }), content);
}