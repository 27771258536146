import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Field } from 'formik';
import { CashieringRoute, BalanceTotals, TransferDisbursementType } from '@tradingblock/types';
import {
  FormGroup,
  TextboxField,
  SelectDropdownInput,
  formatNumber,
  StepperField,
  CurrencyField,
  CheckboxField,
} from '@tradingblock/components';
import { CashieringViewFormValues, CashieringViewProps, ViewOnValidateType } from './data/useCashieringView';
import { validatePositions, validateTransferAmount } from './data/cashieringValidation';
import { useDispatcher } from '../../../data/global/hooks';
import { cashieringSelectors, useStateSelector, subAccountIdSelector } from '../../../data/global/dataSelectors';
import { account } from '../../../data/global/selectors/accountSelectors';
import { TradingBlockLink } from '../../../components/basic/TradingBlockLink';
import { Actions } from '../../../data/global/actions';

interface WithdrawalAmountViewContentProps extends CashieringViewProps<CashieringViewFormValues> {
  getAccountBalances: (subaccountId?: number) => void;
  totals: BalanceTotals;
  availableForWithdrawal: number;
  nickname: string;
  isIRA?: boolean;
}

const DisbursementTypeOptions: { value: TransferDisbursementType; label: string }[] = [
  { value: 'PartialBalance', label: 'Part of  cash balance' },
  { value: 'FullBalance', label: 'Full cash balance' },
];

const WithdrawalAmountViewContent: React.FC<WithdrawalAmountViewContentProps> = ({
  values,
  setValues,
  getAccountBalances,
  totals,
  availableForWithdrawal,
  nickname,
  isIRA,
  children,
}) => {
  const withdrawFullBalance = useMemo(() => values.transfer && values.transfer.disbursementType === 'FullBalance', [
    values,
  ]);

  const globalSubAccountId = useStateSelector(subAccountIdSelector);
  const [transferSubAccountId, setTransferSubAccountId] = useState<number>();
  const subAccounts = useStateSelector(account.subAccounts);
  const accountId = useStateSelector(account.accountId);
  const subAccountBalances = useStateSelector(account.subAccountsBalances);
  const origin = useStateSelector(s => s.auth.origin);
  const dispatch = useDispatch();

  useEffect(() => {
    if (subAccounts && subAccounts.length > 0 && accountId) {
      dispatch(Actions.requestSubAccountBalances({ accountId, subAccounts: subAccounts }));
    }
  }, [subAccounts, dispatch, accountId]);

  const masterAccount = useStateSelector(account.masterAccount);
  const currentSelectedSubAccountId = useStateSelector(account.subAccountId);
  const subAccountOptions = useMemo(() => {
    if (values.transfer && values.transfer.disbursementType === 'FullBalance') {
      const allAccounts = { value: undefined, label: 'All accounts' };
      const subAccs = _.map(subAccounts, sa => ({
        value: sa.Id,
        label: `${sa.Nickname} (${sa.AcctSuffix})`,
      }));
      return [allAccounts, ...subAccs];
    }
    return _.map(subAccounts, sa => ({
      value: sa.Id,
      label: `${sa.Nickname} (${sa.AcctSuffix})`,
    }));
  }, [subAccounts, values.transfer]);

  useEffect(() => {
    if (values.transfer && values.transfer.subAccountId) {
      setTransferSubAccountId(values.transfer.subAccountId);
    }
    if (globalSubAccountId) {
      // If globally selected sub account, force select the dropdown
      setTransferSubAccountId(globalSubAccountId);
      setValues({ ...values, transfer: { ...values.transfer, subAccountId: globalSubAccountId } });
    }
  }, []);

  const onSelectSubAccount = useCallback(
    (id: number) => {
      if (id === undefined) {
        setTransferSubAccountId(undefined);
        setValues({ ...values, transfer: { ...values.transfer, amount: 0, subAccountId: undefined } });
        getAccountBalances();
      }
      setTransferSubAccountId(id);
      setValues({ ...values, transfer: { ...values.transfer, amount: 0, subAccountId: id } });
      getAccountBalances(id);
    },
    [setTransferSubAccountId, values]
  );

  const onChangeDisbursementType = useCallback(
    (disbursementType: TransferDisbursementType) => {
      if (disbursementType === 'FullBalance') {
        setValues({
          ...values,
          transfer: {
            ...values.transfer,
            amount: availableForWithdrawal,
            disbursementType,
            closeAccount: false,
          },
        });
      } else if (disbursementType === 'FullBalanceAndCloseAccount') {
        setValues({
          ...values,
          transfer: {
            ...values.transfer,
            amount: availableForWithdrawal,
            disbursementType,
            closeAccount: true,
          },
        });
      } else {
        setValues({
          ...values,
          transfer: { ...values.transfer, subAccountsValues: {}, amount: 0, disbursementType, closeAccount: false },
        });
      }
    },
    [values]
  );

  useEffect(() => {
    if (
      subAccounts &&
      !_.isEmpty(subAccounts) &&
      (globalSubAccountId === undefined || globalSubAccountId === undefined)
    ) {
      setValues({
        ...values,
        transfer: {
          ...values.transfer,
          amount: values.transfer
            ? Object.values(values.transfer.subAccountsValues ? values.transfer.subAccountsValues : {}).reduce(
                (acc: number, v) => {
                  if (typeof v === 'string') {
                    //Since the currency component will convert it to a string, need to convert it back to a number
                    let value = Number(v.replace(/[^0-9.-]+/g, ''));
                    return acc + value;
                  } else if (typeof v === 'number') {
                    //But if the user has already entered a number, manually, just use it
                    return acc + v;
                  }
                  return acc;
                },
                0 as number
              )
            : 0,
        },
      });
    }
  }, [subAccounts, values.transfer ? values.transfer.subAccountsValues : false]);

  useEffect(() => {
    if (globalSubAccountId === undefined) {
      if (masterAccount !== undefined) {
        setValues({ ...values, transfer: { ...values.transfer, subAccountId: masterAccount.Id } });
      }
    }
  }, [masterAccount, globalSubAccountId]);

  return (
    <>
      <div className="screen-body-section">
        <p className="fields-title">How much to withdraw?</p>
        <p>
          <span className="mute">Withdrawing from:</span> {nickname}.{' '}
          <span className="mute">Available for withdrawal:</span>{' '}
          {formatNumber(availableForWithdrawal, { currency: true })}
        </p>

        <FormGroup>
          <Field
            component={StepperField}
            id="transfer.disbursementType"
            options={DisbursementTypeOptions}
            onChanged={onChangeDisbursementType}
          />
        </FormGroup>

        {subAccounts &&
        !_.isEmpty(subAccounts) &&
        values.transfer &&
        values.transfer.disbursementType === 'PartialBalance' &&
        globalSubAccountId === undefined ? (
          <FormGroup title="How much do you wish to withdraw from your sub-accounts?">
            {subAccounts.map(subAccount => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
                key={subAccount.Id}
              >
                <div
                  style={{
                    flex: 3 / 4,
                  }}
                >
                  <Field
                    key={subAccount.Id}
                    component={CurrencyField}
                    id={`transfer.subAccountsValues.${subAccount.Id}`}
                    label={`${subAccount.Nickname}:`}
                  />
                </div>
                <span
                  style={{
                    flex: 1 / 4,
                    marginLeft: '1rem',
                    fontWeight: 'bold',
                    color: '#A4CFFF',
                  }}
                >
                  Amount Available:{' '}
                  {subAccountBalances[`${subAccount.Id}`]
                    ? availableForWithdrawal !== undefined && availableForWithdrawal > 0
                      ? formatNumber(subAccountBalances[`${subAccount.Id}`].Balances.WithdrawalAmount, {
                          currency: true,
                        })
                      : formatNumber(0, { currency: true })
                    : 'Loading...'}
                </span>
              </div>
            ))}
          </FormGroup>
        ) : (
          subAccounts !== undefined &&
          !_.isEmpty(subAccounts) &&
          values.transfer &&
          values.transfer.disbursementType === 'PartialBalance' && (
            <FormGroup title="Selected Sub-Account">
              <SelectDropdownInput
                name="transfer.subAccountId"
                label="Sub-account"
                options={subAccountOptions}
                value={transferSubAccountId}
                disabled={globalSubAccountId !== undefined}
                onchange={(e, subAccountId) => onSelectSubAccount(subAccountId)}
              />
            </FormGroup>
          )
        )}
        <FormGroup>
          <Field
            component={CurrencyField}
            id="transfer.amount"
            label="Amount"
            disabled={
              (!_.isEmpty(subAccounts) && !globalSubAccountId) ||
              (values.transfer &&
                (values.transfer.disbursementType === 'FullBalance' ||
                  values.transfer.disbursementType === 'FullBalanceAndCloseAccount'))
                ? true
                : false
            }
          />

          {withdrawFullBalance && (
            <>
              <p className="mute">
                The actual amount withdrawn may be reduced by{' '}
                <TradingBlockLink to={origin === 'mb' ? 'MBFeesUrl' : 'TBFeesUrl'}>
                  applicable transfer fees
                </TradingBlockLink>
                , if any, and may change to reflect pending transactions that settle prior to completion of the
                transfer. Any amount related to unsettled trades or existing positions will not be included.
              </p>
            </>
          )}
        </FormGroup>

        {isIRA && (
          <>
            <p className="mute">This is an IRA account. You must provide the following:</p>
            <FormGroup>
              <SelectDropdownInput
                name="distributionReason"
                label="Distribution Reason"
                options={[{ value: 'x', label: 'Reason' }]}
                value={values.transfer && values.transfer.distributionReason}
                onchange={(e, distributionReason) =>
                  setValues({ ...values, transfer: { ...values.transfer, distributionReason } })
                }
              />
            </FormGroup>
            <FormGroup>
              <div className="row">
                <div className="col">
                  <Field component={TextboxField} id="federalTax" label="Federal Tax" />
                </div>
                <div className="col">
                  <Field component={TextboxField} id="stateTax" label="State Tax" />
                </div>
              </div>
            </FormGroup>
          </>
        )}
      </div>

      {withdrawFullBalance && !transferSubAccountId && (
        <div className="screen-body-section">
          <FormGroup title="Close account?">
            <p>
              Check this box if you wish to close your account following this Full Balance withdrawal. Please note an
              account with existing positions or unsettled trades cannot be closed.
            </p>
            <Field
              component={CheckboxField}
              id="transfer.closeAccount"
              label="Close account following full balance transfer"
            />
          </FormGroup>
        </div>
      )}

      {children}
    </>
  );
};

const WithdrawalAmountView: React.FC<CashieringViewProps<CashieringViewFormValues>> = props => {
  const { values } = props;
  const totals = useSelector(cashieringSelectors.balanceTotals);
  const accountInfo = useStateSelector(s =>
    s.account
      ? {
          nickname: s.account.nickname,
          accountNumber: s.account.accountNumber,
          isIRA: s.account.isIRA,
        }
      : undefined
  );
  const balancesForWithdrawal = useStateSelector(s => s.accountData.balances.balancesForWithdrawal);
  const availableForWithdrawal = (balancesForWithdrawal && balancesForWithdrawal.availableForWithdrawal) || 0;

  const { dispatcher } = useDispatcher();
  const globalSubAccountId = useStateSelector(subAccountIdSelector);
  const subAccounts = useStateSelector(account.subAccounts);
  const selectedSubAccount = values.transfer && values.transfer.subAccountId;

  useEffect(() => {
    dispatcher.balances.requestPendingTransfers();
    if (globalSubAccountId) {
      dispatcher.cashiering.balances.request(globalSubAccountId);
    } else if (selectedSubAccount && subAccounts) {
      const selectedSubAccountDetails = subAccounts.find(s => s.Id === selectedSubAccount);
      if (selectedSubAccountDetails && selectedSubAccountDetails.IsMaster === false) {
        dispatcher.cashiering.balances.request(selectedSubAccount);
      }
    } else {
      dispatcher.cashiering.balances.request();
    }
  }, [selectedSubAccount, globalSubAccountId]);

  const getAccountBalances = useCallback(
    (subaccountId?: number) => dispatcher.cashiering.balances.request(subaccountId),
    [dispatcher]
  );

  return (
    <>
      {totals && accountInfo ? (
        <WithdrawalAmountViewContent
          {...props}
          getAccountBalances={getAccountBalances}
          totals={totals}
          availableForWithdrawal={availableForWithdrawal}
          {...accountInfo}
        />
      ) : (
        `Account details not loaded`
      )}
    </>
  );
};

export const useWithdrawalAmountView = (): [typeof WithdrawalAmountView, undefined, ViewOnValidateType] => {
  const hasSubAccounts = useStateSelector(account.hasSubAccounts);
  const totals = useSelector(cashieringSelectors.balanceTotals);
  const hasPositions = useStateSelector(s => s.positions.positions.length > 0);
  const positions = useStateSelector(s => s.positions.positions);

  const subAccounts = useStateSelector(account.subAccounts);
  const accountId = useStateSelector(account.accountId);
  const subAccountBalances = useStateSelector(account.subAccountsBalances);
  const dispatch = useDispatch();

  const balancesForWithdrawal = useStateSelector(s => s.accountData.balances.balancesForWithdrawal);
  const availableForWithdrawal = balancesForWithdrawal ? balancesForWithdrawal.availableForWithdrawal : 0;

  useEffect(() => {
    if (subAccounts && subAccounts.length > 0 && accountId) {
      dispatch(Actions.requestSubAccountBalances({ accountId, subAccounts: subAccounts }));
    }
  }, [subAccounts, dispatch, accountId]);

  const onValidate = useCallback(
    (values: CashieringViewFormValues) => {
      return {
        ...validateTransferAmount(
          values,
          CashieringRoute.Withdraw,
          hasSubAccounts,
          subAccountBalances,
          availableForWithdrawal
        ),
        ...validatePositions(values, hasPositions, positions),
      };
    },
    [hasSubAccounts, totals, hasPositions]
  );

  return [WithdrawalAmountView, undefined, onValidate];
};
