import React, { useEffect } from 'react';
import _ from 'lodash';
import { ResponsiveTdMemo, getTableTdClass, TableRowInstanceProps, getClassNames } from '@tradingblock/components';
import { OptionChainGlobalSelectors } from '../state/OptionChainSelector';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { useBlockSymbolValue } from '../../../components/blocks/BlockState';

export const OptionChainTableRow: React.FC<TableRowInstanceProps> = ({
  row,
  index,
  rowKey,
  rowClass,
  onRowClick,
  size,
  responsiveDisabled,
  tableInstance,
  numColumns,
  columnWidths,
}) => {
  // useRef for scrolling to the closest to price row
  const closestToPriceRowRef = React.useRef<HTMLTableRowElement>(null);
  const symbol = useBlockSymbolValue();

  const tableTdClass = getTableTdClass(size as any);
  const { display, Strike, isClosestToPrice } = row.original;

  // scroll to the strike row closest to the price on expansion
  useEffect(() => {
    if (isClosestToPrice) {
      closestToPriceRowRef.current &&
        closestToPriceRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  const trClassName = _.get(display, 'trClass', '');
  const tdClass = _.get(display, 'tdClass', undefined);
  const bidGreaterThanStrike = useStateSelector(s =>
    OptionChainGlobalSelectors.strikeMoreThanBid(s, { symbol, strike: Strike || -1 })
  );
  const rowProps = row.getRowProps();
  const renderRow = _.get(display, 'renderRow', undefined);
  const renderedRow = _.isFunction(renderRow) ? renderRow(row, index, rowProps) : undefined;
  if (renderedRow) {
    return renderedRow;
  }
  const resolvedClass = rowClass ? (_.isFunction(rowClass) ? rowClass(row) : rowClass) : trClassName;

  return (
    <>
      <tr
        {...rowProps}
        key={rowKey}
        className={resolvedClass}
        onClick={onRowClick ? e => onRowClick(e, row) : undefined}
        ref={isClosestToPrice ? closestToPriceRowRef : undefined}
      >
        {row.cells.map((cell: any, index) => {
          const isCallOption = _.includes(cell.column.id, 'Call.');
          const isStrikeCell = cell.column.id === 'Strike';
          const priceClass = isStrikeCell ? '' : bidGreaterThanStrike === isCallOption ? 'inmoney' : 'outmoney';
          const headerVal = cell.column.Header;
          const header = _.isFunction(headerVal) ? headerVal({ ...tableInstance, column: {} as any }) : headerVal;
          const tdClassName = _.isFunction(tdClass) ? tdClass(cell) : tdClass;
          const cellProps = cell.getCellProps();
          const favoritesStyling = cell.column.id === 'favorites-last-col' ? 'col-delete' : undefined;
          const cellClass = getClassNames([
            tableTdClass,
            _.get(cellProps, 'className', undefined),
            tdClassName,
            priceClass,
            favoritesStyling,
          ]);
          const columnWidth = columnWidths && !_.isEmpty(columnWidths) ? columnWidths[index] : undefined;
          const key = _.get(cellProps, 'key', `td-${index}`);
          return (
            <ResponsiveTdMemo
              key={key}
              {...cellProps}
              columnWidth={columnWidth}
              numColumns={numColumns}
              header={responsiveDisabled ? undefined : header}
              className={cellClass}
            >
              {cell.render('Cell')}
            </ResponsiveTdMemo>
          );
        })}
      </tr>
    </>
  );
};
