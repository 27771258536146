import * as tslib_1 from "tslib";
import { toast } from 'react-toastify';
import { useMemo } from 'react';
export var useAlerts = function useAlerts() {
  var getToastOptions = function getToastOptions(options, defaults) {
    var _a = options || {},
        id = _a.id,
        unClosable = _a.unClosable,
        autoClose = _a.autoClose,
        toastOptions = tslib_1.__rest(_a, ["id", "unClosable", "autoClose"]);

    return Object.assign({}, defaults, {
      toastId: id,
      className: unClosable ? 'not-closable' : undefined,
      closeButton: unClosable ? false : undefined,
      draggable: unClosable ? false : undefined,
      autoClose: unClosable ? false : autoClose
    }, toastOptions);
  };

  var dismiss = function dismiss(id) {
    toast.dismiss(id);
  };

  var error = function error(content, options) {
    toast.error(content, getToastOptions(options, {
      autoClose: false
    }));
  };

  var info = function info(content, options) {
    toast.info(content, getToastOptions(options));
  };

  var success = function success(content, options) {
    toast.success(content, getToastOptions(options));
  };

  var warn = function warn(content, options) {
    toast.warn(content, getToastOptions(options, {
      autoClose: false
    }));
  };

  return useMemo(function () {
    return {
      dismiss: dismiss,
      error: error,
      info: info,
      success: success,
      warn: warn
    };
  }, []);
};