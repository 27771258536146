import { PriceChartSettings } from '@tradingblock/types';
import { SettingsFields } from '../../../components/form/GenericField';
import { useBlockId } from '../../../components/blocks/BlockState';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { blockSettings } from '../../../data/global/selectors/settingSelectors';

export type PriceChartSettingsKey = 'savedData' | 'interval' | 'showLeftToolbar' | 'chartType';

export const DefaultPriceChartSettings: PriceChartSettings = {
  debug: false,
  // theme: 'Dark',
  // defaultInterval: '60',
  // showLeftToolbar: false,
};

// const TimeIntervals = ['60', '30', '5', '1', 'Day'];
export const PriceChartSettingsFields: SettingsFields = {
  debug: {
    type: 'boolean',
  },
  // these settings are now persisted as part of the chart data
  // defaultInterval: {
  //   options: TimeIntervals,
  // },
  // showLeftToolbar: {
  //   type: 'boolean',
  // },
};

// if not in the development environment then remove the debug setting
if (process.env.NODE_ENV !== 'development') {
  delete PriceChartSettingsFields.debug;
}

export const usePriceChartSettings = () => {
  const blockId = useBlockId();
  const settings = useStateSelector(s => blockSettings.pricechart(s, blockId));
  return settings;
};
