import React, { useCallback, useState } from 'react';
import { BasicCheckboxInput, OnChangeHandler } from '@tradingblock/components';
import { Order, OrderLeg, TradingblockOrder } from '@tradingblock/types';

export interface AdminBypassMarginProps {
  order: Order<OrderLeg> | TradingblockOrder | undefined;
  setOrder: (order: Order<OrderLeg> | TradingblockOrder) => void;
}

export default function AdminBypassMargin({ order, setOrder }: AdminBypassMarginProps) {
  const [bypassMargin, setBypassMargin] = useState<boolean>(false);

  const handleBypassMargin = useCallback(
    (event, value) => {
      if (order !== undefined) {
        setBypassMargin(value);
        setOrder({
          ...order,
          BypassMargin: value,
        });
      }
    },
    [bypassMargin, order, setOrder]
  );

  return (
    <div>
      <strong>
        <p className="warn">CAUTION: IF CHECKBOX BELOW IS SELECTED, THIS ORDER WILL BYPASS MARGIN CHECK PROCEDURE.</p>
      </strong>
      <BasicCheckboxInput checked={bypassMargin} onchange={handleBypassMargin}>
        Bypass Margin
      </BasicCheckboxInput>
    </div>
  );
}
