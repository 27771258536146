import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  CashieringView,
  AccountIdException,
  BalanceTotals,
  WireWithdrawalRequest,
  CheckWithdrawalRequest,
  AchWithdrawalRequest,
  TransferDisbursementType,
} from '@tradingblock/types';
import { CashieringViewFormValues, CashieringViewProps, ViewOnSubmitType } from './data/useCashieringView';
import { useDispatcher } from '../../../data/global/hooks';
import { useCashieringDataContext } from './data/useCashieringData';
import { cashieringSelectors, useStateSelector } from '../../../data/global/dataSelectors';
import { account } from '../../../data/global/selectors/accountSelectors';
import { AccountWithdrawalDisplay } from '../../../components/cashiering/AccountWithdrawalDisplay';

interface WithdrawalReviewViewContentProps extends CashieringViewProps<CashieringViewFormValues> {
  totals: BalanceTotals;
  nickname: string;
  isIRA?: boolean;
  availableForWithdrawal: number;
}

const WithdrawalReviewViewContent: React.FC<WithdrawalReviewViewContentProps> = ({
  nickname,
  totals,
  availableForWithdrawal,
  values,
  children,
}) => {
  const { gotoNextView } = useCashieringDataContext();
  const { transfer } = values;

  const achRelationships = useSelector(cashieringSelectors.achRelationships);
  const transferInstructions = useSelector(cashieringSelectors.transferInstructions);

  const achRelationship = useMemo(
    () => _.find(achRelationships, a => !!transfer && a.id === transfer.achRelationshipId),
    [achRelationships, transfer]
  );
  const transferInstruction = useMemo(
    () => _.find(transferInstructions, i => !!transfer && i.id === transfer.instructionId),
    [transferInstructions, transfer]
  );

  return (
    <AccountWithdrawalDisplay
      nickname={nickname}
      availableForWithdrawal={availableForWithdrawal}
      availableForTrading={totals.availableForTrading}
      transfer={transfer}
      achRelationship={achRelationship}
      transferInstructions={transferInstruction}
      gotoNextView={gotoNextView}
    >
      {children}
    </AccountWithdrawalDisplay>
  );
};

const WithdrawalReviewView: React.FC<CashieringViewProps<CashieringViewFormValues>> = props => {
  const totals = useSelector(cashieringSelectors.balanceTotals);
  const accountInfo = useStateSelector(s =>
    s.account
      ? {
          nickname: s.account.nickname,
          accountNumber: s.account.accountNumber,
          isIRA: s.account.isIRA,
        }
      : undefined
  );
  const balancesForWithdrawal = useStateSelector(s => s.accountData.balances.balancesForWithdrawal);
  const availableForWithdrawal = balancesForWithdrawal ? balancesForWithdrawal.availableForWithdrawal : 0;

  return (
    <>
      {totals && accountInfo ? (
        <WithdrawalReviewViewContent
          {...props}
          totals={totals}
          {...accountInfo}
          availableForWithdrawal={availableForWithdrawal}
        />
      ) : (
        `Account details not loaded`
      )}
    </>
  );
};

export const useWithdrawalReviewView = (): [typeof WithdrawalReviewView, ViewOnSubmitType, undefined] => {
  const { dispatcher } = useDispatcher();
  const accountId = useStateSelector(account.accountId);
  const subAccounts = useSelector(account.subAccounts);

  const onSubmit = useCallback(
    (values: CashieringViewFormValues, view: CashieringView | undefined) => {
      if (accountId === undefined) {
        throw AccountIdException;
      }

      const transfer = _.pick(values.transfer, [
        'amount',
        'disbursementType',
        'mechanism',
        'achRelationshipId',
        'instructionId',
        'nameOnAccount',
        'deliveryMethod',
        'memos',
        'subAccountsValues',
        'subAccountId',
      ]);
      if (!transfer.amount || !transfer.disbursementType) {
        throw new Error('Invalid transfer amount');
      }

      // if also closing account, use different disbursementType
      /* 
        due to differences in balance calculations between our backend and our clearing firms
        we must send FullBalance or FullBalanceAndCloseAccount with a disbursementType of 'PartialBalance'
        along with the requested transfer amount. This will ensure that our backend and clearing firms
        will have the same transfer amount.
      */

      const amount = transfer.amount;

      // if amount is a string and has preceding $, remove it, then convert to number
      if (typeof amount === 'string' && amount.charAt(0) === '$') {
        transfer.amount = _.toNumber(amount.slice(1));
      }

      const baseWithdrawal = {
        requestedAmount: _.toNumber(transfer.amount),
        disbursementType:
          transfer.disbursementType === 'FullBalance' && transfer.closeAccount
            ? ('FullBalanceAndCloseAccount' as TransferDisbursementType)
            : ('PartialBalance' as TransferDisbursementType),
      };

      switch (transfer.mechanism) {
        case 'Ach':
          if (!transfer.achRelationshipId) {
            throw new Error('Invalid transfer achRelationshipId');
          }

          if (!transfer.subAccountsValues || _.isEmpty(transfer.subAccountsValues)) {
            const achWithdrawal = {
              ...baseWithdrawal,
              relationshipId: transfer.achRelationshipId,
            };
            dispatcher.cashiering.transfers.achWithdrawal.request({
              accountId,
              subaccountId: transfer.subAccountId,
              ...achWithdrawal,
            });
            break;
          }

          if (transfer.subAccountsValues && Object.values(transfer.subAccountsValues).length > 0) {
            let achWithdrawals: AchWithdrawalRequest[] = [];
            Object.keys(transfer.subAccountsValues).forEach(subAccountId => {
              const subAccount = subAccounts && _.find(subAccounts, s => String(s.Id) === subAccountId);
              if (
                subAccount &&
                transfer.achRelationshipId &&
                transfer.subAccountsValues &&
                transfer.subAccountsValues[subAccountId] !== 0
              ) {
                achWithdrawals.push({
                  ...baseWithdrawal,
                  relationshipId: transfer.achRelationshipId,
                  subaccountId: subAccount.Id,
                  requestedAmount: _.toNumber(transfer.subAccountsValues && transfer.subAccountsValues[subAccountId]),
                  note: `Withdraw from ${subAccount.Nickname}: $${transfer.subAccountsValues &&
                    transfer.subAccountsValues[subAccountId]}.`,
                });
              }
            });

            if (achWithdrawals) {
              achWithdrawals.forEach(achWithdrawal => {
                dispatcher.cashiering.transfers.achWithdrawal.request({
                  accountId,
                  subaccountId: achWithdrawal.subaccountId,
                  ...achWithdrawal,
                });
              });
            }
            break;
          }

        case 'Wire':
          if (!transfer.instructionId) {
            throw new Error('Invalid transfer instructionId');
          }
          if (!transfer.subAccountsValues || _.isEmpty(transfer.subAccountsValues)) {
            const wireWithdrawal = {
              ...baseWithdrawal,
              instructionId: transfer.instructionId,
            };
            dispatcher.cashiering.transfers.wireWithdrawal.request({
              accountId,
              subaccountId: transfer.subAccountId,
              ...wireWithdrawal,
            });
            break;
          }
          if (transfer.subAccountsValues && Object.values(transfer.subAccountsValues).length > 0) {
            let wireWithdrawals: WireWithdrawalRequest[] = [];
            Object.keys(transfer.subAccountsValues).forEach(key => {
              const subAccount = subAccounts && subAccounts.find(sub => String(sub.Id) === key);
              if (
                subAccount &&
                transfer.subAccountsValues &&
                transfer.subAccountsValues[key] !== 0 &&
                transfer.instructionId
              ) {
                wireWithdrawals.push({
                  ...baseWithdrawal,
                  instructionId: transfer.instructionId,
                  requestedAmount: _.toNumber(transfer.subAccountsValues[key]),
                  subaccountId: subAccount.Id,
                  note: `Withdraw from ${subAccount.Nickname}: $${transfer.subAccountsValues[key]}.`,
                });
              }
            });
            if (wireWithdrawals) {
              wireWithdrawals.forEach(w => {
                dispatcher.cashiering.transfers.wireWithdrawal.request({
                  accountId,
                  subaccountId: w.subaccountId,
                  ...w,
                });
              });
            }
            break;
          }

        case 'Check':
          if (!transfer.deliveryMethod) {
            throw new Error('Invalid transfer deliveryMethod');
          }
          if (!transfer.nameOnAccount) {
            throw new Error('Invalid transfer name on account');
          }
          if (!transfer.subAccountsValues || _.isEmpty(transfer.subAccountsValues)) {
            const checkWithdrawal = {
              ...baseWithdrawal,
              nameSecret: transfer.nameOnAccount,
              deliveryMethod: transfer.deliveryMethod,
              memo1: _.get(transfer.memos, 0),
              memo2: _.get(transfer.memos, 1),
              memo3: _.get(transfer.memos, 2),
            };
            dispatcher.cashiering.transfers.checkWithdrawal.request({
              accountId,
              subaccountId: transfer.subAccountId,
              ...checkWithdrawal,
            });
            break;
          }
          if (transfer.subAccountsValues && Object.values(transfer.subAccountsValues).length > 0) {
            let checkWithdrawals: CheckWithdrawalRequest[] = [];
            Object.keys(transfer.subAccountsValues).forEach(key => {
              const subAccount = subAccounts && subAccounts.find(sub => String(sub.Id) === key);
              if (
                subAccount &&
                transfer.subAccountsValues &&
                transfer.subAccountsValues[key] !== 0 &&
                transfer.deliveryMethod
              ) {
                checkWithdrawals.push({
                  ...baseWithdrawal,
                  nameSecret: transfer.nameOnAccount,
                  deliveryMethod: transfer.deliveryMethod,
                  memo1: _.get(transfer.memos, 0),
                  memo2: _.get(transfer.memos, 1),
                  memo3: _.get(transfer.memos, 2),
                  requestedAmount: _.toNumber(transfer.subAccountsValues && transfer.subAccountsValues[key]),
                  subaccountId: subAccount.Id,
                  note: `Withdraw from ${subAccount.Nickname}: $${transfer.subAccountsValues[key]}.`,
                });
              }
            });
            if (checkWithdrawals) {
              checkWithdrawals.forEach(checkWithdrawal => {
                if (checkWithdrawal.requestedAmount !== 0) {
                  dispatcher.cashiering.transfers.checkWithdrawal.request({
                    accountId,
                    subaccountId: checkWithdrawal.subaccountId,
                    ...checkWithdrawal,
                  });
                }
              });
            }
            break;
          }

        default:
          console.warn(
            'useWithdrawalReviewView :: not calling any action for ',
            transfer.mechanism,
            ' withdrawal... ',
            transfer
          );
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId]
  );

  return [WithdrawalReviewView, onSubmit, undefined];
};
