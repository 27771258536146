import React from 'react';
import _ from 'lodash';
import { CurrentItemApplicationModel } from '@tradingblock/types';
import { AdminApplicationActions } from '../../../../../data/global/actions/admin/AdminApplicationActions';
import { useDispatcher } from '../../../../../data/global/hooks';

export const ApplicationsActionCell: React.FunctionComponent<CurrentItemApplicationModel> = application => {
  const { dispatch } = useDispatcher();
  const handleClick = (e: any) => {
    e.preventDefault();
    dispatch(AdminApplicationActions.setAppCurrentItem(application));
  };
  return (
    <>
      <button className="btn" onClick={e => handleClick(e)}>
        View
      </button>
    </>
  );
};
