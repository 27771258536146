import { ActionType, createStandardAction } from 'typesafe-actions';
import {
  ApiResponse,
  ClientEntity,
  GenericError,
  SearchAccountsRequest,
  SearchAccountsResponse,
} from '@tradingblock/types';
import { buildGenericRequestReceiveActions } from '../../actionUtilities';
import { AdminIdRequest } from '.';

const ADMIN_CLIENT_REQUEST = '@admin/clients/get/REQUEST';
const ADMIN_CLIENT_RECEIVE = '@admin/clients/get/RECEIVE';
const ADMIN_CLIENT_ERROR = '@admin/clients/get/ERROR';

const ADMIN_CLIENT_SEARCH_SET_SEARCH = '@admin/clients/search/ui/SET_SEARCH';
const ADMIN_CLIENT_SEARCH_SET_PAGE = '@admin/clients/search/ui/SET_PAGE';
const ADMIN_CLIENT_SEARCH_SET_PAGE_SIZE = '@admin/clients/search/ui/SET_PAGE_SIZE';
const ADMIN_CLIENT_SEARCH_SET_SORTBY = '@admin/clients/search/ui/SET_SORTBY';

const [clientRequest, clientReceive, clientError] = buildGenericRequestReceiveActions({
  REQUEST: ADMIN_CLIENT_REQUEST,
  RECEIVE: ADMIN_CLIENT_RECEIVE,
  ERROR: ADMIN_CLIENT_ERROR,
})<
  SearchAccountsRequest,
  { response: ApiResponse<SearchAccountsResponse>; request: SearchAccountsRequest },
  GenericError<any, SearchAccountsRequest>
>();

export const AdminClientActions = {
  clientRequest,
  clientReceive,
  clientError,
  setSearch: createStandardAction(ADMIN_CLIENT_SEARCH_SET_PAGE)<Pick<SearchAccountsRequest, 'search'>>(),
  setPage: createStandardAction(ADMIN_CLIENT_SEARCH_SET_PAGE)<Pick<SearchAccountsRequest, 'page'>>(),
  setPageSize: createStandardAction(ADMIN_CLIENT_SEARCH_SET_PAGE_SIZE)<Pick<SearchAccountsRequest, 'pageSize'>>(),
  setSortBy: createStandardAction(ADMIN_CLIENT_SEARCH_SET_SORTBY)<
    Pick<SearchAccountsRequest, 'sortBy' | 'sortByOrder'>
  >(),
};

export type AdminClientAction = ActionType<typeof AdminClientActions>;
