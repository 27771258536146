import React, { useMemo } from 'react';
import { formatDate } from '../../../../../utilities/date';
import { useStateSelector } from '../../../../../data/global/dataSelectors';
import {
  AccountManagementUpdateDetailTypes,
  AccountUpdateDetails,
  DefaultAccountUpdateDetails,
  UserUpdateAccountDetails,
  DefaultUserUpdateAccountDetails,
  AccountUpdateAuditItem,
  AccountManagementBaseUpdate,
} from '@tradingblock/types';

export const AccountUpdateEntityAuditItems: React.FunctionComponent<Pick<AccountManagementBaseUpdate, 'item'>> = ({
  item,
}) => {
  const updateDetails: AccountUpdateDetails =
    useStateSelector(s => s.private.accountManagement.currentItem.details) || DefaultAccountUpdateDetails;
  let accountDetails: UserUpdateAccountDetails =
    useStateSelector(s => s.private.accountManagement.currentItem.accountInfo) || DefaultUserUpdateAccountDetails;

  const updateActions = useMemo(() => {
    return updateDetails;
  }, [updateDetails]);

  const accountActions = useMemo(() => {
    return accountDetails;
  }, [accountDetails]);

  if (!updateActions && !accountActions) {
    return null;
  }

  return (
    <>
      <AccountUpdateEntityItem statusAction={updateActions.created} label="Created" />
      {item === 'Address' && accountActions.lastChanged && (
        <AccountUpdateEntityItem statusAction={accountActions.lastChanged} label="Last Modified" />
      )}
      {item === 'MailingAddress' && accountActions.lastChanged && (
        <AccountUpdateEntityItem statusAction={accountActions.lastChanged} label="Last Modified" />
      )}
      {item === 'Email' && accountActions.lastChanged && (
        <AccountUpdateEntityItem statusAction={accountActions.lastChanged} label="Last Modified" />
      )}
      {item === 'PhoneNumber' && accountActions.lastChanged && (
        <AccountUpdateEntityItem statusAction={accountActions.lastChanged} label="Last Modified" />
      )}
      {item === 'Financial' && accountActions.lastChanged && (
        <AccountUpdateEntityItem statusAction={accountActions.lastChanged} label="Last Modified" />
      )}
      <AccountUpdateEntityItem statusAction={updateActions.firmReviewed} label="Firm Reviewed" />
      {updateActions.state === 'Rejected' && (
        <AccountUpdateEntityItem statusAction={updateActions.firmRejected} label="Rejected" />
      )}
    </>
  );
};

const AccountUpdateEntityItem: React.FunctionComponent<{
  statusAction: any | undefined;

  label: string;
}> = ({ statusAction, label }) => {
  if (!statusAction) {
    return null;
  }
  if (statusAction.address || statusAction.email || statusAction.phone) {
    return (
      <p>
        {label}:
        <br />
        <span className="mute">
          {statusAction.date ? formatDate(statusAction.date) : ''}
          {statusAction.address ? `Address: ${formatDate(statusAction.address)} ` : ''}
          <br />
          {statusAction.email ? `Email: ${formatDate(statusAction.email)} ` : ''}
          <br />
          {statusAction.phone ? `Phone: ${formatDate(statusAction.phone)} ` : ''}
          {statusAction.name ? ` by ${statusAction.name}` : ''}
          {statusAction.reason && (
            <>
              <br />
              Reason: {statusAction.reason}
            </>
          )}
        </span>
      </p>
    );
  } else {
    return (
      <p>
        {label}:
        <br />
        <span className="mute">
          {statusAction.date ? formatDate(statusAction.date) : ''}
          {statusAction.name ? ` by ${statusAction.name}` : ''}
          {statusAction.reason && (
            <>
              <br />
              Reason: {statusAction.reason}
            </>
          )}
        </span>
      </p>
    );
  }
};
