import React from 'react';
import _ from 'lodash';
import { Number } from '@tradingblock/components';
import { OrderAction, OptionType, AssetSymbol, Expiration } from '@tradingblock/types';
import { useStateSelector, blocksSelectors } from '../../../data/global/dataSelectors';
import { OptionChainOrderDropdown } from '../OptionChainOrderDropdown';
import { useBlockMetadata, useBlockSymbol } from '../../../components/blocks/BlockState';
import { OptionChainField } from '../OptionChainTypes';
import { getQuoteBidAsk } from '../../../data/global/selectors/quoteSelector';
import { DirectionalValue } from '../../../components/basic/Directional';

const SymbolBidAsk: React.FC<{ occSymbol: string; assetSymbol: AssetSymbol; orderActionForBid: OrderAction; field: OptionChainField; optionType: OptionType; expiration: Expiration; strike: number }> = ({ optionType, field, orderActionForBid, expiration, strike, assetSymbol, occSymbol }) => {
  const { blockId } = useBlockMetadata();
  const orderBlocksExist = useStateSelector(s => blocksSelectors.linkableOrderBlockExists(s, blockId));
  const bidAskValue = useStateSelector(s => {
    const quote = getQuoteBidAsk(s, occSymbol);
    if (_.isNil(quote)) {
      return 0;
    }
    return field === 'Ask' ? quote.AskPrice : quote.BidPrice;
  });
  // const [currentValue, setCurrentValue] = useState();
  // const [direction, setDirection] = useState();
  // useEffect(() => {
  //   if (bidAskValue && bidAskValue !== currentValue) {
  //     if (currentValue === undefined) {
  //       setDirection(undefined);
  //     } else if (currentValue > bidAskValue) {
  //       setDirection('down');
  //     } else if (currentValue < bidAskValue) {
  //       setDirection('up');
  //     }
  //     setCurrentValue(bidAskValue);
  //   }
  // }, [bidAskValue, currentValue]);
  const optionChainDropdownProps = {
    expiration,
    strike,
    occSymbol,
  };
  return (
    <OptionChainOrderDropdown field={field} symbol={assetSymbol} optionType={optionType} orderBlocksExist={orderBlocksExist} orderActionForBid={orderActionForBid} {...optionChainDropdownProps}>
      <DirectionalValue value={bidAskValue}>
        <Number value={bidAskValue} />
      </DirectionalValue>
    </OptionChainOrderDropdown>
  );
};

const BidAsk: React.FC<{ orderActionForBid: OrderAction; field: OptionChainField; optionType: OptionType; expiration: Expiration; strike: number; symbol: string }> = ({ symbol, ...props }) => {
  //const assetSymbol = useOptionChainData(s => s.symbol);
  const assetSymbol = useBlockSymbol();
  return <>{assetSymbol && <SymbolBidAsk {...props} assetSymbol={assetSymbol} occSymbol={symbol} />}</>;
};

export const BidAskMemo = React.memo(BidAsk);
