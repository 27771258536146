import _ from 'lodash';
import { DataState } from '../state';
import { formatAccountNumber } from '../../../utilities/account';
import { account as accountSel } from '../dataSelectors';

export const account = {
  ...accountSel,
  firstAndLastName: (s: DataState) =>
    s.accounts.account && s.accounts.account.FirstName && s.accounts.account.LastName
      ? `${s.accounts.account.FirstName} ${s.accounts.account.MiddleInitial || ''} ${s.accounts.account.LastName} ${s
          .accounts.account.Suffix || ''}`.trim()
      : '',
  accountLoaded: (s: DataState) => _.isNumber(s.accounts.account && s.accounts.account.AccountId),
  allAccounts: (s: DataState) => s.accounts.accounts,
  accountNickname: (s: DataState) => {
    if (s.account.account) {
      const subAccount = _.find(s.account.subaccounts.subaccounts, sa => sa.Id === s.account.subAccountId);
      return formatAccountNumber(
        s.account.accountNumber,
        subAccount && subAccount.AcctSuffix,
        s.accountManagement &&
          s.accountManagement.accountManagementDetails &&
          s.accountManagement.accountManagementDetails.details &&
          s.accountManagement.accountManagementDetails.details.flexibilityType
      );
    }
    return '';
  },
  masterAccount: (s: DataState) =>
    s.account.subaccounts.subaccounts ? s.account.subaccounts.subaccounts.find(sa => sa.IsMaster) : undefined,
  accountId: (s: DataState) =>
    s.account.account && s.account.account.AccountId ? s.account.account.AccountId : undefined,
  subAccountId: (s: DataState) => s.account.subAccountId,
  accountIsIRA: (s: DataState) => s.account.isIRA,
  accountIsUGMA: (s: DataState) => s.account.isUGMA,
  accountIsPartnership: (s: DataState) => s.account.isPartnership,
  userLevel: (s: DataState) => s.account.profile && s.account.profile.current && s.account.profile.current.level,
  userRoles: (s: DataState) => s.account.profile && s.account.profile.current && s.account.profile.current.roles,
  accountNumber: (s: DataState) => s.account.accountNumber,
  showAccountSwitcher: (s: DataState) => s.account.showAccountSwitcher,
  hasSubAccounts: (s: DataState) => !_.isEmpty(s.account.subaccounts.subaccounts),
  hasLinkedAccounts: (s: DataState) => {
    return s.linkedAccounts.accounts && s.linkedAccounts.accounts.length > 1 ? true : false;
  },
  hasMultipleAccounts: (s: DataState) => {
    return s.account.hasMultipleAccounts;
  },
  isSelectedLinkedAccountLoaded: (s: DataState) => s.account.isSelectedLinkedAccountLoaded,
  linkedAccounts: (s: DataState) => s.linkedAccounts,
  multipleAccounts: (s: DataState) => s.linkedAccounts.accounts,
  totalLinkedAccounts: (s: DataState) => s.linkedAccounts.total,
  subAccounts: (s: DataState) => s.account.subaccounts.subaccounts,
  subAccountsIsFetching: (s: DataState) => s.account.subaccounts.isFetching,
  balances: (s: DataState) => (s.accountData.balances ? s.accountData.balances.balances : undefined),
  linkedAccountsBalances: (s: DataState) => s.accountData.accountsBalances,
  subAccountsBalances: (s: DataState) => s.accountData.subAccountsBalances,
  totals: (s: DataState) => (s.accountData.balances ? s.accountData.balances.totals : undefined),
  isFetching: (s: DataState) => (s.accountData.balances ? s.accountData.balances.isFetching : false),
  todaysChangePercent: (s: DataState) =>
    s.accountData.balances && s.accountData.balances.totals && s.accountData.balances.totals.todaysChangePercent
      ? s.accountData.balances.totals.todaysChangePercent
      : undefined,
  type: (s: DataState) => (s.account.account ? s.account.account.AccountType : undefined),
};
