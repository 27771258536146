/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react';
import _ from 'lodash';
import { ITradingBlockApi } from '@tradingblock/api';
import { Expiration } from '@tradingblock/types';
import { useWhyDidYouUpdate } from '@tradingblock/components';
import { useOrderData } from '../state/OrderState';
import { OrderSelectors } from '../state/OrderSelector';
import { useDispatcher, TradingBlockDispatcher } from '../../../data/global/hooks';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { StrikeSelectors, ExpirationSelectors } from '../../../data/global/selectors/expirationStrikeSelector';
import { useApi } from '../../../context/Api';
import { isSameExpiration } from '../../../utilities/date';

// const loadStrikes = (api: ITradingBlockApi, dispatcher: TradingBlockDispatcher) => async (symbolVal: string, expirationVal: Expiration) => {
//   try {
//     dispatcher.strikes.request(symbolVal, expirationVal);
//     const request = await api.options.strikes(symbolVal, expirationVal.date, expirationVal.optionExpirationType);
//     const strikes = request.payload;
//     dispatcher.strikes.receive(symbolVal, expirationVal, strikes);
//     return strikes;
//   } catch (err) {
//     console.warn('loading strikes failed');
//   }
// };

export const useExpirationLoader = (symbol: string) => {
  const expirations = useStateSelector(state => ExpirationSelectors.expirations(state, symbol));
  const expirationsLoading = useStateSelector(state => ExpirationSelectors.isFetching(state, symbol));
  const expirationsAreLoaded = useMemo(() => expirations && expirations.length > 0, [expirations]);
  const { dispatcher } = useDispatcher();
  const api = useApi();
  useEffect(() => {
    const loadExpirations = async (symbolVal: string) => {
      try {
        dispatcher.expirations.request(symbolVal);
        // const request = await api.options.expirations(symbolVal);
        // const expirations = request.payload.expirations;
        // dispatcher.expirations.receive(symbolVal, expirations);
      } catch (err) {
        console.warn('loading expirations failed');
      }
    };
    if (symbol && !expirationsAreLoaded && !expirationsLoading) {
      loadExpirations(symbol);
    }
  }, [symbol]);
  useWhyDidYouUpdate('Order:useExpirationLoader', {
    symbol,
    expirations,
    expirationsLoading,
    expirationsAreLoaded,
    dispatcher,
    api,
  });
};

// const useStrategyRules = (): { expirationRule: ExpirationRules; strikeRule: StrikeRules | undefined } => {
//   const strategy = useOrderData(OrderSelectors.strategy);
//   const expirationRule = () => {
//     if (!strategy || isCustomStrategy(strategy)) {
//       return ExpirationRules.SameExpiry;
//     }
//     return strategy.profile.Rules && strategy.profile.Rules.Expiration ? strategy.profile.Rules.Expiration : ExpirationRules.SameExpiry;
//   };
//   const strikeRule = () => {
//     if (!strategy || isCustomStrategy(strategy)) {
//       return undefined;
//     }
//     return strategy.profile.Rules && strategy.profile.Rules.Strike ? strategy.profile.Rules.Strike : undefined;
//   };
//   return {
//     expirationRule: expirationRule(),
//     strikeRule: strikeRule(),
//   };
// };
// const useLegExpirations = (symbol: string) => {
//   const strategy = useOrderData(OrderSelectors.strategy);
//   const legCount = useMemo(() => (strategy && !isCustomStrategy(strategy) ? strategy.info.LegsAmount : 1), [strategy]);
//   const { expirationRule } = useStrategyRules();
//   const expirations = useStateSelector(state => ExpirationSelectors.expirations(state, symbol || ''));
//   const values = useMemo(() => {
//     const defaultValues = _(expirations)
//       .map(d => dayjs(d))
//       .filter((d: dayjs.Dayjs): boolean => {
//         return d.isAfter(dayjs().add(3, 'week')) && d.isBefore(dayjs().add(5, 'week'));
//       })
//       .orderBy(d => d.unix())
//       .take(expirationRule === ExpirationRules.DifferentExpiries ? legCount : 1)
//       .value();
//     return _.unionBy(defaultValues, v => v.unix());
//   }, [expirations]);
//   return values;
// };

export const useOptionDefaults = (symbol: string) => {
  const legExpirations = useOrderData(OrderSelectors.legExpirations);
  const expirationsBySymbol: Expiration[] = useStateSelector(state =>
    StrikeSelectors.expirationsBySymbol(state, symbol)
  );
  const existingLegExpirationWithoutStrikes = useMemo(() => {
    const res = _.filter(legExpirations, exp => {
      const existingStrikes = _.find(expirationsBySymbol, strikeExp => isSameExpiration(strikeExp, exp.expiration));
      return existingStrikes ? false : true;
    });
    return res;
  }, [legExpirations, expirationsBySymbol]);
  const api = useApi();
  const { dispatcher } = useDispatcher();
  // const strikeFetcher = loadStrikes(api, dispatcher);
  useEffect(() => {
    const loadExpirationStrikes = async (expirationValues: Expiration[]) => {
      const strikes = _.map(expirationValues, exp => {
        dispatcher.strikes.request(symbol, exp);
        //strikeFetcher(symbol, exp)
      });
      const allStrikes = await Promise.all(strikes);
      return allStrikes;
    };

    if (existingLegExpirationWithoutStrikes.length > 0) {
      loadExpirationStrikes(existingLegExpirationWithoutStrikes.map(e => e.expiration));
    }
  }, [symbol, existingLegExpirationWithoutStrikes]);
};
