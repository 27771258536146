import { OptionType } from '@tradingblock/components';
import { AssetSymbol, BlockType } from '@tradingblock/types';
import React, { useCallback, useState } from 'react';
import { getFavorites, useStateSelector } from '../../data/global/dataSelectors';
import { useDispatcher } from '../../data/global/hooks';
import { SymbolPickerHeader } from '../shared/SymbolPickerHeader';

export const FavoritesHeader = () => {
  const { dispatcher } = useDispatcher();

  const onSelect = useCallback(
    (value: OptionType<AssetSymbol>) => {
      const symbolName = value ? value.data : undefined;
      if (symbolName) {
        dispatcher.favorites.add(symbolName);
      }
      setSymbolFound(false);
    },
    [dispatcher.favorites]
  );

  const { favorites } = useStateSelector(s => ({
    favorites: getFavorites(s),
  }));

  const [symbolFound, setSymbolFound] = useState(false);

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div>
          <SymbolPickerHeader
            blockType={BlockType.Favorites}
            symbol={undefined}
            isLoaded={true}
            onSelect={onSelect}
            onBlur={() => setSymbolFound(false)}
            onSearch={(query) => {
              if (!query) {
                setSymbolFound(false);
              } else {
                const found = favorites.find((f) => f.symbol.toLowerCase() === query.toLowerCase());
                setSymbolFound(found !== undefined);
              }
            }}
            placeholder="Add..."
            clearOptionsOnSelect={true}
          />
        </div>
        <div className="error">
          {symbolFound && <span>Symbol already in favorites</span>}
        </div>
      </div>
    </>
  );
};
