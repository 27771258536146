import { useMemo } from 'react';
import _ from 'lodash';
import { OrderType, OrderAction, TradingStrategyNameOrCustom, DebitOrCredit, AssetType } from '@tradingblock/types';
import { useOrderData, useOrderDataSelector } from '../state/OrderState';
import { useOrderPrice } from './useOrderPrice';
import { orderLegsToClassifiedStrategy } from '../../../data/global/utilities/strategyClassification';
import { OrderSelectors } from '../state/OrderSelector';
import { determineIsBuy } from '../OrderUtilities';

const calculateStrategyTotal = (
  isLimitOrder: boolean,
  orderType: OrderType,
  orderAction: OrderAction,
  ask: number,
  bid: number,
  quantity: number,
  totalMultiple: number,
  gcd: number,
  limitPrice: number | undefined,
  stopPrice: number | undefined,
  strategy: TradingStrategyNameOrCustom | undefined,
  debitOrCredit: DebitOrCredit,
  isBuy: boolean | undefined
) => {
  let orderPrice: number | undefined = 0;
  let execDebitOrCredit: DebitOrCredit | undefined = undefined;

  if (orderType === OrderType.Stop_Market) {
    orderPrice = stopPrice;
  } else if (orderType === OrderType.Stop_Limit) {
    orderPrice = limitPrice;
  } else if (isLimitOrder && limitPrice !== undefined) {
    if (isBuy || isBuy === undefined) {
      if (debitOrCredit === DebitOrCredit.Debit) {
        orderPrice = Math.min(ask, limitPrice);
      } else {
        orderPrice = Math.min(ask, -limitPrice);
      }
      if (orderPrice >= 0.0) {
        execDebitOrCredit = DebitOrCredit.Debit;
      } else {
        execDebitOrCredit = DebitOrCredit.Credit;
      }
    } else {
      if (debitOrCredit === DebitOrCredit.Credit) {
        orderPrice = Math.max(bid, limitPrice);
      } else {
        orderPrice = Math.max(bid, -limitPrice);
      }
      if (orderPrice >= 0.0) {
        execDebitOrCredit = DebitOrCredit.Credit;
      } else {
        execDebitOrCredit = DebitOrCredit.Debit;
      }
    }
  } else if (isLimitOrder) {
    orderPrice = undefined;
  } else {
    orderPrice = isBuy ? ask : bid;
  }

  const total = orderPrice && _.isFinite(orderPrice) ? quantity * orderPrice * totalMultiple * gcd : 0;
  return {
    total: Math.abs(total),
    execDebitOrCredit,
  };
};

export const useOrderTotal = () => {
  const orderAction = useOrderDataSelector(s => s.action);
  const orderType = useOrderDataSelector(s => s.orderType);
  const isLimitOrder = useOrderDataSelector(s => s.orderType === OrderType.Limit);
  const strategy = useOrderDataSelector(s => s.strategy);
  const legs = useOrderData(OrderSelectors.legs);
  const isCustomOrder = strategy === 'Custom';
  const classifiedStrategy = orderLegsToClassifiedStrategy(legs);
  const isStrategyMatch = classifiedStrategy && classifiedStrategy.name !== 'Custom';
  const isClassifiedAsCalendar = _.includes(classifiedStrategy.name, 'Calendar');
  const isCustomWithoutClassifiedStrategy = isCustomOrder && !isStrategyMatch;

  const { quantity, debitCredit, price, stopPrice } = useOrderDataSelector(s => ({
    price: s.price,
    stopPrice: s.stopPrice,
    quantity: _.isFinite(s.quantity) ? s.quantity : 1,
    debitCredit: s.debitCredit,
  }));
  const isBuy = determineIsBuy(
    isCustomOrder,
    orderAction,
    isClassifiedAsCalendar,
    isStrategyMatch,
    classifiedStrategy,
    isCustomWithoutClassifiedStrategy,
    debitCredit,
    legs,
    price
  );

  const { ask, bid, totalMultiple, gcd, marginCost } = useOrderPrice(isBuy);

  return useMemo(() => {
    const totalResults = calculateStrategyTotal(
      isLimitOrder,
      orderType,
      orderAction,
      ask,
      bid,
      quantity,
      totalMultiple,
      gcd,
      price !== undefined ? Math.abs(price) : price,
      stopPrice !== undefined ? Math.abs(stopPrice) : stopPrice,
      strategy,
      debitCredit === undefined ? DebitOrCredit.Debit : debitCredit,
      isBuy
    );

    return { ...totalResults, debitCredit, marginCost: marginCost * quantity * totalMultiple * gcd };
  }, [
    isLimitOrder,
    orderAction,
    ask,
    bid,
    quantity,
    totalMultiple,
    gcd,
    price,
    isCustomOrder,
    debitCredit,
    stopPrice,
    isBuy,
    legs,
    strategy,
  ]);
};
