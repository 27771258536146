import React, { useEffect, useMemo } from 'react';
import { useOrderData, OrderState } from '../state/OrderState';
import { useOrderActions } from '../state/useOrderActions';
import PriceComponent from './PriceComponent';
import { DebitOrCredit, OrderAction } from '@tradingblock/types';
import _ from 'lodash';
import { useCallback } from 'react';
import { OrderSelectors } from '../state/OrderSelector';
import { orderLegsToClassifiedStrategy } from '../../../data/global/utilities/strategyClassification';
import { determineIsBuy } from '../OrderUtilities';

export const OrderPrice: React.FC<{}> = () => {
  const { setPrice, setDebitCredit } = useOrderActions();
  const [areOptionLegsSet, setAreOptionLegsSet] = React.useState(false);
  const [isNegativePrice, setIsNegativePrice] = React.useState(false);
  // allow negative prices if the strategy is custom or calendar spreads
  const legs = useOrderData(OrderSelectors.legs);
  const orderAction = useOrderData(s => s.action);
  const debitCredit = useOrderData(s => s.debitCredit);
  const isDebit = debitCredit === DebitOrCredit.Debit;
  const match = useOrderData(s => s.validation && s.validation.match);
  const isCustomOrder = match === undefined ? true : false;
  const isCustomStrategyOrCalendar = useMemo(
    () => (match ? match.info.Name === 'Call Calendar' || match.info.Name === 'Put Calendar' || isCustomOrder : true),
    [match]
  );
  const price = useOrderData((s: OrderState) => {
    return s.price !== undefined ? (isCustomStrategyOrCalendar ? s.price : s.price) : undefined;
  });
  const classifiedStrategy = orderLegsToClassifiedStrategy(legs);
  const isStrategyMatch = classifiedStrategy.name !== 'Custom';
  const isCustomWithoutClassifiedStrategy = isCustomOrder && !isStrategyMatch;
  const isClassifiedAsCalendar = _.includes(classifiedStrategy.name, 'Calendar');

  const isBuy = determineIsBuy(
    isCustomOrder,
    orderAction,
    isClassifiedAsCalendar,
    isStrategyMatch,
    classifiedStrategy,
    isCustomWithoutClassifiedStrategy,
    debitCredit,
    legs,
    price
  );

  useEffect(() => {
    setIsNegativePrice((isBuy && !isDebit) || (!isBuy && isDebit));
  }, [isBuy, isDebit]);

  const onChange = useCallback(
    (value: number | null) => {
      setPrice(
        value === null ? undefined : !isCustomStrategyOrCalendar && value < 0 ? 0 : value,
        false,
        isCustomStrategyOrCalendar ? isNegativePrice : false
      );
    },
    [isStrategyMatch, isNegativePrice, isCustomStrategyOrCalendar]
  );

  const optionLegs = Object.values(legs).filter(leg => leg.OptionType);

  // check if the order contains any option legs, and if so check to see if a strike and expiration have been set for all option legs
  const checkOptionLegs = () => {
    if (legs) {
      if (optionLegs.length > 0) {
        const strikes = optionLegs.filter(leg => leg.Strike !== undefined);
        const expirations = optionLegs.filter(leg => leg.Expiration !== undefined);
        if (strikes.length === optionLegs.length && expirations.length === optionLegs.length) {
          setAreOptionLegsSet(true);
        } else {
          setAreOptionLegsSet(false);
        }
      }
    }
  };

  useEffect(() => {
    checkOptionLegs();
  }, [optionLegs]);

  return (
    <PriceComponent
      value={price}
      onChange={onChange}
      placeholder="Limit Price..."
      isCustomStrategyOrCalendar={isCustomStrategyOrCalendar}
      disabled={optionLegs && optionLegs.length > 0 && !areOptionLegsSet}
    />
  );
};
