import { useOrderData } from '../state/OrderState';
import { OrderSelectors } from '../state/OrderSelector';
import { useOrderSymbol } from '../state/useOrderState';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { ExpirationSelectors, StrikeSelectors } from '../../../data/global/selectors/expirationStrikeSelector';
import { DataState } from '../../../data/global/state';

export const useOrderStatus = () => {
  const isComplete = useOrderData(OrderSelectors.isComplete);
  const symbol = useOrderSymbol();
  const areFetchingExpirations = useStateSelector(state => ExpirationSelectors.isFetching(state, symbol || ''));
  const areStrikesFetching = useStateSelector((state: DataState) => StrikeSelectors.anyFetching(state, symbol || ''));
  const isLoading = areStrikesFetching || areFetchingExpirations;
  return {
    complete: isComplete,
    loading: isLoading,
  };
};
