import _ from 'lodash';
export var columnWidths = function columnWidths(columns, columnOrder) {
  var fullWidth = _.reduce(columns, function (acc, c) {
    var width = _.get(c, 'width', 1);

    return acc + width;
  }, 0); // if column order is provided, sort the columns into the specified order based on id prior to mapping and calculating widths


  if (columnOrder) {
    columns = _.sortBy(columns, function (c) {
      var index = _.indexOf(columnOrder, c.id);

      return index;
    });
  }

  return _.map(columns, function (c) {
    var width = _.get(c, 'width', 1);

    var widthPercent = _.floor(width / fullWidth * 100);

    return "".concat(widthPercent, "%");
  });
};