import React, { useEffect } from 'react';
import { PreviewActions, useOrderPreviewOrder } from './useOrderPreview';
import PriceComponent from '../PriceComponent';
import { orderLegsToClassifiedStrategy } from '../../../../data/global/utilities/strategyClassification';
import _ from 'lodash';
import { determineIsBuy } from '../../OrderUtilities';
import { DebitOrCredit, OrderAction } from '@tradingblock/types';

export const PreviewLimitPrice: React.FC<{ action: PreviewActions }> = ({ action }) => {
  const { order, legs, changeLimitPrice, changeReplacePrice, replacePrice } = useOrderPreviewOrder(action);
  const classifiedStrategy = orderLegsToClassifiedStrategy(legs);
  const [isNegativePrice, setIsNegativePrice] = React.useState(false);
  const isStrategyMatch = classifiedStrategy.name !== 'Custom';
  const isCustomWithoutClassifiedStrategy = action === 'replace' && !isStrategyMatch;
  const isClassifiedAsCalendar = _.includes(classifiedStrategy.name, 'Calendar');
  const isCustomOrder = classifiedStrategy.name === 'Custom';
  const orderAction = order.Action || OrderAction.Buy;
  const debitCredit = order.DebitCredit || DebitOrCredit.Debit;
  const isDebit = debitCredit === DebitOrCredit.Debit;
  const price = order.Price || 0;

  const isBuy = determineIsBuy(
    isCustomOrder,
    orderAction,
    isClassifiedAsCalendar,
    isStrategyMatch,
    classifiedStrategy,
    isCustomWithoutClassifiedStrategy,
    debitCredit,
    legs,
    price
  );

  useEffect(() => {
    setIsNegativePrice((isBuy && !isDebit) || (!isBuy && isDebit));
  }, [isBuy, isDebit]);

  const handlePriceChange = (value: number | null) => {
    if (action === 'replace') {
      changeReplacePrice(value || 0);
      changeLimitPrice(value || 0, isNegativePrice);
    } else {
      changeLimitPrice(value || 0, isNegativePrice);
    }
  };

  const priceValue = action === 'replace' ? replacePrice || order.Price : order.Price || 0;

  return (
    <>
      {order && (
        <PriceComponent
          onChange={handlePriceChange}
          defaultValue={priceValue ? Math.abs(priceValue) : 0}
          value={priceValue ? Math.abs(priceValue) : 0}
        />
      )}
    </>
  );
};
