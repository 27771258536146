import React, { useCallback } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Formik, Field } from 'formik';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { FormGroup, TextboxField, Modal, SelectDropdownField } from '@tradingblock/components';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { AccountManagementDataActions } from '../../../data/global/actions/AccountManagementActions';
import {
  AllStates,
  Country,
  DefaultPhoneValues,
  getGroupedCountryOptions,
  TrustedContactValueProps,
} from '@tradingblock/types';
import { getRequiredError, USPSErrors, uspsVerification } from './Validation';

export const TrustedContactUpdate: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
}> = ({ show, toggleModal }) => {
  const accountId = useStateSelector(s => s.accounts.account && s.accounts.account.AccountId);
  const dispatch = useDispatch();
  const { firstName, middleInitial, suffix, lastName, address, phones, email } = useStateSelector(
    s =>
      s.accountManagement.accountManagementDetails.details.trustedContact || {
        firstName: '',
        middleInitial: '',
        suffix: '',
        lastName: '',
        address: {
          address1: '',
          address2: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        email: '',
        phones: [
          {
            type: 'Primary',
            phoneNumber: '',
          },
        ],
      }
  );

  const initialTrustedContactValues: TrustedContactValueProps = {
    firstName: firstName ? firstName : '',
    middleInitial: middleInitial ? middleInitial : '',
    suffix: suffix ? suffix : '',
    lastName: lastName ? lastName : '',
    address1: address !== null && address.address1 ? address.address1 : '',
    address2: address !== null && address.address2 ? address.address2 : '',
    city: address !== null && address.city ? address.city : '',
    state: address !== null && address.state ? address.state : '',
    postalCode: address !== null && address.postalCode ? address.postalCode : '',
    country: address !== null && address.country ? address.country : '',
    phones: phones ? [...phones] : [DefaultPhoneValues],
    email: email ? email : '',
  };

  // TODO: Review localStorage usage
  const onValidate = (values: TrustedContactValueProps) => {
    const { address1, city, country, postalCode, state, firstName, lastName, phones, email } = values;
    let uspsResponse;
    let isValidAddress;
    const stateOrPostalCodeRequired = country === Country.UnitedStatesOfAmerica;

    const uspsResponseFromStorage = localStorage.getItem('trustedContactUSPS');

    if (uspsResponseFromStorage && uspsResponseFromStorage !== 'undefined') {
      uspsResponse = JSON.parse(uspsResponseFromStorage);
      const { city, state, zip5, error } = uspsResponse;
      const formattedCityCapitalized = _.capitalize(city);
      const startCaseCity = _.startCase(formattedCityCapitalized);
      const isCityEqual = _.toLower(startCaseCity) === _.toLower(values.city);
      const isZipEqual = zip5 === postalCode;
      const isStateEqual = state === values.state;
      const isAddressCorrect = !error && isCityEqual && isZipEqual && isStateEqual;

      isAddressCorrect ? (isValidAddress = true) : (isValidAddress = false);
    }

    if (address1 && city && state && country === Country.UnitedStatesOfAmerica && !isValidAddress) {
      const getUSPS = async () => {
        await new Promise(resolve => setTimeout(resolve, 100));
        return await uspsVerification(values);
      };

      getUSPS().then(result => {
        localStorage.setItem('trustedContactUSPS', JSON.stringify(result));
      });
    }

    if (uspsResponse) {
      const { city, state, zip5, error } = uspsResponse;

      if (error && values.address1 && values.city && values.state && values.postalCode) {
        return {
          [`postalCode`]: `${error.description.trim()} Please review the entered address.`,
          [`city`]: `${error.description.trim()} Please review the entered address.`,
          [`state`]: `${error.description.trim()} Please review the entered address.`,
          [`address1`]: `${error.description.trim()} Please review the entered address.`,
        };
      }

      if (!values.address1 || !values.city) {
        localStorage.setItem('trustedContactUSPS', 'undefined');
      }

      if (city && state && zip5) {
        const formattedCityCapitalized = _.capitalize(city);
        const startCaseCity = _.startCase(formattedCityCapitalized);
        const isCityEqual = _.toLower(startCaseCity) === _.toLower(values.city);
        const isZipEqual = zip5 === values.postalCode;
        const isStateEqual = state === values.state;

        // USPS Verification Errors
        const errors: USPSErrors = {
          [`firstName`]: getRequiredError(firstName),
          [`lastName`]: getRequiredError(lastName),
          [`middleInitial`]:
            values.middleInitial && values.middleInitial.length > 1
              ? 'Middle initial must be 1 character or less.'
              : undefined,
          // suffix should only be characters and possible ".", no spaces or numbers
          [`suffix`]:
            values.suffix && !/^[a-zA-Z.]*$/.test(values.suffix) ? 'Suffix must be characters only.' : undefined,
          [`email`]: getRequiredError(email),
          [`phones[0].phoneNumber`]: getRequiredError(phones[0].phoneNumber),
          [`address1`]: getRequiredError(address1),
          [`country`]: getRequiredError(country),
          [`city`]:
            getRequiredError(city) || !isCityEqual
              ? `Please verify the city. USPS Provided City: ${startCaseCity}`
              : undefined,
          [`state`]:
            getRequiredError(state) || !isStateEqual
              ? `Please verify the state. USPS Provided State: ${state}`
              : undefined,
          [`postalCode`]:
            getRequiredError(postalCode) || !isZipEqual
              ? `Please verify the zip code. USPS Provided Zip Code: ${zip5}`
              : undefined,
        };

        Object.keys(errors).forEach(key => (errors[key] === undefined ? delete errors[key] : {}));

        return errors;
      }
    }
    const errors: USPSErrors = {
      [`firstName`]: getRequiredError(firstName),
      [`middleInitial`]:
        values.middleInitial && values.middleInitial.length > 1
          ? 'Middle initial must be 1 character or less.'
          : undefined,
      // suffix should only be characters and possible ".", no spaces or numbers
      [`suffix`]: values.suffix && !/^[a-zA-Z.]*$/.test(values.suffix) ? 'Suffix must be characters only.' : undefined,
      [`lastName`]: getRequiredError(lastName),
      [`email`]: getRequiredError(email),
      [`phones[0].phoneNumber`]: getRequiredError(phones[0].phoneNumber),
      [`address1`]: getRequiredError(address1),
      [`country`]: getRequiredError(country),
      [`city`]: getRequiredError(city),
      [`state`]: getRequiredError(stateOrPostalCodeRequired ? state : true),
      [`postalCode`]: getRequiredError(stateOrPostalCodeRequired ? postalCode : true),
    };

    Object.keys(errors).forEach(key => (errors[key] === undefined ? delete errors[key] : {}));

    return errors;
  };

  const onSubmit = useCallback(
    (values, { resetForm }) => {
      const {
        address1,
        address2,
        city,
        country,
        postalCode,
        state,
        firstName,
        middleInitial,
        lastName,
        suffix,
        phones,
        email,
      } = values;
      let updateValues = {};

      updateValues = {
        item: 'TrustedContact',
        trustedContact: {
          firstName,
          middleInitial,
          lastName,
          suffix,
          address: {
            address1,
            address2,
            city,
            country,
            postalCode,
            state,
          },
          email,
          phones: [
            {
              type: 'Primary',
              phoneNumber: phones[0].phoneNumber,
            },
          ],
        },
      };
      dispatch(
        AccountManagementDataActions.requestMakeAccountUpdate({
          accountId: accountId,
          request: updateValues,
        })
      );
      resetForm();
      toggleModal();

      localStorage.setItem('trustedContactUSPS', 'undefined');
    },
    [toggleModal]
  );

  return (
    <Formik
      initialValues={initialTrustedContactValues}
      onSubmit={onSubmit}
      validateOnChange={true}
      validate={onValidate}
    >
      {({ handleSubmit, resetForm, values }) => (
        <Modal setShow={() => toggleModal()} show={show}>
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Trusted Contact Update</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            <form id="addressUpdate" onSubmit={handleSubmit}>
              <h2 className="fields-title">Trusted Contact Personal Information</h2>
              <FormGroup>
                <Field component={TextboxField} name="firstName" id="firstName" label="First name" />
              </FormGroup>
              <FormGroup>
                <Field component={TextboxField} name="middleInitial" id="middleInitial" label="Middle initial" />
              </FormGroup>
              <FormGroup>
                <Field component={TextboxField} name="lastName" id="lastName" label="Last name" />
              </FormGroup>
              <FormGroup>
                <Field component={TextboxField} name="suffix" id="suffix" label="Suffix" />
              </FormGroup>
              <FormGroup>
                <Field component={TextboxField} name="email" id="email" label="Email" />
              </FormGroup>
              <FormGroup>
                <Field component={TextboxField} name="phone" id="phones[0].phoneNumber" label="Primary phone number" />
              </FormGroup>
              <h2 className="fields-title">Trusted Contact Address</h2>
              <>
                <FormGroup>
                  <Field
                    component={SelectDropdownField}
                    name={'country'}
                    id={'country'}
                    label="Country"
                    options={getGroupedCountryOptions()}
                    optionsDefaultLabelId="country"
                  />
                </FormGroup>
                <FormGroup>
                  <Field component={TextboxField} name={'address1'} id={'address1'} label="Address 1" />
                </FormGroup>
                <FormGroup>
                  <Field component={TextboxField} name={'address2'} id={'address2'} label="Address 2" />
                </FormGroup>
                <FormGroup>
                  <Field component={TextboxField} name={'city'} id={'city'} label="City" />
                </FormGroup>
                <FormGroup>
                  {values.country === Country.UnitedStatesOfAmerica && (
                    <Field
                      component={SelectDropdownField}
                      name={'state'}
                      id={'state'}
                      label="State"
                      options={AllStates}
                      optionsDefaultLabelId="state"
                    />
                  )}
                  {values.country !== Country.UnitedStatesOfAmerica && values.country !== '' && (
                    <Field component={TextboxField} name={'state'} id={'state'} label="Province" />
                  )}
                </FormGroup>
                <FormGroup>
                  <Field component={TextboxField} name={'postalCode'} id={'postalCode'} label="Postal Code" />
                </FormGroup>
              </>
            </form>
          </BootstrapModal.Body>
          <BootstrapModal.Footer className="modal-footer-justified">
            <Button
              variant="secondary"
              onClick={() => {
                resetForm();
                toggleModal();
                localStorage.setItem('trustedContactUSPS', 'undefined');
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={(values: any) => handleSubmit(values)}>
              Submit
            </Button>
          </BootstrapModal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
