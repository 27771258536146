import React, { useMemo, FC } from 'react';
import _ from 'lodash';
import { AssetSymbol, BlockType } from '@tradingblock/types';
import { useStateSelector, blocksSelectors } from '../../data/global/dataSelectors';
import { useSign, Number, SignedNumber } from '@tradingblock/components';
import { getQuoteMetadata } from '../../data/global/selectors/quoteSelector';
import { TradeButton } from '../../components/blocks/TradeButton';
import { useFeedQuoteWithMetadata, useQuoteDirectionAndValue } from '../../hooks/useFeedQuote';
import { FavoriteButton } from '../../components/blocks/FavoriteButton';
import { useBlockMetadata } from '../../components/blocks/BlockState';
import { useOrderBlock } from '../../components/blocks/hooks/useOrderBlock';
import { SymbolPrice } from '../shared/SymbolPrice';
import { useDirectionalValue } from '../../components/basic/Directional';

//packages\ui\src\blocks\shared\SymbolPrice.tsx
//packages\ui\src\blocks\QuoteExtended\SymbolInfo.tsx
interface SymbolInfoProps {
  //symbol?: AssetSymbol;
  symbol: AssetSymbol | undefined;
  hideNetChange?: boolean;
}
const SymbolInfoTrade: React.FunctionComponent<{ symbol: AssetSymbol }> = ({ symbol }) => {
  const { blockId, groupId } = useBlockMetadata();
  const isLinked = useStateSelector(s => blocksSelectors.isLinked(s)(blockId, BlockType.Order));
  const orderBlocksExist = useStateSelector(s => blocksSelectors.linkableOrderBlockExists(s, blockId));
  const orderData = useMemo(() => {
    return { legs: {} };
  }, []);
  const { createOrUpdateOrderBlock } = useOrderBlock({
    occSymbol: symbol && symbol.symbol,
    data: orderData,
    addBlockToGroupId: groupId,
    baseAssetSymbol: symbol,
    isUnderlyingSymbol: true,
  });
  return <TradeButton className="btn-outline-light btn-mini" linkableBlockExists={!isLinked && orderBlocksExist} handleTradeClick={createOrUpdateOrderBlock} />;
};

const DirectionalSymbolPrice: FC<{ value: number | undefined }> = ({ value }) => {
  const directionalClass = useDirectionalValue(_.isNumber(value) ? value : undefined);
  return (
    <div className={`${directionalClass}`}>
      <strong className={`txt-lg`}>
        <span className="currency">$</span>
        <Number value={value} format />
      </strong>
    </div>
  );
};

export const SymbolInfo: React.FunctionComponent<SymbolInfoProps> = ({ symbol, hideNetChange }) => {
  const symbolQuote = useFeedQuoteWithMetadata(symbol ? symbol.symbol : '');
  const { price } = useQuoteDirectionAndValue(symbol ? symbol.symbol : '');

  const quoteMetaData = useStateSelector(s => getQuoteMetadata(s, symbol ? symbol.symbol : ''));
  const netChange = useMemo(() => (symbolQuote ? symbolQuote.NetChange : 0), [symbolQuote]);
  const [change, changeUi] = useSign(netChange);
  const changePercent = useMemo(() => {
    // const changePercVal = quoteMetaData ? (netChange / quoteMetaData.ClosePrice) * 100 : undefined;
    const changePercVal = symbolQuote.ChangePercentage;
    return _.isNaN(changePercVal) ? undefined : changePercVal;
  }, [quoteMetaData, netChange]);

  return (
    <div className="symbol-summary">
      <div className="symbol-summary-title txt-2x">
        {symbol && (
          <>
            {symbol.symbol + ' '}
            <FavoriteButton symbol={symbol} className="symbol-summary-button" /> <DirectionalSymbolPrice value={price} />
          </>
        )}
      </div>

      {symbolQuote && (
        <div className="symbol-summary-price">
          <ul className="inline-list txt-lg">
            {hideNetChange !== true && (
              <li>
                <strong className={changeUi.className}>
                  <Number prefix={changeUi.prefix} currency value={change} />
                </strong>
              </li>
            )}
            {_.isNumber(changePercent) && hideNetChange !== true && (
              <li>
                <strong className={changeUi.className}>
                  <Number prefix={changeUi.prefix} value={changePercent} percent />
                </strong>
              </li>
            )}
            {symbol && (
              <li>
                <SymbolInfoTrade symbol={symbol} />
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};
