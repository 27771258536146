import React, { useCallback, useState } from 'react';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import {
  BooleanToggleType,
  AllBooleanToggleTypes,
  IndustryEmployedUpdateValueProps,
  AccountManagementDocuments,
} from '@tradingblock/types';
import { FormGroup, Modal, StepperField, Text, TextboxField } from '@tradingblock/components';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { useDispatch } from 'react-redux';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { AccountManagementDataActions } from '../../../data/global/actions/AccountManagementActions';
import { getRequiredError, getUploadError } from './Validation';
import { formatBoolean } from '../../../utilities/accountManagement';
import { MiscUploadFileField } from '../../../components/form/UploadMiscFile';
import { useClearer } from '../../../hooks/useClearer';

/**
 * Modal for updating a user's FINRA affiliation disclosure
 *
 *
 * @param {boolean} show Boolean value used for showing/hiding modal
 * @param {void} toggleModal Function to toggle show from true or false
 * @param {number} accountHolderIndex request maker's index within accountHolders array, specifies the account holder update is for
 * @returns {JSX.Element}
 *
 */

export const IndustryEmployedUpdate: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
  accountHolderIndex: number;
}> = ({ show, toggleModal, accountHolderIndex }) => {
  // Used Hooks
  const dispatch = useDispatch();

  // State Selectors
  const accountId = useStateSelector(s => s.accounts.account && s.accounts.account.AccountId);
  const accountManagementDetails = useStateSelector(s => s.accountManagement.accountManagementDetails.details);
  const { accountHolders } = accountManagementDetails;
  const clearer = useClearer();

  // Used States
  const [uploadId, setUploadId] = useState<number>();

  const handleDocumentUpload = (documentId: number) => {
    setUploadId(documentId);
  };

  /**
   * IndustryEmployedUpdateValueProps
   *
   * @property {BooleanToggleType | undefined} industryEmployed User's Yes/No response to FINRA affiliation
   * @property {string} disclosuresIndustryEmployedFirmName If user is affiliated, the name of user employer
   */

  const initialIndustryEmployedUpdateValues: IndustryEmployedUpdateValueProps = {
    industryEmployed: undefined,
    disclosuresIndustryEmployedFirmName: '',
  };

  // On form submission set update values accordingdly, dispatch update request, and resetForm/toggleModal
  const onSubmit = useCallback(
    (values, { resetForm }) => {
      const { industryEmployed, disclosuresIndustryEmployedFirmName } = values;

      let updateValues = {
        item: 'IndustryEmployed',
        accountHolder: {
          disclosures: {
            industryEmployed: formatBoolean(industryEmployed),
          },
          disclosuresIndustryEmployedFirmName,
        },
        holder: accountHolderIndex,
        uploadedDocuments: [
          {
            accountHolder: accountHolderIndex,
            documentId: uploadId,
            documentName: AccountManagementDocuments.AffiliateApproval,
          },
        ],
      };

      // If update value is equal to value already on record, resetForm/toggleModal
      if (formatBoolean(industryEmployed) === accountHolders[accountHolderIndex].disclosures.industryEmployed) {
        resetForm();
        toggleModal();
        return;
      }
      dispatch(
        AccountManagementDataActions.requestMakeAccountUpdate({
          accountId,
          request: updateValues,
        })
      );
      // Set timeout to dispatch requests for user account information following update request
      // TODO: Develop better success/status handling for update requests

      resetForm();
      toggleModal();
    },

    [toggleModal, uploadId]
  );
  // Form validation prior to submission, ensures all disclosures are answered and necessary docs are gathered
  const onValidate = (values: IndustryEmployedUpdateValueProps) => {
    const { industryEmployed, disclosuresIndustryEmployedFirmName } = values;

    if (!industryEmployed) {
      return {
        [`industryEmployed`]: getRequiredError(industryEmployed),
        [`disclosuresIndustryEmployedFirmName`]: getRequiredError(disclosuresIndustryEmployedFirmName),
      };
    }

    if (industryEmployed === 'yes' && !disclosuresIndustryEmployedFirmName) {
      return {
        [`disclosuresIndustryEmployedFirmName`]: getRequiredError(disclosuresIndustryEmployedFirmName),
      };
    }

    if (industryEmployed === 'yes' && !uploadId) {
      return {
        [`uploadedDocuments`]: getUploadError(uploadId),
      };
    }
  };

  return (
    <Formik initialValues={initialIndustryEmployedUpdateValues} onSubmit={onSubmit} validate={onValidate}>
      {({ handleSubmit, values, errors, submitCount, resetForm }) => (
        <Modal setShow={() => toggleModal()} show={show}>
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Industry Employed Update Request</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            <p>
              Are you employed by or a member of a registered broker-dealer, securities or futures exchange, or
              securities industry regulatory body (e.g., FINRA, NFA)?
            </p>
            <FormGroup>
              <Field
                component={StepperField}
                id="industryEmployed"
                options={AllBooleanToggleTypes}
                defaultLabelId="boolean"
              />
            </FormGroup>

            {values.industryEmployed === BooleanToggleType.Yes && (
              <>
                <FormGroup>
                  <Field component={TextboxField} id="disclosuresIndustryEmployedFirmName" />
                </FormGroup>
                <FormGroup>
                  <Field
                    component={MiscUploadFileField}
                    id="uploadedDocuments"
                    accountId={accountId}
                    description={'Affiliate Approval (3210)'}
                    documentType={AccountManagementDocuments.AffiliateApproval}
                    handleDocumentUpload={handleDocumentUpload}
                    clearer={clearer}
                  />
                  {errors.uploadedDocuments && !!submitCount && !uploadId && (
                    <>
                      <span className="error">
                        <Text id={'uploadedDocuments'} textKey={`affiliateApproval`} type={'error'} />
                      </span>
                    </>
                  )}
                </FormGroup>
                <p className="txt-sm mute">
                  Since you stated you are employed by or a member of a registered broker-dealer, securities or futures
                  exchange, or securities industry regulatory body (e.g., FINRA, NFA), a 3210 Form Letter from your
                  employer is required to process your update.
                </p>
              </>
            )}
          </BootstrapModal.Body>
          <BootstrapModal.Footer className="modal-footer-justified">
            <Button
              variant="secondary"
              onClick={() => {
                resetForm();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={(values: any) => handleSubmit(values)}>
              Submit
            </Button>
          </BootstrapModal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
