import _ from 'lodash';
import { DataState } from '../state';
import createCachedSelector from 're-reselect';
import { releaseNotesRequestKey } from '../utilities/infoUtilities';
import { createSelector } from 'reselect';

export const getReleaseNotesRequestKey = createSelector(
  (state: DataState) => state.private.accountManagement.ui,
  uiState => releaseNotesRequestKey(uiState)
);

export const getReleaseNotesFilterData = createCachedSelector(
  (state: DataState) => state.information.ui,
  getReleaseNotesRequestKey,
  (uiState, requestKey) => {
    return { ...uiState };
  }
)(getReleaseNotesRequestKey);

export const AccountManagementSearchRequestSelector = createCachedSelector(
  (d: DataState) => d.information.ui,
  (d: DataState) => releaseNotesRequestKey(d.information.ui),
  (request, key) => {
    return { request, key };
  }
)((d: DataState) => releaseNotesRequestKey(d.information.ui));
