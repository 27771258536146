import React, { useState, useRef, useCallback } from 'react';
import _ from 'lodash';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { Modal } from '@tradingblock/components';
import { PrivateUserCashieringState } from '@tradingblock/types';
import { CashieringEntityNotes } from '../details/CashieringEntityNotes';

interface CashieringNoteCellProps {
  id: number;
  accountId: number;
  type: keyof Pick<PrivateUserCashieringState, 'relationships' | 'transfers' | 'recipientBanks' | 'wireInstructions'>;
}

export const CashieringNoteCell: React.FunctionComponent<CashieringNoteCellProps> = ({ id, type, accountId }) => {
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);

  const toggleShow = useCallback(
    (shouldShow?: boolean) => {
      const showVal = _.isNil(shouldShow) ? !show : shouldShow;
      setShow(showVal);
      if (showVal === false) {
        setEdit(false);
      }
    },
    [show]
  );
  const onSave = useCallback(() => {
    toggleShow(false);
  }, []);
  const containerRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLButtonElement>(null);
  return (
    <div ref={containerRef}>
      <button className="btn btn-blend" onClick={() => toggleShow()} ref={targetRef}>
        <i className="fal fa-comment-alt-dots fa-2x"></i>
      </button>
      {targetRef.current && containerRef.current && (
        <Modal show={show} setShow={toggleShow}>
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Notes</BootstrapModal.Title>
          </BootstrapModal.Header>
          <CashieringEntityNotes
            accountId={accountId}
            id={id}
            type={type}
            bodyClassName="modal-body"
            footerClassName="modal-footer"
            onEditToggle={setEdit}
            onSave={onSave}
          />
          {!edit && (
            <BootstrapModal.Footer>
              <Button variant="primary" onClick={onSave}>
                OK
              </Button>
            </BootstrapModal.Footer>
          )}
        </Modal>
      )}
    </div>
  );
};
