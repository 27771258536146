import React, { useContext, useMemo } from 'react';
import createReducer from 'react-use/lib/createReducer';
import { getType } from 'typesafe-actions';
import { OutputSelector } from 'reselect';
import _ from 'lodash';
import { newId } from '@tradingblock/components';
import { TabOptions, IBlockState, IBlockDataState, SaveOptions, BlockType } from '@tradingblock/types';
import { useStateSelector, blocksSelectors, blockSelector } from '../../data/global/dataSelectors';
import { StrategyValidationMiddleware } from '../../blocks/Order/data/StrategyValidationMiddleware';
import { cleanOrderState } from '../../blocks/Order/state/cleanStateBeforePersist';
import { useBlockGroupField } from '../../blocks/useGroupState';
import { PersistMiddleware, PrePersistFunc } from './state/PersistMiddleware';
import { BlockActions, GenericBlockActionTypes, BlockAction } from './state/BlockState';
import { LogMiddleware } from './state/LogMiddleware';
import { BlockReducerWithTables, blockReducer } from './BlockReducer';
import { useBlockState } from './useBlockState';
import { OrderStateReducer } from '../../blocks/Order/state/OrderReducer';
export * from './useBlockState';

export function isBlockAction<T>(action: T | BlockActions): action is BlockActions {
  const type = _.get(action, 'type', undefined);
  return (
    type &&
    [
      'initialize',
      'setTitle',
      'setData',
      'setTab',
      'initializeData',
      'updateData',
      'replaceSettings',
      getType(BlockAction.setSettings),
    ].includes(type)
  );
}

const defaultBlockState: IBlockState = {
  groupId: '',
  blockId: '',
  type: BlockType.Account,
  title: '',
  data: {},
  settings: {},
};

export const GenericBlockActionContext = React.createContext<React.Dispatch<any>>(() => { });
export const BlockActionContext = React.createContext<React.Dispatch<BlockActions & any>>(() => { });
export const BlockStateContext = React.createContext<IBlockState>(defaultBlockState);
export const BlockDataContext = React.createContext<any>({});

const prePersistMap: Record<BlockType, undefined | PrePersistFunc<any>> = {
  [BlockType.Account]: undefined,
  [BlockType.AccountStatements]: undefined,
  [BlockType.AccountTransfer]: undefined,
  [BlockType.Activity]: cleanOrderState,
  [BlockType.Favorites]: undefined,
  [BlockType.OptionChain]: undefined,
  [BlockType.Positions]: undefined,
  [BlockType.PriceChart]: undefined,
  [BlockType.Quote]: undefined,
  [BlockType.Strategy]: undefined,
  [BlockType.Info]: undefined,
  [BlockType.Order]: cleanOrderState,
  [BlockType.AccountManagement]: undefined,
  [BlockType.AdminTools]: undefined,
  [BlockType.Analytics]: undefined,
  [BlockType.SubAccountManagement]: undefined,

};

interface GenericBlockState<T extends IBlockState = IBlockState, TT extends BlockActions = any> {
  reducer: (state: T, action: TT) => T;
  initial: T;
}

const getMiddleware = (blockid: string, type?: BlockType) => {
  const prePersist = type ? prePersistMap[type] : undefined;
  const defaultMiddleware = [LogMiddleware(`${type}Reducer`, blockid), PersistMiddleware(blockid, prePersist)];
  if (type === BlockType.Order) {
    return [...defaultMiddleware, StrategyValidationMiddleware()];
  }

  if (type === BlockType.Activity) {
    return [...defaultMiddleware, StrategyValidationMiddleware()]
  }

  return defaultMiddleware;
};

export const GenericBlockStateProvider: React.FunctionComponent<GenericBlockState> = ({
  children,
  initial,
  reducer,
}) => {
  const [state, dispatch] = createReducer(...getMiddleware(initial.blockId, initial.type))(
    BlockReducerWithTables(reducer),
    initial
  );
  return (
    <GenericBlockActionContext.Provider value={dispatch}>
      <BlockActionContext.Provider value={dispatch}>
        <BlockStateContext.Provider value={state}>
          <BlockDataContext.Provider value={state.data || {}}>{children}</BlockDataContext.Provider>
        </BlockStateContext.Provider>
      </BlockActionContext.Provider>
    </GenericBlockActionContext.Provider>
  );
};

export const BlockStateProvider: React.FunctionComponent<IBlockState> = ({ children, ...props }) => {
  const defaultBlockState = {
    ...props,
    groupId: props.groupId || newId(),
  };
  const [state, dispatch] = createReducer(...getMiddleware(props.blockId, props.type))(
    BlockReducerWithTables(blockReducer),
    defaultBlockState
  );

  return (
    <BlockActionContext.Provider value={dispatch}>
      <BlockStateContext.Provider value={state}>
        <BlockDataContext.Provider value={state.data || {}}>{children}</BlockDataContext.Provider>
      </BlockStateContext.Provider>
    </BlockActionContext.Provider>
  );
};

export function useBlockActions<T>() {
  const dispatch = useContext(BlockActionContext);
  return useMemo(() => {
    return {
      initialize: <T extends IBlockState>(state: Partial<T>) => {
        return dispatch({ type: 'initialize', payload: state });
      },
      setTitle: (payload: string) => dispatch({ type: 'setTitle', payload }),
      setGroup: (payload: string) => dispatch({ type: 'setGroup', payload }),
      setTab: (payload: TabOptions) => dispatch({ type: 'setTab', payload }),

      setField: <K extends keyof T>(field: K, value: T[K], options?: SaveOptions) => {
        return dispatch({
          type: 'setData',
          payload: { field: field.toString(), value, options },
        });
      },
      setSubstate: <K extends keyof T, I extends keyof T[K]>(
        field: K,
        key: I,
        value: T[K][I],
        options?: SaveOptions
      ) => {
        return dispatch({
          type: 'setData',
          payload: { field: `${field}[${key}]`, value, options },
        });
      },
      initializeData: <T extends IBlockDataState>(value: Partial<T>) => {
        return dispatch({ type: 'initializeData', payload: value });
      },
      updateData: (value: Partial<T>, options?: SaveOptions) => {
        return dispatch({ type: 'updateData', payload: { data: value, options } });
      },
    };
  }, [dispatch]);
}

//type BlockActionTypes<T, K, I> = { type: string; payload: Partial<T> } | { type: string; payload: { field: string; value: T[K]; options?: SaveOptions } } | { type: string; payload: { field: string; value: T[K][I]; options?: SaveOptions } };
export function useBlockActionDispatch<TT>() {
  const dispatch = useContext(GenericBlockActionContext) as React.Dispatch<TT>;
  return dispatch; //as React.Dispatch<TT>;
}

export function useBaseBlockActionDispatch<TT>() {
  const dispatch = useContext(BlockActionContext) as React.Dispatch<TT>;
  return dispatch; //as React.Dispatch<TT>;
}

export function useBlockActionDispatchAndGenericActions<S extends IBlockDataState, TT extends any>() {
  const dispatch = useBlockActionDispatch<GenericBlockActionTypes<TT>>();
  const actions = useMemo(() => {
    return {
      initialize: (state: Partial<IBlockState<S>>) => {
        return dispatch(BlockAction.initialize(state));
      },
      setTitle: (payload: string) => dispatch({ type: 'setTitle', payload }),
      setTab: (payload: TabOptions) => dispatch({ type: 'setTab', payload }),

      setField: <K extends keyof S>(field: K, value: S[K], options?: SaveOptions) => {
        return dispatch({
          type: 'setData',
          payload: { field: field.toString(), value, options },
        });
      },
      setSubstate: <K extends keyof S, I extends keyof S[K]>(
        field: K,
        key: I,
        value: S[K][I],
        options?: SaveOptions
      ) => {
        return dispatch({
          type: 'setData',
          payload: { field: `${field}[${key}]`, value, options },
        });
      },
      initializeData: (value: Partial<S>) => {
        return dispatch({ type: 'initializeData', payload: value });
      },
      updateData: (value: Partial<S>, options?: SaveOptions) => {
        return dispatch({ type: 'updateData', payload: { data: value, options } });
      },
    };
  }, [dispatch]);
  return [actions, dispatch] as [typeof actions, React.Dispatch<TT>];
}
export function useBlockMetadata() {
  const state = useContext(BlockStateContext);
  const { blockId, groupId, ...restOfState } = state;
  const block = useStateSelector(s => blockSelector.block(s)(blockId));
  return {
    ...restOfState,
    // use blockId from block state...
    blockId,
    // but get groupId directly from block state if possible
    groupId: (block && block.groupId) || groupId,
  };
}

export function useBlockData<T>() {
  const state = useContext(BlockDataContext);
  //return {field: getFieldFromState(state, field)};
  return state as T;
}

export function useBlockSymbol() {
  return useBlockGroupField('symbol');
}
export function useBlockSymbolValue() {
  const symbol = useBlockGroupField('symbol');
  return useMemo(() => (symbol ? symbol.symbol : ''), [symbol]);
}

export function useBlockId() {
  return useBlockState('blockId');
}

type DataSelector<S extends IBlockDataState, T = any> = OutputSelector<S, T, (res: S) => T>;
type DataTransform<S extends IBlockDataState, T = any> = (res: S) => T;
export function useBlockSelector<S extends IBlockDataState, T = any>(
  selector: DataSelector<S, T> | DataTransform<S, T>,
  defaultVal?: T
) {
  const data = useBlockData<S>();
  const res = selector(data);
  return defaultVal && _.isNil(res) ? defaultVal : res;
}
export function useBlockDataField<T, K extends keyof T>(field: K) {
  const state = useContext(BlockDataContext);
  //return {field: getFieldFromState(state, field)};
  return (state as T)[field];
}

export function useBlockGlobalData() {
  const blockId = useBlockState('blockId');
  const quotes = useStateSelector(s => blocksSelectors.getBlockQuoteSubscriptions(s, blockId));
  return useMemo(
    () => ({
      quotes,
    }),
    [quotes]
  );
}

export { blockReducer, BlockAction };
