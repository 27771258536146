import { createStore, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import _ from 'lodash';
import { setupMiddleware } from './middleware/index';
import { RootReducer } from './reducer';
import rootSaga from './sagas';
import { Config } from '../../config';

// Create the middleware instance.

export const DataStore = (options: { enableDevTools: boolean }) => {
  const composeEnhancers = options.enableDevTools
    ? composeWithDevTools({
        predicate: (state, action) => _.get(action, 'meta.verbose', false) !== true,
      })
    : compose;
  const { middleware, sagaMiddleware } = setupMiddleware();
  const storeVal = createStore(RootReducer, composeEnhancers(middleware));
  sagaMiddleware.run(rootSaga);
  return storeVal;
};
