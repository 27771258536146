import { createSelector } from 'reselect';
import _ from 'lodash';
import { AssetType, PositionPerformanceInfo } from '@tradingblock/types';
import { PositionDTO } from '@tradingblock/api';
import { DataState, PositionPerformanceWithPnL } from '../state';
import { SymbolPositions, SymbolPosition } from '../../../types';
import { QuoteDataToPartialQuote, aggregatedSymbolPosition } from './transforms';
import { positionToInfo } from '../../../utilities/position';

const positionRootSelector = (state: DataState) => state.positions.positions;
/**
 * Returns a list of current positions corresponding to the given symbol.
 */
export const getPositionsFromSymbol = createSelector(
  (state: DataState) => positionRootSelector(state),
  (positions: PositionDTO[]) => {
    return _.reduce(
      positions,
      (acc: SymbolPositions, pos: PositionDTO): SymbolPositions => {
        // To make sure that options and common stock fall under the same category, group it buy the underlying symbol,
        // otherwise, if grouped by symbol, the symbols wont match (options have an id in the symbol name while common stock don't)
        const underlyingSymbol = pos.UnderlyingSymbol;
        const DEFAULTVALUE = { shares: 0, value: 0, positions: [], symbol: underlyingSymbol, options: 0 };
        let symbolValue = acc[underlyingSymbol] || DEFAULTVALUE;
        // If the accumulated object doesn't have a symbol, assign it default values.
        // if (!acc.hasOwnProperty(sym)) Object.assign({ [sym]: [pos], symbol: sym }, acc);
        // If the AssetType is common stock
        const isEquity = pos.AssetType === AssetType.Equity;
        // Aggregate the shares and values of the stock
        return {
          ...acc,
          [underlyingSymbol]: {
            ...symbolValue,
            shares: isEquity ? symbolValue.shares + pos.OpenQuantity : symbolValue.shares,
            options: isEquity ? symbolValue.options : symbolValue.options + pos.OpenQuantity,
            // value: symbolValue.value + pos.OpenPrice,
            positions: [...symbolValue.positions, pos],
          },
        };
      },
      {}
    );
  }
);
const uniquePositionQuotesSelector = createSelector(
  positionRootSelector,
  positions => {
    return _(positions)
      .map(p => [p.Symbol, p.UnderlyingSymbol])
      .flatMap()
      .uniq()
      .value();
  }
);
export const getPositionQuotes = createSelector(
  (s: DataState) => s.quotes.quoteMetadata.data,
  uniquePositionQuotesSelector,
  (quotes, symbols) => {
    const quotesData = _.filter(quotes, (v, k: string) => symbols.includes(k));
    return QuoteDataToPartialQuote(quotesData);
  }
);

export const getAllPositionInfo = createSelector(
  getPositionsFromSymbol,
  getPositionQuotes,
  (s: DataState) => s.performance.positions,
  (positions, quotes, positionperformances) => {
    const positionsWithPerformance = _.reduce(
      positions,
      (res, symPosition: SymbolPosition, key: string) => {
        const commission = _.reduce(
          symPosition.positions,
          (acc, p) => {
            return acc + p.Commission;
          },
          0
        );
        const posIds = _.map(symPosition.positions, sp => sp.PositionId);
        const aggregatedPnl = _.reduce(
          positionperformances,
          (acc, pp, key) => {
            if (!posIds.includes(_.toString(key))) {
              return acc;
            }

            return {
              pnl: acc.pnl + pp.pnl,
            };
          },
          { pnl: 0 }
        );
        const curr: PositionPerformanceWithPnL = {
          ...aggregatedSymbolPosition(symPosition.positions, quotes, key),
          pnl: aggregatedPnl.pnl,
          commission,
        };
        return {
          ...res,
          [key]: curr,
        };
      },
      {}
    );
    return _.map(positionsWithPerformance, (v, k) => {
      return positionToInfo(v);
    });
  }
);
export const getPositionValue = createSelector(
  getAllPositionInfo,
  pos => {
    return _(pos)
      .map((p: PositionPerformanceInfo) => p.value)
      .sum();
  }
);

export const initialPositionsAreLoading = createSelector(
  (state: DataState) => state.positions.positions.length,
  (state: DataState) => state.positions.isFetching,
  (state: DataState) => state.positions.loaded,
  (positionCount, isFetching, loaded) => {
    const positionsExist = positionCount > 0 ? true : loaded ? false : undefined;
    return {
      positions: { existing: positionsExist, isFetching },
      loading: positionsExist === undefined || isFetching === true,
    };
  }
);

export const getPositionsForSymbol = createSelector(
  [getPositionsFromSymbol, (state: DataState, symbol: string) => symbol],
  (symbPos: SymbolPositions, symbol: string) => symbPos[symbol]
);
export const PositionSelectors = {
  loaded: createSelector(
    (state: DataState) => state.positions.loaded,
    loaded => loaded
  ),
  initialPositionsAreLoading,
};
