import { buildGenericRequestReceiveActions } from '../actionUtilities';

// Account Daily Statements Actions
const REQUEST_DAILY_ACCOUNT_STATEMENTS = '@data/REQUEST/DAILY_ACCOUNT_STATEMENTS';
const RECEIVE_DAILY_ACCOUNT_STATEMENTS = '@data/RECEIVE/DAILY_ACCOUNT_STATEMENTS';
const ERROR_DAILY_ACCOUNT_STATEMENTS = '@data/ERROR/DAILY_ACCOUNT_STATEMENTS';

const [
  requestDailyAccountStatements,
  receiveDailyAccountStatements,
  errorDailyAccountStatements,
] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_DAILY_ACCOUNT_STATEMENTS,
  RECEIVE: RECEIVE_DAILY_ACCOUNT_STATEMENTS,
  ERROR: ERROR_DAILY_ACCOUNT_STATEMENTS,
})<{ accountId: number; requestedStartDate: Date; requestedEndDate: Date }, { data: any }, { error: any }>();

// Account Monthly Statements Actions
const REQUEST_MONTHLY_ACCOUNT_STATEMENTS = '@data/REQUEST/MONTHLY_ACCOUNT_STATEMENTS';
const RECEIVE_MONTHLY_ACCOUNT_STATEMENTS = '@data/RECEIVE/MONTHLY_ACCOUNT_STATEMENTS';
const ERROR_MONTHLY_ACCOUNT_STATEMENTS = '@data/ERROR/MONTHLY_ACCOUNT_STATEMENTS';

const [
  requestMonthlyAccountStatements,
  receiveMonthlyAccountStatements,
  errorMonthlyAccountStatements,
] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_MONTHLY_ACCOUNT_STATEMENTS,
  RECEIVE: RECEIVE_MONTHLY_ACCOUNT_STATEMENTS,
  ERROR: ERROR_MONTHLY_ACCOUNT_STATEMENTS,
})<{ accountId: number; requestedStartDate: Date; requestedEndDate: Date }, { data: any }, { error: any }>();

// Account Tax Statements Actions
const REQUEST_TAX_ACCOUNT_STATEMENTS = '@data/REQUEST/TAX_ACCOUNT_STATEMENTS';
const RECEIVE_TAX_ACCOUNT_STATEMENTS = '@data/RECEIVE/TAX_ACCOUNT_STATEMENTS';
const ERROR_TAX_ACCOUNT_STATEMENTS = '@data/ERROR/TAX_ACCOUNT_STATEMENTS';

const [
  requestTaxAccountStatements,
  receiveTaxAccountStatements,
  errorTaxAccountStatements,
] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_TAX_ACCOUNT_STATEMENTS,
  RECEIVE: RECEIVE_TAX_ACCOUNT_STATEMENTS,
  ERROR: ERROR_TAX_ACCOUNT_STATEMENTS,
})<
  { accountId: number; taxDocument: string; taxYear: number },
  { data: any },
  { error: any }
>();

export const AccountStatementsActions = {
  requestDailyAccountStatements,
  receiveDailyAccountStatements,
  errorDailyAccountStatements,
  requestMonthlyAccountStatements,
  receiveMonthlyAccountStatements,
  errorMonthlyAccountStatements,
  requestTaxAccountStatements,
  receiveTaxAccountStatements,
  errorTaxAccountStatements,
};
