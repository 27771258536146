import React, { useEffect, useState } from 'react';
import useMount from 'react-use/lib/useMount';
import { RouteComponentProps, Redirect } from 'react-router';
import _ from 'lodash';
import qs from 'qs';
import { GetUrlParams } from '@tradingblock/api';
import { IStorageEngine } from '@tradingblock/types';
import { useLocalStorage, TokenFromStorage, SaveTokenInStorage } from '@tradingblock/storage';
import Config from '../config';
import { useDispatcher } from '../data/global/hooks';
import { useStateSelector, refreshTokenSelector } from '../data/global/dataSelectors';
import { Actions } from '../data/global/actions';
import { validateToken, verifyToken } from '../utilities/auth';
import { LoginRedirectUrl } from '../utilities/url';
import { usePageSettings } from '../framework/WrappedRoute';

const tryGetToken = async (
  storage: IStorageEngine,
  tokenFromUrl: string | undefined,
  tokensFromState: { token: string | undefined }
) => {
  const tokenFromLS = await TokenFromStorage(storage);
  const tokenVal = tokenFromUrl || tokenFromLS;
  const { token } = tokensFromState;

  if (!tokenVal && !token) {
    console.warn('token not found from url or local storage');
    return null;
  }

  // const tokenResult = tokenVal ? await validateToken(tokenVal) : token ? await verifyToken(token) : undefined;
  const tokenResult = tokenVal ? await verifyToken(tokenVal) : token ? await verifyToken(token) : undefined;
  if (!tokenResult) {
    console.warn('token found was invalid');
    return null;
  }

  const { value, decoded } = tokenResult;
  const tokenPayload = { ...decoded, apiToken: decoded.token, token: value };
  await SaveTokenInStorage(storage, value);
  return tokenPayload;
};

const tryGetOrigin = async (storage: IStorageEngine, originFromUrl: string, originFromState: string) => {
  const originValue = originFromUrl;
  const originFromLS = localStorage.getItem('origin');
  let parseOriginLS = '';

  if (typeof originFromLS === 'string') {
    parseOriginLS = JSON.parse(originFromLS);
  }

  if (!originValue && !originFromLS) {
    console.warn('origin found was invalid');
    return undefined;
  }

  if (!originValue) {
    return parseOriginLS;
  }
  localStorage.setItem('origin', JSON.stringify(originValue));
  return originValue;
};

export const AuthenticationPage: React.FC<RouteComponentProps<{}>> = ({ location }) => {
  usePageSettings({ name: 'Authentication', access: 'public' });
  const { dispatch } = useDispatcher();
  const urlParams = qs.parse(_.drop(location.hash, 1).join(''));

  const tokenFromUrl = _.isString(urlParams.id_token) ? (urlParams.id_token as string) : undefined;
  const storage = useLocalStorage();
  const tokensFromState = useStateSelector(refreshTokenSelector);
  const originFromState = useStateSelector(s => s.auth.origin);
  const isExpired = useStateSelector(s => s.auth.isExpired);
  const params = GetUrlParams(location.search);
  const nextPage = params['next'] || '/';
  const grp = process.env.REACT_APP_TB_SITE_GROUP;

  const [currentToken, setCurrentToken] = useState<string | null>();

  useMount(() =>
    tryGetToken(storage, tokenFromUrl, tokensFromState)
      .then(tokenRes => {
        if (tokenRes) {
          // if account is pending, send to "no account" page
          if (tokenRes.pending) {
            window.location.href = `/no-account?grp=${grp}`;
            return;
          }
          dispatch(Actions.receiveToken(tokenRes));
          if (window.location.pathname.includes('/auth')) {
            setCurrentToken(tokenRes.token);
          }
        } else {
          setCurrentToken(null);
        }
      })
      .catch(err => {
        console.log('token verification errored', err);
        setCurrentToken(null);
      })
  );

  useMount(() =>
    tryGetOrigin(storage, urlParams.grp, originFromState)
      .then(originRes => {
        if (originRes) {
          dispatch(Actions.setOrigin({ origin: originRes }));
        } else {
          return;
        }
      })
      .catch(err => {
        console.log('origin errored', err);
      })
  );

  const redirectReps = ['JP1', 'JP2', 'JP3', 'JP4', 'JP5', 'AA1'];
  const userAccount = useStateSelector(s => s.accounts.account);
  let group = origin === 'mb' ? 'mb' : 'tb';

  useEffect(() => {
    if (userAccount !== undefined && userAccount.RepCode !== undefined && redirectReps.includes(userAccount.RepCode)) {
      if (group === 'mb') {
        console.log('Redirecting to legacy site! MB');
        window.location.replace('https://legacy.moneyblock.com/');
      } else {
        console.log('Redirecting to legacy site! TB');
        window.location.replace('https://legacy.tradingblock.com/');
      }
    }
  }, [userAccount]);

  useEffect(() => {
    if (isExpired === true) {
      setCurrentToken(null);
    }
  }, [isExpired]);

  useEffect(() => {
    if (currentToken === null) {
      window.location.href = `${Config.loginUrl}?redirect=${LoginRedirectUrl}&next=/&grp=${grp}`;
    }
  }, [currentToken]);

  return <div>{!_.isNil(currentToken) && <Redirect to={{ pathname: nextPage }} />}</div>;
};
