import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { NumberInput } from '@tradingblock/components';
import { useOrderData, OrderState } from '../../state/OrderState';
import { LegSelector } from '../../state/OrderLegSelector';
import { useOrderActions } from '../../state/useOrderActions';
import { OrderSelectors } from '../../state/OrderSelector';
import { useOrderQuantity } from '../Order/OrderQuantity';
import { useBlockData } from '../../../../components/blocks/BlockState';
import { useBlockActionDispatchAndGenericActions } from '../../../../components/blocks/BlockState';
import { OrderActions, Actions } from '../../state/OrderActions';
import { getType } from 'typesafe-actions';
import { useOrderPrice } from '../../hooks/useOrderPrice';

export const LegQuantity: React.FC<{ id: string; disabled?: boolean }> = ({ id, disabled }) => {
  const legQuantityIsInvalid = useOrderData(s => OrderSelectors.invalidLegPosition(s, id));
  const { setLegQuantity } = useOrderActions();
  const { quantity } = useOrderQuantity();
  const spreadRatio = LegSelector.SpreadRatio(useBlockData<OrderState>(), id);
  const { mid } = useOrderPrice();

  const quantityValue = useMemo(() => {
    return disabled ? quantity * spreadRatio : spreadRatio;
  }, [spreadRatio, quantity]);

  const [{}, dispatch] = useBlockActionDispatchAndGenericActions<OrderState, OrderActions>();

  const onChange = useCallback(
    (value: number) => {
      dispatch({
        type: getType(Actions.setPrice),
        payload: { price: undefined, updateCreditOrDebit: true, mid: mid },
      });
      setLegQuantity(id, value);
    },
    [setLegQuantity, id]
  );
  return (
    <div className={`spinner text-left btn-block ${legQuantityIsInvalid ? 'invalid' : ''}`}>
      <NumberInput min={1} value={quantityValue} precision={0} step={1} onChange={onChange} disabled={disabled} />
    </div>
  );
};
