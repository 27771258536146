import React from 'react';
import { Number } from '@tradingblock/components';
import { CashieringBaseEntity } from '@tradingblock/types';
import { CashieringEntityStatus } from '../../../../../components/cashiering/CashieringEntityStatus';

export const TransactionCell: React.FunctionComponent<
  Pick<
    CashieringBaseEntity,
    'transfer' | 'status' | 'relationship' | 'transferRecipientBank' | 'transferWireInstruction'
  >
> = ({ relationship, status, transfer, transferRecipientBank, transferWireInstruction }) => {
  return (
    <>
      {relationship && (
        <>
          ACH relationship
          <br />
          <CashieringEntityStatus status={status} className={'txt-sm'} />
        </>
      )}
      {transferRecipientBank && (
        <>
          Transfer Recipient Bank Request
          <br />
          <CashieringEntityStatus status={status} className={'txt-sm'} />
        </>
      )}
      {transferWireInstruction && (
        <>
          Transfer Wire Instruction Request
          <br />
          <CashieringEntityStatus status={status} className={'txt-sm'} />
        </>
      )}
      {transfer && (
        <>
          {transfer.amount === null ? (
            transfer.disbursementType === 'FullBalance' ||
            transfer.disbursementType === 'FullBalanceAndCloseAccount' ? (
              'FULL BALANCE'
            ) : transfer.disbursementType === 'PartialBalance' ? (
              'PARTIAL BALANCE'
            ) : (
              ''
            )
          ) : (
            <Number value={transfer.amount} currency={true} />
          )}
          {` ${transfer.direction === 'Incoming' ? 'DEPOSIT' : 'WITHDRAWAL'}`}
          <br />
          <CashieringEntityStatus status={status} className={'txt-sm'} />
        </>
      )}
    </>
  );
};
