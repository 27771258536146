import _ from 'lodash';
import { getType } from 'typesafe-actions';
import { DataState } from '../state';
import { InitialState } from '../initialState';
import { RootAction } from '../actions';
import { HistoryActions } from '../actions/History';

export const history = (state = InitialState.history, action: RootAction): DataState['history'] => {
  switch (action.type) {
    case getType(HistoryActions.receiveGetBalanceHistory): {
      return {
        ...state,
        balanceHistory: action.payload.payload,
      };
    }
    case getType(HistoryActions.receiveHistoryGetBalances): {
      return {};
    }
    default:
      return state;
  }
};
