import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { LifecycleActions, UIActions } from './global/actions';
import { useStateSelector, AuthenticationInfoSelector, account } from './global/dataSelectors';
import { DataFeedInitializer } from './feed/DataFeedInitializer';
import { useDispatcher } from './global/hooks';
import { QuoteRequestInitializer } from './quotes/QuoteRequestHandler';
import { AccountActions } from './global/actions/AccountActions';
import { UserRole } from '@tradingblock/types';
import dayjs, { Dayjs } from 'dayjs';
import { MarketScheduleState } from './global/state';
import _ from 'lodash';

export const DataInitializer: React.FC<{}> = () => {
  const { isAuthenticated, apiToken, token, accountId } = useStateSelector(AuthenticationInfoSelector);
  const dispatch = useDispatch();
  const { dispatcher } = useDispatcher();
  const accountRoles = useStateSelector(account.userRoles);
  const isIRA = useStateSelector(s => s.account.isIRA);
  const isUGMA = useStateSelector(s => s.account.isUGMA);
  const isPartnership = useStateSelector(s => s.account.isPartnership);
  const marketSchedule = useStateSelector(s => s.ui.marketSchedule);
  const currentTimeout = useRef<NodeJS.Timeout | null>(null);
  const currentTargetTime = useRef<Dayjs | null>(null);
  const prevMarketSchedule = useRef<MarketScheduleState | null>(null);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(AccountActions.loadUserData({ accountId }));
      dispatch(LifecycleActions.loadInitialData());
    }
  }, [dispatch, isAuthenticated, accountId]);

  useEffect(() => {
    if (isAuthenticated && accountId) {
      if (accountRoles !== undefined) {
        if (accountRoles.includes(UserRole.CashieringApproval) && !isIRA && !isUGMA && !isPartnership) {
          dispatcher.balances.requestPendingTransfers();
        }
        dispatch(UIActions.requestMarketSchedule());
        runEvery30Minutes();
      }
      // dispatcher.balances.request();
    }
  }, [dispatcher, isAuthenticated, accountRoles]);

  function runEvery30Minutes() {
    setTimeout(() => {
      dispatch(UIActions.requestMarketSchedule());
      runEvery30Minutes();
    }, 1000 * 60 * 30);
  }

  useEffect(() => {
    if (marketSchedule.market && marketSchedule.indexMarket && !_.isEqual(prevMarketSchedule.current, marketSchedule)) {
      let checkDates: Dayjs[] = [];
      if (marketSchedule.market.nextClose) {
        checkDates.push(marketSchedule.market.nextClose);
      } else if (marketSchedule.market.nextOpen) {
        checkDates.push(marketSchedule.market.nextOpen);
      }

      if (marketSchedule.indexMarket.nextClose) {
        checkDates.push(marketSchedule.indexMarket.nextClose);
      }

      let targetTime: Dayjs | null = null;

      checkDates.forEach((date: Dayjs) => {
        if (targetTime) targetTime = date.isBefore(targetTime) ? date : targetTime;
        else targetTime = date;
      });

      if (targetTime) {
        const now = dayjs();
        const target = dayjs(targetTime);

        // Calculate the delay in milliseconds for the new timeout
        const newDelay = target.diff(now);

        if (newDelay <= 0) {
          return;
        }

        // If there's an existing timeout, compare the delays
        if (currentTimeout.current && currentTargetTime.current) {
          const existingDelay = currentTargetTime.current.diff(now);

          if (newDelay >= existingDelay || currentTargetTime.current.isSame(targetTime)) {
            return; // Do not schedule a new timeout
          }

          // Clear the existing timeout if the new one is closer
          clearTimeout(currentTimeout.current as NodeJS.Timeout);
        }

        // Schedule the new timeout
        currentTimeout.current = setTimeout(() => {
          dispatch(UIActions.requestMarketSchedule());
          currentTimeout.current = null; // Reset after execution
          currentTargetTime.current = null;
        }, newDelay);

        currentTargetTime.current = target;
      }
    }
  }, [marketSchedule]);

  useEffect(() => {
    prevMarketSchedule.current = marketSchedule;
  }, [marketSchedule]);

  return (
    <>
      {apiToken && isAuthenticated && token && account && accountId && (
        <>
          <DataFeedInitializer apiToken={apiToken} authToken={token} accountId={accountId} />
          <QuoteRequestInitializer apiToken={apiToken} authToken={token} accountId={accountId} />
        </>
      )}
    </>
  );
};
