import React, { useMemo, useCallback, useState, useEffect, useRef, EffectCallback, DependencyList } from 'react';
import _, { isEqual } from 'lodash';
import { FormGroup, SelectDropdownInput, BasicCheckboxInput } from '@tradingblock/components';
import { SubAccount, UserProfileLevel } from '@tradingblock/types';
import {
  useStateSelector,
  subAccountIdSelector,
  account,
  isMarketOpenSelector,
} from '../../../../data/global/dataSelectors';
import { IsVirtualEnvironment } from '../../../../data/global/selectors/environmentSelector';
import { PreviewActions, useOrderPreviewOrder } from './useOrderPreview';
import { PreviewContent } from './PreviewContent';
import { TradingBlockLink } from '../../../../components/basic/TradingBlockLink';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

export const PreviewPopupContent: React.FC<{
  isCancelOrder: boolean;
  subAccounts?: SubAccount[];
  warningNotAccepted: boolean;
  onWarningAccepted: () => void;
  action?: PreviewActions;
  orderStatusProcessed?: boolean;
  orderValue?: any;
}> = ({
  subAccounts,
  warningNotAccepted,
  onWarningAccepted,
  isCancelOrder,
  action: passedAction,
  orderStatusProcessed,
  orderValue,
}) => {
  const {
    order,
    legs,
    action,
    initialPrice,
    initialStopPrice,
    isWarningSymbol,
    isTradingblockOrder,
    changeSubaccountId,
  } = useOrderPreviewOrder(passedAction ? passedAction : 'execute');

  const globalSubAccountId = useStateSelector(subAccountIdSelector);
  const [orderSubAccountId, setOrderSubAccountId] = useState<number>();
  const userLevel = useSelector(account.userLevel);
  const isAdmin = userLevel && userLevel === UserProfileLevel.Admin;

  const marketOpen = useStateSelector(s => isMarketOpenSelector(s));

  const isVirtualEnv = useStateSelector(IsVirtualEnvironment);

  const actionName = action === 'execute' ? 'execution' : 'replacement';

  const subAccountOptions = useMemo(() => {
    return _.map(subAccounts, sa => ({
      value: sa.Id,
      label: `${sa.Nickname} (${sa.AcctSuffix})`,
    }));
  }, [subAccounts]);

  const legSubAccountId = _.get(legs[0], 'SubaccountId');
  const orderSubaccount = order.SubaccountId
    ? subAccountOptions.find(subaccount => subaccount.value === order.SubaccountId)
    : '';
  const masterAccount = _.find(subAccounts, sa => sa.IsMaster);

  /**
   * Custom hook to perform a deep comparison of dependencies and
   * run the effect only when there is an actual change in dependencies.
   * @param {EffectCallback} callback - The effect function to be executed.
   * @param {DependencyList} dependencies - The dependencies array for the effect.
   */
  const useDeepCompareEffect = (callback: EffectCallback, dependencies: DependencyList) => {
    // Ref to store the current dependencies
    const currentDependenciesRef = useRef<DependencyList>();

    // Perform deep comparison between current and previous dependencies
    if (!isEqual(currentDependenciesRef.current, dependencies)) {
      // If dependencies have changed, update the ref value
      currentDependenciesRef.current = dependencies;
    }

    // Use useEffect with the ref value (which updates only if dependencies change)
    useEffect(callback, [currentDependenciesRef.current]);
  };

  // Usage in your component
  useDeepCompareEffect(() => {
    // If order is not defined, return early to avoid running the rest of the effect
    if (!order) return;

    // Helper function to update sub-account ID
    const updateSubAccountId = (id: number) => {
      setOrderSubAccountId(id);
      changeSubaccountId(id);
    };

    // Logic to handle different cases based on the state
    if (isTradingblockOrder) {
      if (globalSubAccountId) {
        // If globally selected sub-account, force select the dropdown
        updateSubAccountId(globalSubAccountId);
      } else {
        // Back to edit and re-open preview (but no global sub-account selected)
        if (legSubAccountId && legSubAccountId === -1) {
          if (masterAccount) {
            updateSubAccountId(masterAccount.Id);
          }
        } else if (legSubAccountId) {
          updateSubAccountId(legSubAccountId);
        } else {
          if (order.SubaccountId && orderSubaccount) {
            updateSubAccountId(order.SubaccountId);
          }
        }
      }
    } else if (order.SubaccountId && orderSubaccount) {
      // Order Preview from Activity Order Block
      setOrderSubAccountId(order.SubaccountId);
    }
  }, [order, isTradingblockOrder, globalSubAccountId, legSubAccountId, masterAccount]);

  const onSelectSubAccount = useCallback(
    (id: number) => {
      setOrderSubAccountId(id);
      changeSubaccountId(id);
    },
    [setOrderSubAccountId, changeSubaccountId]
  );

  const beforeSomeOptionsCloseTime = dayjs()
    .utc(false)
    .set('hour', 20)
    .set('minute', 15)
    .set('seconds', 0); //3:15PM CST

  useEffect(() => {
    // If admin, automatically accept the leveraged ETF warning
    if (isAdmin && isWarningSymbol) {
      onWarningAccepted();
    }
  }, [isAdmin, isWarningSymbol]);

  useEffect(() => {
    if (order.SubaccountId && (isCancelOrder || action === 'replace')) {
      onSelectSubAccount(order.SubaccountId);
    }
  }, []);

  return (
    <>
      <p>
        Please review the following order details before {isCancelOrder ? `cancelling` : `sending it for ${actionName}`}
        .
      </p>
      {!marketOpen && (
        <>
          <p className="error txt-sm">
            <strong>NYSE Market is closed: </strong>
            {dayjs()
              .utc(false)
              .isBefore(beforeSomeOptionsCloseTime)
              ? 'Only successfully placed orders, with active trading symbols, will be executable. Otherwise the order will be become live at market open for the next trading session.'
              : 'All successfully placed orders will be saved and become live at market open of the next trading session.'}
          </p>
          {order && !order.Price && (
            <p className="error txt-sm">
              <strong>Warning:</strong> Market orders can only be placed during regular market hours. Please consider a
              limit order.
            </p>
          )}
        </>
      )}
      {!_.isEmpty(subAccounts) && !isCancelOrder && actionName !== 'replacement' ? (
        <FormGroup title="Select a sub-account">
          <SelectDropdownInput
            name="transfer.subAccountId"
            label="Sub-account"
            options={subAccountOptions}
            value={orderSubAccountId}
            disabled={globalSubAccountId !== undefined || !isTradingblockOrder || !!orderStatusProcessed}
            onchange={(e, subAccountId) => onSelectSubAccount(subAccountId)}
          />
        </FormGroup>
      ) : (
        //Cancel order -> prefill with order's subaccount source
        !_.isEmpty(subAccounts) &&
        (isCancelOrder || actionName === 'replacement') && (
          <FormGroup title="Selected sub-account">
            <SelectDropdownInput
              name="transfer.subAccountId"
              label="Sub-account"
              defaultValue={orderSubaccount ? orderSubaccount.label : subAccountOptions[0].label}
              options={[!orderSubaccount ? subAccountOptions[0] : orderSubaccount ? orderSubaccount : ({} as any)]}
              disabled={true}
            />
          </FormGroup>
        )
      )}
      {order && (
        <PreviewContent
          order={order}
          isCancelOrder={isCancelOrder}
          legs={legs}
          initialPrice={action === 'replace' ? initialPrice : order.Price || initialPrice}
          initialStopPrice={action === 'replace' ? initialStopPrice : order.Stop || initialStopPrice}
          action={action}
          orderValue={orderValue}
        />
      )}
      {isVirtualEnv && (
        <p>
          <span className="vr caps">
            <i className="fal fa-vr-cardboard"></i> Virtual account
          </span>
        </p>
      )}
      {isWarningSymbol && !isAdmin && (
        <>
          <p>
            Warning: Leveraged, Inverse, and Commodity ETFs carry unique risks and may not be suitable for every
            investor.
            <br />
            <TradingBlockLink to="ETFDisclosureUrl">Learn more</TradingBlockLink>
          </p>
          <BasicCheckboxInput checked={!warningNotAccepted} onchange={onWarningAccepted}>
            I understand the risk and would like to place the order
          </BasicCheckboxInput>
        </>
      )}
    </>
  );
};
