import { Dispatch } from 'redux';
import _ from 'lodash';
import { UiApiFactory } from '@tradingblock/api';
import { APINotAuthorizedResponseCode, ExpiredTokenCode } from '@tradingblock/types';
import { RootAction, SessionActions } from '../global/actions';
import { Config } from '../../config';

export const ApiIntercepts = (dispatch: Dispatch<RootAction>) => {
  return {
    onResponseError: (err: any) => {
      const statusCode = _.get<number | undefined>(err, 'response.status', undefined);
      switch (statusCode) {
        case 403: {
          if (err.response.data.error) {
            try {
              let error = JSON.parse(err.response.data.error);
              if (error.name === 'TokenExpiredError') {
                dispatch(SessionActions.expired({ code: ExpiredTokenCode.ApiTokenExpired }));
                return; //Do not dispatch other errors
              }
            } catch (e) {
              if (e instanceof Error && e.name === 'SyntaxError') {
                dispatch(
                  SessionActions.error({
                    code: APINotAuthorizedResponseCode,
                    url: err.config.url,
                    message: 'Request denied. You are not authorized to perform this action.',
                    reason: 'Unauthorized Request',
                  })
                );
              }
            }
          }
          dispatch(
            SessionActions.error({
              code: APINotAuthorizedResponseCode,
              url: err.config.url,
              message: 'Request denied. You are not authorized to perform this action.',
              reason: 'Unauthorized Request',
            })
          );
        }
        default: {
          console.warn('unknown ui api error occurred', err);
        }
      }

      return err;
    },
  };
};

const apis: any = {};
export const uiApi = (token: string, dispatch: Dispatch<RootAction>) => {
  if (apis[token] !== undefined) {
    return apis[token];
  }
  const api = UiApiFactory(Config.uiApi, token).withResponseHandler({
    onResponse: resp => {
      return resp;
    },
    onResponseError: ApiIntercepts(dispatch).onResponseError,
  });
  apis[token] = api;
  return api;
};
