import _ from 'lodash';
import { IStorageEngine, CreateDashboardPath, StorageDashboardPath, StorageDashboardsPath, DashboardInfo, CreateDashboardRequest } from '@tradingblock/types';

export const trySaveDashboards = async (storage: IStorageEngine, dashboards: DashboardInfo[]) => {
  try {
    return storage.save<DashboardInfo[]>(StorageDashboardsPath, dashboards).then(res => (_.isArray(res) ? res : []));
  } catch (ex) {
    console.error('trySaveDashboards', ex);
    return Promise.resolve([]);
  }
};

export const tryCreateDashboard = async (storage: IStorageEngine, createDashboard: CreateDashboardRequest) => {
  try {
    return storage.save(CreateDashboardPath, createDashboard);
  } catch (ex) {
    console.error('tryCreateDashboards', ex);
    return Promise.resolve(undefined);
  }
};

export const tryCreateEmptyDashboard = async (storage: IStorageEngine, dashboard: DashboardInfo) => {
  try {
    await storage.save(StorageDashboardPath, dashboard);
    return dashboard;
  } catch (ex) {
    console.error('tryCreateEmptyDashboard', ex);
    return Promise.resolve(undefined);
  }
};
