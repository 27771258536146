import { useFavorite } from '../../hooks/useFavorite';
import React, { useMemo } from 'react';
import { DropdownItem } from '@tradingblock/components';
import { AssetSymbolInfo } from '@tradingblock/types';

export const AddRemoveFavorite: React.FC<{ symbol: AssetSymbolInfo, onFavoriteAdd: () => void }> = ({ symbol, onFavoriteAdd }) => {
  const [symbolIsFavorite, toggleFavorite] = useFavorite(symbol);
  const title = useMemo(() => (symbolIsFavorite ? 'Remove favorite' : 'Add to favorites'), [symbolIsFavorite]);
  const addOrRemoveFavoritesDropDown = () => {
    if (title === 'Add to favorites') onFavoriteAdd();
    return toggleFavorite();
  }
  return <DropdownItem onClick={addOrRemoveFavoritesDropDown}>{title}</DropdownItem>;
};
