import { Middleware, Dispatch } from 'redux';
import { getType } from 'typesafe-actions';
import {
  DefaultDashboardPath,
  DefaultDashboardResponse,
  BlockSettingsSavePayload,
  StorageSettingsBlockPath,
} from '@tradingblock/types';
import { RootAction } from '../../actions';
import { DataState } from '../../state';
import { Dispatcher } from '../../dispatcher';
import { SettingsActions } from '../../actions/SettingsActions';
import { uiApiToken } from '../../dataSelectors';
import { UiApiClient } from '../../../../context/Storage';
import Config from '../../../../config';

export const tryLoadDefaultDashboards = async (state: DataState, dispatch: Dispatch<RootAction>) => {
  const provider = UiApiClient(state, dispatch);
  if (provider) {
    const res = await provider.get<DefaultDashboardResponse>(DefaultDashboardPath);
    dispatch(SettingsActions.defaultDashboardReceive(res || { value: [] }));
  }
};

// export const tryLoadBlockSettings = async (state: DataState, dispatch: Dispatch<RootAction>) => {
//   // if (token && (dataNotLoaded || more)) {
//   const token = uiApiToken(state);
//   if (token) {
//     const provider = StorageProviderFactory(Config.uiApi, token);
//     const res = await provider.get<BlockSettingsLoadPayload>(StorageSettingsBlockPath);
//     dispatch(SettingsActions.blockSettingsLoadReceive(res));
//   }
// };

const trySaveGlobalBlockSetting = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  payload: BlockSettingsSavePayload
) => {
  const provider = UiApiClient(state, dispatch);
  if (provider) {
    dispatch(SettingsActions.blockSettingsSaveRequest(payload));
    return await provider
      .save(StorageSettingsBlockPath, payload)
      .then(() => dispatch(SettingsActions.blockSettingsSaveReceive({})))
      .catch(err => {
        dispatch(SettingsActions.blockSettingsSaveError({ error: err, data: payload }));
      });
    // const res = await provider.get<DefaultDashboardResponse>(DefaultDashboardPath);
  }
};

export const SettingsMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  try {
    // state BEFORE action is dispatched
    const prevState = getState();
    const result = next(action);
    // state AFTER action is dispatched
    // const nextState = getState();
    try {
      switch (action.type) {
        case getType(SettingsActions.defaultDashboardRequest): {
          tryLoadDefaultDashboards(prevState, dispatch);
          break;
        }
        case getType(SettingsActions.setBlockSetting): {
          trySaveGlobalBlockSetting(prevState, dispatch, action.payload);
          break;
        }
      }
    } catch (apiError) {
      Dispatcher(dispatch).global.exception({ error: apiError, data: action });
    }
    return result;
  } catch (err) {
    console.error('settingsMiddleware :: Caught an exception for action ', action, err);
  }
};
