import _toConsumableArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _ from 'lodash';
import { StrikeRules, AssetType, OptionType } from '@tradingblock/types';
import { findDuplicateLegs } from '..';

var buildStrikeErrors = function buildStrikeErrors(strikes, sch) {
  return function (message) {
    var errs = [{
      keyword: 'strike',
      message: message,
      params: {
        strikes: strikes,
        rule: StrikeRules[sch]
      }
    }]; // currentValidatorFunc.errores = currentValidatorFunc.errores ? [...currentValidatorFunc.errores, ...errs] : errs;

    return errs;
  };
};

var minStrike = function minStrike(legs, type) {
  return _(legs).filter(function (l) {
    return type ? l.OptionType === type : true;
  }).map(function (l) {
    return l.Strike;
  }).min();
};

var maxStrike = function maxStrike(legs, type) {
  return _(legs).filter(function (l) {
    return type ? l.OptionType === type : true;
  }).map(function (l) {
    return l.Strike;
  }).max();
};

var validateRules = function validateRules(rule, legs, strikes, getErrors) {
  var strikesShouldBeDifferent = rule === StrikeRules.DifferentStrikes || rule === StrikeRules.DifferentStrikesDescending;

  var optionLegs = _.filter(legs, function (l) {
    return l && l.AssetType === AssetType.Option && _.isNumber(l.Strike);
  });

  var getStrikeErrors = getErrors;

  if (strikesShouldBeDifferent) {
    var isValid = _.uniq(strikes).length === optionLegs.length;

    if (!isValid) {
      return getStrikeErrors('Strikes must be different.');
    }
  } else if (rule === StrikeRules.EquidistantStrikes) {
    var distances = _.reduce(strikes, function (acc, currVal, ind) {
      var nextVal = strikes[ind + 1];

      if (nextVal === undefined) {
        return acc;
      }

      var vals = [currVal, nextVal];

      var dist = _.max(vals) - _.min(vals);

      return [].concat(_toConsumableArray(acc), [dist]);
    }, []);

    var _isValid = _.uniq(strikes).length >= 2 && _.uniq(distances).length === 1;

    var hasDuplicateLegs = findDuplicateLegs(optionLegs).length > 0;

    if (!_isValid) {
      return getStrikeErrors('Strikes must be equidistant.');
    }

    if (hasDuplicateLegs) {
      return getStrikeErrors('Cannot have two identical legs in the same order.');
    }
  } else if (rule === StrikeRules.IronCondor || rule === StrikeRules.IronButterfly) {
    var K1 = optionLegs[1].Strike;
    var K2 = optionLegs[0].Strike;
    var K3 = optionLegs[2].Strike;
    var K4 = optionLegs[3].Strike;
    var isButterfly = rule === StrikeRules.IronButterfly;
    var isCondor = rule === StrikeRules.IronCondor; //K1 < K2, K3 > K4; K2 - K1 = K3 - K4; K1 > K3

    if (K1 >= K2) {
      return getStrikeErrors("First CALL strike (".concat(K2, ") must be greater than second CALL strike (").concat(K1, ")"));
    } else if (K4 >= K3) {
      return getStrikeErrors("Second PUT strike (".concat(K4, ") must be less than first PUT strike (").concat(K3, ")"));
    } else if (isCondor && K3 >= K1) {
      return getStrikeErrors("Lowest CALL strike (".concat(K1, ") must be greater than highest PUT strike (").concat(K3, ")"));
    } else if (isButterfly && K3 !== K1) {
      return getStrikeErrors("Lowest CALL strike (".concat(K1, ") must be equal to highest PUT strike (").concat(K3, ")"));
    } else if (K2 - K1 !== K3 - K4) {
      return getStrikeErrors("The difference between CALL strikes (".concat(K2 - K1, ") must equal the difference between PUT strikes (").concat(K3 - K4, ")"));
    }
  } else if (rule === StrikeRules.SameStrikes) {
    var _isValid2 = _.uniq(strikes).length === 1;

    if (!_isValid2) {
      return getStrikeErrors('Strikes must be the same');
    }
  } else if (rule === StrikeRules.CallHigherThanPut) {
    var putLeg = _.find(optionLegs, function (l) {
      return l.OptionType === OptionType.Put;
    });

    var callLeg = _.find(optionLegs, function (l) {
      return l.OptionType === OptionType.Call;
    });

    var callPutErrors = undefined;

    if (!putLeg || !callLeg) {
      callPutErrors = getStrikeErrors('Must have PUT leg and CALL leg');
    }

    var putVal = putLeg.Strike;
    var callVal = callLeg.Strike;

    if (!_.isFinite(putVal) || !_.isFinite(callVal)) {
      callPutErrors = getStrikeErrors('Strikes must have finite values');
    }

    if (putVal >= callVal) {
      callPutErrors = getStrikeErrors('CALL strike must be greater than PUT strike');
    }

    if (callPutErrors) {
      return callPutErrors;
    }
  } else if (rule === StrikeRules.IncreasingOrder || rule === StrikeRules.DecreasingOrder) {
    var isInc = rule === StrikeRules.IncreasingOrder;

    var correctOrder = _.every(strikes, function (strike, ind) {
      if (ind === 0) {
        return true;
      }

      var prevStrike = strikes[ind - 1];
      return isInc ? prevStrike < strike : prevStrike > strike;
    });

    if (!correctOrder) {
      return getStrikeErrors("Strikes must be in ".concat(isInc ? 'ascending' : 'descending', " order"));
    }
  } else if (rule === StrikeRules.EquidistantStrikesByTwos) {
    var firstGroup = _.take(strikes, 2);

    var firstDiff = _.max(firstGroup) - _.min(firstGroup);

    var secondGroup = _.drop(strikes, 2);

    var secondDiff = _.max(secondGroup) - _.min(secondGroup);

    if (firstDiff !== secondDiff) {
      return getStrikeErrors("Distance between first strikes (".concat(firstDiff, ") isnt equal to distance between last strikes (").concat(secondDiff, ")"));
    }
  } else if (rule === StrikeRules.LowestCallEqualsHighestPut) {
    var lowestCall = minStrike(optionLegs, OptionType.Call);
    var highestPut = maxStrike(optionLegs, OptionType.Put);

    if (lowestCall !== highestPut) {
      return getStrikeErrors("Lowest CALL Strike must equal highest PUT Strike");
    }
  } else if (rule === StrikeRules.LowestCallGreaterThanHighestPut) {
    var _lowestCall = minStrike(optionLegs, OptionType.Call);

    var _highestPut = maxStrike(optionLegs, OptionType.Put);

    if (_lowestCall <= _highestPut) {
      return getStrikeErrors("Lowest CALL Strike must be greater than highest PUT Strike");
    }
  }

  return null;
};

var tryValidateRules = function tryValidateRules(rule, legs) {
  var optionLegs = _.filter(legs, function (l) {
    return l && l.AssetType === AssetType.Option && _.isNumber(l.Strike);
  });

  var strikes = optionLegs.map(function (l) {
    return l.Strike;
  });
  var getStrikeErrors = buildStrikeErrors(strikes, rule);

  try {
    return validateRules(rule, legs, strikes, getStrikeErrors);
  } catch (err) {
    return getStrikeErrors("Error occurred while validating: ".concat(JSON.stringify(err)));
  }
};

export var strikeValidation = function strikeValidation(sch) {
  return function strikeValidation(data) {
    if (_.get(strikeValidation, 'errors', null) === null) {}

    var legs = data;
    var rules = _.isArray(sch) ? sch : [sch];

    var errors = _.reduce(rules, function (errors, rule) {
      var ruleErrors = tryValidateRules(rule, legs);

      if (ruleErrors === null) {
        return errors;
      }

      return _.concat(errors, ruleErrors);
    }, []);

    if (errors.length > 0) {
      strikeValidation['errors'] = errors;
      return false;
    }

    return true;
  };
};