import React, { useEffect } from 'react';
import { Loading } from '@tradingblock/components';
import { useStateSelector } from '../../data/global/dataSelectors';
import { useDispatch } from 'react-redux';
import { InfoDataActions } from '../../data/global/actions/InfoActions';

export const ActiveAlertsTab: React.FunctionComponent<{}> = () => {
  const activeAlerts = useStateSelector(s => s.information.alerts.activeAlerts);
  const isAlertsFetching = useStateSelector(s => s.information.alerts.isFetching);
  const hasData = activeAlerts && activeAlerts.length > 0;
  const origin = useStateSelector(s => s.auth.origin);
  const siteConfigId = origin === 'mb' ? 4 : 2;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(InfoDataActions.requestAlertMessages({ siteConfigId }));
  }, []);
  return (
    <>
      {isAlertsFetching && <Loading />}
      {hasData && (
        <ul style={{ listStyleType: 'none' }}>
          <h5 className="pending">Active Alerts</h5>
          {activeAlerts &&
            activeAlerts.map(alert => (
              <li key={alert.id}>
                <p>{alert.message}</p>
              </li>
            ))}
        </ul>
      )}
      {!hasData && !isAlertsFetching && (
        <h5>
          {' '}
          <i className="fal fa-bell-slash" /> No active alerts
        </h5>
      )}
    </>
  );
};
