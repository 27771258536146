import React from 'react';
import _ from 'lodash';
import { AccountManagementBaseUpdate } from '@tradingblock/types';
import { AdminAccountManagementSearchActions } from '../../../../../data/global/actions/admin/AccountManagementActions';
import { useDispatcher } from '../../../../../data/global/hooks';

export const AccountManagementActionCell: React.FunctionComponent<
  Pick<AccountManagementBaseUpdate, 'accountId' | 'id' | 'state'>
> = ({ accountId, id, state }) => {
  const isPending = _.toLower(state).includes('pending');
  const { dispatch } = useDispatcher();
  const handleClick = (e: any, accountId: number, id: number) => {
    e.preventDefault();
    let updateItem = {
      accountId,
      id,
    };
    dispatch(AdminAccountManagementSearchActions.setCurrentItem(updateItem));
  };
  return (
    <>
      <button className={`btn ${isPending ? `btn-outline-light` : ''}`} onClick={e => handleClick(e, accountId, id)}>
        {isPending ? 'Review' : 'View'}
      </button>
    </>
  );
};
