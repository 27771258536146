import React, { useMemo } from 'react';
import { RegisteredDevicesTable } from './components/table/RegisteredDevicesTable';
import { MFASettings } from './modals/MFASettings';
import { AuthHistoryTable } from './components/table/AuthHistoryTable';

//TODO: Once backend is ready, we need to fetch the data from the backend and populate the MFA options
export const AccountSecurity: React.FunctionComponent<{}> = ({}) => {
  const [isTwoFactorEnabled, setIsTwoFactorEnabled] = React.useState(true);
  const [showMFASettings, setShowMFASettings] = React.useState(false);

  const toggleMFASettings = () => {
    setShowMFASettings(!showMFASettings);
  };

  const MFASetting = useMemo(() => {
    return isTwoFactorEnabled ? 'Enabled' : 'Disabled';
  }, [isTwoFactorEnabled]);

  return (
    <>
      <div className="container-fluid" style={{ height: '100%' }}>
        <div className="row">
          {/*TODO: Reimplement when SMS MFA is ready */}
          {/* <div className="col-3">
            <div className="row">
              <div className="col-12">
                <h5>Security Preferences</h5>
                <p>Multi-factor Authentication: {MFASetting}</p>
                {isTwoFactorEnabled ? (
                  <>
                    <p>Current MFA Method: SMS</p>
                  </>
                ) : null}
                <button className="btn btn-primary" onClick={() => toggleMFASettings()}>
                  Update Settings
                </button>
              </div>
            </div>
          </div> */}
          <div className="col-6">
            <AuthHistoryTable />
          </div>
          <div className="col-6">
            {/* <RegisteredDevicesTable /> */}
            {/* Display a message informing users that a new no password login feature is coming soon */}

            <div className="container-fluid">
              <h5>Introducing Our New No-Password Login!</h5>
              <p>
                We're excited to bring you a simpler, faster, and more secure way to access your account – with our new
                No-Password Login feature!
              </p>
              <h5>What is No-Password Login?</h5>
              <p>
                Say goodbye to remembering passwords! Our No-Password Login uses multi-factor authentication (MFA) to
                verify your identity. It's a more secure way to access your account and protect your information.
              </p>
              <h5>How does it work?</h5>
              <p>
                When you sign in, you'll receive a notification on your mobile device. Simply tap "Approve" to log in –
                no password needed!
              </p>
              <h5>Coming Soon!</h5>
              <p>
                We're working hard to bring you this new feature. Stay tuned for updates on when you can start using
                No-Password Login!
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <MFASettings show={showMFASettings} toggleModal={toggleMFASettings} isMFAEnabled={isTwoFactorEnabled} /> */}
    </>
  );
};
