import React, { useEffect, useRef, useState } from 'react';
const DirectionalTimeout = 1000;

export type DirectionalOptions = { upValue?: string; downValue?: string; disableFlash?: boolean };

export const useDirectionalClassValues = (options?: DirectionalOptions) => {
  const upValue = options && options.upValue ? options.upValue : 'pos bold';
  const downValue = options && options.downValue ? options.downValue : 'neg bold';
  return { upValue, downValue };
};

export const useDirectionalClass = (direction: 'up' | 'down' | undefined, value: number | undefined, options?: DirectionalOptions) => {
  const { upValue, downValue } = useDirectionalClassValues(options);
  const disableFlash = options && options.disableFlash ? true : false;
  const [directionClass, setdirectionClass] = useState('');
  const resetTimeout = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (direction) {
      let dirClass = '';
      if (direction === undefined) {
        dirClass = '';
      } else if (direction === 'down') {
        dirClass = downValue;
      } else {
        dirClass = upValue;
      }
      setdirectionClass(dirClass);
      if (disableFlash !== true) {
        if (resetTimeout && resetTimeout.current) {
          clearTimeout(resetTimeout.current);
        }

        resetTimeout.current = setTimeout(function () {
          setdirectionClass(`flash`);
        }, DirectionalTimeout);
      }
    }

    return () => {
      if (resetTimeout && resetTimeout.current) {
        clearTimeout(resetTimeout.current);
      }
    };
  }, [direction, downValue, upValue, value]);

  return directionClass;
};
export const useDirectionalValue = (value: number | undefined, options?: DirectionalOptions) => {
  const [currentValue, setCurrentValue] = useState();
  const [direction, setDirection] = useState();
  useEffect(() => {
    if (value && value !== currentValue) {
      if (currentValue === undefined) {
        setDirection(undefined);
      } else if (currentValue > value) {
        setDirection('down');
      } else if (currentValue < value) {
        setDirection('up');
      }
      setCurrentValue(value);
    }
  }, [value, currentValue]);
  return useDirectionalClass(direction, value, options);
};
export const Directional: React.FunctionComponent<{ wrapperClass?: string; direction: 'up' | 'down' | undefined; value?: number; options?: DirectionalOptions }> = ({ value, direction, options, wrapperClass, children }) => {
  const directionClass = useDirectionalClass(direction, value || undefined, options);
  return <div className={`${directionClass} ${wrapperClass || ''}`}>{children}</div>;
};

export interface DirectionalValueProps {
  value?: number;
  threshold?: number;
  options?: DirectionalOptions;
  wrapperClass?: string;
  precisionOverride?: boolean;
  prefix?: string;
}

export const DirectionalValue: React.FunctionComponent<DirectionalValueProps> = ({ value, children, options, wrapperClass, ...props }) => {
  const [threshold, setThreshold] = useState(props.threshold);
  const [direction, setDirection] = useState();
  useEffect(() => {
    if (value && value !== threshold) {
      if (threshold === undefined) {
        setDirection(undefined);
      } else if (threshold > value) {
        setDirection('down');
      } else if (threshold < value) {
        setDirection('up');
      }
      if (props.threshold === undefined) {
        setThreshold(value);
      }
    }
  }, [value, threshold]);

  return (
    <Directional value={value} direction={direction} options={options} wrapperClass={wrapperClass}>
      {/* <strong className="txt-lg">
        <span className="currency">$</span>
        <Number value={price} format />
      </strong> */}
      {children}
    </Directional>
  );
};
