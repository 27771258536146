import React, { useMemo, FC } from 'react';
import { AssetSymbol, BlockType } from '@tradingblock/types';
import {
    HttpAutocomplete,
    OptionType,
    Pill,
} from '@tradingblock/components';
import { SymbolToOption, useSymbolSearch } from '../../hooks/useSymbolSearch';
import { useQuote } from '../../hooks/useFeedQuote';
import { useDashboardSettings, useDashboardSettingsUpdater } from '../../hooks/useDashboardSettings';

interface DashboardSymbolPickerProps {
    blockType?: BlockType;
    symbol?: AssetSymbol | undefined;
    onSelect?: (value: OptionType<AssetSymbol>) => void;
    onClear?: () => void;
    placeholder?: string;
    clearOptionsOnSelect?: boolean;
    focus?: boolean;
    disableFavorite?: boolean;
    hidePrice?: boolean;
    hideTime?: boolean;
    hidePercent?: boolean;
    onSearch?: (value: string, limit?: number | undefined) => void;
    onBlur?: () => void;
    onChange?: () => void;
}

export const DashboardSymbolPicker: FC<DashboardSymbolPickerProps> = ({
    symbol,
    placeholder = 'Search symbol..',
    clearOptionsOnSelect = true,
    focus
}) => {
    const search = useSymbolSearch();
    const symbolValue = symbol ? symbol.symbol : '';
    const settingsUpdater = useDashboardSettingsUpdater();
    const settings = useDashboardSettings();
    const symbolQuote = useQuote(symbolValue);
    const autoCompleteValue = useMemo(() => {
        return symbol ? SymbolToOption(symbol, true) : undefined;
    }, [symbol]);
    const rest = useMemo(() => {
        return { autoFocus: focus };
    }, [focus]);

    const pillStyle = {
        'backgroundColor': 'var(--dark-blue)'
    }

    const buttonStyle = {
        'marginLeft': '0.2rem',
        'marginRight': '0rem',
        'marginTop': '0.3rem',
        'borderColor': 'var(--blue)',
        'borderWidth': '1px',
        'borderRadius': '5px',
        'borderStyle': 'solid',
        'padding': 'revert',
    }

    const symbolButtonStyle = {
        'display': 'flex'
    }

    const onSelect = (val: any) => {
        if (val && val.data && val.data.symbol && val.data.type) {
            settingsUpdater.updateSettings({
                ...settings,
                bannerSymbols: Array.from(new Set([...settings.bannerSymbols, val.data.symbol]))
            })
        }
    }

    const onBlur = () => { }
    const onClear = () => { }
    const onSearch = (value: string, limit: number | undefined) => {

    }
    const clearSymbolFromList: Function = (symbol_: any): void => {
        if (settings && settings.bannerSymbols && symbol_) {
            const newList = settings.bannerSymbols.filter((symbol: string) => symbol_ !== symbol);
            settingsUpdater.updateSettings({
                ...settings,
                bannerSymbols: newList
            })
        } else return
    }
    return (
        <>
            {settings.bannerSymbols && settings.bannerSymbols.length < 4 ? <div style={{ 'width': '50%' }}>
                <Pill pillStyle={pillStyle}>
                    <div>
                        <HttpAutocomplete
                            onClear={onClear}
                            rest={rest}
                            minimumLength={1}
                            search={(value: string, limit?: number | undefined) => {
                                if (onSearch !== undefined) onSearch(value, limit);
                                return search(value, limit)
                            }}
                            onBlur={onBlur}
                            value={autoCompleteValue}
                            onSelect={onSelect}
                            clearOptionsOnSelect={clearOptionsOnSelect}
                            placeholder={placeholder ? placeholder : ''}
                            debounceTimeout={500}
                            symbol={symbolValue}
                            charLimit={20}
                            rootPortalId='dashboardSymbolPicker'
                        />
                    </div>
                    {symbol && symbolQuote && (
                        <div className='nav-item nav-stock-name'>
                            <div className='txt-sm'><strong>{symbol.symbol}</strong></div>
                            <div className='txt-sm mute' title={symbol.name}>{symbol.name}</div>
                        </div>
                    )}
                </Pill>
            </div> : <div style={{ textDecoration: 'underline' }}>Remove a symbol to search. (Max 4)</div>}
            <div style={{ 'marginTop': '0.5rem' }}>{settings && settings.bannerSymbols && settings.bannerSymbols.length ? 'Remove symbols:' : ''}</div>
            <span style={symbolButtonStyle}>
                {settings && settings.bannerSymbols ?
                    settings.bannerSymbols.map((symbol: string, index: number) =>
                    (<button name={symbol} type='button' className="btn btn-dark" style={buttonStyle} key={index} onClick={() => clearSymbolFromList(symbol)}>{symbol}
                    </button>)) : <></>}
            </span>
        </>
    );
};
