import { Dispatch, Middleware } from 'redux';
import { getType, ActionType } from 'typesafe-actions';
import _ from 'lodash';
import {
  ApiResponse,
  ResponseCodes,
  GenericError,
  SuccessResponseCode,
  UserRole,
  TransferMechanism,
} from '@tradingblock/types';
import { getIdNumber, isString } from '@tradingblock/api';
import { DataState } from '../../../state';
import { RootAction } from '../../../actions';
import { AdminCashieringActions, AdminCashieringAction } from '../../../actions/admin/AdminCashieringActions';
import { buildErrorObject } from '../../../actionUtilities';
import { AdminAccountActions } from '../../../actions/admin/AdminAccountActions';
import { CashieringSearchRequestSelector } from '../../../selectors/admin/cashieringSelectors';
import { Dispatcher } from '../../../dispatcher';
import { ApiProvider, ApiClientType } from '../../../../../context/Api';

const cashieringSearch = (state: DataState, dispatch: Dispatch<RootAction>) => {
  const api = ApiProvider(state, dispatch);
  return {
    request: (action: ActionType<typeof AdminCashieringActions.cashieringSearchRequest>) => {
      return api.cashiering
        .search(action.payload)
        .then(resp => {
          dispatch(AdminCashieringActions.cashieringSearchReceive({ response: resp, request: action.payload }));
        })
        .catch(err => {
          dispatch(AdminCashieringActions.cashieringSearchError(err));
        });
    },
  };
};

const cashieringEntities = (state: DataState, dispatch: Dispatch<RootAction>) => {
  const api = ApiProvider(state, dispatch);
  async function apiGetHandler<T>(
    apiFunc: (api: ApiClientType) => Promise<ApiResponse<T>>,
    resultAction: (resp: ApiResponse<T>) => RootAction,
    errAction: (err: GenericError) => RootAction
  ) {
    return await apiFunc(api)
      .then(resp => {
        if (resp.responseCode !== 0) {
          const err = ResponseCodes[resp.responseCode]
            ? ResponseCodes[resp.responseCode]
            : { name: `Unknown ${resp.responseCode} ResponseCode`, description: '' };
          const message = isString(err.description) ? err.description : err.description(err.name);
          dispatch(errAction({ error: err, message }));
        } else {
          dispatch(resultAction(resp));
        }

        return resp.payload;
      })
      .catch(err => {
        console.warn('error fetching transfer', err);
        dispatch(errAction({ error: err }));
      });
  }
  return {
    transfers: {
      get: async (accountId: number, action: ActionType<typeof AdminCashieringActions.cashieringTransferRequest>) => {
        const transfer = await api.cashiering.transfers
          .get(accountId, action.payload.id)
          .then(resp => {
            if (resp.responseCode === SuccessResponseCode) {
              dispatch(AdminCashieringActions.cashieringTransferReceive(resp));
            } else {
              dispatch(AdminCashieringActions.cashieringTransferError(buildErrorObject(resp, action.payload)));
            }
            return resp.payload;
          })
          .catch(err => {
            console.warn('error fetching transfer', err);
            dispatch(AdminCashieringActions.cashieringTransferError({ error: err, data: action.payload }));
          });
        const entityAccountId = transfer && transfer.accountId ? transfer.accountId : undefined;
        if (entityAccountId) {
          //load account if not already loaded
          if (state.private.account.accounts[entityAccountId] === undefined) {
            dispatch(AdminAccountActions.accountRequest({ id: entityAccountId }));
          }
          dispatch(AdminAccountActions.accountBalancesRequest({ id: entityAccountId }));
          dispatch(AdminAccountActions.accountBalancesForWithdrawalRequest({ id: entityAccountId }));
        }
      },
      details: async (
        accountId: number,
        action: ActionType<typeof AdminCashieringActions.cashieringTransferDetailRequest>
      ) => {
        const id = getIdNumber(action.payload.id);

        // fetch details
        await api.cashiering.details
          .transferDetails(id)
          .then(resp => {
            if (resp.responseCode === SuccessResponseCode) {
              dispatch(AdminCashieringActions.cashieringTransferDetailReceive(resp));
            } else {
              dispatch(AdminCashieringActions.cashieringTransferDetailError(buildErrorObject(resp, action.payload)));
            }
            return resp.payload;
          })
          .catch(err => {
            console.warn('error fetching transfer details', err);
            dispatch(AdminCashieringActions.cashieringTransferDetailError({ error: err, data: action.payload }));
          });

        // also get transfer
        dispatch(AdminCashieringActions.cashieringTransferRequest({ id, accountId }));
      },
      approveRep: async (action: ActionType<typeof AdminCashieringActions.approveRepRequest>) => {
        return await api.cashiering.transfers.approve
          .rep(action.payload.accountId, action.payload.id)
          .then(resp => {
            if (resp.responseCode === SuccessResponseCode) {
              dispatch(AdminCashieringActions.approveRepReceive(resp));
            } else {
              dispatch(AdminCashieringActions.approveRepError(buildErrorObject(resp, action.payload)));
            }
          })
          .catch(err => dispatch(AdminCashieringActions.approveRepError(err)));
      },
      approveFirm: async (action: ActionType<typeof AdminCashieringActions.approveFirmRequest>) => {
        return await api.cashiering.transfers.approve
          .firm(action.payload.accountId, action.payload.id)
          .then(resp => {
            if (resp.responseCode === SuccessResponseCode) {
              dispatch(AdminCashieringActions.approveFirmReceive(resp));
            } else {
              dispatch(AdminCashieringActions.approveFirmError(buildErrorObject(resp, action.payload)));
            }
          })
          .catch(err => dispatch(AdminCashieringActions.approveFirmError(err)));
      },
      update: async (action: ActionType<typeof AdminCashieringActions.updateTransferRequest>) => {
        return await api.cashiering.transfers
          .update(action.payload.accountId, action.payload.id, {
            note: action.payload.note,
            adminNote: action.payload.adminNote,
            alertRep: action.payload.alertRep,
          })
          .then(resp => {
            if (resp.responseCode === SuccessResponseCode) {
              dispatch(AdminCashieringActions.updateTransferReceive(resp));
            } else {
              dispatch(AdminCashieringActions.updateTransferError(buildErrorObject(resp, action.payload)));
            }
          })
          .catch(err => dispatch(AdminCashieringActions.updateTransferError(err)));
      },
      reject: async (action: ActionType<typeof AdminCashieringActions.rejectTransferRequest>) => {
        const { accountId, id, note } = action.payload;
        return await api.cashiering.transfers
          .reject(accountId, id, note)
          .then(resp => {
            if (resp.responseCode === SuccessResponseCode) {
              dispatch(AdminCashieringActions.rejectTransferReceive(resp));
            } else {
              dispatch(AdminCashieringActions.rejectTransferError(buildErrorObject(resp, action.payload)));
            }
          })
          .catch(err => dispatch(AdminCashieringActions.rejectTransferError(err)));
      },
      cancel: async (action: ActionType<typeof AdminCashieringActions.cancelTransferRequest>) => {
        const { accountId, id, note } = action.payload;
        return await api.cashiering.transfers
          .cancel(accountId, id, note)
          .then(resp => {
            if (resp.responseCode === SuccessResponseCode) {
              dispatch(AdminCashieringActions.cancelTransferReceive(resp));
            } else {
              dispatch(AdminCashieringActions.cancelTransferError(buildErrorObject(resp, action.payload)));
            }
          })
          .catch(err => dispatch(AdminCashieringActions.cancelTransferError(err)));
      },
    },

    relationships: {
      get: async (
        accountId: number,
        action: ActionType<typeof AdminCashieringActions.cashieringRelationshipRequest>
      ) => {
        const relationship = await api.cashiering.ach
          .get(accountId, action.payload.id)
          .then(resp => {
            if (resp.responseCode === SuccessResponseCode) {
              dispatch(AdminCashieringActions.cashieringRelationshipReceive(resp));
            } else {
              dispatch(AdminCashieringActions.cashieringRelationshipError(buildErrorObject(resp, action.payload)));
            }
            return resp.payload;
          })
          .catch(err => {
            console.warn('error fetching relationship', err);
            dispatch(AdminCashieringActions.cashieringRelationshipError({ error: err, data: action.payload }));
          });

        const linkedAccountId = relationship && relationship.accountId ? relationship.accountId : undefined;
        if (linkedAccountId) {
          if (state.private.account.accounts[linkedAccountId] === undefined) {
            dispatch(AdminAccountActions.accountRequest({ id: linkedAccountId }));
          }
          dispatch(AdminAccountActions.accountBalancesRequest({ id: linkedAccountId }));
          dispatch(AdminAccountActions.accountBalancesForWithdrawalRequest({ id: linkedAccountId }));
        }
      },
      details: async (
        accountId: number,
        action: ActionType<typeof AdminCashieringActions.cashieringRelationshipDetailRequest>
      ) => {
        const id = getIdNumber(action.payload.id);

        // fetch details
        await api.cashiering.details
          .relationshipDetails(id)
          .then(resp => {
            if (resp.responseCode === SuccessResponseCode) {
              dispatch(AdminCashieringActions.cashieringRelationshipDetailReceive(resp));
            } else {
              dispatch(
                AdminCashieringActions.cashieringRelationshipDetailError(buildErrorObject(resp, action.payload))
              );
            }
            return resp.payload;
          })
          .catch(err => {
            console.warn('error fetching relationship details', err);
            dispatch(AdminCashieringActions.cashieringRelationshipDetailError({ error: err, data: action.payload }));
          });

        // also get relationship
        dispatch(AdminCashieringActions.cashieringRelationshipRequest({ id, accountId }));
      },
      approve: async (action: ActionType<typeof AdminCashieringActions.approveCreateRelationshipRequest>) => {
        const { accountId, id, note, mapToRelationshipId } = action.payload;
        return await api.cashiering.ach
          .approveCreate(accountId, id, note, mapToRelationshipId)
          .then(resp => {
            if (resp.responseCode === SuccessResponseCode) {
              dispatch(AdminCashieringActions.approveCreateRelationshipReceive(resp));
            } else {
              dispatch(AdminCashieringActions.approveCreateRelationshipError(buildErrorObject(resp, action.payload)));
            }
          })
          .catch(err => dispatch(AdminCashieringActions.approveCreateRelationshipError(err)));
      },
      update: async (action: ActionType<typeof AdminCashieringActions.updateRelationshipRequest>) => {
        return await api.cashiering.ach
          .update(action.payload.accountId, action.payload.id, {
            note: action.payload.note,
            adminNote: action.payload.adminNote,
            alertRep: action.payload.alertRep,
          })
          .then(resp => {
            if (resp.responseCode === SuccessResponseCode) {
              dispatch(AdminCashieringActions.updateRelationshipReceive(resp));
            } else {
              dispatch(AdminCashieringActions.updateRelationshipError(buildErrorObject(resp, action.payload)));
            }
          })
          .catch(err => dispatch(AdminCashieringActions.updateRelationshipError(err)));
      },
      reject: async (action: ActionType<typeof AdminCashieringActions.rejectRelationshipRequest>) => {
        const { accountId, id, note } = action.payload;
        return await api.cashiering.ach
          .rejectCreate(accountId, id, note)
          .then(resp => {
            if (resp.responseCode === SuccessResponseCode) {
              dispatch(AdminCashieringActions.rejectRelationshipReceive(resp));
            } else {
              dispatch(AdminCashieringActions.rejectRelationshipError(buildErrorObject(resp, action.payload)));
            }
          })
          .catch(err => dispatch(AdminCashieringActions.rejectRelationshipError(err)));
      },
      cancel: async (action: ActionType<typeof AdminCashieringActions.cancelRelationshipRequest>) => {
        const { accountId, id, note } = action.payload;
        return await api.cashiering.ach
          .cancel(accountId, id, note)
          .then(resp => {
            if (resp.responseCode === SuccessResponseCode) {
              dispatch(AdminCashieringActions.cancelRelationshipReceive(resp));
            } else {
              dispatch(AdminCashieringActions.cancelRelationshipError(buildErrorObject(resp, action.payload)));
            }
          })
          .catch(err => dispatch(AdminCashieringActions.cancelRelationshipError(err)));
      },
    },
    wireInstructions: {
      get: async (
        accountId: number,
        action: ActionType<typeof AdminCashieringActions.cashieringWireInstructionsRequest>
      ) => {
        const id = getIdNumber(action.payload.id);
        await api.cashiering.transferInstructions
          .get(accountId, id)
          .then(resp => {
            if (resp.responseCode === SuccessResponseCode) {
              dispatch(AdminCashieringActions.cashieringWireInstructionsReceive(resp));
            } else {
              dispatch(AdminCashieringActions.cashieringWireInstructionsError(buildErrorObject(resp, action.payload)));
            }
          })
          .catch(err => {
            console.warn('error fetching wire instructions', err);
            dispatch(AdminCashieringActions.cashieringWireInstructionsError({ error: err, data: action.payload }));
          });
        const entityAccountId = getIdNumber(action.payload.accountId);
        if (entityAccountId) {
          //load account if not already loaded
          if (state.private.account.accounts[entityAccountId] === undefined) {
            dispatch(AdminAccountActions.accountRequest({ id: entityAccountId }));
          }
          dispatch(AdminAccountActions.accountBalancesRequest({ id: entityAccountId }));
          dispatch(AdminAccountActions.accountBalancesForWithdrawalRequest({ id: entityAccountId }));
        }
      },
      details: async (
        accountId: number,
        action: ActionType<typeof AdminCashieringActions.cashieringWireInstructionsDetailsRequest>
      ) => {
        const id = getIdNumber(action.payload.id);
        await api.cashiering.transferInstructions
          .details(id)
          .then(resp => {
            if (resp.responseCode === SuccessResponseCode) {
              dispatch(AdminCashieringActions.cashieringWireInstructionsDetailsReceive(resp));
            } else {
              dispatch(
                AdminCashieringActions.cashieringWireInstructionsDetailsError(buildErrorObject(resp, action.payload))
              );
            }
          })
          .catch(err => {
            console.warn('error fetching wire instructions details', err);
            dispatch(
              AdminCashieringActions.cashieringWireInstructionsDetailsError({ error: err, data: action.payload })
            );
          });
        dispatch(AdminCashieringActions.cashieringWireInstructionsRequest({ accountId, id }));
      },
      update: async (action: ActionType<typeof AdminCashieringActions.cashieringWireInstructionsUpdateRequest>) => {
        const id = getIdNumber(action.payload.id);
        return await api.cashiering.transferInstructions
          .update(action.payload.accountId, id, {
            nickName: action.payload.nickName,
            note: action.payload.note,
            adminNote: action.payload.adminNote,
            alertRep: action.payload.alertRep,
          })
          .then(resp => {
            if (resp.responseCode === SuccessResponseCode) {
              dispatch(AdminCashieringActions.cashieringWireInstructionsUpdateReceive(resp));
            } else {
              dispatch(
                AdminCashieringActions.cashieringWireInstructionsUpdateError(buildErrorObject(resp, action.payload))
              );
            }
          })
          .catch(err => dispatch(AdminCashieringActions.cashieringWireInstructionsUpdateError(err)));
      },
      approve: async (action: ActionType<typeof AdminCashieringActions.cashieringWireInstructionsApproveRequest>) => {
        const { accountId, note, adminNote, nickName } = action.payload;
        const id = getIdNumber(action.payload.id);

        return await api.cashiering.transferInstructions
          .approveCreate(accountId, id, { note: note || '', adminNote: adminNote || '', nickName })
          .then(resp => {
            if (resp.responseCode === SuccessResponseCode) {
              dispatch(
                AdminCashieringActions.cashieringWireInstructionsApproveReceive({ instructionId: id, data: resp })
              );
            } else {
              dispatch(
                AdminCashieringActions.cashieringWireInstructionsApproveError(buildErrorObject(resp, action.payload))
              );
            }
            dispatch(AdminCashieringActions.cashieringWireInstructionsDetailsRequest({ accountId, id }));
          });
      },
      reject: async (action: ActionType<typeof AdminCashieringActions.cashieringWireInstructionsRejectRequest>) => {
        const { accountId, note, adminNote } = action.payload;
        const id = getIdNumber(action.payload.id);

        return await api.cashiering.transferInstructions.rejectCreate(accountId, id).then(resp => {
          if (resp.responseCode === SuccessResponseCode) {
            dispatch(AdminCashieringActions.cashieringWireInstructionsRejectReceive({ instructionId: id, data: resp }));
          } else {
            dispatch(
              AdminCashieringActions.cashieringWireInstructionsRejectError(buildErrorObject(resp, action.payload))
            );
          }
          dispatch(AdminCashieringActions.cashieringWireInstructionsDetailsRequest({ accountId, id }));
        });
      },
      cancel: async (action: ActionType<typeof AdminCashieringActions.cashieringWireInstructionsCancelRequest>) => {
        const { accountId, note, adminNote } = action.payload;
        const id = getIdNumber(action.payload.id);

        return await api.cashiering.transferInstructions.cancel(accountId, id).then(resp => {
          if (resp.responseCode === SuccessResponseCode) {
            dispatch(AdminCashieringActions.cashieringWireInstructionsCancelReceive(resp));
          } else {
            dispatch(
              AdminCashieringActions.cashieringWireInstructionsCancelError(buildErrorObject(resp, action.payload))
            );
          }
        });
      },
    },
  };
};

export const AdminCashieringMiddleware: Middleware<
  Dispatch<AdminCashieringAction>,
  DataState,
  Dispatch<RootAction>
> = ({ dispatch, getState }) => (next: Dispatch<AdminCashieringAction>) => (action: AdminCashieringAction) => {
  try {
    // state BEFORE action is dispatched
    const prevState = getState();
    const result = next(action);
    const nextState = getState();

    const search = cashieringSearch(prevState, dispatch);
    const entityHandler = cashieringEntities(prevState, dispatch);
    try {
      switch (action.type) {
        case getType(AdminCashieringActions.setPage):
        case getType(AdminCashieringActions.setPageSize):
        case getType(AdminCashieringActions.setType):
        case getType(AdminCashieringActions.setAccountStatus):
        case getType(AdminCashieringActions.setSortBy):
        case getType(AdminCashieringActions.setTimeframe):
        case getType(AdminCashieringActions.setStatus):
        case getType(AdminCashieringActions.refresh):
        case getType(AdminCashieringActions.setRepCode):
        case getType(AdminCashieringActions.setSearch): {
          const prevRequestKey = CashieringSearchRequestSelector(prevState);
          const { request, key } = CashieringSearchRequestSelector(nextState);
          if (prevRequestKey.key === key && getType(AdminCashieringActions.refresh) !== action.type) {
            //request hasn't changed
            break;
          }
          if (
            prevState.account.profile.current &&
            prevState.account.profile.current.roles &&
            prevState.account.profile.current.roles.includes(UserRole.CashieringApproval)
          ) {
            dispatch(AdminCashieringActions.cashieringSearchRequest(request));
          }
          break;
        }
        case getType(AdminCashieringActions.cashieringSearchRequest): {
          search.request(action);
          break;
        }
        case getType(AdminCashieringActions.cashieringRelationshipRequest): {
          entityHandler.relationships.get(_.toInteger(action.payload.accountId), action);
          break;
        }
        case getType(AdminCashieringActions.cashieringRelationshipDetailRequest): {
          entityHandler.relationships.details(_.toInteger(action.payload.accountId), action);
          break;
        }
        case getType(AdminCashieringActions.cashieringTransferRequest): {
          entityHandler.transfers.get(_.toInteger(action.payload.accountId), action);
          break;
        }
        case getType(AdminCashieringActions.cashieringTransferDetailRequest): {
          entityHandler.transfers.details(_.toInteger(action.payload.accountId), action);
          break;
        }
        case getType(AdminCashieringActions.approveRepRequest): {
          entityHandler.transfers.approveRep(action);
          break;
        }
        case getType(AdminCashieringActions.approveFirmRequest): {
          entityHandler.transfers.approveFirm(action);
          break;
        }
        case getType(AdminCashieringActions.approveCreateRelationshipRequest): {
          entityHandler.relationships.approve(action);
          break;
        }
        case getType(AdminCashieringActions.updateRelationshipRequest): {
          entityHandler.relationships.update(action);
          break;
        }
        case getType(AdminCashieringActions.updateTransferRequest): {
          entityHandler.transfers.update(action);
          break;
        }
        case getType(AdminCashieringActions.rejectRelationshipRequest): {
          entityHandler.relationships.reject(action);
          break;
        }
        case getType(AdminCashieringActions.rejectTransferRequest): {
          entityHandler.transfers.reject(action);
          break;
        }
        case getType(AdminCashieringActions.cancelRelationshipRequest): {
          entityHandler.relationships.cancel(action);
          break;
        }
        case getType(AdminCashieringActions.cancelTransferRequest): {
          entityHandler.transfers.cancel(action);
          break;
        }
        case getType(AdminCashieringActions.cashieringWireInstructionsRequest): {
          entityHandler.wireInstructions.get(_.toInteger(action.payload.accountId), action);
          break;
        }
        case getType(AdminCashieringActions.cashieringWireInstructionsDetailsRequest): {
          entityHandler.wireInstructions.details(_.toInteger(action.payload.accountId), action);
          break;
        }
        case getType(AdminCashieringActions.cashieringWireInstructionsUpdateRequest): {
          entityHandler.wireInstructions.update(action);
          break;
        }
        case getType(AdminCashieringActions.cashieringWireInstructionsApproveRequest): {
          entityHandler.wireInstructions.approve(action);
          break;
        }
        case getType(AdminCashieringActions.cashieringWireInstructionsRejectRequest): {
          entityHandler.wireInstructions.reject(action);
          break;
        }
        case getType(AdminCashieringActions.cashieringWireInstructionsCancelRequest): {
          entityHandler.wireInstructions.cancel(action);
          break;
        }
      }
    } catch (apiError) {
      Dispatcher(dispatch).global.exception({ error: apiError, data: action });
    }

    return result;
  } catch (err) {
    console.error('adminCashieringHandler :: Caught an exception for action ', action, err);
  }
};
