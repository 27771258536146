import React from 'react';
import _ from 'lodash';

export interface OrderActionButtonProps
  extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  onClick: () => void;
  title: string;
  icon: string;
}

export const OrderActionButton: React.FC<OrderActionButtonProps> = ({ onClick, icon, title }) => {
  return (
    <a title={title} onClick={onClick}>
      <span className="fa-stack">
        <i className="fal fa-clipboard fa-stack-2x"></i>
        <i className={`fal fa-stack-1x ${icon}`}></i>
      </span>
    </a>
  );
};

export type OrderButtonProps = Omit<OrderActionButtonProps, 'title' | 'icon'>;
export const CancelOrderButton: React.FC<OrderButtonProps> = props => (
  <OrderActionButton title="Cancel order" icon="fa-times neg" {...props} />
);
export const ReplaceOrderButton: React.FC<OrderButtonProps> = props => (
  <OrderActionButton title="Replace order" icon="fa-pencil" {...props} />
);
export const TradeOrderButton: React.FC<OrderButtonProps> = props => (
  <OrderActionButton title="Trade" icon="fa-clone" {...props} />
);
export const CloseOrderButton: React.FC<OrderButtonProps> = props => (
  <OrderActionButton title="Close position" icon="" {...props} />
);
export const RollOrderButton: React.FC<OrderButtonProps> = props => (
  <OrderActionButton title="Roll position" icon="fa-redo" {...props} />
);
export const FakeOrderButton: React.FC<{}> = props => (
  <OrderActionButton
    onClick={() => alert('you clicked a fake button!')}
    title="Test order action"
    icon={
      _.sample(['fa-wine-glass', 'fa-wine-bottle', 'fa-beer', 'fa-baseball', 'fa-grin', 'fa-grin-squint-tears']) ||
      'fa-question'
    }
    {...props}
  />
);
export const ChartButton: React.FC<OrderButtonProps> = props => (
  <OrderActionButton title="Chart" icon="fa-chart-line" {...props} />
);
