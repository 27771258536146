import React, { useMemo } from 'react';
import _ from 'lodash';
import { Svg } from '@tradingblock/components';
import { useStateSelector, getNotifications } from '../../data/global/dataSelectors';
import { DashboardNotification } from './DashboardNotification';

export const ClientNotifications: React.FunctionComponent = () => {
  const notifications = useStateSelector(getNotifications);

  // disable when no notifications to show
  const isDisabled = useMemo(() => _.isEmpty(notifications), [notifications]);

  return (
    <DashboardNotification title="Notifications" notificationClass="tb-icon-counter-value-notifications" notifications={notifications} disabled={isDisabled}>
      <Svg path="icon-bell" />
    </DashboardNotification>
  );
};
