import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { Field } from 'formik';
import { CashieringRoute, BalanceTotals } from '@tradingblock/types';
import { FormGroup, CurrencyField, SelectDropdownInput, formatNumber } from '@tradingblock/components';
import { CashieringViewFormValues, CashieringViewProps, ViewOnValidateType } from './data/useCashieringView';
import { validateTransferAmount } from './data/cashieringValidation';
import { cashieringSelectors, useStateSelector, subAccountIdSelector } from '../../../data/global/dataSelectors';
import { account } from '../../../data/global/selectors/accountSelectors';
import { Actions } from '../../../data/global/actions';
import { useDispatcher } from '../../../data/global/hooks';

interface DepositAmountViewContentProps extends CashieringViewProps<CashieringViewFormValues> {
  getAccountBalances: (subaccountId?: number) => void;
  totals: BalanceTotals;
  nickname: string;
  isIRA?: boolean;
}

const DepositAmountViewContent: React.FC<DepositAmountViewContentProps> = ({
  values,
  setValues,
  getAccountBalances,
  totals,
  nickname,
  children,
}) => {
  const globalSubAccountId = useStateSelector(subAccountIdSelector);
  const [transferSubAccountId, setTransferSubAccountId] = useState<number>();

  const subAccounts = useStateSelector(account.subAccounts);
  const masterAccount = useStateSelector(account.masterAccount);
  const currentSelectedSubAccountId = useStateSelector(account.subAccountId);
  const subAccountOptions = useMemo(() => {
    return _.map(subAccounts, sa => ({
      value: sa.Id,
      label: `${sa.Nickname} (${sa.AcctSuffix})`,
    }));
  }, [subAccounts]);
  const accountId = useStateSelector(account.accountId);
  const subAccountBalances = useStateSelector(account.subAccountsBalances);
  const dispatch = useDispatch();

  useEffect(() => {
    if (subAccounts && subAccounts.length > 0 && accountId) {
      dispatch(Actions.requestSubAccountBalances({ accountId, subAccounts: subAccounts }));
    }
  }, [subAccounts, dispatch, accountId]);

  useEffect(() => {
    if (values.transfer && values.transfer.subAccountId) {
      setTransferSubAccountId(values.transfer.subAccountId);
    }
    if (globalSubAccountId) {
      // If globally selected sub account, force select the dropdown
      setTransferSubAccountId(globalSubAccountId);
      setValues({ ...values, transfer: { ...values.transfer, subAccountId: globalSubAccountId } });
    }
  }, []);

  useEffect(() => {
    if (
      subAccounts &&
      !_.isEmpty(subAccounts) &&
      (globalSubAccountId === undefined || globalSubAccountId === undefined)
    ) {
      setValues({
        ...values,
        transfer: {
          ...values.transfer,
          amount: values.transfer
            ? Object.values(values.transfer.subAccountsValues ? values.transfer.subAccountsValues : {}).reduce(
                (acc: number, v) => {
                  if (typeof v === 'string') {
                    //Since the currency component will convert it to a string, need to convert it back to a number
                    let value = Number(v.replace(/[^0-9.-]+/g, ''));
                    return acc + value;
                  } else if (typeof v === 'number') {
                    //But if the user has already entered a number, manually, just use it
                    return acc + v;
                  }
                  return acc;
                },
                0 as number
              )
            : 0,
        },
      });
    }
  }, [subAccounts, values.transfer ? values.transfer.subAccountsValues : false]);

  useEffect(() => {
    if (globalSubAccountId === undefined) {
      if (masterAccount !== undefined) {
        setTransferSubAccountId(masterAccount.Id);
        setValues({ ...values, transfer: { ...values.transfer, subAccountId: masterAccount.Id } });
      }
    }
  }, [globalSubAccountId]);

  const onSelectSubAccount = useCallback(
    (id: number) => {
      setTransferSubAccountId(id);
      setValues({ ...values, transfer: { ...values.transfer, amount: 0, subAccountId: id } });
      getAccountBalances(id);
    },
    [setTransferSubAccountId, values]
  );

  return (
    <>
      <div className="screen-body-section">
        <p>
          <span className="mute">Deposit to:</span> {nickname}.
        </p>
        {subAccounts !== undefined && !_.isEmpty(subAccounts) && globalSubAccountId === undefined ? (
          <FormGroup title="How much do you wish to deposit to your sub-accounts?">
            {subAccounts.map(subAccount => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    flex: 3 / 4,
                  }}
                >
                  <Field
                    key={subAccount.Id}
                    component={CurrencyField}
                    id={`transfer.subAccountsValues.${subAccount.Id}`}
                    label={`${subAccount.Nickname}:`}
                  />
                </div>
                <span
                  style={{
                    flex: 1 / 4,
                    marginLeft: '1rem',
                    fontWeight: 'bold',
                    color: '#A4CFFF',
                  }}
                >
                  Current balance:{' '}
                  {subAccountBalances[`${subAccount.Id}`]
                    ? formatNumber(subAccountBalances[`${subAccount.Id}`].Balances.WithdrawalAmount, { currency: true })
                    : 'Loading...'}
                </span>
              </div>
            ))}
          </FormGroup>
        ) : (
          subAccounts !== undefined &&
          !_.isEmpty(subAccounts) && (
            <FormGroup title="Selected Sub-Account">
              <SelectDropdownInput
                name="transfer.subAccountId"
                label="Sub-account"
                options={subAccountOptions}
                value={transferSubAccountId}
                disabled={globalSubAccountId !== undefined}
                onchange={(e, subAccountId) => onSelectSubAccount(subAccountId)}
              />
            </FormGroup>
          )
        )}
        <FormGroup
          title={
            globalSubAccountId === undefined && subAccounts !== undefined
              ? 'Total to deposit'
              : 'How much do you wish to deposit?'
          }
        >
          <Field
            component={CurrencyField}
            disabled={subAccounts !== undefined && subAccounts.length > 0 && globalSubAccountId === undefined}
            id="transfer.amount"
            label="Amount"
          />
        </FormGroup>
      </div>

      {children}
    </>
  );
};

const DepositAmountView: React.FC<CashieringViewProps<CashieringViewFormValues>> = props => {
  const { values } = props;
  const totals = useSelector(cashieringSelectors.balanceTotals);
  const subAccounts = useStateSelector(account.subAccounts);
  const accountInfo = useStateSelector(s =>
    s.account
      ? {
          nickname: s.account.nickname,
          accountNumber: s.account.accountNumber,
          isIRA: s.account.isIRA,
        }
      : undefined
  );

  const { dispatcher } = useDispatcher();
  const globalSubAccountId = useStateSelector(subAccountIdSelector);
  const selectedSubAccount = values.transfer && values.transfer.subAccountId;

  useEffect(() => {
    dispatcher.balances.requestPendingTransfers();
    if (globalSubAccountId) {
      dispatcher.cashiering.balances.request(globalSubAccountId);
    } else if (selectedSubAccount && subAccounts) {
      const selectedSubAccountDetails = subAccounts.find(s => s.Id === selectedSubAccount);
      if (selectedSubAccountDetails && selectedSubAccountDetails.IsMaster === false) {
        dispatcher.cashiering.balances.request(selectedSubAccount);
      }
    } else {
      dispatcher.cashiering.balances.request();
    }
  }, []);

  const getAccountBalances = useCallback(
    (subaccountId?: number) => dispatcher.cashiering.balances.request(subaccountId),
    [dispatcher]
  );

  return (
    <>
      {totals && accountInfo ? (
        <DepositAmountViewContent {...props} getAccountBalances={getAccountBalances} totals={totals} {...accountInfo} />
      ) : (
        `Account details not loaded`
      )}
    </>
  );
};

export const useDepositAmountView = (): [typeof DepositAmountView, undefined, ViewOnValidateType] => {
  const hasSubAccounts = useSelector(account.hasSubAccounts);

  const onValidate = useCallback(
    (values: CashieringViewFormValues) => {
      return validateTransferAmount(values, CashieringRoute.Deposit, hasSubAccounts);
    },
    [hasSubAccounts]
  );

  return [DepositAmountView, undefined, onValidate];
};
