import { getType } from 'typesafe-actions';
import _ from 'lodash';
import {
  SuccessResponseCode,
  AlertType,
  CashieringSearchEntityWithKey,
  CashieringPendingStates,
} from '@tradingblock/types';
import { getResponseCodeDescription } from '../../../utilities/action';
import { InitialState } from '../initialState';
import { RootAction } from '../actions';
import { CashieringActions } from '../actions/CashieringActions';
import { CashieringEventActions } from '../actions/CashieringEventActions';
import { AdminCashieringSearchActions } from '../actions/admin/AdminCashieringActions';
import { buildErrorObject } from '../actionUtilities';
import { getBalanceTotals } from '../utilities/balances';
import { getEntityKey, latestDate } from '../utilities/cashieringUtilities';

export const cashiering = (state = InitialState.cashiering, action: RootAction) => {
  switch (action.type) {
    case getType(CashieringEventActions.beginPollingForPending): {
      return {
        ...state,
        isPollingForPending: true,
      };
    }
    case getType(CashieringEventActions.stopPollingForPending): {
      return {
        ...state,
        isPollingForPending: false,
      };
    }
    case getType(AdminCashieringSearchActions.cashieringSearchRequest):
    case getType(CashieringEventActions.cashieringPendingSearchRequest): {
      return {
        ...state,
        isFetchingEntities: true,
      };
    }
    case getType(CashieringEventActions.cashieringPendingSearchError): {
      return {
        ...state,
        isFetchingEntities: false,
      };
    }
    case getType(CashieringEventActions.cashieringPendingSearchReceive):
    case getType(AdminCashieringSearchActions.cashieringSearchReceive): {
      const incomingData = _.map(
        action.payload.response.payload.data,
        (re): CashieringSearchEntityWithKey => ({ ...re, entityKey: getEntityKey(re) })
      );

      const allEntities = _.reduce(
        incomingData,
        (acc, val) => {
          const existing = acc[val.entityKey];
          return {
            ...acc,
            [val.entityKey]: {
              ...(existing || {}),
              ...val,
            },
          };
        },
        { ...state.entities }
      );

      const latestEventDate = latestDate(_.values(allEntities).filter(e => CashieringPendingStates.includes(e.status)));
      return {
        ...state,
        entities: allEntities,
        latestPendingCashieringEventDate: latestEventDate ? latestEventDate.toDate() : undefined,
        isFetchingEntities: false,
      };
    }
    case getType(CashieringActions.requestBalances):
      return {
        ...state,
        isFetchingBalances: true,
      };
    case getType(CashieringActions.receiveBalances):
      const balances = action.payload.balances || undefined;
      if (!balances || !balances.Balances) {
        return { ...state, balances: {} };
      }

      const pendingTransfers = action.payload.pendingTransfers;
      const transfers = state.transfers.data;
      const totals = getBalanceTotals(
        balances,
        pendingTransfers ? pendingTransfers : transfers ? transfers.filter(t => t.direction === 'Outgoing') : undefined
      );

      return {
        ...state,
        balances: {
          balances,
          totals,
        },
        isFetchingBalances: false,
      };
    case getType(CashieringActions.requestTransfers):
      return {
        ...state,
        transfers: {
          ...state.transfers,
          isFetching: true,
        },
      };
    case getType(CashieringActions.receiveTransfers):
      return {
        ...state,
        transfers: {
          ...state.transfers,
          isFetching: false,
          data: action.payload.data,
        },
      };
    case getType(CashieringActions.resetForm):
      return {
        ...state,
        securityQuestion: {
          ...state.securityQuestion,
          data: undefined,
        },
        forms: {
          ...state.forms,
          account: {
            ...state.forms.account,
            status: undefined,
            message: undefined,
          },
          transfer: {
            ...state.forms.transfer,
            status: undefined,
            message: undefined,
          },
        },
      };
    case getType(CashieringActions.requestSecurityQuestion):
      return {
        ...state,
        securityQuestion: {
          ...state.securityQuestion,
          isFetching: true,
        },
      };
    case getType(CashieringActions.receiveSecurityQuestion):
      return {
        ...state,
        securityQuestion: {
          ...state.securityQuestion,
          isFetching: false,
          data: action.payload.data,
        },
      };
    case getType(CashieringActions.securityQuestionError):
      return {
        ...state,
        securityQuestion: {
          ...state.securityQuestion,
          isFetching: false,
          status: AlertType.danger,
          message: action.payload.message,
        },
        forms: {
          ...state.forms,
          transfer: {
            ...state.forms.transfer,
            status: AlertType.danger,
            message: action.payload.message,
          },
        },
      };
    case getType(CashieringActions.requestVerifySecurityQuestion):
      return {
        ...state,
        forms: {
          ...state.forms,
          transfer: {
            ...state.forms.transfer,
            isSaving: true,
            status: undefined,
            message: undefined,
          },
        },
      };
    case getType(CashieringActions.receiveVerifySecurityQuestion):
      return {
        ...state,
        securityQuestion: {
          ...state.securityQuestion,
          status: !action.payload.result ? AlertType.warning : AlertType.success,
          message: action.payload.message,
        },
        forms: {
          ...state.forms,
          transfer: {
            ...state.forms.transfer,
            isSaving: false,
            status: !action.payload.result ? AlertType.warning : undefined,
            message: action.payload.message,
          },
        },
      };
    case getType(CashieringActions.resetSecurityQuestionStatus):
      return {
        ...state,
        securityQuestion: {
          ...state.securityQuestion,
          status: undefined,
          message: undefined,
        },
      };
    case getType(CashieringActions.allAchRelationshipRequest):
      return {
        ...state,
        achRelationships: {
          ...state.achRelationships,
          isFetching: true,
        },
      };
    case getType(CashieringActions.allAchRelationshipReceive):
      return {
        ...state,
        achRelationships: {
          ...state.achRelationships,
          isFetching: false,
          data: action.payload.payload || [],
        },
      };

    case getType(CashieringActions.plaidLinkTokenReceive):
      return {
        ...state,
        plaidLinkToken: {
          ...state.plaidLinkToken,
          ...action.payload.payload,
        },
      };

    case getType(CashieringActions.createAchRelationshipRequest):
      return {
        ...state,
        forms: {
          ...state.forms,
          account: {
            ...state.forms.account,
            isSaving: true,
            status: undefined,
            message: undefined,
          },
        },
      };
    case getType(CashieringActions.createAchRelationshipReceive):
      const { payload, responseCode } = action.payload;
      const errorObject =
        responseCode !== SuccessResponseCode ? buildErrorObject({ responseCode, payload }, undefined) : undefined;
      const error =
        responseCode !== SuccessResponseCode
          ? `Error saving linked account: ${errorObject && errorObject.message}`
          : undefined;
      const message = error ? undefined : 'Linked account has been saved!';
      return {
        ...state,
        forms: {
          ...state.forms,
          account: {
            ...state.forms.account,
            isSaving: false,
            created: payload,
            status: error ? AlertType.danger : AlertType.success,
            message: error || message,
          },
        },
      };

    case getType(CashieringActions.approveAchRelationshipRequest):
      return {
        ...state,
        forms: {
          ...state.forms,
          account: {
            ...state.forms.account,
            isSaving: true,
            status: undefined,
            message: undefined,
          },
        },
      };
    case getType(CashieringActions.approveAchRelationshipReceive):
      const approved =
        action.payload.responseCode === SuccessResponseCode && action.payload.payload.state === 'Approved';
      const verificationCount =
        action.payload.payload.verification && action.payload.payload.verification.verificationCount;
      const remainingTryCount = 3 - (verificationCount || 0);
      return {
        ...state,
        forms: {
          ...state.forms,
          account: {
            ...state.forms.account,
            isSaving: false,
            status: approved ? AlertType.success : remainingTryCount > 0 ? AlertType.warning : AlertType.danger,
            message: approved
              ? 'Linked account has been verified!'
              : remainingTryCount > 0
              ? `The deposits didn’t match. Try again. You have ${remainingTryCount} ${
                  remainingTryCount === 1 ? 'try' : 'tries'
                } left.`
              : 'The deposits didn’t match. You have no attempts left, please contact customer service.',
          },
        },
      };
    case getType(CashieringActions.approveAchRelationshipError):
      return {
        ...state,
        forms: {
          ...state.forms,
          account: {
            ...state.forms.account,
            isSaving: false,
            status: AlertType.warning,
            message: action.payload.message || action.payload.error,
          },
        },
      };
    case getType(CashieringActions.updateAchRelationshipRequest):
    case getType(CashieringActions.deleteAchRelationshipRequest):
      return {
        ...state,
        achRelationships: {
          ...state.achRelationships,
          isSaving: true,
          status: undefined,
          message: undefined,
        },
      };

    case getType(CashieringActions.requestAchDeposit):
    case getType(CashieringActions.requestAchWithdrawal):
    case getType(CashieringActions.requestWireWithdrawal):
    case getType(CashieringActions.requestCheckWithdrawal): {
      return {
        ...state,
        forms: {
          ...state.forms,
          transfer: {
            ...state.forms.transfer,
            isSaving: true,
            status: undefined,
            message: undefined,
          },
        },
      };
    }
    case getType(CashieringActions.depositAchError):
    case getType(CashieringActions.withdrawalAchError):
    case getType(CashieringActions.withdrawalWireError):
    case getType(CashieringActions.withdrawalCheckError): {
      return {
        ...state,
        forms: {
          ...state.forms,
          transfer: {
            ...state.forms.transfer,
            isSaving: false,
            status: AlertType.danger,
            message: action.payload.message,
          },
        },
      };
    }

    case getType(CashieringActions.updateAchRelationshipError):
    case getType(CashieringActions.deleteAchRelationshipError):
      return {
        ...state,
        achRelationships: {
          ...state.achRelationships,
          isSaving: false,
          status: AlertType.danger,
          message: action.payload.message,
        },
      };
    case getType(CashieringActions.receiveAchDeposit):
    case getType(CashieringActions.receiveAchWithdrawal):
    case getType(CashieringActions.receiveWireWithdrawal):
    case getType(CashieringActions.receiveCheckWithdrawal): {
      const transferType = action.type === getType(CashieringActions.receiveAchDeposit) ? 'Deposit' : 'Withdrawal';
      return {
        ...state,
        transfers: {
          ...state.transfers,
          newTransferId: action.payload.responseCode !== SuccessResponseCode ? undefined : action.payload.payload.id,
        },
        achRelationships: {
          ...state.achRelationships,
          data: undefined, // clear out data to force re-fetch
        },
        forms: {
          ...state.forms,
          transfer: {
            ...state.forms.transfer,
            isSaving: false,
            status: action.payload.responseCode !== SuccessResponseCode ? AlertType.warning : AlertType.success,
            message:
              action.payload.responseCode !== SuccessResponseCode
                ? getResponseCodeDescription(action.payload.responseCode, `${transferType} transfer request failed`)
                : `${transferType} transfer has been created!`,
          },
        },
      };
    }

    case getType(CashieringActions.updateAchRelationshipReceive):
      return {
        ...state,
        achRelationships: {
          ...state.achRelationships,
          isSaving: false,
          status: action.payload.responseCode !== SuccessResponseCode ? AlertType.warning : AlertType.success,
          message:
            action.payload.responseCode !== SuccessResponseCode
              ? getResponseCodeDescription(action.payload.responseCode)
              : 'Linked account has been renamed!',
        },
      };
    case getType(CashieringActions.deleteAchRelationshipReceive):
      return {
        ...state,
        achRelationships: {
          ...state.achRelationships,
          isSaving: false,
          status: action.payload.responseCode !== SuccessResponseCode ? AlertType.warning : AlertType.success,
          message:
            action.payload.responseCode !== SuccessResponseCode
              ? getResponseCodeDescription(action.payload.responseCode)
              : 'Linked account has been deleted!',
        },
      };

    case getType(CashieringActions.deleteTransferRequest):
      return {
        ...state,
        transfers: {
          ...state.transfers,
          isFetching: false,
          isSaving: true,
          status: undefined,
          message: undefined,
        },
      };
    case getType(CashieringActions.deleteTransferReceive):
      return {
        ...state,
        transfers: {
          ...state.transfers,
          data: undefined,
          isSaving: false,
          isFetching: false,
          status: action.payload.responseCode === SuccessResponseCode ? AlertType.success : AlertType.warning,
          message:
            action.payload.responseCode === SuccessResponseCode
              ? 'Transfer has been deleted!'
              : getResponseCodeDescription(action.payload.responseCode, 'Transfer delete request failed'),
        },
      };
    case getType(CashieringActions.deleteTransferError):
      return {
        ...state,
        transfers: {
          ...state.transfers,
          isSaving: false,
          isFetching: false,
          status: AlertType.danger,
          message: action.payload.message,
        },
      };

    case getType(CashieringActions.allTransferInstructionsRequest):
      return {
        ...state,
        transferInstructions: {
          ...state.transferInstructions,
          isFetching: true,
          isSaving: false,
        },
      };
    case getType(CashieringActions.allTransferInstructionsReceive):
      return {
        ...state,
        transferInstructions: {
          data: action.payload.payload || [],
          isFetching: false,
          isSaving: false,
        },
      };

    case getType(CashieringActions.createTransferInstructionsRequest):
    case getType(CashieringActions.updateTransferInstructionsRequest):
    case getType(CashieringActions.deleteTransferInstructionsRequest):
      return {
        ...state,
        transferInstructions: {
          ...state.transferInstructions,
          isFetching: false,
          isSaving: true,
          status: undefined,
          message: undefined,
        },
      };

    case getType(CashieringActions.createTransferInstructionsReceive):
    case getType(CashieringActions.updateTransferInstructionsReceive):
    case getType(CashieringActions.deleteTransferInstructionsReceive):
      const successInstructionAction = action.payload.responseCode === SuccessResponseCode;
      const dataInstruction = !successInstructionAction
        ? state.transferInstructions.data
        : getType(CashieringActions.createTransferInstructionsReceive) === action.type
        ? [...(state.transferInstructions.data || []), action.payload.payload]
        : getType(CashieringActions.updateTransferInstructionsReceive) === action.type
        ? _.map(state.transferInstructions.data || [], tr => {
            return tr.id === action.payload.payload.id ? { ...tr, ...action.payload.payload } : tr;
          })
        : _.filter(state.transferInstructions.data || [], tr => {
            return tr.id !== action.payload.payload.id;
          });
      const messageInstruction =
        getType(CashieringActions.createTransferInstructionsReceive) === action.type
          ? 'saved'
          : getType(CashieringActions.updateTransferInstructionsReceive) === action.type
          ? 'renamed'
          : 'deleted';
      return {
        ...state,
        transferInstructions: {
          ...state.transferInstructions,
          data: dataInstruction,
          isSaving: false,
          isFetching: false,
          status: successInstructionAction ? AlertType.success : AlertType.warning,
          message: successInstructionAction
            ? `Transfer instruction has been ${messageInstruction}!`
            : getResponseCodeDescription(action.payload.responseCode, 'Transfer instruction request failed'),
        },
      };

    case getType(CashieringActions.createTransferInstructionsError):
    case getType(CashieringActions.updateTransferInstructionsError):
    case getType(CashieringActions.deleteTransferInstructionsError):
    case getType(CashieringActions.allTransferInstructionsError):
      return {
        ...state,
        transferInstructions: {
          ...state.transferInstructions,
          isSaving: false,
          isFetching: false,
          status: AlertType.danger,
          message: action.payload.message,
        },
      };

    default:
      return state;
  }
};
