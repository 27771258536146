import { IBlockState } from '@tradingblock/types';
import { OrderState, OrderBlockState } from '../OrderState';

const wrapped = <T = {}>(orderState: IBlockState<OrderState, T>) => ({
  value: () => orderState,
  withLegError: (err?: string) => {
    return wrapped({
      ...orderState,
      data: {
        ...orderState.data,
        legError: err,
      },
    });
  },
});

export const orderstate = <T = {}>(orderState: IBlockState<OrderState, T>) => wrapped(orderState);
