import React, { useState, useEffect, useCallback, FC } from 'react';
import _ from 'lodash';
import { PrivateUserCashieringState, TransferInstruction, TransferRecipientBank } from '@tradingblock/types';
import { TextboxInput, Loading, Button, LoadingImage, FormGroup } from '@tradingblock/components';
import { useStateSelector } from '../../../../../data/global/dataSelectors';
import {
  entitySelector,
  wireInstructionSelectors,
} from '../../../../../data/global/selectors/admin/cashieringSelectors';
import { useDispatcher } from '../../../../../data/global/hooks';
import { AdminCashieringActions } from '../../../../../data/global/actions/admin/AdminCashieringActions';
import { useApi } from '../../../../../context/Api';

export interface CashieringEntityNoteProps {
  id: number;
  accountId: number;
  type: keyof Pick<PrivateUserCashieringState, 'relationships' | 'transfers' | 'recipientBanks' | 'wireInstructions'>;
  wrapperClassName?: string;
  bodyClassName?: string;
  footerClassName?: string;
  onEditToggle?: (edit: boolean) => void;
  onSave?: () => void;
}

export const CashieringEntityNotes: React.FunctionComponent<CashieringEntityNoteProps> = ({
  onEditToggle,
  onSave,
  id,
  type,
  accountId,
  wrapperClassName,
  bodyClassName,
  footerClassName,
}) => {
  const { isSaving, isFetching } = useStateSelector(s => entitySelector.info(s, { id, type }));
  const { dispatch } = useDispatcher();

  const api = useApi();

  const entityNote = useStateSelector(s => entitySelector.entityNote(s, { id, type }));
  const entityAdminNote = useStateSelector(s => entitySelector.entityAdminNote(s, { id, type }));
  const username = useStateSelector(s => s.account.nickname).split(' ');
  const formattedName = username[1] + ' ' + username[0];
  const [editNoteType, setEditNoteType] = useState<'note' | 'adminNote'>();
  const [noteValue, setNoteValue] = useState('');
  const [adminNoteValue, setAdminNoteValue] = useState('');
  const [recipientBankValue, setRecipientBankValue] = useState<TransferRecipientBank>();
  const [transferWireInstructionValue, setTransferWireInstructionValue] = useState<TransferInstruction>();
  const [adminNotes, setAdminNotes] = useState<{ id: number; text: string; createBy: string; createDate: string }[]>(
    []
  );
  const [notes, setNotes] = useState<{ id: number; text: string; createBy: string; createDate: string }[]>([]);
  const [alertEmail, setAlertEmail] = useState(false);

  // const recipientBank = useStateSelector(s => recipientBankSelectors.data(s, id));
  const wireInstruction = useStateSelector(s => wireInstructionSelectors.data(s, id));

  const toggleNoteField = useCallback(
    (noteType?: 'note' | 'adminNote') => {
      const editType = !_.isUndefined(noteType) && noteType !== editNoteType ? noteType : undefined;
      setEditNoteType(editType);
      if (onEditToggle) {
        onEditToggle(!!editType);
      }
      setAdminNoteValue('');
      setNoteValue('');
    },
    [editNoteType]
  );

  const setNotesHelper = (payload: any) => {
    setAdminNotes(payload.adminNotes ? payload.adminNotes : []);
    setNotes(payload.notes ? payload.notes : []);
  };

  const getNotes = async () => {
    if (type === 'relationships') {
      const { payload } = await api.cashiering.ach.get(accountId, id);
      setNotesHelper(payload);
    } else if (type === 'transfers') {
      const { payload } = await api.cashiering.transfers.get(accountId, id);
      setNotesHelper(payload);
    } else if (type === 'wireInstructions') {
      const { payload } = await api.cashiering.transferInstructions.get(accountId, id);
      setNotesHelper(payload);
    }
  };

  useEffect(() => {
    if (entityNote) setNoteValue(entityNote);
  }, [entityNote]);

  useEffect(() => {
    if (entityAdminNote) setAdminNoteValue(entityAdminNote);
  }, [entityAdminNote]);

  useEffect(() => {
    getNotes();
  }, []);

  useEffect(() => {
    if (type === 'wireInstructions') {
      const getWireInstruction = async () => {
        const { payload } = await api.cashiering.transferInstructions.get(accountId, id);
        setTransferWireInstructionValue(payload);
      };
      if (!wireInstruction) {
        getWireInstruction();
      } else {
        setTransferWireInstructionValue(wireInstruction);
      }
    }
  }, [accountId, id, wireInstruction]);

  useEffect(() => {
    if (!isSaving) toggleNoteField();
  }, [isSaving]);

  const onChange = useCallback(
    (e: any, val: string) => {
      const newVal = val || e.target.value;
      if (editNoteType === 'note') {
        setNoteValue(newVal);
      } else if (editNoteType === 'adminNote') {
        setAdminNoteValue(newVal);
      }
    },
    [editNoteType]
  );

  const saveHandler = useCallback(() => {
    const noteField = editNoteType === 'adminNote' ? { adminNote: adminNoteValue } : { note: noteValue };
    const updateAction =
      type === 'relationships'
        ? AdminCashieringActions.updateRelationshipRequest({ id, accountId, alertRep: alertEmail, ...noteField })
        : type === 'wireInstructions'
        ? AdminCashieringActions.cashieringWireInstructionsUpdateRequest({
            id,
            accountId,
            alertRep: alertEmail,
            ...noteField,
          })
        : AdminCashieringActions.updateTransferRequest({ id, accountId, alertRep: alertEmail, ...noteField });
    dispatch(updateAction);
    setAlertEmail(false);
    //note list concatenation
    editNoteType === 'note'
      ? setNotes([
          {
            createDate: new Date().toDateString() + ' ' + new Date().toLocaleTimeString(),
            id: 0,
            text: noteValue,
            createBy: formattedName,
          } as typeof notes[0],
          ...notes,
        ])
      : setAdminNotes([
          {
            createDate: new Date().toDateString() + ' ' + new Date().toLocaleTimeString(),
            id: 0,
            text: adminNoteValue,
            createBy: formattedName,
          } as typeof adminNotes[0],
          ...adminNotes,
        ]);
  }, [onSave, noteValue, adminNoteValue, alertEmail, accountId, id, type, recipientBankValue]);

  // Admin & Client note row
  const NoteRow: FC<{ note: typeof notes[0] & typeof adminNotes[0] }> = ({ note }) => {
    return (
      <>
        <div
          style={{ color: 'ghostwhite', borderBottomWidth: '1px', borderBottomStyle: 'solid', width: 'fit-content' }}
        >
          {note.createBy} —&nbsp;
          <i className="mute">
            {new Date(note.createDate).toDateString() + ' ' + new Date(note.createDate).toLocaleTimeString()}
          </i>
        </div>
        <div style={{ padding: '10px' }}>{note.text}</div>
      </>
    );
  };

  return (
    <div className={wrapperClassName}>
      <div className={bodyClassName}>
        {/* Client Notes */}
        <p className="fields-title">Client notes</p>
        <p>
          <button type="button" className="btn-link" onClick={() => toggleNoteField('note')}>
            Add <i className={`fas ${editNoteType ? 'fa-angle-down ' : 'fa-angle-right '}`} />
          </button>
        </p>
        {editNoteType === 'note' && (
          <>
            <FormGroup>
              <TextboxInput placeholder="Add new note..." value={noteValue} onchange={onChange} label="Client note" />
              <span className="mute">Client notes are displayed to client on dashboard</span>
            </FormGroup>
            <div className={footerClassName}>
              <Button className="btn-reject" onClick={() => toggleNoteField()} disabled={isSaving || isFetching}>
                Cancel
              </Button>{' '}
              <Button className="btn-primary" onClick={saveHandler} disabled={isSaving || isFetching}>
                Save
              </Button>
              {isSaving && !footerClassName && (
                <>
                  &nbsp;&nbsp;&nbsp;
                  <LoadingImage />
                </>
              )}
            </div>
            <br />
          </>
        )}
        {notes.length ? (
          notes.map((note, index) => {
            return <NoteRow key={index} note={note} />;
          })
        ) : (
          <>No note history.</>
        )}
        {/* Admin Notes */}
        <hr />
        <p className="fields-title">Admin notes</p>
        <p>
          <button type="button" className="btn-link" onClick={() => toggleNoteField('adminNote')}>
            Add <i className={`fas ${editNoteType ? 'fa-angle-down ' : 'fa-angle-right '}`} />
          </button>
        </p>
        {editNoteType === 'adminNote' && (
          <>
            <FormGroup>
              <TextboxInput
                placeholder="Add new note..."
                value={adminNoteValue}
                onchange={onChange}
                label="Admin note"
              />
              <span className="mute">Admin notes are only visible to admins/reps</span>
            </FormGroup>
            <div className={footerClassName}>
              <Button className="btn-reject" onClick={() => toggleNoteField()} disabled={isSaving || isFetching}>
                Cancel
              </Button>{' '}
              <Button className="btn-primary" onClick={saveHandler} disabled={isSaving || isFetching}>
                Save
              </Button>
              &nbsp;
              <Button
                onClick={() => setAlertEmail(!alertEmail)}
                style={{ color: 'white', backgroundColor: alertEmail ? 'red' : 'var(--gray)' }}
              >
                &nbsp;&nbsp;<i className={alertEmail ? `fas fa-flag` : ''}></i>
                {alertEmail ? 'Alert Rep' : 'No Alert'}
              </Button>
              <br />
              {isSaving && !footerClassName && (
                <>
                  &nbsp;&nbsp;&nbsp;
                  <LoadingImage />
                </>
              )}
            </div>
            <br />
          </>
        )}
        {adminNotes.length ? (
          adminNotes.map((note, index) => {
            return <NoteRow key={index} note={note} />;
          })
        ) : (
          <>No note history.</>
        )}
        {isFetching && <Loading />}
      </div>
    </div>
  );
};
