import React from 'react';
import { AssetType, DebitOrCredit, OrderAction, OrderLegDTOState, TradingStrategy } from '@tradingblock/types';
import { useStrategySentiment } from './OrderSentiment';
import { useOrderCopyAction } from '../../../components/blocks/hooks/useOrderBlock';
import { useOrderBlockData, useOrderData } from '../state/OrderState';
import { DtoToOrderleg } from '../OrderUtilities';
import { useOrderAssetSymbolOrUndefined } from '../state/useOrderState';
import _ from 'lodash';
import { useBlockGroupInfo } from '../../useGroupState';
import { AssetTypeToSymbolType, toAssetSymbol } from '@tradingblock/api';
import { OrderSelectors } from '../state/OrderSelector';

export const OrderStrategyMatch: React.FC<{ strategy: TradingStrategy; legs: OrderLegDTOState[] }> = ({
  strategy,
  legs,
}) => {
  const { sentimentClass, sentimentText } = useStrategySentiment(strategy);
  const { groupId } = useBlockGroupInfo();

  const assetSymbolValue = toAssetSymbol(
    legs[0].UnderlyingSymbol,
    AssetType.Equity,
    AssetTypeToSymbolType(AssetType.Equity),
    {
      name: legs[0].UnderlyingSymbol,
      underlyingSymbol: legs[0].UnderlyingSymbol,
      rootSymbol: legs[0].UnderlyingSymbol,
    }
  );

  const assetSymbol = useOrderAssetSymbolOrUndefined() || assetSymbolValue;
  const assetSymbolInfo = _.omit(assetSymbol, ['name', 'provider']);
  const orderData = useOrderData(s => s);
  const debitCredit = useOrderBlockData(OrderSelectors.debitOrCredit, DebitOrCredit.Debit);
  let orderAction = debitCredit === DebitOrCredit.Debit ? OrderAction.Buy : OrderAction.Sell;

  const createOrder = () => {
    // covered call/covered puts have a size ratio of 100(shares):1(option) and are not receiving the correct quantity values when setting creating the order
    // so we need to set the quantity to the max of the quantity and the spread ratio
    // if 500 shares and 5 options, the quantity should be 5 (100:1)
    // otherwise utilize Math.max(...[orderData.quantity, ...legs.map(l => (l.SpreadRatio ? l.SpreadRatio : 0))]);

    let quantity = Math.max(...[orderData.quantity, ...legs.map(l => (l.SpreadRatio ? l.SpreadRatio : 0))]);
    const isCoveredCallOrMarriedPut = strategy.info.Name === 'Covered Call' || strategy.info.Name === 'Married Put';
    const isButterflyCallOrPut = strategy.info.Name === 'Call Butterfly' || strategy.info.Name === 'Put Butterfly';

    if (isCoveredCallOrMarriedPut) {
      const shareLeg = legs.find(l => l.AssetType === AssetType.Equity);
      const shareLegRatio = shareLeg ? shareLeg.SpreadRatio : 100;

      quantity = shareLegRatio ? shareLegRatio / 100 : 1;
    }

    if (isButterflyCallOrPut) {
      // for butterflies, the spread ratio is 1:2:1
      // find the leg with the highest spread ratio and divide that by 2 to get the quantity
      const highestSpreadRatioLeg =
        legs && legs.find(l => l.SpreadRatio === Math.max(...(legs.map(l => l.SpreadRatio) as any)));
      // use the leg with the highest spread ratio to determine the quantity
      if (highestSpreadRatioLeg && highestSpreadRatioLeg.SpreadRatio) {
        quantity = highestSpreadRatioLeg.SpreadRatio && highestSpreadRatioLeg.SpreadRatio / 2;
      }
    }

    // need to set actions correctly for custom strategies, particularly vertical spreads
    // for a call vertical spread the order is considered a buy if you are buying the lower strike and selling the higher strike
    // for a put vertical spread the order is considered a buy if you are selling the lower strike and buying the higher strike
    if (strategy && strategy.info.Name === 'Call Vertical') {
      // find the leg with the lower strike
      const lowerStrikeLeg = legs && legs.find(l => l.Strike === Math.min(...(legs.map(l => l.Strike) as any)));
      // use the leg with the lower strike to determine the action
      if (lowerStrikeLeg) {
        if (lowerStrikeLeg.Action === OrderAction.Buy) {
          orderAction = OrderAction.Buy;
        } else {
          orderAction = OrderAction.Sell;
        }
      }
    }
    if (strategy && strategy.info.Name === 'Put Vertical') {
      // find the leg with the lower strike
      const lowerStrikeLeg = legs && legs.find(l => l.Strike === Math.min(...(legs.map(l => l.Strike) as any)));
      // use the leg with the lower strike to determine the action
      if (lowerStrikeLeg) {
        if (lowerStrikeLeg.Action === OrderAction.Buy) {
          orderAction = OrderAction.Sell;
        } else {
          orderAction = OrderAction.Buy;
        }
      }
    }

    // set actions accordingly for butterfly strategies
    if (strategy && (strategy.info.Name === 'Call Butterfly' || strategy.info.Name === 'Put Butterfly')) {
      // find the leg with the highest spread ratio
      const highestSpreadRatioLeg =
        legs && legs.find(l => l.SpreadRatio === Math.max(...(legs.map(l => l.SpreadRatio) as any)));
      // a call butterfly is a buy if you are selling the highest spread ratio leg
      if (highestSpreadRatioLeg && highestSpreadRatioLeg.Action === OrderAction.Sell) {
        orderAction = OrderAction.Buy;
      }

      // a call butterfly is a sell if you are buying the highest spread ratio leg
      if (highestSpreadRatioLeg && highestSpreadRatioLeg.Action === OrderAction.Buy) {
        orderAction = OrderAction.Sell;
      }
    }

    // set actions accordingly for condor strategies
    if (strategy && (strategy.info.Name === 'Call Condor' || strategy.info.Name === 'Put Condor')) {
      // use the two middle legs to determine the order action
      // if the two middle legs are both buys, the order action is a sell
      // if the two middle legs are both sells, the order action is a buy
      const legsByStrike = _.sortBy(legs, l => l.Strike);
      const middleTwoStrikes = _.slice(legsByStrike, 1, 3);
      const middleTwoStrikesActions = _.map(middleTwoStrikes, l => l.Action);
      const middleTwoStrikesActionsAreEqual = _.uniq(middleTwoStrikesActions).length === 1;
      if (middleTwoStrikesActionsAreEqual) {
        if (middleTwoStrikesActions[0] === OrderAction.Buy) {
          orderAction = OrderAction.Sell;
        }
        if (middleTwoStrikesActions[0] === OrderAction.Sell) {
          orderAction = OrderAction.Buy;
        }
      }
    }

    // set actions accordingly for covered call strategy
    // covered call is considered a buy (bullish) if you are buying the stock and selling the call
    // covered call is considered a sell (bearish) if you are selling the stock and buying the call
    if (strategy && strategy.info.Name === 'Covered Call') {
      // find the share leg and use that to determine the action of the order
      const shareLeg = legs.find(l => l.AssetType === AssetType.Equity);
      if (shareLeg) {
        if (shareLeg.Action === OrderAction.Buy) {
          orderAction = OrderAction.Buy;
        } else {
          orderAction = OrderAction.Sell;
        }
      }
    }

    // set actions accordingly for married put strategy
    // both legs of a married put have the same action
    // apply the same action to the order
    if (strategy && strategy.info.Name === 'Married Put') {
      // ensure that both legs have the same action
      const actions = legs.map(l => l.Action);
      const actionsAreEqual = _.uniq(actions).length === 1;
      if (actionsAreEqual) {
        if (actions[0] === OrderAction.Buy) {
          orderAction = OrderAction.Buy;
        } else {
          orderAction = OrderAction.Sell;
        }
      }
    }

    return {
      Legs: legs.map(leg => ({
        ...DtoToOrderleg(leg, assetSymbolInfo),
        SpreadRatio: isCoveredCallOrMarriedPut
          ? leg.AssetType === AssetType.Option
            ? 1
            : 100
          : isButterflyCallOrPut
          ? leg.SpreadRatio && leg.SpreadRatio / quantity
          : 1,
      })),
      OrderClass: strategy.profile.OrderClass,
      Duration: orderData.duration,
      Quantity: quantity,
      OrderType: orderData.orderType,
      AllOrNone: false,
      Description: '',
      Action: orderAction,
    };
  };

  const copy = useOrderCopyAction(createOrder(), assetSymbol, { groupId }, strategy);
  const copyOrder = () => copy();

  return (
    <>
      <button
        className="tb-icon-multiline btn btn-primary-outline matchStrategyButton"
        title={'Set strategy type'}
        onClick={() => copyOrder()}
      >
        <div className="tb-icon-multiline">
          <div className="tb-icon-multiline-icon">
            <span className="tb-icon-column">
              <i className={`fa fa-equals ${sentimentClass} fa-2x`} />
            </span>
          </div>
        </div>
        <div className="tb-icon-multiline-label">
          <div className="prop-stacked caps txt-sm">
            <div className="prop-title mute mr-0">Matches</div>
            <div className={`prop-value ${sentimentClass}`}>{strategy.info.Name}</div>
          </div>
        </div>
      </button>
    </>
  );
};
