/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useLog } from '@tradingblock/components';
import { StorageBlockPath, IStorageProvider, IBlockDataState } from '@tradingblock/types';
import { useBlockActions } from './BlockState';

async function tryLoadBlock<T>(storage: IStorageProvider, key: string) {
  try {
    return storage.get<T>(key);
  } catch (ex) {
    return Promise.resolve(undefined);
  }
}

export const BlockDataLoader: React.FunctionComponent<{
  blockId: string;
  store: IStorageProvider;
}> = ({ store, blockId, children }) => {
  const storeKey = StorageBlockPath(blockId);
  const { initializeData } = useBlockActions();

  const log = useLog('BlockDataLoader');
  useEffect(() => {
    let mounted = true;
    const loadBlock = async () => {
      const block = await tryLoadBlock<IBlockDataState>(store, storeKey);
      if (!block) {
        log('tried loading block %o but nothing was found', blockId);
      }
      if (mounted) {
        initializeData(block || {});
      } else {
        console.warn('loaded block', blockId, 'but BlockDataLoader is no longer mounted!');
      }
    };

    loadBlock();

    return () => {
      // keep track of mounted state of component
      mounted = false;
    };
  }, []);
  return <>{children}</>;
};
