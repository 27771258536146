import React, { useCallback, useEffect } from 'react';
import { DebitOrCredit, OrderAction } from '@tradingblock/types';
import { ToggleButton } from '@tradingblock/components';
import { useOrderBlockData, useOrderData } from '../state/OrderState';
import { OrderSelectors } from '../state/OrderSelector';
import { useOrderActions } from '../state/useOrderActions';
import { getOrderLegPrices } from '../data/orderSelectors';
import { useOrderPrice } from '../hooks/useOrderPrice';

export const DebitCreditToggleButton: React.FC<{
  baseClass?: string;
  setDebitCredit: (debitCredit?: DebitOrCredit | undefined) => void;
  value: DebitOrCredit | undefined;
  enabled: boolean;
  action?: any;
  width?: string;
}> = ({ value, setDebitCredit, enabled, baseClass, width, action }) => {
  const { setPrice } = useOrderActions();
  // if the strategy is a calendar spread and the price has been set already, flip the sign of the price on the toggle
  const isCalendarStrategy = useOrderData((s: any) => s.strategy === 'Call Calendar' || s.strategy === 'Put Calendar');
  const isCustomStrategy = useOrderData((s: any) => s.strategy === 'Custom');
  const match = useOrderData((s: any) => s.validation && s.validation.match);
  const isCalendarMatch =
    isCustomStrategy && match && (match.info.Name === 'Call Calendar' || match.info.Name === 'Put Calendar');
  const price = useOrderData((s: any) => s.price);
  const { bid, mid, ask } = useOrderPrice();

  const handleOnClick = useCallback(
    (isActive: boolean) => {
      setDebitCredit(isActive ? DebitOrCredit.Credit : DebitOrCredit.Debit);
      if ((isCalendarStrategy || isCalendarMatch || isCustomStrategy) && price) {
        setPrice(-price, false, false);
      }
    },
    [setDebitCredit]
  );

  // If the price is not set, we need to determine the debit or credit based on the bid, mid, or ask and the action
  useEffect(() => {
    if (!price) {
      let decisionPrice = mid === null || mid === undefined ? 0 : mid; // Adjust based on how an unset mid is represented

      // Determine if bid, mid, and ask have mixed signs
      let sameSign = [bid, mid, ask].every(val => val >= 0) || [bid, mid, ask].every(val => val <= 0);

      if (!sameSign && (bid !== null && bid !== undefined) && (ask !== null && ask !== undefined)) {
        // If not all the same sign and bid/ask are set, choose based on action
        decisionPrice = action === OrderAction.Buy ? bid : ask;
      } else if (decisionPrice === 0 && (bid !== 0 || ask !== 0)) {
        // Fallback to bid or ask if mid is not set and at least one of bid or ask is non-zero
        decisionPrice = bid !== 0 ? bid : ask;
      }

      if (action === OrderAction.Buy) {
        // If decisionPrice is positive, it's a debit, otherwise, it's a credit.
        setDebitCredit(decisionPrice > 0 ? DebitOrCredit.Debit : DebitOrCredit.Credit);
      } else {
        // For sell, it's the opposite: if positive, it's a credit, otherwise, it's a debit.
        setDebitCredit(decisionPrice > 0 ? DebitOrCredit.Credit : DebitOrCredit.Debit);
      }
    }
  }, [price, bid, mid, ask, action]);

  return (
    <ToggleButton
      active={value === DebitOrCredit.Credit}
      disabled={!enabled}
      on="CRDT"
      off="DBT"
      onstyle={`credit ${baseClass || 'btn-dark'}`}
      offstyle={`debit ${baseClass || 'btn-dark'}`}
      width={width || '100%'}
      onClick={handleOnClick}
    />
  );
};

export const DebitCreditToggle = () => {
  const enabled = useOrderBlockData(OrderSelectors.canEditDebitOrCredit, false);
  const debitCredit = useOrderBlockData(OrderSelectors.debitOrCredit, DebitOrCredit.Debit);
  const { setDebitCredit } = useOrderActions();
  const { action } = useOrderData(s => s);
  return (
    <DebitCreditToggleButton value={debitCredit} action={action} setDebitCredit={setDebitCredit} enabled={enabled} />
  );
};
