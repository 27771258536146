import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PrivateUserCashieringState } from '@tradingblock/types';
import { CashieringReviewFormActions } from './details/CashieringReviewFormActions';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { entitySelector, relationshipSelectors } from '../../../../data/global/selectors/admin/cashieringSelectors';
import { useDispatcher } from '../../../../data/global/hooks';
import { AdminCashieringActions } from '../../../../data/global/actions/admin/AdminCashieringActions';

interface CashieringReviewProps {
  id: number;
  type: keyof Pick<PrivateUserCashieringState, 'relationships' | 'transfers' | 'recipientBanks' | 'wireInstructions'>;
  accountId: number;
}

export const CashieringReviewForm: React.FunctionComponent<CashieringReviewProps> = ({ id, type, accountId }) => {
  const entityType =
    type === 'relationships'
      ? 'relationships'
      : type === 'recipientBanks'
      ? 'recipientBanks'
      : type === 'wireInstructions'
      ? 'wireInstructions'
      : 'transfers';
  const entityStatus = useStateSelector(s => entitySelector.status(s, { id, type: entityType }));

  const adminUserName = useStateSelector(s => s.auth.userName);
  const relationshipDetails = useStateSelector(s => relationshipSelectors.details(s, id));
  const relationshipId = useMemo(() => {
    return (
      relationshipDetails &&
      relationshipDetails.reviewLegacyRelationships &&
      relationshipDetails.reviewLegacyRelationships[0].relationshipId
    );
  }, [relationshipDetails]);

  const { dispatch } = useDispatcher();

  const onApprove = useCallback(() => {
    if (entityType === 'relationships') {
      dispatch(
        AdminCashieringActions.approveCreateRelationshipRequest({ id, accountId, mapToRelationshipId: relationshipId })
      );
    } else if (entityStatus === 'PendingRepCreateApproval') {
      dispatch(AdminCashieringActions.approveRepRequest({ id, accountId }));
    } else if (entityStatus === 'PendingFirmCreateApproval') {
      dispatch(AdminCashieringActions.approveFirmRequest({ id, accountId }));
    } else if (entityType === 'recipientBanks') {
      dispatch(
        AdminCashieringActions.approveRecipientBankRequest({
          id,
          accountId,
          adminNote: `Approved by ${adminUserName}`,
        })
      );
    } else if (entityType === 'wireInstructions') {
      dispatch(
        AdminCashieringActions.cashieringWireInstructionsApproveRequest({
          id,
          accountId,
          adminNote: `Approved by ${adminUserName}`,
        })
      );
    }
  }, [entityType, entityStatus, id, accountId]);

  const onReject = useCallback(() => {
    if (entityType === 'relationships') {
      dispatch(AdminCashieringActions.rejectRelationshipRequest({ id, accountId }));
    } else if (entityType === 'transfers') {
      dispatch(AdminCashieringActions.rejectTransferRequest({ id, accountId }));
    } else if (entityType === 'recipientBanks') {
      dispatch(AdminCashieringActions.rejectRecipientBankRequest({ id, accountId }));
    } else if (entityType === 'wireInstructions') {
      dispatch(AdminCashieringActions.cashieringWireInstructionsRejectRequest({ id, accountId }));
    }
  }, [entityType, id, accountId]);

  const onCancel = useCallback(() => {
    const note = `Cancelled by Admin (${adminUserName})`;
    if (entityType === 'relationships') {
      dispatch(AdminCashieringActions.cancelRelationshipRequest({ id, accountId, note }));
    } else if (entityType === 'transfers') {
      dispatch(AdminCashieringActions.cancelTransferRequest({ id, accountId, note }));
    } else if (entityType === 'wireInstructions') {
      dispatch(AdminCashieringActions.cashieringWireInstructionsCancelRequest({ id, accountId, note }));
    }
  }, [entityType, id, accountId]);

  return (
    <>
      <div className="screen-footer screen-footer-justified">
        <div className="screen-footer-actions-secondary">
          <Link to="/admin/cashiering" className="btn btn-blend">
            <i className="fal fa-arrow-left" /> Close
          </Link>
        </div>
        <CashieringReviewFormActions
          accountId={accountId}
          entityId={id}
          entityType={entityType}
          onApprove={onApprove}
          onReject={onReject}
          onCancel={onCancel}
        />
      </div>
    </>
  );
};
