import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { Field } from 'formik';
import { WireType, TransferBankIdentifierType, getGroupedCountryOptions } from '@tradingblock/types';
import { FormGroup, StepperField, TextboxField, SelectDropdownField, CheckboxInput } from '@tradingblock/components';
import { getTransferBankIdentifierType } from '../../../../utilities/cashiering';
import { CashieringViewProps, CashieringViewFormValues } from '../data/useCashieringView';

interface RecipientBankFormProps extends CashieringViewProps<CashieringViewFormValues> {}

export const RecipientBankForm: React.FunctionComponent<RecipientBankFormProps> = ({ values, setValues }) => {
  const [showIntermediary, setShowIntermediary] = useState(false);
  const bankIdentifierType = useMemo(() => values && values.account && getTransferBankIdentifierType(values.account), [
    values,
  ]);

  const toggleShowIntermediary = () => {
    setValues({
      ...values,
      account: {
        ...values.account,
        intermediary: showIntermediary ? undefined : values.account && values.account.intermediary,
      },
    });
    setShowIntermediary(!showIntermediary);
  };

  return (
    <>
      <p className="fields-title">Save a new bank for wire transfers</p>
      <FormGroup>
        <Field
          component={StepperField}
          id="account.identifierType"
          options={_.map([TransferBankIdentifierType.ABA, TransferBankIdentifierType.BIC], t => ({
            value: t,
            label: _.startCase(t === TransferBankIdentifierType.BIC ? WireType.International : WireType.Domestic),
          }))}
        />
      </FormGroup>
      <FormGroup>
        <Field component={TextboxField} id="account.name" label="Bank name" />
      </FormGroup>
      <FormGroup>
        <Field component={TextboxField} id="account.accountNumber" label="Bank account number" />
      </FormGroup>
      <FormGroup>
        <Field
          component={TextboxField}
          id="account.identifier"
          label={bankIdentifierType === TransferBankIdentifierType.BIC ? 'BIC/SWIFT Code' : 'ABA/Routing Number'}
        />
      </FormGroup>
      <FormGroup>
        <Field component={TextboxField} id="account.nameOnAccount" label="Name as it appears on bank account" />
      </FormGroup>
      <FormGroup>
        <Field
          component={TextboxField}
          id="account.nickName"
          label={`Nickname for account (e.g. ${
            bankIdentifierType === TransferBankIdentifierType.BIC ? 'Overseas account' : 'Personal checking'
          })`}
        />
      </FormGroup>

      {bankIdentifierType === TransferBankIdentifierType.ABA && (
        <p className="mute">
          The name and physical address on file for your account will be sent to the recipient bank when processing this
          wire transfer. You must be the owner of the bank account provided.
        </p>
      )}

      {bankIdentifierType === TransferBankIdentifierType.BIC && (
        <>
          <FormGroup>
            <div className="row">
              <div className="col">
                <Field component={TextboxField} id="account.city" label="City" />
              </div>
              <div className="col">
                <Field component={TextboxField} id="account.stateProvince" label="State or province" />
              </div>
              <div className="col">
                <Field component={TextboxField} id="account.postalCode" label="Postal code" />
              </div>
            </div>
          </FormGroup>
          <FormGroup>
            <Field
              component={SelectDropdownField}
              id="account.country"
              options={getGroupedCountryOptions()}
              defaultLabelId="address.country"
              optionsDefaultLabelId="country"
            />
          </FormGroup>
          <FormGroup>
            <Field
              component={TextboxField}
              id="account.additionalInfo"
              label="Additional information to identify the bank (optional)"
            />
          </FormGroup>
          <p>
            <CheckboxInput
              type="checkbox"
              label={' I need to add intermediary bank details'}
              checked={showIntermediary}
              onchange={toggleShowIntermediary}
            />
          </p>

          {showIntermediary && (
            <>
              <FormGroup>
                <Field component={TextboxField} id="account.intermediary.name" label="Intermediary bank name" />
              </FormGroup>
              <FormGroup>
                <Field
                  component={TextboxField}
                  id="account.intermediary.accountSecret"
                  label="Intermediary account number"
                />
              </FormGroup>
              <FormGroup>
                <Field
                  component={TextboxField}
                  id="account.intermediary.streetAddress1"
                  label="Street Address Line 1"
                />
              </FormGroup>
              <FormGroup>
                <Field
                  component={TextboxField}
                  id="account.intermediary.streetAddress2"
                  label="Street Address Line 2 (optional)"
                />
              </FormGroup>
              <FormGroup>
                <div className="row">
                  <div className="col">
                    <Field component={TextboxField} id="account.intermediary.city" label="City" />
                  </div>
                  <div className="col">
                    <Field component={TextboxField} id="account.intermediary.stateProvince" label="State or province" />
                  </div>
                  <div className="col">
                    <Field component={TextboxField} id="account.intermediary.postalCode" label="Postal code" />
                  </div>
                </div>
              </FormGroup>
              <FormGroup>
                <Field
                  component={SelectDropdownField}
                  id="account.intermediary.country"
                  options={getGroupedCountryOptions()}
                  defaultLabelId="address.country"
                  optionsDefaultLabelId="country"
                />
              </FormGroup>
            </>
          )}
        </>
      )}
      <p className="mute">
        In order to verify your ownership of the account, we require you to upload a copy of your bank statement or a
        voided check associated with the account. All uploads will be reviewed and you will be notified of any change in
        status.
      </p>
    </>
  );
};
