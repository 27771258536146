import React, { useState, useCallback, useMemo } from 'react';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Formik, Field } from 'formik';
import _ from 'lodash';
import { Modal, FormGroup, TextboxField, Hotkey, setLogLevel, CheckboxInput } from '@tradingblock/components';
import { useDashboardSettings } from '../hooks/useDashboardSettings';
import { useReduxConfiguration } from '../hooks/useReduxConfiguration';
import { useStateSelector, uiApiTokenAndVersion } from '../data/global/dataSelectors';
import { Actions } from '../data/global/actions';
import { useDebugLevel } from './useDebugSettings';

export const DebugSettings: React.FC<{}> = () => {
  const [debugLevel, setDebugLevel] = useDebugLevel();
  const [show, setShow] = useState(false);
  const { hotkeys } = useDashboardSettings();
  const dispatch = useDispatch();
  const clientVersion = useStateSelector(s => uiApiTokenAndVersion(s).version);
  const initialvalues = useMemo(() => ({ debuglevel: debugLevel, version: clientVersion || '' }), [
    debugLevel,
    clientVersion,
  ]);
  const { enableRedux, set } = useReduxConfiguration();

  const onDevToolsChange = useCallback(
    (e: React.ChangeEvent<HTMLElement> | undefined, value?: any) => {
      set(value && value === true ? true : false);
    },
    [set]
  );

  const onSubmit = useCallback(
    ({ debuglevel, version }: { debuglevel: string; version: string }) => {
      // setLogLevel(debuglevel);
      setDebugLevel(debuglevel);
      setShow(false);
      if (clientVersion !== version && clientVersion) {
        dispatch(Actions.clientVersionMismatch({ previous: clientVersion, current: version }));
      }
    },
    [clientVersion]
  );

  return (
    <>
      {hotkeys && (
        <>
          <Hotkey keys="d" modifier="alt" callback={() => setShow(true)} />
          <Hotkey keys="r" modifier="alt" callback={() => dispatch(Actions.refreshToken())} />
        </>
      )}

      <Modal setShow={isOpen => setShow(isOpen)} show={show}>
        <Formik initialValues={initialvalues} onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <>
              <BootstrapModal.Header closeButton>
                <BootstrapModal.Title>Dashboard settings</BootstrapModal.Title>
              </BootstrapModal.Header>
              <BootstrapModal.Body>
                <form id="settings" onSubmit={handleSubmit}>
                  <h5>Debug</h5>
                  <FormGroup>
                    <Field
                      autoFocus={true}
                      placeholder={'tb:*'}
                      component={TextboxField}
                      name={'debuglevel'}
                      id={'debuglevel'}
                      label="Debug level"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Field
                      placeholder={'application version'}
                      component={TextboxField}
                      name={'version'}
                      id={'version'}
                      label="Client version"
                    />
                  </FormGroup>

                  <FormGroup>
                    <CheckboxInput
                      checked={enableRedux}
                      type="checkbox"
                      name="checked"
                      label="Enable dev tools"
                      onchange={onDevToolsChange}
                    />
                  </FormGroup>
                </form>
              </BootstrapModal.Body>
              <BootstrapModal.Footer className="modal-footer-justified">
                <Button variant="secondary" onClick={() => setShow(false)}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={() => handleSubmit()}>
                  Save
                </Button>
              </BootstrapModal.Footer>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};
