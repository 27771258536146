/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useClickAway from 'react-use/lib/useClickAway';
import _ from 'lodash';
import {
  PageNav,
  AddBlockModal,
  PageNavTertiary,
  PageNavToggleButton,
  PageNavPrimary,
  isChildNodeOfElement,
  isChildNodeOfModal,
} from '@tradingblock/components';
import { BlockType, AllBlockTypes, UserRole, UserProfileLevel } from '@tradingblock/types';
import { useDispatcher } from '../../data/global/hooks';
import {
  useStateSelector,
  dashboardsSelectors,
  blocksSelectors,
  useAuthenticationSelector,
} from '../../data/global/dataSelectors';
import { account } from '../../data/global/selectors/accountSelectors';
import { ClientNotifications } from '../notifications/ClientNotifications';
import { TradeOrderNotifications } from '../notifications/TradeOrderNotifications';
import { generateNewBlock } from '../../utilities/block';
import { TradingStatusIcon } from './TradingStatus';
import { DashboardMarketClosedCountdown } from './navigation/DashboardMarketClosedCountdown';
import { Logo } from '../basic/Logo';
import { isBlockTypeAllowed } from '../../utilities/dashboard';
import { IsVirtualEnvironment } from '../../data/global/selectors/environmentSelector';
import Config from '../../config';
import { TradingEnvironment } from '../../constants';
import { NotificationActions, UIActions } from '../../data/global/actions';
import { DashboardSymbolWidget } from './DashboardSymbolWidget';
import { useDashboardSettings } from '../../hooks/useDashboardSettings';
import { AccountManagementDataActions } from '../../data/global/actions/AccountManagementActions';

const MenuToggleId = 'menu-toggle';

interface DashboardNavigationProps {
  menuOpen: boolean;
  drawerIsOpen: boolean;
  menuRef: React.MutableRefObject<null>;
  toggleMenu: (nextValue?: any) => void;
  toggleDrawer: () => void;
}

//https://stackoverflow.com/questions/11715646/scroll-automatically-to-the-bottom-of-the-page
export const autoScrollToEnhanced = (element: any) => {
  let notChangedStepsCount = 0;
  const scrollInterval = setInterval(function() {
    if (element) {
      // element found
      clearInterval(scrollInterval);
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
    } else if (document.documentElement.scrollTop + window.innerHeight !== document.documentElement.scrollHeight) {
      // no element -> scrolling
      notChangedStepsCount = 0;
      document.documentElement.scrollTop = document.documentElement.scrollHeight;
    } else if (notChangedStepsCount > 20) {
      // no more space to scroll
      clearInterval(scrollInterval);
    } else {
      // waiting for possible extension (autoload) of the page
      notChangedStepsCount++;
    }
  }, 50);
};

export const DashboardNavigation: React.FC<DashboardNavigationProps> = ({
  menuRef,
  menuOpen,
  drawerIsOpen,
  toggleMenu,
  toggleDrawer,
}) => {
  const { dispatcher } = useDispatcher();
  const { isAuthenticated, accountId } = useAuthenticationSelector();
  const accountNickname = useSelector(account.accountNickname);
  const accountManagementDetails = useStateSelector(s => s.accountManagement.accountManagementDetails.details);
  const accountCanAccessCashiering = useSelector(account.accountCanAccessCashiering);
  const dashboard = useSelector(dashboardsSelectors.dashboard);
  const blocks = useStateSelector(s => blocksSelectors.blocks(s));
  const isVirtualEnv = useStateSelector(IsVirtualEnvironment);
  const userLevel = useSelector(account.userLevel);
  const accountRoles = useStateSelector(account.userRoles);
  const [showAddBlock, setShowAddBlock] = useState(false);
  const isIRA = useStateSelector(account.accountIsIRA);
  const isUGMA = useStateSelector(account.accountIsUGMA);
  const isPartnership = useStateSelector(account.accountIsPartnership);
  const isDashboardLocked = useStateSelector(s => s.ui.dashboard.isLocked);
  const selectedLinkedAccountId = useStateSelector(
    state => state.account.selectedLinkedAccount && state.account.selectedLinkedAccount.accountId
  );
  const hasMultipleAccounts = useStateSelector(account.hasMultipleAccounts);
  const dispatch = useDispatch();
  const toggleAccountSwitcher = useCallback(() => dispatcher.account.toggleAccountSwitcher(), [dispatcher.account]);
  const { bannerSymbols } = useDashboardSettings();
  const subtitle = useMemo(() => {
    switch (Config.TradingEnvironment) {
      case TradingEnvironment.dev:
        return 'Dev';
      case TradingEnvironment.beta:
        return 'Beta';
      default:
        return undefined;
    }
  }, []);

  // all available block types, in custom order
  //const allBlockTypes = [BlockType.Order, BlockType.Activity, BlockType.Account, BlockType.PriceChart, BlockType.Quote, BlockType.OptionChain, BlockType.Positions, BlockType.Favorites, BlockType.AccountTransfer];

  const availableBlockTypes = useMemo(() => {
    let filteredBlockTypes = AllBlockTypes.filter(AllBlocksCategorized => {
      if (!isBlockTypeAllowed(AllBlocksCategorized, isVirtualEnv)) {
        return false;
      }
      if (AllBlocksCategorized === BlockType.AdminTools) {
        return userLevel !== UserProfileLevel.Account;
      }
      return true;
    });

    return filteredBlockTypes;
  }, [AllBlockTypes, accountCanAccessCashiering, isVirtualEnv, userLevel]);

  // load data needed for nav (balances, orders)
  useEffect(() => {
    if (isAuthenticated) {
      if (accountRoles !== undefined) {
        if (
          accountRoles.includes(UserRole.CashieringApproval) &&
          !isIRA &&
          !isUGMA &&
          !isPartnership &&
          !selectedLinkedAccountId
        ) {
          dispatcher.balances.requestPendingTransfers();
        }
      }
      dispatcher.balances.request();
      if (_.isNumber(accountId) && !selectedLinkedAccountId) {
        dispatcher.order.request(selectedLinkedAccountId ? selectedLinkedAccountId : accountId);

        // For the first time loading accountManagementDetails, dispatch to retrieve Flex type
        if (accountManagementDetails && !accountManagementDetails.flexibilityType) {
          dispatch(AccountManagementDataActions.requestAccountManagementDetails({ accountId }));
        }
      } else if (_.isNumber(selectedLinkedAccountId)) {
        dispatcher.order.request(selectedLinkedAccountId);
      }
    }
  }, [isAuthenticated, accountId, selectedLinkedAccountId, bannerSymbols]);

  const currentBlockLength = useRef(blocks.length);
  const onAddBlock = useCallback(
    (type: BlockType) => {
      currentBlockLength.current = blocks.length;
      const newBlock = generateNewBlock(type);
      dispatcher.block.addBlock(newBlock);
      setShowAddBlock(false);
      if (drawerIsOpen) {
        toggleDrawer();
      }
    },
    [dispatcher.block, drawerIsOpen, toggleDrawer, blocks, currentBlockLength.current]
  );

  useEffect(() => {
    const blockReference = document.querySelectorAll('div[id*=block-]')[currentBlockLength.current];
    blockReference && blockReference.classList.add('highlightBlockScroll');
    autoScrollToEnhanced(blockReference);
  }, [currentBlockLength.current]);

  const onAddOrHighlightBlockType = useCallback(
    (blockType: BlockType) => {
      if (_.some(blocks, b => b.type === blockType)) {
        // highlight all blocks of same type
        dispatcher.block.highlightBlocks(blockType);
      } else {
        // add new block
        onAddBlock(blockType);
      }
    },
    [blocks, dispatcher.block, onAddBlock]
  );

  useClickAway(menuRef, event => {
    const targetIsToggle =
      event && event.target && isChildNodeOfElement(event.target as Node, MenuToggleId) ? true : false;
    if (menuOpen && !targetIsToggle && !isChildNodeOfModal(event && event.target)) {
      toggleMenu();
    }
  });

  useEffect(() => {
    toggleMenu(false);
  }, [toggleMenu]);

  const onKeyDown = (e: KeyboardEvent) => {
    // pressed ESC...
    if (e.keyCode === 27) {
      if (showAddBlock) {
        e.preventDefault();
        e.stopPropagation();
        setShowAddBlock(false);
      }
    }
  };

  const toggleLockDashboard = useCallback(() => {
    dispatch(UIActions.toggleLockDashboard({}));
  }, [isDashboardLocked]);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  //Used to disabled / enable symbols when  the viewport is small
  const viewPortResponse = useMemo(() => {
    return dimensions;
  }, [dimensions.width]);

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window, setDimensions, dimensions]);

  const mobileBannerStyle = {
    overflow: 'auto',
    paddingRight: '3px',
    marginTop: '3px',
    marginBottom: '3px',
    overflowX: 'hidden',
  };

  // Space for static nav items
  const BASE_OFFSET = 250;
  // Space per symbol
  const SYMBOLS_OFFSET = 350;
  const elem = document.querySelector('body');
  const calculateElementOffset = useCallback(() => {
    return elem
      ? elem.getBoundingClientRect().x +
          (bannerSymbols.length === 0 ? 1 : bannerSymbols.length * SYMBOLS_OFFSET) +
          BASE_OFFSET
      : 0;
  }, [elem, viewPortResponse.width]);

  const bannerValidation = (bannerSymbols_: string[]) => {
    if (bannerSymbols_.length === 0 || viewPortResponse.width < 900) {
      return [];
    } else return bannerSymbols_;
  };

  if (userLevel !== 'Account') {
    return (
      <PageNav>
        <PageNavPrimary>
          <button
            className="dashboard-switcher-trigger"
            id="dashboard-menu-toggle"
            title="Dashboards"
            onClick={toggleDrawer}
            type="button"
          >
            <Logo />
          </button>
          <div className="dashboard-title hide-mobile txt-lg">
            <span className="dashboard-title-primary">{dashboard && dashboard.title}</span>
            {(subtitle || isVirtualEnv) && (
              <span className="dashboard-title-secondary">
                {subtitle ? `${subtitle} ` : ''}
                {isVirtualEnv && <span className="vr">Virtual Trading</span>}
              </span>
            )}
          </div>
        </PageNavPrimary>
        <div className="dashboard-nav-panel dashboard-nav-secondary">
          <DashboardMarketClosedCountdown />
          <div className="dashboard-nav-tool hide-mobile" aria-hidden="true">
            :
          </div>
          <TradingStatusIcon onAddBlock={onAddBlock} />
          <TradeOrderNotifications onClick={() => onAddOrHighlightBlockType(BlockType.Activity)} />
          <ClientNotifications />
          <>
            {bannerSymbols &&
              (viewPortResponse.width < calculateElementOffset() ? (
                <span style={mobileBannerStyle as object}>
                  {bannerValidation(bannerSymbols).map((symbol, index) => (
                    <DashboardSymbolWidget key={index} symbol={symbol} />
                  ))}
                </span>
              ) : (
                bannerValidation(bannerSymbols).map((symbol, index) => (
                  <DashboardSymbolWidget key={index} symbol={symbol} />
                ))
              ))}
          </>
        </div>
        <PageNavTertiary>
          <div className="dashboard-nav-account txt-sm">
            <button
              type="button"
              className="dashboard-nav-account-item"
              onClick={() => toggleLockDashboard()}
              title={isDashboardLocked ? 'Unlock dashboard' : 'Lock dashboard'}
            >
              {isDashboardLocked ? (
                <i className="fas fa-lg fa-lock-alt" style={{ color: 'salmon' }} />
              ) : (
                <i className="fas fa-lg fa-lock-open-alt" style={{ color: 'teal' }} />
              )}
            </button>
            {hasMultipleAccounts && (
              <button
                type="button"
                className="dashboard-nav-account-item"
                onClick={toggleAccountSwitcher}
                title="Select account"
              >
                <i className="fal fa-lg fa-user" />
              </button>
            )}
            {!isDashboardLocked ? (
              <button
                type="button"
                className="dashboard-nav-account-item"
                style={{ opacity: 1, transition: 'color 1s, opacity 1s' }}
                onClick={() => setShowAddBlock(true)}
                title="Add block"
              >
                <i className="fal fa-2x fa-plus-square" />
              </button>
            ) : (
              <button
                type="button"
                style={{ opacity: 0.5, color: 'grey', transition: 'color 1s, opacity 1s' }}
                className="dashboard-nav-account-item"
                title="Dashboard Locked"
                disabled={true}
              >
                <i className="fal fa-2x fa-plus-square" />
              </button>
            )}
            {/* <AccountPerformance /> revisit in TBFE-459 */}
            <PageNavToggleButton toggleId={MenuToggleId} onClick={toggleMenu} title={accountNickname} />
          </div>
        </PageNavTertiary>

        <AddBlockModal
          blockTypes={availableBlockTypes}
          show={showAddBlock}
          onAdd={onAddBlock}
          onCancel={() => setShowAddBlock(false)}
        />
      </PageNav>
    );
  }

  return (
    <PageNav>
      <PageNavPrimary>
        <button
          className="dashboard-switcher-trigger"
          id="dashboard-menu-toggle"
          title="Dashboards"
          onClick={toggleDrawer}
          type="button"
        >
          <Logo />
        </button>
        <div className="dashboard-title hide-mobile txt-lg">
          <span className="dashboard-title-primary">{dashboard && dashboard.title}</span>
          {(subtitle || isVirtualEnv) && (
            <span className="dashboard-title-secondary">
              {subtitle ? `${subtitle} ` : ''}
              {isVirtualEnv && <span className="vr">Virtual Trading</span>}
            </span>
          )}
        </div>
      </PageNavPrimary>
      <div className="dashboard-nav-panel dashboard-nav-secondary">
        <DashboardMarketClosedCountdown />
        <div className="dashboard-nav-tool hide-mobile" aria-hidden="true">
          :
        </div>
        <TradingStatusIcon onAddBlock={onAddBlock} />
        <TradeOrderNotifications onClick={() => onAddOrHighlightBlockType(BlockType.Activity)} />
        <ClientNotifications />
        <>
          {bannerSymbols &&
            (viewPortResponse.width < calculateElementOffset() ? (
              <span style={mobileBannerStyle as object}>
                {bannerValidation(bannerSymbols).map((symbol, index) => (
                  <DashboardSymbolWidget key={index} symbol={symbol} />
                ))}
              </span>
            ) : (
              bannerValidation(bannerSymbols).map((symbol, index) => (
                <DashboardSymbolWidget key={index} symbol={symbol} />
              ))
            ))}
        </>
      </div>

      <PageNavTertiary>
        <div className="dashboard-nav-account txt-sm">
          <button
            type="button"
            className="dashboard-nav-account-item"
            onClick={() => toggleLockDashboard()}
            title={isDashboardLocked ? 'Unlock dashboard' : 'Lock dashboard'}
          >
            {isDashboardLocked ? (
              <i className="fas fa-lg fa-lock-alt" style={{ color: 'salmon' }} />
            ) : (
              <i className="fas fa-lg fa-lock-open-alt" style={{ color: 'teal' }} />
            )}
          </button>
          {hasMultipleAccounts && (
            <button
              type="button"
              className="dashboard-nav-account-item"
              onClick={toggleAccountSwitcher}
              title="Select account"
            >
              <i className="fal fa-lg fa-user" />
            </button>
          )}
          {!isDashboardLocked ? (
            <button
              type="button"
              className="dashboard-nav-account-item"
              style={{ opacity: 1, transition: 'color 1s, opacity 1s' }}
              onClick={() => setShowAddBlock(true)}
              title="Add block"
            >
              <i className="fal fa-2x fa-plus-square" />
            </button>
          ) : (
            <button
              type="button"
              style={{ opacity: 0.5, color: 'grey', transition: 'color 1s, opacity 1s' }}
              className="dashboard-nav-account-item"
              title="Dashboard Locked"
              disabled={true}
            >
              <i className="fal fa-2x fa-plus-square" />
            </button>
          )}
          {/* <AccountPerformance /> revisit in TBFE-459 */}
          <PageNavToggleButton toggleId={MenuToggleId} onClick={toggleMenu} title={accountNickname} />
        </div>
      </PageNavTertiary>

      <AddBlockModal
        blockTypes={availableBlockTypes}
        show={showAddBlock}
        onAdd={onAddBlock}
        onCancel={() => setShowAddBlock(false)}
      />
    </PageNav>
  );
};
