import React, { useCallback } from 'react';
import { useDispatcher } from '../../data/global/hooks';
import { Hotkey, HotkeyProps } from '@tradingblock/components';
import { EnvironmentActions } from '../../data/global/actions/EnvironmentActions';

export const EnvironmentHotkeys: React.FunctionComponent<Pick<HotkeyProps, 'modifier'>> = ({ modifier }) => {
  const { dispatch } = useDispatcher();
  const toggleVirtualTrading = useCallback(() => dispatch(EnvironmentActions.toggleIsVirtual()), [dispatch]);
  return (
    <>
      <Hotkey keys="v" modifier={modifier} callback={toggleVirtualTrading} />
    </>
  );
};
