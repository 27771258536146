import React from 'react';
import { CashieringDetailBaseEntity, CashieringEntityDetailTypes } from '@tradingblock/types';
import { useStateSelector } from '../../data/global/dataSelectors';
import { entitySelector, getEntityDetailKey } from '../../data/global/selectors/admin/cashieringSelectors';

export const CashieringEntityOwnerNames: React.FC<{ id: number; type: CashieringEntityDetailTypes }> = ({
  id,
  type,
}) => {
  const detailType = getEntityDetailKey(type);
  const data = useStateSelector(s => entitySelector.detailEntityData(s, { id, type: detailType }));
  const entity = data && (data.data as CashieringDetailBaseEntity | undefined);

  if (!entity || !entity.accountName) {
    return null;
  }
  return (
    <p>
      Bank account owner name(s)
      <br />
      <span className="mute">{entity.bankAccountOwnerNameSecret || entity.accountName}</span>
    </p>
  );
};
