import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { BasicDropdown, ToggleButton } from '@tradingblock/components';
import { OrderAction, TradingStrategyOrCustom, isCustomStrategy } from '@tradingblock/types';
import { useOrderData, useOrderStrategy } from '../state/OrderState';
import { useOrderActions } from '../state/useOrderActions';
import { OrderSelectors } from '../state/OrderSelector';
import { useBlockId } from '../../../components/blocks/BlockState';
import { OrderStrategyMatch } from './OrderStrategyMatch';
import { OrderValidationIcon } from './OrderValidationIcon';
import { OrderQuantity } from './Order/OrderQuantity';
import { useOrderSettings } from '../hooks/useOrderSettings';
import { orderLegsToClassifiedStrategy } from '../../../data/global/utilities/strategyClassification';
import { CoveredCall, CustomStrategy, MarriedPut, Shares, Strategies } from '@tradingblock/api';
import { OrderSentiment } from './OrderSentiment';

const StrategyPicker: React.FC<{ strategies: TradingStrategyOrCustom[] }> = ({ strategies }) => {
  const blockId = useBlockId();
  const StrategyOptions = useMemo(() => strategies.map(s => s.info.Name.toString()), [strategies]);
  const isCustomOrUndefinedStrategy = useOrderData(OrderSelectors.isCustomStrategyOrUndefined);
  const strategy = useOrderStrategy();
  const blockSettings = useOrderSettings();
  const { defaultStrategy, defaultQuantityForOptionsAndStrategies, defaultQuantityForShares } = blockSettings;
  const isStrategyInvalid = useOrderData(s => OrderSelectors.isStrategyInvalid(s, blockId));
  const isOrderInvalid = useOrderData(s => s.validation && s.validation.valid === false);
  const match = useOrderData(s => s.validation && s.validation.match);
  const hasLegs = useOrderData(s => OrderSelectors.hasLegs(s, blockId));
  const isComplete = useOrderData(s => OrderSelectors.isComplete(s));
  const legs = useOrderData(OrderSelectors.legs);
  const blockState = useOrderData(s => s);

  const classifiedStrategy = orderLegsToClassifiedStrategy(legs);
  const action = classifiedStrategy.action === 'BUY' ? OrderAction.Buy : OrderAction.Sell;
  const matchedStrategy = Strategies.find(s => s.info.Name === classifiedStrategy.name);
  const { setStrategy, setOrderAction } = useOrderActions();
  const isCustom = blockState.strategy === 'Custom';

  const switchStrategy = useCallback(
    (strat: { name: string; value: string }) => {
      // TODO: need to ensure that the order action and strategy are set correctly
      const matchStrat = strategies.find(s => s.info.Name === strat.name);

      if (matchStrat) {
        setStrategy(matchStrat, {
          action: action,
          reverseLegs: action === OrderAction.Sell && !isCustom ? true : false,
          isDirty: false,
        });
      }
    },
    [setStrategy, strategies, action]
  );

  const selectedStrategy = useMemo(() => {
    if (strategy) {
      return (
        StrategyOptions.find(s => s === strategy.info.Name) ||
        StrategyOptions.find(s => s === defaultStrategy) ||
        CustomStrategy.info.Name
      );
    }
    return StrategyOptions.find(s => s === defaultStrategy) || CustomStrategy.info.Name;
  }, [StrategyOptions, strategy]);

  const isSingleLeggedStrategy = useOrderData(OrderSelectors.isSingleLegStrategy);
  const hideStrategyBuyAndQuantity = useMemo(
    () => (strategy && (isCustomStrategy(strategy) || isSingleLeggedStrategy) ? true : false),
    [strategy, isSingleLeggedStrategy]
  );

  const strategyIsCustom = useMemo(() => (strategy && isCustomStrategy(strategy) ? true : false), [
    strategy,
    isCustomStrategy,
  ]);

  const strategyHasSentiment = useMemo(() => strategy && !isCustomOrUndefinedStrategy, [
    strategy,
    isCustomOrUndefinedStrategy,
  ]);

  const bidirectional = useMemo(
    () => (strategy && !isCustomStrategy(strategy) ? strategy.profile.Bidirectional : true),
    [strategy]
  );

  const actionValue = useMemo(() => (action === OrderAction.Buy ? true : false), [action]);

  const setActionValue = useCallback(
    (isActive: boolean) => setOrderAction(isActive ? OrderAction.Buy : OrderAction.Sell),
    [setOrderAction]
  );

  const validationClass = useMemo(() => (isStrategyInvalid === true ? 'invalid' : ''), [isStrategyInvalid]);

  useEffect(() => {
    // If the strategy is undefined, set it to the default strategy
    if (!strategy) {
      setStrategy(
        { info: { Name: defaultStrategy } },
        {
          action: action,
          reverseLegs: action === OrderAction.Sell && !isCustom ? true : false,
          isDirty: false,
        }
      );
    }
    // If a strategy is set, but it has no order legs, set it to a custom strategy
    if (strategy && !hasLegs) {
      setStrategy(
        { info: { Name: 'Custom' } },
        {
          action: action,
          reverseLegs: action === OrderAction.Sell && !isCustom ? true : false,
          isDirty: false,
        }
      );
    }
  }, [strategy]);

  const handleScrollSelect = (data: any): void => {
    setSelectedScrollPickItem(data);
  };

  const onBlur = (e: any) => {
    setWheelPickerHeight(40);
    setTimeout(() => setSelectedScrollPickItem(persist), 100);
  };

  const onFocus = (e: any) => {
    setWheelPickerHeight(200);
    setPersist(e.target.ariaLabel);
  };

  const [selectedScrollPickItem, setSelectedScrollPickItem] = useState(selectedStrategy);
  const [persist, setPersist] = useState(selectedScrollPickItem);
  const [wheelPickerHeight, setWheelPickerHeight] = useState(40);

  useEffect(() => {
    switchStrategy({ name: selectedScrollPickItem, value: selectedScrollPickItem });
  }, [selectedScrollPickItem, defaultQuantityForOptionsAndStrategies, defaultQuantityForShares]);

  return (
    <div className="order-leg">
      {!hideStrategyBuyAndQuantity && (
        <div className="order-leg-setting order-leg-buysell">
          <ToggleButton
            on="Buy"
            off="Sell"
            onstyle="buy"
            offstyle="sell"
            width="100%"
            disabled={!bidirectional}
            onClick={setActionValue}
            active={actionValue}
          />
        </div>
      )}

      <div className="order-leg-setting">
        {!hideStrategyBuyAndQuantity && (
          // <div className="spinner text-left btn-block">
          //   <NumberInput min={1} value={quantity} precision={0} step={1} onChange={trySetQuantity} disabled={isCustomOrUndefinedStrategy} />
          // </div>
          <OrderQuantity id={legs[0] && legs[0].Id} />
        )}
      </div>
      <div className={`order-leg-setting order-leg-strategy `}>
        {/* TODO: Add back in the wheel picker when bugs are ironed out in TBFE-886 */}
        {/* <div style={{ marginBottom: '2.65rem' }} onBlur={e => onBlur(e)} onFocus={e => onFocus(e)}> */}
        <BasicDropdown
          buttonclassName={`btn-dark btn-tall dropdown-toggle text-left btn-block ${validationClass}`}
          values={StrategyOptions}
          value={selectedStrategy}
          setValue={switchStrategy}
        />
        {/* <WheelPicker
            selectedID={strategy ? strategy.info.Name : 'custom'}
            onChange={handleScrollSelect}
            sideClickScroll={setSelectedScrollPickItem}
            data={strategies.map(s => ({ id: s.info.Name, value: s.info.Name })) || []}
            backgroundColor={'#33425b'}
            shadowColor={'#33425b'}
            activeColor={'#02FFF6'}
            color={'#cad3e070'}
            height={wheelPickerHeight}
            width={180}
            itemHeight={1}
          /> */}
        {/* </div> */}
        {/* <SplitDropdown buttonclassName={`btn btn-dark btn-tall btn-block dropdown-select dropdown-toggle text-left`} className={'btn-block'} value={selectedStrategy} setValue={switchStrategy} values={StrategyOptions}>
          <span className="btn-title">{selectedStrategy.name}</span>
        </SplitDropdown> */}
      </div>
      {isOrderInvalid === true && <OrderValidationIcon />}
      {strategyHasSentiment && !isStrategyInvalid && !isOrderInvalid && isComplete && (
        <div className="order-leg-setting order-leg-sentiment">
          <OrderSentiment legs={legs} strategy={strategy} />
        </div>
      )}
      {matchedStrategy && strategyIsCustom && !isOrderInvalid && isComplete && (
        <div className="order-leg-setting order-leg-sentiment">
          {/* {matchHasSentiment && <OrderSentiment strategy={match} />} */}
          <OrderStrategyMatch legs={legs} strategy={matchedStrategy} />
        </div>
      )}
    </div>
  );
};

export const OrderStrategyPicker: React.FC<{ isSymbolIndex: boolean }> = ({ isSymbolIndex }) => {
  const strategies = useOrderData(o =>
    isSymbolIndex
      ? o.validStrategies.filter(
          s =>
            s.info.Name !== Shares.info.Name &&
            s.info.Name !== MarriedPut.info.Name &&
            s.info.Name !== CoveredCall.info.Name
        )
      : o.validStrategies
  );

  return <>{strategies.length > 0 && <StrategyPicker strategies={strategies} />}</>;
};
