/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import useMount from 'react-use/lib/useMount';
import _ from 'lodash';
import { BlockProps, useWhyDidYouUpdate } from '@tradingblock/components';
import { TabOptions, IBlockDataState, AssetSymbol } from '@tradingblock/types';
import { useBlockState, useBlockActions, useBlockData } from './BlockState';
import { useStore } from '../../context/Storage';
import { BlockDataLoader } from './BlockDataLoader';
import { BlockContentWrapper } from './content/BlockContentWrapper';
import { BlockContentHeader } from './content/BlockContentHeader';
import { BlockLink } from './content/BlockLink';
import { BlockContentHeaderTitle } from './content/BlockContentHeaderTitle';
import { BlockLoading } from './content/BlockLoadingHeaderIndicator';
import { DataState } from '../../data/global/state';
import { SettingsRenderer } from './settings/BlockSettingsTypes';
import { blockGroupId, useStateSelector } from '../../data/global/dataSelectors';
import { groupLinkedColor, groupSymbol } from '../../data/global/selectors/groupSelectors';
import { useDispatcher } from '../../data/global/hooks';
import { BlockActions } from '../../data/global/actions';

export interface IBlockProps<D extends IBlockDataState = any, S = any>
  extends Pick<BlockProps, 'type' | 'className' | 'isLinkable' | 'isRefreshable' | 'onRefresh'> {
  initialData?: D;
  header?: JSX.Element | React.Component | string;
  settings?: S;
  tabs?: TabOptions[];
  isFetchingSelector?: (state: DataState) => boolean;
}
type BaseBlockProps<D extends IBlockDataState = any, S = any> = IBlockProps<D, S> & SettingsRenderer<S>;
export const Block: React.FC<BaseBlockProps> = ({
  children,
  initialData,
  tabs,
  settings,
  className,
  isLinkable,
  isRefreshable,
  onRefresh,
  header,
  isFetchingSelector,
  ...props
}) => {
  const blockId = useBlockState('blockId');
  const { initialize, setField: set } = useBlockActions<any>();
  const groupId = useStateSelector(s => blockGroupId(s, blockId));
  const symbolForGroup = useStateSelector(s => groupSymbol(s, groupId || ''));
  const colorForGroup = useStateSelector(s => groupLinkedColor(s, groupId || ''));
  const { symbol } = useBlockData<{
    symbol: AssetSymbol;
    isLoaded: boolean;
    blockType: string;
  }>();
  const allBlockData: {} = useBlockData();
  const { dispatch } = useDispatcher();
  useEffect(() => {
    if (symbolForGroup && symbol && symbolForGroup.symbol !== symbol.symbol) {
      // set symbol for the block
      set('symbol', symbolForGroup, { persist: true });
      const blockData = {
        data: {
          ...allBlockData,
          symbol: symbolForGroup,
        },
      };
      // dispatch update block action to update block data within the db/store
      dispatch(BlockActions.updateBlock({ blockId, blockData }, { persist: true }));
    }
  }, [symbolForGroup, symbol]);
  useEffect(() => {
    if (colorForGroup) {
      set('linkedColor', colorForGroup, { persist: true });
      dispatch(
        BlockActions.updateBlock(
          { blockId, blockData: { data: { ...allBlockData, linkedColor: colorForGroup } } },
          { persist: true }
        )
      );
    }
  }, [colorForGroup]);
  const store = useStore();
  useMount(() => {
    const blockTabs = tabs ? tabs : [];
    if (initialData || tabs || settings) {
      const value = { data: initialData || {}, tabs: blockTabs, settings };
      initialize(value);
    }
  });

  useWhyDidYouUpdate(`${blockId}:Block`, { ...props });
  const [contentId, setContentId] = useState(_.uniqueId(blockId));
  const handleRefresh = useCallback(() => {
    setContentId(_.uniqueId(blockId));
    if (onRefresh) {
      onRefresh();
    }
  }, [onRefresh]);
  const headerProps = {
    isLinkable,
    onRefresh: handleRefresh,
    isRefreshable,
  };

  return (
    <>
      <BlockDataLoader blockId={blockId} store={store} />
      <BlockContentWrapper className={className}>
        <BlockContentHeader {...props} {...headerProps}>
          <BlockContentHeaderTitle header={header} />
          <BlockLoading as="div" isFetchingSelector={() => false} />
        </BlockContentHeader>
        <React.Fragment key={contentId}>{children}</React.Fragment>
        <BlockLink type={props.type} isLinkable={isLinkable} />
      </BlockContentWrapper>
    </>
  );
};
