import React, { useCallback, useState, useMemo, useContext, useEffect } from 'react';
import _ from 'lodash';
import { BlockProps, useWhyDidYouUpdate, BlockHeader, BlockTitle, BasicSelect } from '@tradingblock/components';
import { SettingsRenderer, BlockSettingsModal } from '../settings/BlockSettings';
import { BlockAction, BlockActionContext, useBlockMetadata, useBlockSymbol } from '../BlockState';
import { useDispatcher } from '../../../data/global/hooks';
import { useStateSelector, blocksSelectors, dashboardIdSelector } from '../../../data/global/dataSelectors';
import { BlockLinkingDropdown } from '../BlockLinkingDropdown';
import { BlockSettingsDropdown } from '../BlockSettingsDropdown';
import { IsVirtualEnvironment } from '../../../data/global/selectors/environmentSelector';
import { VirtualTradingModal } from '../VirtualTradingModal';
import { BlockType } from '@tradingblock/types';
import { useOptionChainSettings } from '../../../blocks/OptionChain/data/useOptionChainSettings';
import { blockSettings } from '../../../data/global/selectors/settingSelectors';

type BlockContentHeaderProps = Pick<BlockProps, 'type' | 'isLinkable' | 'isRefreshable' | 'onRefresh'> &
  SettingsRenderer;

export const BlockContentHeader: React.FunctionComponent<BlockContentHeaderProps> = ({
  isLinkable,
  settingFields,
  children,
  isRefreshable,
  onRefresh,
}) => {
  const { blockId, groupId, type, data } = useBlockMetadata();
  const symbol = useBlockSymbol();
  const blockType = type;

  const symbolValue = useMemo(() => {
    return symbol ? { label: symbol.symbol, data: symbol } : '';
  }, [data]);

  const { dispatcher } = useDispatcher();

  // Option chain visible strike setting
  const dispatch = useContext(BlockActionContext);
  const dashboardId = useStateSelector(dashboardIdSelector);
  const isOptionChainBlock = blockType === BlockType.OptionChain;
  const { current } = useOptionChainSettings();
  const strikeOptions = [
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '40', value: 40 },
    { label: '100', value: 100 },
    { label: 'All', value: 0 },
  ];

  const isVirtualEnv = useStateSelector(IsVirtualEnvironment);

  const isLinked = useStateSelector(s => blocksSelectors.isLinked(s)(blockId));
  const linkingDisabled = useStateSelector(s => blocksSelectors.linkingDisabled(s, blockId));

  const onStartBlockLinking = useCallback(() => {
    dispatcher.grid.toggleLinking(true, { blockId, groupId });
  }, [blockId, groupId]);

  const [showSettings, setShowSettings] = useState(false);

  const toggleSettings = useCallback(() => {
    setShowSettings(!showSettings);
  }, [showSettings, setShowSettings]);

  const [virtualTradingModal, setVirtualTradingModal] = useState(false);
  const toggleVirtualTradingModal = useCallback(() => {
    setVirtualTradingModal(!virtualTradingModal);
  }, [virtualTradingModal, setVirtualTradingModal]);

  useWhyDidYouUpdate(`${blockId}:BlockContentHeader`, {
    showSettings,
    blockId,
    isLinkable,
    settingFields,
    isLinked,
    linkingDisabled,
  });

  // @params option: { label: string; value: number }
  // function to set the visible strike count for the block and save to db
  const handleSetStrikeCount = useCallback(
    (option: { label: string; value: number }) => {
      if (symbolValue) {
        const settingsSaveData = {
          dashboardId: dashboardId || '',
          blockId,
          blockType,
          blockSettings: {
            ...current,
            bidUsesBuyOrderAction: current.bidUsesBuyOrderAction,
            ...blockSettings,
            strikeCount: option.value,
          },
        };

        dispatch(BlockAction.setSettings(settingsSaveData.blockSettings));
        dispatcher.settings.block.setBlockSetting(settingsSaveData);
      }
    },
    [symbolValue, blockSettings, blockId, blockType, dashboardId, dispatch, dispatcher]
  );

  const hasSettings = useMemo(() => settingFields !== undefined && _.keys(settingFields).length > 0, [settingFields]);

  let refreshBtnTimeout: null | ReturnType<typeof setTimeout> = null;
  const [refreshBtnDisabled, setRefreshBtnDisabled] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      if (refreshBtnTimeout) {
        clearTimeout(refreshBtnTimeout)
      }
    }
  }, []);

  return (
    <BlockHeader>
      <BlockTitle>{children}</BlockTitle>
      <BlockSettingsModal show={showSettings} toggleVisibility={toggleSettings} settingFields={settingFields} />
      <VirtualTradingModal show={virtualTradingModal} onClose={toggleVirtualTradingModal} />
      <div className="block-actions">
        <ul>
          {isOptionChainBlock && (
            <>
              <li className="txt-sm">
                <div>
                  <span>Strike count: </span>
                  <BasicSelect
                    className="btn btn-dark btn-tall dropdown-toggle"
                    options={strikeOptions}
                    onChange={(option: { label: string; value: number }) => handleSetStrikeCount(option)}
                    value={current.strikeCount}
                    placeholder={current.strikeCount === 0 ? 'All' : current.strikeCount}
                    isSearchable={false}
                  />
                </div>
              </li>
            </>
          )}
          {isVirtualEnv && (
            <li>
              <button
                className="block-action-button"
                title="You are in virtual trading"
                onClick={toggleVirtualTradingModal}
              >
                <i className="fal fa-vr-cardboard vr"></i>
              </button>
            </li>
          )}
          {isLinkable && isLinked && (
            <li>
              <BlockLinkingDropdown isLinked={isLinked} disabled={linkingDisabled} />
            </li>
          )}
          {(!isLinkable || !isLinked) && (
            <li>
              <button
                className={`block-action-button ${isLinkable && !linkingDisabled ? '' : 'disabled'}`}
                title={isLinkable ? 'Link with...' : 'Not available for linking'}
                disabled={!isLinkable || linkingDisabled}
                onClick={isLinkable && !linkingDisabled ? onStartBlockLinking : undefined}
                type="button"
              >
                <i className="fas fa-link fa-flip-horizontal" />
              </button>
            </li>
          )}
          {isRefreshable && (
            <li>
              <button
                className={`block-action-button ${refreshBtnDisabled ? 'disabled-refresh-btn' : ''}`}
                title={'Refresh'}
                disabled={refreshBtnDisabled}
                onClick={() => {
                  setRefreshBtnDisabled(true);
                  refreshBtnTimeout = setTimeout(() => setRefreshBtnDisabled(false), 2000)
                  onRefresh && onRefresh();
                }}
                type="button"
              >
                <i className="fas fa-redo" />
              </button>
            </li>
          )}
          <li>
            <BlockSettingsDropdown hasSettings={hasSettings} toggleBlockSettings={toggleSettings} />
          </li>
        </ul>
      </div>
    </BlockHeader>
  );
};
