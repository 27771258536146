import React, { useMemo } from 'react';
import _ from 'lodash';
import { OrderSelectors } from './state/OrderSelector';
import { useOrderData } from './state/OrderState';
import { useOptionDefaults } from './data/useExpirationStrikeData';
import { useFeedQuoteWithMetadata } from '../../hooks/useFeedQuote';
import { OrderLeg } from './components/Leg';
import { useBlockId } from '../../components/blocks/BlockState';
import { PositionDTO } from '@tradingblock/api';
import { useOrderState } from './state/useOrderState';
import { AssetType } from '@tradingblock/types';

const OrderLegData: React.FC<{ symbol: string }> = ({ symbol }) => {
  useOptionDefaults(symbol);
  return <></>;
};

export const OrderLegs: React.FC<{
  symbol: string;
  positions: PositionDTO[];
  setNoExpirations?: (val: boolean) => any;
}> = ({ symbol, positions, setNoExpirations }) => {
  const blockId = useBlockId();
  const legIds = useOrderData(OrderSelectors.legIds);
  const symbolQuote = useFeedQuoteWithMetadata(symbol);
  const invalidLegIds = useOrderData(s => OrderSelectors.invalidLegIds(s, blockId));
  const midPrice = useMemo(() => {
    if (!symbolQuote) {
      return undefined;
    }
    const diff = symbolQuote.AskPrice - symbolQuote.BidPrice;
    const mid = symbolQuote.BidPrice + diff;
    return _.isNumber(mid) && _.isFinite(mid) ? mid : undefined;
  }, [symbolQuote]);

  // determine if the current order has any option legs by looking at order type
  const legs = useOrderState('legs');

  const hasOptionLegs = useMemo(() => {
    return _.some(legs, leg => leg.AssetType === AssetType.Option);
  }, [legs]);

  return (
    <>
      {symbol && <OrderLegData symbol={symbol} />}
      {symbol &&
        legIds.map(lid => (
          <OrderLeg
            key={lid}
            id={lid}
            positions={positions}
            price={midPrice || -1}
            invalid={invalidLegIds.includes(lid)}
            setNoExpirations={setNoExpirations}
          />
        ))}
    </>
  );
};
