import React, { useState, useCallback } from 'react';
import { useDropzone, FileError } from 'react-dropzone';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import { useApi } from '../../context/Api';
import { AccountManagementDocuments, ClearerType, ResponseCodes } from '@tradingblock/types';
import { ApiResponse } from '@tradingblock/api';
import { Text } from '@tradingblock/components';

/**
 * MiscellaneousUpload component properties
 *
 * @property {number} accountId Account ID Associated With File Upload
 * @property {void} handleDocumentUpload Following successful upload, passes the documentId to parent component
 * @property {AccountManagementDocuments} documentType Upload document type
 * @property {string} description Description of the document
 */

interface MiscellaneousUploadProps {
  accountId: number;
  handleDocumentUpload: (payload: number, accountHolderIndex: number, documentType: AccountManagementDocuments) => void;
  documentType: AccountManagementDocuments;
  description: string;
  accountHolderIndex: number;
  isCashieringDocument?: boolean;
  recipientBankId?: number;
  disabled?: boolean;
  clearer?: ClearerType;
}

/**
 *  Upload widget for miscellaneous documents related to account management updates (3210 letter, government ids, signatures, etc.)
 *
 *  @param {MiscellaneousUploadProps} props
 *  @returns {JSX.Element}
 */

export const MiscUploadFileField: React.FC<MiscellaneousUploadProps> = ({
  accountId,
  handleDocumentUpload,
  documentType,
  description,
  accountHolderIndex,
  isCashieringDocument,
  recipientBankId,
  disabled,
  clearer,
}) => {
  // Used Hooks
  const api = useApi();

  // Used States
  const [uploadingDocument, setUploadingDocument] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [errors, setErrors] = useState<FileError[]>([]);
  const isRqdIdCard =
    (documentType === AccountManagementDocuments.DriversLicense ||
      documentType === AccountManagementDocuments.StateIdCard) &&
    clearer === ClearerType.RQD;

  // Process upload api response
  const processUploadResponse = useCallback((response: ApiResponse<number>) => {
    // If unsuccessful upload, set state/errors
    if (response.responseCode !== 0) {
      setUploadingDocument(false);
      setErrors([
        {
          message: ResponseCodes[response.responseCode].description as string,
          code: ResponseCodes[response.responseCode].name,
        },
      ]);
    } else {
      // If successful, set states and pass documentId to parent for update submission
      setUploadSuccess(true);
      handleDocumentUpload(response.payload, accountHolderIndex, documentType);
      setErrors([]);
    }
  }, []);

  const maxFiles = isRqdIdCard ? 2 : 1;

  // Dropzone Properties
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: () => {
      setUploadingDocument(true);
    },
    onDropAccepted: acceptedFiles => {
      // If drop accepted by frontend, upload the file to backend
      setFileName(acceptedFiles[0].name);
      if (!isCashieringDocument) {
        if (isRqdIdCard) {
          api.accountManagement
            .uploadMiscellaneousDocument(acceptedFiles[0], accountId, documentType, description)
            .then(response => {
              processUploadResponse(response);
              api.accountManagement
                .uploadMiscellaneousDocument(acceptedFiles[1], accountId, documentType, description)
                .then(response => {
                  processUploadResponse(response);
                });
            });
        } else {
          api.accountManagement
            .uploadMiscellaneousDocument(acceptedFiles[0], accountId, documentType, description)
            .then(response => {
              processUploadResponse(response);
            });
        }
      } else if (isCashieringDocument && recipientBankId) {
        if (isRqdIdCard) {
          api.cashiering.documents
            .upload(acceptedFiles[0], accountId, recipientBankId, documentType, description)
            .then(response => {
              processUploadResponse(response);
              api.cashiering.documents
                .upload(acceptedFiles[1], accountId, recipientBankId, documentType, description)
                .then(response => {
                  processUploadResponse(response);
                });
            });
        } else {
          api.cashiering.documents
            .upload(acceptedFiles[0], accountId, recipientBankId, documentType, description)
            .then(response => {
              processUploadResponse(response);
            });
        }
      }
    },
    // If drop rejected, set states/errors
    onDropRejected: fileRejections => {
      setUploadingDocument(false);
      setErrors(fileRejections[0].errors);
    },
    maxSize: 13631488,
    maxFiles: maxFiles,
    accept: 'image/png, image/jpeg, image/jpg, image/gif, application/pdf, capture=camera',
  });

  return (
    <>
      {!uploadSuccess ? (
        <>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {!uploadingDocument ? (
              <>
                <Button disabled={disabled}>
                  <i className="far fa-paperclip" /> Upload Document
                </Button>
                {isRqdIdCard && (
                  <>
                    <br />
                    <br />
                    <span className="mute">
                      Please upload two separate images (front and back) of your ID using the same file selection
                      prompt.
                    </span>
                  </>
                )}
              </>
            ) : (
              <span>
                <i className="fas fa-circle-notch fa-spin valid" />{' '}
                <Text id={'investigationUpload.uploading'} type={'field'} />
              </span>
            )}
          </div>
        </>
      ) : (
        <>
          <br />
          <span>
            <i className="fas fa-check-circle text-success" /> File successfully uploaded - {fileName}
          </span>
          <br />
        </>
      )}
      {errors.length > 0 && (
        <div className="errors">
          <ul>
            {errors.map((error, errorIndex) => (
              <li className="error" key={`error_${errorIndex}`}>
                {error.message}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
