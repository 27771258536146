import {
  AssetType,
  CustomStrategyName,
  OptionType,
  OrderAction,
  OrderLegDTOState,
  TradingStrategyName,
} from '@tradingblock/types';

//TODO: I want to remove the `bubbleErrors` parameter and catch the errors in the calling function, but for now I'm leaving it in for backwards compatibility hiccups since catching errors is not well handled in the main app yet.

/**
 * Represents a leg of a classification order in trading.
 *
 * @interface ClassificationOrderLeg
 * @property {string} type - The type of security involved in this leg of the order.
 *   It can be 'Shares', 'CALL', or 'PUT'.
 * @property {string} action - The action to be taken for this leg. Possible values are 'BUY' or 'SELL'.
 * @property {number} spreadRatio - A number representing the spread ratio. This is used to determine the quantity of the leg.
 * @property {number} strike - The strike price for the option leg.
 * @property {Date} expiration - The expiration date of the option.
 */
export interface ClassificationOrderLeg {
  type: 'Shares' | 'CALL' | 'PUT';
  action: 'BUY' | 'SELL';
  spreadRatio: number;
  strike: number;
  expiration: Date;
}

/**
 * Defines the structure for a classified trading strategy.
 *
 * @interface ClassifiedStrategy
 * @property {TradingStrategyName | CustomStrategyName} name - The name of the trading strategy.
 *   This can either be a predefined trading strategy name (`TradingStrategyName`)
 *   or a custom strategy name (`CustomStrategyName`).
 * @property {string} action - The action associated with the strategy. Possible values are 'BUY' or 'SELL'.
 * @property {number} quantity - The quantity of the asset to be traded as part of this strategy.
 */
export interface ClassifiedStrategy {
  name: TradingStrategyName | CustomStrategyName;
  action: 'BUY' | 'SELL';
  quantity: number;
}

/**
 * Classifies a single-leg order based on given criteria.
 *
 * @param {ClassificationOrderLeg} leg - The single-leg order to be classified.
 * @param {boolean} [bubbleErrors=false] - If set to true, the function will throw errors
 *   for invalid inputs instead of handling them gracefully.
 * @returns {ClassifiedStrategy} The classified strategy derived from the input leg.
 * @throws Will throw an error if `bubbleErrors` is true and the leg is invalid.
 */
export function classifyOrderLeg(leg: ClassificationOrderLeg, bubbleErrors: boolean = false): ClassifiedStrategy {
  // Validate that the spreadRatio is valid for the leg type
  if (leg.spreadRatio <= 0) {
    if (bubbleErrors) {
      throw new Error('Invalid leg quantity: must be greater than 0');
    } else {
      // Return a default strategy with quantity set to 0 for invalid spreadRatio
      return {
        name: 'Custom',
        action: leg.action,
        quantity: 0,
      };
    }
  }

  // Determine the strategy name based on the type of the leg
  let name: TradingStrategyName | CustomStrategyName = 'Custom';
  switch (leg.type) {
    case 'Shares':
      name = 'Shares';
      break;
    case 'CALL':
      name = 'Call';
      break;
    case 'PUT':
      name = 'Put';
      break;
    default:
      // Handle unrecognized leg types
      if (bubbleErrors) {
        throw new Error("Invalid leg type: must be 'Shares', 'Call', or 'Put'");
      }
      name = 'Custom';
  }

  // Calculate the quantity based on the spreadRatio
  const quantity = leg.spreadRatio > 0 ? leg.spreadRatio : 1;

  return {
    name: name,
    action: leg.action,
    quantity: quantity,
  };
}

/**
 * Classifies a two-leg strategy based on a pair of order legs.
 *
 * @param {ClassificationOrderLeg[]} orderLegs - An array of two order legs to classify.
 * @param {boolean} [bubbleErrors=false] - If true, errors are thrown for invalid inputs or unclassifiable strategies.
 * @returns {ClassifiedStrategy} The classified strategy derived from the input order legs.
 * @throws Will throw an error if the input does not meet the required conditions for classification,
 *   especially when `bubbleErrors` is true.
 */
export function classifyTwoLegStrategy(
  orderLegs: ClassificationOrderLeg[],
  bubbleErrors: boolean = false
): ClassifiedStrategy {
  // Ensure exactly two order legs are provided
  if (orderLegs.length !== 2) {
    throw new Error('classifyTwoLegStrategy function expects exactly two order legs');
  }

  // Sort the order legs based on predefined priorities
  const [leg1, leg2] = orderLegs.sort((a, b) => {
    // Define priorities for types and actions
    const typePriority: { [key: string]: number } = {
      Shares: 1,
      CALL: 2,
      PUT: 3,
    };
    const actionPriority: { [key: string]: number } = {
      BUY: 1,
      SELL: 2,
    };

    // Compare by type first
    if (typePriority[a.type] !== typePriority[b.type]) {
      return typePriority[a.type] - typePriority[b.type];
    }

    // If types are the same, compare by expiration date
    const expirationDiff = a.expiration.getTime() - b.expiration.getTime();
    if (expirationDiff !== 0) {
      return expirationDiff;
    }

    // If expiration dates are the same, compare by action
    if (actionPriority[a.action] !== actionPriority[b.action]) {
      return actionPriority[a.action] - actionPriority[b.action];
    }

    // Finally, compare by strike price (greatest to least)
    return b.strike - a.strike;
  });

  if (leg1.type === 'Shares' && leg2.type === 'Shares') {
    if (bubbleErrors) {
      throw new Error('Invalid legs for a Shares');
    }
    const leg1_spread_ratio = (leg1.action === 'BUY' ? 1 : -1) * leg1.spreadRatio;
    const leg2_spread_ratio = (leg2.action === 'BUY' ? 1 : -1) * leg2.spreadRatio;
    return {
      name: 'Custom',
      action: leg1_spread_ratio + leg2_spread_ratio > 0 ? 'BUY' : 'SELL',
      quantity: Math.abs(leg1_spread_ratio + leg2_spread_ratio),
    };
  }

  // Handle when legs are basically the same
  if (
    leg1.type === leg2.type &&
    leg1.action !== leg2.action &&
    leg1.strike === leg2.strike &&
    leg1.expiration.getTime() === leg2.expiration.getTime()
  ) {
    if (bubbleErrors) {
      throw new Error('Cannot classify the opposite actions of the same leg as a strategy');
    }
    if (leg1.action === 'SELL' && leg2.action === 'SELL') {
      return {
        name: 'Custom',
        action: 'SELL',
        quantity: 1,
      };
    }
    return {
      name: 'Custom',
      action: 'BUY',
      quantity: 1,
    };
  }

  // Default action
  let action: 'BUY' | 'SELL' = 'BUY';

  // Covered Call or Married Put (if involving shares)
  if (
    leg1.type === 'Shares' &&
    ((leg2.type === 'CALL' && leg1.action === 'BUY' && leg2.action === 'SELL') ||
      (leg2.type === 'CALL' && leg1.action === 'SELL' && leg2.action === 'BUY') ||
      (leg2.type === 'PUT' && leg1.action === 'BUY' && leg2.action === 'BUY') ||
      (leg2.type === 'PUT' && leg1.action === 'SELL' && leg2.action === 'SELL'))
  ) {
    if (leg1.spreadRatio !== leg2.spreadRatio * 100) {
      if (bubbleErrors) {
        throw new Error(`Invalid spread ratio for a ${leg2.type} Covered ${leg1.action}`);
      }
      return {
        name: 'Custom',
        action: leg1.action === 'SELL' && leg2.action === 'SELL' ? 'SELL' : 'BUY', // Default action
        quantity: 1,
      };
    }
    return {
      name: leg2.type === 'CALL' ? 'Covered Call' : 'Married Put',
      action: leg1.action,
      quantity: leg2.spreadRatio,
    };
  }

  // Straddle
  if (
    leg1.type !== leg2.type &&
    leg1.action === leg2.action &&
    leg1.strike === leg2.strike &&
    leg1.expiration.getTime() === leg2.expiration.getTime()
  ) {
    // Ensure the spread ratios are the same for both legs
    if (leg1.spreadRatio !== leg2.spreadRatio) {
      if (bubbleErrors) {
        throw new Error('Spread ratios must be the same for a Straddle strategy');
      }
      return {
        name: 'Custom',
        action: leg1.action === 'SELL' && leg2.action === 'SELL' ? 'SELL' : 'BUY', // Default action
        quantity: 1, // Default quantity if spread ratios are not the same
      };
    }

    return {
      name: 'Straddle',
      action: leg1.action,
      quantity: leg1.spreadRatio, // Use the spread ratio to determine the quantity
    };
  }

  // Strangle
  if (
    leg1.type !== leg2.type &&
    leg1.action === leg2.action &&
    leg1.strike !== leg2.strike &&
    leg1.expiration.getTime() === leg2.expiration.getTime() &&
    ((leg1.type === 'CALL' && leg1.strike > leg2.strike) || (leg1.type === 'PUT' && leg1.strike < leg2.strike))
  ) {
    // Ensure the spread ratios are the same for both legs
    if (leg1.spreadRatio !== leg2.spreadRatio) {
      if (bubbleErrors) {
        throw new Error('Spread ratios must be the same for a Strangle strategy');
      }
      return {
        name: 'Custom',
        action: leg1.action === 'SELL' && leg2.action === 'SELL' ? 'SELL' : 'BUY', // Default action
        quantity: 1, // Default quantity if spread ratios are not the same
      };
    }

    return {
      name: 'Strangle',
      action: leg1.action,
      quantity: leg1.spreadRatio, // Use the spread ratio to determine the quantity
    };
  }

  // Call Calendar
  if (leg1.type === 'CALL' && leg2.type === 'CALL' && leg1.expiration.getTime() !== leg2.expiration.getTime()) {
    // Determine which leg has the longer-term expiration
    const longTermLeg = leg1.expiration > leg2.expiration ? leg1 : leg2;
    const shortTermLeg = leg1.expiration <= leg2.expiration ? leg1 : leg2;

    // Check if spread ratios are the same
    if (leg1.spreadRatio !== leg2.spreadRatio) {
      if (bubbleErrors) {
        throw new Error('Spread ratios must be the same for a Call Calendar strategy');
      }
      return {
        name: 'Custom',
        action: leg1.action === 'SELL' && leg2.action === 'SELL' ? 'SELL' : 'BUY', // Default action
        quantity: 1, // Default quantity
      };
    }

    // Check if the longer-term call is bought and the shorter-term call is sold
    if (longTermLeg.action === 'BUY' && shortTermLeg.action === 'SELL') {
      return {
        name: 'Call Calendar',
        action: 'BUY', // This indicates a long Call Calendar position
        quantity: leg1.spreadRatio, // Use the spread ratio to determine the quantity
      };
    }
    // Check if the longer-term call is sold and the shorter-term call is bought (short Call Calendar)
    else if (longTermLeg.action === 'SELL' && shortTermLeg.action === 'BUY') {
      return {
        name: 'Call Calendar',
        action: 'SELL', // This indicates a short Call Calendar position
        quantity: leg1.spreadRatio, // Use the spread ratio to determine the quantity
      };
    }
  }

  // Put Calendar
  if (leg1.type === 'PUT' && leg2.type === 'PUT' && leg1.expiration.getTime() !== leg2.expiration.getTime()) {
    // Determine which leg has the longer-term expiration
    const longTermLeg = leg1.expiration > leg2.expiration ? leg1 : leg2;
    const shortTermLeg = leg1.expiration <= leg2.expiration ? leg1 : leg2;

    // Check if spread ratios are the same
    if (longTermLeg.spreadRatio !== shortTermLeg.spreadRatio) {
      if (bubbleErrors) {
        throw new Error('Spread ratios must be the same for a Put Calendar strategy');
      }
      return {
        name: 'Custom',
        action: leg1.action === 'SELL' && leg2.action === 'SELL' ? 'SELL' : 'BUY', // Default action
        quantity: 1, // Default quantity
      };
    }

    // Check if the longer-term put is bought and the shorter-term put is sold
    if (longTermLeg.action === 'BUY' && shortTermLeg.action === 'SELL') {
      return {
        name: 'Put Calendar',
        action: 'BUY', // This indicates a long Put Calendar position
        quantity: longTermLeg.spreadRatio, // Use the spread ratio to determine the quantity
      };
    }
    // Check if the longer-term put is sold and the shorter-term put is bought (short Put Calendar)
    else if (longTermLeg.action === 'SELL' && shortTermLeg.action === 'BUY') {
      return {
        name: 'Put Calendar',
        action: 'SELL', // This indicates a short Put Calendar position
        quantity: longTermLeg.spreadRatio, // Use the spread ratio to determine the quantity
      };
    }
  }

  // Call Vertical
  if (
    leg1.type === 'CALL' &&
    leg2.type === 'CALL' &&
    leg1.strike !== leg2.strike &&
    leg1.expiration.getTime() === leg2.expiration.getTime()
  ) {
    // Determine which leg has the lower strike price
    const lowerStrikeLeg = leg1.strike < leg2.strike ? leg1 : leg2;
    const higherStrikeLeg = leg1.strike >= leg2.strike ? leg1 : leg2;

    // Ensure the spread ratios are the same for both legs
    if (lowerStrikeLeg.spreadRatio !== higherStrikeLeg.spreadRatio) {
      if (bubbleErrors) {
        throw new Error('Spread ratios must be the same for a Call Vertical strategy');
      }
      return {
        name: 'Custom',
        action: leg1.action === 'SELL' && leg2.action === 'SELL' ? 'SELL' : 'BUY', // Default action
        quantity: 1, // Default quantity if spread ratios are not the same
      };
    }

    // Check if the lower strike call is sold and the higher strike call is bought
    if (lowerStrikeLeg.action === 'SELL' && higherStrikeLeg.action === 'BUY') {
      return {
        name: 'Call Vertical',
        action: 'SELL', // This indicates a short Call Vertical (Call Credit Spread)
        quantity: lowerStrikeLeg.spreadRatio, // Quantity based on the spread ratio
      };
    }
    // Check if the lower strike call is bought and the higher strike call is sold
    else if (lowerStrikeLeg.action === 'BUY' && higherStrikeLeg.action === 'SELL') {
      return {
        name: 'Call Vertical',
        action: 'BUY', // This indicates a long Call Vertical (Call Debit Spread)
        quantity: lowerStrikeLeg.spreadRatio, // Quantity based on the spread ratio
      };
    }
  }

  // Put Vertical
  if (
    leg1.type === 'PUT' &&
    leg2.type === 'PUT' &&
    leg1.strike !== leg2.strike &&
    leg1.expiration.getTime() === leg2.expiration.getTime()
  ) {
    // Determine which leg has the higher strike price
    const higherStrikeLeg = leg1.strike > leg2.strike ? leg1 : leg2;
    const lowerStrikeLeg = leg1.strike <= leg2.strike ? leg1 : leg2;

    // Ensure the spread ratios are the same for both legs
    if (higherStrikeLeg.spreadRatio !== lowerStrikeLeg.spreadRatio) {
      if (bubbleErrors) {
        throw new Error('Spread ratios must be the same for a Put Vertical strategy');
      }
      return {
        name: 'Custom',
        action: leg1.action === 'SELL' && leg2.action === 'SELL' ? 'SELL' : 'BUY', // Default action
        quantity: 1, // Default quantity if spread ratios are not the same
      };
    }

    // Check if the higher strike put is sold and the lower strike put is bought
    if (higherStrikeLeg.action === 'SELL' && lowerStrikeLeg.action === 'BUY') {
      return {
        name: 'Put Vertical',
        action: 'SELL', // This indicates a short Put Vertical (Put Credit Spread)
        quantity: higherStrikeLeg.spreadRatio, // Quantity based on the spread ratio
      };
    }
    // Check if the higher strike put is bought and the lower strike put is sold
    else if (higherStrikeLeg.action === 'BUY' && lowerStrikeLeg.action === 'SELL') {
      return {
        name: 'Put Vertical',
        action: 'BUY', // This indicates a long Put Vertical (Put Debit Spread)
        quantity: higherStrikeLeg.spreadRatio, // Quantity based on the spread ratio
      };
    }
  }

  if (bubbleErrors) {
    throw new Error('Invalid legs for a two-leg strategy');
  }

  // If none of the strategies match, return 'Custom'
  return {
    name: 'Custom',
    action: leg1.action === 'SELL' && leg2.action === 'SELL' ? 'SELL' : 'BUY', // Default action
    quantity: 1,
  };
}

/**
 * Classifies a three-leg strategy based on a trio of order legs.
 *
 * @param {ClassificationOrderLeg[]} orderLegs - An array of three order legs to classify.
 * @param {boolean} [bubbleErrors=false] - If true, errors are thrown for invalid inputs or unclassifiable strategies.
 * @returns {ClassifiedStrategy} The classified strategy derived from the input order legs.
 * @throws Will throw an error if the input does not meet the required conditions for classification,
 *   especially when `bubbleErrors` is true.
 */
export function classifyThreeLegStrategy(
  orderLegs: ClassificationOrderLeg[],
  bubbleErrors: boolean = false
): ClassifiedStrategy {
  if (orderLegs.length !== 3) {
    throw new Error('classifyThreeLegStrategy function expects exactly three order legs');
  }

  // Sort legs by strike for easier comparison
  const sortedLegs = orderLegs.sort((a, b) => a.strike - b.strike);
  const [lowLeg, midLeg, highLeg] = sortedLegs;

  // Validate that the middle leg's strike is between the strikes of the low and high legs
  if (!(lowLeg.strike < midLeg.strike && midLeg.strike < highLeg.strike)) {
    if (bubbleErrors) {
      throw new Error('Invalid strike prices for a Butterfly spread');
    }
    return {
      name: 'Custom',
      action: sortedLegs.every(leg => leg.action === 'SELL') ? 'SELL' : 'BUY',
      quantity: 1,
    };
  }

  // Validate that all legs have the same expiration date
  if (
    !(
      lowLeg.expiration.getTime() === midLeg.expiration.getTime() &&
      midLeg.expiration.getTime() === highLeg.expiration.getTime()
    )
  ) {
    if (bubbleErrors) {
      throw new Error('All legs must have the same expiration for a Butterfly spread');
    }
    return {
      name: 'Custom',
      action: sortedLegs.every(leg => leg.action === 'SELL') ? 'SELL' : 'BUY',
      quantity: 1,
    };
  }

  // Determine if it's a Call or Put Butterfly
  const isButterflyStructured =
    (lowLeg.action === 'BUY' && midLeg.action === 'SELL' && highLeg.action === 'BUY') ||
    (lowLeg.action === 'SELL' && midLeg.action === 'BUY' && highLeg.action === 'SELL');
  const isCallButterfly = sortedLegs.every(leg => leg.type === 'CALL') && isButterflyStructured;
  const isPutButterfly = sortedLegs.every(leg => leg.type === 'PUT') && isButterflyStructured;

  let strategyName: TradingStrategyName;
  if (isCallButterfly) {
    strategyName = 'Call Butterfly';
  } else if (isPutButterfly) {
    strategyName = 'Put Butterfly';
  } else {
    if (!isButterflyStructured) {
      if (bubbleErrors) {
        throw new Error('Invalid strike configuration for a Butterfly spread');
      }
      return {
        name: 'Custom',
        action: sortedLegs.every(leg => leg.action === 'SELL') ? 'SELL' : 'BUY',
        quantity: 1,
      };
    } else if (bubbleErrors) {
      throw new Error('Three-leg strategy must be either all CALL or all PUT for Butterfly');
    }

    return {
      name: 'Custom',
      action: sortedLegs.every(leg => leg.action === 'SELL') ? 'SELL' : 'BUY',
      quantity: 1,
    };
  }

  // Check for correct spread ratios for Butterfly
  // The spread ratio for the wings must be equal, and the body's ratio should be double the wings' ratio
  if (!(lowLeg.spreadRatio === highLeg.spreadRatio && midLeg.spreadRatio === 2 * lowLeg.spreadRatio)) {
    if (bubbleErrors) {
      throw new Error('Invalid spread ratios for a Butterfly spread');
    }
    return {
      name: 'Custom',
      action: sortedLegs.every(leg => leg.action === 'SELL') ? 'SELL' : 'BUY',
      quantity: 1,
    };
  }

  // The quantity for a butterfly is typically defined by the body (middle leg)
  // but since we're using spreadRatio to determine quantity, we'll use the lowest spreadRatio
  const quantity = lowLeg.spreadRatio;

  return {
    name: strategyName,
    action: midLeg.action === 'BUY' ? 'SELL' : 'BUY', // The action of the body leg is often considered the primary action
    quantity: quantity,
  };
}

/**
 * Classifies a four-leg strategy based on a quartet of order legs.
 *
 * @param {ClassificationOrderLeg[]} orderLegs - An array of four order legs to classify.
 * @param {boolean} [bubbleErrors=false] - If true, errors are thrown for invalid inputs or unclassifiable strategies.
 * @returns {ClassifiedStrategy} The classified strategy derived from the input order legs, or a custom strategy if classification fails.
 * @throws Will throw an error if the input does not meet the required conditions for classification,
 *   especially when `bubbleErrors` is true.
 */
export function classifyFourLegStrategy(
  orderLegs: ClassificationOrderLeg[],
  bubbleErrors: boolean = false
): ClassifiedStrategy | never {
  if (orderLegs.length !== 4) {
    if (bubbleErrors) {
      throw new Error('classifyFourLegStrategy function expects exactly four order legs');
    }
    return { name: 'Custom', action: 'BUY', quantity: 1 };
  }

  const sortedLegs = orderLegs.sort((a, b) => {
    const typePriority: { [key: string]: number } = {
      PUT: 1,
      CALL: 2,
    };

    // Compare by type first
    if (typePriority[a.type] !== typePriority[b.type]) {
      return typePriority[a.type] - typePriority[b.type];
    }
    return a.strike - b.strike;
  });
  const [firstLeg, secondLeg, thirdLeg, fourthLeg] = sortedLegs;
  const allCalls = sortedLegs.every(leg => leg.type === 'CALL');
  const allPuts = sortedLegs.every(leg => leg.type === 'PUT');
  const mixedTypes = new Set(sortedLegs.map(leg => leg.type)).size === 2;
  const isSequentialStrikes =
    firstLeg.strike < secondLeg.strike && secondLeg.strike < thirdLeg.strike && thirdLeg.strike < fourthLeg.strike;
  const isStrikesEqualDistance =
    firstLeg.strike - secondLeg.strike === secondLeg.strike - thirdLeg.strike &&
    secondLeg.strike - thirdLeg.strike === thirdLeg.strike - fourthLeg.strike;

  // Check for unique strike prices
  const uniqueStrikes = new Set(sortedLegs.map(leg => leg.strike));
  const uniqueDates = new Set(sortedLegs.map(leg => leg.expiration.getTime()));
  const uniqueSpreadRatios = new Set(sortedLegs.map(leg => leg.spreadRatio));
  if (uniqueStrikes.size < 3) {
    // A Condor has 4 unique strikes, an Iron Butterfly has 3 unique strikes
    if (bubbleErrors) {
      throw new Error('Legs should have different strikes for a four-leg strategy');
    }
    return { name: 'Custom', action: sortedLegs.every(leg => leg.action === 'SELL') ? 'SELL' : 'BUY', quantity: 1 };
  }

  // Check for unique expiration dates
  if (uniqueDates.size !== 1) {
    if (bubbleErrors) {
      throw new Error('Legs should have the same expiration date for a four-leg strategy');
    }
    return { name: 'Custom', action: sortedLegs.every(leg => leg.action === 'SELL') ? 'SELL' : 'BUY', quantity: 1 };
  }

  // Check if all spread ratios are the same for a Call Condor, Put Condor, Iron Butterfly, or Iron Condor
  if (uniqueSpreadRatios.size !== 1) {
    if (bubbleErrors) {
      throw new Error('Spread ratios must be the same for strategy');
    }
    return {
      name: 'Custom',
      action: sortedLegs.every(leg => leg.action === 'SELL') ? 'SELL' : 'BUY',
      quantity: 1,
    };
  }

  // Determine the strategy
  let strategyName: TradingStrategyName | 'Custom' = 'Custom';
  let strategyAction: 'BUY' | 'SELL' = 'BUY';
  let quantity = 1;

  // Call Condor Action Logic
  if (allCalls && isSequentialStrikes && isStrikesEqualDistance) {
    if (
      firstLeg.action === 'BUY' &&
      secondLeg.action === 'SELL' &&
      thirdLeg.action === 'SELL' &&
      fourthLeg.action === 'BUY'
    ) {
      strategyName = 'Call Condor';
    }
    if (
      firstLeg.action === 'SELL' &&
      secondLeg.action === 'BUY' &&
      thirdLeg.action === 'BUY' &&
      fourthLeg.action === 'SELL'
    ) {
      strategyName = 'Call Condor';
      strategyAction = 'SELL';
    }
    quantity = firstLeg.spreadRatio;
  } else if (allCalls && isSequentialStrikes && !isStrikesEqualDistance) {
    if (bubbleErrors) {
      throwEqualDistanceError(firstLeg, secondLeg, thirdLeg, fourthLeg, 'Call Condor');
    }
    return { name: 'Custom', action: sortedLegs.every(leg => leg.action === 'SELL') ? 'SELL' : 'BUY', quantity: 1 };
  }
  // Put Condor Action Logic
  else if (allPuts && isSequentialStrikes && isStrikesEqualDistance) {
    if (
      firstLeg.action === 'BUY' &&
      secondLeg.action === 'SELL' &&
      thirdLeg.action === 'SELL' &&
      fourthLeg.action === 'BUY'
    ) {
      strategyName = 'Put Condor';
    }
    if (
      firstLeg.action === 'SELL' &&
      secondLeg.action === 'BUY' &&
      thirdLeg.action === 'BUY' &&
      fourthLeg.action === 'SELL'
    ) {
      strategyName = 'Put Condor';
      strategyAction = 'SELL';
    }
    quantity = firstLeg.spreadRatio;
  } else if (allPuts && isSequentialStrikes && !isStrikesEqualDistance) {
    if (bubbleErrors) {
      throwEqualDistanceError(firstLeg, secondLeg, thirdLeg, fourthLeg, 'Put Condor');
    }
    return { name: 'Custom', action: sortedLegs.every(leg => leg.action === 'SELL') ? 'SELL' : 'BUY', quantity: 1 };
  }
  // Iron Condor Action Logic
  else if (
    mixedTypes &&
    isSequentialStrikes &&
    firstLeg.strike - secondLeg.strike === thirdLeg.strike - fourthLeg.strike &&
    secondLeg.strike !== thirdLeg.strike
  ) {
    if (
      firstLeg.action === 'BUY' &&
      firstLeg.type === 'PUT' &&
      secondLeg.action === 'SELL' &&
      secondLeg.type === 'PUT' &&
      thirdLeg.action === 'SELL' &&
      thirdLeg.type === 'CALL' &&
      fourthLeg.action === 'BUY' &&
      fourthLeg.type === 'CALL'
    ) {
      strategyName = 'Iron Condor';
      strategyAction = 'SELL';
    } else if (
      firstLeg.action === 'SELL' &&
      firstLeg.type === 'PUT' &&
      secondLeg.action === 'BUY' &&
      secondLeg.type === 'PUT' &&
      thirdLeg.action === 'BUY' &&
      thirdLeg.type === 'CALL' &&
      fourthLeg.action === 'SELL' &&
      fourthLeg.type === 'CALL'
    ) {
      strategyName = 'Iron Condor';
      strategyAction = 'BUY';
    } else if (
      mixedTypes &&
      sortedLegs.filter(leg => leg.type === 'CALL').length === 2 &&
      sortedLegs.filter(leg => leg.type === 'PUT').length === 2
    ) {
      if (bubbleErrors) {
        throw new Error('Invalid leg configuration for the strategy');
      }
      return { name: 'Custom', action: sortedLegs.every(leg => leg.action === 'SELL') ? 'SELL' : 'BUY', quantity: 1 };
    }

    quantity = firstLeg.spreadRatio;
  }
  // Iron Butterfly Action Logic
  else if (
    mixedTypes &&
    secondLeg.strike === thirdLeg.strike &&
    sortedLegs.filter(leg => leg.type === 'CALL').length === 2 &&
    sortedLegs.filter(leg => leg.type === 'PUT').length === 2 &&
    firstLeg.strike - secondLeg.strike === secondLeg.strike - fourthLeg.strike
  ) {
    if (
      firstLeg.action === 'BUY' &&
      firstLeg.type === 'PUT' &&
      secondLeg.action === 'SELL' &&
      secondLeg.type === 'PUT' &&
      thirdLeg.action === 'SELL' &&
      thirdLeg.type === 'CALL' &&
      fourthLeg.action === 'BUY' &&
      fourthLeg.type === 'CALL'
    ) {
      strategyName = 'Iron Butterfly';
      strategyAction = 'SELL';
    } else if (
      firstLeg.action === 'SELL' &&
      firstLeg.type === 'PUT' &&
      secondLeg.action === 'BUY' &&
      secondLeg.type === 'PUT' &&
      thirdLeg.action === 'BUY' &&
      thirdLeg.type === 'CALL' &&
      fourthLeg.action === 'SELL' &&
      fourthLeg.type === 'CALL'
    ) {
      strategyName = 'Iron Butterfly';
      strategyAction = 'BUY';
    }
    quantity = firstLeg.spreadRatio;
  } else if (
    mixedTypes &&
    secondLeg.strike === thirdLeg.strike &&
    firstLeg.strike - secondLeg.strike !== thirdLeg.strike - fourthLeg.strike
  ) {
    if (bubbleErrors) {
      throwEqualDistanceError(firstLeg, secondLeg, thirdLeg, fourthLeg);
    }
    return { name: 'Custom', action: sortedLegs.every(leg => leg.action === 'SELL') ? 'SELL' : 'BUY', quantity: 1 };
  } else if (mixedTypes && isSequentialStrikes && !isStrikesEqualDistance) {
    if (bubbleErrors) {
      throwEqualDistanceError(firstLeg, secondLeg, thirdLeg, fourthLeg);
    }
    return { name: 'Custom', action: sortedLegs.every(leg => leg.action === 'SELL') ? 'SELL' : 'BUY', quantity: 1 };
  }
  // Error for invalid leg configuration
  else {
    if (bubbleErrors) {
      throw new Error('Invalid leg configuration for a four-leg strategy');
    }
    return { name: 'Custom', action: sortedLegs.every(leg => leg.action === 'SELL') ? 'SELL' : 'BUY', quantity: 1 };
  }

  if (strategyName === 'Custom') {
    if (bubbleErrors) {
      throw new Error('Invalid leg configuration for a four-leg strategy');
    }
    return { name: 'Custom', action: sortedLegs.every(leg => leg.action === 'SELL') ? 'SELL' : 'BUY', quantity: 1 };
  }
  return {
    name: strategyName,
    action: strategyAction,
    quantity: quantity,
  };
}

export function generalClassificationErrors(orderLegs: ClassificationOrderLeg[], bubbleErrors: boolean = false) {
  // Validate that the legs are not the same
  const uniqueLegs = new Set(
    orderLegs.map(leg => {
      // Only return the relevant properties for comparison, action is not one...
      return JSON.stringify({
        legType: leg.type,
        legStrike: leg.strike,
        legExpiration: leg.expiration.getTime(),
      });
    })
  );
  if (uniqueLegs.size < orderLegs.length) {
    if (bubbleErrors) {
      throw new Error('Legs should be unique');
    }
    return { name: 'Custom', action: 'BUY', quantity: 1 };
  }
}

/**
 * Classifies a trading strategy based on the composition of order legs.
 *
 * @param {ClassificationOrderLeg[]} orderLegs - An array of order legs to classify.
 * @param {boolean} [bubbleErrors=false] - If true, errors are thrown for invalid inputs or unclassifiable strategies.
 * @returns {ClassifiedStrategy} The classified strategy derived from the input order legs.
 *   Returns a 'Custom' strategy for unclassified or unsupported cases.
 */
export function classifyStrategy(
  orderLegs: ClassificationOrderLeg[],
  bubbleErrors: boolean = false
): ClassifiedStrategy {
  // Use the action from the first leg as the default action for the strategy
  const defaultAction = orderLegs.length > 0 ? orderLegs[0].action : 'BUY';

  // Initialize with a default classified strategy
  let classifiedStrategy: ClassifiedStrategy = {
    name: 'Custom',
    action: defaultAction,
    quantity: 1,
  };

  // Classify the strategy based on the number of legs
  switch (orderLegs.length) {
    case 1:
      // Classify single-leg order
      classifiedStrategy = classifyOrderLeg(orderLegs[0], bubbleErrors);
      break;
    case 2:
      // Classify two-leg strategy
      classifiedStrategy = classifyTwoLegStrategy(orderLegs, bubbleErrors);
      break;
    case 3:
      // Classify three-leg strategy
      classifiedStrategy = classifyThreeLegStrategy(orderLegs, bubbleErrors);
      break;
    case 4:
      // Classify four-leg strategy
      classifiedStrategy = classifyFourLegStrategy(orderLegs, bubbleErrors);
      break;
    default:
      // Strategy classification not supported for more than 4 legs
      // The function returns the default 'Custom' strategy in this case
      break;
  }

  // Return the classified strategy
  return classifiedStrategy;
}

// Util function to throw an error for invalid leg configuration for a strats
function throwEqualDistanceError(
  firstLeg: ClassificationOrderLeg,
  secondLeg: ClassificationOrderLeg,
  thirdLeg: ClassificationOrderLeg,
  fourthLeg: ClassificationOrderLeg,
  strategyName = 'strategy'
) {
  const firstSetDistance = Number(Math.abs(firstLeg.strike - secondLeg.strike).toFixed(4));
  const secondSetDistance = Number(Math.abs(secondLeg.strike - thirdLeg.strike).toFixed(4));
  const thirdSetDistance = Number(Math.abs(thirdLeg.strike - fourthLeg.strike).toFixed(4));

  let legErrorMessage: string[] = [];

  if (firstSetDistance !== secondSetDistance) {
    legErrorMessage.push(
      `First set, ${firstLeg.type}(${firstLeg.strike}) and ${secondLeg.type}(${secondLeg.strike}), distance is ${firstSetDistance} and second set, ${secondLeg.type}(${secondLeg.strike}) and ${thirdLeg.type}(${thirdLeg.strike}), distance is ${thirdSetDistance}.`
    );
  }
  if (secondSetDistance !== thirdSetDistance) {
    legErrorMessage.push(
      `Second set, ${secondLeg.type}(${secondLeg.strike}) and ${thirdLeg.type}(${thirdLeg.strike}), distance is ${secondSetDistance} and third set, ${thirdLeg.type}(${thirdLeg.strike}) and ${fourthLeg.type}(${fourthLeg.strike}), distance is ${thirdSetDistance}.`
    );
  }

  throw new Error(
    `Invalid leg configuration for the ${strategyName}, legs must be equal distance apart.${
      legErrorMessage.length > 0 ? ' ' + legErrorMessage.join(' ') : ''
    }`
  );
}

export function orderLegsToClassifiedStrategy(
  legs: OrderLegDTOState[],
  bubbleErrors: boolean = false
): ClassifiedStrategy {
  const classifyOrderLegs: ClassificationOrderLeg[] = legs.reduce(
    (acc, leg) => {
      // Default the SpreadRatio to 1 if undefined
      if (leg.SpreadRatio === undefined) {
        leg.SpreadRatio = 1;
      }

      // If the AssetType is Equity, construct a Shares type order leg
      if (leg.AssetType === AssetType.Equity) {
        acc.push({
          type: 'Shares',
          action: leg.Action === OrderAction.Buy ? 'BUY' : 'SELL',
          strike: 0,
          expiration: new Date(),
          spreadRatio: leg.SpreadRatio ? leg.SpreadRatio : 0,
        });
        return acc;
      }

      // Skip the leg if necessary details are missing
      if (leg.Strike === undefined || leg.Expiration === undefined) {
        return acc;
      }

      // Construct an option type order leg (CALL or PUT)
      acc.push({
        type: leg.OptionType === OptionType.Call ? 'CALL' : 'PUT',
        action: leg.Action === OrderAction.Buy ? 'BUY' : 'SELL',
        strike: leg.Strike,
        expiration: typeof leg.Expiration.date === 'string' ? new Date(leg.Expiration.date) : leg.Expiration.date,
        spreadRatio: leg.SpreadRatio ? leg.SpreadRatio : 0,
      });
      return acc;
    },
    [] as ClassificationOrderLeg[]
  );

  return classifyStrategy(classifyOrderLegs, bubbleErrors);
}
