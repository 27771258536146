import React, { useCallback } from 'react';
import { HotkeyProps, Hotkey } from '@tradingblock/components';
import { useSounds } from '../../hooks/useSounds';
import { useDashboardSettings } from '../../hooks/useDashboardSettings';

export const AudioHotkeys: React.FunctionComponent<Pick<HotkeyProps, 'modifier'>> = ({ modifier }) => {
  const { errorAudio, successAudio, successControls, errorControls } = useSounds();
  const { muteAllSounds } = useDashboardSettings();
  const onSuccess = useCallback(() => {
    !muteAllSounds && (() => {
      const play = successControls.play();
      if (play !== undefined) {
        play.catch(() => {
          // Do nothing
          //TODO: Determine if we want to warn the user that the sound failed to play
        });
      }
    })();
  }, [successControls]);
  const onError = useCallback(() => {
    !muteAllSounds && (() => {
      const play = errorControls.play();
      if (play !== undefined) {
        play.catch(() => {
          // Do nothing
          //TODO: Determine if we want to warn the user that the sound failed to play
        });
      }
    })();
  }, [errorControls]);
  return (
    <>
      {!muteAllSounds && errorAudio}
      {!muteAllSounds && successAudio}
      <Hotkey keys="s" modifier={'alt+shift'} callback={onSuccess} />
      <Hotkey keys="e" modifier={'alt+shift'} callback={onError} />
    </>
  );
};
