import { IBlockDataState, IBlockState } from '@tradingblock/types';
import { WithTables, BlockTablesState } from './BlockTableState';
import { BlockTableAction, BlockTableActions } from './BlockTableActions';
import { getType } from 'typesafe-actions';

export function BlockTablesReducer<T extends WithTables<IBlockDataState>, S>(
  state: IBlockState<T, S>,
  action: BlockTableAction
): IBlockState<T, any> {
  switch (action.type) {
    case getType(BlockTableActions.setTableState): {
      const { key, table } = action.payload;
      const dataState = state.data || { tables: {} };
      const dataTableState = dataState.tables ? dataState.tables : {};
      const isAdminAccountManagementTable = (key: string) => {
        const adminTableKeys = [
          'adminAccountManagmentTable',
          'adminApplicationsTable',
          'admin-entitlements-table',
          'releaseNotes',
          'adminAlertsTemplateTable',
        ];

        return adminTableKeys.includes(key);
      };
      const defaultTableState = {
        pageSize: isAdminAccountManagementTable(key) ? 10 : 25,
        pageIndex: 0,
        sortBy: [],
      };

      return {
        ...state,
        data: {
          ...dataState,
          tables: {
            ...dataTableState,
            [key]: {
              ...(dataTableState[key] || defaultTableState),
              ...table,
            },
          },
        },
      };
    }
    default:
      return state;
  }
}
