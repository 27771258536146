import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Dropdown, DropdownItem } from '@tradingblock/components';
import { useDispatcher } from '../../data/global/hooks';
import { BlockAction, BlockActionContext, useBlockMetadata } from './BlockState';
import { dashboardIdSelector, useStateSelector } from '../../data/global/dataSelectors';
import { getLinkColorMapping, LINK_COLORS_ARRAY } from '../../data/global/utilities';
import { BlockType, GroupLinkColors } from '@tradingblock/types';
import { groupLinkedColor } from '../../data/global/selectors/groupSelectors';

export const BlockLinkingDropdown: React.FunctionComponent<{ isLinked: boolean, disabled: boolean }> = ({ isLinked, disabled }) => {
  const { blockId, groupId } = useBlockMetadata();
  const { dispatcher } = useDispatcher();
  const highlightGroupId = useStateSelector(s => s.blocks.highlightGroupId);
  const isHighlighted = (groupId === highlightGroupId);
  const getColor = useStateSelector(s => groupLinkedColor(s, groupId || ''));
  const [linkColor, setLinkColor] = useState<GroupLinkColors | undefined>('TEAL');

  useEffect(() => {
    setLinkColor(getColor)
  }, [getColor])

  const onStartBlockLinking = useCallback(() => {
    dispatcher.grid.toggleLinking(true, { blockId, groupId });
    dispatcher.block.setLinkColor(linkColor, { groupId })
    saveLinkColors(linkColor);
  }, [blockId, groupId, dispatcher.grid, linkColor]);

  const onUnlinkBlock = useCallback(() => {
    dispatcher.block.unlinkBlock(blockId);

  }, [blockId, dispatcher.block, linkColor]);

  const onToggleHighlightGroup = useCallback(() => {
    dispatcher.block.highlightGroup(isHighlighted ? undefined : groupId);
  }, [isHighlighted, groupId, dispatcher.block, linkColor]);

  const onSelectLinkColor = (optionValue: GroupLinkColors): void => {
    dispatcher.block.setLinkColor(optionValue, { groupId })
    setLinkColor(optionValue)
    saveLinkColors(optionValue);
  }

  const createLinkColorOptions = (): JSX.Element => {
    return (
      <>
        {LINK_COLORS_ARRAY.map((optionValue: GroupLinkColors, index: number) =>
          (<button onClick={() => onSelectLinkColor(optionValue)} key={index}>&nbsp;<i style={{ color: getLinkColorMapping(optionValue) }} className='fas fa-link'></i></button>))}
      </>
    )
  }

  const dispatch = useContext(BlockActionContext);
  const dashboardId = useStateSelector(dashboardIdSelector);
  const { type } = useBlockMetadata();
  const saveLinkColors = (linkColor: GroupLinkColors | undefined) => {
    //DISPATCH LINKED COLOR 
    if (blockId && dashboardId) {
      const settingsSaveData = {
        dashboardId: dashboardId,
        blockId: blockId,
        blockType: type as BlockType,
        blockSettings: {},
      };
      dispatch(BlockAction.setSettings(settingsSaveData.blockSettings));
      dispatcher.settings.block.setBlockSetting(settingsSaveData);
    }
  };
  return (
    <Dropdown
      buttonOptions={{
        toggleButtonClassName: 'block-action-button',
        title: isLinked ? 'Linked' : 'Link',
      }}
      direction="right"
      content={<i className="fas fa-link fa-flip-horizontal"
        style={(isLinked && (!highlightGroupId || isHighlighted)) ?
          { color: getLinkColorMapping(getColor !== undefined ? getColor : linkColor) } : undefined} />}
    >
      {!isLinked && (<><DropdownItem onClick={onStartBlockLinking} className={disabled ? 'disabled' : ''}>Link with&helllinkip;</DropdownItem>
        <DropdownItem>
          Color &nbsp;
          {createLinkColorOptions()}
        </DropdownItem></>)
      }
      {isLinked && (
        <>
          <DropdownItem onClick={onStartBlockLinking} className={disabled ? 'disabled' : ''}>Link another&hellip;</DropdownItem>
          <DropdownItem onClick={onUnlinkBlock}>Unlink this&hellip;</DropdownItem>
          <DropdownItem onClick={onToggleHighlightGroup}>{isHighlighted ? 'Remove highlight' : 'Highlight linked'}</DropdownItem>
          <DropdownItem>
            Color &nbsp;
            {createLinkColorOptions()}
          </DropdownItem>
        </>
      )}
    </Dropdown>
  );
};
