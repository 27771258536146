import { useLog } from '@tradingblock/components';
import { useBlockState, useBlockStateSelector } from '../useBlockState';
import { useBlockData } from '../BlockState';

export const useBlockLog = (subname?: string) => {
  const { blockId, type } = useBlockStateSelector(s => ({ blockId: s.blockId, type: s.type }));
  const subnames = subname ? [type, subname, blockId] : [type, blockId];
  const log = useLog(subnames);
  return log;
};
