import React, { useEffect, useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import { Cell } from 'react-table';
import { useDispatcher } from '../../../../data/global/hooks';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { useParentSizeContext, ColumnWithSorting, Loading } from '@tradingblock/components';
import { AlertMessageBaseItem } from '@tradingblock/types';
import { AdminAlertActions } from '../../../../data/global/actions/admin/AdminAlertActions';
import { useAlertsData } from '../useAlertsData';
import { isFetchingAlerts } from '../../../../data/global/selectors/admin/alertsSelector';
import { AlertsActionCell } from './table/AlertsActionCell';
import { AddAlertMessage } from './AddAlertMessage';
import { BlockTable } from '../../../shared/BlockTable';
import { EditAlertMessage } from './EditAlertMessage';

const AdminAlertsColumns: ColumnWithSorting<any>[] = [
  {
    Header: 'ID',
    accessor: v => v.id,
    id: 'id',
  },
  {
    Header: 'Template',
    accessor: v => v.template,
    id: 'template',
  },
  {
    Header: 'Edit',
    id: 'edit',
    Cell: ({ row }: Cell<any>) => {
      const [showEditAlertMessage, setShowEditAlertMessage] = useState(false);

      const toggleEditAlertMessage = () => {
        showEditAlertMessage ? setShowEditAlertMessage(false) : setShowEditAlertMessage(true);
      };

      const handleClick = (e: any) => {
        e.preventDefault();
        switch (e.target.id) {
          case 'editAlertMessage':
            setShowEditAlertMessage(true);
            break;
          default:
            break;
        }
      };

      return (
        <>
          <div>
            <button onClick={e => handleClick(e)}>
              <i id="editAlertMessage" className="fal fa-edit fa-lg" />
            </button>
          </div>
          <EditAlertMessage
            show={showEditAlertMessage}
            toggleModal={toggleEditAlertMessage}
            alertMessage={row.original}
          />
        </>
      );
    },
  },
  {
    Header: 'Active',
    accessor: v => v.active,
    id: 'active',
    Cell: ({ row }: Cell<any>) => {
      const { active } = row.original;
      return <span className={active ? 'pos' : 'neg'}>{active ? 'Yes' : 'No'}</span>;
    },
  },
  {
    Header: 'Toggle',
    id: 'toggle',
    className: 'text-center',
    Cell: ({ row }: Cell<any>) => {
      return (
        <div className="text-center">
          <AlertsActionCell {...row.original} />
        </div>
      );
    },
  },
];

export const AlertsTable: React.FunctionComponent<{}> = () => {
  const { dispatch } = useDispatcher();
  const { data, total, ...filterData } = useAlertsData();
  const { pageSize, page } = filterData;

  const [isFetching, hasData] = useStateSelector(isFetchingAlerts);
  const [showAddAlertMessage, setShowAddAlertMessage] = useState(false);

  const toggleAddAlertMessage = () => {
    showAddAlertMessage ? setShowAddAlertMessage(false) : setShowAddAlertMessage(true);
  };

  const handleClick = (event: any) => {
    event.preventDefault();
    switch (event.target.id) {
      case 'addAlertMessage':
        setShowAddAlertMessage(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(AdminAlertActions.requestAlertTemplates(filterData));
  }, [page, pageSize]);

  const initialState = useMemo(() => {
    return {
      sortBy: [],
      pageSize: _.isUndefined(pageSize) ? 10 : pageSize,
      pageIndex: page || 0,
      columnOrder: [],
    };
  }, [pageSize, page]);

  const pageCount = useMemo(() => {
    if (pageSize) {
      return Math.ceil(total / pageSize);
    }
    return 0;
  }, [pageSize, total]);

  const onPage = useCallback((page: number) => {
    dispatch(AdminAlertActions.setAlertsPage({ page }));
  }, []);

  const onPageSize = useCallback((pageSize: number) => {
    dispatch(AdminAlertActions.setAlertsPageSize({ pageSize }));
  }, []);

  const getRowKey = useCallback((data: AlertMessageBaseItem) => `${data.id}`, []);

  return (
    <>
      {isFetching && <Loading />}
      {hasData && !isFetching && (
        <>
          <useParentSizeContext.Provider>
            <BlockTable
              tableKey="adminAlertsTemplateTable"
              columns={AdminAlertsColumns}
              numColumns={5}
              columnWidths={['3%', '77%', '5%', '5%', '10%']}
              data={data}
              initialState={initialState}
              loaded={true}
              getRowKey={getRowKey}
              hasPagination
              manualSortBy
              manualPagination
              pageCount={pageCount}
              onPage={onPage}
              onPageSize={onPageSize}
            />
          </useParentSizeContext.Provider>
          <div>
            <br />
            <button id="addAlertMessage" className="btn btn-primary" onClick={event => handleClick(event)}>
              Add Alert Template
            </button>
            <br />
          </div>
          <AddAlertMessage toggleModal={toggleAddAlertMessage} show={showAddAlertMessage} />
        </>
      )}
    </>
  );
};
