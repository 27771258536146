import React from 'react';
import { Number, LoadingImage, SignedNumber, Loading } from '@tradingblock/components';
import { Column } from '../basic/Column';
import _ from 'lodash';
export const BlockColumn: React.FunctionComponent<{
  title: string;
  value: number;
  signed?: boolean;
  isLoading?: boolean;
  hideIcon?: boolean;
  hidePrefix?: boolean;
}> = ({ title, value, signed, isLoading, hideIcon, hidePrefix }) => {
  const isValid = !_.isNil(value) && !_.isNaN(value) && _.isNumber(value) ? true : false;

  return (
    <Column title={title} titleClass="mute">
      <div className="prop-value">
        {isLoading !== true && isValid ? (
          <>
            {signed ? (
              <SignedNumber value={value} hideIcon={hideIcon} hidePrefix={hidePrefix} format currency />
            ) : (
              <Number value={value} format currency />
            )}
          </>
        ) : (
          <span className="mute">
            <Loading size="small" />
          </span>
        )}
      </div>
    </Column>
  );
};
