import React, { useMemo } from 'react';
import _ from 'lodash';
import { formatNumber } from '@tradingblock/components';
import { toShort } from '@tradingblock/api';
import {
  CashieringView,
  Transfer,
  AchRelationship,
  TransferInstruction,
  CashieringDetailTransfer,
} from '@tradingblock/types';
import {
  getDeliveryMethodLabel,
  getFormattedAchRelationship,
  getFormattedTransferInstruction,
  isNotYetCreatedOrPendingStatus,
} from '../../utilities/cashiering';
import { TradingBlockLink } from '../basic/TradingBlockLink';
import { CashieringEntityOwnerNames } from './CashieringEntityOwnerNames';
import { useStateSelector } from '../../data/global/dataSelectors';
import { CashieringEntityDetails } from './CashieringEntityDetails';

interface AccountWithdrawalDisplayProps {
  title?: string;
  nickname: string;
  availableForWithdrawal: number;
  availableForTrading: number;
  cashBalance?: number;
  marginBalance?: number;
  positions?: number;
  transfer: Partial<Transfer> | undefined;
  transferDetails?: CashieringDetailTransfer;
  achRelationship: AchRelationship | undefined;
  transferInstructions: TransferInstruction | undefined;
  adminReview?: boolean;
  isPendingFullWithdrawal?: boolean;
  gotoNextView?: (view: CashieringView) => void;
}

export const AccountWithdrawalDisplay: React.FC<AccountWithdrawalDisplayProps> = ({
  title,
  nickname,
  availableForWithdrawal,
  isPendingFullWithdrawal,
  availableForTrading,
  positions,
  transfer,
  transferDetails,
  achRelationship,
  transferInstructions,
  adminReview,
  gotoNextView,
  children,
  cashBalance,
  marginBalance,
}) => {
  const amount = useMemo(() => transfer && (transfer.amount || transfer.requestedAmount), [transfer]);
  const fieldTitle = useMemo(() => (title ? title : 'How much to withdraw?'), []);
  const subAccounts = useStateSelector(s => s.account.subaccounts.subaccounts);
  const subAccountTransferValues = transfer && transfer.subAccountsValues;
  const subAccountIds: string[] = Object.keys(subAccountTransferValues || {});
  const origin = useStateSelector(s => s.auth.origin);

  const subAccountDataAndValues = useMemo(() => {
    const subAccountValues = subAccountIds.map(id => {
      const subAccount = _.find(subAccounts, s => s.Id === parseInt(id));
      const transferAmount = subAccountTransferValues && subAccountTransferValues[id];
      return {
        subAccount,
        transferAmount,
      };
    });
    return subAccountValues;
  }, [subAccountIds, subAccounts, subAccountTransferValues]);

  return (
    <>
      <div className="screen-body-section">
        <p className="fields-title">{fieldTitle}</p>
        <p>
          <span className="mute">Withdrawing from:</span> {nickname}.{' '}
          <span className="mute">Available for withdrawal:</span>{' '}
          {formatNumber(availableForWithdrawal, { currency: true })}. <br />
          {/* 
            if admin review and user has a pending full amount withdrawal, show the pending amount and a warning message
          */}
          {adminReview && isPendingFullWithdrawal && (
            <>
              <span className="warn">
                <strong>Note:</strong> This request is for a full balance withdrawal.{' '}
                {formatNumber(availableForWithdrawal, { currency: true })} will be withdrawn from this account.
              </span>
              <br />
            </>
          )}
          <span className="mute">Available for trading:</span> {formatNumber(availableForTrading, { currency: true })}.{' '}
          {!_.isUndefined(positions) && positions !== 0 && (
            <>
              <span className="mute">Positions:</span> {formatNumber(positions, { currency: true })}.
            </>
          )}
        </p>

        <p>
          <span className="mute">Amount:</span>
          <br />
          {transfer && transfer.disbursementType === 'FullBalance' && (
            <span>
              Full Cash Balance{' '}
              <span className="mute">({formatNumber(availableForWithdrawal, { currency: true })})</span>
            </span>
          )}
          {transfer && transfer.disbursementType !== 'FullBalance' && (
            <span>
              {amount &&
                (!subAccountDataAndValues || _.isEmpty(subAccountDataAndValues)) &&
                formatNumber(amount, { currency: true })}
              {amount && !_.isEmpty(subAccountDataAndValues) && (
                <>
                  {subAccountDataAndValues.map(({ subAccount, transferAmount }) => (
                    <>
                      {subAccount && transferAmount !== 0 && (
                        <>
                          {subAccount.Nickname}: {transferAmount && formatNumber(transferAmount, { currency: true })}{' '}
                        </>
                      )}
                    </>
                  ))}
                  <br />
                  Total: {amount && formatNumber(amount, { currency: true })}
                </>
              )}
              {adminReview && <> &ndash; partial withdrawal</>}
            </span>
          )}
        </p>

        {gotoNextView && (
          <p>
            <button type="button" className="btn-link" onClick={() => gotoNextView(CashieringView.Type)}>
              <i className="fal fa-pencil" /> Change
            </button>
          </p>
        )}
      </div>

      <div className="screen-body-section">
        <p className="fields-title">How {adminReview ? '' : 'would you like'} to receive the funds?</p>
        {transfer && transfer.mechanism === 'Ach' && (
          <>
            <p>
              <span>Linked bank account:</span>
              <br />
              {achRelationship && getFormattedAchRelationship(achRelationship)}
            </p>
            {adminReview && transfer.id && <CashieringEntityOwnerNames id={transfer.id} type="transfers" />}
            {adminReview && achRelationship && isNotYetCreatedOrPendingStatus(achRelationship.state) && (
              <p className="warn">This relationship is pending.</p>
            )}
          </>
        )}

        {transfer && transfer.mechanism === 'Wire' && transferInstructions && (
          <>
            <p>
              <span>
                {transferInstructions.recipientBank.identifierType === 'BIC' ? 'International' : 'Domestic'} Wire
                Transfer:
              </span>
              <br />
              {transferInstructions && getFormattedTransferInstruction(transferInstructions)}
            </p>
            {adminReview && transfer.id && <CashieringEntityOwnerNames id={transfer.id} type="transfers" />}
            {adminReview && transfer.id && <CashieringEntityDetails id={transferInstructions.id} />}
            {adminReview && (
              <p>
                <span className="mute">This Wire Account was added on</span> {toShort(transferInstructions.createDate)}
              </p>
            )}
          </>
        )}

        {transfer && transfer.mechanism === 'Check' && (
          <>
            <p>
              <span className="mute">Paper check:</span>
              <br />
              Deliver to account name and address
            </p>
            {adminReview && transfer.id && <CashieringEntityOwnerNames id={transfer.id} type="transfers" />}
            <p>
              <span className="mute">Delivery method:</span>
              <br />
              {getDeliveryMethodLabel(transfer.deliveryMethod)}
              {!adminReview && transfer.deliveryMethod === 'Overnight' && (
                <>
                  <br />
                  <span className="mute">
                    Overnight express mail will be delivered to the physical street address on file for your account
                    (not a P.O. Box).{' '}
                    <TradingBlockLink to={origin === 'mb' ? 'MBFeesUrl' : 'TBFeesUrl'}>
                      Delivery fees may apply
                    </TradingBlockLink>
                    . Requests made after 3 PM Central (US) will be processed the next business day.
                  </span>
                </>
              )}
            </p>
            <p>
              <span className="mute">Memo to print on the check:</span>
              <br />
              {_.some(transfer.memos, m => !!m) ? (
                _.map(_.filter(transfer.memos, m => !!m), (m, i) => (
                  <span key={`memo${i}`}>
                    {m}
                    <br />
                  </span>
                ))
              ) : (
                <span>--None--</span>
              )}
            </p>
          </>
        )}

        {gotoNextView && (
          <p>
            <button type="button" className="btn-link" onClick={() => gotoNextView(CashieringView.Type)}>
              <i className="fal fa-pencil" /> Change
            </button>
          </p>
        )}
      </div>

      {children}
    </>
  );
};
