import React, { useEffect, FC } from 'react';
import useMount from 'react-use/lib/useMount';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { LocalizeProvider, LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { useLocalStorage } from '@tradingblock/storage';
import { getLocalizeOptions, allTranslations, LanguageType } from '@tradingblock/types';
import { AlertsContainer, useTracking } from '@tradingblock/components';
import { DataInitializer } from './data/Initializer';
import { useStateSelector } from './data/global/dataSelectors';
import { RootContentPage } from './pages/RootContentPage';
import { Feed } from './data/feed/Feed';
import { AuthenticationPage } from './pages/AuthenticationPage';
import { LogoutPage } from './pages/LogoutPage';
import { AdminRouter } from './pages/admin/AdminRouter';
import { NoAccountPage } from './pages/NoAccountPage';
import { AppStoreProvider } from './AppStoreProvider';
import { usePageTitle } from './hooks/usePageTitle';
import Config from './config';
import './App.css';
import { CashieringUpdatesPoll } from './components/cashiering/CashieringUpdatesPoll';
import { UpdateConfirmationPage } from './pages/UpdateConfirmationPage';
import ReactGA from 'react-ga4';
import config from './config';
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_ENVIRONMENT !== "local") {
  Sentry.init({
    dsn: "https://9bc4e5a47c6b4ab992dc899357399cc0@o4505086872977408.ingest.sentry.io/4505121853079552",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const TRACKING_ID = config.isVirtual ? config.ga4VTTrackingCode : config.ga4TrackingCode;
if (TRACKING_ID) {
  ReactGA.initialize(TRACKING_ID);
}

const AppRouter: React.FunctionComponent<{}> = () => {
  usePageTitle();

  const tokenFromState = useStateSelector(s => s.auth.uiApiToken);
  return (
    <>
      <BrowserRouter basename={Config.subdirectory}>
        <Switch>
          <Route path="/account-update/:confirm?" exact component={UpdateConfirmationPage} />
          <Route path="/auth" exact component={AuthenticationPage} />
          <Route path="/logout" exact component={LogoutPage} />
          <Route path="/no-account" exact component={NoAccountPage} />
          <Route path="/admin/:path(.*)?" component={tokenFromState ? AdminRouter : AuthenticationPage} />
          <Route path="/:path(.*)" component={tokenFromState ? RootContentPage : AuthenticationPage} />
        </Switch>
      </BrowserRouter>
      {tokenFromState && <CashieringUpdatesPoll />}
    </>
  );
};

const AppContent: React.FC<LocalizeContextProps> = ({ initialize, addTranslationForLanguage, children }) => {
  useMount(() => {
    initialize(getLocalizeOptions([LanguageType.English]));
    addTranslationForLanguage(allTranslations[LanguageType.English], LanguageType.English);
  });
  return (
    <Feed>
      <AlertsContainer />
      <DataInitializer />
      {children}
      {/* <AppRouter /> */}
    </Feed>
  );
};

const AppContentWithLocalize = withLocalize(AppContent);

const AppGATracking: FC<{}> = ({ children }) => {
  // const { initTracking } = useTracking(Config.gaUaTrackingCode, Config.ga4TrackingCode);
  // useMount(() => initTracking());
  return <>{children}</>;
};

const App: React.FC = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    // rg4js('trackEvent', { 
    //   type: 'pageView', 
    //   path: window.location.pathname 
    // });
  }, []);

  return (
    <>
      <AppGATracking />
      <AppStoreProvider>
        <useLocalStorage.Provider>
          <LocalizeProvider>
            <AppContentWithLocalize>
              <AppRouter />
            </AppContentWithLocalize>
          </LocalizeProvider>
        </useLocalStorage.Provider>
      </AppStoreProvider>
    </>
  );
};

export default App;
