import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import * as tslib_1 from "tslib";
import React, { useMemo, useCallback, useState } from 'react';
import { SplitDropdown } from './Dropdown';
import _ from 'lodash';
export var BasicDropdown = function BasicDropdown(_a) {
  var values = _a.values,
      buttonclassName = _a.buttonclassName,
      value = _a.value,
      setValue = _a.setValue,
      rest = tslib_1.__rest(_a, ["values", "buttonclassName", "value", "setValue"]);

  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      innerValue = _useState2[0],
      setInnerValue = _useState2[1];

  var options = useMemo(function () {
    return _.map(values, function (v) {
      return {
        name: v.toString(),
        value: v.toString()
      };
    });
  }, [values]);
  var dropdownValue = useMemo(function () {
    var val = value || innerValue;
    return val ? _.find(options, function (o) {
      return o.value === val;
    }) : undefined;
  }, [value, innerValue, options]);
  var setDropdownValue = useCallback(function (val) {
    if (setValue) {
      return setValue(val);
    } else {
      return setInnerValue(val);
    }
  }, [setValue, setInnerValue]);
  return React.createElement(SplitDropdown, Object.assign({
    buttonclassName: buttonclassName,
    className: 'btn-block',
    value: dropdownValue,
    setValue: setDropdownValue,
    values: options
  }, rest), !value && React.createElement("span", {
    className: "mute"
  }, "Select..."), value && React.createElement("span", {
    className: "btn-title"
  }, dropdownValue.value));
};