import React, { useEffect, useMemo } from 'react';
import _ from 'lodash';
import { useStateSelector } from '../../../../../data/global/dataSelectors';
import { DetailField, DetailFields } from '../../../../../components/basic/DetailFields';
import { useDispatcher } from '../../../../../data/global/hooks';
import { AdminApplicationActions } from '../../../../../data/global/actions/admin/AdminApplicationActions';
import { AccountManagementDocuments, InvestigationDocuments } from '@tradingblock/types';
import dayjs from 'dayjs';
import { getRequestedParticipantsDocuments } from '@tradingblock/api';

export const ApplicationDocumentsRequested: React.FunctionComponent<{}> = () => {
  const application = useStateSelector(s => s.private.applications.currentItem.application);
  const investigation = useStateSelector(s => s.private.applications.currentItem.investigation.response.payload);
  const { dispatch } = useDispatcher();

  const { investigations, applications } = investigation || {
    state: undefined,
    investigations: [],
    applications: [],
  };

  const requestedParticipantsDocuments = useMemo(() => {
    return getRequestedParticipantsDocuments(investigations, applications);
  }, [investigations, applications, investigation]);

  const areDocumentsRequested = useMemo(() => {
    return requestedParticipantsDocuments.some(
      participant =>
        participant.requestedCatagories.investigations.length > 0 ||
        participant.requestedCatagories.application.length > 0
    );
  }, [requestedParticipantsDocuments, investigation]);

  const areDocumentsUploaded = useMemo(() => {
    return requestedParticipantsDocuments.some(participant => participant.uploadedDocuments.length > 0);
  }, [requestedParticipantsDocuments, investigation]);

  useEffect(() => {
    if (application.accountId) {
      dispatch(AdminApplicationActions.requestInvestigations({ accountId: application.accountId }));
    }
  }, []);

  const formatDocumentName = (documentType: AccountManagementDocuments | InvestigationDocuments): string => {
    switch (documentType) {
      case AccountManagementDocuments.DriversLicense:
      case InvestigationDocuments.DriversLicense:
        return "Driver's License";
      case AccountManagementDocuments.StateIdCard:
      case InvestigationDocuments.StateIdCard:
        return 'State ID Card';
      case AccountManagementDocuments.Passport:
      case InvestigationDocuments.Passport:
        return 'Passport';
      case AccountManagementDocuments.MilitaryId:
      case InvestigationDocuments.MilitaryId:
        return 'Military ID';
      case AccountManagementDocuments.SsnCard:
      case InvestigationDocuments.SSNCard:
        return 'SSN Card';
      case AccountManagementDocuments.SsaLetter:
      case InvestigationDocuments.SSALetter:
        return 'SSA Letter';
      case AccountManagementDocuments.IrsItinLetter:
      case InvestigationDocuments.IRSITINLetter:
        return 'IRS ITIN Letter';
      case AccountManagementDocuments.OtherGovernmentId:
      case InvestigationDocuments.ForeignerGovermentId: // Assuming this is intended to be "Foreign Government ID"
        return 'Other Government ID';
      case AccountManagementDocuments.CddDocument:
        return 'CDD Document';
      case AccountManagementDocuments.AllPassingCipResults:
        return 'All Passing CIP Results';
      case AccountManagementDocuments.IrsW9Form:
      case InvestigationDocuments.IRSW9Form:
        return 'IRS W-9 Form';
      case AccountManagementDocuments.SignatureImage:
      case InvestigationDocuments.ForeignerSignature: // Assuming this maps to "Signature Image"
        return 'Signature Image';
      case AccountManagementDocuments.IdDocument:
        return 'ID Document';
      case AccountManagementDocuments.AffiliateApproval:
      case InvestigationDocuments.AffiliateApproval:
        return 'Affiliate Approval';
      case AccountManagementDocuments.Document:
        return 'Document';
      case AccountManagementDocuments.Other:
        return 'Other';
      case AccountManagementDocuments.GovernmentId:
        return 'Government ID';

      // Additional cases from InvestigationDocuments not covered above
      // Add here if any unique values exist in InvestigationDocuments

      default:
        return documentType; // Fallback to returning the raw enum value
    }
  };

  const fields = useMemo(() => {
    let fieldList: DetailField[] = [];
    if (application) {
      fieldList = [
        ...fieldList,
        {
          Value: () => (
            <>
              <span className="fields-title">Documents</span>
            </>
          ),
        },
        {
          Value: () => null,
        },
      ];
      requestedParticipantsDocuments.map((participant, index) => {
        if (areDocumentsRequested && areDocumentsUploaded) {
          fieldList = [
            ...fieldList,
            {
              Value: () => (
                <span className="props-title" style={{ fontWeight: 'bold' }}>
                  {participant.firstName} {participant.middleInitial || ''} {participant.lastName}{' '}
                  {participant.suffix || ''}
                </span>
              ),
            },
            {
              Value: () => null,
            },
            {
              Value: () => (
                <>
                  <span>
                    <span className="txt-sm">Documents Requested to Verify: </span>
                    <br />
                    <br />
                    {participant.requestedCatagories.application.map((category, index) => (
                      <span key={`application_request-${category.name}` + index}>
                        <span>
                          <span>{_.startCase(category.name)}</span>
                        </span>
                        <br />
                        <span style={{ color: 'var(--salmon)' }}>
                          {category.requestedDocuments
                            .map((requestedDoc: string | undefined) => _.startCase(requestedDoc))
                            .join(', ')}
                        </span>
                        <br />
                      </span>
                    ))}
                    {participant.requestedCatagories.investigations.map((category, index) => (
                      <span key={`investigation_request-${category.name}` + index}>
                        <span>
                          <span>{_.startCase(category.name)}</span>
                        </span>
                        <br />
                        <span style={{ color: 'var(--salmon)' }}>{category.requestedDocuments.join(', ')}</span>
                        <br />
                      </span>
                    ))}
                  </span>
                </>
              ),
            },
            {
              Value: () => (
                <>
                  <span className="txt-sm">Documents Uploaded:</span>
                  <br />
                  <br />
                  {participant.uploadedDocuments.map((uploadedDocument, index) => (
                    <span key={`application_upload-${uploadedDocument.miscDocumentId}` + index}>
                      <span>
                        <span>{formatDocumentName(uploadedDocument.documentName)}</span>
                        <br />
                        <span className="mute txt-sm">Original Document Name: {uploadedDocument.originalFileName}</span>
                        <br />
                        <span className="mute txt-sm">Status: {_.startCase(uploadedDocument.state)}</span>
                        <br />
                        {uploadedDocument.takenOn && (
                          <span className="mute txt-sm">
                            Taken On: {dayjs(uploadedDocument.takenOn).format('MM/DD/YYYY')}
                          </span>
                        )}
                      </span>
                      <br />
                      <br />
                    </span>
                  ))}
                </>
              ),
            },
          ];
        }
        if (areDocumentsRequested && !areDocumentsUploaded) {
          fieldList = [
            ...fieldList,
            {
              Value: () => (
                <span className="props-title" style={{ fontWeight: 'bold' }}>
                  {participant.firstName} {participant.middleInitial || ''} {participant.lastName}{' '}
                  {participant.suffix || ''}
                </span>
              ),
            },
            {
              Value: () => null,
            },
            {
              Value: () => (
                <>
                  <span>
                    <span className="txt-sm">Documents Requested to Verify: </span>
                    <br />
                    <br />
                    {participant.requestedCatagories.application.map((category, index) => (
                      <span key={`application_request-${category.name}` + index}>
                        <span>
                          <span>{_.startCase(category.name)}</span>
                        </span>
                        <br />
                        <span style={{ color: 'var(--salmon)' }}>
                          {category.requestedDocuments
                            .map((requestedDoc: string | undefined) =>
                              formatDocumentName(requestedDoc as AccountManagementDocuments | InvestigationDocuments)
                            )
                            .join(', ')}
                        </span>
                        <br />
                      </span>
                    ))}
                    {participant.requestedCatagories.investigations.map((category, index) => (
                      <span key={`investigation_request-${category.name}` + index}>
                        <span>
                          <span>{_.startCase(category.name)}</span>
                        </span>
                        <br />
                        <span style={{ color: 'var(--salmon)' }}>
                          {category.requestedDocuments
                            .map((requestedDoc: string | undefined) =>
                              formatDocumentName(requestedDoc as AccountManagementDocuments | InvestigationDocuments)
                            )
                            .join(', ')}
                        </span>
                        <br />
                      </span>
                    ))}
                  </span>
                </>
              ),
            },
          ];
        }
        if (!areDocumentsRequested && areDocumentsUploaded) {
          fieldList = [
            ...fieldList,
            {
              Value: () => (
                <span className="props-title" style={{ fontWeight: 'bold' }}>
                  {participant.firstName} {participant.middleInitial || ''} {participant.lastName}{' '}
                  {participant.suffix || ''}
                </span>
              ),
            },
            {
              Value: () => null,
            },
            {
              Value: () => <span className="txt-sm">Documents Uploaded:</span>,
            },
            {
              Value: () => null,
            },
          ];
          participant.uploadedDocuments.map((uploadedDocument, index) => {
            fieldList = [
              ...fieldList,
              {
                Value: () => (
                  <div key={`application_upload-${uploadedDocument.miscDocumentId}` + index}>
                    <span>
                      <span>{_.startCase(uploadedDocument.documentName)}</span>
                      <br />
                      <span className="mute txt-sm">Original Document Name: {uploadedDocument.originalFileName}</span>
                      <br />
                      <span className="mute txt-sm">Status: {_.startCase(uploadedDocument.state)}</span>
                      <br />
                      {uploadedDocument.takenOn && (
                        <span className="mute txt-sm">
                          Taken On: {dayjs(uploadedDocument.takenOn).format('MM/DD/YYYY')}
                        </span>
                      )}
                    </span>
                    <br />
                    <br />
                  </div>
                ),
              },
            ];
          });
        }
      });
    }
    return fieldList;
  }, [application, areDocumentsRequested, requestedParticipantsDocuments]);

  return (
    <>
      {(areDocumentsRequested || areDocumentsUploaded) && (
        <div className="screen-body-section">
          <DetailFields fieldList={fields} valueAs="div" />
        </div>
      )}

      {!areDocumentsRequested && !areDocumentsUploaded && (
        <div className="screen-body-section">
          <span className="fields-title">No Documents Requested</span>
        </div>
      )}
    </>
  );
};
