import _toConsumableArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import * as tslib_1 from "tslib";
import React, { useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import { TableRow } from './TableRow';
import { Loading, getTableClass } from '..';
/**
 * This implements react-table with <table>, <td>, <thead>, <tbody>
 * and <td>
 *
 * The headers on this table WILL move when you scroll. Possibly
 * moving out of view.
 */

export var BaseTable = function BaseTable(_a) {
  var tableInstance = _a.tableInstance,
      loaded = _a.loaded,
      className = _a.className,
      size = _a.size,
      responsiveDisabled = _a.responsiveDisabled,
      sortable = _a.sortable,
      _a$headersDraggable = _a.headersDraggable,
      headersDraggable = _a$headersDraggable === void 0 ? true : _a$headersDraggable,
      bodyProps = tslib_1.__rest(_a, ["tableInstance", "loaded", "className", "size", "responsiveDisabled", "sortable", "headersDraggable"]);

  var getTableProps = tableInstance.getTableProps,
      headerGroups = tableInstance.headerGroups,
      setColumnOrder = tableInstance.setColumnOrder,
      expanded = tableInstance.state.expanded;
  var tableProps = getTableProps();
  var tableClassName = getTableClass(className, tableProps.className, responsiveDisabled);

  var columnWidths = _.map(tableInstance.columns, function (c) {
    return _.isString(c.width) ? c.width : "".concat(c.width || '');
  });

  return React.createElement(React.Fragment, null, loaded === false && React.createElement(Loading, {
    size: "medium"
  }), loaded !== false && React.createElement("table", Object.assign({}, getTableProps(), {
    className: tableClassName
  }), React.createElement(BaseTableHead, {
    headersDraggable: headersDraggable,
    headerGroups: headerGroups,
    sortable: sortable,
    getCustomHeaderStyles: function getCustomHeaderStyles(column) {
      return _.get(column, 'style', {});
    },
    setColumnOrder: setColumnOrder
  }), React.createElement(BaseTableBody, Object.assign({
    tableInstance: tableInstance,
    responsiveDisabled: responsiveDisabled,
    columnWidths: columnWidths
  }, bodyProps))));
};
export var BaseTableHead = function BaseTableHead(_ref) {
  var sortable = _ref.sortable,
      headerGroups = _ref.headerGroups,
      getCustomHeaderStyles = _ref.getCustomHeaderStyles,
      setColumnOrder = _ref.setColumnOrder,
      tableInstance = _ref.tableInstance,
      tableKey = _ref.tableKey,
      headersDraggable = _ref.headersDraggable;
  var headerClassName = useCallback(function (column) {
    var className = _.isString(column.className) ? column.className : '';

    if (sortable && column.canSort) {
      className += " sortable ".concat(column.isSorted && !column.isSortedDesc ? 'sorting-asc' : column.isSortedDesc ? 'sorting-desc' : '');
    }

    return className;
  }, [sortable]);
  var isTableExpanded = tableInstance && tableInstance.expandedDepth > 0;

  var getItemStyle = function getItemStyle(_ref2, draggableStyle) {
    var isDragging = _ref2.isDragging,
        isDropAnimating = _ref2.isDropAnimating;
    return Object.assign({}, draggableStyle, {
      userSelect: 'none',
      background: isDragging ? 'var(--lighten3x)' : ''
    }, !isDragging && {
      transform: 'translate(0, 0)'
    }, isDropAnimating && {
      transitionDuration: '0.001s'
    });
  };

  function capitalizeFirstLetter(str) {
    if (!str) return str; // Return the original string if it's falsy (e.g., "", null, undefined)

    if (typeof str !== 'string') return str; // Return the original string if it's not a string (e.g., an object, a number, etc.)

    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  var formatHeader = function formatHeader(header) {
    switch (header) {
      case 'AssetSymbol':
        {
          return 'Symbol';
        }

      case 'LastTradePrice':
        {
          return 'Last';
        }

      case 'NetChange':
        {
          return 'Change';
        }

      case 'BidPrice':
        {
          return 'Bid';
        }

      case 'AskPrice':
        {
          return 'Ask';
        }

      case 'ClosePrice':
        {
          return 'Close';
        }

      case 'expander':
        {
          return 'Symbol';
        }

      case 'avg_price':
        {
          return 'Avg Price';
        }

      case 'description':
        {
          return 'Description';
        }

      case 'shares':
        {
          return 'Shares';
        }

      case 'options':
        {
          return 'Options';
        }

      case 'expander':
        {
          return 'Expiration';
        }

      case 'close':
        {
          return 'Prev. Close';
        }

      case 'last':
        {
          return 'Last';
        }

      case 'change':
        {
          return 'Change';
        }

      case 'bid':
        {
          return 'Bid';
        }

      case 'ask':
        {
          return 'Ask';
        }

      case 'Settlement':
        {
          return 'Close';
        }

      case 'favorites-last-col':
        {
          return 'Remove';
        }

      default:
        {
          // capitalize the first letter of each word
          return capitalizeFirstLetter(header);
        }
    }
  };

  var currentColOrder = React.useRef();
  var currentHeader = React.useRef();
  return React.createElement("thead", null, headerGroups.map(function (headerGroup, trIdx) {
    return React.createElement(DragDropContext, {
      onDragStart: function onDragStart(dragUpdateObj, b) {
        currentColOrder.current = _.map(headerGroup.headers, function (h) {
          return h.id;
        });
        currentHeader.current = dragUpdateObj.draggableId;
      },
      onDragEnd: function onDragEnd(result) {
        if (!result.destination) {
          return; // If dropped outside the list, do nothing
        }

        var colOrder = _toConsumableArray(currentColOrder.current);

        var sIndex = result.source.index;
        var dIndex = result.destination.index; // Remove the column from its original position and insert it into the new position

        colOrder.splice(sIndex, 1);
        colOrder.splice(dIndex, 0, result.draggableId);

        if (tableInstance) {
          // ensure that no column is repeated or removed from the list
          var headers = tableInstance.headers; // if  colOrder is missing a column compared to headers, add it to colOrder at the index of the missing column in headers

          headers.forEach(function (header, index) {
            if (!colOrder.includes(header.id)) {
              colOrder.splice(index, 0, header.id);
            }
          });
        } // Update the state once after the drag operation is completed


        setColumnOrder(colOrder);
      },
      key: trIdx
    }, React.createElement(Droppable, {
      droppableId: "droppable",
      direction: "horizontal",
      renderClone: function renderClone(provided, snapshot, rubric) {
        var styles = getItemStyle(snapshot, provided.draggableProps.style);
        return React.createElement("div", Object.assign({}, provided.draggableProps, provided.dragHandleProps, {
          ref: provided.innerRef,
          style: Object.assign({}, styles, {
            color: 'white',
            fontSize: '0.75rem',
            padding: '0.3em 0.35em',
            verticalAlign: 'middle'
          }),
          key: headerGroup.headers[rubric.source.index].id
        }), formatHeader(currentHeader.current));
      }
    }, function (provided, snapshot) {
      return React.createElement("tr", Object.assign({
        key: "tr-".concat(trIdx)
      }, headerGroup.getHeaderGroupProps(), {
        ref: provided.innerRef
      }), headerGroup.headers.map(function (column, thIdx) {
        var headerProps = sortable ? column.getHeaderProps(column.getSortByToggleProps()) : column.getHeaderProps();
        var customHeaderStyles = getCustomHeaderStyles && getCustomHeaderStyles(column, thIdx);
        var styles = Object.assign({}, headerProps.style || {}, customHeaderStyles || {});
        var header = column.render('Header');
        header = formatHeader(header);
        var customProps = sortable && column.canSort ? {
          title: "Sort by ".concat(_.isString(header) ? header : column.id)
        } : {};
        return React.createElement(Draggable, {
          key: column.id,
          draggableId: column.id,
          index: thIdx,
          isDragDisabled: isTableExpanded || !headersDraggable
        }, function (provided, snapshot) {
          return React.createElement("th", Object.assign({
            key: column.id
          }, headerProps, customProps, provided.draggableProps, provided.dragHandleProps, {
            ref: provided.innerRef,
            className: headerClassName(column),
            style: Object.assign({
              zIndex: tableKey === 'positions' || tableKey === 'optionsChain' || tableKey === 'favorites' ? 100 : 1
            }, {
              position: tableKey === 'positions' || tableKey === 'optionsChain' || tableKey === 'favorites' ? 'sticky' : 'static'
            }, {
              styles: styles
            }, getItemStyle(snapshot, provided.draggableProps.style))
          }), header);
        });
      }), provided.placeholder);
    }));
  }));
};
export var BaseTableBody = function BaseTableBody(_a) {
  var tableInstance = _a.tableInstance,
      className = _a.className,
      currentPage = _a.currentPage,
      RowComponent = _a.RowComponent,
      renderSubRow = _a.renderSubRow,
      size = _a.size,
      getRowKey = _a.getRowKey,
      columnOrder = _a.columnOrder,
      expandRows = _a.expandRows,
      baseRowProps = tslib_1.__rest(_a, ["tableInstance", "className", "currentPage", "RowComponent", "renderSubRow", "size", "getRowKey", "columnOrder", "expandRows"]);

  var prepareRow = tableInstance.prepareRow;
  return React.createElement("tbody", {
    className: className
  }, currentPage.map(function (row, index) {
    prepareRow(row);

    var isExpanded = _.get(row, 'isExpanded', expandRows);

    var rowKey = getRowKey ? getRowKey(row.original) : _.get(row.getRowProps(), 'key') || "row-".concat(index);
    var rowProps = Object.assign({}, baseRowProps, {
      row: row,
      index: index,
      rowKey: rowKey,
      prepareRow: prepareRow,
      size: size,
      tableInstance: tableInstance
    });
    return React.createElement(React.Fragment, {
      key: "".concat(rowKey, "-").concat(index)
    }, !RowComponent && React.createElement(TableRow, Object.assign({}, rowProps)), RowComponent && React.createElement(RowComponent, Object.assign({}, rowProps)), isExpanded && React.createElement(React.Fragment, null, renderSubRow(row, {
      size: size
    })));
  }));
};