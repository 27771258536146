import React, { useState, useCallback, useMemo, useEffect } from 'react';
import _ from 'lodash';
import { Modal as BootstrapModal } from 'react-bootstrap';
import { Order, UserRole } from '@tradingblock/types';
import { ContactPhone, Modal } from '@tradingblock/components';
import { useDispatcher } from '../../../../data/global/hooks';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { account } from '../../../../data/global/selectors/accountSelectors';
import { useOrderPreviewOrder } from './useOrderPreview';
import { OrderPreviewPopupProps } from './types';
import { OrderPreviewActions } from './OrderPreviewActions';
import { PreviewPopupContent } from './PreviewPopupContent';
import { useQuote } from '../../../../hooks/useFeedQuote';
import { useSelector } from 'react-redux';
import AdminCommissionOrderActions from './AdminCommissionOrderActions';
import AdminBypassMargin from './AdminBypassMargin';
import { getOrderStatus } from '@tradingblock/api';
import { getOrders } from '../../../../data/global/selectors/orderSelector';

export interface PreviewResult {
  action: 'order-placed' | 'closed' | 'error';
  error?: any;
}

export const OrderPreviewPopupContent: React.FC<OrderPreviewPopupProps & { order: Order }> = ({
  show,
  isCancelOrder,
  previewClosed,
  action: passedAction,
  orderValue,
  ...rest
}) => {
  const accountOrders = useStateSelector(s => getOrders(s));
  const userLevel = useSelector(account.userLevel);
  const userRoles = useSelector(account.userRoles);
  const ableToBypassMargin = userRoles ? userRoles.includes(UserRole.BypassMarginCheck) : false;
  const ableToChangeCommission = userLevel !== 'Account' && userLevel !== 'Undefined' ? true : false;
  const subAccounts = useStateSelector(account.subAccounts);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [orderStatusProcessed, setOrderStatusProcessed] = useState(false);
  const [orderFillResponse, setOrderFillResponse] = useState<{
    orderStatus: { status: boolean | string | undefined };
    response: boolean;
  }>({
    orderStatus: {} as any,
    response: false,
  });
  const [originalOrders, setOriginalOrders] = useState(accountOrders);
  const { order, action, isWarningSymbol, onSave, setOrder } = useOrderPreviewOrder(passedAction);
  const { dispatcher } = useDispatcher();
  const [warningNotAccepted, setWarningNotAccepted] = useState(isWarningSymbol);
  const latestOrderId = useStateSelector(s => s.positions.latestOrderId);

  const [commissionFilled, setCommissionFilled] = useState(ableToChangeCommission ? false : true);

  const onSaveOrder = useCallback(() => {
    if (order && action !== 'replace') {
      setIsSaving(true);
      try {
        onSave();
        setIsSaving(false);
        setOrder({
          ...order,
          BypassMargin: undefined,
          CommissionScheduleId: undefined,
          CommissionType: undefined,
          ExecutedAs: undefined,
          PlacedAs: undefined,
        });
      } catch (placeOrderError) {
        previewClosed({ action: 'error', error: placeOrderError });
        setIsSaving(false);
        dispatcher.order.errored(order, placeOrderError as any);
      } finally {
        setOrderStatusProcessed(true);
      }
    } else if (orderValue && action === 'replace') {
      setIsSaving(true);
      try {
        onSave();
        setIsSaving(false);
        setOrder({
          ...orderValue,
          BypassMargin: undefined,
          CommissionScheduleId: undefined,
          CommissionType: undefined,
          ExecutedAs: undefined,
          PlacedAs: undefined,
        });
      } catch (placeOrderError) {
        previewClosed({ action: 'error', error: placeOrderError });
        setIsSaving(false);
        dispatcher.order.errored(orderValue, placeOrderError as any);
      } finally {
        setOrderStatusProcessed(true);
      }
    }
  }, [order, previewClosed, onSave, dispatcher]);

  const onClose = useCallback(
    (show: boolean) => {
      return previewClosed({ action: 'closed' });
    },
    [previewClosed]
  );
  useEffect(() => {
    // Find the order that matches the latestOrderId
    const order = accountOrders.find(o => o.OrderId === latestOrderId);

    // Retrieve the order status if the order exists
    const orderStatus = order && order.OrderStatus;

    // Get the object status if orderStatus is a number
    const objectStatus = typeof orderStatus === 'number' && getOrderStatus(orderStatus);

    // Check if the orders have changed or if it's a replace action
    const hasOrdersChanged = orderStatusProcessed && accountOrders.length !== originalOrders.length;
    const isReplaceAction = action === 'replace' && accountOrders.length === originalOrders.length;

    // Set the order fill response if either condition is met
    if ((order && hasOrdersChanged) || isReplaceAction) {
      setOrderFillResponse({
        orderStatus: { status: objectStatus && objectStatus.status },
        response: true,
      });
    }
  }, [accountOrders, orderStatusProcessed, latestOrderId, action, originalOrders.length]);

  const onWarningAccepted = useCallback(() => {
    setWarningNotAccepted(!warningNotAccepted);
  }, [warningNotAccepted, setWarningNotAccepted]);

  const saveDisabled = useMemo(() => isSaving || warningNotAccepted === true, [isSaving, warningNotAccepted]);

  const orderQuote = useQuote(order && order.UnderlyingSymbol ? order.UnderlyingSymbol : '');
  const orderAssetClass = useMemo(() => {
    if (order && order.UnderlyingSymbol && orderQuote) {
      const { AssetClass } = orderQuote;
      return AssetClass;
    }
    return '';
  }, [order && order.UnderlyingSymbol, orderQuote]);

  const isMutualFund: boolean = useMemo(() => {
    if (order && order.UnderlyingSymbol) {
      return order.UnderlyingSymbol.startsWith(':');
    }
    return false;
  }, [order]);

  const isBond = useMemo(() => {
    return orderAssetClass === 16 ? true : false;
  }, [orderAssetClass]);

  return (
    <Modal size={'lg'} show={show} setShow={onClose}>
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>{(isCancelOrder ? 'Cancel' : 'Confirm') + ' Order'}</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <PreviewPopupContent
          {...{
            subAccounts,
            warningNotAccepted,
            onWarningAccepted,
            isCancelOrder,
            action: passedAction,
            orderStatusProcessed,
            orderValue,
          }}
        />

        {orderStatusProcessed && orderFillResponse.response && orderFillResponse.orderStatus.status !== false && (
          <div className="well" style={orderFillResponseStyle}>
            Order processed with status <strong>{String(orderFillResponse.orderStatus.status).toUpperCase()}.</strong>
            <br />
            See <strong>Activity Block → Orders Tab</strong> for more information.
          </div>
        )}
        {orderStatusProcessed === true &&
          orderFillResponse.orderStatus.status === false &&
          orderFillResponse.response === true && <></>}

        {ableToChangeCommission && !isCancelOrder && (
          <AdminCommissionOrderActions setCommissionFilled={setCommissionFilled} order={order} setOrder={setOrder} />
        )}
        {ableToBypassMargin && <AdminBypassMargin order={order} setOrder={setOrder} />}
      </BootstrapModal.Body>
      <BootstrapModal.Footer className="modal-footer-justified">
        <OrderPreviewActions
          isCancelOrder={isCancelOrder}
          previewClosed={previewClosed}
          isSaving={isSaving}
          setOrderStatusProcessed={setOrderStatusProcessed}
          onSave={onSaveOrder}
          disabled={
            (!isCancelOrder && ableToChangeCommission && !commissionFilled) ||
            (!_.isEmpty(subAccounts) && order && order.SubaccountId === undefined) ||
            saveDisabled ||
            isMutualFund ||
            isBond
          }
          show={false}
          action={action}
        />
      </BootstrapModal.Footer>
      {isMutualFund && (
        <div className="mt-3">
          <div className="alert alert-warning mb-0 txt-sm">
            <strong>Alert!</strong>
            <p>
              Mutual fund orders are not currently supported by our new dashboard. Please use our Legacy platform for
              the time being. Thank you.
            </p>
          </div>
        </div>
      )}
      {isBond && (
        <div className="mt-3">
          <div className="alert alert-warning mb-0 txt-sm">
            <strong>Alert!</strong>
            <p>
              Bond orders are not currently supported by our new dashboard. Please contact our Trade Desk for the time
              being. Thank you.
            </p>
            <ContactPhone />
          </div>
        </div>
      )}
    </Modal>
  );
};

export const OrderPreviewPopup: React.FC<OrderPreviewPopupProps> = ({
  isCancelOrder,
  show,
  previewClosed,
  action,
  orderValue,
}) => {
  const { order } = useOrderPreviewOrder(action);
  return (
    <>
      {order && (
        <OrderPreviewPopupContent
          isCancelOrder={isCancelOrder}
          {...{ show, previewClosed, order, action, orderValue }}
        />
      )}
    </>
  );
};

export const orderFillResponseStyle = {
  backgroundColor: 'var(--blue-dark)',
  padding: '20px',
  borderRadius: '5px',
  fontSize: '16px',
  textAlign: 'center',
  margin: '0 auto',
  marginTop: '20px',
  marginBottom: '20px',
} as object;
