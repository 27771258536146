import { combineReducers } from 'redux';
import { privateCashieringReducer } from './privateCashieringReducer';
import { privateAccountReducer } from './privateAccountReducer';
import { privateClientReducer } from './privateClientReducer';
import { privateAccountManagementReducer } from './privateAccountManagementReducer';
import { privateEntitlementsReducer } from './privateEntitlementsReducer';
import { privateAlertsReducer } from './privateAlertsReducer';
import { privateApplicationsReducer } from './privateApplicationsReducer';

export const privateReducer = combineReducers({
  account: privateAccountReducer,
  alerts: privateAlertsReducer,
  accountManagement: privateAccountManagementReducer,
  applications: privateApplicationsReducer,
  cashiering: privateCashieringReducer,
  clients: privateClientReducer,
  entitlements: privateEntitlementsReducer,
});
