import React, { useMemo, useRef } from 'react';
import { AssetSymbol, BlockType } from '@tradingblock/types';
import {
  BlockDragHandle,
  HttpAutocomplete,
  OptionType,
  getBlockTypeName,
  SignedNumber,
  Pill,
  Loading,
} from '@tradingblock/components';
import { SymbolToOption, useSymbolSearch } from '../../hooks/useSymbolSearch';
import { useQuote } from '../../hooks/useFeedQuote';
import { FavoriteButton } from '../../components/blocks/FavoriteButton';
import { QuoteTime } from './QuoteTime';
import { SymbolPrice } from './SymbolPrice';

interface SymbolPickerHeaderProps {
  blockType: BlockType;
  symbol: AssetSymbol | undefined;
  isLoaded: boolean | undefined;
  onSelect: (value: OptionType<AssetSymbol>) => void;
  onClear?: () => void;
  placeholder?: string;
  clearOptionsOnSelect?: boolean;
  focus?: boolean;
  disableFavorite?: boolean;
  hidePrice?: boolean;
  hideTime?: boolean;
  hidePercent?: boolean;
  onSearch?: (value: string, limit?: number | undefined) => void;
  onBlur?: () => void;
}

export const SymbolPickerHeader: React.FunctionComponent<SymbolPickerHeaderProps> = ({
  hidePrice,
  blockType,
  symbol,
  isLoaded,
  onSelect,
  placeholder,
  clearOptionsOnSelect,
  focus,
  onClear,
  disableFavorite,
  hideTime,
  hidePercent,
  onSearch,
  onBlur,
}) => {
  const search = useSymbolSearch();
  const symbolValue = symbol ? symbol.symbol : '';
  const symbolQuote = useQuote(symbolValue);
  const autoCompleteValue = useMemo(() => {
    return symbol ? SymbolToOption(symbol, true) : undefined;
  }, [symbol]);
  const rest = useMemo(() => {
    return { autoFocus: focus };
  }, [focus]);

  return (
    <div className="block-nav nav">
      <BlockDragHandle className="nav-item">{getBlockTypeName(blockType)}</BlockDragHandle>
      <div className="nav-item">
        <Pill>
          {isLoaded && (
            <HttpAutocomplete
              onClear={onClear}
              rest={rest}
              minimumLength={1}
              search={(value: string, limit?: number | undefined) => {
                if (onSearch !== undefined) {
                  onSearch(value, limit);
                }

                return search(value, limit);
              }}
              onBlur={onBlur}
              value={autoCompleteValue}
              onSelect={onSelect}
              clearOptionsOnSelect={clearOptionsOnSelect}
              placeholder={placeholder}
              symbol={symbolValue}
              charLimit={20}
            />
          )}
        </Pill>
      </div>
      {symbol && symbolQuote && (
        <>
          <div className="nav-item nav-stock-name">
            <div className="txt-sm">
              <strong>{symbol.symbol}</strong>
            </div>
            <div className="txt-sm mute" title={symbol.name}>
              {symbol.name}
            </div>
          </div>
          {hidePrice !== true && (
            <div className="nav-item nav-price">
              <SymbolPrice symbol={symbol.symbol} />
              <div className="nav-price-change">
                <div className="txt-sm pos">
                  <SignedNumber value={symbolQuote.NetChange ? symbolQuote.NetChange : 0} currency hideIcon />
                </div>
                {hidePercent !== true && (
                  <div className="txt-sm pos">
                    <SignedNumber
                      value={symbolQuote.ChangePercentage ? symbolQuote.ChangePercentage : 0}
                      percent
                      hideIcon
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {!disableFavorite && (
            <div className="nav-item">
              <FavoriteButton symbol={symbol} />
            </div>
          )}
          {hideTime !== true && <QuoteTime symbol={symbol ? symbol.symbol : ''} />}{' '}
        </>
      )}
    </div>
  );
};
