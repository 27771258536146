import React, { useMemo, useCallback } from 'react';
import _ from 'lodash';
import { Expiration } from '@tradingblock/types';
import { OptionType, AsyncAutocomplete } from '@tradingblock/components';
import { OrderState, useOrderData } from '../../state/OrderState';
import { OrderSelectors } from './../../state/OrderSelector';
import { DataState } from '../../../../data/global/state';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { StrikeSelectors } from '../../../../data/global/selectors/expirationStrikeSelector';
import { useFilteredStrikes } from '../../hooks/useStrikesFilteredForStrategy';
import { LoadingDropdownButton } from '../../../../components/basic/Loading';
import { useBlockActionDispatchAndGenericActions, useBlockMetadata } from '../../../../components/blocks/BlockState';
import { useLastPrice } from '../useLastPrice';
import { getType } from 'typesafe-actions';
import { OrderActions, Actions } from '../../state/OrderActions';
import { useOrderPrice } from '../../hooks/useOrderPrice';

interface OrderLegStrikesProps {
  legId: string;
  symbol: string;
  expiration: Expiration;
  strike?: number;
  defaultStrike?: number;
  setStrike: (val: number) => void;
}

const StrikeToOption = (strike: { value: number; isValid: boolean }) => {
  const sym: OptionType<number> = {
    label: strike.value.toString(),
    value: strike.value.toString(),
    data: strike.value,
    searchValue: strike.value.toString(),
  };

  const numberSizeTrigger = 6;
  const requiredPrecision = 2;

  // edge case fix for when strikes have a large precision. scales with sigfigs. 1, 1000, 10000.
  if (sym.label.length > numberSizeTrigger) {
    // split by decimal grab the suffix and fix the preicision ~.00
    const deimcalSuffix = sym.label.split('.')[1].slice(0, requiredPrecision);
    // assign new property value to object prop. concat decimal then concat formatted suffix
    sym.label = sym.label
      .split('.')[0]
      .concat('.')
      .concat(deimcalSuffix);
    return sym;
  }
  return sym;
};

const useDefaultStrikeValue = (symbol: string, strikes: OptionType<number>[]) => {
  const currentPrice = useLastPrice(symbol);
  if (currentPrice === undefined) {
    return undefined;
  }
  return _(strikes)
    .orderBy(s => Math.abs(currentPrice.LastTradePrice - s.data))
    .first();
};

export const OrderLegStrikes: React.FC<OrderLegStrikesProps> = ({ legId, symbol, expiration, strike, setStrike }) => {
  // const { strikes, isFetching } = useOrderData(s => OrderSelectors.strikesByExpiration(s, expiration));
  const { blockId } = useBlockMetadata();
  const { mid } = useOrderPrice();
  const strikesAreInvalid = useOrderData(s => OrderSelectors.fieldIsInvalid(s, blockId, 'Strike'));
  //const strikes = useStateSelector((state: DataState) => (expiration ? StrikeSelectors.strikes(state, { symbol, expiration: expiration }) || [] : []));
  const strikes = useFilteredStrikes(legId, expiration);
  const isStrikesFetching = useStateSelector((state: DataState) =>
    expiration ? StrikeSelectors.isFetching(state, { symbol, expiration }) : undefined
  );
  // useEffect(() => {
  //   if (!isStrikesFetching && _.isNumber(defaultStrike) && strike === undefined) {
  //     setStrike(defaultStrike);
  //   }
  // }, [strike, isStrikesFetching, setStrike, defaultStrike]);

  const strikeOptions = useMemo(() => _.filter(strikes, s => s.isValid).map(StrikeToOption), [strikes, strike]);
  // const strikeOptions = useMemo(() => _.map(strikes, StrikeToOption), [strikes]);
  const currValue = useMemo(() => (strike ? strikeOptions.find(strikeOpt => strikeOpt.data === strike) : undefined), [
    strikeOptions,
    strike,
  ]);

  const [{}, dispatch] = useBlockActionDispatchAndGenericActions<OrderState, OrderActions>();

  const setValue = useCallback(
    (val: OptionType<number>) => {
      const match = strikeOptions.find(s => s.data === val.data);
      if (match) {
        dispatch({
          type: getType(Actions.setPrice),
          payload: { price: undefined, updateCreditOrDebit: true, mid: mid },
        });
        dispatch({ type: getType(Actions.setStopPrice), payload: { price: undefined, updateCreditOrDebit: false } });
        setStrike(match.data);
      }
    },
    [setStrike, strikeOptions]
  );

  const search = useCallback(
    async (value: string) => {
      return Promise.resolve(strikeOptions);
    },
    [strikeOptions]
  );
  const defaultValue = useDefaultStrikeValue(symbol, strikeOptions);

  return (
    <>
      {isStrikesFetching !== false && <LoadingDropdownButton />}
      {/* {isStrikesFetching === false && (
        <SplitDropdown buttonclassName={`btn-dark btn-tall dropdown-toggle text-left btn-block`} className={'btn-block'} value={currValue} setValue={setValue} values={strikeOptions}>
          {!currValue && <span className="mute">strike</span>}
          {currValue && <span className="btn-title">{currValue.value}</span>}
        </SplitDropdown>
      )} */}
      {isStrikesFetching === false && (
        <div className={`btn btn-dark btn-tall btn-block dropdown-select ${strikesAreInvalid ? 'invalid' : ''}`}>
          <AsyncAutocomplete
            defaultValue={defaultValue}
            isSearchable={false}
            dropdownIcon="fa-chevron-down"
            placeholder="Strike..."
            value={currValue}
            isClearable={false}
            initialValues={strikeOptions}
            minimumLength={0}
            search={search}
            onSelect={setValue}
          />
        </div>
      )}
    </>
  );
};
