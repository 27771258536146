import { ActionType, createStandardAction } from 'typesafe-actions';
import {
  EntitlementsItem,
  EntitlementsSearchRequest,
  EntitlementsSearchResponse,
  GenericError,
  AccountUpdateUserAccountDetailRequest,
  UpdateUserMarketDataEntitlementRequest,
} from '@tradingblock/types';
import { buildGenericRequestReceiveActions } from '../../actionUtilities';
import { AdminIdRequest } from '.';

const ADMIN_REQUEST_ENTITLEMENT_UPDATES = '@admin/entitlements/search/data/REQUEST';
const ADMIN_RECEIVE_ENTITLEMENT_UPDATES = '@admin/entitlements/search/data/RECEIVE';
const ADMIN_ERROR_ENTITLEMENT_UPDATES = '@admin/entitlements/search/data/ERROR';

const ADMIN_REQUEST_ENTITLEMENT_ACCOUNT_DETAILS = '@admin/entitlements/account/data/REQUEST';
const ADMIN_RECEIVE_ENTITLEMENT_ACCOUNT_DETAILS = '@admin/entitlements/account/data/RECEIVE';
const ADMIN_ERROR_ENTITLEMENT_ACCOUNT_DETAILS = '@admin/entitlements/account/data/ERROR';

const ADMIN_ENTITLEMENTS_SET_PAGE = '@admin/entitlements/search/ui/SET_PAGE';
const ADMIN_ENTITLEMENTS_SET_PAGE_SIZE = '@admin/entitlements/search/ui/SET_PAGE_SIZE';

const ADMIN_ENTITLEMENTS_SET_CURRENT_ITEM = '@admin/entitlements/search/ui/SET_CURRENT_ITEM';
const ADMIN_ENTITLEMENTS_CLEAR_CURRENT_ITEM = '@admin/entitlements/search/ui/CLEAR_CURRENT_ITEM';

const ADMIN_REQUEST_ENTITLEMENTS_APPROVE_USER_MARKET_DATA_ENTITLEMENT = '@admin/entitlements/approve/data/REQUEST';
const ADMIN_RECEIVE_ENTITLEMENTS_APPROVE_USER_MARKET_DATA_ENTITLEMENT = '@admin/entitlements/approve/data/RECEIVE';
const ADMIN_ERROR_ENTITLEMENTS_APPROVE_USER_MARKET_DATA_ENTITLEMENT = '@admin/entitlements/approve/data/ERROR';

const ADMIN_REQUEST_ENTITLEMENTS_REJECT_USER_MARKET_DATA_ENTITLEMENT = '@admin/entitlements/reject/data/REQUEST';
const ADMIN_RECEIVE_ENTITLEMENTS_REJECT_USER_MARKET_DATA_ENTITLEMENT = '@admin/entitlements/reject/data/RECEIVE';
const ADMIN_ERROR_ENTITLEMENTS_REJECT_USER_MARKET_DATA_ENTITLEMENT = '@admin/entitlements/reject/data/ERROR';

const [
  requestEntitlementUpdates,
  receiveEntitlementUpdates,
  errorEntitlementUpdates,
] = buildGenericRequestReceiveActions({
  REQUEST: ADMIN_REQUEST_ENTITLEMENT_UPDATES,
  RECEIVE: ADMIN_RECEIVE_ENTITLEMENT_UPDATES,
  ERROR: ADMIN_ERROR_ENTITLEMENT_UPDATES,
})<
  EntitlementsSearchRequest,
  { request: EntitlementsSearchRequest; response: any },
  GenericError<any, AdminIdRequest>
>();

const [
  requestEntitlementAccountDetails,
  receiveEntitlementAccountDetails,
  errorEntitlementAccountDetails,
] = buildGenericRequestReceiveActions({
  REQUEST: ADMIN_REQUEST_ENTITLEMENT_ACCOUNT_DETAILS,
  RECEIVE: ADMIN_RECEIVE_ENTITLEMENT_ACCOUNT_DETAILS,
  ERROR: ADMIN_ERROR_ENTITLEMENT_ACCOUNT_DETAILS,
})<AccountUpdateUserAccountDetailRequest, { response: any }, GenericError<any, AdminIdRequest>>();

const [
  requestApproveUserMarketDataEntitlement,
  receiveApproveUserMarketDataEntitlement,
  errorApproveUserMarketDataEntitlement,
] = buildGenericRequestReceiveActions({
  REQUEST: ADMIN_REQUEST_ENTITLEMENTS_APPROVE_USER_MARKET_DATA_ENTITLEMENT,
  RECEIVE: ADMIN_RECEIVE_ENTITLEMENTS_APPROVE_USER_MARKET_DATA_ENTITLEMENT,
  ERROR: ADMIN_ERROR_ENTITLEMENTS_APPROVE_USER_MARKET_DATA_ENTITLEMENT,
})<UpdateUserMarketDataEntitlementRequest, { response: any }, GenericError<any, AdminIdRequest>>();

const [
  requestRejectUserMarketDataEntitlement,
  receiveRejectUserMarketDataEntitlement,
  errorRejectUserMarketDataEntitlement,
] = buildGenericRequestReceiveActions({
  REQUEST: ADMIN_REQUEST_ENTITLEMENTS_REJECT_USER_MARKET_DATA_ENTITLEMENT,
  RECEIVE: ADMIN_RECEIVE_ENTITLEMENTS_REJECT_USER_MARKET_DATA_ENTITLEMENT,
  ERROR: ADMIN_ERROR_ENTITLEMENTS_REJECT_USER_MARKET_DATA_ENTITLEMENT,
})<UpdateUserMarketDataEntitlementRequest, { response: any }, GenericError<any, AdminIdRequest>>();

export const AdminEntitlementsSearchActions = {
  requestEntitlementUpdates,
  receiveEntitlementUpdates,
  errorEntitlementUpdates,
  requestEntitlementAccountDetails,
  receiveEntitlementAccountDetails,
  errorEntitlementAccountDetails,
  requestApproveUserMarketDataEntitlement,
  receiveApproveUserMarketDataEntitlement,
  errorApproveUserMarketDataEntitlement,
  requestRejectUserMarketDataEntitlement,
  receiveRejectUserMarketDataEntitlement,
  errorRejectUserMarketDataEntitlement,
  setEntitlementsPage: createStandardAction(ADMIN_ENTITLEMENTS_SET_PAGE)<Pick<EntitlementsSearchRequest, 'page'>>(),
  setEntitlementsPageSize: createStandardAction(ADMIN_ENTITLEMENTS_SET_PAGE_SIZE)<
    Pick<EntitlementsSearchRequest, 'pageSize'>
  >(),
  setCurrentItem: createStandardAction(ADMIN_ENTITLEMENTS_SET_CURRENT_ITEM)<EntitlementsItem>(),
  clearCurrentItem: createStandardAction(ADMIN_ENTITLEMENTS_CLEAR_CURRENT_ITEM)<undefined>(),
  refreshEntitlements: createStandardAction('@admin/entitlements/search/ui/REFRESH')<EntitlementsSearchRequest>(),
};

export const AdminEntitlementsActions = {
  ...AdminEntitlementsSearchActions,
};

export type AdminEntitlementsAction = ActionType<typeof AdminEntitlementsActions>;
