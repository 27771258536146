import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useStateSelector } from '../data/global/dataSelectors';
import { UIActions } from '../data/global/actions';

export const useDashboardDrawer = (): [boolean, (open: boolean) => void, () => void] => {
  const drawerIsOpen = useStateSelector(s => s.ui.dashboard.drawerIsOpen);
  const dispatch = useDispatch();
  const setDrawerIsOpen = useCallback(
    (open: boolean) => {
      dispatch(UIActions.setDashboardDrawerOpen({ open }));
    },
    [dispatch]
  );
  const toggleDrawerIsOpen = useCallback(() => {
    dispatch(UIActions.setDashboardDrawerOpen({ open: !drawerIsOpen }));
  }, [drawerIsOpen, dispatch]);
  return [drawerIsOpen, setDrawerIsOpen, toggleDrawerIsOpen];
};
