import React from 'react';
import { BlockType } from '@tradingblock/types';
import { BlockDragHandle, getBlockTypeName } from '@tradingblock/components';

interface HeaderProps {
  blockType: BlockType;
}

export const AdminToolsHeader: React.FunctionComponent<HeaderProps> = ({ blockType }) => {
  return (
    <>
      <div className="block-nav nav">
        <BlockDragHandle className="nav-item">{getBlockTypeName(blockType)}</BlockDragHandle>
      </div>
    </>
  );
};
