import React, { useCallback, useEffect, useMemo } from 'react';
import { Cell, Column, Row } from 'react-table';
import _ from 'lodash';
import { SubAccount, SizeType } from '@tradingblock/types';
import { Table, Loading, formatNumber } from '@tradingblock/components';
import { useStateSelector } from '../../data/global/dataSelectors';
import { account } from '../../data/global/selectors/accountSelectors';
import { SelectSubAccountButton } from './SelectSubAccountButton';
import { formatAccountNumber, isSelectedSubAccount } from '../../utilities/account';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../../data/global/actions';

export const SubAccountTable: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const accountNumber = useStateSelector(account.accountNumber);
  const accountId = useStateSelector(account.accountId);
  const selectedSubAccountId = useStateSelector(account.subAccountId);
  const subAccounts = useStateSelector(account.subAccounts);
  const isFetching = useStateSelector(account.subAccountsIsFetching);
  const hasData = !_.isEmpty(subAccounts);
  const isSelectedLinkedAccountLoaded = useSelector(account.isSelectedLinkedAccountLoaded);
  const subAccountBalances = useStateSelector(account.subAccountsBalances);
  const accountBalance = useStateSelector(account.linkedAccountsBalances);
  const buyingPower = useStateSelector(
    s => s.accountData.balances.balances && s.accountData.balances.balances.BuyingPower.AvailableFunds
  );
  const balanceIsFetching = useStateSelector(s => s.accountData.balances.isFetching);

  const SubAccountListColumns: Column<SubAccount>[] = [
    {
      Header: 'Account',
      accessor: a => a.Id,
      id: 'Account',
      Cell: ({ row }: Cell<SubAccount>) => {
        return (
          <SelectSubAccountButton subAccount={row.original} type="link" disabled={!isSelectedLinkedAccountLoaded} />
        );
      },
      width: '100px',
    },
    {
      Header: 'Nickname',
      accessor: a => a.Nickname,
      id: 'Nickname',
      width: '150px',
    },
    {
      Header: 'Description',
      accessor: a => a.Description,
      id: 'Description',
      width: '250px',
    },
    {
      Header: 'Available for trading',
      accessor: a => {
        const balance = !balanceIsFetching && a.Id === -1 ? accountBalance[a.AccountId] : subAccountBalances[`${a.Id}`];
        if (balance) {
          return formatNumber(balance.BuyingPower.AvailableFunds, { currency: true }) + '*';
        } else if (a.Id === -1 && !balance && buyingPower) {
          return formatNumber(buyingPower, { currency: true }) + '*';
        }
        return <i className={'fas fa-cog fa-spin'} />;
      },
      id: 'AvailableForTrading',
      width: '120px',
    },
    {
      Header: 'Action',
      id: 'action',
      Cell: ({ row }: Cell<SubAccount>) => {
        return (
          <SelectSubAccountButton subAccount={row.original} type="btn" disabled={!isSelectedLinkedAccountLoaded} />
        );
      },
      width: '100px',
    },
  ];

  useEffect(() => {
    if (subAccounts && subAccounts.length > 0 && accountId) {
      dispatch(Actions.requestSubAccountBalances({ accountId: accountId, subAccounts: subAccounts }));
    }
  }, [subAccounts, accountId, dispatch]);

  const data = useMemo(() => {
    if (subAccounts) {
      return [
        {
          Id: -1,
          AccountId: accountId,
          IsMaster: false,
          Nickname: 'ALL',
          Description: 'All sub-accounts combined',
          AvailableForTrade: buyingPower,
        },
        ...subAccounts,
      ];
    }
    return [];
  }, [subAccounts, buyingPower, accountId]);

  const getRowClass = useCallback(
    (row: Row<SubAccount>) => {
      if (isSelectedSubAccount(selectedSubAccountId, row.original)) {
        return 'highlighted';
      }
      return '';
    },
    [selectedSubAccountId]
  );

  return (
    <>
      {isFetching && <Loading />}
      {!isFetching && (
        <>
          {hasData && (
            <>
              <p>Select sub-account of {formatAccountNumber(accountNumber)}</p>
              <Table
                isDraggable={false}
                rowClass={getRowClass}
                columns={SubAccountListColumns}
                data={data}
                responsiveDisabled={true}
                size={SizeType.lg}
              />
            </>
          )}
          {!hasData && <></>}
        </>
      )}
    </>
  );
};
