import { createStandardAction } from 'typesafe-actions';
import { ActionBuilderConstructor } from 'typesafe-actions/dist/type-helpers';
import { TypeConstant, GenericError, ApiResponse, ResponseCodes } from '@tradingblock/types';
import { isString } from '@tradingblock/api';

export function buildRequestReceiveActions<A extends TypeConstant, AA extends TypeConstant, AAA extends TypeConstant>(requestAction: A, receiveAction: AA, errorAction: AAA) {
  return function<R = undefined, RR = undefined, RRR = undefined>(): [ActionBuilderConstructor<A, R>, ActionBuilderConstructor<AA, RR>, ActionBuilderConstructor<AAA, RRR>] {
    return [createStandardAction(requestAction)<R>(), createStandardAction(receiveAction)<RR>(), createStandardAction(errorAction)<RRR>()];
  };
}

export function buildActions<A extends TypeConstant, AA extends TypeConstant, AAA extends TypeConstant>(actions: { REQUEST: A; RECEIVE: AA; ERROR: AAA }) {
  return function<R, RR, E = GenericError>() {
    return buildRequestReceiveActions<A, AA, AAA>(actions.REQUEST, actions.RECEIVE, actions.ERROR)<R, ApiResponse<RR>, E>();
  };
}
export const actionBuilder = <R, RR>() => {
  return <A extends TypeConstant, AA extends TypeConstant, AAA extends TypeConstant>(REQUEST: A, RECEIVE: AA, ERROR: AAA) => {
    return buildActions<A, AA, AAA>({ REQUEST, RECEIVE, ERROR })<R, RR, GenericError>();
  };
};
export const genericActionBuilder = <R, RR>() => {
  return <A extends TypeConstant, AA extends TypeConstant, AAA extends TypeConstant>(REQUEST: A, RECEIVE: AA, ERROR: AAA) => {
    return buildRequestReceiveActions<A, AA, AAA>(REQUEST, RECEIVE, ERROR)<R, RR, GenericError>();
  };
};
export function buildGenericRequestReceiveActions<A extends TypeConstant, AA extends TypeConstant, AAA extends TypeConstant>(actions: { REQUEST: A; RECEIVE: AA; ERROR: AAA }) {
  return function<R, RR, E = GenericError>() {
    return buildRequestReceiveActions<A, AA, AAA>(actions.REQUEST, actions.RECEIVE, actions.ERROR)<R, RR, E>();
  };
}
export function buildApiActions<A extends TypeConstant, AA extends TypeConstant, AAA extends TypeConstant>(actions: [A, AA, AAA]) {
  return function<R, RR, E = GenericError>() {
    return buildRequestReceiveActions<A, AA, AAA>(actions[0], actions[1], actions[2])<R, RR, E>();
  };
}

export function createPersistableAction<A extends TypeConstant>(requestAction: A) {
  return function<R = undefined>() {
    return createStandardAction(requestAction)<R, { persist?: boolean }>();
  };
}

export function buildErrorObject(resp: ApiResponse<any>, data: any) {
  const error = ResponseCodes[resp.responseCode] ? ResponseCodes[resp.responseCode] : { name: `Unknown ${resp.responseCode} ResponseCode`, description: 'Unknown error' };
  const message = isString(error.description) ? error.description : error.description(error.name);
  return {
    error,
    message,
    data,
  };
}
