import { createStandardAction } from 'typesafe-actions';
import { DefaultDashboardResponse, BlockSettingsSavePayload } from '@tradingblock/types';
import { buildGenericRequestReceiveActions } from '../actionUtilities';

const SETTINGS_DEFAULT_DASHBOARDS_REQUEST = '@settings/defaultdashboard/REQUEST';
const SETTINGS_DEFAULT_DASHBOARDS_RECEIVE = '@settings/defaultdashboard/RECEIVE';
const SETTINGS_DEFAULT_DASHBOARDS_ERROR = '@settings/defaultdashboard/ERROR';

const SETTINGS_GLOBALBLOCK_SAVE_REQUEST = '@settings/block/save/REQUEST';
const SETTINGS_GLOBALBLOCK_SAVE_RECEIVE = '@settings/block/save/RECEIVE';
const SETTINGS_GLOBALBLOCK_SAVE_ERROR = '@settings/block/save/ERROR';

const [defaultDashboardRequest, defaultDashboardReceive, defaultDashboardError] = buildGenericRequestReceiveActions({ REQUEST: SETTINGS_DEFAULT_DASHBOARDS_REQUEST, RECEIVE: SETTINGS_DEFAULT_DASHBOARDS_RECEIVE, ERROR: SETTINGS_DEFAULT_DASHBOARDS_ERROR })<{}, DefaultDashboardResponse>();
const [blockSettingsSaveRequest, blockSettingsSaveReceive, blockSettingsSaveError] = buildGenericRequestReceiveActions({ REQUEST: SETTINGS_GLOBALBLOCK_SAVE_REQUEST, RECEIVE: SETTINGS_GLOBALBLOCK_SAVE_RECEIVE, ERROR: SETTINGS_GLOBALBLOCK_SAVE_ERROR })<BlockSettingsSavePayload, {}>();

const DefaultDashboardActions = {
  defaultDashboardRequest,
  defaultDashboardReceive,
  defaultDashboardError,
};

const GlobalBlockSettingsActions = {
  blockSettingsSaveRequest,
  blockSettingsSaveReceive,
  blockSettingsSaveError,
  //setGlobalBlockSetting: createStandardAction('@settings/global-block/SET')<{ blockType: BlockType; globalSettings: GenericSettingsObject }>(),
  setBlockSetting: createStandardAction('@settings/block/SET')<BlockSettingsSavePayload>(),
};
export type SetBlockSettingsAction = ReturnType<typeof GlobalBlockSettingsActions.setBlockSetting>;
export const SettingsActions = {
  ...GlobalBlockSettingsActions,
  ...DefaultDashboardActions,
};
