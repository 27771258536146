import React, { useCallback, useState } from 'react';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import { ExternalLink, FormGroup, Modal, StepperField, Text } from '@tradingblock/components';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { useDispatch } from 'react-redux';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { AccountManagementDataActions } from '../../../data/global/actions/AccountManagementActions';
import {
  AccountManagementDocuments,
  AllBooleanToggleTypes,
  AllFlexibilityTypes,
  ApiResponse,
  BooleanToggleType,
  ClearerType,
  Country,
  FlexibilityType,
  FlexibilityTypeUpdateValueProp,
} from '@tradingblock/types';
import { getInvalidError, getRequiredError, getUploadError } from './Validation';
import { MiscUploadFileField } from '../../../components/form/UploadMiscFile';
import { useApi } from '../../../context/Api';
import { useClearer } from '../../../hooks/useClearer';

/**
 * Modal for updating a user's flexibilityType (Margin/Cash-Only)
 *
 *
 * @param {boolean} show Boolean value used for showing/hiding modal
 * @param {void} toggleModal Function to toggle show from true or false
 * @returns {JSX.Element}
 */

export const FlexibilityTypeUpdate: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
}> = ({ show, toggleModal }) => {
  // Used Hooks
  const dispatch = useDispatch();

  // Used States
  const [clickedAgreements, setClickedAgreements] = useState<string[]>([]);
  const [uploadIds, setUploadId] = useState<{ documentId: number; accountHolderIndex: number }[]>([]);
  const [numberOfUploads, setNumberOfUploads] = useState<number>(0);

  // Array of documents uploaded for update request
  const documentIds: {
    documentId: number;
    accountHolderIndex: number;
    documentType: AccountManagementDocuments;
  }[] = [];

  // Retrieve documentId, accountHolderIndex, documentType of uploaded files, push into documentIds array for submission & increment numberOfUploads
  const handleDocumentUpload = (
    documentId: number,
    accountHolderIndex: number,
    documentType: AccountManagementDocuments
  ) => {
    if (documentId) {
      setUploadId([...uploadIds, { accountHolderIndex, documentId }]);
      documentIds.push({ documentId, accountHolderIndex, documentType });
      setNumberOfUploads(numberOfUploads => numberOfUploads + 1);
    }
    return;
  };

  // Log clicked agreements for disable/enable of accept/reject buttons
  const onClickedAgreementLink = useCallback(
    (agreement: string) => {
      setClickedAgreements([...clickedAgreements, agreement]);
    },
    [clickedAgreements]
  );

  // Return undefined, disabling button, for agreements that have not been clicked
  const getAgreementDisabledOptions = useCallback(
    (agreement: string) => {
      // if haven't clicked agreement, disable toggle buttons
      if (!_.includes(clickedAgreements, agreement)) {
        return AllBooleanToggleTypes;
      }
      return undefined;
    },
    [clickedAgreements]
  );

  // Clearer
  const clearer = useClearer();

  // Margin Agreement Links
  // TODO: Ensure RQD Link is accurate once it is available
  const link = 'https://legacy.tradingblock.com/Docs/Agreements//Margin_Agreement_vTest.pdf';
  const rqdLink = 'https://legacy.tradingblock.com/Docs/Agreements//RQD_Margin_Supplement_Agreement_v3_220421.pdf';

  // State Selectors
  const accountId = useStateSelector(s => s.accounts.account && s.accounts.account.AccountId);
  const accountDetails = useStateSelector(s => s.accountManagement.accountManagementDetails.details);
  const { accountHolders } = accountDetails;

  const api = useApi();
  const [duplicateAccountError, setDuplicateAccountError] = React.useState<string | null>(null);

  const handleDuplicateAccountCheckResponse = useCallback((response: ApiResponse<any>) => {
    if (response.responseCode !== 0) {
      setDuplicateAccountError(response.payload[0]);
    } else {
      return;
    }
  }, []);

  /**
   * FlexibilityTypeUpdateValueProps
   *
   * @property {FlexibilityType | undefined} flexibilityType User response to Account Type (Margin/Cash-Only)
   * @property {BooleanToggleType | null} margin If applicable, user acceptance or rejection of margin agreement
   */

  const initialTradeGoalFlexUpdate: FlexibilityTypeUpdateValueProp = {
    flexibilityType: undefined,
    agreements: {
      margin: null,
    },
  };

  const formatBooleanToggleType = (toggleType: string) => {
    if (toggleType === 'yes') {
      return true;
    } else {
      return false;
    }
  };

  // Check if either account holder has foreign citizenship
  const checkForForeign = () => {
    const holderCitizenship = accountHolders.filter(
      holder => holder.citizenshipCountry !== Country.UnitedStatesOfAmerica
    );
    if (holderCitizenship.length > 0) {
      return true;
    }
    return false;
  };
  // Boolean for foreign account holder
  const isForeign = checkForForeign();

  // On form submission set updateValues and dispatch update request
  const onSubmit = useCallback(
    (values, { resetForm }) => {
      const { flexibilityType } = values;
      let updateValues;
      updateValues = {
        item: 'Flexability',
        flexibilityType,
        agreements: {
          margin: formatBooleanToggleType(values.agreements.margin),
        },
      };

      // if either account holder is foreign, attach required documents to update values
      if (isForeign) {
        updateValues = {
          item: 'Flexability',
          flexibilityType,
          agreements: {
            margin: formatBooleanToggleType(values.agreements.margin),
          },
          uploadedDocuments: documentIds.map(doc => {
            return {
              accountHolder: doc.accountHolderIndex,
              documentId: doc.documentId,
              documentName: doc.documentType,
            };
          }),
        };
      }

      // If flexibility type is stale, reset form and close modal
      if (flexibilityType === accountDetails.flexibilityType) {
        resetForm();
        toggleModal();
        return;
      }

      // dispatch update request
      dispatch(AccountManagementDataActions.requestMakeAccountUpdate({ accountId: accountId, request: updateValues }));

      // re-retrieve account/account management details following update request
      resetForm();
      toggleModal();
    },
    [toggleModal]
  );

  // Form validation prior to submission, ensures all disclosures are answered and necessary docs are gathered
  const onValidate = (values: FlexibilityTypeUpdateValueProp) => {
    const { flexibilityType, agreements } = values;
    const { margin } = agreements;

    if (values.flexibilityType) {
      let requestValues: DuplicateAccountCheckRequest;
      // TODO: Implement duplicate account check for entities once supported and redux implemented
      // if (accountDetails.type === 'Entity') {
      //   requestValues = {
      //     accountIdToIgnore: accountId,
      //     entityAccountType: accountDetails.entityAccountType,
      //     flexibilityType: values.flexibilityType,
      //     primaryTaxIdSecret:
      //   };
      // }

      if (accountDetails.type === 'Individual' && !isForeign) {
        requestValues = {
          accountIdToIgnore: accountId,
          flexibilityType: values.flexibilityType,
          primaryTaxIdSecret: accountHolders[0].ssnSecret,
          retirementAccountType: accountDetails.retirementAccountType ? accountDetails.retirementAccountType : null,
          type: accountDetails.type,
        };

        api.application.duplicateAccountCheck(requestValues).then(response => {
          handleDuplicateAccountCheckResponse(response);
        });
      }
      if ((accountDetails.type === 'Joint' || accountDetails.type === 'Ugma') && !isForeign) {
        requestValues = {
          accountIdToIgnore: accountId,
          flexibilityType: values.flexibilityType,
          primaryTaxIdSecret: accountHolders[0].ssnSecret,
          secondaryTaxIdSecret: accountHolders[1].ssnSecret,
          retirementAccountType: accountDetails.retirementAccountType ? accountDetails.retirementAccountType : null,
          type: accountDetails.type,
        };

        api.application.duplicateAccountCheck(requestValues).then(response => {
          handleDuplicateAccountCheckResponse(response);
        });
      }
    }

    if (!flexibilityType) {
      return {
        [`flexibilityType`]: getRequiredError(flexibilityType),
      };
    }
    if (!isForeign && duplicateAccountError) {
      return {
        [`flexibilityType`]: duplicateAccountError ? duplicateAccountError : undefined,
      };
    }
    if (flexibilityType === FlexibilityType.Margin && !margin) {
      return {
        [`agreements.margin`]: getRequiredError(margin),
      };
    }

    if (flexibilityType === FlexibilityType.Margin && margin && margin !== BooleanToggleType.Yes) {
      return {
        [`agreements.margin`]: getInvalidError(false),
      };
    }

    if (isForeign && accountDetails.type !== 'Joint' && numberOfUploads < 2) {
      return {
        [`uploadDocuments`]: getUploadError(false),
      };
    }

    if (isForeign && accountDetails.type === 'Joint' && numberOfUploads < 4) {
      return {
        [`uploadedDocuments`]: getUploadError(false),
      };
    }
  };

  interface DuplicateAccountCheckRequest {
    accountIdToIgnore?: number;
    entityAccountType?: string;
    flexibilityType: string;
    primaryTaxIdSecret?: string;
    retirementAccountType?: string | null;
    secondaryTaxIdSecret?: string;
    type: string;
  }

  return (
    <Formik initialValues={initialTradeGoalFlexUpdate} onSubmit={onSubmit} validate={onValidate}>
      {({ handleSubmit, values, errors, submitCount, resetForm }) => (
        <Modal setShow={() => toggleModal()} show={show}>
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Financial Information Update Request</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            <form id="flexUpdate" onSubmit={handleSubmit}>
              <h6>Account Type Update</h6>
              <p className="txt-sm mute">What type of trading flexibility do you need?</p>
              <p className="txt-sm mute">Cash-only accounts trade on a cash basis and do not include margin.</p>
              <p className="txt-sm mute">
                Margin accounts offer the most flexibility with advanced trading strategies and the ability to trade on
                margin, subject to approval and acceptance of margin agreement.
              </p>
              <FormGroup>
                <Field component={StepperField} id="flexibilityType" options={AllFlexibilityTypes} />
                {/* {duplicateAccountError && <span className="error">{duplicateAccountError}</span>} */}
              </FormGroup>
              {values.flexibilityType === 'Margin' && (
                <>
                  <h4>
                    <ExternalLink
                      className="mr-3"
                      href={clearer === ClearerType.Apex ? link : rqdLink}
                      onClick={() => onClickedAgreementLink('margin')}
                    >
                      <i className="far fa-download" /> Margin Agreement
                    </ExternalLink>{' '}
                  </h4>
                  <FormGroup>
                    <Field
                      component={StepperField}
                      id={`agreements.margin`}
                      options={AllBooleanToggleTypes}
                      disabledOptions={getAgreementDisabledOptions('margin')}
                      defaultLabelId="agreements"
                    />
                  </FormGroup>
                </>
              )}
              {values.flexibilityType && isForeign && (
                <>
                  <p className="txt-sm mute">
                    Foreign account type updates require signature and government ID images to be uploaded for admin
                    review. Please attach documents accordingly below prior to submission.
                  </p>
                  {errors.uploadedDocuments && !!submitCount && (
                    <>
                      <span className="error">
                        <Text id={'uploadedDocuments'} textKey={`govAndSig`} type={'error'} />
                      </span>
                      <br />
                      <br />
                    </>
                  )}
                  {accountHolders.map((holder, i) => (
                    <div key={i}>
                      <h6>
                        {holder.firstName} {holder.middleInitial || ''} {holder.lastName} {holder.suffix || ''}
                      </h6>
                      <FormGroup>
                        <label className="txt-sm mute">Signature Image</label>
                        <Field
                          component={MiscUploadFileField}
                          id={`uploadDocuments-${i}`}
                          accountId={accountId}
                          accountHolderIndex={i}
                          description={'SignatureImage'}
                          documentType={AccountManagementDocuments.SignatureImage}
                          handleDocumentUpload={handleDocumentUpload}
                          clearer={clearer}
                        />
                        <label className="txt-sm mute">Government ID</label>
                        <Field
                          component={MiscUploadFileField}
                          id={`uploadDocuments-${i}`}
                          accountId={accountId}
                          accountHolderIndex={i}
                          description={'GovernmentId'}
                          documentType={AccountManagementDocuments.GovernmentId}
                          handleDocumentUpload={handleDocumentUpload}
                          clearer={clearer}
                        />
                      </FormGroup>
                    </div>
                  ))}
                  <br />
                </>
              )}
            </form>
          </BootstrapModal.Body>
          <BootstrapModal.Footer className="modal-footer-justified">
            <Button
              variant="secondary"
              onClick={() => {
                resetForm();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={(values: any) => handleSubmit(values)}>
              Submit
            </Button>
          </BootstrapModal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
