import React from 'react';
import { OrderStatusToString } from './util';

export const ActivityOrderStatusWrapper: React.FunctionComponent<{ status?: number }> = ({ status, children }) => {
  return (
    <>
      <div className={OrderStatusToString(status) === 'Cancelled' ? 'mute' : ''}>{children}</div>
    </>
  );
};
