import { isString, verify } from '@tradingblock/api';
import { LoginJwtValue, UIClientTypes } from '@tradingblock/types';
import { Config } from '../config';

type ValidTokenType = {
  value: string;
  decoded: LoginJwtValue;
};
const tryVerify = async (publicKey: string, token: string): Promise<ValidTokenType | null> => {
  try {
    var decoded = await verify(publicKey, token, {
      clientType: UIClientTypes.dashboard,
      algorithms: ['RS256'],
      ignoreExpiration: false,
    });
    if (!decoded || isString(decoded)) {
      console.warn('jwt response was invalid, an object was expected', decoded);
      return null;
    } else {
      const jwtValues = decoded as LoginJwtValue;

      return { value: token, decoded: jwtValues };
    }
  } catch (err) {
    console.warn('token verification failed:', err);
    return null;
  }
};

export const verifyToken = async (id_token: string) => {
  const pubKey = await fetch(`${Config.uiApi}/publickey`).then(res => res.json());
  return await tryVerify(pubKey.key, id_token);
};

export const validateToken = async (id_token: string) => {
  // const pubKey = await fetch(`${Config.uiApi}/publickey`).then(res => res.json());
  const tokenResult = await fetch(`${Config.uiApi}/token`, {
    headers: { Authorization: id_token },
    credentials: 'include',
  }).then(res => res.json());
  const verifyRes = await tryVerify(tokenResult.key, tokenResult.accessToken);
  return verifyRes
    ? {
        ...verifyRes,
      }
    : undefined;
};
