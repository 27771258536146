import createCachedSelector from 're-reselect';

import { DataState } from '../state';

import { blockGroupId } from '../dataSelectors';

import { GroupState, BlockGroupState } from '@tradingblock/types';
import _ from 'lodash';

export const groupIdByBlockId = blockGroupId;
export const groupSymbol = createCachedSelector(
  (state: DataState, groupId: string) => _.find(state.blocks.groups, g => g.groupId === groupId),
  (group: BlockGroupState | undefined) => (group ? group.symbol : undefined)
)((_, id) => `blockgroupsymbol${id}`);
export const groupLinkedColor = createCachedSelector(
  (state: DataState, groupId: string) => _.find(state.blocks.groups, g => g.groupId === groupId),
  (group: BlockGroupState | undefined) => (group ? group.linkedColor : undefined)
)((_, id) => `blockgroupcolor${id}`);
export const groupSymbolByBlockId = createCachedSelector(
  (state: DataState) => state.blocks.groups,
  blockGroupId,
  (groups, groupId) => {
    if (groupId === undefined) {
      return undefined;
    }
    const matchingGroup = _.find(groups, g => g.groupId === groupId);
    return matchingGroup ? matchingGroup.symbol : undefined;
  }
)((_, id) => `groupSymbolByBlockId${id}`);
