import _ from 'lodash';
import { AdminLink, UserRole, UserProfileLevel } from '@tradingblock/types';
import { canAccessAdminLink } from '../../utilities/admin';

const AdminLinks: AdminLink[] = [
  {
    text: 'Forms',
    url: 'https://tradingblock.com/admin-forms',
    secondaryUrl: 'https://moneyblock.com/admin-forms',
    icon: 'fal fa-file-contract',
    roles: undefined, // not specific to a user role, any admin/rep can access
  },
  {
    text: 'Cashiering review',
    url: '/admin/cashiering',
    icon: 'fal fa-cogs',
    roles: [UserRole.CashieringApproval],
  },
];

export const getAdminLinks = (
  userLevel: UserProfileLevel | undefined,
  userRoles: UserRole[] | undefined
): AdminLink[] => {
  return _.filter(AdminLinks, al => !al.hidden && canAccessAdminLink(al, userLevel, userRoles));
};
