import { debug } from 'debug';
export var logger = function logger(subname) {
  var log = debug("tb");

  if (subname) {
    return log.extend(subname);
  }

  return log;
};
export var setLogLevel = function setLogLevel(val) {
  return debug.enable(val);
};