/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useWhyDidYouUpdate } from '@tradingblock/components';
import { useQuoteActions } from '../../../hooks/useQuoteData';
import { useBlockGlobalData, useBlockId } from '../../../components/blocks/BlockState';
import { useOrderData } from './OrderState';
import { OrderSymbolList } from './OrderLegSelector';
import { useOrderDebug } from '../OrderUtilities';
import { areArraysEqual } from '../../../utilities/data';

export const useOrderSymbolData = () => {
  const log = useOrderDebug('useOrderSymbolData');
  const { setQuotes } = useQuoteActions();
  const { quotes } = useBlockGlobalData();
  const blockId = useBlockId();
  const currentLegSymbols = useOrderData(s => OrderSymbolList(s, blockId));

  const [orderSymbols, setOrderSymbols] = useState<string[]>([]);

  useEffect(() => {
    const legsymbolList = currentLegSymbols.map(s => s.symbol).filter(ss => ss !== undefined);
    const symbolsToRemove = _.difference(orderSymbols || [], legsymbolList || []);
    const symbolsToAdd = _.difference(legsymbolList, quotes);
    const symbolChanges = _.union(symbolsToRemove, symbolsToAdd);
    if ((symbolChanges && symbolChanges.length > 0) || !areArraysEqual(orderSymbols, legsymbolList)) {
      log('syncing block symbols %o', { legsymbolList, orderSymbols });
      setQuotes(legsymbolList);
      setOrderSymbols(orderSymbols);
    } else {
      log('order block symbols havent changed %o', { legsymbolList, orderSymbols });
    }
    // setOrderSymbols(currentLegSymbols);
  }, [orderSymbols, currentLegSymbols]);

  useWhyDidYouUpdate('Order:useOrderSymbolData', {
    orderSymbols,
    currentLegSymbols,
    setOrderSymbols,
    quotes,
    setQuotes,
    log,
  });
};
