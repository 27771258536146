import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { CashieringView, BalanceTotals, AccountIdException } from '@tradingblock/types';
import { CashieringViewFormValues, CashieringViewProps, ViewOnSubmitType } from './data/useCashieringView';
import { useDispatcher } from '../../../data/global/hooks';
import { useCashieringDataContext } from './data/useCashieringData';
import { cashieringSelectors, useStateSelector } from '../../../data/global/dataSelectors';
import { account } from '../../../data/global/selectors/accountSelectors';
import { AccountDepositDisplay } from '../../../components/cashiering/AccountDepositDisplay';
import { Actions } from '../../../data/global/actions';

interface DepositReviewViewContentProps extends CashieringViewProps<CashieringViewFormValues> {
  totals: BalanceTotals;
  nickname: string;
  isIRA?: boolean;
}

const DepositReviewViewContent: React.FC<DepositReviewViewContentProps> = ({ nickname, totals, values, children }) => {
  const { gotoNextView } = useCashieringDataContext();
  const { transfer } = values;

  const achRelationships = useSelector(cashieringSelectors.achRelationships);

  const achRelationship = useMemo(
    () => _.find(achRelationships, a => !!transfer && a.id === transfer.achRelationshipId),
    [achRelationships, transfer]
  );

  const balancesForWithdrawal = useStateSelector(s => s.accountData.balances.balancesForWithdrawal);
  const availableForWithdrawal = balancesForWithdrawal ? balancesForWithdrawal.availableForWithdrawal : 0;

  return (
    <AccountDepositDisplay
      nickname={nickname}
      availableForWithdrawal={availableForWithdrawal}
      transfer={transfer}
      achRelationship={achRelationship}
      gotoNextView={gotoNextView}
    >
      {children}
    </AccountDepositDisplay>
  );
};

const DepositReviewView: React.FC<CashieringViewProps<CashieringViewFormValues>> = props => {
  const totals = useSelector(cashieringSelectors.balanceTotals);
  const accountInfo = useStateSelector(s =>
    s.account
      ? {
          nickname: s.account.nickname,
          accountNumber: s.account.accountNumber,
          isIRA: s.account.isIRA,
        }
      : undefined
  );

  return (
    <>
      {totals && accountInfo ? (
        <DepositReviewViewContent {...props} totals={totals} {...accountInfo} />
      ) : (
        `Account details not loaded`
      )}
    </>
  );
};

export const useDepositReviewView = (): [typeof DepositReviewView, ViewOnSubmitType, undefined] => {
  const { dispatcher } = useDispatcher();
  const accountId = useStateSelector(account.accountId);
  const subAccounts = useSelector(account.subAccounts);

  const onSubmit = useCallback(
    (values: CashieringViewFormValues, view: CashieringView | undefined) => {
      if (accountId === undefined) {
        throw AccountIdException;
      }

      const transfer = _.pick(values.transfer, [
        'amount',
        'mechanism',
        'achRelationshipId',
        'subAccountsValues',
        'subAccountId',
      ]);

      if (!transfer.amount) {
        throw new Error('Invalid transfer amount');
      }

      switch (transfer.mechanism) {
        case 'Ach':
          if (!transfer.achRelationshipId) {
            throw new Error('Invalid transfer achRelationshipId');
          }
          const selectedSubAccount = subAccounts
            ? subAccounts.find(sub => sub.Id === transfer.subAccountId)
            : undefined;
          const achDeposit = {
            amount: _.toNumber(transfer.amount),
            relationshipId: transfer.achRelationshipId,
            note:
              transfer.subAccountsValues !== undefined &&
              subAccounts !== undefined &&
              Object.values(transfer.subAccountsValues).length > 0
                ? Object.keys(transfer.subAccountsValues).reduce((acc, key) => {
                    const subAccount = subAccounts.find(sub => String(sub.Id) === key);
                    if (transfer.subAccountsValues && subAccount) {
                      acc += `Deposit to ${subAccount.Nickname}: $${transfer.subAccountsValues[key]}.\n`;
                    }
                    return acc;
                  }, '')
                : selectedSubAccount !== undefined
                ? `Deposit to ${selectedSubAccount.Nickname}: $${transfer.amount}.`
                : undefined,
          };
          dispatcher.cashiering.transfers.achDeposit.request({
            accountId,
            subaccountId: transfer.subAccountId,
            ...achDeposit,
          });
          break;

        default:
          console.warn(
            'useDepositReviewView :: not calling any action for ',
            transfer.mechanism,
            ' deposit... ',
            transfer
          );
          break;
      }
    },
    [dispatcher.cashiering]
  );

  return [DepositReviewView, onSubmit, undefined];
};
