import { Dispatch, Middleware } from 'redux';
import { getType } from 'typesafe-actions';
import _ from 'lodash';
import {
  CreateAchRelationshipRequest,
  ApproveAchRelationshipRequest,
  DeleteAchRelationshipRequest,
  UpdateAchRelationshipRequest,
  AchDepositRequest,
  AchWithdrawalRequest,
  WireWithdrawalRequest,
  AccountRequestFor,
  SuccessResponseCode,
  CheckWithdrawalRequest,
  DeleteTransferRequest,
  SecurityChallengeFailedResponseCode,
  NoAvailableSecurityChallengesResponseCode,
  APINotAuthorizedResponseCode,
  ExpiredTokenCode,
  SecurityChallenge,
  CreateTransferInstructionRequest,
  UpdateTransferInstructionsRequest,
  DeleteTransferInstructionsRequest,
} from '@tradingblock/types';
import { DataState } from '../../state';
import { RootAction, SessionActions } from '../../actions';
import { CashieringActions } from '../../actions/CashieringActions';
import { accountIdSelector, subAccountIdSelector } from '../../dataSelectors';
import { Dispatcher } from '../../dispatcher';
import { ApiProvider } from '../../../../context/Api';

const getSecurityQuestions = (state: DataState, dispatch: Dispatch<RootAction>) => {
  const dispatcher = Dispatcher(dispatch);
  const api = ApiProvider(state, dispatch);
  return {
    fetch: (state: DataState) => {
      const dataNotLoading = !state.cashiering.securityQuestion.isFetching;
      // fetch data if not loading
      if (dataNotLoading) {
        api.cashiering.securityQuestion.fetch().then(resp => {
          // if no security questions available, all have failed
          if (resp.responseCode === NoAvailableSecurityChallengesResponseCode) {
            dispatcher.cashiering.securityQuestion.get.error({
              error: resp.responseCode,
              message: 'You have answered all the security challenges incorrectly.',
            });
          } else if (resp.responseCode === APINotAuthorizedResponseCode) {
            dispatch(SessionActions.expired({ code: ExpiredTokenCode.ApiTokenExpired }));
          } else {
            dispatcher.cashiering.securityQuestion.get.receive(resp.payload);
          }
        });
      }
    },
    verify: (data: SecurityChallenge) => {
      api.cashiering.securityQuestion
        .verify(data)
        .then(resp => {
          if (resp.responseCode === SuccessResponseCode) {
          }
          const result = resp.responseCode === SuccessResponseCode;
          const message = !result
            ? resp.responseCode === SecurityChallengeFailedResponseCode
              ? 'Your answer didn’t match.'
              : 'An error occurred trying to verify your security question.'
            : undefined;
          dispatcher.cashiering.securityQuestion.verify.receive(result, message);
        })
        .catch(err => {
          console.warn('Caught error trying to verify security question: ', err);
          dispatcher.cashiering.securityQuestion.verify.receive(
            false,
            'An error occurred trying to verify your security question.'
          );
        });
    },
  };
};

const getAccountBalances = (state: DataState, dispatch: Dispatch<RootAction>) => {
  const dispatcher = Dispatcher(dispatch);
  const api = ApiProvider(state, dispatch);

  return {
    load: (state: DataState, accountId?: number, subaccountId?: number) => {
      const dataNotLoading = !state.cashiering.isFetchingBalances;
      // fetch data if not loading
      if (dataNotLoading) {
        api.cashiering.balances(accountId, subaccountId).then(resp => {
          dispatcher.cashiering.balances.receive(resp.payload);
        });
      }
    },
    update: (state: DataState) => {
      const balances = state.accountData.balances.balances;
      const pendingTransfers = state.accountData.balances.pendingTransfers;
      if (balances) {
        dispatcher.cashiering.balances.receive(balances, pendingTransfers);
      }
    },
  };
};

const getAchRelationships = (state: DataState, dispatch: Dispatch<RootAction>) => {
  const dispatcher = Dispatcher(dispatch);
  const api = ApiProvider(state, dispatch);
  return {
    all: (state: DataState, accountId: number) => {
      // now always refetching...
      const dataNotLoaded = !state.cashiering.achRelationships.isFetching;
      // fetch data if not loaded
      if (dataNotLoaded && accountId) {
        api.cashiering.ach
          .all(accountId)
          .then(resp => {
            dispatcher.cashiering.achRelationships.all.receive({
              payload: resp.payload,
              responseCode: resp.responseCode,
            });
          })
          .catch(err => {
            dispatcher.cashiering.achRelationships.all.error({ error: err, message: 'AchRelationship get all failed' });
          });
      } else if (state.cashiering.achRelationships.data && !state.cashiering.achRelationships.isFetching) {
        // if data already loaded, dispatch receive action right away
        dispatcher.cashiering.achRelationships.all.receive({
          payload: state.cashiering.achRelationships.data,
          responseCode: SuccessResponseCode,
        });
      }
    },
    fetchPlaidLinkToken: (state: DataState, accountId: number, redirectUri?: string) => {
      if (accountId) {
        api.cashiering.ach.fetchPlaidLinkToken(accountId, redirectUri as string).then(resp => {
          dispatcher.cashiering.achRelationships.plaidLinkToken.receive(resp);
        });
      }
    },
    create: (data: CreateAchRelationshipRequest, accountId: number) => {
      if (accountId) {
        api.cashiering.ach
          .create(accountId, data)
          .then(resp => {
            dispatcher.cashiering.achRelationships.create.receive(resp.payload, resp.responseCode);
          })
          .catch(err => {
            dispatcher.cashiering.achRelationships.create.error({
              error: err,
              message: 'AchRelationship get all failed',
            });
          });
      }
    },
    approve: (data: AccountRequestFor<ApproveAchRelationshipRequest>) => {
      const { accountId, ...request } = data;
      api.cashiering.ach
        .approve(accountId, request.achRelationshipId, _.omit(request, [
          'achRelationshipId',
        ]) as ApproveAchRelationshipRequest)
        .then(resp => {
          dispatcher.cashiering.achRelationships.approve.receive(resp.payload, resp.responseCode);
        })
        .catch(err => {
          dispatcher.cashiering.achRelationships.approve.error({ error: err, message: 'ACH approve failed' });
        });
    },
    update: (data: AccountRequestFor<UpdateAchRelationshipRequest>) => {
      api.cashiering.ach
        .update(data.accountId, data.achRelationshipId, { nickName: data.nickName, note: data.note })
        .then(resp => {
          dispatcher.cashiering.achRelationships.update.receive(resp.payload, resp.responseCode);
        })
        .catch(err => {
          dispatcher.cashiering.achRelationships.update.error({ error: err, message: 'ACH update failed' });
        });
    },
    delete: (data: AccountRequestFor<DeleteAchRelationshipRequest>) => {
      api.cashiering.ach
        .cancel(data.accountId, data.achRelationshipId, data.comment)
        .then(resp => {
          dispatcher.cashiering.achRelationships.delete.receive(resp.payload, resp.responseCode);
        })
        .catch(err => {
          dispatcher.cashiering.achRelationships.delete.error({ error: err, message: 'ACH deletion failed' });
        });
    },
  };
};

const getTransferInstructions = (state: DataState, dispatch: Dispatch<RootAction>) => {
  const dispatcher = Dispatcher(dispatch);
  const api = ApiProvider(state, dispatch);

  return {
    all: (accountId: number) => {
      api.cashiering.transferInstructions
        .all(accountId)
        .then(resp => {
          dispatcher.cashiering.transferInstructions.all.receive({
            payload: resp.payload,
            responseCode: resp.responseCode,
          });
        })
        .catch(err => {
          dispatcher.cashiering.transferInstructions.all.error({
            error: err,
            message: 'TransferInstruction get all failed',
          });
        });
    },
    create: (data: AccountRequestFor<CreateTransferInstructionRequest>) => {
      api.cashiering.transferInstructions
        .create(data.accountId, data)
        .then(resp => {
          dispatcher.cashiering.transferInstructions.create.receive({
            payload: resp.payload,
            responseCode: resp.responseCode,
          });
        })
        .catch(err => {
          dispatcher.cashiering.transferInstructions.create.error({
            error: err,
            message: 'TransferInstruction create failed',
          });
        });
    },
    update: (data: AccountRequestFor<UpdateTransferInstructionsRequest>) => {
      api.cashiering.transferInstructions
        .update(data.accountId, data.id, data)
        .then(resp => {
          dispatcher.cashiering.transferInstructions.update.receive({
            payload: resp.payload,
            responseCode: resp.responseCode,
          });
        })
        .catch(err => {
          dispatcher.cashiering.transferInstructions.update.error({
            error: err,
            message: 'TransferInstruction update failed',
          });
        });
    },
    delete: (data: AccountRequestFor<DeleteTransferInstructionsRequest>) => {
      api.cashiering.transferInstructions
        .delete(data.accountId, data.id, data.comment)
        .then(resp => {
          dispatcher.cashiering.transferInstructions.delete.receive({
            payload: resp.payload,
            responseCode: resp.responseCode,
          });
        })
        .catch(err => {
          dispatcher.cashiering.transferInstructions.delete.error({
            error: err,
            message: 'TransferInstruction delete failed',
          });
        });
    },
  };
};

const getTransfers = (state: DataState, dispatch: Dispatch<RootAction>) => {
  const dispatcher = Dispatcher(dispatch);
  const api = ApiProvider(state, dispatch);
  return {
    load: (state: DataState, accountId: number) => {
      const dataNotLoaded = !state.cashiering.transfers.isFetching;
      // fetch data if not loaded
      if (dataNotLoaded && accountId) {
        api.cashiering.transfers.all(accountId).then(resp => {
          dispatcher.cashiering.transfers.all.receive(resp.payload);
        });
      } else if (state.cashiering.transfers.data && !state.cashiering.transfers.isFetching) {
        // if data already loaded, dispatch receive action right away
        dispatcher.cashiering.transfers.all.receive(state.cashiering.transfers.data);
      }
    },
    achDeposit: (accountId: number, data: AchDepositRequest) => {
      api.cashiering.transfers
        .achDeposit(accountId, data)
        .then(resp => {
          // If we have updated notes or admin notes we need to update those too
          if (data.note !== undefined) {
            api.cashiering.transfers
              .update(accountId, resp.payload.id, {
                note: data.note,
              })
              .then(() => {
                dispatcher.cashiering.transfers.achDeposit.receive({
                  payload: resp.payload,
                  responseCode: resp.responseCode,
                });
              });
          } else {
            dispatcher.cashiering.transfers.achDeposit.receive({
              payload: resp.payload,
              responseCode: resp.responseCode,
            });
          }
          console.log('Ach Deposit response', resp);
        })
        .catch(err => {
          dispatcher.cashiering.transfers.achDeposit.error({ error: err, message: 'Ach Deposit initiation failed' });
        });
    },
    achWithdrawal: (accountId: number, data: AchWithdrawalRequest) => {
      api.cashiering.transfers
        .achWithdrawal(accountId, data)
        .then(resp => {
          // If we have updated notes or admin notes we need to update those too
          if (data.note !== undefined) {
            api.cashiering.transfers
              .update(accountId, resp.payload.id, {
                note: data.note,
              })
              .then(() => {
                dispatcher.cashiering.transfers.achWithdrawal.receive({
                  payload: resp.payload,
                  responseCode: resp.responseCode,
                });
              });
          } else {
            dispatcher.cashiering.transfers.achWithdrawal.receive({
              payload: resp.payload,
              responseCode: resp.responseCode,
            });
          }
          console.log('Ach Withdrawal response', resp);
        })
        .catch(err => {
          dispatcher.cashiering.transfers.achWithdrawal.error({
            error: err,
            message: 'Ach Withdrawal initiation failed',
          });
        });
    },
    wireWithdrawal: (accountId: number, data: WireWithdrawalRequest) => {
      api.cashiering.transfers
        .wireWithdrawal(accountId, data)
        .then(resp => {
          dispatcher.cashiering.transfers.wireWithdrawal.receive({
            payload: resp.payload,
            responseCode: resp.responseCode,
          });
          console.log('Wire Withdrawal response', resp);
        })
        .catch(err => {
          dispatcher.cashiering.transfers.wireWithdrawal.error({
            error: err,
            message: 'Wire Withdrawal initiation failed',
          });
        });
    },
    checkWithdrawal: (accountId: number, data: CheckWithdrawalRequest) => {
      const { nameSecret, ...withdrawalData } = data;
      // bank account name stored separately in beneficiary; need to create beneficiary for check withdrawal
      const beneficiaryData = {
        nameSecret: data.nameSecret,
        nickName: `Check beneficiary for accountId ${accountId}`,
        city: '',
        country: '',
        postalCode: '',
        stateProvince: '',
        streetAddress1: '',
        streetAddress2: '',
      };

      const errorMessage = 'Check Withdrawal initiation failed';

      // after beneficiary created, then create check withdrawal
      api.cashiering.transfers
        .checkWithdrawal(accountId, { ...withdrawalData })
        .then(resp => {
          dispatcher.cashiering.transfers.checkWithdrawal.receive({
            payload: resp.payload,
            responseCode: resp.responseCode,
          });
          console.log('Check Withdrawal response', resp);
        })
        .catch(err => {
          dispatcher.cashiering.transfers.checkWithdrawal.error({ error: err, message: errorMessage });
        });
    },
    delete: (data: AccountRequestFor<DeleteTransferRequest>) => {
      api.cashiering.transfers
        .cancel(data.accountId, data.transferId, data.comment)
        .then(resp => {
          dispatcher.cashiering.transfers.delete.receive(resp.payload, resp.responseCode);
        })
        .catch(err => {
          dispatcher.cashiering.transfers.delete.error({ error: err, message: 'Transfer delete failed' });
        });
    },
  };
};

export const CashieringMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  try {
    // state BEFORE action is dispatched
    const prevState = getState();
    const result = next(action);

    const accountId = accountIdSelector(prevState);
    const subaccountId = subAccountIdSelector(prevState);

    const securityQuestions = getSecurityQuestions(prevState, dispatch);
    const balances = getAccountBalances(prevState, dispatch);
    const transfers = getTransfers(prevState, dispatch);
    const achRelationships = getAchRelationships(prevState, dispatch);
    const transferInstructions = getTransferInstructions(prevState, dispatch);

    try {
      switch (action.type) {
        case getType(CashieringActions.requestSecurityQuestion):
          securityQuestions.fetch(prevState);
          break;
        case getType(CashieringActions.requestVerifySecurityQuestion):
          securityQuestions.verify(action.payload);
          break;
        case getType(CashieringActions.receiveVerifySecurityQuestion):
          if (!action.payload.result) {
            // fetch another question if verification failed
            securityQuestions.fetch(prevState);
          }
          break;

        case getType(CashieringActions.requestBalances):
          if (!subaccountId && action.payload.subaccountId) {
            // select the sub-account
            balances.load(prevState, accountId, action.payload.subaccountId);
          } else {
            balances.update(prevState);
          }
          break;

        case getType(CashieringActions.requestTransfers):
          transfers.load(prevState, accountId);
          break;
        case getType(CashieringActions.requestAchWithdrawal):
          transfers.achWithdrawal(accountId, action.payload);
          break;
        case getType(CashieringActions.requestWireWithdrawal):
          transfers.wireWithdrawal(accountId, action.payload);
          break;
        case getType(CashieringActions.requestCheckWithdrawal):
          transfers.checkWithdrawal(accountId, action.payload);
          break;
        case getType(CashieringActions.requestAchDeposit):
          transfers.achDeposit(accountId, action.payload);
          break;
        case getType(CashieringActions.deleteTransferRequest):
          transfers.delete(action.payload);
          break;

        case getType(CashieringActions.allAchRelationshipRequest):
          achRelationships.all(prevState, accountId);
          break;
        case getType(CashieringActions.plaidLinkTokenRequest):
          achRelationships.fetchPlaidLinkToken(prevState, accountId, action.payload.redirectUri);
          break;
        case getType(CashieringActions.createAchRelationshipRequest):
          achRelationships.create(action.payload, accountId);
          break;
        case getType(CashieringActions.approveAchRelationshipRequest):
          achRelationships.approve(action.payload);
          break;
        case getType(CashieringActions.updateAchRelationshipRequest):
          achRelationships.update(action.payload);
          break;
        case getType(CashieringActions.deleteAchRelationshipRequest):
          achRelationships.delete(action.payload);
          break;
        case getType(CashieringActions.createTransferInstructionsRequest):
          transferInstructions.create(action.payload);
          break;
        case getType(CashieringActions.allTransferInstructionsRequest):
          transferInstructions.all(action.payload);
          break;
        case getType(CashieringActions.updateTransferInstructionsRequest):
          transferInstructions.update(action.payload);
          break;
        case getType(CashieringActions.deleteTransferInstructionsRequest):
          transferInstructions.delete(action.payload);
          break;
      }
    } catch (apiError) {
      Dispatcher(dispatch).global.exception({ error: apiError, data: action });
    }

    return result;
  } catch (err) {
    console.error('cashieringMiddleware :: Caught an exception for action ', action, err);
  }
};
