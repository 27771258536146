import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { Formik, Field } from 'formik';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { FormGroup, TextboxField, Modal, SelectDropdownField } from '@tradingblock/components';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { AccountManagementDataActions } from '../../../data/global/actions/AccountManagementActions';
import { getInvalidError, getMismatchError, getRequiredError, getStaleError } from './Validation';
import { AllPhoneTypes, PhoneUpdateValueProps, SubmitPhoneValues } from '@tradingblock/types';

export const PhoneUpdate: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
  accountHolderIndex: number;
}> = ({ show, toggleModal, accountHolderIndex }) => {
  const initialPhoneUpdateValues: PhoneUpdateValueProps = {
    phoneValue: '',
    phoneConfirm: '',
    phoneType: '',
  };
  const accountId = useStateSelector(s => s.accounts.account && s.accounts.account.AccountId);
  const dispatch = useDispatch();
  const accountDetails = useStateSelector(s => s.accountManagement.accountDetails.data);
  const { homePhone, workPhone, mobilePhone } = accountDetails;

  let header = '';

  const onValidate = (values: PhoneUpdateValueProps) => {
    const { phoneValue, phoneConfirm, phoneType } = values;
    if (!phoneValue || !phoneConfirm || !phoneType) {
      return {
        [`phoneValue`]: getRequiredError(phoneValue),
        [`phoneConfirm`]: getRequiredError(phoneConfirm),
        [`phoneType`]: getRequiredError(phoneType),
      };
    }

    if (phoneValue !== phoneConfirm) {
      return {
        [`phoneConfirm`]: getMismatchError(phoneConfirm === phoneValue),
      };
    }

    if (phoneValue.length < 10 || phoneConfirm.length < 10) {
      return {
        [`phoneValue`]: getInvalidError(phoneValue.length > 10),
        [`phoneConfirm`]: getInvalidError(phoneConfirm.length > 10),
      };
    }

    if (phoneType === 'Work' && phoneValue === workPhone) {
      return {
        [`phoneConfirm`]: getStaleError(phoneValue !== workPhone),
      };
    }

    if (phoneType === 'Mobile' && phoneValue === mobilePhone) {
      return {
        [`phoneConfirm`]: getStaleError(phoneValue !== mobilePhone),
      };
    }

    if (phoneType === 'Home' && phoneValue === homePhone) {
      return {
        [`phoneConfirm`]: getStaleError(phoneValue !== homePhone),
      };
    }
  };

  const onSubmit = useCallback(
    (values, { resetForm }) => {
      const { phoneValue, phoneType } = values;
      const updateValues: SubmitPhoneValues = {
        item: 'PhoneNumber',
        accountHolder: {
          phones: [
            {
              phoneNumber: phoneValue,
              type: phoneType,
            },
          ],
        },
        holder: accountHolderIndex,
      };
      dispatch(
        AccountManagementDataActions.requestMakeAccountUpdate({
          accountId: accountId,
          request: updateValues,
        })
      );

      resetForm();
      toggleModal();
    },
    [toggleModal]
  );

  return (
    <Formik initialValues={initialPhoneUpdateValues} onSubmit={onSubmit} validate={onValidate} validateOnChange={true}>
      {({ handleSubmit, resetForm }) => (
        <Modal setShow={() => toggleModal()} show={show}>
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Phone Number Update</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            <h6>{header}</h6>
            <form id="phoneUpdate" onSubmit={handleSubmit} className="phoneUpdate">
              <FormGroup>
                <Field component={SelectDropdownField} id={`phoneType`} options={AllPhoneTypes} label={'Phone Type'} />
              </FormGroup>
              <>
                <FormGroup>
                  <Field
                    component={TextboxField}
                    name={'phoneValue'}
                    id={'phoneValue'}
                    label="Enter new phone number"
                  />
                </FormGroup>
                <FormGroup>
                  <Field
                    component={TextboxField}
                    name={'phoneConfirm'}
                    id={'phoneConfirm'}
                    label="Confirm new phone number"
                  />
                </FormGroup>
              </>
            </form>
            <p className="txt-sm mute">
              All updates are pending admin review. You will be notified when your submission has been processed.
            </p>
          </BootstrapModal.Body>
          <BootstrapModal.Footer className="modal-footer-justified">
            <Button
              variant="secondary"
              onClick={() => {
                resetForm();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={(values: any) => handleSubmit(values)}>
              Submit
            </Button>
          </BootstrapModal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
