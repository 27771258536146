import {
  ApiResponse,
  Transfer,
  GenericError,
  CashieringEntityChangeRequest,
  CashieringDetailTransfer,
  CashieringEntityRequest,
} from '@tradingblock/types';
import { buildGenericRequestReceiveActions, actionBuilder } from '../../actionUtilities';
import { AdminIdRequest } from '.';

interface AcctGetRequest extends AdminIdRequest {
  accountId: number;
}

const ADMIN_CASHIERING_TRANSFER_REQUEST = '@admin/cashiering/transfer/REQUEST';
const ADMIN_CASHIERING_TRANSFER_RECEIVE = '@admin/cashiering/transfer/RECEIVE';
const ADMIN_CASHIERING_TRANSFER_ERROR = '@admin/cashiering/transfer/ERROR';

const ADMIN_CASHIERING_TRANSFER_DETAIL_REQUEST = '@admin/cashiering/transfer/DETAIL/REQUEST';
const ADMIN_CASHIERING_TRANSFER_DETAIL_RECEIVE = '@admin/cashiering/transfer/DETAIL/RECEIVE';
const ADMIN_CASHIERING_TRANSFER_DETAIL_ERROR = '@admin/cashiering/transfer/DETAIL/ERROR';

const ADMIN_CASHIERING_TRANSFER_APPROVE_REP_REQUEST = '@admin/cashiering/transfer/APPROVE_REP/REQUEST';
const ADMIN_CASHIERING_TRANSFER_APPROVE_REP_RECEIVE = '@admin/cashiering/transfer/APPROVE_REP/RECEIVE';
const ADMIN_CASHIERING_TRANSFER_APPROVE_REP_ERROR = '@admin/cashiering/transfer/APPROVE_REP/ERROR';

const ADMIN_CASHIERING_TRANSFER_APPROVE_FIRM_REQUEST = '@admin/cashiering/transfer/APPROVE_FIRM/REQUEST';
const ADMIN_CASHIERING_TRANSFER_APPROVE_FIRM_RECEIVE = '@admin/cashiering/transfer/APPROVE_FIRM/RECEIVE';
const ADMIN_CASHIERING_TRANSFER_APPROVE_FIRM_ERROR = '@admin/cashiering/transfer/APPROVE_FIRM/ERROR';

const ADMIN_CASHIERING_TRANSFER_REJECT_REQUEST = '@admin/cashiering/transfer/REJECT/REQUEST';
const ADMIN_CASHIERING_TRANSFER_REJECT_RECEIVE = '@admin/cashiering/transfer/REJECT/RECEIVE';
const ADMIN_CASHIERING_TRANSFER_REJECT_ERROR = '@admin/cashiering/transfer/REJECT/ERROR';

const ADMIN_CASHIERING_TRANSFER_CANCEL_REQUEST = '@admin/cashiering/transfer/CANCEL/REQUEST';
const ADMIN_CASHIERING_TRANSFER_CANCEL_RECEIVE = '@admin/cashiering/transfer/CANCEL/RECEIVE';
const ADMIN_CASHIERING_TRANSFER_CANCEL_ERROR = '@admin/cashiering/transfer/CANCEL/ERROR';

const ADMIN_CASHIERING_TRANSFER_UPDATE_REQUEST = '@admin/cashiering/transfer/UPDATE/REQUEST';
const ADMIN_CASHIERING_TRANSFER_UPDATE_RECEIVE = '@admin/cashiering/transfer/UPDATE/RECEIVE';
const ADMIN_CASHIERING_TRANSFER_UPDATE_ERROR = '@admin/cashiering/transfer/UPDATE/ERROR';

const [
  cashieringTransferRequest,
  cashieringTransferReceive,
  cashieringTransferError,
] = buildGenericRequestReceiveActions({
  REQUEST: ADMIN_CASHIERING_TRANSFER_REQUEST,
  RECEIVE: ADMIN_CASHIERING_TRANSFER_RECEIVE,
  ERROR: ADMIN_CASHIERING_TRANSFER_ERROR,
})<AcctGetRequest, ApiResponse<Transfer>, GenericError<any, AdminIdRequest>>();
const [
  cashieringTransferDetailRequest,
  cashieringTransferDetailReceive,
  cashieringTransferDetailError,
] = buildGenericRequestReceiveActions({
  REQUEST: ADMIN_CASHIERING_TRANSFER_DETAIL_REQUEST,
  RECEIVE: ADMIN_CASHIERING_TRANSFER_DETAIL_RECEIVE,
  ERROR: ADMIN_CASHIERING_TRANSFER_DETAIL_ERROR,
})<CashieringEntityRequest, ApiResponse<CashieringDetailTransfer>, GenericError<any, AdminIdRequest>>();
const [approveRepRequest, approveRepReceive, approveRepError] = actionBuilder<AcctGetRequest, Transfer>()(
  ADMIN_CASHIERING_TRANSFER_APPROVE_REP_REQUEST,
  ADMIN_CASHIERING_TRANSFER_APPROVE_REP_RECEIVE,
  ADMIN_CASHIERING_TRANSFER_APPROVE_REP_ERROR
);
const [approveFirmRequest, approveFirmReceive, approveFirmError] = actionBuilder<AcctGetRequest, Transfer>()(
  ADMIN_CASHIERING_TRANSFER_APPROVE_FIRM_REQUEST,
  ADMIN_CASHIERING_TRANSFER_APPROVE_FIRM_RECEIVE,
  ADMIN_CASHIERING_TRANSFER_APPROVE_FIRM_ERROR
);
const [rejectTransferRequest, rejectTransferReceive, rejectTransferError] = actionBuilder<
  CashieringEntityChangeRequest,
  Transfer
>()(
  ADMIN_CASHIERING_TRANSFER_REJECT_REQUEST,
  ADMIN_CASHIERING_TRANSFER_REJECT_RECEIVE,
  ADMIN_CASHIERING_TRANSFER_REJECT_ERROR
);
const [cancelTransferRequest, cancelTransferReceive, cancelTransferError] = actionBuilder<
  CashieringEntityChangeRequest,
  Transfer
>()(
  ADMIN_CASHIERING_TRANSFER_CANCEL_REQUEST,
  ADMIN_CASHIERING_TRANSFER_CANCEL_RECEIVE,
  ADMIN_CASHIERING_TRANSFER_CANCEL_ERROR
);
const [updateTransferRequest, updateTransferReceive, updateTransferError] = actionBuilder<
  CashieringEntityChangeRequest & { note?: string; adminNote?: string; alertRep?: boolean },
  Transfer
>()(
  ADMIN_CASHIERING_TRANSFER_UPDATE_REQUEST,
  ADMIN_CASHIERING_TRANSFER_UPDATE_RECEIVE,
  ADMIN_CASHIERING_TRANSFER_UPDATE_ERROR
);

export const AdminCashieringTransferActions = {
  cashieringTransferRequest,
  cashieringTransferReceive,
  cashieringTransferError,
  cashieringTransferDetailRequest,
  cashieringTransferDetailReceive,
  cashieringTransferDetailError,
  approveRepRequest,
  approveRepReceive,
  approveRepError,
  approveFirmRequest,
  approveFirmReceive,
  approveFirmError,
  rejectTransferRequest,
  rejectTransferReceive,
  rejectTransferError,
  cancelTransferRequest,
  cancelTransferReceive,
  cancelTransferError,
  updateTransferRequest,
  updateTransferReceive,
  updateTransferError,
};
