import { AssetType, SizeRatio } from '@tradingblock/types';
import _ from 'lodash';
export var validateSpreadRatio = function validateSpreadRatio(sch) {
  return function (data) {
    var ratios = _.map(data, function (l) {
      return l.SpreadRatio;
    });

    var shareRatio = _.first(data.filter(function (l) {
      return l.AssetType === AssetType.Equity;
    }).map(function (l) {
      return l.SpreadRatio;
    }));

    var optionRatios = data.filter(function (l) {
      return l.AssetType === AssetType.Option;
    }).map(function (l) {
      return l.SpreadRatio;
    });

    var getError = function getError(message) {
      return {
        keyword: 'spreadratio',
        message: message,
        params: {
          shareRatio: shareRatio,
          optionRatios: optionRatios
        }
      };
    };

    if (sch === SizeRatio.HundredToOneToOne || sch === SizeRatio.HundredToOne) {
      if (!shareRatio) {
        return getError('Order must have share leg');
      }

      if (optionRatios.length < 1) {
        return getError('Order must have option leg');
      }

      var optRatio = _.max(optionRatios);

      if (shareRatio / optRatio !== 100) {
        return getError("Share ratio/Option ratio isnt 100:1 - ".concat(shareRatio / optRatio));
      }
    } else if ([SizeRatio.OneToOne, SizeRatio.OneToOneToOneToOne].includes(sch)) {
      if (_.uniq(ratios).length !== 1) {
        return getError('Ratios are not 1:1');
      }
    } else if (sch === SizeRatio.OneTwoOne) {
      if (_.uniq(ratios).length !== 2) {
        return getError('There must only be 2 unique ratios');
      }

      if (_.max(ratios) / _.min(ratios) !== 2) {
        return getError('Ratios must be 2:1');
      }
    }

    return null;
  };
};