import { createStandardAction, ActionType } from 'typesafe-actions';
import { ExtendedExpiration } from '@tradingblock/types';
import { BlockAction } from '../../../components/blocks/BlockState';
import { Savable } from '../../../utilities/action';

const RESET_EXPIRATIONS = '@optionchain/resetExpirations';
const SET_EXPANDED_EXPIRATIONS = '@optionchain/setExpandedExpirations';
export const OptionChainActionTypes = {
  setExpandedExpirations: SET_EXPANDED_EXPIRATIONS,
  resetExpirations: RESET_EXPIRATIONS,
};

export const OptionChainActions = {
  ...BlockAction,
  resetExpirations: createStandardAction(RESET_EXPIRATIONS)<Savable<{}>>(),
  setExpandedExpirations: createStandardAction(SET_EXPANDED_EXPIRATIONS)<{
    expirations: ExtendedExpiration[] | null;
  }>(),
};

export type OptionChainAction = ActionType<typeof OptionChainActions>;
