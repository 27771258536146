import {
  TradeGoalType,
  RiskLevel,
  InvestmentObjectiveType,
  InvestmentObjectiveDescription,
  YearsEmployedDescription,
  BooleanToggleType,
  TradingAuthorization,
} from '@tradingblock/types';

export const formatIncome = (incomeDescription: string) => {
  let displayString: string = '';
  switch (incomeDescription) {
    case 'Between100KAnd200K':
      displayString = '$100,000 - $200,000';
      break;
    case 'Between200KAnd300K':
      displayString = '$200,000 - $300,000';
      break;
    case 'Between25KAnd50K':
      displayString = '$25,000 - $50,000';
      break;
    case 'Between300KAnd500K':
      displayString = '$300,000 - $500,000';
      break;
    case 'Between500KAnd1Point2M':
      displayString = '$500,000 - $1,200,000';
      break;
    case 'Between50KAnd100K':
      displayString = '$50,000 - $100,000';
      break;
    case 'LessThan25K':
      displayString = 'Less than $25,000';
      break;
    case 'MoreThan1Point2M':
      displayString = 'More than $1,200,000';
      break;
    case 'Between1MAnd5M':
      displayString = '$1,000,000 - $5,000,000';
      break;
    case 'Between200KAnd500K':
      displayString = '$200,000 - $500,000';
      break;
    case 'Between500KAnd1M':
      displayString = '$500,000 - $1,000,000';
      break;
    case 'LessThan50K':
      displayString = 'Less than $50,000';
      break;
    case 'MoreThan5M':
      displayString = 'More than $5,000,000';
      break;
    default:
      displayString = 'N/A';
      break;
  }
  return displayString;
};

export const formatTaxBracket = (bracket: string) => {
  let displayString: string = '';
  switch (bracket) {
    case 'Bottom':
      displayString = '10-19%';
      break;
    case 'Middle':
      displayString = '20-29%';
      break;
    case 'Top':
      displayString = '30-37%';
      break;
    default:
      displayString = 'N/A';
      break;
  }
  return displayString;
};

export const getRiskLevelForTradeGoalType = (type: TradeGoalType) => {
  switch (type) {
    case TradeGoalType.StocksBonds:
      return RiskLevel.None;
    case TradeGoalType.AllAbovePlusCallsPuts:
      return RiskLevel.VeryLow;
    case TradeGoalType.AllAbovePlusOptionSpreads:
      return RiskLevel.Low;
    case TradeGoalType.AllAbovePlusPutWriting:
      return RiskLevel.Moderate;
    case TradeGoalType.AllAbovePlusAllOptionStrategies:
      return RiskLevel.High;
    default:
      throw new Error('Unknown TradeGoalType: ' + type);
  }
};

export const getRiskLevelForInvestmentObjectiveType = (type: InvestmentObjectiveType) => {
  switch (type) {
    case InvestmentObjectiveType.CapitalPreservation:
    case InvestmentObjectiveType.Income:
    case InvestmentObjectiveType.Growth:
    case InvestmentObjectiveType.GrowthAndIncome:
      return RiskLevel.Moderate;
    case InvestmentObjectiveType.Speculation:
      return RiskLevel.High;
    default:
      throw new Error('Unknown InvestmentObjectiveType: ' + type);
  }
};

export const getDescriptionForInvestmentObjective = (type: InvestmentObjectiveType) => {
  switch (type) {
    case InvestmentObjectiveType.CapitalPreservation:
      return InvestmentObjectiveDescription.CapitalPreservation;
    case InvestmentObjectiveType.Income:
      return InvestmentObjectiveDescription.Income;
    case InvestmentObjectiveType.Growth:
      return InvestmentObjectiveDescription.Growth;
    case InvestmentObjectiveType.GrowthAndIncome:
      return InvestmentObjectiveDescription.GrowthAndIncome;
    case InvestmentObjectiveType.Speculation:
      return InvestmentObjectiveDescription.Speculation;
    default:
      throw new Error('Unknown InvestmentObjectiveType: ' + type);
  }
};

export const formatYearsEmployed = (type: string) => {
  switch (type) {
    case 'BetweenZeroAndOne':
      return YearsEmployedDescription.BetweenZeroAndOne;
    case 'BetweenTwoAndFive':
      return YearsEmployedDescription.BetweenTwoAndFive;
    case 'BetweenFiveAndTen':
      return YearsEmployedDescription.BetweenFiveAndTen;
    case 'BetweenTenAndTwenty':
      return YearsEmployedDescription.BetweenTenAndTwenty;
    case 'MoreThanTwenty':
      return YearsEmployedDescription.MoreThanTwenty;
    default:
      throw new Error('Unknown YearsEmployed: ' + type);
  }
};

export const formatYearsTrading = (type: string) => {
  switch (type) {
    case 'Zero':
      return 'Zero';
    case 'One':
      return 'One';
    case 'TwoToThree':
      return '2 - 3 years';
    case 'FourToNine':
      return '4 - 9 years';
    case 'TenOrMore':
      return '10+ years';
    default:
      throw new Error('Unknown YearsTrading: ' + type);
  }
};

export const formatTradesPerYear = (type: string) => {
  switch (type) {
    case 'OneToFive':
      return '1 - 5 trades/year';
    case 'SixToTen':
      return '6 - 10 trades/year';
    case 'ElevenToTwenty':
      return '11 - 20 trades/year';
    case 'MoreThanTwenty':
      return '20+ trades/year';
    default:
      throw new Error('Unknown TradesPerYear: ' + type);
  }
};

// formats BooleanToggleType to regular Boolean
export const formatBoolean = (booleanItem: BooleanToggleType | undefined) => {
  if (booleanItem === 'yes') {
    return true;
  } else {
    return false;
  }
};

export const formatBooleanForDisplay = (booleanItem: boolean) => {
  if (booleanItem) {
    return 'Yes';
  } else return 'No';
};

// formats phone numbers into standard US format
export const formatPhoneNumber = (value: string | undefined) => {
  if (!value) return value;

  if (value === 'N/A') return value;

  const phoneNumber = value.replace(/[^\d]/g, '');

  if (phoneNumber.length < 4) return phoneNumber;

  if (phoneNumber.length < 7) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
  }

  if (phoneNumber.length <= 10) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
  }

  if (phoneNumber.length === 11) {
    return `${phoneNumber.slice(0, 1)}-${phoneNumber.slice(1, 4)}-${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7)}`;
  }

  return `${phoneNumber.slice(0, 2)}-${phoneNumber.slice(2, 6)}-${phoneNumber.slice(6)}`;
};

// Display agent name or not applicable
export const formatAgentName = (name: string | null, auth: boolean) => {
  if (auth) {
    return name;
  } else {
    return 'Not Applicable';
  }
};

// Display firm name or not applicable
export const formatIndustryEmployed = (firmName: string | null) => {
  if (firmName === null) {
    return 'Not Applicable';
  }
  return firmName;
};

// Display market data entitlement as string or not applicable
export const formatMarketDataEntitlement = (entitlement: number | null) => {
  if (entitlement === null) {
    return 'Not Applicable';
  }
  if (entitlement === 0) {
    return 'No entitlement data found...';
  }
  if (entitlement === 1) {
    return 'Professional User';
  }
  if (entitlement === 2) {
    return 'Non-Professional User';
  }
  if (entitlement === 3) {
    return 'Delayed Quotes Only User';
  }
};

export const getAgentPhoneNumbersByType = (type: string, tradingAuthorization: TradingAuthorization) => {
  const phone = tradingAuthorization.phones ? tradingAuthorization.phones.filter(phone => phone.type === type) : [];
  if (phone.length > 0) {
    return phone[0].phoneNumber;
  }
  return 'N/A';
};
