import React, { useMemo } from 'react';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { transferSelectors } from '../../../../data/global/selectors/admin/cashieringSelectors';
import { CashieringEntityStatus } from '../../../../components/cashiering/CashieringEntityStatus';
import { CashieringEntityAuditItems } from './details/CashieringEntityAuditItems';

export const CashieringTransferStatus: React.FunctionComponent<{ id: number; accountId: number }> = ({ id }) => {
  const transfer = useStateSelector(s => transferSelectors.data(s, id));

  const transferState = useMemo(() => {
    return {
      state: transfer && transfer.state,
      rejectReason: transfer && transfer.state === 'Rejected' && transfer.rejectReason,
    };
  }, [transfer]);

  if (!transfer) {
    return null;
  }

  return (
    <div className="screen-body-section">
      <p className="fields-title">Review status</p>

      <p>
        {transferState.state && (
          <strong>
            <CashieringEntityStatus status={transferState.state} />
          </strong>
        )}
        <br />

        {transferState.rejectReason && <span className="neg">Reject reason: {transferState.rejectReason}</span>}
      </p>

      <CashieringEntityAuditItems id={id} type="transfers" />
    </div>
  );
};
