import { ColumnWithSorting, EmptyBlockContent, Loading, Table } from '@tradingblock/components';
import React, { useEffect, useMemo } from 'react';
import { BlockTable } from '../../../shared/BlockTable';
import { useApi } from '../../../../context/Api';
import { useDispatch } from 'react-redux';
import { AccountManagementDataActions } from '../../../../data/global/actions/AccountManagementActions';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import dayjs from 'dayjs';

export const AuthHistoryTable: React.FunctionComponent<{}> = ({}) => {
  // State variables

  // Hooks for API and Redux
  const api = useApi();
  const dispatch = useDispatch();

  // Selectors for Redux state
  const username = useStateSelector(s => s.auth.userName);
  const authHistory = useStateSelector(s => s.accountManagement.authHistory.data);
  const isFetching = useStateSelector(s => s.accountManagement.authHistory.isFetching);

  // Function to fetch the authentication history
  const fetchAuthHistory = () => {
    dispatch(AccountManagementDataActions.requestAuthHistory({ username }));
  };

  // Fetch the authentication history on component mount
  useEffect(() => {
    fetchAuthHistory();
  }, []);

  // Define the columns for the authentication history table
  const AuthHistoryColumns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: (a: any) => a.TimeStamp,
        Cell: (row: any) => {
          return dayjs(row.value + 'Z').format('MM/DD/YYYY');
        },
      },
      {
        Header: 'Time',
        accessor: (a: any) => a.TimeStamp,
        Cell: (row: any) => {
          return dayjs(row.value + 'Z').format('h:mm:ss A');
        },
      },
      {
        Header: 'IP Address',
        accessor: 'ipAddress',
        Cell: (row: any) => {
          // if the ip is null/undefined return fontawesome ban icon
          if (!row.value) {
            return <i className="fas fa-ban mute" />;
          } else {
            return row.value;
          }
        },
      },
    ],
    []
  );

  const initialState = useMemo(() => {
    return {
      pageSize: 10,
      pageIndex: 0,
      sortBy: [],
      columnOrder: [],
    };
  }, []);

  // Return the authentication history table
  return (
    <>
      {isFetching && (
        <div className="container-fluid">
          <div className="row" style={{ alignItems: 'baseline' }}>
            <i className="fal fa-history fa-lg mr-2 dashboard-icon" /> <h5>Log-in History</h5>
          </div>
          <div className="row">
            <Loading size="medium" /> <span className="mute ml-2">Loading...</span>
          </div>
        </div>
      )}
      {!isFetching && authHistory && authHistory.length === 0 && <EmptyBlockContent />}
      {!isFetching && authHistory && authHistory.length > 0 && (
        <div className="container-fluid" style={{ height: '100%' }}>
          <div className="row" style={{ alignItems: 'baseline' }}>
            <i className="fal fa-history fa-lg mr-2 dashboard-icon" />
            <h5>Log-in History</h5>
          </div>
          <div className="row">
            <Table
              tableKey="auth-history"
              numColumns={AuthHistoryColumns.length}
              columns={AuthHistoryColumns}
              data={authHistory}
              columnWidths={['33%', '33%', '33%']}
              hasPagination={true}
              initialState={initialState}
              responsiveDisabled={false}
              sortable={true}
              manualPagination={false}
              manualSortBy={false}
              className="security-table"
            />
          </div>
          <div className="row mt-2">
            <p className="mute txt-sm">The log-in history table displays the last 50 successful log-in attempts.</p>
          </div>
        </div>
      )}
    </>
  );
};
