import { combineReducers } from 'redux';
import { AccountDataState } from './state';
import { balances } from './reducers/balances';
import { accountsBalances } from './reducers/accountsBalances';
import { subAccountsBalances } from './reducers/subAccountBalances';

export const AccountDataReducer = combineReducers<AccountDataState>({
  balances,
  accountsBalances,
  subAccountsBalances,
});
