import { createStandardAction, ActionType } from 'typesafe-actions';
import { GenericError } from '@tradingblock/types';

const SET_PAGE = '@application/page/SET';
const GLOBAL_EXCEPTION = '@application/EXCEPTION';

export const PageActions = {
  set: createStandardAction(SET_PAGE)<{ page: string; access: string }>(),
  globalException: createStandardAction(GLOBAL_EXCEPTION)<GenericError>(),
};

export type PageActionsType = ActionType<typeof PageActions>;
