import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Cell } from 'react-table';
import { throttle } from 'lodash';
import { useApi } from '../../../context/Api';
import { AccountStatementsActions } from '../../../data/global/actions/AccountStatementsActions';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { accountStatementsSelectors } from '../../../data/global/selectors/accountStatementsSelectors';
import { BlockTable } from '../../shared/BlockTable';
import YearPicker from '../../../components/basic/YearPicker';
import { SessionActions } from '../../../data/global/actions';
import { GenericErrorResponseCode } from '@tradingblock/types';
import { DownloadComponent } from '../components/DownloadComponent';

export interface AccountStatementsDailyConfirmsProps {}

export default function AccountStatementsMonthlyStatements() {
  const dispatch = useDispatch();
  const api = useApi();
  const accountId = useStateSelector(s => s.account.accountId);
  const selectedAccount = useStateSelector(s => s.account.selectedLinkedAccount);
  const [timeframe, setTimeframe] = useState<{
    startDate: Date;
    endDate: Date;
  }>({
    startDate: new Date(`${new Date().getFullYear()}-01-01T00:00:00`),
    endDate: new Date(`${new Date().getFullYear()}-12-31T00:00:00`),
  });
  const monthlyStatements = useStateSelector(state => accountStatementsSelectors.monthlyStatements(state, timeframe));
  const formattedMonthlyStatements = useMemo(() => {
    if (monthlyStatements) {
      return monthlyStatements
        .sort((a, b) => (new Date(a.date) < new Date(b.date) ? 1 : -1))
        .map(document => ({
          received_on: new Date(document.date),
          message: `Monthly Statement for ${dayjs(document.date).format('MM/DD/YY')}`,
        }));
    } else {
      return [];
    }
  }, [monthlyStatements]);

  const requestMonthlyAccountStatementsThrottleCallback = useCallback(
    throttle((timeframe, accountId) => {
      dispatch(
        AccountStatementsActions.requestMonthlyAccountStatements({
          accountId: selectedAccount ? selectedAccount.accountId : accountId,
          requestedStartDate: timeframe.startDate,
          requestedEndDate: timeframe.endDate > new Date() ? new Date() : timeframe.endDate,
        })
      );
    }, 2000),
    [dispatch, selectedAccount]
  );

  useEffect(() => {
    requestMonthlyAccountStatementsThrottleCallback(timeframe, accountId);
  }, [timeframe, accountId, selectedAccount]);

  const columns = useMemo(
    () => [
      {
        Header: 'Statement',
        canSort: false,
        accessor: (a: { received_on: Date; message: string }) => a.message,
        id: 'message',
        Cell: ({
          row,
        }: Cell<{
          received_on: Date;
          message: string;
        }>) => row.original.message,
      },
      {
        Header: 'View',
        accessor: (a: { received_on: Date; message: string }) => dayjs(a.received_on).format('MM/DD/YY h:mm A'),
        id: 'received_on',
        canSort: true,
        sortType: 'basic',
        Cell: ({
          row,
        }: Cell<{
          received_on: Date;
          message: string;
        }>) => (
          <i
            style={{
              cursor: 'pointer',
            }}
            className="fal fa-lg fa-search dashboard-icon"
            onClick={() => {
              api.downloads
                .getMonthlyStatement(accountId, row.original.received_on)
                .then((result: any) => {
                  let file = new Blob([result.data], { type: 'application/pdf' });
                  let fileURL = URL.createObjectURL(file);
                  window.open(fileURL);
                })
                .catch(err => {
                  dispatch(
                    SessionActions.error({
                      code: GenericErrorResponseCode,
                      url: err.config.url,
                      message: 'Failed to download monthly statement. Please try again later.',
                      reason: 'Download Error',
                    })
                  );
                });
            }}
          />
        ),
      },
      {
        Header: 'Download',
        accessor: (a: { received_on: Date; message: string }) => dayjs(a.received_on).format('MM/DD/YY h:mm A'),
        id: 'download',
        canSort: true,
        sortType: 'basic',
        Cell: ({
          row,
        }: Cell<{
          received_on: Date;
          message: string;
        }>) => (
          <>
            <DownloadComponent
              row={row}
              accountId={accountId}
              api={api}
              dispatch={dispatch}
              SessionActions={SessionActions}
              GenericErrorResponseCode={GenericErrorResponseCode}
              type="statement"
            />
          </>
        ),
      },
    ],
    [accountId]
  );

  return (
    <>
      <div className="block-section block-section-fixed">
        <div className="row justify-content-between">
          <div className="col-auto">
            <YearPicker
              value={timeframe.startDate.getFullYear()}
              onChange={year => {
                setTimeframe({
                  startDate: new Date(`${year}-01-01T00:00:00`),
                  endDate: new Date(`${year}-12-31T00:00:00`),
                });
              }}
            />
          </div>
        </div>
      </div>
      <BlockTable
        tableKey="orders"
        columnWidths={['80%', '10%', '10%']}
        columns={columns}
        data={formattedMonthlyStatements}
        // loaded={isLoaded}
        sortable
        // rowClass={shouldBeHighlighted}
        // getRowKey={getRowKey}
        frozenHeaders
      />
    </>
  );
}
