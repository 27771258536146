import { useMemo } from 'react';
import { AdminAccountManagementSearchActions } from '../../../data/global/actions/admin/AccountManagementActions';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { useDispatcher } from '../../../data/global/hooks';
import {
  getAccountUpdateSearchEntities,
  getAccountManagementSearchFilterdata,
  getAccountManagementSearchTotal,
} from '../../../data/global/selectors/admin/accountManagementSelector';
import { AccountManagementEntityType, UpdateState } from '@tradingblock/types';

export const useAccountManagementData = () => {
  const data = useStateSelector(getAccountUpdateSearchEntities);
  const total = useStateSelector(getAccountManagementSearchTotal);

  const { startDate, endDate, ...filterData } = useStateSelector(getAccountManagementSearchFilterdata);

  return {
    data,
    total,
    ...filterData,
    timeframe: { startDate, endDate },
  };
};

export const useAccountManagementActions = () => {
  const { dispatch } = useDispatcher();
  return useMemo(
    () => ({
      search: (search: string) => dispatch(AdminAccountManagementSearchActions.setSearch({ search })),
      setTimeframe: (startDate: Date | undefined, endDate: Date | undefined) =>
        dispatch(AdminAccountManagementSearchActions.setTimeframe({ startDate, endDate })),
      setType: (items: AccountManagementEntityType[]) =>
        dispatch(AdminAccountManagementSearchActions.setType({ items })),
      setStatus: (states: UpdateState[]) => dispatch(AdminAccountManagementSearchActions.setStatus({ states })),
    }),
    [dispatch]
  );
};
