import * as tslib_1 from "tslib";
import React from 'react';
import Slider from 'react-slick';
export var Carousel = function Carousel(_a) {
  var settings = tslib_1.__rest(_a, []);

  return React.createElement(Slider, Object.assign({}, settings));
};
export var CarouselResponsiveOptions = [{
  breakpoint: 1024,
  settings: {
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: true,
    dots: true
  }
}, {
  breakpoint: 600,
  settings: {
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 2
  }
}, {
  breakpoint: 480,
  settings: {
    slidesToShow: 1,
    slidesToScroll: 1
  }
}];