import useMount from 'react-use/lib/useMount';
import { createSelector } from 'reselect';
import { DashboardConfiguration } from '@tradingblock/types';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { DataState } from '../../../data/global/state';
import { useDispatcher } from '../../../data/global/hooks';
import { getDashboardPresetsForRepCode } from '../../../utilities/dashboard';
import { dashboardSettingsSelector } from '../../../hooks/useDashboardSettings';

const defaultDashboardsSelector = createSelector(
  (s: DataState) => s.settings.defaultDashboards,
  (s: DataState) => dashboardSettingsSelector(s).columns.lg,
  (s: DataState) => s.environment.isVirtual,
  (s: DataState) => s.settings.ui.breakpoint,
  (defState, lgColumns, isVirtual, breakpoint) => {
    const dashValues = defState.dashboards || [];
    const dashboards = getDashboardPresetsForRepCode('', lgColumns, dashValues, isVirtual, breakpoint);
    return {
      dashboards,
      isFetching: defState.isFetching,
    };
  }
);

export const useDefaultDashboards = (): [DashboardConfiguration[], boolean] => {
  const { dashboards, isFetching } = useStateSelector(defaultDashboardsSelector);

  const { dispatcher } = useDispatcher();
  useMount(() => {
    if (isFetching || (dashboards && dashboards.length > 0)) {
      return;
    } else {
      dispatcher.settings.requestDefaultDashboards();
    }
  });
  return [dashboards, isFetching];
};
