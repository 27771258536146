import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { DetailField, DetailFields } from '../../../../components/basic/DetailFields';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { wireInstructionSelectors } from '../../../../data/global/selectors/admin/cashieringSelectors';
import { formatDate } from '../../../../utilities/date';
import { CashieringDocReview } from './CashieringDocReview';
import { CustomerAccountDetails } from './CustomerAccountDetails';

export const CashieringTransferInstructionDetails: React.FunctionComponent<{
  id: number;
  accountId: number;
  isFetching: boolean;
}> = ({ id, accountId, isFetching }) => {
  const wireInstruction = useStateSelector(s => wireInstructionSelectors.data(s, id));
  const isFetchingDetails = useStateSelector(
    s => s.private.cashiering.wireInstructionsDetails[id] && s.private.cashiering.wireInstructionsDetails[id].isFetching
  );

  const fields = useMemo(() => {
    let fieldList: DetailField[] = [];
    if (wireInstruction) {
      fieldList = [
        ...fieldList,
        {
          Value: () => <>Wire Instruction Information</>,
          className: 'fields-title',
        },
        {
          Value: () => null,
        },
        {
          Value: () => <>Bank Account Number</>,
          className: 'prop-title',
        },
        {
          Value: () => <>Bank Name</>,
          className: 'prop-title',
        },
        {
          Value: () => <span className="mute">{wireInstruction.account}</span>,
        },
        {
          Value: () => <span className="mute">{wireInstruction.name}</span>,
        },
        {
          Value: () => <>ABA/Routing Number</>,
          className: 'prop-title',
        },
        {
          Value: () => <>Nickname</>,
          className: 'prop-title',
        },
        {
          Value: () => <span className="mute">{wireInstruction.recipientBank.identifier}</span>,
        },
        {
          Value: () => <span className="mute">{wireInstruction.nickName}</span>,
        },
        {
          Value: () => <span>Identifier Type</span>,
        },
        {
          Value: () => <>Create Date</>,
          className: 'prop-title',
        },
        {
          Value: () => <span className="mute">{wireInstruction.recipientBank.identifierType}</span>,
        },
        {
          Value: () => <span className="mute">{formatDate(dayjs(wireInstruction.createDate))}</span>,
        },
        {
          Value: () => null,
        },
        {
          Value: () => <>Intermediary Information</>,
          className: 'fields-title',
          hide: !wireInstruction.intermediary,
        },
        {
          Value: () => null,
          hide: !wireInstruction.intermediary,
        },
        {
          Value: () => <>Bank Account Number</>,
          className: 'prop-title',
          hide: !wireInstruction.intermediary,
        },

        {
          Value: () => <>Address</>,
          className: 'prop-title',
          hide: !wireInstruction.intermediary,
        },
        {
          Value: () => (
            <span className="mute">{wireInstruction.intermediary && wireInstruction.intermediary.account}</span>
          ),
          hide: !wireInstruction.intermediary,
        },
        {
          Value: () => (
            <span className="mute">
              {wireInstruction.intermediary && (
                <>
                  {wireInstruction.intermediary.streetAddress1}
                  {wireInstruction.intermediary.streetAddress2 && (
                    <>
                      <br />
                      {wireInstruction.intermediary.streetAddress2}
                    </>
                  )}
                  <br />
                  {wireInstruction.intermediary.city}, {wireInstruction.intermediary.stateProvince}{' '}
                  {wireInstruction.intermediary.postalCode}
                  <br />
                  {wireInstruction.intermediary.country}
                </>
              )}
            </span>
          ),
          hide: !wireInstruction.intermediary,
        },
      ];
    }
    return fieldList;
  }, [wireInstruction]);

  return (
    <>
      {(isFetching || isFetchingDetails) && <div className="screen-body-section">Loading...</div>}
      {!isFetching && !isFetchingDetails && (
        <>
          <div className="screen-body-section">
            <p className="fields-title">Add Wire Instructions Request</p>
          </div>

          {!isFetching && (
            <>
              <CustomerAccountDetails accountId={accountId} />
              <div className="screen-body-section">
                <DetailFields fieldList={fields} />
              </div>
              {id && (
                <div className="screen-body-section">
                  <CashieringDocReview id={id} accountId={accountId} />
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
