import React from 'react';
import { BlockType } from '@tradingblock/types';
import { Svg } from '../lib';
export var getBlockTypeName = function getBlockTypeName(type) {
  switch (type) {
    case BlockType.Account:
      return 'Balance';

    case BlockType.AccountTransfer:
      return 'Cash transfers';

    case BlockType.OptionChain:
      return 'Option chain';

    case BlockType.Order:
      return 'Order';

    case BlockType.PriceChart:
      return 'Chart';

    case BlockType.Strategy:
      return 'Strategies';

    case BlockType.Activity:
    case BlockType.Favorites:
    case BlockType.Positions:
    case BlockType.Quote:
      return type.toString();

    case BlockType.Info:
      return 'News & Notices';

    case BlockType.AccountManagement:
      return 'Account Management';

    case BlockType.AdminTools:
      return 'Admin Tools';

    case BlockType.Analytics:
      return 'History';

    case BlockType.AccountStatements:
      return 'Account Statements';

    case BlockType.SubAccountManagement:
      return 'Sub Account Management';

    default:
      throw new Error("getBlockTypeName :: Unknown BlockType: ".concat(type));
  }
};
export var getBlockTypeIcon = function getBlockTypeIcon(type) {
  switch (type) {
    case BlockType.Account:
      return 'fal fa-5x fa-user account-block-icon';

    case BlockType.AccountTransfer:
      return 'fal fa-5x fa-exchange exchange-block-icon';

    case BlockType.Activity:
      return 'fal fa-5x fa-th-list activity-block-icon';

    case BlockType.Favorites:
      return 'fal fa-5x fa-star favorites-block-icon';

    case BlockType.OptionChain:
      return 'fal fa-5x fa-table table-block-icon';

    case BlockType.Order:
      // custom TB icon
      return React.createElement("span", {
        className: "tb-icon tb-icon-5x tb-icon-stack tb-icon-clipboard"
      }, React.createElement("span", {
        className: "tb-icon tb-icon-stack-2x"
      }, React.createElement(Svg, {
        path: "icon-clipboard"
      })), React.createElement("span", {
        className: "tb-icon tb-icon-stack-1x"
      }, React.createElement(Svg, {
        path: "icon-list"
      })));

    case BlockType.Positions:
      return 'fal fa-5x fa-wallet wallet-block-icon';

    case BlockType.PriceChart:
      return 'fal fa-5x fa-chart-line priceChart-block-icon';

    case BlockType.Quote:
      return 'fal fa-5x fa-receipt quote-block-icon';

    case BlockType.Info:
      return 'fal fa-5x fa-newspaper info-block-icon';

    case BlockType.Strategy:
      return 'fal fa-5x fa-chess strategy-block-icon';

    case BlockType.AccountManagement:
      return 'fal fa-5x fa-user-circle accountManagement-block-icon';

    case BlockType.AdminTools:
      return 'fal fa-5x fa-tools adminTools-block-icon';

    case BlockType.Analytics:
      return 'fal fa-5x fa-analytics analytics-block-icon';

    case BlockType.AccountStatements:
      return 'fal fa-5x fa-file-user accountStatements-block-icon';

    case BlockType.SubAccountManagement:
      return 'fal fa-5x fa-list subAccountManagement-block-icon';

    default:
      throw new Error("getBlockTypeIcon :: Unknown BlockType: ".concat(type));
  }
};