import { useRef } from 'react';
import _ from 'lodash';
import {
  Legs,
  AssetSymbolUndefinedException,
  AssetSymbolInfo,
  OrderLeg,
  AssetType,
  OrderAction,
  PartialOptionLeg,
  PositionEffect,
  OptionType,
  OrderLegDTO,
  TradingStrategy,
  CustomTradingStrategy,
  OrderActionDirection,
  PositionInfo,
  AssetSymbol,
  Expiration,
  SymbolTypes,
  isCustomStrategy,
  TradingStrategyNameOrCustom,
  TradingStrategyOrCustom,
  OrderBlockSettings,
  DebitOrCredit,
  OrderLegDTOState,
} from '@tradingblock/types';
import {
  GetOCCSymbol,
  isIndexSymbol,
  AllStrategies,
  CustomStrategy,
  parseOccSymbol,
  toExpiration,
  Shares,
  Call,
  Put,
  isString,
} from '@tradingblock/api';
import { useDebug, newId } from '@tradingblock/components';
import { ClassifiedStrategy } from '../../data/global/utilities/strategyClassification';

export const getStrategiesForSymbol = (symbol?: string | AssetSymbolInfo) => {
  if (symbol === undefined) {
    return [];
  }
  const isIndex = isIndexSymbol(symbol);
  if (isIndex) {
    return _.filter(AllStrategies, s => {
      if (isCustomStrategy(s)) {
        return true;
      } else {
        const hasShareLegs = _.some(s.profile.Legs, l => l.AssetType === AssetType.Equity);
        return hasShareLegs === false;
      }
    });
  } else {
    return AllStrategies;
  }
};

export const defaultStrategyForSymbol = (
  symbol: string | AssetSymbolInfo | undefined,
  blockDefaultStrategy: TradingStrategyNameOrCustom | undefined,
  globalBlockTypeSettings?: OrderBlockSettings
) => {
  const defaultStrategy =
    blockDefaultStrategy || (globalBlockTypeSettings ? globalBlockTypeSettings.defaultStrategy : undefined);
  if (symbol && defaultStrategy) {
    const valid = getStrategiesForSymbol(symbol);
    const defaultIsValid = valid.find(vs => vs.info.Name === defaultStrategy);
    return defaultIsValid ? defaultStrategy : CustomStrategy.info.Name;
  }
  return CustomStrategy.info.Name;
};

const singleLegStrategies: TradingStrategyOrCustom[] = [Shares, Call, Put];

export const isSingleLegStrategy = (value: CustomTradingStrategy | TradingStrategy | TradingStrategyNameOrCustom) => {
  const name = isString(value) ? value : value.info.Name;
  return singleLegStrategies.map(s => s.info.Name).includes(name);
};

export function useOrderDebug(name: string) {
  const logRef = useRef(useDebug(`OrderBlock:${name}`));
  return logRef.current;
}

export function isCalendarStrategy(value?: TradingStrategy | CustomTradingStrategy | TradingStrategyNameOrCustom) {
  if (_.isNil(value)) {
    return false;
  }
  const strategyName = isString(value) ? value : value.info.Name;
  return ['Call Calendar', 'Put Calendar'].includes(strategyName);
}

/*
The OCC option symbol consists of four parts:

Root symbol of the underlying stock or ETF, padded with spaces to 6 characters
Expiration date, 6 digits in the format yymmdd
Option type, either P or C, for put or call
Strike price, as the price x 1000, front padded with 0s to 8 digits
Examples:[3]
SPX   141122P00019500
*/
export function SymbolForLeg<T extends PartialOptionLeg>(leg: T, assetSymbol: AssetSymbolInfo | undefined) {
  if (assetSymbol === undefined) {
    throw AssetSymbolUndefinedException;
  }
  if (leg && leg.OptionType !== undefined) {
    return GetOCCSymbol({
      underlyingSymbol: assetSymbol.symbol,
      symbolType: assetSymbol.type,
      expiration: leg.Expiration,
      optionType: leg.OptionType,
      strike: leg.Strike,
    });
  }
  if (assetSymbol.type === SymbolTypes.stock) {
    return assetSymbol.symbol;
  }
  const expiration = leg.Expiration;

  return GetOCCSymbol({
    underlyingSymbol: assetSymbol.symbol,
    symbolType: assetSymbol.type,
    expiration: expiration,
    optionType: leg.OptionType,
    strike: leg.Strike,
  });
}

export const DtoToOrderleg = (leg: OrderLegDTO, assetSymbol: AssetSymbolInfo | undefined): OrderLeg => {
  if (assetSymbol === undefined) {
    throw AssetSymbolUndefinedException;
  }
  if (assetSymbol.type === SymbolTypes.stock) {
    return {
      ...leg,
      Symbol: SymbolForLeg(leg, assetSymbol),
      OccSymbol: GetOCCSymbol({
        underlyingSymbol: assetSymbol.symbol,
        symbolType: assetSymbol.type,
        expiration: leg.Expiration,
        optionType: leg.OptionType,
        strike: leg.Strike,
        prefixSymbolName: true,
      }),
    };
  }
  const expiration = leg.Expiration;

  return {
    ...leg,
    Symbol: SymbolForLeg(leg, assetSymbol),
    OccSymbol: GetOCCSymbol({
      underlyingSymbol: assetSymbol.symbol,
      symbolType: assetSymbol.type,
      expiration: expiration,
      optionType: leg.OptionType,
      strike: leg.Strike,
      prefixSymbolName: true,
    }),
  };
};

export const legToDto = (leg: OrderLeg): OrderLegDTO => {
  const symbolInfo = parseOccSymbol(leg.Symbol);

  return {
    ...leg,
    Id: 'tempId',
    UnderlyingSymbol: symbolInfo.symbol,
    Expiration: symbolInfo.expiration ? toExpiration(symbolInfo.expiration, symbolInfo.symbol) : undefined,
    Strike: symbolInfo.strike,
    OptionType: symbolInfo.option,
  };
};

export const getNextLegId = (existingLegs: Legs) => {
  let id = _.uniqueId('leg_');
  while (_.keys(existingLegs).includes(id)) {
    id = _.uniqueId('leg_');
  }
  return id;
};

interface OrderOptions {
  assetType?: AssetType;
  optionType?: OptionType;
  orderAction?: OrderAction;
  quantity?: number;
  strike?: number;
  spreadRatio?: number;
  expiration?: Expiration;
}
export interface OrderLegCreationOptions extends OrderOptions {}
export const HasEquityLeg = (existingLegs: Legs) =>
  _.find(existingLegs, (v, k) => v.AssetType === AssetType.Equity) !== undefined;
export const HasOptionLeg = (existingLegs: Legs) =>
  _.find(existingLegs, (v, k) => v.AssetType === AssetType.Option) !== undefined;

export const BuildDefaultLeg = (
  existingLegs: Legs,
  assetSymbol?: AssetSymbolInfo | AssetSymbol,
  options?: OrderLegCreationOptions
) => {
  if (assetSymbol === undefined) {
    throw AssetSymbolUndefinedException;
  }
  const underlyingSymbol = assetSymbol.underlyingSymbol;
  const { assetType, optionType, orderAction, quantity, strike, expiration, spreadRatio } = (options ||
    {}) as OrderOptions;
  const orderId = getNextLegId(existingLegs);

  let assType = assetType ? assetType : HasEquityLeg(existingLegs) ? AssetType.Equity : AssetType.Option;
  if (underlyingSymbol ? isIndexSymbol(underlyingSymbol) : isIndexSymbol(assetSymbol)) {
    assType = AssetType.Option;
  }
  const legSpreadRatio = spreadRatio
    ? spreadRatio
    : HasOptionLeg(existingLegs) && assType === AssetType.Equity
    ? 100
    : 1;
  const optType = optionType || (assType === AssetType.Equity ? undefined : OptionType.Call);
  const defaultLeg: OrderLegDTO = {
    Id: orderId,
    UniqueId: newId(),
    UnderlyingSymbol: underlyingSymbol || assetSymbol.symbol,
    SpreadRatio: legSpreadRatio,
    AssetType: assType,
    OptionType: optType,
    Action: orderAction || OrderAction.Buy,
    PositionEffect: PositionEffect.Open,
    LegFillQuantity: quantity,
    Strike: strike,
    Expiration: expiration,
  };
  return defaultLeg;
};

export const DefaultLegs = (
  existingLegs: Legs,
  assetSymbol?: AssetSymbolInfo | AssetSymbol,
  options?: OrderLegCreationOptions
) => {
  const defaultLeg: OrderLegDTO = BuildDefaultLeg(existingLegs, assetSymbol, options);
  return { [defaultLeg.Id]: defaultLeg };
};

export const getPositionEffect = (legAction?: OrderAction, direction?: OrderActionDirection) => {
  if (direction === undefined || legAction === undefined) {
    return PositionEffect.Open;
  } else if (legAction === OrderAction.Buy) {
    return direction === OrderActionDirection.Long ? PositionEffect.Open : PositionEffect.Close;
  } else {
    return direction === OrderActionDirection.Long ? PositionEffect.Close : PositionEffect.Open;
  }
};

export function getLegPosition<T extends PartialOptionLeg>(
  positions: PositionInfo,
  leg: T,
  assetSymbol?: AssetSymbolInfo
) {
  if (assetSymbol === undefined) {
    throw AssetSymbolUndefinedException;
  }
  const symbol = SymbolForLeg(leg, assetSymbol);

  const { Action } = leg;
  const existingPosition = positions && symbol ? positions[symbol] : undefined;
  if (existingPosition === undefined) {
    return PositionEffect.Open;
  } else if (Action === OrderAction.Buy) {
    return existingPosition.direction === OrderActionDirection.Long ? PositionEffect.Open : PositionEffect.Close;
  } else {
    return existingPosition.direction === OrderActionDirection.Long ? PositionEffect.Close : PositionEffect.Open;
  }
}

export const determineIsBuy = (
  isCustomOrder: boolean,
  orderAction: OrderAction,
  isClassifiedAsCalendar: boolean,
  isStrategyMatch: boolean,
  classifiedStrategy: ClassifiedStrategy,
  isCustomWithoutClassifiedStrategy: boolean,
  debitCredit: DebitOrCredit | undefined,
  legs: OrderLegDTOState[],
  price?: number
) => {
  // Check if the order is not a custom order
  if (!isCustomOrder) {
    // If not a custom order, return true if orderAction is OrderAction.Buy
    return orderAction === OrderAction.Buy;
  }

  // Check if the strategy matches
  if (isStrategyMatch) {
    // If the strategy is classified as a calendar strategy, always return true
    if (isClassifiedAsCalendar) {
      return true;
    }
    // Otherwise, return true if the classified strategy action is 'BUY'
    return classifiedStrategy.action === 'BUY';
  }

  // Check if it's a custom order without a classified strategy
  if (isCustomWithoutClassifiedStrategy) {
    if (!price) {
      return true;
    }
    // if the debitCredit is Debit, return true if the price is positive
    if (debitCredit === DebitOrCredit.Debit) {
      return price > 0;
    }
    // if the debitCredit is Credit, return true if the price is negative
    return price < 0;
  }

  // Default case: check the legs array
  // Return true if every leg action is OrderAction.Buy or if some leg actions are OrderAction.Buy
  return _.every(legs, l => l.Action === OrderAction.Buy) || _.some(legs, l => l.Action === OrderAction.Buy);
};
