import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { OrderPreviewPopupProps } from './types';
import { IsVirtualEnvironment } from '../../../../data/global/selectors/environmentSelector';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { useDispatcher } from '../../../../data/global/hooks';
import { useOrderPreviewOrder } from './useOrderPreview';

export const OrderPreviewActions: React.FC<
  OrderPreviewPopupProps & {
    setOrderStatusProcessed: React.Dispatch<React.SetStateAction<boolean>>;
    disabled: boolean;
    onSave: () => void;
    isSaving: boolean;
  }
> = ({ previewClosed, onSave, disabled, isSaving, isCancelOrder, action, setOrderStatusProcessed }) => {
  const isVirtualEnv = useStateSelector(IsVirtualEnvironment);
  const btnClassName = useMemo(() => (isVirtualEnv ? 'btn-virtual' : `btn-${isCancelOrder ? 'danger' : 'primary'}`), [
    isVirtualEnv,
  ]);
  const { dispatcher } = useDispatcher();
  const { order } = useOrderPreviewOrder(action);
  const [isProcessing, setIsProcessing] = useState(false);
  const [complete, setIsComplete] = useState(false);

  // Ref to manage the actual processing state reliably across re-renders
  const isProcessingRef = useRef<boolean>(false);

  // Function to handle the order action, with processing logic
  const handleOrderAction = useCallback(
    order => {
      // Prevent further execution if already processing
      if (isProcessingRef.current) return;

      // Set processing state
      isProcessingRef.current = true;
      setIsProcessing(true);

      const processOrder = () => {
        if (complete && !isProcessing) {
          // Handle order completion
          previewClosed({ action: 'closed' });
        } else if (!isCancelOrder) {
          // Handle new order
          setTimeout(() => {
            onSave();
            setIsProcessing(false);
            setIsComplete(true);
            isProcessingRef.current = false;
          }, 2000);
        } else if (order.OrderId) {
          // Handle order cancellation
          setTimeout(() => {
            dispatcher.order.requestCancel(order.OrderId);
            setIsProcessing(false);
            setIsComplete(true);
            setOrderStatusProcessed(true);
            isProcessingRef.current = false;
          }, 2000);
        }
      };

      processOrder();
    },
    [complete, isCancelOrder, onSave, previewClosed, dispatcher]
  );

  // Handle button click without debouncing
  const handleClick = () => {
    if (!isProcessingRef.current) {
      handleOrderAction(order);
    }
  };

  // Cleanup effect to reset processing ref on component unmount
  useEffect(() => {
    return () => {
      isProcessingRef.current = false;
    };
  }, []);

  return (
    <>
      <button className="btn btn-blend" onClick={() => previewClosed({ action: 'closed' })} type="button">
        <i className="fal fa-arrow-left"></i> Back to edit
      </button>
      <button
        className={`btn ${!complete ? btnClassName : 'btn-success'} ${disabled ? 'disabled' : ''}`}
        disabled={disabled || isProcessing}
        onClick={() => handleClick()}
        style={{ transition: 'all 0.5s ease-in-out' }}
        type="button"
      >
        {!isSaving && (
          <>
            <strong>{!complete ? (isCancelOrder ? 'Cancel' : 'Send') + ' Order' : ''} </strong>{' '}
            <i className="fal fa-arrow-right"></i>
          </>
        )}
        {(isSaving || isProcessing) && (
          <>
            <strong>&nbsp; Saving... &nbsp;</strong>
            <span className="spinner-border spinner-border-sm"></span>
          </>
        )}
        {complete && (
          <>
            <strong>&nbsp; OK &nbsp;</strong>
          </>
        )}
      </button>
    </>
  );
};
