import React from 'react';
import { useField, GenericFieldHTMLAttributes, FieldConfig } from 'formik';
import { isString } from '@tradingblock/api';
import { GenericField, RadioSettingsField } from './GenericField';

type FieldConfigurationorGeneric<T = any> = GenericFieldHTMLAttributes & FieldConfig<T>;

type RadioButtonFieldProps<T = any> = { config?: FieldConfigurationorGeneric<T> } & Omit<RadioSettingsField, 'type'>;
export const GenericRadioButton: React.FunctionComponent<RadioButtonFieldProps> = ({ fieldLabel, yesOptionLabel, noOptionLabel, fieldNote, name, ...props }) => {
  const fieldName = name || '';
  const [field, meta] = useField(props.config ? props.config : fieldName);
  const note = fieldNote === undefined ? undefined : isString(fieldNote) ? fieldNote : fieldNote(field.value);

  return (
    <>
      {fieldLabel}
      <br />
      <GenericField name={fieldName} value={field.value} settings={{ type: 'toggle', label: yesOptionLabel }} />
      &nbsp;&nbsp;
      <GenericField name={fieldName} value={!field.value} settings={{ type: 'toggle', label: noOptionLabel, toggleOpposite: true }} />
      {note && (
        <>
          <br />
          <em>{note}</em>
        </>
      )}
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </>
  );
};
