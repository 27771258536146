import _ from 'lodash';
import { Legs, OrderLegDTOState, OrderAction } from '@tradingblock/types';
import { expirationUnixTimestamp } from '../../../utilities/date';

export const getOrderActionForCalendar = (orderLegs: Legs, currentOrderAction: OrderAction): OrderAction => {
  if (_.some(orderLegs, l => l.Expiration === undefined)) {
    return currentOrderAction;
  }
  const latestExpirationLeg = _(orderLegs).reduce(
    (latestExp: OrderLegDTOState, l): OrderLegDTOState => {
      const latestValue = expirationUnixTimestamp(latestExp.Expiration);
      const currentValue = expirationUnixTimestamp(l.Expiration);
      if (latestExp === undefined || currentValue > latestValue) {
        return l;
      }
      return latestExp;
    }
  );
  return latestExpirationLeg ? latestExpirationLeg.Action : currentOrderAction;
};
