import { ActionType, createStandardAction } from 'typesafe-actions';
import {
  ActionMetaOrUndefined,
  LoginJwtValue,
  ActionMetadata,
  FeedQuote,
  UiSettings,
  AllNotificationType,
  Notification,
  IFeedSpread,
  DashboardInfo,
  Layouts,
  BlockConfiguration,
  HistoryItem,
  ResponseCode,
  BlockType,
  BlockInfo,
  Order,
  Balances,
  Transfer,
  SizeType,
  Expiration,
  GroupOrderLeg,
  AssetSymbol,
  ExpiredTokenCode,
  DebounceMetadata,
  PnLItem,
  FeedEventData,
  AssetSymbolInfo,
  OptionPairWithSymbol,
  DashboardBlocksResponse,
  SubAccount,
  AccountDetails,
  GroupLinkColors,
  BalancesForWithdrawal,
} from '@tradingblock/types';
import { SymbolQuote, SymbolDividendData, SymbolQuoteExtended, PositionDTO } from '@tradingblock/api';
import { buildActions, actionBuilder, buildApiActions } from './actionUtilities';
import { CashieringActions } from './actions/CashieringActions';
import { SettingsActions } from './actions/SettingsActions';
import { PageActions } from './actions/PageActions';
import { OrderActions } from './actions/OrderActions';
import { AdminActions } from './actions/admin';
import { AccountActions } from './actions/AccountActions';
import { QuoteDataActions as DataQuoteActions } from './actions/QuoteActions';
import { EnvironmentActions } from './actions/EnvironmentActions';
import { CashieringEventActions } from './actions/CashieringEventActions';
import { InfoDataActions } from './actions/InfoActions';
import { AccountManagementDataActions } from './actions/AccountManagementActions';
import { BalanceState } from './state';
import { LinkedAccountsActions } from './actions/LinkedAccountsActions';
import { AccountStatementsActions } from './actions/AccountStatementsActions';
import { HistoryActions } from './actions/History';
import { SubaccountActions } from './actions/SubAccountActions';
import { MarketSchedule } from '@tradingblock/api/src/commands/marketSchedule';
export * from './actions/OrderActions';

// eslint-disable-next-line @typescript-eslint/camelcase
export const REQUEST_Token = 'REQUEST_Token';
// eslint-disable-next-line @typescript-eslint/camelcase
export const RECEIVE_Token = 'RECEIVE_Token';
export const RECEIVE_AUTH_TOKEN = 'RECEIVE_AUTH_Token';

const [requestWarning, receiveWarning, errorWarning] = buildApiActions([
  '@data/symbols/warning/REQUEST',
  '@data/symbols/warning/RECEIVE',
  '@data/symbols/warning/ERROR',
])<{}, string[]>();

export const SymbolActions = {
  requestWarning,
  receiveWarning,
  errorWarning,
};

export const LifecycleActions = {
  loadInitialData: createStandardAction('@data/initial/REQUEST')(),
};

export const REQUEST_ACCOUNT_BALANCES = '@account/REQUEST_ACCOUNT_BALANCES';
export const FETCH_ACCOUNT_BALANCES = '@account/FETCH_ACCOUNT_BALANCES';
export const RECEIVE_ACCOUNT_BALANCES = '@account/RECEIVE_ACCOUNT_BALANCES';
export const REQUEST_LINKED_ACCOUNT_BALANCES = '@account/REQUEST_LINKED_ACCOUNT_BALANCES';
export const FETCH_LINKED_ACCOUNT_BALANCES = '@account/FETCH_LINKED_ACCOUNT_BALANCES';
export const RECEIVE_LINKED_ACCOUNT_BALANCES = '@account/RECEIVE_LINKED_ACCOUNT_BALANCES';
export const REQUEST_SUB_ACCOUNT_BALANCES = '@account/REQUEST_SUB_ACCOUNT_BALANCES';
export const FETCH_SUB_ACCOUNT_BALANCES = '@account/FETCH_SUB_ACCOUNT_BALANCES';
export const RECEIVE_SUB_ACCOUNT_BALANCES = '@account/RECEIVE_SUB_ACCOUNT_BALANCES';
export const REQUEST_PENDING_TRNASFERS = '@account/REQUEST_PENDING_TRNASFERS';
export const FETCH_PENDING_TRNASFERS = '@account/FETCH_PENDING_TRNASFERS';
export const RECEIVE_PENDING_TRNASFERS = '@account/RECEIVE_PENDING_TRNASFERS';
export const REQUEST_ACCOUNT_HISTORY = '@account/REQUEST_HISTORY';
export const RECEIVE_ACCOUNT_HISTORY = '@account/RECEIVE_HISTORY';
export const REQUEST_ACCOUNT_PNL = '@account/REQUEST_PNL';
export const RECEIVE_ACCOUNT_PNL = '@account/RECEIVE_PNL';
export const REQUEST_ACCOUNT_ORDERS = '@account/REQUEST_ACCOUNT_ORDERS';
export const REQUEST_MORE_ACCOUNT_ORDERS = '@account/orders/REQUEST_MORE';
export const RECEIVE_ACCOUNT_ORDERS = '@account/RECEIVE_ACCOUNT_ORDERS';
export const REQUEST_ACCOUNT_SETTINGS = '@account/REQUEST_SETTINGS';
export const RECEIVE_ACCOUNT_SETTINGS = '@account/RECEIVE_SETTINGS';
export const SET_ACCOUNT_SETTINGS = '@account/SET_ACCOUNT_SETTINGS';
export const TOGGLE_SETTINGS = '@account/TOGGLE_SETTINGS';
export const SET_ORIGIN = '@account/SET_ORIGIN';
export const SET_CURRENT_ACCOUNT_DETAILS = '@account/SET_CURRENT_ACCOUNT_DETAILS';
export const REQUEST_BALANCES_FOR_WITHDRAWAL = '@account/REQUEST_BALANCES_FOR_WITHDRAWAL';
export const FETCH_BALANCES_FOR_WITHDRAWAL = '@account/FETCH_BALANCES_FOR_WITHDRAWAL';
export const RECEIVE_BALANCES_FOR_WITHDRAWAL = '@account/RECEIVE_BALANCES_FOR_WITHDRAWAL';

// CLASSIC API
export const Actions = {
  ...AccountActions,
  signout: createStandardAction(`@account/signout`)(),
  receiveCurrentAccountDetails: createStandardAction(SET_CURRENT_ACCOUNT_DETAILS)<AccountDetails>(),
  requestToken: createStandardAction(REQUEST_Token)(),
  receiveToken: createStandardAction(RECEIVE_Token)<LoginJwtValue & { apiToken: string }>(),
  refreshToken: createStandardAction('@account/token/refresh')(),

  requestSettings: createStandardAction(REQUEST_ACCOUNT_SETTINGS)<{ accountId: number }>(),
  receiveSettings: createStandardAction(RECEIVE_ACCOUNT_SETTINGS)<{ accountId: number; settings: UiSettings }>(),

  setClientVersion: createStandardAction('@application/version/SET')<{ version: string }>(),
  clientVersionMismatch: createStandardAction('@application/version/MISMATCH_ERROR')<{
    previous: string;
    current: string;
  }>(),

  setSettings: createStandardAction(SET_ACCOUNT_SETTINGS)<{ accountId: number; settings: UiSettings }>(),
  toggleSettings: createStandardAction(TOGGLE_SETTINGS)<{ show?: boolean }>(),

  setOrigin: createStandardAction(SET_ORIGIN)<{ origin: string }>(),

  requestInitialAccountBalances: createStandardAction(REQUEST_ACCOUNT_BALANCES)<{ throttle: boolean }>(),
  requestAccountBalances: createStandardAction(REQUEST_ACCOUNT_BALANCES)<{ throttle: boolean }>(),
  fetchBalances: createStandardAction(FETCH_ACCOUNT_BALANCES)<{}, ActionMetadata>(),
  receiveAccountBalances: createStandardAction(RECEIVE_ACCOUNT_BALANCES)<{
    data: Balances;
    responseCode: ResponseCode;
  }>(),
  requestBalancesForWithdrawal: createStandardAction(REQUEST_BALANCES_FOR_WITHDRAWAL)<{ throttle: boolean }>(),
  fetchBalancesForWithdrawal: createStandardAction(FETCH_BALANCES_FOR_WITHDRAWAL)<{}, ActionMetadata>(),
  receiveBalancesForWithdrawal: createStandardAction(RECEIVE_BALANCES_FOR_WITHDRAWAL)<{
    data: BalancesForWithdrawal;
    responseCode: ResponseCode;
  }>(),

  //Related to fetching linked account balances
  requestLinkedAccountBalances: createStandardAction(REQUEST_LINKED_ACCOUNT_BALANCES)<{
    accountIds?: number[];
  }>(),
  fetchLinkedAccountBalances: createStandardAction(FETCH_LINKED_ACCOUNT_BALANCES)<{
    accountIds?: number[];
  }>(),
  receiveLinkedAccountBalances: createStandardAction(RECEIVE_LINKED_ACCOUNT_BALANCES)<{
    accountId: number;
    balance: Balances;
  }>(),

  requestSubAccountBalances: createStandardAction(REQUEST_SUB_ACCOUNT_BALANCES)<{
    accountId: number;
    subAccounts: SubAccount[];
    update?: boolean;
  }>(),
  fetchSubAccountBalances: createStandardAction(FETCH_SUB_ACCOUNT_BALANCES)<{
    accountId: number;
    subAccounts: SubAccount[];
    update?: boolean;
  }>(),
  receiveSubAccountBalances: createStandardAction(RECEIVE_SUB_ACCOUNT_BALANCES)<{
    accountId: number;
    subAccountId: number;
    balance: Balances;
  }>(),

  requestPendingTransfers: createStandardAction(REQUEST_PENDING_TRNASFERS)<{ throttle: boolean }>(),
  fetchPendingTransfers: createStandardAction(FETCH_PENDING_TRNASFERS)<{}, ActionMetadata>(),
  receivePendingTransfers: createStandardAction(RECEIVE_PENDING_TRNASFERS)<{
    data: Transfer[];
    responseCode: ResponseCode;
  }>(),

  requestAccountHistory: createStandardAction(REQUEST_ACCOUNT_HISTORY)<{ accountId: number; subaccountId?: number }>(),
  receiveAccountHistory: createStandardAction(RECEIVE_ACCOUNT_HISTORY)<{ accountId: number; history: HistoryItem[] }>(),

  requestAccountPnL: createStandardAction(REQUEST_ACCOUNT_PNL)<{ accountId: number; subaccountId?: number }>(),
  receiveAccountPnL: createStandardAction(RECEIVE_ACCOUNT_PNL)<{ accountId: number; pnlHistory: PnLItem[] }>(),

  requestAccountOrders: createStandardAction(REQUEST_ACCOUNT_ORDERS)<
    {
      accountId: number;
      subaccountId?: number;
      dateFrom?: Date;
      dateTo?: Date;
      includeOrderEvents?: boolean;
      pageSize?: number;
      pageIndex?: number;
      blockId?: string;
      isTradesTab?: boolean;
      workingOrdersOnly?: boolean;
    },
    ActionMetadata
  >(),
  receiveAccountOrders: createStandardAction(RECEIVE_ACCOUNT_ORDERS)<{
    accountId: number;
    orders: Order[];
    date: Date;
    dateTo?: Date;
    dateFrom?: Date;
    total?: number;
    pageSize?: number;
    pageIndex?: number;
    blockId?: string;
    isTradesTab?: boolean;
  }>(),
  requestMoreAccountOrders: createStandardAction(REQUEST_MORE_ACCOUNT_ORDERS)<{
    accountId: number;
    subaccountId?: number;
  }>(),
  setOrdersRequestPageIndex: createStandardAction('@account/orders/SET_PAGE')<{
    pageIndex: number;
    blockId: string;
  }>(),
  setOrdersRequestPageSize: createStandardAction('@account/orders/SET_PAGE_SIZE')<{
    pageSize: number;
    blockId: string;
  }>(),
};

export const SET_QUOTES = 'SET_QUOTES';
export const QuoteActions = {
  setQuotes: createStandardAction(SET_QUOTES)<FeedQuote[]>(),
};

export const PERSIST_BLOCKS = 'PERSIST_BLOCKS';
export const PERSIST_DASHBOARDS = 'PERSIST_DASHBOARDS';
export const PERSIST_DASHBOARDS_FINISHED = 'PERSIST_DASHBOARDS_FINISHED';
export const PERSIST_LAYOUTS = 'PERSIST_LAYOUTS';
export const PERSIST_FAVORITES = 'PERSIST_FAVORITES';
export const PersistActions = {
  blocks: createStandardAction(PERSIST_BLOCKS)<BlockConfiguration[]>(),
  dashboards: createStandardAction(PERSIST_DASHBOARDS)<DashboardInfo[]>(),
  dashboardsFinished: createStandardAction(PERSIST_DASHBOARDS_FINISHED)(),
  layouts: createStandardAction(PERSIST_LAYOUTS)<Layouts>(),
  favorites: createStandardAction(PERSIST_FAVORITES)(),
};

export const ADD_DASHBOARD = 'ADD_DASHBOARD';
export const ADD_EMPTY_DASHBOARD = 'ADD_EMPTY_DASHBOARD';
export const UPDATE_DASHBOARD = 'UPDATE_DASHBOARD';
const DashboardAddUpdateActions = {
  addDashboard: createStandardAction(ADD_DASHBOARD)<
    { dashboard: DashboardInfo; blocks: BlockConfiguration[] },
    ActionMetaOrUndefined
  >(),
  addEmptyDashboard: createStandardAction(ADD_EMPTY_DASHBOARD)<{ dashboard: DashboardInfo; setAsActive: boolean }>(),
  updateDashboard: createStandardAction(UPDATE_DASHBOARD)<
    { dashboardId: string; dashboardData: Partial<DashboardInfo> },
    ActionMetaOrUndefined
  >(),
};

export const REQUEST_DASHBOARDS = 'REQUEST_DASHBOARDS';
export const RECEIVE_DASHBOARDS = 'RECEIVE_DASHBOARDS';
export const SET_DASHBOARD = 'SET_DASHBOARD';
export const DashboardActions = {
  ...DashboardAddUpdateActions,
  requestDashboards: createStandardAction(REQUEST_DASHBOARDS)(),
  receiveDashboards: createStandardAction(RECEIVE_DASHBOARDS)<DashboardInfo[]>(),
  setDashboard: createStandardAction(SET_DASHBOARD)<{ dashboard: DashboardInfo; addDashboard?: boolean }>(),
};
export type AddDashboardAction = ActionType<typeof DashboardAddUpdateActions.addDashboard>;
export type UpdateDashboardAction = ActionType<typeof DashboardAddUpdateActions.updateDashboard>;

const REQUEST_BLOCKS = '@block/REQUEST_BLOCKS';
const RECEIVE_BLOCKS = '@block/RECEIVE_BLOCKS';
const ADD_BLOCK = '@block/ADD_BLOCK';
const ADDORUPDATE_BLOCK = '@block/ADD_OR_UPDATE_BLOCK';
const UPDATE_BLOCK = '@block/UPDATE_BLOCK';
const REMOVE_BLOCK = '@block/REMOVE_BLOCK';
const SET_BLOCKS = '@block/SET_BLOCKS';
const HIGHLIGHT_BLOCKS = '@block/HIGHLIGHT_BLOCKS';
const HIGHLIGHT_GROUP = '@block/HIGHLIGHT_GROUP';
const CLEAR_HIGHLIGHTED_BLOCKS = '@block/CLEAR_HIGHLIGHTED_BLOCKS';
const ADD_BLOCK_QUOTE_SUBSCRIPTION = '@block/ADD_BLOCK_QUOTE_SUBSCRIPTION';
const SET_BLOCK_QUOTE_SUBSCRIPTION = '@block/SET_QUOTE_SUBSCRIPTION';
const LINK_BLOCKS = '@block/LINK_BLOCKS';
const UNLINK_BLOCK = '@block/UNLINK_BLOCK';
const SET_LINK_COLOR = '@block/SET_LINK_COLOR';
export const BlockActions = {
  requestBlocks: createStandardAction(REQUEST_BLOCKS)(),
  receiveBlocks: createStandardAction(RECEIVE_BLOCKS)<DashboardBlocksResponse>(),
  noBlocks: createStandardAction('@block/NO_BLOCKS')(),
  addBlock: createStandardAction(ADD_BLOCK)<{ block: BlockConfiguration; addedAt: Date }, ActionMetaOrUndefined>(),
  addOrUpdateBlock: createStandardAction(ADDORUPDATE_BLOCK)<
    {
      block: BlockConfiguration;
      addedAt: Date;
      preserveGroupOrderData?: boolean;
      replaceFirstMatch?: { blockType: BlockType; groupId?: string };
    },
    ActionMetaOrUndefined
  >(),
  updateBlock: createStandardAction(UPDATE_BLOCK)<
    { blockId: string; blockData: Partial<BlockConfiguration> },
    ActionMetaOrUndefined
  >(),
  removeBlock: createStandardAction(REMOVE_BLOCK)<string, ActionMetaOrUndefined>(),
  setBlocks: createStandardAction(SET_BLOCKS)<{ blocks: BlockConfiguration[] }, ActionMetaOrUndefined>(),
  highlightBlocks: createStandardAction(HIGHLIGHT_BLOCKS)<{
    blockType: BlockType;
    highlightedAt: Date;
    options?: { activeTab?: string; allMatches?: boolean; timeoutMs?: number };
  }>(),
  highlightGroup: createStandardAction(HIGHLIGHT_GROUP)<{ groupId: string | undefined; highlightAt: Date }>(),
  clearHighlightedBlocks: createStandardAction(CLEAR_HIGHLIGHTED_BLOCKS)(),
  addQuoteSubscription: createStandardAction(ADD_BLOCK_QUOTE_SUBSCRIPTION)<{ blockId: string; symbols: string[] }>(),
  setQuoteSubscription: createStandardAction(SET_BLOCK_QUOTE_SUBSCRIPTION)<{ blockId: string; symbols: string[] }>(),
  linkBlock: createStandardAction(LINK_BLOCKS)<{ blockId: string; groupId: string }>(),
  unlinkBlock: createStandardAction(UNLINK_BLOCK)<{ blockId: string }>(),
  setColoredLink: createStandardAction(SET_LINK_COLOR)<{
    linkColor: GroupLinkColors | undefined;
    blockData: { groupId: string };
  }>(),
};

export const BLOCKGROUP_ADDLEG = '@block/group/ADDLEG';
export const BLOCKGROUP_SetSymbolAndReset = '@block/group/SetSymbolAndReset';
export const BLOCKGROUP_SETSYMBOL = '@block/group/SETSYMBOL';
export const BLOCKGROUP_CREATEORDERFROMGROUP = '@block/group/createOrderFromGroup';
export const SharedBlockGroupActions = {
  SetSymbolAndReset: createStandardAction(BLOCKGROUP_SetSymbolAndReset)<{
    leg: GroupOrderLeg;
    groupId: string;
    symbol: AssetSymbol;
  }>(),
};
export const BlockGroupActions = {
  ...SharedBlockGroupActions,
  addGroupLeg: createStandardAction(BLOCKGROUP_ADDLEG)<{ leg: GroupOrderLeg; groupId: string }>(),
  setGroupSymbol: createStandardAction(BLOCKGROUP_SETSYMBOL)<{ symbol: AssetSymbol | undefined; groupId: string }>(),
  createOrderFromGroup: createStandardAction(BLOCKGROUP_CREATEORDERFROMGROUP)<
    { leg: GroupOrderLeg; groupId: string },
    { persist: true }
  >(),
};
export type BlockGroupAction = ActionType<typeof BlockGroupActions>;

export const TOGGLE_LOCKED = '@grid/TOGGLE_LOCKED';
export const TOGGLE_LINKING = '@grid/TOGGLE_LINKING';
export const GridActions = {
  toggleLocked: createStandardAction(TOGGLE_LOCKED)<{ locked?: boolean; showTooltip?: boolean }>(),
  toggleLinking: createStandardAction(TOGGLE_LINKING)<{
    enabled: boolean | undefined;
    sourceBlock: BlockInfo | undefined;
    targetBlock: { type: BlockType } | undefined;
  }>(),
};

export const ADD_QUOTE = '@feed/ADD_QUOTE';
export const REMOVE_QUOTE = '@feed/REMOVE_QUOTE';
export const ADD_SPREAD = '@feed/ADD_SPREAD';
export const REMOVE_SPREAD = '@feed/REMOVE_SPREAD';
export const FEED_QUOTES_UPDATE = '@feed/quotes/UDPATE';
export const FEED_CLOSED = '@feed/CLOSED';
export const FEED_ERRORED = '@feed/ERRORED';
export const FEED_CONNECTED = '@feed/CONNECTED';
export const FeedActions = {
  addSpread: createStandardAction(ADD_SPREAD)<IFeedSpread | IFeedSpread[]>(),
  removeSpread: createStandardAction(REMOVE_SPREAD)<IFeedSpread | IFeedSpread[] | string>(),
  addQuote: createStandardAction(ADD_QUOTE)<string | string[]>(),
  removeQuote: createStandardAction(REMOVE_QUOTE)<string | string[]>(),
  updateQuotes: createStandardAction(FEED_QUOTES_UPDATE)<
    { quotes: { [symbol: string]: FeedQuote } },
    ActionMetaOrUndefined
  >(),
  feedClosed: createStandardAction(FEED_CLOSED)<FeedEventData>(),
  feedErrored: createStandardAction(FEED_ERRORED)<FeedEventData>(),
  connected: createStandardAction(FEED_CONNECTED)(),
};

export const REQUEST_EXPIRATIONS = '@data/expirations/REQUEST_EXPIRATIONS';
export const RECEIVE_EXPIRATIONS = '@data/expirations/RECEIVE_EXPIRATIONS';
export const REQUEST_STRIKES = '@data/REQUEST_STRIKES';
export const RECEIVE_STRIKES = '@data/RECEIVE_STRIKES';
export const requestExpirationAction = createStandardAction(REQUEST_EXPIRATIONS)<{ symbol: string }>();
export const requestStrikeAction = createStandardAction(REQUEST_STRIKES)<{ symbol: string; expiration: Expiration }>();
export const fetchStrikes = createStandardAction('@data/FETCH_STRIKES')<{ symbol: string; expiration: Expiration }>();
export const ExpirationStrikeActions = {
  requestExpirationAction,
  receiveExpirations: createStandardAction(RECEIVE_EXPIRATIONS)<{ symbol: string; expirations: Expiration[] }>(),
  requestStrikes: requestStrikeAction,
  fetchStrikes,
  receiveStrikes: createStandardAction(RECEIVE_STRIKES)<{
    strikes: number[];
    symbol: string;
    expiration: Expiration;
  }>(),
};
export type ExpirationStrikeAction = ActionType<typeof ExpirationStrikeActions>;
export type RequestExpirationAction = ActionType<typeof requestExpirationAction>;
export type RequestStrikeAction = ActionType<typeof requestStrikeAction>;

export const REQUEST_POSITIONS = '@data/REQUEST_POSITIONS';
export const RECEIVE_POSITIONS = '@data/RECEIVE_POSITIONS';
export const FETCHING_POSITIONS = '@data/FETCHING_POSITIONS';
const MANUAL_REFRESH_OF_POSITIONS = '@data/MANUAL_REFRESH_OF_POSITIONS';
export const QuoteDataActions = DataQuoteActions;
export const DataActions = {
  ...QuoteDataActions,
  requestPositions: createStandardAction(REQUEST_POSITIONS)<
    { accountId?: number; subaccountId?: number; more?: boolean },
    Partial<DebounceMetadata>
  >(),
  fetchingPositions: createStandardAction(FETCHING_POSITIONS)<{ accountId?: number; subaccountId?: number }>(),
  receivePositions: createStandardAction(RECEIVE_POSITIONS)<PositionDTO[]>(),
  manualRefreshOfPositions: createStandardAction(MANUAL_REFRESH_OF_POSITIONS)(),
};

export type OrderUpdateEventAction = ActionType<typeof OrderActions.receivedUpdate>;
export type OrderCancelEventAction = ActionType<typeof OrderActions.requestCancel>;

export const HIDE_NOTIFICATION = '@notification/HIDE_NOTIFICATION';
export const READ_NOTIFICATION = '@notification/READ_NOTIFICATION';
export const VIEW_NOTIFICATION = '@notification/VIEW_NOTIFICATION';
export const ADD_NOTIFICATION = '@notification/ADD_NOTIFICATION';
export const ADD_WARNING_NOTIFICATION = '@notification/ADD_WARNING_NOTIFICATION';
export const NotificationActions = {
  add: createStandardAction(ADD_NOTIFICATION)<AllNotificationType>(),
  hide: createStandardAction(HIDE_NOTIFICATION)<Notification>(),
  read: createStandardAction(READ_NOTIFICATION)<Notification | Notification[]>(),
  view: createStandardAction(VIEW_NOTIFICATION)<Notification>(),
};

export const ADD_FAVORITE = '@favorites/ADD';
export const REMOVE_FAVORITE = '@favorites/REMOVE';
export const REQUEST_FAVORITES = '@favorites/REQUEST';
export const RECEIVE_FAVORITES = '@favorites/RECEIVE';
export const FavoriteActions = {
  addFavorite: createStandardAction(ADD_FAVORITE)<AssetSymbolInfo>(),
  remove: createStandardAction(REMOVE_FAVORITE)<string>(),
  request: createStandardAction(REQUEST_FAVORITES)(),
  receive: createStandardAction(RECEIVE_FAVORITES)<{ value: AssetSymbolInfo[] }>(),
};

export const SET_BREAKPOINT_AND_COLUMNS = '@layout/SET_BREAKPOINT_AND_COLUMNS';
export const SET_LAYOUTS = '@layout/SET_LAYOUTS';
export const SET_ALL_LAYOUTS = '@layout/SET_ALL_LAYOUTS';
export const LayoutActions = {
  setBreakpointAndColumns: createStandardAction(SET_BREAKPOINT_AND_COLUMNS)<{
    breakpoint: SizeType;
    columns: number;
  }>(),
  setLayouts: createStandardAction(SET_LAYOUTS)<{ layouts: Layouts }, ActionMetaOrUndefined>(),
  setAllLayouts: createStandardAction(SET_ALL_LAYOUTS)<{ layouts: Layouts }, ActionMetaOrUndefined>(),
};

export const REQUEST_OPTION_EXPIRATIONS = '@optionchain/REQUEST_OPTION_EXPIRATIONS';
export const RECEIVE_OPTION_EXPIRATIONS = '@optionchain/RECEIVE_OPTION_EXPIRATIONS';
export const REQUEST_OPTION_CHAIN = '@optionchain/REQUEST_OPTION_CHAIN';
export const RECEIVE_OPTION_CHAIN = '@optionchain/RECEIVE_OPTION_CHAIN';
export const REQUEST_OPTION_ERROR = '@optionchain/REQUEST_OPTION_ERROR';
export const SET_STRIKECOUNT = '@optionchain/SET_STRIKECOUNT';
export const OptionChainActions = {
  setStrikeCount: createStandardAction(SET_STRIKECOUNT)<{
    blockId: string;
    symbol: string;
    strikeCount: number;
    expirations: Expiration[];
  }>(),
  requestOptionChainError: createStandardAction(REQUEST_OPTION_ERROR)<{
    blockId: string;
    symbol: string;
    expiration: Expiration;
    responseCode: number;
  }>(),
  requestOptionExpirations: createStandardAction(REQUEST_OPTION_EXPIRATIONS)<{ blockId: string; symbol: string }>(),
  receiveOptionExpirations: createStandardAction(RECEIVE_OPTION_EXPIRATIONS)<{
    blockId: string;
    symbol: string;
    expirations: Expiration[];
  }>(),
  requestOptionChain: createStandardAction(REQUEST_OPTION_CHAIN)<{
    blockId: string;
    symbol: string;
    expirations: Expiration[];
    strikeCount?: number;
  }>(),
  receiveOptionChain: createStandardAction(RECEIVE_OPTION_CHAIN)<{
    blockId: string;
    symbol: string;
    expiration: Expiration;
    pairs: OptionPairWithSymbol[];
    minStrike?: number;
    maxStrike?: number;
  }>(),
};

export const SESSION_EXPIRED = '@session/EXPIRED';
export const SESSION_ERROR = '@session/SESSION_ERROR';
export const RESET_UNAUTHORIZED_REQUEST = '@session/RESET_UNAUTHORIZED_REQUEST';

export const SessionActions = {
  expired: createStandardAction(SESSION_EXPIRED)<{ code: ExpiredTokenCode; reason?: string; syncTab?: boolean }>(),
  error: createStandardAction(SESSION_ERROR)<{ code: number; url?: string; message?: string; reason?: string }>(),
  resetError: createStandardAction(RESET_UNAUTHORIZED_REQUEST)<{}>(),
};

export const UI_DASHBOARD_DRAWER_OPEN = '@ui/dashboard/drawer/open';
export const UI_SET_SUBACCOUNT_FILTER_ID = '@ui/setSubaccountFilterId';
export const SET_MARKET_SCHEDULE = '@ui/setMarketSchedule';
export const REQUEST_MARKET_SCHEDULE = '@ui/requestMarketSchedule';
export const DEFAULT_MARKET_SCHEDULE = '@ui/defaultMarketSchedule';
const UI_SET_SHOW_PENDING_ENTITLEMENT_MODAL = '@ui/setShowPendingEntitlementModal';

export const UIActions = {
  setDashboardDrawerOpen: createStandardAction(UI_DASHBOARD_DRAWER_OPEN)<{ open: boolean }>(),
  toggleLockDashboard: createStandardAction('@ui/dashboard/toggleLock')<{}>(),
  setSubAccountFilterId: createStandardAction(UI_SET_SUBACCOUNT_FILTER_ID)<{ id: number | undefined }>(),
  setMarketSchedule: createStandardAction(SET_MARKET_SCHEDULE)<MarketSchedule>(),
  requestMarketSchedule: createStandardAction(REQUEST_MARKET_SCHEDULE)(),
  defaultMarketSchedule: createStandardAction(DEFAULT_MARKET_SCHEDULE)(),
  setShowPendingEntitlementModal: createStandardAction(UI_SET_SHOW_PENDING_ENTITLEMENT_MODAL)<{
    showPendingEntitlementModal: boolean;
  }>(),
};

export type CashieringAction = ActionType<typeof CashieringActions>;
const allActions = {
  ...HistoryActions,
  ...EnvironmentActions,
  ...PageActions,
  ...SettingsActions,
  ...Actions,
  ...DashboardActions,
  ...GridActions,
  ...QuoteActions,
  ...LifecycleActions,
  ...FeedActions,
  ...PersistActions,
  ...DataActions,
  ...OrderActions,
  ...NotificationActions,
  ...FavoriteActions,
  ...ExpirationStrikeActions,
  ...LayoutActions,
  ...OptionChainActions,
  ...BlockActions,
  ...BlockGroupActions,
  ...CashieringActions,
  ...SessionActions,
  ...SymbolActions,
  ...UIActions,
  ...AdminActions,
  ...CashieringEventActions,
  ...InfoDataActions,
  ...AccountManagementDataActions,
  ...LinkedAccountsActions,
  ...AccountStatementsActions,
  ...SubaccountActions,
};

const gridPersistActions = {
  addBlock: BlockActions.addBlock,
  removeBlock: BlockActions.removeBlock,
  updateBlock: BlockActions.updateBlock,
  setLayouts: LayoutActions.setLayouts,
};
export type PersistActions = ActionType<typeof gridPersistActions>;
export type RootAction = ActionType<typeof allActions>;
