import React, { useCallback, useEffect, useState } from 'react';
import { CreateSubaccountPayload } from '@tradingblock/api/src/commands/subaccounts';
import { Modal, ModalProps, TextboxField, formatNumber, CurrencyField } from '@tradingblock/components';
import { Formik, Form, Field, FormikProps } from 'formik';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { useApi } from '../../context/Api';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { AccountActions } from '../../data/global/actions/AccountActions';
import useSWRMutation from 'swr/mutation';
import _ from 'lodash';
import { account } from '../../data/global/selectors/accountSelectors';
import { ResponseCodes } from '@tradingblock/types';

export const createSubAccountSchema = Yup.object<CreateSubaccountPayload>().shape({
  accountId: Yup.number().required(),
  acctSuffix: Yup.string()
    .matches(/^[A-Za-z0-9]+$/, 'Please only include letters and numbers')
    .required('Please enter an account suffix')
    .length(3, 'Please enter exactly 3 characters')
    .test(
      'empty-check',
      "Can't have an empty Account Suffix",
      (acctSuffix: string) => acctSuffix !== undefined && acctSuffix.length > 0
    ),
  nickname: Yup.string()
    .required('Please enter a nickname')
    .trim()
    .max(15, 'Please enter a max of 15 characters')
    .test(
      'empty-check',
      "Can't have an empty Nickname",
      (nickname: string) => nickname !== undefined && nickname.length > 0
    ),
  description: Yup.string(),
  balance: Yup.string().matches(
    /^\$?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/,
    'Please enter a positive currency value. Ex: $10,101.11'
  ),
});

export interface CreateSubAccountModelProps {
  setShow: ModalProps['setShow'];
  show: ModalProps['show'];
  initialAccountState: {
    accountId: number;
    balance: number;
  };
}

export default function CreateSubAccountModel({ setShow, show, initialAccountState }: CreateSubAccountModelProps) {
  const api = useApi();
  const dispatch = useDispatch();
  const [errorState, setErrorState] = useState<string>('');
  const hasSubAccounts = useSelector(account.hasSubAccounts);
  const [fundingAccountBalance] = useState<number>(initialAccountState.balance);

  const createSubAccountFetcher = useCallback(
    (key: any, { arg }) => {
      return api.subaccounts.createSubaccount(arg);
    },
    [api]
  );

  const {
    data: createSubAccountResult,
    trigger: triggerCreateSubAccount,
    isMutating,
    error: createSubAccountRequestError,
  } = useSWRMutation('create-sub-account', createSubAccountFetcher);

  const onSubmit = async (values: CreateSubaccountPayload) => {
    let greaterThanAccountBalance = false,
      accountBalanceGreaterThanZero = true;

    // create new payload value to let form values stay the same
    const argPayload: CreateSubaccountPayload = {
      accountId: values.accountId,
      acctSuffix: values.acctSuffix,
      description: values.description,
      nickname: values.nickname.trim(),
    };

    // Check for submitted balance and format to a 2 decimal place number
    if (values.balance) {
      // Convert to float and set balance in the new payload
      argPayload.balance = parseFloat(values.balance.toString().replace(/[$,]+/g, ''));

      if (argPayload.balance !== 0) {
        // Check to see if there is an account balance to pull from
        if (fundingAccountBalance > 0) greaterThanAccountBalance = argPayload.balance > fundingAccountBalance;
        else accountBalanceGreaterThanZero = false;
      }
    } else {
      argPayload.balance = undefined;
    }

    // Check to see if the account/master balance has funds > 0
    // and that the submitted/asking balance is not greater than the available funds
    // OR if the submitted balance is 0
    if (
      (!greaterThanAccountBalance && accountBalanceGreaterThanZero) ||
      (argPayload.balance && argPayload.balance === 0)
    ) {
      setErrorState('');
      let result = await triggerCreateSubAccount(argPayload);
      if (result && result.responseCode === 0)
        dispatch(AccountActions.requestSubAccounts({ accountId: initialAccountState.accountId }));
    } else {
      // Set error states
      if (!accountBalanceGreaterThanZero) setErrorState('The funding account does not have a balance greater than 0');
      else if (greaterThanAccountBalance) setErrorState('The balance requested is greater than the funding account');
      else setErrorState('Please contact customer service.');
    }
  };

  useEffect(() => {
    if (createSubAccountResult !== undefined) {
      if (createSubAccountResult.responseCode !== 0) {
        if ((createSubAccountResult.payload as unknown) as string[])
          setErrorState(((createSubAccountResult.payload as unknown) as string[]).join('\n'));
        else if (ResponseCodes[createSubAccountResult.responseCode])
          setErrorState(ResponseCodes[createSubAccountResult.responseCode].description);
        else setErrorState('Error Code ' + createSubAccountResult.responseCode);
      } else {
        setShow(false);
      }
    }
  }, [createSubAccountResult, setShow, setErrorState]);

  useEffect(() => {
    if (createSubAccountRequestError !== undefined) {
      setErrorState(createSubAccountRequestError.message);
    }
  }, [createSubAccountRequestError]);

  return (
    <Modal setShow={setShow} show={show}>
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>Create Sub-Account</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <div style={{ color: 'red' }}>{errorState}</div>
        <Formik
          initialValues={
            {
              accountId: initialAccountState.accountId,
              acctSuffix: '',
              nickname: '',
              description: '',
              balance: undefined,
            } as CreateSubaccountPayload
          }
          validationSchema={createSubAccountSchema}
          onSubmit={onSubmit}
        >
          {({ setFieldValue }: FormikProps<CreateSubaccountPayload>) => (
            <Form translate={'yes'} id="create-sub-account-form">
              <Field component={TextboxField} id="acctSuffix" label="Account Suffix" errorImmediately={true} />
              <Field component={TextboxField} id="nickname" label="Nickname" errorImmediately={true} />
              <Field component={TextboxField} id="description" label="Description" />
              <Field
                component={CurrencyField}
                id="balance"
                label="Balance"
                autoComplete="off"
                errorImmediately={true}
              />
              <br></br>
              {fundingAccountBalance > 0 && (
                <Button
                  variant="primary"
                  onClick={() => {
                    setFieldValue('balance', fundingAccountBalance);
                  }}
                >
                  Take all funds from {hasSubAccounts ? 'Master' : 'Account'}
                </Button>
              )}
            </Form>
          )}
        </Formik>
      </BootstrapModal.Body>
      <BootstrapModal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button variant="primary" type="submit" form={'create-sub-account-form'} disabled={isMutating}>
          Create Sub-Account
        </Button>
      </BootstrapModal.Footer>
    </Modal>
  );
}
