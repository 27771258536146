import React, { useMemo, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { BasicSelect } from '../../containers';
var pageSizes = [5, 10, 25, 50, 0];
export var Paging = function Paging(_ref) {
  var tableInstance = _ref.tableInstance,
      searchOptionPage = _ref.searchOptionPage,
      tableKey = _ref.tableKey,
      search = _ref.search,
      searchOptionsSearchTerm = _ref.searchOptionsSearchTerm;
  var pageOptions = tableInstance.pageOptions,
      pageCount = tableInstance.pageCount,
      canPreviousPage = tableInstance.canPreviousPage,
      canNextPage = tableInstance.canNextPage,
      gotoPage = tableInstance.gotoPage,
      nextPage = tableInstance.nextPage,
      previousPage = tableInstance.previousPage,
      setPageSize = tableInstance.setPageSize,
      _tableInstance$state = tableInstance.state,
      pageIndex = _tableInstance$state.pageIndex,
      pageSize = _tableInstance$state.pageSize;
  var manualPagination = tableInstance.manualPagination;
  !manualPagination || tableKey === 'accountsTable' ? pageSizes = [5, 10, 25] : pageSizes;
  !manualPagination || tableKey === 'messages' || tableKey === 'orders' || tableKey === 'cashieringReviewTable' ? pageSizes = [5, 10, 25, 50] : pageSizes;
  var pageSizeOptions = useMemo(function () {
    return _.map(pageSizes, function (s) {
      return {
        value: s,
        label: s ? "".concat(s, " per page") : 'All pages'
      };
    });
  }, []);
  var pageSizeLabel = useMemo(function () {
    var label = _.find(pageSizeOptions, function (o) {
      return o.value === pageSize;
    });

    return label ? label.label : '';
  }, [pageSize, pageSizeOptions]);
  var disabledProps = useCallback(function (disabled) {
    return disabled ? {
      disabled: disabled,
      style: {
        opacity: 0.25
      }
    } : undefined;
  }, []);
  var onPageSizeChanged = useCallback(function (pageSizeOption) {
    setPageSize(pageSizeOption.value);
    gotoPage(0);
  }, [setPageSize, gotoPage]);
  useEffect(function () {
    if (tableKey === 'accountsTable' && search !== searchOptionsSearchTerm) {
      gotoPage(0);
    }
  }, [search, searchOptionsSearchTerm]);
  useEffect(function () {
    if (searchOptionPage) {
      gotoPage(searchOptionPage);
    }
  }, [searchOptionPage, gotoPage]);
  return React.createElement("div", {
    className: "table-toolbar table-toolbar-bottom"
  }, React.createElement("div", {
    className: "table-toolbar-primary"
  }, pageSize !== 0 && React.createElement("span", {
    className: "paging"
  }, React.createElement("button", Object.assign({
    className: "btn btn-blend",
    onClick: function onClick() {
      return gotoPage(0);
    }
  }, disabledProps(!canPreviousPage), {
    title: "First page"
  }), React.createElement("i", {
    className: "far fa-angle-double-left"
  })), React.createElement("button", Object.assign({
    className: "btn btn-blend",
    onClick: function onClick() {
      return previousPage();
    }
  }, disabledProps(!canPreviousPage), {
    title: "Previous page"
  }), React.createElement("i", {
    className: "far fa-angle-left"
  })), React.createElement("span", {
    className: "paging-current"
  }, "".concat(pageIndex + 1, " of ").concat(pageOptions.length === 0 ? 1 : pageOptions.length, " page"), pageOptions.length !== 1 ? 's' : ''), React.createElement("button", Object.assign({
    className: "btn btn-blend",
    onClick: function onClick() {
      return nextPage();
    }
  }, disabledProps(!canNextPage), {
    title: "Next page"
  }), React.createElement("i", {
    className: "far fa-angle-right"
  })), React.createElement("button", Object.assign({
    className: "btn btn-blend",
    onClick: function onClick() {
      return gotoPage(pageCount - 1);
    }
  }, disabledProps(!canNextPage), {
    title: "Last page"
  }), React.createElement("i", {
    className: "far fa-angle-double-right"
  })))), React.createElement("div", {
    className: "table-toolbar-secondary"
  }, React.createElement(BasicSelect, {
    onChange: onPageSizeChanged,
    placeholder: pageSizeLabel,
    className: "btn btn-dark btn-tall dropdown-toggle text-left",
    options: pageSizeOptions,
    isClearable: false,
    isSearchable: false
  })));
};