import React, { useCallback } from 'react';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import _ from 'lodash';
import { AllBlocksCategorized } from '@tradingblock/types';
import { Modal, getBlockTypeName, getBlockTypeIcon } from '../../lib';

var blockTypesAndIconsObjectBuilder = function blockTypesAndIconsObjectBuilder(blocks) {
  return blocks.map(function (block) {
    return {
      type: block,
      name: block && getBlockTypeName(block),
      icon: block && getBlockTypeIcon(block)
    };
  });
};

export var AddBlockModal = function AddBlockModal(_ref) {
  var blockTypes = _ref.blockTypes,
      show = _ref.show,
      onAdd = _ref.onAdd,
      onCancel = _ref.onCancel;
  //attempts to find within filteredBlockTypes entries of block types
  //on AllBlocksCategorized and maps it accordingly
  var allBlocksCategorizedTypeMapping = {
    front: AllBlocksCategorized.front.map(function (block) {
      return blockTypes.find(function (type) {
        return type === block;
      });
    }).filter(function (block) {
      return block !== undefined;
    }),
    middle: AllBlocksCategorized.middle.map(function (block) {
      return blockTypes.find(function (type) {
        return type === block;
      });
    }).filter(function (block) {
      return block !== undefined;
    }),
    back: AllBlocksCategorized.back.map(function (block) {
      return blockTypes.find(function (type) {
        return type === block;
      });
    }).filter(function (block) {
      return block !== undefined;
    })
  };
  var blockTypesAndIconsFront = blockTypesAndIconsObjectBuilder(allBlocksCategorizedTypeMapping.front);
  var blockTypesAndIconsMiddle = blockTypesAndIconsObjectBuilder(allBlocksCategorizedTypeMapping.middle);
  var blockTypesAndIconsBack = blockTypesAndIconsObjectBuilder(allBlocksCategorizedTypeMapping.back);
  var handleAddBlock = useCallback(function (type) {
    return onAdd(type);
  }, [onAdd]);

  var blockTypesAndIconsModalBodyBuilder = function blockTypesAndIconsModalBodyBuilder(block) {
    return React.createElement(React.Fragment, null, React.createElement(BootstrapModal.Body, null, React.createElement("div", {
      className: "add-block-grid"
    }, block.map(function (block) {
      return React.createElement("button", {
        key: "blockTypes".concat(block.type),
        type: "button",
        className: "btn btn-blend",
        onClick: function onClick() {
          return handleAddBlock(block.type);
        },
        title: "Add ".concat(_.lowerCase(block.name), " block")
      }, _.isString(block.icon) ? React.createElement("i", {
        className: block.icon
      }) : block.icon, React.createElement("span", {
        className: "add-block-title"
      }, block.name));
    }))), React.createElement("hr", null));
  };

  if (!show) {
    return null;
  }

  return React.createElement(Modal, {
    setShow: function setShow() {
      return onCancel;
    },
    show: show
  }, React.createElement(BootstrapModal.Header, {
    onHide: onCancel,
    closeButton: true
  }, React.createElement(BootstrapModal.Title, null, "Add block")), blockTypesAndIconsModalBodyBuilder(blockTypesAndIconsFront), blockTypesAndIconsModalBodyBuilder(blockTypesAndIconsMiddle), blockTypesAndIconsModalBodyBuilder(blockTypesAndIconsBack), React.createElement(BootstrapModal.Footer, null, React.createElement(Button, {
    variant: "secondary",
    onClick: onCancel
  }, "Cancel")));
};