import React, { FC } from 'react';
import { SignedNumber } from '@tradingblock/components';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { PerformanceSelectors } from '../../../data/global/selectors/performanceSelectors';
import _ from 'lodash';

export const PositionLegDailyPL: FC<{ positionIds: string[] }> = ({ positionIds }) => {
  const pnl = useStateSelector(s => PerformanceSelectors.aggregatePositionPerformances(s, positionIds));
  return <>{!_.isNaN(pnl.pnl) && <SignedNumber value={pnl.pnl} bold currency hideIcon />}</>;
};
