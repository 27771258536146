import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { Formik, Field } from 'formik';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { FormGroup, TextboxField, Modal } from '@tradingblock/components';
import { AccountManagementDataActions } from '../../../data/global/actions/AccountManagementActions';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { getInvalidError, getMismatchError, getRequiredError, getStaleError } from './Validation';
import { EmailUpdateValueProps, RE_EMAIL, SubmitEmailValues } from '@tradingblock/types';

export const EmailUpdate: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
  accountHolderIndex: number;
}> = ({ show, toggleModal, accountHolderIndex }) => {

  const accountManagementDetails = useStateSelector(s => s.accountManagement.accountManagementDetails.details);
  const { accountHolders } = accountManagementDetails;
  const user = accountHolders[accountHolderIndex];
  const email = user.email;
  const initialEmailUpdateValues: EmailUpdateValueProps = {
    emailValue: email ? email : '',
    emailConfirm: '',
  };
  const accountId = useStateSelector(s => s.accounts.account && s.accounts.account.AccountId);
  const dispatch = useDispatch();

  const onValidate = (values: EmailUpdateValueProps) => {
    const { emailValue, emailConfirm } = values;

    if (!emailValue) {
      return {
        [`emailValue`]: getRequiredError(emailValue),
        [`emailConfirm`]: getRequiredError(emailConfirm),
      };
    }

    if (!emailConfirm) {
      return {
        [`emailValue`]: getRequiredError(emailValue),
        [`emailConfirm`]: getRequiredError(emailConfirm),
      };
    }

    if (!RE_EMAIL.test(emailValue)) {
      return {
        [`emailValue`]: getInvalidError(RE_EMAIL.test(emailValue)),
        [`emailConfirm`]: getInvalidError(RE_EMAIL.test(emailConfirm)),
      };
    }

    if (!RE_EMAIL.test(emailConfirm)) {
      return {
        [`emailValue`]: getInvalidError(RE_EMAIL.test(emailValue)),
        [`emailConfirm`]: getInvalidError(RE_EMAIL.test(emailConfirm)),
      };
    }

    if (emailValue !== emailConfirm) {
      return {
        [`emailConfirm`]: getMismatchError(emailConfirm === emailValue),
      };
    }

    if (emailValue === email) {
      return {
        [`emailConfirm`]: getStaleError(emailValue !== email),
      };
    }
  };

  const onSubmit = useCallback(
    (values, { resetForm }) => {
      const { emailValue } = values;
      const updateValues: SubmitEmailValues = {
        item: 'Email',
        accountHolder: {
          email: emailValue,
        },
        holder: accountHolderIndex,
      };

      dispatch(
        AccountManagementDataActions.requestMakeAccountUpdate({
          accountId: accountId,
          request: updateValues,
        })
      );
      resetForm();
      toggleModal();
    },
    [toggleModal]
  );

  return (
    <Formik initialValues={initialEmailUpdateValues} onSubmit={onSubmit} validate={onValidate} validateOnChange={true}>
      {({ handleSubmit, resetForm }) => (
        <Modal setShow={() => toggleModal()} show={show}>
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Email Update</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            <form id="emailUpdate" onSubmit={handleSubmit}>
              <FormGroup>
                <Field component={TextboxField} name={'emailValue'} id={'emailValue'} label="Enter new email" />
              </FormGroup>
              <FormGroup>
                <Field component={TextboxField} name={'emailConfirm'} id={'emailConfirm'} label="Confirm new email" />
              </FormGroup>
            </form>
            <p className="txt-sm mute">
              Upon submission, you will receive an email notifcation to accept or deny this update. If confirmed, all
              updates are pending admin review. You will be notified when your submission has been processed.
            </p>
          </BootstrapModal.Body>
          <BootstrapModal.Footer className="modal-footer-justified">
            <Button
              variant="secondary"
              onClick={() => {
                resetForm();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={(values: any) => handleSubmit(values)}>
              Submit
            </Button>
          </BootstrapModal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
