import _ from 'lodash';
import { StorageBlocksPath, BlockConfiguration, IStorageEngine, BlockGroupState, Layouts } from '@tradingblock/types';

interface BlockData {
  blocks: BlockConfiguration[];
  groups: BlockGroupState[];
  layouts: Layouts;
}
export const trySaveBlocks = async (storage: IStorageEngine, dashboardId: string, data: BlockData) => {
  try {
    if (dashboardId === undefined) {
      console.warn('trying to save blocks with an undefined dashboardId');
    }
    const blockData = {
      ...data,
      blocks: _.map(data.blocks, b => ({ ...b, subscriptions: undefined })),
    };
    return storage.save<BlockData>(StorageBlocksPath(dashboardId), blockData).then(res => (_.isArray(res) ? res : []));
  } catch (ex) {
    console.error('trySaveBlocks', ex);
    return Promise.resolve([]);
  }
};
