import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { BasicSelect, OptionType } from '@tradingblock/components';
import { useStateSelector } from '../../data/global/dataSelectors';
import { useDispatch } from 'react-redux';
import { UIActions } from '../../data/global/actions';

export const SubAccountFilter: React.FunctionComponent<{
  setSubAccountId: (subAccountId: number) => void;
  className?: string;
}> = ({ setSubAccountId, className }) => {
  const subAccounts = useStateSelector(s => s.account.subaccounts.subaccounts) || [];
  const subAccountIds = subAccounts.map(s => {
    const { Id, Nickname } = s;
    return {
      label: Nickname,
      id: Id.toString(),
      data: Id.toString(),
    };
  });
  const accountOptions: OptionType<string | undefined>[] = subAccountIds
    .map((s: any) => ({
      value: s.id,
      label: s.label,
      data: s.data,
    }))
    .reverse()
    .concat([{ value: 'All', label: 'All', data: '-1' }])
    .reverse();

  const currentAccount = useMemo(() => {
    const firstAccount = _.first(accountOptions);
    if (firstAccount) {
      return firstAccount.label;
    }
    const defaultAccount = { value: 'All', label: 'All', data: '-1' };
    return defaultAccount;
  }, [accountOptions]);

  const dispatch = useDispatch();
  const subAccountFilterId = useStateSelector(s => s.ui.dashboard.subAccountFilterId);
  const subAccountFilterIdAccount = subAccountFilterId
    ? accountOptions.find(a => a.value === subAccountFilterId.toString()) || { value: 'All', label: 'All', data: '-1' }
    : null;

  const onSubAccountChange = useCallback(
    (val: OptionType | null | undefined) => {
      const subAccountId = _.isNil(val) ? undefined : parseInt(val.data);
      if (subAccountId) {
        setSubAccountId(subAccountId);
        dispatch(UIActions.setSubAccountFilterId({ id: subAccountId }));
      }
    },
    [setSubAccountId]
  );

  useEffect(() => {
    if (subAccountFilterId) {
      setSubAccountId(subAccountFilterId);
    }
  }, [subAccountFilterId]);

  return (
    <div className={`table-toolbar table-toolbar-top sub-account-filter ${className ? className : ''}`}>
      <div className="table-toolbar-secondary">
        <BasicSelect
          className="btn btn-dark btn-tall dropdown-toggle"
          options={accountOptions}
          onChange={onSubAccountChange}
          placeholder={currentAccount}
          value={subAccountFilterIdAccount}
        />
      </div>
    </div>
  );
};
