import { useMemo } from 'react';
import _ from 'lodash';
import { OrderState } from '../state/OrderState';
import { SymbolOccBasicSelector } from '../state/OrderLegSelector';
import { getQuoteMetadata } from '../../../data/global/selectors/quoteSelector';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { useQuoteBidAsk } from '../../../hooks/useFeedQuote';
import { useBlockData } from '../../../components/blocks/BlockState';

export const useBidAsk = (legId: string) => {
  const blockState = useBlockData<OrderState>();
  // const log = useLog('useBidAsk');
  const occSymbol = useMemo(() => SymbolOccBasicSelector(blockState, legId) || '', [legId, blockState]);
  const quote = useStateSelector(s => getQuoteMetadata(s, occSymbol));
  const feedQuote = useQuoteBidAsk(occSymbol);

  // log(`${occSymbol} %o`, { quote, feedQuote, symbolQuote });

  const result = useMemo(() => {
    const feed = feedQuote || { BidPrice: undefined, AskPrice: undefined };
    const quo = quote || { BidPrice: undefined, AskPrice: undefined };
    const bids = [feed.BidPrice, quo.BidPrice];
    const asks = [feed.AskPrice, quo.AskPrice];
    return {
      bid: _.find(bids, v => _.isNumber(v)),
      ask: _.find(asks, v => _.isNumber(v)),
    };
  }, [quote, feedQuote]);
  // useWhyDidYouUpdate(`useBidAsk:${legId}:${occSymbol}`, { quote, feedQuote, blockState, occSymbol, result });
  return result;
};
