/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo } from 'react';
import { Dropdown, DropdownItem, newId } from '@tradingblock/components';
import { OrderAction, OptionType, AssetSymbol, BlockType, AssetType, GroupOrderLeg, Expiration } from '@tradingblock/types';
import { useDispatcher } from '../../data/global/hooks';
import { useBlockMetadata } from '../../components/blocks/BlockState';
import { generateNewBlock } from '../../utilities/block';
import { AddRemoveFavorite } from './AddRemoveFavorite';
import { OptionChainField } from './OptionChainTypes';
import { CustomStrategy } from '@tradingblock/api';
import { useStateSelector, blocksSelectors } from '../../data/global/dataSelectors';
import { BlockGroupActions } from '../../data/global/actions';

interface OptionChainOrderDropdownProps {
  symbol: AssetSymbol;
  occSymbol: string;
  optionType: OptionType;
  expiration: Expiration;
  strike: number;
  orderActionForBid: OrderAction;
  orderBlocksExist?: boolean;
  field: OptionChainField;
}

export const OptionChainOrderDropdown: React.FunctionComponent<OptionChainOrderDropdownProps> = ({ occSymbol, symbol, field, optionType, expiration, strike, orderActionForBid, orderBlocksExist, children }) => {
  const { blockId, groupId } = useBlockMetadata();
  const isLinked = useStateSelector(s => blocksSelectors.isLinked(s)(blockId, BlockType.Order));
  const orderAction: OrderAction = useMemo(() => {
    if ((field === 'Bid') === (orderActionForBid === OrderAction.Buy)) {
      return OrderAction.Buy;
    }
    return OrderAction.Sell;
  }, [field, orderActionForBid]);
  const orderActionName = useMemo(() => (orderAction === OrderAction.Buy ? 'BUY' : 'SELL'), [orderAction]);

  const { dispatcher, dispatch } = useDispatcher();

  const orderLeg: GroupOrderLeg = useMemo(() => {
    const id = newId();
    return {
      UniqueId: id,
      Id: id,
      OptionType: optionType,
      Action: orderAction,
      Strike: strike,
      Expiration: expiration,
      UnderlyingSymbol: symbol.symbol,
      AssetType: optionType ? AssetType.Option : AssetType.Equity,
      SpreadRatio: 1,
    };
  }, [optionType, orderAction, symbol.symbol, expiration, strike]);

  const onAddLegToOrder = useCallback(
    (mode?: 'new' | 'link') => {
      if (mode === 'new') {
        // ... then add new order block, preserving group state
        // const orderBlock = generateNewBlock(BlockType.Order, { ...{ legs: { [orderLeg.Id]: orderLeg } }, symbol });
        const orderBlock = generateNewBlock(BlockType.Order, { ...{ legs: { [orderLeg.Id]: orderLeg }, strategy: CustomStrategy.info.Name, quantity: 1 }, symbol });
        dispatcher.block.addOrUpdateBlock({ ...orderBlock, groupId }, false);
      } else {
        // ... then add new leg to group state
        // make sure group symbol is set first
        if (mode === 'link') {
          // ... then turn on 'linking' mode, only allowing linking to Order blocks
          dispatch(BlockGroupActions.SetSymbolAndReset({ leg: orderLeg, groupId, symbol }));
          dispatcher.grid.toggleLinking(true, { blockId, groupId }, { type: BlockType.Order });
        } else {
          dispatcher.blockGroup.addGroupLeg(groupId, orderLeg);
        }
        //dispatcher.blockGroup.setSymbol(groupId, symbol);
        //dispatcher.blockGroup.addGroupLeg(groupId, orderLeg);
      }
    },
    [blockId, groupId, symbol, orderLeg]
  );

  const onFavoriteAdd = () => {
    const favoritesBlock = generateNewBlock(BlockType.Favorites);
    dispatcher.block.addOrUpdateBlock({ ...favoritesBlock, groupId }, true, { blockType: BlockType.Favorites });
  }

  const optionSymbol = useMemo(() => ({ ...symbol, symbol: occSymbol }), [symbol]);
  return (
    <Dropdown content={children} direction={optionType === OptionType.Put ? 'right' : 'left'} buttonOptions={{ toggleButtonClassName: 'table-inline-btn' }}>
      {!isLinked && <DropdownItem onClick={() => onAddLegToOrder('new')}>Create new order ({orderActionName})</DropdownItem>}
      {!isLinked && orderBlocksExist && <DropdownItem onClick={() => onAddLegToOrder('link')}>Link to existing order&hellip;</DropdownItem>}
      {isLinked && <DropdownItem onClick={() => onAddLegToOrder()}>Add to order ({orderActionName})</DropdownItem>}
      {symbol !== undefined && <AddRemoveFavorite onFavoriteAdd={onFavoriteAdd} symbol={optionSymbol} />}
    </Dropdown>
  );
};
