import { useMemo, useEffect, useRef } from 'react';
import { Dispatch } from 'redux';
import _ from 'lodash';
import { HttpStorageFactory, HttpProviderType } from '@tradingblock/storage';
import { HttpProviderConfig, IStorageProvider, ClientVersionHeader } from '@tradingblock/types';
import { StorageProvider } from '@tradingblock/storage/dist/storageProvider';
import { Config } from '../config';
import { useAuthenticationToken, uiApiTokenAndVersion } from '../data/global/dataSelectors';
import { DataState } from '../data/global/state';
import { RootAction, Actions } from '../data/global/actions';
import { ApiIntercepts } from '../data/api/apiClientFactory';

export const StorageProviderFactory = (apiUrl: string, authToken: string) =>
  HttpStorageFactory({
    responseIntercept: resp => {
      const clientVersion = resp.headers[ClientVersionHeader];
      return resp;
    },
    url: apiUrl,
    authToken,
  });

export const UiApiClient = (state: DataState, dispatch: Dispatch<RootAction>) => {
  const { token, version } = uiApiTokenAndVersion(state);
  if (token) {
    const apiClient = HttpStorageFactory({
      responseIntercept: resp => {
        try {
          const clientVersion = resp.headers[ClientVersionHeader];
          if (_.isString(clientVersion)) {
            if (version === undefined) {
              dispatch(Actions.setClientVersion({ version: clientVersion }));
            } else if (_.toLower(clientVersion) !== _.toLower(version)) {
              const payload = { previous: version, current: clientVersion };
              console.debug('version mismatch detected', payload);
              dispatch(Actions.clientVersionMismatch(payload));
            }
          } else {
            console.debug('version not set', clientVersion);
          }
        } catch (ex) {
          console.warn('api response version check errored', ex);
        }

        return resp;
      },
      responseInterceptError: ApiIntercepts(dispatch).onResponseError,
      url: Config.uiApi,
      authToken: token,
    });
    return apiClient;
  }
  return undefined;
};

const InvalidProviderException = { message: 'provider type doesnt have correct kind' };

export const UiApiHttpClient = (state: DataState, dispatch: Dispatch<RootAction>): HttpProviderType => {
  const client = UiApiClient(state, dispatch);
  const providerKind = _.get(client, 'kind', undefined);
  if (providerKind !== 'http') {
    throw InvalidProviderException;
  }
  return client as HttpProviderType;
};

export const useStore = (): IStorageProvider<HttpProviderConfig> => {
  const authToken = useAuthenticationToken();
  if (!authToken) {
    const error = { error: 'Authentication token not set' };
    throw error;
  }
  const apiUrl = useMemo(() => Config.uiApi, []);
  const storeRef = useRef<StorageProvider<HttpProviderConfig>>(StorageProviderFactory(apiUrl, authToken));
  useEffect(() => {
    if (apiUrl && authToken) {
      storeRef.current = new StorageProvider({ kind: 'http', url: apiUrl, authToken });
    }
  }, [apiUrl, authToken]);
  // return useHttpStore(apiUrl, authToken);
  // return useMemo(() => storeRef.current, [storeRef]);
  return storeRef.current;
};
