import React, { FC } from 'react';
import { useStateSelector } from '../../data/global/dataSelectors';
import _ from 'lodash';
import { SignedNumber } from '@tradingblock/components';
import { PerformanceSelectors } from '../../data/global/selectors/performanceSelectors';
export const ActivitySummaryTotals: FC = () => {
  const todayPnL = useStateSelector(s => PerformanceSelectors.totals.tradedToday(s).total);
  const totalPnL = useStateSelector(s => PerformanceSelectors.totals.all(s).total);
  const notTradedPnL = totalPnL - todayPnL;
  return (
    <ul className="inline-list">
      <li>
        Not traded <strong>today</strong>: Daily P/L
        <SignedNumber value={notTradedPnL} format currency hideIcon />
      </li>
      <li>
        Total Daily P/L:
        <SignedNumber value={totalPnL} format currency hideIcon />
      </li>
    </ul>
  );
};
