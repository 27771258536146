import { BasicDropdown, BasicSelect, CheckboxInput, CheckListInput } from '@tradingblock/components';
import { Order, OrderLeg, TradingblockOrder } from '@tradingblock/types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useStateSelector } from '../../../../data/global/dataSelectors';

export interface AdminCommissionOrderActionsProps {
  order: Order<OrderLeg> | TradingblockOrder | undefined;
  setOrder: (order: Order<OrderLeg> | TradingblockOrder) => void;
  setCommissionFilled: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AdminCommissionOrderActions({
  order,
  setOrder,
  setCommissionFilled,
}: AdminCommissionOrderActionsProps) {

  const userLevel = useStateSelector(s => s.account.profile.current && s.account.profile.current.level);
  const isAdmin = userLevel === 'Admin';
  const [placeAs, setPlaceAs] = useState<'Unsolicited' | 'Solicited' | 'Discretionary' | undefined>(isAdmin ? undefined : 'Unsolicited');
  const [executeAs, setExecuteAs] = useState<'Agent' | 'Principal' | 'Undefined'>('Agent');
  const [commission, setCommission] = useState<'Online' | 'Assisted' | 'Override' | 'Undefined'>('Online');
  const [commissionOverrideCode, setCommissionOverrideCode] = useState<undefined | { label: string; value: number }>();
  const currentUserProfileCommissionCodes = useStateSelector(state =>
    state.account.profile.current ? state.account.profile.current.commissionCodes : undefined
  );
  const commissionCodes = useMemo(() => {
    return currentUserProfileCommissionCodes
      ? Object.entries(currentUserProfileCommissionCodes).map(([commissionLabel, commissionCode]) => ({
        label: commissionLabel,
        value: commissionCode,
      }))
      : undefined;
  }, [currentUserProfileCommissionCodes]);
  const accountId = useStateSelector(s => s.account.accountId);

  const handleSetCommissionFilled = useCallback(
    (value: boolean) => {
      setCommissionFilled(value);
    },
    [setCommissionFilled]
  );

  useEffect(() => {
    if (order !== undefined) {
      // Make sure all the options are selected before admin can send it
      if (placeAs !== undefined && executeAs !== 'Undefined' && commission !== 'Undefined') {
        // If the user has Override commission, make sure the code is set too before allowing
        // them to place an order.
        if (commission === 'Override' && commissionOverrideCode === undefined) {
          handleSetCommissionFilled(false);
        } else {
          handleSetCommissionFilled(true);
        }
      } else {
        handleSetCommissionFilled(false);
      }
    }
  }, [placeAs, executeAs, commission, commissionOverrideCode]);

  useEffect(() => {
    if (order !== undefined) {
      setOrder({
        ...order,
        PlacedAs: placeAs,
        ExecutedAs: executeAs,
        CommissionType: commission,
        CommissionScheduleId: commissionOverrideCode ? commissionOverrideCode.value : undefined,
      });
    }
  }, [])

  return (
    <div>
      <div>
        <span>Placed As:</span>
        <CheckListInput
          value={placeAs}
          onchange={(_, value) => {
            if (order !== undefined) {
              setPlaceAs(value);
              setOrder({
                ...order,
                PlacedAs: value as any,
              })
            }
          }}
          type="radio"
          options={[
            {
              value: 'Unsolicited',
              label: 'Unsolicited',
            },
            {
              value: 'Solicited',
              label: 'Solicited',
            },
            {
              value: 'Discretionary',
              label: 'Discretionary',
            },
          ]}
        />
      </div>
      <div>
        <span>Executed As:</span>
        <CheckListInput
          value={executeAs}
          onchange={(_, value) => {
            if (order !== undefined) {
              setExecuteAs(value);
              setOrder({
                ...order,
                ExecutedAs: value as any,
              })
            }
          }}
          type="radio"
          options={[
            {
              value: 'Agent',
              label: 'Agent',
            },
            {
              value: 'Principal',
              label: 'Principal',
            },
          ]}
        />
      </div>
      <div>
        <span>Commission:</span>
        <CheckListInput
          value={commission}
          onchange={(_, value) => {
            if (order !== undefined) {
              setCommission(value);
              setOrder({
                ...order,
                CommissionType: value as any,
              })
            }
          }}
          type="radio"
          options={[
            {
              value: 'Online',
              label: 'Online',
            },
            {
              value: 'Assisted',
              label: 'Assisted',
            },
            {
              value: 'Override',
              label: 'Override',
            },
          ]}
        />
        {commission === 'Override' && commissionCodes && (
          <div
            style={{
              position: 'relative',
              top: '-44px',
              left: '112px',
              width: 'fit-content',
            }}
          >
            <BasicSelect
              className="btn btn-dark btn-tall dropdown-toggle"
              options={commissionCodes}
              value={commissionOverrideCode}
              onChange={(selectedOption: { label: string; value: number }) => {
                if (order !== undefined) {
                  setCommissionOverrideCode(selectedOption);
                  setOrder({
                    ...order,
                    CommissionScheduleId: selectedOption.value,
                  })
                }
              }}
              isSearchable={false}
            />
          </div>
        )}
      </div>
    </div>
  );
}
