import React, { useEffect, useMemo } from 'react';
import { useActiveBlockTab } from '../../components/blocks/BlockAccessors';
import { ActivityOrderContent } from './ActivityOrderContent';
import { ActivityMessages } from './ActivityMessages';
import { ActivitySummary } from './ActivitySummary';
import { accountIdSelector, subAccountIdSelector, useStateSelector } from '../../data/global/dataSelectors';
import { areOrdersLoaded, getOrders } from '../../data/global/selectors/orderSelector';
import { Loading, EmptyBlockContent } from '@tradingblock/components';
import { useBlockId } from '../../components/blocks/BlockState';
import { useActivityTimeframeValues } from './data/useActivityTimeframe';
import { useActivityBlockData, useActivitySettingsValue } from './useActivitySettings';
import { useDispatch } from 'react-redux';
import { Actions } from '../../data/global/actions';

export const ActivityContent: React.FunctionComponent<{}> = () => {
  const activeTab = useActiveBlockTab();
  const accountId = useStateSelector(accountIdSelector);
  const subaccountId = useStateSelector(subAccountIdSelector);
  const blockId = useBlockId();
  const accountOrders = useStateSelector(getOrders);
  const { workingOrdersOnly } = useActivitySettingsValue();
  const { startDate, endDate } = useActivityTimeframeValues();
  const { pageSize, pageIndex } = useActivityBlockData();
  const dispatch = useDispatch();
  const tab = useMemo(() => (activeTab ? activeTab.title : undefined), [activeTab ? activeTab.title : undefined]);
  const isTradesTab = useMemo(() => tab === 'Trades', [tab]);
  const isMessagesTab = tab === 'Messages';

  useEffect(() => {
    // Initially fetch orders for the block and establish redux state for each block, refetch when accountOrders changes (e.g. when a new order is placed)
    if (accountId) {
      dispatch(
        Actions.requestAccountOrders(
          {
            accountId,
            subaccountId,
            dateFrom: startDate,
            dateTo: endDate,
            includeOrderEvents: isMessagesTab ? true : false,
            pageSize,
            pageIndex,
            blockId,
            isTradesTab,
            workingOrdersOnly: workingOrdersOnly,
          },
          { debounce: undefined }
        )
      );
    }
  }, [
    accountOrders,
    workingOrdersOnly,
    startDate,
    endDate,
    pageSize,
    pageIndex,
    accountId,
    subaccountId,
    isTradesTab,
    isMessagesTab,
  ]);

  const isLoaded = useStateSelector(areOrdersLoaded);
  return (
    <>
      {isLoaded ? (
        <>
          {tab === 'Messages' && <ActivityMessages />}
          {tab === 'Orders' && (
            <ActivityOrderContent tabType="orders" />
          )}
          {tab === 'Trades' && (
            <ActivityOrderContent tabType="trades" />
          )}
          {tab === 'Summary' && <ActivitySummary />}
        </>
      ) : (
        <EmptyBlockContent>{<Loading />}</EmptyBlockContent>
      )}
    </>
  );
};
