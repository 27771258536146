import React from 'react';
import _ from 'lodash';
import { Svg } from '@tradingblock/components';
import { DashboardNotification } from './DashboardNotification';
import { useStateSelector } from '../../data/global/dataSelectors';
import { getOpenOrdersCount } from '../../data/global/selectors/orderSelector';

interface TradeOrderNotificationsProps {
  onClick: () => void;
}

export const TradeOrderNotifications: React.FunctionComponent<TradeOrderNotificationsProps> = ({ onClick }) => {
  const orderCount = useStateSelector(s => getOpenOrdersCount(s));

  return (
    <DashboardNotification title={`Orders${orderCount ? ` - ${orderCount} open` : ''}`} notificationClass="tb-icon-counter-value-orders" notifications={[]} onClick={onClick}>
      <span className="tb-icon tb-icon-stack-2x">
        <Svg path="icon-clipboard" />
      </span>
      <span className="tb-icon tb-icon-stack-1x">
        <Svg path="icon-list" />
      </span>
      {_.isNumber(orderCount) && orderCount > 0 && <span className="tb-icon-counter-value tb-icon-counter-value-orders">{orderCount}</span>}
    </DashboardNotification>
  );
};
