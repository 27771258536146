import { useStateSelector, isFavorite } from '../data/global/dataSelectors';
import { DataState } from '../data/global/state';

import { useDispatcher } from '../data/global/hooks';
import { useCallback } from 'react';
import { AssetSymbolInfo } from '@tradingblock/types';

type FavoriteSymbol = [boolean, () => void];

export const useFavorite = (symbol: AssetSymbolInfo): FavoriteSymbol => {
  const symbolIsFavorite = useStateSelector((s: DataState) => isFavorite(s, symbol.symbol));

  const { dispatcher } = useDispatcher();
  const addRemoveFavorite = useCallback(() => {
    if (symbol) {
      if (symbolIsFavorite) {
        dispatcher.favorites.remove(symbol.symbol);
      } else {
        dispatcher.favorites.add(symbol);
      }
    }
  }, [dispatcher, symbolIsFavorite, symbol]);
  return [symbolIsFavorite, addRemoveFavorite];
};
