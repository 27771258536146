import { RootAction, Actions } from '../actions';
import { InitialState } from '../initialState';
import { EnvironmentState } from '../state';
import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { EnvironmentActions } from '../actions/EnvironmentActions';

export const environmentReducer = combineReducers<EnvironmentState>({
  isVirtual: (state = InitialState.environment.isVirtual, action: RootAction) => {
    switch (action.type) {
      case getType(Actions.receiveToken): {
        return action.payload.environment === 'virtual' ? true : false;
      }
      case getType(EnvironmentActions.toggleIsVirtual): {
        return state === true ? false : true;
      }
      default: {
        return state;
      }
    }
  },
});
