import React, { useMemo } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { DetailField, DetailFields } from '../../../../components/basic/DetailFields';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { DefaultEntitlementsItem } from '@tradingblock/types';
import { Loading } from '@tradingblock/components';

export const EntitlementRequestDetails: React.FunctionComponent<{}> = () => {
  const details = useStateSelector(s => s.private.entitlements.currentItem.details) || DefaultEntitlementsItem;
  const isFetching = useStateSelector(s => s.private.entitlements.currentItem.isFetching);

  const {
    AccountNumber,
    UserId,
    FirstName,
    LastName,
    RequestDateTime,
    RequestedMarketDataEntitlementType,
    PrimaryAccountIdAssociatedWithUser,
    ApprovedUserMarketDataEntitlementType,
    UserLevel,
    UserName,
    ApprovingUserName,
  } = details;

  const isPending = ApprovingUserName === undefined || ApprovingUserName === null ? true : false;

  const fields = useMemo(() => {
    let fieldList: DetailField[] = [];
    if (details) {
      fieldList = [
        ...fieldList,
        {
          Value: () => (
            <>
              <span className="fields-title">Entitlement Request Details</span>
            </>
          ),
        },

        {
          Value: () => null,
        },

        {
          Value: () => (
            <>
              <span className="props-title">Account Number</span>
              <br />
              <span className="mute">{AccountNumber}</span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="props-title">User Id</span>
              <br />
              <span className="mute">{UserId}</span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="props-title">Name</span>
              <br />
              <span className="mute">{`${FirstName} ${LastName}`}</span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="props-title">User Name</span>
              <br />
              <span className="mute">{UserName}</span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="props-title">Primary Account Id Associated With User</span>
              <br />
              <span className="mute">{PrimaryAccountIdAssociatedWithUser}</span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="props-title">User Level</span>
              <br />
              <span className="mute">{UserLevel}</span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="props-title">Request Date</span>
              <br />
              <span className="mute">
                {dayjs(RequestDateTime).format('MM/DD/YYYY')} - {dayjs(RequestDateTime).format('h:mm A')}
              </span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="props-title">Requested Entitlement</span>
              <br />
              <span className="mute">
                {RequestedMarketDataEntitlementType &&
                  RequestedMarketDataEntitlementType.replace(/([A-Z])/g, ' $1').trim()}
              </span>
            </>
          ),
        },

        {
          Value: () => (
            <>
              <span className="props-title">Approved User Entitlement</span>
              <br />
              <span className="mute">
                {!isPending
                  ? ApprovedUserMarketDataEntitlementType &&
                    ApprovedUserMarketDataEntitlementType.replace(/([A-Z])/g, ' $1').trim()
                  : 'Pending approval'}
              </span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="props-title">Approving User</span>
              <br />
              <span className="mute">{!isPending ? ApprovingUserName : 'Pending approval'}</span>
            </>
          ),
        },
      ];
    }
    return fieldList;
  }, [details, isFetching]);

  if (isFetching) return <Loading />;

  return (
    <div className="screen-body-section">
      <DetailFields fieldList={fields} />
    </div>
  );
};
