import React, { useMemo } from 'react';
import _ from 'lodash';
import { GenericError, isResponseCodeDescription } from '@tradingblock/types';

export const CashieringDetailError: React.FC<{ error?: GenericError }> = ({ error }) => {
  const title = useMemo(() => _.startCase(error && error.error && isResponseCodeDescription(error.error) ? error.error.name : undefined), [error]);
  return (
    <div className="screen-body-section">
      <p className="fields-title">
        <span className="neg">Error{error && `: ${title}`}</span>
      </p>
      {error && <p>{error.message}</p>}
    </div>
  );
};
