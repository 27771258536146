import _toConsumableArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import * as tslib_1 from "tslib";
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { CheckboxInput } from '.';
export var CheckListInput = function CheckListInput(_a) {
  var options = _a.options,
      value = _a.value,
      className = _a.className,
      onchange = _a.onchange,
      rest = tslib_1.__rest(_a, ["options", "value", "className", "onchange"]);

  var name = rest.name,
      type = rest.type;

  var _useState = useState(value),
      _useState2 = _slicedToArray(_useState, 2),
      checklistValue = _useState2[0],
      setChecklistValue = _useState2[1];

  useEffect(function () {
    setChecklistValue(value);
  }, [value]);

  var onChange = function onChange(val) {
    var allValues = _.includes(checklistValue, val) ? _.filter(checklistValue, function (v) {
      return v !== val;
    }) : [].concat(_toConsumableArray(_.isArray(checklistValue) ? checklistValue : [checklistValue]), [val]); // enforce single selection for radio

    var newValues = type !== 'checkbox' ? val : allValues;

    if (onchange) {
      onchange(undefined, newValues);
    } else {
      setChecklistValue(newValues);
    }
  };

  return React.createElement("ol", {
    className: "checklist ".concat(className || '')
  }, options.map(function (o, i) {
    return React.createElement("li", {
      key: "checklist-".concat(i)
    }, React.createElement(CheckboxInput, Object.assign({
      id: "".concat(name, ".").concat(o.value),
      checked: _.isArray(checklistValue) ? _.includes(checklistValue, o.value) : checklistValue === o.value,
      label: o.label,
      onchange: function onchange() {
        return onChange(o.value);
      }
    }, rest)));
  }));
};