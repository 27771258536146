import React, { useCallback, useEffect } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import { useBlockActionDispatchAndGenericActions } from '../../components/blocks/BlockState';
import { InfoBlockAction, InfoBlockActions } from './state/InfoBlockAction';

import { MarketNewsTab } from './MarketNews';
import { ActiveAlertsTab } from './ActiveAlerts';
import { useInfoData, InfoBlockConstants, InfoBlockDataState, isInfoTab } from './state/InfoState';
import { Loading } from '@tradingblock/components';
import { ReleaseNotesTable } from './components/ReleaseNotesTable';

const { releaseNotes, newFeatures, alerts } = InfoBlockConstants.tabs;

export const InfoContent: React.FunctionComponent<{}> = ({}) => {
  const tab = useInfoData(s => s.tab || 'newFeatures');
  const isLoaded = useInfoData(s => s.isLoaded);

  const [, blockDispatch] = useBlockActionDispatchAndGenericActions<InfoBlockDataState, InfoBlockAction>();
  const setTab = useCallback(
    (ev: string) => {
      if (isInfoTab(ev)) {
        blockDispatch(InfoBlockActions.setTab(ev));
      }
    },
    [blockDispatch]
  );

  return (
    <>
      {!isLoaded && <Loading />}
      {isLoaded && (
        <div className="nav-tabs-pill">
          <Tabs defaultActiveKey={tab} id="notification-tabs" onSelect={(ev: string) => setTab(ev)}>
            <Tab eventKey={newFeatures} title="Market News">
              {tab === newFeatures && <MarketNewsTab />}
            </Tab>
            <Tab eventKey={releaseNotes} title="Release Notices">
              {tab === releaseNotes && (
                <div id="releaseNotesTable">
                  <ReleaseNotesTable />
                </div>
              )}
            </Tab>
            <Tab eventKey={alerts} title="Active Alerts">
              {tab === alerts && <ActiveAlertsTab />}
            </Tab>
          </Tabs>
        </div>
      )}
    </>
  );
};
