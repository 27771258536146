import React, { useMemo } from 'react';
import { AssetSymbol } from '@tradingblock/types';
import { Number } from '@tradingblock/components';
import { useFeedQuoteWithMetadata } from '../../hooks/useFeedQuote';
import { DirectionalPrice } from '../../components/trading/DirectionalPrice';
import { isIndexSymbol } from '@tradingblock/api';

const OrderField: React.FunctionComponent<{ label: string; cssClass: string }> = ({ label, cssClass, children }) => (
  <li className={cssClass}>
    <span className="mute prop-title">{label}</span> <span className="prop-value">{children}</span>
  </li>
);

export const SymbolOrders: React.FunctionComponent<{ symbol: AssetSymbol }> = ({ symbol }) => {
  const feedQuote = useFeedQuoteWithMetadata(symbol ? symbol.symbol : '');
  const symbolQuote = useMemo(() => {
    const defaultQuote = { BidPrice: undefined, High: 'NA', AskPrice: undefined, Low: 'NA', Volume: 'NA' };
    if (symbol) {
      return feedQuote || defaultQuote;
    }
    return defaultQuote;
  }, [feedQuote, symbol]);
  const isIndex = isIndexSymbol(symbol);

  return (
    <ul className="props props-cols-auto txt-sm">
      <OrderField label="Bid" cssClass="prop-stacked block-quote-bid">
        {!isIndex && (
          <>
            <DirectionalPrice value={symbolQuote.BidPrice} wrapperClass="price" />{' '}
            <span className="txt-sm nb">
              x <Number value={feedQuote.BidSize} integer />
            </span>
          </>
        )}
      </OrderField>
      <OrderField label="Ask" cssClass="prop-stacked block-quote-ask">
        {!isIndex && (
          <>
            <DirectionalPrice value={symbolQuote.AskPrice} wrapperClass="price" />{' '}
            <span className="txt-sm nb">
              x <Number value={feedQuote.AskSize} integer />
            </span>
          </>
        )}
      </OrderField>
      <OrderField label="Open" cssClass="prop-stacked block-quote-open">
        <Number value={feedQuote.Open} currency />
      </OrderField>
      <OrderField label="High" cssClass="prop-stacked block-quote-high">
        <Number value={symbolQuote.High} currency />
      </OrderField>
      <OrderField label="Low" cssClass="prop-stacked block-quote-low">
        <Number value={symbolQuote.Low} currency />
      </OrderField>
      <OrderField label="Prev. Close" cssClass="prop-stacked block-quote-close">
        <Number value={feedQuote.ClosePrice} currency />
      </OrderField>
    </ul>
  );
};
