import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BlockBody, Loading, Tab, Tabs } from '@tradingblock/components';
import { BalanceHistory, BlockType, TabOptions } from '@tradingblock/types';
import { AnalyticsChart } from './AnalyticsChart';
import { getBlockProps } from '../../utilities/block';
import { Block } from '../../components/blocks/Block';
import { useActiveBlockTab } from '../../components/blocks/BlockAccessors';
import { AnalyticsBalanceHistory } from './AnalyticsBalanceHistory';
import { accountIdSelector, useStateSelector } from '../../data/global/dataSelectors';
import { AnalyticsCashFlow } from './AnalyticsCashFlow';
import { Timeframe } from '../../components/basic/Timeframe';
import { exportToCsv } from './AnalyticsHelper';
import useSWR from 'swr';
import { useApi } from '../../context/Api';
import { GetBalancesRequestParams } from '@tradingblock/api/src/commands/historyGetBalance';
import dayjs from 'dayjs';
import { useActivitySettings } from '../Activity/useActivitySettings';
import { AnalyticsHeader } from './AnalyticsHeader';
import { isToday } from '../../utilities/date';
import CircularProgressBar from '../../components/basic/CircularProgressBar';

export const AnalyticsBlock: React.FC = (): JSX.Element => {
  const [dateRange, setDateRange] = useState({
    start: new Date(dayjs().year() - 1, dayjs().month(), dayjs().date()),
    end: new Date(dayjs().year(), dayjs().month(), dayjs().date()),
  });
  const [subTab, setSubTab] = useState('Chart');
  const { current, definitions } = useActivitySettings();
  const activeTab = useActiveBlockTab();
  const tab = useMemo(() => (activeTab ? activeTab.title : 'Balance History'), [activeTab]);
  const ActivityTabs: TabOptions[] = [{ title: 'Balance History', active: true }, { title: 'Cash Flow' }];
  const handleTimeFrame = (start = new Date(), end = new Date()): void => setDateRange({ start: start, end: end });
  const selectedAccountId = useStateSelector(accountIdSelector);

  const [currentPage, setCurrentPage] = useState(0);
  const [currentChartPage, setCurrentChartPage] = useState(0);
  const [pageSize, setPageSize] = useState<5 | 10 | 25 | 50>(25);
  const accountId = useStateSelector(accountIdSelector);
  const [isChartDataLoaded, setIsChartDataLoaded] = useState(false);
  const [allBalances, setAllBalances] = useState<BalanceHistory[]>([]);
  const [progress, setProgress] = useState(0);
  const timeoutRef = useRef<number | null>(null);
  const [chartError, setChartError] = useState<string | undefined>(undefined);

  // historical information is not available for the current day
  // if the end date is today, set the end date to yesterday
  // we don't need to worry about weekends or holidays as they will carry over the previous day's balance
  // if the endDate is in the future, set the end date to yesterday
  const endDateValue = isToday(dateRange.end)
    ? dayjs(dateRange.end)
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
    : dayjs(dateRange.end).isAfter(dayjs())
    ? dayjs()
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
    : dayjs(dateRange.end).format('YYYY-MM-DD');

  const balanceRequestParams: GetBalancesRequestParams = {
    accountId: accountId,
    startDate: dayjs(dateRange.start).format('YYYY-MM-DD'),
    endDate: endDateValue,
    sortBy: 'RecordDate',
    sortByOrder: 'Desc',
    page: currentPage,
    pageSize: pageSize,
  };

  const api = useApi();
  const { data, error, isLoading } = useSWR(balanceRequestParams, api.history.getBalances);

  const filteredBalances = data && data.payload && data.payload.data ? data.payload.data : [];

  /**
   * useDelay - Custom hook for creating a cancellable delay in a React component.
   *
   * This hook returns a `delay` function that resolves a promise after a specified
   * number of milliseconds. It ensures proper cleanup of the timeout when the
   * component unmounts or if the delay is cancelled, preventing potential memory
   * leaks and state update issues on unmounted components.
   *
   * Usage:
   * const delay = useDelay();
   * await delay(1000); // Creates a 1-second delay
   *
   * Returns:
   * A function that takes a number of milliseconds and returns a promise that
   * resolves after that delay.
   */
  const useDelay = () => {
    const timerRef = useRef<number | null>(null);

    const delay = useCallback((ms: number) => {
      return new Promise<void>(resolve => {
        timerRef.current = window.setTimeout(resolve, ms);
      });
    }, []);

    const clearDelay = useCallback(() => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    }, []);

    useEffect(() => {
      return () => {
        clearDelay();
      };
    }, [clearDelay]);

    return delay;
  };

  const delay = useDelay();

  // we need to pull in all of the balance items for a selected period
  // however, they are currently paginated responses from the API with a max of 50 items per page
  // so we need to batch multiple requests to the API to get all of the data and display it in the chart
  // the table will only show the data for the current page
  const fetchDataForChart = async () => {
    let allBalances: BalanceHistory[] = [];
    let currentPage = 0;
    const perPage = 50;
    const totalItems = data && data.payload && data.payload.total;
    const totalPages = totalItems && Math.ceil(totalItems / perPage);

    while (totalPages && currentPage <= totalPages && totalItems && allBalances.length < totalItems) {
      try {
        const { payload } = await api.history.getBalances({
          ...balanceRequestParams,
          page: currentPage,
          pageSize: perPage,
        });
        allBalances = allBalances.concat(payload.data);
        currentPage++;
        setCurrentChartPage(currentPage);
        const currentProgress = Math.round((currentPage / totalPages) * 100);
        setProgress(currentProgress);
        // Wait for the visual transition to complete before updating the progress again
        await delay(500); // Match this with the CSS transition duration
      } catch (error) {
        setChartError('Error fetching balance history. Please wait for a few moments and try again.');
        setProgress(0);
        setCurrentChartPage(0);
        setIsChartDataLoaded(true);
        break;
      }
    }
    // Ensure the final update to 100% happens after the visual transition
    timeoutRef.current = window.setTimeout(() => {
      setProgress(100);
    }, 500); // Again, match with the CSS transition duration
    // set the state to true so we don't fetch the data again
    setAllBalances(allBalances);
    setIsChartDataLoaded(true);
    return allBalances;
  };

  useEffect(() => {
    if (data && data.payload && data.payload.total && !isChartDataLoaded) {
      fetchDataForChart();
      setCurrentPage(0);
      // Cleanup function
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }
  }, [data, isChartDataLoaded]);

  // need to reset the chart data when the date range changes
  useEffect(() => {
    setIsChartDataLoaded(false);
    setAllBalances([]);
    setCurrentChartPage(0);
    setCurrentPage(0);
  }, [dateRange && dateRange.start, dateRange && dateRange.end, selectedAccountId]);

  useEffect(() => {
    // If the progress is 100%, clear the timeout and set the progress back to 0
    if (progress === 100) {
      timeoutRef.current = window.setTimeout(() => {
        setProgress(0);
      }, 500);
    }
  }, [progress]);

  // put the filtered balances in ascending order by date in a new array
  // lightweight charts requires the data to be in ascending order by date
  // but we need the data in descending order by date for the table
  const sortedBalances =
    allBalances && [...allBalances].sort((a, b) => new Date(a.recordDate).getTime() - new Date(b.recordDate).getTime());

  const handlePageChange = (pageIndex: number): void => {
    setCurrentPage(pageIndex);
  };

  const handlePageSizeChange = (pageSize: number): void => {
    setPageSize(pageSize as 5 | 10 | 25 | 50);
  };

  return (
    <Block
      isRefreshable={true}
      {...getBlockProps(
        BlockType.Analytics,
        <AnalyticsHeader handleDownloadCSV={() => exportToCsv(filteredBalances, BlockType.Analytics)} />,
        ActivityTabs
      )}
      settings={current}
      settingFields={definitions}
    >
      <BlockBody className="block-body chart-container">
        {(isLoading || data === undefined) && <Loading />}
        {!isLoading && data !== undefined && !error && (
          <>
            <div>
              <Timeframe
                initialEndDate={dateRange.end}
                initialStartDate={dateRange.start}
                caret={<i className={'fas fa-caret-down'} />}
                onSave={handleTimeFrame}
              />
              {tab === 'Balance History' && (
                <span className="analytics-pill nav-tabs-pill">
                  <Tabs defaultActiveKey={subTab} id="notification-tabs" onSelect={(ev: string) => setSubTab(ev)}>
                    <Tab eventKey={'Chart'} title="Chart" />
                    <Tab eventKey={'Table'} title="Table" />
                  </Tabs>
                </span>
              )}
            </div>

            {/* Circular Loading Bar for fetching multiple pages of data */}
            {((subTab === 'Chart' && tab === 'Balance History') || tab === 'Cash Flow') &&
              filteredBalances &&
              filteredBalances.length !== 0 &&
              !isChartDataLoaded && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    flexDirection: 'column',
                  }}
                >
                  <CircularProgressBar progress={progress} />
                  <div>Loading balance data...</div>
                </div>
              )}

            {tab === 'Cash Flow' && (filteredBalances && filteredBalances.length > 0) && (
              <>
                {isChartDataLoaded && filteredBalances && (
                  <AnalyticsCashFlow
                    handleTimeFrame={handleTimeFrame}
                    dateRange={{ end: dateRange.end!, start: dateRange.start! }}
                    filteredBalances={sortedBalances ? sortedBalances : []}
                    pageIndex={currentPage}
                    pageSize={pageSize}
                    handlePageSizeChange={handlePageSizeChange}
                    total={data.payload.total}
                  />
                )}
              </>
            )}

            {(filteredBalances === undefined || (filteredBalances && filteredBalances.length === 0)) && (
              <>
                <BlockBody>
                  <div className="no-graph-data">No data for current period</div>
                </BlockBody>
              </>
            )}
            {subTab === 'Chart' && tab === 'Balance History' && filteredBalances && filteredBalances.length !== 0 && (
              <>
                {isChartDataLoaded && (
                  <>
                    {chartError && (
                      <div className="no-graph-data" style={{ color: 'red' }}>
                        {chartError}
                      </div>
                    )}
                    {!chartError && (
                      <AnalyticsChart
                        filteredBalances={sortedBalances ? sortedBalances : []}
                        dateRange={{ end: dateRange.end!, start: dateRange.start! }}
                      />
                    )}
                  </>
                )}
              </>
            )}
            {subTab === 'Table' && tab === 'Balance History' && filteredBalances && filteredBalances.length > 0 && (
              <AnalyticsBalanceHistory
                handleTimeFrame={handleTimeFrame}
                filteredBalances={filteredBalances}
                pageIndex={currentPage}
                pageSize={pageSize}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
                total={data.payload.total}
              />
            )}
            {error && <div>Error getting data</div>}
          </>
        )}
      </BlockBody>
    </Block>
  );
};
