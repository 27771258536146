export var useElementPosition = function useElementPosition(element) {
  if (!element) {
    return undefined;
  }

  var rect = element.getBoundingClientRect();
  var scrollX = window.scrollX;
  var scrollY = window.scrollY;
  return {
    left: scrollX + rect.left,
    top: scrollY + rect.top,
    width: rect.width,
    height: rect.height
  };
};