import React from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import { Modal } from '@tradingblock/components';
import { useStateSelector } from '../data/global/dataSelectors';
import { useDispatch } from 'react-redux';
import { SessionActions } from '../data/global/actions';
import _ from 'lodash';

export const ErrorModal: React.FunctionComponent = () => {
  const reason = useStateSelector(s => (s.errors && s.errors.reason) || undefined);
  const reasonIsLogout = _.includes(reason, 'logged out');
  const isAuthExpired = useStateSelector(s => s.auth.isExpired);
  const message = useStateSelector(s => s.errors && s.errors.message);

  const dispatch = useDispatch();
  let urlPaths: string[] | undefined = undefined;
  let isTransferRequest: boolean = false;

  // URL Parsing to display unauthorized action
  const actionUrl = useStateSelector(s => s.errors && s.errors.url);

  if (actionUrl) {
    const [basePath] = actionUrl.split('?');
    urlPaths = basePath
      .split('/')
      .filter(p => p.replace(/\d+/g, '') && p !== '' && p !== 'RestHub' && p !== 'api' && p !== 'v1');
    //
    urlPaths = urlPaths.map(p => p.replace(/([A-Z])/g, ' $1').trim() && _.startCase(p));
    // Check if transfer request, urlPaths will be ['Cashiering', 'Transfers']
    // This is to prevent showing error modal when transfer request fails as certain accounts will always fail due to lack of permissions to cashiering no matter the approval level of the rep/admin attempting to access the transfers
    isTransferRequest = urlPaths.length === 2 && urlPaths[0] === 'Cashiering' && urlPaths[1] === 'Transfers';
  }

  const show = useStateSelector(
    s => s.errors && s.errors.showModal === true && !reasonIsLogout && !isAuthExpired && !isTransferRequest
  );

  const closeModal = (e: any) => {
    e.preventDefault();
    dispatch(SessionActions.resetError({ code: 0 }));
  };

  return (
    <Modal show={show} setShow={() => true} backdrop="static">
      <BootstrapModal.Header>
        <BootstrapModal.Title>Unexpected Error</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <div className="container">
          <div className="row" style={{ alignItems: 'center' }}>
            <div className="col-2">
              <i className="fal fa-exclamation-circle neg" style={{ fontSize: '3em' }} />
            </div>
            <div className="col-10">
              <p>{message}</p>
              {urlPaths && urlPaths.length > 0 && <strong>Action: {urlPaths && urlPaths.join(' - ')}</strong>}
            </div>
          </div>
        </div>
      </BootstrapModal.Body>
      <BootstrapModal.Footer>
        <button className="btn btn-primary" onClick={e => closeModal(e)}>
          Close
        </button>
      </BootstrapModal.Footer>
    </Modal>
  );
};
