import { Modal, ModalProps } from '@tradingblock/components';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { subAccountIdSelector, useStateSelector } from '../../data/global/dataSelectors';
import { AccountActions } from '../../data/global/actions/AccountActions';
import useSWRMutation from 'swr/mutation';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { Subaccount } from '@tradingblock/api/src/commands/subaccounts';
import { useApi } from '../../context/Api';
import { ResponseCodes } from '@tradingblock/types';
import { isSameDate } from '../../utilities/date';
import dayjs from 'dayjs';
import { Actions, DataActions } from '../../data/global/actions';

export interface DeleteSubAccountModelProps {
  setShow: ModalProps['setShow'];
  show: ModalProps['show'];
  initialSubAccountState: Subaccount;
}

export function DeleteSubAccountModel({ setShow, show, initialSubAccountState }: DeleteSubAccountModelProps) {
  const api = useApi();
  const dispatch = useDispatch();
  const orders = useStateSelector(state => state.account.orders);
  const pos = useStateSelector(state => state.positions);
  const globalSubAccountId = useStateSelector(subAccountIdSelector);
  const { AccountId, Id, DistributionRatio } = initialSubAccountState;
  const [errorState, setErrorState] = useState<string>('');
  const hasOpenOrdersMessage = 'Please close all open orders before attempting to delete.';
  const hasPositionsMessage = 'Please Journal Positions to other accounts before attempting to delete.';
  const hasDistributionMessage = 'Please Update the Distributions to other accounts before attempting to delete.';
  const [hasDistribution] = useState<boolean>(DistributionRatio ? DistributionRatio !== 0 : false);
  const [hasPositions] = useState<boolean>(
    pos.positions.findIndex(p => p.SubaccountId && p.SubaccountId === Id) !== -1
  );
  const [hasOpenOrders] = useState<boolean>(
    orders &&
      orders.findIndex(o =>
        o.SubaccountId
          ? (o.SubaccountId === Id && (o.OrderStatus && [16, 18, 20, 21, 22].includes(o.OrderStatus))) ||
            (o.OrderStatus &&
              [1, 2, 6, 7, 11, 14].includes(o.OrderStatus) &&
              (o.Duration === 'GTC' || o.Date ? isSameDate(dayjs(o.Date), dayjs()) : false))
          : false
      ) !== -1
      ? true
      : false
  );
  /*  This is the rule that the hasOpenOrders is 
SELECT	s.Id as OrderId
  FROM	Strategy s WITH (NOLOCK)
LEFT JOIN	StrategySubaccounts AS ss  with(nolock) ON ss.StrategyId = s.Id
--INNER JOIN	GetVisibleAccountsByUser(@UserId) fn ON (fn.AccountId = s.AccountId)
 WHERE	s.AccountId = 31791 
   AND	ss.SubaccountId = 4759
   AND	((OrderStatusId IN (16, 18, 20, 21, 22)) OR 
    (OrderStatusId IN (1, 2, 6, 7, 11, 14)) AND (DurationId = 2 OR convert(varchar, CreateDate, 101) = convert(varchar, GETDATE(), 101)))  
    */

  const deleteSubAccountFetcher = useCallback(
    (key: any, { arg }) => {
      return api.subaccounts.deleteSubaccount(arg);
    },
    [api]
  );

  const {
    data: deleteSubAccountResult,
    trigger: triggerDeleteSubAccount,
    isMutating,
    error: deleteSubAccountRequestError,
  } = useSWRMutation('delete-sub-account', deleteSubAccountFetcher);

  const onSubmit = async () => {
    if (!hasPositions && !hasDistribution && !hasOpenOrders) {
      let result = await triggerDeleteSubAccount({ accountId: AccountId, subaccountId: Id });
      if (result && result.responseCode === 0) {
        if (globalSubAccountId === Id) {
          const subaccountId = undefined;
          const accountId = AccountId;
          dispatch(AccountActions.setSubAccount({ subaccountId }));
          dispatch(Actions.requestAccountBalances({ throttle: false }));
          dispatch(DataActions.requestPositions({ accountId, subaccountId, more: true }, { debounce: 1000 }));
          dispatch(Actions.requestAccountOrders({ accountId, subaccountId }, { debounce: 2000 }));
        }
        dispatch(AccountActions.requestSubAccounts({ accountId: AccountId }));
      }
    }
  };

  useEffect(() => {
    if (deleteSubAccountResult) {
      if (deleteSubAccountResult.responseCode !== 0) {
        if ((deleteSubAccountResult.payload as unknown) as string[])
          setErrorState(((deleteSubAccountResult.payload as unknown) as string[]).join('\n'));
        else if (ResponseCodes[deleteSubAccountResult.responseCode])
          setErrorState(ResponseCodes[deleteSubAccountResult.responseCode].description);
        else setErrorState('Error Code ' + deleteSubAccountResult.responseCode);
      } else {
        setShow(false);
      }
    }
  }, [deleteSubAccountResult]);

  useEffect(() => {
    if (deleteSubAccountRequestError !== undefined) {
      setErrorState(deleteSubAccountRequestError.message);
    }
  }, [deleteSubAccountRequestError]);

  return (
    <Modal setShow={setShow} show={show}>
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>Delete Subaccount</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <div style={{ color: 'red' }}>{errorState}</div>
        {hasPositions && <div style={{ color: 'red' }}>{hasPositionsMessage}</div>}
        {hasDistribution && <div style={{ color: 'red' }}>{hasDistributionMessage}</div>}
        {hasOpenOrders && <div style={{ color: 'red' }}>{hasOpenOrdersMessage}</div>}
        <div>Please review and confirm you want to delete the following Sub-Account.</div>
        <div>Account ID: {initialSubAccountState.AccountId}</div>
        <div>Sub-Account ID: {initialSubAccountState.Id}</div>
        <div>Nickname: {initialSubAccountState.Nickname}</div>
        <div>Balance: {initialSubAccountState.Number}</div>
        <div>Distribution Ratio: {initialSubAccountState.DistributionRatio}%</div>
        <div>Description: {initialSubAccountState.Description}</div>
      </BootstrapModal.Body>
      <BootstrapModal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={onSubmit}
          form={'edit-sub-account-form'}
          disabled={isMutating || hasPositions || hasDistribution || hasOpenOrders}
        >
          Delete
        </Button>
      </BootstrapModal.Footer>
    </Modal>
  );
}
