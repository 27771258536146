import { Middleware, Dispatch } from 'redux';
import { getType } from 'typesafe-actions';
import { OrderAction } from '@tradingblock/types';
import { DataState } from '../state';
import { RootAction, BlockActions, BlockGroupActions } from '../actions';
import { blockSettings } from '../selectors/settingSelectors';
import { getBlockAndGroupIdForUpdate, getNewOrderBlock, getNewPriceChartBlock } from '../utilities/blockStateUtilities';
import { GroupManager } from '../../../context/GroupManager';
import { OrderInitializerActions, OrderGroupActions } from '../../../blocks/Order/state/OrderActions';
import { ChartInitializerActions } from '../../../blocks/PriceChart/state/PriceChartActions';
import { isOrderBlock, isChartBlock } from '../../../utilities/block';

export const GroupMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  try {
    // state BEFORE action is dispatched
    const prevState = getState();

    switch (action.type) {
      case getType(BlockGroupActions.SetSymbolAndReset): {
        const { leg, groupId } = action.payload;
        GroupManager.dispatch(groupId, action);
        if (leg && groupId) {
          //GroupManager.dispatch(groupId, OrderInitializerActions.reset({ legs: { [leg.Id]: leg }, quantity: 1, symbol: symbol, strategy: CustomStrategy }));
        }
        break;
      }
      case getType(BlockGroupActions.addGroupLeg): {
        const { leg, groupId } = action.payload;
        if (leg && groupId) {
          GroupManager.dispatch(groupId, OrderGroupActions.addOrderLegFromGroup({ leg }, { persist: true }));
        }
        break;
      }
      case getType(BlockGroupActions.createOrderFromGroup): {
        const { groupId } = action.payload;
        GroupManager.dispatch(groupId, BlockGroupActions.createOrderFromGroup(action.payload, { persist: true }));
        break;
      }
      case getType(BlockGroupActions.setGroupSymbol): {
        const { groupId, symbol } = action.payload;
        const defaultOrderSettings = blockSettings.order(prevState, undefined);
        GroupManager.dispatch(
          groupId,
          OrderGroupActions.setSymbol(
            { symbol, options: { defaultToEmptyCustomIfSymbolChanges: false, settings: defaultOrderSettings } },
            { persist: true }
          )
        );
        break;
      }
      case getType(BlockActions.addOrUpdateBlock): {
        const blockToUpdate = getBlockAndGroupIdForUpdate(prevState.blocks, action);
        const block = action.payload.block;

        // Order block
        if (isOrderBlock(block)) {
          const settings = blockToUpdate
            ? blockSettings.order(prevState, block.blockId)
            : blockSettings.order(prevState, block.blockId);

          const result = next(action);

          // const legs = block.data.legs ? _.values(block.data.legs) : undefined;
          const blockData = {
            settings,
            quantity: block.data.quantity,
            strategy: block.data.strategy,
            orderType: block.data.orderType,
            legs: block.data.legs,
            symbol: block.data.symbol,
            action: block.data.action || OrderAction.Buy,
          };

          if (blockToUpdate) {
            // Existing Order block
            GroupManager.dispatch(blockToUpdate.groupId, OrderInitializerActions.reset(blockData));
          } else {
            // New Order block
            const newBlock = getNewOrderBlock(prevState, getState());
            if (newBlock) {
              //GroupManager.dispatch(newBlock.groupId, BlockGroupActions.createOrderFromGroup({ quantity: block.data.quantity, strategy: block.data.strategy, legs: block.data.legs, symbol: block.data.symbol }));
              GroupManager.dispatch(newBlock.groupId, OrderInitializerActions.reset(blockData));
            } else {
              console.log('doing nothing for action', action);
            }
          }
          return result;
        }
        // Chart block
        else if (isChartBlock(block)) {
          const settings = blockToUpdate
            ? blockSettings.pricechart(prevState, block.blockId)
            : blockSettings.pricechart(prevState, block.blockId);
          //const newSettings = blockSettings.pricechart(prevState, block.blockId);
          //const settings = {defaultStrategy: "Custom", defaultQuantityForOptionsAndStrategies: 1, defaultQuantityForShares: 1, disableSounds: false};

          const result = next(action);

          // const legs = block.data.legs ? _.values(block.data.legs) : undefined;
          const blockData = {
            settings,
            symbol: block.data.symbol,
          };

          if (blockToUpdate) {
            // Existing Chart block
            GroupManager.dispatch(blockToUpdate.groupId, ChartInitializerActions.reset(blockData));
          } else {
            const newBlock = getNewPriceChartBlock(prevState, getState());
            if (newBlock) {
              //GroupManager.dispatch(newBlock.groupId, BlockGroupActions.createOrderFromGroup({ quantity: block.data.quantity, strategy: block.data.strategy, legs: block.data.legs, symbol: block.data.symbol }));
              GroupManager.dispatch(newBlock.groupId, ChartInitializerActions.reset(blockData));
            } else {
              console.log('doing nothing for action', action);
            }
            return result;
          }
        }
      }
      default: {
      }
    }
    // state AFTER action is dispatched
    return next(action);
  } catch (err) {
    console.error('GroupMiddleware :: Caught an exception for action ', action, err);
  }
};
