import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import { SizeType } from '@tradingblock/types';
import { useWhyDidYouUpdate, useWindowSizeContext } from '@tradingblock/components';
import { BlockDimensions } from '../../types';

function useBbox<T extends Element>(timeout?: number): [{ width: number; height: number } | undefined, React.MutableRefObject<T | null>] {
  const ref = useRef<T>(null);
  // const [bbox, setBbox] = useState<ClientRect | DOMRect>();
  const [bbox, setBbox] = useState<{ width: number; height: number }>();

  useEffect(() => {
    const set = () => {
      if (ref.current) {
        const currBbox = ref.current.getBoundingClientRect();
        if (!_.isEqual(bbox, currBbox)) {
          return setBbox(ref && ref.current ? { width: currBbox.width, height: currBbox.height } : undefined);
        }
      }
    };
    set();
    let updateTimeout: NodeJS.Timeout | undefined = undefined;
    if (ref && ref.current) {
      const resizeObs = _.get(window, 'ResizeObserver', undefined);
      if (typeof resizeObs === 'function') {
        let resizeObserver: ResizeObserver | null = new resizeObs(() => {
          if (updateTimeout) {
            clearTimeout(updateTimeout);
          }
          updateTimeout = setTimeout(() => set(), timeout || 250);
        });
        resizeObserver.observe(ref.current);
        return () => {
          if (updateTimeout) {
            clearTimeout(updateTimeout);
          }
          if (!resizeObserver) {
            return;
          }

          resizeObserver.disconnect();
          resizeObserver = null;
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeout]);

  return [bbox, ref];
}

const defaultDimensions: BlockDimensions = {
  width: SizeType.lg,
  height: SizeType.lg,
  widthClassName: `block-width-lg`,
  heightClassName: `block-height-lg`,
};
export function useBboxDimensions<T extends Element>(timeout?: number): [BlockDimensions, React.MutableRefObject<T | null>] {
  const [bbox, ref] = useBbox<T>(timeout);
  // even though window size not used for dim calculation, still need to use as dep so we update size when window size changes
  const windowSize = useWindowSizeContext();
  const [dimensions, setDimensions] = useState<BlockDimensions>(defaultDimensions);
  useEffect(() => {
    let width: SizeType = dimensions.width;
    let height: SizeType = dimensions.width;
    if (bbox && windowSize) {
      if (bbox.width && bbox.width < 670) {
        width = SizeType.sm;
      } else if (bbox.width && bbox.width < 850) {
        width = SizeType.md;
      } else {
        width = SizeType.lg;
      }
      if (bbox.height && bbox.height < 200) {
        height = SizeType.sm;
      } else if (bbox.width && bbox.height < 500) {
        height = SizeType.md;
      } else {
        height = SizeType.lg;
      }
    }
    const newDimensions = {
      width,
      height,
      bboxWidth: bbox && bbox.width,
      bboxHeight: bbox && bbox.height,
      widthClassName: `block-width-${width}`,
      heightClassName: `block-height-${height}`,
    };
    if (!_.isEqual(dimensions, newDimensions)) {
      setDimensions(newDimensions);
    }
  }, [bbox, dimensions, windowSize]);

  useWhyDidYouUpdate('useBboxDimensions', { bbox, windowSize, dimensions, ref });
  return [dimensions, ref];
}

export function useBlockSize<T = number>(dimensions: BlockDimensions, widthOrHeight: 'width' | 'height', values: [T, T, T]) {
  if (dimensions[widthOrHeight] === SizeType.sm) {
    return values[0];
  } else if (dimensions[widthOrHeight] === SizeType.md) {
    return values[1];
  } else {
    return values[2];
  }
}
