import React from 'react';
import { EntitlementsTable } from './components/EntitlementsTable';

export const AdminEntitlementsIndex = () => {
  return (
    <div>
      <EntitlementsTable />
    </div>
  );
};
