import React from 'react';
import {
  CashieringView,
  CashieringRoute,
  CashieringManageAction,
  AccountData,
  TransferData,
} from '@tradingblock/types';
import { useAuthorizeView } from '../AuthorizeView';
import { useWithdrawalAmountView } from '../WithdrawalAmountView';
import { useWithdrawalTypeView } from '../WithdrawalTypeView';
import { useWithdrawalReviewView } from '../WithdrawalReviewView';
import { useDepositAmountView } from '../DepositAmountView';
import { useDepositTypeView } from '../DepositTypeView';
import { useDepositReviewView } from '../DepositReviewView';
import { useManageView } from '../ManageView';
import { useOAuthPlaidRedirectView } from '../OAuthPlaidRedirectView';

export interface CashieringViewProps<T extends {}> {
  values: Partial<T>;
  setValues: (values: Partial<T>) => void;
}

export type CashieringViewFormValues = {
  account?: AccountData;
  transfer?: TransferData;
  authorizeFailed?: boolean;
};

export type ViewOnSubmitType = (values: CashieringViewFormValues, view: CashieringView | undefined) => void | true;
export type ViewOnValidateType = (values: CashieringViewFormValues) => object;

export const useCashieringView = (
  route: CashieringRoute,
  view: CashieringView | undefined,
  action: CashieringManageAction | undefined
): [
  React.FC<CashieringViewProps<CashieringViewFormValues>>,
  ViewOnSubmitType | undefined,
  ViewOnValidateType | undefined
] => {
  const AuthorizeView = useAuthorizeView();
  const WithdrawalAmountView = useWithdrawalAmountView();
  const WithdrawalTypeView = useWithdrawalTypeView(action);
  const WithdrawalReviewView = useWithdrawalReviewView();
  const DepositAmountView = useDepositAmountView();
  const DepositTypeView = useDepositTypeView(action);
  const DepositReviewView = useDepositReviewView();
  const ManageView = useManageView();
  const OAuthPlaidRedirectView = useOAuthPlaidRedirectView();

  switch (view) {
    case CashieringView.Authorize:
      return AuthorizeView;
    case CashieringView.Amount:
      return route === CashieringRoute.Withdraw ? WithdrawalAmountView : DepositAmountView;
    case CashieringView.Type:
      return route === CashieringRoute.Withdraw ? WithdrawalTypeView : DepositTypeView;
    case CashieringView.Review:
      return route === CashieringRoute.Withdraw ? WithdrawalReviewView : DepositReviewView;
    case CashieringView.Manage:
      return ManageView;
    case CashieringView.OAuthPlaidRedirect:
      return OAuthPlaidRedirectView;
    default:
      if (view) {
        throw new Error('Invalid TransferView type: ' + view);
      }
      return [() => null, undefined, undefined];
  }
};
