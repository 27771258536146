import { useStore } from '../context/Storage';
import { useEffect, useState, useCallback } from 'react';
import { objects, DebugSettings } from '@tradingblock/types';
import { setLogLevel } from '@tradingblock/components';

export const useDebugLevel = (): [string, (val: string) => void] => {
  const apiStore = useStore();
  const [debugLevel, setDebugLevel] = useState<string>('');
  useEffect(() => {
    apiStore.get<DebugSettings>(objects.debugSettings).then(res => {
      if (res) {
        setDebugLevel(res.level || '');
      }
    });
  }, []);
  const setLevel = useCallback(
    (level?: string) => {
      const debugLvl = level || '';
      setLogLevel(debugLvl);
      setDebugLevel(debugLvl);
      apiStore.save(objects.debugSettings, { level: debugLvl });
    },
    [setDebugLevel]
  );
  return [debugLevel, setLevel];
};
