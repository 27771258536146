import React from 'react';
import { UpdateState } from '@tradingblock/types';
import _ from 'lodash';

export const StatusClassMap: Partial<{ [key in UpdateState]: string }> = {
  Approved: 'pos',
  Complete: 'pos',
  Rejected: 'neg',
  Cancelled: 'mute',
  Canceled: 'mute',
  PendingConfirm: 'pending',
  PendingFirmReview: 'pending',
};

export const AccountUpdateItemStatus: React.FunctionComponent<{ state?: UpdateState; className?: string }> = ({
  state,
  className,
  children,
}) => {
  const statusClass = state ? StatusClassMap[state] || '' : '';
  return <span className={`${statusClass} ${className}`}>{children || _.startCase(state)}</span>;
};
