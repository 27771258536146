import React from 'react';
import { Number } from '@tradingblock/components';
import { DirectionalValue, DirectionalValueProps } from '../basic/Directional';

export const DirectionalPrice: React.FunctionComponent<DirectionalValueProps> = ({ value, precisionOverride, ...directionProps }) => {
  return (
    <DirectionalValue  {...directionProps} value={value}>
      <Number value={value} prefix={directionProps.prefix ? directionProps.prefix : ''} precisionOverride={precisionOverride} currency />
    </DirectionalValue>
  );
};
