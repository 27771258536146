import React, { FC } from 'react';
import { Number } from '@tradingblock/components';
import { DirectionalValue, DirectionalOptions } from '../../components/basic/Directional';

export const DirectionalCurrency: FC<{ value: number; options?: DirectionalOptions }> = ({ value, options }) => {
  const currencyLabel = value === 0 ? '$' : value >= 0 ? '+$' : '-$';
  return (
    <DirectionalValue value={value} threshold={0} options={options}>
      <strong className="txt-lg">
        <span className="currency">{currencyLabel}</span>
        <Number value={Math.abs(value)} format />
      </strong>
    </DirectionalValue>
  );
};
