import dayjs from 'dayjs';
import _ from 'lodash';
import { AssetType, OrderLeg, Order, OptionExpirationType, OptionType } from '@tradingblock/types';
import { OCCSymbolToOptionData, deriveOptionExpirationType } from '@tradingblock/api';

/**
 * This type contains the majority of the data needed
 * to fill out the "details" section of the table in the
 * ActivityOrders and ActivityTrades tab of the activity block
 */
export interface OrderLegDetails {
  day?: string;
  month?: string;
  year?: string;
  strikePrice?: string;
  orderType?: string;
  symbol?: string;
  quantity?: string;
}

export function optionToDetailString<O extends OrderLeg>(leg: O, order: Pick<Order, 'Quantity' | 'UnderlyingSymbol'>) {
  const { Quantity, UnderlyingSymbol } = order;
  const { LegFillQuantity } = leg;
  const { month, day, strikePrice, orderType } = orderLegToDetails(leg, UnderlyingSymbol || '');
  const q = LegFillQuantity ? LegFillQuantity : Quantity;
  if (leg.AssetType === AssetType.Equity) {
    return `${q > 1 ? 'SHRS' : 'SHR'}`;
  }
  return `${month} ${day} $${strikePrice} ${orderType}`;
}

/**  This function accepts a string in the
 * format "Symbol DDMMMYY StrikePrice OrderType [quantity]" and converts it to the format shown in the ActivityBlock documentation
 *
 * @param detail a string in the form "Symbol DDMMMYY StrikePrice CALLorPUT [quantity]"
 */
export function orderLegToDetails<O extends OrderLeg>(leg: O, underlyingSymbol: string): OrderLegDetails {
  const occSymbol = leg.OccSymbol ? leg.OccSymbol : leg.Symbol;
  const { symbol, expiration, assetType, strike, option } = OCCSymbolToOptionData(occSymbol);
  if (assetType === AssetType.Equity) {
    return {
      symbol: symbol,
    };
  }

  const d = dayjs(expiration);
  const optionExpirationType = deriveOptionExpirationType(leg.Symbol, underlyingSymbol);
  const day = d.format('DD');
  const dayAndTimeInfo =
    optionExpirationType === OptionExpirationType.AM ? `${day} ${OptionExpirationType[optionExpirationType]}` : day;

  return {
    day: dayAndTimeInfo,
    month: d.format('MMM'),
    year: d.format('YY'),
    strikePrice: _.toString(strike),
    orderType: option ? OptionType[option] : undefined,
    quantity: _.toString(leg.LegFillQuantity),
    symbol: symbol,
  };
}
