import React, { useEffect, useRef } from 'react';
import { createChart, CrosshairMode, IChartApi, SeriesMarker, Time } from 'lightweight-charts';
import { BalanceHistory } from '@tradingblock/types';
import { is } from 'immutable';

interface AnalyticsChartProps {
  dateRange: {
    start: Date;
    end: Date;
  };
  filteredBalances: BalanceHistory[];
}

export const AnalyticsChart: React.FC<AnalyticsChartProps> = ({
  dateRange,
  filteredBalances,
}: AnalyticsChartProps): JSX.Element => {
  const chartContainerRef = useRef<Element & HTMLElement>({} as HTMLElement);
  const chart = useRef<IChartApi | null>(null);
  const resizeObserver = useRef<ResizeObserver>({} as ResizeObserver);

  enum MarkerTypes {
    Deposit = 'D',
    Withdrawal = 'W',
    Dividend = 'DIV',
    CapitalGainsDistribution = 'CG',
    Reinvestment = 'RE',
    JournalEntry = 'JE',
    Interest = 'I',
  }

  const getMarkerType = (markerType: string): string => {
    switch (markerType) {
      case 'Deposit':
        return MarkerTypes.Deposit;
      case 'Withdrawal':
        return MarkerTypes.Withdrawal;
      case 'Dividend':
        return MarkerTypes.Dividend;
      case 'CapitalGainsDistribution':
        return MarkerTypes.CapitalGainsDistribution;
      case 'Reinvestment':
        return MarkerTypes.Reinvestment;
      case 'JournalEntry':
        return MarkerTypes.JournalEntry;
      case 'Interest':
        return MarkerTypes.Interest;
      default:
        return '';
    }
  };

  useEffect(() => {
    chart.current = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
      layout: {
        backgroundColor: 'rgb(3, 18, 55)',
        textColor: 'rgba(255, 255, 255, 0.9)',
        fontSize: 14,
      },
      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          visible: false,
        },
      },
      crosshair: {
        mode: CrosshairMode.Magnet,
      },
    });
  }, []);

  useEffect(() => {
    if (filteredBalances.length && chart.current !== null) {
      const baseLineSeries = chart.current.addBaselineSeries({
        baseValue: { type: 'price', price: 0 },
        lineWidth: 1,
      });
      //marker series
      const balanceHistoryMarkers: SeriesMarker<Time>[] = [];
      //translate filteredBalances items to specific balanceHistory objects and collect matching marker series entries
      const seenDates = new Set();
      let filteredBalanceSeries = filteredBalances
        .map(historyItem => {
          if (historyItem.cashieringTransfers !== null && historyItem.cashieringTransfers !== undefined) {
            historyItem.cashieringTransfers.forEach(cashieringTransfer => {
              balanceHistoryMarkers.push({
                time: historyItem.recordDate,
                position: cashieringTransfer.direction === 'Outgoing' ? 'belowBar' : 'aboveBar',
                color: cashieringTransfer.direction === 'Outgoing' ? 'rgb(237, 103, 103)' : 'rgb(2, 255, 246)',
                shape: cashieringTransfer.direction === 'Outgoing' ? 'arrowUp' : 'arrowDown',
                text: `${
                  cashieringTransfer.direction === 'Outgoing' ? MarkerTypes.Withdrawal : MarkerTypes.Deposit
                } ${(cashieringTransfer.direction === 'Outgoing' ? '-' : '') +
                  '$' +
                  cashieringTransfer.amount
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
                size: 0.5,
              });
            });
          }

          if (historyItem.dividends !== null && historyItem.dividends !== undefined) {
            const seenDividendDates = new Set();
            historyItem.dividends.forEach(dividend => {
              // check if this is the first dividend for the date
              const isFirstDividend = !seenDividendDates.has(historyItem.recordDate);
              isFirstDividend && seenDividendDates.add(historyItem.recordDate);
              balanceHistoryMarkers.push({
                time: historyItem.recordDate,
                position: dividend.amount > 0 ? 'aboveBar' : 'belowBar',
                color: dividend.amount > 0 ? 'rgb(2, 255, 246)' : 'rgb(237, 103, 103)',
                shape: isFirstDividend ? (dividend.amount > 0 ? 'arrowDown' : 'arrowUp') : 'circle',
                text: `${getMarkerType(dividend.type)}\n$${dividend.amount
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
                size: isFirstDividend ? 0.5 : 0.1,
              });
            });
          }
          //return data point for chart series
          return {
            time: historyItem.recordDate,
            value: historyItem.accountValue,
          };
        })
        //filter out duplicate dates
        .filter(historyItem => {
          if (!seenDates.has(historyItem.time)) {
            seenDates.add(historyItem.time);
            return true;
          }
          return false;
        });

      //set data points for series
      baseLineSeries.setData(filteredBalanceSeries);

      //set data points for marker series
      baseLineSeries.setMarkers(balanceHistoryMarkers);
      return () => {
        //remove series on unmount
        if (chart.current !== null) {
          chart.current.removeSeries(baseLineSeries);
        }
      };
    }
  }, [filteredBalances, dateRange, chart]);

  //resize chart on container resizes.
  useEffect(() => {
    if (filteredBalances.length && chart.current !== null) {
      resizeObserver.current = new ResizeObserver(entries => {
        const { width, height } = entries[0].contentRect;
        //right-scale price format function
        //regex adds commas into the price e.g 1000000 -> 1,000,000
        if (chart.current !== null) {
          const priceFormatter = (price: number) =>
            '$' +
            price
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          chart.current.applyOptions({ localization: { priceFormatter: priceFormatter }, width, height });
          setTimeout(() => {
            if (chart.current !== null) {
              chart.current.timeScale().fitContent();
            }
          }, 0);
        }
      });
      resizeObserver.current.observe(chartContainerRef.current);
      return () => resizeObserver.current.disconnect();
    }
  }, [filteredBalances, dateRange, chart]);
  return <div ref={chartContainerRef as React.LegacyRef<HTMLDivElement> | undefined} className="chart-element"></div>;
};
