import _ from 'lodash';
import { createSelector } from 'reselect';
import { InfoBlockSettings } from '@tradingblock/types';
import { AllStrategies } from '@tradingblock/api';
import { SettingsFields } from '../../../components/form/GenericField';
import { DataState } from '../../../data/global/state';

export const DefaultInfoBlockSettings: InfoBlockSettings = {
  dailyNewsfeed: true,
  corporateActionsDividends: false,
  earningsReports: false,
  rumorsAndIdeas: false,
  companyNews: false,
  sectorNews: false,
  ipos: false,
  economicsAndGovernment: false,
  theMarkets: false,
  etfCoverage: false,
  options: false,
};

export const InfoBlockSettingsFields: SettingsFields = {
  dailyNewsfeed: {
    type: 'boolean',
  },
  corporateActionsDividends: {
    type: 'boolean',
    defaultLabelId: 'Corporate Actions/Dividends',
  },
  earningsReports: {
    type: 'boolean',
  },
  rumorsAndIdeas: {
    type: 'boolean',
  },
  companyNews: {
    type: 'boolean',
  },
  sectorNews: {
    type: 'boolean',
  },
  ipos: {
    type: 'boolean',
    defaultLabelId: 'IPOs',
  },
  economicsAndGovernment: {
    type: 'boolean',
  },
  theMarkets: {
    type: 'boolean',
  },
  etfCoverage: {
    type: 'boolean',
    defaultLabelId: 'ETF Coverage',
  },
  options: {
    type: 'boolean',
  },
};

export const defaultInfoBlockSettingsSelector = createSelector(
  (s: DataState) => s.blocks.blockTypeSettings.Info || {},
  defaultInfoBlockState => {
    return {
      ...DefaultInfoBlockSettings,
      ...defaultInfoBlockState,
    };
  }
);
