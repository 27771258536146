import qs from 'qs';
import _ from 'lodash';
import { SearchAccountsRequest, ClientEntity } from '@tradingblock/types';
import { alphabeticalSort } from '../../../utilities/data';

export const clientListRequestToQueryString = (request: SearchAccountsRequest) =>
  qs.stringify(request, { indices: false, sort: alphabeticalSort });

export const clientListRequestKey = (request: SearchAccountsRequest) => clientListRequestToQueryString({ ...request });

export const clientEntityKey = (client: ClientEntity) => `${client.accountNumber}-${client.accountId}`;

export const getClientKey = (re: ClientEntity) => clientEntityKey(re);
