import React from 'react';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';

import { Modal } from '@tradingblock/components';

export const PendingEntitlementModal: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
}> = ({ show, toggleModal }) => (
  <Modal setShow={toggleModal} show={show} backdrop="static">
    <BootstrapModal.Header closeButton>
      <BootstrapModal.Title>Pending Real-time Quotes Request</BootstrapModal.Title>
    </BootstrapModal.Header>
    <BootstrapModal.Body className="pending-entitlement-modal-body">
        <p>Your request to receive real-time quotes is pending review and approval.</p>
        <p>You will be shown delayed quotes until your request for real-time data is approved.</p>
    </BootstrapModal.Body>
    <BootstrapModal.Footer className="modal-footer-justified">
      <Button variant="secondary" onClick={toggleModal}>
        Close
      </Button>
    </BootstrapModal.Footer>
  </Modal>
);
