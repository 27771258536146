import { BasicCheckboxInput } from '@tradingblock/components';
import { BlockType } from '@tradingblock/types';
import React, { useContext, useEffect } from 'react';
import { BlockAction, BlockActionContext, useBlockMetadata } from '../../components/blocks/BlockState';
import { dashboardIdSelector, useStateSelector } from '../../data/global/dataSelectors';
import { useDispatcher } from '../../data/global/hooks';
import { blockSettings } from '../../data/global/selectors/settingSelectors';
import { DataState } from '../../data/global/state';

export const ExpirationsFilter: React.FunctionComponent<{}> = ({}) => {
  const dispatch = useContext(BlockActionContext);
  const { dispatcher } = useDispatcher();

  const { blockId, type } = useBlockMetadata();
  const blockType = type;
  const dashboardId = useStateSelector(dashboardIdSelector);
  // Determine the selector function based on blockType
  const selectorFunction =
    blockType === BlockType.OptionChain
      ? (s: DataState) => blockSettings.optionChain(s, blockId)
      : (s: DataState) => blockSettings.order(s, blockId); // assuming blockSettings.order is the selector for 'Order'

  // Call useStateSelector unconditionally
  const settings = useStateSelector(selectorFunction);

  const { weeklyExpirations, monthlyExpirations, quarterlyExpirations, yearlyExpirations } = settings;

  const handleChange = (expirationFrequency: string) => {
    let settingsSaveData = {
      dashboardId: dashboardId || '',
      blockId,
      blockType,
      blockSettings: {
        ...settings,
      },
    };

    // if user is unchecking the last checkbox, do not allow it
    switch (expirationFrequency) {
      case 'weeklyExpirations':
        if (weeklyExpirations && !monthlyExpirations && !quarterlyExpirations && !yearlyExpirations) {
          return;
        }
        settingsSaveData.blockSettings.weeklyExpirations = !weeklyExpirations;
        break;
      case 'monthlyExpirations':
        if (!weeklyExpirations && monthlyExpirations && !quarterlyExpirations && !yearlyExpirations) {
          return;
        }
        settingsSaveData.blockSettings.monthlyExpirations = !monthlyExpirations;
        break;
      case 'quarterlyExpirations':
        if (!weeklyExpirations && !monthlyExpirations && quarterlyExpirations && !yearlyExpirations) {
          return;
        }
        settingsSaveData.blockSettings.quarterlyExpirations = !quarterlyExpirations;
        break;
      case 'yearlyExpirations':
        if (!weeklyExpirations && !monthlyExpirations && !quarterlyExpirations && yearlyExpirations) {
          return;
        }
        settingsSaveData.blockSettings.yearlyExpirations = !yearlyExpirations;
        break;
    }

    dispatch(BlockAction.setSettings(settingsSaveData.blockSettings));
    dispatcher.settings.block.setBlockSetting(settingsSaveData);
  };

  const isOrderBlock = type === BlockType.Order;
  const linking = useStateSelector(s => s.blocks.linking);
  useEffect(() => {
    if (linking && isOrderBlock) {
      let settingsSaveData = {
        dashboardId: dashboardId || '',
        blockId,
        blockType,
        blockSettings: {
          weeklyExpirations: true,
          monthlyExpirations: true,
          quarterlyExpirations: true,
          yearlyExpirations: true,
          ...settings,
        },
      };
      dispatch(BlockAction.setSettings(settingsSaveData.blockSettings));
      dispatcher.settings.block.setBlockSetting(settingsSaveData);
    }
  }, [linking, isOrderBlock]);

  return (
    <div
      className="expirations-filter"
      style={{
        justifyContent: isOrderBlock ? 'flex-start' : 'flex-end',
        paddingTop: isOrderBlock ? '12px' : undefined,
      }}
    >
      <span className="expirations-filter-item">
        <i className="fal fa-calendar fa-lg expirations-filter-icon" />{' '}
        <span className="checklist-label txt-sm">{isOrderBlock && 'Option '}Expirations:</span>
      </span>
      <BasicCheckboxInput
        className="expirations-filter-item txt-sm"
        checked={weeklyExpirations}
        onchange={() => {
          handleChange('weeklyExpirations');
        }}
      >
        <span className="checklist-label">Weekly</span>
      </BasicCheckboxInput>

      <BasicCheckboxInput
        className="expirations-filter-item txt-sm"
        checked={monthlyExpirations}
        onchange={() => {
          handleChange('monthlyExpirations');
        }}
      >
        <span className="checklist-label">Monthly</span>
      </BasicCheckboxInput>

      <BasicCheckboxInput
        className="expirations-filter-item txt-sm"
        checked={quarterlyExpirations}
        onchange={() => {
          handleChange('quarterlyExpirations');
        }}
      >
        <span className="checklist-label">Quarterly</span>
      </BasicCheckboxInput>

      <BasicCheckboxInput
        className="expirations-filter-item txt-sm"
        checked={yearlyExpirations}
        onchange={() => {
          handleChange('yearlyExpirations');
        }}
      >
        <span className="checklist-label">Yearly</span>
      </BasicCheckboxInput>
    </div>
  );
};
