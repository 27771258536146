import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { CashieringManageAction, CashieringView, TransferInstruction } from '@tradingblock/types';
import { cashieringSelectors } from '../../../../data/global/dataSelectors';
import { AccountListWrapperProps, createGenericAccountList } from './GenericAccountList';
import { RecipientBankForm } from './RecipientBankForm';
import { AccountRenameForm } from './AccountRenameForm';
import { useCashieringDataContext } from '../data/useCashieringData';
import {
  getTransferActionStyle,
  getTransferActionIconClass,
  getFormattedTransferInstruction,
  isRejectedOrCancelledStatus,
  isRejectedCancelledOrPendingStatus,
  isNotYetCreatedOrPendingStatus,
  isRejectedStatus,
} from '../../../../utilities/cashiering';

interface RecipientBankListProps extends AccountListWrapperProps<TransferInstruction> {}

const BaseTransferInstructionsList = createGenericAccountList<TransferInstruction>();

export const RecipientBankList: React.FunctionComponent<RecipientBankListProps> = ({
  values,
  setValues,
  selectable,
  onRename,
  addBtn,
  ...props
}) => {
  const { action, setAction, formStatus } = useCashieringDataContext();

  const isManageView = useMemo(() => formStatus.view === CashieringView.Manage, [formStatus]);

  const transferInstructions = useSelector(cashieringSelectors.transferInstructions);

  // if we are in a manage view, show all transfer instructions. otherwise, only show ones that are not pending/cancelled/rejected
  const selectableTransferInstructions = useMemo(() => {
    if (!transferInstructions) {
      return [];
    }

    // if we are in a manage view, show all transfer instructions. otherwise, only show ones that are not pending/cancelled/rejected
    return transferInstructions.filter(
      (ti: any) => isManageView || (ti.state && !isRejectedOrCancelledStatus(ti.state))
    );
  }, [transferInstructions, isManageView]);

  const isDisabled = !_.isUndefined(action);

  const onSelect = (instructionId: number) => {
    setValues({ transfer: { ...values.transfer, instructionId } });
  };

  const onCloseClick = () => {
    setValues({ ...values, account: undefined });
    setAction(undefined);
  };

  const isEditing = (acct: TransferInstruction) => {
    return values.account && acct.id === values.account.id;
  };

  const isTransferInstructionDisabled = (acct: TransferInstruction) => {
    if (isDisabled) {
      return true;
    }
    return false;
  };

  const getAccountName = (acct: TransferInstruction) => {
    const style = isEditing(acct) ? getTransferActionStyle(action) : undefined;
    // const transferBeneficiary = getTransferBeneficiary(acct);
    return <div style={style}>{getFormattedTransferInstruction(acct)}</div>;
  };

  const getAccountIcon = (acct: TransferInstruction) => {
    const style = isEditing(acct) ? getTransferActionStyle(action) : undefined;
    const icon = (isEditing(acct) && getTransferActionIconClass(action)) || 'fa-check';

    if (acct.state && isNotYetCreatedOrPendingStatus(acct.state)) {
      return (
        <span className="pending" style={style}>
          <i className="fal fa-2x fa-hourglass-half" />
        </span>
      );
    }
    if (acct.state && isRejectedStatus(acct.state)) {
      return (
        <span className="warn">
          <i className="fal fa-2x fa-times" />
        </span>
      );
    }
    return <i className={`fal fa-2x ${icon}`} style={style} />;
  };

  const getAccountStatus = (acct: TransferInstruction) => {
    if (acct.state === 'PendingFirmApproval') {
      return (
        <>
          <span className="pending" style={getTransferActionStyle(CashieringManageAction.Verify)}>
            Pending Firm Approval*
          </span>
          {acct.note && (
            <>
              <br />
              <span className="txt-sm pending">Note: {acct.note}</span>
              <br />
            </>
          )}
        </>
      );
    }

    if (acct.state === 'Pending') {
      return (
        <>
          <span className="pending" style={getTransferActionStyle(CashieringManageAction.Verify)}>
            Pending*
          </span>
          {acct.note && (
            <>
              <br />
              <span className="txt-sm pending">Note: {acct.note}</span>
              <br />
            </>
          )}
        </>
      );
    }

    if (acct.state === 'Rejected') {
      return (
        <>
          <span className="error" style={getTransferActionStyle(CashieringManageAction.Verify)}>
            Rejected* {selectable ? '' : '(please delete)'}
          </span>
          <br />
          {acct.note && <span className="txt-sm error">Note: {acct.note}</span>}
        </>
      );
    }

    if (acct.state === 'Approved') {
      return (
        <>
          <span className="pos bold" style={getTransferActionStyle(CashieringManageAction.Verify)}>
            Active
          </span>
          {onRename && (
            <>
              <br />
              <button className="btn-link" type="button" onClick={() => onRename(acct)} disabled={isDisabled}>
                Rename
              </button>
            </>
          )}
        </>
      );
    }

    if (!selectable && onRename) {
      return (
        <>
          <button className="btn-link" type="button" onClick={() => onRename(acct)} disabled={isDisabled}>
            Rename
          </button>
        </>
      );
    }
    return null;
  };

  return (
    <>
      {action !== CashieringManageAction.Add && (
        <BaseTransferInstructionsList
          {...props}
          selectable={selectable}
          data={selectableTransferInstructions || undefined}
          onSelect={onSelect}
          addBtn={isDisabled ? undefined : addBtn}
          isDisabled={isTransferInstructionDisabled}
          renderName={getAccountName}
          renderIcon={getAccountIcon}
          renderStatus={getAccountStatus}
        />
      )}
      {action && (
        <>
          <hr />

          {action !== CashieringManageAction.Delete && (
            <button
              className="btn btn-blend"
              type="button"
              title="Close"
              onClick={onCloseClick}
              style={{ float: 'right' }}
            >
              <i className="fas fa-2x fa-times-circle" />
            </button>
          )}

          {action === CashieringManageAction.Add && <RecipientBankForm values={values} setValues={setValues} />}
          {action === CashieringManageAction.Rename && <AccountRenameForm mechanism="Wire" />}
          {action === CashieringManageAction.Delete && (
            <p className="fields-title">Confirm delete of saved bank for wire transfers?</p>
          )}
        </>
      )}
    </>
  );
};
