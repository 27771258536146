import React from 'react';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { Modal } from '@tradingblock/components';

interface ScreenWrapperProps {
  showConfirmClose: boolean;
  screenType: string;
  onClose: () => void;
  onCancel: () => void;
}

export const ScreenContentWrapper: React.FunctionComponent<{}> = ({ children }) => {
  return <div className="screen">{children}</div>;
};

export const ScreenWrapper: React.FunctionComponent<ScreenWrapperProps> = ({ children, ...props }) => {
  return (
    <ScreenContentWrapper>
      {children}
      <CloseScreenModal {...props} />
    </ScreenContentWrapper>
  );
};

const CloseScreenModal: React.FunctionComponent<ScreenWrapperProps> = ({ showConfirmClose, screenType, onClose, onCancel }) => {
  return (
    <Modal show={showConfirmClose} setShow={() => true} backdrop="static">
      <BootstrapModal.Header>
        <BootstrapModal.Title>Confirm cancel</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <p>
          Are you sure you want to cancel this {screenType} and close the window? All {screenType} information will be discarded.
        </p>
      </BootstrapModal.Body>
      <BootstrapModal.Footer className="modal-footer-justified">
        <Button variant="secondary" onClick={onCancel}>
          Continue {screenType}
        </Button>
        <Button variant="primary" onClick={onClose}>
          Cancel and close window
        </Button>
      </BootstrapModal.Footer>
    </Modal>
  );
};
