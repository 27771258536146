import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { ExternalLink, ExternalLinkProps } from '@tradingblock/components';
import { useVirtualTrading } from '../../../hooks/useVirtualTrading';
import { isLocalhost } from '../../../utilities/url';
import { useDispatcher } from '../../../data/global/hooks';
import Config from '../../../config';

export const ToggleVirtualTradingButton: React.FC<Pick<ExternalLinkProps, 'className' | 'onClick'>> = ({ className, onClick, children }) => {
  const { isVirtualEnv } = useVirtualTrading();

  const href = useMemo(() => (isVirtualEnv ? Config.liveTradingUrl : Config.virtualTradingUrl), [isVirtualEnv]);

  const { dispatcher } = useDispatcher();

  const onToggleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (isLocalhost() && href.includes(window.location.host)) {
        e.preventDefault();
        dispatcher.environment.toggleIsVirtual();
      }
      if (onClick) {
        onClick(e);
      }
    },
    [href, dispatcher, onClick]
  );

  return (
    <ExternalLink className={className || 'btn-link'} href={href} onClick={onToggleClick}>
      {children}
    </ExternalLink>
  );
};
