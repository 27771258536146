import React, { useCallback, useMemo, useEffect, useState } from 'react';
import _ from 'lodash';
import { useInput, BasicSelect, OptionType } from '@tradingblock/components';
import { Timeframe } from '../../../../components/basic/Timeframe';
import { AccountManagementEntityType, UpdateState, UpdateStates } from '@tradingblock/types';
import { useAccountManagementActions, useAccountManagementData } from '../useAccountManagement';

export const typeOptions: OptionType<AccountManagementEntityType | undefined>[] = [
  { value: 'All', label: 'Type: All', data: undefined },
  { value: 'Address', label: 'Address', data: AccountManagementEntityType.Address },
  { value: 'Account Disclosures', label: 'Account Disclosures', data: AccountManagementEntityType.AccountDisclosures },
  { value: 'Beneficiaries', label: 'Beneficiaries', data: AccountManagementEntityType.Beneficiaries },
  { value: 'Disclosures', label: 'Disclosures', data: AccountManagementEntityType.Disclosures },
  { value: 'Email', label: 'Email', data: AccountManagementEntityType.Email },
  { value: 'Employment', label: 'Employment', data: AccountManagementEntityType.Employment },
  { value: 'Experience', label: 'Experience', data: AccountManagementEntityType.Experience },
  { value: 'Financial', label: 'Financial Info.', data: AccountManagementEntityType.Financial },
  { value: 'Flexibility', label: 'Flexibility', data: AccountManagementEntityType.Flexability },
  { value: 'Industry Employed', label: 'Industry Employed', data: AccountManagementEntityType.IndustryEmployed },
  { value: 'Mailing Address', label: 'Mailing Address', data: AccountManagementEntityType.MailingAddress },
  {
    value: 'Personal Information',
    label: 'Personal Information',
    data: AccountManagementEntityType.PersonalInformation,
  },
  { value: 'Phone Number', label: 'Phone Number', data: AccountManagementEntityType.Phone },
  {
    value: 'Remove Trade Authorization',
    label: 'Remove Trade Authorization',
    data: AccountManagementEntityType.RemoveTradeAuthorization,
  },
  { value: 'Service Profile', label: 'Service Profile', data: AccountManagementEntityType.ServiceProfile },
  { value: 'Suitability', label: 'Suitability', data: AccountManagementEntityType.Suitability },
  { value: 'Trade Authorization', label: 'Trade Authorization', data: AccountManagementEntityType.TradeAuthorization },
  { value: 'Trade Goal', label: 'Trade Goal', data: AccountManagementEntityType.TradeGoal },
  { value: 'Trusted Contact', label: 'Trusted Contact', data: AccountManagementEntityType.TrustedContact },
];

export const statusOptions: OptionType<UpdateState | string | undefined>[] = _(UpdateStates)
  .map(
    (s: UpdateState): OptionType<UpdateState | string | undefined> => ({
      value: s,
      label: s.replace(/([A-Z])/g, ' $1').trim(),
      data: s,
    })
  )
  .union([{ value: 'All', label: 'Status: All', data: undefined }])
  .reverse()
  .value();

export const AccountManagementFilters = () => {
  const { search, timeframe, items, states } = useAccountManagementData();
  const { setTimeframe, setType, setStatus, search: onSearch } = useAccountManagementActions();

  const [searchSubmitted, setSearchSubmitted] = useState(false);

  const onSearchSubmit = useCallback((search: string) => {
    onSearch(search);
    setSearchSubmitted(!_.isEmpty(search));
  }, []);

  const [searchValue, setSearchValue, inputProps] = useInput(undefined, { onEnter: onSearchSubmit });

  const onClearSearch = useCallback(() => {
    onSearchSubmit('');
    setSearchValue('');
  }, []);

  const currentSearch = useMemo(() => {
    if (!_.isUndefined(searchValue)) {
      return searchValue;
    }
    return search || '';
  }, [search, searchValue]);

  const currentType = useMemo(() => {
    const firstType = _.first(items);
    if (firstType) {
      return firstType;
    }
    const defaultType = _.first(typeOptions);
    return defaultType ? defaultType.label : '';
  }, [items, typeOptions]);

  const currentStatus = useMemo(() => {
    const firstStatus = _.first(states);
    if (firstStatus) {
      return firstStatus;
    }
    const defaultStatus = _.first(statusOptions);
    return defaultStatus ? defaultStatus.label : '';
  }, [states, statusOptions]);

  const onTimeframeChange = useCallback((startDate: Date | undefined, endDate: Date | undefined) => {
    if (_.isNil(startDate)) {
      setTimeframe(undefined, undefined);
    } else if (_.isNil(endDate)) {
      setTimeframe(startDate, undefined);
    } else {
      setTimeframe(startDate, endDate);
    }
  }, []);

  const onTypeChange = useCallback((val: OptionType | OptionType[] | null | undefined, { action }) => {
    const types = _.isNil(val) ? [] : _.isArray(val) ? val : [val];
    setType(types.filter(v => v.data !== undefined).map(t => t.data));
  }, []);

  const onStatusChange = useCallback((val: OptionType | OptionType[] | null | undefined, { action }) => {
    const values = _.isNil(val) ? [] : _.isArray(val) ? val : [val];
    setStatus(values.filter(v => v.data !== undefined).map(t => t.data));
  }, []);

  return (
    <div className="table-toolbar table-toolbar-top">
      <div className="table-toolbar-primary">
        <input
          className="input input-dark input-tall"
          type="text"
          placeholder={'Search...'}
          value={currentSearch}
          {...inputProps}
        />
        {currentSearch && (
          <i
            className="fa fa-times fa-2x"
            onClick={onClearSearch}
            style={{ color: 'var(--blue)', cursor: 'pointer', paddingLeft: '0.32em' }}
          />
        )}
      </div>
      <div className="table-toolbar-secondary">
        <Timeframe
          initialStartDate={timeframe.startDate}
          initialEndDate={timeframe.endDate}
          btnClassName="btn-dark btn-tall dropdown-toggle text-left"
          placeholderClassName="select__placeholder"
          caret={<>&nbsp;&nbsp;</>}
          onSave={onTimeframeChange}
        />
        <BasicSelect
          onChange={onTypeChange}
          placeholder={currentType}
          className="btn btn-dark btn-tall dropdown-toggle text-left"
          options={typeOptions}
          isClearable={false}
        />
        <BasicSelect
          onChange={onStatusChange}
          placeholder={currentStatus}
          className="btn btn-dark btn-tall dropdown-toggle text-left"
          isClearable={false}
          options={statusOptions}
        />
      </div>
    </div>
  );
};
