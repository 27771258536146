import React from 'react';
/**
 * Block properties.
 */

export var BlockHeader = function BlockHeader(_ref) {
  var children = _ref.children;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "block-header"
  }, children));
};
export default BlockHeader;