import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { usePageContext, useWindowSizeContext } from '@tradingblock/components';
import { cashieringSelectors, useStateSelector } from '../data/global/dataSelectors';
import { UseAccountApiProvider } from '../context/Api';
import { DashboardView } from './views/DashboardView';
import { BaseCashieringView } from './views/BaseCashieringView';
import { SessionWrapper } from './wrappers/SessionWrapper';
import { usePageSettings } from '../framework/WrappedRoute';
import { AccountSwitcher } from '../components/account/AccountSwitcher';
import { account } from '../data/global/selectors/accountSelectors';
import { useDispatcher } from '../data/global/hooks';
import { IsVirtualEnvironment } from '../data/global/selectors/environmentSelector';
import { origin } from '../data/global/selectors/originSelector';
import { PendingEntitlementModal } from './modals/PendingEntitlementModal';
import { UIActions } from '../data/global/actions';

export const RootContentPage: React.FC<RouteComponentProps<{ path: string }>> = () => {
  usePageSettings({ name: 'Root content', access: 'public' });
  const token = useStateSelector(s => s.auth.uiApiToken);
  const isVirtual = useStateSelector(IsVirtualEnvironment);
  const originVal = useStateSelector(origin.origin);
  let group = originVal === 'mb' ? 'mb' : 'tb';
  const { dispatcher } = useDispatcher();
  const showAccountSwitcher = useStateSelector(account.showAccountSwitcher);
  const toggleAccountSwitcher = useCallback(() => {
    dispatcher.account.toggleAccountSwitcher();
  }, [dispatcher.account]);
  const userAccount = useStateSelector(s => s.accounts.account);
  const selectedLinkedAccount = useStateSelector(s => s.account.selectedLinkedAccount);
  const isPendingMarketDataEntitlementPolicyUpdatePresent = useStateSelector((s) =>
    s.account.profile.current &&
    s.account.profile.current.entitlement &&
    s.account.profile.current.entitlement.isPendingMarketDataEntitlementPolicyUpdatePresent
  );
  const showPendingEntitlementModal = useStateSelector((s) => 
    s.ui.dashboard.entitlements.showPendingEntitlementModal
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (isPendingMarketDataEntitlementPolicyUpdatePresent && showPendingEntitlementModal === null) {
      dispatch(UIActions.setShowPendingEntitlementModal({ showPendingEntitlementModal: true }));
    }
  }, [isPendingMarketDataEntitlementPolicyUpdatePresent]);

  useEffect(() => {
    const redirectReps = ['JP1', 'JP2', 'JP3', 'JP4', 'JP5', 'AA1'];
    if (
      userAccount !== undefined &&
      userAccount.RepCode !== undefined &&
      redirectReps.includes(userAccount.RepCode) &&
      !selectedLinkedAccount
    ) {
      window.location.href = '/legacy';
    }
  }, [userAccount]);

  if (!token) {
    return <span>Loading account info...</span>;
  }

  function LegacyRedirect() {
    useEffect(() => {
      console.log('Redirecting to legacy site! ' + group);
      if (group === 'mb') {
        window.location.replace('https://legacy.moneyblock.com/');
      } else {
        window.location.replace('https://legacy.tradingblock.com/');
      }
    }, [group]);

    return null;
  }

  return (
    <useWindowSizeContext.Provider>
      <usePageContext.Provider>
        <SessionWrapper>
          <UseAccountApiProvider>
            <Switch>
              <Route path="/legacy" component={LegacyRedirect} />
              <Route path="/accounts/oauth" component={BaseCashieringView} />
              <Route path="/:route(withdraw|deposit|accounts)" component={BaseCashieringView} />
              <Route path="/:dashboardid([\w-]{36})?" exact component={DashboardView} />
            </Switch>
            {!isVirtual && <AccountSwitcher show={showAccountSwitcher} toggleModal={toggleAccountSwitcher} />}
            {!isVirtual &&
              showPendingEntitlementModal &&
              <PendingEntitlementModal
                show={showPendingEntitlementModal}
                toggleModal={() => dispatch(UIActions.setShowPendingEntitlementModal({ showPendingEntitlementModal: !showPendingEntitlementModal }))}
              />
            }
          </UseAccountApiProvider>
        </SessionWrapper>
      </usePageContext.Provider>
    </useWindowSizeContext.Provider>
  );
};
