import React, { useMemo, useCallback, useEffect } from 'react';
import { createSelector } from 'reselect';
import { BlockBody } from '@tradingblock/components';
import { BlockType, AssetSymbol } from '@tradingblock/types';
import { QuoteHeader } from './QuoteHeader';
import { QuoteContent } from './QuoteContent';
import { QuoteState } from './state/QuoteState';
import { useStateSelector, createDeepSelector } from '../../data/global/dataSelectors';
import { getQuoteMetadata } from '../../data/global/selectors/quoteSelector';
import { Block } from '../../components/blocks/Block';
import { useBlockData, useBlockActions } from '../../components/blocks/BlockState';
import { useQuoteSymbolSubscription } from '../../hooks/useQuoteData';
import { getBlockProps } from '../../utilities/block';
import { useGroupStateHandler } from '../useGroupState';

const rootSelector = (state: QuoteState) => state;
const symbolSel = createSelector(
  rootSelector,
  v => v.symbol
);
const quoteMetaDataSel = createDeepSelector(rootSelector, v => v.quoteMetaData);
const isLoadedSel = createDeepSelector(rootSelector, v => v.isLoaded);
const selectors = {
  symbol: symbolSel,
  symbolName: createDeepSelector(symbolSel, v => (v ? v.symbol : undefined)),
  isLoaded: createDeepSelector(symbolSel, isLoadedSel, (s, i) => {
    return i === true && s !== undefined;
  }),
  quoteMetaData: quoteMetaDataSel,
};

export const useQuoteBlockGroupHandler = () => {
  const state = useBlockData<QuoteState>();
  const quoteSymbol = selectors.symbol(state);
  const { setField: set } = useBlockActions<QuoteState>();

  const setSymbol = useCallback((sym: AssetSymbol) => set('symbol', sym, { persist: true }), [set]);
  const onSymbolChange = useCallback(
    (symbol: AssetSymbol | undefined) => {
      if (symbol && (!quoteSymbol || quoteSymbol.symbol !== symbol.symbol)) {
        setSymbol(symbol);
      }
    },
    [quoteSymbol, setSymbol]
  );
  useGroupStateHandler('symbol', onSymbolChange, { fireOnGroupChange: true });
};

export const QuoteContentBlock: React.FunctionComponent<{ symbol: AssetSymbol }> = ({ symbol }) => {
  const quoteMetaData = useStateSelector(s => getQuoteMetadata(s, symbol.symbol));
  return <BlockBody>{quoteMetaData && <QuoteContent symbol={symbol} />}</BlockBody>;
};

export const QuoteExtendedBlock: React.FunctionComponent<{}> = () => {
  const state = useBlockData<QuoteState>();
  const { setField: set } = useBlockActions<QuoteState>();

  const setSymb = useCallback((sym: AssetSymbol) => {
    set('symbol', sym, { persist: true });
  }, []);
  const symbol = selectors.symbol(state);

  useQuoteBlockGroupHandler();
  // const symbolSelector = useCallback((s: QuoteState) => s.symbol, []);
  // const actionCreator = useCallback((groupId: string, value: AssetSymbol) => {
  //   const act: GroupActions = { type: 'setSymbol', payload: { symbol: value, groupId } };
  //   return act;
  // }, []);
  // useGroupStateSync<QuoteState, AssetSymbol>(symbolSelector, actionCreator);
  const loaded = useMemo(() => state.isLoaded === true, [state.isLoaded]);
  // const [quote, setQuote] = useState();

  useQuoteSymbolSubscription<QuoteState>((blockState: QuoteState) => (blockState.symbol ? blockState.symbol.symbol : undefined));

  return <Block {...getBlockProps(BlockType.Quote, <QuoteHeader blockType={BlockType.Quote} setSymbol={setSymb} />)}>{loaded && symbol && <QuoteContentBlock symbol={symbol} />}</Block>;
};
