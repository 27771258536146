import React, { useEffect } from 'react';
import { Button, Modal as BootstrapModal } from 'react-bootstrap';
import _ from 'lodash';
import { Loading, Modal } from '@tradingblock/components';
import { SubAccountTable } from './SubAccountTable';
import AccountsTable from './AccountTable';
import { useDispatch, useSelector } from 'react-redux';
import { LinkedAccountsActions } from '../../data/global/actions/LinkedAccountsActions';
import { IsVirtualEnvironment } from '../../data/global/selectors/environmentSelector';
import { useStateSelector } from '../../data/global/dataSelectors';
import { account } from '../../data/global/selectors/accountSelectors';
import { Actions } from '../../data/global/actions';

export const AccountSwitcher: React.FC<{ show?: boolean; toggleModal: () => void }> = ({ show, toggleModal }) => {
  const dispatch = useDispatch();
  const isVirtual = useStateSelector(IsVirtualEnvironment);
  const hasLinkedAccounts = useSelector(account.hasLinkedAccounts);
  const hasSubAccounts = useSelector(account.hasSubAccounts);
  const isSelectedLinkedAccountLoaded = useSelector(account.isSelectedLinkedAccountLoaded);

  //Run initial load of accounts to see if this is a multi-account user
  useEffect(() => {
    if (!isVirtual) {
      dispatch(
        LinkedAccountsActions.requestLinkedAccounts({
          pageSize: 5,
        })
      );
    }
  }, [!isVirtual]);

  useEffect(() => {
    if (hasLinkedAccounts || hasSubAccounts) {
      dispatch(Actions.setMultipleAccounts());
    }
  }, [hasLinkedAccounts, hasSubAccounts]);

  return (
    <Modal
      size="xl"
      setShow={() => toggleModal()}
      show={show}
      backdrop={!isSelectedLinkedAccountLoaded ? 'static' : undefined}
      keyboard={!isSelectedLinkedAccountLoaded ? false : undefined}
    >
      <BootstrapModal.Header closeButton={!isSelectedLinkedAccountLoaded ? false : true}>
        <BootstrapModal.Title>From which account would you like to trade?</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <div id="account-switcher__accounts">
          <AccountsTable />
        </div>
        <div id="account-switcher-table">
          <SubAccountTable />
        </div>
        <p className={'inlineheader'} style={{ fontSize: 12 }}>
          *These are not realtime and are subject to change slightly.
        </p>
      </BootstrapModal.Body>
      <BootstrapModal.Footer>
        <Button variant="secondary" onClick={toggleModal} disabled={!isSelectedLinkedAccountLoaded}>
          Close
        </Button>
      </BootstrapModal.Footer>
      {!isSelectedLinkedAccountLoaded && (
        <div className="alert alert-info container">
          <div
            className="row"
            style={{
              alignItems: 'center',
            }}
          >
            <div className="col-1">
              <Loading size="medium" />
            </div>
            <div className="col-11">Loading account information...</div>
          </div>
        </div>
      )}
    </Modal>
  );
};
