import { useMemo } from 'react';
import { Expiration } from '@tradingblock/types';
import { useBlockData } from '../../../components/blocks/BlockState';
import { OrderState } from './OrderState';
import { LegSelector } from './OrderLegSelector';

export const useOrderLegData = (id: string) => {
  const blockState = useBlockData<OrderState>();
  const expiration: Expiration | undefined = useMemo(
    () => (blockState.legs[id] ? blockState.legs[id].Expiration : undefined),
    [blockState, id]
  );
  const strike = LegSelector.Strike(blockState, id);

  return {
    expiration,
    strike,
    occSymbol: LegSelector.SymbolAsOCC(blockState, { id }),
  };
};
