import React from 'react';
export var BlockBody = function BlockBody(_ref) {
  var children = _ref.children,
      className = _ref.className;
  return React.createElement("div", {
    className: className ? className.toString() : 'block-body'
  }, children);
};
export var BlockFooter = function BlockFooter(_ref2) {
  var children = _ref2.children;
  return React.createElement("div", {
    className: "block-footer"
  }, children);
};
export var BlockContent = function BlockContent(_ref3) {
  var children = _ref3.children;
  return React.createElement("div", {
    className: "block-content"
  }, children);
};