import _ from 'lodash';
import {
  AssetSymbol,
  Legs,
  OrderAction,
  TradingStrategy,
  OrderLegDTO,
  BlockType,
  IBlockDataState,
  PriceChartSettings,
  TradingStrategyNameOrCustom,
  TradingStrategyName,
  InfoBlockSettings,
} from '@tradingblock/types';
import { BuildInitialOrder, getKnownStrategy } from '@tradingblock/api';
import { OrderState } from './Order/state/OrderState';
import { DefaultOrderState } from './Order/state/OrderReducer';
import { DefaultOrderBlockSettings } from './Order/state/OrderSettings';
import { PriceChartState } from './PriceChart/state/PriceChartState';
import { DefaultPriceChartSettings } from './PriceChart/state/PriceChartSettings';
import { OptionChainState } from './OptionChain/state/OptionChainState';
import { QuoteState } from './QuoteExtended/state/QuoteState';
import { InfoBlockDataState } from './Info/state/InfoState';
import { DefaultInfoBlockSettings } from './Info/state/InfoSettings';
export const OrderStateFactory = {
  Initial: DefaultOrderState(DefaultOrderBlockSettings, 'Custom'),
  Build: (
    strategyOrStrategyName: TradingStrategy | TradingStrategyName,
    symbol: AssetSymbol,
    reverse: boolean = false
  ): OrderState => {
    const strategy =
      typeof strategyOrStrategyName === 'string' ? getKnownStrategy(strategyOrStrategyName) : strategyOrStrategyName;
    if (strategy && strategy.profile) {
      const defaultLegs = BuildInitialOrder(strategy, { Symbol: symbol.symbol }).Legs;
      const legs = _.reduce(
        defaultLegs,
        (acc: Legs, val: OrderLegDTO) => {
          const legAction = reverse
            ? val.Action === OrderAction.Buy
              ? OrderAction.Sell
              : OrderAction.Buy
            : val.Action;
          return { ...acc, [val.Id]: { ...val, Action: legAction } };
        },
        {}
      );
      return {
        ...DefaultOrderState(DefaultOrderBlockSettings, strategy.info.Name),
        symbol,
        legs,
        strategy: strategy.info.Name,
      };
    }
    return {
      ...DefaultOrderState(DefaultOrderBlockSettings, 'Custom'),
      symbol,
      strategy: 'Custom',
    };
  },
};
export const PriceChartStateFactory: StateFactory<PriceChartState, PriceChartSettings> = {
  Initial: {},
  Build: (symbol: AssetSymbol): PriceChartState => {
    return {
      symbol,
      isDirty: false,
    };
  },
  Settings: DefaultPriceChartSettings,
};
export const OptionChainStateFactory = {
  Initial: {},
  Build: (symbol: AssetSymbol): OptionChainState => {
    return {
      isDirty: false,
    };
  },
};
export const QuoteStateFactory = {
  Initial: {},
  Build: (symbol: AssetSymbol): QuoteState => {
    return {
      symbol,
      isDirty: false,
    };
  },
};
export const InfoStateFactory: StateFactory<InfoBlockDataState, InfoBlockSettings> = {
  Initial: {},
  Build: (symbol: AssetSymbol): InfoBlockDataState => {
    return {
      symbol,
      isDirty: false,
    };
  },
  Settings: DefaultInfoBlockSettings,
};
interface StateSettingsFactory<SET = any> {
  Settings: SET;
}
interface StateFactory<S extends IBlockDataState, SET = any> extends StateSettingsFactory<SET> {
  Initial: Partial<S>;
  Build: (...args: any[]) => S;
}
const FactoryMap = {
  [BlockType.PriceChart]: PriceChartStateFactory,
};
export function blockStateFactory<T extends BlockType>(type: T): StateSettingsFactory {
  const defaultFactory: StateSettingsFactory = { Settings: {} };
  return _.get(FactoryMap, type, defaultFactory);
}
