import { BlockDragHandle, getBlockTypeName } from '@tradingblock/components'
import { BlockType } from '@tradingblock/types'
import React, { FC } from 'react'

export interface SubAccountManagementHeaderProps { }

export const SubAccountManagementHeader: FC<SubAccountManagementHeaderProps> = () => {
    return (
        <div className="block-nav nav">
            <BlockDragHandle className="nav-item">{getBlockTypeName(BlockType.SubAccountManagement)}</BlockDragHandle>
        </div>
    )
}
