import { Dispatch, Middleware } from 'redux';
import { getType } from 'typesafe-actions';
import dayjs from 'dayjs';
import _ from 'lodash';
import { PlaceOrderRequest, ResponseCodes, SuccessResponseCode, OrderReplaceRequest } from '@tradingblock/types';
import { transformOrderForApi } from '@tradingblock/api';
import { DataState } from './../../state';
import { RootAction, Actions, OrderCancelEventAction } from '../../actions';
import { OrderActions } from '../../actions/OrderActions';
import { apiTokenSelector, orderFromSelector, accountIdSelector } from '../../dataSelectors';
import { Dispatcher } from '../../dispatcher';
import { ApiProvider } from '../../../../context/Api';

export const loadAccountOrders = (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  accountId: number,
  subaccountId?: number,
  dateFrom?: Date,
  dateTo?: Date,
  includeOrderEvents?: boolean,
  pageSize?: number,
  pageIndex?: number,
  blockId?: string,
  isTradesTab?: boolean,
  workingOrdersOnly?: boolean
) => {
  const now = dayjs().toDate();
  const token = apiTokenSelector(state);
  const orderStatusId = isTradesTab ? [2, 3] : -1;

  if (token) {
    const api = ApiProvider(state, dispatch);
    if (api) {
      api
        .order(accountId, subaccountId)
        .get({
          accountId,
          subaccountId,
          dateFrom: dateFrom ? dateFrom : undefined,
          dateTo: dateTo ? dateTo : undefined,
          includeOrderEvents,
          pageSize,
          pageIndex,
          blockId,
          orderStatusId,
          workingOrdersOnly,
        })
        .then(resp => {
          dispatch(
            Actions.receiveAccountOrders({
              accountId,
              date: now,
              dateFrom,
              dateTo,
              orders: (resp.payload && resp.payload.items) || [],
              total: (resp.payload && (resp.payload as any).totalNumOfAvailableItems) || 0, //Force cast to any due to capitalization on this field only for orders
              pageSize,
              pageIndex,
              blockId: blockId || undefined,
            })
          );
        });
    }
  }
};

export const handleOrderCancel = (state: DataState, dispatch: Dispatch<RootAction>, action: OrderCancelEventAction) => {
  const { orderId } = action.payload;
  const accountId = accountIdSelector(state);

  if (_.isNumber(accountId)) {
    const api = ApiProvider(state, dispatch);
    if (api) {
      api
        .order(accountId)
        .cancel(orderId)
        .then(resp => {
          dispatch(
            OrderActions.receiveCancel({
              orderId,
              date: dayjs().toDate(),
              response: resp.payload,
              responseCode: resp.responseCode,
            })
          );
          // refetch orders right away to reflect the cancelled order
          Dispatcher(dispatch).order.request(accountId);
        });
    } else {
      console.warn('order cancel skipped because token or accountid are null!');
    }
  }
  return state;
};

const orderHandler = (state: DataState, dispatch: Dispatch<RootAction>) => {
  const dispatcher = Dispatcher(dispatch);
  const api = ApiProvider(state, dispatch);
  return {
    place: (state: DataState, orderRequest: PlaceOrderRequest) => {
      const accountId = accountIdSelector(state);
      api
        .order(accountId)
        .create(orderRequest)
        .then(resp => {
          const { payload, responseCode } = resp;
          const success = responseCode === SuccessResponseCode;
          if (!success) {
            dispatcher.order.errored(orderRequest, { Code: responseCode, Response: payload });
          } else {
            dispatch(
              OrderActions.receivePlaceOrder({
                order: transformOrderForApi(orderRequest),
                response: { payload, responseCode },
              })
            );
          }
        });
    },
    change: (state: DataState, orderRequest: OrderReplaceRequest) => {
      const accountId = accountIdSelector(state);
      api
        .order(accountId)
        .change(orderRequest)
        .then(resp => {
          const { payload, responseCode } = resp;
          const { Message } = payload;
          const responseType = ResponseCodes[responseCode];
          const success = responseCode === SuccessResponseCode;
          const error =
            !success && responseType
              ? _.isString(responseType.description)
                ? responseType.description
                : responseType.description(Message)
              : undefined;
          if (!success) {
            dispatch(
              OrderActions.errorChange({
                message: Message,
                error: error,
                data: { orderRequest, responseCode, Response: payload },
              })
            );
          } else {
            dispatch(OrderActions.receiveChange({ order: orderRequest, response: { payload, responseCode } }));
          }
        });
    },
  };
};

export const OrderMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  try {
    // state BEFORE action is dispatched
    const prevState = getState();
    const result = next(action);

    const handler = orderHandler(prevState, dispatch);
    try {
      switch (action.type) {
        case getType(OrderActions.requestPlaceOrder): {
          handler.place(prevState, action.payload);
          break;
        }
        case getType(OrderActions.requestChange): {
          handler.change(prevState, action.payload);
          break;
        }
      }
    } catch (apiError) {
      console.error('Order placement error', apiError);
    }

    return result;
  } catch (err) {
    console.error('ordersMiddleware :: Caught an exception for action ', action, err);
  }
};
