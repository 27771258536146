import React from 'react';
import _ from 'lodash';
import { EntitlementBaseUpdate } from '@tradingblock/types';
import { AdminEntitlementsSearchActions } from '../../../../../data/global/actions/admin/EntitlementsActions';
import { useDispatcher } from '../../../../../data/global/hooks';

export const EntitlementsActionCell: React.FunctionComponent<
  Pick<
    EntitlementBaseUpdate,
    | 'AccountNumber'
    | 'UserId'
    | 'ApprovingUserName'
    | 'FirstName'
    | 'LastName'
    | 'RequestDateTime'
    | 'PrimaryAccountIdAssociatedWithUser'
    | 'RequestedMarketDataEntitlementType'
    | 'ApprovedUserMarketDataEntitlementType'
    | 'UserLevel'
    | 'UserName'
    | 'Id'
  >
> = ({
  AccountNumber,
  UserId,
  ApprovingUserName,
  FirstName,
  LastName,
  RequestDateTime,
  RequestedMarketDataEntitlementType,
  PrimaryAccountIdAssociatedWithUser,
  ApprovedUserMarketDataEntitlementType,
  UserLevel,
  UserName,
  Id,
}) => {
  const isPending = ApprovingUserName === null || ApprovingUserName === undefined ? true : false;
  const { dispatch } = useDispatcher();
  const handleClick = (
    e: any,
    AccountNumber: string,
    UserId: number,
    FirstName: string,
    LastName: string,
    RequestDateTime: string,
    RequestedMarketDataEntitlementType: string,
    PrimaryAccountIdAssociatedWithUser: number,
    ApprovedUserMarketDataEntitlementType: string,
    UserLevel: number,
    UserName: string,
    ApprovingUserName: string,
    Id: number
  ) => {
    e.preventDefault();
    let updateItem = {
      AccountNumber,
      UserId,
      FirstName,
      LastName,
      RequestDateTime,
      RequestedMarketDataEntitlementType,
      PrimaryAccountIdAssociatedWithUser,
      ApprovedUserMarketDataEntitlementType,
      UserLevel,
      UserName,
      ApprovingUserName,
      Id,
    };
    dispatch(AdminEntitlementsSearchActions.setCurrentItem(updateItem));
  };

  return (
    <>
      <button
        className={`btn ${isPending ? `btn-outline-light` : ''}`}
        onClick={e =>
          handleClick(
            e,
            AccountNumber,
            UserId,
            FirstName,
            LastName,
            RequestDateTime,
            RequestedMarketDataEntitlementType,
            PrimaryAccountIdAssociatedWithUser,
            ApprovedUserMarketDataEntitlementType,
            UserLevel,
            UserName,
            ApprovingUserName,
            Id
          )
        }
      >
        {isPending ? 'Review' : 'View'}
      </button>
    </>
  );
};
