import React, { useState, useMemo, useRef } from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import dayjs from 'dayjs';
import { getLocalDateFromTimezone, timeZone } from '@tradingblock/api';
import { isToday } from '../../utilities/date';
import { Modal } from '@tradingblock/components';
import { useQuoteTime } from '../../hooks/useFeedQuote';
import { findTimeZone } from 'timezone-support';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export const QuoteTime: React.FC<{ symbol: string }> = ({ symbol }) => {
  const quoteTime = useQuoteTime(symbol);
  const [showQuoteTimeInfo, setShowQuoteTimeInfo] = useState(false);
  const time = useMemo(() => (quoteTime ? quoteTime : undefined), [quoteTime]);

  const date = dayjs(quoteTime, 'MM/DD/YYYY HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss') + 'Z';

  // force correct date format
  const timeValue = time && dayjs(time, 'MM/DD/YYYY HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss') + 'Z';
  const dateValue: Date = new Date(date);

  const tz: any = findTimeZone(timeZone);
  const { abbreviations } = tz;

  let zone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];

  const timestampRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {quoteTime && (
        <div className="nav-item" ref={timestampRef}>
          <div className="timestamp txt-sm">
            <button type="button" onClick={() => setShowQuoteTimeInfo(true)}>
              {!isToday(dateValue) && <span className="timestamp-date">{dayjs(timeValue).format('MMM D, YYYY')}</span>}
              <span className="timestamp-time">
                {dayjs(timeValue).format('h:mm:ss A')}{' '}
                {zone ? zone : abbreviations && abbreviations ? abbreviations[1].replace('S' || 'D', '') : null}
              </span>
            </button>
          </div>
        </div>
      )}
      {/* <Overlay placement="bottom" target={timestampRef.current || undefined} show={showQuoteTimeInfo}>
        <Popover id={`trade-order-popover`}>
          <NotificationWrapper onClickAway={handleClickAway}>TEST</NotificationWrapper>
        </Popover>
      </Overlay> */}
      <Modal setShow={setShowQuoteTimeInfo} show={showQuoteTimeInfo}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>About quote updates</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          <div>
            <p>
              Quote was updated at the time displayed. Some or all intraday quotes may be delayed up to 20 minutes, and
              may not reflect current bid, ask or last sale.
            </p>
          </div>
        </BootstrapModal.Body>
      </Modal>
    </>
  );
};
