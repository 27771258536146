import React, { useCallback } from 'react';
import { BlockLinkingMask } from '../BlockLinkingMask';
import { BlockProps } from '@tradingblock/components';
import { useBlockMetadata } from '../BlockState';
import { useDispatcher } from '../../../data/global/hooks';
import { useStateSelector, blocksSelectors } from '../../../data/global/dataSelectors';

type BlockLinkProps = Pick<BlockProps, 'type' | 'isLinkable'>;

export const BlockLink: React.FC<BlockLinkProps> = ({ type, isLinkable }) => {
  const { blockId, groupId } = useBlockMetadata();
  const { dispatcher } = useDispatcher();
  const onLinkBlock = useCallback(() => {
    // complete linking of this block
    dispatcher.block.linkBlock(blockId, groupId);
  }, [blockId, groupId]);
  const onCancelLinking = useCallback(() => {
    // cancel block linking
    dispatcher.grid.toggleLinking(false);
  }, []);
  const isLinked = useStateSelector(s => blocksSelectors.isLinked(s)(blockId));
  return (
    <>
      <div className="block-mask block-movable block-drag-handle">
        <i className="fas fa-arrows-alt" />
      </div>
      <BlockLinkingMask type={type} isLinkable={isLinkable} isLinked={isLinked} onClick={onLinkBlock} onCancelClick={onCancelLinking} />
    </>
  );
};
