import React, { useMemo } from 'react';
import { AssetSymbol } from '@tradingblock/types';
import { useBlockState } from '../../components/blocks/BlockState';
import { useBboxDimensions, useBlockSize } from '../shared/useBboxDimensions';
import { useGroupState } from '../useGroupState';
import { PriceChartGraph } from './PriceChartGraph';

export const PriceChartContent: React.FC<{ symbol?: AssetSymbol }> = ({ symbol }) => {
  const blockId = useBlockState('blockId');
  const [dimensions, tableElement] = useBboxDimensions<HTMLDivElement>();
  const chartHeight = useBlockSize(dimensions, 'height', [100, 200, 500]);
  const chartwidth = useBlockSize(dimensions, 'width', [100, 200, 500]);
  const containerId = useMemo(() => `tradingview_59d9e#${blockId}`, [blockId]);

  //const [symbol] = useGroupState('symbol');

  return (
    <>
      <div className="block-section block-section-flexible block-section-noborder" ref={tableElement}>
        <div className="price-chart">
          {symbol && (
            <PriceChartGraph
              containerId={containerId}
              symbol={symbol}
              {...{ height: chartHeight, width: chartwidth }}
            />
          )}
        </div>
      </div>
    </>
  );
};
