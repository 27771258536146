import { Loading } from '@tradingblock/components';
import React, { useEffect, useState } from 'react';

export const DownloadComponent = ({
  row,
  accountId,
  api,
  dispatch,
  SessionActions,
  GenericErrorResponseCode,
  type,
}: {
  row: any;
  accountId: number;
  api: any;
  dispatch: any;
  SessionActions: any;
  GenericErrorResponseCode: number;
  type: 'taxDoc' | 'statement' | 'confirm';
}) => {
  // Define state for download status and error message
  const [downloadStatus, setDownloadStatus] = useState('idle'); // 'idle', 'inProgress', 'success', 'error'
  const [errorMessage, setErrorMessage] = useState('');

  const handleDownload = () => {
    // Update download status to inProgress
    setDownloadStatus('inProgress');

    switch (type) {
      case 'taxDoc': {
        const [_, formType] = row.original.documentType.split('Form');
        api.downloads
          .getTaxDocument(accountId, formType, row.original.received_on)
          .then((result: any) => {
            let file = new Blob([result.data], { type: 'application/pdf' });
            let fileURL = URL.createObjectURL(file);

            // Create a temporary link to initiate the download
            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', 'filename.pdf'); // Set the file name for the download
            document.body.appendChild(link);
            link.click();
            link.remove();

            // Update download status to success
            setDownloadStatus('success');

            // Revoke the object URL after a delay to ensure the download has completed
            setTimeout(() => URL.revokeObjectURL(fileURL), 100);
          })
          .catch((err: any) => {
            // Update download status to error and set the error message
            setDownloadStatus('error');
            setErrorMessage('Failed to download tax document. Please try again later.');

            dispatch(
              SessionActions.error({
                code: GenericErrorResponseCode,
                url: err && err.config && err.config.url ? err.config.url : '',
                message: errorMessage || 'Failed to download tax document. Please try again later.',
                reason: 'Download Failed',
              })
            );
          });
        break;
      }
      case 'statement': {
        api.downloads
          .getMonthlyStatement(accountId, row.original.received_on)
          .then((result: any) => {
            let file = new Blob([result.data], { type: 'application/pdf' });
            let fileURL = URL.createObjectURL(file);

            // Create a temporary link to initiate the download
            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', 'filename.pdf'); // Set the file name for the download
            document.body.appendChild(link);
            link.click();
            link.remove();

            // Update download status to success
            setDownloadStatus('success');

            // Revoke the object URL after a delay to ensure the download has completed
            setTimeout(() => URL.revokeObjectURL(fileURL), 100);
          })
          .catch((err: any) => {
            // Update download status to error and set the error message
            setDownloadStatus('error');
            setErrorMessage('Failed to download monthly statement. Please try again later.');

            dispatch(
              SessionActions.error({
                code: GenericErrorResponseCode,
                url: err && err.config && err.config.url ? err.config.url : '',
                message: errorMessage || 'Failed to download monthly statement. Please try again later.',
                reason: 'Download Error',
              })
            );
          });
        break;
      }
      case 'confirm': {
        api.downloads
          .getDailyConfirm(accountId, row.original.received_on)
          .then((result: any) => {
            let file = new Blob([result.data], { type: 'application/pdf' });
            let fileURL = URL.createObjectURL(file);

            // Create a temporary link to initiate the download
            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', 'filename.pdf'); // Set the file name for the download
            document.body.appendChild(link);
            link.click();
            link.remove();

            // Update download status to success
            setDownloadStatus('success');

            // Revoke the object URL after a delay to ensure the download has completed
            setTimeout(() => URL.revokeObjectURL(fileURL), 100);
          })
          .catch((err: any) => {
            console.log({ err });
            // Update download status to error and set the error message
            setDownloadStatus('error');
            setErrorMessage('Failed to download daily confirm. Please try again.');
            dispatch(
              SessionActions.error({
                code: GenericErrorResponseCode,
                url: err && err.config && err.config.url ? err.config.url : '',
                message: errorMessage || 'Failed to download daily confirm. Please try again.',
                reason: 'Download Failed',
              })
            );
          });
        break;
      }
      default: {
        // Update download status to error and set the error message
        setDownloadStatus('error');
        setErrorMessage('Invalid download type');
      }
    }
  };

  // reset download status to idle after 5 seconds
  // with cleanup function to clear the timeout
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDownloadStatus('idle');
    }, 5000);
    return () => clearTimeout(timeout);
  }, [downloadStatus]);

  // reset error message when download status changes
  useEffect(() => {
    if (downloadStatus !== 'error') {
      setErrorMessage('');
    }
  }, [downloadStatus]);

  return (
    <div>
      {downloadStatus === 'idle' && (
        <i style={{ cursor: 'pointer' }} className="fal fa-lg fa-download dashboard-icon" onClick={handleDownload} />
      )}
      {downloadStatus === 'inProgress' && <Loading size="small" />}
      {downloadStatus === 'success' && <i className="fal fa-check pos" />}
      {downloadStatus === 'error' && (
        <p>
          <i className="fal fa-times" /> {errorMessage}
        </p>
      )}
    </div>
  );
};
