import React, { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import { Modal as BootstrapModal, Button, FormGroup } from 'react-bootstrap';
import { Modal, TextboxField, Alert } from '@tradingblock/components';
import { DashboardInfo, AlertType } from '@tradingblock/types';
import { isString } from '@tradingblock/api';
import { dashboardsSelectors } from '../../../data/global/dataSelectors';
import { DataState } from '../../../data/global/state';
import { GenericField } from '../../form/GenericField';
import { isLocalhost } from '../../../utilities/url';

export interface EditBoardProps {
  validateOnMount?: boolean;
  dashboard: DashboardInfo;
  header?: string;
  show: boolean;
  onSave: (board: DashboardInfo) => void;
  onDelete?: () => void;
  onClose: () => void;
}

type DashboardFormValues = Pick<DashboardInfo, 'title'> & { confirmDelete: string };
export const EditDashboardModal: React.FunctionComponent<EditBoardProps> = ({ header, dashboard, show, onSave, onDelete, onClose, validateOnMount }) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const dashboardTitlesExcludingCurrent = useSelector((s: DataState) => dashboardsSelectors.dashboardTitlesExcludingId(s, dashboard.dashboardId));
  const onValidate = useCallback(
    (values: DashboardFormValues) => {
      // console.warn('EditBoard :: validate values ', values);
      if (!values.title) {
        return { title: 'Title is required' };
      }
      if (dashboardTitlesExcludingCurrent.includes(values.title.toLocaleLowerCase().trim())) {
        return { title: 'This title is already in use. Please rename your dashboard.' };
      }
      return {};
    },
    [dashboardTitlesExcludingCurrent]
  );
  const onSubmit = useCallback(
    (values: DashboardFormValues) => {
      const title = values.title;
      const cleanValues = {
        ...values,
        title: title && isString(title) ? title.trim() : title,
      };
      onSave({ ...dashboard, ...cleanValues });

      onClose();
    },
    [dashboard, onClose, onSave]
  );
  const onDeleteClick = () => {
    if (onDelete && isLocalhost()) {
      onDelete();
      onClose();
    } else {
      setConfirmDelete(true);
    }
  };
  const onDeleteConfirmClick = (values: DashboardFormValues) => {
    const confirmDeleteValue = values.confirmDelete;
    if (onDelete && confirmDeleteValue && confirmDeleteValue.toLocaleLowerCase().trim() === 'delete') {
      onDelete();
      setConfirmDelete(false);
      onClose();
    }
  };
  const initialValues: DashboardFormValues = useMemo(() => ({ title: dashboard ? dashboard.title : '', confirmDelete: '' }), [dashboard]);
  const headerText = useMemo(() => {
    if (header) {
      return header;
    } else {
      return `${confirmDelete ? 'Delete' : dashboard && dashboard.dashboardId ? 'Edit' : 'Add'} dashboard`;
    }
  }, [confirmDelete, dashboard, header]);
  if (!show) {
    return null;
  }

  return (
    <Formik
      validate={onValidate}
      // validateOnBlur={true}
      validateOnMount={_.isNil(validateOnMount) || validateOnMount === true}
      validateOnChange={true}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit, errors }) => (
        <Modal setShow={() => onClose} show>
          <BootstrapModal.Header onHide={onClose} closeButton>
            <BootstrapModal.Title>{headerText}</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            <form id="edit-board" onSubmit={handleSubmit}>
              <FormGroup>
                {/* <Field component={TextboxField} id="title" placeholder="Title" disabled={confirmDelete} /> */}
                <GenericField name="title" value={values.title} settings={{ type: 'string', label: 'Dashboard title', error: errors.title }} />
                {errors.title && <Alert alertType={AlertType.warning} message={errors.title} />}
              </FormGroup>

              {onDelete && confirmDelete && (
                <>
                  <FormGroup>
                    <p>
                      To confirm deletion of this dashboard, please type <strong>DELETE</strong> in the box below.
                    </p>
                    <Field component={TextboxField} id="confirmDelete" placeholder="Type 'DELETE' to confirm" />
                  </FormGroup>
                </>
              )}
            </form>
          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            {onDelete && (
              <>
                {!confirmDelete && (
                  <button type="button" className="btn-link neg mr-auto" onClick={onDeleteClick}>
                    Delete dashboard
                  </button>
                )}
                {confirmDelete && (
                  <>
                    <Button variant="secondary" onClick={() => setConfirmDelete(false)}>
                      Cancel
                    </Button>
                    <Button variant="danger" onClick={() => onDeleteConfirmClick(values)}>
                      Delete
                    </Button>
                  </>
                )}
              </>
            )}
            {!confirmDelete && (
              <>
                <Button variant="secondary" onClick={onClose}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={() => handleSubmit()}>
                  Save
                </Button>
              </>
            )}
          </BootstrapModal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
