import { createStandardAction, ActionType } from 'typesafe-actions';
import { useBlockActionDispatch } from '../../../components/blocks/BlockState';
import { useMemo } from 'react';

const ActivityActionTypes = {
  setTimeframe: 'setTimeframe',
  toggleActivityPopover: 'toggleActivityPopover',
};
export const Actions = {
  setTimeframe: createStandardAction(ActivityActionTypes.setTimeframe)<{ startDate: Date | undefined; endDate: Date | undefined }>(),
  toggleActivityPopover: createStandardAction(ActivityActionTypes.toggleActivityPopover)<{}>(),
};
export type ActivityActions = ActionType<typeof Actions>;

export function useActivityActions() {
  const dispatch = useBlockActionDispatch<ActivityActions>();

  return useMemo(
    () => ({
      setTimeframe: (startDate: Date | undefined, endDate: Date | undefined) => {
        return dispatch({ type: ActivityActionTypes.setTimeframe, payload: { startDate: startDate, endDate: endDate } });
      },
    }),
    [dispatch]
  );
}
