import React, { FC, useCallback, useState } from 'react';
import { Cell } from 'react-table';

import { CashieringTable } from './components/CashieringTable';
import { CashieringFilters } from './components/CashieringFilters';
import { ColumnWithSorting } from '@tradingblock/components';
import { useLatestPendingCashieringDate } from '../../../hooks/useLatestPendingCashieringDate';
import { useDispatch } from 'react-redux';
import { AdminCashieringActions } from '../../../data/global/actions/admin/AdminCashieringActions';
import { CashieringBaseEntity, ClientEntity } from '@tradingblock/types';
import { TransactionCell } from './components/table/CashieringTransactionCell';
import { CashieringMethodCell } from './components/table/CashieringMethodCell';
import dayjs from 'dayjs';
import { CashieringNoteCell } from './components/table/CashieringNoteCell';
import { getIdNumber } from '@tradingblock/api';
import { CashieringActionCell } from './components/table/CashieringActionCell';
import { useCashieringActions } from './useCashiering';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { AccountActions } from '../../../data/global/actions/AccountActions';
import { LinkedAccountsActions } from '../../../data/global/actions/LinkedAccountsActions';
import { useHistory } from 'react-router-dom';

const CashieringAdminReviewColumns: ColumnWithSorting<CashieringBaseEntity>[] = [
  {
    Header: 'Account',
    accessor: v => v.accountNumber,
    sortType: 'alphanumeric',
    id: 'AccountNumber',
  },
  {
    Header: 'Name',
    accessor: v => v.accountName,
    sortType: 'alphanumeric',
    id: 'AccountName',
  },
  {
    Header: 'Rep Code',
    accessor: v => v.repCode,
    sortType: 'alphanumeric',
    id: 'RepCode',
  },
  {
    Header: 'Transaction',
    id: 'transaction',
    Cell: ({ row }: Cell<CashieringBaseEntity>) => {
      const { transfer, status, relationship, transferRecipientBank, transferWireInstruction } = row.original;
      return (
        <TransactionCell {...{ transfer, status, relationship, transferRecipientBank, transferWireInstruction }} />
      );
    },
  },
  {
    Header: 'Method',
    id: 'method',
    Cell: ({ row }: Cell<CashieringBaseEntity>) => {
      const { transfer, relationship } = row.original;
      return <CashieringMethodCell {...{ transfer, relationship }} />;
    },
  },
  {
    Header: 'Date',
    accessor: v => v.date,
    sortType: 'alphanumeric',
    sortDescFirst: true,
    id: 'Date',
    Cell: ({ row }: Cell<CashieringBaseEntity>) => {
      const dateVal = dayjs(row.original.date);
      if (dateVal && dateVal.isValid()) {
        return (
          <>
            {dateVal.format('M/DD/YY')}
            <br />
            {dateVal.format('hh:mm A')}
          </>
        );
      }
      return <></>;
    },
  },
  {
    Header: 'Account Status',
    id: 'accountStatus',
    sortType: 'alphanumeric',
    Cell: ({ row }: Cell<CashieringBaseEntity>) => {
      const { accountStatus } = row.original;
      const getAccountStatus = useCallback((status: string) => {
        // format status to place a space between words
        const formattedStatus = status.replace(/([A-Z])/g, ' $1').trim();
        switch (status) {
          case 'Active':
            return (
              <span className="pos" style={{ fontWeight: 500 }}>
                {formattedStatus}
              </span>
            );
          case 'Inactive':
            return (
              <span className="neg" style={{ fontWeight: 500 }}>
                {formattedStatus}
              </span>
            );
          case 'Closed':
            return (
              <span className="neg" style={{ fontWeight: 500 }}>
                {formattedStatus}
              </span>
            );
          default:
            return <span className="warn">{formattedStatus}</span>;
        }
      }, []);

      return <>{getAccountStatus(accountStatus)}</>;
    },
  },
  {
    Header: 'Notes',
    id: 'note',
    Cell: ({ row }: Cell<CashieringBaseEntity>) => {
      const { note, adminNote, transfer, relationship, transferWireInstruction, accountId } = row.original;
      const id = transfer
        ? transfer.transferId
        : relationship
        ? relationship.relationshipId
        : transferWireInstruction
        ? transferWireInstruction.transferWireInstructionId
        : undefined;
      const type = transfer
        ? 'transfers'
        : relationship
        ? 'relationships'
        : transferWireInstruction
        ? 'wireInstructions'
        : undefined;
      if (id && type && (note || adminNote)) {
        return (
          <>
            <CashieringNoteCell accountId={accountId} id={getIdNumber(id)} type={type} />
          </>
        );
      }
      return <></>;
    },
  },
  {
    Header: 'Action',
    id: 'action',
    className: 'text-center',
    Cell: ({ row }: Cell<CashieringBaseEntity>) => {
      const {
        status,
        transfer,
        relationship,
        type,
        accountId,
        transferRecipientBank,
        transferWireInstruction,
      } = row.original;

      const handleClick = useCallback(() => {
        sessionStorage.setItem('cashieringSearch', JSON.stringify({ index: row.index }));
      }, [row]);

      return (
        <div className="text-center" onClick={() => handleClick()}>
          <CashieringActionCell
            {...{ status, transfer, relationship, type, accountId, transferRecipientBank, transferWireInstruction }}
          />
        </div>
      );
    },
  },
  {
    Header: 'Select',
    id: 'switch',
    disableSortBy: true,
    Cell: ({ row }: Cell<ClientEntity>) => {
      const dispatch = useDispatch();
      const history = useHistory();
      const searchValue = row.original.accountNumber
        ? row.original.accountNumber.trim()
        : row.original.accountName.trim();
      const selectedLinkedAccountId = useStateSelector(
        state => state.account.selectedLinkedAccount && state.account.selectedLinkedAccount.accountId
      );

      const isSelected = selectedLinkedAccountId === row.original.accountId;
      const accountSelectedCallback = (accountId: number) => {
        dispatch(
          AccountActions.retrieveSetAccountData({
            accountId,
            searchOptions: {
              page: 0,
              pageSize: 5,
              sortBy: '',
              sortByOrder: 'desc',
              search: searchValue,
              status: '',
            },
          })
        );
        dispatch(AccountActions.requestSubAccounts({ accountId: accountId }));
        history.push('/');
      };

      return (
        <div className="text-center">
          <button
            disabled={isSelected}
            onClick={() => {
              dispatch(
                LinkedAccountsActions.requestLinkedAccounts({
                  page: 0,
                  pageSize: 5,
                  sortBy: '',
                  sortByOrder: 'desc',
                  search: searchValue,
                  status: '',
                })
              );
              setTimeout(() => accountSelectedCallback(row.original.accountId), 1000);
            }}
          >
            {isSelected ? <i className="fas fa-circle" /> : <i className="fal fa-check-circle" />}
          </button>
        </div>
      );
    },
    width: '100px',
  },
  {
    Header: 'Filter',
    id: 'filter',
    Cell: ({ row }: Cell<CashieringBaseEntity>) => {
      const { search: onSearch, setPage, setType, setStatus } = useCashieringActions();
      // searchValue should default to accountNumber, if no accountNumber, then use accountName
      const searchValue = row.original.accountNumber
        ? row.original.accountNumber.trim()
        : row.original.accountName.trim();
      const handleSetFilter = useCallback(() => {
        setPage(0);
        setType([]);
        setStatus([]);
        onSearch(searchValue);
        sessionStorage.removeItem('cashieringSearch');
        row.original.handleSetSelectedRow(undefined);
      }, [row.original.accountId]);

      return (
        <div className="text-center">
          <button onClick={() => handleSetFilter()}>
            <i className="fal fa-filter" />
          </button>
        </div>
      );
    },
  },
];

const CASHIERING_TABLE_DATE_COLUMN = 'Date';

const CashieringHeader: FC<{}> = () => {
  const dispatch = useDispatch();

  const [newPendingRequestsExist, setNewPendingRequestsExist] = useState(false);
  const handleNewCashieringRequests = useCallback(() => setNewPendingRequestsExist(true), []);
  useLatestPendingCashieringDate(handleNewCashieringRequests, true);

  const refreshCashieringData = useCallback(() => {
    setNewPendingRequestsExist(false);
    dispatch(
      AdminCashieringActions.refresh({ sortBy: CASHIERING_TABLE_DATE_COLUMN, sortByOrder: 'desc', replace: true })
    );
  }, []);

  return (
    <>
      <h1>Cashiering review</h1>

      {newPendingRequestsExist && (
        <span style={{ display: 'inline-block' }}>
          {'New requests have been received. '}
          <a href="#" onClick={refreshCashieringData}>
            Refresh list
          </a>
          {' to see them. '}
        </span>
      )}
    </>
  );
};

export const AdminCashieringIndex = () => {
  const [selectedRowIndex, setSelectedRowIndex] = React.useState<number | undefined>(undefined);

  const handleSetSelectedRowIndex = useCallback((index: number | undefined) => {
    setSelectedRowIndex(index);
  }, []);

  return (
    <div className="d-flex admin-cashiering-index">
      <div className="screen-body-section flex-1">
        <CashieringHeader />
        <CashieringTable
          handleSetSelectedRow={handleSetSelectedRowIndex}
          selectedRowIndex={selectedRowIndex}
          CashieringAdminReviewColumns={CashieringAdminReviewColumns}
        />
      </div>
      <div className="screen-body-section cashiering-filters-wrapper">
        <CashieringFilters
          handleSetSelectedRow={handleSetSelectedRowIndex}
          CashieringAdminReviewColumns={CashieringAdminReviewColumns}
        />
      </div>
    </div>
  );
};
