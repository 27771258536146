import { useMemo } from 'react';
import _ from 'lodash';
export function useStringOrDefault() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  var res = useMemo(function () {
    return args.map(function (arg) {
      var init = '';
      var def = '';

      if (_.isArray(arg)) {
        init = arg[0];
        def = _.isNil(arg[1]) ? '' : arg[1];
      } else {
        init = typeof arg === 'string' ? arg : arg[0];
      }

      return _.isNil(init) ? def : init;
    });
  }, [args]);
  return res;
}
export function useArrayOrDefault(arr, def) {
  var res = useMemo(function () {
    if (_.isNil(arr) || arr.length === 0) {
      return def;
    }

    return arr;
  }, [arr, def]);
  return res;
}
export function useArrayOrDefaultWithInitialValue(arr, def, findFunc, initializeFunc) {
  var res = useArrayOrDefault(arr, def);

  if (_.isNil(res) || res.length === 0) {
    return def;
  }

  var firstVal = _.find(res, findFunc);

  if (firstVal !== undefined) {
    return res;
  }

  return _.map(res, function (t, ind) {
    if (ind === 0) {
      return initializeFunc(t);
    }

    return t;
  });
}