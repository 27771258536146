import { useBlockMetadata, useBlockActions } from './BlockState';

import { useStateSelector, blockGroupId } from '../../data/global/dataSelectors';

import { useEffect } from 'react';

export const useBlockGroupSync = () => {
  const { blockId, groupId } = useBlockMetadata();
  const { setGroup } = useBlockActions();
  const rootStateGroup = useStateSelector(s => blockGroupId(s, blockId));
  useEffect(() => {
    if (rootStateGroup && groupId !== rootStateGroup) {
      setGroup(rootStateGroup);
    }
  }, [groupId, rootStateGroup, setGroup]);
};
