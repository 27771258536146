import { isBlockAction, blockReducer, BlockAction } from '../../../components/blocks/BlockState';
import { getType } from 'typesafe-actions';
import { InfoBlockState, InfoBlockDataState } from './InfoState';
import { InfoBlockActions, InfoBlockAction } from './InfoBlockAction';

export const InfoBlockReducer = (blockState: InfoBlockState, action: InfoBlockAction): InfoBlockState => {
  const state = isBlockAction(action) ? blockReducer<InfoBlockDataState, {}>(blockState, action) : blockState;

  switch (action.type) {
    case getType(InfoBlockActions.setTab): {
      const tabVal = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          tab: tabVal,
        },
      };
    }
    case getType(InfoBlockActions.setNoticePeriod): {
      const infoPeriod = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          noticesPeriod: infoPeriod,
        },
      };
    }

    default:
      return state;
  }
};
