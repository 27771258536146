import _ from 'lodash';
import { IBlockState, PersistableOrderState } from '@tradingblock/types';
import { OrderState } from './OrderState';

export const cleanOrderState = (s: IBlockState<OrderState>): IBlockState<PersistableOrderState> => {
  const { data } = s;
  const keysToPersist: (keyof PersistableOrderState)[] = [
    'symbol',
    'duration',
    'orderType',
    'legs',
    'strategy',
    'action',
    'quantity',
    'tables',
  ];
  const orderDataToPersist = _.pick(data, keysToPersist);
  return {
    ...s,
    data: orderDataToPersist,
  };
};
