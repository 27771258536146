import { OCCSymbolToOptionData } from '@tradingblock/api';
import { AssetType, OptionType } from '@tradingblock/types';

/**
 * Gives the expiration date corresponding to a given option
 * @param occ an option (as a string) to be parsed
 * @returns the expiration date (as a string) corresponding to the given option
 */
export function ParseOCCExpiration(occ: string): Date | undefined {
  const x = OCCSymbolToOptionData(occ);
  if (x.expiration === undefined) return undefined;
  else return x.expiration;
}

/**
 * Gives the symbol corresponding to the given option
 * @param occ an option (as a string) to be parsed
 * @returns the ticker (as a string) corresponding to the given option
 */
export function ParseOCCSymbol(occ: string): string | undefined {
  const x = OCCSymbolToOptionData(occ).symbol;
  return x;
}

/**
 * Gives the type of option a given OCC string is. I.e. "call" or "put"
 * @param occ the OCC option to be parsed
 * @returns the type of option corresponding to the given string. I.e "call" or "put".
 * @returns undefined if the given string is not an option
 */
export function ParseOCCOption(occ: string): string | undefined {
  const x = OCCSymbolToOptionData(occ).option;
  if (x === OptionType.Call) return 'Call';
  else if (x === OptionType.Put) return 'Put';
  else return undefined;
}
/**
 * Gives the strike price of a given option
 * @param occ the OCC option to be parsed
 * @returns the strike price of the given option as a string
 * @returns undefined if the given string doesn't have a strike price
 */
export function ParseOCCStrike(occ: string): string | undefined {
  const x = OCCSymbolToOptionData(occ).strike;
  if (x === undefined) return undefined;
  else return `${x}`;
}

export const sortColumnsByOrder = (columns: JSX.Element[], columnOrder: string[]) => {
  columns.sort((a, b) => {
    const aIndex = columnOrder.indexOf(a.props.id);
    const bIndex = columnOrder.indexOf(b.props.id);
    return aIndex - bIndex;
  });
  return columns;
};

/**
 * Calculates the position cost value based on the average price, the number of contracts and the multiplier (100 - option | 1 - share).
 * @param avgPrice - number | the average price
 * @param numOfContracts - number | the number of contracts
 * @param assetType - AssetType | the asset type
 * @param multiplier - number (optional) | the multiplier
 * @returns - number | the position cost value
 */
export const calculatePositionCostValue = (
  avgPrice: number,
  numOfContracts: number,
  assetType: AssetType,
  multiplier?: number
): number => {
  // We are setting another multiplier based on the asset type to create a fallback in the case the multiplier is not available from the backend.
  let multiplierFromAssetType = 1;

  if (assetType === AssetType.Option) {
    multiplierFromAssetType = 100;
  }

  return avgPrice * numOfContracts * (multiplier || multiplierFromAssetType);
};
