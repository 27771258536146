import React, { useCallback } from 'react';
import { Durations } from '@tradingblock/types';
import { ToggleButton } from '@tradingblock/components';
import { useBlockActions } from '../../../components/blocks/BlockState';
import { useOrderData, OrderState } from '../state/OrderState';

// interface OrderDuration extends DropdownItemType {
//   duration: Durations;
// }
// const toItemType = (d: Durations) => ({
//   name: Durations[d],
//   value: Durations[d],
//   duration: d,
// });
// const durations: OrderDuration[] = [Durations.Day, Durations.GTC, Durations.Manual].map(toItemType);

// export const DurationPicker: React.FunctionComponent<{}> = ({}) => {
//   const duration = useOrderData((s: OrderState) => s.duration, Durations.Day);

//   const { setField: set } = useBlockActions<OrderState>();
//   const setDuration = useCallback(
//     (dur: DropdownItemType) => {
//       const durVal = durations.find(d => d.name === dur.name);
//       if (durVal) {
//         set('duration', durVal.duration, { persist: true });
//       }
//     },
//     [set]
//   );

//   return (
//     <SplitDropdown buttonclassName={`btn-tall dropdown-toggle text-left btn-block`} className={'btn-block'} value={toItemType(duration)} setValue={setDuration} values={durations}>
//       <span className="btn-title">{Durations[duration]}</span>
//     </SplitDropdown>
//   );
// };

export const DurationPicker: React.FC<{}> = () => {
  const duration = useOrderData((s: OrderState) => s.duration, Durations.Day);

  const { setField: set } = useBlockActions<OrderState>();

  const setValue = useCallback(
    (isActive: boolean) => {
      set('duration', isActive ? Durations.Day : Durations.GTC, { persist: true });
    },
    [set]
  );

  return (
    <ToggleButton
      on={Durations[Durations.Day]}
      off={Durations[Durations.GTC]}
      onstyle="dark"
      offstyle="dark"
      width="100%"
      disabled={false}
      onClick={setValue}
      active={duration === Durations.Day}
    />
  );
};
