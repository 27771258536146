import { createSelector } from "reselect";
import { DataState } from "../../state";
import createCachedSelector from "re-reselect";
import _ from "lodash";
import { clientListRequestKey } from "../../utilities/accountUtilities";
import { getBalanceTotals } from "../../utilities/balances";

export const getRequestKey = createSelector(
    (state: DataState) => state.private.clients.ui,
    uiState => clientListRequestKey(uiState)
  );

  export const clientBalances = {
    totals: createCachedSelector((s: DataState, accountId: number) => s.private.account.balances[accountId], (b) => {
      if(_.isNil(b) || _.isNil(b.data)) {
        return undefined;
      }
      return getBalanceTotals(b.data);
    }
      )((s, id) => `${id}-balance-totals`)
  }

  
export const clientList={
    // getClients: createCachedSelector((state: DataState) => state.private.cashiering.entities, 
    //     (state: DataState) => state.private.cashiering.byQuery, 
    //     (state: DataState) => state.private.cashiering.ui, getRequestKey, 
    //     (entities, queryState, uiState, requestKey) => {
    //   const allValues = queryState[requestKey] ? queryState[requestKey].ids : [];
    //   // const { page, pageSize } = { ...{ page: 0, pageSize: 25 }, ...uiState };
    //   return (
    //     _(allValues)
    //       .map(id => entities[id])
    //       .filter(v => v !== undefined)
    //       // .drop(page * pageSize)
    //       // .take(pageSize)
    //       .value()
    //   );
    // })(getRequestKey),
    // totals: createCachedSelector((state: DataState) => state.private.cashiering.entities, (state: DataState) => state.private.cashiering.byQuery, (state: DataState) => state.private.cashiering.ui, getRequestKey, (entities, queryState, uiState, requestKey) => {
    //   const total = queryState[requestKey] ? queryState[requestKey].total : 0;
    //   return total;
    // })(getRequestKey),
    isFetching: createCachedSelector((state: DataState) => state.private.clients.byQuery, getRequestKey, (queryState, requestKey) => {
      const qState = queryState[requestKey];
      const isFetching = qState ? qState.isFetching : true;
      const hasData = qState && qState.ids.length > 0;
      return [isFetching, hasData];
    })(getRequestKey),
    clients: createCachedSelector((state: DataState) => state.private.clients.clients, 
    (state: DataState) => state.private.clients.byQuery, 
    getRequestKey, 
    (items, queryState, requestKey) => {
      const allValues = queryState[requestKey] ? queryState[requestKey].ids : [];
      // const { page, pageSize } = { ...{ page: 0, pageSize: 25 }, ...uiState };
      return (
        _(allValues)
          .map(id => items[id])
          .filter(v => v !== undefined)
          // .drop(page * pageSize)
          // .take(pageSize)
          .value()
      );
    })(getRequestKey),
    total: createCachedSelector(
    (state: DataState) => state.private.clients.byQuery, 
    getRequestKey, (queryState, requestKey) => {
      const total = queryState[requestKey] ? queryState[requestKey].total : 0;
      return total;
    })(getRequestKey),
    filters: createCachedSelector((state: DataState) => state.private.clients.ui, getRequestKey, (uiState, requestKey) => {
      return { ...uiState };
    })(getRequestKey),
    requestKey: createCachedSelector(
      (d: DataState) => d.private.clients.ui,
      (d: DataState) => clientListRequestKey(d.private.clients.ui),
      (request, key) => {
        return { request, key };
      }
      )((d: DataState) => clientListRequestKey(d.private.clients.ui))
      
  
  }