import { createStandardAction, ActionType } from 'typesafe-actions';
import { BlockAction } from '../BlockState';
import { BlockTableState } from './BlockTableState';

export const BlockTableActions = {
  setTableState: createStandardAction('@block/tables/setSort')<
    { key: string; table: Partial<BlockTableState> },
    { persist: boolean }
  >(),
};

const allBlockActions = {
  ...BlockAction,
  ...BlockTableActions,
};

export type BlockTableAction = ActionType<typeof BlockTableActions>;
export type BlockAndTableActions = ActionType<typeof allBlockActions>;
