import dayjs from 'dayjs';
import _ from 'lodash';
import {
  OrderLeg,
  OrderAction,
  PositionEffect,
  AssetType,
  OrderType,
  Order,
  PnLItem,
  OrderStatuses,
  OptionType,
  Durations,
  DebitOrCredit,
  OptionExpirationType,
  OrderActivity,
} from '@tradingblock/types';
import {
  TradingBlockAccountApi,
  PnLItemGetPnLItemRequest,
  OCCSymbolToOptionData,
  deriveOptionExpirationType,
  OrderStatusCategories,
} from '@tradingblock/api';
import { formatNumber } from '@tradingblock/components';
import { TradingBlockDispatcher } from '../dispatcher';
import { optionToDetailString, orderLegToDetails } from './options';
import { isErrorActivity } from '../../../utilities/activity';

/**
 * This type contains the majority of the data needed
 * to fill out the "details" section of the table in the
 * ActivityOrders and ActivityTrades tab of the activity block
 */
interface ActivityOrderDetailProps {
  day?: string;
  month?: string;
  year?: string;
  strikePrice?: string;
  orderType?: string;
  symbol?: string;
  quantity?: string;
}

/**
 * This function takes in an OrderType and returns a formatted
 * string corresponding to that type.
 *
 * @param orderType The enum value given to any order.
 */
function orderTypeToString(orderType: OrderType): string {
  switch (orderType) {
    case OrderType.Market:
      return 'MKT';
    case OrderType.Limit:
      return 'LMT';
    case OrderType.Stop_Limit:
      return 'STOP LMT';
    case OrderType.Stop_Market:
      return 'STOP MKT';
    case OrderType.Market_On_Close:
      return 'MKT ON CLOSE';
    default:
      return 'MKT';
  }
}

export function formatOrderPrice(order: Pick<Order, 'OrderType' | 'DebitCredit' | 'Price' | 'Duration' | 'Stop'>) {
  const { Price, Duration, DebitCredit, Stop } = order;
  const orderType = order.OrderType;
  if (_.isNil(Price)) {
    return '';
  }

  const priceValue = Math.abs(Price);
  const price = formatNumber(priceValue, { currency: true });
  const stopValue = Stop ? Math.abs(Stop) : undefined;
  const stop = stopValue ? formatNumber(stopValue, { currency: true }) : '';
  const prefix = DebitCredit ? (DebitCredit === DebitOrCredit.Debit ? 'DBT' : 'CRDT') : '';
  const orderTypeString = orderTypeToString(orderType);
  const duration = Duration === Durations.GTC ? ' GTC' : '';

  if (orderType === OrderType.Stop_Limit) {
    return `${stop} ${orderTypeString} ${price} ${prefix} ${duration}`;
  } else if (orderType === OrderType.Stop_Market) {
    return `${stop} ${orderTypeString} ${prefix} ${duration}`;
  } else if (orderType === OrderType.Market) {
    return `${orderTypeString} ${prefix} ${duration}`;
  } else {
    return `${price} ${orderTypeString} ${prefix} ${duration}`;
  }
}

/**
 * Gets the total number of filled orders
 * @param legs An array of orderlegs
 * @param backUpQuantity in the event the orderLegs don't exist, this value will be treated as the fallback
 * @returns the total number of filled orders
 */
export function getFilledQuantity(legs: OrderLeg[], backUpQuantity: number) {
  let filledQuantity = backUpQuantity;
  if (legs === null || legs === undefined) {
    filledQuantity = backUpQuantity;
  } else {
    filledQuantity = _.sum(_.map(legs, l => l.LegFillQuantity));
  }

  return filledQuantity;
}

/**
 * Checks to see if the OrderStatus is either undefined, "New", "Pending New", or "Initiated"
 * @param orderStatus The number of the enum value corresponding with the OrderStatus
 */
export function isNewOrderStatus(orderStatus: number | undefined) {
  return _.includes([undefined, OrderStatuses.New, OrderStatuses.PendingNew, OrderStatuses.Initiated], orderStatus);
}

/**
 * Checks to see if the OrderStatus is "Cancelled"
 * @param orderStatus The number of the enum value corresponding with the OrderStatus
 */
export function isCancelledOrderStatus(orderStatus: number | undefined) {
  return _.includes([OrderStatuses.Cancelled], orderStatus);
}

/**
 * Checks to see if the OrderStatus is "Partially Filled"
 * @param orderStatus The number of the enum value corresponding with the OrderStatus
 */
export function isPartialOrderStatus(orderStatus: number | undefined) {
  return _.includes([OrderStatuses.PartiallyFilled], orderStatus);
}

export function isPartiallyCancelledOrderStatus(orderStatus: number | undefined) {
  return _.includes([OrderStatuses.PartiallyCancelled], orderStatus);
}

/**
 * Checks to see if the OrderStatus is "Filled"
 * @param orderStatus The number of the enum value corresponding with the OrderStatus
 */
export function isFilledOrderStatus(orderStatus: number | undefined) {
  return _.includes([OrderStatuses.Filled], orderStatus);
}

/**
 * Checks to see if the OrderStatus is new or partially filled
 * @param orderStatus The number of the enum value corresponding with the OrderStatus
 */
export function isNewOrPartialOrderStatus(orderStatus: number | undefined) {
  return isNewOrderStatus(orderStatus) || isPartialOrderStatus(orderStatus);
}

/**
 * Checks to see if the OrderStatus is new or cancelled
 * @param orderStatus The number of the enum value corresponding with the OrderStatus
 */
export function isNewOrCancelledOrderStatus(orderStatus: number | undefined) {
  //return isNewOrderStatus(orderStatus) || isCancelledOrderStatus(orderStatus);
  if (orderStatus === undefined) {
    return false;
  }
  return [OrderStatuses.New, OrderStatuses.Cancelled, OrderStatuses.Replaced].includes(orderStatus);
}

/**
 * This function calls the TradingBlock API and returns
 * an asynchronous function that allows you to get the
 * PnL items corresponding with the given account
 * @param _api the tradingblock api object
 * @param _dispatcher the dispatcher
 */
export function loadPnL(_api: TradingBlockAccountApi, _dispatcher: TradingBlockDispatcher) {
  return async (_accountId: number, request: PnLItemGetPnLItemRequest) => {
    _dispatcher.pnlHistory.request(_accountId);
    const _request = (await _api.pnl(request)).payload;

    const pnl = _request ? (_request.Items as PnLItem[]) : ([] as PnLItem[]);
    _dispatcher.pnlHistory.receive(_accountId, pnl);
    return pnl;
  };
}

export function formatOrderLeg(
  leg: OrderLeg,
  details: ActivityOrderDetailProps,
  order: OrderActivity | Order,
  showSymbol = false
) {
  const { Quantity, OrderStatus } = order;
  const { SpreadRatio } = leg;
  let orderAction = '';

  let isBuy = OrderAction[leg.Action] === 'Buy';
  if (isErrorActivity(order)) {
    orderAction = `Error ${isBuy ? 'buying' : 'selling'}`;
  } else if (_.isNil(OrderStatuses) || !OrderStatus) {
    orderAction = '';
  } else if (OrderStatusCategories.Filled.includes(OrderStatus)) {
    orderAction = isBuy ? 'Bought' : 'Sold';
  } else {
    orderAction = isBuy ? 'Buy' : 'Sell';
  }
  if (OrderStatus === OrderStatuses.Initiated) {
    orderAction = `Initiated ${_.toLower(orderAction)}`;
  }

  const e = PositionEffect[leg.PositionEffect];
  const s = leg.AssetType === AssetType.Equity ? leg.Symbol : details.symbol;
  const q = Quantity * (SpreadRatio || 1);

  const i = optionToDetailString(leg, order);
  return `${orderAction} to ${e} ${showSymbol ? s : ''} ${q}x ${i}`;
}

/**  This function accepts a string in the
 * format "Symbol DDMMMYY StrikePrice OrderType [quantity]" and converts it to the format shown in the ActivityBlock documentation
 *
 * @param detail a string in the form "Symbol DDMMMYY StrikePrice CALLorPUT [quantity]"
 */
export function detailFormattor(leg: OrderLeg, underlyingSymbol: string): ActivityOrderDetailProps {
  return orderLegToDetails(leg, underlyingSymbol);
}
/**
 * Takes in an OrderStatus value (as a number) and returns
 * A formatted string corresponding to that value
 *
 * @param status an enum value of OrderStatus
 * @returns a string with the corresponding enum value
 */
export function OrderStatusToString(status?: number): string {
  switch (status) {
    case OrderStatuses.New:
      return 'New';
    case OrderStatuses.PartiallyFilled:
      return 'Partial';
    case OrderStatuses.Filled:
      return 'Filled';
    case OrderStatuses.Cancelled:
      return 'Cancelled';
    case OrderStatuses.Replaced:
      return 'Replaced';
    case OrderStatuses.PendingCancel:
      return 'Pending Cancel';
    case OrderStatuses.Rejected:
      return 'Rejected';
    case OrderStatuses.PendingNew:
      return 'Pending New';
    case OrderStatuses.Expired:
      return 'Expired';
    case OrderStatuses.PendingReplace:
      return 'Pending Replace';
    case OrderStatuses.Saved:
      return 'Saved';
    case OrderStatuses.Initiated:
      return 'Initiated';
    case OrderStatuses.ReplaceInitiated:
      return 'Replace Initiated';
    case OrderStatuses.CancelInitiated:
      return 'Cancel Initiated';
    case OrderStatuses.CancelRejected:
      return 'Cancel Rejected';
    case OrderStatuses.ReplaceRejected:
      return 'Replace Rejected';
    case OrderStatuses.DoneForDay:
      return 'Done For Day';
    case OrderStatuses.Acknowledged:
      return 'Acknowledged';
    case OrderStatuses.PartiallyCancelled:
      return 'Partially Cancelled';
    // case OrderStatuses.Stopped:
    //   return 'Stopped';
    // case OrderStatuses.Suspended:
    //   return 'Suspended';
    // case OrderStatuses.Calculated:
    //   return 'Calculated';
    // case OrderStatuses.LiveUntriggered:
    //   return 'Live Untriggered';
    // case OrderStatuses.Scheduled:
    //   return 'Scheduled';
    // case OrderStatuses.OCO_Untriggered:
    //   return 'OCO Untriggered';
    // case OrderStatuses.CancelledUntriggered:
    //   return 'Cancelled Untriggered';
    // case OrderStatuses.Busted:
    //   return 'Busted';
    // case OrderStatuses.PreAllocated:
    //   return 'Preallocated';
    default:
      return '';
  }
}

export const ActivityStatusMessage = (activity: OrderActivity) => {
  if (isErrorActivity(activity)) {
    if (activity.data.error) {
      return activity.data.error;
    }
    return activity.data.Response ? activity.data.Response.Message : undefined;
  }
  return undefined;
};

/**
 * This function converts a given Order object To a string array
 * to be used in the Messages tab of the activity block
 * @param order the Order you want to convert
 */
export function orderToMessage(order: Order<OrderLeg>) {
  const underlyingSymbol = order.UnderlyingSymbol;
  if (underlyingSymbol === undefined) {
    return [];
  }
  return _.map(order.Legs, val => {
    return formatOrderLeg(val, detailFormattor(val, underlyingSymbol), order, true);
  });
}
