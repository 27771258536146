import { DashboardMiddleware } from './dashboard';
import { CashieringMiddleware } from './cashiering';
import { OptionChainMiddleware } from './optionchain';
import { OptionsMiddleware } from './options';
import { SettingsMiddleware } from './settingsHandler';
import { FavoriteMiddleware } from './favorites';
import { AccountMiddleware } from './account';
import { LifecycleMiddleware } from './lifecycle';
import { OrderMiddleware } from './orders';
import { AdminAccountMiddleware } from './admin/adminAccountHandler';
import { AdminCashieringMiddleware } from './admin/adminCashieringHandler';
import { OrderFeedMiddleware } from './orderFeedMiddleware';
import { AdminClientMiddleware } from './admin/adminClientHandler';
import { QuotesMiddleware } from './quote';
import { StrikeMiddleware } from './chain';
import { BalancesMiddleware } from './balances';
import { TokenMiddleware } from './tokenhandler';
import { CashieringEventMiddleware } from './cashieringEventMiddleware';
import { InformationMiddleware } from './information';
import { AccountManagementMiddleware } from './accountManagement';
import { AdminAccountManagementMiddleware } from './admin/adminAccountManagementHandler';
import { AdminEntitlementsMiddleware } from './admin/adminEntitlementsHandler';
import { AdminAlertsMiddleware } from './admin/adminAlertsHandler';
import { AdminApplicationsMiddleware } from './admin/adminApplicationHandler';
import { LinkedAccountMiddleware } from './linkedAccounts';
import { AccountStatements } from './accountStatements';
import { HistoryMiddleware } from './history';
import { SubaccountManagementMiddleware } from './subAccountManagement';
import { MarketScheduleMiddleware } from './marketSchedule';

// prettier-ignore
export const HandlerMiddleware = [
  HistoryMiddleware,
  OrderMiddleware,
  LifecycleMiddleware,
  AccountMiddleware,
  DashboardMiddleware,
  CashieringMiddleware,
  OptionChainMiddleware,
  OptionsMiddleware,
  SettingsMiddleware,
  FavoriteMiddleware,
  AdminAccountMiddleware,
  AdminCashieringMiddleware,
  OrderFeedMiddleware,
  AdminClientMiddleware,
  QuotesMiddleware,
  StrikeMiddleware,
  BalancesMiddleware,
  CashieringEventMiddleware,
  TokenMiddleware,
  MarketScheduleMiddleware,
  InformationMiddleware,
  AccountManagementMiddleware,
  AdminAccountManagementMiddleware,
  AdminEntitlementsMiddleware,
  AdminAlertsMiddleware,
  AdminApplicationsMiddleware,
  LinkedAccountMiddleware,
  AccountStatements,
  SubaccountManagementMiddleware
];
