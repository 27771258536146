import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { createSelectorCreator, defaultMemoize, createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import _ from 'lodash';
import {
  BlockConfiguration,
  BlockType,
  DefaultGridLayout,
  GroupState,
  PositionInfo,
  Option,
  UiSettings,
  CashieringFormState,
  CashieringState,
  OptionPairWithSymbol,
  DashboardInfo,
  AccountRole,
  UserProfileLevel,
  MarketStateTypes,
} from '@tradingblock/types';
import { isString } from '@tradingblock/api';
import { DataState } from './state';
import { isArrayEqual } from '../../utilities/data';
import { canBlockBeLinked } from '../../utilities/block';
import { ExpirationOptionPairs, ExpirationOptionPair, OptionChainBlockState } from '../../types';
import { isAdminUserLevel, isRepUserLevel } from '../../utilities/admin';

export const createDeepSelector = createSelectorCreator(defaultMemoize, _.isEqual);
export const createDeepArraySelector = createSelectorCreator(defaultMemoize, isArrayEqual);

const rootSelector = (state: DataState) => state;
export const isAuthenticated = (store: DataState) => store.auth.isAuthenticated;
export const isAuthenticatedOrAuthenticating = (store: DataState) => store.auth.isAuthenticated;
export const apiToken = (store: DataState) =>
  store.auth.isAuthenticated && store.auth.apiToken ? store.auth.apiToken : undefined;
export const uiApiToken = (store: DataState) => (store.auth.uiApiToken ? store.auth.uiApiToken : undefined);
export const uiApiTokenAndVersion = (store: DataState) => ({
  token: store.auth.uiApiToken || undefined,
  version: store.auth.version || undefined,
});
export const refreshTokenSelector = createSelector(
  uiApiTokenAndVersion,

  ({ token, version }) => ({ token, version })
);

const userLevel = (s: DataState) => s.account.profile && s.account.profile.current && s.account.profile.current.level;

export const account = {
  firstAndLastName: (s: DataState) =>
    s.account.account && s.account.account.FirstName && s.account.account.LastName
      ? `${s.account.account.FirstName} ${s.account.account.MiddleInitial || ''} ${s.account.account.LastName} ${s
          .account.account.Suffix || ''}`.trim()
      : '',
  accountLoaded: (s: DataState) => _.isNumber(s.accounts.account && s.accounts.account.AccountId),
  allAccounts: (s: DataState) => s.accounts.accounts,
  accountNickname: (s: DataState) => {
    const trimmedAccountNumber = _.takeRight(s.account.accountNumber, 4).join('');
    if (!s.account.account) {
      return '';
    } else {
      // return `${s.account.account.AccountTitle || 'Account'} #${trimmedAccountNumber}`;
      return `#${trimmedAccountNumber}`;
    }
  },
  accountId: (s: DataState) =>
    s.accounts.account && s.accounts.account.AccountId ? s.accounts.account.AccountId : undefined,
  accountCanAccessCashiering: (s: DataState) => {
    // can't access cashiering for IRA/UGMA accounts
    if (
      s.account.isIRA ||
      s.account.isUGMA ||
      (s.account.profile.current && s.account.profile.current.level !== UserProfileLevel.Account)
    ) {
      return false;
    }
    // ensure account has cashiering role
    return _.includes(s.account.roles, AccountRole.Cashiering);
  },
  userLevel: userLevel,
  userRoles: (s: DataState) => s.account.profile && s.account.profile.current && s.account.profile.current.roles,
  hasCashieringAdminPermission: createSelector(
    userLevel,
    userLevel => {
      return userLevel && (isAdminUserLevel(userLevel) || isRepUserLevel(userLevel));
    }
  ),
  accountNumber: (s: DataState) => s.account.accountNumber,
};

export const accountBalances = {
  balances: (s: DataState) => (s.accountData.balances ? s.accountData.balances.balances : undefined),
  totals: (s: DataState) => (s.accountData.balances ? s.accountData.balances.totals : undefined),
  isFetching: (s: DataState) => (s.accountData.balances ? s.accountData.balances.isFetching : false),
  todaysChangePercent: (s: DataState) =>
    s.accountData.balances && s.accountData.balances.totals && s.accountData.balances.totals.todaysChangePercent
      ? s.accountData.balances.totals.todaysChangePercent
      : undefined,
};

const rootBlocksSelector = (store: DataState) => store.blocks;
const blocks = createDeepSelector(rootBlocksSelector, blocksState =>
  blocksState && blocksState.blocks ? blocksState.blocks : []
);
const groups = createDeepSelector(rootBlocksSelector, blocksState =>
  blocksState && blocksState.groups ? blocksState.groups : []
);
const lockedSel = createDeepSelector(rootBlocksSelector, bs => bs.locked);
// const isLinkedSel = createDeepSelector(rootBlocksSelector, bs =>
//   _.memoize((blockId: string, blockType?: BlockType) => {
//     const block = _.find(bs.blocks, b => b.blockId === blockId);
//     return !!block && _.some(bs.blocks, b => b.blockId !== blockId && b.groupId === block.groupId && (!blockType || b.type === blockType));
//   })
// );
const isLinkedSel = createCachedSelector(rootBlocksSelector, bs => (blockId: string, blockType?: BlockType) => {
  const block = _.find(bs.blocks, b => b.blockId === blockId);
  return (
    !!block &&
    _.some(bs.blocks, b => b.blockId !== blockId && b.groupId === block.groupId && (!blockType || b.type === blockType))
  );
})(
  (state: DataState, blockId: string, blockType?: BlockType) => `islinked-${blockId}${blockType ? `-${blockType}` : ''}`
);
export const blockSelector = {
  block: createDeepSelector(blocks, b => _.memoize((blockId: string) => b.find(bb => bb.blockId === blockId))),
  blockType: createDeepSelector(blocks, b =>
    _.memoize((blockId: string) => {
      const res = b.find(bb => bb.blockId === blockId);
      return res ? res.type : undefined;
    })
  ),
};
export const blockIdsAndTypesSelector = createDeepSelector(blocks, bs => {
  if (!bs) {
    return [];
  }
  return bs.map(bb => ({
    groupId: bb.groupId,
    blockId: bb.blockId || '',
    blockType: bb.type,
    data: bb.data || undefined,
  }));
});

export const blockGroupId = createCachedSelector(
  (state: DataState) => state.blocks.blocks,
  (state: DataState, blockId: string) => blockId,
  (blocks: BlockConfiguration[], blockId: string) => {
    const block = _.find(blocks, b => b.blockId === blockId);

    if (!block) {
      return undefined;
    }
    return block.groupId;
  }
)((_, blockId) => `blockgroupid${blockId}`);
export const blockGroup = createCachedSelector(
  (state: DataState) => state.blocks.groups,
  blockGroupId,
  (state: DataState, id: string) => id,

  (groups: GroupState, groupId: string | undefined) => {
    const group = _.find(groups, g => g.groupId === groupId);

    if (!group) {
      return undefined;
    }
    return group;
  }
)((_, id) => `blockgroup${id}`);

export const getDashboardBlockGroupMap = createCachedSelector(
  (store: DataState) => store.blocks.blocks || [],
  (store: DataState) => (store.dashboards.dashboard ? store.dashboards.dashboard.dashboardId : undefined),
  blocks => {
    if (!blocks) {
      return [];
    }
    return blocks.map(b => b.groupId).filter(g => isString(g)) as string[];
  }
)((store: DataState) => (store.dashboards.dashboard ? store.dashboards.dashboard.dashboardId : 'default'));
const rootBlocksBlockSelector = (state: DataState) => state.blocks.blocks;
const linkableBlockSelector = (blocks: BlockConfiguration[], blockId: string): BlockConfiguration[] => {
  const groupedBlocks = _.groupBy(blocks, b => b.groupId);
  const currentBlock = _.find(blocks, b => b.blockId === blockId);
  if (currentBlock === undefined) {
    return [];
  }
  const blockGroupId = currentBlock.groupId;
  const currentGroup = groupedBlocks[blockGroupId];
  const isCurrentLinked = _.size(currentGroup) > 1;

  return _.reduce(
    groupedBlocks,
    (acc: BlockConfiguration[], group, groupId) => {
      if (
        // can only link to other groups
        blockGroupId !== groupId &&
        // if not linked, can link to other groups; otherwise can link to other blocks not already in a group
        (!isCurrentLinked || group.length === 1)
      ) {
        // filter out target blocks that can't be linked to
        return [...acc, ..._.filter(group, b => canBlockBeLinked(b.type, currentGroup))];
      }

      return acc;
    },
    []
  );
};
export const blocksSelectors = {
  getBlockQuoteSubscriptions: createCachedSelector(
    rootBlocksBlockSelector,
    (state: DataState, blockId: string) => blockId,
    (blocks: BlockConfiguration[], blockId: string) => {
      const block = _.find(blocks, b => b.blockId === blockId);
      return block && block.subscriptions ? block.subscriptions.quotes : [];
    }
  )((state: DataState, blockId: string) => blockId, { selectorCreator: createDeepArraySelector }),
  allBlockQuotes: createSelector(
    rootBlocksBlockSelector,
    (blocks: BlockConfiguration[]) => {
      return _.reduce(
        blocks,
        (symbols: string[], block: BlockConfiguration) => {
          return [...symbols, ...(block.subscriptions ? block.subscriptions.quotes : [])];
        },
        []
      ).sort();
    }
  ),
  blocks,
  blockGroups: groups,
  linkableBlocks: createCachedSelector(
    rootBlocksBlockSelector,
    (state: DataState, blockId: string) => blockId,
    linkableBlockSelector
  )((_, blockId) => blockId),
  linkableOrderBlockExists: createCachedSelector(
    rootBlocksBlockSelector,
    (state: DataState, blockId: string) => blockId,
    (blocks: BlockConfiguration[], blockId: string) => {
      const linkable = linkableBlockSelector(blocks, blockId);
      return _.some(linkable, b => b.type === BlockType.Order);
    }
  )((_, blockId) => blockId),
  linkingDisabled: createCachedSelector(
    rootBlocksBlockSelector,
    (state: DataState, blockId: string) => blockId,
    (blocks: BlockConfiguration[], blockId: string) => {
      const linkableBlocks = linkableBlockSelector(blocks, blockId);
      return !_.some(linkableBlocks, b => b.blockId !== blockId);
    }
  )((_, blockId) => blockId),
  locked: lockedSel,
  isLinked: isLinkedSel,
  isHighlighted: createDeepSelector(rootBlocksSelector, bs =>
    _.memoize((blockId: string) => {
      return _.includes(bs.highlightBlockIds, blockId);
    })
  ),
  highlightBlockIds: createDeepSelector(rootBlocksSelector, bs => bs.highlightBlockIds),
  highlightedAt: createDeepSelector(rootBlocksSelector, bs => bs.highlightedAt),
  highlightTimeoutMs: createDeepSelector(rootBlocksSelector, bs => bs.highlightTimeoutMs),
};

const rootLayoutSelector = (store: DataState) => store.layout;

export const layoutSelectors = {
  breakpoint: createDeepSelector(rootLayoutSelector, l => (l.breakpoint ? l.breakpoint : DefaultGridLayout.breakpoint)),
  columns: createDeepSelector(rootLayoutSelector, l => (l.columns ? l.columns : DefaultGridLayout.columns)),
  layouts: createDeepSelector(rootLayoutSelector, l => l.layouts),
};

const rootCashieringSelector = (store: DataState) => store.cashiering;
type CashieringFormsDataType = keyof CashieringFormState;
type CashieringDataType = keyof Pick<
  CashieringState,
  'transfers' | 'achRelationships' | 'securityQuestion' | 'transferInstructions'
>;
export const cashieringSelectors = {
  accountFormData: createDeepSelector(rootCashieringSelector, s => s.forms.account.data),
  transferFormData: createDeepSelector(rootCashieringSelector, s => s.forms.transfer.data),
  forms: {
    dataIsFetching: createCachedSelector(
      rootCashieringSelector,
      (state: DataState, dataType: CashieringFormsDataType) => dataType,
      (s, dataType) => s.forms[dataType].isFetching
    )((_, dataType) => dataType),
    dataIsLoading: createCachedSelector(
      rootCashieringSelector,
      (state: DataState, dataType: CashieringFormsDataType) => dataType,
      (s, dataType) => s.forms[dataType].isFetching || s.forms[dataType].isSaving
    )((_, dataType) => dataType),
    dataIsSaving: createCachedSelector(
      rootCashieringSelector,
      (state: DataState, dataType: CashieringFormsDataType) => dataType,
      (s, dataType) => s.forms[dataType].isSaving
    )((_, dataType) => dataType),
    dataStatus: createCachedSelector(
      rootCashieringSelector,
      (state: DataState, dataType: CashieringFormsDataType) => dataType,
      (s, dataType) => s.forms[dataType].status
    )((_, dataType) => dataType),
    dataMessage: createCachedSelector(
      rootCashieringSelector,
      (state: DataState, dataType: CashieringFormsDataType) => dataType,
      (s, dataType) => s.forms[dataType].message
    )((_, dataType) => dataType),
  },
  dataIsFetching: createCachedSelector(
    rootCashieringSelector,
    (state: DataState, dataType: CashieringDataType) => dataType,
    (s, dataType) => s[dataType].isFetching
  )((_, dataType) => dataType),
  dataIsLoading: createCachedSelector(
    rootCashieringSelector,
    (state: DataState, dataType: CashieringDataType) => dataType,
    (s, dataType) => s[dataType].isFetching || s[dataType].isSaving
  )((_, dataType) => dataType),
  dataIsSaving: createCachedSelector(
    rootCashieringSelector,
    (state: DataState, dataType: CashieringDataType) => dataType,
    (s, dataType) => s[dataType].isSaving
  )((_, dataType) => dataType),
  dataStatus: createCachedSelector(
    rootCashieringSelector,
    (state: DataState, dataType: CashieringDataType) => dataType,
    (s, dataType) => s[dataType].status
  )((_, dataType) => dataType),
  dataMessage: createCachedSelector(
    rootCashieringSelector,
    (state: DataState, dataType: CashieringDataType) => dataType,
    (s, dataType) => s[dataType].message
  )((_, dataType) => dataType),
  securityQuestion: createDeepSelector(rootCashieringSelector, s => s.securityQuestion.data),
  balanceTotals: createDeepSelector(rootCashieringSelector, s => s.balances.totals),
  transfers: createDeepSelector(rootCashieringSelector, s => s.transfers.data),
  newTransferId: createDeepSelector(rootCashieringSelector, s => s.transfers.newTransferId),
  plaidLinkToken: createDeepSelector(rootCashieringSelector, s => s.plaidLinkToken && s.plaidLinkToken.linkToken),
  achRelationships: createDeepSelector(rootCashieringSelector, s => s.achRelationships.data),
  transferInstructions: createDeepSelector(rootCashieringSelector, s => s.transferInstructions.data),
  // achRelationshipsCreatedAccount: createDeepSelector(rootCashieringSelector, s => s.achRelationships.created),
};

const rootDashboardsSelector = (store: DataState) => store.dashboards;
const dashboards = createSelector(
  rootDashboardsSelector,
  blocksState =>
    blocksState && blocksState.dashboards ? _.filter(blocksState.dashboards, d => !d.isDeleted) : undefined
);
const dashboard = createDeepSelector(rootDashboardsSelector, blocksState =>
  blocksState && blocksState.dashboard ? blocksState.dashboard : undefined
);

export const dashboardIdSelector = (store: DataState) =>
  store.dashboards.dashboard ? store.dashboards.dashboard.dashboardId : undefined;

const dashboardTitlesSelector = (dashboards: DashboardInfo[]) =>
  _(dashboards)
    .filter(d => d.isDeleted !== true)
    .map(d => d.title.toLocaleLowerCase().trim())
    .orderBy(v => v)
    .value();

const dashboardTitles = createSelector(
  (store: DataState) => store.dashboards.dashboards || [],
  dashboardTitlesSelector
);
const dashboardTitlesExcludingId = createSelector(
  (store: DataState, id?: string) => store.dashboards.dashboards || [],
  (store: DataState, id?: string) => id,
  (dashboards, id) => dashboardTitlesSelector(_.filter(dashboards, d => d.dashboardId !== id))
);

export const dashboardsSelectors = {
  dashboards,
  dashboard,
  dashboardId: dashboardIdSelector,
  dashboardTitlesExcludingId,
  dashboardTitles,
};
export const apiTokenSelector = createSelector(
  (state: DataState) => state.auth,
  auth => auth.apiToken
);
export const orderFromSelector = createSelector(
  (state: DataState) => state.account,
  state => state.ordersLastRequestedAt
);
export const shouldLoadMorePositions = createSelector(
  (state: DataState) => state.positions,
  positions => {
    if (positions.isFetching === true) {
      return false;
    } else {
      return positions.isDirty || positions.positions.length === 0 || positions.stale === true;
    }
  }
);

export const accountTypeSelector = createSelector(
  (state: DataState) => state.account,
  account => (account.account ? account.account.AccountType : undefined)
);

export const accountIdValue = (state: DataState) => state.account.accountId;
export const accountIdSelector = createSelector(
  (state: DataState) => state.account,
  account => account.accountId
);
export const isIRASelector = createSelector(
  (state: DataState) => state.account,
  account => account.isIRA
);
export const subAccountIdValue = (state: DataState) => state.account.subAccountId;
export const subAccountIdSelector = createSelector(
  (state: DataState) => state.account,
  account => account.subAccountId
);
export const subAccountSelector = (subaccountId?: number) =>
  createSelector(
    (state: DataState) => state.account.subaccounts.subaccounts || [],
    subaccounts => {
      return _.find(subaccounts, sa => sa.Id === subaccountId);
    }
  );
export const useShowAccountSwitcher = () => useStateSelector(s => s.account.showAccountSwitcher);
export const orderSelector = createSelector(
  (state: DataState) => state.account.orders || [],
  (state: DataState, orderId: number) => orderId,
  (orders, orderId) => {
    return _.find(orders, o => o.OrderId === orderId);
  }
);
export const orderIsKnown = createSelector(
  (state: DataState) => state.account.orders || [],
  (state: DataState, orderId: number) => orderId,
  (orders, orderId) => {
    return _.find(orders, o => o.OrderId === orderId) === undefined ? false : true;
  }
);

export const useStateSelector = <T>(sel: (state: DataState) => T) => useSelector<DataState, T>(sel, _.isEqual);
export const useBlockSelector = <T>(blockSel: (blockSelect: typeof blocksSelectors) => (state: DataState) => T) =>
  useStateSelector(blockSel(blocksSelectors));

export const useApiToken = () => useStateSelector(s => s.auth.apiToken);
export const useAuthenticationToken = () => useStateSelector(s => s.auth.uiApiToken);
export const AuthenticationInfoSelector = createSelector(
  (s: DataState) => s.auth,
  (s: DataState) => s.account.accountId,
  (authState, accountId) => {
    return {
      apiToken: authState.apiToken,
      isAuthenticated: authState.isAuthenticated,
      token: authState.uiApiToken,
      accountId,
    };
  }
);
export const useAuthenticationSelector = () => useStateSelector(AuthenticationInfoSelector);
export const useShowSettings = () => useStateSelector(s => s.settings.showSettings);
export function settingsValue<K extends keyof UiSettings>(field: K) {
  return createSelector(
    (s: DataState) => s.settings.ui,
    v => v[field]
  );
}

export const getNotifications = createDeepArraySelector(rootSelector, (state: DataState) =>
  _.filter(state.notifications, n => !n.global)
);
export const getGlobalNotifications = createDeepArraySelector(rootSelector, (state: DataState) =>
  _.filter(state.notifications, n => !!n.global)
);
export const getGlobalErrorOrWarning = createSelector(
  rootSelector,
  (state: DataState) => {
    return _(state.notifications)
      .filter(n => n.global === true && ['Warn', 'Error'].includes(n.status) && !n.hide)
      .first();
  }
);
export const getGlobalMessage = createSelector(
  rootSelector,
  (state: DataState) => {
    return _(state.notifications)
      .filter(n => n.global === true && ['Info'].includes(n.status) && !n.hide)
      .first();
  }
);
export const getGlobalWarning = createSelector(
  rootSelector,
  (state: DataState) => _.filter(state.notifications, n => n.global === true && n.status === 'Warn')
);
export const getModalError = createSelector(
  rootSelector,
  (state: DataState) => {
    return _(state.notifications)
      .filter(n => n.modal === true && ['Error'].includes(n.status) && !n.hide && !n.viewed)
      .first();
  }
);

export const getFavorites = createSelector(
  rootSelector,
  (state: DataState) => {
    //Filter out expiring contracts on the frontend
    return state.favorites.symbols.filter(symbol => {
      if (symbol.symbol.includes(' ')) {
        const [_, contractInfo] = symbol.symbol.split(' ');
        const [year, month, day] = [contractInfo.substr(0, 2), contractInfo.substr(2, 2), contractInfo.substr(4, 2)];
        const expiry = new Date(`${month}/${day}/${year}`);
        if (dayjs().isBefore(dayjs(expiry).add(1, 'day'))) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    });
  }
);
export const getFavoriteSymbols = createSelector(
  getFavorites,
  favorites => _.map(favorites, f => f.symbol)
);
export const areFavoritesLoading = createSelector(
  rootSelector,
  (state: DataState) => state.favorites.isFetching
);
export const isFavorite = createCachedSelector(
  [getFavoriteSymbols, (state: DataState, id: string) => id],
  (favorites: string[], id: string): boolean => {
    return _(favorites).includes(id);
  }
)(
  // re-reselect keySelector
  // Use "libraryId" as cacheKey
  (_, id) => id
);

export const PositionSymbolsSelector = createSelector(
  (state: DataState) => state.positions,
  positions => {
    var symbols =
      positions && positions.positions ? _.map(positions.positions, p => [p.Symbol, p.UnderlyingSymbol]) : [];
    return _(symbols)
      .flatMap()
      .uniq()
      .sort()
      .value();
  }
);
export const OrderSymbols = (s: DataState) =>
  _(s.account.orders || [])
    .flatMap(o => o.Legs.flatMap(ol => ol.Symbol))
    .uniq()
    .sort()
    .value();

export const GetPositionAndOrderSymbols = createDeepArraySelector(
  OrderSymbols,
  PositionSymbolsSelector,
  (ordSymbols, positionSymbols) =>
    _(ordSymbols)
      .union(positionSymbols)
      .uniq()
      .sort()
      .value()
);

export const positionInfo = createCachedSelector(
  (s: DataState) => s.positions.info,
  (s: DataState, symbol: string) => symbol,
  (info, symbol) => {
    return info[symbol] || { quantity: 0, direction: undefined };
  }
)((s, symbol) => symbol);
export const positionInfos = createCachedSelector(
  (s: DataState) => s.positions.info,
  (s: DataState, symbols: string[]) => symbols,
  (info, symbols) => {
    let positions: PositionInfo = {};
    return _.reduce(
      symbols,
      (acc: PositionInfo, symb) => {
        const matchingInfo = _.reduce(
          info,
          (allSymbolInfo, curr, symbolKey) => {
            if (symbolKey === symb || curr.underlyingSymbol === symb) {
              return { ...allSymbolInfo, [symbolKey]: info[symbolKey] };
            }
            return allSymbolInfo;
          },
          {}
        );

        return {
          ...acc,
          ...matchingInfo,
        };
      },
      positions
    );
  }
)((s, symbols) => symbols.sort().join(','));
type OptionSymbolQuote = Pick<Option, 'Symbol' | 'Volume'> & { UnderlyingSymbol: string };

export const optionChainSelectors = {
  loadedChainsBySymbol: createCachedSelector(
    (state: DataState, symbol: string, blockId: string) => (state.optionchain && state.optionchain[blockId]) || {},
    (state: DataState, symbol: string, blockId: string) => symbol,
    (state: OptionChainBlockState, symbol: string) => {
      const symbolState = state && state[symbol];
      const allChains = symbolState && symbolState.optionchain ? _.values(symbolState.optionchain) : [];
      return _.filter(allChains, (c: ExpirationOptionPair) => c.isFetching === false);
    }
  )((state: DataState, blockId: string, symbol: string) => `${blockId}-${symbol}`),
  optionChainSymbol: createCachedSelector(
    [
      //
      (state: DataState, symbol: string) =>
        (state.optionchain && state.optionchain.bySymbol[symbol] ? state.optionchain.bySymbol[symbol] : undefined) ||
        {},
    ],
    (symbolState: ExpirationOptionPairs) => {
      // const expirations = symbolExpState && symbolExpState.expirations ? symbolExpState.expirations : [];

      const aggOptionChain = _.reduce(
        symbolState || {},
        (expOpt: ExpirationOptionPairs, expirationOptionChain: ExpirationOptionPair, key: string) => {
          const currPairs = _.reduce(
            expirationOptionChain.pairs,
            (acc: OptionPairWithSymbol[], p: OptionPairWithSymbol) => {
              const { Call, Put } = p;
              return [
                ...acc,
                {
                  ...p,
                  Call: Call,
                  Put: Put,
                },
              ];
            },
            []
          );
          return {
            ...expOpt,
            [key]: {
              ...expirationOptionChain,
              pairs: currPairs,
            },
          };
        },
        // eslint-disable-next-line @typescript-eslint/no-object-literal-type-assertion
        {} as ExpirationOptionPairs
      );
      return aggOptionChain;
    }
  )((state: DataState, blockId: string, symbol: string) => `${blockId}-${symbol}`),
};

export const loadedoptionChains = createCachedSelector(optionChainSelectors.optionChainSymbol, res =>
  res ? _.filter(res, r => r.isFetching === false) : []
)((state: DataState, blockId: string, symbol: string) => `${blockId}-${symbol}`);

export const symbolExpirationStateSelector = createCachedSelector(
  (s: DataState, symbol: string) => s.expirations[symbol],
  symbolExp => {
    if (symbolExp === undefined) {
      return { expirations: [], isFetching: false };
    }
    const exp = symbolExp;
    return exp !== undefined ? symbolExp : { expirations: [], isFetching: false };
  }
)((s: DataState, symbol: string) => symbol);

export const symbolExpirationSelector = createCachedSelector(
  (s: DataState, symbol: string) => s.expirations[symbol],
  symbolExp => {
    if (symbolExp === undefined) {
      return { expirations: [], isFetching: false };
    }
    const exp = symbolExp.expirations;
    return exp !== undefined ? exp : [];
  }
)((s: DataState, symbol: string) => symbol);
export const multipleExpirationForDateExist = createCachedSelector(
  (s: DataState, { symbol }: { symbol: string; expiration: Date }) =>
    s.expirations[symbol] ? s.expirations[symbol].expirations : [],
  (s: DataState, { expiration }: { symbol: string; expiration: Date }) => expiration,
  (expirations, expiration) => {
    const matches = _(expirations)
      .map(e => dayjs(e.date))
      .filter(e => e.isSame(dayjs(expiration), 'day'))
      .value();
    return matches.length > 1;
  }
)(
  (s: DataState, { symbol, expiration }: { symbol: string; expiration: Date }) =>
    `${symbol}-${dayjs(expiration).format()}`
);
export const origin = createCachedSelector(
  (s: DataState, origin: string) => s.auth.origin,
  origin => {
    if (!origin) {
      return undefined;
    }

    return origin;
  }
);

export const isMarketOpenSelector = (store: DataState) =>
  store.ui.marketSchedule && store.ui.marketSchedule.market && store.ui.marketSchedule.market.state
    ? store.ui.marketSchedule.market.state === MarketStateTypes.Open
    : undefined;

export const MarketOpenTimeSelector = (store: DataState) =>
  store.ui.marketSchedule && store.ui.marketSchedule.market && store.ui.marketSchedule.market.nextOpen
    ? store.ui.marketSchedule.market.nextOpen
    : undefined;

export const MarketCloseTimeSelector = (store: DataState) =>
  store.ui.marketSchedule && store.ui.marketSchedule.market && store.ui.marketSchedule.market.nextClose
    ? store.ui.marketSchedule.market.nextClose
    : undefined;

export const isIndexMarketOpenSelector = (store: DataState) =>
  store.ui.marketSchedule && store.ui.marketSchedule.indexMarket && store.ui.marketSchedule.indexMarket.state
    ? store.ui.marketSchedule.indexMarket.state === MarketStateTypes.Open
    : undefined;

export const IndexMarketOpenTimeSelector = (store: DataState) =>
  store.ui.marketSchedule && store.ui.marketSchedule.indexMarket && store.ui.marketSchedule.indexMarket.nextOpen
    ? store.ui.marketSchedule.indexMarket.nextOpen
    : undefined;

export const IndexMarketCloseTimeSelector = (store: DataState) =>
  store.ui.marketSchedule && store.ui.marketSchedule.indexMarket && store.ui.marketSchedule.indexMarket.nextClose
    ? store.ui.marketSchedule.indexMarket.nextClose
    : undefined;
