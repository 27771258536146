import { Dispatch } from 'redux';
import { StorageBlockPath, DashboardGridState, StorageBlocksPath } from '@tradingblock/types';
import { DataState } from '../../state';
import { BlockActions, RootAction } from '../../actions';
import { uiApiToken, dashboardIdSelector } from '../../dataSelectors';
import { UiApiClient } from '../../../../context/Storage';
import { Config } from '../../../../config';

export const handleBlockDeletion = async (state: DataState, dispatch: Dispatch<RootAction>, blockId: string) => {
  const provider = UiApiClient(state, dispatch);
  if (provider) {
    await provider.delete(StorageBlockPath(blockId));
  }
};

export const handleDashboardBlocksRequest = async (state: DataState, dispatch: Dispatch<RootAction>) => {
  const dashboardId = dashboardIdSelector(state) || '';
  const provider = UiApiClient(state, dispatch);
  if (provider) {
    try {
      const apiRes = await provider.get<DashboardGridState>(StorageBlocksPath(dashboardId));

      if (apiRes) {
        dispatch(
          BlockActions.receiveBlocks({
            ...(apiRes || {}),
            dashboardId,
            layouts: apiRes.layouts || {},
          })
        );
      } else {
        dispatch(BlockActions.noBlocks());
      }
    } catch (ex) {
      console.error('tryLoadBlocks error', ex);
      return Promise.resolve([]);
    }
  }
};
