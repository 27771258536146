import React, { useMemo } from 'react';
import _ from 'lodash';
import { StackedColumn } from './Column';

export interface DetailField {
  title?: string;
  Value: any;
  className?: string;
  hide?: boolean;
}

interface DetailFieldsProps {
  fieldList: DetailField[];
  valueAs?: 'p' | 'div';
}

export const DetailFields: React.FunctionComponent<DetailFieldsProps> = ({ fieldList, valueAs }) => {
  const fields = useMemo(() => {
    return _(fieldList)
      .chunk(2)
      .value();
  }, [fieldList]);

  return (
    <>
      {fields.map((curr, rowIdx) => (
        <div className="row" key={`fieldDetails-${rowIdx}`}>
          {_.map(curr, ({ title, Value, className, hide }, fieldIdx) => {
            return (
              <StackedColumn
                title={hide ? undefined : title}
                valueAs={valueAs ? valueAs : 'p'}
                className={className}
                key={`fieldDetails-${rowIdx}-${fieldIdx}`}
              >
                {!hide && <Value />}
              </StackedColumn>
            );
          })}
        </div>
      ))}
    </>
  );
};
