import { BlockDragHandle, getBlockTypeName } from "@tradingblock/components";
import { BlockType } from "@tradingblock/types";
import React from "react";

export interface AccountStatementsHeaderProps {
  blockType: BlockType;
}

export default function AccountStatementsHeader({ blockType }: AccountStatementsHeaderProps) {
  return (
    <>
    <div className="block-nav nav">
      <BlockDragHandle className="nav-item">{getBlockTypeName(blockType)} </BlockDragHandle>
    </div>
  </>
  );
}