import React, { FC, useCallback } from 'react';
import { TradingStrategyName, OrderAction, TradingStrategyNameOrCustom } from '@tradingblock/types';
import { getKnownStrategy } from '@tradingblock/api';
import { Svg } from '@tradingblock/components';
import { useOrderActions } from '../../blocks/Order/state/useOrderActions';

export const StrategyButton: FC<{ strategy: TradingStrategyName | TradingStrategyNameOrCustom }> = props => {
  const stratName = props.strategy;
  const strategy = getKnownStrategy(stratName as TradingStrategyName);
  const { setStrategy } = useOrderActions();
  const onClick = useCallback(() => setStrategy(strategy, { action: OrderAction.Buy, reverseLegs: false, isDirty: false }), [strategy]);

  return <button className="btn btn-outline-light" onClick={onClick}>
    <span className="strategy-templates-icon">
      {strategy.info.IconKind === 'svg' ? (
        <span className="strategy-icon">
          <Svg path={`strategy-${strategy.info.SvgPath}`} />
        </span>
      ) : (
        <i className={strategy.info.Icon} />
      )}
    </span>
    <span className="strategy-templates-title">{strategy.info.Name}</span>
  </button>
};
