import React, { useEffect, useMemo } from 'react';
import { Number, Loading } from '@tradingblock/components';
import { useStateSelector } from '../../data/global/dataSelectors';
import { quoteExtendedSelectors, quoteSelectors } from '../../data/global/selectors/quoteSelector';
import dayjs from 'dayjs';
import { useFeedQuoteWithMetadata } from '../../hooks/useFeedQuote';
import numeral from 'numeral';
import { useQuoteData } from './state/QuoteState';
import { useDispatch } from 'react-redux';
import { QuoteDataActions } from '../../data/global/actions';

export const SummaryInfo: React.FunctionComponent<{ symbol: string }> = ({ symbol }) => {
  //const { isLoading, extendedQuote } = useStateSelector(s => quoteExtendedSelectors.quote(s, symbol));
  const dispatch = useDispatch();
  const extendedQuote = useStateSelector(s => quoteExtendedSelectors.quote(s, symbol));
  const feedQuote = useFeedQuoteWithMetadata(symbol ? symbol : '');
  const period = useQuoteData(s => s.earningsPeriod || 'quarter');
  const { isLoading } = useStateSelector(s => quoteSelectors.earnings.symbolEarnings(s, { symbol, period }));
  const symbolQuote = useMemo(() => {
    const defaultQuote = { BidPrice: 'NA', High: 'NA', AskPrice: 'NA', Low: 'NA', Volume: 'NA' };
    if (symbol) {
      return feedQuote || defaultQuote;
    }
    return defaultQuote;
  }, [feedQuote, symbol]);

  const isDateInPast = (date: Date) => {
    const dateToCheck = new Date(date).setHours(0, 0, 0, 0);
    const today = new Date().setHours(0, 0, 0, 0);
    return dateToCheck <= today ? true : false;
  };

  useEffect(() => {
    dispatch(QuoteDataActions.requestEarnings({ symbol: symbol, period }));
  }, [symbol]);

  return (
    <>
      {(isLoading && extendedQuote === undefined) && <Loading />}
      {extendedQuote && !isLoading && (
        <>
          <div className="columns columns2">
            <div className="column">
              <ul className="props props-aligned-auto props-bordered">
                <li className="prop-inline">
                  <span className="mute prop-title txt-sm">52 Week Range</span>
                  {extendedQuote && (
                    <span className="prop-value">
                      <Number value={extendedQuote.week52Low} currency /> -{' '}
                      <Number value={extendedQuote.week52High} currency />
                    </span>
                  )}
                </li>
                <li className="prop-inline">
                  <span className="mute prop-title txt-sm">Volume</span>
                  <span className="prop-value">
                    <Number value={symbolQuote.Volume} format integer />
                  </span>
                </li>
                <li className="prop-inline">
                  <span className="mute prop-title txt-sm">Avg Volume (10 days)</span>
                  <span className="prop-value">
                    <Number value={extendedQuote.avg10Volume} format integer />
                  </span>
                </li>
                <li className="prop-inline">
                  <span className="mute prop-title txt-sm">EPS (TTM)</span>
                  <span className="prop-value">
                    <Number value={extendedQuote.ttmEps} currency />
                  </span>
                </li>
                <li className="prop-inline">
                  <span className="mute prop-title txt-sm">200-Day Moving Average</span>
                  <span className="prop-value">
                    <Number value={extendedQuote.day200MovingAvg} currency />
                  </span>
                </li>
                <li className="prop-inline">
                  <span className="mute prop-title txt-sm">50-Day Moving Average</span>
                  <span className="prop-value">
                    <Number value={extendedQuote.day50MovingAvg} currency />
                  </span>
                </li>
                <li className="prop-inline">
                  <span className="mute prop-title txt-sm">Next Earnings Date</span>
                  <span className="prop-value">
                    {extendedQuote.nextEarningsDate &&
                      !isDateInPast(extendedQuote.nextEarningsDate) &&
                      dayjs(extendedQuote.nextEarningsDate).format('MM/DD/YYYY')}
                  </span>
                </li>
              </ul>
            </div>
            <div className="column">
              <ul className="props props-aligned-auto props-bordered">
                <li className="prop-inline">
                  <span className="mute prop-title txt-sm">Market Cap</span>
                  <span className="prop-value currency-abr">{numeral(extendedQuote.marketCap).format('$0.00 a')}</span>
                </li>
                <li className="prop-inline">
                  <span className="mute prop-title txt-sm">Shares Outstanding</span>
                  <span className="prop-value currency-abr">
                    {numeral(extendedQuote.sharesOutstanding).format('0.00 a')}
                  </span>
                </li>
                <li className="prop-inline">
                  <span className="mute prop-title txt-sm">P/E Ratio (TTM)</span>
                  <span className="prop-value">
                    <Number value={extendedQuote.peRatio} />
                  </span>
                </li>
                <li className="prop-inline">
                  <span className="mute prop-title txt-sm">Beta</span>
                  <span className="prop-value">
                    <Number value={extendedQuote.beta} />
                  </span>
                </li>
                <li className="prop-inline">
                  <span className="mute prop-title txt-sm">Dividend Yield (TTM)</span>
                  <span className="prop-value">{numeral(extendedQuote.dividendYield).format('0.00%')}</span>
                </li>
                <li className="prop-inline">
                  <span className="mute prop-title txt-sm">Dividend Rate (TTM)</span>
                  <span className="prop-value">
                    <Number value={extendedQuote.ttmDividendRate} currency />
                  </span>
                </li>
                <li className="prop-inline">
                  <span className="mute prop-title txt-sm">Next Ex-Dividend Date</span>
                  <span className="prop-value">
                    {extendedQuote.nextDividendDate &&
                      !isDateInPast(extendedQuote.nextDividendDate) &&
                      dayjs(extendedQuote.nextDividendDate).format('MM/DD/YYYY')}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </>
      )}
    </>
  );
};
