import { ActionType, getType } from 'typesafe-actions';
import { legacyOrderGroupActions, LegacyOrderGroupAction } from './OrderActions';

type GroupAction = ActionType<typeof legacyOrderGroupActions.handleOrderGroupChange>;
export function isGroupHandlingAction<T extends { type: string }>(action: T | GroupAction): action is GroupAction {
  return [legacyOrderGroupActions.handleOrderGroupChange].map(a => getType(a)).includes(action.type);
}

export function isLegacyOrderGroupAction<T extends { type: string }>(
  action: T | LegacyOrderGroupAction
): action is LegacyOrderGroupAction {
  return [legacyOrderGroupActions.addOrderLeg, legacyOrderGroupActions.handleOrderGroupChange]
    .map(a => getType(a))
    .includes(action.type);
}
