import { AddressModel, Country, DataValue, ErrorType, RE_EMAIL } from '@tradingblock/types';
import _ from 'lodash';
import Config from '../../../config';
import xml2js from 'xml2js';

export interface USPSErrors {
  [key: string]: string | undefined;
}

export const getError = (value: DataValue, error: ErrorType) => {
  if (!value && !_.isNumber(value)) {
    return error;
  }
  return undefined;
};

export const getRequiredError = (value: DataValue) => {
  return getError(value, ErrorType.Required);
};

export const getInvalidError = (value: DataValue) => {
  return getError(value, ErrorType.Invalid);
};

export const getAmountError = (value: DataValue) => {
  return getError(value, ErrorType.Amount);
};

export const getContingentError = (value: DataValue) => {
  return getError(value, ErrorType.Contingent);
};

export const getMismatchError = (value: DataValue) => {
  return getError(value, ErrorType.Mismatch);
};

export const getEmailError = (value: string | null | undefined) => {
  return getRequiredError(value) || getInvalidError(value && RE_EMAIL.test(value));
};

export const getStaleError = (value: DataValue) => {
  return getError(value, ErrorType.Stale);
};

export const getUploadError = (value: DataValue) => {
  return getError(value, ErrorType.Upload);
};

export const RE_PO_BOX = /^ *((#\d+)|((box|bin)[-. \/\\]?\d+)|(.*p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b|(#|num)?\d+))|(p(ost)? *(o(ff(ice)?)?)? *((box|bin)|b)? *\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(number|num|#)? *\d+|(num|number|#) *\d+)/i;

// Promise parser for parsing xml to json
const promisesParser = (string: string) => {
  return new Promise(function(resolve, reject) {
    xml2js.parseString(string, (err, result) => {
      if (err) {
        return reject(err);
      } else {
        return resolve(result);
      }
    });
  });
};

export const uspsVerification = async (values: AddressModel) => {
  const baseURL = Config.uspsBaseUrl;
  const uspsId = Config.uspsId;

  const requestConfig = {
    Headers: {
      'Content-Type': 'text/xml',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Methods': 'GET',
    },
    method: 'get',
  };

  const { address1, address2, city, state, country, postalCode } = values;

  // Build XML Request
  const xml = `<AddressValidateRequest USERID="${uspsId}"><Address ID="0"><Address1>${encodeURIComponent(
    address1
  )}</Address1><Address2>${encodeURIComponent(
    address2
  )}</Address2><City>${city}</City><State>${state}</State><Zip5>${postalCode}</Zip5><Zip4></Zip4></Address></AddressValidateRequest>`;

  // Utility function to simplify address for comparison
  // Removes common suffixes, unit indicators, directional abbreviations, and whitespace
  const simplifyAddress = (address: string) => {
    return address
      .toUpperCase()
      .replace(
        /\b(STREET|ST\.?|ROAD|RD\.?|AVENUE|AVE\.?|BOULEVARD|BLVD\.?|DRIVE|DR\.?|LANE|LN\.?|COURT|CT\.?|CIRCLE|CIR\.?|TERRACE|TER\.?|PLACE|PL\.?|LOOP|LOOP|HIGHWAY|HWY\.?|PARKWAY|PKWY\.?|ALLEY|ALY\.?|PLAZA|PLZ\.?|PATH|PATH|WAY|WAY)\b/gi,
        '' // Remove common street suffixes
      )
      .replace(/\b(UNIT|APT|APARTMENT|SUITE|STE|#)\b/gi, '') // Normalize unit indicators
      .replace(/\b(SOUTH|S\.?|NORTH|N\.?|EAST|E\.?|WEST|W\.?)\b/gi, '') // Normalize directional abbreviations
      .replace(/\s+/g, ' ') // Replace multiple whitespace with a single space
      .trim();
  };

  // Function to check if addresses are flipped in USPS validation response based on simplified comparison
  const isFlipped = (address1: string, address2: string) => {
    return _.includes(simplifyAddress(address1), simplifyAddress(address2));
  };

  try {
    if (address1 && city && state && country === Country.UnitedStatesOfAmerica) {
      const response = await fetch(`${baseURL}${xml}`, requestConfig);
      const xmlText = await response.text();
      const xmlResult: any = await promisesParser(xmlText);
      const { AddressValidateResponse } = xmlResult;
      const { Address } = AddressValidateResponse;

      const { City, State, Zip5, Zip4, Address1, Address2, Error } = Address[0];
      if (Error) {
        // Handle API error response
        const { Number, Source, Description } = Error[0];
        return {
          error: {
            number: Number[0],
            source: Source[0],
            description: Description[0],
          },
        };
      } else {
        // Extract address lines from API response
        let address1Value = Address1 ? Address1[0] : null;
        let address2Value = Address2 ? Address2[0] : null;

        // Correct the address lines if they have been flipped
        if (!address1Value) {
          address1Value = address2Value;
          address2Value = null;
        } else if (Address1 && isFlipped(address1, address2Value)) {
          // Swap the address lines using array destructuring
          [address1Value, address2Value] = [address2Value, address1Value];
        }

        // Return the corrected and standardized address components
        return {
          city: City[0] || '',
          state: State[0] || '',
          zip5: Zip5[0] || '',
          zip4: Zip4[0] || '',
          uspsAddress1: address1Value || '',
          uspsAddress2: address2Value || null,
        };
      }
    }
  } catch (e) {
    console.error(e);
  }
};
