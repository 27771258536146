import React from 'react';
import { CashieringBaseEntity } from '@tradingblock/types';
import { getApprovalMethod } from '../../../../../utilities/cashiering';

export const CashieringMethodCell: React.FunctionComponent<Pick<CashieringBaseEntity, 'transfer' | 'relationship'>> = ({
  relationship,
  transfer,
}) => {
  return (
    <>
      {relationship && (
        <>
          Method: {getApprovalMethod(relationship.approvalMethod)}
          <br />
          {relationship.text}
        </>
      )}
      {transfer && (
        <>
          {transfer.mechanism}
          <br />
          {transfer.text}
        </>
      )}
    </>
  );
};
