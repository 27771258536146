import React from 'react';
import { DataState } from '../../../data/global/state';
import { useStateSelector } from '../../../data/global/dataSelectors';

export const BlockLoading: React.FC<{
  isFetchingSelector?: (state: DataState) => boolean;
  as: 'li' | 'div';
}> = ({ isFetchingSelector, as }) => {
  const isRefreshing = useStateSelector(isFetchingSelector ? isFetchingSelector : (state: DataState) => false);
  return (
    <>
      {isRefreshing && as === 'li' && (
        <li className="nav-item">
          <i className="fas fa-spin fa-spinner pending" title="Refreshing..."></i>
        </li>
      )}
      {isRefreshing && as === 'div' && (
        <div className="nav-item">
          <i className="fas fa-spin fa-spinner pending" title="Refreshing..."></i>
        </div>
      )}
    </>
  );
};
