/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { Hotkey, HotkeyProps } from '@tradingblock/components';
import { useDispatcher } from '../../data/global/hooks';

export const DashboardHotkeys: React.FunctionComponent<Pick<HotkeyProps, 'modifier'>> = ({ modifier }) => {
  const { dispatcher } = useDispatcher();
  const toggleSettings = useCallback(() => dispatcher.settings.toggleSettings(), [dispatcher]);
  return (
    <>
      <Hotkey keys="s" modifier={modifier} callback={toggleSettings} />
    </>
  );
};
