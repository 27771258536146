import { getType } from 'typesafe-actions';
import { InitialState } from '../initialState';
import { RootAction, Actions } from '../actions';

export const accountsBalances = (state = InitialState.accountData.accountsBalances, action: RootAction) => {
  switch (action.type) {
    case getType(Actions.requestLinkedAccountBalances): {
      return { ...state };
    }
    case getType(Actions.fetchLinkedAccountBalances): {
      return { ...state };
    }
    case getType(Actions.receiveLinkedAccountBalances): {
      return {
        ...state,
        [action.payload.accountId]: action.payload.balance,
      };
    }
    default:
      return state;
  }
};
