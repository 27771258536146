import { Middleware, Dispatch } from 'redux';
import { getType } from 'typesafe-actions';
import { DataState } from '../../state';
import { RootAction, LifecycleActions, SymbolActions } from '../../actions';
import { ApiProvider } from '../../../../context/Api';

export const loadSymbolsWarning = (state: DataState, dispatch: Dispatch<RootAction>) => {
  const api = ApiProvider(state, dispatch);
  if (api) {
    api.symbols.warnings().then(res => {
      dispatch(SymbolActions.receiveWarning(res.payload));
    });
  }
};

export const LifecycleMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  try {
    // state BEFORE action is dispatched
    const prevState = getState();
    const result = next(action);
    // state AFTER action is dispatched
    // const nextState = getState();

    switch (action.type) {
      case getType(LifecycleActions.loadInitialData): {
        loadSymbolsWarning(prevState, dispatch);
        break;
      }
    }

    return result;
  } catch (err) {
    console.error('lifecycleMiddleware :: Caught an exception for action ', action, err);
  }
};
