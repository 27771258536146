import { AccountDetails, Device, PendingAccountManagementUpdate } from '@tradingblock/types';
import { buildGenericRequestReceiveActions } from '../actionUtilities';
import { AccountUpdateUserAccountDetailRequest, GenericError } from '@tradingblock/types';

// Account Detail Actions
const REQUEST_ACCOUNT_DETAILS = '@data/REQUEST/ACCOUNT_DETAILS';
const RECEIVE_ACCOUNT_DETAILS = '@data/RECEIVE/ACCOUNT_DETAILS';
const ERROR_ACCOUNT_DETAILS = '@data/ERROR/ACCOUNT_DETAILS';

const [requestAccountDetails, receiveAccountDetails, errorAccountDetails] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_ACCOUNT_DETAILS,
  RECEIVE: RECEIVE_ACCOUNT_DETAILS,
  ERROR: ERROR_ACCOUNT_DETAILS,
})<{ accountId: number }, { accountDetails: AccountDetails }, { error: any; accountId: number }>();

// View Account Update Actions
const REQUEST_ACCOUNT_UPDATE = '@account/REQUEST/ACCOUNT_UPDATE';
const RECEIVE_ACCOUNT_UPDATE = '@account/RECEIVE/ACCOUNT_UPDATE';
const ERROR_ACCOUNT_UPDATE = '@account/ERROR/ACCOUNT_UPDATE';

const [requestAccountUpdate, receiveAccountUpdate, errorAccountUpdate] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_ACCOUNT_UPDATE,
  RECEIVE: RECEIVE_ACCOUNT_UPDATE,
  ERROR: ERROR_ACCOUNT_UPDATE,
})<{ confirm: any }, { update: any }, { error: any }>();

// Make Account Update Actions
const REQUEST_MAKE_ACCOUNT_UPDATE = '@account/REQUEST/UPDATE_ACCOUNT';
const RECEIVE_MAKE_ACCOUNT_UPDATE = '@account/RECEIVE/UPDATE_ACCOUNT';
const ERROR_MAKE_ACCOUNT_UPDATE = '@account/ERROR/UPDATE_ACCOUNT';

const [requestMakeAccountUpdate, receiveMakeAccountUpdate, errorMakeAccountUpdate] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_MAKE_ACCOUNT_UPDATE,
  RECEIVE: RECEIVE_MAKE_ACCOUNT_UPDATE,
  ERROR: ERROR_MAKE_ACCOUNT_UPDATE,
})<{ accountId: any; request: any; accountHolder?: number }, { response: any }, { error: any }>();

// Confirm Account Email Updates Actions
const REQUEST_CONFIRM_UPDATE = '@account/REQUEST/CONFIRM_UPDATE';
const RECEIVE_CONFIRM_UPDATE = '@account/RECEIVE/CONFIRM_UPDATE';
const ERROR_CONFIRM_UPDATE = '@account/ERROR/CONFIRM_UPDATE';

const [requestConfirmUpdate, receiveConfirmUpdate, errorConfirmUpdate] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_CONFIRM_UPDATE,
  RECEIVE: RECEIVE_CONFIRM_UPDATE,
  ERROR: ERROR_CONFIRM_UPDATE,
})<{ confirm: any }, { response: any }, { error: any }>();

// Request/Retrieve Account Management Details
const REQUEST_ACCOUNT_MANAGEMENT_DETAILS = '@account/REQUEST/ACCOUNT_MANAGEMENT_DETAILS';
const RECEIVE_ACCOUNT_MANAGEMENT_DETAILS = '@account/RECEIVE/ACCOUNT_MANAGEMENT_DETAILS';
const ERROR_ACCOUNT_MANAGEMENT_DETAILS = '@account/ERROR/ACCOUNT_MANAGEMENT_DETAILS';

const [
  requestAccountManagementDetails,
  receiveAccountManagementDetails,
  errorAccountManagementDetails,
] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_ACCOUNT_MANAGEMENT_DETAILS,
  RECEIVE: RECEIVE_ACCOUNT_MANAGEMENT_DETAILS,
  ERROR: ERROR_ACCOUNT_MANAGEMENT_DETAILS,
})<AccountUpdateUserAccountDetailRequest, { response: any }, GenericError<any>>();

// Request/Retrieve Pending Account Management Updates
const REQUEST_PENDING_ACCOUNT_MANAGEMENT_UPDATES = '@account/REQUEST/PENDING_ACCOUNT_MANAGEMENT_UPDATES';
const RECEIVE_PENDING_ACCOUNT_MANAGEMENT_UPDATES = '@account/RECEIVE/PENDING_ACCOUNT_MANAGEMENT_UPDATES';
const ERROR_PENDING_ACCOUNT_MANAGEMENT_UPDATES = '@account/ERROR/PENDING_ACCOUNT_MANAGEMENT_UPDATES';

const [
  requestPendingAccountManagementUpdates,
  receivePendingAccountManagementUpdates,
  errorPendingAccountManagementUpdates,
] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_PENDING_ACCOUNT_MANAGEMENT_UPDATES,
  RECEIVE: RECEIVE_PENDING_ACCOUNT_MANAGEMENT_UPDATES,
  ERROR: ERROR_PENDING_ACCOUNT_MANAGEMENT_UPDATES,
})<{ accountId: number }, { pendingItems: PendingAccountManagementUpdate[] }, GenericError<any>>();

// // Retrieve User Account Updates
// const REQUEST_USER_ACCOUNT_UPDATES = '@admin/REQUEST/USER_ACCOUNT_UPDATES';
// const RECEIVE_USER_ACCOUNT_UPDATES = '@admin/RECEIVE/USER_ACCOUNT_UPDATES';
// const ERROR_USER_ACCOUNT_UPDATES = '@admin/ERROR/USER_ACCOUNT_UPDATES';

// const [
//   requestUserAccountUpdates,
//   receiveUserAccountUpdates,
//   errorUserAccountUpdates,
// ] = buildGenericRequestReceiveActions({
//   REQUEST: REQUEST_USER_ACCOUNT_UPDATES,
//   RECEIVE: RECEIVE_USER_ACCOUNT_UPDATES,
//   ERROR: ERROR_USER_ACCOUNT_UPDATES,
// })<AccountUpdateSearchRequest, { response: any; request: AccountUpdateSearchRequest }, GenericError<any>>();

// Security/MFA Actions
const REQUEST_NOPASS_DEVICES = '@account/REQUEST/NOPASS_DEVICES';
const RECEIVE_NOPASS_DEVICES = '@account/RECEIVE/NOPASS_DEVICES';
const ERROR_NOPASS_DEVICES = '@account/ERROR/NOPASS_DEVICES';

const [requestNoPassDevices, receiveNoPassDevices, errorNoPassDevices] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_NOPASS_DEVICES,
  RECEIVE: RECEIVE_NOPASS_DEVICES,
  ERROR: ERROR_NOPASS_DEVICES,
})<any, { devices: Device[] }, GenericError<any>>();

const REQUEST_DELETE_NOPASS_DEVICE = '@account/REQUEST/DELETE_NOPASS_DEVICE';
const RECEIVE_DELETE_NOPASS_DEVICE = '@account/RECEIVE/DELETE_NOPASS_DEVICE';
const ERROR_DELETE_NOPASS_DEVICE = '@account/ERROR/DELETE_NOPASS_DEVICE';

const [
  requestDeleteNoPassDevice,
  receiveDeleteNoPassDevice,
  errorDeleteNoPassDevice,
] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_DELETE_NOPASS_DEVICE,
  RECEIVE: RECEIVE_DELETE_NOPASS_DEVICE,
  ERROR: ERROR_DELETE_NOPASS_DEVICE,
})<any, { devices: Device[] }, GenericError<any>>();

const REQUEST_CHECK_NOPASS_ENABLED = '@account/REQUEST/CHECK_NOPASS_ENABLED';
const RECEIVE_CHECK_NOPASS_ENABLED = '@account/RECEIVE/CHECK_NOPASS_ENABLED';
const ERROR_CHECK_NOPASS_ENABLED = '@account/ERROR/CHECK_NOPASS_ENABLED';

const [
  requestCheckNoPassEnabled,
  receiveCheckNoPassEnabled,
  errorCheckNoPassEnabled,
] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_CHECK_NOPASS_ENABLED,
  RECEIVE: RECEIVE_CHECK_NOPASS_ENABLED,
  ERROR: ERROR_CHECK_NOPASS_ENABLED,
})<any, { response: any }, GenericError<any>>();

const REQUEST_AUTH_HISTORY = '@account/REQUEST/AUTH_HISTORY';
const RECEIVE_AUTH_HISTORY = '@account/RECEIVE/AUTH_HISTORY';
const ERROR_AUTH_HISTORY = '@account/ERROR/AUTH_HISTORY';

const [requestAuthHistory, receiveAuthHistory, errorAuthHistory] = buildGenericRequestReceiveActions({
  REQUEST: REQUEST_AUTH_HISTORY,
  RECEIVE: RECEIVE_AUTH_HISTORY,
  ERROR: ERROR_AUTH_HISTORY,
})<any, { response: any }, GenericError<any>>();

export const AccountManagementDataActions = {
  requestAccountDetails,
  receiveAccountDetails,
  errorAccountDetails,
  requestAccountUpdate,
  receiveAccountUpdate,
  errorAccountUpdate,
  requestMakeAccountUpdate,
  receiveMakeAccountUpdate,
  errorMakeAccountUpdate,
  requestConfirmUpdate,
  receiveConfirmUpdate,
  errorConfirmUpdate,
  requestAccountManagementDetails,
  receiveAccountManagementDetails,
  errorAccountManagementDetails,
  requestPendingAccountManagementUpdates,
  receivePendingAccountManagementUpdates,
  errorPendingAccountManagementUpdates,
  requestNoPassDevices,
  receiveNoPassDevices,
  errorNoPassDevices,
  requestDeleteNoPassDevice,
  receiveDeleteNoPassDevice,
  errorDeleteNoPassDevice,
  requestCheckNoPassEnabled,
  receiveCheckNoPassEnabled,
  errorCheckNoPassEnabled,
  requestAuthHistory,
  receiveAuthHistory,
  errorAuthHistory,
};
