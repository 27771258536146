import React, { useMemo } from 'react';
import { BalanceHistory, BalanceHistoryDividend } from '@tradingblock/types';
import { useStateSelector } from '../../data/global/dataSelectors';
import { Table, onDateSort } from '@tradingblock/components';
import { formatMoney } from './AnalyticsHelper';
interface AnalyticsBalanceHistoryProps {
  filteredBalances: BalanceHistory[];
  pageSize?: number;
  pageIndex?: number;
  total?: number;
  handleTimeFrame: (start: Date | undefined, end: Date | undefined) => void;
  handlePageChange: (pageIndex: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
}

//a tab within performance/analytics block
export const AnalyticsBalanceHistory: React.FC<AnalyticsBalanceHistoryProps> = ({
  filteredBalances,
  pageSize,
  pageIndex,
  total,
  handleTimeFrame,
  handlePageChange,
  handlePageSizeChange,
}: AnalyticsBalanceHistoryProps): JSX.Element => {
  const initialState = useMemo(() => {
    return {
      pageSize: pageSize === undefined ? 50 : pageSize,
      pageIndex: pageIndex || 0,
      sortBy: [],
      columnOrder: [],
    };
  }, [pageSize, pageIndex]);

  const pageCount = useMemo(() => {
    if (pageSize) {
      return Math.ceil((total || 0) / pageSize) === 0 ? 1 : Math.ceil((total || 0) / pageSize);
    }
    return 0;
  }, [total, pageSize]);

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        canSort: true,
        sortType: onDateSort,
        accessor: (accessData: BalanceHistory) => {
          return new Date(accessData.recordDate).toLocaleDateString();
        },
        id: 'date',
      },
      {
        Header: 'Cash Available',
        accessor: (accessData: BalanceHistory) => {
          return formatMoney(accessData.cashAvailable);
        },
        id: 'cashAvailable',
      },
      {
        Header: 'Money Market Value',
        accessor: (accessData: BalanceHistory) => {
          return formatMoney(accessData.moneyMarketValue);
        },
        id: 'moneyMarketValue',
      },
      {
        Header: 'Securities Value',
        accessor: (accessData: BalanceHistory) => {
          return formatMoney(accessData.securitiesValue);
        },
        id: 'securitiesValue',
      },
      {
        Header: 'Dividends',
        accessor: (accessData: BalanceHistory) => {
          // if dividend values, sum up the amounts and return
          // ignore any negative values
          // otherwise clients will not see a dividend value if it was reinvested
          if (accessData.dividends && accessData.dividends.length > 0) {
            return formatMoney(
              accessData.dividends.reduce((acc: number, dividend: BalanceHistoryDividend) => {
                if (dividend.amount > 0) {
                  return acc + dividend.amount;
                }
                return acc;
              }, 0)
            );
          } else {
            return formatMoney(0);
          }
        },
        id: 'dividends',
      },
      {
        Header: 'Account Value',
        accessor: (accessData: BalanceHistory) => {
          return formatMoney(accessData.accountValue);
        },
        id: 'accountBalance',
      },
    ],
    [filteredBalances]
  );
  const isDashboardLocked = useStateSelector(s => s.ui.dashboard.isLocked);
  return (
    <Table
      isDraggable={!isDashboardLocked}
      tableKey="balanceHistory"
      columnWidths={['16.66%', '16.66%', '16.66%', '16.66%', '16.66%', '16.66%']}
      sortable={true}
      columns={columns}
      hasPagination={true}
      pageCount={pageCount}
      initialState={initialState}
      data={filteredBalances}
      frozenHeaders
      onPage={handlePageChange}
      onPageSize={handlePageSizeChange}
      manualPagination={true}
    />
  );
};
