import React, { useEffect, useCallback, useMemo } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Cell } from 'react-table';
import { useParentSizeContext, ColumnWithSorting, Loading } from '@tradingblock/components';
import { AccountManagementSearchEntityWithKey, AccountUpdateSearchRequest } from '@tradingblock/types';
import { useDispatcher } from '../../../../data/global/hooks';
import { useStateSelector } from '../../../../data/global/dataSelectors';
import { AccountUpdateSearchResponse } from '@tradingblock/types';
import { useAccountManagementData } from '../useAccountManagement';
import {
  AdminAccountManagementActions,
  AdminAccountManagementSearchActions,
} from '../../../../data/global/actions/admin/AccountManagementActions';
import { isFetchingAccountManagementSearchUpdates } from '../../../../data/global/selectors/admin/accountManagementSelector';
import { ItemCell } from './table/AccountManagementItemCell';
import { AccountManagementActionCell } from './table/AccountManagementActionCell';
import { AdminAccountManagementDetail } from '../AdminAccountManagementDetail';
import { AccountManagementFilters } from './AccountManagementFilters';
import { BlockTable } from '../../../shared/BlockTable';
import { useSelector } from 'react-redux';
import { account } from '../../../../data/global/selectors/accountSelectors';

const AdminAccountManagementColumns: ColumnWithSorting<AccountUpdateSearchResponse>[] = [
  {
    Header: 'Account',
    accessor: v => v.accountNumber,
    sortType: 'alphanumeric',
    id: 'AccountNumber',
  },
  {
    Header: 'AccountId',
    accessor: v => v.accountId,
    sortType: 'alphanumeric',
    id: 'AccountId',
  },
  {
    Header: 'Name',
    accessor: v => v.accountName,
    sortType: 'alphanumeric',
    id: 'AccountName',
  },
  {
    Header: 'Item',
    accessor: v => v.item,
    Cell: ({ row }: Cell<any>) => {
      const { item, state, updated } = row.original;
      if (!item) {
        return <></>;
      } else {
        return <ItemCell {...{ item, state, updated }} />;
      }
    },
    id: 'Item',
    disableSortBy: true,
  },
  {
    Header: 'Date',
    accessor: v => v.date,
    sortDescFirst: true,
    id: 'Date',
    Cell: ({ row }: Cell<any>) => {
      const dateVal = dayjs(row.original.date);
      if (dateVal && dateVal.isValid()) {
        return (
          <>
            {dateVal.format('M/DD/YY')}
            <br />
            {dateVal.format('hh:mm A')}
          </>
        );
      }
      return <></>;
    },
  },
  {
    Header: 'Notes',
    accessor: v => v.note,
    disableSortBy: true,
    Cell: ({ row }: Cell<any>) => {
      const { note } = row.original;
      if (!note) {
        return <></>;
      } else {
        return <>{note}</>;
      }
    },
  },
  {
    Header: 'Action',
    id: 'action',
    className: 'text-center',
    Cell: ({ row }: Cell<any>) => {
      const { state, accountId, id } = row.original;
      return (
        <div className="text-center">
          <AccountManagementActionCell {...{ state, accountId, id }} />
        </div>
      );
    },
  },
];

export const AccountManagementTable: React.FunctionComponent<{}> = () => {
  const { dispatch } = useDispatcher();
  const { data, total, timeframe, ...filterData } = useAccountManagementData();
  const { sortBy, sortByOrder, pageSize, page } = filterData;
  const currentItemRequest = useStateSelector(s => s.private.accountManagement.currentItem.request);
  const isUpdated = useStateSelector(s => s.private.accountManagement.currentItem.isUpdated);
  const [isFetching, hasData] = useStateSelector(isFetchingAccountManagementSearchUpdates);
  const userLevel = useSelector(account.userLevel);
  const isAdmin = userLevel === 'Account' || !userLevel ? false : true;

  useEffect(() => {
    if (isUpdated) {
      dispatch(AdminAccountManagementActions.requestUserAccountUpdates(filterData));
    }
  }, [isUpdated]);

  useEffect(() => {
    if (!hasData) {
      dispatch(AdminAccountManagementActions.requestUserAccountUpdates(filterData));
    }

    if (isAdmin) {
      const intervalId = setInterval(function() {
        dispatch(AdminAccountManagementActions.refresh({ ...filterData, replace: true }));
      }, 60 * 3 * 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, []);

  const getCurrentSortBy = useCallback(
    (newDesc?: boolean) => {
      if (sortBy) {
        return { id: sortBy, desc: !_.isUndefined(newDesc) ? newDesc : sortByOrder === 'desc' };
      }
      return undefined;
    },
    [sortBy, sortByOrder]
  );
  const initialState = useMemo(() => {
    const currentSortBy = getCurrentSortBy();
    return {
      sortBy: currentSortBy ? [currentSortBy] : [],
      pageSize: _.isUndefined(pageSize) ? 10 : pageSize,
      pageIndex: page || 0,
      columnOrder: [],
    };
  }, [pageSize, page, getCurrentSortBy]);

  const pageCount = useMemo(() => {
    if (pageSize) {
      return _.ceil(total / pageSize);
    }
    return 0;
  }, [pageSize, total]);

  const onSort = useCallback(
    (sort: any[]) => {
      const sortVal = _.first(sort) || getCurrentSortBy(sortByOrder === 'asc');
      if (sortVal) {
        const { id, desc } = sortVal;
        const sortData: Pick<AccountUpdateSearchRequest, 'sortBy' | 'sortByOrder'> = {
          sortBy: id,
          sortByOrder: desc === true ? 'desc' : 'asc',
        };
        dispatch(AdminAccountManagementSearchActions.setSortBy(sortData));
      } else {
        dispatch(AdminAccountManagementSearchActions.setSortBy({ sortBy: undefined, sortByOrder: undefined }));
      }
    },
    [getCurrentSortBy]
  );

  const onPage = useCallback((page: number) => {
    dispatch(AdminAccountManagementSearchActions.setPage({ page }));
  }, []);

  const onPageSize = useCallback((pageSize: number) => {
    dispatch(AdminAccountManagementSearchActions.setPageSize({ pageSize }));
  }, []);

  const getRowKey = useCallback((data: AccountManagementSearchEntityWithKey) => `${data.entityKey}`, []);

  if (!_.isEmpty(currentItemRequest)) {
    return <AdminAccountManagementDetail />;
  }

  return (
    <>
      {isFetching && <Loading />}
      <useParentSizeContext.Provider>
        {!isFetching && (
          <>
            <AccountManagementFilters />
            {hasData && (
              <>
                <BlockTable
                  tableKey="adminAccountManagmentTable"
                  columns={AdminAccountManagementColumns}
                  numColumns={7}
                  initialState={initialState}
                  data={data}
                  loaded={true}
                  onSort={onSort}
                  getRowKey={getRowKey}
                  sortable
                  hasPagination
                  manualSortBy
                  manualPagination
                  pageCount={pageCount}
                  onPage={onPage}
                  onPageSize={onPageSize}
                />
              </>
            )}
            {!hasData && (
              <>
                <p>
                  <i className="fal fa-folder-open" /> No matching items found
                </p>
              </>
            )}
          </>
        )}
      </useParentSizeContext.Provider>
    </>
  );
};
