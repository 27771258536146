import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CashieringView, CreateAchRelationshipRequest } from '@tradingblock/types';
import { CashieringViewFormValues, CashieringViewProps, ViewOnSubmitType, ViewOnValidateType } from "./data/useCashieringView";
import { SelectDropdownInput, TextboxField } from '@tradingblock/components';
import _ from 'lodash';
import { FormGroup } from 'react-bootstrap';
import { PlaidLinkOnSuccessMetadata, usePlaidLink } from 'react-plaid-link';
import { Field } from 'formik';
import { useDispatcher } from '../../../data/global/hooks';

//TODO: Need to consolidate this form with our other plaid form.
const OAuthAchRelationshipPlaidForm: React.FunctionComponent<any> = ({ plaidLinkToken, values, setValues }) => {
  const [plaidMetadata, setPlaidMetadata] = useState<PlaidLinkOnSuccessMetadata>();
  const [plaidFailed, setPlaidFailed] = useState<boolean>();

  const plaidSucceeded = useMemo(() => {
    return values.account && values.account.plaidPublicToken ? true : false;
  }, [values]);

  const plaidConfig = useMemo(() => {
    return {
      token: plaidLinkToken,
      linkCustomizationName: 'config_en',
      onExit: (error: object | null) => {
        console.warn('Exited Plaid Link... ', error);
        setPlaidFailed(true);
      },
      onSuccess: (plaidPublicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
        setPlaidMetadata(metadata);
        setValues({ ...values, account: { ...values.account, plaidPublicToken } });
      },
      receivedRedirectUri: window.location.href, 
    };
  }, []);

  const { open: openPlaidLink, ready: plaidReady } = usePlaidLink(plaidConfig);

  useEffect(() => {
    // open Plaid link right away once it's ready
    if (plaidReady) {
      openPlaidLink();
    }
  }, [plaidReady, openPlaidLink]);

  const bankName = useMemo(() => {
    return (plaidMetadata && plaidMetadata.institution && plaidMetadata.institution.name) || 'bank';
  }, [plaidMetadata]);

  const accounts = useMemo(() => {
    // only allow checking|savings accounts to be selected
    return _.filter(plaidMetadata && plaidMetadata.accounts, a => a.type === 'depository' && (a.subtype === 'checking' || a.subtype === 'savings'));
  }, [plaidMetadata]);

  const onChangePlaidAccountId = useCallback(
    (plaidAccountId: string) => {
      const bankAccount = _.find(accounts, a => a.id === plaidAccountId);
      setValues({ ...values, account: { ...values.account, plaidAccountId, nickName: bankAccount ? bankAccount.name : undefined, approvalMethod: 'Plaid' } });
    },
    [accounts, values]
  );

  return (
    <>
      <p className="fields-title">Link new bank account</p>
      {!plaidSucceeded && plaidFailed && (
        <p>There was an issue creating your bank account link.</p>
      )}
      {plaidSucceeded && (
        <>
          <p className="mute">Select an account and give it a nickname to complete linking.</p>
          <FormGroup>
            <p>
              <span className="mute">Bank:</span> {bankName}
            </p>
          </FormGroup>
          <FormGroup>
            <Field
              component={SelectDropdownInput}
              id="account.plaidAccountId"
              value={values.account && values.account.plaidAccountId}
              options={_.map(accounts, a => ({
                value: a.id,
                label: (
                  <>
                    {a.name} <span className="mute">(***{a.mask})</span>
                  </>
                ),
              }))}
              label={`Select ${bankName} account`}
              onchange={(e: any, plaidAccountId: string) => onChangePlaidAccountId(plaidAccountId)}
            />
          </FormGroup>
          <FormGroup>
            <Field component={TextboxField} id="account.nickName" label="Nickname for account (e.g. Personal checking)" />
          </FormGroup>
        </>
      )}
    </>
  );
};

const OAuthPlaidRedirectView: React.FC<CashieringViewProps<CashieringViewFormValues>> = ({ values, setValues }) => {
  const plaidLinkToken = localStorage.getItem('lastPlaidLinkToken');
  
  return (
    <>
      <div className="screen-body-section">
        {plaidLinkToken ? <OAuthAchRelationshipPlaidForm plaidLinkToken={plaidLinkToken} setValues={setValues} values={values} /> : (<p>There was an issue getting your account info. Please try again.</p>)}
      </div>
    </>
  );
};

export const useOAuthPlaidRedirectView = (): [typeof OAuthPlaidRedirectView, ViewOnSubmitType, ViewOnValidateType] => {
  const { dispatcher } = useDispatcher();
  
  const onValidate = useCallback(
    (values: CashieringViewFormValues) => {
      const errors = {};
      return errors;
    }, []);

  const onSubmit = useCallback(
    (values: CashieringViewFormValues, view: CashieringView | undefined) => {
      const { account } = values;
      const request = _.pick(account, ['plaidPublicToken', 'plaidAccountId', 'nickName', 'approvalMethod']) as CreateAchRelationshipRequest;
      dispatcher.cashiering.achRelationships.create.request(request);
    }, []);

  return [OAuthPlaidRedirectView, onSubmit, onValidate];
};

