import { getType } from 'typesafe-actions';
import { InitialState } from '../initialState';
import { RootAction, Actions, FeedActions } from '../actions';
import { getBalanceTotals } from '../utilities/balances';
import { OrderFeedActions } from '../actions/OrderFeedActions';
import { CashieringActions } from '../actions/CashieringActions';

export const balances = (state = InitialState.accountData.balances, action: RootAction) => {
  switch (action.type) {
    case getType(CashieringActions.createTransferBeneficiaryReceive):
    case getType(OrderFeedActions.receivedUpdate):
    case getType(FeedActions.feedErrored):
    case getType(FeedActions.feedClosed): {
      return {
        ...state,
        stale: true,
      };
    }
    case getType(Actions.fetchBalances): {
      //const isFetching = state.balances === undefined || refresh === false;
      return { ...state, isFetching: true };
    }
    case getType(Actions.receiveAccountBalances): {
      const balances = action.payload.data || undefined;
      if (!balances || !balances.Balances) {
        return { ...state, isFetching: false, stale: false, balances };
      }
      const totals = getBalanceTotals(balances);
      return { ...state, isFetching: false, stale: false, balances, totals };
    }
    case getType(Actions.fetchBalancesForWithdrawal): {
      return { ...state, isFetching: true };
    }
    case getType(Actions.receiveBalancesForWithdrawal): {
      const balancesForWithdrawal = action.payload.data || undefined;
      return { ...state, isFetching: false, stale: false, balancesForWithdrawal };
    }
    case getType(Actions.fetchPendingTransfers): {
      return { ...state, isFetching: true };
    }
    case getType(Actions.receivePendingTransfers): {
      const pendingTransfers = action.payload.data || undefined;
      return { ...state, isFetching: false, stale: false, pendingTransfers };
    }

    default:
      return state;
  }
};
