import { OutputSelector, createSelector } from 'reselect';
import _ from 'lodash';
import {
  PositionInfo,
  PersistableOrderState,
  Legs as OrderLegsType,
  OrderLegDTO,
  TradingStrategyOrCustom,
  TradingStrategy,
  Order,
  IBlockState,
  GroupOrderLeg,
  OrderValidationError,
} from '@tradingblock/types';
import { OrderBlockSettings, SaveOptions } from '@tradingblock/types';
import { getStrategy } from '@tradingblock/api';
import { useBlockData, useBlockId } from '../../../components/blocks/BlockState';
import { WithTables } from '../../../components/blocks/state/blockTable/BlockTableState';

export enum OrderStrength {
  Low,
  Medium,
  High,
  VeryHigh,
}

export interface OrderQuote {
  BidPrice: number;
  AskPrice: number;
  LastTradePrice: number;
  Symbol: string;
  Multiplier: number;
}

export interface OrderState extends WithTables<PersistableOrderState> {
  preview: {
    enabled: boolean;
  };
  validStrategies: TradingStrategyOrCustom[];
  matchingStrategy?: TradingStrategyOrCustom[];
  isFetching?: boolean;
  isInitializing?: boolean;
  price?: number;
  stopPrice?: number;
  recalculatePrice: boolean;
  legError?: string;
  autoFocus?: boolean;
  positions: PositionInfo;
  orderId?: number;
  quotes: Record<string, OrderQuote>;
  placeAs?: 'Unsolicited' | 'Solicited' | 'Discretionary' | undefined;
  executeAs?: 'Agent' | 'Principal' | 'Undefined';
  commission?: 'Online' | 'Assisted' | 'Override' | 'Undefined';
  commissionOverrideCode?: number;
  bypassMargin?: boolean;
  validation: {
    legIds?: string[];
    isValidating: boolean;
    valid?: boolean;
    match?: TradingStrategy;
    message?: string;
    field?: keyof Order | keyof OrderLegDTO;
    errors: OrderValidationError[];
  };
}
export type OrderBlockState = IBlockState<OrderState, OrderBlockSettings>;

type OrderStateQuantitySymbolStrategy = Pick<OrderState, 'symbol' | 'strategy' | 'quantity' | 'action' | 'orderType'>;
export type PartialOrderStateFromGroup = OrderStateQuantitySymbolStrategy & {
  legs: OrderLegsType | { [legId: string]: GroupOrderLeg };
  settings: OrderBlockSettings;
};

// export function useLegState(id: string) {
//   const state = useContext(OrderStateContext);
//   return getLegById(state, id);
// }
type Selector<T> = OutputSelector<OrderState, T, (res: OrderState) => T>;
type Transform<T> = (res: OrderState) => T;
type SelectorWithBlockId<T> = OutputSelector<OrderState, T, (res: OrderState, blockId: string) => T>;
type TransformWithBlockId<T> = (res: OrderState, blockId: string) => T;
export function useOrderData<T>(selector: Selector<T> | Transform<T>, defaultVal?: T) {
  const data = useBlockData<OrderState>();
  const res = selector(data);
  return defaultVal && _.isNil(res) ? defaultVal : res;
}
export function useOrderBlockData<T>(selector: SelectorWithBlockId<T> | TransformWithBlockId<T>, defaultVal?: T) {
  const blockId = useBlockId();
  const data = useBlockData<OrderState>();
  const res = selector(data, blockId);
  return defaultVal && _.isNil(res) ? defaultVal : res;
}

export function useOrderDataSelector<T>(selector: (res: OrderState) => T, defaultVal?: T) {
  const data = useBlockData<OrderState>();
  const select = createSelector(
    (s: OrderState) => s,
    selector
  );
  const res = select(data);
  return defaultVal && _.isNil(res) ? defaultVal : res;
}

export type setLegField<K extends keyof Partial<OrderLegDTO>> = (
  val: Pick<Partial<OrderLegDTO>, K>,
  options?: SaveOptions | undefined
) => void;
export type useLegStateResponse<K extends keyof Partial<OrderLegDTO>> = [Pick<Partial<OrderLegDTO>, K>, setLegField<K>];

export function useOrderStrategy() {
  const strategyName = useOrderData(s => s.strategy);
  return strategyName ? getStrategy(strategyName) : undefined;
}
