import React, { useState, ChangeEvent, useEffect, useRef, useMemo, useCallback } from 'react';
import { BasicSelect, Loading } from '@tradingblock/components';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { useBlockId } from '../../components/blocks/BlockState';
import { InfoDataActions } from '../../data/global/actions/InfoActions';
import { useStateSelector, getFavorites, getFavoriteSymbols } from '../../data/global/dataSelectors';
import { getAllPositionInfo } from '../../data/global/selectors/positionSelector';
import { MarketNews } from '@tradingblock/types';
import { useInfoSettings } from './hooks/useInfoSettings';
import { useGroupAssetSymbol } from '../useGroupState';
import { DJISymbols, NASDAQSymbols, SP500Symbols } from './utils/MarketSymbols';

export const MarketNewsTab: React.FunctionComponent<{}> = ({ }) => {
  // State Selectors
  const { favorites, symbols } = useStateSelector(s => ({
    favorites: getFavorites(s),
    symbols: getFavoriteSymbols(s),
  }));
  const positions = useStateSelector(getAllPositionInfo);
  const isPollingMarketNews = useStateSelector(s => s.information.isPollingForMarketNews);
  const positionsLoaded = useStateSelector(s => s.positions.loaded);
  const isFetchingFavorites = useStateSelector(s => s.favorites.isFetching);

  // Array Handling
  const positionSymbols = positions.map(position => position.underlyingSymbol);
  const favoriteSymbols = favorites.map(favorite => favorite.symbol);
  if (symbols.length === 0 && positions.length === 0) {
    symbols[0] = 'FB';
    symbols[1] = 'AAPL';
    symbols[2] = 'AMZN';
    symbols[3] = 'NFLX';
    symbols[4] = 'GOOG';
    symbols[5] = 'MSFT';
  }
  const allSymbols = symbols
  .concat(positionSymbols)
  .concat(favoriteSymbols)
  .sort();
  const uniqueAllSymbols = [...new Set(allSymbols)];
  const symbolArr = uniqueAllSymbols.join();

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);

  const channelArray: string[] = [];
  
  const settings = useInfoSettings();

  const channelMappings = [
    { active: settings.dailyNewsfeed, name: 'DailyNewsfeed' },
    { active: settings.corporateActionsDividends, name: 'CorporateActionsDividends' },
    { active: settings.earningsReports, name: 'EarningsReports' },
    { active: settings.rumorsAndIdeas, name: 'RumorsAndIdeas' },
    { active: settings.companyNews, name: 'CompanyNews' },
    { active: settings.sectorNews, name: 'SectorNews' },
    { active: settings.ipos, name: 'Ipos' },
    { active: settings.economicsAndGovernment, name: 'EconomicsAndGovernment' },
    { active: settings.theMarkets, name: 'TheMarkets' },
    { active: settings.etfCoverage, name: 'EtfCoverage' },
    { active: settings.options, name: 'Options' },
  ];

  const blockId = useBlockId();

  const groupAsset = useGroupAssetSymbol();

  // Populate channelArray for MarketNews polling request based on useInfoSettings()
  if (_.isEmpty(settings)) {
    channelArray.push('DailyNewsfeed');
  } else {
    channelMappings.forEach((channelObj) => {
      if (channelObj.active)
        channelArray.push(channelObj.name)
    })
  }

  // Select MarketNews
  const { isFetching, data = [] } = useStateSelector(
    s => s.information.marketNews[blockId] || { isFetching: true, data: [] }
  );

  const [allItems, setAllItems] = useState<MarketNews[]>([]);
  const total = useMemo(() => {
    return allItems.length;
  }, [allItems]);

  const [socketResult, setSocketResult] = useState<MarketNews[]>([]);

  /** Date stuff **/
  var today = new Date();

  // Dynamic delay
  const [delay, setDelay] = useState<number>(300000);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDelay(Number(event.target.value));
  };

  // Refs for restoring article list scroll position and scrolling to top of article element when article is being displayed
  const articleRefs: any = useRef([]);
  const scrollRef: any = useRef(null);

  // executeScroll
  // @DESC    based on index of clicked item, scroll to the ref w/ corresponding index
  // @PARAMS  index - Position of reference in articleRefs array, saved in localStorage on article click and retrieved where executeScroll is called
  const executeScroll = (index: number) => {
    articleRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  // executeTopScroll
  // @DESC    scroll to the scrollRef div, located at the top of the article element. called when article is set and displayed to bring user to top of text
  // @PARAMS  NONE
  const executeTopScroll = () => {
    scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  const [article, setArticle] = React.useState<undefined | MarketNews>();

  const isDJIA = (groupAsset && groupAsset.symbol === '$DJIA') || (groupAsset && groupAsset.symbol === '$DJI');
  const isSPX = groupAsset && groupAsset.symbol === '$SPX';
  const isNasdaq = groupAsset && groupAsset.symbol.includes('$NDX');
  const isVIX = groupAsset && groupAsset.symbol === '$VIX';

  // Get Market News
  const dispatch = useDispatch();
  useEffect(() => {
    // Set page to 1 for initial requests
    setPage(1);
    dispatch(InfoDataActions.stopPollingForMarketNews());

    /* 
      If groupAsset is DJIA, SPX, or Nasdaq, use the corresponding symbols
      Else, use the symbols entered or symbols from the user's favorites/positions 
    */
    if (groupAsset) {
      if (isDJIA) {
        dispatch(
          InfoDataActions.beginPollingForMarketNews({
            symbols: DJISymbols,
            updatedSince: dayjs.unix(0).toISOString(),
            channels: channelArray.toString(),
            timeoutMS: delay,
            blockId: blockId,
          })
        );
      }
      if (isSPX || isVIX) {
        dispatch(
          InfoDataActions.beginPollingForMarketNews({
            symbols: SP500Symbols,
            updatedSince: dayjs.unix(0).toISOString(),
            channels: channelArray.toString(),
            timeoutMS: delay,
            blockId: blockId,
          })
        );
      }
      if (isNasdaq) {
        dispatch(
          InfoDataActions.beginPollingForMarketNews({
            symbols: NASDAQSymbols,
            updatedSince: dayjs.unix(0).toISOString(),
            channels: channelArray.toString(),
            timeoutMS: delay,
            blockId: blockId,
          })
        );
      }
      dispatch(
        InfoDataActions.beginPollingForMarketNews({
          symbols: [groupAsset.symbol],
          updatedSince: dayjs.unix(0).toISOString(),
          channels: channelArray.toString(),
          timeoutMS: delay,
          blockId: blockId,
        })
      );
      if (article !== undefined) {
        setArticle(undefined);
      }
    } else {
      dispatch(
        InfoDataActions.beginPollingForMarketNews({
          symbols: [symbolArr],
          updatedSince: dayjs.unix(0).toISOString(),
          channels: channelArray.toString(),
          timeoutMS: delay,
          blockId: blockId,
        })
      );
      if (article !== undefined) {
        setArticle(undefined);
      }
    }

    articleRefs.current = articleRefs.current.slice(0, allItems.length);
  }, [
    symbolArr,
    settings.dailyNewsfeed,
    settings.corporateActionsDividends,
    settings.earningsReports,
    settings.rumorsAndIdeas,
    settings.companyNews,
    settings.sectorNews,
    settings.ipos,
    settings.economicsAndGovernment,
    settings.theMarkets,
    settings.etfCoverage,
    groupAsset,
    settings.options,
    positionsLoaded,
  ]);

  // handleListItemClick
  // @DESC    sets localStorage item 'articleElement' to the index of article within allItems array, updates article state with API data, and scrolls to top of article text
  // @PARAMS  article - individual article data from allItems.map, articleEl - index of the article within allItems array, which corresponds to the articleRef index of the article
  const handleListItemClick = (article: MarketNews, articleEl: number) => {
    localStorage.setItem('articleElement', JSON.stringify(articleEl));
    setArticle(article);
    executeTopScroll();
  };

  // handleBackClick
  /* @DESC    updates article state with undefined, rendering the article list, 
    retrieves localStorage item 'articleElement' containing the index of the article that was previously clicked, 
    scrolls the user back to that article within the article list  */
  // @PARAMS  NONE
  function handleBackClick() {
    setArticle(undefined);
    let index: any = localStorage.getItem('articleElement');
    setTimeout(function () {
      executeScroll(index);
    }, 50);
  }

  const paginate = (array: MarketNews[], pageSize: number, pageNumber: number) => {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  };

  const getPageCount = (array: MarketNews[], pageSize: number) => {
    return Math.ceil(array.length / pageSize);
  };

  const pageSizes = [5, 10, 15, 25];

  const pageCount = getPageCount(allItems, pageSize);

  const pageSizeOptions = useMemo(
    () => _.map(pageSizes, s => ({ value: s, label: s ? `${s} per page` : 'All pages' })),
    []
  );

  const pageSizeLabel = useMemo(() => {
    const label = _.find(pageSizeOptions, o => o.value === pageSize);
    return label ? label.label : '';
  }, [pageSize, pageSizeOptions]);

  const onPageSizeChanged = useCallback(
    (pageSizeOption: { value: number }) => {
      setPageSize(pageSizeOption.value);
      setPage(1);
    },
    [setPage, setPageSize]
  );

  useEffect(() => {
    setAllItems([...socketResult, ...data]);
  }, [isFetching]);

  if (allItems.length === 0 && !isFetching)
    return (
      <>
        <p className="mute">
          <i className="fal fa-search" /> No articles found... Please adjust search parameters.
        </p>
      </>
    );
  if (isFetching || isFetchingFavorites || (positions.length > 0 ? !positionsLoaded : false)) return <Loading />;
  return (
    <>
      {allItems && (
        <>
          <div className="scroll" ref={scrollRef} />
          {!article && (
            <>
              <ul className="news">
                {paginate(allItems, pageSize, page).map((item, index) => (
                  <li
                    className={`news-item marketnews-${item.id}`}
                    key={`marketnews-${index}`}
                    id={item.id.toString()}
                    ref={el => (articleRefs.current[index] = el)}
                  >
                    <div className="news-title">
                      <div className="news-title-icon">
                        <i className="fal fa-newspaper"></i>
                      </div>
                      <div className="news-title-summary">
                        <p>
                          <strong>
                            <a
                              className="news-title-link"
                              onClick={() => {
                                handleListItemClick(
                                  {
                                    author: item.author,
                                    id: item.id,
                                    title: item.title,
                                    body: item.body,
                                    updated: item.updated,
                                    stocks: item.stocks,
                                    channels: item.channels,
                                  },
                                  index
                                );
                              }}
                              href="#"
                            >
                              {item.title}
                            </a>
                          </strong>
                        </p>
                        <p className="news-title-details">
                          <span className="time-stamp" key={`timestamp-${index}`}>
                            {dayjs(item.updated).format('ddd, DD MMM YYYY hh:mm:ssa ZZ UTC')}
                          </span>
                          {item.stocks && (
                            <>
                              {item.stocks.map((item, index) => (
                                <span className="stocks" key={`stocks-${index}`}>
                                  {item}
                                </span>
                              ))}
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="table-toolbar table-toolbar-bottom">
                <div className="table-toolbar-primary">
                  {pageSize !== 0 && (
                    <span className="paging">
                      <button
                        className="btn btn-blend"
                        onClick={() => {
                          setPage(1);
                          executeTopScroll();
                        }}
                        title="First Page"
                      >
                        <i className="fal fa-angle-double-left" />
                      </button>
                      <button
                        className="btn btn-blend"
                        onClick={() => {
                          if (page - 1 >= 1) {
                            setPage(page - 1);
                            executeTopScroll();
                          }
                        }}
                        title="Previous page"
                      >
                        <i className="far fa-angle-left" />
                      </button>
                      <span className="paging-current">
                        {`${page} of ${pageCount} page`}
                        {pageCount !== 1 ? 's' : ''}
                      </span>
                      <button
                        className="btn btn-blend"
                        onClick={() => {
                          if (page + 1 <= pageCount) {
                            setPage(page + 1);
                            executeTopScroll();
                          }
                        }}
                        title="Next page"
                      >
                        <i className="far fa-angle-right" />
                      </button>
                      <button
                        className="btn btn-blend"
                        onClick={() => {
                          setPage(pageCount);
                          executeTopScroll();
                        }}
                        title="Last page"
                      >
                        <i className="far fa-angle-double-right" />
                      </button>
                    </span>
                  )}
                </div>
                <div className="table-toolbar-secondary">
                  <BasicSelect
                    className="btn btn-dark btn-tall dropdown-toggle text-left"
                    options={pageSizeOptions}
                    placeholder={pageSizeLabel}
                    onChange={onPageSizeChanged}
                    isClearable={false}
                    isSearchable={false}
                  />
                </div>
              </div>
            </>
          )}

          {!!article && (
            <>
              <div className="article_pane">
                <p>
                  <button className="btn btn-outline-light" onClick={handleBackClick}>
                    <i className="fal fa-angle-left"></i> Back to all news
                  </button>
                </p>
                <div className="news-title">
                  <div className="news-title-icon">
                    <i className="fal fa-newspaper"></i>
                  </div>
                  <div className="news-title-summary">
                    <p>
                      <strong>
                        {article.title} -- {article.author}
                      </strong>
                    </p>
                    <p className="news-title-details">
                      <span className="time-stamp">
                        {' '}
                        {dayjs(article.updated).format('ddd, DD MMM YYYY hh:mm:ssa ZZ UTC')}
                      </span>
                      {article.stocks && (
                        <>
                          {article.stocks.map((item, index) => (
                            <span className="stocks" key={`stocks-${index}`}>
                              {item.name}
                            </span>
                          ))}
                        </>
                      )}
                    </p>
                  </div>
                </div>

                <div
                  className="news-title-body"
                  dangerouslySetInnerHTML={{
                    __html: article.body,
                  }}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
