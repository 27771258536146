import { useEffect, useCallback } from 'react';
import { AssetSymbol } from '@tradingblock/types';
import { useBlockData, useBlockActions, useBlockActionDispatch } from '../../../components/blocks/BlockState';
import { GenericBlockActionTypes } from '../../../components/blocks/state/BlockState';
import { GroupManager } from '../../../context/GroupManager';
import { useBlockGroupField, useBlockGroupInfo, useGroupStateHandler } from '../../useGroupState';
import { PriceChartState } from '../state/PriceChartState';
import { PriceChartActions } from '../state/PriceChartActions';

export const usePriceChartGroupHandler = () => {
  const { blockId, groupId } = useBlockGroupInfo();
  const dispatch = useBlockActionDispatch<GenericBlockActionTypes<PriceChartActions>>();
  const { symbol } = useBlockData<PriceChartState>();
  const { setField: set } = useBlockActions<PriceChartState>();

  const setSymbol = useCallback((sym: AssetSymbol) => set('symbol', sym, { persist: true }), [set]);

  useEffect(() => {
    if (groupId) {
      GroupManager.attach({ blockId, groupId, dispatch }, true);
      if (symbol) {
        setSymbol(symbol);
      }
    }

    return () => {
      GroupManager.detach(blockId);
    };
  }, [groupId]);

  const onSymbolChange = useCallback(
    (incomingSymbol: AssetSymbol | undefined) => {
      if (incomingSymbol && (!symbol || symbol.symbol !== incomingSymbol.symbol)) {
        setSymbol(incomingSymbol);
      }
    },
    [symbol, setSymbol]
  );

  useGroupStateHandler('symbol', onSymbolChange, { fireOnGroupChange: true });
};
