import qs from 'qs';
import _ from 'lodash';
import { alphabeticalSort } from '../../../utilities/data';
import { ApplicationModel, ApplicationsSearchRequest, DefaultApplicationModel } from '@tradingblock/types';

export const applicationRequestToQueryString = (request: ApplicationsSearchRequest) =>
  qs.stringify(request, { indices: false, sort: alphabeticalSort });

export const applicationRequestKey = (request: ApplicationsSearchRequest) =>
  applicationRequestToQueryString({ ...request });

export const applicationUpdateKey = (type: string, id: number | string) => `${type}-${id}`;

export const getEntityKey = (re: ApplicationModel) => {
  const id = re.ownerUsername || '';
  const type = re.type || '';
  return applicationUpdateKey(type, id);
};
