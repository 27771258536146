import React from 'react';
import { Field } from 'formik';
import { FormGroup, TextboxField } from '@tradingblock/components';
import { CashieringViewProps, CashieringViewFormValues } from '../data/useCashieringView';

interface AchRelationshipVerifyFormProps extends CashieringViewProps<CashieringViewFormValues> {}

export const AchRelationshipVerifyForm: React.FunctionComponent<AchRelationshipVerifyFormProps> = ({ values }) => {
  return (
    <>
      <p className="fields-title">Verify bank account&mdash;{values && values.account && values.account.nickName}</p>
      <p className="mute">
        Look for two deposits under 0.50. Enter them below to verify the account.
        <br />
        You have 10 days to verify your account and you are limited to three attempts.
      </p>

      <FormGroup>
        <div className="row">
          <div className="col">
            <Field component={TextboxField} id="account.amount1" label="Enter Deposit 1 < 0.50" />
          </div>
          <div className="col">
            <Field component={TextboxField} id="account.amount2" label="Enter Deposit 2 < 0.50" />
          </div>
        </div>
      </FormGroup>
    </>
  );
};
