import React, { useMemo } from 'react';
import { useWhyDidYouUpdate } from 'use-hooks';
import { Block, BlockType } from '@tradingblock/types';

import { PriceChartBlock } from '../../blocks/PriceChart/PriceChartBlock';
import { PositionBlock } from '../../blocks/Positions/PositionBlock';
import { AccountBlock } from '../../blocks/Account/AccountBlock';
import { AccountManagementBlock } from '../../blocks/AccountManagement/AccountManagementBlock';
import { FavoritesBlock } from '../../blocks/Favorites/FavoritesBlock';
import { useAuthenticationSelector, useStateSelector } from '../../data/global/dataSelectors';
import { OrderBlock } from '../../blocks/Order/OrderBlock';
import { BlockStateProvider, GenericBlockStateProvider } from './BlockState';
import { ActivityBlock } from '../../blocks/Activity/ActivityBlock';
import { AnalyticsBlock } from '../../blocks/Analytics/AnalyticsBlock';
import { AccountTransferBlock } from '../../blocks/AccountTransfer/AccountTransferBlock';
import { OptionChainBlock } from '../../blocks/OptionChain/OptionChainBlock';
import { OrderStateFactory, blockStateFactory } from '../../blocks/BlockStateFactory';
import { OrderStateReducer } from '../../blocks/Order/state/OrderReducer';
import { ActivityStateReducer } from '../../blocks/Activity/state/ActivityStateReducer';
import { OptionChainBlockReducer } from '../../blocks/OptionChain/state/OptionChainReducer';

import { BlockReducerWithTables } from './BlockReducer';
import _ from 'lodash';
import { DefaultOrderBlockSettings, defaultOrderBlockSettingsSelector } from '../../blocks/Order/state/OrderSettings';
import { QuoteExtendedBlock } from '../../blocks/QuoteExtended/QuoteExtendedBlock';
import { QuoteBlockReducer } from '../../blocks/QuoteExtended/state/QuoteBlockReducer';

import { InfoBlockReducer } from '../../blocks/Info/state/InfoBlockReducer';
import { InfoBlock } from '../../blocks/Info/InfoBlock';
import { defaultInfoBlockSettingsSelector } from '../../blocks/Info/state/InfoSettings';
import { AccountManagementReducer } from '../../blocks/AccountManagement/state/AccountManagementReducer';
import { AdminToolsBlock } from '../../blocks/admin/AdminToolsBlock';
import { AdminToolsReducer } from '../../blocks/admin/state/AdminToolsReducer';
import AccountStatements from '../../blocks/AccountStatements/AccountStatements';
import { SubAccountManagementBlock } from '../../blocks/SubAccountManagement/SubAccountManagementBlock';

interface BlockFactoryProps extends Pick<Block, 'blockId' | 'type' | 'groupId'> {
  data: any | undefined;
  wrapperId: string;
}

export const BlockFactory: React.FunctionComponent<BlockFactoryProps> = ({
  blockId,
  wrapperId,
  type,
  data,
  groupId,
}) => {
  const { isAuthenticated } = useAuthenticationSelector();
  useWhyDidYouUpdate('BlockFactory', { isAuthenticated, type, wrapperId, blockId });
  const isOrderBlock = useMemo(() => type === BlockType.Order, [type]);
  const isActivityBlock = useMemo(() => type === BlockType.Activity, [type]);
  const isOptionChainBlock = useMemo(() => type === BlockType.OptionChain, [type]);
  const isInfoBlock = useMemo(() => type === BlockType.Info, [type]);
  const isAccountManagementBlock = useMemo(() => type === BlockType.AccountManagement, [type]);
  const isAdminToolsBlock = useMemo(() => type === BlockType.AdminTools, [type]);
  const initialBlockData = useMemo(() => {
    const initData = isOrderBlock ? { ...OrderStateFactory.Initial, ...(data || {}) } : data;
    const tablesValue = _.get(initData, 'tables', undefined);
    if (_.isNil(tablesValue)) {
      return {
        ...initData,
        tables: {},
      };
    }
    return initData;
  }, [data, isOrderBlock]);
  // const initialData = isOrderBlock ? _.merge(OrderStateFactory.Initial, data || {}) : data || {};
  const typeFactory = blockStateFactory(type);
  const defaultOrderBlockSettings = useStateSelector(defaultOrderBlockSettingsSelector);
  const defaultInfoBlockSettings = useStateSelector(defaultInfoBlockSettingsSelector);

  return (
    <>
      {!isAuthenticated && <p>Loading...</p>}
      {isAuthenticated &&
        type &&
        (isOrderBlock ? (
          <GenericBlockStateProvider
            initial={{
              groupId,
              blockId,
              type,
              title: type,
              data: initialBlockData,
              settings: defaultOrderBlockSettings,
            }}
            reducer={OrderStateReducer}
          >
            <OrderBlock />
          </GenericBlockStateProvider>
        ) : isActivityBlock ? (
          <GenericBlockStateProvider
            initial={{
              groupId,
              blockId,
              type,
              title: type,
              data: initialBlockData,
              settings: defaultOrderBlockSettings,
            }}
            reducer={OrderStateReducer}
          >
            <ActivityBlock />
          </GenericBlockStateProvider>
        ) : isOptionChainBlock ? (
          <GenericBlockStateProvider
            initial={{ title: type, type, blockId, groupId, data: initialBlockData, settings: {} }}
            reducer={OptionChainBlockReducer}
          >
            <OptionChainBlock />
          </GenericBlockStateProvider>
        ) : type === BlockType.Quote ? (
          <GenericBlockStateProvider
            initial={{ title: type, type, blockId, groupId, data: initialBlockData, settings: {} }}
            reducer={QuoteBlockReducer}
          >
            <QuoteExtendedBlock />
          </GenericBlockStateProvider>
        ) : isInfoBlock ? (
          <GenericBlockStateProvider
            initial={{
              title: type,
              type,
              blockId,
              groupId,
              data: initialBlockData,
              settings: defaultInfoBlockSettings,
            }}
            reducer={InfoBlockReducer}
          >
            <InfoBlock />
          </GenericBlockStateProvider>
        ) : isAccountManagementBlock ? (
          <GenericBlockStateProvider
            initial={{
              title: type,
              type,
              blockId,
              groupId,
              data: initialBlockData,
              settings: typeFactory.Settings,
            }}
            reducer={AccountManagementReducer}
          >
            <AccountManagementBlock />
          </GenericBlockStateProvider>
        ) : isAdminToolsBlock ? (
          <GenericBlockStateProvider
            initial={{
              title: type,
              type,
              blockId,
              groupId,
              data: initialBlockData,
              settings: typeFactory.Settings,
            }}
            reducer={AdminToolsReducer}
          >
            <AdminToolsBlock />
          </GenericBlockStateProvider>
        ) : (
          <BlockStateProvider
            groupId={groupId}
            blockId={blockId}
            type={type}
            title={type}
            data={initialBlockData}
            settings={typeFactory.Settings}
          >
            {type === BlockType.AccountStatements && <AccountStatements />}
            {type === BlockType.Account && <AccountBlock />}
            {type === BlockType.AccountTransfer && <AccountTransferBlock />}
            {type === BlockType.Favorites && <FavoritesBlock />}
            {type === BlockType.Positions && <PositionBlock />}
            {type === BlockType.PriceChart && <PriceChartBlock />}
            {type === BlockType.AccountManagement && <AccountManagementBlock />}
            {type === BlockType.AdminTools && <AdminToolsBlock />}
            {type === BlockType.Analytics && <AnalyticsBlock />}
            {type === BlockType.SubAccountManagement && <SubAccountManagementBlock />}

          </BlockStateProvider>
        ))}
    </>
  );
};
