import { GenericErrorResponseCode } from '@tradingblock/types';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Cell } from 'react-table';
import { Timeframe } from '../../../components/basic/Timeframe';
import { useApi } from '../../../context/Api';
import { SessionActions } from '../../../data/global/actions';
import { AccountStatementsActions } from '../../../data/global/actions/AccountStatementsActions';
import { apiToken, useStateSelector } from '../../../data/global/dataSelectors';
import { accountStatementsSelectors } from '../../../data/global/selectors/accountStatementsSelectors';
import { BlockTable } from '../../shared/BlockTable';
import { DownloadComponent } from '../components/DownloadComponent';

export interface AccountStatementsDailyConfirmsProps {}

export default function AccountStatementsDailyConfirms() {
  const dispatch = useDispatch();
  const api = useApi();
  const accountId = useStateSelector(s => s.account.accountId);
  const selectedAccount = useStateSelector(s => s.account.selectedLinkedAccount);
  const [timeframe, setTimeframe] = useState<{
    startDate: Date;
    endDate: Date;
  }>({
    startDate: new Date(),
    endDate: new Date(),
  });
  const dailyStatements = useStateSelector(state => accountStatementsSelectors.dailyStatements(state, timeframe));
  const formattedDailyStatements = useMemo(() => {
    if (dailyStatements) {
      return dailyStatements
        .sort((a, b) => (new Date(a.date) < new Date(b.date) ? 1 : -1))
        .map(document => ({
          received_on: new Date(document.date),
          message: `Daily Statement for ${dayjs(document.date).format('MM/DD/YY')}`,
        }));
    } else {
      return [];
    }
  }, [dailyStatements]);

  useEffect(() => {
    dispatch(
      AccountStatementsActions.requestDailyAccountStatements({
        accountId: selectedAccount ? selectedAccount.accountId : accountId,
        requestedStartDate: timeframe.startDate,
        requestedEndDate: timeframe.endDate > new Date() ? new Date() : timeframe.endDate,
      })
    );
  }, [timeframe, selectedAccount]);

  const columns = useMemo(
    () => [
      {
        Header: 'Statement',
        canSort: true,
        accessor: (a: { received_on: Date; message: string }) => a.message,
        id: 'message',
        Cell: ({
          row,
        }: Cell<{
          received_on: Date;
          message: string;
        }>) => row.original.message,
      },
      {
        Header: 'View',
        accessor: (a: { received_on: Date; message: string }) => dayjs(a.received_on).format('MM/DD/YY h:mm A'),
        id: 'received_on',
        canSort: false,
        sortType: 'basic',
        Cell: ({
          row,
        }: Cell<{
          received_on: Date;
          message: string;
        }>) => (
          <i
            style={{
              cursor: 'pointer',
            }}
            className="fal fa-lg fa-search dashboard-icon"
            onClick={() => {
              api.downloads
                .getDailyConfirm(accountId, row.original.received_on)
                .then((result: any) => {
                  let file = new Blob([result.data], { type: 'application/pdf' });
                  let fileURL = URL.createObjectURL(file);
                  window.open(fileURL);
                })
                .catch(err => {
                  dispatch(
                    SessionActions.error({
                      code: GenericErrorResponseCode,
                      url: err.config.url,
                      message: 'Failed to download daily confirm. Please try again.',
                      reason: 'Download Failed',
                    })
                  );
                });
            }}
          />
        ),
      },
      {
        Header: 'Download',
        accessor: (a: { received_on: Date; message: string }) => dayjs(a.received_on).format('MM/DD/YY h:mm A'),
        id: 'download',
        canSort: false,
        sortType: 'basic',
        Cell: ({
          row,
        }: Cell<{
          received_on: Date;
          message: string;
        }>) => (
          <>
            <DownloadComponent
              row={row}
              accountId={accountId}
              api={api}
              dispatch={dispatch}
              SessionActions={SessionActions}
              GenericErrorResponseCode={GenericErrorResponseCode}
              type="confirm"
            />
          </>
        ),
      },
    ],
    [accountId]
  );

  return (
    <>
      <div className="block-section block-section-fixed">
        <div className="row justify-content-between">
          <div className="col-auto">
            <Timeframe
              initialStartDate={timeframe.startDate}
              initialEndDate={timeframe.endDate}
              onSave={(startDate, endDate) => {
                setTimeframe({
                  startDate: startDate ? startDate : new Date(),
                  endDate: endDate ? endDate : new Date(),
                });
              }}
              caret={<i className="fas fa-caret-down" />}
            />
          </div>
        </div>
      </div>
      <BlockTable
        tableKey="orders"
        columnWidths={['80%', '10%', '10%']}
        columns={columns}
        data={formattedDailyStatements}
        sortable
        frozenHeaders
      />
    </>
  );
}
