import React, { useCallback, useMemo } from 'react';
import { Field } from 'formik';
import _ from 'lodash';
import {
  CashieringRoute,
  CashieringManageAction,
  AchRelationship,
  ApprovalMethod,
  ClearerType,
} from '@tradingblock/types';
import { CheckboxField, FormGroup } from '@tradingblock/components';
import { AccountsListProps } from './AccountsList';
import { AchRelationshipList } from './AchRelationshipList';
import { useCashieringDataContext } from '../data/useCashieringData';
import { getApprovalMethodDescription, getApprovalMethodLabel } from '../../../../utilities/cashiering';
import { useClearer } from '../../../../hooks/useClearer';

interface SelectAchRelationshipTransferProps extends AccountsListProps {
  setManageAction: (action: CashieringManageAction | undefined, data?: AchRelationship) => void;
}

export const SelectAchRelationshipTransfer: React.FunctionComponent<SelectAchRelationshipTransferProps> = ({
  route,
  values,
  setValues,
  setManageAction,
}) => {
  const { action } = useCashieringDataContext();
  const isRQDAccount = useClearer() === ClearerType.RQD;

  const selectable = route !== CashieringRoute.Accounts;
  const approvalMethods: ApprovalMethod[] = isRQDAccount ? ['Plaid'] : ['Plaid', 'MicroDeposit', 'PDF'];

  const showAddOptions = useMemo(() => action === CashieringManageAction.AddOptions, [action]);

  const onAddClick = useCallback(() => {
    const nextAction = !showAddOptions ? CashieringManageAction.AddOptions : undefined;
    setManageAction(nextAction);
  }, [showAddOptions]);

  const onSelectApprovalMethod = useCallback((approvalMethod: ApprovalMethod) => {
    const bankAccountType = approvalMethod === 'MicroDeposit' ? 'Checking' : undefined;
    setManageAction(CashieringManageAction.AddOptions, { bankAccountType, approvalMethod } as AchRelationship);
  }, []);

  const onVerifyAchRelationship = (data: AchRelationship) => {
    setManageAction(CashieringManageAction.Verify, data);
  };

  const onRenameAchRelationship = (data: AchRelationship) => {
    setManageAction(CashieringManageAction.Rename, data);
  };

  const onDeleteAchRelationship = (data: AchRelationship) => {
    setManageAction(CashieringManageAction.Delete, data);
  };

  return (
    <>
      {selectable && (
        <p>
          <span className="mute">Select account below. Free transfers with verified accounts. </span>
          {route === CashieringRoute.Deposit && (
            <span className="mute">Deposits may take 1-2 business days to process.</span>
          )}
          {route === CashieringRoute.Withdraw && (
            <span className="mute">Withdrawals submitted before 1:30pm CT are processed same day.</span>
          )}
        </p>
      )}
      <FormGroup>
        <AchRelationshipList
          field="transfer.achRelationshipId"
          values={values}
          setValues={setValues}
          selectable={selectable}
          addBtn={{ text: 'Link new bank account', active: showAddOptions }}
          onAdd={onAddClick}
          onVerify={onVerifyAchRelationship}
          onRename={selectable ? undefined : onRenameAchRelationship}
          onDelete={selectable ? undefined : onDeleteAchRelationship}
        />

        {showAddOptions && (
          <ul className="checklist">
            {_.map(approvalMethods, approvalMethod => (
              <li key={`approvalMethod-${approvalMethod}`}>
                <Field
                  component={CheckboxField}
                  id="account.approvalMethod"
                  type="radio"
                  value={approvalMethod}
                  label={
                    <label htmlFor={`account.approvalMethod.${approvalMethod}`}>
                      {getApprovalMethodLabel(approvalMethod)}
                    </label>
                  }
                  onChanged={() => onSelectApprovalMethod(approvalMethod)}
                />
                {getApprovalMethodDescription(approvalMethod, route)}
              </li>
            ))}
          </ul>
        )}
      </FormGroup>
    </>
  );
};
