import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { isOCCSymbol, OCCSymbolToOptionData, deriveOptionExpirationType } from '@tradingblock/api';
import { OptionType, OptionExpirationType } from '@tradingblock/types';
import _ from 'lodash';

const OptionSymbolName: React.FC<{ symbol: string; underlyingSymbol: string }> = ({ symbol, underlyingSymbol }) => {
  const { expiration, strike, option } = OCCSymbolToOptionData(symbol);
  const optionExpirationType = deriveOptionExpirationType(symbol, underlyingSymbol || '');
  const symbolDate = useMemo(() => _([dayjs(expiration).format('MMM D'), optionExpirationType === OptionExpirationType.AM ? ' AM' : '']).join(''), [expiration, optionExpirationType]);
  return (
    <>
      <strong className="txt-rg">{underlyingSymbol}</strong>
      <span className="btn-subtitle">{symbolDate}</span>
      <span className="btn-subtitle">
        {strike} {option === OptionType.Call ? 'Call' : 'Put'}
      </span>
    </>
  );
};

export const SymbolName: React.FC<{ symbol: string; underlyingSymbol: string }> = ({ symbol, underlyingSymbol }) => {
  return (
    <>
      {isOCCSymbol(symbol) && <OptionSymbolName symbol={symbol} underlyingSymbol={underlyingSymbol} />}
      {!isOCCSymbol(symbol) && <strong className="txt-rg">{symbol}</strong>}
    </>
  );
};
