import { getType } from 'typesafe-actions';
import { InitialState } from '../initialState';
import { RootAction } from '../actions';
import { LinkedAccountsActions } from '../actions/LinkedAccountsActions';
import { LinkedAccountsState } from '../state';
import { Fetchable } from '@tradingblock/types';

export const linkedAccounts = (
  state = InitialState.linkedAccounts,
  action: RootAction
): Fetchable<LinkedAccountsState> => {
  switch (action.type) {
    case getType(LinkedAccountsActions.requestLinkedAccounts):
      return { ...state, isFetching: true };
    case getType(LinkedAccountsActions.receiveLinkedAccounts):
      const payload = action.payload.payload;
      return {
        ...state,
        accounts: payload.data,
        pageSize: payload.pageSize,
        page: payload.page,
        total: payload.total,
        isFetching: false,
      };
    default:
      return state;
  }
};
