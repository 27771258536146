import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { StateSync } from '@tradingblock/types';
import { useLocalStorage, SyncTabStorageKey, ExpireTokenInStorage } from '@tradingblock/storage';
import { GetUrlParams } from '@tradingblock/api';
import { useDispatcher } from '../data/global/hooks';
import { useStateSelector } from '../data/global/dataSelectors';
import { usePageSettings } from '../framework/WrappedRoute';

export const LogoutPage: React.FunctionComponent = () => {
  usePageSettings({ name: 'Logout', access: 'public' });
  const { dispatcher } = useDispatcher();
  const storage = useLocalStorage();
  const tokenFromState = useStateSelector(s => s.auth.uiApiToken);

  const redirectUrl = `/`;

  useEffect(() => {
    localStorage.setItem(SyncTabStorageKey, JSON.stringify({ sync: StateSync.SIGNOUT, createDate: Date.now() }));
    ExpireTokenInStorage(storage);
    dispatcher.settings.signout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatcher.settings]);

  if (!tokenFromState) {
    return <Redirect to={redirectUrl} />;
  }
  return null;
};
