import qs from 'qs';
import dayjs from 'dayjs';
import _ from 'lodash';
import { alphabeticalSort } from '../../../utilities/data';
import { AccountManagementBaseUpdate, AccountUpdateSearchRequest } from '@tradingblock/types';

export const accountManagementRequestToQueryString = (request: AccountUpdateSearchRequest) =>
  qs.stringify(request, { indices: false, sort: alphabeticalSort });

export const accountManagementRequestKey = (request: AccountUpdateSearchRequest) =>
  accountManagementRequestToQueryString({ ...request });

const objectTypes: {
  [key: string]: any;
} = {
  Email: 'Email',
  PhoneNumber: 'PhoneNumber',
  Address: 'Address',
  MailingAddress: 'MailingAddress',
  Financial: 'Financial',
  TradeGoal: 'TradeGoal',
  Employment: 'Employment',
  TrustedContact: 'TrustedContact',
  Disclosures: 'Disclosures',
  AccountDisclosures: 'AccountDisclosures',
  Experience: 'Experience',
  IndustryEmployed: 'IndustryEmployed',
  Suitability: 'Suitability',
  Flexability: 'Flexibility',
};

export const accountManagementUpdateKey = (type: string, id: number | string) => `${type}-${id}`;

export const getEntityKey = (re: AccountManagementBaseUpdate) => {
  const updateId = re.id;

  return accountManagementUpdateKey(objectTypes[re.item], updateId);
};
