/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect } from 'react';
import createCachedSelector from 're-reselect';
import _ from 'lodash';
import { useWhyDidYouUpdate, BoldNumber, ContactPhone } from '@tradingblock/components';
import { DebitOrCredit, OrderType, TradingblockOrder } from '@tradingblock/types';
import { useOrderData, OrderState, useOrderBlockData } from './state/OrderState';
import { OrderSelectors } from './state/OrderSelector';
import { useOrderTotal } from './hooks/useOrderTotal';
import { OrderPreviewPopup } from './components/Preview/PreviewPopup';
import { ProfitLoss } from './components/ProfitLoss';
import { useOrderPreview } from './components/Preview/useOrderPreview';
import { useQuote } from '../../hooks/useFeedQuote';
import Config from '../../config';
import { useDashboardSettings } from '../../hooks/useDashboardSettings';

const OrderFooterPrice = () => {
  const { total, debitCredit, marginCost } = useOrderTotal();
  const totalInfo = useMemo(() => {
    const isDebit = debitCredit === DebitOrCredit.Debit;
    const priceVal = _.isNumber(total) ? Math.abs(total) : total;
    return isDebit
      ? {
        text: 'Est. Debit',
        directionClass: 'neg',
        price: priceVal,
      }
      : {
        text: 'Est. Credit',
        directionClass: 'pos',
        price: priceVal,
      };
  }, [total, debitCredit]);

  return (
    <>
      <span>
        {totalInfo.text}
        <span className={totalInfo.directionClass}>
          <BoldNumber prefix=" " value={totalInfo.price} currency />
        </span>
      </span>
      <span>
        {' Standard Est. Margin'}
        <span className={'neg'}>
          <BoldNumber prefix=" " value={marginCost} currency />
        </span>
      </span>
    </>
  );
};

const isOrderValidSelector = createCachedSelector(
  (s: OrderState, blockId: string) => s.validation.valid,
  (s: OrderState, blockId: string) => {
    const legs = s.legs;
    if (_.isNil(legs) || _.values(legs).length === 0) {
      return false;
    }
    return _.every(legs, ({ OptionType, Strike, Expiration, SpreadRatio }) => {
      if (_.isNil(OptionType)) {
        return true;
      } else {
        return _.every([Strike, Expiration, SpreadRatio], v => !_.isNil(v));
      }
    });
  },
  (valid, complete) => {
    return { valid, complete };
  }
)((s: OrderState, blockId: string) => blockId);

export const OrderFooter: React.FC<{ symbol: string; noExpirations?: boolean }> = ({ noExpirations }) => {
  const {
    isPreview,
    togglePreview,
    previewClosed,
    audio: { successAudio, errorAudio },
  } = useOrderPreview();

  const message = useOrderData(s => s.validation.message);
  const previewEnabled = useOrderData(s => s.preview.enabled);
  const tbOrder = useOrderBlockData(OrderSelectors.order);
  const tbOrderLegs = useOrderBlockData(OrderSelectors.legs);
  const orderQuote = useQuote(tbOrder.UnderlyingSymbol ? tbOrder.UnderlyingSymbol : '');
  const { muteAllSounds } = useDashboardSettings();
  const orderAssetClass = useMemo(() => {
    if (tbOrder.UnderlyingSymbol && orderQuote) {
      const { AssetClass } = orderQuote;
      return AssetClass;
    }
    return '';
  }, [tbOrder.UnderlyingSymbol, orderQuote]);
  const [isPriceValid, setIsPriceValid] = React.useState(false);

  const isMutualFund = useMemo(() => {
    return tbOrder && tbOrder.UnderlyingSymbol && tbOrder.UnderlyingSymbol.startsWith(':') && tbOrderLegs.length > 0;
  }, [tbOrder]);

  const isBond = useMemo(() => {
    return orderAssetClass === 16 && tbOrderLegs.length > 0 ? true : false;
  }, [orderAssetClass]);

  useWhyDidYouUpdate('OrderFooter', { message, previewEnabled });

  // need to perform price validation before preview is enabled
  // market and market on close orders do not require a price or stop price to be valid
  // limit orders require a price to be valid
  // stop orders require a stop price to be valid
  // stop limit orders require a price and stop price to be valid

  const validatePrice = (order: TradingblockOrder) => {
    const { Price, Stop } = order;
    if (order.OrderType === OrderType.Market || order.OrderType === OrderType.Market_On_Close) {
      return setIsPriceValid(true);
    } else if (order.OrderType === OrderType.Limit) {
      return setIsPriceValid(_.isNumber(Price));
    } else if (order.OrderType === OrderType.Stop_Market) {
      return setIsPriceValid(_.isNumber(Stop));
    } else if (order.OrderType === OrderType.Stop_Limit) {
      return setIsPriceValid(_.isNumber(Price) && _.isNumber(Stop));
    } else {
      return false;
    }
  };

  // need to perform validatePrice
  // if the order is valid, then enable preview
  // if the order is invalid, then disable preview
  useEffect(() => {
    if (tbOrder) {
      validatePrice(tbOrder);
    }
  }, [tbOrder.Price, tbOrder.Stop, tbOrder.OrderType]);

  return (
    <>
      {!muteAllSounds && successAudio}
      {!muteAllSounds && errorAudio}
      <div className="block-order-footer">
        <div className="block-order-footer-summary txt-sm">
          <OrderFooterPrice />
          <ProfitLoss />
        </div>
        {isMutualFund && (
          <div className="alert alert-warning order-warning mb-0 txt-sm">
            <strong>Alert!</strong>
            <p>Please use our Legacy platform to place your mutual fund order. Thank you.</p>
          </div>
        )}
        {isBond && (
          <div className="alert alert-warning order-warning mb-0 txt-sm">
            <strong>Alert!</strong>
            <p>Please contact our Trade Desk to place your bond order. Thank you.</p>
            <ContactPhone siteGroup={Config.siteGroup} />
          </div>
        )}
        <button
          className="btn btn-order block-order-footer-action caps"
          onClick={togglePreview}
          disabled={!previewEnabled || noExpirations || !isPriceValid}
          title={message}
          type="button"
        >
          <strong>Preview</strong> <i className="fal fa-arrow-right"></i>
        </button>
      </div>
      {isPreview && <OrderPreviewPopup show={isPreview} previewClosed={previewClosed} isCancelOrder={false} />}
    </>
  );
};
