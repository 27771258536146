import { IBlockState, IBlockDataState } from '@tradingblock/types';

import { useContext } from 'react';

import { BlockStateContext } from './BlockState';

import _ from 'lodash';
import { OutputSelector } from 'reselect';
export type Selector<S, T = any> = OutputSelector<S, T, (res: S) => T>;
export type Transform<S, T = any> = (res: S) => T;
export function useBlockState<K extends keyof IBlockState>(field: K) {
  const state = useContext(BlockStateContext);
  //return {field: getFieldFromState(state, field)};
  return state[field];
}
export function useBlockStateSelector<D extends IBlockDataState, S = any>(selector: Selector<IBlockState<D, S>> | Transform<IBlockState<D, S>>, defaultVal?: IBlockState<D, S>) {
  //converting to type T is a hack :(
  const state = useContext(BlockStateContext) as IBlockState<D, S>;
  const res = selector(state);
  return defaultVal && _.isNil(res) ? defaultVal : res;
}
