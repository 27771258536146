import { InitialState } from '../../initialState';
import { getType } from 'typesafe-actions';
import _ from 'lodash';
import {
  PrivateEntitlementsState,
  EntitlementsSearchEntityWithKey,
  EntitlementsSearchUpdatesState,
  DefaultUserUpdateAccountDetails,
} from '@tradingblock/types';
import { AdminEntitlementsAction, AdminEntitlementsActions } from '../../actions/admin/EntitlementsActions';
import {
  entitlementRequestKey,
  entitlementRequestToQueryString,
  getEntityKey,
} from '../../utilities/entitlementsUtilities';

const defaultQueryState = (queryString: string) => ({
  queryString,
  ids: [],
  isFetching: false,
  error: undefined,
});

type Actions = AdminEntitlementsAction;
export const privateEntitlementsReducer = (
  state = InitialState.private.entitlements,
  action: Actions
): PrivateEntitlementsState => {
  switch (action.type) {
    case getType(AdminEntitlementsActions.requestEntitlementUpdates): {
      const requestKey = entitlementRequestKey(action.payload);
      const existingQueryState =
        state.byQuery[requestKey] || defaultQueryState(entitlementRequestToQueryString(action.payload));
      return {
        ...state,
        byQuery: {
          ...state.byQuery,
          [requestKey]: {
            ...existingQueryState,
            isFetching: true,
            error: undefined,
          },
        },
      };
    }
    case getType(AdminEntitlementsActions.receiveEntitlementUpdates): {
      const { request, response } = action.payload;
      const querykey = entitlementRequestKey(request);
      const existingQueryState = state.byQuery[querykey] || defaultQueryState(entitlementRequestToQueryString(request));

      const data = response && response.data ? response.data : [];
      const total = response && response.total ? response.total : 0;
      const incomingData = _.map(
        data,
        (re): EntitlementsSearchEntityWithKey => ({ ...re, entityKey: getEntityKey(re) })
      );
      const updatedItems = _.reduce(
        incomingData,
        (ent: EntitlementsSearchUpdatesState, value: EntitlementsSearchEntityWithKey) => {
          return {
            ...ent,
            [value.entityKey]: value,
          };
        },
        state.entitlementItems
      );

      const ids = incomingData.map(i => i.entityKey);

      return {
        ...state,
        entitlementItems: updatedItems,
        byQuery: {
          ...state.byQuery,
          [querykey]: {
            ...existingQueryState,
            isFetching: false,
            ids,
            total,
          },
        },
      };
    }
    case getType(AdminEntitlementsActions.errorEntitlementUpdates): {
      return {
        ...state,
        entitlementItems: {},
        isFetching: false,
        error: action.payload,
        ui: {},
      };
    }
    case getType(AdminEntitlementsActions.setEntitlementsPage):
    case getType(AdminEntitlementsActions.setEntitlementsPageSize): {
      return {
        ...state,
        ui: {
          ...state.ui,
          ...action.payload,
        },
      };
    }
    case getType(AdminEntitlementsActions.setCurrentItem): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          request: {
            UserId: action.payload.PrimaryAccountIdAssociatedWithUser,
            AccountNumber: action.payload.AccountNumber,
          },
          details: action.payload,
          isFetching: false,
        },
        ui: {
          ...state.ui,
        },
      };
    }
    case getType(AdminEntitlementsActions.clearCurrentItem): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          request: {
            UserId: undefined,
            AccountNumber: undefined,
          },
          isFetching: false,
          isAccountFetching: false,
          accountInfo: DefaultUserUpdateAccountDetails,
          details: {},
        },
      };
    }
    case getType(AdminEntitlementsActions.requestEntitlementAccountDetails): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          isAccountFetching: true,
        },
      };
    }
    case getType(AdminEntitlementsActions.receiveEntitlementAccountDetails): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          isAccountFetching: false,
          accountInfo: action.payload.response.payload,
        },
      };
    }
    case getType(AdminEntitlementsActions.requestApproveUserMarketDataEntitlement): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          isUpdated: false,
        },
      };
    }
    case getType(AdminEntitlementsActions.receiveApproveUserMarketDataEntitlement): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          isUpdated: true,
        },
      };
    }
    case getType(AdminEntitlementsActions.errorApproveUserMarketDataEntitlement): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          isUpdated: false,
          error: action.payload,
        },
      };
    }
    case getType(AdminEntitlementsActions.requestRejectUserMarketDataEntitlement): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          isUpdated: false,
        },
      };
    }
    case getType(AdminEntitlementsActions.receiveRejectUserMarketDataEntitlement): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          isUpdated: true,
        },
      };
    }
    case getType(AdminEntitlementsActions.errorRejectUserMarketDataEntitlement): {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          isUpdated: false,
          error: action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};
