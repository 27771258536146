import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import { PersistablePriceChartState, AssetSymbol, BlockType } from '@tradingblock/types';
import { useDispatcher } from '../../../data/global/hooks';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { useFeedQuoteWithMetadata } from '../../../hooks/useFeedQuote';
import { PriceChartState } from '../../../blocks/PriceChart/state/PriceChartState';
import { BlockActions } from '../../../data/global/actions';
import { generateNewBlock } from '../../../utilities/block';
import { ReplaceFirstMatchOptions } from './useOrderBlock';

export interface PriceChartBuilderType {
  symbol: (symbol: AssetSymbol) => PriceChartBuilderType;
  priceChart: () => Partial<PersistablePriceChartState>;
}

export class PriceChartBuilder implements PriceChartBuilderType {
  private priceChartState: Partial<PersistablePriceChartState>;
  private dirty: Partial<{ [K in keyof PersistablePriceChartState]: boolean }>;
  constructor() {
    this.priceChartState = {};
    this.dirty = {};
  }
  set<K extends keyof PersistablePriceChartState>(k: K, value: PersistablePriceChartState[K]) {
    this.dirty[k] = true;
    this.priceChartState = {
      ...this.priceChartState,
      [k]: value,
    };
    return this;
  }
  symbol(symbol: AssetSymbol) {
    return this.set('symbol', symbol);
  }
  priceChart() {
    return this.priceChartState;
  }
}

interface UseChartActionsProps {
  occSymbol: string | undefined;
  addBlockToGroupId?: string;
  data?: Partial<PriceChartState>;
  baseAssetSymbol: AssetSymbol;
  isUnderlyingSymbol?: boolean;
}
export const usePriceChartBlock = ({
  occSymbol,
  addBlockToGroupId,
  data,
  baseAssetSymbol,
  isUnderlyingSymbol,
}: UseChartActionsProps) => {
  const { dispatcher } = useDispatcher();
  const symbolQuote = useFeedQuoteWithMetadata(occSymbol || '');
  const symbol: AssetSymbol | undefined = useMemo(() => {
    if (symbolQuote) {
      // const { Symbol, Description, Exchange, UnderlyingSymbol, AssetClass } = symbolQuote;
      return baseAssetSymbol;
    }
    return baseAssetSymbol;
  }, [symbolQuote, baseAssetSymbol]);

  const createOrUpdatePriceChartBlock = useCallback(
    (addBlockAsNew?: boolean) => {
      const initialBlockData = data || { symbol };
      const underlying = symbol.underlyingSymbol;
      if (isUnderlyingSymbol && underlying) {
      }
      const newBlock = generateNewBlock(BlockType.PriceChart, {
        ...initialBlockData,
        symbol,
      });
      newBlock.groupId = addBlockToGroupId || '';
      // if adding block as new, don't pass "replace first match" options

      dispatcher.block.addOrUpdateBlock(
        newBlock,
        false,
        addBlockAsNew ? undefined : { blockType: BlockType.PriceChart, groupId: addBlockToGroupId }
      );
    },
    [data, symbol, addBlockToGroupId, dispatcher.block]
  );

  // if linkable block exists, show 2 trade options in dropdown
  return { createOrUpdatePriceChartBlock };
};

export const useChartBlockWithOrder = (
  build: (builder: PriceChartBuilderType) => PriceChartBuilderType,
  replaceFirstMatch?: ReplaceFirstMatchOptions
) => {
  const { dispatcher, dispatch } = useDispatcher();
  const priceChartBlockBuilder = useCallback(
    (addBlockAsNew?: boolean) => {
      const priceChartBlockData = build(new PriceChartBuilder()).priceChart();
      const newBlock = generateNewBlock(BlockType.PriceChart, priceChartBlockData);
      dispatch(
        BlockActions.addOrUpdateBlock(
          {
            block: newBlock,
            addedAt: dayjs().toDate(),
            preserveGroupOrderData: false,
            replaceFirstMatch: addBlockAsNew === true ? undefined : replaceFirstMatch,
          },
          { persist: true }
        )
      );
    },
    [replaceFirstMatch, dispatcher.block]
  );

  // if linkable block exists, show 2 trade options in dropdown
  return priceChartBlockBuilder;
};

export const useChartSymbolAction = (
  symbol: AssetSymbol,
  fn: (builder: PriceChartBuilderType) => PriceChartBuilderType,
  blockSelectionOptions?: Pick<ReplaceFirstMatchOptions, 'groupId'>
) => {
  const buildChartOrder = (builder: PriceChartBuilderType) => {
    const baseBuilder = builder.symbol(symbol);
    return fn(baseBuilder);
  };
  const blockSelectionOpts = blockSelectionOptions || {};
  // if linkable block exists, show 2 chart options in dropdown
  const handleChartOrder = useChartBlockWithOrder(buildChartOrder, {
    blockType: BlockType.PriceChart,
    ...blockSelectionOpts,
  });
  return useMemo(() => handleChartOrder, [fn, symbol]);
};
