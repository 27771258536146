import React from 'react';
import _ from 'lodash';
import { useWhyDidYouUpdate, BlockDragHandle } from '@tradingblock/components';
import { useBlockMetadata } from '../BlockState';
import { IBlockProps } from '../Block';

export const BlockContentHeaderTitle: React.FunctionComponent<Pick<IBlockProps, 'header'>> = ({ header }) => {
  const { blockId } = useBlockMetadata();

  useWhyDidYouUpdate(`${blockId}:BlockContentHeaderTitle`, { blockId, header });

  if (!header) {
    return null;
  }
  return <>{_.isString(header) ? <BlockDragHandle>{header}</BlockDragHandle> : header}</>;
};
