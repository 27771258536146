import _ from 'lodash';
import {
  BlockConfiguration,
  DashboardConfiguration,
  Layout,
  BlockType,
  Layouts,
  SizeType,
  BlockLayouts,
  DefaultDashboardConfiguration,
  BlockTypeConfiguration,
  CreateDashboardRequest,
} from '@tradingblock/types';
import { Shares, getMinBlockSize } from '@tradingblock/api';
import { newId } from '@tradingblock/components';
import {
  OrderStateFactory,
  PriceChartStateFactory,
  OptionChainStateFactory,
  QuoteStateFactory,
  InfoStateFactory,
} from '../blocks/BlockStateFactory';

type BlockPreset = Pick<BlockConfiguration, 'type' | 'layouts' | 'data' | 'groupId'>;

export const isBlockTypeAllowed = (blockType: BlockType, isVirtualEnv: boolean) => {
  if (isVirtualEnv) {
    // some block types are not allowed in virtual env
    return (
      !_.includes([BlockType.AccountTransfer], blockType) &&
      !_.includes([BlockType.AccountManagement], blockType) &&
      !_.includes([BlockType.AdminTools], blockType) &&
      !_.includes([BlockType.AccountStatements], blockType) &&
      !_.includes([BlockType.SubAccountManagement], blockType)
    );
  }

  return true;
};

const getBlocksWithIds = (blocks: Omit<BlockConfiguration, 'blockId'>[], isVirtualEnv: boolean) => {
  const allowedBlocks = _.filter(blocks, b => isBlockTypeAllowed(b.type, isVirtualEnv));
  return _.map(allowedBlocks, b => ({ ...b, blockId: newId(), groupId: b.groupId || newId() }));
};

const getPresetData = (blocks: BlockPreset[], isVirtualEnv: boolean) => {
  const blocksWithIds = getBlocksWithIds(blocks, isVirtualEnv);
  // separate out layouts from blocks
  const presetLayouts = _.reduce(
    blocksWithIds,
    (layouts: Layouts, block) => {
      return _.reduce(
        block.layouts || {},
        (acc: Layouts, blockLayout, breakpoint) => {
          const breakpointLayouts = [
            ...(acc[breakpoint] || []),
            { ...blockLayout, i: block.blockId, type: block.type },
          ];
          return { ...acc, [breakpoint]: breakpointLayouts };
        },
        layouts
      );
    },
    {}
  );
  // remove layouts from each block
  const presetBlocks = _.map(blocksWithIds, b => _.omit(b, 'layouts'));
  return {
    presetBlocks,
    presetLayouts,
  };
};

const presetBlockLayoutAndData = (columns: number, breakpoint: SizeType) => (type: BlockType, layout: Layout) => (
  b: BlockTypeConfiguration
) => {
  const isMatch = b.type === type;
  if (isMatch) {
    let data: any = b.initialData;
    const initSymbol = b.initialData.symbol;
    if (type === BlockType.PriceChart) {
      data = PriceChartStateFactory.Build(b.initialData.symbol);
    } else if (initSymbol && type === BlockType.OptionChain) {
      data = OptionChainStateFactory.Build(initSymbol);
    } else if (initSymbol && type === BlockType.Order) {
      data = OrderStateFactory.Build(Shares, initSymbol);
    } else if (initSymbol && type === BlockType.Quote) {
      data = QuoteStateFactory.Build(initSymbol);
    } else if (initSymbol && type === BlockType.Info) {
      data = InfoStateFactory.Build(initSymbol);
    }

    const minSize = getMinBlockSize(type, columns, breakpoint);
    const layouts = _.reduce(
      _.values(SizeType),
      (acc: BlockLayouts, b) => ({ ...acc, [b]: { ...layout, type, minW: minSize.w, minH: minSize.h } }),
      {}
    );
    return {
      ...b,
      layouts,
      data,
    };
  }

  return b;
};

const getDashboardPreset = (
  columns: number,
  dashboardConfig: DefaultDashboardConfiguration,
  isVirtualEnv: boolean,
  breakpoint: SizeType
) => {
  const equalSize = Math.floor(columns * 0.5);
  // const narrowSize = Math.floor(columns * 0.45);

  // calculate w/x for block layouts based on columns
  const layouts = {
    full: { w: columns, x: 0 },
    equal: {
      left: { w: equalSize, x: 0 },
      right: { w: columns - equalSize, x: equalSize },
    },
    // offset: {
    //   left: { w: narrowSize, x: 0 },
    //   right: { w: columns - narrowSize, x: narrowSize },
    // },
  };

  const presetBlockData = presetBlockLayoutAndData(columns, breakpoint);

  switch (dashboardConfig.title) {
    case 'Account Summary':
      return {
        ...dashboardConfig,
        ...getPresetData(
          dashboardConfig.blocks
            .map(presetBlockData(BlockType.Favorites, { ...layouts.equal.left, h: 7, y: 1 }))
            .map(presetBlockData(BlockType.Favorites, { ...layouts.equal.left, h: 7, y: 1 }))
            .map(presetBlockData(BlockType.AccountManagement, { ...layouts.equal.left, h: 5, y: 2 }))
            .map(presetBlockData(BlockType.AccountStatements, { ...layouts.equal.left, h: 4, y: 3 }))
            .map(presetBlockData(BlockType.Account, { ...layouts.equal.right, h: 2, y: 1 }))
            .map(presetBlockData(BlockType.Positions, { ...layouts.equal.right, h: 4, y: 2 }))
            .map(presetBlockData(BlockType.Order, { ...layouts.equal.right, h: 6, y: 3 }))
            .map(presetBlockData(BlockType.AccountTransfer, { ...layouts.equal.right, h: 4, y: 4 })),
          isVirtualEnv
        ),
      };

    case 'Research':
      return {
        ...dashboardConfig,
        ...getPresetData(
          dashboardConfig.blocks
            .map(presetBlockData(BlockType.Favorites, { ...layouts.equal.left, h: 6, y: 1 }))
            .map(presetBlockData(BlockType.OptionChain, { ...layouts.equal.left, h: 5, y: 2 }))
            .map(presetBlockData(BlockType.Info, { ...layouts.equal.left, h: 5, y: 3 }))
            .map(presetBlockData(BlockType.Account, { ...layouts.equal.right, h: 2, y: 1 }))
            .map(presetBlockData(BlockType.PriceChart, { ...layouts.equal.right, h: 9, y: 2 }))
            .map(presetBlockData(BlockType.Quote, { ...layouts.equal.right, h: 5, y: 3 })),
          isVirtualEnv
        ),
      };

    case 'Trading': {
      return {
        ...dashboardConfig,
        ...getPresetData(
          dashboardConfig.blocks
            .map(presetBlockData(BlockType.Order, { ...layouts.equal.left, h: 6, y: 1 }))
            .map(presetBlockData(BlockType.Activity, { ...layouts.equal.left, h: 4, y: 2 }))
            .map(presetBlockData(BlockType.OptionChain, { ...layouts.equal.left, h: 6, y: 3 }))
            .map(presetBlockData(BlockType.Account, { ...layouts.equal.right, h: 2, y: 1 }))
            .map(presetBlockData(BlockType.Positions, { ...layouts.equal.right, h: 6, y: 2 }))
            .map(presetBlockData(BlockType.PriceChart, { ...layouts.equal.right, h: 8, y: 3 })),
          isVirtualEnv
        ),
      };
    }
  }
  return {
    ...dashboardConfig,
    ...getPresetData(dashboardConfig.blocks, isVirtualEnv),
  };
};

export const getDashboardPresetsForRepCode = (
  repCode: string | undefined,
  columns: number,
  dashboardConfig: DefaultDashboardConfiguration[],
  isVirtualEnv: boolean,
  breakpoint: SizeType
): DashboardConfiguration[] => {
  if (repCode) {
    // preset dashboards for specific rep codes not defined yet...
    return [];
  }
  // default preset dashboards (rep code not defined)
  return dashboardConfig.map(dc => getDashboardPreset(columns, dc, isVirtualEnv, breakpoint));
};

export const getCreateDashboardRequest = (board: DashboardConfiguration): CreateDashboardRequest => {
  const { presetBlocks, presetLayouts, ...info } = board;
  return {
    info,
    blocks: presetBlocks,
    layouts: presetLayouts,
    dashboard: { dashboardTemplateId: info.dashboardTemplateId },
  };
};
