import React from 'react';
import _ from 'lodash';
import { ResponsiveTdMemo, getTableTdClass } from '.';
import { getClassNames } from '../../utilities';
export var TableRow = function TableRow(_ref) {
  var row = _ref.row,
      index = _ref.index,
      rowKey = _ref.rowKey,
      prepareRow = _ref.prepareRow,
      rowClass = _ref.rowClass,
      onRowClick = _ref.onRowClick,
      size = _ref.size,
      responsiveDisabled = _ref.responsiveDisabled,
      tableInstance = _ref.tableInstance,
      numColumns = _ref.numColumns,
      columnWidths = _ref.columnWidths;
  var tableTdClass = getTableTdClass(size);
  var display = row.original.display;

  var trClassName = _.get(display, 'trClass', '');

  var tdClass = _.get(display, 'tdClass', undefined);

  var rowProps = row.getRowProps();

  var renderRow = _.get(display, 'renderRow', undefined);

  var renderedRow = _.isFunction(renderRow) ? renderRow(row, index, rowProps) : undefined;

  if (renderedRow) {
    return renderedRow;
  }

  var resolvedClass = rowClass ? _.isFunction(rowClass) ? rowClass(row) : rowClass : trClassName;
  return React.createElement("tr", Object.assign({
    key: rowKey
  }, rowProps, {
    className: resolvedClass,
    onClick: onRowClick ? function (e) {
      return onRowClick(e, row);
    } : undefined
  }), row.cells.map(function (cell, index) {
    var headerVal = cell.column.Header;
    var header = _.isFunction(headerVal) ? headerVal(Object.assign({}, tableInstance, {
      column: {}
    })) : headerVal;
    var tdClassName = _.isFunction(tdClass) ? tdClass(cell) : tdClass;
    var cellProps = cell.getCellProps();
    var favoritesStyling = cell.column.id === 'favorites-last-col' ? 'col-delete' : undefined;
    var cellClass = getClassNames([tableTdClass, _.get(cellProps, 'className', undefined), tdClassName, favoritesStyling]);
    var columnWidth = !_.isEmpty(columnWidths) ? columnWidths[index] : undefined;
    return React.createElement(ResponsiveTdMemo, Object.assign({}, cellProps, {
      columnWidth: columnWidth,
      style: {
        minWidth: '80px'
      },
      numColumns: numColumns,
      header: responsiveDisabled ? undefined : header,
      className: cellClass
    }), cell.render('Cell'));
  }));
};