import React from 'react';
import { EntitlementBaseUpdate } from '@tradingblock/types';

export const EntitlementsItemCell: React.FunctionComponent<
  Pick<
    EntitlementBaseUpdate,
    'ApprovingUserName' | 'RequestedMarketDataEntitlementType' | 'ApprovedUserMarketDataEntitlementType'
  >
> = ({ ApprovingUserName, RequestedMarketDataEntitlementType, ApprovedUserMarketDataEntitlementType }) => {
  const isPending = ApprovingUserName === null || ApprovingUserName === undefined ? true : false;
  const isRejected = !isPending && ApprovedUserMarketDataEntitlementType !== RequestedMarketDataEntitlementType;
  return (
    <>
      {isPending && (
        <>
          <span className="txt-sm">
            {RequestedMarketDataEntitlementType && RequestedMarketDataEntitlementType.replace(/([A-Z])/g, ' $1').trim()}
          </span>
          <br />
          <span className="txt-sm pending">Pending</span>
        </>
      )}
      {!isPending && (
        <>
          <span className="txt-sm">
            {RequestedMarketDataEntitlementType && RequestedMarketDataEntitlementType.replace(/([A-Z])/g, ' $1').trim()}
          </span>
          <br />
          <span className={`${isRejected ? 'neg' : 'pos'} txt-sm`}>{isRejected ? 'Rejected' : 'Approved'}</span>
        </>
      )}
    </>
  );
};
