import { DateModel } from '@tradingblock/types';
import dayjs from 'dayjs';

export const formatAgreementKey = (agreementKey: string) => {
  switch (agreementKey) {
    case 'clientRelationshipSummary':
      return 'Client Relationship Summary';
    case 'customerAndPrivacyPolicy':
      return 'Customer and Privacy Policy';
    case 'electronicConsent':
      return 'Electronic Consent';
    case 'exchangeDataAgreement':
      return 'Exchange Data Agreement';
    case 'entityMargin':
      return 'Entity Margin';
    case 'entityOption':
      return 'Entity Option';
    case 'iRACoverDellAdoption':
      return 'IRA Cover Dell Adoption';
    case 'iRASepAdoption':
      return 'IRA Sep Adoption';
    case 'iRASimpleAdoption':
      return 'IRA Simple Adoption';
    case 'iRARothAdoption':
      return 'IRA Roth Adoption';
    case 'limitedMargin':
      return 'Limited Margin';
    case 'margin':
      return 'Margin';
    case 'option':
      return 'Option';
    case 'onlineServices':
      return 'Online Services';
    case 'riskDisclosure':
      return 'Risk Disclosure';
    case 'corporateCash':
      return 'Corporate Cash';
    case 'corporateMargin':
      return 'Corporate Margin';
    case 'jointCommunity':
      return 'Joint Community';
    case 'jointSurvivor':
      return 'Joint Survivor';
    case 'llcAccount':
      return 'LLC Account';
    case 'rqdClearingDisclosures':
      return 'RQD Clearing Disclosures';
    case 'rqdCustomerAgreement':
      return 'RQD Customer Agreement';
    case 'rqdCustomerInformationBrochure':
      return 'RQD Customer Information Brochure';
    case 'rqdExecutionDisclosures':
      return 'RQD Execution Disclosures';
    case 'rqdLimitedMarginAgreement':
      return 'RQD Limited Margin Agreement';
    case 'rqdLimitedTradingAuthorization':
      return 'RQD Limited Trading Authorization';
    case 'rqdMargin':
      return 'RQD Margin Agreement';
    case 'rqdOption':
      return 'RQD Option Agreement';
    case 'rqdPowerOfAttorney':
      return 'RQD Fee Payment and Full Trading Authorization';
    case 'rqdPrivacyNotice':
      return 'RQD Privacy Notice';
    case 'rqdPrivacyPolicy':
      return 'RQD Privacy Policy';
    case 'rqdResponsibilitiesOfIbAndCarryingBroker':
      return 'RQD Responsibilities of IB and Carrying Broker';
    case 'rqdSpecialStatementForUncoveredOptionWriters':
      return 'RQD Special Statement for Uncovered Option Writers';
    case 'apiAgreement':
      return 'API Agreement';
    default:
      return agreementKey;
  }
};

export const formatMarketDataKey = (marketDataKey: string) => {
  switch (marketDataKey) {
    case 'associationRegistration':
      return 'Association Registration';
    case 'benefitsReceived':
      return 'Benefits Received';
    case 'capitalTrading':
      return 'Capital Trading';
    case 'corporationTrading':
      return 'Corporation Trading';
    case 'employmentFunctions':
      return 'Employment Functions';
    case 'investmentProfessional':
      return 'Investment Professional';
    case 'jobFunctions':
      return 'Job Functions';
    case 'personalUse':
      return 'Personal Use';
    case 'secCftcRegistration':
      return 'SEC CFTC Registration';
    default:
      return marketDataKey;
  }
};

export const formatBirthDate = (birthDate: DateModel | undefined) => {
  const { day, month, year } = birthDate || { day: '', month: '', year: '' };
  const dateString = `${month}/${day}/${year}`;
  if (birthDate) {
    return dayjs(dateString).format('MMMM DD, YYYY');
  }
  return 'None';
};

export const maskSSN = (ssn: string | undefined) => {
  const masked = ssn || '';
  const prefix = 'xxxx-xx-';
  const unmaskedIdx = masked.length - 4;
  return prefix + masked.substring(unmaskedIdx);
};
