import React, { FC, useMemo } from 'react';
import _ from 'lodash';
import { Dropdown, DropdownItem } from '@tradingblock/components';
import { OrderAction, OrderLegDTO } from '@tradingblock/types';
import { PositionDTO, AggregatedPositionDTO } from '@tradingblock/api';
import { OrderActionButtonProps, OrderActionButton } from '../../components/blocks/actions/OrderActionButtons';

export interface PositionActionsProps {
  tradeData: {
    legs: {
      [x: string]: OrderLegDTO;
    };
    action: OrderAction;
    quantity: number;
  };
  position: AggregatedPositionDTO;
  maxVisibleActions: number;
}

export const PositionActions: FC<{
  actions: OrderActionButtonProps[];
  identity: string;
  maxVisibleActions: number;
}> = ({ actions, identity, maxVisibleActions }) => {
  const hasOverflowActions = useMemo(() => actions.length > maxVisibleActions, [actions, maxVisibleActions]);

  const primaryActions = useMemo(() => {
    return hasOverflowActions ? _.take(actions, maxVisibleActions) : actions;
  }, [actions, hasOverflowActions, maxVisibleActions]);

  const secondaryActions = useMemo(() => {
    return hasOverflowActions ? _.drop(actions, maxVisibleActions) : [];
  }, [actions, hasOverflowActions, maxVisibleActions]);

  return (
    <ul className="inline-actions">
      {primaryActions.map((action, ind) => (
        <li key={`${identity}-primary-${ind}`}>
          <OrderActionButton {...action} />
        </li>
      ))}
      {secondaryActions.length > 0 && (
        <li>
          <Dropdown
            content={<i className="fa fa-ellipsis-h" />}
            direction="right"
            buttonOptions={{ toggleButtonClassName: 'btn btn-blend dropdown-toggle no-caret' }}
          >
            {secondaryActions.map((sa, ind) => (
              <DropdownItem onClick={sa.onClick} key={`${identity}-secondary-${ind}`}>
                <span className="fa-stack">
                  <i className="fal fa-clipboard fa-stack-2x"></i>
                  <i className={`fal fa-stack-1x ${sa.icon}`}></i>
                </span>
                {sa.title}
              </DropdownItem>
            ))}
          </Dropdown>
        </li>
      )}
    </ul>
  );
};
