import { select, call, put, delay, takeLatest } from 'redux-saga/effects';
import { getHttpStore } from '@tradingblock/storage';
import { DashboardInfo, GroupState, Layouts } from '@tradingblock/types';
import {
  uiApiToken,
  dashboardsSelectors,
  dashboardIdSelector,
  blocksSelectors,
  layoutSelectors,
} from '../dataSelectors';
import {
  PersistActions,
  ADD_DASHBOARD,
  UPDATE_DASHBOARD,
  PERSIST_DASHBOARDS,
  AddDashboardAction,
  UpdateDashboardAction,
} from '../actions';
import Config from '../../../config';
import { trySaveDashboards } from '../../async/dashboards';
import { trySaveBlocks } from '../../async/blocks';

// export const tryLoadDashboards = async (storage: IStorageEngine) => {
//   try {
//     return storage.get<StorageValue<DashboardInfo[]>>(StorageDashboardsPath);
//   } catch (ex) {
//     console.error('tryLoadDashboards', ex);
//     return Promise.resolve([]);
//   }
// };

// export function* loadDashboards() {
//   const tkn = yield select(uiApiToken);
//   const store = getHttpStore(Config.uiApi, tkn);
//   const dashboards = yield call(tryLoadDashboards, store);
//   yield put(DashboardActions.receiveDashboards(dashboards));
// }

function isAddAction(action: AddDashboardAction | UpdateDashboardAction): action is AddDashboardAction {
  return action.type === ADD_DASHBOARD;
}

function* persistDashboards(action: AddDashboardAction | UpdateDashboardAction) {
  yield delay(500);

  const token: string = yield select(uiApiToken);
  const dashboards: DashboardInfo[] = yield select(dashboardsSelectors.dashboards);
  const dashboardId: string = yield select(dashboardIdSelector);
  const groups: GroupState = yield select(blocksSelectors.blockGroups);
  const layouts: Layouts = yield select(layoutSelectors.layouts);
  const store = getHttpStore(Config.uiApi, token);

  yield call(trySaveDashboards, store, dashboards);

  if (isAddAction(action)) {
    console.log('trying to save blocks', dashboardId, action.payload);
    yield call(trySaveBlocks, store, dashboardId, { blocks: action.payload.blocks, groups, layouts });
  }

  yield put(PersistActions.dashboardsFinished());
}

function* tryPersistDashboards(action: PersistActions) {
  if (action.meta && action.meta.persist === false) {
    return;
  } else {
    const dashboards: DashboardInfo[] = yield select(dashboardsSelectors.dashboards);
    yield put(PersistActions.dashboards(dashboards));
  }
}

export function* watchShouldPersistDashboardsCheck() {
  // yield takeLatest([ADD_DASHBOARD, UPDATE_DASHBOARD, SET_DASHBOARD], tryPersistDashboards);
  yield takeLatest([ADD_DASHBOARD, UPDATE_DASHBOARD], tryPersistDashboards);
}

export function* watchDashboardChanges() {
  yield takeLatest([PERSIST_DASHBOARDS], persistDashboards);
}
