import { ActionType } from 'typesafe-actions';
import { AdminAccountActions } from './AdminAccountActions';
import { AdminCashieringActions } from './AdminCashieringActions';
import { AdminClientActions } from './AdminClientActions';
import { AdminAccountManagementActions } from './AccountManagementActions';
import { AdminEntitlementsActions } from './EntitlementsActions';
import { AdminAlertActions } from './AdminAlertActions';
import { AdminApplicationActions } from './AdminApplicationActions';

export interface AdminIdRequest {
  id: number | string;
}

export const AdminActions = {
  ...AdminAccountActions,
  ...AdminAlertActions,
  ...AdminCashieringActions,
  ...AdminClientActions,
  ...AdminAccountManagementActions,
  ...AdminEntitlementsActions,
  ...AdminApplicationActions,
};

export type AdminAction = ActionType<typeof AdminActions>;
