import React, { useEffect, useCallback, useMemo } from 'react';
import { SortingRule, Table, useWhyDidYouUpdate, useParentSizeContext, DataTableState } from '@tradingblock/components';
import { useBboxDimensions } from '../useBboxDimensions';
import { useBlockId } from '../../../components/blocks/BlockState';
import { useBlockTableSize } from './useBlockTableSize';
import { useBlockTable } from './useBlockTableActions';
import { BlockTableProps } from './BlockTableTypes';
import { useStateSelector } from '../../../data/global/dataSelectors';

export const BlockTableContent: React.FunctionComponent<BlockTableProps> = ({
  onSizeChanged,
  onSort,
  tableKey,
  onPage,
  onPageSize,
  pageCount,
  getColumnOrder,
  expandRows,
  firstRowExpanded,
  ...props
}) => {
  const b = useParentSizeContext();
  const [{ width }, ref] = useBboxDimensions<HTMLDivElement>(250);
  const size = useBlockTableSize(width);
  const blockTableState = useBlockTable(tableKey);
  const handleonSort = useCallback(
    (sorts: SortingRule<any>[]) => {
      if (onSort) {
        onSort(sorts);
      }
      blockTableState.setTable({ sortBy: sorts });
    },
    [onSort, blockTableState.setTable]
  );
  const handleOnColumnOrderChanged = useCallback(
    (columnOrder: string[]) => {
      blockTableState.setTable({ columnOrder });
    },
    [blockTableState.setTable]
  );
  useEffect(() => {
    if (onSizeChanged) {
      onSizeChanged(size);
    }
  }, [size, onSizeChanged]);

  const blockId = useBlockId();
  useWhyDidYouUpdate(`${blockId}:BlockTable`, { size, ref, blockId, ...props });
  const initialState = useMemo((): DataTableState => {
    const tableState = blockTableState.state;
    return {
      ...(tableState || {}),
      ...(props.initialState || {}),
      columnOrder: tableState.columnOrder || (props.initialState && props.initialState.columnOrder),
      sortBy: tableState.sortBy || (props.initialState && props.initialState.sortBy),
    };
  }, [props.initialState, blockTableState.state]);

  useEffect(() => {
    if (getColumnOrder) {
      getColumnOrder(blockTableState.state.columnOrder);
    }
  }, [getColumnOrder, blockTableState.state.columnOrder]);

  // use the blockTableState to set the page index and page size if they differ from the initial state
  // this is to ensure that the table state is always in sync with the blockTableState
  // currently the activities block is not set up to persist tab or timeframe selections, so we cannot use the blockTableState to set the page index and page size
  // TODO: refactor the activities block to persist tab and timeframe selections
  useEffect(() => {
    if (
      initialState.pageIndex !== blockTableState.state.pageIndex &&
      onPage &&
      tableKey !== 'orders' &&
      tableKey !== 'accountsTable'
    ) {
      onPage(blockTableState.state.pageIndex);
    }
    if (
      initialState.pageSize !== blockTableState.state.pageSize &&
      onPageSize &&
      tableKey !== 'orders' &&
      tableKey !== 'accountsTable'
    ) {
      onPageSize(blockTableState.state.pageSize);
    }
  }, [blockTableState.state.pageIndex, blockTableState.state.pageSize]);

  const handleOnPage = useCallback(
    (pageIndex: number) => {
      if (onPage) {
        onPage(pageIndex);
      }
      blockTableState.setTable({ pageIndex });
    },
    [onPage, blockTableState.setTable]
  );

  const handleOnPageSize = useCallback(
    (pageSize: number) => {
      if (onPageSize) {
        onPageSize(pageSize);
      }
      blockTableState.setTable({ pageSize });
    },
    [onPageSize, blockTableState.setTable]
  );
  const isDashboardLocked = useStateSelector(s => s.ui.dashboard.isLocked);
  return (
    <div ref={ref} className="table-fixed-wrapper">
      <Table
        {...props}
        isDraggable={!isDashboardLocked}
        initialState={initialState}
        size={size}
        windowSize={b}
        onSort={handleonSort}
        onColumnOrderChanged={handleOnColumnOrderChanged}
        onPage={handleOnPage}
        onPageSize={handleOnPageSize}
        pageCount={pageCount}
        tableKey={tableKey}
        expandRows={expandRows}
        firstRowExpanded={firstRowExpanded}
      />
    </div>
  );
};
