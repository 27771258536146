import React, { useEffect, useRef, useState } from 'react';
import InputNumber, { InputNumberProps } from 'rc-input-number';
import { useOrderData } from '../state/OrderState';
import { useOrderStatus } from '../data/useOrderStatus';

export interface DEPRECATED_PriceComponentProps
  extends Omit<InputNumberProps<number>, 'formatter' | 'parser' | 'precision' | 'step'> {
  isCustomStrategyOrCalendar?: boolean;
}

export function DEPRECATED_PriceComponent({ isCustomStrategyOrCalendar, ...props }: PriceComponentProps) {
  const priceInputNumberRef = useRef<HTMLInputElement>(null);

  // Odd hack, but basically InputNumber takes control over the input, and does not listen to reference updates as it
  // Alters it according to the source code. The issue is that when we clear the value, it does not update the input
  // So we have to manually update the input when the value changes
  useEffect(() => {
    if (priceInputNumberRef.current && props.value === undefined) {
      priceInputNumberRef.current.value = '';
    }
  }, [priceInputNumberRef, props.value]);

  const { price } = useOrderData(s => ({ price: s.price }));

  return (
    <div className="spinner text-left btn-block">
      <InputNumber
        ref={priceInputNumberRef}
        precision={4}
        step={price ? (price >= 0 ? 0.01 : -0.01) : 0.01}
        formatter={(value: number | string | undefined, { userTyping, input }): string | undefined | null => {
          // Handle value and input slippage when the user is entering a value and the input is slipping behind
          if (value === '' && (input === '' || input === '$' || input === '0')) {
            return '';
          }

          // Handles when the user is typing and the input is slipping behind on deletion
          if (value === '' && userTyping !== true) {
            if (input === '$0.01') {
              return '$0.00';
            }
            return '';
          }

          // Handle when the user is entering input as it can be handled different
          if (userTyping) {
            // If the user is typing, return the value with a dollar sign and 2 decimal places
            return `${input !== '' &&
              input !== '$' &&
              input !== '.' &&
              input !== '0.' &&
              input !== '0' &&
              input !== '$0.00' &&
              input !== '-'
              ? input
              : value !== '0' && value !== ''
                ? isCustomStrategyOrCalendar // if isCustomStrategyOrCalendar is true, we allow negative
                  ? typeof value === 'number'
                    ? value
                    : parseFloat(value === undefined || value === '' ? '0' : value)
                  : Math.abs(
                    typeof value === 'number' ? value : parseFloat(value === undefined || value === '' ? '0' : value)
                  )
                : '$0.00'
              }`;
          }

          // If the user is not inputting anything
          if (value === undefined) {
            return '';
          }
          // Output the value of the value passed in with a dollar sign and 2 decimal places and removes ending two 00 if they exist on 4th decimal place
          // log the output prior to returning to see what is being returned
          return `$${Math.abs(typeof value === 'number' ? value : parseFloat(value === '' ? '0' : value))
            .toFixed(4)
            .replace(/(\.\d{2})(0*)$/, '$1')}`;
        }}
        parser={(displayValue: string | undefined): number => {
          return displayValue ? Number(displayValue.replace(/[$\s,]/g, '')) : 0;
        }}
        {...props}
      />
    </div>
  );
}


/**
 * Props for the PriceComponent.
 * @typedef {Object} PriceComponentProps
 * @property {string} [placeholder] - The placeholder text for the input field.
 * @property {(value: number | null) => void} onChange - Callback function that is called when the input value changes.
 * @property {boolean} [isCustomStrategyOrCalendar] - Flag indicating if custom strategy or calendar logic is applied.
 * @property {boolean} [disabled] - Flag indicating if the input should be disabled.
 * @property {number | null} [value] - The initial value of the input.
 */
export interface PriceComponentProps {
  placeholder?: string;
  onChange: (value: number | null) => void;
  isCustomStrategyOrCalendar?: boolean;
  disabled?: boolean;
  value?: number | null;
}

/**
 * A component for inputting price values.
 * @param {PriceComponentProps} props - The props for the component.
 */
export default function PriceComponent({
  placeholder,
  isCustomStrategyOrCalendar,
  onChange: valueFinalized,
  disabled,
  value,
}: PriceComponentProps) {
  const [displayValue, setDisplayValue] = useState<string>('');
  const [priceValue, setPriceValue] = useState<number>(value || 0);
  const priceInputNumberRef = useRef<HTMLInputElement | null>(null);
  const { complete } = useOrderStatus();

  // When the value prop changes, format it and update state.
  useEffect(() => {
    if (value === null) {
      setDisplayValue('$0.00'); // Clear display if value is null or undefined
      setPriceValue(0);
      valueFinalized(0)
    } else if (value === undefined) {
      setDisplayValue(''); // Clear display if value is null or undefined
      setPriceValue(0);
    } else {
      // Format value for display, removing trailing "00" if present
      let formattedDisplayValue = `$${Math.abs(value).toFixed(4)}`;
      if (formattedDisplayValue.endsWith("00")) {
        formattedDisplayValue = formattedDisplayValue.slice(0, -2);
      }
      setDisplayValue(formattedDisplayValue);
      setPriceValue(value); // Store the actual numeric value
    }
  }, [value]);

  /**
   * Handle input change events.
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event.
   */
  const onInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    // Define regex based on whether custom strategy or calendar is enabled
    const regex = new RegExp(/^\$?\d*(\.\d{0,4})?$/);
  
    // Check for multiple leading zeros and replace them with a single zero
    let sanitizedInputValue = inputValue.replace(/^\$?0+/, (match) => match === '$0' || match === '$00' ? '$0' : '0');

    if (sanitizedInputValue.length > 20) {
      return;
    }

    // Update state if input value matches allowed format
    if (sanitizedInputValue === '$' || regex.test(sanitizedInputValue)) {
      const numericValue = parseFloat(inputValue.replace(/[^\d.-]/g, '')) || 0;
      setDisplayValue(sanitizedInputValue);
      setPriceValue(numericValue);
    }
  };

  /**
   * Format the input value when the input field loses focus.
   * @param {React.FocusEvent<HTMLInputElement>} e - The focus event.
   */
  const formatOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    let inputValue = e.target.value.replace(/[^\d.-]/g, '');
    let numericValue = parseFloat(inputValue) || 0;

    // Format the numeric value for display, removing trailing "00" if present
    let formattedDisplayValue = `$${Math.abs(numericValue).toFixed(4)}`;
    if (formattedDisplayValue.endsWith("00")) {
      formattedDisplayValue = formattedDisplayValue.slice(0, -2);
    }
    setDisplayValue(formattedDisplayValue);
    setPriceValue(numericValue);
    valueFinalized(numericValue); // Invoke the callback with the numeric value
  };

  /**
   * Adjust the current price value by a given amount.
   * @param {number} adjustment - The amount to adjust the price by.
   */
  const adjustPenny = (adjustment: number) => {
    if (priceValue === 0 && adjustment < 0) {
      return
    }
    // If the price is negative, then we need to invert the logic of the + and = button so that + still technically increase the value.
    if(priceValue < 0) {
      adjustment = adjustment * -1;
    }
    
    // Math.round is to handle precision, hate it but until we use a decimal format for USD it is what it is...
    let numericValue = Math.round((priceValue + adjustment) * 10000) / 10000;
    setPriceValue(numericValue); // Update the numeric value

    // Format the new price value for display, removing trailing "00" if present
    let formattedDisplayValue = `$${Math.abs(numericValue).toFixed(4)}`;
    if (formattedDisplayValue.endsWith("00")) {
      formattedDisplayValue = formattedDisplayValue.slice(0, -2);
    }
    setDisplayValue(formattedDisplayValue);
    valueFinalized(numericValue); // Invoke the callback with the new numeric value
  };

  /**
   * Increase the price value by one penny (0.01).
   */
  const addPenny = () => adjustPenny(0.01);

  /**
   * Decrease the price value by one penny (0.01).
   */
  const subtractPenny = () => adjustPenny(-0.01);
  
  /**
   * Handle the 'Enter' key press event to blur the input field.
   * @param {React.KeyboardEvent<HTMLInputElement>} e - The keyboard event.
   */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && priceInputNumberRef.current) {
      priceInputNumberRef.current.blur();
    }
  };

  // Render the component UI
  return (
    <div className="spinner text-left btn-block">
      <div className="rc-input-number">
        <div className="rc-input-number-handler-wrap">
          <button
            disabled={disabled}
            style={{
              padding: '2px',
              cursor: complete ? 'pointer' : 'not-allowed'
            }}
            onClick={addPenny}
            unselectable="on"
            role="button"
            aria-label="Increase Value"
            aria-disabled="false"
            className="rc-input-number-handler rc-input-number-handler-up">
            <span unselectable="on" className="rc-input-number-handler-up-inner"></span>
          </button>
          <button
            disabled={disabled}
            style={{
              padding: '2px',
              cursor: complete ? 'pointer' : 'not-allowed'
            }}
            onClick={subtractPenny}
            unselectable="on"
            role="button"
            aria-label="Decrease Value"
            aria-disabled="false"
            className="rc-input-number-handler rc-input-number-handler-down"
          >
            <span unselectable="on" className="rc-input-number-handler-down-inner"></span>
          </button>
        </div>
        <div className="rc-input-number-input-wrap">
          <input
            role="spinbutton"
            placeholder={placeholder}
            style={{
              cursor: complete ? 'text' : 'not-allowed'
            }}
            className="rc-input-number-input"
            disabled={disabled}
            type="text"
            onChange={onInputChanged}
            value={displayValue}
            onBlur={formatOnBlur}
            onKeyDown={handleKeyDown}
            ref={priceInputNumberRef}
            />
        </div>
      </div>
    </div>
  );
}