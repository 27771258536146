import { Middleware, Dispatch } from 'redux';
import { getType, ActionType } from 'typesafe-actions';
import dayjs from 'dayjs';
import _ from 'lodash';
import { RefreshTokenPath, ExpiredTokenCode } from '@tradingblock/types';
import { LocalStorageProvider, TokenStorageKey } from '@tradingblock/storage';
import { RootAction, Actions, SessionActions } from '../../actions';
import { DataState } from '../../state';
import { refreshTokenSelector } from '../../dataSelectors';
import { uiApi } from '../../../api/apiClientFactory';
import { Config } from '../../../../config';
import { verifyToken } from '../../../../utilities/auth';
import { ApiProvider } from '../../../../context/Api';

const MINIMUM_REFRESHTOKEN_INTERVAL_MS = 30000;

type RefreshTokenResponse = { accessToken: string };

const localStorageProvider = LocalStorageProvider({});

const timeout = {
  set: (timer: NodeJS.Timeout) => {
    (window as any).tokenRefreshTimeout = timer;
  },
  clear: () => {
    const existingTimer = (window as any).tokenRefreshTimeout;
    if (existingTimer) {
      clearTimeout(existingTimer);
    }
  },
};

const handleTokenReceived = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof Actions.receiveToken>
) => {
  const { exp } = action.payload;
  if (_.isNil(exp)) {
    console.debug('token doesnt have expiration');
    return;
  }
  const expiresAt = dayjs.unix(exp);
  const refreshAt = expiresAt.add(-2, 'minute');
  const refreshFromNow_seconds = refreshAt.unix() - dayjs(new Date()).unix();
  const timeoutMs =
    refreshFromNow_seconds <= MINIMUM_REFRESHTOKEN_INTERVAL_MS / 1000
      ? MINIMUM_REFRESHTOKEN_INTERVAL_MS
      : refreshFromNow_seconds * 1000;

  timeout.clear();
  console.debug('refreshing token in', timeoutMs, 'ms', { exp, now: dayjs(new Date()).unix() });

  timeout.set(
    setTimeout(async () => {
      dispatch(Actions.refreshToken());
    }, timeoutMs)
  );
};

const tryRefreshToken = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof Actions.refreshToken>
) => {
  const { token } = refreshTokenSelector(state);
  const env = Config.isVirtual ? 'virtual' : 'dashboard';

  if (token) {
    const client = uiApi(token, dispatch);
    // const request = fetch(`${Config.uiApi}/${RefreshTokenPath}`, { credentials: 'include', headers: { Authorization: token } });
    try {
      const tokenResponse = await client
        .authenticated()
        .post<{}, RefreshTokenResponse>(env === 'virtual' ? '/virtual' + RefreshTokenPath : RefreshTokenPath, {});
      if (tokenResponse) {
        const { accessToken } = tokenResponse;
        const tokenResult = await verifyToken(accessToken);
        if (!tokenResult) {
          console.warn('token found was invalid', accessToken);
          return null;
        }
        const { value, decoded } = tokenResult;

        await localStorageProvider.save(TokenStorageKey, value);

        dispatch(Actions.receiveToken({ ...decoded, apiToken: decoded.token, token: accessToken }));
      }
    } catch (err) {
      if (token) {
        const currentTokenIsValid = await verifyToken(token);
        if (_.isNil(currentTokenIsValid)) {
          dispatch(SessionActions.expired({ code: ExpiredTokenCode.Expired }));
        }
      }
      console.log('token refresh error', err);
    }
  }
};

const handleTokenExpiration = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof SessionActions.expired>
) => {
  //clearing refresh timeout
  timeout.clear();
  console.debug('stopping token refresh due to', action.type, ':', action.payload);

  await localStorageProvider.delete(TokenStorageKey);

  const api = ApiProvider(state, dispatch);
  const res = await api.auth.logout();
  console.debug('logged out of tb api', res);
};

export const TokenMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  try {
    const result = next(action);
    // state AFTER action is dispatched
    const nextState = getState();
    switch (action.type) {
      case getType(Actions.receiveToken): {
        handleTokenReceived(nextState, dispatch, action);
        break;
      }
      case getType(Actions.refreshToken): {
        tryRefreshToken(nextState, dispatch, action);
        break;
      }
      case getType(SessionActions.expired): {
        handleTokenExpiration(nextState, dispatch, action);
      }
    }

    return result;
  } catch (err) {
    console.error('QuotesMiddleware :: Caught an exception for action ', action, err);
  }
};
