import { getType } from 'typesafe-actions';
import _ from 'lodash';
import { Expiration } from '@tradingblock/types';
import { ExpirationStrikeActions, RootAction, OptionChainActions } from '../actions';
import { ExpirationState } from '../state';
import { InitialState } from '../initialState';
import { StrikeByExpirationSymbolMap, ExpirationStrike } from '../../../types';
import { expirationValue } from '../../../utilities/date';

export const expirations = (state = InitialState.expirations, action: RootAction): ExpirationState => {
  const getExistingSubstate = (symbol: string) => state[symbol] || { symbol };
  switch (action.type) {
    case getType(ExpirationStrikeActions.requestExpirationAction): {
      const { symbol } = action.payload;
      const existing = getExistingSubstate(symbol);
      //only set isFetching if expirations arent already loaded
      const isFetching = existing && existing.expirations && existing.expirations.length > 0 ? false : true;
      return {
        ...state,
        [symbol]: {
          ...existing,
          isFetching,
        },
      };
    }
    case getType(OptionChainActions.receiveOptionExpirations): {
      const { symbol, expirations } = action.payload;
      const expirationsWithMonthlyAndWeekly = _.reduce(
        expirations,
        (acc: Expiration[], exp: Expiration) => {
          return [...acc, exp];
        },
        []
      );
      const existing = getExistingSubstate(symbol);
      return {
        ...state,
        [symbol]: {
          ...existing,
          isFetching: false,
          expirations: expirationsWithMonthlyAndWeekly,
        },
      };
    }
    case getType(ExpirationStrikeActions.receiveExpirations): {
      const { symbol, expirations } = action.payload;
      const expirationsWithMonthlyAndWeekly = _.reduce(
        expirations,
        (acc: Expiration[], exp: Expiration) => {
          // if (exp.type === ExpirationType.Monthly) {
          //   return [...acc, { ...exp, type: ExpirationType.Weekly }, exp];
          // }
          return _.orderBy([...acc, exp], v => v.date.valueOf());
        },
        []
      );
      const existing = getExistingSubstate(symbol);
      return {
        ...state,
        [symbol]: {
          ...existing,
          isFetching: false,
          expirations: expirationsWithMonthlyAndWeekly,
        },
      };
    }

    default:
      return state;
  }
};

const updateStrikeState = (
  state: {
    [symbol: string]: StrikeByExpirationSymbolMap;
  },
  symbol: string,
  expiration: Expiration,
  updateFunc: (strikeState: ExpirationStrike) => ExpirationStrike
) => {
  const expKey = expirationValue(expiration);
  const existingSymbol: StrikeByExpirationSymbolMap = _.find(state, (v, k) => (k === symbol ? true : false)) || {};
  const existing = _.map(existingSymbol, (v: ExpirationStrike) => v);
  const symbolExpState: ExpirationStrike = _.find<ExpirationStrike>(existing, (v: ExpirationStrike) =>
    v && v.symbol === symbol && expirationValue(v.expiration) === expKey ? true : false
  ) || { expiration, symbol, strikes: [], isFetching: false };
  return {
    ...state,
    [symbol]: {
      ...existingSymbol,
      [expKey]: updateFunc(symbolExpState),
    },
  };
};

export const strikes = (state = InitialState.strikes, action: RootAction) => {
  switch (action.type) {
    case getType(ExpirationStrikeActions.fetchStrikes): {
      const { symbol, expiration } = action.payload;
      return updateStrikeState(state, symbol, expiration, (subState: ExpirationStrike) => ({
        ...subState,
        isFetching: true,
        strikesLastLoaded: new Date(),
      }));
    }
    case getType(ExpirationStrikeActions.receiveStrikes): {
      const { strikes, symbol, expiration } = action.payload;
      return updateStrikeState(state, symbol, expiration, (subState: ExpirationStrike) => ({
        ...subState,
        isFetching: false,
        strikes,
      }));
    }

    default:
      return state;
  }
};
