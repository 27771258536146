import React, { useMemo } from 'react';
import { Column } from 'react-table';
import _ from 'lodash';
import {
  OrderLegDTO,
  OrderAction,
  PositionEffect,
  AssetType,
  TradingblockOrder,
  Order,
  OrderType,
  Durations,
} from '@tradingblock/types';
import { useDebug, formatNumber } from '@tradingblock/components';
import {
  useStateSelector,
  subAccountIdSelector,
  subAccountSelector,
  account as accountSel,
} from '../../../../data/global/dataSelectors';
import { optionToDetailString } from '../../../../data/global/utilities/options';
import { BlockTable } from '../../../shared/BlockTable';
import { PreviewActions } from './useOrderPreview';
import { PreviewLimitPrice } from './PreviewLimitPrice';
import { PreviewStopPrice } from './PreviewStopPrice';
import { PreviewOrderTotal } from './PreviewOrderTotal';
import { PreviewDebitOrCredit } from './PreviewDebitOrCredit';
import EstCommissionPreview from './EstCommissionPreview';
import { useQuoteMetadata } from '../../../../hooks/useFeedQuote';
import { QuoteSymbolQuote } from '../../../../data/global/state';
import { parseOccSymbol } from '@tradingblock/api';

export type ColumnWithStyle<D extends object> = Column<D> & {
  style?: object;
};

const getColumns = (quantity: number, underlyingSymbol?: string): ColumnWithStyle<OrderLegDTO & QuoteSymbolQuote>[] => {
  return [
    {
      Header: 'Instrument',
      id: 'instrument',
      accessor: (leg: OrderLegDTO) => {
        if (leg.AssetType === AssetType.Equity) {
          return 'Shares';
        }
        return optionToDetailString(
          { ...leg, Symbol: leg.OccSymbol || underlyingSymbol || '' },
          { Quantity: quantity, UnderlyingSymbol: underlyingSymbol }
        );
      },
    },
    {
      Header: 'Underlying',
      accessor: (leg: OrderLegDTO) => {
        return leg.UnderlyingSymbol ? leg.UnderlyingSymbol : leg.OccSymbol ? leg.OccSymbol : '';
      },
      id: 'symbol',
    },
    {
      Header: 'Symbol',
      accessor: (leg: OrderLegDTO) => {
        return leg.OccSymbol ? leg.OccSymbol : '';
      },
      id: 'occsymbol',
    },
    {
      Header: 'Action',
      accessor: (leg: OrderLegDTO) => {
        return `${OrderAction[leg.Action]} (to ${PositionEffect[leg.PositionEffect]})`;
      },
      id: 'action',
    },
    {
      Header: 'Qty',
      id: 'qty',
      accessor: (leg: OrderLegDTO) => {
        // To handle the case where the quantity is NaN, we return the quantity as 1x
        if (Number.isNaN(quantity) || leg.Quantity === undefined) {
          const quant = (leg.SpreadRatio || 1) * quantity;
          return `${quant.toString()}x`;
        }
        // if spread ratio is present then we multiply the quantity by the spread ratio
        // else we just return the quantity
        if (leg.SpreadRatio) {
          const quant = quantity * leg.SpreadRatio;
          return `${quant.toString()}x`;
        }

        const quant = leg.Quantity;
        return `${quant.toString()}x`;
      },
    },
    {
      Header: 'Close',
      id: 'close',
      accessor: leg => {
        if (leg.ClosePrice === undefined) {
          return '';
        }
        return `${formatNumber(leg.ClosePrice, { currency: true })}`;
      },
    },
    {
      Header: 'Last',
      id: 'last',
      accessor: leg => {
        if (leg.LastOrClosePrice === undefined) {
          return '';
        }
        return `${formatNumber(leg.LastOrClosePrice, { currency: true })}`;
      },
    },
    {
      Header: 'Bid',
      id: 'bid',
      accessor: leg => {
        if (leg.BidPrice === undefined || leg.BidSize === undefined) {
          return '';
        }
        return `${formatNumber(leg.BidPrice, { currency: true })} x ${leg.BidSize}`;
      },
    },
    {
      Header: 'Ask',
      id: 'ask',
      accessor: leg => {
        if (leg.AskPrice === undefined || leg.AskSize === undefined) {
          return '';
        }
        return `${formatNumber(leg.AskPrice, { currency: true })} x ${leg.AskSize}`;
      },
    },
  ];
};

const fields = (
  action: PreviewActions,
  initialPrice?: number,
  initialStopPrice?: number,
  accountName?: string,
  isCancelOrder?: boolean,
  orderType?: OrderType
) => [
  {
    id: 'price',
    title: action === 'replace' && !isCancelOrder ? 'Price (before editing)' : 'Price',
    value: (ord?: Order) => {
      const orderPrice = action !== 'replace' && ord ? ord.Price : initialPrice;
      const stopPrice = action !== 'replace' && ord ? ord.Stop : initialStopPrice;
      if (ord === undefined) {
        return 'NA';
      }
      switch (ord.OrderType) {
        case OrderType.Limit:
          return `Limit: ${orderPrice && formatNumber(Math.abs(orderPrice), { currency: true })}`;
        case OrderType.Stop_Limit:
          return (
            <div>
              <div>{`Stop Price: ${stopPrice && formatNumber(Math.abs(stopPrice), { currency: true })}`}</div>
              <div>{`Limit Price: ${orderPrice && formatNumber(Math.abs(orderPrice), { currency: true })}`}</div>
            </div>
          );
        case OrderType.Market:
          return 'Market';
        case OrderType.Stop_Market:
          return `Stop Market: ${ord.Stop && formatNumber(Math.abs(ord.Stop), { currency: true })}`;
        case OrderType.Market_On_Close:
          return `Market on Close`;
        default:
          return 'NA';
      }
    },
  },
  {
    id: 'effect',
    title: 'In effect',
    value: (ord?: Order) => {
      if (ord === undefined) {
        return '?';
      }
      if (ord.Duration === Durations.Day) {
        return 'Day';
      }
      return `${Durations[ord.Duration]}`;
    },
  },
  {
    id: 'commission',
    actions: ['execute'],
    title: 'Est. commission',
    value: (ord?: Order) => <EstCommissionPreview order={ord} />,
  },
  {
    id: 'account',
    title: 'Account',
    value: (ord?: Order) => accountName,
  },
  {
    id: 'total',
    actions: ['execute'],
    title: 'Est. total',
    value: (ord?: Order) => <PreviewOrderTotal order={ord} />,
  },

  {
    id: 'stopPrice',
    actions: ['replace'],
    title: !isCancelOrder && orderType === OrderType.Stop_Limit && 'Stop Price',
    value: (ord?: Order) => {
      return (
        !isCancelOrder &&
        ord !== undefined &&
        ord.OrderType === OrderType.Stop_Limit && <PreviewStopPrice action={action} />
      );
    },
  },
  {
    id: 'price',
    actions: ['replace'],
    title: !isCancelOrder && 'Limit Price',
    value: (ord?: Order) => !isCancelOrder && <PreviewLimitPrice action={action} />,
  },
  {
    id: 'debitOrCredit',
    actions: ['replace'],
    title: !isCancelOrder && 'Debit or Credit',
    value: (ord?: Order) => {
      return !isCancelOrder && ord !== undefined && <PreviewDebitOrCredit action={action} />;
    },
    Cell: (props: any) => {
      return <>{!isCancelOrder && <PreviewDebitOrCredit action={action} />}</>;
    },
  },
];

export const PreviewContent: React.FC<{
  order: TradingblockOrder | Order;
  legs: OrderLegDTO[];
  action: PreviewActions;
  isCancelOrder: boolean;
  initialPrice?: number;
  initialStopPrice?: number;
  orderValue?: Order;
}> = ({ order, legs, action, initialPrice, initialStopPrice, isCancelOrder, orderValue }) => {
  const { SubaccountId, Quantity, UnderlyingSymbol } = order;
  const quotes = useQuoteMetadata(legs.map((l: any) => (l.Symbol ? l.Symbol : '')));
  const tableColumns = useMemo(() => {
    let tmpCols;
    if (action === 'replace' && orderValue) {
      tmpCols = getColumns(orderValue.Quantity, orderValue.UnderlyingSymbol);
    } else {
      tmpCols = getColumns(Quantity, UnderlyingSymbol);
    }
    const widths = ['6%', '10%', '24%', '16%', '44%'];
    return tmpCols.map((c, ind) => {
      return {
        ...c,
        style: {
          ...(c.style || {}),
          width: widths[ind] || undefined,
        },
      };
    });
  }, [Quantity, UnderlyingSymbol, action, orderValue]);

  const firstAndLastName = useStateSelector(accountSel.firstAndLastName);
  const accountNickname = useStateSelector(accountSel.accountNickname);
  const subAccountId = useStateSelector(subAccountIdSelector);
  const subAccount = useStateSelector(subAccountSelector(SubaccountId || subAccountId));
  const formattedAccName = `${firstAndLastName} ${accountNickname}${subAccount ? ` - ${subAccount.AcctSuffix}` : ''}`;

  const previewFields = useMemo(
    () =>
      _.filter(
        fields(action, initialPrice, initialStopPrice, formattedAccName, isCancelOrder, order.OrderType),
        f => f.actions === undefined || f.actions.includes(action)
      ),
    [action, initialPrice, formattedAccName]
  );

  const quoteLegs = useMemo(
    () =>
      legs.map(leg => {
        return {
          ...leg,
          ...quotes.find(q => q.Symbol === (leg as any).Symbol),
        };
      }),
    [legs]
  );

  return (
    <>
      <BlockTable tableKey="preview-popup" columns={tableColumns} data={quoteLegs} loaded={true} />
      <ul className="props props-aligned props-aligned-wide">
        {_.map(previewFields, (field, ind) => {
          const fieldTitle = _.isFunction(field.title) ? field.title(order) : field.title;
          return (
            <li className="prop-inline" key={`field-${ind}`} id={`field-${ind}`}>
              <span className="mute prop-title">{fieldTitle}</span>
              <span className="prop-value">{field.value(order)}</span>
            </li>
          );
        })}
      </ul>
    </>
  );
};
