import { BlockType } from '@tradingblock/types';
import { ceil } from 'lodash';
/**
 * Calculates the minimum block size based on the block type, number of columns, and screen size.
 * @param {BlockType} type - The type of block.
 * @param {number} columns - The number of columns in the grid.
 * @param {SizeType} breakpoint - The current screen size breakpoint.
 * @returns An object containing the minimum height (h) and width (w) for the block.
 */

export var getMinBlockSize = function getMinBlockSize(type, columns, breakpoint) {
  // Calculate minWidth based on the breakpoint, adjusting for screen size
  var minWidth = breakpoint === 'lg' ? ceil(columns / 3) : breakpoint === 'md' ? ceil(columns / 2) : columns; // 100% width for small screens, 50% for medium screens, 33% for large screens

  switch (type) {
    case BlockType.Account:
      // Specific handling for 'Account' block type
      return {
        h: 2,
        w: Math.max(minWidth, 1)
      };

    default:
      // Default handling for other block types
      return {
        h: 5,
        w: Math.max(minWidth, 1)
      };
  }
};
/**
 * Determines the default block size based on type, columns, and breakpoint.
 * This function uses getMinBlockSize for calculating the minimum required size and adjusts it based on additional rules.
 * @param {BlockType} type - The type of block.
 * @param {number} columns - The number of columns in the grid.
 * @param {SizeType} breakpoint - The current screen size breakpoint.
 * @returns An object containing the adjusted height (h) and width (w) for the block.
 */

export var getDefaultBlockSize = function getDefaultBlockSize(type, columns, breakpoint, isInvalidLayout) {
  // Get the minimum block size for the given parameters
  var min = getMinBlockSize(type, columns, breakpoint);
  var size = {
    minH: min.h,
    minW: min.w
  };

  if (isInvalidLayout) {
    // for lg screens, we need to adjust the width to 50% if the layout is invalid
    if (breakpoint === 'lg') {
      return Object.assign({}, size, {
        h: 5,
        w: Math.max(ceil(columns / 2), min.w)
      });
    } // for anything smaller, we need to adjust the width to 100% if the layout is invalid


    return Object.assign({}, size, {
      h: 5,
      w: columns
    });
  } // Adjust width for screens smaller than 'lg'


  if (breakpoint !== 'lg') {
    switch (type) {
      case BlockType.Quote:
      case BlockType.Info:
      case BlockType.PriceChart:
        // Specific handling for Quote, Info, and PriceChart block types
        return Object.assign({}, size, {
          h: 5,
          w: min.w
        });

      case BlockType.Account:
        // Specific handling for Account block type
        return Object.assign({}, size, {
          h: 2,
          w: min.w
        });

      default:
        // Default handling for other block types on smaller screens
        return Object.assign({}, size, {
          h: 5,
          w: columns
        });
    }
  } // Handling for 'lg' (large) screens and above


  switch (type) {
    case BlockType.Account:
      // Specific handling for Account block type on larger screens
      return Object.assign({}, size, {
        h: 2,
        w: Math.max(ceil(columns / 3), min.w)
      });

    default:
      // Default handling for other block types on larger screens
      return Object.assign({}, size, {
        h: 5,
        w: Math.max(ceil(columns / 2), min.w)
      });
  }
};