import React, { useCallback } from 'react';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import { BooleanToggleType, AllBooleanToggleTypes, AccountDisclosuresUpdateValueProps } from '@tradingblock/types';
import { FormGroup, Modal, StepperField, TextboxField } from '@tradingblock/components';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { useDispatch } from 'react-redux';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import { AccountManagementDataActions } from '../../../data/global/actions/AccountManagementActions';
import { getRequiredError } from './Validation';
import { formatBoolean } from '../../../utilities/accountManagement';

export const AccountDisclosuresUpdate: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
}> = ({ show, toggleModal }) => {
  const dispatch = useDispatch();

  const accountId = useStateSelector(s => s.accounts.account && s.accounts.account.AccountId);

  const initialAccountDisclosuresUpdateValues: AccountDisclosuresUpdateValueProps = {
    foreignBank: undefined,
    foreignFinancialInstitution: undefined,
    foreignShellBank: undefined,
    proprietarySecuritiesAccount: undefined,
    disclosuresForeignBankUSAgent: '',
  };

  const onValidate = (values: AccountDisclosuresUpdateValueProps) => {
    const {
      foreignBank,
      foreignShellBank,
      foreignFinancialInstitution,
      proprietarySecuritiesAccount,
      disclosuresForeignBankUSAgent,
    } = values;

    if (!foreignBank || !foreignShellBank || !foreignFinancialInstitution || !proprietarySecuritiesAccount) {
      return {
        [`foreignBank`]: getRequiredError(foreignBank),
        [`foreignShellBank`]: getRequiredError(foreignShellBank),
        [`foreignFinancialInstitution`]: getRequiredError(foreignFinancialInstitution),
        [`proprietarySecuritiesAccount`]: getRequiredError(proprietarySecuritiesAccount),
        [`disclosuresForeignBankUSAgent`]: getRequiredError(disclosuresForeignBankUSAgent),
      };
    }

    if (foreignBank === 'yes' && !disclosuresForeignBankUSAgent) {
      return {
        [`disclosuresForeignBankUSAgent`]: getRequiredError(disclosuresForeignBankUSAgent),
      };
    }
  };

  const onSubmit = useCallback(
    (values, { resetForm }) => {
      const {
        foreignBank,
        foreignShellBank,
        foreignFinancialInstitution,
        proprietarySecuritiesAccount,
        disclosuresForeignBankUSAgent,
      } = values;

      let updateValues = {
        item: 'AccountDisclosures',
        accountDisclosures: {
          foreignBank: formatBoolean(foreignBank),
          foreignShellBank: formatBoolean(foreignShellBank),
          foreignFinancialInstitution: formatBoolean(foreignFinancialInstitution),
          proprietarySecuritiesAccount: formatBoolean(proprietarySecuritiesAccount),
        },
        disclosuresForeignBankUSAgent,
      };

      dispatch(AccountManagementDataActions.requestMakeAccountUpdate({ accountId, request: updateValues }));

      resetForm();
      toggleModal();

      // setTimeout(() => {
      //   dispatch(AccountManagementDataActions.requestAccountDetails({ accountId }));
      //   dispatch(AccountManagementDataActions.requestAccountManagementDetails({ accountId }));
      // }, 1000);
      // if (isUpdateLoaded && !isUpdateLoading) {
      //   dispatch(AccountManagementDataActions.requestAccountDetails({ accountId }));
      //   dispatch(AccountManagementDataActions.requestAccountManagementDetails({ accountId }));

      // }
    },
    [toggleModal]
  );

  return (
    <Formik initialValues={initialAccountDisclosuresUpdateValues} onSubmit={onSubmit} validate={onValidate}>
      {({ handleSubmit, values, resetForm }) => (
        <Modal setShow={() => toggleModal()} show={show}>
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Account Disclosures Update Request</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            <form>
              <p>Is this account maintained on behalf of a foreign bank?</p>
              <FormGroup>
                <Field
                  component={StepperField}
                  id="foreignBank"
                  options={AllBooleanToggleTypes}
                  defaultLabelId="boolean"
                />
              </FormGroup>
              {values.foreignBank === BooleanToggleType.Yes && (
                <>
                  <FormGroup>
                    <Field component={TextboxField} id="disclosuresForeignBankUSAgent" />
                  </FormGroup>
                </>
              )}
              <p>Is this account maintained on behalf of a foreign shell bank?</p>
              <FormGroup>
                <Field
                  component={StepperField}
                  id="foreignShellBank"
                  options={AllBooleanToggleTypes}
                  defaultLabelId="boolean"
                />
              </FormGroup>
              <p>Is this account maintained on behalf of a foreign financial institution?</p>
              <FormGroup>
                <Field
                  component={StepperField}
                  id="foreignFinancialInstitution"
                  options={AllBooleanToggleTypes}
                  defaultLabelId="boolean"
                />
              </FormGroup>
              <p>
                Is this account maintained on behalf of a broker or dealer’s proprietary securities account (PAB
                account)?
              </p>
              <FormGroup>
                <Field
                  component={StepperField}
                  id="proprietarySecuritiesAccount"
                  options={AllBooleanToggleTypes}
                  defaultLabelId="boolean"
                />
              </FormGroup>
            </form>
          </BootstrapModal.Body>
          <BootstrapModal.Footer className="modal-footer-justified">
            <Button
              variant="secondary"
              onClick={() => {
                resetForm();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={(values: any) => handleSubmit(values)}>
              Submit
            </Button>
          </BootstrapModal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
