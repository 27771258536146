import React, { useMemo, useState, useEffect } from 'react';
import { useStateSelector } from '../../data/global/dataSelectors';
import { DefaultAccountManagementDetails } from '@tradingblock/types';
import { DetailFields, DetailField } from '../../components/basic/DetailFields';
import { Loading } from '@tradingblock/components';
import {
  formatIncome,
  formatTaxBracket,
  formatTradesPerYear,
  formatYearsTrading,
} from '../../utilities/accountManagement';
import { FinancialUpdate } from './modals/FinancialUpdate';
import { useDispatch } from 'react-redux';
import { AccountManagementDataActions } from '../../data/global/actions/AccountManagementActions';
import { TradeExperienceUpdate } from './modals/TradeExperience';
import { SuitabilityUpdate } from './modals/SuitabilityUpdate';
import { formatTradeGoalType, TradeGoalUpdate } from './modals/TradeGoalUpdate';
import { FlexibilityTypeUpdate } from './modals/FlexibilityTypeUpdate';
import dayjs from 'dayjs';

export const FinancialDetails: React.FunctionComponent<{}> = () => {
  const accountManagementDetails = useStateSelector(s => s.accountManagement.accountManagementDetails.details);
  const isFetching = useStateSelector(s => s.accountManagement.accountManagementDetails.isFetching);
  const areDetailsLoaded = useStateSelector(s => s.accountManagement.accountDetails.isLoaded);
  const {
    characteristics,
    annualIncome,
    liquidNetWorth,
    totalNetWorth,
    investmentObjectiveType,
    experience,
    lastChanged,
    tradeGoalType,
    flexibilityType,
  } = accountManagementDetails || DefaultAccountManagementDetails;
  const selectedLinkedAccountId = useStateSelector(
    s => s.account.selectedLinkedAccount && s.account.selectedLinkedAccount.accountId
  );

  // Pending Account Management Update Requests
  const pendingAccountUpdates = useStateSelector(s => s.accountManagement.pendingAccountManagementUpdates.data);
  const pendingSuitabilityUpdates =
    pendingAccountUpdates && pendingAccountUpdates.filter(u => u.item === 'Suitability');
  const pendingTradeLevelUpdates = pendingAccountUpdates && pendingAccountUpdates.filter(u => u.item === 'TradeGoal');
  const pendingFlexabilityUpdates =
    pendingAccountUpdates && pendingAccountUpdates.filter(u => u.item === 'Flexability');
  const { risk, bracket, horizon, liquidity } = characteristics;
  const accountId = useStateSelector(s => s.account.accountId);

  const { bonds, options, stocks, commodities, margins } = experience;
  const { address, phone } = lastChanged;

  const [showAssetUpdate, setShowAssetUpdate] = useState(false);
  const [showExperienceUpdate, setShowExperienceUpdate] = useState(false);
  const [showSuitabilityUpdate, setShowSuitabilityUpdate] = useState(false);
  const [showTradeGoalUpdate, setShowTradeGoalUpdate] = useState(false);
  const [showFlexibilityTypeUpdate, setShowFlexibilityTypeUpdate] = useState(false);
  const updateData = useStateSelector(s => s.accountManagement.update.data);

  const toggleAssetUpdate = () => {
    showAssetUpdate ? setShowAssetUpdate(false) : setShowAssetUpdate(true);
  };

  const toggleExperienceUpdate = () => {
    showExperienceUpdate ? setShowExperienceUpdate(false) : setShowExperienceUpdate(true);
  };

  const toggleSuitabilityUpdate = () => {
    showSuitabilityUpdate ? setShowSuitabilityUpdate(false) : setShowSuitabilityUpdate(true);
  };

  const toggleTradeGoalUpdate = () => {
    showTradeGoalUpdate ? setShowTradeGoalUpdate(false) : setShowTradeGoalUpdate(true);
  };

  const toggleFlexibilityTypeUpdate = () => {
    showFlexibilityTypeUpdate ? setShowFlexibilityTypeUpdate(false) : setShowFlexibilityTypeUpdate(true);
  };

  const [assetType, setAssets] = useState('');
  const [updateType, setUpdateType] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (!areDetailsLoaded && accountId) {
      if (selectedLinkedAccountId) {
        dispatch(AccountManagementDataActions.requestAccountDetails({ accountId: selectedLinkedAccountId }));
        dispatch(AccountManagementDataActions.requestAccountManagementDetails({ accountId: selectedLinkedAccountId }));
      } else {
        dispatch(AccountManagementDataActions.requestAccountDetails({ accountId }));
        dispatch(AccountManagementDataActions.requestAccountManagementDetails({ accountId }));
      }
    }
  }, [dispatch, accountId, updateData, areDetailsLoaded, selectedLinkedAccountId]);

  const handleClick = (event: any) => {
    event.preventDefault();
    switch (event.target.id) {
      case 'financialInformation':
        setAssets(event.target.id);
        setShowAssetUpdate(true);
        break;
      case 'tradeExperience':
        setUpdateType(event.target.id);
        setShowExperienceUpdate(true);
        break;
      case 'suitability':
        setShowSuitabilityUpdate(true);
        break;
      case 'tradeGoalType':
        setShowTradeGoalUpdate(true);
        break;
      case 'flexibilityType':
        setShowFlexibilityTypeUpdate(true);
        break;
      default:
        break;
    }
  };

  const fields = useMemo(() => {
    let fieldList: DetailField[] = [];
    if (accountManagementDetails) {
      fieldList = [
        ...fieldList,
        {
          Value: () => (
            <>
              <i className="fal fa-money-bill-alt dashboard-icon" /> Financial Information{' '}
              <button className="mute txt-sm" id="financialInformation" onClick={event => handleClick(event)}>
                (Update)
              </button>
            </>
          ),
          className: 'fields-title',
        },
        {
          Value: () => (
            <>
              <i className="fal fa-piggy-bank dashboard-icon" /> Trade Experience{' '}
              <button className="mute txt-sm" id="tradeExperience" onClick={event => handleClick(event)}>
                (Update)
              </button>
            </>
          ),
          className: 'fields-title',
        },

        {
          Value: () => (
            <>
              <span className="prop-title">Annual Income</span>

              <span className="mute">{formatIncome(annualIncome)}</span>
            </>
          ),
        },

        {
          Value: () => (
            <>
              <span className="prop-title">Bonds</span>
              <span className="txt-sm">Years Trading:</span>{' '}
              <span className="mute txt-sm">{formatYearsTrading(bonds.years)}</span>{' '}
              {bonds.tradesPerYear && (
                <>
                  <br />
                  <span className="txt-sm">Trades per Year:</span>{' '}
                  <span className="mute txt-sm">{formatTradesPerYear(bonds.tradesPerYear)}</span>
                </>
              )}
            </>
          ),
        },

        {
          Value: () => (
            <>
              <span className="prop-title">Total Net Worth</span>
              <span className="mute">{formatIncome(totalNetWorth)}</span>{' '}
            </>
          ),
        },

        {
          Value: () => (
            <>
              <span className="prop-title">Options</span>
              <span className="txt-sm">Years Trading:</span>{' '}
              <span className="mute txt-sm">{formatYearsTrading(options.years)}</span>{' '}
              {options.tradesPerYear && (
                <>
                  <br />
                  <span className="txt-sm">Trades per Year:</span>{' '}
                  <span className="mute txt-sm">{formatTradesPerYear(options.tradesPerYear)}</span>
                </>
              )}
            </>
          ),
        },

        {
          Value: () => (
            <>
              <span className="prop-title">Liquid Net Worth</span>
              <span className="mute">{formatIncome(liquidNetWorth)}</span>{' '}
            </>
          ),
        },

        {
          Value: () => (
            <>
              <span className="prop-title">Stocks</span>
              <span className="txt-sm">Years Trading:</span>{' '}
              <span className="mute txt-sm">{formatYearsTrading(stocks.years)}</span>{' '}
              {stocks.tradesPerYear && (
                <>
                  <br />
                  <span className="txt-sm">Trades per Year:</span>{' '}
                  <span className="mute txt-sm">{formatTradesPerYear(stocks.tradesPerYear)}</span>
                </>
              )}
            </>
          ),
        },

        {
          Value: () => (
            <>
              <span className="prop-title">Tax Bracket</span>

              <span className="mute">{formatTaxBracket(bracket)}</span>
            </>
          ),
        },

        {
          Value: () => (
            <>
              <span className="prop-title">Commodities</span>
              <span className="txt-sm">Years Trading:</span>{' '}
              <span className="mute txt-sm">{formatYearsTrading(commodities.years)}</span>{' '}
              {commodities.tradesPerYear && (
                <>
                  <br />
                  <span className="txt-sm">Trades per Year:</span>{' '}
                  <span className="mute txt-sm">{formatTradesPerYear(commodities.tradesPerYear)}</span>{' '}
                </>
              )}
            </>
          ),
        },

        {
          Value: () => (
            <>
              <span className="prop-title">Investment Horizon</span>
              <span className="mute">{horizon ? horizon : 'N/A'}</span>{' '}
            </>
          ),
        },

        {
          Value: () => (
            <>
              <span className="prop-title">Margins</span>
              <span className="txt-sm">Years Trading:</span>{' '}
              <span className="mute txt-sm">{formatYearsTrading(margins.years)}</span>
              {margins.tradesPerYear && (
                <>
                  <br />
                  <span className="txt-sm">Trades per Year:</span>{' '}
                  <span className="mute txt-sm">{formatTradesPerYear(margins.tradesPerYear)}</span>
                </>
              )}
            </>
          ),
        },

        { Value: () => null },
      ];
    }
    return fieldList;
  }, [
    annualIncome,
    totalNetWorth,
    liquidNetWorth,
    investmentObjectiveType,
    risk,
    bracket,
    horizon,
    liquidity,
    bonds,
    options,
    stocks,
    address,
    phone,
  ]);

  const suitabilityFields = useMemo(() => {
    let fieldList: DetailField[] = [];
    if (accountManagementDetails) {
      fieldList = [
        ...fieldList,
        {
          Value: () => (
            <>
              <i className="fal fa-user-check dashboard-icon" /> Suitability Information{' '}
              <button className="mute txt-sm" id="suitability" onClick={event => handleClick(event)}>
                (Update)
              </button>
            </>
          ),
          className: 'fields-title',
        },
        {
          Value: () => (
            <>
              <i className="fal fa-bullseye dashboard-icon" /> Account Details
            </>
          ),
          className: 'fields-title',
        },
        {
          Value: () => (
            <>
              <span className="prop-title">Investment Objective</span>
              <span className="mute">
                {investmentObjectiveType
                  ? investmentObjectiveType.replace(/([A-Z])/g, ' $1').trim()
                  : 'No Investment Objective Type Found'}
              </span>{' '}
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="prop-title">
                Approved Trade Level{' '}
                <button className="mute txt-sm" id="tradeGoalType" onClick={event => handleClick(event)}>
                  (Update)
                </button>
              </span>
              <span className="mute">
                {tradeGoalType ? formatTradeGoalType(tradeGoalType) : 'No Trade Goal Type Found'}
              </span>{' '}
              <br />
              {pendingTradeLevelUpdates && pendingTradeLevelUpdates.length > 0 && (
                <span className="pending txt-sm">
                  <strong>Update Request Pending Admin Review</strong>
                  <br />
                  <strong>
                    {dayjs(pendingTradeLevelUpdates[pendingTradeLevelUpdates.length - 1].createDate).format(
                      'MM/DD/YYYY - hh:mm A'
                    )}
                  </strong>
                </span>
              )}
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="prop-title">Risk Tolerance</span>
              <span className="mute">{risk ? risk : 'N/A'}</span>{' '}
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="prop-title">
                Account Type{' '}
                <button className="mute txt-sm" id="flexibilityType" onClick={event => handleClick(event)}>
                  (Update)
                </button>
              </span>

              <span className="mute">
                {flexibilityType ? flexibilityType.replace(/([A-Z])/g, ' $1').trim() : 'No Flexibility Type Found'}
              </span>
              {pendingFlexabilityUpdates && pendingFlexabilityUpdates.length > 0 && (
                <>
                  <br />
                  <span className="pending txt-sm">
                    <strong>Update Request Pending Admin Review</strong>
                    <br />
                    <strong>
                      {dayjs(pendingFlexabilityUpdates[pendingFlexabilityUpdates.length - 1].createDate).format(
                        'MM/DD/YYYY - hh:mm A'
                      )}
                    </strong>
                  </span>
                </>
              )}
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="prop-title">Liquidity Preference</span>

              <span className="mute">
                {liquidity ? liquidity.replace(/([A-Z])/g, ' $1').trim() : 'No Liquidity Preference Found'}
              </span>

              {pendingSuitabilityUpdates && pendingSuitabilityUpdates.length > 0 && (
                <>
                  <br />
                  <br />
                  <span className="pending txt-sm">
                    <strong>Update Request Pending Admin Review</strong>
                    <br />
                    <strong>
                      {dayjs(pendingSuitabilityUpdates[pendingSuitabilityUpdates.length - 1].createDate).format(
                        'MM/DD/YYYY - hh:mm A'
                      )}
                    </strong>
                  </span>
                </>
              )}
            </>
          ),
        },
      ];
    }

    return fieldList;
  }, [
    liquidity,
    tradeGoalType,
    risk,
    flexibilityType,
    investmentObjectiveType,
    pendingAccountUpdates,
    pendingTradeLevelUpdates,
    pendingFlexabilityUpdates,
    pendingSuitabilityUpdates,
  ]);

  if (!areDetailsLoaded || isFetching || !accountManagementDetails) return <Loading />;

  return (
    <>
      <div className="screen-body-section">
        <DetailFields fieldList={fields} />
      </div>
      <div className="screen-body-section">
        <DetailFields fieldList={suitabilityFields} />
      </div>
      <FinancialUpdate toggleModal={toggleAssetUpdate} show={showAssetUpdate} assetType={assetType} />
      <TradeExperienceUpdate
        toggleModal={toggleExperienceUpdate}
        show={showExperienceUpdate}
        instrumentType={updateType}
      />
      <SuitabilityUpdate toggleModal={toggleSuitabilityUpdate} show={showSuitabilityUpdate} />
      <TradeGoalUpdate toggleModal={toggleTradeGoalUpdate} show={showTradeGoalUpdate} />
      <FlexibilityTypeUpdate toggleModal={toggleFlexibilityTypeUpdate} show={showFlexibilityTypeUpdate} />
    </>
  );
};
