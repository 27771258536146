import React from 'react';
import { ExpiredTokenCode } from '@tradingblock/types';
import { useActivityTimeout } from '@tradingblock/components';
import { useStateSelector } from '../../data/global/dataSelectors';
import { useDispatcher } from '../../data/global/hooks';
import { SessionExpiredModal } from '../../components/SessionExpiredModal';
import { SessionActions } from '../../data/global/actions';
import { ErrorModal } from '../../components/ErrorModal';

export const SessionWrapper: React.FC = ({ children }) => {
  const { dispatch } = useDispatcher();
  const authTimeout = useStateSelector(s => s.settings.authenticationTimeout);

  // use activity timeout to display inactive session notification
  useActivityTimeout(() => {
    dispatch(SessionActions.expired({ code: ExpiredTokenCode.Inactivity }));
  }, authTimeout);

  return (
    <>
      {children}
      <SessionExpiredModal />
      <ErrorModal />
    </>
  );
};
