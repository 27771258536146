import React, { useEffect, useState, useMemo } from 'react';
import { useStateSelector } from '../../data/global/dataSelectors';
import { useDispatch } from 'react-redux';
import { AccountManagementDataActions } from '../../data/global/actions/AccountManagementActions';
import { Loading } from '@tradingblock/components';
import { DetailFields, DetailField } from '../../components/basic/DetailFields';
import { IndividualRepresentationsUpdate } from './modals/IndividualRepresentations';
import { TradeAuthUpdate } from './modals/TradeAuthUpdate';
import { IndustryEmployedUpdate } from './modals/IndustryEmployedUpdate';
import {
  formatAgentName,
  formatIndustryEmployed,
  formatBooleanForDisplay,
  formatPhoneNumber,
  getAgentPhoneNumbersByType,
} from '../../utilities/accountManagement';
import { AccountDisclosuresUpdate } from './modals/AccountDisclosuresUpdate';
import { ServiceProfileUpdate } from './modals/ServiceProfileUpdate';
import dayjs from 'dayjs';
import { Country, TradeAuthorizationType } from '@tradingblock/types';
import { formatDateWithoutTime } from '../../utilities/date';
import { RemoveTrustedContactOrTradeAuth } from './modals/RemoveTrustedContactOrTradeAuth';

export const AccountDisclosures: React.FunctionComponent<{}> = () => {
  const accountId = useStateSelector(s => s.account.accountId);
  const isLoaded = useStateSelector(s => s.accountManagement.accountDetails.isLoaded);
  const accountManagementDetails = useStateSelector(s => s.accountManagement.accountManagementDetails.details);
  const isFetching = useStateSelector(s => s.accountManagement.accountManagementDetails.isFetching);
  const updateData = useStateSelector(s => s.accountManagement.update.data);

  const {
    accountHolders,
    tradingAuthorization,
    accountDisclosures,
    disclosuresForeignBankUSAgent,
    serviceProfile,
  } = accountManagementDetails;

  const {
    foreignBank,
    foreignFinancialInstitution,
    foreignShellBank,
    proprietarySecuritiesAccount,
  } = accountDisclosures;

  const { issuerDirectCommunication } = serviceProfile;
  const hasGrantedTradeAuth: boolean = tradingAuthorization !== null;
  const grantedTradeAuthPriorToUpdates: boolean = tradingAuthorization && tradingAuthorization.email === null;
  const agentName = tradingAuthorization && tradingAuthorization.firstName + ' ' + tradingAuthorization.lastName;

  // Pending Account Management Update Requests
  const pendingAccountUpdates = useStateSelector(s => s.accountManagement.pendingAccountManagementUpdates.data);
  const pendingIndustryEmployedUpdate =
    pendingAccountUpdates && pendingAccountUpdates.filter(u => u.item === 'IndustryEmployed');
  const pendingServiceProfileUpdates =
    pendingAccountUpdates && pendingAccountUpdates.filter(u => u.item === 'ServiceProfile');
  const selectedLinkedAccountId = useStateSelector(
    s => s.account.selectedLinkedAccount && s.account.selectedLinkedAccount.accountId
  );

  const dispatch = useDispatch();
  const [showIndividualRepUpdate, setShowIndividualRepUpdate] = useState(false);
  const [showTradeAuthUpdate, setShowTradeAuthUpdate] = useState(false);
  const [showIndustryEmployedUpdate, setShowIndustryEmployedUpdate] = useState(false);
  const [showAccountDisclosuresUpdate, setShowAccountDisclosuresUpdate] = useState(false);
  const [showServiceProfileUpdate, setShowServiceProfileUpdate] = useState(false);
  const [accountHolderIndex, setAccountHolderIndex] = useState<number>(0);
  const [showRemoveTradeAuth, setShowRemoveTradeAuth] = useState(false);

  const getAccountHolderName = (index: number) => {
    if (accountHolders[index].firstName && accountHolders[index].lastName) {
      return `${accountHolders[index].firstName} ${accountHolders[index].middleInitial || ''} ${
        accountHolders[index].lastName
      } ${accountHolders[index].suffix || ''}`;
    }
    return 'Name not found';
  };

  useEffect(() => {
    if (!isLoaded && accountId) {
      if (selectedLinkedAccountId) {
        dispatch(AccountManagementDataActions.requestAccountDetails({ accountId: selectedLinkedAccountId }));
        dispatch(AccountManagementDataActions.requestAccountManagementDetails({ accountId: selectedLinkedAccountId }));
      } else {
        dispatch(AccountManagementDataActions.requestAccountDetails({ accountId }));
        dispatch(AccountManagementDataActions.requestAccountManagementDetails({ accountId }));
      }
    }
  }, [dispatch, updateData, accountId, isLoaded, selectedLinkedAccountId]);

  const handleClick = (event: any) => {
    event.preventDefault();
    switch (event.target.id) {
      case 'tradeAuthorization':
        setShowTradeAuthUpdate(true);
        break;
      case 'industryEmployed-0':
        setAccountHolderIndex(0);
        setShowIndustryEmployedUpdate(true);
        break;
      case 'industryEmployed-1':
        setAccountHolderIndex(1);
        setShowIndustryEmployedUpdate(true);
        break;
      case 'accountDisclosures':
        setShowAccountDisclosuresUpdate(true);
        break;
      case 'individualReps-0':
        setAccountHolderIndex(0);
        setShowIndividualRepUpdate(true);
        break;
      case 'individualReps-1':
        setAccountHolderIndex(1);
        setShowIndividualRepUpdate(true);
        break;
      case 'serviceProfile':
        setShowServiceProfileUpdate(true);
        break;
      case 'removeTradeAuth':
        setShowRemoveTradeAuth(true);
        break;
      default:
        break;
    }
  };

  // Toggle Modal Functions
  const toggleIndividualRepUpdate = () => {
    setShowIndividualRepUpdate(!showIndividualRepUpdate);
  };

  const toggleTradeAuthUpdate = () => {
    setShowTradeAuthUpdate(!showTradeAuthUpdate);
  };

  const toggleIndustryEmployedUpdate = () => {
    setShowIndustryEmployedUpdate(!showIndustryEmployedUpdate);
  };

  const toggleAccountDisclosuresUpdate = () => {
    setShowAccountDisclosuresUpdate(!showAccountDisclosuresUpdate);
  };

  const toggleServiceProfileUpdate = () => {
    showServiceProfileUpdate ? setShowServiceProfileUpdate(false) : setShowServiceProfileUpdate(true);
  };

  const toggleRemoveTradeAuth = () => {
    showRemoveTradeAuth ? setShowRemoveTradeAuth(false) : setShowRemoveTradeAuth(true);
  };

  const tradeAuthFields = useMemo(() => {
    let fieldList: DetailField[] = [];

    if (accountManagementDetails) {
      fieldList = [
        ...fieldList,

        {
          Value: () => (
            <>
              <i className="fal fa-check-square dashboard-icon" /> Trade Authorization{' '}
              <button
                className="mute txt-sm"
                id="tradeAuthorization"
                onClick={event => handleClick(event)}
                hidden={hasGrantedTradeAuth}
              >
                (Update)
              </button>
            </>
          ),
          className: 'fields-title',
        },

        { Value: () => null },

        {
          Value: () => (
            <>
              <span className="prop-title">Third Party Trade Authorization Granted</span>
              <span className="mute">{hasGrantedTradeAuth ? 'Yes' : 'No'}</span>{' '}
            </>
          ),
        },

        {
          Value: () => (
            <>
              <span className="prop-title">Agent Name</span>

              <span className="mute">{formatAgentName(agentName, hasGrantedTradeAuth)}</span>
            </>
          ),
        },
      ];
    }

    if (hasGrantedTradeAuth && !grantedTradeAuthPriorToUpdates) {
      fieldList = [
        ...fieldList,
        {
          Value: () => (
            <>
              <span className="prop-title">Trade Authorization Type</span>
              <span className="mute">{tradingAuthorization.type}</span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="prop-title">Email</span>
              <span className="mute">{tradingAuthorization.email}</span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="prop-title">Address</span>
              <span className="mute">
                {tradingAuthorization.address && tradingAuthorization.address.address1
                  ? tradingAuthorization.address.address1
                  : 'N/A'}
              </span>
              <br />
              {tradingAuthorization.address && tradingAuthorization.address.address2 && (
                <>
                  <span className="mute">{tradingAuthorization.address.address2}</span>
                  <br />
                </>
              )}
              <span className="mute">
                {tradingAuthorization.address && tradingAuthorization.address.city
                  ? tradingAuthorization.address.city
                  : ''}
                ,{' '}
                {tradingAuthorization.address && tradingAuthorization.address.state
                  ? tradingAuthorization.address.state
                  : ''}{' '}
                {tradingAuthorization.address && tradingAuthorization.address.postalCode
                  ? tradingAuthorization.address.postalCode
                  : ''}
              </span>
              <br />
              <span className="mute">
                {tradingAuthorization.address && tradingAuthorization.address.country
                  ? tradingAuthorization.address.country
                  : 'N/A'}{' '}
              </span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="prop-title">Phone Numbers</span>
              <span className="txt-sm">
                <span>Home: </span>
                <span className="mute">
                  {formatPhoneNumber(getAgentPhoneNumbersByType('Home', tradingAuthorization))}
                </span>{' '}
              </span>
              <br />
              <span className="txt-sm">
                <span>Mobile: </span>
                <span className="mute">
                  {formatPhoneNumber(getAgentPhoneNumbersByType('Mobile', tradingAuthorization))}
                </span>{' '}
              </span>
              <br />
              <span className="txt-sm">
                <span>Work: </span>
                <span className="mute">
                  {formatPhoneNumber(getAgentPhoneNumbersByType('Work', tradingAuthorization))}
                </span>{' '}
              </span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="prop-title">Employment Type</span>
              <span className="mute">{tradingAuthorization.employmentType}</span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="prop-title">Employer Name</span>
              <span className="mute">{tradingAuthorization.employer ? tradingAuthorization.employer : 'N/A'}</span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="prop-title">Citizenship Country</span>
              <span className="mute">{tradingAuthorization.citizenshipCountry}</span>
              {tradingAuthorization.citizenshipCountry !== Country.UnitedStatesOfAmerica && (
                <>
                  <br />
                  <span className="prop-title">Foreign Tax ID Country</span>
                  <span className="mute">{tradingAuthorization.foreignTaxIdCountry}</span>
                  <br />
                  <span className="prop-title">Foreign Tax ID</span>
                  <span className="mute">{tradingAuthorization.foreignTaxIdNumber}</span>
                </>
              )}
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="prop-title">Date of Birth</span>
              <span className="mute">
                {tradingAuthorization.dateOfBirth
                  ? formatDateWithoutTime(tradingAuthorization.dateOfBirth)
                  : 'Date not found'}
              </span>
            </>
          ),
        },
        {
          Value: () => (
            <>
              <span className="prop-title">Disclosures</span>
              <span className="txt-sm">
                Is the agent employed by or a member of a registered broker-dealer, securities or futures exchange, or
                securities industry regulatory body (e.g., FINRA, NFA)?{' '}
                <span className="mute">
                  {tradingAuthorization.disclosures && tradingAuthorization.disclosures.industryEmployed ? 'Yes' : 'No'}
                </span>
              </span>

              {tradingAuthorization.disclosures && tradingAuthorization.disclosures.industryEmployed && (
                <>
                  <br />
                  <span className="txt-sm">
                    Firm name: <span className="mute">{tradingAuthorization.disclosuresIndustryEmployedFirmName}</span>
                  </span>
                </>
              )}
              <br />
              <br />
              <span className="txt-sm">
                Is the agent a 10% shareholder, director, and/or policy maker of a publicly-owned company?{' '}
                <span className="mute">
                  {tradingAuthorization.disclosures && tradingAuthorization.disclosures.stakeholder ? 'Yes' : 'No'}
                </span>
              </span>
              {tradingAuthorization.disclosures && tradingAuthorization.disclosures.stakeholder && (
                <>
                  <br />
                  <span className="txt-sm">
                    Ticker symbol:{' '}
                    <span className="mute">
                      {tradingAuthorization.disclosures && tradingAuthorization.disclosuresStakeholderTickerSymbol}
                    </span>
                  </span>
                </>
              )}
            </>
          ),
          hide: tradingAuthorization.type === TradeAuthorizationType.Limited,
        },
      ];
    }

    if (hasGrantedTradeAuth) {
      fieldList = [
        ...fieldList,

        {
          Value: () => (
            <>
              <button className="mute txt-sm" id="removeTradeAuth" onClick={event => handleClick(event)}>
                Remove
              </button>
            </>
          ),
          hide: tradingAuthorization === null,
        },
      ];
    }

    return fieldList;
  }, [tradingAuthorization]);

  const industryEmployedFields = useMemo(() => {
    let fieldList: DetailField[] = [];

    if (accountManagementDetails && !isFetching && isLoaded) {
      accountHolders.map((accountHolder, index) => {
        fieldList = [
          ...fieldList,

          {
            Value: () => (
              <>
                <i className="fal fa-university dashboard-icon" /> Industry Employed -{' '}
                <span className="mute">{getAccountHolderName(index)}</span>{' '}
                <button className="mute txt-sm" id={`industryEmployed-${index}`} onClick={event => handleClick(event)}>
                  (Update)
                </button>
              </>
            ),
            className: 'fields-title',
          },

          { Value: () => null },
          {
            Value: () => (
              <>
                <span className="prop-title">
                  Are you employed by or a member of a registered broker-dealer, securities or futures exchange, or
                  securities industry regulatory body (e.g., FINRA, NFA)?
                </span>

                <span className="mute">{formatBooleanForDisplay(accountHolder.disclosures.industryEmployed)}</span>
                {pendingIndustryEmployedUpdate && pendingIndustryEmployedUpdate.length > 0 && (
                  <>
                    <br />
                    <span className="pending txt-sm">
                      <strong>Update Request Pending Admin Review</strong>
                      <br />
                      <strong>
                        {dayjs(
                          pendingIndustryEmployedUpdate[pendingIndustryEmployedUpdate.length - 1].createDate
                        ).format('MM/DD/YYYY - hh:mm A')}
                      </strong>
                    </span>
                  </>
                )}
              </>
            ),
          },
          {
            Value: () => (
              <>
                <span className="prop-title">Industry Employed Firm Name</span>

                <span className="mute">
                  {formatIndustryEmployed(accountHolder.disclosuresIndustryEmployedFirmName)}
                </span>
              </>
            ),
          },
        ];
      });
    }
    return fieldList;
  }, [accountHolders, isFetching, isLoaded, pendingIndustryEmployedUpdate]);

  const disclosureFields = useMemo(() => {
    let fieldList: DetailField[] = [];
    if (accountManagementDetails && !isFetching && isLoaded) {
      accountHolders.map((accountHolder, index) => {
        fieldList = [
          ...fieldList,

          {
            Value: () => (
              <>
                <i className="fal fa-info dashboard-icon" /> Individual Representations -{' '}
                <span className="mute">{getAccountHolderName(index)}</span>{' '}
                <button className="mute txt-sm" id={`individualReps-${index}`} onClick={event => handleClick(event)}>
                  (Update)
                </button>
              </>
            ),
            className: 'fields-title',
          },

          { Value: () => null },

          {
            Value: () => (
              <>
                <span className="prop-title">
                  Are you a 10% shareholder, director, and/or policy maker of a publicly-owned company?
                </span>

                <span className="mute">{formatBooleanForDisplay(accountHolder.disclosures.stakeholder)}</span>
                {accountHolder.disclosures.stakeholder && (
                  <>
                    <br />

                    <span className="txt-sm">
                      Company ticker:{' '}
                      <span className="mute">
                        {accountHolder.disclosuresStakeholderTickerSymbol
                          ? accountHolder.disclosuresStakeholderTickerSymbol
                          : 'N/A'}
                      </span>
                    </span>
                  </>
                )}
              </>
            ),
          },

          {
            Value: () => (
              <>
                <span className="prop-title">Are you notified by the IRS that I am subject to backup withholding?</span>

                <span className="mute">{formatBooleanForDisplay(accountHolder.disclosures.irsWithholding)}</span>
              </>
            ),
          },

          {
            Value: () => (
              <>
                <span className="prop-title">
                  Are you a current or former senior public official (US or foreign), or immediate family relation to
                  such?
                </span>

                <span className="mute">{formatBooleanForDisplay(accountHolder.disclosures.govOfficial)}</span>
                {accountHolder.disclosures.govOfficial && (
                  <>
                    <br />
                    <span className="txt-sm">
                      Family Member(s):{' '}
                      <span className="mute">
                        {accountHolder.disclosuresGovOfficialImmediateFamily
                          ? accountHolder.disclosuresGovOfficialImmediateFamily
                          : 'N/A'}
                      </span>
                    </span>{' '}
                    <br />
                    <span className="txt-sm">
                      Organization:{' '}
                      <span className="mute">
                        {accountHolder.disclosuresGovOfficialPoliticalOrg
                          ? accountHolder.disclosuresGovOfficialPoliticalOrg
                          : 'N/A'}
                      </span>
                    </span>{' '}
                  </>
                )}
              </>
            ),
          },
          {
            Value: () => null,
          },
        ];
      });
    }
    return fieldList;
  }, [accountHolders, isFetching, isLoaded]);

  const accountDisclosureFields = useMemo(() => {
    let fieldList: DetailField[] = [];
    if (accountManagementDetails) {
      fieldList = [
        ...fieldList,

        {
          Value: () => (
            <>
              <i className="fal fa-info dashboard-icon" /> Account Disclosures{' '}
              <button className="mute txt-sm" id="accountDisclosures" onClick={event => handleClick(event)}>
                (Update)
              </button>
            </>
          ),
          className: 'fields-title',
        },

        { Value: () => null },

        {
          Value: () => (
            <>
              <span className="prop-title">Is this account maintained on behalf of a foreign bank?</span>
              <span className="mute">{formatBooleanForDisplay(foreignBank)}</span>{' '}
              {foreignBank && (
                <>
                  <br />
                  <span className="txt-sm">
                    U.S. agent for service of process:{' '}
                    <span className="mute">{disclosuresForeignBankUSAgent || 'Not applicable'}</span>
                  </span>
                </>
              )}
            </>
          ),
        },

        {
          Value: () => (
            <>
              <span className="prop-title">Is this account maintained on behalf of a foreign shell bank?</span>
              <span className="mute">{formatBooleanForDisplay(foreignShellBank)}</span>{' '}
            </>
          ),
        },

        {
          Value: () => (
            <>
              <span className="prop-title">
                Is this account maintained on behalf of a foreign financial institution?
              </span>
              <span className="mute">{formatBooleanForDisplay(foreignFinancialInstitution)}</span>{' '}
            </>
          ),
        },

        {
          Value: () => (
            <>
              <span className="prop-title">
                Is this account maintained on behalf of a broker or dealer’s proprietary securities account (PAB
                account)?
              </span>
              <span className="mute">{formatBooleanForDisplay(proprietarySecuritiesAccount)}</span>{' '}
            </>
          ),
        },
      ];
    }

    return fieldList;
  }, [
    foreignBank,
    foreignShellBank,
    foreignFinancialInstitution,
    proprietarySecuritiesAccount,
    disclosuresForeignBankUSAgent,
  ]);

  const serviceProfileFields = useMemo(() => {
    let fieldList: DetailField[] = [];
    if (serviceProfile) {
      fieldList = [
        ...fieldList,
        {
          Value: () => (
            <>
              <i className="fal fa-info dashboard-icon" /> Service Profile{' '}
              <button className="mute txt-sm" id="serviceProfile" onClick={event => handleClick(event)}>
                (Update)
              </button>
            </>
          ),
          className: 'fields-title',
        },
        // TODO: Develop a component to better handle table views. DetailField defaults to two-columns requiring null values for a one column display
        { Value: () => null },

        {
          Value: () => (
            <>
              <span className="prop-title">Disclosure of name and address on securities you own:</span>

              <span className="mute">{issuerDirectCommunication ? issuerDirectCommunication : 'N/A'}</span>
              {pendingServiceProfileUpdates && pendingServiceProfileUpdates.length > 0 && (
                <>
                  <br />
                  <span className="pending txt-sm">
                    <strong>Update Request Pending Admin Review</strong>
                    <br />
                    <strong>
                      {dayjs(pendingServiceProfileUpdates[pendingServiceProfileUpdates.length - 1].createDate).format(
                        'MM/DD/YYYY - hh:mm A'
                      )}
                    </strong>
                  </span>
                </>
              )}
            </>
          ),
        },
      ];
    }

    return fieldList;
  }, [issuerDirectCommunication, pendingServiceProfileUpdates]);

  if (!isLoaded || isFetching || !accountManagementDetails) return <Loading />;

  return (
    <>
      <div className="screen-body-section">
        <DetailFields fieldList={industryEmployedFields} />
      </div>
      <div className="screen-body-section">
        <DetailFields fieldList={disclosureFields} />
      </div>
      <div className="screen-body-section">
        <DetailFields fieldList={accountDisclosureFields} />
      </div>
      <div className="screen-body-section">
        <DetailFields fieldList={tradeAuthFields} />
      </div>
      <div className="screen-body-section">
        <DetailFields fieldList={serviceProfileFields} />
      </div>
      <IndividualRepresentationsUpdate
        toggleModal={toggleIndividualRepUpdate}
        show={showIndividualRepUpdate}
        accountHolderIndex={accountHolderIndex}
      />
      <TradeAuthUpdate toggleModal={toggleTradeAuthUpdate} show={showTradeAuthUpdate} />
      <IndustryEmployedUpdate
        toggleModal={toggleIndustryEmployedUpdate}
        show={showIndustryEmployedUpdate}
        accountHolderIndex={accountHolderIndex}
      />
      <AccountDisclosuresUpdate toggleModal={toggleAccountDisclosuresUpdate} show={showAccountDisclosuresUpdate} />
      <ServiceProfileUpdate toggleModal={toggleServiceProfileUpdate} show={showServiceProfileUpdate} />
      <RemoveTrustedContactOrTradeAuth
        toggleModal={toggleRemoveTradeAuth}
        show={showRemoveTradeAuth}
        type="removeTradeAuthorization"
      />
    </>
  );
};
