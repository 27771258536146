import createCachedSelector from 're-reselect';
import { DataState } from '../../../data/global/state';

export const getBidPriceOrLastPriceOrUndefined = (s: DataState, props: { symbol: string; strike?: number }) => {
  if (s.quotes.quoteMetadata.data[props.symbol] === undefined) {
    return undefined;
  }
  const { BidPrice, LastTradePrice } = s.quotes.quoteMetadata.data[props.symbol];
  if (BidPrice && BidPrice > 0) {
    return BidPrice;
  }
  return LastTradePrice;
};

export const OptionChainGlobalSelectors = {
  strikeMoreThanBid: createCachedSelector(
    [getBidPriceOrLastPriceOrUndefined, (s: DataState, props: { symbol: string; strike: number }) => props.strike],
    (bidOrLastPrice: number | undefined, strike: number) => {
      return bidOrLastPrice === undefined ? true : bidOrLastPrice > strike;
    }
  )((s: DataState, props: { symbol: string }) => `strikeMoreThanBid-${props.symbol}`),
  getBidPriceOrLastPriceOrUndefined: createCachedSelector(
    [getBidPriceOrLastPriceOrUndefined],
    (bidOrLastPrice: number | undefined) => bidOrLastPrice
  )((s: DataState, props: { symbol: string }) => `getBidPriceOrLastPriceOrUndefined-${props.symbol}`),
};
