import { Middleware, Dispatch } from 'redux';
import { getType, ActionType } from 'typesafe-actions';
import { DataState } from '../../../state';
import { RootAction, SessionActions } from '../../../actions';
import { AdminAlertActions } from '../../../actions/admin/AdminAlertActions';
import { ApiProvider } from '../../../../../context/Api';
import { AlertSearchRequestSelector } from '../../../selectors/admin/alertsSelector';

const loadAlertMessageTemplates = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AdminAlertActions.requestAlertTemplates>
) => {
  const api = ApiProvider(state, dispatch);
  if (api) {
    try {
      const response = await api.information.getAlertTemplates(action.payload);
      dispatch(AdminAlertActions.receiveAlertTemplates({ request: action.payload, response }));
    } catch (err) {
      console.error('requestAlertTemplates error', err);
      return err;
    }
  }
};

const createAlertMessage = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AdminAlertActions.requestCreateAlert>
) => {
  const api = ApiProvider(state, dispatch);
  if (api) {
    try {
      const response = await api.information.createAlert(action.payload);
      return response;
    } catch (err) {
      console.error('requestCreateAlert error', err);
      return err;
    }
  }
};

const updateAlertMessage = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AdminAlertActions.requestUpdateAlert>
) => {
  const api = ApiProvider(state, dispatch);
  if (api) {
    try {
      const { id, active, template, page, pageSize } = action.payload;
      const response = await api.information.updateAlert({ id, template, active });
      if (response.responseCode === 0) {
        dispatch(AdminAlertActions.requestAlertTemplates({ page, pageSize }));
      }
      return response;
    } catch (err) {
      console.error('requestToggleAlert error', err);
      return err;
    }
  }
};

export const AdminAlertsMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  try {
    const previousState = getState();

    const result = next(action);

    const nextState = getState();
    switch (action.type) {
      case getType(AdminAlertActions.requestAlertTemplates): {
        loadAlertMessageTemplates(nextState, dispatch, action);
        break;
      }
      case getType(AdminAlertActions.requestCreateAlert): {
        createAlertMessage(nextState, dispatch, action);
        break;
      }
      case getType(AdminAlertActions.requestUpdateAlert): {
        updateAlertMessage(nextState, dispatch, action);
        break;
      }
      case getType(AdminAlertActions.setAlertsPage):
      case getType(AdminAlertActions.setAlertsPageSize): {
        const prevRequestKey = AlertSearchRequestSelector(previousState);
        const { request, key } = AlertSearchRequestSelector(nextState);
        if (prevRequestKey.key !== key) {
          break;
        }
        dispatch(AdminAlertActions.requestAlertTemplates(request));
        break;
      }
    }
    return result;
  } catch (err) {
    console.error('AdminAlertsMiddleware error', err);
    throw err;
  }
};
