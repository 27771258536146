import _ from 'lodash';
import { Dispatch, Middleware } from 'redux';
import { getType } from 'typesafe-actions';
import { DataState } from '../../state';
import { RootAction } from '../../actions';
import { AccountActions } from '../../actions/AccountActions';
import { HistoryActions } from '../../actions/History';
import { ApiProvider } from '../../../../context/Api';
import { GetBalancesRequestParams } from '@tradingblock/api/src/commands/historyGetBalance';

// const tryGetBalanceHistory = async (state: DataState, dispatch: Dispatch<RootAction>, accountId?: number) => {
//   const api = ApiProvider(state, dispatch);

//   if (api) {
//     //TODO: add proper handling of a undefined accountId
//     if (accountId) {
//       await api.history.getBalanceHistory(accountId)
//         .then(res => {
//           dispatch(HistoryActions.receiveGetBalanceHistory(res));
//         })
//         .catch(err => dispatch(AccountActions.errorUserProfile(err)));
//     }
//   } else {
//     console.warn('not loading profile because either accountId or Token are undefined');
//   }
// };

const tryGetBalanceHistory = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  payload: GetBalancesRequestParams
) => {
  const api = ApiProvider(state, dispatch);

  if (api) {
    await api.history
      .getBalances(payload)
      .then(res => {
        dispatch(HistoryActions.receiveHistoryGetBalances(res));
      })
      .catch(err => dispatch(AccountActions.errorUserProfile(err)));
  }
};

export const HistoryMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  try {
    // state BEFORE action is dispatched
    const prevState = getState();
    const result = next(action);
    // state AFTER action is dispatched
    // const nextState = getState();
    switch (action.type) {
      case getType(HistoryActions.requestGetBalanceHistory):
        // tryGetBalanceHistory(prevState, dispatch, action.payload);
        break;
      case getType(HistoryActions.requestHistoryGetBalances):
        tryGetBalanceHistory(prevState, dispatch, action.payload);
        break;
    }

    return result;
  } catch (err) {
    console.error('accountMiddleware :: Caught an exception for action ', action, err);
  }
};
