import { Middleware, Dispatch } from 'redux';
import { getType, ActionType } from 'typesafe-actions';
import { DataState } from '../../state';
import { RootAction, SessionActions } from '../../actions';
import { AccountManagementDataActions } from '../../actions/AccountManagementActions';
import { ApiProvider } from '../../../../context/Api';

const loadAccountDetails = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AccountManagementDataActions.requestAccountDetails>
) => {
  const api = ApiProvider(state, dispatch);
  const { accountId } = action.payload;
  if (api) {
    try {
      const { payload } = await api.accounts.details(accountId);
      dispatch(AccountManagementDataActions.receiveAccountDetails({ accountDetails: payload }));
    } catch (err) {
      console.error('loadAccountDetails error', err);
      dispatch(AccountManagementDataActions.errorAccountDetails({ error: err, accountId: accountId }));
    }
  }
};

const updateAccount = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AccountManagementDataActions.requestMakeAccountUpdate>
) => {
  const api = ApiProvider(state, dispatch);
  const { accountId, request } = action.payload;
  if (api) {
    try {
      const response = await api.accounts.update(accountId, request);
      dispatch(AccountManagementDataActions.receiveMakeAccountUpdate({ response: response.payload }));
      if (response.responseCode === 0 || response.responseCode === 99) {
        dispatch(AccountManagementDataActions.requestAccountDetails({ accountId }));
        dispatch(AccountManagementDataActions.requestAccountManagementDetails({ accountId }));
        dispatch(AccountManagementDataActions.requestPendingAccountManagementUpdates({ accountId }));
      }
    } catch (err) {
      console.error('updateAccount Error', err);
      dispatch(AccountManagementDataActions.errorMakeAccountUpdate({ error: err }));
    }
  }
};

const getAccountUpdate = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AccountManagementDataActions.requestAccountUpdate>
) => {
  const api = ApiProvider(state, dispatch);
  const { confirm } = action.payload;
  if (api) {
    try {
      const { payload } = await api.accounts.getUpdate(confirm);
      dispatch(AccountManagementDataActions.receiveAccountUpdate({ update: payload }));
    } catch (err) {
      console.error('getAccountUpdate Error', err);
      dispatch(AccountManagementDataActions.errorAccountUpdate({ error: err }));
    }
  }
};

const confirmAccountUpdate = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AccountManagementDataActions.requestConfirmUpdate>
) => {
  const api = ApiProvider(state, dispatch);
  const { confirm } = action.payload;
  if (api) {
    try {
      const response = await api.accounts.confirmUpdate(confirm);
      return response;
    } catch (err) {
      console.error('confirmAccountUpdate Error', err);
    }
  }
};

const getAccountManagementDetails = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AccountManagementDataActions.requestAccountManagementDetails>
) => {
  const api = ApiProvider(state, dispatch);

  if (api) {
    try {
      const response = await api.accounts.getUserAccountDetails(action.payload);
      dispatch(AccountManagementDataActions.receiveAccountManagementDetails({ response: response.payload }));
    } catch (err) {
      dispatch(AccountManagementDataActions.errorAccountManagementDetails({ error: err }));
      console.error('getAccountManagementDetails Error', err);
    }
  }
};

const getPendingAccountManagementUpdates = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AccountManagementDataActions.requestPendingAccountManagementUpdates>
) => {
  const api = ApiProvider(state, dispatch);
  const selectedLinkedAccountId = state.account.selectedLinkedAccount && state.account.selectedLinkedAccount.accountId;

  if (api) {
    try {
      const { payload } = await api.accountManagement.getPendingUpdates(
        selectedLinkedAccountId ? selectedLinkedAccountId : action.payload.accountId
      );
      dispatch(AccountManagementDataActions.receivePendingAccountManagementUpdates({ pendingItems: payload }));
    } catch (err) {
      dispatch(AccountManagementDataActions.errorPendingAccountManagementUpdates({ error: err }));
      console.error('getPendingAccountManagementUpdates Error', err);
    }
  }
};

const checkIsNoPassEnabled = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AccountManagementDataActions.requestCheckNoPassEnabled>
) => {
  const api = ApiProvider(state, dispatch);
  const { username } = action.payload;
  if (api) {
    try {
      const { payload } = await api.accountManagement.checkNoPassEnabled(username);
      dispatch(AccountManagementDataActions.receiveCheckNoPassEnabled({ response: payload }));
    } catch (err) {
      dispatch(AccountManagementDataActions.errorCheckNoPassEnabled({ error: err }));
      console.error('checkIsNoPassEnabled Error', err);
    }
  }
};

const getNoPassDevices = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AccountManagementDataActions.requestNoPassDevices>
) => {
  const api = ApiProvider(state, dispatch);
  if (api) {
    try {
      const { payload, responseCode } = await api.accountManagement.getNoPassDevices();
      if (responseCode === 0) {
        dispatch(AccountManagementDataActions.receiveNoPassDevices({ devices: payload }));
      } else if (responseCode === 119) {
        // retry after 2 seconds
        const timer = setTimeout(() => {
          dispatch(AccountManagementDataActions.requestNoPassDevices({}));
        }, 2000);
        return () => clearTimeout(timer);
      }
    } catch (err) {
      dispatch(AccountManagementDataActions.errorNoPassDevices({ error: err }));
      console.error('getNoPassDevices Error', err);
    }
  }
};

const deleteNoPassDevice = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AccountManagementDataActions.requestDeleteNoPassDevice>
) => {
  const api = ApiProvider(state, dispatch);
  const { deviceid } = action.payload;
  if (api) {
    try {
      await api.accountManagement.deleteNoPassDevice(deviceid);
      const { payload: devices } = await api.accountManagement.getNoPassDevices();
      dispatch(AccountManagementDataActions.receiveDeleteNoPassDevice({ devices: devices }));
    } catch (err) {
      dispatch(AccountManagementDataActions.errorDeleteNoPassDevice({ error: err }));
      console.error('deleteNoPassDevice Error', err);
    }
  }
};

const getAuthHistory = async (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  action: ActionType<typeof AccountManagementDataActions.requestAuthHistory>
) => {
  const api = ApiProvider(state, dispatch);
  if (api) {
    try {
      const { payload } = await api.accountManagement.getAuthHistory();
      dispatch(AccountManagementDataActions.receiveAuthHistory({ response: payload }));
    } catch (err) {
      dispatch(AccountManagementDataActions.errorAuthHistory({ error: err }));
      console.error('getAuthHistory Error', err);
    }
  }
};

export const AccountManagementMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  try {
    const previousState = getState();
    const result = next(action);
    const nextState = getState();
    switch (action.type) {
      case getType(AccountManagementDataActions.requestAccountDetails): {
        loadAccountDetails(nextState, dispatch, action);
        break;
      }
      case getType(AccountManagementDataActions.requestMakeAccountUpdate): {
        updateAccount(nextState, dispatch, action);
        break;
      }
      case getType(AccountManagementDataActions.requestAccountUpdate): {
        getAccountUpdate(nextState, dispatch, action);
        break;
      }
      case getType(AccountManagementDataActions.requestConfirmUpdate): {
        confirmAccountUpdate(nextState, dispatch, action);
        break;
      }
      case getType(AccountManagementDataActions.requestAccountManagementDetails): {
        getAccountManagementDetails(nextState, dispatch, action);
        break;
      }
      case getType(AccountManagementDataActions.requestPendingAccountManagementUpdates): {
        getPendingAccountManagementUpdates(nextState, dispatch, action);
        break;
      }
      case getType(AccountManagementDataActions.requestCheckNoPassEnabled): {
        checkIsNoPassEnabled(nextState, dispatch, action);
        break;
      }
      case getType(AccountManagementDataActions.requestNoPassDevices): {
        getNoPassDevices(nextState, dispatch, action);
        break;
      }
      case getType(AccountManagementDataActions.requestDeleteNoPassDevice): {
        deleteNoPassDevice(nextState, dispatch, action);
        break;
      }
      case getType(AccountManagementDataActions.requestAuthHistory): {
        getAuthHistory(nextState, dispatch, action);
        break;
      }
    }

    return result;
  } catch (err) {
    console.error('AccountManagementMiddleware :: Caught an exception for action ', action, err);
  }
};
