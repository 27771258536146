import { getType } from 'typesafe-actions';
import _ from 'lodash';
import {
  PrivateUserCashieringState,
  SuccessResponseCode,
  GenericError,
  CashieringSearchEntityWithKey,
  CashieringSearchEntitiesState,
  PrivateClientsState,
  ClientEntity,
  PrivateClientDataState,
} from '@tradingblock/types';
import { getIdNumber } from '@tradingblock/api';
import { InitialState } from '../../initialState';
import { AdminClientAction, AdminClientActions } from '../../actions/admin/AdminClientActions';
import { clientListRequestKey, clientListRequestToQueryString, getClientKey } from '../../utilities/accountUtilities';

const defaultQueryState = (queryString: string) => ({
  queryString,
  ids: [],
  isFetching: false,
  error: undefined,
});

const SearchError = (message: string, error: string): GenericError<string, string> => ({
  message,
  error,
});

export const privateClientReducer = (
  state: PrivateClientsState = InitialState.private.clients,
  action: AdminClientAction
): PrivateClientsState => {
  switch (action.type) {
    case getType(AdminClientActions.setPage):
    case getType(AdminClientActions.setPageSize):
    case getType(AdminClientActions.setSortBy):
    case getType(AdminClientActions.setSearch): {
      return {
        ...state,
        ui: {
          ...state.ui,
          ...action.payload,
        },
      };
    }
    case getType(AdminClientActions.clientRequest): {
      const requestkey = clientListRequestKey(action.payload);
      const existingQueryState =
        state.byQuery[requestkey] || defaultQueryState(clientListRequestToQueryString(action.payload));
      return {
        ...state,
        byQuery: {
          ...state.byQuery,
          [requestkey]: {
            ...existingQueryState,
            isFetching: true,
            error: undefined,
          },
        },
      };
    }
    case getType(AdminClientActions.clientReceive): {
      const { request, response } = action.payload;
      const querykey = clientListRequestKey(request);
      const existingQueryState = state.byQuery[querykey] || defaultQueryState(clientListRequestToQueryString(request));
      const { payload } = response || { payload: undefined };
      const data = payload && payload.data ? payload.data : [];
      const total = payload && payload.total ? payload.total : 0;
      const isErrored = response.responseCode !== SuccessResponseCode;
      const updatedEntities = _.reduce(
        data,
        (acc: PrivateClientDataState, value: ClientEntity) => {
          return {
            ...acc,
            [value.accountId]: value,
          };
        },
        state.clients
      );
      return {
        ...state,
        clients: updatedEntities,
        byQuery: {
          ...state.byQuery,
          [querykey]: {
            ...existingQueryState,
            isFetching: false,
            ids: _(existingQueryState.ids)
              .union(data.map(v => v.accountId))
              .uniq()
              .value(),
            total,
            error: isErrored
              ? SearchError('Cashiering search error', `Responsecode ${response.responseCode}`)
              : undefined,
          },
        },
      };
    }

    // case getType(AdminClientActions.clientError): {
    //   const error = action.payload;
    //   const { data } = error;
    //   if (data === undefined) {
    //     return state;
    //   }
    //   const id = getIdNumber(data.id);
    //   return {
    //     ...state,
    //     [entityType]: {
    //       ...state[entityType],
    //       [id]: {
    //         ...(state[entityType][id] || {}),
    //         isFetching: false,
    //         isSaving: false,
    //         isErrored: true,
    //         error,
    //       },
    //     },
    //   };
    // }

    default:
      return state || InitialState.private.clients;
  }
};
