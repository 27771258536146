import { getType, isActionOf, ActionType } from 'typesafe-actions';
import { Actions as ActActions } from '../../Activity/state/ActivityActions';
import _ from 'lodash';
import {
  Legs,
  OrderLegDTO,
  isCustomStrategy,
  OrderAction,
  AssetType,
  Order,
  StrikeRules,
  PositionInfo,
  DebitOrCredit,
  GroupOrderLeg,
  AssetSymbolInfo,
  Expiration,
  OrderValidationError,
  OrderBlockSettings,
  isKnownStrategyType,
  TradingStrategyNameOrCustom,
  Durations,
  OrderType,
} from '@tradingblock/types';
import {
  DeriveLegAssetType,
  CustomStrategy,
  getStrategy,
  isSymbolEqual,
  getDebitOrCredit,
  isString,
  AllStrategies,
  getDefaultOrderQuantity,
  Strategies,
} from '@tradingblock/api';
import { toArray } from '../../../utilities/data';
import { OrderStateFactory } from '../../BlockStateFactory';
import {
  OrderActions,
  Actions,
  OrderInitializeAction,
  addOrderLegFromGroupType,
  SetSymbolAndResetType,
  AllOrderActions,
} from './OrderActions';
import { OrderState, OrderBlockState, OrderQuote } from './OrderState';
import {
  isCalendarStrategy,
  getNextLegId,
  getLegPosition,
  getStrategiesForSymbol,
  defaultStrategyForSymbol,
  SymbolForLeg,
} from '../OrderUtilities';
import { isLegacyOrderGroupAction, isGroupHandlingAction } from './OrderActionUtility';
import { getOrderActionForCalendar } from './orderReducerUtilities';
import { orderstate } from './transforms/orderStateTransforms';
import { OrderPreviewReducer } from './reducers/OrderPreviewReducer';
import { blockReducer, isBlockAction } from '../../../components/blocks/BlockState';
import { BlockTablesReducer } from '../../../components/blocks/state/blockTable/BlockTableReducer';
import { BlockTableAction } from '../../../components/blocks/state/blockTable/BlockTableActions';
import { OrderToOrderState } from './OrderStateToOrder';
import dayjs from 'dayjs';
import { orderLegsToClassifiedStrategy } from '../../../data/global/utilities/strategyClassification';
import { useBidAsk } from '../components/useBidAsk';

export const DefaultOrderState = (
  orderSettings: OrderBlockSettings,
  strategy?: TradingStrategyNameOrCustom
): OrderState => ({
  validStrategies: AllStrategies,
  duration: Durations.Day,
  orderType: OrderType.Limit,
  legs: {},
  recalculatePrice: false,
  quantity: strategy ? getDefaultOrderQuantity(strategy, orderSettings) : 1,
  action: OrderAction.Buy,
  isDirty: false,
  positions: {},
  debitCredit: DebitOrCredit.Debit,
  quotes: {},
  preview: {
    enabled: false,
  },
  validation: {
    isValidating: false,
    errors: [],
  },
  tables: {},
});

const emptyValidation = {
  errors: [],
  message: undefined,
  valid: undefined,
  match: undefined,
  isValidating: false,
  field: undefined,
};

const emptyValidValidation = {
  ...emptyValidation,
  valid: true,
};

const updateAllLegs = (legs: Legs, update: (existing: OrderLegDTO) => Partial<OrderLegDTO>) => {
  return _.reduce(
    legs,
    (all, leg) => {
      const updatedLeg = update(leg);
      return {
        ...all,
        [leg.Id]: {
          ...leg,
          ...updatedLeg,
        },
      };
    },
    {}
  );
};

type OrderLegInfoDto = Omit<OrderLegDTO, 'PositionEffect'>;
export function reduceLeg<T extends OrderLegInfoDto>(positions: PositionInfo) {
  return (leg: T, assetSymbol: AssetSymbolInfo | undefined) => {
    return {
      ...leg,
      PositionEffect: getLegPosition(positions, leg, assetSymbol),
    };
  };
}

export function reduceLegs(positions: PositionInfo, assetSymbol: AssetSymbolInfo | undefined) {
  const reduce = reduceLeg(positions);
  return (legs: Legs) => {
    return _(legs)
      .keys()
      .reduce((acc: Legs, id: string) => {
        const existingLeg = acc[id];

        return existingLeg
          ? {
              ...acc,
              [id]: reduce(existingLeg, assetSymbol),
            }
          : acc;
      }, legs);
  };
}

export const BaseOrderStateReducer = (orderState: OrderBlockState, action: OrderActions): OrderBlockState => {
  const state = orderState;
  const legReducer = reduceLeg(state.data.positions);

  switch (action.type) {
    // I Do not like that we technically now how both activity reducer and order reducer here... but we do now...
    case getType(ActActions.setTimeframe): {
      const { startDate, endDate } = action.payload as {
        startDate: Date | undefined;
        endDate: Date | undefined;
      };
      let startDateValue = dayjs(startDate)
        .startOf('day')
        .toDate();
      let endDateValue = dayjs(endDate)
        .endOf('day')
        .toDate();
      if (startDateValue && endDateValue && dayjs(endDateValue).isBefore(startDateValue)) {
        endDateValue = dayjs(startDateValue)
          .endOf('day')
          .toDate();
        startDateValue = dayjs(endDateValue)
          .startOf('day')
          .toDate();
      }
      return {
        ...state,
        data: {
          ...state.data,
          startDate: startDateValue,
          endDate: endDateValue,
        },
      } as any;
    }
    case 'initializeData': {
      const dataState = state.data;
      const strat = dataState.strategy;
      let orderActionValue =
        strat && isCalendarStrategy(strat)
          ? getOrderActionForCalendar(dataState.legs, state.data.action)
          : state.data.action;
      return {
        ...state,
        data: {
          ...state.data,
          action: orderActionValue,
          legError: undefined,
          validation: {
            ...emptyValidation,
          },
        },
      };
    }
    case getType(Actions.clear): {
      return {
        ...state,
        data: {
          ...state.data,
          legs: {},
          legError: undefined,
          price: undefined,
          validation: {
            ...emptyValidation,
            isValidating: false,
          },
        },
      };
    }
    case 'startValidation': {
      return {
        ...state,
        data: {
          ...state.data,
          validation: {
            ...emptyValidation,
            isValidating: true,
          },
        },
      };
    }
    case 'finishValidation': {
      const { valid, match } = action.payload;
      const errors: OrderValidationError[] = _.filter(action.payload.errors, e => e !== undefined);
      const errMap: OrderValidationError[] = _.reduce(
        errors,
        (acc: OrderValidationError[], v: OrderValidationError): OrderValidationError[] => {
          const symbol = isString(v) ? undefined : _.get(v, 'symbol', undefined);
          const message = isString(v) ? v : v.message;
          const updatedError: OrderValidationError = v.type === 'OrderFieldError' ? { ...v, message, symbol } : v;
          return [...acc, updatedError];
        },
        []
      );
      const firstError = errMap ? _.first(errMap) : undefined;
      let message: string | undefined = undefined;
      let field: keyof Order | keyof OrderLegDTO | undefined = undefined;
      let legIds: string[] = [];
      if (firstError) {
        message = firstError.message;
        if (firstError.type === 'OrderFieldError') {
          const fieldVal = firstError.field.trim().toLowerCase() === 'strike' ? 'Strike' : (firstError.field as any);
          field = fieldVal;
        }
        if (valid === false && firstError.type === 'OrderLegError') {
          legIds = _.filter(state.data.legs, l => {
            const occSymbol = SymbolForLeg(l, state.data.symbol);
            return occSymbol === firstError.symbol;
          }).map(l => l.Id);
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          legError: undefined,
          validation: {
            errors,
            message,
            valid,
            match,
            field,
            isValidating: false,
            legIds,
          },
        },
      };
    }
    case getType(Actions.orderPlaced): {
      const result = {
        ...state,
      };
      return result;
    }
    case getType(Actions.removeLeg): {
      const { id } = action.payload;
      const legsWithoutCurrent = _.reduce(
        state.data.legs,
        (acc: Legs, value: OrderLegDTO, key: string) => {
          if (key === id) {
            return acc;
          }
          acc[key] = value;
          return acc;
        },
        {}
      );
      const legsStillExist = _.keys(legsWithoutCurrent).length > 0;
      return {
        ...state,
        data: {
          ...state.data,
          legs: legsWithoutCurrent,
          recalculatePrice: legsStillExist,
          price: legsStillExist ? state.data.price : undefined,
        },
      };
    }
    case getType(Actions.setSymbol): {
      const { symbol, options } = action.payload;
      const { settings } = options;

      const blockSettings = state.settings;
      if (isSymbolEqual(symbol, state.data.symbol)) {
        return state;
      }
      const combinedBlockSettings = { ...(settings || {}), ...(blockSettings || {}) };
      const resetOrder = options.defaultToEmptyCustomIfSymbolChanges === true;
      if (symbol === undefined && resetOrder) {
        const resetState = {
          ...state,
          data: {
            ...DefaultOrderState(combinedBlockSettings, state.data.strategy),
            isLoaded: true,
          },
        };
        return resetState;
      }
      let defaultStrategy = defaultStrategyForSymbol(symbol, blockSettings.defaultStrategy, settings);

      if (defaultStrategy === undefined) {
        return {
          ...state,
          data: {
            ...DefaultOrderState(combinedBlockSettings),
            isLoaded: true,
          },
        };
      }
      let legs = isKnownStrategyType(defaultStrategy) ? OrderStateFactory.Build(defaultStrategy, symbol).legs : {};
      if (resetOrder) {
        legs = {};
        defaultStrategy = 'Custom';
      }
      return {
        ...state,
        data: {
          ...state.data,
          legError: undefined,
          quantity: getDefaultOrderQuantity(defaultStrategy, combinedBlockSettings),
          price: undefined,
          recalculatePrice: true,
          action: OrderAction.Buy,
          symbol: symbol,
          strategy: defaultStrategy,
          legs,
          validStrategies: AllStrategies,
          validation: emptyValidValidation,
        },
      };
    }
    case getType(Actions.SetSymbolAndReset): {
      const payload = (action as SetSymbolAndResetType).payload;
      const symbol = payload.symbol;
      const leg = payload.leg;
      const legs: Legs = {};
      const strategy = defaultStrategyForSymbol(symbol, state.settings.defaultStrategy);
      return {
        ...state,

        data: {
          ...state.data,
          isDirty: true,
          legs: {
            ...legs,
            [leg.Id]: legReducer({ ...leg, AssetType: DeriveLegAssetType(leg) }, symbol),
          },
          symbol,
          strategy,
          legError: undefined,
          recalculatePrice: true,
          validation: {
            ...emptyValidation,
            isValidating: false,
          },
        },
      };
    }
    case getType(Actions.addOrderLegFromGroup): {
      const { leg } = (action as addOrderLegFromGroupType).payload;
      let legs = state.data.legs;
      const existingSymbol = state && state.data && state.data.symbol ? state.data.symbol : undefined;
      if (getType(Actions.addOrderLegFromGroup) === action.type) {
        if (!existingSymbol || (leg.UnderlyingSymbol && leg.UnderlyingSymbol !== existingSymbol.underlyingSymbol)) {
          console.warn('not adding leg, incorrect underlying symbol');
        }
      }

      // set leg error if already max # of custom legs
      if (_.size(legs) >= 4) {
        return orderstate(state)
          .withLegError('Maximum legs already added to order')
          .value();
      }

      return {
        ...state,

        data: {
          ...state.data,
          isDirty: true,
          legs: {
            ...legs,
            [leg.Id]: legReducer(
              {
                ...leg,
                AssetType: DeriveLegAssetType(leg),
              },
              existingSymbol
            ),
          },
          strategy: CustomStrategy.info.Name,
          legError: undefined,
          recalculatePrice: true,
          validation: {
            ...emptyValidation,
            isValidating: false,
          },
        },
      };
    }
    // this is the {type: 'initialize', ...} action!
    case getType(Actions.reset): {
      const { strategy, symbol, legs, quantity, settings, orderType } = (action as OrderInitializeAction).payload;
      const orderAction = action.payload.action;
      const legValues = _.reduce(
        legs,
        (all, l) => {
          return {
            ...all,
            [l.Id]: {
              ...l,
              UnderlyingSymbol: symbol ? symbol.underlyingSymbol : l.UnderlyingSymbol,
            },
          };
        },
        {}
      );
      const getCalendarLegAction = (_legs: Legs | { [legId: string]: GroupOrderLeg }) => {
        // Get id values for legs. leg_## is the format
        const legKeys = Object.getOwnPropertyNames(legs);
        // Compare dates. Choose the leg reference by their latest date. Acchtopess the leg using the OwnProperties lookup.
        if (legs[legKeys[0]].Expiration && legs[legKeys[1]].Expiration) {
          const laterLegIndex =
            new Date(legs[legKeys[0]].Expiration!.date) > new Date(legs[legKeys[1]].Expiration!.date)
              ? legKeys[0]
              : legKeys[1];
          // Return that legs action when updating the component
          return legs[laterLegIndex].Action;
        }
      };
      // Action buttons in positions/activities tables reset the order block with updatedState.
      // Previously, closing a long position incorrectly showed a debit when selling.
      // This fix resets the order block to reflect the typical debit/credit for the order action.
      // In unusual cases (like negative prices), debit/credit is recalculated after setting the price.
      let debitCredit = orderAction === OrderAction.Buy ? DebitOrCredit.Debit : DebitOrCredit.Credit;
      const updatedState = {
        ...state,
        data: {
          ...DefaultOrderState(settings),
          quantity,
          strategy,
          symbol,
          orderType: orderType,
          legs: legValues,
          validStrategies: AllStrategies,
          legError: undefined,
          isLoaded: true,
          recalculatePrice: true,
          price: undefined,
          debitCredit,
          action:
            strategy && strategy.includes('Calendar') && Object.keys(legs).length
              ? getCalendarLegAction(legs)
              : orderAction
              ? orderAction
              : OrderAction.Buy,
        },
      };
      return updatedState;
    }
    case getType(Actions.addOrderLeg):
    case getType(Actions.handleOrderGroupChange): {
      const { settings } = action.payload;
      const symbol = isLegacyOrderGroupAction(action) && action.payload.symbol ? action.payload.symbol : undefined;
      const validStrategies = getStrategiesForSymbol(symbol);
      let strategy = isGroupHandlingAction(action) ? action.payload.strategy : undefined;
      if (
        strategy === undefined ||
        _.find(validStrategies, vs => strategy && vs.info.Name === strategy) === undefined
      ) {
        strategy = defaultStrategyForSymbol(symbol, state.settings.defaultStrategy);
      }
      const orderLeg: GroupOrderLeg | undefined =
        isLegacyOrderGroupAction(action) && action.payload.leg ? action.payload.leg : undefined;
      const legAction = orderLeg && orderLeg ? orderLeg.Action : undefined;
      const orderAction = action.payload.action || legAction || state.data.action;
      const quantity = action.payload.quantity || state.data.quantity;
      const legId = orderLeg
        ? orderLeg.Id || orderLeg.UniqueId || getNextLegId(state.data.legs)
        : getNextLegId(state.data.legs);
      const groupSymbolChanged = isLegacyOrderGroupAction(action) && isSymbolEqual(symbol, state.data.symbol) !== true;
      const reinitialize = groupSymbolChanged;
      // reset legs if current strategy isn't custom
      const currentLegs = !state.data.strategy || state.data.strategy !== 'Custom' ? {} : state.data.legs;

      // what should asset type be?
      const assetType = AssetType.Option;

      // short circuit if leg already part of order
      if (!reinitialize && legId !== undefined && _.some(currentLegs, l => l.UniqueId === legId || l.Id === legId)) {
        return {
          ...state,
          data: {
            ...state.data,
            legError: undefined,
          },
        };
      }

      // set leg error if already max # of custom legs
      if (_.size(currentLegs) >= 4) {
        return {
          ...state,
          data: {
            ...state.data,
            legError: 'Maximum legs already added to order',
          },
        };
      }
      const newLeg: Omit<OrderLegDTO, 'PositionEffect'> | undefined = orderLeg
        ? {
            Id: legId,

            AssetType: assetType,
            ...orderLeg,
          }
        : undefined;
      let baseData = state.data;
      if (reinitialize && state.data.symbol !== symbol) {
        baseData = DefaultOrderState(settings);
      }
      const legs = newLeg
        ? {
            ...currentLegs,
            [legId]: legReducer(newLeg, symbol),
          }
        : currentLegs;
      const orderStrategy = strategy || state.data.strategy;
      const orderQuant = quantity
        ? quantity
        : orderStrategy
        ? getDefaultOrderQuantity(orderStrategy, state.settings)
        : 1;
      return {
        ...state,

        data: {
          ...baseData,
          isDirty: true,
          validStrategies: AllStrategies,
          action: orderAction,
          quantity: orderQuant,
          strategy: orderStrategy,
          symbol: symbol || state.data.symbol,
          legError: undefined,
          legs,
          // price: undefined,
          recalculatePrice: true,
          validation: {
            ...emptyValidation,
            isValidating: false,
          },
        },
      };
    }
    case getType(Actions.setStrategy): {
      const { legs, validStrategies } = state.data;
      const { symbol, strategy, reverseLegs, settings } = (action as ActionType<typeof Actions.setStrategy>).payload;
      const isCustom = isCustomStrategy(strategy);
      const orderAction = isCustom ? OrderAction.Buy : state.data.action;
      let updatedLegs = legs;
      const validStrats = validStrategies.length > 0 ? validStrategies : getStrategiesForSymbol(symbol);
      if (
        validStrats.length > 0 &&
        _.find(validStrats, vs => strategy && vs.info.Name === strategy.info.Name) === undefined
      ) {
        return state;
      }
      if (state.data.strategy && state.data.strategy === strategy.info.Name) {
        return state;
      }
      if (isCustom) {
        updatedLegs = {};
      } else if (symbol && !isCustomStrategy(strategy)) {
        updatedLegs = OrderStateFactory.Build(strategy, symbol, reverseLegs).legs;
      }
      const result = {
        ...state,
        data: {
          ...state.data,
          validStrategies: AllStrategies,
          strategy: strategy.info.Name,
          legError: undefined,
          price: undefined,
          stopPrice: undefined,
          recalculatePrice: true,
          isInitializing: true,
          legs: updatedLegs,
          quantity: getDefaultOrderQuantity(strategy.info.Name, settings),
          action: orderAction,
          validation: {
            ...emptyValidation,
            isValidating: false,
          },
        },
      };
      return result;
    }
    case getType(Actions.setOrderAction):
    case getType(Actions.setLegAction): {
      const { strategy, legs } = state.data;
      const { legId } = action.payload;
      const isCustom = strategy && isCustomStrategy(strategy);
      const orderAction = isCustom
        ? state.data.action
        : state.data.action === OrderAction.Buy
        ? OrderAction.Sell
        : OrderAction.Buy;
      const updatedLegs = _.reduce(
        legs,
        (acc: Legs, val: OrderLegDTO): Legs => {
          const inverseAction = val.Action === OrderAction.Buy ? OrderAction.Sell : OrderAction.Buy;
          let legAction = val.Action;
          if (legId && isCustom && val.Id === legId) {
            legAction = action.payload.action ? action.payload.action : inverseAction;
          } else if (!isCustom) {
            legAction = val.Action === OrderAction.Buy ? OrderAction.Sell : OrderAction.Buy;
          }
          return {
            ...acc,
            [val.Id]: legReducer({ ...val, Action: legAction }, state.data.symbol),
          };
        },
        {}
      );

      return {
        ...state,

        data: {
          ...state.data,
          isDirty: true,
          action: orderAction,
          legError: undefined,
          legs: {
            ...updatedLegs,
          },
          price: undefined,
        },
      };
    }

    case 'setStrike': {
      const { strike, legId } = action.payload;
      const { strategy } = state.data;
      const stateStrategy = strategy ? getStrategy(strategy) : undefined;
      let setAllStrikes =
        stateStrategy &&
        !isCustomStrategy(stateStrategy) &&
        toArray(stateStrategy.profile.Rules.Strike).includes(StrikeRules.SameStrikes);
      const legs = updateAllLegs(state.data.legs, existing => {
        const shouldUpdateLegStrike = setAllStrikes === true || existing.Id === legId;
        return legReducer(
          {
            ...existing,
            Strike: shouldUpdateLegStrike ? strike : existing.Strike,
          },
          state.data.symbol
        );
      });
      return {
        ...state,
        data: {
          ...state.data,
          recalculatePrice: true,
          legError: undefined,
          legs: {
            ...state.data.legs,
            ...legs,
          },
        },
      };
    }

    case getType(Actions.setExpiration): {
      const { legId } = action.payload;
      const expiration: Expiration = action.payload.expiration;
      const { strategy } = state.data;
      const stateStrategy = strategy ? getStrategy(strategy) : undefined;
      const isCalendar = stateStrategy && isCalendarStrategy(stateStrategy);
      const orderLegs = state.data.legs;
      let updateAllOptionLegs = stateStrategy && !isCustomStrategy(stateStrategy) && !isCalendar;
      const updatedLegs: Legs = _.reduce(
        orderLegs,
        (all, leg): Legs => {
          const legMatchesId = legId === leg.Id;
          const legIsOptionAndAllShouldUpdate = updateAllOptionLegs && leg.AssetType === AssetType.Option;
          const shouldUpdateExpiration = legMatchesId || legIsOptionAndAllShouldUpdate;
          const legExpiration = shouldUpdateExpiration ? expiration : leg.Expiration;
          return {
            ...all,
            [leg.Id]: legReducer(
              {
                ...leg,
                Expiration: legExpiration,
                Strike: shouldUpdateExpiration ? undefined : leg.Strike,
              },
              state.data.symbol
            ),
          };
        },
        {}
      );

      let orderActionValue = isCalendar ? getOrderActionForCalendar(updatedLegs, state.data.action) : state.data.action;

      return {
        ...state,

        data: {
          ...state.data,
          isDirty: true,
          action: orderActionValue,
          price: undefined,
          recalculatePrice: true,
          legError: undefined,
          legs: updatedLegs,
        },
      };
    }

    case 'setLegQuantity': {
      const { legId, quantity } = action.payload;
      const { legs } = state.data;
      const hasOneLeg = _.keys(legs).length === 1;
      return {
        ...state,

        data: {
          ...state.data,
          isDirty: true,
          legError: undefined,
          recalculatePrice: hasOneLeg ? false : true,
          legs: {
            ...state.data.legs,
            [legId]: legReducer(
              {
                ...(state.data.legs[action.payload.legId] || {}),
                SpreadRatio: hasOneLeg ? undefined : quantity,
              },
              state.data.symbol
            ),
          },
        },
      };
    }

    case 'setOptionType': {
      const { legId, optionType } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          recalculatePrice: true,
          legError: undefined,
          legs: {
            ...state.data.legs,
            [legId]: legReducer(
              {
                ...(state.data.legs[action.payload.legId] || {}),
                OptionType: optionType,
              },
              state.data.symbol
            ),
          },
        },
      };
    }
    case getType(Actions.setPositionInfo): {
      const positions = action.payload.info as PositionInfo;

      return {
        ...state,
        data: {
          ...state.data,
          positions,
          legs: reduceLegs(positions, state.data.symbol)(state.data.legs),
        },
      };
    }
    case getType(Actions.setQuantity): {
      const quantity = action.payload.quantity as number;
      return {
        ...state,

        data: {
          ...state.data,
          isDirty: true,
          quantity,
        },
      };
    }
    case getType(Actions.setPrice): {
      if (!isActionOf(Actions.setPrice, action)) {
        return state;
      } else {
        const { price, negativePrice } = action.payload;
        let priceValue = price;

        const currentDebitOrCredit = state.data.debitCredit;

        if (negativePrice && priceValue && priceValue > 0 && state.data.strategy === 'Custom') {
          priceValue = priceValue * -1;
        }

        const classifyStrategy = orderLegsToClassifiedStrategy(Object.values(state.data.legs) as any);

        const allSells = _.every(state.data.legs, l => l.Action === OrderAction.Sell);
        const allBuys = _.every(state.data.legs, l => l.Action === OrderAction.Buy);

        // if the strategy is a calendar, then we need to determine debit/credit based on the price and the legs to determine if the cost is positive or negative
        const isCustom = state.data.strategy === 'Custom';
        const isCalendarMatch = classifyStrategy.name.includes('Calendar') && state.data.strategy === 'Custom';
        const isCalendar = classifyStrategy.name.includes('Calendar') && state.data.strategy !== 'Custom';
        const latestExpiringLeg = _.maxBy(Object.values(state.data.legs), l => l.Expiration && l.Expiration.date);

        let debitValue;
        let orderPrice = priceValue || 0;

        if (isCalendar && latestExpiringLeg) {
          // Consolidate the logic for debit/credit when isCalendar is true
          debitValue =
            orderPrice > 0
              ? latestExpiringLeg.Action === OrderAction.Buy
                ? DebitOrCredit.Debit
                : DebitOrCredit.Credit
              : latestExpiringLeg.Action === OrderAction.Buy
              ? DebitOrCredit.Credit
              : DebitOrCredit.Debit;
        } else if (isCalendarMatch) {
          // Directly assign debit or credit based on the price value for isCalendarMatch
          debitValue = orderPrice > 0 ? DebitOrCredit.Debit : DebitOrCredit.Credit;
        }
        // Assuming the rest of the logic for setting `debitCreditValue` remains applicable only if `debitValue` is defined
        let debitCreditValue;
        if (debitValue !== undefined) {
          debitCreditValue = debitValue;
        } else {
          // Fallback or other logic if neither isCalendar nor isCalendarMatch conditions are met
          debitCreditValue =
            classifyStrategy.name.includes('Calendar') || (!allSells && !allBuys && classifyStrategy.name === 'Custom')
              ? currentDebitOrCredit
              : classifyStrategy.action === 'BUY'
              ? DebitOrCredit.Debit
              : DebitOrCredit.Credit;
        }
        // Only allow negative prices for calendar strategies or custom strategies that match the calendar strategy
        // If the price is negative and the strategy is not a calendar strategy, set the price to 0
        if (priceValue && priceValue < 0 && !isCalendar && !isCalendarMatch && !isCustom) {
          priceValue = 0;
        }

        return {
          ...state,
          data: {
            ...state.data,
            price: priceValue,
            debitCredit: debitCreditValue,
            recalculatePrice: false,
          },
        };
      }
    }
    case getType(Actions.setStopPrice): {
      return {
        ...state,
        data: {
          ...state.data,
          stopPrice: action.payload.price,
        },
      };
    }
    case getType(Actions.setDebitCredit): {
      const debitCredit = isActionOf(Actions.setDebitCredit, action) ? action.payload.debitCredit : undefined;
      const classifyStrategy = orderLegsToClassifiedStrategy(Object.values(state.data.legs) as any);
      const allSells = _.every(state.data.legs, l => l.Action === OrderAction.Sell);
      const allBuys = _.every(state.data.legs, l => l.Action === OrderAction.Buy);

      return {
        ...state,
        data: {
          ...state.data,
          debitCredit:
            classifyStrategy.name.includes('Calendar') || (!allSells && !allBuys && classifyStrategy.name === 'Custom')
              ? debitCredit
              : classifyStrategy.action === 'BUY'
              ? DebitOrCredit.Debit
              : DebitOrCredit.Credit,
        },
      };
    }
    case getType(Actions.setSubaccountId): {
      const subaccountId = action.payload.subaccountId;
      return {
        ...state,
        data: {
          ...state.data,
          subaccountId,
        },
      };
    }
    case getType(Actions.setOrder): {
      const order = action.payload.order;
      const orderState = OrderToOrderState(order);
      return {
        ...state,
        data: {
          ...state.data,
          ...orderState,
        },
      };
    }
    case getType(Actions.updateQuote): {
      const quotesArray = isActionOf(Actions.updateQuote, action) ? _.values(action.payload) : [];
      const quotes: Record<string, OrderQuote> = _.reduce(
        quotesArray,
        (acc, v) => {
          return {
            ...acc,
            [v.Symbol]: v,
          };
        },
        {}
      );
      return {
        ...state,
        data: {
          ...state.data,
          quotes,
        },
      };
    }
    case getType(Actions.removeQuote): {
      const key = isActionOf(Actions.removeQuote, action) ? action.payload : undefined;
      const quotes: Record<string, OrderQuote> = _(state.data.quotes)
        .filter(q => q.Symbol !== key)
        .reduce((acc: Record<string, OrderQuote>, v) => ({ ...acc, [v.Symbol]: v }), {});
      return {
        ...state,
        data: {
          ...state.data,
          quotes,
        },
      };
    }
    default:
      return state;
  }
};

export const OrderStateReducer = (initialOrderState: OrderBlockState, action: AllOrderActions) => {
  const baseBlockState = isBlockAction(action)
    ? blockReducer<OrderState, OrderBlockSettings>(initialOrderState, action)
    : initialOrderState;
  const intermediateState: OrderBlockState = BaseOrderStateReducer(baseBlockState, action as any);
  const orderState = OrderPreviewReducer(intermediateState, action);
  const finalOrderState = BlockTablesReducer(orderState, action as BlockTableAction);
  return finalOrderState;
};
