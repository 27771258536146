/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useMemo, useEffect, useState } from 'react';
import useMount from 'react-use/lib/useMount';
import useToggle from 'react-use/lib/useToggle';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import _ from 'lodash';
import { Mask, usePageNav, DashboardToggle, getClassNames, Chord, newId } from '@tradingblock/components';
import { DashboardDrawer } from '../../components/dashboard/DashboardDrawer';
import { DashboardMenu } from '../../components/dashboard/Menu';
import { DashboardNavigation } from '../../components/dashboard/DashboardNavigation';
import { DashboardContentWrapper } from '../../components/dashboard/DashboardContentWrapper';
import { DashboardWrapper } from '../../components/dashboard/DashboardWrapper';
import { useStateSelector, dashboardsSelectors } from '../../data/global/dataSelectors';
import { useDispatcher } from '../../data/global/hooks';
import { useDashboardSettings } from '../../hooks/useDashboardSettings';
import { BlockHotkeys } from '../../components/hotkeys/BlockHotkeys';
import { DashboardHotkeys } from '../../components/hotkeys/DashboardHotkeys';
import { GridHotkeys } from '../../components/hotkeys/GridHotkeys';
import { DashboardGrid } from '../../components/dashboard/DashboardGrid';
import { useDashboardDrawer } from '../../hooks/useDashboardDrawer';
import { AudioHotkeys } from '../../components/hotkeys/AudioHotkeys';
import { getCreateDashboardRequest } from '../../utilities/dashboard';
import { tryCreateDashboard } from '../../data/async/dashboards';
import { useDefaultDashboards } from '../../components/dashboard/hooks/useDefaultDashboards';
import { useStore } from '../../context/Storage';
import { EnvironmentHotkeys } from '../../components/hotkeys/EnvironmentHotkeys';
import { AlertMessage } from '../../components/notifications/AlertMessage';
import { ExpireTokenInStorage, SyncTabStorageKey, useLocalStorage } from '@tradingblock/storage';
import { StateSync } from '@tradingblock/types';

interface DashboardScreenRouteProps {
  dashboardid: string;
}

export const DashboardView: React.FC<RouteComponentProps<DashboardScreenRouteProps>> = ({ match }) => {
  const [menuOpen, toggleMenu] = useToggle(false);
  const [nav] = usePageNav();
  const [drawerIsOpen, setDrawerIsOpen, toggleDrawer] = useDashboardDrawer();
  const menuRef = useRef(null);

  const dashboardIdFromUrl = match.params.dashboardid;
  const token = useStateSelector(s => s.auth.uiApiToken);
  const dashboards = useSelector(dashboardsSelectors.dashboards);
  const dashboard = useSelector(dashboardsSelectors.dashboard);

  const { dispatcher } = useDispatcher();
  const store = useStore();

  const [defaultDashboards] = useDefaultDashboards();
  const [addingDefaultDashboard, setAddingDefaultDashboard] = useState(false);

  const redirectToDashboardId = useMemo(() => {
    return dashboard && dashboard.dashboardId;
  }, [dashboard]);

  useMount(() => {
    if (token) {
      dispatcher.dashboards.requestDashboards();
      dispatcher.favorites.request();
    }
  });
  useEffect(() => {
    if (dashboards && !dashboard) {
      // if dashboard list is empty, try to create default dashboard from first default dashboard
      if (_.isEmpty(dashboards)) {
        const firstDefaultDashboard = _.first(defaultDashboards);
        if (firstDefaultDashboard && !addingDefaultDashboard) {
          const newBoard = {
            ...firstDefaultDashboard,
            dashboardId: newId(),
          };
          const createRequest = getCreateDashboardRequest(newBoard);

          setAddingDefaultDashboard(true);
          tryCreateDashboard(store, createRequest).then(dashboardSaved => {
            if (!dashboardSaved) {
              console.warn('saving default dashboard failed! ', newBoard);
            }
            dispatcher.dashboards.setDashboard({ dashboard: { ...newBoard } });
            setAddingDefaultDashboard(false);
          });
        }
      } else {
        const next = _.find(dashboards, d => d.dashboardId === dashboardIdFromUrl) || dashboards[0];
        dispatcher.dashboards.setDashboard({ dashboard: next });
      }
    }
  }, [
    dashboards,
    dashboard,
    defaultDashboards,
    dashboardIdFromUrl,
    addingDefaultDashboard,
    store,
    dispatcher.dashboards,
  ]);

  useEffect(() => {
    // request grid of blocks every time current dashboard changes
    if (dashboard && dashboard.dashboardId === dashboardIdFromUrl) {
      dispatcher.block.requestBlocks();
    }
  }, [dashboard, dashboardIdFromUrl, dispatcher.grid]);

  const storage = useLocalStorage();
  useEffect(() => {
    const logoutOnClose = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      localStorage.setItem(SyncTabStorageKey, JSON.stringify({ sync: StateSync.SIGNOUT, createDate: Date.now() }));
      ExpireTokenInStorage(storage);
      dispatcher.settings.signout();
    };
    window.addEventListener('beforeunload', logoutOnClose);

    return () => {
      window.removeEventListener('beforeunload', logoutOnClose);
    };
  }, [storage, dispatcher.settings, localStorage]);

  //System Alerts

  const activeAlerts = useStateSelector(s => s.information.alerts.activeAlerts);

  const [showAlerts, setShowAlerts] = useState(false);
  const toggleShowAlerts = () => {
    showAlerts ? setShowAlerts(false) : setShowAlerts(true);
  };

  const alertViewed = document.cookie.includes('alertViewed');

  useEffect(() => {
    if (activeAlerts && activeAlerts.length > 0 && !alertViewed) {
      setShowAlerts(true);
    }
  }, [activeAlerts]);

  useEffect(() => {
    if (showAlerts) {
      document.cookie = 'alertViewed=true; max-age=+60*60*8+; path=/;';
      document.cookie = `alertIds=${activeAlerts.map(a => a.id)}; max-age=+60*60*8+; path=/;`;
    }
  }, [showAlerts, activeAlerts]);

  useEffect(() => {
    // Check for new active alerts and only expire alertViewed cookie if there are new alerts
    if (activeAlerts && activeAlerts.length > 0) {
      const activeAlertsIds = activeAlerts.map(a => a.id.toString());
      const cookieAlertIds = document.cookie.includes('alertIds')
        ? document.cookie.split('alertIds=')[1].split(';')[0]
        : '';
      const cookieAlertIdsAsArray = cookieAlertIds.split(',');
      const newAlertIds = _.difference(activeAlertsIds, cookieAlertIdsAsArray);

      if (newAlertIds.length > 0) {
        document.cookie = `alertViewed=true; max-age=0; path=/;`;
      }
      document.cookie = `alertIds=${activeAlertsIds}; max-age=+60*60*8+; path=/;`;
    }
    if (!activeAlerts || activeAlerts.length === 0) {
      document.cookie = `alertIds=; max-age=0; path=/;`;
    }
  }, [activeAlerts, alertViewed]);

  const dashboardClass = useMemo(() => {
    return getClassNames([
      drawerIsOpen ? 'dashboard-drawer-open' : undefined,
      menuOpen ? 'dashboard-menu-open' : undefined,
      nav.collapsed ? 'collapsed-nav' : undefined,
    ]);
  }, [drawerIsOpen, menuOpen, nav.collapsed]);

  if (redirectToDashboardId && redirectToDashboardId !== dashboardIdFromUrl) {
    return <Redirect to={`/${redirectToDashboardId}`} />;
  }

  return (
    <>
      <DashboardWrapper className={dashboardClass}>
        <Mask />

        <DashboardNavigation
          toggleDrawer={toggleDrawer}
          drawerIsOpen={drawerIsOpen}
          menuRef={menuRef}
          menuOpen={menuOpen}
          toggleMenu={toggleMenu}
        />

        <DashboardContentWrapper editClass="edit-mode" linkingClass="link-mode">
          <Hotkeys />
          <DashboardGrid />
          {!drawerIsOpen && <DashboardToggle />}
        </DashboardContentWrapper>

        <DashboardDrawer isOpen={drawerIsOpen} toggleIsOpen={() => setDrawerIsOpen(false)} />

        <div className="dashboard-menu" ref={menuRef}>
          <DashboardMenu toggleMenu={toggleMenu} />
        </div>
      </DashboardWrapper>
      <AlertMessage show={showAlerts} toggleModal={toggleShowAlerts} />
    </>
  );
};

const Hotkeys = () => {
  const { hotkeys } = useDashboardSettings();
  const modifier: Chord = useMemo(() => 'alt' as Chord, []);
  if (!hotkeys) {
    return null;
  }
  return (
    <>
      <AudioHotkeys modifier={modifier} />
      <BlockHotkeys modifier={modifier} />
      <DashboardHotkeys modifier={modifier} />
      <GridHotkeys modifier={modifier} />
      <EnvironmentHotkeys modifier={modifier} />
    </>
  );
};
