import { getType } from 'typesafe-actions';
import { Middleware, Dispatch } from 'redux';
import { DataState } from '../state';
import { RootAction, Actions, OrderActions, DataActions, BlockActions, FeedActions } from '../actions';
import { accountIdSelector, shouldLoadMorePositions } from '../dataSelectors';
import { accountApiFromState } from '../../../context/Api';
import { loadAccountOrders, handleOrderCancel } from './handlers/orders';
import { handleBlockDeletion, handleDashboardBlocksRequest } from './handlers/block';
import { handleFeedReconnect } from './handlers/feed';

export const loadPositions = (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  subaccountId?: number,
  more = false
) => {
  const accountId = accountIdSelector(state);
  const shouldLoad = shouldLoadMorePositions(state);
  // only fetch data if not loaded or fetching more
  const api = accountApiFromState(state);

  if (shouldLoad || more) {
    dispatch(DataActions.fetchingPositions({ accountId, subaccountId }));
    if (api) {
      api.positions().then(resp => {
        dispatch(DataActions.receivePositions(resp.payload));
      });
    } else {
      console.warn('not loading positions because either accountId or Token are undefined');
    }
  }
};

// reference https://redux.js.org/advanced/middleware/ for best practices
export const dataRequestMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = middleware => (
  next: Dispatch<RootAction>
) => (action: RootAction) => {
  // const log = useDebug('dataRequestMiddleware');
  try {
    const dispatch = middleware.dispatch;
    // state BEFORE action is dispatched
    const prevState = middleware.getState();
    const result = next(action);
    // state AFTER action is dispatched
    const nextState = middleware.getState();
    switch (action.type) {
      case getType(BlockActions.requestBlocks): {
        handleDashboardBlocksRequest(nextState, dispatch);
        break;
      }
      case getType(BlockActions.removeBlock): {
        handleBlockDeletion(nextState, dispatch, action.payload);
        break;
      }
      case getType(Actions.requestAccountOrders):
        loadAccountOrders(
          prevState,
          dispatch,
          action.payload.accountId,
          action.payload.subaccountId,
          action.payload.dateFrom,
          action.payload.dateTo,
          action.payload.includeOrderEvents,
          action.payload.pageSize,
          action.payload.pageIndex,
          action.payload.blockId,
          action.payload.isTradesTab,
          action.payload.workingOrdersOnly
        );
        break;
      case getType(DataActions.requestPositions):
        loadPositions(prevState, dispatch, action.payload.subaccountId, action.payload.more);
        break;
      case getType(OrderActions.requestCancel):
        handleOrderCancel(nextState, dispatch, action);
        break;
      case getType(FeedActions.connected):
        handleFeedReconnect(nextState, dispatch);
        break;
    }
    return result;
  } catch (err) {
    console.error('dataRequestMiddleware :: Caught an exception for action ', action, err);
  }
};

export const DataMiddleware = dataRequestMiddleware;
