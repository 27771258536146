import React, { useCallback, useEffect, useState } from 'react';
import { Modal, ModalProps, SelectDropdownField, TextboxField, formatNumber } from '@tradingblock/components';
import { Modal as BootstrapModal, Button, FormGroup } from 'react-bootstrap';
import { Subaccount, TransferPositionsPayload } from '@tradingblock/api/src/commands/subaccounts';
import * as Yup from 'yup';
import { useApi } from '../../context/Api';
import { useDispatch } from 'react-redux';
import { useStateSelector } from '../../data/global/dataSelectors';
import useSWRMutation from 'swr/mutation';
import { Field, Form, Formik } from 'formik';
import { DataActions } from '../../data/global/actions';
import { ResponseCodes } from '@tradingblock/types';
import dayjs from 'dayjs';

const transferPositionsSubAccountSchema = Yup.object<TransferPositionsPayload>().shape({
  destinationSubaccountId: Yup.number().required('Please select a destination account'),
  positionIds: Yup.array().required('Please select at least one position'),
});

export interface JournalAccountModelProps {
  setShow: ModalProps['setShow'];
  show: ModalProps['show'];
  initialSubAccountState: Subaccount;
}

export default function JournalAccountModel({ show, setShow, initialSubAccountState }: JournalAccountModelProps) {
  const api = useApi();
  const [errorState, setErrorState] = useState<string>('');
  const [destSubAccnts, setDestSubAccnts] = useState<Number[]>([]);
  const [destSubAccntLabels, setDestSubAccntLabels] = useState<{ [symbol: string]: string }>({});
  const [posArr, setPosArr] = useState<string[]>([]);
  const [posLabelArr, setPosLabelArr] = useState<{ [symbol: string]: string }>({});
  const { AccountId, Id, Nickname } = initialSubAccountState;
  const dispatch = useDispatch();
  const subAccounts = useStateSelector(s => s.account.subaccounts);
  const positions = useStateSelector(state => state.positions);

  const tranfserPositionsFetcher = useCallback(
    (key: any, { arg }) => {
      return api.subaccounts.transferPositions(arg);
    },
    [api]
  );

  const {
    data: transferPositionsResult,
    trigger: triggerTransferPositionsForSubAccounts,
    isMutating,
    error: transferPositionsForSubAccountRequestError,
  } = useSWRMutation('transfer-positions-sub-account', tranfserPositionsFetcher);

  const onSubmit = async (values: any) => {
    let result = await triggerTransferPositionsForSubAccounts(values as TransferPositionsPayload);
    if (result && result.responseCode === 0)
      dispatch(DataActions.requestPositions({ accountId: AccountId, more: true }, {}));
  };

  useEffect(() => {
    dispatch(DataActions.requestPositions({ accountId: AccountId, more: true }, {}));
  }, []);

  useEffect(() => {
    if (transferPositionsForSubAccountRequestError !== undefined) {
      setErrorState(transferPositionsForSubAccountRequestError.message);
    }
  }, [transferPositionsForSubAccountRequestError]);

  useEffect(() => {
    if (transferPositionsResult) {
      if (transferPositionsResult.responseCode !== 0) {
        if ((transferPositionsResult.payload as unknown) as string[])
          setErrorState(((transferPositionsResult.payload as unknown) as string[]).join('\n'));
        else if (ResponseCodes[transferPositionsResult.responseCode])
          setErrorState(ResponseCodes[transferPositionsResult.responseCode].description);
        else setErrorState('Error Code ' + transferPositionsResult.responseCode);
      } else {
        setShow(false);
      }
    }
  }, [transferPositionsResult]);

  useEffect(() => {
    if (subAccounts && subAccounts.subaccounts) {
      const filteredSA = subAccounts.subaccounts.filter(sa => sa.Id !== Id);
      if (filteredSA) {
        const filteredIds = filteredSA.map(sa => sa.Id);
        let nicknames = {};
        filteredSA.forEach(
          sa =>
            (nicknames = {
              ...nicknames,
              [sa.Id]: `${sa.Nickname} (${sa.Id})`,
            })
        );
        setDestSubAccntLabels({ ...destSubAccntLabels, ...nicknames });
        setDestSubAccnts(filteredIds);
      }
    }
  }, [subAccounts, Id]);

  useEffect(() => {
    if (positions && subAccounts && subAccounts.subaccounts) {
      const subAccountPositions = positions.positions.filter(pos => pos.SubaccountId && pos.SubaccountId === Id);
      const sortedArr = subAccountPositions.sort((a, b) => a.Symbol.localeCompare(b.Symbol));
      let displayLabelArr = {};
      const formatString = 'DDMMMYY h:mm A';
      sortedArr.forEach(pos => {
        const date = dayjs(pos.DateOpened);
        const isStock = pos.AssetType === 1;
        let optionDescArr = pos.Description.split(' ');
        return (displayLabelArr = {
          ...displayLabelArr,
          [pos.Id]: (
            <div style={isStock ? { color: '#00a2ff' } : {}}>
              {isStock ? (
                <>
                  {pos.Symbol}&nbsp;&nbsp;{`[${pos.OpenQuantity}]`}&nbsp;&nbsp;
                  {`( ${formatNumber(pos.OpenPrice, { currency: true })} )`}
                </>
              ) : (
                <>
                  {optionDescArr[0]}&nbsp;&nbsp;{optionDescArr[1]}&nbsp;&nbsp;{optionDescArr[2]}
                  &nbsp;{optionDescArr[3]}&nbsp;&nbsp;{optionDescArr[4]}&nbsp;&nbsp;
                  {`( ${formatNumber(pos.OpenPrice, { currency: true })} )`}
                </>
              )}
              <br />
              &nbsp;&nbsp;{date.format(formatString)}
            </div>
          ),
        });
      });
      setPosLabelArr({ ...posLabelArr, ...displayLabelArr });
      const optionArr = subAccountPositions.map(p => p.Id);
      setPosArr(optionArr);
    }
  }, [positions, subAccounts]);

  return (
    <Modal setShow={setShow} show={show} className="remove-position">
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>Add Journal</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <div style={{ color: 'red' }}>{errorState}</div>
        <Formik
          initialValues={
            {
              accountId: AccountId,
              subaccountId: Id,
              destinationSubaccountId: undefined,
              positionIds: [],
            } as TransferPositionsPayload
          }
          validationSchema={transferPositionsSubAccountSchema}
          onSubmit={onSubmit}
        >
          <Form translate={'yes'} id="journal-account-positions-form">
            <Field disabled component={TextboxField} id="accountId" placeholder="Account Id" />
            <Field
              disabled
              component={TextboxField}
              id="subaccountId"
              placeholder={Nickname ? `${Nickname}` : 'Subaccount Id'}
            />

            <FormGroup>
              <Field
                component={SelectDropdownField}
                id={'destinationSubaccountId'}
                label="Destination Account"
                rawOptionLabels={destSubAccntLabels}
                options={destSubAccnts}
                useRawOptions={true}
                errorImmediately={true}
              />
            </FormGroup>

            <FormGroup>
              <Field
                component={SelectDropdownField}
                id={'positionIds'}
                label="Positions"
                rawOptionLabels={posLabelArr}
                options={posArr}
                useRawOptions={true}
                errorImmediately={true}
                isMulti
              />
            </FormGroup>
          </Form>
        </Formik>
      </BootstrapModal.Body>
      <BootstrapModal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button variant="primary" form="journal-account-positions-form" type="submit" disabled={isMutating}>
          Save
        </Button>
      </BootstrapModal.Footer>
    </Modal>
  );
}
