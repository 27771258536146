import React, { useEffect, useRef } from 'react';
import { useApi } from '../../../../context/Api';
import _ from 'lodash';
import { formatDate } from '../../../../utilities/date';

export const CashieringDocReview: React.FunctionComponent<{ accountId: number; id: number }> = ({ accountId, id }) => {
  const imageDocumentsRef = useRef<HTMLDivElement>(null);
  const intermediaryImageDocumentsRef = useRef<HTMLDivElement>(null);
  const api = useApi();
  const [docsUploaded, setDocsUploaded] = React.useState<boolean>(false);
  const [pdfsUploaded, setPdfsUploaded] = React.useState<
    {
      id: number;
      fileUrl: string;
      base64: string;
      data: string;
    }[]
  >([]);

  useEffect(() => {
    if (imageDocumentsRef.current) {
      imageDocumentsRef.current.innerHTML = '';

      api.cashiering.documents
        .getId(accountId, id)
        .then(resp => {
          if (resp.responseCode === 0) {
            if (resp.payload) {
              setDocsUploaded(true);
            }
            const docId = resp.payload[0].documentId;

            if (docId) {
              api.cashiering.documents
                .getDocMetadata(accountId, id, docId)
                .then(res => {
                  if (imageDocumentsRef.current && res.responseCode === 0) {
                    imageDocumentsRef.current.id = `document-${docId}`;
                    imageDocumentsRef.current.innerHTML += `<span>${res.payload.documentName
                      .split(/(?=[A-Z])/)
                      .join(' ')}</span>`;
                    imageDocumentsRef.current.innerHTML += `<br />`;
                    imageDocumentsRef.current.innerHTML += `<span>${res.payload.imageName}</span>`;
                    imageDocumentsRef.current.innerHTML += `<br />`;
                    imageDocumentsRef.current.innerHTML += `<span>${formatDate(res.payload.takenOn)}</span>`;

                    api.cashiering.documents
                      .getDoc(accountId, id, docId)
                      .then((res: any) => {
                        if (res && res.toString().includes('data:application/pdf;') && imageDocumentsRef.current) {
                          const resArray = res.toString().split(',');
                          const data = resArray[0];
                          const base64 = resArray[1];

                          let file = new Blob([res], { type: 'application/pdf' });
                          let fileUrl = URL.createObjectURL(file);

                          setPdfsUploaded(current => [...current, { id: docId, fileUrl, base64, data }]);
                          imageDocumentsRef.current.innerHTML += `<br />`;
                          imageDocumentsRef.current.innerHTML += `<br />`;
                        } else {
                          if (imageDocumentsRef.current && res) {
                            imageDocumentsRef.current.innerHTML += `<br />`;
                            imageDocumentsRef.current.innerHTML += `<br />`;
                            imageDocumentsRef.current.innerHTML += `<img src="${res}" />`;
                          }
                        }
                      })
                      .catch(err => {
                        console.error(err);
                      });
                  }
                })
                .catch(err => {
                  console.error(err);
                });
            }

            const intermediaryDocId = resp.payload[1].documentId;

            if (intermediaryDocId) {
              api.cashiering.documents
                .getDocMetadata(accountId, id, intermediaryDocId)
                .then(res => {
                  if (intermediaryImageDocumentsRef.current && res.responseCode === 0) {
                    intermediaryImageDocumentsRef.current.id = `document-${intermediaryDocId}`;
                    intermediaryImageDocumentsRef.current.innerHTML += `<span>${res.payload.documentName
                      .split(/(?=[A-Z])/)
                      .join(' ')}</span>`;
                    intermediaryImageDocumentsRef.current.innerHTML += `<br />`;
                    intermediaryImageDocumentsRef.current.innerHTML += `<span>${res.payload.imageName}</span>`;
                    intermediaryImageDocumentsRef.current.innerHTML += `<br />`;
                    intermediaryImageDocumentsRef.current.innerHTML += `<span>${formatDate(
                      res.payload.takenOn
                    )}</span>`;

                    api.cashiering.documents
                      .getDoc(accountId, id, intermediaryDocId)
                      .then((res: any) => {
                        if (intermediaryImageDocumentsRef.current) {
                          if (
                            res &&
                            res.toString().includes('data:application/pdf;') &&
                            intermediaryImageDocumentsRef.current
                          ) {
                            const resArray = res.toString().split(',');
                            const data = resArray[0];
                            const base64 = resArray[1];

                            let file = new Blob([res], { type: 'application/pdf' });
                            let fileUrl = URL.createObjectURL(file);

                            setPdfsUploaded(current => [...current, { id: intermediaryDocId, fileUrl, base64, data }]);
                            intermediaryImageDocumentsRef.current.innerHTML += `<br />`;
                            intermediaryImageDocumentsRef.current.innerHTML += `<br />`;
                          } else {
                            intermediaryImageDocumentsRef.current.innerHTML += `<br />`;
                            intermediaryImageDocumentsRef.current.innerHTML += `<br />`;
                            intermediaryImageDocumentsRef.current.innerHTML += `<img src="${res}" />`;
                          }
                        }
                      })
                      .catch(err => {
                        console.error(err);
                      });
                  }
                })
                .catch(err => {
                  console.error(err);
                });
            }
          }
        })
        .catch(err => {
          console.warn('CashieringDocReview', err);
        });
    }
  }, [imageDocumentsRef, accountId]);

  const openPDF = (refId: string) => {
    const id = parseInt(refId.split('-')[1]);
    const pdf = _.find(pdfsUploaded, { id });
    let pdfWindow = window.open('');
    const src = pdf && 'data:application/pdf;base64,' + pdf.base64;
    pdfWindow &&
      pdfWindow.document.write(
        `<iframe src="${src}" width="100%" height="100%" name="Document Review" title="Document Review"></iframe>`
      );
  };

  const isDocPDF = (refId: string) => {
    const id = parseInt(refId.split('-')[1]);
    const doc = pdfsUploaded.filter(pdf => pdf.id === id);
    if (doc.length > 0) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="fields-title">Cashiering Docs</div>
      <div ref={imageDocumentsRef}>
        <i className="fas fa-spinner fa-spin" />
      </div>
      {imageDocumentsRef &&
        imageDocumentsRef.current &&
        imageDocumentsRef.current.id &&
        isDocPDF(imageDocumentsRef.current.id) && (
          <>
            <button
              onClick={() => imageDocumentsRef && imageDocumentsRef.current && openPDF(imageDocumentsRef.current.id)}
              className="btn btn-primary"
            >
              <i className="fal fa-download" /> View PDF
            </button>
            <br />
          </>
        )}

      <br />
      <div ref={intermediaryImageDocumentsRef}></div>
      {intermediaryImageDocumentsRef &&
        intermediaryImageDocumentsRef.current &&
        intermediaryImageDocumentsRef.current.id &&
        isDocPDF(intermediaryImageDocumentsRef.current.id) && (
          <>
            <button
              onClick={() =>
                intermediaryImageDocumentsRef &&
                intermediaryImageDocumentsRef.current &&
                openPDF(intermediaryImageDocumentsRef.current.id)
              }
              className="btn btn-primary"
            >
              <i className="fal fa-download" /> View PDF
            </button>
            <br />
          </>
        )}
      {!docsUploaded && (
        <div>
          <p className="error" style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
            NO DOCUMENTS UPLOADED
          </p>
        </div>
      )}
    </>
  );
};
