import { Dispatch } from 'redux';
import { Expirable, UserRole } from '@tradingblock/types';
import { DataState } from '../../state';
import { RootAction } from '../../actions';
import { Dispatcher } from '../../dispatcher';
import { accountIdSelector, subAccountIdSelector } from '../../dataSelectors';

const shouldFetch = <T>(state: Expirable<T>) => state.stale === true && state.isFetching !== true;

export const handleFeedReconnect = (state: DataState, dispatch: Dispatch<RootAction>) => {
  const dispatcher = Dispatcher(dispatch);
  const accountId = accountIdSelector(state);
  const subaccountId = subAccountIdSelector(state);
  const { positions, accountData, orders } = state;
  const { balances } = accountData;
  const accountRoles = state.account.profile && state.account.profile.current && state.account.profile.current.roles;
  const isIRA = state.account.isIRA;
  const isUGMA = state.account.isUGMA;
  const isPartnership = state.account.isPartnership;
  const selectedLinkedAccountId = state.account.selectedLinkedAccount && state.account.selectedLinkedAccount.accountId;

  if (shouldFetch(positions)) {
    dispatcher.data.positions.request(selectedLinkedAccountId ? selectedLinkedAccountId : accountId);
  }
  if (shouldFetch(balances)) {
    if (accountRoles !== undefined) {
      if (accountRoles.includes(UserRole.CashieringApproval) && !isIRA && !isUGMA && !isPartnership) {
        dispatcher.balances.requestPendingTransfers();
      }
    }
    dispatcher.balances.request();
  }
  if (shouldFetch(orders)) {
    dispatcher.order.request(selectedLinkedAccountId ? selectedLinkedAccountId : accountId, subaccountId);
  }
};
