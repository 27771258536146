import { getType } from 'typesafe-actions';
import _ from 'lodash';
import { Expiration, OptionPairWithSymbol } from '@tradingblock/types';
import { OptionChainActions, ExpirationStrikeActions, RootAction } from '../actions';
import { InitialState } from '../initialState';
import { expirationValue } from '../../../utilities/date';
import { OptionChainState, ExpirationOptionPairs, ExpirationOptionPair } from '../../../types';
import { changeCalculation } from '../../../utilities/position';

const expirationKey = (expiration: Expiration) => expirationValue(expiration);

export const optionchain = (state = InitialState.optionchain, action: RootAction): OptionChainState => {
  switch (action.type) {
    case getType(OptionChainActions.requestOptionExpirations): {
      const { blockId, symbol } = action.payload;
      return {
        ...state,
        [blockId]: {
          ...state[blockId],
          [symbol]: {},
        },
      };
    }
    case getType(ExpirationStrikeActions.receiveExpirations): {
      const { symbol, expirations } = action.payload;
      const optionChain = _.reduce(
        expirations,
        (acc, value) => {
          return {
            ...acc,
            [expirationKey(value)]: {
              symbol,
              expiration: value,
              pairs: [],
              isFetching: false,
              errored: false,
            },
          };
        },
        {}
      );
      const blockIds = _.keys(state).filter(k => k !== 'bySymbol');
      const updatedBlocksState = _.reduce(
        blockIds,
        (updatedState: OptionChainState, key: string) => {
          const blockState = state[key];
          if (blockState === undefined) {
            return {
              ...updatedState,
              [key]: {},
            };
          }
          const includesSymbol = _.keys(blockState).includes(action.payload.symbol);
          const existingBlockSymbolStateOptionChain = blockState[symbol] ? blockState[symbol] : undefined;
          const replaceExistingBlockOptionChain =
            existingBlockSymbolStateOptionChain && _.keys(existingBlockSymbolStateOptionChain).length > 0
              ? false
              : true;
          return {
            ...updatedState,
            [key]: includesSymbol
              ? {
                  ...blockState,
                  [symbol]: {
                    ...(replaceExistingBlockOptionChain
                      ? optionChain
                      : { ...existingBlockSymbolStateOptionChain, ...optionChain }),
                  },
                }
              : blockState,
          };
        },
        state
      );

      return {
        ...state,
        ...updatedBlocksState,
        bySymbol: {
          ...state.bySymbol,
          [symbol]: {
            ...(state.bySymbol[symbol] || {}),
            ...optionChain,
          },
        },
      };
    }
    case getType(OptionChainActions.receiveOptionExpirations): {
      const { blockId, symbol, expirations } = action.payload;
      const optionChain = _.reduce(
        expirations,
        (acc, value) => {
          return {
            ...acc,
            [expirationKey(value)]: {
              symbol,

              pairs: [],
              isFetching: false,
              errored: false,
            },
          };
        },
        {}
      );
      return {
        ...state,
        bySymbol: {
          ...state.bySymbol,
          [symbol]: {
            ...(state.bySymbol[symbol] || {}),
            ...optionChain,
          },
        },
        [blockId]: {
          ...state[blockId],
          [symbol]: {
            ...optionChain,
          },
        },
      };
    }

    case getType(OptionChainActions.requestOptionChain): {
      const { blockId, symbol, expirations } = action.payload;
      const existingState = state[blockId] && state[blockId][symbol];
      const optionchainState = _.reduce(
        expirations,
        (stateVal, exp) => {
          const key = expirationKey(exp);

          const expState = stateVal[key] || { symbol, expiration: exp, pairs: [] };
          return {
            ...stateVal,
            [key]: {
              ...expState,

              isFetching: true,
            },
          };
        },
        existingState || {}
      );
      return {
        ...state,
        bySymbol: {
          ...state.bySymbol,
          [symbol]: {
            ...(state.bySymbol[symbol] || {}),
            ...optionchainState,
          },
        },
        [blockId]: {
          ...state[blockId],
          [symbol]: {
            ...state[blockId][symbol],
            ...optionchainState,
          },
        },
      };
    }
    case getType(OptionChainActions.setStrikeCount): {
      const { blockId, symbol, strikeCount } = action.payload;
      const existingBlockSymbolState = state[blockId] && state[blockId][symbol] ? state[blockId][symbol] : {};
      const optionchainState = existingBlockSymbolState ? existingBlockSymbolState : {};

      const updatedOptionChain = _.reduce(
        optionchainState,
        (acc, value, key) => {
          const existingCount = value.pairs.length;
          const extra = strikeCount >= existingCount ? 0 : existingCount - strikeCount;
          const skip = _.round(extra / 2);

          return {
            ...acc,
            [key]: {
              ...value,
              pairs: _(value.pairs)
                .drop(skip)
                .take(skip)
                .value(),
            },
          };
        },
        {}
      );
      return {
        ...state,
        bySymbol: {
          ...state.bySymbol,
          [symbol]: {
            ...(state.bySymbol[symbol] || {}),
            ...updatedOptionChain,
          },
        },
        [blockId]: {
          ...state[blockId],
          [symbol]: {
            ...existingBlockSymbolState,
            ...updatedOptionChain,
          },
        },
      };
    }
    case getType(OptionChainActions.receiveOptionChain): {
      const { blockId, symbol, expiration, pairs } = action.payload;
      const optionchainState = state[blockId] && state[blockId][symbol] && state[blockId][symbol];
      const updatedSymbolSubstate: ExpirationOptionPairs = _(pairs).reduce(
        (acc: ExpirationOptionPairs, value: OptionPairWithSymbol) => {
          const key = expirationKey(expiration);
          const date = value.Expiry;
          const existing = optionchainState && optionchainState[key] ? optionchainState[key] : {};
          const currentExpirationPairs = _.filter(pairs, p => expirationKey(expiration) === key);
          const alteredExpirationPairs = currentExpirationPairs.map(currentPair => {
            //Alter change to use our frontend fromula
            //TODO: We need to make this calculation on the backend.
            // Calculation is now done on the backend, we are keeping the calculated values on a new property CalculatedChange
            const { change: callChange } = changeCalculation(
              currentPair.Call.Last,
              currentPair.Call.Close,
              currentPair.Call.Close,
              currentPair.Call.Bid,
              currentPair.Call.Ask,
              currentPair.underlyingSymbol,
              false
            );

            const { change: putChange } = changeCalculation(
              currentPair.Put.Last,
              currentPair.Put.Close,
              currentPair.Put.Close,
              currentPair.Put.Bid,
              currentPair.Put.Ask,
              currentPair.underlyingSymbol,
              false
            );
            return {
              ...currentPair,
              Call: {
                ...currentPair.Call,
                CalculatedChange: callChange,
              },
              Put: {
                ...currentPair.Put,
                CalculatedChange: putChange,
              },
            };
          });
          const updated: ExpirationOptionPair = {
            ...existing,
            symbol: value.symbol,
            underlyingSymbol: symbol,
            expiration: {
              ...expiration,
              date,
            },
            pairs: alteredExpirationPairs,
            isFetching: false,
            error: false,
          };
          return {
            ...optionchainState,
            [key]: updated,
          };
        },
        {}
      );
      const updatedSymbolState = {
        ...optionchainState,
        ...updatedSymbolSubstate,
      };
      return {
        ...state,
        bySymbol: {
          ...state.bySymbol,
          [symbol]: {
            ...state.bySymbol[symbol],
            ...updatedSymbolState,
          },
        },
        [blockId]: {
          ...state[blockId],
          [symbol]: {
            ...state[blockId][symbol],
            ...updatedSymbolState,
          },
        },
      };
    }
    case getType(OptionChainActions.requestOptionChainError): {
      const { blockId, symbol, expiration } = action.payload;
      const key = expirationValue(expiration);
      const optionchainState = state[blockId] && state[blockId][symbol];
      const expirationState = (optionchainState && optionchainState[key]) || { pairs: [], expiration, symbol };
      const existingSymbolState = state.bySymbol[symbol] || { [key]: {} };
      return {
        ...state,
        bySymbol: {
          ...state.bySymbol,
          [symbol]: {
            ...existingSymbolState,
            [key]: {
              ...existingSymbolState[key],
              errorMessage: 'Error requesting options',
              error: true,
              isFetching: false,
            },
          },
        },
        [blockId]: {
          ...state[blockId],
          [symbol]: {
            ...state[blockId][symbol],
            ...optionchainState,
            [key]: {
              ...expirationState,
              errorMessage: 'Error requesting options',
              error: true,
              isFetching: false,
            },
          },
        },
      };
    }

    default:
      return state;
  }
};
