import { PositionInfo } from '@tradingblock/types';
import _ from 'lodash';
import { areArraysEqual } from './data';

const toSortedValues = (info: PositionInfo) =>
  _(info)
    .map((v, k) => ({ symbol: k, ...v }))
    .orderBy(v => v.symbol)
    .value();

export const areEqual = (info: PositionInfo, info2: PositionInfo) => {
  const first = toSortedValues(info);
  const second = toSortedValues(info2);
  return areArraysEqual(first, second);
};
