import React from 'react';
import { CashieringRoute } from '@tradingblock/types';
import {
  CashieringViewFormValues,
  CashieringViewProps,
  ViewOnValidateType,
  ViewOnSubmitType,
} from './data/useCashieringView';
import { useCashieringAccountsList } from './components/AccountsList';

const ManageView: React.FC<CashieringViewProps<CashieringViewFormValues>> = ({ children, ...props }) => {
  const [TransferAccountsList] = useCashieringAccountsList();
  return (
    <>
      <div className="screen-body-section">
        <p className="fields-title">Update linked and saved accounts used for transfers</p>
        <TransferAccountsList {...props} route={CashieringRoute.Accounts} />
      </div>
      {children}
    </>
  );
};

export const useManageView = (): [typeof ManageView, ViewOnSubmitType, ViewOnValidateType] => {
  const [, onSubmit, onValidate] = useCashieringAccountsList();

  return [ManageView, onSubmit, onValidate];
};
