import React from 'react';

/**
 * @property {string} text Text to display for the selection
 * @property {string} selectedText Text to display when selected
 * @property {string} selected Determine if the button is selected
 * @property {() => void} onSelectedCallback Callback when the button is pressed
 * @property {"link" | "btn"} onSelectedCallback determine the type to display
 */
export interface AccountSelectionButton {
  text: string;
  selectedText?: string;
  selected?: boolean;
  onSelectedCallback: () => void;
  type?: 'link' | 'btn';
  disabled?: boolean;
}

/**
 * A button used to select accounts
 * @param {AccountSelectionButton} props
 * @returns {JSX.Element} Account Selection Button
 */
export default function AccountSelectionButton({
  text,
  selectedText,
  selected = false,
  onSelectedCallback,
  type = 'btn',
  disabled,
}: AccountSelectionButton) {
  return (
    <>
      {selected ? (
        <strong className="selected caps">
          <i className="fa fa-check" /> {selectedText}
        </strong>
      ) : (
        <button
          type="button"
          className={type === 'link' ? 'btn-link' : 'btn btn-outline-light'}
          onClick={onSelectedCallback}
          disabled={disabled}
        >
          {text}
        </button>
      )}
    </>
  );
}
