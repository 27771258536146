import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useMemo, useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { useElementPosition } from '.';
export var useScrollablePosition = function useScrollablePosition(parent, scrollableParent) {
  var originalPosition = useElementPosition(parent);

  var _useState = useState(originalPosition),
      _useState2 = _slicedToArray(_useState, 2),
      position = _useState2[0],
      setPosition = _useState2[1];

  var scrollableParents = useMemo(function () {
    // always use the dashboard-content scrollable element
    var scrollable = [document.getElementById('dashboard-content')]; // handle custom scrollable parent passed in

    if (scrollableParent) {
      if (!_.isString(scrollableParent)) {
        scrollable.push(scrollableParent);
      } else {
        // step up DOM tree to find scrollable parent by class name
        var nextParent = parent;

        while (nextParent && !nextParent.className.includes(scrollableParent)) {
          nextParent = nextParent.parentElement;
        }

        if (nextParent) {
          scrollable.push(nextParent);
        }
      }
    }

    return scrollable;
  }, [scrollableParent, parent]);
  var onScroll = useCallback(_.debounce(function () {
    // update position on scroll
    setPosition(useElementPosition(parent));
  }, 10), [parent]);
  useEffect(function () {
    _(scrollableParents).filter(function (s) {
      return _.isNil(s) === false && s.addEventListener !== undefined;
    }).forEach(function (s) {
      if (s === undefined) {
        return;
      }

      s.addEventListener('scroll', onScroll);
    });

    return function () {
      _(scrollableParents).filter(function (s) {
        return _.isNil(s) === false && s.removeEventListener !== undefined;
      }).forEach(function (s) {
        return s.removeEventListener('scroll', onScroll);
      });
    };
  }, [scrollableParents]);
  return useMemo(function () {
    return position;
  }, [position]);
};