import { SettingsFields } from '../../components/form/GenericField';
import { BlockSettingsObject, FavoriteSettings } from '@tradingblock/types';
import { useBlockId } from '../../components/blocks/BlockState';
import { useStateSelector } from '../../data/global/dataSelectors';
import { blockSettings } from '../../data/global/selectors/settingSelectors';

export const DefaultFavoriteBlockSettings: FavoriteSettings = {
  Symbol: true,
  Last: true,
  Change: true,
  ChangePercent: true,
  Bid: true,
  Ask: true,
  Open: true,
  PrevClose: true,
  Close: true,
  High: true,
  Low: true,
  Trade: true,
  Chart: true,
  Remove: true,
};

export const FavoriteSettingsFields: SettingsFields<FavoriteSettings> = {
  Last: { type: 'boolean' },
  Change: { type: 'boolean' },
  ChangePercent: { type: 'boolean' },
  Bid: { type: 'boolean' },
  Ask: { type: 'boolean' },
  Open: { type: 'boolean' },
  PrevClose: { type: 'boolean' },
  Close: { type: 'boolean' },
  High: { type: 'boolean' },
  Low: { type: 'boolean' },
  Trade: { type: 'boolean' },
  Chart: { type: 'boolean' },
};

export const filterHiddenColumns = (columns: any[], current: BlockSettingsObject<FavoriteSettings>) => {
  return columns.filter(column => {
    //exclude these columns as they are not meant to be toggled on/off.
    if (column.Header === 'Symbol' || column.Header === 'Remove') {
      return true;
    } else if (column.Header === '(%)') {
      return current.ChangePercent;
    } else if (column.Header === 'Prev. Close') {
      return current.PrevClose;
    } else {
      return current[column.Header as keyof typeof useFavoriteSettings];
    }
  });
};

export const useFavoriteSettings = () => {
  const blockId = useBlockId();
  const current = useStateSelector(s => blockSettings.favorite(s, blockId));
  return {
    current: current,
    definitions: FavoriteSettingsFields,
    filterHiddenColumns,
  };
};

export const useFavoriteBlockData = () => {
    const blockId = useBlockId();
    const blockState = useStateSelector(s => s.account.ordersUIState[blockId]);
    if (!blockState) {
        return { pageSize: 50, pageIndex: 0, total: 0 };
    }
    return {
        pageSize: blockState.pageSize || 50,
        pageIndex: blockState.pageIndex || 0,
        total: blockState.total,
    };
};
