import _ from 'lodash';
import { DataFeedSubscription, QuoteFeedType, SpreadFeedType, OrderUpdateFeedType } from '@tradingblock/types';
import { isOCCSymbol, toPrefixedOccSymbol } from '@tradingblock/api';

export const DATA_FEED_PREFIX = 'tradingblockfeed';

function getSubscriptionData(s: DataFeedSubscription) {
  switch (s.kind) {
    case 'Quote': {
      const allSymbols = _.map(s.symbols, sym => (isOCCSymbol(sym) ? toPrefixedOccSymbol(sym) : sym)).join(',');
      return s.symbols.length > 0 ? `${QuoteFeedType}=${allSymbols}` : '';
    }
    case 'Spread':
      return s && s.spreadDefinition && s.spreadDefinition.length > 0
        ? `${SpreadFeedType}=${JSON.stringify(s.spreadDefinition)}`
        : '';
    case 'OrderUpdate':
      return `${OrderUpdateFeedType}=1`;
  }
}

export const getQuoteSubscription = (
  apiToken: string,
  accountId: string | number,
  subs: DataFeedSubscription[],
  unsubscribe?: boolean
) => {
  const headerValues = [`0=${apiToken}`, `1=${accountId}`, unsubscribe ? '9=0' : '9=1'].filter(v => v !== undefined);
  const orderedSubscriptions = _.orderBy(subs, s => s)
    .filter(ss =>
      ss.kind === 'Quote' ? ss.symbols.length > 0 : ss.kind === 'Spread' ? ss.spreadDefinition.length > 0 : true
    )
    .map(ss => getSubscriptionData(ss));
  if (apiToken && subs && orderedSubscriptions.length > 0) {
    const subscriptString = _.union(headerValues, orderedSubscriptions).join('|');
    return subscriptString;
  }
  return undefined;
};
export const getOrderUpdateSubscription = (apiToken: string, accountId: string | number, unsubscribe?: boolean) => {
  const value = unsubscribe === true ? 0 : 1;
  return `0=${apiToken}|1=${accountId}|13=${value}`;
};

/*

  0 = 1 (Success)
  1 = 1 (i.e. single leg quote. any instrument)
  2 = QuoteTime
  3 = Symbol
  4 = LastTradePrice
  5 = BidPrice
  6 = AskPrice
  7 = NetChange
  8 = High
  9 = Low
  10 = Volume
  11 = Change Percentage

example:

  0=0|1=1|2=07/24/2019 18:35:38|3=TSLA|4=240.3|5=240.3|6=240.59|7=-19.87|8=266.07|9=258.1601|10=10494992|11=-7.64

*/

export const tryParseMessage = (message: string) => {};

// const parser = (value: string) => {
//   const vals = value.split('|').map(vv => vv.replace(/\d+=/, ''));
//   if (vals.length < 12) {
//     console.warn('quote string from feed has less fields than expected', value);
//   }
//   const str = (ind: number) => vals[ind];
//   const int = (ind: number) => parseInt(str(ind));
//   const float = (ind: number) => parseFloat(str(ind));
//   const success = (ind: number) => (int(ind) === 0 ? true : false);
//   const mtype = (typeVal: number): FeedMessageType => (typeVal === 0 ? 'HeartBeat' : typeVal === 1 ? 'Quote' : typeVal === 2 ? 'Spread' : typeVal === 3 ? 'OrderUpdate' : undefined);
//   const result = {
//     success: success(0),
//     type: mtype(1),
//   };
//   return {
//     str,
//     int,
//     float,
//     success,
//     mtype,
//     result,
//   };
// };

// export const parseQuote = (quote: string): FeedData<FeedQuote> => {
//   const { str, float, result } = parser(quote);
//   return {
//     ...result,
//     QuoteTime: str(2),
//     Symbol: str(3),
//     LastTradePrice: float(4),
//     BidPrice: float(5),
//     AskPrice: float(6),
//     NetChange: float(7),
//     High: float(8),
//     Low: float(9),
//     Volume: float(10),
//     ChangePercentage: float(11),
//     raw: quote,
//   };
// };
