import React from 'react';
import { Tooltip } from '@tradingblock/components';
import { useOrderData } from '../state/OrderState';
import { OrderSelectors } from '../state/OrderSelector';
import { useBlockId } from '../../../components/blocks/BlockState';

export const OrderValidationIcon: React.FC<{}> = () => {
  const blockId = useBlockId();
  const message = useOrderData(s => OrderSelectors.validationErrorMessage(s, blockId));
  // const validation = useOrderData(s => s.validation);
  if (!message) {
    return <></>;
  }

  return (
    <div className="order-leg-setting order-leg-sentiment">
      <Tooltip
        effect="solid"
        id="orderValidationMessage"
        content={
          <div>
            <i className="warn fas fa-exclamation-triangle"></i>
            {message}
          </div>
        }
        type="light"
      >
        <button className="btn btn-blend txt-lg" type="button">
          <i className="warn fas fa-exclamation-triangle"></i>
        </button>
      </Tooltip>
    </div>
  );
};
