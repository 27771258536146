import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import {
  AssetSymbol,
  BlockType,
  TradingStrategyOrCustom,
  AssetType,
  OptionType,
  Order,
  OrderAction,
  OrderLeg,
  OrderStatuses,
  TradingStrategyNameOrCustom,
  TradingStrategy,
  StrikeRules,
} from '@tradingblock/types';
import {
  CustomStrategy,
  OrderBuilderType,
  OrderBuilder,
  parseOccSymbol,
  Put,
  Call,
  Shares,
  getDefaultOrderQuantity,
} from '@tradingblock/api';
import { useDispatcher } from '../../../data/global/hooks';
import { generateNewBlock } from '../../../utilities/block';
import { useFeedQuoteWithMetadata } from '../../../hooks/useFeedQuote';
import { OrderState } from '../../../blocks/Order/state/OrderState';
import { DefaultLegs, defaultStrategyForSymbol } from '../../../blocks/Order/OrderUtilities';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { globalSettings } from '../../../data/global/selectors/settingSelectors';
import { BlockActions } from '../../../data/global/actions';
import { getType } from 'typesafe-actions';
import { Actions, OrderActions } from '../../../blocks/Order/state/OrderActions';
import { useBlockActionDispatchAndGenericActions } from '../BlockState';

interface UseTradeActionsProps {
  occSymbol: string | undefined;
  addBlockToGroupId?: string;
  data?: Partial<OrderState>;
  baseAssetSymbol: AssetSymbol;
  isUnderlyingSymbol?: boolean;
}
export const useOrderBlock = ({
  occSymbol,
  addBlockToGroupId,
  data,
  baseAssetSymbol,
  isUnderlyingSymbol,
}: UseTradeActionsProps) => {
  const { dispatcher } = useDispatcher();
  const globalOrderSettings = useStateSelector(globalSettings.order);

  const symbolQuote = useFeedQuoteWithMetadata(occSymbol || '');
  const symbol: AssetSymbol | undefined = useMemo(() => {
    if (symbolQuote) {
      // const { Symbol, Description, Exchange, UnderlyingSymbol, AssetClass } = symbolQuote;
      return baseAssetSymbol;
    }
    return baseAssetSymbol;
  }, [symbolQuote, baseAssetSymbol]);

  const createOrUpdateOrderBlock = useCallback(
    (addBlockAsNew?: boolean) => {
      const initialBlockData = data || {
        legs: DefaultLegs({}, symbol, { optionType: undefined, strike: undefined, expiration: undefined }),
      };
      // const optionType = _.get(_.first(_.values(initialBlockData && initialBlockData.legs)), 'OptionType');
      // let strategy: TradingStrategyOrCustom = optionType ? (optionType === OptionType.Call ? Call : Put) : CustomStrategy;
      let strategy: TradingStrategyNameOrCustom = CustomStrategy.info.Name;
      const underlying = symbol.underlyingSymbol;
      if (isUnderlyingSymbol && underlying) {
        strategy = defaultStrategyForSymbol(underlying, undefined, globalOrderSettings);
      }
      // const quantity = _([defaultOrderQuantity, initialBlockData ? initialBlockData.quantity : undefined])
      //   .filter(v => v !== undefined)
      //   .min();
      const newBlock = generateNewBlock(BlockType.Order, {
        ...initialBlockData,
        symbol,
        strategy: strategy,
        quantity: getDefaultOrderQuantity(strategy, globalOrderSettings),
      });
      newBlock.groupId = addBlockToGroupId || '';
      // if adding block as new, don't pass "replace first match" options

      dispatcher.block.addOrUpdateBlock(
        newBlock,
        false,
        addBlockAsNew ? undefined : { blockType: BlockType.Order, groupId: addBlockToGroupId }
      );
    },
    [data, symbol, addBlockToGroupId, dispatcher.block, globalOrderSettings]
  );

  // if linkable block exists, show 2 trade options in dropdown
  return { createOrUpdateOrderBlock };
};

export interface ReplaceFirstMatchOptions {
  blockType: BlockType;
  groupId?: string;
}
export const useOrderBlockWithOrder = (
  build: (builder: OrderBuilderType) => OrderBuilderType,
  replaceFirstMatch?: ReplaceFirstMatchOptions
) => {
  const { dispatcher, dispatch } = useDispatcher();
  const globalOrderSettings = useStateSelector(globalSettings.order);
  const [{}, orderBlockDispatcher] = useBlockActionDispatchAndGenericActions<OrderState, OrderActions>();
  const orderBlockBuilder = useCallback(
    (addBlockAsNew?: boolean) => {
      const orderBlockData = build(new OrderBuilder(globalOrderSettings).quantity(1)).order();
      const newBlock = generateNewBlock(BlockType.Order, orderBlockData);
      //dispatcher.block.addOrUpdateBlock(newBlock, false, addBlockAsNew === true ? undefined : replaceFirstMatch);

      dispatch(
        BlockActions.addOrUpdateBlock(
          {
            block: newBlock,
            addedAt: dayjs().toDate(),
            preserveGroupOrderData: false,
            replaceFirstMatch: addBlockAsNew === true ? undefined : replaceFirstMatch,
          },
          { persist: true }
        )
      );

      orderBlockDispatcher({ type: getType(Actions.asyncValidateOrder), payload: {} });
    },
    [replaceFirstMatch, dispatcher.block, orderBlockDispatcher, dispatch]
  );

  // if linkable block exists, show 2 trade options in dropdown
  return orderBlockBuilder;
};

export const getStrategyForLegs = (legs: OrderLeg[]) => {
  const firstLeg = _.first(legs);
  if (legs.length === 1 && firstLeg) {
    const sinfo = parseOccSymbol(firstLeg.OccSymbol || firstLeg.Symbol);
    if (sinfo.assetType === AssetType.Option) {
      return sinfo.option === OptionType.Put ? Put : Call;
    } else {
      return Shares;
    }
  }
  return CustomStrategy;
};

export const useCloseOrderAction = (
  order: Pick<Order, 'Quantity' | 'Legs' | 'OrderStatus'>,
  symbol: AssetSymbol,
  blockSelectionOptions?: Pick<ReplaceFirstMatchOptions, 'groupId'>,
  subaccountId?: number
) => {
  const { Quantity, Legs } = order;
  const strategy: TradingStrategyOrCustom = getStrategyForLegs(Legs);
  const isCustom = strategy === CustomStrategy;
  const isSingleLeggedOrder = Legs.length === 1;
  const firstLeg = _.first(Legs);

  const buildCloseOrder = (builder: OrderBuilderType) => {
    // const legs = DefaultLegs({}, assetSymbol, { assetType, optionType, orderAction: action, strike, expiration:  });

    let baseBuilder = builder;
    let orderQuantity = isCustom ? 1 : Math.abs(Quantity);
    let orderAction: OrderAction = OrderAction.Buy;

    if (strategy.info.Name === 'Shares') {
      if (orderQuantity < 1) {
        orderQuantity = Math.ceil(orderQuantity);
      } else {
        orderQuantity = Math.floor(orderQuantity);
      }
    }

    if (isSingleLeggedOrder && firstLeg) {
      orderAction = firstLeg.Action;
    }

    baseBuilder = builder
      .symbol(symbol)
      .strategy(strategy)
      .action(orderAction)
      .quantity(orderQuantity)
      .subaccountId(subaccountId ? subaccountId : -1);

    return _.reduce(
      Legs,
      (b, l) => {
        const legQuantity = isCustom ? Math.abs(Quantity) : 1;

        return b.leg(ll => {
          const symbolInfo = parseOccSymbol(l.Symbol);
          const buildRes = ll
            .assetType(symbolInfo.assetType)
            .action(l.Action)
            .quantity(legQuantity)
            .subaccountId(subaccountId);

          if (symbolInfo.assetType === AssetType.Equity) {
            return buildRes;
          }
          return buildRes
            .optionType(symbolInfo.option || OptionType.Call)
            .expiration(symbolInfo.expiration)
            .strike(symbolInfo.strike)
            .subaccountId(subaccountId);
        });
      },
      baseBuilder
    );
  };

  const blockSelectionOpts = blockSelectionOptions || {};
  // if linkable block exists, show 2 trade options in dropdown
  const handleCloseOrder = useOrderBlockWithOrder(buildCloseOrder, {
    blockType: BlockType.Order,
    ...blockSelectionOpts,
  });
  return useMemo(() => handleCloseOrder, [order, symbol]);
};

export const useTradeSymbolAction = (
  symbol: AssetSymbol,
  fn: (builder: OrderBuilderType) => OrderBuilderType,
  blockSelectionOptions?: Pick<ReplaceFirstMatchOptions, 'groupId'>
) => {
  const buildTradeOrder = (builder: OrderBuilderType) => {
    // const legs = DefaultLegs({}, assetSymbol, { assetType, optionType, orderAction: action, strike, expiration:  });
    const baseBuilder = builder
      .symbol(symbol)
      .strategy(CustomStrategy)
      .quantity(1);
    return fn(baseBuilder);
  };
  const blockSelectionOpts = blockSelectionOptions || {};
  // if linkable block exists, show 2 trade options in dropdown
  const handleTradeOrder = useOrderBlockWithOrder(buildTradeOrder, {
    blockType: BlockType.Order,
    ...blockSelectionOpts,
  });

  return useMemo(() => handleTradeOrder, [fn, symbol]);
};

export const useOrderCopyTradeAction = (
  order: Pick<Order, 'Quantity' | 'Legs' | 'OrderStatus'>,
  symbol: AssetSymbol,
  blockSelectionOptions?: Pick<ReplaceFirstMatchOptions, 'groupId'>
) => {
  const { Quantity, Legs, OrderStatus } = order;
  const strategy = getStrategyForLegs(Legs);
  const isCustom = strategy.info.Name === CustomStrategy.info.Name;
  const isSingleLeggedStrategyOrder = !isCustom && Legs.length === 1;
  const firstLeg = _.first(Legs);
  const orderCancelled = OrderStatus === OrderStatuses.Cancelled;

  const handleTradeOrder = (builder: OrderBuilderType) => {
    // const legs = DefaultLegs({}, assetSymbol, { assetType, optionType, orderAction: action, strike, expiration:  });

    let baseBuilder = builder;
    let quantity = 1;
    let orderAction = OrderAction.Buy;

    // if (firstLeg && (isSingleLeggedStrategyOrder || orderCancelled)) {
    //   quantity = Quantity;
    //   orderAction = firstLeg.Action === OrderAction.Buy ? OrderAction.Sell : OrderAction.Buy;
    // } else if (!isCustom && firstLeg) {
    //   quantity = Math.abs(firstLeg.LegFillQuantity || 1);
    //   orderAction = firstLeg.Action === OrderAction.Buy ? OrderAction.Sell : OrderAction.Buy;
    // }
    if (isSingleLeggedStrategyOrder && firstLeg) {
      quantity = Quantity;
      orderAction = firstLeg.Action === OrderAction.Buy ? OrderAction.Sell : OrderAction.Buy;
    }

    baseBuilder = baseBuilder
      .symbol(symbol)
      .action(orderAction)
      .strategy(strategy)
      .quantity(quantity);

    return _.reduce(
      Legs,
      (b, l) => {
        const { LegFillQuantity, SpreadRatio } = l;
        // const legQuantity = isCustom ? LegFillQuantity : 1;
        const legQuantity = isCustom ? (SpreadRatio || 1) * Quantity : 1;

        return b.leg(ll => {
          const symbolInfo = parseOccSymbol(l.Symbol);
          const buildRes = ll
            .assetType(symbolInfo.assetType)
            .action(l.Action === OrderAction.Buy ? OrderAction.Sell : OrderAction.Buy)
            .quantity(legQuantity);

          if (symbolInfo.assetType === AssetType.Equity) {
            return buildRes;
          }
          return buildRes
            .optionType(symbolInfo.option || OptionType.Call)
            .expiration(symbolInfo.expiration)
            .strike(symbolInfo.strike);
        });
      },
      baseBuilder
    );
  };

  const blockSelectionOpts = blockSelectionOptions || {};
  // if linkable block exists, show 2 trade options in dropdown
  const handleTradeOrderBuilder = useOrderBlockWithOrder(handleTradeOrder, {
    blockType: BlockType.Order,
    ...blockSelectionOpts,
  });
  return useMemo(() => handleTradeOrderBuilder, [order, symbol]);
};

export const useOrderCopyAction = (
  order: Order<OrderLeg>,
  symbol: AssetSymbol,
  blockSelectionOptions?: Pick<ReplaceFirstMatchOptions, 'groupId'>,
  matchingStrategy?: TradingStrategy,
  setCustom?: boolean,
  duplicateOrder?: boolean
) => {
  const { Quantity, Legs, OrderType, Action } = order;
  let strategy = setCustom ? CustomStrategy : getStrategyForLegs(Legs);
  const isCustom = strategy.info.Name === CustomStrategy.info.Name;
  const isSingleLeggedStrategyOrder = !isCustom && Legs.length === 1;
  const isSingleLeggedCustomOrder = isCustom && Legs.length === 1;
  const firstLeg = Legs[0];

  if (isCustom && matchingStrategy && matchingStrategy.info.Name) {
    strategy = matchingStrategy;
  }

  /**
   * Adjusts the order of legs in a strategy based on the defined strike rules.
   *
   * This function is designed to work with two-legged options strategies where
   * the order of strikes (increasing or decreasing) matters, such as in put/call
   * vertical spreads. It checks the strategy's strike rules and reorders the
   * legs of the strategy if they are not in the correct order according to the
   * rules.
   *
   * @param {Object[]} Legs - An array of leg objects in the strategy. Each leg object
   *                           should have a 'Strike' property.
   * @param {Object} strategyProfile - The profile object of the strategy, which should
   *                                   contain the 'Rules' related to strike ordering.
   *
   * @example
   * For a call vertical buy, Legs should be ordered with lower strike first:
   * If Legs are [{Strike: 110}, {Strike: 100}], they will be reordered to
   * [{Strike: 100}, {Strike: 110}]
   * adjustLegOrderForStrikeRules(Legs, matchingStrategy.profile);
   *
   * Notes:
   * - The function directly modifies the 'Legs' array.
   * - It only applies adjustments for strategies with exactly two legs.
   * - The function assumes 'strategyProfile' has a structure where 'Rules' is an object
   *   that contains a 'Strike' property with values like 'IncreasingOrder' or
   *   'DecreasingOrder'.
   * - If the 'Rules' object or 'Strike' property is not defined, or if the strategy
   *   does not conform to the expected structure (e.g., not a two-legged strategy),
   *   the function does nothing.
   */
  const adjustLegOrderForStrikeRules = (Legs: any[], strategyProfile: TradingStrategy['profile']) => {
    if (Legs.length !== 2) {
      return; // Only adjust for two-legged strategies
    }

    const strikeRule = strategyProfile && strategyProfile.Rules && strategyProfile.Rules.Strike;
    if (!strikeRule) {
      return; // No strike rule defined, exit early
    }

    const firstLegHigher = Legs[0].Strike > Legs[1].Strike;

    // Reverse Legs if the order is not aligned with the strike rule
    if (
      (strikeRule === StrikeRules.IncreasingOrder && firstLegHigher) ||
      (strikeRule === StrikeRules.DecreasingOrder && !firstLegHigher)
    ) {
      Legs.reverse();
    }
  };

  const handleTradeOrder = (builder: OrderBuilderType) => {
    let baseBuilder = builder;
    let orderAction = Action ? Action : OrderAction.Buy;

    if (isSingleLeggedStrategyOrder && firstLeg) {
      orderAction = firstLeg.Action === OrderAction.Buy ? OrderAction.Buy : OrderAction.Sell;
    }

    if ('profile' in strategy) {
      adjustLegOrderForStrikeRules(Legs, strategy.profile);
    }

    let calculatedQuantity = setCustom ? (isSingleLeggedCustomOrder ? Quantity : 1) : Quantity;

    baseBuilder = baseBuilder
      .symbol(symbol)
      .orderType(OrderType)
      .action(orderAction)
      .strategy(strategy)
      .quantity(calculatedQuantity);

    return _.reduce(
      Legs,
      (b, l) => {
        const { LegFillQuantity, SpreadRatio } = l;
        // let legQuantity = isCustom ? LegFillQuantity : 1;
        // if (orderCancelled && LegFillQuantity === 0) {
        //   legQuantity = (SpreadRatio || 1) * Quantity;
        // }
        // if the order is being built from the duplicate order action button, then we need to multiply spread ratio by the quantity to determine the leg quantity
        const legQuantity =
          isCustom && !duplicateOrder
            ? isSingleLeggedCustomOrder
              ? Quantity
              : SpreadRatio || 1
            : isCustom && duplicateOrder
            ? (SpreadRatio || 1) * Quantity
            : 1;
        return b.leg(ll => {
          const symbolInfo = parseOccSymbol(l.Symbol);
          const buildRes = ll
            .assetType(symbolInfo.assetType)
            .action(l.Action)
            .quantity(legQuantity);

          if (symbolInfo.assetType === AssetType.Equity) {
            return buildRes;
          }
          return buildRes
            .optionType(symbolInfo.option || OptionType.Call)
            .expiration(symbolInfo.expiration)
            .strike(symbolInfo.strike);
        });
      },
      baseBuilder
    );
  };

  const blockSelectionOpts = blockSelectionOptions || {};
  // if linkable block exists, show 2 trade options in dropdown
  const handleTradeOrderBuilder = useOrderBlockWithOrder(handleTradeOrder, {
    blockType: BlockType.Order,
    ...blockSelectionOpts,
  });
  return useMemo(() => handleTradeOrderBuilder, [order, symbol]);
};
