import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useStateSelector, accountIdSelector } from '../../../data/global/dataSelectors';
import { Actions } from '../../../data/global/actions';
import { DashboardSettingsPath, UiSettings } from '@tradingblock/types';
import { InitialState } from '../../../data/global/initialState';
import { useStore } from '../../../context/Storage';
import { DataState } from '../../../data/global/state';
import { createSelector } from 'reselect';

const accountInfoSelector = createSelector(
  accountIdSelector,
  (s: DataState) => s.settings.ui.loaded,
  (accountId, loaded) => ({ accountId, loaded })
);

export const useAccountSettings = () => {
  const dispatch = useDispatch();
  const { accountId, loaded } = useStateSelector(accountInfoSelector);
  const storage = useStore();
  useEffect(() => {
    const getSettings = async (id: number) => {
      dispatch(Actions.requestSettings({ accountId: id }));
      const settings = await storage.get<UiSettings>(DashboardSettingsPath);
      dispatch(Actions.receiveSettings({ accountId: id, settings: settings || { ...InitialState.settings.ui } }));
    };
    if (accountId && loaded !== true) getSettings(accountId);
  }, [loaded, accountId]);
};
