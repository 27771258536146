import * as tslib_1 from "tslib";
import { StorageProvider, HttpStorageEngineFactory } from './storageProvider';
import { getAxiosClient } from './providers/httpProvider'; // export const StorageProviderFactory = (apiUrl: string, authToken: string) => {
//   return new StorageProvider<HttpProviderConfig>({ kind: 'http', url: apiUrl, authToken },(conf) => HttpStorageProviderFactory(getAxiosClient(conf),conf) );
// };

export var HttpStorageFactory = function HttpStorageFactory(factoryOptions) {
  var responseIntercept = factoryOptions.responseIntercept,
      responseInterceptError = factoryOptions.responseInterceptError,
      httpConfig = tslib_1.__rest(factoryOptions, ["responseIntercept", "responseInterceptError"]);

  return new StorageProvider(Object.assign({
    kind: 'http'
  }, httpConfig), function (conf) {
    var axios = getAxiosClient(conf);

    if (responseIntercept || responseInterceptError) {
      axios.interceptors.response.use(responseIntercept, responseInterceptError);
    }

    return HttpStorageEngineFactory(axios, conf);
  });
};