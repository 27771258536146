import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Number } from '@tradingblock/components';
import { useStateSelector } from '../../data/global/dataSelectors';
import { quoteExtendedSelectors, quoteSelectors } from '../../data/global/selectors/quoteSelector';
import { Loading, Button, OptionType, BasicSelect } from '@tradingblock/components';
import { DividendTimeframeValues } from '@tradingblock/types';
import { QuoteDataActions } from '../../data/global/actions/QuoteActions';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useQuoteData, QuoteState } from './state/QuoteState';
import { useBlockActionDispatchAndGenericActions } from '../../components/blocks/BlockState';
import { QuoteBlockAction, QuoteBlockActions } from './state/QuoteBlockAction';

const OrderField: React.FunctionComponent<{ label: string }> = ({ label, children }) => (
  <li className="prop-inline">
    <span className="mute prop-title">{label}</span> <span className="prop-value">{children}</span>
  </li>
);

export const dividendOptions: OptionType<DividendTimeframeValues>[] = [
  { value: 'One year', label: 'One year', data: '1y' },
  { value: 'Two year', label: 'Two year', data: '2y' },
  { value: 'Five year', label: 'Five year', data: '5y' },
];

export const DividendsInfo: React.FunctionComponent<{ symbol: string }> = ({ symbol }) => {
  const dispatch = useDispatch();
  const period = useQuoteData(s => s.dividendsPeriod || '1y');
  const [__, blockDispatch] = useBlockActionDispatchAndGenericActions<QuoteState, QuoteBlockAction>();
  const setPeriod = useCallback(
    (ev: DividendTimeframeValues) => blockDispatch(QuoteBlockActions.setDividendsPeriod(ev)),
    [blockDispatch]
  );
  const currentTimeframeOption = useMemo(() => {
    const curr = _.find(dividendOptions, o => o.data === period);
    return curr ? curr : dividendOptions[0];
  }, [period, dividendOptions]);
  const currentTimeframe = useMemo(() => currentTimeframeOption.label, [currentTimeframeOption]);

  const dividendPeriod = (currentTimeframe: string) => {
    var type = currentTimeframe == 'Year-to-Date' ? currentTimeframe : 'Quarter';
    return type;
  };

  useEffect(() => {
    if (symbol) {
      dispatch(QuoteDataActions.requestDividends({ symbol: symbol, range: period }));
    }
  }, [symbol, period, dispatch]);
  const onTimeframeChange = useCallback((opt: OptionType<DividendTimeframeValues>) => {
    const range = opt.data;
    setPeriod(range);
    dispatch(QuoteDataActions.requestDividends({ symbol: symbol, range }));
  }, []);
  const { isLoading, dividendInfo } = useStateSelector(s =>
    quoteSelectors.dividends.symbolDividends(s, { symbol, period })
  );
  return (
    <>
      <div className="block-section block-section-fixed">
        <div className="row justify-content-between">
          <div className="col-auto">
            <BasicSelect
              value={currentTimeframeOption}
              onChange={onTimeframeChange}
              placeholder={currentTimeframe}
              className="btn btn-dark btn-tall dropdown-toggle text-left"
              options={dividendOptions}
              isClearable={false}
              isSearchable={false}
            />
          </div>
        </div>
      </div>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <table>
            <thead>
              <tr>
                <th>Declaration</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Ex Date</th>
                <th>Record</th>
                <th>Payment</th>
              </tr>
            </thead>
            <tbody>
              {dividendInfo && (
                <>
                  {dividendInfo.filter(item => item.amount !== 0).map((item, index) => (
                    <tr key={`dividend-${period}-${item.id || index}`}>
                      <td key={`declareDate-${index}`}>{item.declaredDate ? dayjs(item.declaredDate).format('MM/DD/YYYY') : ''}</td>
                      <td key={`amount-${index}`}>
                        <Number value={item.amount} currency />
                      </td>
                      <td key={`timeframe-${index}`}>{dividendPeriod(currentTimeframe)}</td>
                      <td key={`exDate-${index}`}>{item.exDate ? dayjs(item.exDate).format('MM/DD/YYYY') : ''}</td>
                      <td key={`recordDate-${index}`}>{item.recordDate ? dayjs(item.recordDate).format('MM/DD/YYYY') : ''}</td>
                      <td key={`paymentDate-${index}`}>{item.paymentDate ? dayjs(item.paymentDate).format('MM/DD/YYYY') : ''}</td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </>
      )}
    </>
  );
};
