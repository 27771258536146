import { useDebug } from '@tradingblock/components';
import _ from 'lodash';

export const LogMiddleware = (name: string, identifier?: string) => {
  const debuglog = useDebug(name);
  return function<T, A>(store: { getState: () => T }) {
    return (next: (action: A) => any) => (action: A) => {
      const actType = _.get(action, 'type', undefined);
      let log = debuglog;
      log = identifier ? log.extend(identifier) : log;
      log = actType ? log.extend(actType) : log;
      const before = store.getState();
      let result = next(action);
      const after = store.getState();
      log(`dispatched %o %o`, action, { before, after });
      // console.group(`OrderReducer: %c${action.type}`, 'color: blue');
      // console.debug(action);
      // console.debug('initial', orderState);
      // console.debug('after', ordState);
      // console.groupEnd();
      return result;
    };
  };
};
