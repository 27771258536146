import React, { useEffect, useState } from 'react';
import { RouteComponentProps, Redirect } from 'react-router';
import _ from 'lodash';
import { ContactPhone, Loading } from '@tradingblock/components';
import { getIdNumber } from '@tradingblock/api';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { entitySelector } from '../../../data/global/selectors/admin/cashieringSelectors';
import { CashieringTransferDetails } from './components/CashieringTransferDetails';
import { CashieringReviewForm } from './components/CashieringReviewForm';
import { CashieringRelationshipDetails } from './components/CashieringRelationshipDetails';
import { useDispatcher } from '../../../data/global/hooks';
import { AdminCashieringActions } from '../../../data/global/actions/admin/AdminCashieringActions';
import { CashieringDetailError } from './components/CashieringDetailError';
import { CashieringEntityNotes } from './components/details/CashieringEntityNotes';
import { CashieringRelationshipStatus } from './components/CashieringRelationshipStatus';
import { CashieringTransferStatus } from './components/CashieringTransferStatus';
import { CashieringTransferInstructionDetails } from './components/CashieringTransferInstructionDetails';
import { CashieringTransferInstructionStatus } from './components/CashieringTransferInstructionStatus';
import { FilterForAccount } from './components/FilterForAccount';
import { accountDetailsSelectors } from '../../../data/global/selectors/admin/accountSelectors';

const useCashieringData = (
  entityType: 'relationships' | 'transfers' | 'wireInstructions',
  id: string,
  accountId: number
) => {
  const { dispatch } = useDispatcher();
  useEffect(() => {
    if (entityType === 'relationships') {
      dispatch(AdminCashieringActions.cashieringRelationshipDetailRequest({ accountId, id }));
    } else if (entityType === 'transfers') {
      dispatch(AdminCashieringActions.cashieringTransferDetailRequest({ accountId, id }));
    } else if (entityType === 'wireInstructions') {
      dispatch(AdminCashieringActions.cashieringWireInstructionsDetailsRequest({ accountId, id }));
    }
  }, [accountId, dispatch, entityType, id]);
  const entityInfo = useStateSelector(s => entitySelector.info(s, { id, type: entityType }));

  return { ...entityInfo, accountId };
};

export const AdminCashieringDetail: React.FunctionComponent<
  RouteComponentProps<{ id: string; entityType: string; accountId: string }>
> = ({ match }) => {
  const { id, entityType } = match.params;
  const accountId = _.toInteger(match.params.accountId);
  const isRelationship = ['AchRelationship', 'relationship']
    .map(v => v.toLocaleLowerCase())
    .includes(entityType.toLocaleLowerCase());
  const isTransferWireInstruction = ['TransferWireInstruction', 'transferWireInstruction']
    .map(v => v.toLocaleLowerCase())
    .includes(entityType.toLocaleLowerCase());

  const type = isRelationship ? 'relationships' : isTransferWireInstruction ? 'wireInstructions' : 'transfers';
  const idValue = getIdNumber(id);
  const { isFetching, isSaving, isErrored, error } = useCashieringData(type, id, accountId);
  const accountDetails = useStateSelector(s => accountDetailsSelectors.data(s, accountId));
  const accountStatus = accountDetails ? accountDetails.accountStatus : undefined;
  const targetedAccountStatuses =
    accountStatus === 'Rejected' || accountStatus === 'Closed' || accountStatus === 'Closed Never Funded';
  const rejectMsg =
    'Please note that cashiering actions are disabled at the moment due to the account status. Please contact the support team for more information.';

  const [editNote, setEditNote] = useState(false);

  const [saveState, setSaveState] = useState<'saving' | 'saved'>();
  useEffect(() => {
    //only track 'saving' state if we aren't saving the note
    if (isSaving && !editNote) {
      setSaveState('saving');
    } else if (saveState === 'saving') {
      if (!isErrored) {
        // save completed successfully
        setSaveState('saved');
      } else {
        // clear save state if errored
        setSaveState(undefined);
      }
    }
  }, [isSaving, saveState, isErrored]);

  // redirect back to list after saving
  if (saveState === 'saved') {
    return <Redirect to="/admin/cashiering" />;
  }
  return (
    <>
      {!isFetching && <FilterForAccount accountId={accountId} />}

      {isRelationship && !isFetching && (
        <CashieringRelationshipDetails id={idValue} accountId={accountId} isFetching={isFetching} />
      )}
      {!isRelationship && !isTransferWireInstruction && !isFetching && (
        <CashieringTransferDetails id={idValue} accountId={_.toInteger(accountId)} isFetching={isFetching} />
      )}
      {!isRelationship && isTransferWireInstruction && !isFetching && (
        <CashieringTransferInstructionDetails id={idValue} accountId={_.toInteger(accountId)} isFetching={isFetching} />
      )}

      {isFetching && (
        <div className="screen-body-section">
          Fetching details...
          <Loading />
        </div>
      )}

      {!isFetching && isRelationship && <CashieringRelationshipStatus id={idValue} accountId={accountId} />}
      {!isFetching && !isRelationship && !isTransferWireInstruction && (
        <CashieringTransferStatus id={idValue} accountId={_.toInteger(accountId)} />
      )}
      {!isFetching && !isRelationship && isTransferWireInstruction && (
        <CashieringTransferInstructionStatus id={idValue} accountId={_.toInteger(accountId)} />
      )}

      {!isFetching && !isErrored && !targetedAccountStatuses && (
        <CashieringEntityNotes
          accountId={accountId}
          wrapperClassName="screen-body-section"
          id={idValue}
          type={type}
          onEditToggle={setEditNote}
        />
      )}

      {isErrored && <CashieringDetailError error={error} />}

      {targetedAccountStatuses && (
        <p className="screen-body-section mute">
          {rejectMsg} <ContactPhone />
        </p>
      )}

      {!editNote && !targetedAccountStatuses && <CashieringReviewForm id={idValue} type={type} accountId={accountId} />}
    </>
  );
};
