import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import _ from 'lodash';
import {
  BlockTypeStrings,
  BlockSettingsObject,
  OptionChainSettings,
  OrderBlockSettings,
  PriceChartSettings,
  PositionSettings,
  ActivitySettings,
  InfoBlockSettings,
  FavoriteSettings,
  AnalyticsSettings,
} from '@tradingblock/types';
import { DataState } from '../state';
import { DefaultOrderBlockSettings } from '../../../blocks/Order/state/OrderSettings';
import { DefaultPriceChartSettings } from '../../../blocks/PriceChart/state/PriceChartSettings';
import { DefaultInfoBlockSettings } from '../../../blocks/Info/state/InfoSettings';
import { DefaultFavoriteBlockSettings } from '../../../blocks/Favorites/useFavoriteSettings';
import { DefaultOptionChainBlockSettings } from '../../../blocks/OptionChain/state/OptionChainSettings';
import { DefaultAnalyticBlockSettings } from '../../../blocks/Analytics/useAnalyticsSettings';
import { DefaultPositionsBlockSettings } from '../../../blocks/Positions/usePositionSettings';

const globalBlockSettingsSelector = (s: DataState) => s.blocks.blockTypeSettings;

export const BlockSettingSelector = createCachedSelector(
  (s: DataState, blockId: string) => _.find(s.blocks.blocks, b => b.blockId === blockId),
  globalBlockSettingsSelector,
  (block, blockTypeSettings) => {
    const blockSettings = block ? block.settings : undefined;
    const globalSettings = block && blockTypeSettings ? blockTypeSettings[block.type] : undefined;
    return {
      globalSettings,
      blockSettings,
    };
  }
)((s, blockId) => `blocksettings${blockId}`);

const blockByBlockId = (s: DataState, blockId?: string) =>
  blockId ? _.find(s.blocks.blocks, b => b.blockId === blockId) : undefined;
const globalTypeSelector = <T>(blockTypeStr: BlockTypeStrings) => (s: DataState): BlockSettingsObject<T> => {
  const globalSettings = (s.blocks.blockTypeSettings
    ? s.blocks.blockTypeSettings[blockTypeStr]
    : {}) as BlockSettingsObject<T>;
  if (blockTypeStr === 'Order') {
    return globalSettings ? ({ ...DefaultOrderBlockSettings, ...globalSettings } as any) : DefaultOrderBlockSettings;
  }
  if (blockTypeStr === 'Analytics') {
    return globalSettings
      ? ({ ...DefaultAnalyticBlockSettings, ...globalSettings } as any)
      : DefaultAnalyticBlockSettings;
  }
  if (blockTypeStr === 'PriceChart') {
    return globalSettings ? ({ ...DefaultPriceChartSettings, ...globalSettings } as any) : DefaultPriceChartSettings;
  }
  if (blockTypeStr === 'Info') {
    return globalSettings ? ({ ...DefaultInfoBlockSettings, ...globalSettings } as any) : DefaultInfoBlockSettings;
  }
  if (blockTypeStr === 'OptionChain') {
    return globalSettings
      ? ({ ...DefaultOptionChainBlockSettings, ...globalSettings } as any)
      : DefaultOptionChainBlockSettings;
  }
  if (blockTypeStr === 'Favorites') {
    return globalSettings
      ? ({ ...DefaultFavoriteBlockSettings, ...globalSettings } as any)
      : DefaultFavoriteBlockSettings;
  }
  if (blockTypeStr === 'Positions') {
    return globalSettings
      ? ({ ...DefaultPositionsBlockSettings, ...globalSettings } as any)
      : DefaultPositionsBlockSettings;
  }
  return globalSettings;
};

export const createBlockSettingsSelector = <T>(blockTypeStr: BlockTypeStrings, defaultVal: BlockSettingsObject<T>) => {
  return;
};

const blockIdKey = (s: DataState, blockId?: string) => (blockId ? blockId : 'default');

export const globalSettings = {
  analytics: createSelector(
    globalTypeSelector<AnalyticsSettings>('Analytics'),
    globals => {
      return globals;
    }
  ),
  favorite: createSelector(
    globalTypeSelector<FavoriteSettings>('Favorites'),
    globals => {
      return globals;
    }
  ),
  order: createSelector(
    globalTypeSelector<OrderBlockSettings>('Order'),
    globals => {
      return globals;
    }
  ),
  pricechart: createSelector(
    globalTypeSelector<PriceChartSettings>('PriceChart'),
    globals => {
      return globals;
    }
  ),
  info: createSelector(
    globalTypeSelector<InfoBlockSettings>('Info'),
    globals => {
      return globals;
    }
  ),
  positions: createSelector(
    globalTypeSelector<PositionSettings>('Positions'),
    globals => {
      return globals;
    }
  ),
};

export const blockSettings = {
  analytics: createCachedSelector(
    blockByBlockId,
    globalTypeSelector<AnalyticsSettings>('Analytics'),
    (block, defaultSettings) =>
      block && block.settings ? (block.settings as BlockSettingsObject<AnalyticsSettings>) : defaultSettings
  )(blockIdKey),
  activity: createCachedSelector(
    blockByBlockId,
    globalTypeSelector<ActivitySettings>('Activity'),
    (block, defaultSettings) =>
      block && block.settings ? (block.settings as BlockSettingsObject<ActivitySettings>) : defaultSettings
  )(blockIdKey),
  favorite: createCachedSelector(
    blockByBlockId,
    globalTypeSelector<FavoriteSettings>('Favorites'),
    (block, defaultSettings) => {
      return block && block.settings && Object.keys(block.settings).length > 0
        ? (block.settings as BlockSettingsObject<FavoriteSettings>)
        : defaultSettings;
    }
  )(blockIdKey),
  position: createCachedSelector(
    blockByBlockId,
    globalTypeSelector<PositionSettings>('Positions'),
    (block, defaultSettings) => {
      return block && block.settings && Object.keys(block.settings).length > 0
        ? (block.settings as BlockSettingsObject<PositionSettings>)
        : defaultSettings;
    }
  )(blockIdKey),
  optionChain: createCachedSelector(
    blockByBlockId,
    globalTypeSelector<OptionChainSettings>('OptionChain'),
    (block, defaultSettings) => {
      const settings =
        block && block.settings && Object.keys(block.settings).length > 0 ? block.settings : defaultSettings;
      for (const key of Object.keys(defaultSettings)) {
        if (settings[key] === undefined) {
          settings[key] = defaultSettings[key as keyof typeof defaultSettings];
        }
      }
      return settings;
    }
  )(blockIdKey),
  order: createCachedSelector(blockByBlockId, globalSettings.order, (block, globalDefaultSettings) => {
    try {
      if (block && block.settings) {
        return block.settings as BlockSettingsObject<OrderBlockSettings>;
      }
      return globalDefaultSettings;
    } catch (err) {
      console.warn('error getting order settings, returning defaults');
    }
    return DefaultOrderBlockSettings;
  })(blockIdKey),
  info: createCachedSelector(blockByBlockId, globalSettings.info, (block, globalDefaultSettings) => {
    try {
      if (block && block.settings) {
        return block.settings as BlockSettingsObject<InfoBlockSettings>;
      }
      return globalDefaultSettings;
    } catch (err) {
      console.warn('error getting info settings, returning defaults');
    }
    return DefaultInfoBlockSettings;
  })(blockIdKey),
  pricechart: createCachedSelector(blockByBlockId, globalSettings.pricechart, (block, globalDefaultSettings) => {
    try {
      if (block && block.settings) {
        return block.settings as BlockSettingsObject<PriceChartSettings>;
      }
      return globalDefaultSettings;
    } catch (err) {
      console.warn('error getting pricechart settings, returning defaults');
    }
    return DefaultPriceChartSettings;
  })(blockIdKey),
  global: {
    analytics: globalTypeSelector<AnalyticsSettings>('Analytics'),
    order: globalTypeSelector<OrderBlockSettings>('Order'),
    pricechart: globalTypeSelector<PriceChartSettings>('PriceChart'),
    info: globalTypeSelector<InfoBlockSettings>('Info'),
    favorite: globalTypeSelector<FavoriteSettings>('Favorites'),
    positions: globalTypeSelector<PositionSettings>('Positions'),
  },
};
