import { useMemo } from 'react';
import { AccountStatus, CashieringSearchEntityType, TransferState } from '@tradingblock/types';
import { useStateSelector } from '../../../data/global/dataSelectors';
import {
  getCashieringSearchEntities,
  getCashieringSearchFilterData,
  getCashieringSearchTotal,
} from '../../../data/global/selectors/admin/cashieringSelectors';
import { useDispatcher } from '../../../data/global/hooks';
import { AdminCashieringSearchActions } from '../../../data/global/actions/admin/AdminCashieringActions';

export const useCashieringData = () => {
  const data = useStateSelector(getCashieringSearchEntities);
  const total = useStateSelector(getCashieringSearchTotal);
  const { startDate, endDate, ...filterData } = useStateSelector(getCashieringSearchFilterData);
  return {
    data,
    total,
    ...filterData,
    timeframe: { startDate, endDate },
  };
};

export const useCashieringActions = () => {
  const { dispatch } = useDispatcher();
  return useMemo(
    () => ({
      search: (search: string | undefined) => dispatch(AdminCashieringSearchActions.setSearch({ search })),
      repCode: (repCode: string | undefined) => dispatch(AdminCashieringSearchActions.setRepCode({ repCode })),
      setTimeframe: (startDate: Date | undefined, endDate: Date | undefined) =>
        dispatch(AdminCashieringSearchActions.setTimeframe({ startDate, endDate })),
      setType: (types: CashieringSearchEntityType[] | undefined) => dispatch(AdminCashieringSearchActions.setType({ types })),
      setStatus: (status: TransferState[] | undefined) => dispatch(AdminCashieringSearchActions.setStatus({ status })),
      setPage: (page: number) => dispatch(AdminCashieringSearchActions.setPage({ page })),
      setAccountStatus: (accountStatus: AccountStatus[] | undefined) => dispatch(AdminCashieringSearchActions.setAccountStatus({ accountStatus })),
    }),
    [dispatch]
  );
};
