import _ from 'lodash';
import { InitialState } from '../initialState';

import { RootAction, FeedActions, DataActions, OrderActions } from '../actions';

import { getType } from 'typesafe-actions';

import { PositionInfo, OrderActionDirection } from '@tradingblock/types';

import { OrderStatusCategories } from '@tradingblock/api';

export const positionsReducer = (state = InitialState.positions, action: RootAction) => {
  switch (action.type) {
    case getType(DataActions.manualRefreshOfPositions):
    case getType(FeedActions.feedErrored):
    case getType(FeedActions.feedClosed): {
      return {
        ...state,
        stale: true,
      };
    }
    case getType(DataActions.fetchingPositions):
      return { ...state, isFetching: true };
    case getType(DataActions.receivePositions): {
      const positions = action.payload;
      const positionsWithId = positions.map(p => {
        return {
          ...p,
        };
      });
      const info = _.reduce(
        positionsWithId,
        (acc: PositionInfo, p) => {
          const symbolKey = p.Symbol;
          const existing = acc[symbolKey] || { quantity: 0 };
          const direction =
            p.OpenQuantity + existing.quantity < 0 ? OrderActionDirection.Short : OrderActionDirection.Long;
          const quantity = existing.quantity + p.OpenQuantity;
          return {
            ...acc,
            [symbolKey]: {
              underlyingSymbol: p.UnderlyingSymbol,
              quantity,
              direction,
            },
          };
        },
        {}
      );
      return {
        ...state,
        stale: false,
        loaded: true,
        isFetching: false,
        isDirty: false,
        positions: positionsWithId,
        info,
      };
    }

    // case getType(OrderActions.orderCreated):
    //   return { ...state, isDirty: true };
    case getType(OrderActions.receivePlaceOrder): {
      const { order, response } = action.payload;

      let orderId = response.payload.OrderId;
      let stale = state.stale;
      if (getType(OrderActions.receivePlaceOrder)) {
        stale = true;
      } else {
        stale = order.OrderStatus ? OrderStatusCategories.Filled.includes(order.OrderStatus) : false;
      }

      return {
        ...state,
        stale,
        latestOrderId: orderId,
      };
    }

    case getType(OrderActions.receivedUpdate): {
      const { order } = action.payload;
      let stale = state.stale;
      if (getType(OrderActions.receivePlaceOrder)) {
        stale = true;
      } else {
        stale = order.OrderStatus ? OrderStatusCategories.Filled.includes(order.OrderStatus) : false;
      }

      return {
        ...state,
        stale,
        latestOrderId: order.OrderId,
      };
    }
    default:
      return state;
  }
};
