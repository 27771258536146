import { useOrderData, OrderState } from './OrderState';
import { useGroupState } from '../../useGroupState';

type fieldOf<T> = keyof T;
type selectorOf<T, TT> = (val: T) => TT;

export function useOrderState<K extends keyof OrderState>(field: K) {
  // const blockId = useBlockId();
  // const selector = createCachedSelector((s: OrderState, blockId: string) => s[field], (val: OrderState[K]) => val)((_: OrderState, blockId: string) => blockId);
  // const value = useOrderData(s => selector(s, blockId));
  const value = useOrderData(s => s[field]);
  return value;
}
export function useOrderStateAs<K extends keyof OrderState, T>(field: K, transform: (val: OrderState[K]) => T) {
  const value = useOrderData(s => s[field]);
  return transform(value);
}

export const useOrderSymbol = () => {
  const [symbol] = useGroupState('symbol');
  return symbol ? symbol.symbol : '';
  //return useOrderStateAs('symbol', s => (s ? s.symbol : ''));
};
export const useOrderSymbolOrUndefined = () => {
  const [symbol] = useGroupState('symbol');
  return symbol ? symbol.symbol : undefined;
};

export const useOrderAssetSymbolOrUndefined = () => {
  const [symbol] = useGroupState('symbol');
  return symbol ? symbol : undefined;
};
