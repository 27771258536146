import { Middleware, Dispatch } from 'redux';
import { getType } from 'typesafe-actions';
import _, { delay } from 'lodash';
import { Balances, SubAccount, SuccessResponseCode } from '@tradingblock/types';
import { BalanceState, DataState } from '../../state';
import { accountIdSelector } from '../../dataSelectors';
import { RootAction, Actions, OrderActions } from '../../actions';
import { CashieringActions } from '../../actions/CashieringActions';
import { ApiProvider, accountApiFromState, useApi, buildApiFromState } from '../../../../context/Api';

export const loadAccountBalances = (state: DataState, dispatch: Dispatch<RootAction>) => {
  const dataNotLoaded = !state.accountData.balances.balances && !state.accountData.balances.isFetching;
  // fetch data if not loaded
  const refreshExisting = true;
  const api = accountApiFromState(state);
  if (dataNotLoaded || refreshExisting === true) {
    if (api) {
      api.balances().then(resp => {
        dispatch(Actions.receiveAccountBalances({ data: resp.payload, responseCode: resp.responseCode }));
      });
    } else {
      console.warn('not loading balances because either accountId or Token are undefined');
    }
  } else if (state.accountData.balances.balances && !state.accountData.balances.isFetching) {
    // if data already loaded, dispatch receive action right away
    dispatch(
      Actions.receiveAccountBalances({ data: state.accountData.balances.balances, responseCode: SuccessResponseCode })
    );
  }
};

export const loadBalancesForWithdrawal = (state: DataState, dispatch: Dispatch<RootAction>) => {
  const api = accountApiFromState(state);
  if (api) {
    api.availableWithdrawalFunds().then(resp => {
      dispatch(Actions.receiveBalancesForWithdrawal({ data: resp.payload, responseCode: resp.responseCode }));
    });
  } else {
    console.warn('not loading balances because either accountId or Token are undefined');
  }
};

export const loadSubAccountBalances = (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  accountId: number,
  subAccounts: SubAccount[],
  update?: boolean
) => {
  const api = buildApiFromState(state);
  const subAccountIds = subAccounts.map(subAccount => subAccount.Id);
  if (api) {
    if (state.account.subaccounts && state.account.subaccounts.subaccounts) {
      const subAccountBalances = Object.keys(state.accountData.subAccountsBalances);

      // If update is not undefined/false, update all the subaccount balances
      let items = [];
      if (update) {
        items = subAccountIds.map(subAccountId => {
          return {
            accountId: accountId,
            subAccountId: subAccountId,
          };
        });
      } else {
        const missingDataSubAccountIds = subAccountIds.filter(
          subAccountId => !subAccountBalances.includes(subAccountId.toString())
        );
        items = missingDataSubAccountIds.map(subAccountId => {
          return {
            accountId: accountId,
            subAccountId: subAccountId,
          };
        });
      }

      if (items.length > 0) {
        api.balances
          .getForAccounts(items)
          .then(resp => {
            if (resp.responseCode === SuccessResponseCode) {
              const balances = resp.payload;
              balances.forEach(balance => {
                if (balance.AccountId && balance.SubaccountId) {
                  dispatch(
                    Actions.receiveSubAccountBalances({
                      accountId: balance.AccountId,
                      subAccountId: balance.SubaccountId,
                      balance: balance,
                    })
                  );
                }
              });
            }
          })
          .catch(err => {
            console.error('error loading subaccount balances', err);
          });
      }
    }
  }
};

export const loadLinkedAccountBalances = (
  state: DataState,
  dispatch: Dispatch<RootAction>,
  accountIds: number[] | undefined
) => {
  const api = buildApiFromState(state);
  if (api) {
    if (accountIds && accountIds.length > 0) {
      //Get only the missing balances in account ids that are loaded
      const accountIdsBalances = Object.keys(state.accountData.accountsBalances);
      const missingDataAccountIds = accountIds.filter(accountId => !accountIdsBalances.includes(accountId.toString()));

      const items = missingDataAccountIds.map((accountId, index) => {
        return {
          accountId: accountId,
        };
      });

      if (items.length > 0) {
        api.balances
          .getForAccounts(items)
          .then(resp => {
            if (resp.responseCode === SuccessResponseCode) {
              resp.payload.forEach(balance => {
                if (balance.AccountId) {
                  dispatch(
                    Actions.receiveLinkedAccountBalances({
                      balance: balance,
                      accountId: balance.AccountId,
                    })
                  );
                }
              });
            }
          })
          .catch(err => {
            console.error('getForAccounts error:', { err });
          });
      }
    } else if (state.linkedAccounts.accounts) {
      const items = state.linkedAccounts.accounts.map((account, index) => {
        return {
          accountId: account.accountId,
        };
      });
      api.balances
        .getForAccounts(items)
        .then(resp => {
          if (resp.responseCode === SuccessResponseCode) {
            resp.payload.forEach(balance => {
              if (balance.AccountId) {
                dispatch(
                  Actions.receiveLinkedAccountBalances({
                    balance: balance,
                    accountId: balance.AccountId,
                  })
                );
              }
            });
          }
        })
        .catch(err => {
          console.error('getForAccounts error:', { err });
        });
    }
  }
};

export const loadPendingTransfers = (state: DataState, dispatch: Dispatch<RootAction>) => {
  const dataNotLoaded = !state.accountData.balances.pendingTransfers && !state.accountData.balances.isFetching;
  // fetch data if not loaded
  const refreshExisting = true;
  const accountId = accountIdSelector(state);
  const selectedLinkedAccountId = state.account.selectedLinkedAccount && state.account.selectedLinkedAccount.accountId;
  const api = ApiProvider(state, dispatch);
  if (dataNotLoaded || refreshExisting === true) {
    if (api) {
      api.cashiering.transfers
        .all(selectedLinkedAccountId ? selectedLinkedAccountId : accountId, {
          directions: 'Outgoing',
          states: 'PendingRepCreateApproval,PendingFirmCreateApproval,Pending,PendingPrinting,FundsHeld',
        })
        .then(resp => {
          dispatch(Actions.receivePendingTransfers({ data: resp.payload, responseCode: resp.responseCode }));
        });
    } else {
      console.warn('not loading transfers because either accountId or Token are undefined');
    }
  } else if (state.accountData.balances.pendingTransfers && !state.accountData.balances.isFetching) {
    // if data already loaded, dispatch receive action right away
    dispatch(
      Actions.receivePendingTransfers({
        data: state.accountData.balances.pendingTransfers,
        responseCode: SuccessResponseCode,
      })
    );
  }
};

export const BalancesMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = ({
  dispatch,
  getState,
}) => (next: Dispatch<RootAction>) => (action: RootAction) => {
  try {
    // state BEFORE action is dispatched
    const prevState = getState();
    const result = next(action);
    // state AFTER action is dispatched
    const nextState = getState();
    switch (action.type) {
      case getType(CashieringActions.receiveAchWithdrawal):
      case getType(CashieringActions.receiveCheckWithdrawal):
      case getType(CashieringActions.receiveAchDeposit):
      case getType(CashieringActions.receiveWireWithdrawal):
      case getType(OrderActions.receiveCancel):
      case getType(OrderActions.receivePlaceOrder): {
        dispatch(Actions.requestAccountBalances({ throttle: true }));
        break;
      }
      case getType(Actions.requestAccountBalances): {
        if (action.payload.throttle) {
          dispatch(
            Actions.fetchBalances(
              {},
              {
                throttle:
                  // settingsValue('balancePollingThrottleMs')(nextState) ||
                  // InitialState.settings.ui.balancePollingThrottleMs,
                  1000 * 5,
              }
            )
          );
        } else {
          dispatch(Actions.fetchBalances({}, {}));
        }
        break;
      }
      case getType(Actions.fetchBalances): {
        loadAccountBalances(prevState, dispatch);
        break;
      }
      case getType(Actions.requestBalancesForWithdrawal): {
        if (action.payload.throttle) {
          dispatch(
            Actions.fetchBalancesForWithdrawal(
              {},
              {
                throttle:
                  // settingsValue('balancePollingThrottleMs')(nextState) ||
                  // InitialState.settings.ui.balancePollingThrottleMs,
                  1000 * 5,
              }
            )
          );
        } else {
          dispatch(Actions.fetchBalancesForWithdrawal({}, {}));
        }
      }
      case getType(Actions.fetchBalancesForWithdrawal): {
        loadBalancesForWithdrawal(prevState, dispatch);
        break;
      }
      case getType(Actions.requestPendingTransfers): {
        dispatch(Actions.fetchPendingTransfers({}, {}));
        break;
      }
      case getType(Actions.fetchPendingTransfers): {
        loadPendingTransfers(prevState, dispatch);
        break;
      }
      case getType(Actions.requestLinkedAccountBalances): {
        dispatch(
          Actions.fetchLinkedAccountBalances({
            accountIds: action.payload.accountIds,
          })
        );
        break;
      }
      case getType(Actions.fetchLinkedAccountBalances): {
        loadLinkedAccountBalances(prevState, dispatch, action.payload.accountIds);
        break;
      }
      case getType(Actions.requestSubAccountBalances): {
        dispatch(
          Actions.fetchSubAccountBalances({
            accountId: action.payload.accountId,
            subAccounts: action.payload.subAccounts,
            update: action.payload.update,
          })
        );
        break;
      }
      case getType(Actions.fetchSubAccountBalances): {
        loadSubAccountBalances(
          prevState,
          dispatch,
          action.payload.accountId,
          action.payload.subAccounts,
          action.payload.update
        );
        break;
      }
      case getType(Actions.receiveLinkedAccountBalances): {
        break;
      }
    }

    return result;
  } catch (err) {
    console.error('QuotesMiddleware :: Caught an exception for action ', action, err);
  }
};
