import { createStandardAction, ActionType } from 'typesafe-actions';
import {
  OrderValidationError,
  OrderAction,
  AssetSymbol,
  TradingStrategyOrCustom,
  OptionType,
  TradingStrategy,
  GroupOrderLeg,
  PositionInfo,
  OrderFieldError,
  DebitOrCredit,
  BlockGroupOrderInfo,
  Expiration,
  OrderBlockSettings,
  Order,
  OrderLeg,
} from '@tradingblock/types';
import { SharedBlockGroupActions } from '../../../data/global/actions';
import { createPersistableAction } from '../../../data/global/actionUtilities';
import { OrderQuote, PartialOrderStateFromGroup } from './OrderState';
import { BlockTableActions } from '../../../components/blocks/state/blockTable/BlockTableActions';
import { BlockActions } from '../../../components/blocks/state/BlockState';
import { Savable } from '../../../utilities/action';

type WithSymbol<T> = T & { symbol: AssetSymbol };

const SET_LEG_ACTION = '@leg/setLegAction';
const REMOVE_LEG = '@leg/remove';
const ORDER_PLACED = '@event/ORDER_PLACED';
const SET_SYMBOL = 'setSymbol';

const SET_POSITION_INFO = '@order/SET_POSITION_INFO';

const OrderActionTypes = {
  setLegAction: SET_LEG_ACTION,
  setStrategy: 'setStrategy',
  setStrike: 'setStrike',
  setExpiration: 'setExpiration',
  setOptionType: 'setOptionType',
  setLegQuantity: 'setLegQuantity',
  setOrderAction: 'setOrderAction',
  addOrderLeg: 'addOrderLeg',
  clear: 'clear',
  setPrice: 'setPrice',
  setQuantity: 'setQuantity',
  setDebitCredit: 'setDebitCredit',
  updateQuote: 'updateQuote',
  removeQuote: 'removeQuote',
  handleOrderGroupChange: 'handleOrderGroupChange',
  groupTest: 'groupTest',
  initialize: 'initialize',
  addLegFromGroup: 'addLegFromGroup',
  setStopPrice: 'setStopPrice',
  setSubaccountId: 'setSubaccountId',
  setOrder: 'setOrder',
};

export const legacyOrderGroupActions = {
  // addOrderLeg: createStandardAction(OrderActionTypes.addOrderLeg)<Savable<{ leg: GroupOrderLeg; strategy: TradingStrategyOrCustom; symbol?: AssetSymbol }>>(),
  addOrderLeg: createPersistableAction(OrderActionTypes.addOrderLeg)<
    Savable<WithSymbol<BlockGroupOrderInfo> & { settings: OrderBlockSettings }>
  >(),
  handleOrderGroupChange: createStandardAction(OrderActionTypes.handleOrderGroupChange)<
    Savable<WithSymbol<BlockGroupOrderInfo> & { settings: OrderBlockSettings }>
  >(),
};

export const OrderGroupActions = {
  ...SharedBlockGroupActions,
  setSymbol: createStandardAction(SET_SYMBOL)<
    { symbol?: AssetSymbol; options: { defaultToEmptyCustomIfSymbolChanges?: boolean; settings: OrderBlockSettings } },
    { persist?: boolean }
  >(),
  // addOrderLeg: createStandardAction(OrderActionTypes.addOrderLeg)<Savable<{ leg: GroupOrderLeg; strategy: TradingStrategyOrCustom; symbol?: AssetSymbol }>>(),
  addOrderLegFromGroup: createStandardAction(OrderActionTypes.addLegFromGroup)<
    { leg: GroupOrderLeg },
    { persist?: boolean }
  >(),
};

export const OrderInitializerActions = {
  reset: createStandardAction(OrderActionTypes.initialize)<PartialOrderStateFromGroup>(),
};

export const Actions = {
  ...OrderInitializerActions,
  asyncValidateOrder: createStandardAction('asyncValidateOrder')<{}>(),
  clear: createPersistableAction(OrderActionTypes.clear)<Savable<{}>>(),
  setLegAction: createPersistableAction(OrderActionTypes.setLegAction)<
    Savable<{ legId: string; action?: OrderAction }>
  >(),
  setStrategy: createPersistableAction(OrderActionTypes.setStrategy)<{
    settings: OrderBlockSettings;
    strategy: TradingStrategyOrCustom;
    symbol?: AssetSymbol;
    isDirty?: boolean;
    action?: OrderAction;
    reverseLegs?: boolean;
  }>(),
  setOrderAction: createPersistableAction(OrderActionTypes.setOrderAction)<Savable<{ action: OrderAction }>>(),
  setStrike: createPersistableAction(OrderActionTypes.setStrike)<Savable<{ legId: string; strike: number }>>(),
  setExpiration: createPersistableAction(OrderActionTypes.setExpiration)<
    Savable<{ legId: string; expiration?: Expiration }>
  >(),
  setOptionType: createPersistableAction(OrderActionTypes.setOptionType)<{ legId: string; optionType: OptionType }>(),
  setLegQuantity: createPersistableAction(OrderActionTypes.setLegQuantity)<{ legId: string; quantity: number }>(),
  setQuantity: createPersistableAction(OrderActionTypes.setQuantity)<{ quantity: number }>(),

  ...legacyOrderGroupActions,
  ...OrderGroupActions,
  setPositionInfo: createStandardAction(SET_POSITION_INFO)<{ info: PositionInfo }>(),
  orderPlaced: createStandardAction(ORDER_PLACED)<{}>(),
  setPrice: createStandardAction(OrderActionTypes.setPrice)<{
    price: number | undefined;
    updateCreditOrDebit: boolean;
    mid: number | undefined;
    negativePrice?: boolean;
  }>(),
  setStopPrice: createStandardAction(OrderActionTypes.setStopPrice)<{ price?: number }>(),
  setDebitCredit: createPersistableAction(OrderActionTypes.setDebitCredit)<{ debitCredit?: DebitOrCredit }>(),
  setSubaccountId: createStandardAction(OrderActionTypes.setSubaccountId)<{ subaccountId?: number }>(),
  setOrder: createStandardAction(OrderActionTypes.setOrder)<{ order: Order<OrderLeg> }>(),
  updateQuote: createStandardAction(OrderActionTypes.updateQuote)<OrderQuote[]>(),
  removeQuote: createStandardAction(OrderActionTypes.removeQuote)<string>(),
  removeLeg: createPersistableAction(REMOVE_LEG)<{ id: string }>(),
};

export const ValidationActions = {
  startValidation: createStandardAction('startValidation')<{ strategy?: TradingStrategy }>(),
  finishValidation: createStandardAction('finishValidation')<{
    match?: TradingStrategy;
    valid: boolean;
    errors: OrderValidationError[];
  }>(),
};

export type OrderInitializeAction = ActionType<typeof OrderInitializerActions>;
export type LegacyOrderGroupAction = ActionType<typeof legacyOrderGroupActions>;
export type OrderGroupAction = ActionType<typeof OrderGroupActions>;
export type SetSymbolAndResetType = ActionType<typeof OrderGroupActions.SetSymbolAndReset>;
export type addOrderLegFromGroupType = ActionType<typeof OrderGroupActions.addOrderLegFromGroup>;
export type OrderValidationActions = ActionType<typeof ValidationActions>;
export type OrderPersistActions = ActionType<typeof Actions>;
export type OrderActions = OrderPersistActions | OrderValidationActions | BlockActions;
const AllActions = {
  ...Actions,
  ...ValidationActions,
  ...BlockTableActions,
};
export type AllOrderActions = ActionType<typeof AllActions>;
