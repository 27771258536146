import React, { FC, useState, useCallback } from 'react';

interface PositionDropdownProps {
  expandRow?: boolean;
  children?: React.ReactNode;
}

/**
 * This is the dropdown that shows each individual position for the top-level position
 */
export const PositionDropdown: FC<PositionDropdownProps> = ({ expandRow, children }) => {
  const [arrow, setArrow] = useState(expandRow ? 'down' : 'right');
  const toggle = useCallback(() => {
    setArrow(arrow === 'right' ? 'down' : 'right');
  }, [arrow, setArrow]);

  return (
    <>
      <button className="btn btn-blend txt-rg" data-toggle="dropdown" title="Options" onClick={toggle} type="button">
        <strong>
          <i className={`far fa-angle-${arrow}`} /> {children}
        </strong>
      </button>
    </>
  );
};
