import React, { useState, useEffect, useCallback } from 'react';
import { DashboardInfo } from '@tradingblock/types';
import { ExternalLink, getClassNames } from '@tradingblock/components';
import { EditDashboardModal } from './EditDashboardModal';

export const DashboardItem: React.FunctionComponent<{
  dashboard: DashboardInfo;
  isSelected?: boolean;
  isLocked?: boolean;
  openInNewWindow?: boolean;
  onSelectBoard?: () => void;
  onSaveBoard?: (board: DashboardInfo) => void;
  onDeleteBoard?: () => void;
}> = ({ dashboard, isSelected, isLocked, openInNewWindow, onSelectBoard, onSaveBoard, onDeleteBoard, children }) => {
  const [showEditBoard, setShowEditBoard] = useState(false);
  const onKeyDown = (e: KeyboardEvent) => {
    // if modals open while pressing ESC, close them
    if (showEditBoard && e.keyCode === 27) {
      e.preventDefault();
      e.stopPropagation();
      if (showEditBoard) {
        setShowEditBoard(false);
      }
    }
  };

  useEffect(() => {
    // NOTE: we add listener to body, instead of document (since dashboard drawer listens on document, and this inner listener should fire first)
    document.body.addEventListener('keydown', onKeyDown);
    return () => {
      document.body.removeEventListener('keydown', onKeyDown);
    };
  });
  const onKeyPressHandler = useCallback(() => {}, []);

  const className = getClassNames(['board-thumb', isSelected ? 'board-thumb-current' : null]);
  return (
    <>
      <div className={className} onClick={onSelectBoard} role="button" tabIndex={0} onKeyPress={onKeyPressHandler}>
        <div className="board-thumb-image">
          <div className="board-thumb-title">{dashboard && dashboard.title}</div>
        </div>
        {isSelected && (
          <span className="board-thumb-current-indicator fa-stack fa-2x">
            <i className="fas fa-circle fa-stack-2x"></i>
            <i className="fas fa-check fa-stack-1x fa-inverse"></i>
          </span>
        )}
        {isLocked && (
          <div className="board-thumb-icon">
            <i className="fal fa-lg fa-lock-alt" title="Not editable"></i>
          </div>
        )}
      </div>
      {children}
      {onSaveBoard && (
        <>
          <div className="board-thumb-edit">
            <button type="button" className="btn-link" onClick={() => setShowEditBoard(true)}>
              <i className="fas fa-cog fa-lg"></i> Edit board
            </button>
          </div>
          <EditDashboardModal dashboard={dashboard} show={showEditBoard} onSave={onSaveBoard} onDelete={onDeleteBoard} onClose={() => setShowEditBoard(false)} />
        </>
      )}
      {openInNewWindow && dashboard.dashboardId && (
        <>
          <div className="board-thumb-edit">
            <ExternalLink href={`/${dashboard.dashboardId}`}>
              <i className="fas fa-external-link fa-lg"></i> Open in new tab
            </ExternalLink>
          </div>
        </>
      )}
    </>
  );
};
