import React, { useMemo } from 'react';
import _ from 'lodash';
import { useStateSelector } from '../../../../../data/global/dataSelectors';
import { DetailField, DetailFields } from '../../../../../components/basic/DetailFields';
import { Country, DateModel } from '@tradingblock/types';
import { formatPhoneNumber, formatYearsEmployed } from '../../../../../utilities/accountManagement';
import dayjs from 'dayjs';

export const ApplicationAccountInformation: React.FunctionComponent<{}> = () => {
  const application = useStateSelector(s => s.private.applications.currentItem.application);
  const { primaryAccountHolder, secondaryAccountHolder } = application;
  const accountHolders = secondaryAccountHolder
    ? [primaryAccountHolder, secondaryAccountHolder]
    : [primaryAccountHolder];
  const isUgma = application.type === 'Ugma';
  const secondaryOrUgmaBen = isUgma ? 'UGMA Beneficiary' : 'Secondary Account Holder';

  const maskSSN = (ssn: string | undefined) => {
    const masked = ssn || '';
    const prefix = 'xxxx-xx-';
    const unmaskedIdx = masked.length - 4;
    return prefix + masked.substring(unmaskedIdx);
  };

  const formatBirthDate = (birthDate: DateModel | undefined) => {
    const { day, month, year } = birthDate || { day: '', month: '', year: '' };
    const dateString = `${month}/${day}/${year}`;
    if (birthDate) {
      return dayjs(dateString).format('MMMM DD, YYYY');
    }
    return 'None';
  };
  const fields = useMemo(() => {
    let fieldList: DetailField[] = [];
    if (application && primaryAccountHolder) {
      fieldList = [
        ...fieldList,
        {
          Value: () => (
            <>
              <div className="fields-title mb-3">Account Information</div>
            </>
          ),
        },
        {
          Value: () => null,
        },
      ];
      accountHolders.map((accountHolder, index) => {
        const prefix = index === 0 ? 'Primary Account Holder' : secondaryOrUgmaBen;

        fieldList = [
          ...fieldList,
          {
            Value: () => <span className="fields-title">{prefix}</span>,
          },
          { Value: () => null },
          {
            title: 'Name',
            Value: () => (
              <div className="mute mb-3">
                {accountHolder && accountHolder.firstName} {(accountHolder && accountHolder.middleInitial) || ''}{' '}
                {accountHolder && accountHolder.lastName} {(accountHolder && accountHolder.suffix) || ''}
              </div>
            ),
          },
          {
            title: 'Phone',
            Value: () => (
              <a className="mute" href={`tel:${accountHolder && accountHolder.phones[0].phoneNumber}`}>
                {accountHolder && accountHolder.phones[0]
                  ? formatPhoneNumber(accountHolder.phones[0].phoneNumber)
                  : 'Phone Number Not Found'}{' '}
                - {`${accountHolder && accountHolder.phones[0].type}`}
              </a>
            ),
          },
          {
            title: 'Residential Address',
            Value: () => (
              <div className="mb-3">
                {accountHolder && accountHolder.address ? (
                  <>
                    {accountHolder.address.address1 && <div className="mute">{accountHolder.address.address1}</div>}
                    {accountHolder.address.address2 && accountHolder.address.address2 !== '' && (
                      <div className="mute">{accountHolder.address.address2}</div>
                    )}
                    <span className="mute">
                      {accountHolder.address.city}
                      {accountHolder.address.state ? `, ${accountHolder.address.state}` : ''}{' '}
                      {accountHolder.address.postalCode}
                    </span>
                    <div className="mute">{accountHolder.address.country}</div>
                  </>
                ) : null}
              </div>
            ),
          },
          {
            title: 'Mailing Address',
            Value: () => (
              <>
                {accountHolder && accountHolder.mailingAddress ? (
                  <>
                    {accountHolder.mailingAddress.address1 && (
                      <div className="mute">{accountHolder.mailingAddress.address1}</div>
                    )}
                    {accountHolder.mailingAddress.address2 && accountHolder.mailingAddress.address2 !== '' && (
                      <div className="mute">{accountHolder.mailingAddress.address2}</div>
                    )}
                    <span className="mute">
                      {accountHolder.mailingAddress.city}
                      {accountHolder.mailingAddress.state ? `, ${accountHolder.mailingAddress.state}` : ''}{' '}
                      {accountHolder.mailingAddress.postalCode}
                    </span>
                    <div className="mute">{accountHolder.mailingAddress.country}</div>
                  </>
                ) : null}
                {accountHolder && !accountHolder.mailingAddress && (
                  <span className="mute">Same as Residential Address</span>
                )}
              </>
            ),
          },
          {
            Value: () => (
              <>
                {accountHolder && (
                  <div className="mb-3">
                    <div className="props-title" style={{ fontWeight: 'bold' }}>
                      Personal Information
                    </div>
                    <div className="mb-3">
                      Birth Date
                      {accountHolder.dateOfBirth ? (
                        <div className="mute">{formatBirthDate(accountHolder.dateOfBirth)}</div>
                      ) : (
                        'Not found'
                      )}
                    </div>

                    <div className="mb-3">
                      Social Security/ITIN{' '}
                      <div className="mute">{accountHolder.ssn ? maskSSN(accountHolder.ssn) : 'Not found'}</div>
                    </div>
                    <div className="mb-3">
                      Marital Status{' '}
                      <div className="mute">
                        {accountHolder.maritalStatus ? accountHolder.maritalStatus : 'Not found'}
                      </div>
                    </div>
                    <div className="mb-3">
                      Dependents{' '}
                      <div className="mute">{accountHolder.dependents ? accountHolder.dependents : 'Not found'}</div>
                    </div>
                    <div className="props-title">
                      US Citizen?{' '}
                      <div className="mute">
                        {accountHolder.citizenshipCountry === Country.UnitedStatesOfAmerica ? 'Yes' : 'No'}
                      </div>
                    </div>
                    {accountHolder.address.country !== Country.UnitedStatesOfAmerica && (
                      <>
                        <div className="props-title mt-3 mb-3">
                          Foreign Tax Identification Number{' '}
                          <div className="mute">
                            {accountHolder.foreignTaxWithholding.taxIdentificationNumber
                              ? accountHolder.foreignTaxWithholding.taxIdentificationNumber
                              : 'Not found'}
                          </div>
                        </div>
                        {!accountHolder.foreignTaxWithholding.taxIdentificationNumber && (
                          <>
                            <div className="props-title mb-3">
                              Jurisdiction does not issue Tax IDs?{' '}
                              <div className="mute">
                                {accountHolder.foreignTaxWithholding.jurisdictionDoesNotIssueTIN ? 'Yes' : 'No'}
                              </div>
                            </div>
                            <div className="props-title">
                              Account holder not issued Tax ID?{' '}
                              <div className="mute">
                                {accountHolder.foreignTaxWithholding.notIssuedForeignTIN ? 'Yes' : 'No'}
                              </div>
                            </div>
                          </>
                        )}
                        {accountHolder.foreignTaxWithholding.notIssuedForeignTIN && (
                          <>
                            <div className="props-title">
                              <div className="mb-3 mt-3">Foreign Tax ID Not Required Explanation</div>
                              <div className="mute">
                                {accountHolder.foreignTaxWithholding.foreignTINNotRequiredExplanation
                                  ? accountHolder.foreignTaxWithholding.foreignTINNotRequiredExplanation
                                  : 'Not found'}
                              </div>
                            </div>
                          </>
                        )}
                        {accountHolder.mailingAddress &&
                          accountHolder.mailingAddress.country === Country.UnitedStatesOfAmerica &&
                          accountHolder.address.country !== Country.UnitedStatesOfAmerica && (
                            <>
                              <div className="props-title mt-3 mb-3">
                                <div className="mb-3">Explanation of US Mailing Address</div>
                                <div className="mute">
                                  {accountHolder.foreignTaxWithholding.letterOfExplanation
                                    ? accountHolder.foreignTaxWithholding.letterOfExplanation
                                    : 'Not found'}
                                </div>
                              </div>
                            </>
                          )}
                      </>
                    )}
                  </div>
                )}
              </>
            ),
          },
          {
            Value: () => (
              <>
                {accountHolder && (
                  <>
                    <div className="props-title mb-3" style={{ fontWeight: 'bold' }}>
                      Employment Information
                    </div>
                    <div>
                      <div>Status</div>
                      <div className="mute">
                        {accountHolder.employmentType ? accountHolder.employmentType : 'Not found'}
                      </div>
                    </div>
                    {accountHolder.employmentType && accountHolder.employmentType === 'Employed' && (
                      <>
                        <div className="mt-3 mb-3">
                          Employer:{' '}
                          <div className="mute">{accountHolder.employer ? accountHolder.employer : 'Not found'}</div>
                        </div>
                        <div className="mb-3">
                          Job Title:{' '}
                          <div className="mute">
                            {accountHolder.jobPosition ? accountHolder.jobPosition : 'Not found'}
                          </div>
                        </div>
                        <div className="mb-5">
                          Years Employed:{' '}
                          <div className="mute">
                            {accountHolder.yearsEmployed
                              ? formatYearsEmployed(accountHolder.yearsEmployed)
                              : 'Not found'}
                          </div>
                        </div>
                        <div className="props-title mb-3">Employer Address</div>
                        <div className="mute">
                          {accountHolder.employerAddress && (
                            <>
                              {accountHolder.employerAddress.address1 && (
                                <div className="mb-3">{accountHolder.employerAddress.address1}</div>
                              )}
                              {accountHolder.employerAddress.address2 && (
                                <div className="mb-3">{accountHolder.employerAddress.address2}</div>
                              )}
                              <div>
                                {accountHolder.employerAddress.city}
                                {accountHolder.employerAddress.state && `, ${accountHolder.employerAddress.state}`}{' '}
                                {accountHolder.employerAddress.postalCode}
                              </div>
                              <div>{accountHolder.employerAddress.country}</div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            ),
          },
        ];
      });
    }
    return fieldList;
  }, [application]);

  return (
    <div className="screen-body-section">
      <DetailFields fieldList={fields} valueAs="div" />
    </div>
  );
};
