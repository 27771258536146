import qs from 'qs';
import _ from 'lodash';
import { alphabeticalSort } from '../../../utilities/data';
import { EntitlementBaseUpdate, EntitlementsSearchRequest } from '@tradingblock/types';

export const entitlementRequestToQueryString = (request: EntitlementsSearchRequest) =>
  qs.stringify(request, { indices: false, sort: alphabeticalSort });

export const entitlementRequestKey = (request: EntitlementsSearchRequest) =>
  entitlementRequestToQueryString({ ...request });

export const entitlementUpdateKey = (userName: string, id: number | string) => `${userName}-${id}`;

export const getEntityKey = (re: EntitlementBaseUpdate) => {
  const updateId = re.UserId;
  const userName = re.UserName;

  return entitlementUpdateKey(userName, updateId);
};
