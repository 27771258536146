import dayjs from 'dayjs';
import { useMemo } from 'react';
import { TimeframePresetValues, ActivityTimeFrameSelector } from '../state/ActivitySelector';
import { useActivitySettingsValue } from '../useActivitySettings';
import { useActivityDataSelector } from '../state/ActivityState';
import { useBlockId } from '../../../components/blocks/BlockState';

export const useActivityTimeframes = () => {
  const startOfToday = dayjs().startOf('day');
  const endOfToday = dayjs().endOf('day');
  const { all, today } = useMemo(() => {
    return TimeframePresetValues(startOfToday, endOfToday);
  }, [startOfToday, endOfToday]);
  return { presets: all };
};

export const useActivityTimeframeValues = () => {
  const blockId = useBlockId();
  const { timeframe } = useActivitySettingsValue();
  const { startDate, endDate } = useActivityDataSelector(s => ActivityTimeFrameSelector(s, blockId, timeframe));
  // Bad hack to memoize the date strings so that the datepicker doesn't re-render on every date reference change, even if same date
  const startDateString = useMemo(() => (startDate instanceof Date ? startDate.toISOString() : new Date()), [startDate]);
  const endDateString = useMemo(() => (endDate instanceof Date ? endDate.toISOString() : new Date()), [endDate]);

  const memoStart = useMemo(() => startDate, [startDateString]);
  const memoEnd = useMemo(() => endDate, [endDateString]);
  return { startDate: memoStart, endDate: memoEnd };
};
