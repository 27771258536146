import React, { useCallback } from 'react';
import { Field, Formik } from 'formik';
import _ from 'lodash';
import {
  AllBooleanToggleTypes,
  BooleanToggleType,
  MarketDataProfileReviewUpdateValueProps,
  ProSubType,
} from '@tradingblock/types';
import { CheckListInput, FormGroup, Modal, StepperField, TextboxField } from '@tradingblock/components';
import { useStateSelector } from '../../../data/global/dataSelectors';
import { useDispatch } from 'react-redux';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';

export const MarketDataProfileReview: React.FunctionComponent<{
  show?: boolean;
  toggleModal: () => void;
}> = ({ show, toggleModal }) => {
  // const dispatch = useDispatch();

  // const accountId = useStateSelector(s => s.accounts.account && s.accounts.account.AccountId);
  // const accountDisclosures = useStateSelector(
  //   s =>
  //     s.accountManagement.accountManagementDetails &&
  //     s.accountManagement.accountManagementDetails.details.accountDisclosures
  // );

  const initialMarketDataProfileReviewValues: MarketDataProfileReviewUpdateValueProps = {
    industryEmployed: undefined,
    foreignBank: undefined,
    foreignShellBank: undefined,
    foreignFinancialInstitution: undefined,
    proprietarySecuritiesAccount: undefined,
    personalUse: undefined,
    secCftcRegistration: undefined,
    associationRegistration: undefined,
    jobFunctions: undefined,
  };

  const onValidate = (values: MarketDataProfileReviewUpdateValueProps) => {
    return {};
  };

  const onSubmit = useCallback(
    (values, { resetForm }) => {
      //TODO: create action to update market data profile review when endpoint is ready
      // dispatch(AccountManagementDataActions.updateMarketDataProfileReview(accountId, values));
      resetForm();
      toggleModal();
    },
    [toggleModal]
  );

  return (
    <Formik initialValues={initialMarketDataProfileReviewValues} onSubmit={onSubmit} validate={onValidate}>
      {({ handleSubmit, isSubmitting, values }) => (
        <Modal show={show} setShow={() => toggleModal()}>
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>Market Data User Profile Review</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body className="txt-sm">
            <p>
              Please answer the questions below to verify your status as a professional or non-professional user of
              NYSE, AMEX, Nasdaq and OPRA market data. Please note:
            </p>

            <ul>
              <li>Delayed market data is provided at no cost to all users.</li>
              <li>Real-time market data is provided at no cost to all non-professional users.</li>
              <li>Real-time market data is provided at cost to all professional users.</li>
            </ul>

            <p>
              Are you employed or a member of a registered broker-dealer, securities or futures exchange, or securities
              industry regulatory body (e.g., FINRA, NFA)?
            </p>
            <FormGroup>
              <Field
                id="industryEmployed"
                defaultLabelId="boolean"
                component={StepperField}
                options={AllBooleanToggleTypes}
                disabled={isSubmitting}
              />
            </FormGroup>
            {values.industryEmployed === BooleanToggleType.Yes && (
              <FormGroup>
                <Field
                  id="disclosuresIndustryEmployedFirmName"
                  defaultLabelId="disclosuresIndustryEmployedFirmName"
                  component={TextboxField}
                  disabled={isSubmitting}
                  placeholder="Firm Name"
                />
              </FormGroup>
            )}
            <p>Are you opening this account on behalf of one of the following:</p>

            <p>A foreign bank?</p>
            <FormGroup>
              <Field
                id="foreignBank"
                defaultLabelId="boolean"
                component={StepperField}
                options={AllBooleanToggleTypes}
                disabled={isSubmitting}
              />
            </FormGroup>

            <p>A foreign shell bank?</p>
            <FormGroup>
              <Field
                id="foreignShellBank"
                defaultLabelId="boolean"
                component={StepperField}
                options={AllBooleanToggleTypes}
                disabled={isSubmitting}
              />
            </FormGroup>

            <p>A foreign financial institution?</p>
            <FormGroup>
              <Field
                id="foreignFinancialInstitution"
                defaultLabelId="boolean"
                component={StepperField}
                options={AllBooleanToggleTypes}
                disabled={isSubmitting}
              />
            </FormGroup>

            <p>A broker or dealer's proprietary securities account (PAB account)?</p>
            <FormGroup>
              <Field
                id="proprietarySecuritiesAccount"
                defaultLabelId="boolean"
                component={StepperField}
                options={AllBooleanToggleTypes}
                disabled={isSubmitting}
              />
            </FormGroup>

            <p>Do you use market data for your own personal, non-business use?</p>
            <FormGroup>
              <Field
                id="personalUse"
                defaultLabelId="boolean"
                component={StepperField}
                options={AllBooleanToggleTypes}
                disabled={isSubmitting}
              />
            </FormGroup>
            {values.personalUse === BooleanToggleType.Yes && (
              <>
                <p>Are you currently registered with the SEC or the CFTC?</p>
                <FormGroup>
                  <Field
                    id="secCftcRegistration"
                    defaultLabelId="boolean"
                    component={StepperField}
                    options={AllBooleanToggleTypes}
                    disabled={isSubmitting}
                  />
                </FormGroup>
                <p>
                  Are you currently registered or qualified with any securities agency, any securities exchange,
                  association or regulatory body, or any commodities or futures contract market, association or
                  regulatory body, in the United States or elsewhere?
                </p>
                <FormGroup>
                  <Field
                    id="associationRegistration"
                    defaultLabelId="boolean"
                    component={StepperField}
                    options={AllBooleanToggleTypes}
                    disabled={isSubmitting}
                  />
                </FormGroup>
                <p>
                  Do you perform any functions that require (or are similar to those that require) registration or
                  qualification with any US or non-US securities or futures regulatory body or agency (i.e. SEC, CFTC,
                  other state or federal securities or futures agency, regulator, exchange, market or association)?
                </p>
                <FormGroup>
                  <Field
                    id="jobFunctions"
                    defaultLabelId="boolean"
                    component={StepperField}
                    options={AllBooleanToggleTypes}
                    disabled={isSubmitting}
                  />
                </FormGroup>
              </>
            )}
            <p>* Select whether you wish to receive Real-time or Delayed quotes:</p>
            <FormGroup>
              <Field
                id="marketDataType"
                defaultLabelId="marketDataType"
                component={CheckListInput}
                options={[
                  {
                    label: 'Delayed quotes (Free)',
                    value: ProSubType.Delayed,
                  },
                  {
                    label: 'Real-time quotes ($122.50 per month)',
                    value: ProSubType.RealTime,
                  },
                ]}
                disabled={isSubmitting}
              />
            </FormGroup>
          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <Button variant="secondary" onClick={() => toggleModal()}>
              Cancel
            </Button>
            <Button variant="primary" onClick={(values: any) => handleSubmit(values)} disabled={isSubmitting}>
              Submit
            </Button>
          </BootstrapModal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
