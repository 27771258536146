import _slicedToArray from "/var/lib/jenkins/jobs/workspace/tradingblock_prod/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import React, { useState, useCallback, useMemo } from 'react';
import Toggle from 'react-bootstrap-toggle';
export var ToggleButton = function ToggleButton(_ref) {
  var active = _ref.active,
      onClick = _ref.onClick,
      on = _ref.on,
      off = _ref.off,
      size = _ref.size,
      onstyle = _ref.onstyle,
      offstyle = _ref.offstyle,
      disabled = _ref.disabled,
      width = _ref.width;

  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      activeState = _useState2[0],
      setIsActive = _useState2[1];

  var activeValue = useMemo(function () {
    return active === undefined ? activeState : active;
  }, [active, activeState]);
  var onToggle = useCallback(function () {
    var newValue = activeValue === true ? false : true;
    setIsActive(newValue);

    if (onClick) {
      onClick(newValue);
    }
  }, [setIsActive, activeValue, onClick]);
  return React.createElement(Toggle, {
    onClick: onToggle,
    on: on || 'ON',
    off: off || 'OFF',
    size: size,
    onstyle: onstyle,
    offstyle: offstyle,
    active: activeValue,
    disabled: disabled,
    width: width
  });
};