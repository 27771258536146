import React, { useEffect, useMemo, useState } from 'react';
import { PositionEffect, OrderActionDirection, PositionInfo, OrderAction } from '@tradingblock/types';
import { OrderState } from '../../state/OrderState';
import { SymbolOccBasicSelector, LegSelector } from '../../state/OrderLegSelector';
import { useStateSelector, positionInfo } from '../../../../data/global/dataSelectors';
import { useBlockData } from '../../../../components/blocks/BlockState';
import { PositionDTO } from '@tradingblock/api';
import _ from 'lodash';

const Position = ({ position, quantity }: { position?: PositionEffect; quantity: number }) => {
  const quantityValue = useMemo(() => (quantity !== 0 ? `(${quantity})` : ''), [quantity]);
  const direction = useMemo(() => (position === PositionEffect.Open ? 'open' : 'close'), [position]);
  const directionClass = useMemo(() => (position === PositionEffect.Open ? 'neg' : 'pos'), [position]);
  const positionClass = useMemo(() => {
    if (quantity < 0) {
      return 'neg';
    } else if (quantity > 0) {
      return 'pos';
    } else if (quantity === 0) {
      return '';
    }
  }, [quantity]);

  return position ? (
    <div className="prop-stacked caps txt-sm">
      <div className={`prop-title compact`}>
        <span className="mute">Position</span>
        <span className={`${positionClass}`}> {quantityValue}</span>{' '}
      </div>
      <div className={`prop-value ${directionClass}`}>To {direction}</div>
    </div>
  ) : (
    <></>
  );
};

const PositionMemo = React.memo(Position);

export const LegPosition: React.FC<{ legId: string; positions: PositionDTO[] }> = ({ legId, positions }) => {
  const blockState = useBlockData<OrderState>();
  const occSymbol = useMemo(() => SymbolOccBasicSelector(blockState, legId) || '', [legId, blockState]);

  // States
  const [accountPositionInfo, setAccountPositionInfo] = useState<PositionInfo>({});

  // Redux
  const subId = useStateSelector(s => s.account.subAccountId);

  useEffect(() => {
    if (positions) {
      const positionsWithId = positions.map(p => {
        return {
          ...p,
        };
      });
      const info = _.reduce(
        positionsWithId,
        (acc: PositionInfo, p) => {
          const symbolKey = p.Symbol;
          const existing = acc[symbolKey] || { quantity: 0 };
          const direction =
            p.OpenQuantity + existing.quantity < 0 ? OrderActionDirection.Short : OrderActionDirection.Long;
          const quantity = existing.quantity + p.OpenQuantity;
          return {
            ...acc,
            [symbolKey]: {
              underlyingSymbol: p.UnderlyingSymbol,
              quantity,
              direction,
            },
          };
        },
        {}
      );

      if (info) {
        setAccountPositionInfo(info);
      }
    }
  }, []);

  const positionQuantity = useStateSelector(s => {
    const { quantity } = positionInfo(s, occSymbol);
    const info = accountPositionInfo[occSymbol];
    const quantityValue = subId && info ? info.quantity : quantity;

    return quantityValue;
  });

  const legPosition = useMemo(() => {
    const position = LegSelector.PositionEffect(blockState, legId);
    const action = LegSelector.Action(blockState, legId);
    const info = accountPositionInfo[occSymbol];

    const positionIsLong = info && info.quantity > 0;
    let subPosition: PositionEffect | undefined;

    if (positionIsLong && action === OrderAction.Buy) {
      subPosition = PositionEffect.Open;
    } else if (positionIsLong && action === OrderAction.Sell) {
      subPosition = PositionEffect.Close;
    } else if (!positionIsLong && action === OrderAction.Buy) {
      subPosition = PositionEffect.Close;
    } else if (!positionIsLong && action === OrderAction.Sell) {
      subPosition = PositionEffect.Open;
    }

    const positionValue = subId && info ? subPosition : position;
    // if can't determine position effect, default to Open
    return positionValue || PositionEffect.Open;
  }, [legId, blockState, subId, accountPositionInfo]);

  return <PositionMemo position={legPosition} quantity={positionQuantity} />;
};
