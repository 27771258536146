import React from 'react';
import { useActivityActions } from '../state/ActivityActions';
import { Timeframe } from '../../../components/basic/Timeframe';
import { useActivityTimeframeValues } from '../data/useActivityTimeframe';

export const TimeframeContainer: React.FC<{ type?: string }> = ({ children, type }) => {
  const { startDate, endDate } = useActivityTimeframeValues();
  const { setTimeframe } = useActivityActions();

  let blockStyle = "block-section block-section-fixed";

  //if key is present, remove block-section class 
  if (type) {
    if (['activityBlockMessage', 'activityBlockTimeFrame'].includes(type)) {
      blockStyle = blockStyle.split(" ")[1].trim()
    }
  }

  return (
    <div style={{ paddingBottom: '10px' }} className={blockStyle}>
      <div className="row">
        <div className="col-auto">
          <Timeframe initialStartDate={startDate} initialEndDate={endDate} onSave={setTimeframe} caret={<i className="fas fa-caret-down" />} />
        </div>
        {children}
      </div>
    </div>
  );
};
