import { Middleware, Dispatch } from 'redux';
import { Action, PayloadMetaAction, TypeConstant } from 'typesafe-actions';
import dayjs from 'dayjs';
import _ from 'lodash';
import { DataState } from '../state';
import { RootAction } from '../actions';

interface ThrottleActionState {
  throttled: boolean;
  nextAction?: RootAction;
  lastRun?: Date;
  timeout?: number;
}

function isThrottledAction<T extends TypeConstant, P, M extends { throttle: number }>(
  action: RootAction | PayloadMetaAction<T, P, M>
): action is PayloadMetaAction<T, P, M> {
  const throttle = _.get(action, 'meta.throttle', undefined);
  if (_.isNil(throttle)) {
    return false;
  }
  return true;
}

const throttleState: { [actionType: string]: ThrottleActionState } = {};

const defaultActionThrottleState: ThrottleActionState = { throttled: false };

const getState = (actionType: string) => {
  return throttleState[actionType] || defaultActionThrottleState;
};
const setState = (actionType: string, updateFunc: (curr: ThrottleActionState) => ThrottleActionState) => {
  const existing = getState(actionType);
  const updatedState = updateFunc(existing);
  throttleState[actionType] = updatedState;
  return updatedState;
};

const timeFromNow = (
  date: Date,
  millisecondOffset: number,
  unit: dayjs.QUnitType | dayjs.OpUnitType = 'millisecond'
) => {
  const timeoutFinished = dayjs(date).add(millisecondOffset, 'millisecond');
  const now = dayjs(new Date());
  const diff = timeoutFinished.diff(now, unit);
  return diff;
};

const nextRunTime = (actionType: string, unit: dayjs.QUnitType | dayjs.OpUnitType) => {
  const curr = getState(actionType);
  const { lastRun, timeout } = curr;
  if (lastRun !== undefined && timeout !== undefined) {
    return timeFromNow(lastRun, timeout, unit);
  }
  return undefined;
};

const runActionAfterTimeout = (type: string, timeout: number, next: (act: RootAction) => void) => {
  return setTimeout(() => {
    // throttled[action.type] = false;
    // throttledTimeout[action.type] = undefined;
    const actionToRun = getState(type).nextAction;
    // setState(type, val => {
    //   return {
    //     ...val,
    //     throttled: false,
    //     nextAction: undefined,
    //     lastRun: new Date(),
    //   };
    // });
    // const actionToRun = actions[action.type];

    if (actionToRun) {
      console.log(`rerunning throttled %c${type}`, 'color:blue', actionToRun);
      setState(type, val => {
        return {
          ...val,
          throttled: false,
          nextAction: undefined,
          lastRun: new Date(),
        };
      });
      next(actionToRun);
      // actions[action.type] = undefined;
    } else {
      setState(type, val => {
        return {
          ...val,
          throttled: false,
          nextAction: undefined,
        };
      });
    }
  }, timeout);
};

// const throttled: { [actionType: string]: boolean } = {};
// const throttledTimeout: { [actionType: string]: number | undefined } = {};
// const actions: { [actionType: string]: RootAction | undefined } = {};
export const throttleMiddleware: Middleware<Dispatch<RootAction>, DataState, Dispatch<RootAction>> = () => (
  next: Dispatch<RootAction>
) => (action: RootAction) => {
  if (isThrottledAction(action)) {
    const time = action.meta && action.meta.throttle;

    if (!time) {
      return next(action);
    }

    const curr = getState(action.type);
    const lastRun = curr.lastRun;
    if (curr && curr.throttled) {
      //actions[action.type] = action;
      const currState = setState(action.type, val => ({
        ...val,
        nextAction: action,
      }));
      const nextRunTimeMS = nextRunTime(action.type, 'second');
      console.log(
        `Throttled ${action.type}. ${nextRunTimeMS ? `(rerunning in %c${nextRunTimeMS} %cseconds)` : ''}`,
        'color:blue',
        'color:black'
      );
      return;
    } else if (lastRun && timeFromNow(lastRun, time) > 0) {
      const diffms = timeFromNow(lastRun, time);
      const currState = setState(action.type, val => ({
        ...val,
        throttled: true,
        timeout: diffms,
        nextAction: action,
      }));
      runActionAfterTimeout(action.type, diffms, next);
    } else {
      // throttled[action.type] = true;
      // throttledTimeout[action.type] = time;
      setState(action.type, val => {
        return {
          ...val,
          timeout: time,
          throttled: true,
          lastRun: new Date(),
        };
      });
      runActionAfterTimeout(action.type, time, next);

      next(action);
    }
  } else {
    next(action);
  }
};
