import React from 'react';
import { AccountManagementTable } from '../AccountManagement/components/AccountManagementTable';

export const AdminAccountManagementIndex = () => {
  return (
    <div>
      <AccountManagementTable />
    </div>
  );
};
