import createCachedSelector from 're-reselect';
import dayjs from 'dayjs';
import { ActivityState } from './ActivityState';
import { ActivityTimeframes } from '@tradingblock/types';
import _ from 'lodash';

export const TimeframePresetValues = (startOfToday: dayjs.Dayjs, endOfToday: dayjs.Dayjs) => {
  const today = {
    text: 'Today',
    timeframeKey: ActivityTimeframes.Today,
    start: startOfToday,
    end: endOfToday,
  };
  return {
    today,
    all: [
      today,
      {
        text: 'Week',
        timeframeKey: ActivityTimeframes.Week,
        start: dayjs(startOfToday).subtract(1, 'week'),
        end: endOfToday,
      },
      {
        text: 'Month',
        timeframeKey: ActivityTimeframes.Month,
        start: dayjs(startOfToday).subtract(1, 'month'),
        end: endOfToday,
      },
      {
        text: 'Year',
        timeframeKey: ActivityTimeframes.Year,
        start: dayjs(startOfToday).subtract(1, 'year'),
        end: endOfToday,
      },
      {
        text: '3y',
        timeframeKey: ActivityTimeframes['Three Years'],
        start: dayjs(startOfToday).subtract(3, 'year'),
        end: endOfToday,
      },
    ],
  };
};

export const ActivityTimeFrameSelector = createCachedSelector(
  ({ startDate, endDate }: ActivityState, blockId: string, defaultTimeframe: ActivityTimeframes | undefined) => ({
    defaultTimeframe,
    startDate,
    endDate,
  }),
  ({ startDate, endDate, defaultTimeframe }) => {
    const startOfToday = dayjs().startOf('day');
    const endOfToday = dayjs().endOf('day');
    const { all, today } = TimeframePresetValues(startOfToday, endOfToday);

    const presetValue = defaultTimeframe ? all.find(tp => tp.timeframeKey === defaultTimeframe) : undefined;
    if (_.isNil(startDate) && _.isNil(endDate)) {
      if (_.isNil(presetValue)) {
        return {
          startDate: today.start ? today.start.toDate() : undefined,
          endDate: today.end ? today.end.toDate() : undefined,
        };
      }
      return {
        startDate: presetValue.start ? presetValue.start.toDate() : undefined,
        endDate: presetValue.end ? presetValue.end.toDate() : undefined,
      };
    }

    return { startDate, endDate };
  }
)((state, blockId) => blockId);
